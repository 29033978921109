var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ElementRef, Renderer2, OnInit, EventEmitter, OnChanges, SimpleChanges, OnDestroy, AfterViewInit } from "@angular/core";
import { fromEvent } from "rxjs";
import { HelperBlock } from "../widgets/helper-block";
import { ResizeHandle } from "../widgets/resize-handle";
import { Position } from "../model/position";
import { Size } from "../model/size";
var AngularResizableDirective = /** @class */ (function () {
    function AngularResizableDirective(el, renderer) {
        this.el = el;
        this.renderer = renderer;
        this._resizable = true;
        this._handles = {};
        this._handleType = [];
        this._handleResizing = null;
        this._direction = null;
        this._directionChanged = null;
        this._aspectRatio = 0;
        this._containment = null;
        this._origMousePos = null;
        /** Original Size and Position */
        this._origSize = null;
        this._origPos = null;
        /** Current Size and Position */
        this._currSize = null;
        this._currPos = null;
        /** Initial Size and Position */
        this._initSize = null;
        this._initPos = null;
        /** Snap to gird */
        this._gridSize = null;
        this._bounding = null;
        /**
         * Bugfix: iFrames, and context unrelated elements block all events, and are unusable
         * https://github.com/xieziyu/angular2-draggable/issues/84
         */
        this._helperBlock = null;
        this.draggingSub = null;
        this._adjusted = false;
        /**
         * Which handles can be used for resizing.
         * @example
         * [rzHandles] = "'n,e,s,w,se,ne,sw,nw'"
         * equals to: [rzHandles] = "'all'"
         *
         * */
        this.rzHandles = "e,s,se";
        /**
         * Whether the element should be constrained to a specific aspect ratio.
         *  Multiple types supported:
         *  boolean: When set to true, the element will maintain its original aspect ratio.
         *  number: Force the element to maintain a specific aspect ratio during resizing.
         */
        this.rzAspectRatio = false;
        /**
         * Constrains resizing to within the bounds of the specified element or region.
         *  Multiple types supported:
         *  Selector: The resizable element will be contained to the bounding box of the first element found by the selector.
         *            If no element is found, no containment will be set.
         *  Element: The resizable element will be contained to the bounding box of this element.
         *  String: Possible values: "parent".
         */
        this.rzContainment = null;
        /**
         * Snaps the resizing element to a grid, every x and y pixels.
         * A number for both width and height or an array values like [ x, y ]
         */
        this.rzGrid = null;
        /** The minimum width the resizable should be allowed to resize to. */
        this.rzMinWidth = null;
        /** The minimum height the resizable should be allowed to resize to. */
        this.rzMinHeight = null;
        /** The maximum width the resizable should be allowed to resize to. */
        this.rzMaxWidth = null;
        /** The maximum height the resizable should be allowed to resize to. */
        this.rzMaxHeight = null;
        /** Whether to prevent default event */
        this.preventDefaultEvent = true;
        /** emitted when start resizing */
        this.rzStart = new EventEmitter();
        /** emitted when start resizing */
        this.rzResizing = new EventEmitter();
        /** emitted when stop resizing */
        this.rzStop = new EventEmitter();
        this._helperBlock = new HelperBlock(el.nativeElement, renderer);
    }
    Object.defineProperty(AngularResizableDirective.prototype, "appNgResizable", {
        /** Disables the resizable if set to false. */
        set: function (v) {
            if (v !== undefined && v !== null && v !== "") {
                this._resizable = !!v;
                this.updateResizable();
            }
        },
        enumerable: true,
        configurable: true
    });
    AngularResizableDirective.prototype.ngOnChanges = function (changes) {
        if (changes["rzHandles"] && !changes["rzHandles"].isFirstChange()) {
            this.updateResizable();
        }
        if (changes["rzAspectRatio"] && !changes["rzAspectRatio"].isFirstChange()) {
            this.updateAspectRatio();
        }
        if (changes["rzContainment"] && !changes["rzContainment"].isFirstChange()) {
            this.updateContainment();
        }
    };
    AngularResizableDirective.prototype.ngOnInit = function () {
        this.updateResizable();
    };
    AngularResizableDirective.prototype.ngOnDestroy = function () {
        this.removeHandles();
        this._containment = null;
        this._helperBlock.dispose();
        this._helperBlock = null;
    };
    AngularResizableDirective.prototype.ngAfterViewInit = function () {
        var elm = this.el.nativeElement;
        this._initSize = Size.getCurrent(elm);
        this._initPos = Position.getCurrent(elm);
        this._currSize = Size.copy(this._initSize);
        this._currPos = Position.copy(this._initPos);
        this.updateAspectRatio();
        this.updateContainment();
    };
    /** A method to reset size */
    AngularResizableDirective.prototype.resetSize = function () {
        this._currSize = Size.copy(this._initSize);
        this._currPos = Position.copy(this._initPos);
        this.doResize();
    };
    /** A method to get current status */
    AngularResizableDirective.prototype.getStatus = function () {
        if (!this._currPos || !this._currSize) {
            return null;
        }
        return {
            size: {
                width: this._currSize.width,
                height: this._currSize.height
            },
            position: {
                top: this._currPos.y,
                left: this._currPos.x
            }
        };
    };
    AngularResizableDirective.prototype.updateResizable = function () {
        var element = this.el.nativeElement;
        // clear handles:
        this.renderer.removeClass(element, "ng-resizable");
        this.removeHandles();
        // create new ones:
        if (this._resizable) {
            this.renderer.addClass(element, "ng-resizable");
            this.createHandles();
        }
    };
    /** Use it to update aspect */
    AngularResizableDirective.prototype.updateAspectRatio = function () {
        if (typeof this.rzAspectRatio === "boolean") {
            if (this.rzAspectRatio && this._currSize.height) {
                this._aspectRatio = (this._currSize.width / this._currSize.height);
            }
            else {
                this._aspectRatio = 0;
            }
        }
        else {
            var r = Number(this.rzAspectRatio);
            this._aspectRatio = isNaN(r) ? 0 : r;
        }
    };
    /** Use it to update containment */
    AngularResizableDirective.prototype.updateContainment = function () {
        if (!this.rzContainment) {
            this._containment = null;
            return;
        }
        if (typeof this.rzContainment === "string") {
            if (this.rzContainment === "parent") {
                this._containment = this.el.nativeElement.parentElement;
            }
            else {
                this._containment = document.querySelector(this.rzContainment);
            }
        }
        else {
            this._containment = this.rzContainment;
        }
    };
    /** Use it to create handle divs */
    AngularResizableDirective.prototype.createHandles = function () {
        if (!this.rzHandles) {
            return;
        }
        var tmpHandleTypes;
        if (typeof this.rzHandles === "string") {
            if (this.rzHandles === "all") {
                tmpHandleTypes = ["n", "e", "s", "w", "ne", "se", "nw", "sw"];
            }
            else {
                tmpHandleTypes = this.rzHandles.replace(/ /g, "").toLowerCase().split(",");
            }
            for (var _i = 0, tmpHandleTypes_1 = tmpHandleTypes; _i < tmpHandleTypes_1.length; _i++) {
                var type = tmpHandleTypes_1[_i];
                // default handle theme: ng-resizable-$type.
                var handle = this.createHandleByType(type, "ng-resizable-" + type);
                if (handle) {
                    this._handleType.push(type);
                    this._handles[type] = handle;
                }
            }
        }
        else {
            tmpHandleTypes = Object.keys(this.rzHandles);
            for (var _a = 0, tmpHandleTypes_2 = tmpHandleTypes; _a < tmpHandleTypes_2.length; _a++) {
                var type = tmpHandleTypes_2[_a];
                // custom handle theme.
                var handle = this.createHandleByType(type, this.rzHandles[type]);
                if (handle) {
                    this._handleType.push(type);
                    this._handles[type] = handle;
                }
            }
        }
    };
    /** Use it to create a handle */
    AngularResizableDirective.prototype.createHandleByType = function (type, css) {
        var _el = this.el.nativeElement;
        if (!type.match(/^(se|sw|ne|nw|n|e|s|w)$/)) {
            console.error("Invalid handle type:", type);
            return null;
        }
        return new ResizeHandle(_el, this.renderer, type, css, this.onMouseDown.bind(this));
    };
    AngularResizableDirective.prototype.removeHandles = function () {
        for (var _i = 0, _a = this._handleType; _i < _a.length; _i++) {
            var type = _a[_i];
            this._handles[type].dispose();
        }
        this._handleType = [];
        this._handles = {};
    };
    AngularResizableDirective.prototype.onMouseDown = function (event, handle) {
        // skip right click;
        if (event instanceof MouseEvent && event.button === 2) {
            return;
        }
        if (this.preventDefaultEvent) {
            // prevent default events
            event.stopPropagation();
            event.preventDefault();
        }
        if (!this._handleResizing) {
            this._origMousePos = Position.fromEvent(event);
            this.startResize(handle);
            this.subscribeEvents();
        }
    };
    AngularResizableDirective.prototype.subscribeEvents = function () {
        var _this = this;
        this.draggingSub = fromEvent(document, "mousemove", { passive: false }).subscribe(function (event) { return _this.onMouseMove(event); });
        this.draggingSub.add(fromEvent(document, "touchmove", { passive: false }).subscribe(function (event) { return _this.onMouseMove(event); }));
        this.draggingSub.add(fromEvent(document, "mouseup", { passive: false }).subscribe(function () { return _this.onMouseLeave(); }));
        // fix for issue #164
        var isIEOrEdge = /msie\s|trident\//i.test(window.navigator.userAgent);
        if (!isIEOrEdge) {
            this.draggingSub.add(fromEvent(document, "mouseleave", { passive: false }).subscribe(function () { return _this.onMouseLeave(); }));
        }
        this.draggingSub.add(fromEvent(document, "touchend", { passive: false }).subscribe(function () { return _this.onMouseLeave(); }));
        this.draggingSub.add(fromEvent(document, "touchcancel", { passive: false }).subscribe(function () { return _this.onMouseLeave(); }));
    };
    AngularResizableDirective.prototype.unsubscribeEvents = function () {
        this.draggingSub.unsubscribe();
        this.draggingSub = null;
    };
    AngularResizableDirective.prototype.onMouseLeave = function () {
        if (this._handleResizing) {
            this.stopResize();
            this._origMousePos = null;
            this.unsubscribeEvents();
        }
    };
    AngularResizableDirective.prototype.onMouseMove = function (event) {
        if (this._handleResizing && this._resizable && this._origMousePos && this._origPos && this._origSize) {
            this.resizeTo(Position.fromEvent(event));
            this.onResizing();
        }
    };
    AngularResizableDirective.prototype.startResize = function (handle) {
        var elm = this.el.nativeElement;
        this._origSize = Size.getCurrent(elm);
        this._origPos = Position.getCurrent(elm); // x: left, y: top
        this._currSize = Size.copy(this._origSize);
        this._currPos = Position.copy(this._origPos);
        if (this._containment) {
            this.getBounding();
        }
        this.getGridSize();
        // Add a transparent helper div:
        this._helperBlock.add();
        this._handleResizing = handle;
        this.updateDirection();
        this.rzStart.emit(this.getResizingEvent());
    };
    AngularResizableDirective.prototype.stopResize = function () {
        // Remove the helper div:
        this._helperBlock.remove();
        this.rzStop.emit(this.getResizingEvent());
        this._handleResizing = null;
        this._direction = null;
        this._origSize = null;
        this._origPos = null;
        if (this._containment) {
            this.resetBounding();
        }
    };
    AngularResizableDirective.prototype.onResizing = function () {
        this.rzResizing.emit(this.getResizingEvent());
    };
    AngularResizableDirective.prototype.getResizingEvent = function () {
        return {
            host: this.el.nativeElement,
            handle: this._handleResizing ? this._handleResizing.el : null,
            size: {
                width: this._currSize.width,
                height: this._currSize.height
            },
            position: {
                top: this._currPos.y,
                left: this._currPos.x
            },
            direction: __assign({}, this._directionChanged),
        };
    };
    AngularResizableDirective.prototype.updateDirection = function () {
        this._direction = {
            n: !!this._handleResizing.type.match(/n/),
            s: !!this._handleResizing.type.match(/s/),
            w: !!this._handleResizing.type.match(/w/),
            e: !!this._handleResizing.type.match(/e/)
        };
        this._directionChanged = __assign({}, this._direction);
        // if aspect ration should be preserved:
        if (this.rzAspectRatio) {
            // if north then west (unless ne)
            if (this._directionChanged.n && !this._directionChanged.e) {
                this._directionChanged.w = true;
            }
            // if south then east (unless sw)
            if (this._directionChanged.s && !this._directionChanged.w) {
                this._directionChanged.e = true;
            }
            // if east then south (unless ne)
            if (this._directionChanged.e && !this._directionChanged.n) {
                this._directionChanged.s = true;
            }
            // if west then south (unless nw)
            if (this._directionChanged.w && !this._directionChanged.n) {
                this._directionChanged.s = true;
            }
        }
    };
    AngularResizableDirective.prototype.resizeTo = function (p) {
        p.subtract(this._origMousePos);
        var tmpX = Math.round(p.x / this._gridSize.x) * this._gridSize.x;
        var tmpY = Math.round(p.y / this._gridSize.y) * this._gridSize.y;
        if (this._direction.n) {
            // n, ne, nw
            this._currPos.y = this._origPos.y + tmpY;
            this._currSize.height = this._origSize.height - tmpY;
        }
        else if (this._direction.s) {
            // s, se, sw
            this._currSize.height = this._origSize.height + tmpY;
        }
        if (this._direction.e) {
            // e, ne, se
            this._currSize.width = this._origSize.width + tmpX;
        }
        else if (this._direction.w) {
            // w, nw, sw
            this._currSize.width = this._origSize.width - tmpX;
            this._currPos.x = this._origPos.x + tmpX;
        }
        this.checkBounds();
        this.checkSize();
        this.adjustByRatio();
        this.doResize();
    };
    AngularResizableDirective.prototype.doResize = function () {
        var container = this.el.nativeElement;
        if (!this._direction || this._direction.n || this._direction.s || this._aspectRatio) {
            this.renderer.setStyle(container, "height", this._currSize.height + "px");
        }
        if (!this._direction || this._direction.w || this._direction.e || this._aspectRatio) {
            this.renderer.setStyle(container, "width", this._currSize.width + "px");
        }
        this.renderer.setStyle(container, "left", this._currPos.x + "px");
        this.renderer.setStyle(container, "top", this._currPos.y + "px");
    };
    AngularResizableDirective.prototype.adjustByRatio = function () {
        if (this._aspectRatio && !this._adjusted) {
            if (this._direction.e || this._direction.w) {
                var newHeight = Math.floor(this._currSize.width / this._aspectRatio);
                if (this._direction.n) {
                    this._currPos.y += this._currSize.height - newHeight;
                }
                this._currSize.height = newHeight;
            }
            else {
                var newWidth = Math.floor(this._aspectRatio * this._currSize.height);
                if (this._direction.n) {
                    this._currPos.x += this._currSize.width - newWidth;
                }
                this._currSize.width = newWidth;
            }
        }
    };
    AngularResizableDirective.prototype.checkBounds = function () {
        if (this._containment) {
            var maxWidth = this._bounding.width - this._bounding.pr - this._bounding.deltaL - this._bounding.translateX - this._currPos.x;
            var maxHeight = this._bounding.height - this._bounding.pb - this._bounding.deltaT - this._bounding.translateY - this._currPos.y;
            if (this._direction.n && (this._currPos.y + this._bounding.translateY < 0)) {
                this._currPos.y = -this._bounding.translateY;
                this._currSize.height = this._origSize.height + this._origPos.y + this._bounding.translateY;
            }
            if (this._direction.w && (this._currPos.x + this._bounding.translateX) < 0) {
                this._currPos.x = -this._bounding.translateX;
                this._currSize.width = this._origSize.width + this._origPos.x + this._bounding.translateX;
            }
            if (this._currSize.width > maxWidth) {
                this._currSize.width = maxWidth;
            }
            if (this._currSize.height > maxHeight) {
                this._currSize.height = maxHeight;
            }
            /**
             * Fix Issue: Additional check for aspect ratio
             * https://github.com/xieziyu/angular2-draggable/issues/132
             */
            if (this._aspectRatio) {
                this._adjusted = false;
                if ((this._direction.w || this._direction.e) &&
                    (this._currSize.width / this._aspectRatio) >= maxHeight) {
                    var newWidth = Math.floor(maxHeight * this._aspectRatio);
                    if (this._direction.w) {
                        this._currPos.x += this._currSize.width - newWidth;
                    }
                    this._currSize.width = newWidth;
                    this._currSize.height = maxHeight;
                    this._adjusted = true;
                }
                if ((this._direction.n || this._direction.s) &&
                    (this._currSize.height * this._aspectRatio) >= maxWidth) {
                    var newHeight = Math.floor(maxWidth / this._aspectRatio);
                    if (this._direction.n) {
                        this._currPos.y += this._currSize.height - newHeight;
                    }
                    this._currSize.width = maxWidth;
                    this._currSize.height = newHeight;
                    this._adjusted = true;
                }
            }
        }
    };
    AngularResizableDirective.prototype.checkSize = function () {
        var minHeight = !this.rzMinHeight ? 1 : this.rzMinHeight;
        var minWidth = !this.rzMinWidth ? 1 : this.rzMinWidth;
        if (this._currSize.height < minHeight) {
            this._currSize.height = minHeight;
            if (this._direction.n) {
                this._currPos.y = this._origPos.y + (this._origSize.height - minHeight);
            }
        }
        if (this._currSize.width < minWidth) {
            this._currSize.width = minWidth;
            if (this._direction.w) {
                this._currPos.x = this._origPos.x + (this._origSize.width - minWidth);
            }
        }
        if (this.rzMaxHeight && this._currSize.height > this.rzMaxHeight) {
            this._currSize.height = this.rzMaxHeight;
            if (this._direction.n) {
                this._currPos.y = this._origPos.y + (this._origSize.height - this.rzMaxHeight);
            }
        }
        if (this.rzMaxWidth && this._currSize.width > this.rzMaxWidth) {
            this._currSize.width = this.rzMaxWidth;
            if (this._direction.w) {
                this._currPos.x = this._origPos.x + (this._origSize.width - this.rzMaxWidth);
            }
        }
    };
    AngularResizableDirective.prototype.getBounding = function () {
        var el = this._containment;
        var computed = window.getComputedStyle(el);
        if (computed) {
            var p = computed.getPropertyValue("position");
            var nativeEl = window.getComputedStyle(this.el.nativeElement);
            var transforms = nativeEl.getPropertyValue("transform").replace(/[^-\d,]/g, "").split(",");
            this._bounding = {};
            this._bounding.width = el.clientWidth;
            this._bounding.height = el.clientHeight;
            this._bounding.pr = parseInt(computed.getPropertyValue("padding-right"), 10);
            this._bounding.pb = parseInt(computed.getPropertyValue("padding-bottom"), 10);
            this._bounding.deltaL = this.el.nativeElement.offsetLeft - this._currPos.x;
            this._bounding.deltaT = this.el.nativeElement.offsetTop - this._currPos.y;
            if (transforms.length >= 6) {
                this._bounding.translateX = parseInt(transforms[4], 10);
                this._bounding.translateY = parseInt(transforms[5], 10);
            }
            else {
                this._bounding.translateX = 0;
                this._bounding.translateY = 0;
            }
            this._bounding.position = computed.getPropertyValue("position");
            if (p === "static") {
                this.renderer.setStyle(el, "position", "relative");
            }
        }
    };
    AngularResizableDirective.prototype.resetBounding = function () {
        if (this._bounding && this._bounding.position === "static") {
            this.renderer.setStyle(this._containment, "position", "relative");
        }
        this._bounding = null;
    };
    AngularResizableDirective.prototype.getGridSize = function () {
        // set default value:
        this._gridSize = { x: 1, y: 1 };
        if (this.rzGrid) {
            if (typeof this.rzGrid === "number") {
                this._gridSize = { x: this.rzGrid, y: this.rzGrid };
            }
            else if (Array.isArray(this.rzGrid)) {
                this._gridSize = { x: this.rzGrid[0], y: this.rzGrid[1] };
            }
        }
    };
    return AngularResizableDirective;
}());
export { AngularResizableDirective };
