import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { CartItemModel } from "../model/CartDataModel";
import { environment } from "../../../environments/environment";
import { ShipMethodModel, ShipMethodPreferenceModel } from "../model/ShipMethodModels";
import groupBy from "lodash-es/groupBy";

@Injectable()
export class ShipMethodPreferenceService extends IpsBaseWebService {

    constructor(http: HttpClient, public activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("ShipMethodPreference", "Shop", "v1");
    }

    public searchByRelation(searchInfo: SearchInfo, route: string): Promise<any> {
        return this.search(searchInfo, route);
    }

    public search(searchInfo: SearchInfo, route: string): Promise<any> {
        const searchText = searchInfo.searchText || "";
        const returnPromise = new Promise<SearchResponse<any>>((resolve, reject) => {
            const getRoute = `${this.urlApiBaseProductPlusVersion}ShipMethod`;
            this.getByRoute(getRoute).then((data: ShipMethodModel[]) => {
                data = data.filter((item) => !searchText || item.DisplayName.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1);
                data = data.map((item) => {
                    const model = new ShipMethodModel();
                    Object.assign(model, item);
                    return model;
                });

                data = data.sort(this.compareShipMethod);

                const returnVal = {
                    ResultList: data,
                    ResultChunkAttributes: {
                        "Index": 0,
                        "TotalRecords": data.length,
                        "RecordCount": data.length
                    }
                };

                resolve(returnVal);
            });
        });

        return returnPromise;
    }

    public save(preferences: ShipMethodPreferenceModel[]): Promise<any> {
        const saveRoute = `${this.urlApiBaseProductPlusVersion}ShipMethodPreferences`;
        return this.put(preferences, null, saveRoute);
    }

    public get(): Promise<any> {
        const getRoute = `${this.urlApiBaseProductPlusVersion}ShipMethodPreferences`;
        const promise = new Promise<ShipMethodPreferenceModel[]>((resolve, reject) => {
            this.getByRoute(getRoute).then((data: any[]) => {
                data = data.map((item) => {
                    const model = new ShipMethodPreferenceModel(null);
                    Object.assign(model, item);
                    return model;
                });

                data = this.applyDefaultSort(data);

                resolve(data);
            },
                (response: any) => {
                    reject(response.Message);
                });
        });

        return promise;
    }

    public getShipMethods(): Promise<any> {
        const getRoute = `${this.urlApiBaseProductPlusVersion}ShipMethod`;
        return this.getByRoute(getRoute);
    }

    private compareShipMethod(a: ShipMethodModel, b: ShipMethodModel): number {
        if (a.DisplayName < b.DisplayName) {
            return -1;
        } else if (a.DisplayName > b.DisplayName) {
            return 1;
        } else {
            return 0;
        }
    }

    public applyDefaultSort(preferences: ShipMethodPreferenceModel[]): ShipMethodPreferenceModel[] {
        return preferences.sort((a, b) => {
            return this.compareShipMethod(a.ShipMethod, b.ShipMethod);
        });
    }
}
