<div [formGroup]="parent">
    <div class="form-row" formArrayName="Markets">
        <div class="col-12 d-none d-lg-block">
            <div class="form-row">
                <div class="col-12 col-lg">
                    <div class="form-group col-12 mb-0 pl-0"><label class="required">{{label | translate}}</label></div>
                </div>
                <div class="form-group col-6 w-col-lg-18 w-col-xl-15 mb-0 text-center"><label class="justify-content-center mx-0 required text-nowrap" translate>LOCATION_HANDLING</label></div>
                <div class="form-group col-6 w-col-lg-18 w-col-xl-13 mb-0 text-center"><label class="justify-content-center mx-0" translate>LOCATION_BALANCE</label></div>
           </div>
        </div>
        <div class="col-12">
            <div *ngFor="let market of Markets.controls; let i=index; let first=first;">       
                <div class="form-row" [formGroupName]="i">
                    <div class="form-group mb-0 col-12 col-lg">
                        <div class="form-group col-12 px-0">
                            <label class="d-lg-none required" translate>MARKET</label>
                                <app-market-typeahead-search [market]="market"
                                     [marketIndex]="i"
                                     pendingLocationWarning="{{'MARKET_PENDING_LOCATIONS_WARNING' | translate}}"
                                     [showInUseFeatures]="true"
                                     [marketSource]="getMarketSource(i)"
                                     [featuresInAudience]="featuresInAudience"
                                     [featuresInQuestion]="featuresInQuestion"
                                     [fixtureGroupsInQuestion]="fixtureGroupsInQuestion"
                                     (marketChanged)="marketChangedEventHandler()"
                                     (marketDeleted)="marketDeletedEventHandler($event.index)"
                                     (removeMarkets)="removeMarkets($event.markets)"
                                     (addNewMarkets)="addNewMarkets($event.markets)"
                                     [disableByFeature]="false"></app-market-typeahead-search>

                            <ips-show-errors [control]="market.get('MarketName')" [errorMessages]="getErrorMessages('market')"></ips-show-errors>
                        </div>
                    </div>
                    <div class="form-group col-sm-6 w-col-lg-18 w-col-xl-15 text-center">
                        <label class="d-lg-none mx-0 required text-nowrap" translate>LOCATION_HANDLING</label>
                        <div class="btn-group h-100">
                            <button [disabled]="first" class="btn btn-lg btn-default ips-market-button" [ngClass]="{ 'active': market.value.OperationType === 'Union' }" (click)="locationHandlerChange(market, 'Union')" ngbTooltip="{{'AUDIENCE_LOCATIONS_ADD_TIP' | translate}}" placement="bottom">
                                <span class="fa fa-plus"></span>
                            </button>
                            <button [disabled]="first" class="btn btn-lg btn-default  ips-market-button" [ngClass]="{ 'active': market.value.OperationType === 'Except' }" (click)="locationHandlerChange(market, 'Except')" ngbTooltip="{{'AUDIENCE_LOCATIONS_SUBTRACT_TIP' | translate}}" placement="bottom" >
                                <span class="fa fa-minus"></span>
                            </button>
                            <button [disabled]="first" class="btn btn-lg btn-default  ips-market-button" [ngClass]="{ 'active': market.value.OperationType === 'Intersect' }" (click)="locationHandlerChange(market, 'Intersect')" ngbTooltip="{{'AUDIENCE_LOCATIONS_INTERSECT_TIP' | translate}}" placement="bottom">
                                <span class="fa custom-ampersand">&amp;</span>
                            </button>
                        </div>
                    </div>
                    <div class="form-group col-sm-6 w-col-lg-18 w-col-xl-15 text-center">
                        <label class="d-lg-none mx-0" translate>LOCATION_BALANCE</label>
                        <button type="button" class="btn btn-lg btn-default w-100 px-0 ips-market-button" [disabled]="!market.valid" (click)="showMarketLocationBalanceLocations(market.get('Ordinal').value)">{{market.get('LocationBalance').value | number}}</button>
                    </div>
                    <div class="btn-group-vertical mb-3">
                        <button class="btn btn-sm btn-default rounded pt-0" *ngIf="i !== 0" (click)="moveMarket(-1, i)"><i class="fa fa-caret-up"></i></button>
                        <button class="btn btn-sm btn-default rounded pt-0" *ngIf="i !== Markets.controls.length - 1" (click)="moveMarket(1, i)"><i class="fa fa-caret-down"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-row">
                <div class="form-group col-sm-12 w-col-lg-64 w-col-xl-70 mb-2">
                    <button type="button" class="btn btn-lg btn-block text-left ips-add-bar pr-1" (click)="refineMarket()" [ipsFocus]="focusableMarketInput" [disabled]="!Markets.valid">
                        <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
                        <span class="add-label">{{refineAudience_key | translate}}</span>
                        <span class="infoIcon msg-header-tooltip pull-right tooltip-container" ngbTooltip="{{refineAudienceInfo_key | translate}}" placement="bottom-right"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

