var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import * as uuid from "uuid";
var PunchOutService = /** @class */ (function (_super) {
    __extends(PunchOutService, _super);
    function PunchOutService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.init("Punchout", "Shop", "v1");
        return _this;
    }
    PunchOutService.prototype.setLocalStorageData = function (item) {
        //save data to local storage for creating a cart item after punchout
        var key = uuid();
        localStorage.setItem(key, JSON.stringify(item));
        return key;
    };
    PunchOutService.prototype.getCustomizeUrl = function (item) {
        var key = this.setLocalStorageData(item);
        var addToCartUrl = environment.redirectUrl + "shop/xmpieaddtocart?ItemKey=" + key;
        var canceltUrl = environment.redirectUrl + "shop/my-products";
        //tslint:disable-next-line: max-line-length
        var route = this.urlApiBasePlusController + "GetCustomizeUrl?uStoreId=" + item.XMPieStoreId + "&pODProductId=" + item.XMPieProductId + "&userName=" + this.activeProfileService.profile.UserName + "&BusinessIdentity=" + this.activeProfileService.profile.businessIdentity + "&logoutUrl=" + canceltUrl + "&cancelUrl=" + canceltUrl + "&addToCartUrl=" + addToCartUrl;
        return this.getByRoute(route);
    };
    PunchOutService.prototype.getFinalizeUrl = function (item, orderId, userName) {
        var key = this.setLocalStorageData(item);
        var addToCartUrl = encodeURIComponent(environment.redirectUrl + "shop/xmpieaddtocart?ItemKey=" + key + "&reorder=true&originalOrderId=" + orderId);
        var canceltUrl = environment.redirectUrl + "shop/myorders/view/" + orderId;
        //tslint:disable-next-line: max-line-length
        var route = this.urlApiBasePlusController + "getFinalizeUrl?OrderProductId=" + item.OrderProductId + "&userName=" + userName + "&BusinessIdentity=" + this.activeProfileService.profile.businessIdentity + "&logoutUrl=" + canceltUrl + "&cancelUrl=" + canceltUrl + "&addToCartUrl=" + addToCartUrl;
        return this.getByRoute(route);
    };
    PunchOutService.prototype.reorder = function (orderProductId, orderId) {
        var route = this.urlApiBasePlusController + "Reorder";
        var data = { OrderProudctId: orderProductId, OrderId: orderId };
        return this.postByRoute(route, data);
    };
    PunchOutService.prototype.getCustomizeUrlByKey = function (key) {
        var localStorageData = localStorage.getItem(key);
        if (localStorageData) {
            var item = JSON.parse(localStorageData);
            if (item) {
                return this.getCustomizeUrl(item);
            }
        }
        return new Promise(function (resolve, reject) {
            resolve(null);
        });
    };
    return PunchOutService;
}(IpsBaseWebService));
export { PunchOutService };
