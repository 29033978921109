<div class="col-12 d-block d-sm-none">
    <div id="noticeContainer" class="message-container mt-3">
        <div class="notice-label-set"><i class="fa fa-bell notice-icon" aria-hidden="true"></i>{{'NOTICE_ALLCAPS' | translate}}:</div>
        <div class="notice-text" translate>EDIT_CAMPAIGNS_FOR_MOBILE</div>
    </div>
</div>
<div class="col-12 d-none d-sm-block">
    <div class="d-flex justify-content-between align-items-center">
        <h3>{{breadCrumbLabel | translate}}</h3>
        <div class="custom-control custom-switch" *ngIf="loaded">
            <input type="checkbox" class="custom-control-input" id="campaignObsoleteSwitch" [disabled]="disableCreatePromotion" (change)="toggleObsoleteCampaign()" [checked]="IsObsolete" >
            <label class="custom-control-label" for="campaignObsoleteSwitch">Toggle Obsolete</label>
        </div>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_CAMPAIGN_INFO' | translate}}'}">
        <div *ngIf="loaded">
            <form id="ramiInfoInput" class="form-horizontal" name="campaignForm" [formGroup]="campaignForm" novalidate ipsUnsavedWarningForm>
                <div class="card mb-4">
                    <div class="card-body pb-0">
                        <div class="form-row">
                            <div class="form-group col-12 col-md-6 col-lg-4">
                                <label class="required" for="Name" translate>NAME</label>
                                <input type="text" name="Name" id="Name" formControlName="Name" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_CAMPAIGN_NAME' | translate}}" focus="true">
                                <ips-show-errors [control]="Name" [errorMessages]="getErrorMessages('Name')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                            <div class="form-group col-12 col-md-6 col-lg-4">
                                <label for="AbbreviatedName" translate>ABBREVIATED_NAME</label>
                                <input type="text" name="AbbreviatedName" id="AbbreviatedName" formControlName="AbbreviatedName" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_ABBREVIATED_NAME' | translate}}" focus="true" (keydown.space)="$event.preventDefault()">
                                <ips-show-errors [control]="AbbreviatedName" [errorMessages]="getErrorMessages('AbbreviatedName')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12 col-md-6 col-lg-4">
                                <label class="required" for="InStoreDate" translate>{{InStoreDateText}}</label>
                                <div class="input-group ips-input-group-icon">
                                    <input type="text" name="InStoreDate" id="InStoreDate" class="form-control form-control-lg input-full"
                                           formControlName="InStoreDate"
                                           placeholder="{{ 'ENTER_INSTORE_DATE' | translate }}"
                                           #d="bsDatepicker" bsDatepicker [bsConfig]="inStoreDateOptions" (bsValueChange)="changeInStoreDate($event)" triggers="" />
                                    <span class="input-group-append">
                                        <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                    </span>
                                </div>
                                <ips-show-errors [control]="InStoreDate" [errorMessages]="getErrorMessages('InStoreDate')" cssClass="validation-message-Qty"></ips-show-errors>
                                <div class="ips-text-warning" [hidden]="!showInStoreDateWarning">
                                    <i class="fa fa-exclamation-triangle"></i>
                                    <span>{{PromotionsStartAfterInstore}}</span>
                                </div>
                            </div>
                            <div class="form-group col-12 col-md-6 col-lg-4">
                                <label class="required" for="StartDate" translate>{{StartDateText}}</label>
                                <div class="input-group ips-input-group-icon">
                                    <input type="text" name="StartDate" id="StartDate" class="form-control form-control-lg input-full"
                                           formControlName="StartDate"
                                           placeholder="{{ 'ENTER_START_DATE' | translate }}"
                                           #d2="bsDatepicker" bsDatepicker [bsConfig]="startDateOptions" (bsValueChange)="changeStartDate($event)" (blur)="blurStartDate($event)" triggers="" />
                                    <span class="input-group-append">
                                        <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d2.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                    </span>
                                </div>
                                <ips-show-errors [control]="StartDate" [errorMessages]="getErrorMessages('StartDate')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                            <div class="form-group col-12 col-md-6 col-lg-4">
                                <label class="required" for="EndDate" translate>{{EndDateText}}</label>
                                <div class="input-group ips-input-group-icon">
                                    <input type="text" name="EndDate" id="EndDate" class="form-control form-control-lg input-full"
                                           formControlName="EndDate"
                                           placeholder="{{ 'ENTER_END_DATE' | translate }}"
                                           #d3="bsDatepicker" bsDatepicker [bsConfig]="endDateOptions" (blur)="blurEndDate($event)" triggers="" />
                                    <span class="input-group-append">
                                        <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d3.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                    </span>
                                </div>
                                <ips-show-errors [control]="EndDate" [errorMessages]="getErrorMessages('EndDate')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12 col-md-6 col-lg-4">
                                <label class="required" for="FulfillmentQuantity" translate>FULFILLMENT_DEFAULT_PERCENT</label>
                                <div class="input-group ips-input-group-icon">
                                    <input type="text" name="FulfillmentQuantity" id="FulfillmentQuantity" formControlName="FulfillmentQuantity" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_FULFILLMENT_DEFAULT' | translate}}">
                                    <div class="input-group-append">
                                        <span class="input-group-text fa fa-percent"></span>
                                    </div>
                                </div>
                                <ips-show-errors [control]="FulfillmentQuantity" [errorMessages]="getErrorMessages('FulfillmentQuantity')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                            <div class="form-group col-12 col-md-6 col-lg-4">
                                <label class="required" for="ElementQuantity" translate>ELEMENT_DEFAULT_QTY</label>
                                <div class="input">
                                    <input type="number" name="ElementQuantity" id="ElementQuantity" formControlName="ElementQuantity" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_ELEMENT_QTY_DEFAULT' | translate}}">
                                </div>
                                <ips-show-errors [control]="ElementQuantity" [errorMessages]="getErrorMessages('ElementQuantity')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                        </div>
                        <div *ngIf="hasCampaignAddresses" class="form-row mt-1">
                            <div class="form-group col-12">
                                <label translate>SHIPPING_ADDRESS_CONTROL</label>
                                <div class="input-group option-control ips-market-button">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <input type="checkbox" formControlName="UseCampaignAddress">
                                        </div>
                                    </div>
                                    <input type="text" class="form-control form-control-lg" readonly (click)="changeUseCampaignAddress()" value="{{'OVERRIDE_CAMPAIGN_SHIPPING_ADDRESS' | translate}}">
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="Notes" translate>NOTES</label>
                            <textarea class="form-control form-control-lg input-full" name="Notes" id="Notes" cols="30" rows="3" formControlName="Notes" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                            <ips-show-errors [control]="Notes" [errorMessages]="getErrorMessages('Notes')" cssClass="validation-message-Qty"></ips-show-errors>
                        </div>
                        <div class="form-group mt-3">
                            <button class="btn btn-lg btn-block add-promotion text-left pr-0" [disabled]="disableCreatePromotion" (click)="addPromotion()">
                                <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i><span class="add-holder-label" translate>CREATE_PROMOTION</span>
                                <div class="pull-right color-gray" ngbTooltip="{{'ADD_PROMOTION' | translate}}" placement="bottom-right">
                                    <i class="fa fa-info-circle mr-3"></i>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveCampaign"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveCampaignPrompt(false)" translate>
                                SAVE
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveReturnCampaign"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveCampaignPrompt(true)" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="cancelCampaign" class="btn btn-default btn-lg btn-block" uiSref="main.campaign.search" type="submit" translate>CANCEL</button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="deleteCampaign"
                                    class="btn btn-lg btn-block alert-danger"
                                    (click)="deleteCampaignPrompt()"
                                    ips-unsaved-warning-clear
                                    [disabled]="!campaignForm.value.Id"
                                    [ngClass]="{'btn-default': !campaignForm.value.Id}" translate>
                                DELETE_CAMPAIGN
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_CAMPAIGN_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
