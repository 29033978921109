import { OnInit, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { SearchModalComponent } from "../../../shared/index";
import { FileDownloadService, ActiveProfileService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { OrderService, CartService, CartItemPostData, PunchOutService, FulfillmentOrderViewModel, ShipmentModel, OrderItemDetailsModel, OrderAddressModel, ShipmentStatusModel, UStoreProductionStatus, KeyValuePair } from "../../index";
import { StatusModalComponent } from "../status-modal/status-modal.component";
import { SortHelperService } from "../../../shared/service/sort-helper.service";
import { ShopSettingsService } from "../../service/shop-settings.service";
var OrderViewComponent = /** @class */ (function () {
    function OrderViewComponent(translateService, $state, transition, ipsMessage, orderService, punchOutService, activeProfileService, authService, cartService, shopSettingsService, fileDownload, ipsModal) {
        this.translateService = translateService;
        this.$state = $state;
        this.transition = transition;
        this.ipsMessage = ipsMessage;
        this.orderService = orderService;
        this.punchOutService = punchOutService;
        this.activeProfileService = activeProfileService;
        this.authService = authService;
        this.cartService = cartService;
        this.shopSettingsService = shopSettingsService;
        this.fileDownload = fileDownload;
        this.ipsModal = ipsModal;
        this.showBanner = false;
        this.busy = false;
        this.displayList = [];
        this.orderItemList = [];
        this.digitalItemList = [];
        this.printedItemList = [];
        this.filteredOrderItemList = [];
        this.chunkSize = 20;
        this.statusDictionary = {};
        this.screenLoaded = false;
        this.textNA = "NA";
        this.pluralMapping = {
            "=1": "PRODUCT",
            "other": "PRODUCTS"
        };
        this.emptyGuid = "00000000-0000-0000-0000-000000000000";
        this.showDeliverySelection = false;
        this.printSortHelper = new SortHelperService();
        this.digitalSortHelper = new SortHelperService();
    }
    OrderViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orderId = Number(this.transition.params().id);
        this.showBanner = Boolean(this.transition.params().showBanner);
        this.myOrder = {
            Id: Number(this.orderId)
        };
        this.translateService.get("ALL").subscribe(function () { return _this.TranslateText(); });
        //tslint:disable-next-line: max-line-length
        this.displayList = [{ Name: "CustomerItemCode", Label: "CUSTOMER_ITEM_CODE", CanHighlight: true }, { Name: "Quantity", Label: "QTY", CanHighlight: false }, { Name: "LocationLabel", Label: "LOCATION", CanHighlight: false }, { Name: "ShipmentAddress", Label: "SHIPPING_ADDRESS", CanHighlight: false },
            { Name: "ShipmentStatus", Label: "SHIPMENT_STATUS", CanHighlight: false }, { Name: "ShipmentTrackingNumber", Label: "SHIPMENT_TRACKING_NUMBER", CanHighlight: false }];
        var promises = [this.loadOrderSummary(), this.loadOrderItemDetails(), this.getShowDeliverySelectionSetting()];
        Promise.all(promises).then(function () {
            _this.screenLoaded = true;
        });
    };
    Object.defineProperty(OrderViewComponent.prototype, "sortedPrintRecords", {
        get: function () {
            var returnValue = this.printSortHelper.applySort(this.printedItemList);
            return returnValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderViewComponent.prototype, "sortedDigitalRecords", {
        get: function () {
            var returnValue = this.digitalSortHelper.applySort(this.digitalItemList);
            return returnValue;
        },
        enumerable: true,
        configurable: true
    });
    OrderViewComponent.prototype.loadOrderSummary = function () {
        var _this = this;
        return this.orderService.get(this.orderId).then(function (order) {
            Object.assign(_this.myOrder, order);
            _this.myOrder.StatusLabel = _this.statusDictionary[_this.myOrder.Status].text;
            _this.myOrder.EstimatedHandling = _this.myOrder.EstimatedHandling || 0;
            _this.myOrder.EstimatedTotal = _this.myOrder.EstimatedSubtotal + _this.myOrder.EstimatedShippingCost + _this.myOrder.EstimatedTax + _this.myOrder.EstimatedHandling;
            _this.orderLoaded = true;
        }).catch(function (err) {
            _this.orderLoadError = true;
        });
    };
    OrderViewComponent.prototype.TranslateText = function () {
        this.pluralMapping["=1"] = this.translateService.instant("PRODUCT");
        this.pluralMapping["other"] = this.translateService.instant("PRODUCTS");
        this.selectedLocation = { Id: 0, LocationIdentifier: "", Name: "", Label: this.translateService.instant("ALL_UPPERCASE") };
        this.selectedShipmentStatus = { value: "ALL", text: this.translateService.instant("ALL_UPPERCASE") };
        this.statusDictionary["ALL"] = { value: "ALL", text: this.translateService.instant("ALL_UPPERCASE") };
        this.statusDictionary["Unknown"] = { value: "Unknown", text: this.translateService.instant("UNKNOWN") };
        this.statusDictionary["Canceled"] = { value: "Canceled", text: this.translateService.instant("CANCELED") };
        this.statusDictionary["Completed"] = { value: "Completed", text: this.translateService.instant("COMPLETED") };
        this.statusDictionary["Processing"] = { value: "Processing", text: this.translateService.instant("PROCESSING") };
        this.statusDictionary["PartialShip"] = { value: "PartialShip", text: this.translateService.instant("PARTIAL_SHIP") };
        this.statusDictionary["Shipped"] = { value: "Shipped", text: this.translateService.instant("SHIPPED") };
        this.statusDictionary["Delivered"] = { value: "Delivered", text: this.translateService.instant("DELIVERED") };
        this.statusDictionary["Complete"] = { value: "Complete", text: this.translateService.instant("COMPLETE") };
        this.statusDictionary["Backordered"] = { value: "Backordered", text: this.translateService.instant("BACKORDERED") };
        this.statusDictionary["DeliveryException"] = { value: "DeliveryException", text: this.translateService.instant("DELIVERY_EXCEPTION") };
        this.statusDictionary["InTransit"] = { value: "InTransit", text: this.translateService.instant("IN_TRANSIT") };
        this.statusDictionary["Printed"] = { value: "Printed", text: this.translateService.instant("PRINTED") };
        this.textNA = this.translateService.instant("NA");
    };
    OrderViewComponent.prototype.loadOrderItemDetails = function () {
        var _this = this;
        return this.promise = this.orderService.getOrderItemDetails(this.orderId).then(function (response) {
            Object.assign(_this.orderItemList, response);
            _this.orderItemList.map(function (orderItem) {
                orderItem.Cards = [];
                if (_this.displayList) {
                    _this.displayList.forEach(function (display) {
                        var val = _this.findValueInOrderItem(display.Name, orderItem);
                        // if Order Reason was populated as part of the order, then show it
                        // otherwise don't show it
                        if (display.Name !== "OrderReason" || val) {
                            orderItem.Cards.push({
                                Id: orderItem.Id,
                                Key: display.Label,
                                Value: val,
                                Type: "Order",
                                CanHighlight: display.CanHighlight
                            });
                        }
                    });
                }
                //Setup image URLS
                orderItem.FullImage = orderItem.Media.ResourceUri + "?BusinessIdentity=" + _this.activeProfileService.businessIdentity + "&idToken=" + _this.authService.getIdToken();
                orderItem.ThumbImage = orderItem.FullImage + "&height=300&width=300";
                // calculate total line item cost
                orderItem.TotalCost = orderItem.UnitPrice * orderItem.Quantity;
                orderItem.ShipmentAddress = _this.formatAddress(orderItem.ShipToAddress);
                if (!orderItem.DigitalDownload) {
                    var trackingLinks = _this.findValueInOrderItem("ShipmentTrackingNumber", orderItem);
                    orderItem.TrackingLinks = trackingLinks;
                }
                return orderItem;
            });
            _this.printedItemList = _this.orderItemList.filter(function (item) { return !item.DigitalDownload || item.ProductType === "DropShipment"; });
            _this.digitalItemList = _this.orderItemList.filter(function (item) { return item.DigitalDownload && item.ProductType !== "DropShipment"; });
            _this.initializeVariablePodPolling(response);
            _this.productsLoaded = true;
        });
    };
    OrderViewComponent.prototype.getShowDeliverySelectionSetting = function () {
        var _this = this;
        return this.shopSettingsService.getShowDeliverySelectionSetting().then(function (response) {
            if (response) {
                _this.showDeliverySelection = response;
            }
        });
    };
    Object.defineProperty(OrderViewComponent.prototype, "incompleteVariablePodItems", {
        get: function () {
            var _this = this;
            var listToFilter = this.orderItemList || [];
            return listToFilter.filter(function (item) {
                return item.Variable && _this.hasInProgressStatus(item);
            }).map(function (item) {
                return item.Id;
            });
        },
        enumerable: true,
        configurable: true
    });
    OrderViewComponent.prototype.initializeVariablePodPolling = function (items) {
        var _this = this;
        var pollingInterval = 20000;
        if (this.incompleteVariablePodItems.length) {
            if (!this.timer) {
                this.timer = setInterval(function () {
                    _this.pollVariablePod();
                }, pollingInterval);
            }
        }
    };
    OrderViewComponent.prototype.pollVariablePod = function () {
        var _this = this;
        return;
        this.loadOrderItemDetails().then(function () {
            if (_this.incompleteVariablePodItems.length === 0) {
                clearInterval(_this.timer);
                _this.timer = null;
            }
        });
    };
    OrderViewComponent.prototype.hasInProgressStatus = function (orderItem) {
        var inProgressStatuses = [
            UStoreProductionStatus.Unknown,
            UStoreProductionStatus.Waiting,
            UStoreProductionStatus.InProgress
        ];
        return inProgressStatuses.includes(orderItem.PodVariableStatus);
    };
    OrderViewComponent.prototype.hasFailedStatus = function (orderItem) {
        var failedStatus = [
            UStoreProductionStatus.Aborted,
            UStoreProductionStatus.Aborting,
            UStoreProductionStatus.Failed,
            UStoreProductionStatus.Suspended
        ];
        return failedStatus.includes(orderItem.PodVariableStatus);
    };
    OrderViewComponent.prototype.getPodStatus = function (orderItem) {
        switch (true) {
            case this.hasInProgressStatus(orderItem):
                return "In Progress";
            case this.hasCompletedStatus(orderItem):
                return "Complete";
            case this.hasFailedStatus(orderItem):
                return "Failed";
            case this.downloadHasExpired(orderItem):
                return "Expired";
        }
    };
    OrderViewComponent.prototype.hasCompletedStatus = function (orderItem) {
        var completedStatus = [
            UStoreProductionStatus.Completed
        ];
        return completedStatus.includes(orderItem.PodVariableStatus);
    };
    OrderViewComponent.prototype.generateTrackingLink = function (trackingNumber, trackingUrl) {
        var link = "<a href=\"" + trackingUrl + "\" target=\"_blank\" class=\"text-primary\">" + trackingNumber + "</a>";
        var span = "<span>" + trackingNumber + "</span>";
        if (trackingUrl) {
            return link;
        }
        else {
            return span;
        }
    };
    OrderViewComponent.prototype.getShipmentStatus = function (orderItem) {
        var status = orderItem.Shipments.length > 0 ? this.statusDictionary[orderItem.Shipments[0].Status].text : this.statusDictionary["Processing"].text;
        status = status === "Complete" ? "Shipped" : status;
        return status;
    };
    OrderViewComponent.prototype.findValueInOrderItem = function (key, orderItem) {
        var _this = this;
        var orderItemKeys = Object.keys(orderItem);
        var found = orderItemKeys.some(function (q) { return q === key; });
        if (found) {
            return orderItem[key];
        }
        if (orderItem.DigitalDownload) {
            if (key === "ShipmentAddress" || key === "ShipmentTrackingNumber" || key === "ShipmentStatus") {
                return this.textNA;
            }
        }
        if (orderItem.Shipments !== undefined && orderItem.Shipments !== null) {
            if (key === "ShipmentStatus") {
                return orderItem.Shipments.length > 0 ? this.statusDictionary[orderItem.Shipments[0].Status].text : this.statusDictionary["Processing"].text;
            }
            if (key === "ShipmentTrackingNumber") {
                var trackingInfo = this.orderService.getTrackingData(orderItem);
                return trackingInfo.map(function (trackingItem) {
                    return _this.generateTrackingLink(trackingItem.Key, trackingItem.Value);
                });
            }
        }
        if (key === "ShipmentAddress" && orderItem.ShipToAddress !== undefined && orderItem.ShipToAddress !== null) {
            return orderItem.ShipToAddress.Line1 + " " + orderItem.ShipToAddress.City + ", " + orderItem.ShipToAddress.StateProvince + " " + orderItem.ShipToAddress.PostalCode;
        }
        return "";
    };
    OrderViewComponent.prototype.selectLocation = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "locationbyorder", orderId: this.orderId } })
            .then(function (response) {
            _this.selectedLocation = response.item;
            // this.getListData();
        });
    };
    OrderViewComponent.prototype.selectShipmentStatus = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(StatusModalComponent, { resolve: { type: "shipment" } })
            .then(function (response) {
            _this.selectedShipmentStatus = response;
            // this.getListData();
        }, function () {
            // Canceled, do nothing
        });
    };
    OrderViewComponent.prototype.downloadHasExpired = function (item) {
        var today = new Date();
        var todayAtMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        var expirationDate = new Date(item.PodDownloadUrlExpiration);
        if (expirationDate) {
            return expirationDate < todayAtMidnight;
        }
        else {
            return false;
        }
    };
    OrderViewComponent.prototype.downloadClicked = function (item, isStatic) {
        var _this = this;
        if (isStatic === void 0) { isStatic = false; }
        if (isStatic) {
            this.orderService.getStaticItemDownloadUrl(item.CustomerItemCode, item.ItemId, item.LocationId, item.Id).then(function (response) {
                _this.fileDownload.Download(response.Url, response.FileName);
            });
        }
        else {
            this.orderService.getItemDownloadUrl(item.ItemId, item.LocationId, item.Id, item.OrderReason).then(function (response) {
                window.open(response.Url, "_blank");
                // TODO:  uncomment the below, and delete the above line, once the Veracore CORS issues are resolved
                // this.fileDownload.Download(response);
            });
        }
    };
    OrderViewComponent.prototype.ngOnDestroy = function () {
        if (this.timer) {
            clearInterval(this.timer);
        }
    };
    OrderViewComponent.prototype.print = function () {
        event.preventDefault();
        window.print();
    };
    OrderViewComponent.prototype.formatAddressForDisplay = function (address) {
        if (address) {
            return address.Line1 + "<br/>" + address.City + ", " + address.StateProvince + " " + address.PostalCode;
        }
        else {
            return null;
        }
    };
    OrderViewComponent.prototype.formatAddress = function (address) {
        if (address) {
            return address.Line1 + " " + address.City + ", " + address.StateProvince + " " + address.PostalCode;
        }
        else {
            return null;
        }
    };
    OrderViewComponent.prototype.locationLabelSegment = function (locationLabel, index) {
        try {
            var token = " - ";
            var segments = locationLabel.split(token);
            return segments[index].replace(token, "");
        }
        catch (_a) {
            return "";
        }
    };
    Object.defineProperty(OrderViewComponent.prototype, "TotalQuantity", {
        get: function () {
            var totalQuantity = 0;
            this.orderItemList.map(function (item) { return item.Quantity; }).forEach(function (item) {
                totalQuantity += Number(item);
            });
            return totalQuantity;
        },
        enumerable: true,
        configurable: true
    });
    OrderViewComponent.prototype.reorder = function (item) {
        var _this = this;
        //save data to local storage for creating a cart item after punchout
        var cartItem = {
            Id: 0,
            LocationId: item.LocationId ? item.LocationId : -1,
            CartId: this.cartService.myCurrentCart.Id,
            ItemId: item.ItemId,
            DigitalDownload: item.DigitalDownload,
            Qty: item.Quantity,
            OrderReason: item.OrderReason,
            Variable: item.Variable,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            OrderProductId: item.PodOrderProductId
        };
        //POD item
        if (item.PodOrderProductId) {
            this.punchOutService.reorder(item.PodOrderProductId, item.PodOrderId).then(function (response) {
                cartItem.OrderProductId = response.OrderProductId;
                var userName = response.UserName;
                _this.punchOutService.getFinalizeUrl(cartItem, _this.orderId, userName).then(function (url) {
                    window.location.href = url;
                });
            });
        }
        else { // non-POD
            var duplicate = this.cartService.findDuplicateCartItem(cartItem);
            if (duplicate === null) {
                this.cartService.addToCart(cartItem).then(function () {
                    item.NumberInCart = cartItem.Qty;
                });
            }
            else {
                duplicate.Qty = duplicate.Qty + cartItem.Qty;
                this.cartService.updateItemInMyCart(duplicate);
                var totalQuantity = this.cartService.numberOfItemInLocationCart(cartItem.LocationId, cartItem.ItemId);
                item.NumberInCart = totalQuantity;
            }
        }
    };
    return OrderViewComponent;
}());
export { OrderViewComponent };
