import { OnInit, QueryList } from "@angular/core";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common"; // TODO: in angular 6 we can just import formatDate function from angular/common
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PageTitleService, IpsImageEditComponent } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { LocationGroupService } from "../../../imagine-ui-ng-store-profile";
import { CampaignService } from "../../../imagine-ui-ng-campaign";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";
import { AuthService } from "imagine-ui-ng-security";
import { SurveyService } from "../../../imagine-ui-ng-survey";
import { IpsModalService } from "imagine-ui-ng-modal";
import { LocationGroupDeleteModalComponent } from "../location-group-delete-modal/location-group-delete-modal.component";
import { PaymentSettingsService } from "../../../imagine-ui-ng-shop";
var LocationGroupEditComponent = /** @class */ (function () {
    function LocationGroupEditComponent(locationGroupService, ipsMessage, $state, transition, translateService, pageTitleService, activeProfileService, campaignService, datePipe, authService, surveyService, ipsModal, paymentSettingsService) {
        var _this = this;
        this.locationGroupService = locationGroupService;
        this.ipsMessage = ipsMessage;
        this.$state = $state;
        this.transition = transition;
        this.translateService = translateService;
        this.pageTitleService = pageTitleService;
        this.activeProfileService = activeProfileService;
        this.campaignService = campaignService;
        this.datePipe = datePipe;
        this.authService = authService;
        this.surveyService = surveyService;
        this.ipsModal = ipsModal;
        this.paymentSettingsService = paymentSettingsService;
        this.indexer = 0;
        this.editingExisting = false;
        this.disableRuleSelection = false;
        this.HasNA = false;
        this.featureImageFiles = [];
        this.emptyGuid = "00000000-0000-0000-0000-000000000000";
        this.idNumber = 0;
        this.OptionTypes = [
            { TypeName: "Text", DisplayName: "OPTION_TYPE_TEXT", IconClass: "option-type-text-icon" },
            { TypeName: "Bool", DisplayName: "OPTION_TYPE_BOOL", IconClass: "option-type-bool-icon" },
            { TypeName: "Number", DisplayName: "OPTION_TYPE_NUMBER", IconClass: "option-type-number-icon" },
            { TypeName: "Date", DisplayName: "OPTION_TYPE_DATE", IconClass: "option-type-date-icon" }
        ];
        this.errorMessages = {
            "required": function () { return _this.requiredMessage; },
            "maxlength": function (params) { return IpsString.Format(_this.maxLengthMessage, params.requiredLength); },
            "dupeSubGroupNameMessage": function () { return _this.dupeSubGroupNameMessage; },
            "bsDate": function () { return _this.invalidDateErrorMessage; },
            "reservedName": function () { return _this.reservedNameMessage; }
        };
    }
    LocationGroupEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var paramId = this.transition.params().id === "0" ? "" : this.transition.params().id;
        this.focusInput = paramId.toString() === "" ? "nameInput" : "addSubGroupInput";
        var pageTitle = paramId.toString() === "" ? "CREATE_LOCATION_GROUP" : "EDIT_LOCATION_GROUP";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);
        this.editingExisting = paramId > 0;
        this.createForm();
        //If we got an ID to load, load it.
        if (paramId > 0) {
            //Initial call to populate screen on load
            this.getLocationGroup(paramId);
        }
        else if (paramId === "") {
            this.loaded = true;
        }
        var localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();
        this.dateOptions = {
            containerClass: "theme-default",
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
    };
    Object.defineProperty(LocationGroupEditComponent.prototype, "SubGroups", {
        get: function () {
            return this.locationGroupForm.get("SubGroups");
        },
        enumerable: true,
        configurable: true
    });
    LocationGroupEditComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(["DUPLICATE_SUBGROUP_NAME", "PLEASE_ENTER_HEIGHT", "FIELD_REQUIRED", "MAX_LENGTH_ERROR", "PLEASE_ENTER_OPTION", "PROMPT_DELETE_BODY",
            "CALENDAR_DATE_FORMAT", "RESERVED_SUBGROUP_NAME"]).subscribe(function (res) {
            _this.dupeSubGroupNameMessage = res["DUPLICATE_SUBGROUP_NAME"];
            _this.enterHeightMessage = res["PLEASE_ENTER_HEIGHT"];
            _this.requiredMessage = res["FIELD_REQUIRED"];
            _this.maxLengthMessage = res["MAX_LENGTH_ERROR"];
            _this.requiredNameMessage = res["PLEASE_ENTER_OPTION"];
            _this.promptDeleteBodyMessage = res["PROMPT_DELETE_BODY"];
            _this.invalidDateErrorMessage = IpsString.Format(res["CALENDAR_DATE_FORMAT"], _this.dateFormat);
            _this.reservedNameMessage = res["RESERVED_SUBGROUP_NAME"];
        });
    };
    LocationGroupEditComponent.prototype.getNameErrorMessages = function () {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = function () { return _this.requiredNameMessage; };
        return msgs;
    };
    LocationGroupEditComponent.prototype.getOptionTypeErrorMessages = function () {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = function () { return _this.requiredMessage; };
        return msgs;
    };
    LocationGroupEditComponent.prototype.createForm = function () {
        var _this = this;
        this.Id = new FormControl("");
        this.BusinessIdentity = new FormControl("");
        this.Name = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.Notes = new FormControl("", Validators.maxLength(500));
        this.Rule = new FormControl("", Validators.required);
        this.OptionType = new FormControl({ value: "", disabled: this.editingExisting }, Validators.required);
        this.OptionType.valueChanges.subscribe(function (value) {
            _this.optionTypeChanged(value);
        });
        this.MasterMediaId = new FormControl(this.emptyGuid);
        this.MediaType = new FormControl("Unknown");
        this.FullSizeImage = new FormControl("");
        this.SelectedImage = new FormControl(false);
        this.IsObsolete = new FormControl(false);
        this.IsCampaignLocationFeature = new FormControl(false);
        this.locationGroupForm = new FormGroup({
            Id: this.Id,
            BusinessIdentity: this.BusinessIdentity,
            Name: this.Name,
            Notes: this.Notes,
            Rule: this.Rule,
            OptionType: this.OptionType,
            SubGroups: new FormArray([this.getNewSubGroupCtrl(this.createEmptySubGroup(), this.currentOptionType)]),
            MasterMediaId: this.MasterMediaId,
            MediaType: this.MediaType,
            FullSizeImage: this.FullSizeImage,
            SelectedImage: this.SelectedImage,
            IsObsolete: this.IsObsolete,
            IsCampaignLocationFeature: this.IsCampaignLocationFeature
        });
    };
    LocationGroupEditComponent.prototype.createEmptySubGroup = function (isNa) {
        if (isNa === void 0) { isNa = false; }
        return {
            Id: 0,
            Name: isNa ? "Not Applicable" : "",
            BusinessIdentity: this.activeProfileService.businessIdentity,
            InputName: "subGroupHtmlName" + this.indexer++,
            LocationCount: 0,
            IsNA: isNa
        };
    };
    LocationGroupEditComponent.prototype.optionTypeChanged = function (optionTypeValue) {
        var _this = this;
        if (optionTypeValue === this.currentOptionType) {
            return;
        }
        // Check for changes
        var hasContent = false;
        if (this.currentOptionType !== "Bool") {
            hasContent = this.SubGroups.controls.some(function (subGroup) {
                var value = subGroup.get("Name").value;
                if (!!value) {
                    return true;
                }
                return false;
            });
        }
        if (hasContent) {
            // prompt the user if they actually want to make a change
            this.ipsMessage.confirm({
                title: "WARNING",
                body: "OPTION_TYPE_CHANGE_WARNING",
                ok: "YES_RESET_MY_INFO",
                cancel: "NO_KEEP_MY_INFO"
            }).then(function () {
                _this.setOptionsForNewOptionType(optionTypeValue);
            })
                .catch(function () {
                // User chose to keep changes and not select a different option type, reset the radio button back to what it was.
                _this.OptionType.patchValue(_this.currentOptionType);
            });
        }
        else {
            // There aren't any changes, just make the change
            this.setOptionsForNewOptionType(optionTypeValue);
        }
    };
    LocationGroupEditComponent.prototype.toggleObsolete = function (locationGroupControl) {
        if (event.srcElement.tagName === "INPUT") {
            return;
        }
        var isObsoleteControl = locationGroupControl.get("IsObsolete");
        isObsoleteControl.setValue(!isObsoleteControl.value);
        isObsoleteControl.markAsDirty();
    };
    LocationGroupEditComponent.prototype.toggleCampaignLocationFeature = function (locationGroupControl) {
        if (event.srcElement.tagName === "INPUT") {
            return;
        }
        var isCampaignLocationFeatureControl = locationGroupControl.get("IsCampaignLocationFeature");
        isCampaignLocationFeatureControl.setValue(!isCampaignLocationFeatureControl.value);
        isCampaignLocationFeatureControl.markAsDirty();
    };
    LocationGroupEditComponent.prototype.setOptionsForNewOptionType = function (optionTypeValue) {
        this.currentOptionType = optionTypeValue;
        this.HasNA = false;
        while (this.SubGroups.length !== 0) {
            this.SubGroups.removeAt(0);
        }
        //User chose to proceed with the change
        if (optionTypeValue === "Bool") {
            var yes = this.createEmptySubGroup();
            yes.Name = "Yes";
            var yesCtrl = this.getNewSubGroupCtrl(yes, this.currentOptionType);
            this.SubGroups.push(yesCtrl);
            var no = this.createEmptySubGroup();
            no.Name = "No";
            var noCtrl = this.getNewSubGroupCtrl(no, this.currentOptionType);
            this.SubGroups.push(noCtrl);
            //set default assignment rule to one to one for optionType = yes/no
            //disable assignment rule selection
            this.Rule.patchValue("Single");
            this.Rule.disable();
            this.disableRuleSelection = true;
        }
        else {
            this.SubGroups.push(this.getNewSubGroupCtrl(this.createEmptySubGroup(), this.currentOptionType));
            //enable assignement rule selection for none yes/no type
            this.disableRuleSelection = false;
            this.Rule.enable();
        }
    };
    LocationGroupEditComponent.prototype.getLocationGroup = function (id) {
        var _this = this;
        this.loaded = false;
        this.promise = this.locationGroupService.get(id).then(function (response) {
            //sort subGroups
            _this.locationGroupService.sortSubGroup(response.SubGroups, response.OptionType);
            _this.currentOptionType = response.OptionType;
            var fullSizeImage = (response.Media && response.MasterMediaId !== _this.emptyGuid) ?
                response.Media.ResourceUri + "?BusinessIdentity=" + _this.activeProfileService.businessIdentity + "&idToken=" + _this.authService.getIdToken() : "";
            _this.locationGroupForm.patchValue({
                Id: response.Id,
                BusinessIdentity: response.BusinessIdentity,
                Name: response.Name,
                Notes: response.Notes,
                Rule: response.Rule,
                OptionType: response.OptionType,
                MasterMediaId: response.MasterMediaId || _this.emptyGuid,
                MediaType: response.MediaType || "Unknown",
                RemoveImage: "",
                FullSizeImage: fullSizeImage,
                SelectedImage: fullSizeImage ? fullSizeImage.indexOf("/PlaceHolder/") === -1 : false,
                IsObsolete: response.IsObsolete,
                IsCampaignLocationFeature: response.IsCampaignLocationFeature
            });
            _this.HasNA = response.SubGroups.some(function (s) { return !!s.IsNA; });
            var subGroupFGs = response.SubGroups.map(function (subGroup) { return _this.getNewSubGroupCtrl(subGroup, subGroup.IsNA ? "Text" : response.OptionType); });
            var subGroups = new FormArray(subGroupFGs);
            _this.locationGroupForm.setControl("SubGroups", subGroups);
            _this.originalRule = response.Rule;
            _this.getFocusableInput();
            _this.loaded = true;
        });
    };
    LocationGroupEditComponent.prototype.getFocusableInput = function () {
        this.focusableInput = "subGroupHtmlName" + (this.indexer - 1);
    };
    LocationGroupEditComponent.prototype.getNewSubGroupCtrl = function (subGroup, optionType) {
        var nameVal = subGroup.Name;
        if (!!nameVal) {
            if (optionType === "Date") {
                nameVal = new Date(nameVal);
            }
            else if (optionType === "Number") {
                nameVal = Number(nameVal);
            }
        }
        var fullSizeImage = (subGroup.Media && subGroup.MasterMediaId !== this.emptyGuid) ?
            subGroup.Media.ResourceUri + "?BusinessIdentity=" + this.activeProfileService.businessIdentity + "&idToken=" + this.authService.getIdToken() : "";
        var nameControl = new FormControl({ value: nameVal, disabled: subGroup.IsNA }, [Validators.required, this.subGroupValidator, Validators.maxLength(50)]);
        var ctrl = new FormGroup({
            Id: new FormControl(subGroup.Id),
            BusinessIdentity: new FormControl(subGroup.BusinessIdentity),
            Name: nameControl,
            InputName: new FormControl(subGroup.InputName),
            LocationCount: new FormControl(subGroup.LocationCount),
            MasterMediaId: new FormControl(subGroup.MasterMediaId || this.emptyGuid),
            MediaType: new FormControl(subGroup.MediaType || "Unknown"),
            RemoveImage: new FormControl(""),
            FullSizeImage: new FormControl(fullSizeImage),
            IdNumber: new FormControl(this.idNumber++),
            SelectedImage: new FormControl(fullSizeImage ? fullSizeImage.indexOf("/PlaceHolder/") === -1 : false),
            IsNA: new FormControl(subGroup.IsNA)
        });
        if (optionType === "Bool") {
            ctrl.disable();
            ctrl.valueChanges.subscribe(function (value) {
                if (ctrl.valid) {
                    value.setHours(0, 0, 0, 0);
                    ctrl.patchValue(value, { emitEvent: false });
                }
            });
            //disable assignment rule selection
            this.Rule.disable();
            this.disableRuleSelection = true;
        }
        return ctrl;
    };
    LocationGroupEditComponent.prototype.addSubGroup = function (isNa) {
        var _this = this;
        if (isNa === void 0) { isNa = false; }
        var subGroups = this.SubGroups;
        var ctrl = this.getNewSubGroupCtrl(this.createEmptySubGroup(isNa), isNa ? "Text" : this.currentOptionType);
        subGroups.push(ctrl);
        if (isNa) {
            this.HasNA = true;
            this.locationGroupForm.markAsDirty();
            setTimeout(function () { _this.SubGroups.controls.forEach(function (newCtrl) { return newCtrl.get("Name").updateValueAndValidity(); }); }, 50);
        }
        this.getFocusableInput();
    };
    LocationGroupEditComponent.prototype.removeSubGroup = function (frmGrpIndex, toolTip) {
        var _this = this;
        toolTip.close();
        var subGroups = this.SubGroups;
        if (subGroups.controls.length <= 1) {
            this.ipsMessage.error("AT_LEAST_ONE_OPTION_REQUIRED");
            return;
        }
        var subgroup = subGroups.controls[frmGrpIndex];
        if (subgroup && subgroup.get("Id").value > 0) {
            var subGroupId_1 = subgroup.get("Id").value;
            this.campaignService.getCountByLocationSubGroup(subGroupId_1).then(function (count) {
                var errorMessage = "";
                if (count > 0) {
                    errorMessage = IpsString.Format(_this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_CAMPAIGN"), count);
                }
                _this.surveyService.getLocationSubGroupSurveyInUse(subGroupId_1)
                    .then(function (surveysInUse) {
                    var usedInAudience = false;
                    var surveyNames = [];
                    if (surveysInUse.length > 0) {
                        var surveysWithAudience = surveysInUse.filter(function (s) { return s.Audiences && s.Audiences.length > 0; });
                        usedInAudience = surveysWithAudience.length > 0;
                        surveyNames = surveysWithAudience.map(function (s) { return s.Name; });
                    }
                    if (usedInAudience) {
                        errorMessage = errorMessage === "" ? errorMessage : errorMessage + "\n\n";
                        errorMessage = errorMessage +
                            IpsString.Format(_this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_SURVEY"), surveyNames.join(", "));
                    }
                    _this.paymentSettingsService.checkLocationSubGroupsAreInUse([subGroupId_1])
                        .then(function (result) {
                        if (result) {
                            errorMessage = errorMessage === "" ? errorMessage : errorMessage + "\n\n";
                            errorMessage += _this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_PAYMENTSETTINGS");
                        }
                        if (errorMessage !== "") {
                            _this.ipsMessage.error(errorMessage);
                        }
                        else {
                            _this.locationGroupService.deleteCheck(subGroupId_1.toString())
                                .then(function (response) {
                                var canDeleteErrorList = response;
                                if (canDeleteErrorList.length > 0) {
                                    _this.ipsMessage.error("Unable to delete! Record is begin used in the following Market Groups:\r\n" + canDeleteErrorList.join("\r\n"));
                                }
                                else {
                                    _this.ipsMessage.confirm()
                                        .then(function (confirm) {
                                        if (confirm) {
                                            if (_this.deleteSubGroupCtrl(frmGrpIndex)) {
                                                _this.locationGroupForm.markAsDirty();
                                            }
                                        }
                                    })
                                        .catch(function () {
                                        // rejection
                                    });
                                }
                            })
                                .catch(function () {
                                _this.ipsMessage.error("Unable to delete! Record is may be used in the following Market Groups:\r\n-ERROR-");
                            });
                        }
                    });
                });
            });
        }
        else {
            if (this.deleteSubGroupCtrl(frmGrpIndex)) {
                this.locationGroupForm.markAsDirty();
            }
        }
    };
    LocationGroupEditComponent.prototype.deleteSubGroupCtrl = function (index) {
        var _this = this;
        var subGroupsControl = this.locationGroupForm.controls["SubGroups"];
        var count = subGroupsControl.controls.length;
        var control = subGroupsControl.at(index);
        if (!!control.get("IsNA").value) {
            this.HasNA = false;
            setTimeout(function () { _this.SubGroups.controls.forEach(function (newCtrl) { return newCtrl.get("Name").updateValueAndValidity(); }); }, 50);
        }
        subGroupsControl.removeAt(index);
        return count !== subGroupsControl.controls.length;
    };
    LocationGroupEditComponent.prototype.saveLocationGroupPrompt = function (redirect) {
        var _this = this;
        var id = this.locationGroupForm.value.Id || 0;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: function () { return _this.saveLocationGroup(); }, progressMessage: "SAVING" }).then(function (result) {
            if (result && redirect) {
                _this.$state.go("main.storeProfileLocationGroup.search");
            }
            else {
                _this.$state.go("main.storeProfileLocationGroup.edit", { id: _this.locationGroupForm.value.Id }, { reload: true });
            }
        });
    };
    LocationGroupEditComponent.prototype.saveLocationGroup = function () {
        var _this = this;
        // use getRawValue to include disabled controls values.
        var locationGroup = this.locationGroupForm.getRawValue();
        //add business Identity to locationGroup/subgroupd
        for (var i = 0; i < locationGroup.SubGroups.length; i++) {
            var subGroup = locationGroup.SubGroups[i];
            subGroup.BusinessIdentity = this.activeProfileService.businessIdentity;
            if (this.currentOptionType === "Date" && !subGroup.IsNA) {
                // Store in the format we want on the View and List pages. This is also parseable by .Net
                var date = subGroup.Name;
                subGroup.Name = this.datePipe.transform(date, "MMM d, yyyy");
            }
        }
        locationGroup.BusinessIdentity = this.activeProfileService.businessIdentity;
        if (locationGroup.Id) {
            return this.locationGroupService.put(locationGroup).then(function (response) {
                _this.populateDataFromResponse(response);
                return _this.saveImages().then(function (r) {
                    _this.populateMediaDataFromResponse(r);
                    _this.locationGroupForm.markAsPristine();
                });
            });
        }
        else {
            return this.locationGroupService.post(locationGroup).then(function (response) {
                _this.populateDataFromResponse(response);
                return _this.saveImages().then(function (r) {
                    _this.populateMediaDataFromResponse(r);
                    _this.locationGroupForm.markAsPristine();
                });
            });
        }
    };
    LocationGroupEditComponent.prototype.saveImages = function () {
        var promises = [];
        var imageSaveList = this.pullImageObject();
        for (var i = 0; i < imageSaveList.length; i++) {
            if (imageSaveList[i].Type === "feature") {
                //Feature image
                promises.push(this.locationGroupService.saveFeatureImage(imageSaveList[i].Id, imageSaveList[i].Files));
            }
            else {
                //Option images
                promises.push(this.locationGroupService.saveOptionImage(imageSaveList[i].Id, imageSaveList[i].Files));
            }
        }
        if (promises.length === 0) {
            promises.push(Promise.resolve());
        }
        return Promise.all(promises);
    };
    LocationGroupEditComponent.prototype.pullImageObject = function () {
        var _this = this;
        //Pull all the images that need to be saved
        return this.images.filter(function (item) { return item.fileInput.nativeElement.files.length > 0; }).map(function (m) {
            var id = 0;
            var type = "";
            if (m.name.toLowerCase() === "featureimage") {
                id = _this.locationGroupForm.value.Id;
                type = "feature";
            }
            else {
                id = _this.SubGroups.controls.find(function (ctrl) { return ctrl.get("IdNumber").value === Number(m.name); }).get("Id").value;
                type = "option";
            }
            return { Id: id, Type: type, Files: m.fileInput.nativeElement.files[0] };
        });
    };
    LocationGroupEditComponent.prototype.populateDataFromResponse = function (response) {
        this.locationGroupForm.patchValue({
            Id: response.Id,
        });
        //Do options
        this.SubGroups.controls.forEach(function (option) {
            var subGroupItem = response.SubGroups.find(function (item) { return String(item.Name).toLowerCase() === String(option.value.Name).toLowerCase(); });
            if (subGroupItem) {
                option.patchValue({ Id: subGroupItem.Id });
            }
        });
    };
    LocationGroupEditComponent.prototype.populateMediaDataFromResponse = function (response) {
        var _this = this;
        if (response && response[0]) {
            response.forEach(function (result) {
                var option = _this.SubGroups.controls.find(function (item) { return item.value.Id === result.Id; });
                if (option) {
                    option.patchValue({ MasterMediaId: result.MasterMediaId, MediaType: result.MediaType });
                }
            });
        }
    };
    LocationGroupEditComponent.prototype.deleteLocationGroupPrompt = function () {
        var _this = this;
        var promptMessage = IpsString.Format(this.promptDeleteBodyMessage, this.locationGroupForm.get("Name").value);
        var locationGroupId = this.locationGroupForm.value.Id;
        // TODO: add check for campaigns in use also.
        this.campaignService.getCountByLocationGroup(locationGroupId).then(function (campaignCount) {
            _this.surveyService.getLocationGroupSurveyInUse(locationGroupId).then(function (surveysInUse) {
                var deleteNotAllowedMsg = "";
                if (campaignCount > 0) {
                    deleteNotAllowedMsg = IpsString.Format(_this.translateService.instant("UNABLE_DELETE_LOCGROUP_USED_BY_CAMPAIGN"), campaignCount);
                }
                var usedInAudience = surveysInUse.length > 0 && surveysInUse.some(function (s) { return s.Audiences && s.Audiences.length > 0; });
                // If used in an audience, cannot delete.
                if (usedInAudience) {
                    var surveyNames = surveysInUse.filter(function (s) { return s.Audiences && s.Audiences.length > 0; })
                        .map(function (s) { return s.Name; });
                    var msg = IpsString.Format(_this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_SURVEY"), surveyNames.join(", "));
                    deleteNotAllowedMsg += deleteNotAllowedMsg.length > 0 ? "\n\n" + msg : msg;
                }
                var subGroupIds = _this.SubGroups.value.map(function (sg) { return sg.Id; });
                _this.paymentSettingsService.checkLocationSubGroupsAreInUse(subGroupIds)
                    .then(function (result) {
                    if (result) {
                        deleteNotAllowedMsg = deleteNotAllowedMsg === "" ? deleteNotAllowedMsg : deleteNotAllowedMsg + "\n\n";
                        deleteNotAllowedMsg += _this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_PAYMENTSETTINGS");
                    }
                    if (deleteNotAllowedMsg.length > 0) {
                        return _this.ipsMessage.error(deleteNotAllowedMsg);
                    }
                    // If we made it this far, not used in Markets/Audiences. Check for questions in use.
                    if (surveysInUse.length > 0) {
                        _this.ipsModal.displayComponent(LocationGroupDeleteModalComponent, { surveysInUse: surveysInUse }, { backdrop: "static" }).then(function (agreed) {
                            if (agreed) {
                                return _this.ipsModal.prompt({
                                    displayMode: "Progress",
                                    workFunction: function () { return _this.deleteLocationGroup(); },
                                    progressMessage: "DELETING"
                                }, {}).then(function (confirm) {
                                    if (confirm) {
                                        _this.$state.go("main.storeProfileLocationGroup.search");
                                    }
                                });
                            }
                        }, function (canceled) {
                            // Do nothing on cancel
                        });
                        // Nothing in use
                    }
                    else {
                        _this.ipsMessage
                            .confirmDelete({
                            body: promptMessage,
                            workFunction: function () { return _this.deleteLocationGroup(); },
                            progressMessage: "DELETING"
                        }).then(function (confirm) {
                            if (confirm) {
                                _this.$state.go("main.storeProfileLocationGroup.search");
                            }
                        });
                    }
                }, function (error) {
                    return _this.ipsMessage.error(error);
                });
            }, function (error) {
                return _this.ipsMessage.error(error);
            });
        }, function (error) {
            return _this.ipsMessage.error(error);
        });
    };
    LocationGroupEditComponent.prototype.deleteLocationGroup = function () {
        var locationGroup = this.locationGroupForm.value;
        return this.locationGroupService.delete(locationGroup.Id);
    };
    LocationGroupEditComponent.prototype.subGroupValidator = function (control) {
        if (!control.parent) {
            return null;
        }
        // Skip type validation for this one
        if (control.parent.value.IsNA) {
            return null;
        }
        var subGroups = control.parent.parent;
        var controlValue = null;
        if (!!control.value) {
            if (control.value.getMonth) {
                // Use toDateString to ignore the time component of the date.
                controlValue = control.value.toDateString();
            }
            else {
                controlValue = control.value.toString().toLowerCase();
            }
        }
        var itemsWithSameName = subGroups.controls.filter(function (sg) {
            var sgValue = sg.get("Name").value;
            if (!!sgValue) {
                if (sgValue.getMonth) {
                    sgValue = sgValue.toDateString();
                }
                else {
                    sgValue = sgValue.toString().toLowerCase();
                }
            }
            return sgValue === controlValue;
        });
        if (itemsWithSameName.length > 1) {
            return { dupeSubGroupNameMessage: { valid: false } };
        }
        if (controlValue === "not applicable") {
            return { reservedName: { valid: false } };
        }
        return null;
    };
    LocationGroupEditComponent.prototype.ruleChange = function () {
        var _this = this;
        if (this.originalRule === "Multiple") {
            //call server to validate if rule can change
            this.locationGroupService.canSwitchRule(this.locationGroupForm.value.Id.toString()).then(function (result) {
                if (!result) {
                    _this.Rule.patchValue(_this.originalRule);
                    _this.ipsMessage.error("UNABLE_CHANGE_RULE");
                }
            });
        }
    };
    LocationGroupEditComponent.prototype.onSelectedImageChanged = function (files, name) {
        var featureImageFile;
        var image = this.images.find(function (item) { return item.name === name.toString(); });
        if (this.featureImageFiles) {
            featureImageFile = this.featureImageFiles.find(function (item) { return item.Name === name.toString(); });
        }
        if (featureImageFile) {
            featureImageFile.Files = files;
        }
        else {
            this.featureImageFiles.push({ Name: name, Files: files });
        }
        this.locationGroupForm.markAsDirty();
        if (!files) {
            image.selectedImage = false;
            if (typeof (name) === "string" && name.toLowerCase() === "featureimage") {
                this.locationGroupForm.patchValue({
                    MasterMediaId: "00000000-0000-0000-0000-000000000000"
                });
            }
            else {
                var subGroup = this.SubGroups.controls.find(function (ctrl) { return ctrl.get("IdNumber").value === name; });
                subGroup.get("MasterMediaId").setValue("00000000-0000-0000-0000-000000000000");
            }
        }
        this.locationGroupForm.markAsDirty();
    };
    return LocationGroupEditComponent;
}());
export { LocationGroupEditComponent };
