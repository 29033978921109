<div>
    <button type="button" class="fa fa-remove ips-close" aria-label="Close"
            (click)="close()" data-dismiss="modal"></button>
</div>
<div class="ips-modal-header ips-modal-dark-header">
    <h4 class="modal-title m-0">{{ title }}</h4>
</div>
<div class="modal-body">
    <div *ngIf="multiSelect">
        <div class="ips-ls-long-text-wrap list-group mb-4">
            <div class="modal-scrollable list-search-infinite-container">
                <div *ngFor="let item of internalItems; let i = index"
                     class="card ips-shadow mb-2 subgroup-card d-flex flex-row align-items-center clicker"
                     [ngClass]="{ 'ips-checked-card': item.selected }">
                    <div class="p-2 pl-3 flex-grow-1" (click)="selectItem(i);">
                        {{ item[labelField] }}
                    </div>
                    <div *ngIf="item.selected" class="p-2">
                        <i class="fa fa-check fa-2x pr-3 location-check clicker"></i>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-default btn-block btn-lg" (click)="confirm()" [disabled]="selectedItemCount() === 0">Choose {{ selectedItemCount() }} item(s)</button>
    </div>
</div>
