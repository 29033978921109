var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
var CustomDataFieldService = /** @class */ (function (_super) {
    __extends(CustomDataFieldService, _super);
    function CustomDataFieldService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("CustomData", "Profile", "v1");
        return _this;
    }
    CustomDataFieldService.prototype.getCustomDataWithValue = function (area, linkId, subArea) {
        var route = "CustomData/" + area + "/" + linkId + "/CustomDataWithValue?subAreaType=" + subArea;
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    CustomDataFieldService.prototype.putCustomData = function (inputData) {
        var route = "CustomDataValue";
        return this.put(inputData, null, "" + this.urlApiBaseProductPlusVersion + route);
    };
    CustomDataFieldService.prototype.createNameControl = function (name, isFieldRequired) {
        var nameCtrl = new FormControl(name);
        if (isFieldRequired) {
            nameCtrl.setValidators([Validators.required, this.ValidateCheckName()]);
        }
        else {
            nameCtrl.setValidators([this.ValidateCheckName()]);
        }
        nameCtrl.updateValueAndValidity();
        return nameCtrl;
    };
    CustomDataFieldService.prototype.ValidateCheckName = function () {
        return function (control) {
            if (control && control.value !== "" && control.parent) {
                var validNameControl = control.parent.get("ValidName");
                if (control.value !== validNameControl.value) {
                    return {
                        optionNameInvalid: {
                            valid: false
                        }
                    };
                }
            }
            return null;
        };
    };
    return CustomDataFieldService;
}(IpsBaseWebService));
export { CustomDataFieldService };
