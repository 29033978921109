var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService } from "imagine-ui-ng-core";
var ShopSettingsService = /** @class */ (function (_super) {
    __extends(ShopSettingsService, _super);
    function ShopSettingsService(http, activeProfileService, helperFunctionsService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.helperFunctionsService = helperFunctionsService;
        _this.ShowOrderReasonSettingName = "ShowOrderReason";
        _this.OrderReasonSettingName = "OrderReasons";
        _this.PONumberSettingName = "PONumberOptions";
        _this.HandlingFeePerAddressSettingName = "HandlingFeePerAddress";
        _this.HandlingFeePerLineItemSettingName = "HandlingFeePerLineItem";
        _this.HandlingFeePerEachSettingName = "HandlingFeePerEach";
        _this.ReferenceNumberSettingName = "ShowReferenceNumber";
        _this.CheckoutLabelSettingName = "CheckoutLabel";
        _this.NeedByDateSettingName = "ShowNeedByDate";
        _this.CommentsSettingName = "ShowComments";
        _this.CategoryFilterSettingName = "ShowCategoryFilter";
        _this.CategoryPermissionSettingName = "CategoryHasPermissions";
        _this.AllLocationsSettingName = "AllLocations";
        _this.HideCampaignSettingName = "HideCampaign";
        _this.DeliverySelectionSettingName = "ShowDeliverySelection";
        _this.ShipMethodPreferencesSettingName = "ShipMethodPreferences";
        _this.init("Settings", "Shop", "v1");
        return _this;
    }
    ShopSettingsService.prototype.getSettingByName = function (name) {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            if (!name) {
                resolve(null);
            }
            else {
                _this.getList().then(function (response) {
                    var matches = response.filter(function (item) {
                        return item.Name === name;
                    });
                    if (matches.length) {
                        resolve(matches[0]);
                    }
                    else {
                        resolve(null);
                    }
                });
            }
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.hasAdminAccess = function () {
        var adminRoleNames = ["ShoppingAdmin", "EcommSiteAdmin"];
        var allThemes = this.activeProfileService.profile.businessIdentityList;
        var allRoles = [];
        allThemes.forEach(function (theme) {
            allRoles.push.apply(allRoles, theme.Role);
        });
        var matchingRoles = allRoles.filter(function (item) { return adminRoleNames.indexOf(item) >= 0; });
        return matchingRoles.length > 0;
    };
    ShopSettingsService.prototype.getPONumberSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.PONumberSettingName).then(function (settingModel) {
                var returnValue = settingModel;
                var parsedObj = JSON.parse(settingModel.Value);
                returnValue.EnableList = parsedObj.EnableList;
                returnValue.Options = parsedObj.Options;
                var rawOptions = returnValue.Options.split(/\r\n|\r|\n/g);
                var allowedPONumbers = rawOptions.filter(function (item) {
                    if (item.indexOf("_") === 0) {
                        return _this.hasAdminAccess();
                    }
                    else {
                        return true;
                    }
                });
                allowedPONumbers = allowedPONumbers.map(function (item) {
                    if (item.indexOf("_") === 0) {
                        return item.substring(1);
                    }
                    else {
                        return item;
                    }
                });
                returnValue.Options = allowedPONumbers.join("\r\n");
                resolve(returnValue);
            });
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.getCheckoutLabelSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.CheckoutLabelSettingName).then(function (response) {
                if (response) {
                    resolve(response.Value);
                }
                else {
                    resolve("Checkout by Invoice");
                }
            });
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.getReferenceNumberSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.ReferenceNumberSettingName).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(true);
                }
            });
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.getNeedByDateSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.NeedByDateSettingName).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(false);
                }
            });
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.getCategoryFilterSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.CategoryFilterSettingName).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(false);
                }
            });
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.getCategoryPermissionsSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.CategoryPermissionSettingName).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(false);
                }
            });
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.getCommentsSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.CommentsSettingName).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(false);
                }
            });
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.getOrderReasons = function () {
        var _this = this;
        var defaultValues = [
            "Additional POP",
            "Element Missing from Kit",
            "Faulty Packaging",
            "Grand Opening",
            "Kit Not Received",
            "Profile Change",
            "Store Discard",
            "Weather / Damage by Shipper",
            "Wrong Size Element in Kit"
        ];
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.OrderReasonSettingName).then(function (settingModel) {
                if (settingModel && settingModel.Value) {
                    var returnValue = settingModel.Value.split(/\r\n|\r|\n/g);
                    resolve(returnValue);
                }
                else {
                    resolve(defaultValues);
                }
            });
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.getAllLocationsSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.AllLocationsSettingName).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(false);
                }
            });
        });
        return returnPromise;
    };
    ShopSettingsService.prototype.getHideCampaignSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.HideCampaignSettingName).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(false);
                }
            });
        });
        return returnPromise;
    };
    // Work item 20612 [INC0115821]
    // If 'ShowDeliverySelection' setting is not found, we default it to 'true'
    ShopSettingsService.prototype.getShowDeliverySelectionSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.DeliverySelectionSettingName).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(true);
                }
            });
        });
        return returnPromise;
    };
    /**
     * Get settings that aren't returned by standard route.
     * @param routeName - name to use for restricted route. Example: HandlingFees
     */
    ShopSettingsService.prototype.GetRestrictedSettings = function (routeName) {
        return this.getByRoute(this.urlApiBaseProductPlusVersion + "Settings/" + routeName);
    };
    ShopSettingsService.prototype.PutRestrictedSettings = function (data, routeName) {
        return this.put(data, null, this.urlApiBaseProductPlusVersion + "Settings/" + routeName);
    };
    return ShopSettingsService;
}(IpsBaseWebService));
export { ShopSettingsService };
