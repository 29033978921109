import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import { Component, OnInit, Input, NgZone, ViewChildren, QueryList } from "@angular/core";
import { FormGroup, AbstractControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import {
    MessagePlacementCalculationResultModel, MessagePlacementCalculationModel, PromotionMessagePlacementGroupModel,
    PromotionMessageLocationBalanceModel, PatternGroupService, PromotionMessagePlacementModel, PatternType,
    PlacementEditModel, Operator, QuantityIncreaseTarget, PlacementModel, FulfillmentOperationType,
    PlacementLocationBalanceListRequestModel, PromotionMessageService, PromotionMessagePatternGroupModel, PromotionMessagePatternModel,
    MessageNameChangeModel, PatternGroupType, PromotionMessagePlacementSaveModel, PromotionSaveModel, PromotionMessagePatternSaveModel
} from "../../index";
import { TranslateService } from "@ngx-translate/core";
import { FixtureTypeSearchResultModel, HolderSearchResultModel, HolderVersionService } from "../../../imagine-ui-ng-store-profile/index";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { PromotionHelperService } from "../../service/promotionHelper.service";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { CustomDataFieldContainerComponent } from "../../../shared/custom-data-field/custom-data-field-container/custom-data-field-container.component";
import { CustomDataWithValueModel } from "../../../shared/index";
import { String as IpsString } from "typescript-string-operations";
import { MarketModel } from "../../../market";

export interface PromotionMessagePlacementUIModel extends PromotionMessagePlacementModel {
    IdName?: string;
    TempId: number;
    Show: boolean;
}

interface PlacementGroupUIModel extends PromotionMessagePlacementGroupModel {
    DisplayName?: string;
    MessagePlacements?: PlacementEditUIModel[];
    PatternGroupType?: PatternGroupType;
    PositionQty: number;
    QuantityIncreaseIdName?: string;
    QuantityIncrease?: number;
    QuantityIncreaseTarget?: string;
    FulfillmentOperand?: number;
    FulfillmentOperator?: FulfillmentOperationType;
    FinalQuantity?: number;
}

interface PlacementEditUIModel extends PlacementEditModel {
    MessagePlacementList: PromotionMessagePlacementUIModel[];
    MessagePlacementSizes?: PromotionMessagePlacementUIModel[];
    PrintMessage: boolean;
    loaded: boolean; //UI used for pattern msg show no holder msg
}

interface CdfValueChangeEventData {
    values: CustomDataWithValueModel[];
    initialLoad: boolean;
}


@Component({
    selector: "app-placement-edit",
    templateUrl: "./placement-edit.component.html",
    styleUrls: ["./placement-edit.component.scss"]
})
export class PlacementEditComponent implements OnInit {
    @ViewChildren(CustomDataFieldContainerComponent) customDataFieldContainers: QueryList<CustomDataFieldContainerComponent>;

    @Input() parent: FormGroup;
    @Input() messageAndPlacements: MessagePlacementCalculationResultModel[];
    @Input() campaignFulfillmentQty: number;
    @Input() campaignFulfillmentType: FulfillmentOperationType;
    @Input() promoId: number;
    @Input() useMessagePattern: boolean;
    @Input() selectedMessagePattern: string;
    // Initial list of pattern groups loaded from DB.
    @Input() patternGroups: PromotionMessagePatternGroupModel[];
    @Input() isClone = false;

    public positionLimitLoaded = false;
    public loaded = false;
    public promise: Promise<void>;
    private tmpIdIndexer = 1;
    public withinFixtureTooltip: string;
    public acrossFixtureTooltip: string;
    public placementQtyIncreaseTypePositionTooltip: string;
    public placementQtyIncreaseTypeTooltip: string;
    private positiveNumberPattern = "^\\d+$";

    private TranslateStrings: { [key: string]: string } = {
        "ENTER_POSITIVE_WHOLE_NUMBER": "",
        "MAX_LENGTH_ERROR": ""
    };

    private errorMessages = {
        "required": () => this.TranslateStrings["ENTER_POSITIVE_WHOLE_NUMBER"],
        "pattern": () => this.TranslateStrings["ENTER_POSITIVE_WHOLE_NUMBER"],
        "maxlength": (params) => IpsString.Format(this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength)
    };

    constructor(private translateService: TranslateService, private patternGroupService: PatternGroupService, private activeProfileService: ActiveProfileService,
        private ipsModal: IpsModalService, private promotionMessageService: PromotionMessageService, private promotionHelperService: PromotionHelperService,
        private fb: FormBuilder, private zone: NgZone, private holderVersionService: HolderVersionService) { }

    ngOnInit() {
        this.loaded = false;

        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(() => this.TranslateText());

        this.initializePlacementsForUI();
    }

    createFormControls(placementGroups: PromotionMessagePlacementGroupModel[], placements: PlacementEditUIModel[]): any {
        let placementsArray = this.fb.array(this.createPlacementFormArray(placementGroups, placements));

        if (this.placementsFrmArr) {
            this.parent.setControl("placementsFrmArr", placementsArray);
        } else {
            this.parent.addControl("placementsFrmArr", placementsArray);
        }
    }

    private createPlacementFormArray(placementGroups: PromotionMessagePlacementGroupModel[], placements: PlacementEditUIModel[]): any[] {
        let formArray = [];

        placementGroups.forEach((placemengGroup: PlacementGroupUIModel) => {
            let placementGroupPlacements = placements.filter(plc => plc.HolderId === placemengGroup.HolderId);
            let placementFormGroup = this.createPlacementGroupFormGroup(placemengGroup, placementGroupPlacements);
            formArray.push(placementFormGroup);
        });

        return formArray;
    }

    private createPlacementGroupFormGroup(placementGroup: PlacementGroupUIModel, placements: PlacementEditUIModel[]): FormGroup {
        let formGroup = this.fb.group(placementGroup);
        formGroup.addControl("defaultCdfValues", this.fb.control([]));
        if (placementGroup.PositionLocationCountOptions) {
            let locationCountOptionsForms =
                placementGroup.PositionLocationCountOptions.map(option => this.fb.group(option));
            formGroup.setControl("PositionLocationCountOptions", this.fb.array(locationCountOptionsForms));
        }
        let placementForms = placements.map(plc => this.createPlacementFormGroup(plc, formGroup));
        this.addPlacementGroupListenters(formGroup);

        formGroup.addControl("MessagePlacements", this.fb.array(placementForms));
        return formGroup;
    }

    private createPlacementFormGroup(placement: PlacementEditUIModel, placementGroup: FormGroup): FormGroup {
        let placementForm = this.fb.group(placement);
        let locationAssignments: number[] = [];

        //added listener to printMessage for changes
        const ctrl = placementForm.get("PrintMessage");
        if (ctrl !== null) {
            ctrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
                this.printMessageChanged(placementForm, value);
            });
        }

        if (placement.MessagePlacement) {

            locationAssignments = Object.assign([], placement.MessagePlacement.LocationAssignments);

            let msgPlacement = this.fb.group({ ...placement.MessagePlacement, LocationAssignments: this.fb.array(locationAssignments) });

            if (!placement.MessagePlacement.CustomerItemCode) {
                msgPlacement.addControl("CustomerItemCode", this.fb.control(""));
            }
            if (!placement.MessagePlacement.CategoryCode) {
                msgPlacement.addControl("CategoryCode", this.fb.control(""));
            }
            placementForm.setControl("MessagePlacement", msgPlacement);
        }

        if (placement.MessagePlacementList) {
            const msgPlacementFGs = placement.MessagePlacementList.map(item => {

                let grp = this.fb.group({ ...item, LocationAssignments: this.fb.array(locationAssignments) });

                if (!item.CustomerItemCode) {
                    grp.addControl("CustomerItemCode", this.fb.control(""));
                }
                if (!item.CategoryCode) {
                    grp.addControl("CategoryCode", this.fb.control(""));
                }
                return grp;
            });
            const msgPlacements = new FormArray(msgPlacementFGs);
            placementForm.setControl("MessagePlacementSizes", msgPlacements);
            this.getMessagePlacementSizesCtrl(placementForm).controls.forEach((item: FormGroup) => {

                if (!this.useMessagePattern) {
                    this.setCtrlValidationAndSubscription(item, placementGroup);
                } else {
                    item.get("CustomerItemCode").setValidators([Validators.maxLength(50)]);
                }

                if (!item.get("Selected").value) {
                    item.get("QuantityIncrease").disable();
                    item.get("FulfillmentOperand").disable();
                    item.patchValue({ FinalQuantity : 0 });
                }

                //added listener to printSize for changes
                this.addLisentnerToPrintSize(item);

            });

            delete placement.MessagePlacementList;
        } else {
            placementForm.setControl("MessagePlacementSizes", new FormArray([]));
        }

        return placementForm;
    }

    private addPlacementGroupListenters(placementGroupForm: FormGroup) {
        const ctrl = placementGroupForm.get("SelectedPosition");
        if (ctrl !== null) {
            ctrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
                this.positionLimitSelected(placementGroupForm);
            });
        }
        const patternTypeCtrl = placementGroupForm.get("SelectedPatternType");
        if (patternTypeCtrl !== null) {
            patternTypeCtrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
                this.patternTypeSelected(placementGroupForm);
            });
        }

        placementGroupForm.get("QuantityIncrease").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        placementGroupForm.get("QuantityIncrease").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
            this.patternQtyIncreaseChange(placementGroupForm, value);
        });

        placementGroupForm.get("FulfillmentOperand").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        placementGroupForm.get("FulfillmentOperand").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
            this.patternFulfillmentOperandChanged(placementGroupForm, value);
        });
    }

    get placementsFrmArr(): FormArray {
        return this.parent.get("placementsFrmArr") as FormArray;
    }

    /**
     * Get the list of placements that exist for the specified placementGroup control
     * @param placementGroupCtrl - PlacementGroup control that contains the placements.
     */
    private getMsgPlacementsCtrl(placementGroupCtrl: AbstractControl): FormArray {
        return placementGroupCtrl.get("MessagePlacements") as FormArray;
    }

    /**
     * Returns the inner MessagePlacement formGroup contained in the PlacementEdit formGroup
     * @param placementCtrl
     */
    private getMessagePlacementCtrl(placementCtrl: FormGroup): FormGroup {
        return placementCtrl.get("MessagePlacement") as FormGroup;
    }

    /**
     * Returns the inner MessagePlacementSizes formGroup contained in the PlacementEdit formGroup
     * @param placementCtrl
     */
    private getMessagePlacementSizesCtrl(placementCtrl: AbstractControl): FormArray {
        return placementCtrl.get("MessagePlacementSizes") as FormArray;
    }

    private TranslateText() {
        this.translateService.get("ALL").subscribe(() => {
            this.withinFixtureTooltip = this.translateService.instant("TOOLTIP_PATTERN_WITHIN_FIXTURE");
            this.acrossFixtureTooltip = this.translateService.instant("TOOLTIP_PATTERN_ACROSS_FIXTURE");
            this.placementQtyIncreaseTypePositionTooltip = this.translateService.instant("TOOLTIP_PLACEMENT_POSITION_QTY_INCREASE_TYPE");
            this.placementQtyIncreaseTypeTooltip = this.translateService.instant("TOOLTIP_PLACEMENT_QTY_INCREASE_TYPE");

            for (let key of Object.keys(this.TranslateStrings)) {
                this.TranslateStrings[key] = this.translateService.instant(key);
            }
        });
    }

    public getErrorMessages() {
        return this.errorMessages;
    }

    /**
     * Call to set various properties needed by UI.
     */
    private initializePositionLocationCountsOptions(placementGroups: PromotionMessagePlacementGroupModel[]): Promise<void> {
        this.loaded = false;
        this.positionLimitLoaded = false;
        if (placementGroups[0] === undefined || placementGroups[0].PatternTypeEnum === "None") {
            return Promise.resolve();
        }
        return this.loadPositionLocationCountsOptions(placementGroups, this.patternGroups).then(() => {
            this.loaded = true;
            this.positionLimitLoaded = true;
        });
    }

    /**
     * Call this method to get the latest position location counts. Will NOT update control values.
     * @param placementGroups - List of placement groups to get counts for.
     * @param patternGroup - Optional. If not set, will get the first pattern group from the controls. Must pass this in on initial load.
     */
    private loadPositionLocationCountsOptions(placementGroups: PromotionMessagePlacementGroupModel[], patternGroups?: PromotionMessagePatternGroupModel[] ): Promise<PromotionMessagePlacementGroupModel[]> {
        // Making an assumption for now that we only want position location counts when there is one patternGroup only, so get the first patternGroup.
        if (!patternGroups) {
            patternGroups = this.promotionHelperService.getCurrentPatternGroupsFromForm(this.parent);
        }
        let promoMessages: PromotionMessageLocationBalanceModel[];
        if (this.selectedMessagePattern === "priorityFill") {
            let marketModel: MarketModel[];
            marketModel = [];
            patternGroups[0].MessagePatterns.map(mp => {
                mp.Markets.forEach((mar) => {
                    marketModel.push(mar);
                });
            });
            promoMessages = patternGroups.map(pg => {
                pg.Markets.forEach((m, i) => m.Ordinal = i + 1);
                const promoMessage: PromotionMessageLocationBalanceModel = {
                    Id: 0,
                    Name: "",
                    PromotionId: this.promoId,
                    StartDate: this.parent.value.StartDate,
                    EndDate: this.parent.value.EndDate,
                    Markets: marketModel
                };
                return promoMessage;
            });
        }
        else {
            promoMessages = patternGroups.map(pg => {
                pg.Markets.forEach((m, i) => m.Ordinal = i + 1);
                const promoMessage: PromotionMessageLocationBalanceModel = {
                    Id: 0,
                    Name: "",
                    PromotionId: this.promoId,
                    StartDate: this.parent.value.StartDate,
                    EndDate: this.parent.value.EndDate,
                    Markets: pg.Markets
                };
                return promoMessage;
            });
        }



        return this.patternGroupService.calculatePositionLocationCounts(promoMessages, placementGroups).then((response) => {
            //update PlacementGroup With PositionLocationCountsOptions
            response.forEach((responsePlacementGroup) => {
                let placementGroup = placementGroups.find(pg => pg.HolderId === responsePlacementGroup.HolderId);
                if (placementGroup) {
                    let position: number;
                    let options = responsePlacementGroup.PositionLocationCountOptions;
                    let maxPosition = options[options.length - 1];
                    if (responsePlacementGroup.PositionLimit === 0 || responsePlacementGroup.PositionLimit === undefined ||
                        responsePlacementGroup.PositionLimit > maxPosition.Position ||
                        (responsePlacementGroup.SelectedPosition !== undefined && responsePlacementGroup.SelectedPosition > maxPosition.Position)) {

                        position = maxPosition.Position;
                    } else {
                        let selected = responsePlacementGroup.PositionLocationCountOptions.filter(plo => plo.Position === responsePlacementGroup.PositionLimit)[0];

                        position = selected.Position;
                    }

                    placementGroup.SelectedPatternType = placementGroup.PatternTypeEnum;
                    placementGroup.SelectedPosition = position;
                    placementGroup.PositionLimit = position;
                    placementGroup.PositionLocationCountOptions = responsePlacementGroup.PositionLocationCountOptions;
                }
            });

            return placementGroups;
        });
    }

    private initializePlacementsForUI(): void {
        let placements = [] as PlacementEditUIModel[];
        let placementGroups = [] as PromotionMessagePlacementGroupModel[]; // UI placement group for non-pattern. groups by holder. does NOT match DB

        if (!this.parent.get("ClearAllMessages").value) {

            this.messageAndPlacements.forEach((calcResultModel) => {

                //Get unique holder list
                let uniqueHolderIds = calcResultModel.MessagePlacements.map(item => item.HolderId).filter((v, i, a) => a.indexOf(v) === i);

                //Merge all MessagePlacement into an array
                uniqueHolderIds.forEach(holderId => {
                    let msgPlacements = calcResultModel.MessagePlacements.filter(item => item.HolderId === holderId) as PlacementEditUIModel[];

                    // Temporary list of placements for a specific holder, could be from multiple DB placementGroups for non-pattern.
                    msgPlacements[0].MessagePlacementList = [];
                    for (let i = 0; i < msgPlacements.length; i++) {
                        let newPlacement = Object.assign({}, msgPlacements[i].MessagePlacement) as PromotionMessagePlacementUIModel;
                        newPlacement.Selected = msgPlacements[i].Selected;
                        newPlacement.HolderId = msgPlacements[i].HolderId;

                        if (newPlacement.Height && newPlacement.Width) {
                            newPlacement.Show = true;
                        }

                        msgPlacements[0].MessagePlacementList.push(newPlacement);
                    }
                });

                //Remove unneeded msgPlacements
                for (let i = calcResultModel.MessagePlacements.length - 1; i > 0; i--) {
                    if (!(calcResultModel.MessagePlacements[i] as PlacementEditUIModel).MessagePlacementList) {
                        calcResultModel.MessagePlacements.splice(i, 1);
                    }
                }

                calcResultModel.MessagePlacements.forEach((placement: PlacementEditUIModel) => {
                    let tempIdIndex = this.tmpIdIndexer++;
                    placement.DisplayName = `${placement.FixtureTypeName} - ${placement.HolderName}`;
                    placement.loaded = true;
                    let msgPlacement = <PromotionMessagePlacementUIModel>placement.MessagePlacement;
                    msgPlacement.HolderId = placement.HolderId;
                    msgPlacement.IdName = "placement" + tempIdIndex;
                    msgPlacement.TempId = tempIdIndex;

                    // Set message info
                    msgPlacement.TempMessageId = calcResultModel.PromotionMessage.TempId;
                    msgPlacement.PromotionMessageName = calcResultModel.PromotionMessage.Name;

                    placements = placements.concat(placement);
                });
            });



            this.patternGroups.forEach((patternGroup) => {
                patternGroup.PlacementGroups.forEach((pg: PlacementGroupUIModel) => {
                    // Set PrintMessage on the UI placement foreach DB placementGroup
                    placements.filter(p => p.PromotionMessagePlacementGroupId === pg.Id).forEach(uiP => uiP.PrintMessage = pg.PrintMessage);

                    //These sums are for patterns only!
                    let positionQty = 0;
                    let finalQuantity = 0;
                    this.messageAndPlacements.forEach((msgItem) => {
                        msgItem.MessagePlacements.forEach((msgPlacementItem: PlacementEditUIModel) => {
                            if (msgPlacementItem.PromotionMessagePlacementGroupId === pg.Id) {

                                for (let i = 0; i < msgPlacementItem.MessagePlacementList.length; i++) {
                                    //Don't include the fake row results
                                    if (msgPlacementItem.MessagePlacementList[i].Show) {
                                        positionQty += msgPlacementItem.MessagePlacementList[i].HolderCount;
                                        finalQuantity += msgPlacementItem.MessagePlacementList[i].FinalQuantity;
                                    }
                                }
                            }
                        });
                    });

                    // If we don't have a GUI placement group yet, make one. This means the first placement group settings win.
                    if (!placementGroups.some(pgItem => pgItem.HolderId === pg.HolderId)) {
                        const plc = placements.find(item => item.HolderId === pg.HolderId);
                        // set displayName
                        pg.PatternGroupType = patternGroup.PatternGroupType;
                        pg.SelectedPatternType = pg.PatternTypeEnum ? pg.PatternTypeEnum : "none" as PatternType;
                        pg.SelectedPosition = pg.PositionLimit;
                        pg.PositionQty = positionQty;
                        pg.QuantityIncreaseIdName = "placement" + this.tmpIdIndexer++;
                        pg.FinalQuantity = finalQuantity;

                        if (plc) {
                            pg.DisplayName = plc.DisplayName;
                            pg.QuantityIncrease = plc.MessagePlacement.QuantityIncrease;
                            pg.QuantityIncreaseTarget = plc.MessagePlacement.QuantityIncreaseTarget || "Holder";
                            pg.FulfillmentOperand = plc.MessagePlacement.FulfillmentOperand;
                            pg.FulfillmentOperator = plc.MessagePlacement.FulfillmentOperator || this.campaignFulfillmentType;
                            pg.HolderId = plc.HolderId;
                        } else {
                            pg.DisplayName = "";
                            pg.QuantityIncrease = 0;
                            pg.QuantityIncreaseTarget = "Holder";
                            pg.FulfillmentOperand =  this.campaignFulfillmentQty;
                            pg.FulfillmentOperator = this.campaignFulfillmentType;
                        }
                        placementGroups.push(pg);
                    }
                });
            });
        }

        if (this.isClone) {
            this.zeroOutIds(placementGroups, placements);
        }

        // Add array to avoid console error before asyn operation
        this.parent.addControl("placementsFrmArr", this.fb.array([]));
        if (this.useMessagePattern && placementGroups) {
            this.initializePositionLocationCountsOptions(placementGroups).then(() => {
                this.createFormControls(placementGroups, placements);
                this.sortPlacementGroups();
                this.sortPlacements();
                this.loaded = true;
            });
        } else {
            Promise.all(placementGroups.map(pg => this.initializePositionLocationCountsOptions([pg]))).then(() => {
                this.createFormControls(placementGroups, placements);
                this.sortPlacementGroups();
                this.sortPlacements();
                this.loaded = true;
            });
        }
    }

    /**
     * Zeros out ids for items that would be saved to the db like placement group, message id, promotion id.
     * @param placementGroups
     * @param placements
     */
    private zeroOutIds(placementGroups: PromotionMessagePlacementGroupModel[], placements: PlacementEditUIModel[]) {
        placementGroups.forEach(pg => {
            pg.Id = undefined;
            pg.PromotionMessagePatternGroupId = 0;
        });

        placements.forEach(p => {
            p.PromotionId = 0;
            p.PromotionMessagePlacementGroupId = undefined; // Can't be 0, causes foreign key constraint error.
            p.MessagePlacement.Id = 0;
            if (p.MessagePlacementList) {
                p.MessagePlacementList.forEach(pl => {
                    pl.Id = 0;
                    pl.PromotionMessageId = 0;
                    pl.CustomerItemCode = undefined;
                });
            }
            if (p.MessagePlacementSizes) {
                p.MessagePlacementSizes.forEach(pl => {
                    pl.Id = 0;
                    pl.PromotionMessageId = 0;
                    pl.CustomerItemCode = undefined;
                });
            }
        });
    }

    public shouldShowPlacements(): boolean {
        return this.loaded && this.placementsFrmArr && this.placementsFrmArr.controls.length > 0;
    }

    public onHolderPlacementsAdded(response: FixtureTypeSearchResultModel[]) {
        //remove placements not selected
        let indexesToRemove = [] as number[];
        this.placementsFrmArr.controls.forEach((placementGroupCtrl, index) => {
            let placementGroup = placementGroupCtrl.value as PromotionMessagePlacementGroupModel;
            let found = response.some((item) => {
                return item.Holders.some((holder) => {
                    return holder.Id === placementGroup.HolderId;
                });
            });
            if (!found) {
                indexesToRemove.push(index);
            }
        });

        if (indexesToRemove.length > 0) {
            indexesToRemove = indexesToRemove.sort();
            // Walk backwards removing items from the end of the formArray
            for (let i = indexesToRemove.length - 1; i >= 0; i--) {
                this.placementsFrmArr.removeAt(indexesToRemove[i]);
            }
            this.placementsFrmArr.markAsDirty();
        }

        //placement to add
        let controls = this.placementsFrmArr.controls;
        let currentTempMsgIds = [] as number[];
        let msgs = this.promotionHelperService.getCurrentMessagesFromForm(this.parent);
        let patternGroups = this.promotionHelperService.getCurrentPatternGroupsFromForm(this.parent);
        // Remove duplicates
        msgs = msgs.filter((msg) => {
            if (currentTempMsgIds.indexOf(msg.TempMessageId) === -1) {
                currentTempMsgIds.push(msg.TempMessageId);
                return true;
            }
            return false;
        });
        let holderIdsAdded = [] as number[];
        response.forEach((fixtureGroup: FixtureTypeSearchResultModel) => {
            fixtureGroup.Holders.forEach((holder) => {
                let hasHolderId =
                    controls.some(ctrl => (ctrl.value as PromotionMessagePlacementGroupModel).HolderId === holder.Id);
                if (!hasHolderId) {
                    holderIdsAdded.push(holder.Id);
                    let placements = this.createPlacements(fixtureGroup, holder, msgs);
                    let placementGroup = <PlacementGroupUIModel>{
                        Id: 0,
                        HolderId: holder.Id,
                        PromotionMessagePatternGroupId: this.useMessagePattern ? patternGroups[0].Id : 0,
                        PatternGroupType: this.useMessagePattern ? "MultiMessage" : "SingleMessage",
                        PatternTypeEnum: "None",
                        BusinessIdentity: this.activeProfileService.businessIdentity,
                        DisplayName: placements[0].DisplayName,
                        PositionLimit: this.useMessagePattern ? undefined : 99999, // PositionLimit will be filled in later when using patterns.
                        SelectedPosition: this.useMessagePattern ? undefined : 99999,
                        SelectedPatternType: "None",
                        PositionQty: 0,
                        QuantityIncreaseIdName: "placement" + this.tmpIdIndexer++,
                        QuantityIncrease: 0,
                        QuantityIncreaseTarget: "Holder",
                        FulfillmentOperand: this.campaignFulfillmentQty,
                        FulfillmentOperator: this.campaignFulfillmentType,
                        FinalQuantity: 0,
                        PrintMessage: true
                    };

                    let newPlacementGroupCtrl: FormGroup;
                    if (this.useMessagePattern) {
                        this.positionLimitLoaded = false;
                        this.loadPositionLocationCountsOptions([placementGroup]).then((placementGroups) => {
                            newPlacementGroupCtrl = this.createPlacementGroupFormGroup(placementGroups[0] as PlacementGroupUIModel, placements);
                            this.placementsFrmArr.push(newPlacementGroupCtrl);
                            this.placementsFrmArr.markAsDirty();
                            this.calculatePlacementGroupQuantities(newPlacementGroupCtrl);
                            this.positionLimitLoaded = true;
                        });
                    } else {
                        newPlacementGroupCtrl = this.createPlacementGroupFormGroup(placementGroup, placements);
                        this.placementsFrmArr.push(newPlacementGroupCtrl);
                        this.placementsFrmArr.markAsDirty();
                    }
                }
            });
        });

        if (!this.useMessagePattern && this.selectedMessagePattern === "priorityFill" && holderIdsAdded.length > 0) {
            this.calculateAllPriorityFillPlacementQuantities(msgs, holderIdsAdded);
        } else if (!this.useMessagePattern && holderIdsAdded.length > 0) {
            for (let i = 0; i < msgs.length; i++) {
                this.calculateAllPlacementQuantities(msgs[i].TempMessageId, holderIdsAdded);
            }
        }
    }

    private createPlacements(fixtureGroup: FixtureTypeSearchResultModel, holder: HolderSearchResultModel, messages: PromotionMessagePatternModel[]): PlacementEditUIModel[] {
        let ret: PlacementEditUIModel[] = [];
        messages.forEach((msg) => {
            let placement = this.getNewPlacement(msg, fixtureGroup.Name, holder);
            ret.push(placement);
        });

        return ret;
    }

    private getNewPlacement(mainMsg: PromotionMessagePatternModel, fixtureGroupName: string, holder: HolderSearchResultModel): PlacementEditUIModel {

        // Generate a new placement for a given holder and its fixture group (aka fixtureType)

        let messagePlacement = this.createNewPromotionMessagePlacementModel(holder.Id, mainMsg.MessageName, mainMsg.Id, mainMsg.TempMessageId);

        let placement = <PlacementEditUIModel>{
            HolderId: holder.Id,
            HolderName: holder.Name,
            FixtureTypeName: fixtureGroupName,
            PromotionId: this.promoId,
            DisplayName: `${fixtureGroupName} - ${holder.Name}`,
            Selected: true,
            PromotionMessagePlacementGroupId: null,
            MessagePlacement: messagePlacement,
            MessagePlacementList: [<PromotionMessagePlacementUIModel>messagePlacement],
            PrintMessage: true,
            loaded: true
    };

        return placement;
    }

    private createNewPromotionMessagePlacementModel(holderId: number, promotionMessageName: string, promotionMessageId: number, promotionMessageTempMessageId: number): PromotionMessagePlacementModel {
        let index = this.tmpIdIndexer++;

        return <PromotionMessagePlacementModel> {
            Id: 0,  // New placement, use id 0
            IdName: "placement" + index,
            TempId: index,
            HolderId: holderId,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            PromotionMessageName: promotionMessageName,
            FinalQuantity: 0, // Lookup quantity
            FulfillmentOperand: this.campaignFulfillmentQty,
            FulfillmentOperator: this.campaignFulfillmentType,
            HolderCount: 0, // Calculated value, default to 0
            LocationCount: 0, // Calculated value, default to 0
            LocationAssignments: [],
            PromotionMessageId: promotionMessageId,
            QuantityIncrease: 0,
            QuantityIncreaseTarget: "Holder",
            TempMessageId: promotionMessageTempMessageId,
            UseAllSizes: this.useMessagePattern,
            Selected: true,
            CustomerItemCode: "",
            HolderVersionInfoName: undefined,
            CategoryCode: ""
        };
    }

    private reSortPlacementAndCalculateHolderQuantity(): void {
        if (this.placementsFrmArr.length === 0) {
            return;
        }

        //re-order placements within the groups
        this.sortPlacements();

        this.calculateAllPlacementQuantities(null);
    }

    /**
     * Order placement groups relative to each other by displayname
     */
    private sortPlacementGroups(): void {
        // Sort placementGroups by DisplayName
        let placementGroups = this.placementsFrmArr;
        placementGroups.controls = placementGroups.controls.sort((n1, n2) => (n1.value.DisplayName.localeCompare(n2.value.DisplayName)));
    }

    /**
     * Loops through each placement group and orders placements within each group by messageName or ordinal
     */
    private sortPlacements() {
        const currentMessages = this.promotionHelperService.getCurrentMessagesFromForm(this.parent);
        this.placementsFrmArr.controls.forEach((ctrl: FormGroup) => this.sortPlacementsWithinPlacementGroup(ctrl, currentMessages));
    }

    /**
     * For a given placement group, will order messages by name if nonPattern, or will order messages by ordinal if pattern.
     * @param placementGroupCtrl
     */
    private sortPlacementsWithinPlacementGroup(placementGroupCtrl: FormGroup, currentMessages: PromotionMessagePatternModel[]) {
        const placmentGroup = placementGroupCtrl.value as PlacementGroupUIModel;
        if (placmentGroup.PatternGroupType === "MultiMessage") {
            let messgePlacements = this.getMsgPlacementsCtrl(placementGroupCtrl);
            messgePlacements.controls = messgePlacements.controls.sort((n1, n2) => {
                // get ordinal from first message found based on tempId, will have the lowest ordinal since they are kept in order.
                let n1Msg = currentMessages.find(msg => msg.TempMessageId ===
                    (<PlacementEditModel>n1.value).MessagePlacement.TempMessageId);
                let n2Msg = currentMessages.find(msg => msg.TempMessageId ===
                    (<PlacementEditModel>n2.value).MessagePlacement.TempMessageId);
                return n1Msg.Ordinal - n2Msg.Ordinal;
            });
        }
    }

    public getOperatorIconName(operator: Operator): string {
        let result = "NA";
        switch (operator) {
            case "Percent":
                result = "fa-percent";
                break;
            case "Number":
                result = "fa-hashtag";
                break;
        }
        return result;
    }

    public getIncreaseTargetNameKey(increaseTarget: QuantityIncreaseTarget): string {
        let result = "NA";
        switch (increaseTarget) {
            case "Location":
                result = "LOCATION";
                break;
            case "Holder":
                if (this.useMessagePattern) {
                    result = "POSITION";
                } else {
                    result = "HOLDER";
                }
                break;
        }

        return result;
    }

    public getIncreaseTargetTooltip(): string {
        return this.useMessagePattern ? this.placementQtyIncreaseTypePositionTooltip : this.placementQtyIncreaseTypeTooltip;
    }

    public patternTypeClick(placementGroupCtrl: FormGroup): void {
        let placementGroup = placementGroupCtrl.value as PromotionMessagePlacementGroupModel;
        const newPatternType = placementGroup.PatternTypeEnum === "AcrossFixtures" ? "WithinFixture" : "AcrossFixtures";
        placementGroupCtrl.patchValue({
            PatternTypeEnum: newPatternType
        });
        placementGroupCtrl.markAsDirty();
        // reload positionLocationCountOptions and recalculate placements
        this.loadPositionLocationCountsOptionsAndReCalculatePlacement([placementGroupCtrl]);
    }

    /**
     * Call this when type is changed
     * @param placementCtrl - placement control at size level
     * @param placementGroup - placement group control of placement control
     */
    public qtyIncreaseTypeClick(placementGroup: FormGroup, placementCtrl: FormGroup): void {
        placementCtrl.patchValue({
            QuantityIncreaseTarget: placementCtrl.value.QuantityIncreaseTarget === "Holder" ? "Location" : "Holder"
        });
        placementCtrl.markAsDirty();


        this.calculatePlacementQuantities(placementGroup, placementCtrl);
    }

    public qtyIncreaseChange(placementCtrl: FormGroup, placementGroup: FormGroup): void {

        this.calculatePlacementQuantities(placementGroup, placementCtrl);
    }

    /**
     * Call when fulfillment type changes
     * @param placementCtrl - - placement control at size level
     * @param placementGroup - placement group control of placement control
     */
    public fulfillmentOperatorClick(placementGroup: FormGroup, placementCtrl: FormGroup): void {
        //const msgPlacementCtrl = this.getMessagePlacementCtrl(placementCtrl);
        //let msgPlacement = msgPlacementCtrl.value as PromotionMessagePlacementModel;
        placementCtrl.patchValue({
            FulfillmentOperator: placementCtrl.value.FulfillmentOperator === "Percent" ? "Number" : "Percent"
        });
        placementCtrl.markAsDirty();

        this.calculatePlacementQuantities(placementGroup, placementCtrl);
    }

    public fulfillmentOperandChanged(msgPlacementCtrl: FormGroup, placementGroup: FormGroup): void {
        this.calculatePlacementQuantities(placementGroup, msgPlacementCtrl);
    }

    public positionLimitSelected(placementGroupCtrl: FormGroup): void {
        placementGroupCtrl.patchValue({
            PositionLimit: Number(placementGroupCtrl.get("SelectedPosition").value)
        });
        placementGroupCtrl.markAsDirty();

        this.calculatePlacementGroupQuantities(placementGroupCtrl);
    }

    public patternTypeSelected(placementGroupCtrl: FormGroup): void {
        let val = placementGroupCtrl.get("SelectedPatternType").value;
        placementGroupCtrl.patchValue({
            PatternTypeEnum: val
        });
        placementGroupCtrl.markAsDirty();

        // reload positionLocationCountOptions and recalculate placements
        if (val === "None") {
            placementGroupCtrl.patchValue({
                SelectedPosition: 99999
            });
            this.positionLimitSelected(placementGroupCtrl);
        } else {
            this.loadPositionLocationCountsOptionsAndReCalculatePlacement([placementGroupCtrl]);
        }
    }

    public onMainMessageAdded(mainMessage: PromotionMessagePatternModel): void {
        // Only work with non-empty messages.
        if (mainMessage.TempMessageId !== undefined && mainMessage.TempMessageId !== null) {
            let placementGroupCtrls = this.placementsFrmArr.controls;
            placementGroupCtrls.forEach((ctrl) => {
                let existingPlacements = this.getMsgPlacementsCtrl(ctrl);
                const existingPlacement = existingPlacements.at(0).value as PlacementEditModel;
                let fixtureGroupName = existingPlacement.FixtureTypeName;
                let holder = <HolderSearchResultModel>{
                    Name: existingPlacement.HolderName,
                    Id: existingPlacement.HolderId
                };
                let newPlacement = this.getNewPlacement(mainMessage, fixtureGroupName, holder);
                let placementCtrl = this.createPlacementFormGroup(newPlacement, ctrl as FormGroup);
                existingPlacements.push(placementCtrl);
            });
        }

        this.calculateAllPlacementQuantities(mainMessage.TempMessageId);
    }

    /**
     * We call DB to get new position limits, then update the controls, and optionally call calculate location balance.
     * @param placementGroupCtrl
     * @param doCalculate - defaults to true. Set to false when you want to update the location counts without calculating. Current use
     * case is when markets change, we want one large recalc instead of doing a recalc here.
     */
    private loadPositionLocationCountsOptionsAndReCalculatePlacement(placementGroupCtrls: FormGroup[], doCalculate: boolean = true): Promise<void> {
        this.positionLimitLoaded = false;
        const placementGroupValues = placementGroupCtrls.map(ctrl => ctrl.value);
        return this.loadPositionLocationCountsOptions(placementGroupValues).then((placementGroups: PlacementGroupUIModel[]) => {
            placementGroups.forEach((placementGroup) => {
                let placementGroupCtrl =
                    placementGroupCtrls.find(ctrl => (<PlacementGroupUIModel>ctrl.value).HolderId === placementGroup.HolderId);

                placementGroupCtrl.setControl(
                    "PositionLocationCountOptions",
                    this.fb.array(placementGroup.PositionLocationCountOptions.map(pg => this.fb.group(pg))));

                let positionIndex = placementGroup.PositionLocationCountOptions[placementGroup.PositionLocationCountOptions.length - 1].Position;
                placementGroupCtrl.patchValue({ SelectedPosition: positionIndex, PositionLimit: positionIndex }, { emitEvent: doCalculate });
                placementGroupCtrl.markAsDirty();
            });
            this.positionLimitLoaded = true;
        });
    }

    public onPatternMessageOrdinalChanged(): void {
        // Use zone.run to make sure the GUI updates.
        this.zone.run(() => {
            this.reSortPlacementAndCalculateHolderQuantity();
        });
    }

    public onMainMessageDeleted(mainMessage: PromotionMessagePatternModel): void {
        let placementGroupsToRemove = [] as number[];
        //For this.placementsFrmArr.controls, each entry is a placement group.
        //For placementsCtrl.controls, each entry is a placement w/ MessagePlacement property.
        this.placementsFrmArr.controls.forEach((placementGroupsCtrl, index) => {
            let placementsCtrl = this.getMsgPlacementsCtrl(placementGroupsCtrl);
            placementsCtrl.controls.some((placementControl, placeIndex) => {
                let placement = placementControl.value as PlacementEditModel;
                if (placement.MessagePlacement.TempMessageId === mainMessage.TempMessageId) {
                    placementsCtrl.removeAt(placeIndex);
                    // Should only be one placement per message, break out.
                    return true;
                }
                // tell the 'some' loop to keep going.
                return false;
            });

            if (placementsCtrl.controls.length === 0) {
                placementGroupsToRemove.push(index);
            }
        });

        for (let i = placementGroupsToRemove.length - 1; i >= 0; i--) {
            this.placementsFrmArr.removeAt(placementGroupsToRemove[i]);
        }

        if (this.useMessagePattern) {
            this.reSortPlacementAndCalculateHolderQuantity();
        }

        if (this.selectedMessagePattern === "priorityFill") {
            const currentPatternGroups: PromotionMessagePatternGroupModel[] = this.promotionHelperService.getCurrentPatternGroupsFromForm(this.parent);
            const patternGroup: PromotionMessagePatternGroupModel = currentPatternGroups[0];
            this.calculateAllPriorityFillPlacementQuantities(patternGroup.MessagePatterns);
        }

    }

    public onMessageNameChanged(messageInfo: MessageNameChangeModel) {
        this.placementsFrmArr.controls.forEach((placementGroupCtrl) => {
            let placements = this.getMsgPlacementsCtrl(placementGroupCtrl);
            placements.controls.some((placementControl: FormGroup, placeIndex) => {
                let placement = placementControl.value as PlacementEditModel;
                if (placement.MessagePlacement.TempMessageId === messageInfo.TempMessageId) {
                    let ctrl = this.getMessagePlacementCtrl(placementControl);
                    ctrl.patchValue({
                        PromotionMessageName: messageInfo.MessageName
                    });

                    let sizeCtrl = this.getMessagePlacementSizesCtrl(placementControl);
                    if (sizeCtrl && sizeCtrl.controls) {
                        sizeCtrl.controls.forEach(ct => {
                            ct.patchValue({
                                PromotionMessageName: messageInfo.MessageName
                            });
                        });
                    }
                    // Should only be one placement per message, break out.
                    return true;
                }
                // tell the 'some' loop to keep going.
                return false;
            });
        });
    }

    public removePlacement(index: number) {
        this.placementsFrmArr.removeAt(index);
        this.placementsFrmArr.markAsDirty();
    }

    public showLocationBalanceModal(placementCtrl: FormGroup, placementGroupCtrl: FormGroup) {
        let msgPlacement = placementCtrl.value as PromotionMessagePlacementModel;

        if (msgPlacement.LocationAssignments && msgPlacement.LocationAssignments.length) {
            this.ipsModal.displayTemplateScrollable(SearchModalComponent,
                {
                    resolve: { search: "listbylocation", listOfLocationIds: msgPlacement.LocationAssignments }
                },
                {
                    windowClass: "no-list-group-item-interaction"
                });
        }
        else if (msgPlacement.LocationCount === 0) {
            this.ipsModal.displayTemplateScrollable(SearchModalComponent,
                {
                    resolve: { search: "listbylocation", listOfLocationIds: <number[]>[] }
                },
                {
                    windowClass: "no-list-group-item-interaction"
                });
        } else {
            let balanceCalcModel = this.deriveMessagePlacementCalculationModel(placementCtrl, true, placementGroupCtrl);

            let locationListRequest: PlacementLocationBalanceListRequestModel = {
                MessagePlacementCalculationDetail: balanceCalcModel,
                TargetMessageTempId: msgPlacement.TempMessageId,
                HolderId: msgPlacement.HolderId,
                Width: msgPlacement.Width,
                Height: msgPlacement.Height,
                HolderversionInfoName: msgPlacement.HolderVersionInfoName
            };

            this.promotionMessageService.calculatePlacementLocationBalanceList(locationListRequest).then(locationIdList => {
                this.ipsModal.displayTemplateScrollable(SearchModalComponent,
                    {
                        resolve: { search: "listbylocation", listOfLocationIds: locationIdList.map(Number) }
                    },
                    {
                        windowClass: "no-list-group-item-interaction"
                    });
            });
        }
    }

    /**
     * Call this method to recalculate quantities for all placements, pattern or non-pattern.
     * @param tempMessageId - If null or undefined, must be a MultiMessage pattern. Otherwise patternGroup for specified TempMessageId will be used
     * and only placements for that tempMessageId will be used.
     * @param holderIds - Limit the placements recalculated to this list of holder ids. Used when adding new placements.
     */
    private calculateAllPlacementQuantities(tempMessageId: number, holderIds?: number[]) {
        let patternGroup: PromotionMessagePatternGroupModel;
        if (tempMessageId === undefined || tempMessageId === null) {
            let patternGroups = this.promotionHelperService.getCurrentPatternGroupsFromForm(this.parent);
            patternGroup = patternGroups[0];
            let patternGroupType = patternGroup.PatternGroupType;
            if (patternGroupType === "SingleMessage") {
                throw new Error("tempMessageId must not be undefined when working with nonPatterns");
            }
        } else {
            patternGroup = this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, tempMessageId);
        }

        patternGroup.StartDate = this.parent.value.StartDate;
        patternGroup.EndDate = this.parent.value.EndDate;

        // message placements / placement groups
        const balanceCalcModel = this.GetPlacementModelForMarketCalc(patternGroup, holderIds);
        balanceCalcModel.PatternGroupInfo = patternGroup;
        balanceCalcModel.ReturnLocationBalance = false;

        if (this.selectedMessagePattern === "priorityFill") {
            if (balanceCalcModel.PatternGroupInfo) {
                balanceCalcModel.PatternGroupInfo.Markets = balanceCalcModel.PatternGroupInfo.MessagePatterns[0].Markets;

                balanceCalcModel.PatternGroupInfoForPriorityFill = [];
                balanceCalcModel.PatternGroupInfoForPriorityFill.push(balanceCalcModel.PatternGroupInfo);
            }

            this.promotionMessageService.calculatePriorityFillLocationBalance(balanceCalcModel).then((response: MessagePlacementCalculationResultModel) => {
                if (response !== null) {
                    this.UpdatePlacementsAfterCalculation(response.MessagePlacements, patternGroup);
                }
            });
        }
        else {
            // Call server to get new totals
            this.promotionMessageService.calculateLocationBalance(balanceCalcModel).then((response: MessagePlacementCalculationResultModel) => {
                if (response !== null) {
                    this.UpdatePlacementsAfterCalculation(response.MessagePlacements, patternGroup);
                }
            });
        }
    }

    private calculatePlacementQuantitiesByPlacementGroup(plcGroup: PlacementGroupUIModel) {
        plcGroup.MessagePlacements.forEach(x => {
            this.calculateAllPlacementQuantities(x.MessagePlacement.TempMessageId, [plcGroup.HolderId]);
        });
    }

    private calculateAllPriorityFillPlacementQuantities(messageData: PromotionMessagePatternModel[], holderIds?: number[]) {

        let patternGroup: PromotionMessagePatternGroupModel;
        let patternGroupData: PromotionMessagePatternGroupModel;
        for (let i = 0; i < messageData.length; i++) {
            let tempMessageId = messageData[i].TempMessageId;
            if (!patternGroup) {
                patternGroup = this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, tempMessageId);
            }
        }

        const balanceCalcModel = this.GetPlacementModelForMarketCalc(patternGroup, holderIds);

        patternGroup.StartDate = this.parent.value.StartDate;
        patternGroup.EndDate = this.parent.value.EndDate;

        balanceCalcModel.PatternGroupInfo = patternGroup;

        if (balanceCalcModel.PatternGroupInfo) {
            balanceCalcModel.PatternGroupInfo.Markets = balanceCalcModel.PatternGroupInfo.MessagePatterns[0].Markets;

            //Added PR 8873 "Unhide Priority Fill"
            balanceCalcModel.PatternGroupInfoForPriorityFill = [];
            balanceCalcModel.PatternGroupInfoForPriorityFill.push(balanceCalcModel.PatternGroupInfo);
        }
        balanceCalcModel.ReturnLocationBalance = false;
        // Call server to get new totals
        this.promotionMessageService.calculatePriorityFillLocationBalance(balanceCalcModel).then((response: MessagePlacementCalculationResultModel) => {
            if (response !== null) {
                this.UpdatePlacementsAfterCalculation(response.MessagePlacements, patternGroupData);
            }
        });
    }

    /**
      * Call this to recalculate for all placements in a single placementGroup. This only works when the patternGroup is multiMessage patternGroup and all placements
      * below the placementGroup are using the same markets
      * @param placementGroupCtrl
      */
    private calculatePlacementGroupQuantities(placementGroupCtrl: FormGroup) {
        const plcGroupVal = placementGroupCtrl.value;
        if (plcGroupVal.PatternGroupType === "SingleMessage") {
            this.calculatePlacementQuantitiesByPlacementGroup(plcGroupVal);
        } else {
            this.calculatePlacementQuantities(placementGroupCtrl);
        }

    }

    /**
     * Calls the DB to calculate HolderCount, FinalQuantity, and LocationCount
     * @param messagePlacement - only passed in by togglePrintMessage() The Message Placement control has messagePlacementSizes. It's loaded property is used to hide the no holder message
     * @param placementCtrl - Placement control is used only to check if FulfillmentOperand/QuantityIncrease has a value
     * @param placementGroupCtrl - Placement Group control that contains all the placement info.
     */
    private calculatePlacementQuantities(placementGroupCtrl: FormGroup, placementCtrl?: FormGroup, messagePlacement?: FormGroup) {

        // only recalc if we have a valid value
        let msgPlacement;
        if (this.useMessagePattern) {
            //message pattern - only placementGroup is passed in
            msgPlacement = placementGroupCtrl.value;
        } else {
            msgPlacement = placementCtrl.value;
        }

        if ((msgPlacement.FulfillmentOperand !== null &&
            msgPlacement.FulfillmentOperand >= 0) &&
            (msgPlacement.QuantityIncrease !== null &&
                msgPlacement.QuantityIncrease >= 0)) {

            let balanceCalcModel =
                this.deriveMessagePlacementCalculationModel(placementCtrl, false, placementGroupCtrl);

            //set Message Placement loaded to false so that the no holder message won't shown
            if (messagePlacement) {
                messagePlacement.patchValue({ loaded: false });
            }
            if (this.selectedMessagePattern === "priorityFill") {
                this.promotionMessageService.calculatePriorityFillLocationBalance(balanceCalcModel).then(
                    (response: MessagePlacementCalculationResultModel) => {
                        if (response !== null) {
                            this.updatePlacementQuantities(response.MessagePlacements, placementGroupCtrl);
                            //set Message Placement loaded to true after it's loaded
                            if (messagePlacement) {
                                messagePlacement.patchValue({ loaded: true });
                            }
                        }

                    });
            } else {
                this.promotionMessageService.calculateLocationBalance(balanceCalcModel).then(
                    (response: MessagePlacementCalculationResultModel) => {
                        if (response !== null) {
                            this.updatePlacementQuantities(response.MessagePlacements, placementGroupCtrl);
                            //set Message Placement loaded to true after the it's loaded
                            if (messagePlacement) {
                                messagePlacement.patchValue({ loaded: true });
                            }
                        }

                    });
            }
        }
    }


    public UpdatePostionLocationCountsForMarketCalc(): Promise<void> {
        if (this.useMessagePattern && this.placementsFrmArr.controls.length > 0) {
            // Reload position location counts
            return this.loadPositionLocationCountsOptionsAndReCalculatePlacement(
                this.placementsFrmArr.controls as FormGroup[],
                false);
        } else {
            return Promise.resolve();
        }
    }

    public GetPlacementModelForMarketCalc(patternGroup: PromotionMessagePatternGroupModel, holderIds?: number[]): MessagePlacementCalculationModel {

        let result = <MessagePlacementCalculationModel>{
            MessagePlacements: [],
            PlacementGroups: [],
            ReturnLocationBalance: false,
            PatternGroupInfo: undefined,
            PlacementElements: undefined,
            DefaultFulfillmentOperator: this.campaignFulfillmentType,
            DefaultFulfillmentQuantity: this.campaignFulfillmentQty
        };

        const tempMsgIds = patternGroup.MessagePatterns.map(msg => msg.TempMessageId);
        // Foreach placementGroup, find the messages that match the patternGroup messageid
        if (this.placementsFrmArr) {
            this.placementsFrmArr.controls.forEach((placementGroupCtrl) => {
                let calcPlaceGroup: PlacementGroupUIModel = Object.assign({}, placementGroupCtrl.value);

                const placements = calcPlaceGroup.MessagePlacements.filter(plc => tempMsgIds.some(tmp => tmp === plc.MessagePlacement.TempMessageId &&
                    (!holderIds || holderIds.some(h => h === plc.HolderId))));
                if (placements && placements.length > 0) {
                    calcPlaceGroup.MessagePlacements = undefined;
                    calcPlaceGroup.PositionLocationCountOptions = undefined;
                    calcPlaceGroup.PositionLimit = calcPlaceGroup.SelectedPosition;
                    calcPlaceGroup.SelectedPosition = undefined;
                    calcPlaceGroup.SelectedPatternType = undefined;

                    result.PlacementGroups.push(calcPlaceGroup);
                    result.MessagePlacements = result.MessagePlacements.concat(placements);
                }
            });
        }

        return result;
    }

    public UpdatePlacementsAfterCalculation(messagePlacements: PlacementModel[], patternGroup: PromotionMessagePatternGroupModel) {
        this.placementsFrmArr.controls.forEach((placementGroupCtrl: FormGroup) => {
            this.updatePlacementQuantities(messagePlacements, placementGroupCtrl);
        });
    }

    /**
     * Call this function whenever there are calculation results that need to be updated on the screen
     * holder qantity.
     * @param placements - List of placements returned by calculation model
     * @param placementGroupCtrl - placementGroup to update.
     */
    public updatePlacementQuantities(placements: PlacementModel[], placementGroupCtrl: FormGroup) {
        let placementsCtrl = this.getMsgPlacementsCtrl(placementGroupCtrl);
        let placementGroup = placementGroupCtrl.value as PlacementGroupUIModel;
        let finalQty = 0;
        let posQty = 0;

        placements.forEach((calculatedPlace) => {
            if (calculatedPlace.HolderId === placementGroup.HolderId) {
                // Find the placement in this group
                let placementCtrl = placementsCtrl.controls.find(plc => plc.value.MessagePlacement.TempMessageId ===
                    calculatedPlace.MessagePlacement.TempMessageId);

                // find the placement control by size
                let controls = this.getMessagePlacementSizesCtrl(placementCtrl).controls;
                let foundPlacementCtrl = controls.find((plcCtrl) =>
                    (<PromotionMessagePlacementModel>plcCtrl.value).TempMessageId === calculatedPlace.MessagePlacement.TempMessageId &&
                    (<PromotionMessagePlacementModel>plcCtrl.value).Width === calculatedPlace.MessagePlacement.Width &&
                    (<PromotionMessagePlacementModel>plcCtrl.value).Height === calculatedPlace.MessagePlacement.Height &&
                    // NOTE: double equal compare on purpose. when name comes back null from backend, the triple equal wasn't working here, was considered different, and then
                    // we were showing an empty placement with no sizes since it happened to be the null weight record.
                    (<PromotionMessagePlacementModel>plcCtrl.value).HolderVersionInfoName == calculatedPlace.MessagePlacement.HolderVersionInfoName // tslint:disable-line
                ) as FormGroup;


                if (foundPlacementCtrl) {
                    foundPlacementCtrl.patchValue({
                        HolderCount
                            : calculatedPlace.MessagePlacement.HolderCount,
                        FinalQuantity: calculatedPlace.MessagePlacement.FinalQuantity,
                        LocationCount: calculatedPlace.MessagePlacement.LocationCount,
                        //LocationAssignments: calculatedPlace.MessagePlacement.LocationAssignments
                    });
                    if (this.selectedMessagePattern === "priorityFill") foundPlacementCtrl.setControl("LocationAssignments", this.fb.array(calculatedPlace.MessagePlacement.LocationAssignments));
                } else {

                    //Lets add the new size
                    let existingMsgPlacement = placementCtrl.value.MessagePlacementSizes[0] as PromotionMessagePlacementModel;
                    let newMsgPlacement = <PromotionMessagePlacementUIModel>this.createNewPromotionMessagePlacementModel(existingMsgPlacement.HolderId, existingMsgPlacement.PromotionMessageName, existingMsgPlacement.PromotionMessageId, existingMsgPlacement.TempMessageId);

                    Object.assign(newMsgPlacement, calculatedPlace.MessagePlacement);
                    newMsgPlacement.Show = true;
                    newMsgPlacement.HolderVersionInfoName = calculatedPlace.MessagePlacement.HolderVersionInfoName;

                    let plcGrpCtrl = this.fb.group({ ...newMsgPlacement, LocationAssignments: this.fb.array(newMsgPlacement.LocationAssignments) });
                    if (!this.useMessagePattern) {
                        this.setCtrlValidationAndSubscription(plcGrpCtrl, placementGroupCtrl);
                    } else {
                        plcGrpCtrl.get("CustomerItemCode").setValidators([Validators.maxLength(50)]);
                    }

                    this.addLisentnerToPrintSize(plcGrpCtrl);

                    this.getMessagePlacementSizesCtrl(placementCtrl).push(plcGrpCtrl);
                }

                //Lets clear out the sizes that no longer exist
                let messagePlacementSizes = (placementCtrl.get("MessagePlacementSizes") as FormArray);
                if (this.selectedMessagePattern !== "priorityFill") {
                    for (let i = messagePlacementSizes.controls.length - 1; i >= 0; i--) {

                        //Make sure to leave the null size row
                        if (messagePlacementSizes.controls[i].get("Height") === null && messagePlacementSizes.controls[i].get("Width") === null) {
                            continue;
                        }

                        if (this.checkIfExists(placements, messagePlacementSizes.controls[i] as FormGroup)) {
                            messagePlacementSizes.removeAt(i);
                        }
                    }
                }

                finalQty += calculatedPlace.MessagePlacement.FinalQuantity;
                posQty += calculatedPlace.MessagePlacement.HolderCount;
                //placementCtrl.patchValue({ "HolderVersionInfoName": calculatedPlace.HolderVersionInfoName };
            }
        });

        //update PositionQTY and FinalQTY for pattern
        if (this.useMessagePattern) {
            placementGroupCtrl.patchValue({
                "PositionQty": posQty,
                "FinalQuantity": finalQty
            });
        }
    }

    private setCtrlValidationAndSubscription(placementCtrl: FormGroup, placementGroupCtrl: FormGroup) {
        placementCtrl.get("QuantityIncrease").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        placementCtrl.get("FulfillmentOperand").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        placementCtrl.get("CustomerItemCode").setValidators([Validators.maxLength(50)]);

        placementCtrl.get("QuantityIncrease").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
            this.qtyIncreaseChange(placementCtrl, placementGroupCtrl);
        });

        placementCtrl.get("FulfillmentOperand").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(() => {
            this.fulfillmentOperandChanged(placementCtrl, placementGroupCtrl);
        });
    }

    private checkIfExists(placements: PlacementModel[], placementSize: FormGroup): boolean {

        return !placements.find(item =>
            item.MessagePlacement.TempMessageId === placementSize.get("TempMessageId").value &&
            item.MessagePlacement.Height === placementSize.get("Height").value &&
            item.MessagePlacement.Width === placementSize.get("Width").value &&
            placementSize.get("HolderVersionInfoName") ? item.MessagePlacement.HolderVersionInfoName === placementSize.get("HolderVersionInfoName").value : true);
    }

    /**
     * Will return a single placementGroup for the given placement. Use when updating just one placement row.
     * @param placementCtrl - control that has placement detail
     * @param forLocationBalance - true to calculatie location balance
     * @param placementGroupCtrl - control that has a list of MessagePlacements
     */
    private deriveMessagePlacementCalculationModel(placementCtrl: FormGroup,
        forLocationBalance: boolean = false,
        placementGroupCtrl: FormGroup): MessagePlacementCalculationModel {
        let ret = <MessagePlacementCalculationModel>{};

        let placementsForCalc = [] as PlacementEditModel[];
        let placementGroupData = [];
        let calcPlacementGroup: PlacementGroupUIModel;

        calcPlacementGroup = Object.assign({}, placementGroupCtrl.value);

        // assmble MessagePlacements for non-pattern message
        // UI stored the placement details in MessagePlacementSizes under the first item in MessagePlacements
        // recreate MessagePlacements by MessagePlacementSizes

        if (calcPlacementGroup.PatternGroupType === "SingleMessage") {
            let msgPlacement =
                calcPlacementGroup.MessagePlacements.find(mp => mp.MessagePlacement.TempMessageId ===
                    placementCtrl.value.TempMessageId);
            msgPlacement.MessagePlacementSizes.forEach(mp => {
                let plcModel = Object.assign({}, calcPlacementGroup.MessagePlacements[0]);
                plcModel.MessagePlacement = mp;
                placementsForCalc.push(plcModel);
            });
        } else if (calcPlacementGroup.PatternGroupType === "MultiMessage") {
            // assmble MessagePlacements for pattern message
            // UI stored the placement details in MessagePlacementSizes under the first item in MessagePlacements
            calcPlacementGroup.MessagePlacements.forEach(mp => {
                //the placement detail are the same for all sizes, so only send in the first one for calculation
                //set useAllSizes to true
                let msgPlacement = mp.MessagePlacementSizes.find(mps => mps.Width === undefined);
                if (!msgPlacement) {
                    msgPlacement = mp.MessagePlacementSizes[0];
                }
                if (msgPlacement) {
                    mp.MessagePlacement = msgPlacement;
                }
                mp.MessagePlacement.UseAllSizes = true;

            });
            placementsForCalc = calcPlacementGroup.MessagePlacements;
        } else if (calcPlacementGroup.PatternGroupType === "PriorityMessage") {
            const targetTempMessageId = placementCtrl.value.TempMessageId;
            const msgPlacement = calcPlacementGroup.MessagePlacements.find(mp => mp.MessagePlacement.TempMessageId === targetTempMessageId);
            const initialPlacement = calcPlacementGroup.MessagePlacements[0];
            msgPlacement.MessagePlacementSizes.forEach(mp => {
                let plcModel = Object.assign({}, initialPlacement);
                plcModel.MessagePlacement = mp;
                placementsForCalc.push(plcModel);
            });
            let otherPlacements = calcPlacementGroup.MessagePlacements.filter(mp => mp.MessagePlacement.TempMessageId !== targetTempMessageId);
            otherPlacements.forEach(otherPlacement => {
                otherPlacement.MessagePlacementSizes.forEach(mps => {
                    let plcModel = Object.assign({}, otherPlacement);
                    plcModel.MessagePlacement = mps;
                    placementsForCalc.push(plcModel);
                });
            });
        }

        calcPlacementGroup.MessagePlacements = undefined;  // Clear these out so we don't send over the wire.
        calcPlacementGroup.PositionLimit = calcPlacementGroup.SelectedPosition; // The gui doesn't set PositionLimit, just selectedPosition limit
        placementGroupData.push(calcPlacementGroup);

        let targetedPatternGroup = this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, placementsForCalc[0].MessagePlacement.TempMessageId);
        if (calcPlacementGroup.PatternGroupType === "PriorityMessage") {
            let targetMessageGroup = targetedPatternGroup.MessagePatterns.find(n => n.TempMessageId === placementsForCalc[0].MessagePlacement.TempMessageId);
            if (targetMessageGroup) {
                targetedPatternGroup.Markets = Object.assign([], targetMessageGroup.Markets);
            }
        }
        ret = <MessagePlacementCalculationModel>{
            PromotionMessage: undefined,
            MessagePlacements: placementsForCalc,
            ReturnLocationBalance: forLocationBalance,
            PatternGroupInfo: targetedPatternGroup,
            PatternGroupInfoForPriorityFill : [],
            PlacementGroups: placementGroupData,
            DefaultFulfillmentOperator: this.campaignFulfillmentType,
            DefaultFulfillmentQuantity: this.campaignFulfillmentQty
        };
        ret.PatternGroupInfo.StartDate = this.parent.value.StartDate;
        ret.PatternGroupInfo.EndDate = this.parent.value.EndDate;

        return ret;
    }

    public getCurrentPlacements(): PlacementEditModel[] {
        let result = [] as PlacementEditModel[];
        let placementGroups = this.placementsFrmArr.controls.map(plc => plc.value);
        placementGroups.forEach((placementGroup) => {
            result = result.concat(placementGroup.MessagePlacements);
        });
        return result;
    }

    public prepareSaveModel(promoSave: PromotionSaveModel) {

        if (this.placementsFrmArr === null) {
            return;
        }

        let uiPlacementGroups = this.placementsFrmArr.value as PlacementGroupUIModel[];

        promoSave.PatternGroups.forEach((patternGroupModel) => {

            //PlacementGroups
            patternGroupModel.PlacementGroups = [];
            let businessIdentity = promoSave.BusinessIdentity;
            uiPlacementGroups.forEach((uiPlacementGroup) => {
                if (uiPlacementGroup.PatternGroupType === "MultiMessage") {
                    let placementGrp = Object.assign({}, uiPlacementGroup) as PromotionMessagePlacementGroupModel;

                    //Remove unneeded data
                    delete placementGrp["DisplayName"];
                    delete placementGrp["MessagePlacements"];
                    delete placementGrp["PositionLocationCountOptions"];
                    patternGroupModel.PlacementGroups.push(placementGrp);
                } else {
                    // Can't use the parent placementGroup, must make one for each message that belongs in this patternGroup
                    uiPlacementGroup.MessagePlacements.forEach((messagePlacement) => {
                        if (patternGroupModel.MessagePatterns.some(msgPattern => !!(<PlacementEditUIModel>messagePlacement).MessagePlacementSizes.find(item => item.TempMessageId === msgPattern.TempMessageId))) {
                            let plcGrpModel = {
                                Id: messagePlacement.PromotionMessagePlacementGroupId,
                                HolderId: messagePlacement.HolderId,
                                PatternTypeEnum: uiPlacementGroup.PatternTypeEnum,
                                PositionLimit: uiPlacementGroup.PositionLimit,
                                PromotionMessagePatternGroupId: patternGroupModel.Id,
                                BusinessIdentity: promoSave.BusinessIdentity,
                                PrintMessage: messagePlacement.PrintMessage
                            } as PromotionMessagePlacementGroupModel;

                            patternGroupModel.PlacementGroups.push(plcGrpModel);
                        }
                    });
                }

            });

            //MessagePatterns => MessagePlacements
            patternGroupModel.MessagePatterns.forEach((mp) => {
                let promotionMessagePlacementSaveModel: PromotionMessagePlacementSaveModel[] = [];
                let tempId = mp.TempMessageId;
                if (!tempId) {
                    // Skip this empty message
                    return;
                }
                uiPlacementGroups.filter((placmentGroup) => placmentGroup.MessagePlacements.filter((placement) => placement.MessagePlacement.TempMessageId === tempId)).map(fixture => {
                    let newFixture = Object.assign({}, fixture);

                    let newPlacement: PlacementEditUIModel;
                    for (let i = 0; i < newFixture.MessagePlacements.length; i++) {
                        let placement = <PlacementEditUIModel>newFixture.MessagePlacements[i];

                        let sizes = placement.MessagePlacementSizes.filter(item => item.TempMessageId === tempId);
                        if (sizes) {
                            if (sizes.find(item => !this.useMessagePattern || item.Show) || (sizes.length === 1 && sizes[0].UseAllSizes)) {
                                newPlacement = placement;
                                break;

                            }
                        }
                    }

                    if (newPlacement) {
                        //Add in sizes to save model
                        newPlacement.MessagePlacementSizes.forEach(item => {
                            let newMsgPlacement = Object.assign({}, item as PromotionMessagePlacementSaveModel);
                            newMsgPlacement.HolderId = newPlacement.HolderId;
                            if (!newMsgPlacement.BusinessIdentity) {
                                newMsgPlacement.BusinessIdentity = businessIdentity;
                            }
                            newMsgPlacement.PromotionMessagePlacementGroupId = newPlacement.PromotionMessagePlacementGroupId;
                            promotionMessagePlacementSaveModel.push(newMsgPlacement as PromotionMessagePlacementSaveModel);
                        });
                    }

                    return null;
                });

                mp.MessagePlacements = promotionMessagePlacementSaveModel;

            });
        });
    }

    /**
     * Removes all existing placements
     */
    public clearPlacements() {
        let placementGroups = this.placementsFrmArr;
        if (placementGroups && placementGroups.length > 0) {
            while (placementGroups.length > 0) {
                placementGroups.removeAt(0);
            }
        }
    }

    public patternQtyIncreaseTypeClick(placementGroupCtrl: FormGroup): void {

        placementGroupCtrl.patchValue({
            QuantityIncreaseTarget: placementGroupCtrl.value.QuantityIncreaseTarget === "Holder" ? "Location" : "Holder"
        });

        (placementGroupCtrl.get("MessagePlacements") as FormArray).controls.forEach(placementItem => {
            this.getMessagePlacementSizesCtrl(placementItem).controls.forEach(sizeItem => {
                sizeItem.get("QuantityIncreaseTarget").setValue(placementGroupCtrl.get("QuantityIncreaseTarget").value);
            });
        });

        placementGroupCtrl.markAsDirty();

        this.calculatePlacementQuantities(placementGroupCtrl);
    }

    public patternFulfillmentOperatorClick(placementGroupCtrl: FormGroup): void {
        placementGroupCtrl.patchValue({
            FulfillmentOperator: placementGroupCtrl.value.FulfillmentOperator === "Percent" ? "Number" : "Percent"
        });

        (placementGroupCtrl.get("MessagePlacements") as FormArray).controls.forEach(placementItem => {
            this.getMessagePlacementSizesCtrl(placementItem).controls.forEach(sizeItem => {
                sizeItem.get("FulfillmentOperator").setValue(placementGroupCtrl.get("FulfillmentOperator").value);
            });
        });

        placementGroupCtrl.markAsDirty();

        this.calculatePlacementQuantities(placementGroupCtrl);
    }

    private addLisentnerToPrintSize(placement: FormGroup) {
        let ctrl = placement.get("Selected");
        if (ctrl !== null) {
            ctrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
                this.printSizeChanged(placement, value);
            });
        }
    }

    public printMessageChanged(placement: FormGroup, printMessage: boolean) {
        let placementGroup = placement.parent.parent as FormGroup;
        let messagePlacementSizes = (placement.get("MessagePlacementSizes") as FormArray);
        if (printMessage) {
            this.calculatePlacementQuantities(placementGroup, messagePlacementSizes.controls[0] as FormGroup, placement);
        } else {
            //Clear all sizes and add one with no sizes
            let messagePlacement = <PromotionMessagePlacementUIModel>this.createNewPromotionMessagePlacementModel(
                messagePlacementSizes.value[0].HolderId,
                messagePlacementSizes.value[0].PromotionMessageName,
                messagePlacementSizes.value[0].PromotionMessageId,
                messagePlacementSizes.value[0].TempMessageId);
                messagePlacement.UseAllSizes = true; // Set to true because size will be null. balance calc needs it true to get back list of sizes using these settings.

            while (messagePlacementSizes.length > 0) {
                messagePlacementSizes.removeAt(0);
            }
            messagePlacementSizes.push(this.fb.group(messagePlacement));
        }

        placement.patchValue({ PrintMessage: printMessage });
        placement.markAsDirty();
        placementGroup.markAsDirty();
    }

    public printSizeChanged(placementSize: FormGroup, printSize: boolean) {
        let placementGroup = placementSize.parent.parent.parent.parent as FormGroup;
        if (!printSize) {
            placementSize.get("QuantityIncrease").disable();
            placementSize.get("FulfillmentOperand").disable();
            placementSize.patchValue({ FinalQuantity: 0 });
        } else {
            placementSize.get("QuantityIncrease").enable();
            placementSize.get("FulfillmentOperand").enable();
            placementSize.patchValue({ QuantityIncrease: 0 });
            placementSize.patchValue({
                QuantityIncrease: 0,
                QuantityIncreaseTarget: "Holder",
                FulfillmentOperand: this.campaignFulfillmentQty,
                FulfillmentOperator: this.campaignFulfillmentType
            });

            this.calculatePlacementQuantities(placementGroup, placementSize);
        }

        placementSize.markAsDirty();
    }

    public patternQtyIncreaseChange(placementGroup: FormGroup, value: number): void {
        if (value || value === 0) {
            this.getMsgPlacementsCtrl(placementGroup).controls.forEach(placementItem => {
                this.getMessagePlacementSizesCtrl(placementItem).controls.forEach(sizeItem => {
                    sizeItem.get("QuantityIncrease").setValue(value);
                });
            });

            this.calculatePlacementQuantities(placementGroup);
        }
    }

    public patternFulfillmentOperandChanged(placementGroup: FormGroup, value: number): void {
        if (value || value === 0) {
            this.getMsgPlacementsCtrl(placementGroup).controls.forEach(placementItem => {
                this.getMessagePlacementSizesCtrl(placementItem).controls.forEach(sizeItem => {
                    sizeItem.get("FulfillmentOperand").setValue(value);
                });
            });

            this.calculatePlacementQuantities(placementGroup);
        }
    }

    public saveCustomData(saveModel: PromotionSaveModel): Promise<any>[] {
        let promises: Promise<any>[] = [];
        this.customDataFieldContainers.forEach(container => {
            if (container.subArea === "PlacementFixture") {
                const placement = container.parent.value as PromotionMessagePlacementModel;
                for (let i = 0; i < saveModel.PatternGroups.length; i++) {
                    let savedPatternGroup = saveModel.PatternGroups[i];
                    const matchMsgPattern = savedPatternGroup.MessagePatterns.find(mp => mp.MessageName === placement.PromotionMessageName) as PromotionMessagePatternSaveModel;
                    if (matchMsgPattern && matchMsgPattern.MessagePlacements) {
                        const matchPlc = matchMsgPattern.MessagePlacements.find(plc => plc.HolderId === placement.HolderId &&
                            plc.Height === placement.Height &&
                            plc.Width === placement.Width &&
                            // NOTE: double equal compare on purpose. when name comes back null from backend, the triple equal wasn't working here, was considered different, and then
                            // we were saving an empty placement with no holderversion .
                            plc.HolderVersionInfoName == placement.HolderVersionInfoName); // tslint:disable-line
                        if (matchPlc) {
                            container.linkId = matchPlc.Id;
                            promises.push(container.save(matchPlc.Id));
                            break;
                        }
                    }
                }
            } else {
                const placementGroup = container.parent.value as PromotionMessagePlacementGroupModel;
                for (let i = 0; i < saveModel.PatternGroups.length; i++) {
                    let savedPatternGroup = saveModel.PatternGroups[i];
                    const matchPlcGrp = savedPatternGroup.PlacementGroups.find(savedPlcGrp => savedPlcGrp.HolderId === placementGroup.HolderId);
                    if (matchPlcGrp) {
                        container.linkId = matchPlcGrp.Id;
                        promises.push(container.save(matchPlcGrp.Id));
                        break;
                    }
                }
            }
        });

        return promises;
    }

    public showNoFixtureMessage(messagePlacementSizes: FormArray): boolean {
        return messagePlacementSizes.controls.length === 0 ||
            (messagePlacementSizes.controls.length === 1 && !messagePlacementSizes.controls[0].value.Show); //for fake row
    }

    public defaultCdfValuesChanged(cdfValues: CdfValueChangeEventData, placementGroupCtrl: FormGroup) {
        if (cdfValues.initialLoad) {
            placementGroupCtrl.patchValue({ defaultCdfValues: cdfValues }, { emitEvent: true });
        } else {
            //fix save of when defaults are changed after intial save.
            cdfValues.values[0].Values.forEach(function (value) {
                value.Id = 0;
            });
            placementGroupCtrl.patchValue({ defaultCdfValues: cdfValues }, { emitEvent: true });
        }
    }

    public GetPlacementGroupDefaultCdf(placementGroupCtrl: FormGroup): CustomDataWithValueModel[] {
        return placementGroupCtrl.get("defaultCdfValues").value as CustomDataWithValueModel[];
    }

}
