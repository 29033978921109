import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SearchInfo, FileDownloadService, ListSearchHelper, QueryParamItem } from "imagine-ui-ng-core";
import { CampaignService } from "../../service/campaign.service";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-campaign-search",
    templateUrl: "./campaign-search.component.html",
    styleUrls: ["./campaign-search.component.scss"]
})
export class CampaignSearchComponent implements OnInit {
    public campaign: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<any>;
    public query: string;
    public includeObsolete = false;
    public searchText: string;
    private maxRecordCount = 20; // limit the amount of data that comes back on this screen
    private totalRecords = 0;
    private querySubject: Subject<string> = new Subject<string>();

    private pluralMapping: { [k: string]: string } = {};

    private sortOptions: any[] = [
        {
            fieldLabel: "Name",
            field: "name",
            direction: "asc",
            directionLabel: "A - Z"
        },
        {
            fieldLabel: "Name",
            field: "name",
            direction: "desc",
            directionLabel: "Z - A"
        },
        {
            fieldLabel: "In-Store Date",
            field: "instoredate",
            direction: "desc",
            directionLabel: "Newest first"
        },
        {
            fieldLabel: "In-Store Date",
            field: "instoredate",
            direction: "asc",
            directionLabel: "Oldest first"
        },
        {
            fieldLabel: "Start Date",
            field: "startdate",
            direction: "desc",
            directionLabel: "Newest first"
        },
        {
            fieldLabel: "Start Date",
            field: "startdate",
            direction: "asc",
            directionLabel: "Oldest first"
        },
        {
            fieldLabel: "End Date",
            field: "ebddate",
            direction: "desc",
            directionLabel: "Newest first"
        },
        {
            fieldLabel: "End Date",
            field: "enddate",
            direction: "asc",
            directionLabel: "Oldest first"
        }
    ];

    private selectedSortOption: any = null;

    constructor(private campaignService: CampaignService, listSearchHelper: ListSearchHelper, private fileDownload: FileDownloadService) {
        this.campaign = listSearchHelper.getListSearchHelper(campaignService);
        this.busy = this.campaign.busy;

        this.pluralMapping["=1"] = "Campaign";
        this.pluralMapping["other"] = "Campaigns";

        this.selectedSortOption = this.sortOptions[0];

        this.querySubject.pipe(
            debounceTime(350),
            distinctUntilChanged())
            .subscribe((model: string) => {
                this.getListData({ search: model });
            });
    }

    ngOnInit() {
        this.campaignService.head().then((data: number) => {
            this.totalRecords = data;
        });

        this.getListData({ search: "" });
    }

    public onQueryChange(query: string) {
        this.query = query;
        this.querySubject.next(query);
    }

    public get matchingRecordCount(): number {
        return this.campaign.ResultChunkAttributes.TotalRecords || 0;
    }

    public sortOptionSelected(event: any) {
        console.log("event: ", event);
        this.selectedSortOption = event;

        this.getListData({ search: this.query });
    }

    public getListData(search: any) {
        let searchTerm = "";

        let additionalQueryParams: QueryParamItem[] = [];

        if (search !== undefined && search.search !== undefined) {
            searchTerm = search.search;
        }
        this.query = searchTerm;
        this.searchText = search.search;
        let sortOption = this.selectedSortOption || {
            field: "name",
            direction: "asc"
        };

        let searchInfo: SearchInfo = {
            searchText: searchTerm,
            recordCount: this.maxRecordCount,
            additionalQueryParams: [
                { param: "SortBy", paramValue: sortOption.field },
                { param: "SortDirection", paramValue: sortOption.direction }
            ]
        };

        if (this.includeObsolete) {
            searchInfo.additionalQueryParams.push({ param: "ShowObsoleteItems", paramValue: this.includeObsolete.toString() });
        }


        this.promise = this.campaign.searchHelper(searchInfo, undefined, "Campaign/SimpleCampaign/Search");
    }

    private fileDownloadHandler(url: string) {
        this.fileDownload.Download(url);
    }

    public getObsoleteData() {
        this.includeObsolete = !this.includeObsolete;
        this.getListData({search: this.searchText});
    }
}
