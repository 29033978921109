<div class="d-flex justify-content-between align-items-center px-3">
    <h2 class="title" translate>MY_CART</h2>
    <a uiSref="main.myproducts" class="text-underline text-hover-primary" translate>CONTINUE_SHOPPING</a>
</div>
<div class="card mx-3 mb-3 ips-shadow">
    <div class="card-header view-info-header d-flex">
        <span class="pr-3" translate>SUMMARY</span>
    </div>
    <div class="card-body" *ngIf="screenLoaded && !busy">
        <div *ngIf="CurrentCart.HasInvalidItems" class="form-row text-danger border border-danger mb-3">
            <div class="col-11 mx-3 mb-3 pr-2 pt-2 pl-2 pb-2">
            Please review the items below in your cart that have either been deleted have reached their end date. These items need to be removed before continuing.
            </div>
        </div>
            <div class="form-row">
                <div class="col-12 col-md-6 col-lg-8 col-xl-9">
                    <p class="mb-2">
                        <span class="view-info-label" translate>MY_CART_QUANTITY</span>
                        <span class="ml-1">{{ CurrentCart.TotalQuantity | number }}</span>
                    </p>
                    <p class="mb-2">
                        <span class="view-info-label" translate>MY_CART_SUBTOTAL</span>
                        <span class="ml-1">{{ CurrentCart.TotalCost | currency }}</span>
                    </p>
                    <p class="mb-2">
                        <span class="view-info-label" translate>LOCATIONS</span>
                        <span class="ml-1">{{ cartLocations.length | number }}</span>
                    </p>

                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <button *ngIf="showInvoiceButton" class="btn btn-lg alert-success btn-block mb-3" [disabled]="CurrentCart.TotalQuantity === 0 || CurrentCart.HasInvalidItems"
                            (click)="checkout('Invoice')">
                        {{ checkoutButtonLabel }}
                    </button>
                    <button *ngIf="showCreditButton" class="btn btn-lg alert-success btn-block mb-3" [disabled]="CurrentCart.TotalQuantity === 0 || CurrentCart.HasInvalidItems"
                            (click)="checkout('Credit')" translate>
                        CHECKOUT_BY_CREDIT_CARD
                    </button>
                    <button *ngIf="!showInvoiceButton && !showCreditButton" class="btn btn-lg alert-success btn-block mb-3" [disabled]="CurrentCart.TotalQuantity === 0 || CurrentCart.HasInvalidItems"
                            (click)="checkout('Both')" translate>
                        CHECKOUT
                    </button>
                    <button class="btn btn-lg btn-default btn-block" [disabled]="CurrentCart.TotalQuantity === 0"
                            (click)="clearCart()" translate>
                        CLEAR_CART
                    </button>
                </div>
            </div>
        </div>
    </div>

<div class="col" ipsFocus="searchInput" *ngIf="cartLocations?.length">
    <ips-list-search searchLabel="{{'ITEM' | translate }}"
                     searchLabelPlural="{{'MY_PRODUCTS' | translate }}"
                     labelLoading="{{'ACCESSING_MY_CART' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_MY_CART' | translate }}"
                     searchItemClass="shadowed-search-item"
                     [listData]="cartItemHelper"
                     (onListDataChange)="getListData($event.search)"
                     [section]="'main.mycart'"
                     [scroller]=true
                     [quickRef]=false
                     [promise]="promise"
                     [autoFocus]=true
                     [query]="searchText"
                     *ngIf="screenLoaded">
        <ng-template #searchHeaderTemplate>
            <div class="ips-ls-search-module-imgn">
                <div class="row">
                    <div class="col-12 col-xl-9 form-group">
                        <label class="labels" for="businessCustomer" translate>SHOWING_CART_FOR_LOCATION</label>
                        <button id="businessCustomer" (click)="chooseLocation()"
                                class="btn btn-lg btn-block ellipsis btn-default text-left">
                            {{ selectedLocation.Value }}
                        </button>
                        <input type="hidden" name="customerExternalId" />
                    </div>
                    <div class="col-12 col-xl-3 form-group">
                        <label class="labels d-none d-xl-inline-block">&nbsp;</label>
                        <button class="btn btn-lg btn-block btn-default"
                                (click)="clearCurrentLocationFromCart()" translate>
                            CLEAR_CART_FOR_LOCATION
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <div class="input-group">
                            <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                            <input type="text" class="form-control form-control-lg ips-ls-input"
                                   placeholder="{{ 'MY_CART_SEARCH_PLACEHOLDER' | translate}}"
                                   [(ngModel)]="searchText"
                                   (ngModelChange)="onQueryChange($event)" />
                        </div>
                    </div>
                </div>
                <div class="row no-gutters mt-3">
                    <div class="col-12 col-md-auto ml-3">
                        <span class="badge-pill badge-secondary font-weight-normal">{{ matchingRecordCount | number }}</span>
                        <span class="ml-1">{{ matchingRecordCount | i18nPlural: pluralMapping }} {{ 'FOUND_OF' | translate }}</span>
                    </div>
                    <div class="col-12 col-md-auto ml-3 ml-md-2 mt-1 mt-md-0">
                        <span class="badge-pill badge-secondary font-weight-normal">{{ totalRecordCount | number }}</span>
                        <span class="ml-1" translate>{{ 'TOTAL' | translate }} {{ totalRecordCount | i18nPlural: pluralMapping }}</span>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #listSearchItemTemplate let-result="result">
            <app-item-display-card [LocationItemData]="result"
                                   [LocationId]="selectedLocation.Key" DisplayMode="cart"
                                   [Highlight]="searchText"
                                   [ShowOrderReasonControl]="showOrderReason" [OrderReasons]="orderReasons"></app-item-display-card>
        </ng-template>
    </ips-list-search>
    <div *ngIf="showNoResults" class="card px-3 ips-shadow">
        <h2 translate>NO_MATCHING_PRODUCTS</h2>
    </div>
</div>

<div class="col" *ngIf="!screenLoaded">
    <div class="ips-flex-loading-box mb-4">
        <div class="content">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
            <span class="d-block" translate>ACCESSING_CART_INFORMATION</span>
        </div>
    </div>
</div>

<div *ngIf="screenLoaded && !(cartLocations?.length)" class="card px-3 mx-3 ips-shadow">
    <h2 translate>NO_PRODUCTS_IN_CART</h2>
</div>
