import { Component, OnInit, Input, Output, ViewChild, TemplateRef, EventEmitter } from "@angular/core";
import { IpsModalService, IpsModalInstance } from "imagine-ui-ng-modal";
import { SignPlanService } from "../service/sign-plan.service";

@Component({
    selector: "app-sign-plan-transform-detail",
    templateUrl: "./sign-plan-transform-detail.component.html",
    styleUrls: ["./sign-plan-transform-detail.component.scss"]
})

export class SignPlanTransformDetailComponent implements OnInit {
    @ViewChild("editTemplate") editTemplate: TemplateRef<any>;

    @Input() id = 0;
    @Input() type = "Holder";
    @Input() imageUrl = "";
    @Input() top = 0;
    @Input() left = 0;
    @Input() height?: number;
    @Input() width?: number;
    @Input() rotation = 0;

    @Output() changesAccepted = new EventEmitter<any>();

    private modalInstance: IpsModalInstance;
    private model: any;

    public loaded = false;
    public loadingContent = false;

    public previewHeight: number;
    public previewOffset: number;

    constructor(
        private modalService: IpsModalService,
        private signPlanService: SignPlanService
    ) {
    }

    get enableDiagnostics(): boolean {
        return (<any>window).enableDiagnostics;
    }

    public cancel() {
        this.close();
    }

    public edit() {
        this.loadingContent = true;
        this.modalInstance = this.modalService.displayComponentAndReturnInstance(this.editTemplate, null, { size: "lg", backdrop: "static", container: ".sign-plan-transform-container" });
        this.loadingContent = false;
    }

    //
    public close(closeMessage?: any) {
        this.modalInstance.close();
        this.initializeModel();
    }

    public acceptChanges() {
        this.modalInstance.close();
        this.changesAccepted.emit(this.model);
    }

    onDragEnd(event: any) {
        let x = event.position.x;
        let y = event.position.y;

        this.model.left = x;
        this.model.top = y;
    }

    onResizeEnd(event: any) {
        this.model.height = event.size.height;
        this.model.width = event.size.width;
    }

    public getButtonLabel(): string {
        const cropped = (!!this.top ||
            !!this.left ||
            !!this.width ||
            !!this.height);

        const rotated = Number(this.rotation) !== 0;

        if (cropped && rotated) {
            return "Cropped + Rotated";
        } else if (cropped || rotated) {
            if (cropped) {
                return "Cropped";
            } else if (rotated) {
                return "Rotated";
            }
        } else {
            return "Not Transformed";
        }
    }

    private initializeModel() {
        this.model = {
            id: this.id,
            rotation: this.rotation || 0,
            top: this.top,
            left: this.left,
            height: this.height,
            width: this.width
        };
    }

    public getRotateHeight(height: number, width: number, rotation: number): number {
        if (rotation === 90 || rotation === 270) {
            return width;
        } else {
            return height;
        }
    }

    public getPreviewTransform(model: any): string {
        console.log("get preview transform: ", model);

        const translateFactor = this.getTranslateFactor(model);
        let xVal = translateFactor;
        let yVal = translateFactor;

        if (model.rotation === 90 || model.rotation === 270) {
            yVal = 0;
            xVal = 0;
        } else {
            xVal = 0;
        }

        let returnVal = `rotate(${model.rotation}deg) translateX(${xVal}px) translateY(${yVal}px)`;

        console.log("got preview transform: ", returnVal);

        return returnVal;
    }

    public getLargestDimension(model: any): number {
        if (model.height > model.width) {
            return model.height;
        } else if (model.width > model.height) {
            return model.width;
        } else {
            return 0;
        }
    }

    private getTranslateFactor(model: any) {
        if (model.height > model.width) {
            if (model.rotation === 90 || model.rotation === 270) {
                const difference = model.height - model.width;
                let factor = Math.floor(difference / 2) * -1;

                if (model.rotation === 270) {
                    factor = factor * -1;
                }

                return factor;
            } else {
                return 0;
            }
        } else if (model.width > model.height) {
            if (model.rotation === 90 || model.rotation === 270) {
                const difference = model.width - model.height;
                let factor = Math.floor(difference / 2);

                if (model.rotation === 270) {
                    factor = factor * -1;
                }

                return factor;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    // ----- ON INIT -----
    //
    ngOnInit() {
        this.initializeModel();

        if (this.imageUrl) {
            this.loaded = true;
        } else {
            if (this.id) {
                if (this.type.toLowerCase() === "holder") {
                    this.signPlanService.getHolderPreviewImage(this.id).then((response: string) => {
                        this.imageUrl = response;
                        this.loaded = true;
                    });
                } else if (this.type.toLowerCase() === "element") {
                    this.signPlanService.getElementPreviewImage(this.id).then((response: string) => {
                        this.imageUrl = response;
                        this.loaded = true;
                    });
                } else {
                    this.loaded = true;
                }
            } else {
                this.loaded = true;
            }
        }
    }
}
