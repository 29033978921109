import { Component, OnInit, ViewChild } from "@angular/core";
import { I18nPluralPipe } from "@angular/common";
import { Location, LocationStrategy, PathLocationStrategy, SlicePipe } from "@angular/common";
import { NgForm } from "@angular/forms";
import { TargetState, StateService, StateParams, Transition } from "@uirouter/core";
import { ListSearchComponent, ComplexSearchBoxComponent, TranslatedTexts } from "imagine-ui-ng-list-search";
import { IpsModalService } from "imagine-ui-ng-modal";
import { ActiveProfileService, QueryParamItem, ListSearchHelper, SearchInfo, FileDownloadService } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { AuthService } from "imagine-ui-ng-security";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";

import { SpaceService, LocationService, LocationFixtureService, FixtureByLocationSumService, LocationModel, SpaceModel, FixtureModel, LocationFixtureModel, FixtureLocationSumModel } from "../../index";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { INVALID } from "@angular/forms/src/model";
import { AssignedFilterPipe } from "../../pipe/assigned-filter.pipe";

/**
* LocationFixtureSumModel with an original sum for tracking changes
*/
export interface FixtureLocationSumModelTracker extends FixtureLocationSumModel {
    OriginalSum: number;
}

/**
* LocationModel with a Label for display purposes
*/
interface LocationModelWithLabel extends LocationModel {
    Label: string;
}

/**
* SpaceModel with a Label for display purposes
*/
interface SpaceModelWithLabel extends SpaceModel {
    Label: string;
}

/**
* FixtureModel with a Label for display purposes
*/
interface FixtureModelWithLabel extends FixtureModel {
    Label: string;
}

@Component({
    selector: "app-fixture-search",
    templateUrl: "./fixture-search.component.html",
    styleUrls: ["./fixture-search.component.scss"],
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class FixtureSearchComponent implements OnInit {
    @ViewChild("fixtureForm") public fixtureForm: NgForm;
    @ViewChild(ComplexSearchBoxComponent) public searchBox: ComplexSearchBoxComponent;

    public pluralMapping: { [k: string]: string } = {
        "=1": "label",
        "other": "label"
    };

    private dataService: FixtureByLocationSumService;
    public fixture: ListSearchHelper;
    private space: ListSearchHelper;
    private spaceDataService: SpaceService;
    private locationDataService: LocationService;
    private location: ListSearchHelper;
    private allLabel = "ALL_UPPERCASE";
    private assignedLabel = "ASSIGNED";
    private unassignedLabel = "UNASSIGNED";

    public busy = true;
    public includeObsolete = false;
    public maxDropDownCount = 3;
    public totalLocationCount = 0;
    public totalSpaceCount = 0;
    public scroller = true;
    public quickref = false;
    public query = "";
    public selectedFilterOption = "";
    public filterOptions = "";
    public availableLocations: LocationModelWithLabel[] = [<LocationModelWithLabel>{ Id: 0, Name: " ", Label: this.allLabel }];
    public availableSpaces: SpaceModelWithLabel[] = [<SpaceModelWithLabel>{ Id: 0, Name: " ", Label: this.allLabel }];
    public availableFixtures: FixtureModel[] = [];
    public displayList: FixtureLocationSumModel[] = [];
    public chunkSize = 20;
    public numberToDisplay = this.chunkSize;
    public selectedLocation: LocationModel;
    public userSelectedLocation: LocationModel;
    public selectedSpace: SpaceModel;
    public userSelectedSpace: SpaceModel;
    public selectedFixture: FixtureModel;
    public userSelectedFixture: FixtureModel;
    public promise: Promise<any>;
    public spaceFilter: (model: SpaceModel) => boolean;
    public assignedFilter: (model: FixtureLocationSumModelTracker) => boolean;
    public translatedTexts: TranslatedTexts;
    public scrollToFixture: number;
    public translated = false;
    public totalFixtureCount = 0;
    public searchLabelPlural: string;
    public searchLabel: string;

    constructor(private $state: StateService, private translateService: TranslateService, private $location: Location, private fixtureByLocationSumService: FixtureByLocationSumService, private spaceService: SpaceService
        , private locationService: LocationService, private ipsModal: IpsModalService, private ipsMessage: IpsMessageService, private locationFixtureService: LocationFixtureService
        , private listSearchHelper: ListSearchHelper, private activeProfileService: ActiveProfileService, private authService: AuthService, private transition: Transition, private downloadService: FileDownloadService, ) {

        this.dataService = fixtureByLocationSumService;
        this.fixture = listSearchHelper.getListSearchHelper(this.dataService);

        this.spaceDataService = spaceService;
        this.space = listSearchHelper.getListSearchHelper(this.spaceDataService);

        this.locationDataService = locationService;
        this.location = listSearchHelper.getListSearchHelper(this.locationDataService);

        this.fixtureByLocationSumService.head().then((data) => {
            this.totalFixtureCount = data;
        });
    }

    ngOnInit() {

        // set chunkCount to -1 so we get the whole fixture list.
        this.dataService.chunkCount = -1;

        //translation
        this.translatedTexts = { foundOf: "found of", total: "total", loadMoreResults: "load more results" };
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());


        this.getSpaceList();
        this.getLocationList();
        this.setInitalFilters();

    }

    private translateText() {
        this.translateService.get(["FIXTURE", "FIXTURES", "FOUND_OF", "TOTAL", "LOAD_MORE_RESULTS", "ALL_UPPERCASE", "ASSIGNED", "UNASSIGNED"]).subscribe((res: [string]) => {
            this.translatedTexts.foundOf = res["FOUND_OF"];
            this.translatedTexts.total = res["TOTAL"];
            this.translatedTexts.loadMoreResults = res["LOAD_MORE_RESULTS"];
            this.allLabel = res["ALL_UPPERCASE"];
            this.assignedLabel = res["ASSIGNED"];
            this.unassignedLabel = res["UNASSIGNED"];
            this.searchLabel = res["FIXTURE"];
            this.searchLabelPlural = res["FIXTURES"];

            this.availableLocations[0].Label = this.allLabel;
            this.availableSpaces[0].Label = this.allLabel;
            this.availableFixtures = [
                <FixtureModelWithLabel>{ Id: 0, Label: this.allLabel },
                <FixtureModelWithLabel>{ Id: 1, Label: this.assignedLabel },
                <FixtureModelWithLabel>{ Id: 2, Label: this.unassignedLabel }];
            this.selectedLocation = (this.selectedLocation ? this.selectedLocation : this.availableLocations[0]);
            this.userSelectedLocation = this.selectedLocation;
            this.selectedSpace = (this.selectedSpace ? this.selectedSpace : this.availableSpaces[0]);
            this.userSelectedSpace = this.selectedSpace;
            this.selectedFixture = this.availableFixtures[0];
            this.userSelectedFixture = this.selectedFixture;

            this.pluralMapping = {
                "=1": this.searchLabel,
                "other": this.searchLabelPlural
            };

            this.translated = true;
        });
    }
    public getSpaceList() {
        this.spaceDataService.head()
            .then((response) => {
                this.totalSpaceCount = response;
                if (!this.spaceModalButtonVisible()) {
                    // Update the service chunkCount so we get back all the spaces.
                    this.spaceDataService.chunkCount = this.maxDropDownCount;
                    this.space.searchHelper({ searchText: "" })
                        .then(() => {
                            this.space.resultList.forEach(function (item: any) {
                                item.Name = item.Label;
                            });
                            this.availableSpaces = this.availableSpaces.concat(this.space.resultList);
                            if (this.availableSpaces.length === 2 && this.availableSpaces[0].Id === 0) {
                                this.removeAllItemFromList(this.availableSpaces);
                                this.selectedSpace = this.availableSpaces[0];
                                this.userSelectedSpace = this.selectedSpace;
                            }
                        });
                }
            });
    }

    public spaceModalButtonVisible() {
        return this.totalSpaceCount > this.maxDropDownCount;
    }

    private removeAllItemFromList(list: any[]) {
        if (list.length > 0 && list[0].Id === 0) {
            list.shift();
        }
    }

    public getLocationList() {
        this.locationDataService.head()
            .then((response) => {
                this.totalLocationCount = response;
                if (!this.locationModalButtonVisible()) {
                    // Update the service chunkCount so we get back all the locations.
                    this.locationDataService.chunkCount = this.maxDropDownCount;
                    this.location.searchHelper({ searchText: "" })
                        .then(() => {
                            this.location.resultList.forEach((item: any) => {
                                item.Label = `${item.LocationIdentifier} - ${item.Name} - ${item.Addresses[0].City}, ${item.Addresses[0].StateProvince}`;
                            });
                            this.availableLocations = this.availableLocations.concat(this.location.resultList);
                            if (this.availableLocations.length === 2 && this.availableLocations[0].Id === 0) {
                                this.removeAllItemFromList(this.availableLocations);
                                this.setSelectedLocation(this.availableLocations[0]);
                            }
                        });
                }
            });

    }

    public locationModalButtonVisible() {
        return this.totalLocationCount > this.maxDropDownCount;
    }

    private getInitLoc(locId: number) {
        if (isFinite(locId)) {
            return this.locationDataService.get<LocationModel>(locId).then((initLoc: LocationModelWithLabel) => {
                initLoc.Label = IpsString.Format("{0} - {1} - {2}, {3}",
                    initLoc.LocationIdentifier,
                    initLoc.Name,
                    initLoc.Addresses[0].City,
                    initLoc.Addresses[0].StateProvince);
                this.setSelectedLocation(initLoc);
            });
        } else {
            return Promise.resolve();
        }
    }

    private getInitFixture(fixtureId: number) {
        if (fixtureId && fixtureId > 0) {
            return this.dataService.get<FixtureModel>(fixtureId).then((initFixture) => {
                let initSpace = <SpaceModelWithLabel>{
                    Id: initFixture.SpaceId,
                    Name: initFixture.SpaceName,
                    Label: initFixture.SpaceName
                };
                this.scrollToFixture = initFixture.Id;
                this.selectedSpace = initSpace;
                this.userSelectedSpace = initSpace;
                this.searchBox.selectedItem2 = initSpace;
                this.userSelectedFixture = this.availableFixtures[0];
                this.selectedFixture = this.availableFixtures[0];
            });
        } else {
            return Promise.resolve();
        }
    }


    private scrollToParamVal() {
        let ele: HTMLElement;
        if (this.scrollToFixture) {
            ele = document.getElementById(this.scrollToFixture.toString());
            // trigger infinite scroll if selected fixture not in top 20
            if (this.fixture.currentChunkIndex < this.fixture.resultList.length && !ele) {
                this.getMoreListData();
            }
            if (ele) {
                setTimeout(() => { ele.scrollIntoView(true); }, 1000);
                this.scrollToFixture = undefined;
            }
        }
    }

    //Checks if either LocationId or FixtureId are passed in on the URL.
    //If so, gets their object and sets filters show their related record(s) in this controller
    private setInitalFilters() {
        let initalFixtureId = this.transition.params().fixtureId;
        let initalLocationId = this.transition.params().locationId;

        return Promise.all([this.getInitLoc(initalLocationId), this.getInitFixture(initalFixtureId)]).then(() => {
            this.busy = this.fixture.busy;
            this.getListData();
        });
    }

    private setSelectedLocation(location: LocationModel) {
        this.selectedLocation = location;
        this.userSelectedLocation = location;
        this.searchBox.selectedItem1 = location;
    }

    // Get list of fixtures.
    public getListData() {
        if (this.busy) {
            return;
        }
        if (!this.query) {
            this.query = "";
        }

        // reset number to display
        this.numberToDisplay = this.chunkSize;
        let additionalQueryParams: QueryParamItem[] = [];
        if (this.selectedLocation && this.selectedLocation.Id > 0) {
            additionalQueryParams.push(<QueryParamItem>{ param: "LocationId", paramValue: this.selectedLocation.Id.toString() });
        }

        if (this.includeObsolete) {
            additionalQueryParams.push({ param: "ShowObsoleteItems", paramValue: this.includeObsolete.toString() });
        }

        let searchInfo = <SearchInfo>{};
        searchInfo.searchText = this.query;
        searchInfo.additionalQueryParams = additionalQueryParams;

        this.promise = this.fixture.searchHelper(searchInfo).then((response) => {
            Object.assign(this.fixture, response);
            this.updateDisplayList();
        });
    }

    /**
    * Iterates over fixture.resultList getting media where fixtures have one assigned, initalizing their OriginalSum with the
    * locationFixtureSum, then setting the fixture service"s resultList to the controller"s displayList before finally setting
    * the fixtureForm to pristine
    */
    private updateDisplayList() {
        for (let i = 0; i < this.fixture.resultList.length; i++) {
            let item = this.fixture.resultList[i];
            let media = item.Media;
            if (media.ResourceUri) {
                if (media.ResourceUri.indexOf("BusinessIdentity") === -1) {
                    media.ResourceUri = IpsString.Format("{0}?BusinessIdentity={1}&idToken={2}", media
                        .ResourceUri, this.activeProfileService.businessIdentity, this.authService.getIdToken());
                }
                media.Thumbnail = media.ResourceUri.replace("Original", "Thumbnail");
            }
            // Save the originalSum so we can undo.
            item.OriginalSum = item.LocationFixtureSum;
        }
        this.displayList = this.fixture.resultList;
        this.updateFilteredSearchCount();
    }

    public imagePopover(fullSizeUrl: string) {
        this.ipsModal.imagePopOver(fullSizeUrl, "FIXTURE_ALT", "CLICK_TO_SHRINK");
    }
    /**
     * Apply work to items in fixture.resultList where their LocationFIxtureSum no longer matches their OriginalSum
     * @param workToPerform Function that accepts a single item from fixture.resultList.
     */
    private workOnChangedFixtures<P, R>(workToPerform: (item: P) => R) {
        let result;
        let workResults = [];
        for (let i = 0; i < this.fixture.resultList.length; i++) {
            let item = this.fixture.resultList[i];
            if (item.LocationFixtureSum !== item.OriginalSum) {
                result = workToPerform(item);
                if (result) {
                    workResults.push(result);
                }
            }
        }
        return workResults;
    }

    private unsavedChangesExist() {
        let changesExist = false;
        this.workOnChangedFixtures((item: FixtureLocationSumModel) => {
            changesExist = true;
            return item;
        });
        return changesExist;
    }

    private discardUnsavedChanges() {
        this.workOnChangedFixtures((fixture: FixtureLocationSumModelTracker) => {
            fixture.LocationFixtureSum = fixture.OriginalSum;
            return fixture;
        });
        //this.$scope.fixtureForm.$setPristine();
    }

    private showLocationSelectionModal() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "location" } })
            .then((response: any) => {
                this.setSelectedLocation(response.item);
                this.getListData();
                // remove the first item, which is All
                this.removeAllItemFromList(this.location.resultList);
                this.updateFilteredSearchCount();
            },
                // Rejected
                () => {
                    // remove the first item, which is All
                    this.removeAllItemFromList(this.location.resultList);
                });
    }

    private showSpaceSelectionModal() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "space" } })
            .then((response: any) => {
                this.selectedSpace = response.item;
                this.userSelectedSpace = response.item;
                this.searchBox.selectedItem2 = response.item;
                this.discardUnsavedChanges();
                // remove the first item, which is All
                this.removeAllItemFromList(this.space.resultList);
                this.updateFilteredSearchCount();
            },
                // Rejected
                () => {
                    // remove the first item, which is All
                    this.removeAllItemFromList(this.space.resultList);
                });
    }

    public updateFilteredSearchCount() {

        let filteredList = this.displayList;


        if (this.selectedSpace && this.selectedSpace.Id > 0) {
            filteredList = filteredList.filter(f => f.SpaceId === this.selectedSpace.Id);
        }

        if (this.selectedFixture.Id === 1) {
            filteredList = filteredList.filter(f => f.OriginalSum > 0);
        } else if (this.selectedFixture && this.selectedFixture.Id > 1) {
            filteredList = filteredList.filter(f => f.OriginalSum === 0);
        }

        this.fixture.ResultChunkAttributes.TotalRecords = filteredList.length;

    }

    public getMoreListData() {
        if (this.numberToDisplay + this.chunkSize < this.displayList.length) {
            this.numberToDisplay += this.chunkSize;
        } else if (this.displayList.length > 0) {
            // vm.displayList.length == 0 is probably caused by this function being called before the data
            // is loaded which causes a problem because infinite scroll won"t call again until the control size changes.
            // Since having the numberToDisplay greater than zero when vm.displayList.length == 0 isn"t a problem we"ll
            // just assume that"s the case and leave the display number the same
            this.numberToDisplay = this.displayList.length;
        }
    }

    public openPage(id: any) {
        this.$state.go("main.storeProfileFixture.view", { id: id });
    }

    // show location select modal
    public selectLocation(item: LocationModel) {
        if (this.unsavedChangesExist()) {
            if (item) {
                // Save the item passed in so we can use it after the confirmation message, item loses scope so we can"t use that.
                this.userSelectedLocation = item;
            }
            this.ipsMessage.confirm({ body: "UNSAVED_CHANGES_NAV", ok: "PROMPT_LEAVE_PAGE", cancel: "PROMPT_STAY_PAGE" }, undefined)
            .then(() => {
                //User chose to select new location and discard changes
                if (this.locationModalButtonVisible()) {
                    this.showLocationSelectionModal();
                } else {
                    this.selectedLocation = item ? item : this.userSelectedLocation;
                    this.getListData();
                }
            })
            .catch(() => {
                // User chose to keep changes and not select a different location
                this.userSelectedLocation = this.selectedLocation;
            });
        } else { //no unsaved changes exist so just set the selectedLocation
            if (this.locationModalButtonVisible()) {
                this.showLocationSelectionModal();
            } else {
                this.selectedLocation = item ? item : this.userSelectedLocation;
                this.getListData();
            }
        }
    }
    // Show space select modal
    public selectSpace(item: SpaceModel) {
        if (this.unsavedChangesExist()) {
            if (item) {
                // Save the item passed in so we can use it after the confirmation message, item loses scope so we can"t use that.
                this.userSelectedSpace = item;
            }
            this.ipsMessage.confirm({ body: "UNSAVED_CHANGES_FILTER", ok: "PROMPT_DISCARD_CHANGES", cancel: "PROMPT_KEEP_CHANGES" }, undefined)
            .then(() => {
                //User chose to select different space and discard changes
                // note: showSpaceSelectionModal will call discardUnsavedChanges() after a section is selected,
                //////	so if a user doesn"t select a different location, the changes are not lost.
                if (this.spaceModalButtonVisible()) {
                    this.showSpaceSelectionModal();
                } else {
                    this.selectedSpace = item ? item : this.userSelectedSpace;
                    this.discardUnsavedChanges();
                }
            })
            .catch(() => {
                // User chose to cancel filtering and keep the changes
                this.userSelectedSpace = this.selectedSpace;
            });
        } else { //no unsaved changes exist so just set the selectedSpace
            if (this.spaceModalButtonVisible()) {
                this.showSpaceSelectionModal();
            } else {
                this.selectedSpace = item ? item : this.userSelectedSpace;
            }
        }
    }
    public selectFixture(item: FixtureModel) {
        if (this.unsavedChangesExist()) {
            if (item) {
                // Save the item passed in so we can use it after the confirmation message, item loses scope so we can"t use that.
                this.userSelectedFixture = item;
            }
            this.ipsMessage.confirm({ body: "UNSAVED_CHANGES_FILTER", ok: "PROMPT_DISCARD_CHANGES", cancel: "PROMPT_KEEP_CHANGES" }, undefined)
            .then(() => {
                //User chose to select different fixture(assigned/unassigned/all) and discard changes
                this.selectedFixture = this.userSelectedFixture;
                this.discardUnsavedChanges();
            })
            .catch(() => {
                // User chose to keep changes and cancel filter change
                this.userSelectedFixture = this.selectedFixture;
            });
        } else { //no unsaved changes exist so just set the selectedFixture
            this.selectedFixture = item ? item : this.userSelectedFixture;
        }
    }

    public filterSearch(changes) {
        let originText = this.query;
        if (changes.list1SelectedItem) {
            this.userSelectedLocation = changes.list1SelectedItem;
            this.selectedLocation = this.userSelectedLocation;
        }
        if (changes.list2SelectedItem) {
            this.userSelectedSpace = changes.list2SelectedItem;
            this.selectedSpace = this.userSelectedSpace;
        }
        if (changes.list3SelectedItem) {
            this.userSelectedFixture = changes.list3SelectedItem;
            this.selectedFixture = this.userSelectedFixture;
        }

        if (originText !== changes.query) {
            this.query = changes.query;

            if (this.unsavedChangesExist()) {
                this.ipsMessage.confirm({ body: "UNSAVED_CHANGES_FILTER", ok: "PROMPT_DISCARD_CHANGES", cancel: "PROMPT_KEEP_CHANGES" }, undefined)
                .then(() => {
                    //User chose to filter by search and discard changes
                    this.discardUnsavedChanges();
                    this.getListData();
                })
                .catch(() => {
                    // User chose to keep changes and cancel filter change
                    this.query = originText;
                });
            } else { //no unsaved changes exist so just execute getListData
                this.getListData();
            }
        }

        this.updateFilteredSearchCount();


    }

    public savePrompt() {
        // After save, keep the page the way it was, don't change anything.
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.save(), progressMessage: "SAVING" }, undefined);
    }
    public save() {
        let locFixListToSave = this.workOnChangedFixtures((item: FixtureLocationSumModel) => {
            return this.locationFixtureService.createLocFixModel(item.LocationFixture_Id, item.Id, this.selectedLocation.Id, item.LocationFixtureSum);
        });

        return this.locationFixtureService.SaveList(locFixListToSave)
            .then((locationFixtureList: any) => {

                // If we are reloading the whole page, this isn"t needed. Otherwise set original to new value.
                this.workOnChangedFixtures((item: FixtureLocationSumModelTracker) => {
                    //If item"sLocationFixtureSum is 0, assume it was deleted and will not be in the result array
                    //If the value was greater than 0 Loop over retured LocationFixtureModels and update the item passed in if it matches based on LocationFixture.Id, or Location.Id and Fixture.Id
                    for (let i = 0; i < locationFixtureList.length; i++) {
                        let locationFixture = locationFixtureList[i];
                        if (item.LocationFixture_Id !== 0 && item.LocationFixture_Id === locationFixture.Id) {
                            item.OriginalSum = locationFixture.Count;
                            item.LocationFixtureSum = locationFixture.Count;
                        } else if (item.Id === locationFixture.FixtureId &&
                            this.selectedLocation.Id === locationFixture.LocationId) {
                            item.OriginalSum = locationFixture.Count;
                            item.LocationFixtureSum = locationFixture.Count;
                            item.LocationFixture_Id = locationFixture.Id;
                        }
                    }
                    return item;
                });
                this.fixtureForm.form.markAsPristine();
            });
    }

    public locationFixtureSumChanged(result: FixtureLocationSumModelTracker) {
        if (this.validateInput(result)) {
            if (result.LocationFixtureSum === result.OriginalSum) {
                if (!this.unsavedChangesExist()) {
                    this.fixtureForm.form.markAsPristine();
                }
            }
        }
    }

    public reportDownloadClickHandler() {
        let url = "";
        if (this.selectedLocation.Id === 0) {
            url = `${this.dataService.urlApiBaseProductPlusVersion}Fixture/Download`;
        } else {
            url = `${this.dataService.urlApiBaseProductPlusVersion}Fixture/Download/` + this.selectedLocation.Id;
        }
        this.downloadService.Download(url);
    }

    validateInput(result: FixtureLocationSumModelTracker) {
        let valid = /^\+?(0|[1-9]\d*)$/.test(result.LocationFixtureSum.toString());
        if (!valid) {
            this.fixtureForm.form.markAsDirty();
            this.fixtureForm.form.controls["fixtureSum" + result.Id].setErrors({ "invalid": true });
        } else {
            this.fixtureForm.form.controls["fixtureSum" + result.Id].setValue(parseInt(result.LocationFixtureSum.toString(), 10));
        }
        return valid;
    }

    public getObsoleteData() {
        this.includeObsolete = !this.includeObsolete;
        this.getListData();
    }
}
