import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ItemService, PunchOutService, ShopSettingsService } from "../index";
import { SearchCardUIModel } from "../../shared/index";
import { SearchModalComponent } from "../../shared/search-modal/search-modal.component";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";

import { ActiveProfileService, HelperFunctionsService, FileDownloadService } from "imagine-ui-ng-core";
import { CartItemPostData, ItemDataModel, ItemSimpleDataModel, CartService, CartDataModel, CartItemModel, OrderService } from "../index";
import { AuthService } from "imagine-ui-ng-security";
import { FileDownloadModel } from "../model/FileDownloadModel";

interface ItemCharacteristicUiModel extends SearchCardUIModel {
    IsDate: boolean;
}

@Component({
    selector: "app-item-display-card",
    templateUrl: "./item-display-card.component.html",
    styleUrls: ["./item-display-card.component.scss"]
})
export class ItemDisplayCardComponent implements OnInit {
    // ----- DATA -----
    // Imports
    @Input() ItemData: ItemDataModel;
    @Input() LocationItemData: CartItemModel;
    @Input() LocationId: number;
    @Input() Highlight: string;
    @Input() DisplayMode: string;
    @Input() ShowOrderReasonControl: boolean;
    @Input() OrderReasons: string[];

    @Output() punchOut = new EventEmitter<void>();

    // Static
    TranslatedTexts: any;
    TextToTranslate = [
        "ADDITIONAL_POP",
        "PROFILE_CHANGE",
        "STORE_DISCARD",
        "WEATHER_DAMAGE_BY_SHIPPER",
        "REPLACEMENT_KIT_NOT_DELIVERED",
        "BOARD_TOUR_EXEC_VISIT",
        "REFRESH_REPLACEMENT_INV",
        "REMODEL_STORE",
        "ELEMENT_MISSING",
        "WRONG_SIZE_ELEMENT",
        "MONTHLY_KIT_NOT_RECEIVED",
        "FAULTY_PACKAGING",
        "KIT_NOT_RECEIVED",
        "NEW_STORE_ORDER",
        "GRAND_OPENING",
        "CHANGEOVER_STORE",
        "CHANGES",
        "CHANGE_IN_CONTROL_KIT",
        "ACQUISITION_KIT",
        "NEW_STORE_ACQUISITION",
        "INCORRECT_MESSAGE",
        "CUSTOMER_ITEM_CODE",
        "PRODUCT_DESCRIPTION",
        "PROMPT_CLEAR_ITEM_FROM_CART",
        "SIZE_WIDTH_HEIGHT",
        "SIZE_WIDTH_HEIGHT_DEPTH",
        "AVAILABLE_QUANTITY",
        "LAST_AVAILABLE_DATE",
        "PRICE",
        "PROMOTION",
        "FIXTURE_GROUP_HOLDER",
        "SPACE",
        "PRODUCT_TYPE",
        "AVAILABLE_NOW",
        "PLACEMENT",
        "NOT_AVAILABLE",
        "ITEM_PREVIOUSLY_ORDERED",
        "CATEGORY",
        "IMAGINE_ITEM_CODE"
    ];

    // Other
    public Image: string;
    public IsFavorite: boolean;
    public NumberInCart: number;
    public Characteristics: ItemCharacteristicUiModel[];
    public invalidCartItems: ItemSimpleDataModel[] = [];


    // ----- CONSTRUCTOR -----
    constructor(
        private translateService: TranslateService,
        private activeProfileService: ActiveProfileService,
        private authService: AuthService,
        private helperFunctionsService: HelperFunctionsService,
        private ipsMessage: IpsMessageService,
        private cartService: CartService,
        private itemService: ItemService,
        private punchOutService: PunchOutService,
        private orderService: OrderService,
        private fileDownloadService: FileDownloadService,
        private settingsService: ShopSettingsService
    ) {
        this.Image = "https://d1icd6shlvmxi6.cloudfront.net/gsc/P5ND2U/75/c6/e8/75c6e8ec3f904f23a84f28b1b25f91d8/images/pbi_9860_-_my_products_-_list/u18349.svg?token=2a3af84035cea29bc988bc0522beef84f9a487807152e6566b52a77a1985924d";
        this.IsFavorite = false;
        this.ShowOrderReasonControl = true;
        this.Characteristics = [];
    }

    // ----- FUNCTIONS -----
    // Determines if the item exists in the current user's cart for the given location.
    public inCart(): boolean {
        if (this.cartService.inCart(this.LocationItemData.LocationId, this.LocationItemData.Item.Id)) {
            // if (!this.LocationItemData.OrderReason) {
            //     this.synchFromCart();
            // }
            return true;
        }
        return false;
    }

    //
    public resetLocalSelections(): void {
        this.LocationItemData.OrderReason = "";
        this.setInitialQuantity();
    }

    // Adds an item to a given location in the cart.
    public addToCart(toAdd: CartItemModel): void {
        toAdd = toAdd || this.LocationItemData;
        let duplicate = this.cartService.findDuplicateCartItem(toAdd);
        if (duplicate === null) {
            this.cartService.addItemToMyCart(
                this.helperFunctionsService.copyObject(toAdd)
            ).then(() => {
                this.resetLocalSelections();
                this.synchFromCart();
            });
        }
        else {
            duplicate.Qty = duplicate.Qty + toAdd.Qty;
            this.cartService.updateItemInMyCart(duplicate);
            this.resetLocalSelections();
            this.synchFromCart();
        }
    }

    // Removes an item from a given location in the cart.
    public removeFromCart(): void {
        const locationDisplay = this.cartService.getLocationDisplay(this.LocationItemData.LocationId);
        let translated = this.translateService.instant(
            "PROMPT_CLEAR_ITEM_FROM_CART",
            { itemName: this.LocationItemData.Item.Description, locationName: locationDisplay }
        );
        this.ipsMessage.confirm({
            title: "WARNING",
            body: translated,
            ok: "YES_REMOVE_PRODUCTS",
            cancel: "NO_KEEP_PRODUCTS"
        }).then(() => {
            this.ipsMessage.waitForWork({
                body: "PROCESSING",
                workFunction: () => this.cartService.removeItemFromMyCart(
                    this.LocationItemData.LocationId, this.LocationItemData.Id
                ),
                progressMessage: "PROCESSING"
            });
        })
            .catch(() => {
                // rejection
            });
    }

    // Makes a call back to a passed in function to either add or remove the item from
    // the cart (based on whether or not it is already present for the given location).
    public toggleInCart(): void {
        if (this.inCart()) {
            this.removeFromCart();
        }
        else {
            this.addToCart(this.LocationItemData);
        }
    }

    // Makes a call back to the passed in function that handles toggling the "favorite" setting
    // of the item.
    public toggleFavorite(): void {
        console.log(this.LocationItemData.LocationId);
    }

    private setInitialQuantity() {
        if (this.hasOrderQuantities) {
            this.LocationItemData.Qty = this.getQuantityOptions()[0].Quantity;
        } else {
            this.LocationItemData.Qty = 1;
        }
    }

    public isCurrentItemInvalid(itemId: number) {
        if (this.DisplayMode === "cart") {
            this.invalidCartItems = this.cartService.invalidCartItems;
            return this.invalidCartItems.some(item => item.Id === itemId);
        }
        return false;
    }

    // Makes a call back to the passed in function that handles an items details being changed,
    // but only if the item is already part of the user's cart.
    public itemDetailsChanged(): void {
        if ((!this.LocationItemData.Qty) || (this.LocationItemData.Qty < 1)) {
            this.setInitialQuantity();
        }
        else {
            this.LocationItemData.Qty = parseInt(
                Math.floor(this.LocationItemData.Qty).toString(),
                10
            );
            let elmnt = <HTMLInputElement>document.getElementById(
                "item-display-card-1-" + this.LocationItemData.Id + "-" + this.LocationItemData.Item.Id
            );
            if (elmnt) {
                elmnt.value = this.LocationItemData.Qty.toString();
            }
            elmnt = <HTMLInputElement>document.getElementById(
                "item-display-card-2-" + this.LocationItemData.Id + "-" + this.LocationItemData.Item.Id
            );
            if (elmnt) {
                elmnt.value = this.LocationItemData.Qty.toString();
            }
        }
        if (this.DisplayMode === "cart") {
            this.cartService.updateItemInMyCart(
                this.LocationItemData
            );
        }
        this.synchFromCart();
    }

    // Adds characteristics to the item for display.
    private addCharacteristic(key: string, value: any): void {
        let newCharacteristic: ItemCharacteristicUiModel = {
            Id: this.Characteristics.length,
            Key: key,
            Value: value,
            Type: "Kit",
            CanHighlight: false,
            IsDate: false
        };
        switch (key) {
            case "CustomerItemCode":
                newCharacteristic.Key = this.TranslatedTexts["CUSTOMER_ITEM_CODE"];
                break;
            case "ProductType":
                newCharacteristic.Key = this.TranslatedTexts["PRODUCT_TYPE"];
                break;
            case "Size":
                newCharacteristic.Key = this.TranslatedTexts["SIZE_WIDTH_HEIGHT"];
                break;
            case "SizeWithDepth":
                newCharacteristic.Key = this.TranslatedTexts["SIZE_WIDTH_HEIGHT_DEPTH"];
                break;
            // case "AvailableQty":
            //     newCharacteristic.Key = this.TranslatedTexts["AVAILABLE_QUANTITY"];
            //     break;
            case "EndDate":
                newCharacteristic.Key = this.TranslatedTexts["LAST_AVAILABLE_DATE"];
                if (this.isRental) {
                    newCharacteristic.Value = "N/A";
                } else {
                    if (newCharacteristic.Value) {
                        newCharacteristic.Value =
                            this.helperFunctionsService.formattedAsReadableDate(newCharacteristic.Value.toString());
                        newCharacteristic.IsDate = true;
                    } else {
                        newCharacteristic.Value = this.TranslatedTexts["AVAILABLE_NOW"];
                    }
                }
                break;
            case "UnitPrice":
                newCharacteristic.Key = this.TranslatedTexts["PRICE"];
                if (this.isRental) {
                    newCharacteristic.Value = "N/A";
                } else if (!this.hasPriceTiers) {
                    newCharacteristic.Value = this.toPriceString(newCharacteristic.Value);
                }
                break;
            case "UnitPriceOrdered":
                newCharacteristic.Key = this.TranslatedTexts["PRICE"];
                break;
            case "AvailableQty":
                newCharacteristic.Key = this.TranslatedTexts["AVAILABLE_QUANTITY"];

                if (this.isVariablePOD || this.isDigitalDownload) {
                    newCharacteristic.Value = "N/A";
                } else if (this.LocationItemData.Item.DisplayAvailable === false && newCharacteristic.Value === 0) {
                    newCharacteristic.Value = "N/A";
                } else if (newCharacteristic.Value) {
                    newCharacteristic.Value = Number(newCharacteristic.Value).toString();
                }
                break;
            case "PromotionName":
                newCharacteristic.Key = this.TranslatedTexts["PROMOTION"];
                break;
            case "HolderName":
                newCharacteristic.Key = this.TranslatedTexts["PLACEMENT"];
                break;
            case "SpaceName":
                newCharacteristic.Key = this.TranslatedTexts["SPACE"];
                break;
            case "ImagineItemCode":
                newCharacteristic.Key = this.TranslatedTexts["IMAGINE_ITEM_CODE"];
                break;
            case "Category":
                newCharacteristic.Key = this.TranslatedTexts["CATEGORY"];
                break;
            default:
                return; // If not a presently displayed value, should not be added.
        }
        this.Characteristics.push(newCharacteristic);
    }

    // Updates the local LocationItemData based on what's already in the cart if that's
    // applicable.
    private synchFromCart(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            let totalQuantity = 0;
            if (this.DisplayMode === "shop") {
                totalQuantity = this.cartService.numberOfItemInLocationCart(
                    this.LocationItemData.LocationId, this.ItemData.Id
                );
            }
            this.NumberInCart = totalQuantity;
            resolve();
        });

        return promise;
    }

    // Calls the translation service to process text.
    private translateText(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.translateService.get(this.TextToTranslate).toPromise().then((response) => {
                this.TranslatedTexts = response;
                resolve();
            });
        });

        return promise;
    }

    public get isVariablePOD(): boolean {
        if (this.DisplayMode === "shop") {
            return this.ItemData.Variable || false;
        }
        return false;
    }

    public get isDigitalDownload(): boolean {
        let returnValue = false;

        if (this.DisplayMode === "shop") {
            returnValue = this.ItemData.DigitalDownload;
        } else if (this.DisplayMode === "cart") {
            returnValue = this.LocationItemData.DigitalDownload;
        }

        return returnValue;
    }

    public getQuantityOptions(): any[] {
        const item = this.DisplayMode === "shop" ? this.ItemData : this.LocationItemData.Item;

        if (item && item.OrderQuantities) {
            const quantityOptions = JSON.parse(item.OrderQuantities);
            return quantityOptions;
        } else {
            return [];
        }
    }

    public getPriceTiers(): any[] {
        const item = this.DisplayMode === "shop" ? this.ItemData : this.LocationItemData.Item;

        if (item && item.PriceQuantities) {
            const priceTiers = JSON.parse(item.PriceQuantities);
            return priceTiers;
        } else {
            return [];
        }
    }

    public get hasPriceTiers(): boolean {
        return this.getPriceTiers().length > 0;
    }

    public get hasOrderQuantities(): boolean {
        return this.getQuantityOptions().length > 0;
    }

    public get showOrderQuantity(): boolean {
        if (this.DisplayMode === "shop") {
            if (this.ItemData.ProductType === "DropShipment") {
                return false;
            } else {
                return !this.ItemData.DigitalDownload;
            }
        } else if (this.DisplayMode === "cart") {
            if (this.LocationItemData.Item.ProductType === "DropShipment") {
                return false;
            } else {
                return !this.LocationItemData.DigitalDownload;
            }
        }
    }

    public get showOrderReason(): boolean {
        if (this.DisplayMode === "shop") {
            if (this.ItemData.ProductType === "DropShipment") {
                return false;
            } else {
                return this.ShowOrderReasonControl;
            }
        } else if (this.DisplayMode === "cart") {
            if (this.LocationItemData.Item.ProductType === "DropShipment") {
                return false;
            } else {
                return this.ShowOrderReasonControl;
            }
        }
    }

    public get orderReasonDisabled(): boolean {
        if (this.DisplayMode === "shop") {
            if (this.ItemData.DigitalDownload && this.NumberInCart > 0) {
                return true;
            } else {
                return false;
            }
        } else if (this.DisplayMode === "cart") {
            return false;
        }
    }

    public get imageUrl(): string {
        if (this.DisplayMode === "shop") {
            return this.Image;
        } else if (this.DisplayMode === "cart") {
            const mediaId = this.LocationItemData.Item.MasterMediaId;
            const baseUrl = this.cartService.urlApiBase;
            const businessId = this.activeProfileService.businessIdentity;
            const token = this.authService.getIdToken();

            const url = `${baseUrl}Media/v1/MasterMedia/${businessId}/Item/${mediaId}/Original/Jpg?BusinessIdentity=${businessId}&idToken=${token}`;

            return url;
        }
    }

    public get availableForDownload(): boolean {
        const isVariable = this.ItemData.Variable;
        const isDigitalDownload = this.ItemData.DigitalDownload;
        const isDropShipment = (this.ItemData && this.ItemData.ProductType === "DropShipment");
        const hasCost = this.ItemData.UnitPrice > 0;
        const hasBeenOrdered = this.ItemData.OrderIds && !!this.ItemData.OrderIds.length;

        if (isDropShipment) {
            return false;
        }
        if (isVariable) {
            return false;
        } else {
            return isDigitalDownload && (!hasCost || hasBeenOrdered);
        }
    }

    public get downloadButtonDisabled(): boolean {
        return this.LocationItemData.OrderReason === "";
    }

    public get canAddToCart(): boolean {
        let returnValue = false;

        if (this.isDigitalDownload) {
            if (this.NumberInCart > 0) {
                returnValue = false;
            } else {
                if (this.showOrderReason) {
                    returnValue = this.LocationItemData.OrderReason !== "";
                } else {
                    return true;
                }
            }
        } else {
            if (this.showOrderReason) {
                returnValue = this.LocationItemData.OrderReason !== "";
            } else {
                return true;
            }
        }

        return returnValue;
    }

    public get isRental(): boolean {
        if (this.DisplayMode === "shop") {
            return this.ItemData.ProductType === "DropShipment";
        } else if (this.DisplayMode === "cart") {
            return this.LocationItemData.Item.ProductType === "DropShipment";
        }
    }

    // ----- START UP -----
    ngOnInit() {
        this.translateText().then(() => {
            if (!this.LocationItemData) {
                this.LocationItemData = this.itemService.ItemDataToCartLocationItem(
                    this.ItemData, this.LocationId, 1, ""
                );
            }

            this.synchFromCart()
                .then(() => {
                    // Add the characteristics for display.
                    if ((this.LocationItemData.Item.MasterMediaId) &&
                        (this.LocationItemData.Item.Media)) {
                        this.Image =
                            `${this.LocationItemData.Item.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
                    }

                    this.addCharacteristic(
                        "CustomerItemCode",
                        this.LocationItemData.Item.CustomerItemCode
                    );

                    if (this.LocationItemData.Item["Depth"]) {
                        this.addCharacteristic(
                            "SizeWithDepth",
                            this.LocationItemData.Item["Width"] + " x " +
                            this.LocationItemData.Item["Height"] + " x " +
                            this.LocationItemData.Item["Depth"]
                        );
                    } else if (this.LocationItemData.Item["Width"]) {
                        this.addCharacteristic(
                            "Size",
                            this.LocationItemData.Item["Width"] + " x " +
                            this.LocationItemData.Item["Height"]
                        );
                    } else {
                        this.addCharacteristic(
                            "Size",
                            this.TranslatedTexts["NOT_AVAILABLE"]
                        );
                    }

                    this.addCharacteristic(
                        "ProductType",
                        this.LocationItemData.Item.ProductType === "DropShipment" ? "Rental" : this.LocationItemData.Item.ProductType
                    );

                    const hasAvailableQuantity = !!this.LocationItemData.Item.AvailableQty;
                    let availableQuantity = this.LocationItemData.Item.AvailableQty;

                    if (hasAvailableQuantity && availableQuantity < 0) {
                        availableQuantity = 0;
                    }

                    this.addCharacteristic(
                        "AvailableQty",
                        availableQuantity
                    );

                    this.addCharacteristic(
                        "EndDate",
                        this.LocationItemData.Item.EndDate
                    );

                    this.addPriceCharacteristic();

                    if (this.LocationItemData.Item.SpaceName) {
                        this.addCharacteristic(
                            "SpaceName",
                            this.LocationItemData.Item.SpaceName
                        );
                    }

                    if (this.LocationItemData.Item.FixtureGroupName && this.LocationItemData.Item.HolderName) {
                        this.addCharacteristic(
                            "HolderName",
                            `${this.LocationItemData.Item.FixtureGroupName} - ${this.LocationItemData.Item.HolderName}`
                        );
                    } else if (this.LocationItemData.Item.ElementName) {
                        this.addCharacteristic(
                            "HolderName",
                            this.LocationItemData.Item.ElementName
                        );
                    }

                    if (this.LocationItemData.Item.PromotionName) {
                        this.addCharacteristic(
                            "PromotionName",
                            this.LocationItemData.Item.PromotionName
                        );
                    }

                    if (this.LocationItemData.Item.ImagineItemCode) {
                        this.addCharacteristic(
                            "ImagineItemCode",
                            this.LocationItemData.Item.ImagineItemCode
                        );
                    }


                    if (this.LocationItemData.Item.CategoryList) {
                        this.addCharacteristic(
                            "Category",
                            this.LocationItemData.Item.CategoryList
                        );
                    }
                });
        });
    }

    private toPriceString(val: any) {
        return "$" + Number(val).toFixed(2);
    }

    private addPriceCharacteristic() {
        if (this.LocationItemData.DigitalDownload && this.LocationItemData.Item.OrderIds && this.LocationItemData.Item.OrderIds.length > 0) {
            const formattedPrice = this.helperFunctionsService.formatAsPrice(this.LocationItemData.Item.UnitPrice);
            const originalPriceTag = `<span class="previously-ordered">$${formattedPrice}</span>`;
            const newPriceTag = `<span class="ml-2">$0.00</span>`;
            const pricingNoteTag = `<span class="previously-ordered-note d-block">${this.TranslatedTexts["ITEM_PREVIOUSLY_ORDERED"]}</span>`;

            const finalString = `${originalPriceTag}${newPriceTag}${pricingNoteTag}`;

            this.addCharacteristic("UnitPriceOrdered", finalString);
        } else {
            if (this.hasPriceTiers) {
                const tableOpenTag = `<table class="table table-sm table-striped"><tbody>`;
                const tableEndTag = `</tbody></table>`;
                let tableBody = "";

                this.getPriceTiers().forEach((tier) => {
                    if (tier.QuantityTo) {
                        const rowString = `<tr><td>${tier.QuantityFrom} - ${tier.QuantityTo}</td><td class="text-right">${this.toPriceString(tier.Price)}</td></tr>`;
                        tableBody = tableBody + rowString;
                    } else {
                        const rowString = `<tr><td>${tier.QuantityFrom}+</td><td class="text-right">${this.toPriceString(tier.Price)}</td></tr>`;
                        tableBody = tableBody + rowString;
                    }
                });

                this.addCharacteristic("UnitPrice", `${tableOpenTag}${tableBody}${tableEndTag}`);
            } else {
                this.addCharacteristic(
                    "UnitPrice",
                    this.LocationItemData.Item.UnitPrice
                );
            }
        }
    }

    customize() {
        //save data to local storage for creating a cart item after punchout
        const cartItem: CartItemPostData = {
            Id: 0,
            LocationId: this.LocationItemData.LocationId,
            CartId: this.cartService.myCurrentCart.Id,
            ItemId: this.LocationItemData.Item.Id,
            DigitalDownload: this.LocationItemData.Item.DigitalDownload,
            Qty: this.LocationItemData.Qty,
            OrderReason: this.LocationItemData.OrderReason,
            Variable: true,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            XMPieProductId: this.ItemData.XMPieProductId,
            XMPieStoreId: this.ItemData.XMPieStoreId,
            DirectMail: this.ItemData.DirectMail
        };

        this.punchOutService.getCustomizeUrl(cartItem).then(url => {
            this.punchOut.emit();
            window.location.href = url;
        });
    }

    public downloadClicked() {
        const itemId = this.ItemData.Id;
        const productCode = this.ItemData.CustomerItemCode;
        const locationId = this.LocationId;

        this.orderService.getStaticItemDownloadUrl(productCode, itemId, locationId, null).then((response: FileDownloadModel) => {
            this.LocationItemData.OrderReason = "";
            this.fileDownloadService.Download(response.Url, response.FileName);
        });
    }
}
