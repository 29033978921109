var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var PromotionMessageService = /** @class */ (function (_super) {
    __extends(PromotionMessageService, _super);
    function PromotionMessageService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("PromotionMessage", "Campaign", "v1");
        return _this;
    }
    PromotionMessageService.prototype.getPromotionMessages = function (promoId) {
        var route = "Promotion/" + promoId + "/PromotionMessage";
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    PromotionMessageService.prototype.GetByPromotionMessagesWithPlacements = function (promoId, returnFixtures) {
        if (returnFixtures === void 0) { returnFixtures = false; }
        var route = "Promotion/" + promoId + "/PromotionMessageWithPlacements?returnFixtures=" + returnFixtures;
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    PromotionMessageService.prototype.calculateLocationBalance = function (model) {
        var isModelValid = this.isBalanceCalcModelValid(model);
        if (isModelValid) {
            var route = this.urlApiBaseProductPlusVersion + "PromotionMessage/CalculateLocationBalance";
            return this.postByRoute(route, model);
        }
        return Promise.resolve(null);
    };
    PromotionMessageService.prototype.calculatePriorityFillLocationBalance = function (model) {
        var isModelValid = this.isBalanceCalcModelValid(model);
        if (model.MessagePlacements.length) {
            model.MessagePlacements.forEach(function (mp) {
                mp.MessagePlacement.LocationAssignments = [];
            });
        }
        model.PatternGroupInfoForPriorityFill.forEach(function (patternGroup) {
            if (patternGroup.MessagePatterns && patternGroup.MessagePatterns.length) {
                patternGroup.MessagePatterns.forEach(function (mp) {
                    if (mp.Markets && mp.Markets.length) {
                        mp.Markets.forEach(function (m) { delete m.DisplayInfo; });
                    }
                });
            }
        });
        if (isModelValid) {
            var route = this.urlApiBaseProductPlusVersion + "PromotionMessage/CalculatePriorityFillLocationBalance";
            return this.postByRoute(route, model);
        }
        return Promise.resolve(null);
    };
    PromotionMessageService.prototype.checkUniqueMessageName = function (model) {
        var route = this.urlApiBaseProductPlusVersion + "Campaign/Promotion/IsMessageNameAvailable";
        return this.postByRoute(route, model);
    };
    PromotionMessageService.prototype.calculateMarketLocationBalanceList = function (requestModel) {
        var route = this.urlApiBaseProductPlusVersion + "PromotionMessage/MarketLocationBalanceList";
        return this.postByRoute(route, requestModel);
    };
    PromotionMessageService.prototype.calculatePlacementLocationBalanceList = function (requestModel) {
        var route = this.urlApiBaseProductPlusVersion + "PromotionMessage/PlacementLocationBalanceList";
        return this.postByRoute(route, requestModel);
    };
    PromotionMessageService.prototype.calculateElementLocationBalanceList = function (requestModel) {
        var route = this.urlApiBaseProductPlusVersion + "PromotionMessage/ElementLocationBalanceList";
        return this.postByRoute(route, requestModel);
    };
    PromotionMessageService.prototype.GetPlacementElementDetails = function (id) {
        var route = "PlacementElement/" + id + "/Details";
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    /**
     * This is not a complete valid check. Only does bare minimum check for the case where one of the market controls is invalid.
     * @param model
     */
    PromotionMessageService.prototype.isBalanceCalcModelValid = function (model) {
        if (model && model.PatternGroupInfo && model.PatternGroupInfo.Markets) {
            return !model.PatternGroupInfo.Markets.some(function (m) { return m.TargetMarketType === ""; });
        }
        return false;
    };
    return PromotionMessageService;
}(IpsBaseWebService));
export { PromotionMessageService };
