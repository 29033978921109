import { Component, Injector, Input, forwardRef, OnInit } from "@angular/core";
import { InputType } from "../../type/InputType";
import { WrappedFormControlSuperclass } from "../wrapped-form-control-superclass";
import { provideValueAccessor } from "../form-control-superclass";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { NG_VALIDATORS } from "@angular/forms";

@Component({
    selector: "app-multi-type-input",
    templateUrl: "./multi-type-input.component.html",
    providers: [provideValueAccessor(MultiTypeInputComponent),
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MultiTypeInputComponent),
            multi: true,
        }]
})
export class MultiTypeInputComponent extends WrappedFormControlSuperclass<string> implements OnInit {
    @Input() inputType: InputType;
    @Input() dateOptions?: BsDatepickerConfig = {} as BsDatepickerConfig;
    @Input() ipsClass?: string;
    @Input() placeholder?: string;
    @Input() placeholderPrefix?: string;
    @Input() focus?: string;
    @Input() name: string;  // value to use for html 'name' attribute

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        if (this.placeholderPrefix) {
            switch (this.inputType) {
                case "Date":
                    this.placeholder = this.placeholderPrefix + "_DATE";
                    break;
                case "Number":
                    this.placeholder = this.placeholderPrefix + "_NUMBER";
                    break;
                default:
                    this.placeholder = this.placeholderPrefix + "_NAME";
                    break;
            }
        }
    }

}
