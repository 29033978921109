<div class="col locationSearchPage" *ngIf="translated" ipsFocus="searchInput">
    <ips-list-search labelLoading="{{'ACCESSING_LOCATION_INFO' | translate }}"
    labelLoadingError="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}"
    [listData]="location"
    [busy]="busy"
    (onListDataChange)="getListData($event.search, null, $event.filterOption)"
    [scroller]=true
    [promise]="promise"
    [query]="query">
        <ng-template #searchHeaderTemplate>
            <div class="row">
                <div class="col-12">
                    <section>
                        <h3 class="ips-gray-color">
                            <span class="mr-2">{{searchLabelPlural}}</span>
                            <a uiSref="main.storeProfileLocation.edit" [uiParams]="{id: 0}" ngbTooltip="{{'CREATE_NEW_LOCATION' | translate }}" placement="bottom" ipsPermission="main.storeProfileLocation.edit"><span class="fa fa-plus ips-ls-addIcon"></span></a>
                            <a [href]="" *ngIf="selectedOption.value!=='closed'" (click)="locationDownloadClickHandler($event)"><span class="fa fa-arrow-circle-down ips-gray-color clicker pl-2"></span></a><span *ngIf="selectedOption.value==='closed'" class="fa fa-arrow-circle-down ips-gray-color pl-2"></span>
                        </h3>
                    </section>
                </div>
                <section class="col-12" [ngbCollapse]="collapse">
                    <div class="ips-ls-search-module-imgn">
                        <form id="ramiInfoInput" [formGroup]="locationForm" name="locationForm" novalidate ipsUnsavedWarningForm>
                            <div class="card ips-shadow mt-2 mb-3" *ngIf="advanceSearchState!='Hide'">
                                <div class="card-body py-0">
                                    <div class="form-row ips-font-lg my-3">
                                        <div class="col-12 col-md-auto pr-0">
                                            <span class="mr-2 ips-gray-color" translate>ADVANCED_SEARCH</span>
                                        </div>
                                        <div class="col-auto px-0">
                                            <button class="btn btn-transparent px-1 py-0" (click)="createLocationSearch()" ngbTooltip="{{'CREATE_ADVANCED_SEARCH' | translate }}" placement="bottom"><span class="fa fa-plus fa-lg ips-gray-color"></span></button>                                          
                                        </div>
                                        <div class="col-auto px-0">
                                            <button class="btn btn-transparent px-1 py-0" (click)="findLocationSearch()" ngbTooltip="{{'FIND_LOCATION_SEARCH' | translate }}" placement="bottom"><span class="fa fa-search fa-lg ips-gray-color"></span></button>
                                        </div>
                                        <div class="col px-0">
                                            <button class="btn btn-transparent px-1 py-0" (click)="findLocationSearch(true)" ngbTooltip="{{'COPY_LOCATION_SEARCH' | translate }}" placement="bottom"><i class="copy-icon fa-lg ips-gray-color"></i></button>
                                        </div>
                                        <div class="col-2 col-sm-1 d-flex justify-content-end pl-0">
                                            <button class="btn ips-btn-link-icon" (click)="closeLocationSearch()">
                                                <span class="text-right fa fa-times ips-close m-0"></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12 w-col-lg-64 w-col-xl-70 ips-w-xl form-group" [ngClass]="{'col-md-8': advanceSearchState=='Partial'}">
                                            <label for="Name" class="required" translate>NAME</label>
                                            <input type="text" name="Name" id="Name" formControlName="Name" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_LOCATION_SEARCH_NAME' | translate}}" focus="true">
                                            <ips-show-errors [control]="Name" [errorMessages]="getErrorMessages('Name')" cssClass="validation-message-Qty"></ips-show-errors>
                                        </div>
                                        <div class="col-12 col-md-4 w-col-lg-36 w-col-xl-30" *ngIf="advanceSearchState=='Partial'">
                                            <label><!--Blank save button label--></label>
                                            <div class="form-group mt-2 w-100">
                                                <button id="saveSearch"
                                                        class="btn btn-lg btn-block alert-success"
                                                        (click)="saveLocationSearchPrompt(false)"
                                                        [disabled]="locationForm.invalid || locationForm.pristine"
                                                        [ngClass]="{'btn-default': locationForm.invalid || locationForm.pristine}" translate>
                                                    SAVE
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <app-market-edit [parent]="locationForm"
                                    [marketsModel]="markets"
                                    [label]="'LOCATION_CRITERIA'"
                                    [balanceCalcService]="marketGroupService"
                                    (marketChanged)="balanceChangedEventHandler($event)"
                                    (marketFinalCountChanged)="marketFinalCountChanged($event)"
                                    [hidden]="advanceSearchState=='Partial'"
                                    [marketsToExclude]="marketsToExclude"></app-market-edit>

                                    <div class="form-row"  *ngIf="advanceSearchState=='Full'">
                                        <div class="form-group col-12">
                                            <label for="Notes" class="mb-2" translate>NOTES</label>
                                            <textarea class="form-control form-control-lg input-full" name="Notes" id="Notes" cols="30" rows="3" formControlName="Notes" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                                            <ips-show-errors [control]="Notes" [errorMessages]="getErrorMessages('Notes')" cssClass="validation-message-Qty"></ips-show-errors>
                                        </div>
                                    </div>

                                    <div class="form-row" *ngIf="advanceSearchState=='Full'">
                                        <div class="form-group col-12 col-md-4 offset-md-4 w-col-lg-36 ips-offset-lg-28 w-col-xl-30 ips-offset-xl-40">
                                            <button id="saveSearch"
                                            class="btn btn-lg btn-block alert-success"
                                            (click)="saveLocationSearchPrompt(false)"
                                            [disabled]="locationForm.invalid || locationForm.pristine"
                                            [ngClass]="{'btn-default': locationForm.invalid || locationForm.pristine}" translate>
                                                SAVE
                                            </button>
                                        </div>
                                        <div class="form-group col-12 col-md-4 w-col-lg-36 w-col-xl-30">
                                            <button id="delete"
                                            class="btn btn-lg btn-block alert-danger"
                                            (click)="deleteLocationSearchPrompt()"
                                            ips-unsaved-warning-clear
                                            [disabled]="!locationForm.value.Id"
                                            [ngClass]="{'btn-default': !locationForm.value.Id}" translate>
                                                DELETE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="ips-ls-searchBox input-group">
                            <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                            <input type="text"
                            class="form-control form-control-lg ips-ls-input"
                            [(ngModel)]="query" (ngModelChange)="onQueryChange($event)"
                            id="btn-search"
                            value=""
                            maxlength="20"
                            [ngModelOptions]="{ debounce: 350 }"
                            aria-label="Text input with dropdown button" name="searchInput">
                            <div class="input-group-btn location-option-width">
                                <select class="form-control form-control-lg ips-select ips-ls-btn-right"
                                id="btn-search-dropdown"
                                [compareWith]="compareFn"
                                [(ngModel)]="selectedOption"
                                data-html="1"
                                (ngModelChange)="onFilterChange($event)"
                                placement="bottom-right"
                                bs-select
                                aria-haspopup="true"
                                aria-expanded="false">
                                    <option *ngFor="let option of optionList" [ngValue]="option">{{option.text}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div [ngbCollapse]="collapse" class="col-12 col-sm">
                                <div class="row no-gutters mt-3">
                                    <div class="col-12 col-md-auto ml-3">
                                        <span class="badge-pill badge-secondary font-weight-normal">{{ location.ResultChunkAttributes.TotalRecords | number }}</span>
                                        <span class="ml-1">{{location.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping | translate}} {{'FOUND' | translate}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto d-flex justify-content-end mt-1 list-options-margin">
                                <button class="btn btn-link px-0 pb-0" (click)="selectListOptions()" [disabled]="this.selectedOption.value === 'closed'">
                                    <i class="fa fa-lg fa-th-list mr-2" aria-hidden="true"></i>{{'LIST_OPTIONS' | translate}}
                                </button>
                            </div>
                            <div class="col-auto d-flex justify-content-end mt-1">
                                <button class="btn btn-link px-0 pb-0" (click)="toggleAdvanceSearch()">
                                    <i class="fa fa-lg mr-2" [ngClass]="{'fa-chevron-up': advanceSearchState=='Full', 'fa-chevron-down': advanceSearchState!='Full'}" aria-hidden="true"></i>{{'ADVANCED_SEARCH' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div *ngIf="this.selectedOption.value === 'closed'" class="col-12 mt-3">
                <div class="card border-warning">
                    <div class="card-body text-warning">
                        <p class="card-text" translate>INFO_CLOSED_LOCATION_WARNING</p>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #listSearchItemTemplate let-result="result">
            <div class="row ips-search-result-big">
                <div class="col pr-0">
                    <a uiSref="main.storeProfileLocation.view" [uiParams]="{id:result.Id}" class="no-underline">
                        <div class="px-3 pt-3 loc-label">
                            <div>
                                <span>{{result.LocationIdentifier}} {{result.Name}} {{(result.ReferenceIdentifier ? "#" + result.ReferenceIdentifier + "#" : "")}}</span>
                                <div class="d-inline-block loc-status">
                                    <div class="{{result.LocationStatusInfo.IconClass}} fa-lg" ngbTooltip="{{result.LocationStatusInfo.Tooltip | translate}}" placement="bottom"></div>
                                </div>
                            </div>
                            <div class="loc-city-state">{{result.Addresses[0].City}}, {{result.Addresses[0].StateProvince}}</div>
                        </div>
                    </a>
                </div>
                <div class="col-2 col-md-1 mt-2" ipsPermission="main.storeProfileLocation.edit">
                    <a uiSref="main.storeProfileLocation.edit" [uiParams]="{id:result.Id}" class="no-underline">
                        <div class="pt-3 pr-3 d-flex justify-content-end">
                            <span class="fa fa-pencil"></span>
                        </div>
                    </a>
                </div>
            </div>
            <div *ngIf="result.LocationStatusInfo.Status !== 'CLOSED'">
                <app-search-cards [CardData]="result.Cards" [LocationId]="result.Id" [EditOrViewPermission]="editOrViewPermission" [FullImage]="result.FullImage"></app-search-cards>
            </div>
        </ng-template>
    </ips-list-search>
</div>
