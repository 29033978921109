import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StateService, StateParams } from "@uirouter/core";

import { String as IpsString } from "typescript-string-operations";
import { distinctUntilChanged, debounceTime, map } from "rxjs/operators";
import { Subject } from "rxjs";

import { ListSearchHelper, SearchInfo, SearchResponse, QueryParamItem, ActiveProfileService, HelperFunctionsService } from "imagine-ui-ng-core";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { IpsModalService } from "imagine-ui-ng-modal";
import { IpsMessageService } from "imagine-ui-ng-messaging";

import { ShopSettingsService } from "../../service/shop-settings.service";
import { ShopSettingModel } from "../../model/ShopSettingModel";

import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { ItemService, LocationService, LocationModel, LocationModelWithLabel, CartService, CartDataModel, KeyValuePair  } from "../../index";

import { AllowPayment, LocationPaymentMethodModel, PaymentSettingsService } from "../../index";

interface ScrollInfo {
    search: string;
    positionY: number;
    chunkIndex: number;
}


@Component({
    selector: "app-cart-search",
    templateUrl: "./cart-search.component.html",
    styleUrls: ["./cart-search.component.scss"]
})
export class CartSearchComponent implements OnInit, OnDestroy {
    // ----- DATA -----
    public CurrentCart: CartDataModel;

    public cartItemHelper: ListSearchHelper;
    public busy = true;
    public promise: Promise<any>;
    public screenLoaded = false;

    public showOrderReason = true;
    public orderReasons: string[] = [];

    public selectedLocationId?: number;
    public selectedLocation = <KeyValuePair<number, string>>null;

    public searchText = "";
    public searchInfo: SearchInfo;
    public totalRecordCount: number;

    private scrollY: number;
    private searchScrollKey: string;

    private locale = "en-US";

    private pluralMapping: { [k: string]: string } = {};

    private querySubject: Subject<string> = new Subject<string>();
    public cartLocations: number[];

    public showCreditButton = false;
    public showInvoiceButton = false;
    public checkoutButtonLabel = "Checkout by Invoice";

    public showAllLocationsFilter = false;

    // Static
    TranslatedTexts: any;
    TextToTranslate = [
        "PROMPT_CLEAR_CART",
    ];

    // ----- CONSTRUCTOR -----
    constructor(
        private locationService: LocationService,
        private cartService: CartService,
        listSearchHelper: ListSearchHelper,
        private activeProfileService: ActiveProfileService,
        private ipsModal: IpsModalService,
        private ipsMessage: IpsMessageService,
        private stateService: StateService,
        private helperService: HelperFunctionsService,
        private translateService: TranslateService,
        private shopSettingsService: ShopSettingsService,
        private paymentSettingsService: PaymentSettingsService
    ) {
        this.cartItemHelper = listSearchHelper.getListSearchHelper(cartService);

        this.querySubject.pipe(
            debounceTime(350),
            distinctUntilChanged())
            .subscribe((model: string) => {
                this.getListData(model);
            });
    }

    // ----- FUNCTIONS -----
    ngOnInit() {
        this.locale = this.activeProfileService.profile.Locale || navigator.language;

        this.searchScrollKey = "cart_search_scroll_" + this.activeProfileService.businessIdentity;
        let scrollInfo: ScrollInfo = JSON.parse(localStorage.getItem(this.searchScrollKey));

        if (scrollInfo) {
            this.searchText = scrollInfo.search;
            localStorage.removeItem(this.searchScrollKey);
        }

        this.processQueryParams(this.stateService.params);

        const promises: Promise<any>[] = [];

        promises.push(this.getShopSettings());

        promises.push(this.shopSettingsService.getOrderReasons().then((response: string[]) => {
            this.orderReasons = response.sort((a, b) => a > b ? 1 : -1);
        }));

        promises.push(this.translateService.get(["PRODUCT", "PRODUCTS"]).toPromise().then((response) => {
            this.pluralMapping["=1"] = response["PRODUCT"];
            this.pluralMapping["other"] = response["PRODUCTS"];
        }));

        promises.push(this.cartService.head().then((response) => {
            this.totalRecordCount = response;
        }));

        promises.push(this.shopSettingsService.getCheckoutLabelSetting().then((response) => {
            this.checkoutButtonLabel = response;
        }));

        //Only allow All Locations for Admin users
        if (this.isInRole(["EcommSiteAdmin", "ShoppingAdmin"])) {
            promises.push(this.shopSettingsService.getAllLocationsSetting().then((response: boolean) => {
                if (response) {
                    this.showAllLocationsFilter = response;
                }
            }));
        } else {
            this.showAllLocationsFilter = false;
        }

        Promise.all(promises).then(() => {
            this.screenLoaded = true;
            this.getListData(this.searchText, true, scrollInfo);
        });

        this.cartService.onCartUpdated.pipe (
            map((x: CartDataModel) => x.TotalQuantity),
            distinctUntilChanged()).subscribe((data) => {
                this.CurrentCart = this.cartService.myCurrentCartData();
                this.cartService.getMyInvalidCartItems().then((response) => {
                    this.CurrentCart.InvalidCartItems = response;
                    this.CurrentCart.HasInvalidItems = this.cartService.invalidCartItems.length > 0;
                });


            const tempLocations = this.CurrentCart.Items.map(x => x.LocationId);
            this.cartLocations = tempLocations.filter(function(x, pos) {
                return tempLocations.indexOf(x) === pos; // Ensures only the first instance is added.
            });

            if (this.screenLoaded) {
                this.getListData(this.searchText, false);
            }
            else {
                if (this.CurrentCart.Locations.length > 0) {
                    this.selectedLocation = {
                        Key: this.CurrentCart.Locations[0].Key,
                        Value: this.CurrentCart.Locations[0].Value
                    };
                }
            }


            //Grab the payment method for each location
            this.paymentSettingsService.getAvailablePaymentMethods(this.CurrentCart.Locations && this.CurrentCart.Locations.length > 0 ? this.CurrentCart.Locations.map(i => i.Key) : [0]).then((result: LocationPaymentMethodModel[]) => {
                if (!!result.find((cl) => cl.CreditCard)) {
                    this.showCreditButton = true;
                }
                if (!!result.find((cl) => cl.Invoice)) {
                    this.showInvoiceButton = true;
                }

                this.busy = false;
            });

        });

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    public get matchingRecordCount(): number {
        return this.cartItemHelper.ResultChunkAttributes.TotalRecords || 0;
    }

    public get showNoResults() {
        return this.selectedLocation &&
            this.cartItemHelper.chunkIndex > 0 &&
            this.cartItemHelper.resultList.length === 0;
    }

    private getShopSettings(): Promise<any> {
        return this.shopSettingsService.getSettingByName("ShowOrderReason").then((response: ShopSettingModel) => {
            if (response) {
                this.showOrderReason = (response.Value === "item");
            }
        });
    }

    // Calls the translation service to process text.
    private translateText(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.translateService.get(this.TextToTranslate).toPromise()
            .then((response) => {
                this.TranslatedTexts = response;
                resolve();
            });
        });

        return promise;
    }

    public checkout(paymentMethod: AllowPayment): void {
        this.stateService.transitionTo("main.checkout", { cartId: this.CurrentCart.Id, paymentMethod: paymentMethod });
    }

    public clearCart(): void {
        this.ipsMessage.confirm({
            title: "WARNING",
            body: "PROMPT_CLEAR_CART",
            ok: "YES_REMOVE_PRODUCTS",
            cancel: "NO_KEEP_PRODUCTS"
        }).then(() => {
            this.ipsMessage.waitForWork({
                body: "PROCESSING",
                workFunction: () => this.cartService.clearMyCart(),
                progressMessage: "PROCESSING"
            });
        })
        .catch(() => {
            // rejection
        });
    }

    public clearCurrentLocationFromCart(): void {
        let translated = this.translateService.instant(
            "PROMPT_CLEAR_CART_LOCATION",
            { locationName: this.selectedLocation.Value }
        );
        // translated = IpsString.Format(translated, this.selectedLocation.Value);
        this.ipsMessage.confirm({
            title: "WARNING",
            body: translated,
            ok: "YES_REMOVE_PRODUCTS",
            cancel: "NO_KEEP_PRODUCTS"
        }).then(() => {
            this.ipsMessage.waitForWork({
                body: "PROCESSING",
                workFunction: () => this.cartService.clearLocationFromMyCart(
                    this.selectedLocation.Key
                ),
                progressMessage: "PROCESSING"
            }).then(() => {
                this.stateService.transitionTo("main.mycart", { locationId: 0 }, { reload: true });
            });
        })
        .catch(() => {
            // rejection
        });
    }

    public chooseLocation() {
        this.ipsModal.displayTemplateScrollable(
            SearchModalComponent, { resolve: { addAll: this.showAllLocationsFilter, allLabel: "NO_LOCATION_SPECIFIED", search: "location", listOfLocationIds: this.cartLocations } }
        ).then((response: any) => {

                let locId = response.item.Id === 0 ? -1 : response.item.Id;

                this.selectedLocation.Key = locId;
                this.selectedLocation.Value = this.cartService.getLocationDisplay(locId);
                this.getListData("");
            },
            // Rejected
            () => {
                // remove the first item, which is All
            }
        );
    }

    public getListData(search: string, updateQueryString = true, scrollInfo?: ScrollInfo) {
        if (!search) {
            search = "";
        }

        this.searchInfo = {
            searchText: search || ""
        };

        if (this.selectedLocation) {
            this.searchInfo.additionalQueryParams =
                [{ param: "LocationId", paramValue: this.selectedLocation.Key.toString() }];

            // Update the count to reflect the total items in the cart for the current location.
            this.totalRecordCount =
                this.cartService.itemsInLocationCart(this.selectedLocation.Key);

            this.promise = this.cartItemHelper.searchHelper(this.searchInfo, true, undefined)
            .then((response) => {

                if (scrollInfo) {
                    scrollInfo.chunkIndex--;
                    if (scrollInfo.chunkIndex > 0) {
                        this.getListData(search, false, scrollInfo);
                    } else {
                        setTimeout(() => { window.scrollTo(0, scrollInfo.positionY); }, 500);
                    }
                }

                if (updateQueryString) {
                    this.updateQueryString();
                }

                // Return for the promise
                return true;
            });
        }
    }

    public onQueryChange(query: string) {
        this.searchText = query;
        this.querySubject.next(query);
    }

    public processQueryParams(parms: StateParams) {
        if (parms.locationId) {
            this.selectedLocationId = parms.locationId;
        }
    }

    private updateQueryString() {
        this.helperService.updateURLParameters({
            locationId: this.selectedLocation.Key
        });
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        } else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    }

    ngOnDestroy() {
        if (this.cartItemHelper) {
            localStorage.setItem(this.searchScrollKey, JSON.stringify({
                search: this.searchText || null,
                positionY: this.scrollY,
                chunkIndex: this.cartItemHelper.chunkIndex
            }));
        }
    }

    private isInRole(roleNames: string[]): boolean {
        const allThemes = this.activeProfileService.profile.businessIdentityList;

        const allRoles = <string[]>[];

        allThemes.forEach((theme) => {
            allRoles.push(...theme.Role);
        });

        const matchingRoles = allRoles.filter((item) => roleNames.indexOf(item) >= 0);

        return matchingRoles.length > 0;
    }
}
