
import {from as observableFrom, of as observableOf, timer as observableTimer,  Observable } from "rxjs";
import { map, debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { MarketModel, MarketService} from "../../market";
import { PromotionMessageService } from "../service/promotion-message.service";
import { PromotionMessagePatternGroupModel } from "../model/PromotionMessagePatternGroupModel";
import { MessageNameChangeModel } from "../model/MessageNameChangeModel";
import { AbstractControl, Validators, AsyncValidatorFn, FormArray, FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { PromotionMessagePatternModel, PromotionMessagePatternUIModel } from "../index";
import { PromotionSaveModel } from "../model/PromotionSaveModel";

@Injectable()
export class PromotionHelperService {
    private messageNameChanged: EventEmitter<MessageNameChangeModel>;
    private msgHtmlName = "MessageHtmlName";
    public emptyPositionMessageName: string;
    private tempPatternGroupId = 1;
    public tempMessageId: number;
    private msgIndexer = 0;
    private parent: FormGroup;

    constructor(private activeProfileService: ActiveProfileService, private marketService: MarketService,
        private fb: FormBuilder, private promotionMessageService: PromotionMessageService) {
    }

    public createForm(parent: FormGroup, messageNameChanged: EventEmitter<MessageNameChangeModel>, useMessagePattern: boolean, msgPatternType?: string) {
        //clear data when toggle pattern on/off
        if (parent.get("messageForm")) {
            parent.removeControl("messageForm");
            this.tempPatternGroupId = 1;
        }

        this.parent = parent;
        this.messageNameChanged = messageNameChanged;

        let messageForm: FormGroup;
        if (msgPatternType === "priority") {
            this.tempPatternGroupId = 1;
            messageForm = this.fb.group({
                PatternGroups: this.fb.array([this.newPriorityFillGroup(useMessagePattern, true)])
            });
        }
        else {
            messageForm = this.fb.group({
                PatternGroups: this.fb.array([this.newPatternGroup(useMessagePattern, true)])
            });
        }

        parent.addControl("messageForm", messageForm);
    }

    /**
     * returns unique message count accross patternGroups
     * @param patternGroupsModel
     */
    public initializeForm(patternGroupsModel: PromotionMessagePatternGroupModel[], isClone: boolean): number {
        let msgCount = 0;
        for (let i = 0; i < patternGroupsModel.length; i++) {
            let patternGroupModel = patternGroupsModel[i];
            if (isClone) {
                patternGroupModel.Id = 0;
                patternGroupModel.MarketGroupId = 0;
                patternGroupModel.Markets.forEach(m => {
                    m.Id = 0;
                });
            }
            let patternGroups = this.PatternGroups;
            let patternGrpCtrl = patternGroups.at(i) as FormGroup;

            let allIds = patternGroupModel.MessagePatterns.filter(p => p.PromotionMessageId !== undefined).map(p => p.TempMessageId);
            msgCount = msgCount + new Set(allIds).size;

            if (!patternGrpCtrl) {
                patternGrpCtrl = this.createPatternGroupFormGroup(patternGroupModel);
                patternGroups.push(patternGrpCtrl);
            } else {
                patternGrpCtrl.patchValue({
                    Id: patternGroupModel.Id,
                    PromotionId: patternGroupModel.PromotionId,
                    Name: patternGroupModel.Name,
                    MarketGroupId: patternGroupModel.MarketGroupId,
                    BusinessIdentity: patternGroupModel.BusinessIdentity,
                    PatternGroupType: patternGroupModel.PatternGroupType
                });
            }

            //init message patterns
            let patternArray = patternGrpCtrl.get("MessagePatterns") as FormArray;
            while (patternArray.length > 0) {
                patternArray.removeAt(0);
            }

            //set IsEmpty to ture if promomtionMessagId of MessagePatern == null
            patternGroupModel.MessagePatterns.map((msgPattern: PromotionMessagePatternUIModel) => {
                // empty messages have undefined id, leave those alone when cloning
                if (isClone) {
                    msgPattern.Id = 0;
                    msgPattern.PromotionMessagePatternGroupId = 0;
                    if (msgPattern.PromotionMessageId !== undefined) {
                        msgPattern.PromotionMessageId = 0;
                    }
                }

                if (msgPattern.PromotionMessageId === undefined) {
                    msgPattern.IsEmpty = true;
                    msgPattern.MessageName = this.emptyPositionMessageName;
                } else {
                    msgPattern.IsEmpty = false;
                }
                if (patternGroupModel.PatternGroupType === "PriorityMessage") {
                    patternArray.push(this.createPriorityFormGroup(patternGrpCtrl, msgPattern));
                }
                else {
                    patternArray.push(this.createPatternFormGroup(patternGrpCtrl, msgPattern));
                }
            });


        }
        return msgCount;
    }

    private createPatternGroupFormGroup(patternGroup: PromotionMessagePatternGroupModel): FormGroup {
        let patternGroupCtrl = this.fb.group({
            Id: this.fb.control(patternGroup.Id),
            PromotionId: this.fb.control(patternGroup.PromotionId),
            Name: this.fb.control(patternGroup.Name),
            Markets: this.fb.array([]),
            MarketGroupId: this.fb.control(patternGroup.MarketGroupId),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity),
            PatternGroupType: this.fb.control(patternGroup.PatternGroupType),
            TempPatternGroupId: this.tempPatternGroupId++
        });

        patternGroupCtrl.addControl("MessagePatterns", this.fb.array([]));
        return patternGroupCtrl;
    }

    private createPatternFormGroup(patternGroup: FormGroup, pattern: PromotionMessagePatternUIModel): FormGroup {
        let inputName = pattern.IsEmpty ? "" : this.msgHtmlName + this.msgIndexer++;
        let ctrl = new FormGroup({
            Id: this.fb.control(pattern.Id),
            MessageName: this.fb.control({ value: pattern.MessageName, disabled: pattern.IsEmpty }, [Validators.required, Validators.maxLength(350)], [this.checkMessageName(patternGroup)]),
            PromotionMessageId: this.fb.control(pattern.PromotionMessageId),
            PromotionMessagePatternGroupId: this.fb.control(pattern.PromotionMessagePatternGroupId),
            BusinessIdentity: this.fb.control(pattern.BusinessIdentity),
            IsEmpty: this.fb.control(pattern.IsEmpty),
            TempMessageId: this.fb.control(pattern.TempMessageId),
            Ordinal: this.fb.control(pattern.Ordinal),
            InputName: this.fb.control(inputName),
            IsCdfEnabled: this.fb.control(pattern.IsCdfEnabled || false)

        });

        this.addMsgNameChangeListener(patternGroup, ctrl);
        return ctrl;
    }

    private createPriorityFormGroup(patternGroup: FormGroup, pattern: any): FormGroup {
        let inputName = pattern.IsEmpty ? "" : this.msgHtmlName + this.msgIndexer++;
        let marketCtrl = this.marketService.newMarketFormGroup(1);
        let ctrl = new FormGroup({
            Id: this.fb.control(pattern.Id),
            MessageName: this.fb.control({ value: pattern.MessageName, disabled: pattern.IsEmpty }, [Validators.required, Validators.maxLength(350)], [this.checkMessageName(patternGroup)]),
            PromotionMessageId: this.fb.control(pattern.PromotionMessageId),
            PromotionMessagePatternGroupId: this.fb.control(pattern.PromotionMessagePatternGroupId),
            BusinessIdentity: this.fb.control(pattern.BusinessIdentity),
            IsEmpty: this.fb.control(pattern.IsEmpty),
            TempMessageId: this.fb.control(pattern.TempMessageId),
            Ordinal: this.fb.control(pattern.Ordinal),
            InputName: this.fb.control(inputName),
            IsCdfEnabled: this.fb.control(pattern.IsCdfEnabled || false),
            MarketGroupId: this.fb.control(pattern.MarketGroupId),
            Markets: this.fb.array(pattern.Markets || marketCtrl)
        });

        this.addMsgNameChangeListener(patternGroup, ctrl);
        return ctrl;
    }

    public newPatternFormGroup(patternGroup: FormGroup, isEmptyPosition: boolean = false, isFirst: boolean = false): FormGroup {
        let msgName = isEmptyPosition ? this.emptyPositionMessageName : isFirst ? this.parent.value.Name : "";
        let msgId = isEmptyPosition ? undefined : 0;
        let tempMsgId = isEmptyPosition ? undefined : this.tempMessageId++;

        let patternMsg = {
            Id: 0,
            MessageName: msgName,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            PromotionMessageId: msgId,
            PromotionMessagePatternGroupId: 0,
            Ordinal: this.getNextPatternOrdinal(patternGroup),
            TempMessageId: tempMsgId,
            IsEmpty: isEmptyPosition,
            IsCdfEnabled: !isEmptyPosition
        } as PromotionMessagePatternUIModel;

        return this.createPatternFormGroup(patternGroup, patternMsg);
    }


    public newPriorityFillGroup(useMessagePattern: boolean, isFirst: boolean = false): FormGroup {
        let marketCtrl = this.marketService.newMarketFormGroup(1);
        let patternType = "PriorityMessage";
        let patternGroup = this.fb.group({
            Id: this.fb.control(0),
            PromotionId: this.fb.control(0),
            Name: this.fb.control(""),
            Markets: this.fb.control([]),
            MarketGroupId: this.fb.control(0),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity),
            PatternGroupType: this.fb.control(patternType),
            TempPatternGroupId: this.tempPatternGroupId++,
            IsCdfEnabled : true
        });
        patternGroup.addControl("MessagePatterns", this.fb.array([this.newPriorityFillFormGroup(patternGroup, isFirst)]));
        return patternGroup;
    }

    public newPriorityFillFormGroup(patternGroup: FormGroup, isFirst: boolean = false): FormGroup {
        let msgName = isFirst ? this.parent.value.Name : "";
        let msgId =  0;
        let tempMsgId = this.tempMessageId++;
        //let marketCtrl = ;
        let patternMsg = {
            Id: 0,
            MessageName: msgName,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            PromotionMessageId: msgId,
            PromotionMessagePatternGroupId: 0,
            Ordinal: this.getNextPatternOrdinal(patternGroup),
            TempMessageId: tempMsgId,
            IsCdfEnabled: true,
            Markets: []
        } as PromotionMessagePatternUIModel;

        return this.createPriorityFormGroup(patternGroup, patternMsg);
    }

    public newPatternGroup(useMessagePattern: boolean, isFirst: boolean = false): FormGroup {
        let marketCtrl = this.marketService.newMarketFormGroup(1);
        let patternType = useMessagePattern ? "MultiMessage" : "SingleMessage";
        let patternGroup = this.fb.group({
            Id: this.fb.control(0),
            PromotionId: this.fb.control(0),
            Name: this.fb.control(""),
            Markets: this.fb.array([marketCtrl]),
            MarketGroupId: this.fb.control(0),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity),
            PatternGroupType: this.fb.control(patternType),
            TempPatternGroupId: this.tempPatternGroupId++
        });

        patternGroup.addControl("MessagePatterns", this.fb.array([this.newPatternFormGroup(patternGroup, false, isFirst)]));

        return patternGroup;
    }

    public addMsgNameChangeListener(patternGroup: FormGroup, patternCtrl: AbstractControl) {
        let messageName = patternCtrl.get("MessageName") as FormControl;
        messageName.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(msg => {
            //when change message name after clone, need to mark the message name control touch for validation
            patternCtrl.get("MessageName").markAsTouched();
            let msgPatterns = this.MessagePatterns(patternGroup);
            let pattern = patternCtrl.value;
            msgPatterns.controls.forEach(ptn => {
                if (ptn.value.TempMessageId === pattern.TempMessageId && ptn.value.MessageName !== msg) {
                    ptn.patchValue({
                        MessageName: msg
                    });
                }
            });
            const tempId = patternCtrl.get("TempMessageId").value;
            this.messageNameChanged.emit(<MessageNameChangeModel>{ MessageName: msg, TempMessageId: tempId });
        });

        //Add instant value change check for removing returns
        messageName.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
            this.DisableReturn(messageName, value);
        });
    }

    public getMessageSuggestions(): string[] {
        let suggestions: string[] = [];
        let patternGroups = this.PatternGroups.value;

        if (patternGroups[0].PatternGroupType === "MultiMessage") {
            suggestions = patternGroups.reduce((accumlator, current) => {
                return accumlator.concat(current.MessagePatterns.filter((pattern: PromotionMessagePatternUIModel) => !pattern.IsEmpty).map(msg => msg.MessageName));
            }, []);
        }
        if (patternGroups[0].PatternGroupType === "SingleMessage") {
            suggestions = patternGroups.map(msgPattern => msgPattern.MessagePatterns.map((msgName) => msgName.MessageName));
        }


        return suggestions.filter((value, index, self) => {
            return self.indexOf(value) === index; // return unique name
        });
    }

    public getNextPatternOrdinal(patternGroup: FormGroup) {
        if (!this.parent.controls.messageForm || !this.PatternGroups) {
            return 1;
        }

        let patterns = patternGroup.value.MessagePatterns;
        if (!patterns || patterns.length <= 0) {
            return 1;
        }
        return Math.max(...patterns.map((pattern) => pattern.Ordinal)) + 1;
    }

    private checkMessageName(patternGroup: FormGroup): AsyncValidatorFn {
        let promoId = this.parent.get("Id").value;
        let campaignId = this.parent.get("CampaignId").value;
        let useMessagePattern = patternGroup.value.PatternGroupType === "MultiMessage";
        return (control: AbstractControl) => {
            return observableTimer(500).pipe(switchMap(() => {

                if (!control || !control.touched || control.value.length <= 0 || control.value === this.emptyPositionMessageName) {
                    // Don't bother checking, return all is okay.
                    return observableOf(null);
                }
                let checkeMessageModel = {
                    PromotionId: promoId,
                    CampaignId: campaignId,
                    MessageName: control.value
                };
                return observableFrom(this.promotionMessageService.checkUniqueMessageName(checkeMessageModel)).pipe(map(
                    result => {
                        if (result) {
                            let filteredList: string[];
                            //Now check that we dont have any duplicate message names on the active promo
                            if (useMessagePattern) {
                                //filter out no size row too
                                filteredList = patternGroup.value.MessagePatterns.filter(ptn => ptn.MessageName && ptn.MessageName.toLowerCase() === control.value.toLowerCase())
                                    .map(ptn => ptn.TempMessageId)
                                    .filter((value, index, self) => {
                                        return self.indexOf(value) === index; // return unique tempMessageId
                                    });

                            } else {
                                filteredList = this.PatternGroups.value.reduce((accumlator, current) => {
                                    return accumlator.concat(current.MessagePatterns.filter(pattern => pattern.MessageName.toLowerCase() === control.value.toLowerCase()).map(msg => msg.MessageName));
                                }, []);
                            }

                            if (filteredList.length > 1) {
                                if (useMessagePattern) {
                                    return { "patternNameExists": true };
                                } else {
                                    return { "messageNameUniqueToCampaign": true };
                                }
                            }
                        } else {
                            return { "messageNameUniqueToCampaign": true };
                        }
                    }
                ));
            }));
        };
    }

    /**
     * Will update the given patternGroup markets with the passed in market list
     * @param patternGroup - Pattern group that has the markets to be updated
     * @param markets - List of markets to use for updating the patternGroup markets.
     */
    public updateMarketCtrlAfterCalculation(patternGroup: FormGroup, markets: MarketModel[]) {
        let marketItems = (<FormArray>patternGroup.controls.Markets);
        let marketDictionary = [];
        this.marketService.updateMarkets(marketItems, markets, marketDictionary);
    }

    public updateMarketCtrlAfterCalculationPriorityFill(marketItems: FormArray, markets: MarketModel[]) {
        let marketDictionary = [];
        this.marketService.updateMarkets(marketItems, markets, marketDictionary);
    }

    public getCurrentMessagesFromForm(promoForm: FormGroup): PromotionMessagePatternModel[] {
        return this.getCurrentMessages(promoForm.value.messageForm.PatternGroups);
    }

    public getCurrentMessages(patternGroups: PromotionMessagePatternGroupModel[]): PromotionMessagePatternModel[] {
        let currentMessages = [] as PromotionMessagePatternModel[];

        patternGroups.forEach((patternGroup) => {
            patternGroup.MessagePatterns.forEach((message: PromotionMessagePatternUIModel) => {
                if (!message.IsEmpty) {
                    currentMessages.push(message);
                }
            });
        });
        return currentMessages;
    }

    public getPatternGroupForMessageFromForm(promoForm: FormGroup, tempMessageId: number): PromotionMessagePatternGroupModel {
        return this.getPatternGroupForMessage(promoForm.value.messageForm.PatternGroups, tempMessageId);
    }

    public getPatternGroupForMessage(patternGroups: PromotionMessagePatternGroupModel[], tempMessageId: number): PromotionMessagePatternGroupModel {
        let result: PromotionMessagePatternGroupModel;
        patternGroups.some((patternGroup) => {
            let messageFound = false;
            patternGroup.MessagePatterns.some((message) => {
                if (message.TempMessageId === tempMessageId) {
                    result = patternGroup;
                    // If found, 'some' loop will stop.
                    messageFound = true;
                }
                // Keep the loop going
                return messageFound;
            });
            // If found, 'some' loop will stop.
            return messageFound;
        });

        return result;
    }

    /**
     * Get a patternGroup control for a given TempPatternGroupId
     * @param promoForm
     * @param TempPatternGroupId
     */
    public getPatternGroup(promoForm: FormGroup, TempPatternGroupId: number): FormGroup {
        let patternGroupCtrls = this.PatternGroups;

        for (let i = 0; i < patternGroupCtrls.controls.length; i++) {
            let patternGroupCtrl = patternGroupCtrls.controls[i];
            if (patternGroupCtrl.value.TempPatternGroupId === TempPatternGroupId) {
                return patternGroupCtrl as FormGroup;
            }
        }
    }

    /**
     * Get a current list of patternGroups as the PromotionmessagePatternGroupModel
     * @param promoForm
     */
    public getCurrentPatternGroupsFromForm(promoForm: FormGroup): PromotionMessagePatternGroupModel[] {
        if (!this.PatternGroups) {
            return [];
        }
        return this.PatternGroups.controls.map(item => item.value as PromotionMessagePatternGroupModel);
    }

    get PatternGroups(): FormArray {
        return this.parent.controls.messageForm.get("PatternGroups") as FormArray;
    }

    public MessagePatterns(patternGroup: FormGroup): FormArray {
        return patternGroup.get("MessagePatterns") as FormArray;
    }

    public MessageMarkets(patternGroup: FormGroup): FormArray {
        return patternGroup.get("Markets") as FormArray;
    }

    public getFocusableMessageInput() {
        return this.msgHtmlName + (this.msgIndexer - 1);
    }

    public CheckIfInvalid(control: FormControl): boolean {
        return control.invalid && (control.dirty || control.touched);
    }

    public PlacementsExist(promoForm: FormGroup): boolean {
        const holderPlacements = promoForm.get("placementsFrmArr") as FormArray;
        const elementPlacements = promoForm.get("placementElementsFrmArr") as FormArray;

        return (holderPlacements && holderPlacements.length > 0) || (elementPlacements && elementPlacements.length > 0);
    }

    public DisableReturn(existingValue: FormControl, newValue: string) {
        //Look for the new line and just set value back to its original value
        if (newValue && newValue.indexOf("\n") > -1) {
            existingValue.setValue(newValue.replace("\n", ""));
        }
    }

    public patchMessageIdsAfterSave(saveModel: PromotionSaveModel) {
        // Create dictionary of Message names and Ids
        let msgDict: { [name: string]: number } = {};
        saveModel.PatternGroups.forEach(pg => {
            pg.MessagePatterns.forEach(p => {
                if (!msgDict[p.MessageName]) {
                    msgDict[p.MessageName] = p.PromotionMessageId;
                }
            });
        });

        this.PatternGroups.controls.forEach((pgCtrl: FormGroup) => {
            this.MessagePatterns(pgCtrl).controls.forEach(msgCtrl => {
                let msgName = msgCtrl.get("MessageName").value;
                if (msgName && msgName !== this.emptyPositionMessageName && msgDict[msgName]) {
                    msgCtrl.patchValue({ PromotionMessageId: msgDict[msgName] }, { emitEvent: true });
                }
            });
        });
    }
}
