import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { LocationModel } from "../../model/LocationModel";
import { environment } from "../../../../environments/environment";


@Injectable()
export class LocationByLocationGroupService extends IpsBaseWebService {
    public LocationId: string;
    private route: string;

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Location", "Profile", "v1");
        this.route = `${this.urlApiBaseProductPlusVersion}LocationGroup/{id}/Location`;
    }

    getByFixture(id: string, option: any) {
        this.LocationId = id;
        return this.getByRoute(this.route.replace("{id}", id));
    }

    headByLocationGroup(id: string, subGroupId: string) {
        return this.headByRoute(this.route.replace("{id}", id) + "?subGroupId=" + subGroupId);
    }

    // override ipsBaseWebService search method
    search<T>(searchInfo: SearchInfo, route: string): Promise<T> {
        return this.searchByRelation(searchInfo, "LocationGroup/" + searchInfo.id + "/Location" + "/Search");
    }

}
