import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { HolderVersionService } from "../../service/holder-version.service";
import { HolderVersionInfoModel } from "../../model/HolderVersionInfoModel";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { NgForm } from "@angular/forms";
import { TemplateFormBase } from "../../../shared/templateFormBase";

@Component({
  selector: "app-holder-version-edit",
  templateUrl: "./holder-version-edit.component.html",
  styleUrls: ["./holder-version-edit.component.scss"]
})
export class HolderVersionEditComponent extends TemplateFormBase implements OnInit {
    @ViewChild("holderversionForm") public holderversionForm: NgForm;

    public loaded: boolean;
    public promise: Promise<any>;
    public versionList: HolderVersionInfoModel[];
    public originalName: string;

    public deleteBodyPrompt: string;
    public accessHolderVersionInfo: string;
    public breadCrumbLabel: string;
    public holderversion: HolderVersionInfoModel;
    public saveError: string;

    @Input() stateParams;
    constructor(private holderVersionService: HolderVersionService, private ipsMessage: IpsMessageService, private $state: StateService,
                private trans: Transition, private translateService: TranslateService, private pageTitleService: PageTitleService) {
        super();
    }

    ngOnInit() {
        let holderversionId = Number(this.trans.params().id);
        let pageTitle = holderversionId > 0 ? "EDIT_HOLDER_VERSION_INFO" : "CREATE_HOLDER_VERSION_INFO";

        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        // Clear out current location, fill in defaults
        this.holderversion = { Id: holderversionId } as HolderVersionInfoModel;
        this.getVersionList();

        // If we got an ID to load, load it.
        // If paramId is not a number it will evaluate to false
        if (holderversionId > 0) {
            //Initial call to populate screen on load
            this.getVersionInfo(holderversionId);
        } else {
            this.loaded = true;
        }

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
        super.setFormPristine(this.holderversionForm);
    }

    private translateText() {
        this.translateService.get(["ACCESSING_HOLDER_VERSION_INFO", "PROMPT_DELETE_BODY", "DUPLICATE_VERSION"]).subscribe((res: [string]) => {
            this.accessHolderVersionInfo = res["ACCESSING_HOLDER_VERSION_INFO"];
            this.deleteBodyPrompt = res["PROMPT_DELETE_BODY"];
            this.saveError = res["DUPLICATE_VERSION"];
        });
    }

    //Private Functions
    private deleteVersionInfo (): Promise<void> {
        let id = this.holderversion.Id;
        return this.holderVersionService.delete(id);
    }

    private getVersionInfo (id: number) {
        this.loaded = false;

        this.promise = this.holderVersionService.get(id).then((response) => {
            Object.assign(this.holderversion, response);
            this.originalName = this.holderversion.Name;
            this.loaded = true;
        });
    }

    private saveVersionInfo (): Promise<any> {
        let id = this.holderversion.Id;
        if (id > 0) {
            return this.holderVersionService.put<HolderVersionInfoModel>(this.holderversion).then((response: HolderVersionInfoModel) => {
                this.holderversionForm.form.markAsPristine();
            });
        } else if (this.holderversion.Id === 0) {
            return this.holderVersionService.post<HolderVersionInfoModel>(this.holderversion).then((response: HolderVersionInfoModel) => {
                this.holderversionForm.form.markAsPristine();
            });
        }

    }

    private checkUnique(version: string) {
        let item = this.versionList.filter(x => x.Name && x.Name.toLocaleLowerCase().trim() === version.toLocaleLowerCase().trim());
            if (item.length === 0) {
                return true;
            } else {
                return false;
            }
    }

    private getVersionList() {
        return this.holderVersionService.getList<HolderVersionInfoModel[]>()
            .then((response) => {
                this.versionList = response;
            });
    }

    // Public Functions used by view
    public deleteVersionInfoPrompt () {
        let translated: string = this.deleteBodyPrompt;
        translated = IpsString.Format(translated, this.holderversion.Name) as string;
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deleteVersionInfo(), progressMessage: "DELETING" })
        .then((result) => {
            if (result) {
                this.$state.go("main.storeProfileHolderVersion.search");
            }
        })
        .catch(() => {
            // rejection
        });
    }

    public saveVersionInfoPrompt() {
        let unique = this.checkUnique(this.holderversion.Name);
        if ((unique && this.holderversion.Id === 0) || (this.holderversion.Id > 0 && (!unique && this.originalName === this.holderversion.Name)) || (this.holderversion.Id > 0 && unique)) {
            return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveVersionInfo(), progressMessage: "SAVING" }).then((result) => {
                if (result) {
                    this.$state.go("main.storeProfileHolderVersion.search");
                }
            });
        } else {
            this.ipsMessage.error(this.saveError);
        }
    }

}
