<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:'{{'ACCESSING_ALTERNATE_ADDRESS_INFO' | translate}}'}">
        <form #alternateAddressForm="ngForm" id="ramiInfoInput" class="form-horizontal updateSection" name="alternateAddressForm" novalidate ipsUnsavedWarningForm role="form">
            <div [hidden]="!loaded">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-4">
                        <div class="form-group">
                            <label for="AddressIdentifier" class="required" translate>ID</label>
                            <input type="text" name="AddressIdentifier" id="AddressIdentifier" [(ngModel)]="alternateAddress.AddressIdentifier" #AddressIdentifier="ngModel"
                                   class="form-control form-control-lg" placeholder="{{ 'ENTER_ADDRESS_ID' | translate}}"
                                   required maxlength="50" appValidation>
                            <div *ngIf="AddressIdentifier.errors" class="invalid-feedback">
                                <div *ngIf="AddressIdentifier.errors.required" translate>
                                    PLEASE_ENTER_ADDRESS_ID
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                        <div class="form-group">
                            <label class="required" for="name" translate>NAME</label>
                            <input type="text" name="name" id="name" [(ngModel)]="alternateAddress.Name" #Name="ngModel"
                                   class="form-control form-control-lg" placeholder="{{ 'ENTER_ADDRESS_NAME' | translate}}"
                                   required maxlength="50" appValidation>
                            <div *ngIf="Name.errors" class="invalid-feedback">
                                <div *ngIf="Name.errors.required" translate>
                                    PLEASE_ENTER_ADDRESS_NAME
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                        <div class="form-group">
                            <label for="pointOfContact" translate>POINT_OF_CONTACT</label>
                            <input type="text" name="pointOfContact" id="pointOfContact" [(ngModel)]="alternateAddress.PointOfContact" class="form-control form-control-lg" placeholder="{{ 'ENTER_POINT_OF_CONTACT' | translate}}">
                        </div>
                    </div>
                </div>

                <hr />

                <app-address-edit *ngIf="loaded" [address]="alternateAddress" [showFax]="false" [showEmail]="false"></app-address-edit>

                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                            <label class="" for="notes" translate>NOTES</label>
                            <textarea [(ngModel)]="alternateAddress.Notes" class="form-control form-control-lg input-full" name="notes" id="notes" cols="30" rows="3" maxlength="500" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                        </div>
                    </div>
                </div>

                <hr />

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveLocation"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="validateAddressForLocationSave(false)"
                                    [disabled]="alternateAddressForm.form.invalid || alternateAddressForm.form.pristine"
                                    [ngClass]="{'btn-default': alternateAddressForm.form.invalid || alternateAddressForm.form.pristine}" translate>
                                SAVE_ALTERNATE_ADDRESS
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveReturnLocation"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="validateAddressForLocationSave(true)"
                                    [disabled]="alternateAddressForm.form.invalid || alternateAddressForm.form.pristine"
                                    [ngClass]="{'btn-default': alternateAddressForm.form.invalid || alternateAddressForm.form.pristine}" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="cancelLocation" class="btn btn-default btn-lg btn-block" uiSref="main.storeProfileAlternateAddress.search" translate>CANCEL</button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="deleteLocation"
                                    class="btn btn-lg btn-block alert-danger"
                                    (click)="deleteLocationPrompt()"
                                    ips-unsaved-warning-clear
                                    [disabled]="!alternateAddress.Id"
                                    [ngClass]="{'btn-default': !alternateAddress.Id}">
                                {{'DELETE_ALTERNATE_ADDRESS' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_ALTERNATE_ADDRESS_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
