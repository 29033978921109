<div>
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0" translate>CHOOSE_SHIPMENT</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive tab-search-modal shipment">
            <ips-list-search headerLabel=""
                             [searchLabel]="searchLabel"
                             [searchLabelPlural]="searchLabelPlural"
                             labelLoading="{{'ACCESSING_INFO' | translate }}"
                             labelLoadingError="{{'ERROR_ACCESSING_INFO' | translate }}"
                             [listData]="listSearch"
                             (onListDataChange)="getListData($event.search)"
                             [busy]="busy"
                             [scroller]="true"
                             [hideHeader]="true"
                             [promise]="promise"
                             [autoFocus]="true"
                             [isModal]="true"
                             [query]="query">
                <ng-template #listSearchItemTemplate let-result="result">
                    <div class="card ips-shadow my-1 subgroup-card d-flex flex-row align-items-center mt-3">
                        <div class="p-2 pl-3 flex-grow-1 clicker" (click)="shipmentClicked(result)">
                            <p><strong translate>SHIPMENT_NUMBER</strong>: {{result.ShipmentNumber}}</p>
                            <p><strong translate>DATE_SHIPPED</strong>: {{result.DateShipped}}</p>
                            <p><strong translate>SHIPMENT_STATUS</strong>: {{result.ShipmentStatus}}</p>
                        </div>
                    </div>
                </ng-template>
            </ips-list-search>
        </div>
    </form>
</div>
