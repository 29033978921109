import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, SearchInfo, IpsBaseWebService, QueryParamItem } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ProductAdminService extends IpsBaseWebService {

    constructor(private http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("ProductAdmin", "Profile", "v1");
    }

    public search(searchInfo: SearchInfo): Promise<any> {
        return this.searchByRelation(searchInfo, "ProductAdmin/Search");
    }

    public get(id: number): Promise<any> {
        const searchInfo = <SearchInfo>{ searchText: "", additionalQueryParams: <QueryParamItem[]>[{ param: "Estimate", paramValue: id.toString() }] };
        searchInfo.chunkIndex = 0;
        searchInfo.recordCount = 10;

        return this.searchByRelation(searchInfo, "Estimate/Search");
    }
}
