var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var CampaignService = /** @class */ (function (_super) {
    __extends(CampaignService, _super);
    function CampaignService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.init("Campaign", "Campaign", "v1");
        return _this;
    }
    CampaignService.prototype.checkCampaignName = function (campaign) {
        var name = encodeURIComponent(campaign.Name);
        var route = "Campaign/Name/available?Name=" + name + "&BusinessIdentity=" + this.activeProfileService.businessIdentity;
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    CampaignService.prototype.getCountByLocationSubGroup = function (locationSubGroupId) {
        var route = "LocationSubGroup/" + locationSubGroupId + "/Campaign";
        return this.headByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    CampaignService.prototype.getCountByLocationGroup = function (locationGroupId) {
        var route = "LocationGroup/" + locationGroupId + "/Campaign";
        return this.headByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    CampaignService.prototype.getMostRecentActiveCampaign = function () {
        var route = "Campaign/MostRecentActive";
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    CampaignService.prototype.hasAlternateAddresses = function () {
        var route = "Campaign/HasAlternateAddresses";
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    CampaignService.prototype.getContentListShippingGroup = function (campaignId) {
        var route = "Campaign/" + campaignId + "/ContentListShippingGroup";
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    CampaignService.prototype.generateSignPlan = function (locationId, campaignId) {
        var route = "" + this.urlApiBasePlusController + campaignId + "/GenerateSignPlan/" + locationId;
        return this.getByRoute(route);
    };
    CampaignService.prototype.getSignPlanProblems = function (campaignId) {
        var route = "" + this.urlApiBasePlusController + campaignId + "/SignPlanProblems";
        return this.getByRoute(route);
    };
    CampaignService.prototype.generateImagineDemo = function (locationId, reportDate) {
        var route = this.urlApiBasePlusController + "GenerateUpDownReport/" + locationId + "?reportDate=" + encodeURIComponent(reportDate.toISOString());
        return this.getByRoute(route);
    };
    CampaignService.prototype.generateUpDown = function (locationId, campaignId) {
        var route = "" + this.urlApiBasePlusController + campaignId + "/GenerateUpDownReport/" + locationId;
        return this.getByRoute(route);
    };
    CampaignService.prototype.obsoleteCampaign = function (locationId, isObsolete) {
        var route = "Campaign/Obsolete";
        var model = {
            "Id": locationId,
            "IsObsolete": isObsolete,
            "BusinessIdentity": this.activeProfileService.businessIdentity
        };
        return this.postByRoute("" + this.urlApiBaseProductPlusVersion + route, model);
    };
    return CampaignService;
}(IpsBaseWebService));
export { CampaignService };
