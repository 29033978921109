import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { ItemDataModel } from "../model/ItemDataModel";
import { CartItemPostData } from "../model/CartDataModel";
import * as uuid from "uuid";

@Injectable()
export class PunchOutService extends IpsBaseWebService {

    constructor(http: HttpClient, public activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Punchout", "Shop", "v1");
    }

    private setLocalStorageData(item: CartItemPostData): string {
        //save data to local storage for creating a cart item after punchout
        const key = uuid();
        localStorage.setItem(key, JSON.stringify(item));

        return key;
    }

    public getCustomizeUrl(item: CartItemPostData): Promise<string> {
        const key = this.setLocalStorageData(item);

        const addToCartUrl = `${environment.redirectUrl}shop/xmpieaddtocart?ItemKey=${key}`;
        const canceltUrl = `${environment.redirectUrl}shop/my-products`;
        //tslint:disable-next-line: max-line-length
        const route = `${this.urlApiBasePlusController}GetCustomizeUrl?uStoreId=${item.XMPieStoreId}&pODProductId=${item.XMPieProductId}&userName=${this.activeProfileService.profile.UserName}&BusinessIdentity=${this.activeProfileService.profile.businessIdentity}&logoutUrl=${canceltUrl}&cancelUrl=${canceltUrl}&addToCartUrl=${addToCartUrl}`;
        return this.getByRoute(route);
    }

    public getFinalizeUrl(item: CartItemPostData, orderId: number, userName: string): Promise<string> {
        const key = this.setLocalStorageData(item);

        const addToCartUrl = encodeURIComponent(`${environment.redirectUrl}shop/xmpieaddtocart?ItemKey=${key}&reorder=true&originalOrderId=${orderId}`);
        const canceltUrl = `${environment.redirectUrl}shop/myorders/view/${orderId}`;
        //tslint:disable-next-line: max-line-length
        const route = `${this.urlApiBasePlusController}getFinalizeUrl?OrderProductId=${item.OrderProductId}&userName=${userName}&BusinessIdentity=${this.activeProfileService.profile.businessIdentity}&logoutUrl=${canceltUrl}&cancelUrl=${canceltUrl}&addToCartUrl=${addToCartUrl}`;
        return this.getByRoute(route);
    }

    public reorder(orderProductId: number, orderId: number): Promise<any> {
        const route = `${this.urlApiBasePlusController}Reorder`;
        const data = { OrderProudctId: orderProductId, OrderId: orderId };
        return this.postByRoute(route, data);

    }

    public getCustomizeUrlByKey(key: string): Promise<string> {
        const localStorageData = localStorage.getItem(key);

        if (localStorageData) {
            const item = <CartItemPostData>JSON.parse(localStorageData);

            if (item) {
                return this.getCustomizeUrl(item);
            }
        }

        return new Promise((resolve, reject) => {
            resolve(null);
        });
    }
}
