var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var CampaignUpDownAnalyticsService = /** @class */ (function (_super) {
    __extends(CampaignUpDownAnalyticsService, _super);
    function CampaignUpDownAnalyticsService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.init("Campaign", "Campaign", "v1");
        return _this;
    }
    CampaignUpDownAnalyticsService.prototype.headByUpDownUser = function (locationId, startDate, endDate) {
        var route;
        if (startDate && endDate) {
            route = this.urlApiBaseProductPlusVersion + "Campaign/GetUpDownReportUser/" + locationId + "?startDate=" + encodeURIComponent(startDate.toUTCString()) + "&endDate=" + encodeURIComponent(endDate.toUTCString());
        }
        else {
            route = this.urlApiBaseProductPlusVersion + "Campaign/GetUpDownReportUser/" + locationId;
        }
        return this.headByRoute(route);
    };
    CampaignUpDownAnalyticsService.prototype.headByAnalyticsCount = function () {
        var route = this.urlApiBaseProductPlusVersion + "Campaign/GetUpDownReportAnalytics/Count";
        return this.headByRoute(route);
    };
    return CampaignUpDownAnalyticsService;
}(IpsBaseWebService));
export { CampaignUpDownAnalyticsService };
