var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var MarketGroupService = /** @class */ (function (_super) {
    __extends(MarketGroupService, _super);
    function MarketGroupService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("MarketGroup", "Campaign", "v1");
        return _this;
    }
    MarketGroupService.prototype.calculateAudienceLocationBalance = function (model) {
        var route = this.urlApiBaseProductPlusVersion + "MarketGroup/CalculateLocationBalance";
        return this.postByRoute(route, model);
    };
    MarketGroupService.prototype.calculateLocationBalanceAndList = function (requestModel) {
        var route = this.urlApiBaseProductPlusVersion + "MarketGroup/CalculateLocationBalanceAndList";
        return this.postByRoute(route, requestModel);
    };
    MarketGroupService.prototype.calculateAudienceLocationBalanceList = function (requestModel) {
        var route = this.urlApiBaseProductPlusVersion + "MarketGroup/MarketLocationBalanceList";
        return this.postByRoute(route, requestModel);
    };
    MarketGroupService.prototype.checkForCircularMarketGroup = function (requestModel) {
        var route = this.urlApiBaseProductPlusVersion + "MarketGroup/CheckForCircularMarketGroup";
        return this.postByRoute(route, requestModel);
    };
    MarketGroupService.prototype.deleteCheck = function (id) {
        var route = this.urlApiBaseProductPlusVersion + "MarketGroup/" + id + "/DeleteCheck";
        return this.getByRoute(route);
    };
    return MarketGroupService;
}(IpsBaseWebService));
export { MarketGroupService };
