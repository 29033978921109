import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { IpsMessageService } from "imagine-ui-ng-messaging";

export function validateAllFormFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup || control instanceof FormArray) {
            validateAllFormFields(control);
        }
    });
}

export function validateAllFormFieldsWithDialog(formGroup: FormGroup, ipsMessage: IpsMessageService, workDelegate: () => void): void {
    validateAllFormFields(formGroup);
    if (formGroup.valid) {
        workDelegate();
    } else {
        ipsMessage.error("INVALID_FORM_ENTRIES");
    }
}
