<!--The content below is only a placeholder and can be replaced.-->
<div class="login-page">
  <div id="layout-masthead">
    <header class="container-fluid clearfix">
      <div class="logo pull-left"></div>
    </header>
  </div>
  <div id="layout-content">
    <div id="content" class="container">
        <div class="row">
            <div class="col-12">
                <ui-view></ui-view>
            </div>
        </div>
    </div>
  </div>
  <div id="layout-footer">
    <footer class="login-footer">
      <div class="container text-center">
        <p class="crInfo mb-0">
            <img src="../../assets/img/logo_brand.png" alt="IMAGINE" />
        </p>
      </div>
    </footer>
  </div>
</div>
