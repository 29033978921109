/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@uirouter/angular/lib/directives/uiView.ngfactory";
import * as i2 from "@uirouter/angular/lib/directives/uiView";
import * as i3 from "@uirouter/core";
import * as i4 from "./store-profile-fixture.component";
var styles_StoreProfileFixtureComponent = [];
var RenderType_StoreProfileFixtureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StoreProfileFixtureComponent, data: {} });
export { RenderType_StoreProfileFixtureComponent as RenderType_StoreProfileFixtureComponent };
export function View_StoreProfileFixtureComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "ui-view", [], null, null, null, i1.View_UIView_0, i1.RenderType_UIView)), i0.ɵdid(1, 245760, null, 0, i2.UIView, [i3.UIRouter, "UIView.PARENT_INJECT", i0.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_StoreProfileFixtureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-store-profile-fixture", [], null, null, null, View_StoreProfileFixtureComponent_0, RenderType_StoreProfileFixtureComponent)), i0.ɵdid(1, 114688, null, 0, i4.StoreProfileFixtureComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StoreProfileFixtureComponentNgFactory = i0.ɵccf("app-store-profile-fixture", i4.StoreProfileFixtureComponent, View_StoreProfileFixtureComponent_Host_0, {}, {}, []);
export { StoreProfileFixtureComponentNgFactory as StoreProfileFixtureComponentNgFactory };
