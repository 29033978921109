<div class="col-md-12">
    <div class="row">
        <section class="col-12">
            <h3 class="title">
                <span class="header-label-imgn">{{'VIEW_SPACE' | translate}}</span>
                <a class="ml-2" uiSref="main.storeProfileSpace.edit" [uiParams] = "{id: myspace.Id}" ipsPermission="main.storeProfileSpace.edit" ngbTooltip="{{'EDIT' | translate}} {{myspace.Name}}" placement="bottom-right"><span class="fa fa fa-pencil editIcon"></span></a>
            </h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{ message:{{'ACCESSING_SPACE_INFO' | translate}}}">
        <div class="row" [hidden]="!loaded">
            <section class="col-12 col-md-6">
                <div class="form-group">
                    <div class="card">
                        <div class="card-header view-info-header">
                            {{myspace.Name}}
                        </div>
                        <div class="card-body">
                            <ul class="list-group long-text-wrap">
                                <li class="list-group-item">
                                    <div class="view-info-label" translate>NOTES_SPACE</div>
                                    <div class="view-info-format-imgn"> {{myspace.Notes}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <hr>
        <div class="row" [hidden]="!loaded" *ngIf="translated">
            <section class="col-12 col-md-6">
                <ips-list-search headerLabel="{{'RELATED_FIXTURES' | translate }}"
                                 [searchLabel]="fixtureLabel"
                                 [searchLabelPlural]="fixtureLabelPlural"
                                 labelLoading="{{'ACCESSING_FIXTURE_INFO' | translate }}"
                                 labelLoadingError="{{'ERROR_ACCESSING_FIXTURE_INFO' | translate }}"
                                 [listData]="fixtureSearchHelper"
                                 [busy]="fixtureSearchHelper.busy"
                                 (onListDataChange)="getFixtureList($event.search, myspace.Id)"
                                 section="main.storeProfileFixture"
                                 [scroller]=false
                                 quickRef=false
                                 itemClickPermission="main.storeProfileFixture.view"
                                 [promise]="fixtureSearchHelper.promise"></ips-list-search>
            </section>
            <section class="col-12 col-md-6">
                <ips-list-search headerLabel="{{'RELATED_LOCATIONS' | translate }}"
                                 [searchLabel]="locationLabel"
                                 [searchLabelPlural]="locationLabelPlural"
                                 labelLoading="{{'ACCESSING_LOCATION_INFO' | translate }}"
                                 labelLoadingError="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}"
                                 [listData]="locationSearchHelper"
                                 [busy]="locationSearchHelper.busy"
                                 (onListDataChange)="getLocationList($event.search, myspace.Id)"
                                 section="main.storeProfileLocation"
                                 [scroller]=false
                                 quickRef=false
                                 itemClickPermission="main.storeProfileLocation.view"
                                 [promise]="locationSearchHelper.promise"></ips-list-search>
            </section>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_SPACE_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
