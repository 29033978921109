
<div ipsPermission="{{listSearch.itemClickPermission}}" ipsPermissionLinkDisable="true" class="list-group-item no-border py-3">
    <!--Location group header-->
    <div class="row ips-search-result-big">
        <div class="col px-0">
            <a uiSref="main.storeProfileLocationGroup.view" [uiParams]="{id:result.Id}" class="no-underline">
                <div class="px-3 loc-label">
                    <div>
                        <span class="mr-2" innerHTML="{{ result.Name | highlight : search}}"></span>
                        <div class="location-group-rule-icon" [ngClass]="result.ruleClass" [ngbTooltip]="result.ruleTooltip" placement="bottom"></div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4 col-sm-3 col-md-2 col-lg-1 d-flex justify-content-end pl-0">
            <div ipsPermission="main.storeProfileLocationGroup.edit">
                <a uiSref="main.storeProfileLocationGroup.edit" [uiParams]="{id:result.Id}" class="no-underline">
                    <span class="fa fa-pencil mr-3"></span>
                </a>
            </div>
            <a id="collapse-button" (click)="activeCollapse(result)">
                <span [ngClass]="['text-right fa fa-chevron-down ips-ls-addIcon', isCollapsed ? '' : 'fa-flip-vertical']"></span>
            </a>
            </div>
        </div>

    <!--SubGroup list if not editing-->
    <div *ngIf="!editing" class="card mt-3" [ngbCollapse]="isCollapsed">
        <div class="card-body px-0">
            <div class="form-row">
                <div class="ips-loc-feature-pill text-center"><label class="view-info-label" translate>LOCATIONS</label></div>
                <div class="col pl-0"><label class="view-info-label" translate>FEATURE_OPTIONS</label></div>
            </div>

            <!--PendingAssignment count-->
            <div class="form-row mb-3" *ngIf="result.PendingAssignmentCount > 0">
                <div class="ips-loc-feature-pill text-center"><button class="btn badge-pill ips-pill ips-pill-pending" (click)="showLocations(result.Id, 0)">{{result.PendingAssignmentCount | number }}</button></div>
                <div class="col location-group-pending-assignment-label pl-0 d-flex align-items-center">
                    <a uiSref="main.storeProfileLocationGroup.view" [uiParams]="{id: result.Id}" class="no-underline">
                        <div class="location-group-pending-assignment-label" translate>PENDING_ASSIGNMENT</div>
                    </a>
                </div>
            </div>
            <div class="form-row mb-3" *ngFor="let subGroup of result.SubGroups">
                <div class="ips-loc-feature-pill text-center"><button class="btn badge-pill ips-pill" (click)="showLocations(result.Id, subGroup.Id)">{{subGroup.LocationCount | number}}</button></div>
                <div class="col pl-0 d-flex align-items-center">
                    <a uiSref="main.storeProfileLocationGroup.view" [uiParams]="{id: result.Id, subgroup: subGroup.Name}" class="no-underline">
                        <div innerHTML="{{ subGroup.Name | highlight : search}}"></div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <!--SubGroup list if editing-->
    <div *ngIf="editing" class="card mt-3" [ngbCollapse]="isCollapsed">
        <div class="card-body">

            <div class="form-row mb-3">
                <div class="col col-sm-6 col-md-5 col-lg-4 col-xl-3"><button type="button" [disabled]="optionsIsDisabled" class="btn btn-default btn-block"  [ngClass]="[optionsIsDisabled ? 'options-disabled' : '']" (click)="optionsClear(result)">{{optionsText}}</button></div>
            </div>

            <div class="form-row">
                <div class="ips-loc-feature-pill-edit text-center"><label class="view-info-label mb-0 pb-0" translate>ASSIGN</label></div>
                <div class="col pl-0"><label class="view-info-label" translate>FEATURE_OPTIONS</label></div>
            </div>

            <div class="form-row mb-3" *ngFor="let subGroup of result.SubGroups">
                <div class="ips-loc-feature-pill-edit text-center">
                    <input *ngIf="result.Rule === 'Single'" name="{{result.Name}}" type="radio" [value]="subGroup.Id" [(ngModel)]="result.selectedSubGroup" (ngModelChange)="subGroupRadioChanged(result)" />
                    <input *ngIf="result.Rule === 'Multiple'" name="{{result.Name}}" type="checkbox" [checked]="subGroup.LocationCount === 1" (change)="subGroupCheckChanged($event.target.checked, subGroup, result)" />
                </div>
                <div class="col pl-0"><a uiSref="main.storeProfileLocationGroup.view" [uiParams]="{id: result.Id, subgroup: subGroup.Name}" innerHTML="{{ subGroup.Name | highlight : search}}"></a></div>
            </div>
        </div>
    </div>
    <div *ngIf="editing">
        <!--Undo and Save buttons-->
        <div *ngIf="result.unSavedChanges">
            <div class="location-groupList-saveContainer">
                <div class="location-groupList-undoAll">
                    <i class="fa fa-reply fa-2x" aria-hidden="true" (click)="undoLocationGroupChanges(result);" ngbTooltip="{{'UNDO' | translate}}" placement="bottom"></i>
                </div>
                <div class="location-groupList-saveAll">
                    <button type="button" class="btn btn-default btn-block"
                            id="locationGroupBtn{{result.Id}}" ng-disabled="listForm.$invalid"
                            (click)="saveChangesInternal()" translate>
                        SAVE_ALL
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
