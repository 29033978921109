<div>
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0">{{ title | translate }}</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive tab-search-modal">
            <ips-list-search *ngIf="showLocationGroupSearch"
                             headerLabel=""
                             searchLabel="{{'LOCATION_GROUP' | translate }}"
                             searchLabelPlural="{{'LOCATION_GROUPS' | translate }}"
                             labelLoading="{{'ACCESSING_LOCATION_GROUP_INFO' | translate }}"
                             labelLoadingError="{{'ERROR_ACCESSING_LOCATION_GROUP_INFO' | translate }}"
                             [listData]="locationGroups"
                             (onListDataChange)="getLocationGroupData($event.search)"
                             [busy]="busy"
                             [scroller]="true"
                             [hideHeader]="true"
                             [promise]="locationGroupPromise"
                             [autoFocus]="focusLocationGroup"
                             [isModal]="true"
                             [translatedTexts]="translatedTexts"
                             [query]="locationGroupQuery">
                <ng-template #listSearchItemTemplate let-result="result">
                    <div class="list-group-item no-border location-group-modal-container" [ngClass]="{'disabled': result.Disabled}">
                        <div class="location-group-header row">
                            <div class="location-group-title col-8">{{result.Name}}
                                <div class="location-group-rule-icon" ngClass="{{result.ruleClass}}" ngbTooltip="{{result.ruleTooltip}}" placement="bottom"></div>
                            </div>
                            <div class="location-groupList-assignment-container col">
                                <span class="location-groupList-assignment-label" translate>LOCATIONS</span>
                            </div>
                        </div>
                        <div class="list-group-item mt-3 border-warning rounded" *ngIf="result.Disabled">
                            <p><b translate>FEATURE_UNAVAILABLE</b></p>
                            <span>{{result.warningMessage}}</span>
                        </div>

                        <div class="mt-3">
                            <div *ngFor="let subGroup of result.SubGroups">
                            <div class="list-group-item list-group-subgroup-button list-group-item-action col-12 py-0 border-0">
                                <div class="row border">
                                    <div class="col py-2">{{subGroup.Name}}</div>
                                    <div class="location-groupList-assignmentCount-label col-auto py-2">{{subGroup.LocationCount}}</div>
                                    <div class="col-auto input-group-append px-0">
                                        <div class="input-group-text rounded-0 border-0">
                                            <input id="{{subGroup.Name}}" type="checkbox" [checked]="result.Disabled || subGroup.Disabled" (change)="locationGroupChecked(subGroup, result,$event.target.checked)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </ng-template>
            </ips-list-search>

            <ips-list-search *ngIf="showSavedSearch"
                             headerLabel=""
                             searchLabel="{{'LOCATION_SEARCH' | translate }}"
                             searchLabelPlural="{{'LOCATION_SEARCHES' | translate }}"
                             labelLoading="{{'ACCESSING_INFO' | translate }}"
                             labelLoadingError="{{'ERROR_ACCESSING_INFO' | translate }}"
                             [listData]="savedSearches"
                             [busy]="busy"
                             (onListDataChange)="getSavedSearchData($event.search)"
                             [section]=""
                             [scroller]="true"
                             [quickRef]="true"
                             [hideHeader]="true"
                             [itemClickPermission]="PUBLIC"
                             [promise]="locationPromise"
                             [autoFocus]="focusLocation"
                             [isModal]="true"
                             [translatedTexts]="translatedTexts"
                             [query]="locationQuery">
                <div>
                    <ng-template #listSearchItemTemplate let-result="result">

                        <div class="list-group-item input-group no-round change-hover-color p-0">
                            <div class="row">
                                <div class="col"><div class="py-2 px-3">{{result.Label}}</div></div>
                                <div class="input-group-append col-auto">
                                    <div class="input-group-text border-0">
                                        <input type="checkbox" [checked]="result.Disabled" (change)="savedSearchSelected(result,$event.target.checked)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
            </div>
            </ips-list-search>
        </div>

        
        <div class="row align-items-center" *ngIf="showLocationGroupSearch">
            <div class="col-12 col-md-6 ml-5 pr-0">
                <span class="badge-pill badge-success">{{selectedCount}}</span>
                <span class="ml-1">{{selectedCount | i18nPlural: LocationFeaturePluralMapping}}</span>
            </div>
            <div class="col-12 col-md-5 pl-0 pr-4">
                <button type="submit" class="btn btn-default btn-lg btn-block search-modal-done-button mb-2"
                        id="doneButton"
                        (click)="locationFeaturesSelected()" translate>
                    DONE
                </button>
            </div>
        </div>

        
        <div class="row align-items-center" *ngIf="showSavedSearch">
            <div class="col-12 col-md-6 ml-5 pr-0">
                <span class="badge-pill badge-success">{{selectedCount}}</span>
                <span class="ml-1">{{selectedCount | i18nPlural: savedSearchPluralMapping}}</span>
            </div>
            <div class="col-12 col-md-5 pl-0 pr-4">
                <button type="submit" class="btn btn-default btn-lg btn-block search-modal-done-button mb-2"
                        id="doneButton"
                        (click)="saveSearchMultiselect()" translate>
                    DONE
                </button>
            </div>
        </div>
    </form>
</div>
