<div>
    <div>
        <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
    </div>
    <div class="ips-modal-header ips-modal-dark-header">
        <h4 class="modal-title m-0" translate>LOCATION_ADDRESSES</h4>
    </div>
    <div class="modal-body">
        <div class="ips-ls-search-module-imgn mb-3">
            <div class="input-group my-3">
                <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                <input type="text" [(ngModel)]="filter" (input)="filterUpdated()"
                       class="form-control form-control-lg ips-ls-input long-text-wrap">
            </div>
            <div class="row no-gutters mt-3">
                <div class="col-12 col-md-auto ml-3">
                    <span class="badge-pill badge-secondary font-weight-normal">{{ total | number }}</span>
                    <span class="ml-1">{{total | i18nPlural: pluralMapping}} {{'FOUND_OF' | translate}}</span>
                </div>
                <div class="col-12 col-md-auto ml-3 ml-md-2 mt-1 mt-md-0">
                    <span class="badge-pill badge-secondary font-weight-normal">{{ total | number }}</span>
                    <span class="ml-1">{{'TOTAL' | translate}} {{total | i18nPlural: pluralMapping}}</span>
                </div>
            </div>
        </div>
        <div class="ips-ls-long-text-wrap list-group mb-3">
            <div class="modal-scrollable list-search-infinite-container">
                <div *ngFor="let location of availableLocations"
                class="card ips-shadow mb-2 subgroup-card d-flex flex-row align-items-center">
                    <div class="p-2 pl-3 flex-grow-1">
                        <p class="font-weight-light mb-0">{{location.Label}}</p>
                        <p class="mb-0">{{location.Address}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
