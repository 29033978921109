import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IpsBaseWebService, ActiveProfileService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { AuthService } from "imagine-ui-ng-security";

@Injectable({
  providedIn: "root"
})
export class BrandSettingService extends IpsBaseWebService {


    private businessId: string;
    private token: string;

    constructor(http: HttpClient,
        public authService: AuthService,
        public activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("BrandSetting", "Profile", "v1");

        this.businessId = activeProfileService.businessIdentity;
        this.token = authService.getIdToken();
    }

    public getSettingByName(name: string): Promise<string> {
        const route = `${this.urlApiBasePlusController}${name}`;
        return this.getByRoute(route);
    }

    //Help Documents
    getHelpDocuments(): Promise<any> {
        let url = `${this.urlApiBasePlusController}HelpDocument`;
        return this.getByRoute(url);
    }

    getHelpDocumentMediaUrl(mediaId: string, mediaType: number): string {

        let typeName = "";
        switch (mediaType) {
            case 1: typeName = "Jpg"; break;
            case 2: typeName = "Gif"; break;
            case 3: typeName = "Bmp"; break;
            case 4: typeName = "Tif"; break;
            case 5: typeName = "Pdf"; break;
            case 6: typeName = "Csv"; break;
            case 7: typeName = "Png"; break;
            case 8: typeName = "Xlsx"; break;
            case 9: typeName = "Docx"; break;
            case 10: typeName = "Pptx"; break;
            case 11: typeName = "Vsdx"; break;
            case 12: typeName = "Xps"; break;
            case 13: typeName = "Zip"; break;
            case 14: typeName = "Txt"; break;
            default: typeName = "Pdf"; break;
        }

        let url = `${this.urlApiBase}Media/v1/MasterMedia/${this.businessId}/HelpDocument/${mediaId}/Original/${typeName}?BusinessIdentity=${this.businessId}&idToken=${this.token}`;
        return url;
    }

    deleteHelpDocument(id: number): Promise<any> {
        let url = `${this.urlApiBasePlusController}HelpDocument/${id}`;
        return this.deleteByRoute(url);
    }

    addHelpDocument(model: any, file?: any): Promise<any> {
        let url = `${this.urlApiBasePlusController}HelpDocument`;
        return this.post(model, file, url);
    }

}
