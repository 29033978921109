import { Directive } from "@angular/core";
import { NgForm, ControlContainer } from "@angular/forms";

// This directive should be placed in any nested child directives html in order to provide the parent form. This allows the parent
// form to be aware of the child component controls. Taken from https://medium.com/@john_oerter/angular-nested-forms-and-validation-844ea05d4063
@Directive({
    selector: "[appProvideParentForm]",
    providers: [
        {
            provide: ControlContainer,
            useFactory: function (form: NgForm) {
                return form;
            },
            deps: [NgForm]
        }
    ]
})
export class ProvideParentFormDirective { }
