import { Injectable } from "@angular/core";
import orderBy from "lodash-es/orderBy";
import get from "lodash-es/get";

@Injectable()
export class SortHelperService<T> {
    private currentSort: string;
    private currentSortDir: string;

    constructor() { }

    public setSort(sortField: string) {
        const previousSort = this.currentSort;
        this.currentSort = sortField;

        if (previousSort === this.currentSort) {
            if (this.currentSortDir === "asc") {
                this.currentSortDir = "desc";
            } else {
                this.currentSortDir = "asc";
                this.currentSort = null;
            }
        } else {
            this.currentSortDir = "asc";
        }
    }

    private isUnsorted(field: string): boolean {
        return this.currentSort !== field;
    }

    private isSortedAsc(field: string): boolean {
        return this.currentSort === field
            && this.currentSortDir === "asc";
    }

    private isSortedDesc(field: string): boolean {
        return this.currentSort === field
            && this.currentSortDir === "desc";
    }

    public getSortClass(field: string, fixedWidth = false): string {
        const unsortedClass = "fa fa-unsorted d-print-none";
        const sortedAscClass = "fa fa-sort-asc d-print-none";
        const sortedDescClass = "fa fa-sort-desc d-print-none";

        let returnValue = "";

        if (this.isUnsorted(field)) {
            returnValue = unsortedClass;
        } else if (this.isSortedAsc(field)) {
            returnValue = sortedAscClass;
        } else if (this.isSortedDesc(field)) {
            returnValue = sortedDescClass;
        }

        if (fixedWidth) {
            returnValue += " fa-fw";
        }

        return returnValue;
    }

    public applySort(data: T[]): T[] {
        let returnData = data;

        if (this.currentSort) {
            returnData = orderBy(returnData, [item => (get(item, this.currentSort) || "")], this.currentSortDir);
        }

        return returnData;
    }
}
