import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { PromotionMessageModel } from "../model/PromotionMessageModel";
import { PromotionMessageWithPlacementsModel } from "../model/PromotionMessageWithPlacementsModel";
import { MessagePlacementCalculationResultModel } from "../model/MessagePlacementCalculationResultModel";
import { MessagePlacementCalculationModel } from "../model/MessagePlacementCalculationModel";
import { MarketLocationBalanceListRequestModel } from "../model/MarketLocationBalanceListRequestModel";
import { PlacementLocationBalanceListRequestModel } from "../model/PlacementLocationBalanceListRequestModel";
import { ElementLocationBalanceListRequestModel } from "../model/ElementLocationBalanceListRequestModel";
import { PromotionMessagePlacementElementModel } from "../model/PromotionMessagePlacementElementModel";

@Injectable()
export class PromotionMessageService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("PromotionMessage", "Campaign", "v1");
    }

    public getPromotionMessages(promoId: number): Promise<PromotionMessageModel[]> {
        let route = `Promotion/${promoId}/PromotionMessage`;
        return this.getByRoute<PromotionMessageModel[]>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public GetByPromotionMessagesWithPlacements(promoId: number, returnFixtures = false): Promise<PromotionMessageWithPlacementsModel> {
        let route = `Promotion/${promoId}/PromotionMessageWithPlacements?returnFixtures=${returnFixtures}`;
        return this.getByRoute<PromotionMessageWithPlacementsModel>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public calculateLocationBalance(model: MessagePlacementCalculationModel): Promise<MessagePlacementCalculationResultModel> {
        let isModelValid = this.isBalanceCalcModelValid(model);
        if (isModelValid) {
            let route = `${this.urlApiBaseProductPlusVersion}PromotionMessage/CalculateLocationBalance`;
            return this.postByRoute<MessagePlacementCalculationResultModel>(route, model);
        }
        return Promise.resolve(null);
    }

    public calculatePriorityFillLocationBalance(model: MessagePlacementCalculationModel): Promise<MessagePlacementCalculationResultModel> {
        let isModelValid = this.isBalanceCalcModelValid(model);
        if (model.MessagePlacements.length) {
            model.MessagePlacements.forEach(mp => {
                mp.MessagePlacement.LocationAssignments = [];
            });
        }

        model.PatternGroupInfoForPriorityFill.forEach(patternGroup => {
            if (patternGroup.MessagePatterns && patternGroup.MessagePatterns.length) {
                patternGroup.MessagePatterns.forEach(mp => {
                    if (mp.Markets && mp.Markets.length) {
                        mp.Markets.forEach(m => { delete m.DisplayInfo; });
                    }
                });
            }
        });

        if (isModelValid) {
            let route = `${this.urlApiBaseProductPlusVersion}PromotionMessage/CalculatePriorityFillLocationBalance`;
            return this.postByRoute<MessagePlacementCalculationResultModel>(route, model);
        }
        return Promise.resolve(null);
    }

    public checkUniqueMessageName(model: any): Promise<boolean> {
        let route = `${this.urlApiBaseProductPlusVersion}Campaign/Promotion/IsMessageNameAvailable`;
        return this.postByRoute<boolean>(route, model);
    }

    public calculateMarketLocationBalanceList(requestModel: MarketLocationBalanceListRequestModel): Promise<number[]> {
        let route = `${this.urlApiBaseProductPlusVersion}PromotionMessage/MarketLocationBalanceList`;
        return this.postByRoute<number[]>(route, requestModel);
    }

    public calculatePlacementLocationBalanceList(requestModel: PlacementLocationBalanceListRequestModel): Promise<number[]> {
        let route = `${this.urlApiBaseProductPlusVersion}PromotionMessage/PlacementLocationBalanceList`;
        return this.postByRoute<number[]>(route, requestModel);
    }

    public calculateElementLocationBalanceList(requestModel: ElementLocationBalanceListRequestModel): Promise<number[]> {
        let route = `${this.urlApiBaseProductPlusVersion}PromotionMessage/ElementLocationBalanceList`;
        return this.postByRoute<number[]>(route, requestModel);
    }

    public GetPlacementElementDetails(id: number): Promise<PromotionMessagePlacementElementModel> {
        let route = `PlacementElement/${id}/Details`;
        return this.getByRoute<PromotionMessagePlacementElementModel>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    /**
     * This is not a complete valid check. Only does bare minimum check for the case where one of the market controls is invalid.
     * @param model
     */
    public isBalanceCalcModelValid(model: MessagePlacementCalculationModel) {
        if (model && model.PatternGroupInfo && model.PatternGroupInfo.Markets) {
            return !model.PatternGroupInfo.Markets.some(m => m.TargetMarketType as any === "");
        }
        return false;
    }
}
