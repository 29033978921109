import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchHelper } from "imagine-ui-ng-core";
import { OrderService, OrderStatus, OrderStatusModel, ShipmentModel } from "../../index";

@Component({
  selector: "app-shipment-modal",
  templateUrl: "./shipment-modal.component.html",
  styleUrls: ["./shipment-modal.component.scss"]
})
export class ShipmentModalComponent implements OnInit {
    public searchLabel: string;
    public searchLabelPlural: string;
    public listSearch: ListSearchHelper;
    public promise: Promise<any>;
    public query = "";
    public busy: boolean;
    //private pluralMapping: { [k: string]: string } = {};

    constructor(private orderService: OrderService, private translateService: TranslateService, private listSearchHelper: ListSearchHelper, private modalInstance: NgbActiveModal) {
        this.listSearch = this.listSearchHelper.getListSearchHelper(this.orderService);
    }

    ngOnInit() {
        // Call translate get in order to wait until translations are loaded, then call our translateText method.
        this.translateService.get("ALL").subscribe(() => this.translateText());
        this.translateService.onLangChange.subscribe(() => this.translateText());


        //this.orderService.head().then((total: number) => {
        //    this.listSearch.TotalRecords = total;
        //});

        this.getListData();

    }
    public getListData(search?: string) {
        this.query = search || this.query;
        this.promise = this.getData().then(response => {
            this.listSearch = response;
        });
    }


    getData() {
        this.promise = new Promise(
            (resolve, reject) => {
                const order = {
                    resultList: [{
                        Id: 1, ShipmentStatus: "Complete", ShipmentNumber: "43243245612568785475", DateShipped: "2019-10-25 10:51:36.1365210 -05:00"
                    },
                    {
                        Id: 2, ShipmentStatus: "Complete", ShipmentNumber: "93243245612568785475", DateShipped: "2019-10-25 10:51:36.1365210 -05:00"
                    }],
                    TotalRecords: 2,
                    ResultChunkAttributes: { Index: 0, RecordCount: 20, TotalRecords: "2" },
                    LoadMore: true

                };
                setTimeout(() => { resolve(order); }, 100);
            }
        );
        return this.promise;
    }


    translateText() {
        this.searchLabel = this.translateService.instant("SHIPMENT");
        this.searchLabelPlural = this.translateService.instant("SHIPMENTS");

    }


    shipmentClicked(shipment: ShipmentModel) {
        return this.modalInstance.close(shipment);
    }

    close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }
}

