import { Component, OnInit } from "@angular/core";
import { ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { AlternateAddressService } from "../../service/alternate-address.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-alternate-address-search",
  templateUrl: "./alternate-address-search.component.html",
  styleUrls: ["./alternate-address-search.component.scss"]
})
export class AlternateAddressSearchComponent implements OnInit {
    public alternateAddress: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<{}>;
    public createNewTooltip: string;
    public textMaxLength = "MAX_LENGTH_ERROR";
    public searchLabel: string;
    public searchLabelPlural: string;
    public translated = false;

    constructor(private alternateAddressService: AlternateAddressService, private listSearchHelper: ListSearchHelper, private translateService: TranslateService) {
        this.alternateAddress = this.listSearchHelper.getListSearchHelper(alternateAddressService);
        this.busy = this.alternateAddress.busy;

        alternateAddressService.head().then((data) => {
            this.alternateAddress.TotalRecords = data;
        });
    }

    ngOnInit() {
        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() => this.TranslateText());
        this.getListData("");
    }
    private TranslateText() {
        this.createNewTooltip = this.translateService.instant("CREATE_NEW_ALTERNATE_ADDRESS");
        this.textMaxLength = this.translateService.instant("MAX_LENGTH_ERROR");
        this.searchLabel = this.translateService.instant("ALTERNATE_ADDRESS");
        this.searchLabelPlural = this.translateService.instant("ALTERNATE_ADDRESSES");
        this.translated = true;
    }
    public getListData(search: string) {
        if (this.busy) {
            return;
        }

        let searchInfo: SearchInfo = {
            searchText: search === undefined ? "" : search,
            filterOption: "1"
        };
        this.promise = this.alternateAddress.searchHelper(searchInfo, null, "AlternateAddress/Address/Search");
    }

}
