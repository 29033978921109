var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, AfterViewInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActiveProfileService, CountryModel } from "imagine-ui-ng-core";
import { PageTitleService, CountryService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { AlternateAddressService } from "../../index";
import { Transition, StateService } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString } from "typescript-string-operations";
import { TemplateFormBase } from "../../../shared/templateFormBase";
import { AuthService } from "imagine-ui-ng-security";
import { AddressService } from "../../../shared/service/address.service";
import { SuggestedAddressModalComponent } from "../../../shared/suggested-addresses-modal/suggested-addresses-modal.component";
import { IpsModalService } from "imagine-ui-ng-modal";
var AlternateAddressEditComponent = /** @class */ (function (_super) {
    __extends(AlternateAddressEditComponent, _super);
    function AlternateAddressEditComponent(transition, alternateAddressService, addressService, ipsModal, ipsMessage, countryService, $state, translateService, pageTitleService, activeProfileService, authService) {
        var _this = _super.call(this) || this;
        _this.alternateAddressService = alternateAddressService;
        _this.addressService = addressService;
        _this.ipsModal = ipsModal;
        _this.ipsMessage = ipsMessage;
        _this.countryService = countryService;
        _this.$state = $state;
        _this.translateService = translateService;
        _this.pageTitleService = pageTitleService;
        _this.activeProfileService = activeProfileService;
        _this.authService = authService;
        _this.loaded = false;
        _this.information = "INFORMATION_UPPERCASE";
        _this.failedAddressValidation = "FAILED_ADDRESS_VALIDATION";
        _this.countryList = [];
        _this.deleteLocation = function () {
            return _this.dataService.delete(_this.alternateAddress.Id);
        };
        _this.dataService = alternateAddressService;
        _this.id = Number(transition.params().id);
        // Clear out current location, fill in defaults
        _this.alternateAddress = {
            Attention: "LOCATION MANAGER",
            Country: "USA",
            AddressType: "Campaign"
        };
        return _this;
    }
    AlternateAddressEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateService.get("ALL").subscribe(function () { return _this.TranslateText(); });
        this.translateService.onLangChange.subscribe(function () { return _this.TranslateText(); });
        var pageTitle = this.id > 0 ? "EDIT_ALTERNATE_ADDRESS" : "CREATE_ALTERNATE_ADDRESS";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);
        // If we got an ID to load, load it.
        if (this.id > 0) {
            //Initial call to populate screen on load
            this.getLocation(this.id);
        }
        else {
            this.loaded = true;
        }
        this.countryPromise = this.countryService.Get();
        this.countryPromise.then(function (response) {
            _this.countryList = response;
        });
    };
    AlternateAddressEditComponent.prototype.ngAfterViewInit = function () {
        _super.prototype.setFormPristine.call(this, this.alternateAddressForm, 700);
    };
    AlternateAddressEditComponent.prototype.TranslateText = function () {
        this.information = this.translateService.instant("INFORMATION_UPPERCASE");
        this.failedAddressValidation = this.translateService.instant("FAILED_ADDRESS_VALIDATION");
    };
    AlternateAddressEditComponent.prototype.getLocation = function (id) {
        var _this = this;
        this.loaded = false;
        this.promise = this.dataService.get(id).then(function (response) {
            Object.assign(_this.alternateAddress, response);
            _this.originalAddress = _this.alternateAddress;
            _this.loaded = true;
        });
    };
    AlternateAddressEditComponent.prototype.saveLocation = function () {
        var _this = this;
        var id = this.alternateAddress.Id;
        if (id) {
            return this.dataService.put(this.alternateAddress).then(function () {
                _this.alternateAddressForm.form.markAsPristine();
            });
        }
        else {
            return this.dataService.post(this.alternateAddress).then(function (response) {
                _this.alternateAddressForm.form.markAsPristine();
                _this.alternateAddress = response;
            });
        }
    };
    AlternateAddressEditComponent.prototype.deleteLocationPrompt = function () {
        var _this = this;
        var translated;
        if (this.alternateAddress.InUse) {
            translated = this.translateService.instant("PROMPT_DELETE_BODY_ALTERNATE_ADDRESS");
        }
        else {
            translated = this.translateService.instant("PROMPT_DELETE_BODY");
        }
        translated = IpsString.Format(translated, this.alternateAddress.Name);
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: this.deleteLocation, progressMessage: "DELETING" }).then(function (result) {
            if (result) {
                _this.$state.go("main.storeProfileAlternateAddress.search");
            }
        })
            .catch(function () {
            // rejection
        });
    };
    // Validates the entered address for the location.
    AlternateAddressEditComponent.prototype.validateAddressForLocationSave = function (redirect) {
        var _this = this;
        this.addressService.validateAddress(this.addressService.addressModeltoShippingAddress(this.alternateAddress)).then(function (validation) {
            if (!validation.Valid) {
                _this.ipsModal.displayTemplateScrollable(SuggestedAddressModalComponent, {
                    suggestedAddresses: validation.Addresses ? validation.Addresses : [],
                    invalidAddress: _this.addressService.addressModeltoShippingAddress(_this.alternateAddress)
                }).then(function (response) {
                    var newAddress = _this.addressService.updateAddressModelFromShippingAddress(_this.alternateAddress, response, _this.countryList);
                    Object.assign(_this.alternateAddress, newAddress);
                    _this.saveAlternateAddressPrompt(redirect);
                }).catch(function (canceled) { });
            }
            else {
                _this.alternateAddress.ValidationStatus = "Validated";
                _this.saveAlternateAddressPrompt(redirect);
            }
        }, function (reason) {
            return _this.ipsMessage.confirm({
                body: "FAILED_ADDRESS_VALIDATION_PROMPT",
                buttons: "OK"
            }).then(function (result) {
                // nothing to do, user has been warned and closed the dialog
            }).catch(function (canceled) {
                // nothing to do, user has been warned and closed the dialog
            });
        });
    };
    AlternateAddressEditComponent.prototype.saveAlternateAddressPrompt = function (redirect) {
        var _this = this;
        return this.ipsMessage.waitForWork({
            body: "SAVING",
            workFunction: function () { return _this.saveLocation(); },
            progressMessage: "SAVING"
        }).then(function (result) {
            if (result) {
                if (redirect) {
                    _this.$state.go("main.storeProfileAlternateAddress.search");
                }
                else {
                    _this.$state.go("main.storeProfileAlternateAddress.edit", { id: _this.alternateAddress.Id });
                }
            }
        });
    };
    return AlternateAddressEditComponent;
}(TemplateFormBase));
export { AlternateAddressEditComponent };
