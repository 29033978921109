/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./campaign-landing.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "imagine-ui-ng-security";
import * as i3 from "@uirouter/angular/lib/directives/uiSref";
import * as i4 from "@uirouter/core";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./campaign-landing.component";
var styles_CampaignLandingComponent = [i0.styles];
var RenderType_CampaignLandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CampaignLandingComponent, data: {} });
export { RenderType_CampaignLandingComponent as RenderType_CampaignLandingComponent };
export function View_CampaignLandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "section", [["class", "col-6 col-lg-2 landingNavDescription"], ["ipsPermission", "main.campaign.search"]], null, null, null, null, null)), i1.ɵdid(2, 4276224, null, 0, i2.ɵb, [i1.Renderer2, i1.ElementRef, i2.PermissionService], { ipsPermission: [0, "ipsPermission"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "a", [["class", "landingNavLink"], ["id", "spacesLink"], ["uiSref", "main.campaign.search"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i3.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(5, 737280, null, 0, i3.UISref, [i4.UIRouter, [2, i3.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "campaignsImg landingNavIcon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h3", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(8, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["CAMPAIGNS"])), (_l()(), i1.ɵeld(10, 0, null, null, 8, "section", [["class", "col-6 col-lg-2 landingNavDescription"], ["ipsPermission", "main.storeProfileElement.search"]], null, null, null, null, null)), i1.ɵdid(11, 4276224, null, 0, i2.ɵb, [i1.Renderer2, i1.ElementRef, i2.PermissionService], { ipsPermission: [0, "ipsPermission"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "a", [["class", "landingNavLink"], ["id", "elementsLink"], ["uiSref", "main.storeProfileElement.search"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i3.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(14, 737280, null, 0, i3.UISref, [i4.UIRouter, [2, i3.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "div", [["class", "elementImg landingNavIcon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "h3", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(17, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["ELEMENTS"]))], function (_ck, _v) { var currVal_0 = "main.campaign.search"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "main.campaign.search"; _ck(_v, 5, 0, currVal_1); var currVal_2 = ""; _ck(_v, 8, 0, currVal_2); var currVal_3 = "main.storeProfileElement.search"; _ck(_v, 11, 0, currVal_3); var currVal_4 = "main.storeProfileElement.search"; _ck(_v, 14, 0, currVal_4); var currVal_5 = ""; _ck(_v, 17, 0, currVal_5); }, null); }
export function View_CampaignLandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-campaign-landing", [], null, null, null, View_CampaignLandingComponent_0, RenderType_CampaignLandingComponent)), i1.ɵdid(1, 114688, null, 0, i6.CampaignLandingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CampaignLandingComponentNgFactory = i1.ɵccf("app-campaign-landing", i6.CampaignLandingComponent, View_CampaignLandingComponent_Host_0, {}, {}, []);
export { CampaignLandingComponentNgFactory as CampaignLandingComponentNgFactory };
