<div>

    <div class="row mt-3">
        <div class="col-12">
            This will detect Sign Plan Problems before the actual Sign Plan PDF generation takes place.
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-3">
            <button type="button" class="btn btn-primary" (click)="selectCampaign()">Select a Campaign</button>
        </div>
    </div>

    <div *ngIf="campaignName" class="row mt-3">
        <div class="col-12 text-center">
            <h3>Campaign Name: <span class="font-weight-bold">{{campaignName}}</span></h3>
        </div>
    </div>

    <div *ngIf="data">
        <div class="row mt-3">
            <div class="col-12">
                <ngb-tabset [destroyOnHide]="false">
                    <ngb-tab>
                        <ng-template ngbTabTitle><b>Campaign Allocation File</b><span *ngIf="!data.AllocationDate" class="badge badge-danger ml-2">*</span><span *ngIf="data.AllocationDate" class="badge badge-success ml-2"><i class="fa fa-check text-white" aria-hidden="true"></i></span></ng-template>
                        <ng-template ngbTabContent>
                            <div *ngIf="!data.AllocationDate" class="font-italic my-3 pl-3">To Fix: Go To File Processing and do a Campaign Allocation download.</div>

                            <div *ngIf="data.AllocationDate">
                                <label>Allocation File Creation Time</label>
                                <input class="form-control" value="{{data.AllocationDate | date}}" />
                            </div>
                            <div *ngIf="!data.AllocationDate">
                                <label class="text-danger">Allocation File Missing!</label>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab>
                        <ng-template ngbTabTitle><b>Missing Image Code</b><span *ngIf="data.NoImageCode && data.NoImageCode.length > 0" class="badge badge-danger ml-2">{{data.NoImageCode.length}}</span><span *ngIf="!data.NoImageCode || (data.NoImageCode && data.NoImageCode.length == 0)" class="badge badge-success ml-2"><i class="fa fa-check text-white" aria-hidden="true"></i></span></ng-template>
                        <ng-template ngbTabContent>
                            <div class="font-italic my-3 pl-3">To Fix: Upload the Production Details file with the "Imagine Item Code" field for the message(s) specified below.</div>
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Promotion Name</th>
                                        <th scope="col">Message</th>
                                        <th scope="col">Image Code</th>
                                        <th scope="col">Width</th>
                                        <th scope="col">Height</th>
                                        <th scope="col">Holder Name</th>
                                        <th scope="col">Job Number/th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data.NoImageCode">
                                        <td><a uiSref="main.campaign.promotion.edit" [uiParams]="{campaignId: campaignId, id:item.PromotionId}">{{item.PromotionName}}</a></td>
                                        <td>{{item.Message}}</td>
                                        <td><span class="text-danger" translate>MISSING</span> </td>
                                        <td>{{item.Width}}</td>
                                        <td>{{item.Height}}</td>
                                        <td>{{item.HolderName}}</td>
                                        <td>{{item.JobNumber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab>
                        <ng-template ngbTabTitle><b>Missing Images</b><span *ngIf="data.NoImage && data.NoImage.length > 0" class="badge badge-danger ml-2">{{data.NoImage.length}}</span><span *ngIf="!data.NoImage || (data.NoImage && data.NoImage.length == 0)" class="badge badge-success ml-2"><i class="fa fa-check text-white" aria-hidden="true"></i></span></ng-template>
                        <ng-template ngbTabContent>
                            <div class="font-italic my-3 pl-3">To Fix: Add image to the Campaign Signs folder -or- Click on the Promotion link below and update the CustomerItemCode to a valid image.</div>
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Promotion Name</th>
                                        <th scope="col">Message</th>
                                        <th scope="col">Image Code</th>
                                        <th scope="col">Width</th>
                                        <th scope="col">Height</th>
                                        <th scope="col">Holder Name</th>
                                        <th scope="col">Job Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data.NoImage">
                                        <td><a uiSref="main.campaign.promotion.edit" [uiParams]="{campaignId: campaignId, id:item.PromotionId}">{{item.PromotionName}}</a></td>
                                        <td>{{item.Message}}</td>
                                        <td>{{item.ImageCode}}</td>
                                        <td>{{item.Width}}</td>
                                        <td>{{item.Height}}</td>
                                        <td>{{item.HolderName}}</td>
                                        <td>{{item.JobNumber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab>
                        <ng-template ngbTabTitle><b>Fixture Needs Setup (ALL)</b><span *ngIf="data.FixtureNoImage && data.FixtureNoImage.length > 0" class="badge badge-danger ml-2">{{data.FixtureNoImage.length}}</span><span *ngIf="!data.FixtureNoImage || (data.FixtureNoImage && data.FixtureNoImage.length == 0)" class="badge badge-success ml-2"><i class="fa fa-check text-white" aria-hidden="true"></i></span></ng-template>
                        <ng-template ngbTabContent>
                            <div class="font-italic my-3 pl-3">To Fix: Click on a fixture below, Navigate to the Sign Plan tab, and Fill out the Sign Plan form.</div>
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Fixture Name</th>
                                        <th scope="col">Model</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data.FixtureNoImage">
                                        <td><a uiSref="main.storeProfileFixture.edit" [uiParams]="{id:item.Id}">{{item.Name}}</a></td>
                                        <td>{{item.Model}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab>
                        <ng-template ngbTabTitle><b>Fixture Placements Needs Setup (ALL)</b><span *ngIf="data.NoHolderDetail && data.NoHolderDetail.length > 0" class="badge badge-danger ml-2">{{data.NoHolderDetail.length}}</span><span *ngIf="!data.NoHolderDetail || (data.NoHolderDetail && data.NoHolderDetail.length == 0)" class="badge badge-success ml-2"><i class="fa fa-check text-white" aria-hidden="true"></i></span></ng-template>
                        <ng-template ngbTabContent>
                            <div class="font-italic my-3 pl-3">To Fix: Click on a fixture below, Navigate to the Sign Plan tab, and Fill out the Sign Plan form.</div>
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Fixture Name</th>
                                        <th scope="col">Model</th>
                                        <th scope="col">Width</th>
                                        <th scope="col">Height</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data.NoHolderDetail">
                                        <td><a uiSref="main.storeProfileFixture.edit" [uiParams]="{id:item.Id}">{{item.Name}}</a></td>
                                        <td>{{item.Model}}</td>
                                        <td>{{item.Width}}</td>
                                        <td>{{item.Height}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>
</div>
