
<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>

        </section>
    </div>
    <div [cgBusy]="promise" [cgBusyConfig]="{message: accessSpaceInfo}">
        <div class="row" [hidden]="!loaded">
            <div class="col-12 updateSection">
                <form id="ramiInfoInput" #spaceForm="ngForm" class="form-horizontal" name="spaceForm" novalidate ipsUnsavedWarningForm role="form">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 form-group">
                        <label class="required" for="Name" translate>NAME</label>
                        <input type="text" name="name" #spaceName="ngModel" class="form-control form-control-lg input-full" id="name" placeholder="{{ 'ENTER_SPACE_NAME' | translate}}"
                               required maxlength="50" [(ngModel)]="myspace.Name" appValidation />
                        <div *ngIf="spaceName.errors" class="invalid-feedback">
                            <div *ngIf="spaceName.errors.required">
                                {{ 'PLEASE_ENTER_NAME' | translate}}
                            </div>
                            <div *ngIf="spaceName.errors.maxlength">
                                {{ validationMaxLength(50) }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 form-group">
                        <label for="notes" translate>NOTES</label>
                        <textarea #notes="ngModel" [(ngModel)]="myspace.Notes" class="form-control form-control-lg input-full"  maxlength="500"  appValidation
                                  name="notes" id="" cols="30" rows="3" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                        <div *ngIf="notes.errors" class="invalid-feedback">
                            <div *ngIf="notes.errors.maxlength">
                                {{ validationMaxLength(500) }}
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-3 col-lg-3 form-group">
                            <div class="formSubmit">
                                <button id="saveReturnSpace"
                                        class="btn btn-lg btn-block alert-success" type="submit"
                                        (click)="saveSpacePrompt()"
                                        [disabled]="spaceForm.form.invalid || spaceForm.form.pristine"
                                        [ngClass]="{'btn-default': spaceForm.form.invalid || spaceForm.form.pristine}" translate>
                                    SAVE_AND_RETURN
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                            <div class="formSubmit">
                                <button id="cancelSpace" class="btn btn-lg btn-block btn-default" type="submit" uiSref="main.storeProfileSpace.search" translate>CANCEL</button>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3 col-md-3 offset-sm-2 offset-md-3">
                            <div class="formSubmit">
                                <button id="deleteSpace" class="btn btn-lg btn-block alert-danger" type="submit"
                                        (click)="deleteSpacePrompt()"
                                        ips-unsaved-warning-clear
                                        [disabled]="!myspace.Id"
                                        [ngClass]="{'btn-default': !myspace.Id}" translate>
                                    DELETE_SPACE
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_SPACE_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>

