<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:'{{'ACCESSING_FIXTURE_INFO' | translate}}'}">
        <div [hidden]="!loaded">
            <form id="ramiInfoInput" [formGroup]="fixtureForm" class="form-horizontal" name="fixtureForm" novalidate ipsUnsavedWarningForm>

                <div *ngIf="!enableSignPlanLayout">
                    <div class="form-row">
                        <div class="col-12 col-md-7 col-lg-7">
                            <div class="form-row">
                                <div class="form-group col-12 col-sm-10 col-md-8 col-lg-8 ">
                                    <label class="required" for="fixtureName" translate>NAME</label>
                                    <input type="text" name="fixtureName" id="fixtureName" formControlName="fixtureName"
                                           class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_NAME' | translate}}">
                                    <ips-show-errors [control]="fixtureName" [errorMessages]="getErrorMessage('name')"></ips-show-errors>
                                </div>
                                <div class="ml-2">
                                    <label class="d-none d-md-block">&nbsp;</label>
                                    <div class="input-group" (click)="toggleObsolete(fixtureForm)">
                                        <div class="form-control form-control-lg input-full">{{'OBSOLETE' | translate}}</div>
                                        <div class="input-group-append">
                                            <span class="input-group-text"> <input formControlName="isObsolete" name="isObsolete" id="isObsolete" type="checkbox"/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-row">
                                <div class="form-group col-12 col-sm-10 col-md-8 col-lg-8">
                                    <label for="fixtureMakeModel" translate>MAKE_MODEL</label>
                                    <input type="text" name="fixtureMakeModel" id="fixtureMakeModel" formControlName="fixtureMakeModel"
                                           validation="max_len:345"
                                           class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_MAKE_MODEL' | translate}}">
                                    <ips-show-errors [control]="fixtureMakeModel" [errorMessages]="getErrorMessage()"></ips-show-errors>
                                </div>
                            </div>
                            
                            <div class="form-row">
                                <div class="form-group col-12 col-sm-10 col-md-8 col-lg-8" *ngIf="showVeracoreOfferId">
                                    <label for="fixtureMakeModel" translate>VERACORE_OFFER_ID</label>
                                    <input type="text" name="offerId" id="offerId" formControlName="offerId"
                                           validation="max_len:50"
                                           class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_VERACORE_OFFER_ID' | translate}}">
                                    <ips-show-errors [control]="offerId" [errorMessages]="getErrorMessage()"></ips-show-errors>
                                </div>
                            </div>
                            
                            <div class="form-row">
                                <div class="form-group col-12 col-sm-10 col-md-8 col-lg-8">
                                    <label class="required" for="fixtureSpace" translate>SPACE</label>
                                    <select name="fixtureSpace" id="fixtureSpace"
                                            class="form-control form-control-lg ips-select"
                                            [compareWith]="compareFn"
                                            formControlName="spaceSelect">
                                        <!--Needed to make IE default to unselected-->
                                        <option *ngIf="!fixtureForm.value.spaceSelect" [ngValue]="null" selected disabled></option>
                                        <option *ngFor="let item of spaceList" [ngValue]="item">{{item.Name}}</option>
                                    </select>
                                    <ips-show-errors [control]="spaceSelect" [errorMessages]="getErrorMessage('space')"></ips-show-errors>
                                </div>
                            </div>
                            
                        </div>

                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 offset-md-2 offset-lg-2 text-center">
                            <ips-image-edit #primaryImage name="file" [showDelete]="true" (fileChanged)="onSelectedImageChanged($event)"></ips-image-edit>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12">
                            <div class="box pt-3">
                                <div class="form-group">
                                    <label class="required" for="fixtureGroup" translate>FIXTURE_GROUP</label>
                                    <select name="fixtureGroup" id="fixtureGroup"
                                            class="form-control form-control-lg ips-select"
                                            [compareWith]="compareFn"
                                            (ngModelChange)="fixtureGroupChange($event)"
                                            formControlName="fixtureGroupSelect">
                                        <!--Needed to make IE default to unselected-->
                                        <option *ngIf="!fixtureForm.value.fixtureGroupSelect" [ngValue]="null" selected disabled></option>
                                        <option *ngFor="let item of fgList" [ngValue]="item">{{item.Name}}</option>
                                    </select>
                                    <ips-show-errors [control]="fixtureGroupSelect" [errorMessages]="getErrorMessage('fixtureGroup')"></ips-show-errors>
                                    <div *ngIf="!!fixtureForm.value.fixtureGroupSelect">
                                        <div class="row text-center" [hidden]="!fixtureForm.value.fixtureGroupSelect.Id">
                                            <!--<br />-->
                                            <div class="col-lg-1 fixture-group-title d-none d-lg-block required" translate>
                                                Qty
                                            </div>
                                            <div class="col-lg-2 fixture-group-title d-none d-lg-block" translate>
                                                HOLDER_NAME
                                            </div>
                                            <div class="col-lg-3 fixture-group-title d-none d-lg-block required" translate>
                                                SIGN_SIZE_IN_INCHES
                                            </div>
                                            <div class="col-lg-2 fixture-group-title d-none d-lg-block" translate>
                                                VERSION_INFO
                                            </div>
                                            <div class="col-lg-2 fixture-group-title d-none d-lg-block" translate>
                                                CATEGORY_CODE
                                            </div>
                                            <div class="col-lg-2 fixture-group-title d-none d-lg-block" translate>
                                                OBSOLETE
                                            </div>
                                        </div>
                                        <hr [hidden]="!fixtureForm.value.fixtureGroupSelect.Id" />
                                        <div formArrayName="holders">
                                            <div *ngFor="let item of fixtureForm.controls.holders.controls; let i=index">
                                                <div class="form-row" [formGroupName]="i">
                                                    <div class="form-group col-12 col-lg-1">
                                                        <label class="d-lg-none required" translate>QUANTITY</label>
                                                        <input type="number" formControlName="quantity"
                                                               (change)="holderQuantityChanged(quantity,id)"
                                                               class="form-control form-control-lg input-full text-center-lg min-padding-lg" placeholder="{{ 'QTY' | translate}}" />
                                                        <ips-show-errors [control]="item.get('quantity')" [errorMessages]="getErrorMessage('quantity')" cssClass="validation-message-Qty"></ips-show-errors>
                                                    </div>
                                                    <div class="form-group col-12 col-lg-2 mb-0">
                                                        <label class="d-lg-none" translate>HOLDER_NAME</label>
                                                        <div class="col-12 form-control-lg text-center-lg left-padding">
                                                            {{item.get('holderName').value}}
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-12 col-lg-3 mb-0">
                                                        <div class="form-row">
                                                            <div class="col-12 d-lg-none ml-3 font-weight-bold">
                                                                <label class="required" translate>SIGN_SIZE_IN_INCHES</label>
                                                            </div>
                                                        </div>
                                                        <div class="form-row">
                                                            <div class="col-5">
                                                                <input type="number" formControlName="width"
                                                                       class="form-control form-control-lg input-full col-lg-12 text-center min-padding" placeholder="{{ 'WIDTH' | translate}}"
                                                                       (blur)="msgPrompt(item)">
                                                                <ips-show-errors [control]="item.get('width')" [errorMessages]="getErrorMessage('width')" cssClass="validation-message-size"></ips-show-errors>
                                                            </div>
                                                            <div class="col-2 form-control-lg text-center">
                                                                X
                                                            </div>
                                                            <div class="col-5">
                                                                <input type="number" formControlName="height"
                                                                       class="form-control form-control-lg input-full col-lg-12 text-center min-padding" placeholder="{{ 'HEIGHT' | translate}}"
                                                                       (blur)="msgPrompt(item)">
                                                                <ips-show-errors [control]="item.get('height')" [errorMessages]="getErrorMessage('height')" cssClass="validation-message-size"></ips-show-errors>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-12 col-lg-2">
                                                        <label class="d-lg-none" translate>VERSION_INFO</label>
                                                        <select name="holderVersionInfoId" id="holderVersionInfoId"
                                                                class="form-control form-control-lg ips-select"
                                                                formControlName="versionSelect">
                                                            <!--Needed to make IE default to unselected-->
                                                            <option *ngIf="!fixtureForm.value.holderVersionInfoId" [ngValue]="null" selected></option>
                                                            <option *ngFor="let item of versionList" [ngValue]="item">{{item.Name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-12 col-lg-2">
                                                        <label class="d-lg-none" translate>CATEGORY_CODE</label>
                                                        <input type="text" formControlName="categoryCode"
                                                               class="form-control form-control-lg input-full col-lg-12 text-center min-padding" placeholder="{{ 'CATEGORY_CODE' | translate}}"
                                                               maxlength="100">
                                                    </div>
                                                    <div class="form-group col-12 col-lg-2 mb-0 ">
                                                        <label class="d-lg-none" translate>OBSOLETE</label>
                                                        <div class="input-group justify-content-center mt-2">
                                                            <span class="input-group-text">
                                                                <input type="checkbox" formControlName="isObsolete" name="isObsolete">
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr class="d-lg-none" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12 col-md-10">
                            <label for="notes" translate>NOTES</label>
                            <textarea class="form-control form-control-lg input-full" name="notes" id="notes" cols="30" rows="3" formControlName="notes" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                            <ips-show-errors [control]="notes" [errorMessages]="getErrorMessage()"></ips-show-errors>
                        </div>
                    </div>
                </div>

                <div *ngIf="enableSignPlanLayout">
                    <tabset class="order-tabs">
                        <!-- Main fixture edit tab -->
                        <tab heading="Fixture Details">
                            <div class="form-row">
                                <div class="col-12 col-md-7 col-lg-7">
                                    <div class="form-row">
                                        <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8">
                                            <label class="required" for="fixtureName" translate>NAME</label>
                                            <input type="text" name="fixtureName" id="fixtureName" formControlName="fixtureName"
                                                   class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_NAME' | translate}}">
                                            <ips-show-errors [control]="fixtureName" [errorMessages]="getErrorMessage('name')"></ips-show-errors>
                                        </div>
                                        <div class="ml-2">
                                            <label class="d-none d-md-block">&nbsp;</label>
                                            <div class="input-group" (click)="toggleObsolete(fixtureForm)">
                                                <div class="form-control form-control-lg input-full">{{'OBSOLETE' | translate}}</div>
                                                <div class="input-group-append">
                                                    <span class="input-group-text"> <input formControlName="isObsolete" name="isObsolete" id="isObsolete" type="checkbox"/></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8">
                                            <label for="fixtureMakeModel" translate>MAKE_MODEL</label>
                                            <input type="text" name="fixtureMakeModel" id="fixtureMakeModel" formControlName="fixtureMakeModel"
                                                   validation="max_len:345"
                                                   class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_MAKE_MODEL' | translate}}">
                                            <ips-show-errors [control]="fixtureMakeModel" [errorMessages]="getErrorMessage()"></ips-show-errors>
                                        </div>
                                    </div>
                                    
                                    <div class="form-row">
                                        <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8" *ngIf="showVeracoreOfferId">
                                            <label for="fixtureMakeModel" translate>VERACORE_OFFER_ID</label>
                                            <input type="text" name="offerId" id="offerId" formControlName="offerId"
                                                   validation="max_len:50"
                                                   class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_VERACORE_OFFER_ID' | translate}}">
                                            <ips-show-errors [control]="offerId" [errorMessages]="getErrorMessage()"></ips-show-errors>
                                        </div>
                                    </div>
                                    
                                    <div class="form-row">
                                        <div class="form-group col-12 col-sm-8 col-md-8 col-lg-8">
                                            <label class="required" for="fixtureSpace" translate>SPACE</label>
                                            <select name="fixtureSpace" id="fixtureSpace"
                                                    class="form-control form-control-lg ips-select"
                                                    [compareWith]="compareFn"
                                                    formControlName="spaceSelect">
                                                <!--Needed to make IE default to unselected-->
                                                <option *ngIf="!fixtureForm.value.spaceSelect" [ngValue]="null" selected disabled></option>
                                                <option *ngFor="let item of spaceList" [ngValue]="item">{{item.Name}}</option>
                                            </select>
                                            <ips-show-errors [control]="spaceSelect" [errorMessages]="getErrorMessage('space')"></ips-show-errors>
                                        </div>
                                    </div>
                                   
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-lg-3 offset-md-2 offset-lg-2 text-center">
                                    <ips-image-edit #primaryImage name="file" [showDelete]="true" (fileChanged)="onSelectedImageChanged($event)"></ips-image-edit>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12">
                                    <div class="box pt-3">
                                        <div class="form-group">
                                            <label class="required" for="fixtureGroup" translate>FIXTURE_GROUP</label>
                                            <select name="fixtureGroup" id="fixtureGroup"
                                                    class="form-control form-control-lg ips-select"
                                                    [compareWith]="compareFn"
                                                    (ngModelChange)="fixtureGroupChange($event)"
                                                    formControlName="fixtureGroupSelect">
                                                <!--Needed to make IE default to unselected-->
                                                <option *ngIf="!fixtureForm.value.fixtureGroupSelect" [ngValue]="null" selected disabled></option>
                                                <option *ngFor="let item of fgList" [ngValue]="item">{{item.Name}}</option>
                                            </select>
                                            <ips-show-errors [control]="fixtureGroupSelect" [errorMessages]="getErrorMessage('fixtureGroup')"></ips-show-errors>
                                            <div *ngIf="!!fixtureForm.value.fixtureGroupSelect">
                                                <div class="row text-center" [hidden]="!fixtureForm.value.fixtureGroupSelect.Id">
                                                    <!--<br />-->
                                                    <div class="col-lg-1 fixture-group-title d-none d-lg-block required">
                                                        Qty
                                                    </div>
                                                    <div class="col-lg-2 fixture-group-title d-none d-lg-block" translate>
                                                        HOLDER_NAME
                                                    </div>
                                                    <div class="col-lg-2 fixture-group-title d-none d-lg-block required">
                                                        {{ 'SIGN_SIZE' | translate }} <br/> {{ 'SIZE_DESCRIPTION' | translate }}
                                                    </div>
                                                    <div class="col-lg-2 fixture-group-title d-none d-lg-block" translate>
                                                        VERSION_INFO
                                                    </div>
                                                    <div class="col-lg-2 fixture-group-title d-none d-lg-block">
                                                        Sign Plan Options
                                                    </div>
                                                    <div class="col-lg-2 fixture-group-title d-none d-lg-block" translate>
                                                        CATEGORY_CODE
                                                    </div>
                                                    <div class="col-lg-1 fixture-group-title d-none d-lg-block" translate>
                                                        OBSOLETE
                                                    </div>
                                                </div>
                                                <hr [hidden]="!fixtureForm.value.fixtureGroupSelect.Id" />
                                                <div formArrayName="holders">
                                                    <div *ngFor="let item of fixtureForm.controls.holders.controls; let i=index">
                                                        <div class="form-row" [formGroupName]="i">
                                                            <div class="form-group col-12 col-lg-1">
                                                                <label class="d-lg-none required" translate>QUANTITY</label>
                                                                <input type="number" formControlName="quantity"
                                                                       (ngModelChange)="holderQuantityChanged(quantity,item)"
                                                                       class="form-control form-control-lg input-full text-center-lg min-padding-lg" placeholder="{{ 'QTY' | translate}}" />
                                                                <ips-show-errors [control]="item.get('quantity')" [errorMessages]="getErrorMessage('quantity')" cssClass="validation-message-Qty"></ips-show-errors>
                                                            </div>
                                                            <div class="form-group col-12 col-lg-2 mb-0">
                                                                <label class="d-lg-none" translate>HOLDER_NAME</label>
                                                                <div class="col-12 form-control-lg text-center-lg left-padding">
                                                                    {{item.get('holderName').value}}
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-lg-2 mb-0">
                                                                <div class="form-row">
                                                                    <div class="col-12 d-lg-none ml-3 font-weight-bold">
                                                                        <label class="required" translate>SIGN_SIZE_IN_INCHES</label>
                                                                    </div>
                                                                </div>
                                                                <div class="form-row">
                                                                    <div class="col-5 pr-0">
                                                                        <input type="number" formControlName="width"
                                                                               class="form-control form-control-lg input-full col-lg-12 text-center-lg min-padding-lg" placeholder="{{ 'WIDTH' | translate}}"
                                                                               (blur)="msgPrompt(item)">
                                                                        <ips-show-errors [control]="item.get('width')" [errorMessages]="getErrorMessage('width')" cssClass="validation-message-size"></ips-show-errors>
                                                                    </div>
                                                                    <div class="col-2 form-control-lg text-center">
                                                                        X
                                                                    </div>
                                                                    <div class="col-5 pl-0">
                                                                        <input type="number" formControlName="height"
                                                                               class="form-control form-control-lg input-full col-lg-12 text-center min-padding" placeholder="{{ 'HEIGHT' | translate}}"
                                                                               (blur)="msgPrompt(item)">
                                                                        <ips-show-errors [control]="item.get('height')" [errorMessages]="getErrorMessage('height')" cssClass="validation-message-size"></ips-show-errors>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-lg-2">
                                                                <label class="d-lg-none" translate>VERSION_INFO</label>
                                                                <select name="holderVersionInfoId" id="holderVersionInfoId"
                                                                        class="form-control form-control-lg ips-select"
                                                                        formControlName="versionSelect">
                                                                    <!--Needed to make IE default to unselected-->
                                                                    <option *ngIf="!fixtureForm.value.holderVersionInfoId" [ngValue]="null" selected></option>
                                                                    <option *ngFor="let item of versionList" [ngValue]="item">{{item.Name}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group col-12 col-lg-2">
                                                                <label class="d-lg-none">Sign Plan Options</label>
                                                                <app-sign-plan-transform-detail (changesAccepted)="onSignPlanTransformation($event, item)"
                                                                                                [id]="item.value.id"
                                                                                                type="Holder"
                                                                                                [top]="item.value.cropTop"
                                                                                                [left]="item.value.cropLeft"
                                                                                                [width]="item.value.cropWidth"
                                                                                                [height]="item.value.cropHeight"
                                                                                                [rotation]="item.value.cropRotation">
                                                                </app-sign-plan-transform-detail>
                                                            </div>
                                                            <div class="form-group col-12 col-lg-2">
                                                                <label class="d-lg-none" translate>CATEGORY_CODE</label>
                                                                <input type="text" formControlName="categoryCode"
                                                                       class="form-control form-control-lg input-full col-lg-12 text-center min-padding" placeholder="{{ 'CATEGORY_CODE' | translate}}"
                                                                       maxlength="100">
                                                            </div>
                                                            <div class="form-group col-12 col-lg-1 mb-0 ">
                                                                <label class="d-lg-none" translate>OBSOLETE</label>
                                                                <div class="input-group justify-content-center mt-2">
                                                                    <span class="input-group-text">
                                                                        <input type="checkbox" formControlName="isObsolete" name="isObsolete">
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr class="d-lg-none" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="notes" translate>NOTES</label>
                                    <textarea class="form-control form-control-lg input-full" name="notes" id="notes" cols="30" rows="3" formControlName="notes" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                                    <ips-show-errors [control]="notes" [errorMessages]="getErrorMessage()"></ips-show-errors>
                                </div>
                            </div>
                        </tab>

                        <!-- Fixture layout edit tab -->
                        <tab heading="Sign Plan Layout">
                            <div class="form-group">
                                <label>Layout Size</label>
                                <select name="signPlanWidth"
                                        id="signPlanWidth"
                                        class="form-control form-control-lg"
                                        formControlName="signPlanWidth">
                                    <option *ngFor="let option of signPlanWidthOptions" [ngValue]="option.value">{{ option.key }}</option>
                                </select>
                            </div>
                            <div class="py-4 px-3 holder-area bg-light border rounded mb-3" *ngIf="!hasLayoutImage()">
                                <div class="text-secondary font-weight-bold">
                                    <span class="fa fa-info-circle text-info"></span> Choose an image to build your Sign Plan Layout for this fixture.
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-center full-image">
                                <div style="position:relative;">
                                    <ips-image-edit #layoutImage name="layoutFile" [showDelete]="true" (fileChanged)="onSelectedLayoutImageChanged($event)"></ips-image-edit>
                                    <div *ngFor="let signHolder of getPlacedHolders()" class="draggable placed"
                                         (stopped)="onDragEnd($event, signHolder)" (rzStop)="onResizeEnd($event, signHolder)"
                                         [style.top]="signHolder.Top + 'px'"
                                         [style.left]="signHolder.Left + 'px'"
                                         [style.height]="signHolder.Height + 'px'"
                                         [style.width]="signHolder.Width + 'px'"
                                         [style.background]="signHolder.BackgroundColor"
                                         [style.position]="'absolute'" appNgDraggable appNgResizable>
                                        <div class="d-flex align-self-stretch align-content-center justify-content-center h-100 align-items-center">
                                            {{ signHolder.HolderName }} <span>[{{ signHolder.Index }}]</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header">
                                    <h5 class="font-weight-bold mt-0">Sign Placement</h5>
                                    <div *ngIf="allHoldersPlaced()" class="text-success d-flex justify-content-between align-items-center">
                                        <div class="font-weight-bold">
                                            <span class="fa fa-check"></span> All holders have been placed.
                                        </div>
                                        <button class="btn btn-outline-success btn-sm" (click)="resetAll()">Reset All</button>
                                    </div>
                                    <p *ngIf="!allHoldersPlaced()" class="text-secondary font-weight-bold">
                                        <span class="fa fa-info-circle text-info"></span> Drag each sign holder to its position on the image above
                                    </p>
                                </div>
                                <table class="table mb-0">
                                    <thead>
                                        <tr>
                                            <th>Holder</th>
                                            <th>Coordinates</th>
                                            <th>Size</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let signHolder of placedHolders">
                                            <td style="vertical-align:middle;">
                                                <div class="color-preview mr-2" [style.background]="signHolder.BackgroundColor"></div>
                                                <span>{{ signHolder.HolderName}}</span> <span>[{{ signHolder.Index }}]</span>
                                            </td>
                                            <td>{{ signHolder.Left }}, {{ signHolder.Top }}</td>
                                            <td>{{ signHolder.Height }} x {{ signHolder.Width }}</td>
                                            <td>
                                                <span class="link" (click)="placeHolder(signHolder)" *ngIf="!signHolder.Placed"><span class="fa fa-plus"></span> Place</span>
                                                <span class="link mr-2 pr-2 border-right" *ngIf="signHolder.Placed"><span class="fa fa-pencil"></span> Edit</span>
                                                <span class="link" *ngIf="signHolder.Placed" (click)="resetPlacement(signHolder)"><span class="fa fa-undo"></span> Reset</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="placedHolders.length == 0">
                                            <td colspan="2">
                                                <span class="fa fa-info-circle text-info"></span> Please configure Holder Quantities for this fixture
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="card-footer">
                                    <div *ngIf="showStats">
                                        <span class="mr-2">
                                            <label class="font-weight-bold">Ratio: </label> <span>{{ getImageRatio().x }} x {{ getImageRatio().y }}</span>
                                        </span>
                                        <span class="mr-2">
                                            <label class="font-weight-bold">Original Size: </label> <span>{{ originalWidth }} x {{ originalHeight }}</span>
                                        </span>
                                        <span class="mr-2">
                                            <label class="font-weight-bold">Rendered Size: </label> <span>{{ renderedWidth }} x {{ renderedHeight }}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </tab>

                        <!-- Supporting Documents tab -->
                        <tab heading="Supporting Images">
                            <p class="alert alert-secondary p-4">
                                <span class="fa fa-info-circle"></span> Upload Images to be shown alongside this fixture in your Sign Plan documents
                            </p>
                            <button class="btn btn-sm btn-outline-primary mb-3" (click)="promptEdit(null)"><span class="fa fa-plus"></span> Attach New Image</button>
                            <table class="table border">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Label</th>
                                        <th>Width</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let document of attachedDocuments">
                                        <td style="vertical-align:middle;text-align:center;">
                                            <img style="height:auto;max-height:150px;" [style.maxWidth]="(document.width || 300) + 'px'" [src]="attachmentUrl(document)" alt="{{ document.Label }} {{ document.Label2 }}" />
                                        </td>
                                        <td style="vertical-align:middle;">
                                            {{ document.Label }}<br />
                                            {{ document.Label2 }}
                                        </td>
                                        <td style="vertical-align:middle;">
                                            {{ getWidthLabel(document.Width) }}
                                        </td>
                                        <td style="vertical-align:middle;" class="text-center">
                                            <button class="btn btn-sm btn-outline-danger" (click)="promptDelete(document)"><span class="fa fa-remove"></span></button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!attachedDocuments || attachedDocuments.length < 1">
                                        <td colspan="4">
                                            <span class="fa fa-info-circle"></span> There are no supporting images attached for this fixture
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </tab>
                    </tabset>
                </div>
            </form>

            <hr>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-group mt-2">
                        <button id="saveFixture"
                                class="btn btn-lg btn-block alert-success"
                                (click)="saveFixturePrompt(false)"
                                [disabled]="fixtureForm.invalid || fixtureForm.pristine"
                                [ngClass]="{'btn-default': fixtureForm.invalid || fixtureForm.pristine}" translate>
                            SAVE_FIXTURE
                        </button>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-group mt-2">
                        <button id="saveReturnFixture"
                                class="btn btn-lg btn-block alert-success"
                                (click)="saveFixturePrompt(true)"
                                [disabled]="fixtureForm.invalid || fixtureForm.pristine"
                                [ngClass]="{'btn-default': fixtureForm.invalid || fixtureForm.pristine}" translate>
                            SAVE_AND_RETURN
                        </button>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                    <div class="form-group mt-2">
                        <button id="cancelFixture" class="btn btn-default btn-lg btn-block" uiSref="main.storeProfileFixture.search" translate>CANCEL</button>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="form-group mt-2">
                        <button id="deleteFixture"
                                class="btn btn-lg btn-block alert-danger"
                                (click)="deleteFixturePrompt()"
                                ips-unsaved-warning-clear
                                [disabled]="!fixtureForm.value.id"
                                [ngClass]="{'btn-default': !fixtureForm.value.id}" translate>
                            DELETE_FIXTURE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_FIXTURE_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>

<ng-template #editTemplate>
    <div class="modal-header bg-light">
        <h4 class="modal-title pull-left">{{ (editing ? 'Edit Document Attachment' : 'Add Document Attachment') }}</h4>
        <button type="button" class="close pull-right" aria-label="Close" [disabled]="processing" (click)="cancelEdit()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body px-4 text-large pb-0" *ngIf="processingError">
        <p class="alert alert-danger mb-0">
            <span class="fa fa-exclamation-triangle"></span> {{ processingError }}
        </p>
    </div>
    <form novalidate ipsUnsavedWarningForm #addForm>
        <div class="modal-body px-4">
            <div class="form-row form-group">
                <div class="col-12 col-sm-6">
                    <label class="font-weight-bold ml-2 required">Label</label>
                    <input type="text" [(ngModel)]="editTarget.Label" [ngModelOptions]="{ standalone: true }" class="form-control form-control-lg" />
                </div>
            </div>
            <div class="form-row form-group">
                <div class="col-6 col-sm-4 col-lg-3">
                    <label class="font-weight-bold ml-2 required">Label 2</label>
                    <input type="text" [(ngModel)]="editTarget.Label2" [ngModelOptions]="{ standalone: true }" class="form-control form-control-lg" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <label class="font-weight-bold ml-2 required">Width</label>
                    <select [(ngModel)]="editTarget.Width" [ngModelOptions]="{ standalone: true }" class="form-control form-control-lg">
                        <option value="2"> 1/6</option>
                        <option value="3"> 1/4</option>
                        <option value="4">1/3</option>
                        <option value="6">1/2</option>
                        <option value="8">2/3</option>
                        <option value="9">3/4</option>
                        <option value="10">5/6</option>
                        <option value="12">Full page</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <label class="font-weight-bold ml-2 required">Image</label>
                    <ips-image-edit #additionalImage name="additionalImageFile" [showDelete]="true" (fileChanged)="onAdditionalImageChanged($event)"></ips-image-edit>
                </div>
            </div>

        </div>
        <div class="modal-footer">
            <button type="button" [disabled]="processing" class="btn btn-lg btn-link px-5" (click)="cancelEdit()">
                Cancel
            </button>
            <button type="submit" [disabled]="processing || addForm.invalid || addForm.pristine" class="btn btn-lg btn-primary px-5" (click)="save()">
                <span [ngClass]="{ 'fa-spin fa-spinner': processing, 'fa-save': !processing }" class="fa fa-fw"></span> Save
            </button>
        </div>
    </form>
</ng-template>

<ng-template #deleteTemplate>
    <div class="modal-header bg-light">
        <h4 class="modal-title pull-left">Delete {{ deletionTarget.Label }} {{ deletionTarget.Label2 }}</h4>
    </div>
    <div class="modal-body px-4 text-large pb-0" *ngIf="processingError">
        <p class="alert alert-danger mb-0">
            <span class="fa fa-exclamation-triangle"></span> {{ processingError }}
        </p>
    </div>
    <div class="modal-body px-4 text-large">
        <h4>Are you sure you want to delete {{ deletionTarget.Label }} {{ deletionTarget.Label2 }}?</h4>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="processing" class="btn btn-lg btn-link px-5" data-dismiss="modal" (click)="cancelDelete()">
            No, Go Back
        </button>
        <button type="button" [disabled]="processing" class="btn btn-lg btn-danger px-5" (click)="delete()">
            <span class="fa fa-fw" [ngClass]="{ 'fa-spinner fa-spin': processing, 'fa-trash': !processing }"></span> Yes, Delete it
        </button>
    </div>
</ng-template>
