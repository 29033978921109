import { Component, OnInit, Input } from "@angular/core";
import { SurveyInUseModel } from "../../../imagine-ui-ng-survey";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-location-group-delete-modal",
    templateUrl: "./location-group-delete-modal.component.html",
    styleUrls: ["./location-group-delete-modal.component.scss"]
})
export class LocationGroupDeleteModalComponent implements OnInit {
    @Input() surveysInUse: SurveyInUseModel[];
    @Input() modalTitle = "WARNING";

    private cancelMessage = "CANCEL";

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {
    }

    public close() {
        this.activeModal.dismiss(this.cancelMessage);
    }

    public agree(): Promise<any> | void {
        return this.activeModal.close(true);
    }
    public disagree() {
        this.activeModal.dismiss(this.cancelMessage);
    }
}
