import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm, FormGroup } from "@angular/forms";
import { LocationService, StoreFrontCategory } from "../../index";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";
import { IpsMessageService } from "imagine-ui-ng-messaging";

@Component({
    selector: "app-store-front",
    templateUrl: "./store-front.component.html",
    styleUrls: ["./store-front.component.scss"]
})
export class StoreFrontComponent implements OnInit {
    @ViewChild("locationForm") public locationForm: NgForm;

    public storeFrontForm: FormGroup;
    public loaded: Boolean = true;
    public promise: Promise<any>;
    private locService: LocationService;
    public categories: StoreFrontCategory[];

    public dataRow = [
        {
            name: null,
            elements: [],
        },
    ];
    public selectedLocation = null;
    public imageName: string = null;
    public query: string;
    //private querySubject: Subject<string> = new Subject<string>();
    public searchByRef = true;
    public selectedItem = { sideIndex: -1, elementIndex: -1 };
    public categoryTop: number = null;
    public categoryBottom: number = null;

    constructor(private locationService: LocationService,
        private ipsMessage: IpsMessageService) {

        this.locService = locationService;

        // this.querySubject.pipe(
        //     debounceTime(350),
        //     distinctUntilChanged())
        //     .subscribe((model: string) => {
        //         this.getStoreFrontData(model);
        //     });
    }

    ngOnInit() {

        this.locService.storeFrontGetCategories().then((result: any[]) => {
            if (result && result.length > 0) {
                this.categories = result;
            }
        });

    }

    /*TEST DATA ---------------------------------------------*/
    //public dataRow = [
    //    {
    //        name: null,
    //        elements: [
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: true, selected: false },
    //            { type: 'DD', blocked: false, selected: false },
    //            { type: 'DS', blocked: true, selected: true },
    //        ],
    //    },
    //    {
    //        name: null,
    //        elements: [
    //            { type: 'WF', blocked: false, selected: false },
    //            { type: 'WF', blocked: true, selected: false },
    //            { type: 'DD', blocked: false, selected: false },
    //            { type: 'DS', blocked: true, selected: true },
    //        ],
    //    },
    //];
    /*--------------------------------------------------------*/
    public setToBlock = (e, sideIndex: number, elementIndex: number) => {
        e.stopPropagation();
        e.preventDefault();

        this.dataRow[sideIndex].elements[elementIndex].blocked = !this.dataRow[sideIndex].elements[elementIndex].blocked;
    }

    public removeSection = (e, sideIndex: number, elementIndex: number) => {
        e.stopPropagation();
        e.preventDefault();

        this.dataRow[sideIndex].elements.splice(elementIndex, 1);
    }

    public selectItem = (sideIndex: number, elementIndex: number) => {
        this.selectedItem = { sideIndex: sideIndex, elementIndex: elementIndex };
        this.categoryTop = this.dataRow[sideIndex].elements[elementIndex].categoryTop;
        this.categoryBottom = this.dataRow[sideIndex].elements[elementIndex].categoryBottom;

        for (let sIndex = 0; sIndex < this.dataRow.length; sIndex++) {
            for (let eIndex = 0; eIndex < this.dataRow[sIndex].elements.length; eIndex++) {
                if (eIndex === elementIndex && sIndex === sideIndex) {
                    this.dataRow[sIndex].elements[eIndex].selected = true;
                } else {
                    this.dataRow[sIndex].elements[eIndex].selected = false;
                }
            }
        }
    }

    public addElement = (sideIndex: number, type: string) => {
        let selectedIndex = (this.dataRow[sideIndex].elements.length || 1) - 1; //default to add at the end

        let index = this.dataRow[sideIndex].elements.findIndex(q => q.selected);
        if (index > -1) {
            selectedIndex = index;
        }

        this.dataRow[sideIndex].elements.splice(selectedIndex + 1, 0, { selected: true, type: type, blocked: false });
        this.dataRow[sideIndex].elements[selectedIndex].selected = false;
    }

    public clearAll = (sideIndex: number) => {
        this.dataRow[sideIndex].elements = [];
    }

    public addEntrance = () => {
        this.dataRow.push({
            name: null,
            elements: [],
        });
    }

    public removeEntrance = (sideIndex: number) => {
        this.dataRow.splice(sideIndex, 1);
    }

    public onQueryChange(query: string) {
        this.query = query;
        this.locationForm.form.markAsPristine();
    }

    public getStoreFrontData(query: string) {
        this.locService.storeFrontSearch(query, this.searchByRef).then((result: any[]) => {
            if (result && result.length > 0) {
                this.selectedLocation = result[0];
                this.dataRow = result[0].SignPlan ? JSON.parse(result[0].SignPlan.MappingJson) : [
                    {
                        name: null,
                        elements: [],
                    },
                ];
                this.locationForm.form.markAsDirty();
            } else {
                this.selectedLocation = null;
                this.dataRow = [
                    {
                        name: null,
                        elements: [],
                    },
                ];
                this.locationForm.form.markAsPristine();
            }
        });
    }

    public savePrompt() {
        return this.ipsMessage.waitForWork({
            body: "SAVING", workFunction: () => this.save(),
            progressMessage: "SAVING"
        }).then((result) => {

            //TODO add a saved modal popup!

        });
    }

    public onSearchType() {
        this.searchByRef = !this.searchByRef;
    }

    public save(): Promise<void> {

        //Dont store the select item to DB
        if (this.dataRow) {
            for (let sIndex = 0; sIndex < this.dataRow.length; sIndex++) {
                for (let eIndex = 0; eIndex < this.dataRow[sIndex].elements.length; eIndex++) {
                    this.dataRow[sIndex].elements[eIndex].selected = false;
                }
            }
        }


        if (this.selectedLocation.SignPlan && this.selectedLocation.SignPlan.Id > 0) {
            return this.locService.storeFrontPut({
                Id: this.selectedLocation.SignPlan.Id,
                LocationId: this.selectedLocation.Location.Id,
                LocationIdentifier: this.selectedLocation.Location.LocationIdentifier,
                ReferenceIdentifier: this.selectedLocation.Location.ReferenceIdentifier,
                MappingJson: JSON.stringify(this.dataRow)
            }).then((response) => {
                this.locationForm.form.markAsPristine();
                this.locationForm.reset();
                this.searchByRef = true;
                this.selectedLocation = null;
                this.dataRow = [
                    {
                        name: null,
                        elements: [],
                    },
                ];
            });
        } else {
            return this.locService.storeFrontPost({
                Id: 0,
                LocationId: this.selectedLocation.Location.Id,
                LocationIdentifier: this.selectedLocation.Location.LocationIdentifier,
                ReferenceIdentifier: this.selectedLocation.Location.ReferenceIdentifier,
                MappingJson: JSON.stringify(this.dataRow)
            }).then((response) => {
                this.locationForm.form.markAsPristine();
                this.locationForm.reset();
                this.searchByRef = true;
                this.selectedLocation = null;
                this.dataRow = [
                    {
                        name: null,
                        elements: [],
                    },
                ];
            });
        }
    }

    public onCategoryChange(category: number, type: string) {
        if (this.selectedItem) {
            if (type === "top") {
                this.categoryTop = category;
                this.dataRow[this.selectedItem.sideIndex].elements[this.selectedItem.elementIndex].categoryTop = category;

            } else {
                this.categoryBottom = category;
                this.dataRow[this.selectedItem.sideIndex].elements[this.selectedItem.elementIndex].categoryBottom = category;
            }
        }
    }

    public search() {
       this.getStoreFrontData(this.query);
    }

}
