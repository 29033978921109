import { Component, OnInit } from "@angular/core";
import { StateService, StateParams, TransitionService } from "@uirouter/core";
import { SearchInfo, MediaReferenceModel, ActiveProfileService } from "imagine-ui-ng-core";

import { ProductAdminService } from "../../service/product-admin.service";
import { AuthService } from "imagine-ui-ng-security";
import { String } from "typescript-string-operations";

@Component({
    selector: "app-product-admin-search",
    templateUrl: "./product-search.component.html"
})
export class ProductAdminSearchComponent implements OnInit {
    public currentSearchText: string;
    public searchTerms: string[] = [];
    public searchResults: any[];

    public processing = false;
    public searchProblems = false;

    constructor(private stateService: StateService,
        private productAdminService: ProductAdminService,
        private transitionService: TransitionService,
        private activeProfileService: ActiveProfileService,
        private authService: AuthService) { }

    ngOnInit() {
        this.processQueryParams(this.stateService.params);
    }

    addSearchTerm() {
        const term = this.currentSearchText;

        if (term) {
            const findResult = this.searchTerms.find((x) => x.toLowerCase() === term.toLowerCase());

            if (!findResult) {
                const tempSearchTerms = this.searchTerms;
                tempSearchTerms.push(term);
                this.searchTerms = tempSearchTerms.sort();
            }

            this.currentSearchText = "";

            this.doSearch();
        }
    }

    removeSearchTerm(term: string) {
        const targetIndex = this.searchTerms.findIndex((x) => x.toLowerCase() === term.toLowerCase());

        if (targetIndex >= 0) {
            this.searchTerms.splice(targetIndex, 1);
        }

        if (this.searchTerms.length) {
            this.doSearch();
        } else {
            this.searchResults = null;
            this.updateQueryString();
        }
    }

    doSearch(updateQueryString = true) {
        this.processing = true;

        const searchInfo = <SearchInfo>{};
        searchInfo.chunkIndex = 0;
        searchInfo.recordCount = 20;

        const searchTermText = this.searchTerms.join(";");

        searchInfo.additionalQueryParams = [{ param: "query", paramValue: searchTermText }, { param: "rowCount", paramValue: "100" }, { param: "ShowIssues", paramValue: this.searchProblems.toString() }];

        this.productAdminService.search(searchInfo).then((response) => {
            this.searchResults = (response && response.ResultList) || [];
            this.processing = false;
        });

        if (updateQueryString) {
            this.updateQueryString();
        }
    }

    public processQueryParams(parms: StateParams) {
        let searchString = "";

        if (parms.searchTerm) {
            searchString = decodeURIComponent(parms.searchTerm);
            this.searchTerms = searchString.split(",");

            this.doSearch(false);
        }
    }

    private updateQueryString() {
        const searchTermString = this.searchTerms.join(",");
        this.stateService.transitionTo(this.stateService.current, { searchTerm: searchTermString }, { location: "replace" });
    }

    public getImageUrl(mediaId: string): string {
        let mediaToUse = mediaId;

        if (!mediaId) {
            mediaToUse = this.getDefaultImage();
        } else {
            if (this.isBlankGuid(mediaId)) {
                mediaToUse = this.getDefaultImage();
            }
        }

        return this.buildImageUrl(mediaToUse);
    }

    private isBlankGuid(guid: string): boolean {
        const blankGuid = "00000000-0000-0000-0000-000000000000";
        return guid === blankGuid;
    }

    private getDefaultImage(): string {
        const returnModel: MediaReferenceModel = {
            Id: null,
            FileName: "thumbnail.jpg",
            FileExtension: "jpg",
            ResourceUri: "/assets/img/imageNotAvailable.png?BusinessIdentity=0",
            MimeType: null,
            Measurement: null,
            Content: null,
            ContentSize: null
        };

        return this.buildImageUrl(returnModel.ResourceUri);
    }

    private buildImageUrl(mediaResourceUri: string): string {
        let returnUri = mediaResourceUri;
        if (mediaResourceUri.indexOf("BusinessIdentity") === -1) {
            returnUri = String.Format("{0}?BusinessIdentity={1}&idToken={2}", mediaResourceUri,
                this.activeProfileService.businessIdentity, this.authService.getIdToken());
        }

        return returnUri;
    }

    public searchType(input: boolean) {
        this.searchProblems = input;

        this.doSearch();
    }
}
