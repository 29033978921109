<div class="col-12">
    <div>
        <form
            #locationForm="ngForm"
            id="ramiInfoInput"
            class="form-horizontal updateSection"
            name="locationForm"
            novalidate
            ipsUnsavedWarningForm
            role="form"
        >
            <div [hidden]="!loaded">
                <div class="store-front">
                    <div>
                        <h2>Store Front Setup</h2>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="row mb-3 gap-3">
                                    <div class="col-12">
                                        <div
                                            class="border border-dark border-2 p-3"
                                        >
                                            <h4
                                                class="fw-bold d-flex justify-content-between"
                                            >
                                                <span>Location Search</span>
                                            </h4>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div
                                                        class="input-group mb-3 position-relative"
                                                    >
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="{{
                                                                searchByRef
                                                                    ? 'Search by Reference #'
                                                                    : 'Search by Location Id'
                                                            }}"
                                                            style="
                                                                padding-left: 2.25rem;
                                                            "
                                                            [(ngModel)]="query"
                                                            (ngModelChange)="
                                                                onQueryChange(
                                                                    $event
                                                                )
                                                            "
                                                            name="query"
                                                        />
                                                        <div
                                                            class="input-group-append"
                                                        >
                                                            <button
                                                                class="btn btn-outline-secondary"
                                                                type="button"
                                                                (click)="
                                                                    onSearchType()
                                                                "
                                                            >
                                                                {{
                                                                    searchByRef
                                                                        ? "By Ref#"
                                                                        : "By Location Id"
                                                                }}
                                                            </button>
                                                        </div>
                                                        <span
                                                            style="
                                                                position: absolute;
                                                                z-index: 5;
                                                                left: 12px;
                                                                top: 7px;
                                                            "
                                                            class="text-black-50"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                class="bi bi-search"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                                                                />
                                                            </svg>
                                                        </span>
                                                        <button
                                                            type="button"
                                                            class="btn btn-default ml-2 px-4"
                                                            (click)="search()"
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <h2
                                    *ngIf="
                                        selectedLocation &&
                                        selectedLocation?.Location?.IsClosed
                                    "
                                    class="text-danger"
                                >
                                    Location is currently Closed!
                                </h2>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <h2 *ngIf="selectedLocation">
                                    Ref#:
                                    {{
                                        selectedLocation?.Location
                                            ?.ReferenceIdentifier
                                    }}
                                    / Location ID:
                                    {{
                                        selectedLocation?.Location
                                            ?.LocationIdentifier
                                    }}
                                </h2>
                            </div>
                        </div>

                        <div class="row">
                            <div *ngIf="selectedLocation" class="col-12">
                                <div
                                    *ngFor="
                                        let sideItem of dataRow;
                                        let sideIndex = index
                                    "
                                    class="row mb-3"
                                >
                                    <div class="col-12">
                                        <div
                                            class="border border-dark border-2 pt-3 px-3"
                                        >
                                            <h4
                                                class="fw-bold d-flex justify-content-between"
                                            >
                                                <span>
                                                    #{{ sideIndex + 1 }}
                                                    {{
                                                        sideIndex == 0
                                                            ? "Primary Entrance"
                                                            : "Alternative Entrance"
                                                    }}
                                                </span>
                                                <span>
                                                    <button
                                                        class="btn btn-sm btn-outline-danger"
                                                        (click)="
                                                            removeEntrance(
                                                                sideIndex
                                                            )
                                                        "
                                                    >
                                                        X Remove
                                                    </button>
                                                </span>
                                            </h4>
                                            <div
                                                class="scrolling-wrapper mb-3 bg-light border border-secondary"
                                            >
                                                <div
                                                    class="inin"
                                                    style="vertical-align: top"
                                                >
                                                    <img
                                                        src="../../../../assets/img/storefront/left.png"
                                                        width="173"
                                                        height="248"
                                                    />
                                                </div>
                                                <div
                                                    class="inin bg-top"
                                                    style="font-size: 1rem"
                                                >
                                                    <div>
                                                        <div
                                                            class="p-0 d-flex justify-content-center"
                                                            style="
                                                                height: 106px;
                                                            "
                                                        >
                                                            <img
                                                                src="../../../../assets/img/storefront/top_logo.png"
                                                                width="42"
                                                                height="50"
                                                            />
                                                        </div>
                                                        <div class="p-0">
                                                            <div
                                                                *ngFor="
                                                                    let item of sideItem.elements;
                                                                    let index = index
                                                                "
                                                                class="inline position-relative"
                                                                [ngClass]="{
                                                                    'border border-3 border-warning':
                                                                        item.selected
                                                                }"
                                                            >
                                                                <div
                                                                    [ngSwitch]="
                                                                        item.type
                                                                    "
                                                                >
                                                                    <div
                                                                        *ngSwitchCase="
                                                                            'WF'
                                                                        "
                                                                        class="inline doorWin border-0 p-0"
                                                                        (click)="
                                                                            selectItem(
                                                                                sideIndex,
                                                                                index
                                                                            )
                                                                        "
                                                                    >
                                                                        <img
                                                                            src="../../../../assets/img/storefront/full_window.png"
                                                                            width="60"
                                                                            height="{{
                                                                                item.selected
                                                                                    ? '140'
                                                                                    : '142'
                                                                            }}"
                                                                            [ngClass]="{
                                                                                blockElement:
                                                                                    item.blocked
                                                                            }"
                                                                        />

                                                                        <button
                                                                            class="btn btn-warning btn-sm showBlock"
                                                                            [ngClass]="{
                                                                                showButtons:
                                                                                    item.selected
                                                                            }"
                                                                            (click)="
                                                                                setToBlock(
                                                                                    $event,
                                                                                    sideIndex,
                                                                                    index
                                                                                )
                                                                            "
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-eye"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                                                                                />
                                                                                <path
                                                                                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                        <button
                                                                            class="btn btn-danger btn-sm showDelete"
                                                                            [ngClass]="{
                                                                                showButtons:
                                                                                    item.selected
                                                                            }"
                                                                            (click)="
                                                                                removeSection(
                                                                                    $event,
                                                                                    sideIndex,
                                                                                    index
                                                                                )
                                                                            "
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        *ngSwitchCase="
                                                                            'WH'
                                                                        "
                                                                        class="inline doorWin border-0 p-0"
                                                                        (click)="
                                                                            selectItem(
                                                                                sideIndex,
                                                                                index
                                                                            )
                                                                        "
                                                                    >
                                                                        <img
                                                                            src="../../../../assets/img/storefront/half_window.png"
                                                                            width="60"
                                                                            height="{{
                                                                                item.selected
                                                                                    ? '140'
                                                                                    : '142'
                                                                            }}"
                                                                            [ngClass]="{
                                                                                blockElement:
                                                                                    item.blocked
                                                                            }"
                                                                        />

                                                                        <button
                                                                            class="btn btn-warning btn-sm showBlock"
                                                                            [ngClass]="{
                                                                                showButtons:
                                                                                    item.selected
                                                                            }"
                                                                            (click)="
                                                                                setToBlock(
                                                                                    $event,
                                                                                    sideIndex,
                                                                                    index
                                                                                )
                                                                            "
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-eye"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                                                                                />
                                                                                <path
                                                                                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                        <button
                                                                            class="btn btn-danger btn-sm showDelete"
                                                                            [ngClass]="{
                                                                                showButtons:
                                                                                    item.selected
                                                                            }"
                                                                            (click)="
                                                                                removeSection(
                                                                                    $event,
                                                                                    sideIndex,
                                                                                    index
                                                                                )
                                                                            "
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        *ngSwitchCase="
                                                                            'DD'
                                                                        "
                                                                        class="inline doorWin border-0 p-0"
                                                                        (click)="
                                                                            selectItem(
                                                                                sideIndex,
                                                                                index
                                                                            )
                                                                        "
                                                                    >
                                                                        <img
                                                                            src="../../../../assets/img/storefront/double_door.png"
                                                                            width="90"
                                                                            height="{{
                                                                                item.selected
                                                                                    ? '140'
                                                                                    : '142'
                                                                            }}"
                                                                            [ngClass]="{
                                                                                blockElement:
                                                                                    item.blocked
                                                                            }"
                                                                        />

                                                                        <button
                                                                            class="btn btn-warning btn-sm showBlock"
                                                                            [ngClass]="{
                                                                                showButtons:
                                                                                    item.selected
                                                                            }"
                                                                            (click)="
                                                                                setToBlock(
                                                                                    $event,
                                                                                    sideIndex,
                                                                                    index
                                                                                )
                                                                            "
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-eye"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                                                                                />
                                                                                <path
                                                                                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                        <button
                                                                            class="btn btn-danger btn-sm showDelete"
                                                                            [ngClass]="{
                                                                                showButtons:
                                                                                    item.selected
                                                                            }"
                                                                            (click)="
                                                                                removeSection(
                                                                                    $event,
                                                                                    sideIndex,
                                                                                    index
                                                                                )
                                                                            "
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        *ngSwitchCase="
                                                                            'DS'
                                                                        "
                                                                        class="inline doorWin border-0 p-0"
                                                                        (click)="
                                                                            selectItem(
                                                                                sideIndex,
                                                                                index
                                                                            )
                                                                        "
                                                                    >
                                                                        <img
                                                                            src="../../../../assets/img/storefront/single_door.png"
                                                                            width="48"
                                                                            height="{{
                                                                                item.selected
                                                                                    ? '140'
                                                                                    : '142'
                                                                            }}"
                                                                            [ngClass]="{
                                                                                blockElement:
                                                                                    item.blocked
                                                                            }"
                                                                        />

                                                                        <button
                                                                            class="btn btn-warning btn-sm showBlock"
                                                                            [ngClass]="{
                                                                                showButtons:
                                                                                    item.selected
                                                                            }"
                                                                            (click)="
                                                                                setToBlock(
                                                                                    $event,
                                                                                    sideIndex,
                                                                                    index
                                                                                )
                                                                            "
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                                className="bi bi-eye"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                                                                                />
                                                                                <path
                                                                                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                        <button
                                                                            class="btn btn-danger btn-sm showDelete"
                                                                            [ngClass]="{
                                                                                showButtons:
                                                                                    item.selected
                                                                            }"
                                                                            (click)="
                                                                                removeSection(
                                                                                    $event,
                                                                                    sideIndex,
                                                                                    index
                                                                                )
                                                                            "
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="inin"
                                                    style="vertical-align: top"
                                                >
                                                    <img
                                                        src="../../../../assets/img/storefront/right.png"
                                                        width="169"
                                                        height="248"
                                                    />
                                                </div>
                                            </div>

                                            <div class="row my-3">
                                                <div class="col-12">
                                                    <div
                                                        class="border border-secondary p-2"
                                                    >
                                                        <div
                                                            class="row"
                                                            [ngClass]="{
                                                                visible:
                                                                    sideIndex ===
                                                                    selectedItem.sideIndex,
                                                                invisible:
                                                                    !selectedItem ||
                                                                    sideIndex !==
                                                                        selectedItem.sideIndex
                                                            }"
                                                        >
                                                            <div
                                                                class="col-3 form-group"
                                                            >
                                                                <label
                                                                    class="form-label fw-bold"
                                                                    >Category
                                                                    Top</label
                                                                >
                                                                <select
                                                                    name="categoryTop"
                                                                    [(ngModel)]="
                                                                        categoryTop
                                                                    "
                                                                    (ngModelChange)="
                                                                        onCategoryChange(
                                                                            $event,
                                                                            'top'
                                                                        )
                                                                    "
                                                                    class="form-control dropdownBtn form-control-lg ellipsis"
                                                                >
                                                                    <option
                                                                        [ngValue]="
                                                                            null
                                                                        "
                                                                    >
                                                                        -None-
                                                                    </option>
                                                                    <option
                                                                        *ngFor="
                                                                            let cat of categories
                                                                        "
                                                                        [ngValue]="
                                                                            cat.Id
                                                                        "
                                                                    >
                                                                        {{
                                                                            cat.Name
                                                                        }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div
                                                                class="col-3 form-group"
                                                            >
                                                                <label
                                                                    class="form-label fw-bold"
                                                                    >Category
                                                                    Bottom</label
                                                                >
                                                                <select
                                                                    name="categoryBottom"
                                                                    [(ngModel)]="
                                                                        categoryBottom
                                                                    "
                                                                    (ngModelChange)="
                                                                        onCategoryChange(
                                                                            $event,
                                                                            'bottom'
                                                                        )
                                                                    "
                                                                    class="form-control dropdownBtn form-control-lg ellipsis"
                                                                >
                                                                    <option
                                                                        [ngValue]="
                                                                            null
                                                                        "
                                                                    >
                                                                        -None-
                                                                    </option>
                                                                    <option
                                                                        *ngFor="
                                                                            let cat of categories
                                                                        "
                                                                        [ngValue]="
                                                                            cat.Id
                                                                        "
                                                                    >
                                                                        {{
                                                                            cat.Name
                                                                        }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="p-0 row my-3">
                                                <div class="col-4">
                                                    <div
                                                        class="border border-secondary form-group p-2"
                                                    >
                                                        <label
                                                            class="form-label text-center fw-bold"
                                                            >Doors</label
                                                        >
                                                        <button
                                                            class="btn btn-sm btn-primary form-control mb-2"
                                                            (click)="
                                                                addElement(
                                                                    sideIndex,
                                                                    'DD'
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <img
                                                                    src="../../../../assets/img/storefront/door-double-icon.png"
                                                                    width="15"
                                                                    height="15"
                                                                    class="mr-2"
                                                                />
                                                                Add Double Door
                                                            </div>
                                                        </button>
                                                        <button
                                                            class="btn btn-sm btn-primary form-control mb-2"
                                                            (click)="
                                                                addElement(
                                                                    sideIndex,
                                                                    'DS'
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <img
                                                                    src="../../../../assets/img/storefront/door-single-icon.png"
                                                                    width="15"
                                                                    height="15"
                                                                    class="mr-2"
                                                                />
                                                                Add Single Door
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-4 d-grid gap-2">
                                                    <div
                                                        class="border border-secondary form-group p-2"
                                                    >
                                                        <label
                                                            class="form-label text-center fw-bold"
                                                            >Windows</label
                                                        >
                                                        <button
                                                            class="btn btn-sm btn-primary form-control mb-2"
                                                            (click)="
                                                                addElement(
                                                                    sideIndex,
                                                                    'WF'
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <img
                                                                    src="../../../../assets/img/storefront/window-full-icon.png"
                                                                    width="15"
                                                                    height="15"
                                                                    class="mr-2"
                                                                />
                                                                Add Full Window
                                                            </div>
                                                        </button>
                                                        <button
                                                            class="btn btn-sm btn-primary form-control mb-2"
                                                            (click)="
                                                                addElement(
                                                                    sideIndex,
                                                                    'WH'
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <img
                                                                    src="../../../../assets/img/storefront/window-half-icon.png"
                                                                    width="15"
                                                                    height="15"
                                                                    class="mr-2"
                                                                />
                                                                Add Half Window
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div
                                                        class="border border-secondary d-flex align-items-end p-2"
                                                    >
                                                        <button
                                                            class="btn btn-sm btn-secondary form-control mb-2"
                                                            (click)="
                                                                clearAll(
                                                                    sideIndex
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    class="bi bi-x mr-2"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                                                    />
                                                                </svg>
                                                                Clear All
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedLocation" class="row mb-4">
                            <div class="col-6 d-grid">
                                <button
                                    class="btn btn-sm btn-secondary"
                                    (click)="addEntrance()"
                                >
                                    <div
                                        class="d-flex align-items-center justify-content-center"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-shop mr-2"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z"
                                            />
                                        </svg>
                                        Add Entrance
                                    </div>
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div class="row">
                            <div class="col-3">
                                <button
                                    id="saveLocation"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="savePrompt()"
                                    [disabled]="!selectedLocation"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ips-error-handler
        label="{{ 'ERROR_ACCESSING_LOCATION_INFO' | translate }}"
        [promise]="promise"
    ></ips-error-handler>
</div>
