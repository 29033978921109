var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { environment } from "../../../../environments/environment";
import { BaseWebServiceWithDefaultImage } from "imagine-ui-ng-quick-start";
var LocationGroupService = /** @class */ (function (_super) {
    __extends(LocationGroupService, _super);
    function LocationGroupService(http, activeProfileService, authService) {
        var _this = _super.call(this, http, environment, activeProfileService, authService) || this;
        _this.init("LocationGroup", "Profile", "v1");
        return _this;
    }
    LocationGroupService.prototype.canSwitchRule = function (locationGroupId) {
        var route = this.urlApiBaseProductPlusVersion + "LocationGroup/{0}/canSwitchRule";
        return this.getByRoute(route.replace("{0}", locationGroupId));
    };
    LocationGroupService.prototype.setLocFeatureInfo = function (locationGroup, $translate) {
        var singleAssignmentTooltip = "TOOLTIP_LOCATION_GROUP_RULE_SINGLE";
        var multipleAssignmentTooltip = "TOOLTIP_LOCATION_GROUP_RULE_MULTIPLE";
        var singleAssignmentClass = "location-group-rule-single";
        var multipleAssignmentClass = "location-group-rule-multiple";
        var textOptionClass = "option-type-text-icon";
        var yesNoOptionClass = "option-type-bool-icon";
        var numberOptionClass = "option-type-number-icon";
        var dateOptionClass = "option-type-date-icon";
        switch (locationGroup.Rule) {
            case "Multiple":
                locationGroup.ruleTooltip = multipleAssignmentTooltip;
                locationGroup.ruleText = $translate.instant("LOCATION_GROUP_RULE_MULTIPLE");
                locationGroup.ruleClass = multipleAssignmentClass;
                break;
            case "Single":
                locationGroup.ruleTooltip = singleAssignmentTooltip;
                locationGroup.ruleText = $translate.instant("LOCATION_GROUP_RULE_SINGLE");
                locationGroup.ruleClass = singleAssignmentClass;
                break;
            default:
                locationGroup.ruleText = "";
                locationGroup.ruleClass = "";
                locationGroup.ruleTooltip = "";
                break;
        }
        switch (locationGroup.OptionType) {
            case "Number":
                locationGroup.optionTypeText = $translate.instant("OPTION_TYPE_NUMBER");
                locationGroup.optionTypeClass = numberOptionClass;
                break;
            case "Bool":
                locationGroup.optionTypeText = $translate.instant("OPTION_TYPE_BOOL");
                locationGroup.optionTypeClass = yesNoOptionClass;
                break;
            case "Date":
                locationGroup.optionTypeText = $translate.instant("OPTION_TYPE_DATE");
                locationGroup.optionTypeClass = dateOptionClass;
                break;
            default:
                locationGroup.optionTypeText = $translate.instant("OPTION_TYPE_TEXT");
                locationGroup.optionTypeClass = textOptionClass;
                break;
        }
        locationGroup.ruleTooltip = $translate.instant(locationGroup.ruleTooltip).replace("{0}", locationGroup.Name);
    };
    LocationGroupService.prototype.saveList = function (locationId, locationGroupList) {
        var route = this.urlApiBaseProductPlusVersion + "Location/" + locationId + "/LocationGroup/saveList";
        return this.put(locationGroupList, null, route);
    };
    LocationGroupService.prototype.simpleSearch = function (searchParams) {
        return this.search(searchParams, "LocationGroup/SimpleSearch");
    };
    LocationGroupService.prototype.simpleSearchWithSubGroup = function (searchParams) {
        return this.search(searchParams, "LocationGroup/SimpleSearchWithSub");
    };
    LocationGroupService.prototype.compareNumber = function () {
        return function (a, b) {
            if (a.IsNA)
                return 1;
            if (b.IsNA)
                return -1;
            return a.Name - b.Name;
        };
    };
    LocationGroupService.prototype.compareString = function () {
        return function (a, b) {
            if (a.IsNA)
                return 1;
            if (b.IsNA)
                return -1;
            return a.Name.localeCompare(b.Name);
        };
    };
    LocationGroupService.prototype.compareDate = function () {
        return function (a, b) {
            if (a.IsNA)
                return 1;
            if (b.IsNA)
                return -1;
            a = new Date(a.Name);
            b = new Date(b.Name);
            return a > b ? 1 : a < b ? -1 : 0;
        };
    };
    LocationGroupService.prototype.sortSubGroup = function (subGroups, optionType) {
        switch (optionType) {
            case "Number":
                subGroups.sort(this.compareNumber());
                break;
            case "Date":
                subGroups.sort(this.compareDate());
                break;
            default:
                subGroups.sort(this.compareString());
                break;
        }
    };
    LocationGroupService.prototype.saveFeatureImage = function (locationFeatureId, file) {
        var route = this.urlApiBaseProductPlusVersion + "LocationGroup/" + locationFeatureId + "/Image";
        return this.put({ Id: locationFeatureId }, file, route);
    };
    LocationGroupService.prototype.saveOptionImage = function (featureOptionId, file) {
        var route = this.urlApiBaseProductPlusVersion + "LocationSubGroup/" + featureOptionId + "/Image";
        return this.put({ Id: featureOptionId }, file, route);
    };
    LocationGroupService.prototype.deleteCheck = function (id) {
        var route = this.urlApiBaseProductPlusVersion + "LocationSubGroup/" + id + "/DeleteCheck";
        return this.getByRoute(route);
    };
    return LocationGroupService;
}(BaseWebServiceWithDefaultImage));
export { LocationGroupService };
