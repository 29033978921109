<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_DATA_INFO' | translate}}}">
        <div [hidden]="!loaded">
            <form id="ramiInfoInput" #customDataForm="ngForm" class="form-horizontal" name="customDataForm" novalidate ipsUnsavedWarningForm role="form">
                <div class="form-row">
                    <section class="form-group col-12 col-md-6">
                        <label class="required" for="name" translate>NAME</label>
                        <input type="text" name="name" #name="ngModel" id="name" required appFieldNameValidator="{{originName}}" IpsMaxLength="50" [(ngModel)]="myCustomData.Name"
                               class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_CUSTOM_FIELD_NAME' | translate}}">
                        <ips-show-errors [control]="name" [errorMessages]="getErrorMessages('name')"></ips-show-errors>
                    </section>
                </div>
                <div class="form-row">
                    <section class="form-group col-12 col-md-6">
                        <label for="notes" translate>NOTES</label>
                        <textarea [(ngModel)]="myCustomData.Notes" class="form-control form-control-lg input-full" name="notes" #notes="ngModel" id="" cols="30" rows="3" IpsMaxLength="500" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                        <ips-show-errors [control]="notes" [errorMessages]="errorMessages"></ips-show-errors>
                    </section>
                </div>
                <div class="form-row">
                    <section class="form-group no-round-grp col-12 col-md-6">
                        <label class="required" translate>AREA</label>
                        <div class="no-round" *ngFor="let area of areaList; let last = last">
                            <div class="input-group">
                                <div class="form-control form-control-lg input-full no-float" [ngClass]="{'option-type-disabled': editingExisting}">
                                    {{area.DisplayName | translate}}
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <input name="{{area.Name}}" type="radio" [value]="area.Id" required #myArea="ngModel" [disabled]="editingExisting" [(ngModel)]="myCustomData.AreaId" (change)="onAreaChange(area)" />
                                    </span>
                                </div>
                            </div>
                            <ips-show-errors [control]="myArea" [errorMessages]="getErrorMessages('area')"></ips-show-errors>
                        </div>
                    </section>
                </div>
                <div class="form-row col-md-6 pl-0" *ngIf="myCustomData.AreaId === 'RequestForm'">
                    <section class="form-group col-12 field-rule-container">
                        <label class="required" translate>FIELD_GROUP</label>
                        <div class="no-round pb-3" *ngFor="let group of customDataGroupList; let i = index">
                            <div class="input-group">
                                <input type="text"
                                       [name]="group.inputName"
                                       required IpsMaxLength="50" #newSubArea="ngModel"
                                       class="form-control form-control-lg input-full no-float"
                                       placeholder="{{ 'ENTER_FIELD_GROUP' | translate}}"
                                       [ngClass]="{'mb-0': errors, 'option-type-disabled': editingExisting}"
                                       [(ngModel)]="group.Name">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <input name="{{group.Name + i}}" type="radio" [value]="group.Id" required #mySubArea="ngModel" [disabled]="editingExisting" [(ngModel)]="myCustomData.CustomDataGroupId" (change)="onCustomDataGroupChange(group)" />
                                    </span>
                                    <span class="input-group-text clicker input-x" (click)="removeGroup(group.inputName)" ngbTooltip="{{'DELETE_HOLDER' | translate}}" placement="bottom"><i class="fa fa-remove fa-lg"></i></span>
                                </div>
                            </div>
                            <div class="pb-2 mt-0"></div>
                            <ips-show-errors [control]="newSubArea" [errorMessages]="getErrorMessages('newSubArea')"></ips-show-errors>
                            <ips-show-errors [control]="mySubArea" [errorMessages]="getErrorMessages('group')"></ips-show-errors>
                        </div>
                            <div class="form-row pt-2">
                                <div class="form-group col-12">
                                    <button type="button" class="btn btn-lg btn-block add-holder" (click)="addFieldGroup()" name="addFieldGroup" [disabled]="!canAddFieldGroup">
                                        <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
                                        <span class="add-holder-label" translate>ADD_FIELD_GROUP</span>
                                    </button>
                                </div>
                            </div>
                    </section>
                </div>

                <div class="form-row">
                    <section class="form-group col-12 col-md-6">
                        <label class="required" for="ordinal" translate>FIELD_ORDER</label>
                        <input type="number" name="ordinal" #ordinal="ngModel" id="ordinal" required IpsMaxLength="50" appPositiveWholeNumberRequiredValidator [(ngModel)]="myCustomData.Ordinal" (ngModelChange)="onFieldOrderChange($event)"
                               class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_FIELD_ORDER' | translate}}">
                        <ips-show-errors [control]="ordinal" [errorMessages]="getErrorMessages('fieldorder')"></ips-show-errors>
                        <div *ngIf="showOrdinalWarning" class="ips-text-warning">
                            <div translate>FIELED_ORDER_WARNING</div>
                        </div>
                    </section>
                </div>
                <div class="form-row">
                    <section class="form-group col-12 col-md-6">
                        <label class="required" translate>REQUIREMENT_RULE</label>
                        <div class="no-round-grp">
                            <div class="input-group no-round">
                                <div class="form-control form-control-lg input-full no-float" translate>
                                    FIELD_REQUIRED
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <input name="requiredY" type="radio" [value]="true" required [(ngModel)]="myCustomData.IsFieldRequired" />
                                    </span>
                                </div>
                            </div>
                            <div class="input-group no-round">
                                <div class="form-control form-control-lg input-full no-float" translate>
                                    FIELD_NOT_REQUIRED
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <input name="requiredN" #myRule="ngModel" type="radio" [value]="false" required [(ngModel)]="myCustomData.IsFieldRequired" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ips-show-errors [control]="myRule" [errorMessages]="getErrorMessages('rule')"></ips-show-errors>
                    </section>
                </div>
                <div class="form-row">
                    <section class="form-group no-round-grp col-12 col-md-6">
                        <label class="required" translate>DATA_COLLECTION</label>
                        <div class="no-round" *ngFor="let fieldType of fieldTypes; let last = last">
                            <div class="input-group">
                                <div class="form-control form-control-lg input-full no-float" [ngClass]="{'option-type-disabled': editingExisting}">
                                    <div class="d-inline-block col-1 px-0 text-center mr-1"><span class="location-group-rule-icon mr-2" [ngClass]="fieldType.IconClass"></span></div><span>{{fieldType.DisplayName | translate}}</span>
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <input name="{{fieldType.TypeName}}" type="radio" [value]="fieldType.TypeName" required #myFieldType="ngModel" [disabled]="editingExisting" [(ngModel)]="myCustomData.FieldType" (change)="onFieldTypeChange(fieldType)" />
                                    </span>
                                </div>
                            </div>
                            <ips-show-errors [control]="myFieldType" [errorMessages]="getErrorMessages('fieldtype')"></ips-show-errors>
                        </div>
                    </section>
                </div>
                <div class="form-row col-md-6 pl-0" *ngIf="myCustomData.FieldType && myCustomData.FieldType !== 'Date' && myCustomData.FieldType !== 'Number' && myCustomData.FieldType !== 'Email'">
                    <section class="form-group col-12 field-rule-container">
                        <label class="required">{{ruleName}}</label>
                        <div class="no-round-grp pt-1">
                            <div class="input-group no-round">
                                <div class="form-control form-control-lg input-full no-float" [ngClass]="{'option-type-disabled': editingExisting}">{{singleRule}}</div>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <input name="ruleSingle" type="radio" value="Single" required [disabled]="editingExisting" [(ngModel)]="myCustomData.SelectionRule" />
                                    </span>
                                </div>
                            </div>
                            <div class="input-group no-round">
                                <div class="form-control form-control-lg input-full no-float" [ngClass]="{'option-type-disabled': editingExisting}"> {{multipleRule}} </div>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <input name="ruleMultiple" #ruleMultiple="ngModel" type="radio" value="Multiple" required [disabled]="editingExisting" [(ngModel)]="myCustomData.SelectionRule" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ips-show-errors [control]="ruleMultiple" [errorMessages]="getErrorMessages('rule')"></ips-show-errors>
                        <div class="form-group" *ngIf="myCustomData.FieldType === 'List'">
                            <label class="required pt-3" translate>FIELD_SELECTIONS</label>
                            <div class="no-round-grp pt-1">
                                <div *ngFor="let option of myCustomData.CustomDataOptionList; let i = index">
                                    <div class="form-row">
                                        <div class="col-12 d-flex align-items-center">
                                            <input type="text"
                                                   [name]="option.ControlName"
                                                   [disabled]="option.Id !== 0"
                                                   required IpsMaxLength="50" #myOption="ngModel"
                                                   class="form-control form-control-lg input-full"
                                                   placeholder="{{ 'ENTER_FIELD_SELECTION' | translate}}"
                                                   [ngClass]="{'mb-0': myOption.errors}"
                                                   [(ngModel)]="option.Name"
                                                   (change)="onOptionChange(option)">
                                            <button class="clicker input-x" (click)="removeFieldOption(i)" ngbTooltip="{{'DELETE_FIELD_SELECTION' | translate}}" placement="bottom"><i class="fa fa-remove fa-lg"></i></button>
                                        </div>
                                    </div>
                                    <div class="pb-2 mt-0"><ips-show-errors [control]="myOption" [errorMessages]="getErrorMessages('fieldselection')"></ips-show-errors></div>

                                </div>
                            </div>
                            <div class="form-row pt-2">
                                <div class="form-group col-12">
                                    <button type="button" class="btn btn-lg btn-block add-holder" (click)="addFieldOption()" name="addFieldOption" ipsFocus="{{focusableInput}}">
                                        <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
                                        <span class="add-holder-label" translate>ADD_FIELD_SELECTION</span>
                                    </button>

                                </div>
                            </div>

                        </div>
                    </section>
                </div>
                <hr>
                <div class="row">
                    <section class="col-12 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveCustomData"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveCustomDataPrompt(false)"
                                    [disabled]="disableSave()"
                                    [ngClass]="{'btn-default': customDataForm.form.invalid || customDataForm.form.pristine}" translate>
                                SAVE
                            </button>
                        </div>
                    </section>
                    <section class="col-12 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveReturnCustomData"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveCustomDataPrompt(true)"
                                    [disabled]="disableSave()"
                                    [ngClass]="{'btn-default': customDataForm.form.invalid || customDataForm.form.pristine}" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </section>
                    <section class="col-12 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="cancelCustomData" class="btn btn-default btn-lg btn-block" uiSref="main.customdata.search" translate>CANCEL</button>
                        </div>
                    </section>
                    <section class="col-12 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="deleteCustomData"
                                    class="btn btn-lg btn-block alert-danger"
                                    (click)="deleteCustomDataPrompt()"
                                    ips-unsaved-warning-clear
                                    [disabled]="!myCustomData.Id"
                                    [ngClass]="{'btn-default': !myCustomData.Id}" translate>
                                DELETE
                            </button>
                        </div>
                    </section>
                </div>
            </form>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_CUSTOM_DATA_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
