import { OnInit, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StateService } from "@uirouter/core";
import { distinctUntilChanged, debounceTime, map } from "rxjs/operators";
import { Subject } from "rxjs";
import { ListSearchHelper, SearchInfo, ActiveProfileService, HelperFunctionsService } from "imagine-ui-ng-core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { ShopSettingsService } from "../../service/shop-settings.service";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { LocationService, CartService, CartDataModel, KeyValuePair } from "../../index";
import { PaymentSettingsService } from "../../index";
var CartSearchComponent = /** @class */ (function () {
    // ----- CONSTRUCTOR -----
    function CartSearchComponent(locationService, cartService, listSearchHelper, activeProfileService, ipsModal, ipsMessage, stateService, helperService, translateService, shopSettingsService, paymentSettingsService) {
        var _this = this;
        this.locationService = locationService;
        this.cartService = cartService;
        this.activeProfileService = activeProfileService;
        this.ipsModal = ipsModal;
        this.ipsMessage = ipsMessage;
        this.stateService = stateService;
        this.helperService = helperService;
        this.translateService = translateService;
        this.shopSettingsService = shopSettingsService;
        this.paymentSettingsService = paymentSettingsService;
        this.busy = true;
        this.screenLoaded = false;
        this.showOrderReason = true;
        this.orderReasons = [];
        this.selectedLocation = null;
        this.searchText = "";
        this.locale = "en-US";
        this.pluralMapping = {};
        this.querySubject = new Subject();
        this.showCreditButton = false;
        this.showInvoiceButton = false;
        this.checkoutButtonLabel = "Checkout by Invoice";
        this.showAllLocationsFilter = false;
        this.TextToTranslate = [
            "PROMPT_CLEAR_CART",
        ];
        this.cartItemHelper = listSearchHelper.getListSearchHelper(cartService);
        this.querySubject.pipe(debounceTime(350), distinctUntilChanged())
            .subscribe(function (model) {
            _this.getListData(model);
        });
    }
    // ----- FUNCTIONS -----
    CartSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locale = this.activeProfileService.profile.Locale || navigator.language;
        this.searchScrollKey = "cart_search_scroll_" + this.activeProfileService.businessIdentity;
        var scrollInfo = JSON.parse(localStorage.getItem(this.searchScrollKey));
        if (scrollInfo) {
            this.searchText = scrollInfo.search;
            localStorage.removeItem(this.searchScrollKey);
        }
        this.processQueryParams(this.stateService.params);
        var promises = [];
        promises.push(this.getShopSettings());
        promises.push(this.shopSettingsService.getOrderReasons().then(function (response) {
            _this.orderReasons = response.sort(function (a, b) { return a > b ? 1 : -1; });
        }));
        promises.push(this.translateService.get(["PRODUCT", "PRODUCTS"]).toPromise().then(function (response) {
            _this.pluralMapping["=1"] = response["PRODUCT"];
            _this.pluralMapping["other"] = response["PRODUCTS"];
        }));
        promises.push(this.cartService.head().then(function (response) {
            _this.totalRecordCount = response;
        }));
        promises.push(this.shopSettingsService.getCheckoutLabelSetting().then(function (response) {
            _this.checkoutButtonLabel = response;
        }));
        //Only allow All Locations for Admin users
        if (this.isInRole(["EcommSiteAdmin", "ShoppingAdmin"])) {
            promises.push(this.shopSettingsService.getAllLocationsSetting().then(function (response) {
                if (response) {
                    _this.showAllLocationsFilter = response;
                }
            }));
        }
        else {
            this.showAllLocationsFilter = false;
        }
        Promise.all(promises).then(function () {
            _this.screenLoaded = true;
            _this.getListData(_this.searchText, true, scrollInfo);
        });
        this.cartService.onCartUpdated.pipe(map(function (x) { return x.TotalQuantity; }), distinctUntilChanged()).subscribe(function (data) {
            _this.CurrentCart = _this.cartService.myCurrentCartData();
            _this.cartService.getMyInvalidCartItems().then(function (response) {
                _this.CurrentCart.InvalidCartItems = response;
                _this.CurrentCart.HasInvalidItems = _this.cartService.invalidCartItems.length > 0;
            });
            var tempLocations = _this.CurrentCart.Items.map(function (x) { return x.LocationId; });
            _this.cartLocations = tempLocations.filter(function (x, pos) {
                return tempLocations.indexOf(x) === pos; // Ensures only the first instance is added.
            });
            if (_this.screenLoaded) {
                _this.getListData(_this.searchText, false);
            }
            else {
                if (_this.CurrentCart.Locations.length > 0) {
                    _this.selectedLocation = {
                        Key: _this.CurrentCart.Locations[0].Key,
                        Value: _this.CurrentCart.Locations[0].Value
                    };
                }
            }
            //Grab the payment method for each location
            _this.paymentSettingsService.getAvailablePaymentMethods(_this.CurrentCart.Locations && _this.CurrentCart.Locations.length > 0 ? _this.CurrentCart.Locations.map(function (i) { return i.Key; }) : [0]).then(function (result) {
                if (!!result.find(function (cl) { return cl.CreditCard; })) {
                    _this.showCreditButton = true;
                }
                if (!!result.find(function (cl) { return cl.Invoice; })) {
                    _this.showInvoiceButton = true;
                }
                _this.busy = false;
            });
        });
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
    };
    Object.defineProperty(CartSearchComponent.prototype, "matchingRecordCount", {
        get: function () {
            return this.cartItemHelper.ResultChunkAttributes.TotalRecords || 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CartSearchComponent.prototype, "showNoResults", {
        get: function () {
            return this.selectedLocation &&
                this.cartItemHelper.chunkIndex > 0 &&
                this.cartItemHelper.resultList.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    CartSearchComponent.prototype.getShopSettings = function () {
        var _this = this;
        return this.shopSettingsService.getSettingByName("ShowOrderReason").then(function (response) {
            if (response) {
                _this.showOrderReason = (response.Value === "item");
            }
        });
    };
    // Calls the translation service to process text.
    CartSearchComponent.prototype.translateText = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.translateService.get(_this.TextToTranslate).toPromise()
                .then(function (response) {
                _this.TranslatedTexts = response;
                resolve();
            });
        });
        return promise;
    };
    CartSearchComponent.prototype.checkout = function (paymentMethod) {
        this.stateService.transitionTo("main.checkout", { cartId: this.CurrentCart.Id, paymentMethod: paymentMethod });
    };
    CartSearchComponent.prototype.clearCart = function () {
        var _this = this;
        this.ipsMessage.confirm({
            title: "WARNING",
            body: "PROMPT_CLEAR_CART",
            ok: "YES_REMOVE_PRODUCTS",
            cancel: "NO_KEEP_PRODUCTS"
        }).then(function () {
            _this.ipsMessage.waitForWork({
                body: "PROCESSING",
                workFunction: function () { return _this.cartService.clearMyCart(); },
                progressMessage: "PROCESSING"
            });
        })
            .catch(function () {
            // rejection
        });
    };
    CartSearchComponent.prototype.clearCurrentLocationFromCart = function () {
        var _this = this;
        var translated = this.translateService.instant("PROMPT_CLEAR_CART_LOCATION", { locationName: this.selectedLocation.Value });
        // translated = IpsString.Format(translated, this.selectedLocation.Value);
        this.ipsMessage.confirm({
            title: "WARNING",
            body: translated,
            ok: "YES_REMOVE_PRODUCTS",
            cancel: "NO_KEEP_PRODUCTS"
        }).then(function () {
            _this.ipsMessage.waitForWork({
                body: "PROCESSING",
                workFunction: function () { return _this.cartService.clearLocationFromMyCart(_this.selectedLocation.Key); },
                progressMessage: "PROCESSING"
            }).then(function () {
                _this.stateService.transitionTo("main.mycart", { locationId: 0 }, { reload: true });
            });
        })
            .catch(function () {
            // rejection
        });
    };
    CartSearchComponent.prototype.chooseLocation = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: this.showAllLocationsFilter, allLabel: "NO_LOCATION_SPECIFIED", search: "location", listOfLocationIds: this.cartLocations } }).then(function (response) {
            var locId = response.item.Id === 0 ? -1 : response.item.Id;
            _this.selectedLocation.Key = locId;
            _this.selectedLocation.Value = _this.cartService.getLocationDisplay(locId);
            _this.getListData("");
        }, 
        // Rejected
        function () {
            // remove the first item, which is All
        });
    };
    CartSearchComponent.prototype.getListData = function (search, updateQueryString, scrollInfo) {
        var _this = this;
        if (updateQueryString === void 0) { updateQueryString = true; }
        if (!search) {
            search = "";
        }
        this.searchInfo = {
            searchText: search || ""
        };
        if (this.selectedLocation) {
            this.searchInfo.additionalQueryParams =
                [{ param: "LocationId", paramValue: this.selectedLocation.Key.toString() }];
            // Update the count to reflect the total items in the cart for the current location.
            this.totalRecordCount =
                this.cartService.itemsInLocationCart(this.selectedLocation.Key);
            this.promise = this.cartItemHelper.searchHelper(this.searchInfo, true, undefined)
                .then(function (response) {
                if (scrollInfo) {
                    scrollInfo.chunkIndex--;
                    if (scrollInfo.chunkIndex > 0) {
                        _this.getListData(search, false, scrollInfo);
                    }
                    else {
                        setTimeout(function () { window.scrollTo(0, scrollInfo.positionY); }, 500);
                    }
                }
                if (updateQueryString) {
                    _this.updateQueryString();
                }
                // Return for the promise
                return true;
            });
        }
    };
    CartSearchComponent.prototype.onQueryChange = function (query) {
        this.searchText = query;
        this.querySubject.next(query);
    };
    CartSearchComponent.prototype.processQueryParams = function (parms) {
        if (parms.locationId) {
            this.selectedLocationId = parms.locationId;
        }
    };
    CartSearchComponent.prototype.updateQueryString = function () {
        this.helperService.updateURLParameters({
            locationId: this.selectedLocation.Key
        });
    };
    CartSearchComponent.prototype.onWindowScroll = function ($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        }
        else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    };
    CartSearchComponent.prototype.ngOnDestroy = function () {
        if (this.cartItemHelper) {
            localStorage.setItem(this.searchScrollKey, JSON.stringify({
                search: this.searchText || null,
                positionY: this.scrollY,
                chunkIndex: this.cartItemHelper.chunkIndex
            }));
        }
    };
    CartSearchComponent.prototype.isInRole = function (roleNames) {
        var allThemes = this.activeProfileService.profile.businessIdentityList;
        var allRoles = [];
        allThemes.forEach(function (theme) {
            allRoles.push.apply(allRoles, theme.Role);
        });
        var matchingRoles = allRoles.filter(function (item) { return roleNames.indexOf(item) >= 0; });
        return matchingRoles.length > 0;
    };
    return CartSearchComponent;
}());
export { CartSearchComponent };
