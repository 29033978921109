
<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>

        </section>
    </div>
    <div [cgBusy]="promise" [cgBusyConfig]="{message: accessHolderVersionInfo}">
        <div class="row" [hidden]="!loaded">
            <div class="col-12 updateSection">
                <form id="ramiInfoInput" #holderversionForm="ngForm" class="form-horizontal" name="holderversionForm" novalidate ipsUnsavedWarningForm role="form">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 form-group">
                        <label class="required" for="Name" translate>NAME</label>
                        <input type="text" name="name" #holderversionName="ngModel" class="form-control form-control-lg input-full" id="name" placeholder="{{ 'ENTER_VERSION_INFO_NAME' | translate}}"
                               required maxlength="50" [(ngModel)]="holderversion.Name" appValidation />
                        <div *ngIf="holderversionName.errors" class="invalid-feedback">
                            <div *ngIf="holderversionName.errors.required">
                                {{ 'PLEASE_ENTER_NAME' | translate}}
                            </div>
                            <div *ngIf="holderversionName.errors.maxlength">
                                {{ validationMaxLength(50) }}
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 form-group">
                        <label for="notes" translate>NOTES</label>
                        <textarea #notes="ngModel" [(ngModel)]="holderversion.Notes" class="form-control form-control-lg input-full"  maxlength="500"  appValidation
                                  name="notes" id="" cols="30" rows="3" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                        <div *ngIf="notes.errors" class="invalid-feedback">
                            <div *ngIf="notes.errors.maxlength">
                                {{ validationMaxLength(500) }}
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-3 col-lg-3 form-group">
                            <div class="formSubmit">
                                <button id="saveReturnholderversion"
                                        class="btn btn-lg btn-block alert-success" type="submit"
                                        (click)="saveVersionInfoPrompt()"
                                        [disabled]="holderversionForm.form.invalid || holderversionForm.form.pristine"
                                        [ngClass]="{'btn-default': holderversionForm.form.invalid || holderversionForm.form.pristine}" translate>
                                    SAVE_AND_RETURN
                                </button>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                            <div class="formSubmit">
                                <button id="cancelHolderVersion" class="btn btn-lg btn-block btn-default" type="submit" uiSref="main.storeProfileHolderVersion.search" translate>CANCEL</button>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3 col-md-3 offset-sm-2 offset-md-3">
                            <div class="formSubmit">
                                <button id="deleteHolderVersion" class="btn btn-lg btn-block alert-danger" type="submit"
                                        (click)="deleteVersionInfoPrompt()"
                                        ips-unsaved-warning-clear
                                        [disabled]="!holderversion.Id"
                                        [ngClass]="{'btn-default': !holderversion.Id}" translate>
                                    DELETE_VERSION_INFO
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_VERSION_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>

