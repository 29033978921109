import { Component, OnInit } from "@angular/core";
import { AlternateAddressService } from "../../service/alternate-address.service";
import { Transition } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { AlternateAddressModel } from "../../";

@Component({
  selector: "app-alternate-address-view",
  templateUrl: "./alternate-address-view.component.html",
  styleUrls: ["./alternate-address-view.component.scss"]
})
export class AlternateAddressViewComponent implements OnInit {

    private dataService: AlternateAddressService;


    public optionList: HTMLOptionElement[];
    public selectedOption: HTMLOptionElement;
    public alternateAddress: AlternateAddressModel;
    public promise: Promise<void>;
    public loaded: boolean;
    public busy: boolean;


    public addressInvalidWarning = "ADDRESS_INVALID";
    public addressNotValidated = "ADDRESS_NOT_VALIDATED";
    public information = "INFORMATION_UPPERCASE";
    public showOpenDateEmptyWarning = false;
    public showOpenDateAfterSuspendDateWarning = false;
    public showSuspendDateBeforeOpenDateWarning = false;
    public translatedTexts: TranslatedTexts;

    private alternateAddressId: number;

    constructor(
        private transition: Transition,
        private alternateAddressService: AlternateAddressService,
        private translateService: TranslateService) {

        this.dataService = alternateAddressService;

        this.alternateAddressId = Number(this.transition.params().id);
        this.alternateAddress = { Id: this.alternateAddressId } as AlternateAddressModel;

        this.busy = false;
    }

    ngOnInit() {
        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() => this.TranslateText());

        // If we got an ID to load, load it.
        if (this.alternateAddressId > 0) {
            //Initial call to populate screen on load
            this.getLocation(Number(this.alternateAddressId));
        }
    }

    private TranslateText() {
        this.addressInvalidWarning = this.translateService.instant("ADDRESS_INVALID");
        this.addressNotValidated = this.translateService.instant("ADDRESS_NOT_VALIDATED");
        this.information = this.translateService.instant("INFORMATION_UPPERCASE");
    }
    private getLocation(id: number) {
        this.loaded = false;

        // Make sure to return the promise.
        this.promise = this.dataService.get<AlternateAddressModel>(id).then((response: any) => {
            Object.assign(this.alternateAddress, response);
            this.loaded = true;
        });
    }

}
