<button class="btn btn-lg btn-default btn-block" (click)="edit()" [disabled]="!imageUrl">{{ getButtonLabel() }}</button>

<div class="sign-plan-transform-container">

</div>

<ng-template #editTemplate>
    <div class="modal-header bg-ips-gray">
        <h4 class="modal-title pull-left">{{ type }} Sign Plan Transformations</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form novalidate ipsUnsavedWarningForm (submit)="acceptChanges()" *ngIf="loaded">
        <div class="modal-body px-4">
            <div class="d-lg-none">
                <div class="bg-light text-black-50 px-3 py-2 border-danger border-left border-xl">
                    <span class="fa fa-info-circle text-danger"></span> The image crop and rotation feature is not available on a screen this size.  Please try again with a larger screen.
                </div>
            </div>
            <div class="d-none d-lg-block">
                <div class="bg-light text-black-50 mb-3 px-3 py-2 border-info border-left border-xl">
                    <span class="fa fa-info-circle text-info"></span> Image shown is a sample taken from a recent campaign and may not reflect current promotional data
                </div>
                <tabset class="order-tabs">
                    <tab heading="Crop and Rotate">
                        <div class="form-row">
                            <div class="col-12 form-group">
                                <label class="font-weight-bold ml-2 required">Crop Image</label>
                                <div class="d-flex justify-content-center">
                                    <div class="image-crop d-inline-block" #imageCrop>
                                        <img [src]="imageUrl" />
                                        <div class="loading-overlay d-flex align-items-center justify-content-stretch" *ngIf="loadingContent">
                                            <div class="text-center w-100">
                                                <div class="mb-4">
                                                    <span class="fa fa-2x fa-spin fa-circle-o-notch"></span>
                                                </div>
                                                <p class="mb-0 h5">Loading image data...</p>
                                            </div>
                                        </div>
                                        <div class="draggable"
                                             (stopped)="onDragEnd($event)" (rzStop)="onResizeEnd($event)"
                                             [bounds]="imageCrop"
                                             [inBounds]="true"
                                             [style.top]="(model.top || 0) + 'px'"
                                             [style.left]="(model.left || 0) + 'px'"
                                             [style.height]="model.height ? model.height + 'px' : '100%'"
                                             [rzContainment]="imageCrop"
                                             [style.width]="model.width ? model.width + 'px' : '100%'" appNgDraggable appNgResizable *ngIf="!loadingContent">
                                            <pre *ngIf="enableDiagnostics">{{ model | json }}</pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="font-weight-bold ml-2 mt-3 mt-sm-0">Rotation</label>
                                <select name="rotation" class="form-control form-control-lg" [(ngModel)]="model.rotation">
                                    <option [ngValue]="0">No Rotation</option>
                                    <option [ngValue]="90">Rotate Right</option>
                                    <option [ngValue]="270">Rotate Left</option>
                                    <option [ngValue]="180">Rotate Upside Down</option>
                                </select>
                            </div>
                        </div>
                    </tab>

                    <tab heading="Preview Output">
                        <div class="form-row">
                            <div class="col-12">
                                <div class="preview">
                                    <label class="font-weight-bold ml-2 mt-3 mt-sm-0">Preview Image Output</label>
                                    <div class="">
                                        <div [style.height]="getLargestDimension(model) + 'px'" class="d-flex justify-content-center align-items-center border bg-light">
                                            <div class="image-crop d-inline-block"
                                                 [style.height]="model.height + 'px'"
                                                 [style.width]="model.width + 'px'"
                                                 [ngStyle]="{ 'transform': getPreviewTransform(model) }">
                                                <img [src]="imageUrl"
                                                     [style.left]="'-' + model.left + 'px'"
                                                     [style.top]="'-' + model.top + 'px'">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-lg-none text-center">
                <button type="button" class="btn btn-lg btn-link border px-5" (click)="cancel()">
                    OK
                </button>
            </div>
            <div class="d-none d-lg-block text-center">
                    <button type="button" class="btn btn-lg btn-link border px-5 mr-3" (click)="cancel()">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-lg btn-default px-5" (click)="acceptChanges()">
                        <span class="fa fa-fw fa-check"></span> Accept Changes
                    </button>
                </div>
            </div>
</form>
</ng-template>
