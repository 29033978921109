import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { CustomDataWithValueModel } from "../model/CustomDataWithValueModel";
import { CustomDataValueSaveModel } from "../model/CustomDataValueSaveModel";
import { FormGroup, FormControl, FormArray, Validators, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

@Injectable()
export class CustomDataFieldService extends IpsBaseWebService {

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("CustomData", "Profile", "v1");
    }

    public getCustomDataWithValue(area: string, linkId: number, subArea: string): Promise<CustomDataWithValueModel[]> {
        let route = `CustomData/${area}/${linkId}/CustomDataWithValue?subAreaType=${subArea}`;
        return this.getByRoute<CustomDataWithValueModel[]>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public putCustomData(inputData: CustomDataValueSaveModel[]): Promise<CustomDataValueSaveModel[]> {
        let route = `CustomDataValue`;
        return this.put<CustomDataValueSaveModel[]>(inputData , null, `${this.urlApiBaseProductPlusVersion}${route}`);
    }


    public createNameControl(name: string, isFieldRequired: boolean): FormControl {
        let nameCtrl = new FormControl(name);
        if (isFieldRequired) {
            nameCtrl.setValidators([Validators.required, this.ValidateCheckName()]);
        } else {
            nameCtrl.setValidators([this.ValidateCheckName()]);
        }
        nameCtrl.updateValueAndValidity();
        return nameCtrl;
    }

    public ValidateCheckName(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control && control.value !== "" && control.parent) {

                let validNameControl = control.parent.get("ValidName");
                if (control.value !== validNameControl.value) {
                    return {
                        optionNameInvalid: {
                            valid: false
                        }
                    };
                }
            }
            return null;
        };
    }

}
