var PaymentMethodSettingModel = /** @class */ (function () {
    function PaymentMethodSettingModel() {
    }
    return PaymentMethodSettingModel;
}());
export { PaymentMethodSettingModel };
var AdminPaymentMethodSettingsModel = /** @class */ (function () {
    function AdminPaymentMethodSettingsModel() {
    }
    Object.defineProperty(AdminPaymentMethodSettingsModel.prototype, "IsValid", {
        get: function () {
            return this.CreditCardEnabled || this.InvoiceEnabled;
        },
        enumerable: true,
        configurable: true
    });
    return AdminPaymentMethodSettingsModel;
}());
export { AdminPaymentMethodSettingsModel };
var UserPaymentMethodSettingsModel = /** @class */ (function () {
    function UserPaymentMethodSettingsModel() {
        this.LocationSubGroups = [];
    }
    return UserPaymentMethodSettingsModel;
}());
export { UserPaymentMethodSettingsModel };
var SubGroupSettingViewModel = /** @class */ (function () {
    function SubGroupSettingViewModel() {
    }
    Object.defineProperty(SubGroupSettingViewModel.prototype, "IsValid", {
        get: function () {
            var returnVal = this.CreditCardEnabled || this.InvoiceEnabled;
            return returnVal;
        },
        enumerable: true,
        configurable: true
    });
    return SubGroupSettingViewModel;
}());
export { SubGroupSettingViewModel };
var ShopperSettingsViewModel = /** @class */ (function () {
    function ShopperSettingsViewModel() {
    }
    Object.defineProperty(ShopperSettingsViewModel.prototype, "IsValid", {
        get: function () {
            if (this.AllLocations) {
                return this.CreditCardEnabled || this.InvoiceEnabled;
            }
            else {
                var isValid_1 = this.LocationGroupSettings.length > 0;
                this.LocationGroupSettings.forEach(function (setting) {
                    isValid_1 = (isValid_1 && setting.IsValid);
                });
                return isValid_1;
            }
        },
        enumerable: true,
        configurable: true
    });
    ShopperSettingsViewModel.prototype.setLocationGroupInfo = function (id, name, pendingLocations) {
        this.LocationGroupId = id;
        this.LocationGroupName = name;
        this.PendingLocations = pendingLocations;
    };
    ShopperSettingsViewModel.prototype.setLocationSubGroups = function (groups) {
        this.LocationGroupSettings = groups.map(function (item) {
            var returnValue = new SubGroupSettingViewModel();
            returnValue.CreditCardEnabled = false;
            returnValue.InvoiceEnabled = false;
            returnValue.SubGroupId = item.Id;
            returnValue.SubGroupName = item.Name;
            return returnValue;
        });
    };
    return ShopperSettingsViewModel;
}());
export { ShopperSettingsViewModel };
var PaymentMethodSettingsViewModel = /** @class */ (function () {
    function PaymentMethodSettingsViewModel() {
    }
    Object.defineProperty(PaymentMethodSettingsViewModel.prototype, "IsValid", {
        get: function () {
            return this.ShopperSettings.IsValid && this.AdminSettings.IsValid;
        },
        enumerable: true,
        configurable: true
    });
    return PaymentMethodSettingsViewModel;
}());
export { PaymentMethodSettingsViewModel };
