import { Component, OnInit } from "@angular/core";
import { SearchInfo, SearchResultModel, ListSearchHelper } from "imagine-ui-ng-core";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchComponent, ResultDetailComponent, TranslatedTexts } from "imagine-ui-ng-list-search";
import { FixtureGroupService, FixtureTypeSearchResultModel } from "../../index";

// allows us to have objects that look like both interfaces
interface FixtureTypeSearchResultHybird extends FixtureTypeSearchResultModel, SearchResultModel {
}

@Component({
    selector: "app-fixture-group-search",
    templateUrl: "./fixture-group-search.component.html",
    styleUrls: ["./fixture-group-search.component.scss"]
})
export class FixtureGroupSearchComponent implements OnInit {
    public fixtureGroup: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<void>;
    private searchLabel: string;
    private searchLabelPlural: string;
    public translated = false;

    constructor(private fixtureGroupService: FixtureGroupService, private listSearchHelper: ListSearchHelper, private translateService: TranslateService) {
        this.fixtureGroup = listSearchHelper.getListSearchHelper(fixtureGroupService);
        this.busy = this.fixtureGroup.busy;

        this.fixtureGroupService.head().then((data) => {
            this.fixtureGroup.TotalRecords = data;
        });
    }

    ngOnInit() {

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
        this.getListData("");
    }

    private translateText() {
        this.translateService.get(["FIXTURE_GROUP", "FIXTURE_GROUPS"]).subscribe((res: [string]) => {
            this.searchLabel = res["FIXTURE_GROUP"];
            this.searchLabelPlural = res["FIXTURE_GROUPS"];
            this.translated = true;
        });
    }

    public getListData(search: string) {

        if (!search) {
            search = "";
        }

        let searchInfo: SearchInfo = { searchText: search };
        this.promise = this.fixtureGroup.searchHelper(searchInfo, undefined, undefined).then(() => {
            // the default search page wants a the standard search result model, so let"s convert
            // since this gets called on subsequent loads (e.g., load more) avoid the reconvert if
            // it"s been done because the data service caches the previous results
            this.fixtureGroup.resultList.forEach((result: FixtureTypeSearchResultHybird) => {
                if (result.Label !== result.Name) {
                    result.Label = result.Name;
                    result.ChildList = result.Holders.map((holder) => {
                        return holder.Name;
                    });
                }
            });
        });
    }
}
