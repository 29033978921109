import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    FormControl,
    FormArray,
    AbstractControl,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString } from "typescript-string-operations";
import {
    MarketModel,
    MarketService,
    MarketSaveModel,
    MarketOperator,
    DataService,
    ProfileQuestionModel,
    MarketEditUIModel,
    MarketDisplayInfoLocationSubGroup,
} from "../index";
import { Observable } from "rxjs";
import { IpsModalService } from "imagine-ui-ng-modal";
import { PromotionMessageModel } from "../../imagine-ui-ng-campaign/model/PromotionMessageModel";
import {
    MarketLocationBalanceListRequestModel,
    PromotionMessageService,
} from "../../imagine-ui-ng-campaign";
import { AudienceBalanceListRequestModel } from "../../imagine-ui-ng-survey";
import { SearchModalComponent } from "../../shared/search-modal/search-modal.component";
import { MarketsAndBalanceListModel } from "../model/MarketsAndBalanceListModel";
import { MarketGroupService } from "../service/market-group.service";

@Component({
    selector: "app-market-edit",
    templateUrl: "./market-edit.component.html",
    styleUrls: ["./market-edit.component.scss"],
})
export class MarketEditComponent implements OnInit, OnDestroy, OnChanges {
    @Input() marketsModel: MarketModel[];
    @Input() parent: FormGroup;
    @Input() label: string; // Label to use for title
    @Input() balanceCalcService: PromotionMessageService | MarketGroupService;
    @Input() promoStartDate?: Date;
    @Input() promoEndDate?: Date;
    @Input() marketsToExclude: MarketModel[] = []; // Set values here that you want disabled in modals and typeahead
    @Output() balanceChanged = new EventEmitter<number>(true);
    // Fired when the market changes. Will include the parent FormGroup passed in.
    @Output() marketChanged = new EventEmitter<FormGroup>(true);
    @Output() marketFinalCountChanged = new EventEmitter<number[]>(true);

    private pleaseEnterValid_key = "";
    private pleaseEnter_key = "";
    public refineAudience_key = "";
    public refineAudienceInfo_key = "";
    public featuresInAudience = [] as MarketModel[];
    public featuresInQuestion = [] as ProfileQuestionModel[];
    public fixtureGroupsInQuestion = [] as ProfileQuestionModel[];
    public fixturesInQuestion = [] as ProfileQuestionModel[];
    private initialized = false;

    private TranslateStrings: { [key: string]: string } = {
        MARKET_PENDING_LOCATIONS_WARNING: "",
        MAX_LENGTH_ERROR: "",
    };

    private errorMessages = {
        required: () => this.TranslateStrings[this.pleaseEnter_key],
        maxlength: (params) =>
            IpsString.Format(
                this.TranslateStrings["MAX_LENGTH_ERROR"],
                params.requiredLength
            ),
        invalidMarketName: () =>
            this.TranslateStrings[this.pleaseEnterValid_key],
    };

    private marketSources: Observable<any>[] = [];
    public focusableMarketInput: string;
    private isMsgMarket = false;

    constructor(
        private translateService: TranslateService,
        private ipsModal: IpsModalService,
        private fb: FormBuilder,
        private marketService: MarketService,
        private dataService: DataService
    ) {}

    ngOnInit() {
        this.isMsgMarket = !!(
            this.balanceCalcService as PromotionMessageService
        ).calculateMarketLocationBalanceList;

        this.pleaseEnterValid_key = `PLEASE_ENTER_VALID_${this.label}`;
        this.pleaseEnter_key = `PLEASE_ENTER_${this.label}`;
        this.refineAudience_key = `REFINE_${this.label}`;
        this.refineAudienceInfo_key = `REFINE_${this.label}_INFO`;

        if (!this.marketsToExclude) {
            this.marketsToExclude = [];
        } else {
            this.featuresInAudience.concat(this.marketsToExclude);
        }
        // track featuresInAudience internally
        this.featuresInQuestion = this.dataService.featuresInQuestion;
        this.fixtureGroupsInQuestion = this.dataService.fixtureGroupsInQuestion;
        this.fixturesInQuestion = this.dataService.fixturesInQuestion;

        this.TranslateStrings[this.pleaseEnterValid_key] = "";
        this.TranslateStrings[this.pleaseEnter_key] = "";

        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() =>
            this.TranslateText()
        );
        this.CreateForm();
        this.InitalizeForm(this.marketsModel);
        this.initialized = true;
    }

    ngOnDestroy() {
        // Clear these out, we done with them and any changes while open
        // don't apply to the next instance of this component.
        this.dataService.featuresInQuestion = [];
        this.dataService.featuresInAudience = [];
        this.dataService.fixtureGroupsInQuestion = [];
        this.dataService.fixturesInQuestion = [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.initialized) {
            return;
        }

        const marketsToExcludeChange = changes.marketsToExclude;
        if (marketsToExcludeChange) {
            this.setCurrentAudiences();
        }
    }

    private TranslateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    }

    private CreateForm() {
        let marketCtrl = this.marketService.newMarketFormGroup(1);
        //let marketGroup = this.fb.group({
        //    markets: this.fb.array([marketCtrl])
        //});
        let marketArray = this.fb.array([marketCtrl]);
        if (this.parent.get("Markets")) {
            this.parent.removeControl("Markets");
        }
        this.parent.addControl("Markets", marketArray);
    }

    private InitalizeForm(marketsModel: MarketModel[]) {
        //init markets
        while (this.Markets.length > 0) {
            this.Markets.removeAt(0);
        }
        if (this.Markets.length === 0) {
            this.featuresInAudience = [];
            this.dataService.updateStorage(marketsModel, "FeatureInAudience"); //clear data in featureinaudience
        }
        if (marketsModel.length > 0) {
            this.AddWithExistingMarket(marketsModel);
        } else {
            this.refineMarket();
        }
    }

    public AddWithExistingMarket(
        marketsModel: MarketModel[],
        toUpdate: Boolean = false
    ) {
        let prevMarket = this.Markets.value;
        let marketLength = prevMarket.length;
        let marketCtrl: any;
        if (marketLength !== 0) {
            if (
                prevMarket[marketLength - 1].TargetMarketId === 0 &&
                toUpdate &&
                !this.Markets.valid
            ) {
                this.Markets.removeAt(marketLength - 1);
            }
        }
        if (marketsModel.length > 0) {
            marketsModel.map((market: MarketModel) => {
                marketCtrl = this.marketService.getMarketFormGroup(market);
                this.addMarketObservable(marketCtrl);
                this.Markets.push(marketCtrl);
            });
            this.balanceChanged.emit(
                marketsModel[marketsModel.length - 1].LocationBalance
            );
        } else {
            this.refineMarket();
        }

        if (toUpdate) {
            marketsModel = this.featuresInAudience.concat(marketsModel);
        }

        //Reset prior list of in use markets
        this.featuresInAudience = marketsModel.concat(this.marketsToExclude);
        // Add to data service aulso if anyone is using the data service to track this. Can't use this for promotion edit because features can be used in multiple
        // audiences, one per message.
        this.dataService.updateStorage(marketsModel, "FeatureInAudience");
    }

    private hasFinalCountObservers(): boolean {
        return (
            this.marketFinalCountChanged.observers &&
            this.marketFinalCountChanged.observers.length > 0
        );
    }

    public AddNewMarkets(marketsModel: MarketModel[]) {
        this.marketsModel = marketsModel;
        this.marketSources = [];
        this.CreateForm();
        this.InitalizeForm(this.marketsModel);
    }

    public getMarketSource(index) {
        return this.marketSources[index];
    }

    public refineMarket(): void {
        let newMarket = this.marketService.newMarketFormGroup(
            this.Markets.length + 1
        );
        this.addMarketObservable(newMarket);
        this.Markets.push(newMarket);
        this.focusableMarketInput = newMarket.value.InputName;
    }

    public removeMarket(marketIndex: number): void {
        let addObservable = false;
        if (this.Markets.length === 1) {
            addObservable = true;
        }
        //save market observables
        this.marketService.removeMarket(marketIndex, this.Markets);
        //update market observables after delete market;
        if (addObservable) {
            this.marketSources.splice(0, 1);
            this.addMarketObservable(this.Markets.at(0));
        } else {
            for (let i = marketIndex; i < this.Markets.length; i++) {
                this.marketSources[i] = this.marketSources[i + 1];
            }
            this.marketSources.splice(this.Markets.length, 1);
        }

        this.Markets.markAsDirty();
    }

    /*
     *Call this to open a search modal to search locations within a specific Location Balance
     */
    public showMarketLocationBalanceLocations(marketOrdinal: number): void {
        if (this.isMsgMarket) {
            const marketList = this.Markets.value as MarketModel[];

            let message: PromotionMessageModel = {
                Id: 0, // Not used
                Name: "",
                PromotionId: 0, // Not used
                TempId: 0, // Not used
                BusinessIdentity: marketList[0].BusinessIdentity,
                StartDate: this.promoStartDate, // PromoStartDate
                EndDate: this.promoEndDate, // PromoEndDate
                Markets: marketList,
            };

            let locationListRequest: MarketLocationBalanceListRequestModel = {
                PromotionMessage: message,
                TargetMessageTempId: message.TempId,
                MarketOrdinal: marketOrdinal,
            };

            let promise: Promise<number[]>;
            promise = (
                this.balanceCalcService as PromotionMessageService
            ).calculateMarketLocationBalanceList(locationListRequest);

            promise.then((locationIdList) => {
                this.ipsModal
                    .displayTemplateScrollable(
                        SearchModalComponent,
                        {
                            resolve: {
                                search: "listbylocation",
                                listOfLocationIds: locationIdList.map(Number),
                            },
                        },
                        {
                            windowClass: "no-list-group-item-interaction",
                        }
                    )
                    .then(
                        () => {},
                        (rejectReason) => {}
                    );
            });
        } else {
            // Below is for Audience scenario
            let markets = this.getMarketSaveModel();
            let locationListRequest: AudienceBalanceListRequestModel = {
                Markets: markets,
                MarketOrdinal: marketOrdinal,
            };

            (this.balanceCalcService as MarketGroupService)
                .calculateAudienceLocationBalanceList(locationListRequest)
                .then((locationIdList) => {
                    this.ipsModal
                        .displayTemplateScrollable(
                            SearchModalComponent,
                            {
                                resolve: {
                                    search: "listbylocation",
                                    listOfLocationIds:
                                        locationIdList.map(Number),
                                },
                            },
                            {
                                windowClass: "no-list-group-item-interaction",
                            }
                        )
                        .then(
                            () => {},
                            (rejectReason) => {}
                        );
                });
        }
    }

    private getMarketSaveModel(): MarketSaveModel[] {
        let markets = [] as MarketSaveModel[];
        this.Markets.value.forEach((m: MarketModel, index) => {
            let market: MarketSaveModel = {
                Id: m.Id,
                TargetMarketId: m.TargetMarketId,
                TargetMarketType: m.TargetMarketType,
                TargetAttributeValue: m.TargetAttributeValue,
                Ordinal: index + 1,
                OperationType: m.OperationType,
                BusinessIdentity: m.BusinessIdentity,
            };
            markets.push(market);
        });
        return markets;
    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        return msgs;
    }

    public locationHandlerChange(
        market: FormControl,
        newOperation: MarketOperator
    ): void {
        if (newOperation) {
            market.patchValue({
                OperationType: newOperation,
            });
            // For some reason the patchValue call is not enough to clear pristine on parent formgroup.
            market.markAsDirty();

            //recalculate audience location balance
            this.calculateAudienceLocationBalance();
        }
    }

    public calculateAudienceLocationBalance() {
        if (this.isMsgMarket) {
            this.balanceChanged.emit(0);
        } else {
            let markets = {
                Markets: this.getMarketSaveModel(),
                MarketOrdinal: null,
            };

            // Call server to get new totals
            if (this.hasFinalCountObservers()) {
                // Get balance calc and location id list
                (this.balanceCalcService as MarketGroupService)
                    .calculateLocationBalanceAndList(markets)
                    .then((response: MarketsAndBalanceListModel) => {
                        this.marketFinalCountChanged.emit(response.LocationIds);
                        let marketDictionary = [];
                        this.marketService.updateMarkets(
                            this.Markets,
                            response.Markets,
                            marketDictionary
                        );

                        this.balanceChanged.emit(
                            response.Markets[response.Markets.length - 1]
                                .LocationBalance
                        );
                    });
            } else {
                // Just do balance calc, we don't need location id list.
                (this.balanceCalcService as MarketGroupService)
                    .calculateAudienceLocationBalance(markets.Markets)
                    .then((response: MarketModel[]) => {
                        let marketDictionary = [];
                        this.marketService.updateMarkets(
                            this.Markets,
                            response,
                            marketDictionary
                        );

                        this.balanceChanged.emit(
                            response[response.length - 1].LocationBalance
                        );
                    });
            }
        }
        this.marketChanged.emit(this.parent);
    }

    public marketChangedEventHandler() {
        this.calculateAudienceLocationBalance();

        this.setCurrentAudiences();
    }

    public moveMarket(shift, currentIndex) {
        const markets = this.Markets as FormArray;

        if (markets.length > 1) {
            let newIndex: number = currentIndex + shift;
            if (newIndex >= 0 && newIndex < markets.length) {
                markets.at(newIndex).value.Ordinal = currentIndex;
                markets.at(currentIndex).value.Ordinal = newIndex;

                if (newIndex === -1) {
                    newIndex = markets.length - 1;
                } else if (newIndex === markets.length) {
                    newIndex = 0;
                }

                const currentGroup = markets.at(currentIndex);
                markets.removeAt(currentIndex);
                markets.insert(newIndex, currentGroup);

                //Reset all id back to zero so save will work
                let ordinal = 1;
                markets.controls.forEach((mrkt) => {
                    (mrkt as FormGroup).controls["Id"].setValue(0);
                    (mrkt as FormGroup).controls["Ordinal"].setValue(ordinal++);
                });

                this.calculateAudienceLocationBalance();
            }
        }
    }

    public marketDeletedEventHandler(marketIndex: number) {
        this.removeMarket(marketIndex);

        //recalculate audience location balance
        if (this.Markets.length > 0) {
            this.calculateAudienceLocationBalance();
        } else {
            this.balanceChanged.emit(0);
        }

        this.setCurrentAudiences();
    }
    public addNewMarkets(newMarkets: any) {
        let toUpdate = true;
        this.AddWithExistingMarket(newMarkets, toUpdate);
        this.setCurrentAudiences();
        this.calculateAudienceLocationBalance();
    }

    public removeMarkets(markets: any) {
        for (let i = 0; i < markets.length; i++) {
            let indexToRemove = markets[i].Index - i;
            this.marketDeletedEventHandler(indexToRemove);
            this.setCurrentAudiences();
        }
    }

    public setCurrentAudiences() {
        //notify markets changes
        //MarketModel has DisplayInfo contents LocationGroupId
        //This is needed for linked feature search modal to check feature availability
        let models = this.Markets.value as MarketModel[];
        models.forEach((model: MarketEditUIModel) => {
            if (model.TargetMarketType === "LocationSubGroup") {
                model.DisplayInfo = {
                    LocationGroupId: model.LocationGroupId,
                    LocationGroupName: model.LocationGroupName,
                    LocationSubGroupName: model.LocationSubGroupName,
                    LocationSubGroupId: model.LocationSubGroupId,
                    OptionType: model.OptionType,
                } as MarketDisplayInfoLocationSubGroup;
            }
        });

        this.featuresInAudience = models.concat(this.marketsToExclude);
        this.dataService.updateStorage(models, "FeatureInAudience");
    }

    private addMarketObservable(marketCtrl: AbstractControl) {
        let marketSource = this.marketService.createFilteredMarketSource(
            marketCtrl,
            this.fixtureGroupsInQuestion,
            this.featuresInQuestion,
            this.featuresInAudience,
            this.fixturesInQuestion
        );
        this.marketSources.push(marketSource);
    }

    get Markets(): FormArray {
        // return this.parent.controls.marketGroup.get("markets") as FormArray;
        return this.parent.get("Markets") as FormArray;
    }
}
