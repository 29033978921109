<div>
    <div>
        <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
    </div>
    <div class="ips-modal-header ips-modal-dark-header">
        <h4 class="modal-title m-0">{{modalTitle | translate}}</h4>
    </div>
    <div class="px-3 py-3">
        <div class="pb-3 pl-3" translate>PROMPT_DELETE_LOCGROUP_BODY_HEADER</div>
        <div *ngFor="let survey of surveysInUse" class="pl-4">
            <div><span class="font-weight-bold">{{'SURVEY' | translate}}: </span>{{survey.Name}}</div>
            <div><span class="font-weight-bold">{{'SECTION' | translate}}: </span>{{survey.Questions[0].SectionName}}</div>
            <div><span class="font-weight-bold">{{'QUESTION' | translate}}: </span>{{survey.Questions[0].QuestionName}}</div>
            <div *ngIf="survey.QuestionCount === 1" translate>PROMPT_DELETE_LOCGROUP_SURVEY_ONEQUESTION</div>
            <br />
        </div>
        <div class="text-center" translate>PROMPT_DELETE_LOCGROUP_BODY_FOOTER</div>
    </div>
    <div class="ips-modal-footer">
        <button id="leftButton" class="btn btn-default" (click)="agree()" translate>PROMPT_DELETE_OK</button>
        <button id="rightButton" class="btn btn-default" (click)="disagree()" translate>PROMPT_DELETE_CANCEL</button>
    </div>
</div>
