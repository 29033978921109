import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-store-profile-holder-version",
  templateUrl: "./store-profile-holder-version.component.html",
  styleUrls: ["./store-profile-holder-version.component.scss"]
})
export class StoreProfileHolderVersionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
