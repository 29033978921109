import { Component, OnInit } from "@angular/core";
import { ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { SpaceService } from "../../service/space.service";
import { LocationModel } from "../../model/LocationModel";
import { ListSearchComponent, ResultDetailComponent, TranslatedTexts } from "imagine-ui-ng-list-search";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString, StringBuilder } from "typescript-string-operations";


@Component({
  selector: "app-space-search",
  templateUrl: "./space-search.component.html",
  styleUrls: ["./space-search.component.scss"]
})
export class SpaceSearchComponent implements OnInit {
    public space: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<{}>;
    public createNewTooltip: string;
    public textMaxLength = "MAX_LENGTH_ERROR";
    public searchLabel: string;
    public searchLabelPlural: string;
    public translated = false;

    constructor(private spaceService: SpaceService, private listSearchHelper: ListSearchHelper, private translateService: TranslateService) {
        this.space = this.listSearchHelper.getListSearchHelper(spaceService);
        this.busy = this.space.busy;

        spaceService.head().then((data) => {
            this.space.TotalRecords = data;
        });
    }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
        this.getListData("");
    }
    private translateText() {
        this.translateService.get(["SPACE", "SPACES", "CREATE_NEW_SPACE", "MAX_LENGTH_ERROR"]).subscribe((res: [string]) => {
            this.createNewTooltip = res["CREATE_NEW_SPACE"];
            this.textMaxLength = res["MAX_LENGTH_ERROR"];
            this.searchLabel = res["SPACE"];
            this.searchLabelPlural = res["SPACES"];
            this.translated = true;
        });
    }
    public getListData(search: string) {
        if (this.busy) {
            return;
        }

        let searchInfo: SearchInfo = {
            searchText: search === undefined ? "" : search
        };
        this.promise = this.space.searchHelper(searchInfo);
    }
    public validationMaxLength(value: number) {
        return IpsString.Format(this.textMaxLength, value);
    }
}
