import { Component, OnInit } from "@angular/core";
import { SearchInfo, SearchResultModel, ListSearchHelper } from "imagine-ui-ng-core";
import { ListSearchComponent, ResultDetailComponent, TranslatedTexts } from "imagine-ui-ng-list-search";
import { TranslateService } from "@ngx-translate/core";
import { Transition } from "@uirouter/core";

import { FixtureGroupService, FixtureGroupModel, FixtureByFixtureGroupService } from "../../index";


@Component({
    selector: "app-fixture-group-view",
    templateUrl: "./fixture-group-view.component.html",
    styleUrls: ["./fixture-group-view.component.scss"]
})
export class FixtureGroupViewComponent implements OnInit {
    private fixtureGroup: FixtureGroupModel;
    private dataService: FixtureGroupService;
    private fixtureDataService: FixtureByFixtureGroupService;
    private fixtureSearchByFixtureGroup: ListSearchHelper;
    private searchLabel: string;
    private searchLabelPlural: string;
    public translated = false;

    public busy: boolean;
    public myFixtureGroup: FixtureGroupModel;
    public qrFixture: ListSearchHelper;
    public qrFixtureSearch: string;
    public loaded: boolean;
    public promise: Promise<void>;
    public qrFixturePromise: Promise<{}>;

    constructor(fixtureGroupService: FixtureGroupService, fixtureByFixtureGroupService: FixtureByFixtureGroupService, listSearchHelper: ListSearchHelper, transition: Transition, private translateService: TranslateService) {
        this.dataService = fixtureGroupService;
        this.fixtureDataService = fixtureByFixtureGroupService;
        this.fixtureSearchByFixtureGroup = listSearchHelper.getListSearchHelper(this.fixtureDataService);

        let paramId = transition.params().id;
        // If we got an ID to load, load it.
        if ( paramId > 0) {
            //Initial call to populate screen on load
            this.getFixtureGroup(paramId);
        }

        this.busy = false;
        this.myFixtureGroup = new FixtureGroupModel();
        this.myFixtureGroup.Id = paramId;
        this.myFixtureGroup.Holders = [];
        this.qrFixture = this.fixtureSearchByFixtureGroup;
        this.qrFixtureSearch = "";
        this.loaded = false;
    }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

    }
    private translateText() {
        this.translateService.get(["FIXTURE", "FIXTURES"]).subscribe((res: [string]) => {
            this.searchLabel = res["FIXTURE"];
            this.searchLabelPlural = res["FIXTURES"];
            this.translated = true;
        });
    }

    private getFixtureGroup(id: string) {
        this.loaded = false;

        this.promise = this.dataService.get<FixtureGroupModel>(id).then((response) => {
            Object.assign(this.myFixtureGroup, response);
            // Populate quick reference by FixtureGroup
            this.getFixtureList("", Number(id));
            this.fixtureDataService.headByFixtureGroup(id).then((data) => {
                this.fixtureSearchByFixtureGroup.TotalRecords = data;
            });
            this.loaded = true;
        });
    }

    public getFixtureList(search: string, id: number) {
        if (!search) {
            search = "";
        }

        let searchInfo: SearchInfo = { searchText: search, id: id };
        this.qrFixturePromise = this.fixtureSearchByFixtureGroup.searchHelper(searchInfo);

        //Add label for search grid
        this.qrFixturePromise.then(() => {
            this.qrFixture.resultList.forEach(function (item: any) {
                item.Label = item.Model ? item.Name + " - " + item.Model : item.Name;
            });
        });
    }
}
