
<div class="col-md-12">
    <div class="row">
        <section class="col-12 col-md-6 title">
            <h3>
                <span translate>VIEW_ELEMENT</span>
                <a class="ml-2" uiSref="main.storeProfileElement.edit" [uiParams]="{id:myElement.Id}" ipsPermission="main.storeProfileElement.edit" ngbTooltip="{{'EDIT' | translate}} {{myElement.Name}}" placement="bottom"><span class="fa fa fa-pencil editIcon"></span></a>
            </h3>
        </section>
    </div>
    <div class="row" [hidden]="!loaded" [cgBusy]="promise" cgBusyConfig="{ message: '{{'ACCESSING_ELEMENT_INFO' | translate}}' } }">
        <div class="viewProfileInfo col-12 col-lg-6">
            <div class="card long-text-wrap">
                <div class="card-header view-info-header">{{ myElement.Name }}</div>
                <div class="card-body">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <div class="view-info-label" translate>Obsolete</div>
                            <p class="ml-4 mb-2 view-info-format-imgn">{{myElement.IsObsolete ? "Yes" : "No"}}</p>
                        </li>
                        <li class="list-group-item">
                            <div class="view-info-label">{{ (hasElementDetails() && myElement.ElementDetails.length > 1 ? 'SIZES' : 'SIZE') | translate }}</div>
                            <p *ngFor="let detail of myElement.ElementDetails" class=" ml-4 mb-2 view-info-format-imgn">{{detail.Width | number}} x {{detail.Height | number}}</p>
                        </li>
                        <li class="list-group-item">
                            <div class="view-info-label" translate>NOTES</div>
                            <p class="ml-4 mb-2 view-info-format-imgn">{{ myElement.Notes }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>

    <ips-error-handler label="{{'ERROR_ACCESSING_ELEMENT_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
