<h3 class="title ml-3" translate>ANALYTICS</h3>

<div *ngIf="!screenReady" class="ips-flex-loading-box mb-4" >
    <div class="content">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
        <span class="d-block">Accessing Analytics Info.</span>
    </div>
</div>

<div *ngIf="screenReady" class="mb-4">
    <tabset>
        <tab heading="Campaign Up and Down">
            <div class="row mt-2 p-4">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-12 pr-0">
                            <div class="pr-0">
                                <span><i class="fa fa-info-circle fa-lg ips-ls-info-icon text-info"></i> <b>INFORMATION:</b> {{welcomeMessage}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-4">
                <div class="col-12">
                    <div class="report-header p-4">
                        <div class="row">
                            <div class="col-12 col-md-4 mb-1 d-md-flex">
                                <button type="button" class="btn btn-default form-control long-text-wrap"  (click)="changeLocation()" >{{selectedLocation.Label}}</button>
                                <div class="d-flex align-items-start mt-2 pl-2">
                                    <div class="d-none d-md-block tooltip-container" ngbTooltip="{{'TOOLTIP_ANALYTICS_LOCATION_INFO' | translate}}" placement="bottom-right">
                                        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                                    </div>
                                </div>  
                            </div>
                            <div class="col-12 col-md-5 mb-1 d-md-flex">
                                <form name="dateForm" novalidate #dateForm="ngForm" role="form">
                                    <app-ips-date-range  [startDate]="fromDateModel" [endDate]="toDateModel" [daySpan]="30"  [showLabel]="false"  (rangeChanged)="changeDateRange($event)"></app-ips-date-range>
                                </form>
                                <div class="d-flex align-items-start mt-2 pl-2">
                                    <div class="d-none d-md-block tooltip-container" ngbTooltip="{{'TOOLTIP_ANALYTICS_DATE_RANGE_INFO' | translate}}"  placement="bottom-right">
                                        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="d-flex align-items-start mt-2 pl-2">
                                     <h6 class="mt-1 pl-2 d-flex align-items-center ips-gray-color link bottom-left" (click)="download()">
                                        <span class="font-weight-lighter">Download Report</span>
                                        <a [href]="" class="clicker input-x text-center " ngbTooltip="{{'TOOLTIP_ANALYTICS_DOWNLOAD' | translate}}" placement="bottom-right"><span class="fa fa-arrow-circle-down fa-lg ips-ls-info-icon ips-gray-color clicker pl-1 mr-1"></span></a>
                                    </h6>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 mb-2 align-self-md-center">
                                <div class="d-flex align-items-md-baseline justify-content-md-end">
                                    <div class="ml-3">
                                        <span class="font-weight-bold">{{ sortedPrintRecords.length  | number}}</span>
                                        <span class="ml-1 font-weight-lighter">{{ campaignUpDownAnalytics.ResultChunkAttributes.TotalRecords| i18nPlural: pluralMapping}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="loading" class="row">
                            <div class="col-12">
                                <div class="ips-flex-loading-box mb-4" >
                                    <div class="content">
                                        <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
                                        <span class="d-block" translate>Searching...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!loading" class="row">
                            <div class="col-12">
                                <div class="table-responsive-sm">
                                    <div *ngIf="sortedPrintRecords.length > 0">
                                        <h6 *ngIf="selectedLocation.Id == 0 && sortedPrintRecords.length < 20" class="mt-0 font-weight-lighter text-muted"><b class="text-danger font-weight-bold">*NOTICE:</b> {{ withDateRangeMessage }}</h6>
                                        <h6 *ngIf="selectedLocation.Id == 0 && sortedPrintRecords.length >= 20 && campaignUpDownAnalytics.TotalRecords > 20" class="mt-0 font-weight-lighter text-muted"><b class="text-danger font-weight-bold">*NOTICE:</b> {{ withDateRangeTwentyMessage }}</h6>
                                        <h6 *ngIf="selectedLocation.Id > 0" class="mt-0 font-weight-lighter text-muted"><b class="text-danger font-weight-bold">*NOTICE:</b> {{ withLocationAndDateMessage }}</h6>
                                    </div>
                                    <table class="table  border d-lg-table  d-print-table table-responsive-sm">
                                        <thead class="bg-ips-gray-white">
                                            <tr>
                                                <th class="sorted" (click)="printSortHelper.setSort('LocationName')">Location Name <span class="fa fa-fw d-print-none" [class]="printSortHelper.getSortClass('LocationName', true)"></span></th>
                                                <th class="sorted" (click)="printSortHelper.setSort('City')">City <span class="fa fa-fw d-print-none" [class]="printSortHelper.getSortClass('City', true)"></span></th>
                                                <th class="sorted" (click)="printSortHelper.setSort('State')">State <span class="fa fa-fw d-print-none" [class]="printSortHelper.getSortClass('State', true)"></span></th>
                                                <th class="sorted" (click)="printSortHelper.setSort('DownloadCount')">Download Count <span class="fa fa-fw d-print-none" [class]="printSortHelper.getSortClass('DownloadCount', true)"></span></th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody class="clicker">
                                            <tr *ngFor="let item of sortedPrintRecords">
                                                <td>{{ item.LocationIdentifier}} - {{ item.LocationName }}</td>
                                                <td>{{ item.City }}</td>
                                                <td>{{ item.State }}</td>
                                                <td>{{ item.DownloadCount}}</td>
                                                <td (click)="viewUserModal(item)">
                                                    <span class="link"><span class="fa fa-eye"></span> Details</span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="!sortedPrintRecords || sortedPrintRecords.length < 1">
                                                <td colspan="2">
                                                    <span class="fa fa-info-circle"></span> There are no results found!
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tab>
        <tab heading="Other Report"></tab>
    </tabset>
</div>
