var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService } from "imagine-ui-ng-core";
import { PaymentMethodSettingsViewModel, AdminPaymentMethodSettingsModel, ShopperSettingsViewModel, SubGroupSettingViewModel, UserPaymentMethodSettingsModel } from "../model/PaymentMethodSettingModels";
import { LocationGroupService } from "../../imagine-ui-ng-store-profile/index";
import uniq from "lodash-es/uniq";
var PaymentSettingsService = /** @class */ (function (_super) {
    __extends(PaymentSettingsService, _super);
    function PaymentSettingsService(http, activeProfileService, helperFunctionsService, locationGroupService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.helperFunctionsService = helperFunctionsService;
        _this.locationGroupService = locationGroupService;
        _this.init("PaymentMethodSettings", "Shop", "v1");
        return _this;
    }
    PaymentSettingsService.prototype.checkLocationSubGroupsAreInUse = function (locationSubGroupIds) {
        var route = this.urlApiBasePlusController + "LocationSubGroupsInUse";
        return this.postByRoute(route, locationSubGroupIds);
    };
    PaymentSettingsService.prototype.getAvailablePaymentMethods = function (locationIds) {
        var route = this.urlApiBasePlusController + "AvailablePaymentMethods?";
        locationIds.forEach(function (i) {
            route += "LocationId=" + i + "&";
        });
        return this.getByRoute(route.substr(0, route.length - 1));
    };
    PaymentSettingsService.prototype.loadData = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getList().then(function (response) {
                var convertedResponse = _this.convertToViewModel(response);
                if (convertedResponse.ShopperSettings.LocationGroupId) {
                    _this.locationGroupService.get(convertedResponse.ShopperSettings.LocationGroupId).then(function (locGroupResponse) {
                        convertedResponse.ShopperSettings.PendingLocations = locGroupResponse.PendingAssignmentCount;
                        resolve(convertedResponse);
                    });
                }
                else {
                    resolve(convertedResponse);
                }
            });
        });
        return returnPromise;
    };
    PaymentSettingsService.prototype.saveData = function (model) {
        var convertedModel = this.convertToSaveModel(model);
        return this.put(convertedModel);
    };
    PaymentSettingsService.prototype.convertToViewModel = function (model) {
        var _a;
        var returnModel = new PaymentMethodSettingsViewModel();
        returnModel.AdminSettings = new AdminPaymentMethodSettingsModel();
        returnModel.AdminSettings.CreditCardEnabled = model.AdminSettings.CreditCardEnabled;
        returnModel.AdminSettings.InvoiceEnabled = model.AdminSettings.InvoiceEnabled;
        returnModel.ShopperSettings = new ShopperSettingsViewModel();
        returnModel.ShopperSettings.LocationGroupName = !model.CreditCardSettings.LocationGroupName ? model.InvoiceSettings.LocationGroupName : model.CreditCardSettings.LocationGroupName;
        returnModel.ShopperSettings.LocationGroupId = !model.CreditCardSettings.LocationGroupId ? model.InvoiceSettings.LocationGroupId : model.CreditCardSettings.LocationGroupId;
        returnModel.ShopperSettings.PendingLocations = !model.CreditCardSettings.PendingLocations ? model.InvoiceSettings.PendingLocations : model.CreditCardSettings.PendingLocations;
        returnModel.ShopperSettings.AllLocations = !!(model.CreditCardSettings.AllLocations || model.InvoiceSettings.AllLocations);
        returnModel.ShopperSettings.CreditCardEnabled = model.CreditCardSettings.AllLocations;
        returnModel.ShopperSettings.InvoiceEnabled = model.InvoiceSettings.AllLocations;
        returnModel.ShopperSettings.LocationGroupSettings = [];
        // get all subgroups
        var allSubgroups = uniq((_a = model.CreditCardSettings.LocationSubGroups).concat.apply(_a, model.InvoiceSettings.LocationSubGroups));
        var ccSubGroupNames = model.CreditCardSettings.LocationSubGroupNames || [];
        var invoiceSubGroupNames = model.InvoiceSettings.LocationSubGroupNames || [];
        var allSubgroupNames = uniq(ccSubGroupNames.concat.apply(ccSubGroupNames, invoiceSubGroupNames));
        var groupSettings = [];
        allSubgroups.forEach(function (subgroupId) {
            var currentSubGroup = allSubgroupNames.find(function (item) { return item.Key === subgroupId; });
            var currentSubGroupName = currentSubGroup === undefined ? "" : currentSubGroup.Value;
            var group = new SubGroupSettingViewModel();
            group.SubGroupId = subgroupId;
            group.SubGroupName = currentSubGroupName;
            group.CreditCardEnabled = false;
            group.InvoiceEnabled = false;
            group.CreditCardEnabled = (model.CreditCardSettings.LocationSubGroups.indexOf(group.SubGroupId) >= 0);
            group.InvoiceEnabled = (model.InvoiceSettings.LocationSubGroups.indexOf(group.SubGroupId) >= 0);
            groupSettings.push(group);
        });
        returnModel.ShopperSettings.LocationGroupSettings = groupSettings;
        return returnModel;
    };
    PaymentSettingsService.prototype.convertToSaveModel = function (model) {
        var saveModel = {
            AdminSettings: model.AdminSettings,
            CreditCardSettings: null,
            InvoiceSettings: null
        };
        var newCreditSettings = new UserPaymentMethodSettingsModel();
        newCreditSettings.AllLocations = model.ShopperSettings.AllLocations && model.ShopperSettings.CreditCardEnabled;
        newCreditSettings.LocationGroupName = null; // populating this so TS doesn't complain
        newCreditSettings.LocationGroupId = null; // populating this so TS doesn't complain
        newCreditSettings.LocationSubGroupNames = null; // populating this so TS doesn't complain
        newCreditSettings.LocationSubGroups = [];
        var newInvoiceSettings = new UserPaymentMethodSettingsModel();
        newInvoiceSettings.AllLocations = model.ShopperSettings.AllLocations && model.ShopperSettings.InvoiceEnabled;
        newInvoiceSettings.LocationGroupName = null; // populating this so TS doesn't complain
        newInvoiceSettings.LocationGroupId = null; // populating this so TS doesn't complain
        newInvoiceSettings.LocationSubGroupNames = null; // populating this so TS doesn't complain
        newInvoiceSettings.LocationSubGroups = [];
        var ccSubGroups = model.ShopperSettings.LocationGroupSettings.filter(function (setting) { return setting.CreditCardEnabled; }).map(function (item) { return item.SubGroupId; });
        var invoiceSubGroups = model.ShopperSettings.LocationGroupSettings.filter(function (setting) { return setting.InvoiceEnabled; }).map(function (item) { return item.SubGroupId; });
        if (!model.ShopperSettings.AllLocations) {
            newCreditSettings.LocationSubGroups = ccSubGroups;
        }
        else {
            newCreditSettings.LocationSubGroups = [];
        }
        if (!model.ShopperSettings.AllLocations) {
            newInvoiceSettings.LocationSubGroups = invoiceSubGroups;
        }
        else {
            newInvoiceSettings.LocationSubGroups = [];
        }
        saveModel.CreditCardSettings = newCreditSettings;
        saveModel.InvoiceSettings = newInvoiceSettings;
        return saveModel;
    };
    return PaymentSettingsService;
}(IpsBaseWebService));
export { PaymentSettingsService };
