/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./store-profile-space.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@uirouter/angular/lib/directives/uiView.ngfactory";
import * as i3 from "@uirouter/angular/lib/directives/uiView";
import * as i4 from "@uirouter/core";
import * as i5 from "./store-profile-space.component";
var styles_StoreProfileSpaceComponent = [i0.styles];
var RenderType_StoreProfileSpaceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StoreProfileSpaceComponent, data: {} });
export { RenderType_StoreProfileSpaceComponent as RenderType_StoreProfileSpaceComponent };
export function View_StoreProfileSpaceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "ui-view", [], null, null, null, i2.View_UIView_0, i2.RenderType_UIView)), i1.ɵdid(1, 245760, null, 0, i3.UIView, [i4.UIRouter, "UIView.PARENT_INJECT", i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_StoreProfileSpaceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-store-profile-space", [], null, null, null, View_StoreProfileSpaceComponent_0, RenderType_StoreProfileSpaceComponent)), i1.ɵdid(1, 114688, null, 0, i5.StoreProfileSpaceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StoreProfileSpaceComponentNgFactory = i1.ɵccf("app-store-profile-space", i5.StoreProfileSpaceComponent, View_StoreProfileSpaceComponent_Host_0, {}, {}, []);
export { StoreProfileSpaceComponentNgFactory as StoreProfileSpaceComponentNgFactory };
