import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

import { ActiveProfileService } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { StateService } from "@uirouter/core";

import { GeneralSettingModel } from "../../../shared/model/GeneralSettingModel";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";
import { SpaceModel } from "../../model/SpaceModel";
import { SpaceService } from "../../service/space.service";

import { SortableComponent, SortableItem } from "ngx-bootstrap/sortable";

@Component({
    selector: "app-general-settings-edit",
    templateUrl: "./general-settings-edit.component.html",
    styleUrls: ["./general-settings-edit.component.scss"]
})
export class GeneralSettingsEditComponent implements OnInit {
    @ViewChild("settingsForm") settingsForm: NgForm;
    @ViewChild(SortableComponent) sortable: SortableComponent;

    public screenReady = false;
    private allSettings: GeneralSettingModel[];

    private allSpaces: SpaceModel[];
    private selectedSpaces: SpaceModel[] = [];
    private availableSpaces: SpaceModel[] = [];
    private currentSpace: string;
    private originalSpaceOrder: any;

    private settingTypes = [
        {
            id: 1,
            name: "SignPlan",
            label: "Sign Plan"
        },
        {
            id: 2,
            name: "PSI",
            label: "PSI Integration"
        },
        {
            id: 3,
            name: "Survey",
            label: "Survey"
        },
        {
            id: 4,
            name: "Report",
            label: "Report"
        }
    ];

    private orientationOptions = [
        {
            key: "Portrait",
            value: 1
        },
        {
            key: "Landscape",
            value: 2
        }
    ];

    private pageSizeOptions = [
        {
            key: "A3",
            value: "A3"
        }
    ];

    private enableSignPlanOptions = [
        {
            key: "Yes",
            value: true
        },
        {
            key: "No",
            value: false
        }
    ];

    private mapToCustomerItemCode = [
        {
            key: "Yes",
            value: true
        },
        {
            key: "No",
            value: false
        }
    ];

    private expandSurveyOptions = [
        {
            key: "Yes",
            value: true
        },
        {
            key: "No",
            value: false
        }
    ];

    private enableUpDownReportOptions = [
        {
            key: "Yes",
            value: true
        },
        {
            key: "No",
            value: false
        }
    ];

    constructor(private ipsMessage: IpsMessageService,
        private stateService: StateService,
        private settingsService: GeneralSettingsService,
        private spaceService: SpaceService) {
    }

    ngOnInit() {
        const promises: Promise<any>[] = [];
        promises.push(this.settingsService.getCurrentSettings().then((response) => {
            this.allSettings = response;

            this.allSettings.forEach((setting) => {
                this.setOptionValues(setting);
                if (setting.Name === "SpaceOrder") {
                    this.originalSpaceOrder = setting.Value;
                }
            });
        }));

        promises.push(this.getAllSpaces());

        Promise.all(promises).then(() => {

            let spaceSetting = this.allSettings.find(setting => setting.Name === "SpaceOrder");
            if (spaceSetting) {
                const spaceIds = <number[]>JSON.parse(spaceSetting.Value);
                this.selectedSpaces = spaceIds.map((id) => {
                    const returnSpace = this.allSpaces.find(space => space.Id === id);
                    if (returnSpace) {
                        return returnSpace;
                    }
                });
                this.refreshAvailableSpaces();
            }

            this.screenReady = true;
        });
    }

    private getAllSpaces(): Promise<any> {
        return this.spaceService.getList().then((response: SpaceModel[]) => {
            this.allSpaces = response;
        });
    }

    onSortableChange(spaces: SpaceModel[]) {
        if (this.settingsForm && this.settingsForm.form) {
            if (spaces) {
                const oldSpaceIds = spaces.map((space) => {
                    return space.Id;
                });
                const current = JSON.stringify(oldSpaceIds);
                if (current !== this.originalSpaceOrder) {
                    this.settingsForm.form.markAsDirty();
                }
            }

        }
    }

    selectSpace(event: any) {
        const space: SpaceModel = event.item;
        let sortableSpace: SortableItem = {
            id: this.selectedSpaces.length,
            initData: space,
            value: JSON.stringify(space)
        };



        this.selectedSpaces.push(space);
        this.sortable.items.push(sortableSpace);

        this.refreshAvailableSpaces();
        this.currentSpace = null;
        this.settingsForm.form.markAsDirty();
    }

    removeSpace(space: SpaceModel) {
        let index = this.selectedSpaces.findIndex(s => s.Id === space.Id);

        if (index >= 0) {
            this.selectedSpaces.splice(index, 1);
            this.sortable.items.splice(index, 1);

            this.refreshAvailableSpaces();
        }

        this.settingsForm.form.markAsDirty();
    }

    refreshAvailableSpaces() {
        this.availableSpaces = this.allSpaces.filter((space) => {
            let matchingSpace = this.selectedSpaces.find(selectedSpace => selectedSpace.Id === space.Id);
            return !matchingSpace;
        });
    }

    setOptionValues(setting: GeneralSettingModel) {
        switch (setting.Name) {
            case "PageSize":
                setting.Options = this.pageSizeOptions;
                break;
            case "PageOrientation":
                setting.Options = this.orientationOptions;
                break;
            case "EnableSignPlanLayout":
                setting.Options = this.enableSignPlanOptions;
                break;
            case "MapMaterialNumberToCustomerItemCode":
                setting.Options = this.mapToCustomerItemCode;
                break;
            case "ExpandSurveyOptions":
                setting.Options = this.expandSurveyOptions;
                break;
            case "EnableUpDownReport":
                setting.Options = this.enableUpDownReportOptions;
                break;
        }
    }

    getSettingsByType(type: string): GeneralSettingModel[] {
        return this.allSettings.filter(setting => setting.Type === type);
    }

    private saveData(): Promise<any> {

        const spaceOrderSetting = this.allSettings.find(setting => setting.Name === "SpaceOrder");

        if (spaceOrderSetting) {
            const selectedSpaceIds = this.selectedSpaces.map((space) => {
                return space.Id;
            });
            spaceOrderSetting.Value = JSON.stringify(selectedSpaceIds);
        }
        let custno = this.allSettings.find(setting => setting.Name === "PSICustomerNumber").Value.slice(-6); // last six digits are valid.
        if (custno.length > 0) {
            this.allSettings.find(setting => setting.Name === "PSICustomerNumber").Value = custno.padStart(12, "0"); // pad front with zeros as psi does
        }

        return this.settingsService.save(this.allSettings);
    }

    public savePreferencesPrompt(redirect: boolean) {
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveData(), progressMessage: "SAVING" })
            .then((result) => {
                if (result) {
                    this.settingsForm.form.markAsPristine();

                    if (redirect) {
                        this.stateService.go("main.toolsControls");
                    } else {
                        this.stateService.go("main.generalSettingsEdit", {}, { reload: true });
                    }
                }
            });
    }
}
