import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { KeyValuePair } from "../../index";

@Component({
    selector: "app-ship-method-list-modal",
    templateUrl: "./ship-method-list-modal.component.html",
    styleUrls: ["./ship-method-list-modal.component.scss"]
})

export class ShipMethodListModalComponent {
    // ----- DATA -----
    public items: KeyValuePair<number, string>[];

    constructor(private modalInstance: NgbActiveModal) {}

    // ----- FUNCTIONS -----
    //
    public itemClicked(item: KeyValuePair<number, string>) {
        this.modalInstance.close(item);
    }

    //
    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }
}
