var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "imagine-ui-ng-core";
var CustomerRequestService = /** @class */ (function (_super) {
    __extends(CustomerRequestService, _super);
    function CustomerRequestService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("CustomerRequest", "Profile", "v1");
        return _this;
    }
    CustomerRequestService.prototype.submitRequest = function (request, file) {
        var route = this.urlApiBaseProductPlusVersion + "CustomerRequest";
        return this.post(request, file, route);
    };
    CustomerRequestService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerRequestService_Factory() { return new CustomerRequestService(i0.inject(i1.HttpClient), i0.inject(i2.ActiveProfileService)); }, token: CustomerRequestService, providedIn: "root" });
    return CustomerRequestService;
}(IpsBaseWebService));
export { CustomerRequestService };
