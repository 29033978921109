<div>
    <h2 class="d-inline-block" translate>SEARCH_PRODUCTS</h2>
    <!--<a class="text-info text-underline-hover d-inline-block ml-2 pl-2 border-left" uiSref="main.productadminedit" [uiParams]="{ id: 0 }" translate>
        <span class="fa fa-plus"></span> ADD_NEW_PRODUCT
    </a>
    <a class="text-info text-underline-hover d-inline-block ml-2 pl-2 border-left" uiSref="main.productadminedit" [uiParams]="{ id: 0 }" translate>
        <span class="fa fa-upload"></span> BULK_IMPORT
    </a>-->
</div>
<div>
    <form (ngSubmit)="addSearchTerm()">
        <div class="input-group mb-3">
            <input type="text" class="form-control form-control-lg" [(ngModel)]="currentSearchText" [ngModelOptions]="{standalone: true}" placeholder="{{ 'PRODUCT_ADMIN_SEARCH_PLACEHOLDER' | translate }}" />
            <div class="input-group-append">
                <button class="btn btn-default" type="button" [ngClass]="{'active': !searchProblems}" (click)="searchType(false)" translate>ALL</button>
                <button class="btn btn-default" type="button" [ngClass]="{'active': searchProblems}" (click)="searchType(true)" translate>PROBLEMS</button>
            </div>
        </div>
    </form>
</div>

<div class="row">
    <div class="col-12 d-flex align-items-center justify-content-between">
        <div class="d-inline px-3 py-3" *ngIf="searchResults">
            <span *ngFor="let term of searchTerms" class="px-3 d-inline-flex align-items-center align-content-center badge badge-pill badge-info mr-2 font-size-base font-weight-normal">
                {{ term }}<button class="btn btn-sm btn-link p-0 m-0 ml-2" (click)="removeSearchTerm(term)"><span class="fa fa-times-circle text-white"></span></button>
            </span>
        </div>
        <div *ngIf="searchResults" class="px-3">
            <span class=""> {{ searchResults.length }} {{ 'RECORDS_FOUND' | translate }}</span><a href="#" class="d-inline-block ml-2 pl-2 border-left text-info text-underline-hover" click="print()"><span class="fa fa-print"></span> Print</a>
        </div>
    </div>
</div>
<div *ngIf="processing" class="border bg-light">
    <p class="text-center p-5 m-3">
        <span class="fa fa-spin fa-spinner fa-2x d-block mb-3"></span> {{ 'LOADING_DATA' | translate }}...
    </p>
</div>
<div *ngIf="!processing">
    <div *ngIf="!searchResults" class="border bg-light mt-4">
        <p class="text-center p-5 m-3">
            <span class="fa fa-fw fa-info-circle"></span> <span [innerHtml]="'PRODUCT_ADMIN_SEARCH_PROMPT' | translate"></span>
        </p>
    </div>
    <div *ngIf="searchResults">
        <table class="table table-sm table-bordered table-hover">
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Available Quantity</th>
                    <th>Start Date</th>
                    <th>Special Attributes</th>
                    <th>Unit Price</th>
                    <th>Dimensions <small>(W x H)</small></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let product of searchResults">
                    <td>
                        <div>{{ product.Title }}</div>
                        <small class="text-black-50">{{ product.CustomerItemCode }}</small>
                        <p class="mb-0 long-text-wrap">{{ product.Description }}</p>
                        <p *ngIf="product.Problem" class="mb-0 text-danger font-italic">Problem: {{ product.Problem | translate }}</p>
                    </td>
                    <td class="text-right">{{ product.AvailableQty }}</td>
                    <td>{{ product.StartDate | date }}</td>
                    <td>
                        <div *ngIf="product.IsFavorite">
                            <span class="badge badge-pill badge-info font-weight-normal">
                                Favorite
                            </span>
                        </div>
                        <div *ngIf="product.IsFeatured">
                            <span class="badge badge-pill badge-info font-weight-normal">
                                Featured
                            </span>
                        </div>
                        <div *ngIf="product.DigitalDownload">
                            <span class="badge badge-pill badge-info font-weight-normal">
                                Digital Download
                            </span>
                        </div>
                    </td>
                    <td class="text-right">{{ product.UnitPrice | currency }}</td>
                    <td>
                        <small class="d-block text-black-50">Item Dimensions:</small>
                        <div>
                            {{ product.Product_Width || product.Width || '0' }} x {{ product.Product_Height || product.Height || '0' }}
                        </div>
                        <small class="d-block text-black-50">Campaign Dimensions:</small>
                        <div *ngIf="product.Campaign_Width || product.Campaign_Height">
                            {{ product.Campaign_Width || '0' }} x {{ product.Campaign_Height || '0' }}
                        </div>
                        <div *ngIf="!product.Campaign_Width && !product.Campaign_Height" class="font-italic" translate>
                            NO_FIXTURE
                        </div>
                    </td>
                </tr>
                <tr *ngIf="searchResults.length === 0">
                    <td colspan="6">No records found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
