<div class="col" *ngIf="translated" ipsFocus="searchInput">
    <ips-list-search [searchLabel]="searchLabel"
                     [searchLabelPlural]="searchLabelPlural"
                     labelLoading="{{'ACCESSING_CUSTOM_DATA_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_CUSTOM_DATA_INFO' | translate }}"
                     [listData]="customData"
                     [busy]="busy"
                     (onListDataChange)="getListData($event.search)"
                     [section]="'main.customdata'"
                     [scroller]=true
                     [quickRef]=false
                     [itemClickPermission]="'main.customdata.search'"
                     [promise]="promise"
                     [createNewTooltip]="createNewTooltip"
                     [autoFocus]=true
                     [query]="query">
        <ng-template #listSearchItemTemplate let-result="result">
            <div class="row ips-search-result-big">
                <div class="col">
                    <a uiSref="main.customdata.edit" [uiParams]="{id:result.Id}" class="no-underline">
                        <div class="p-3">
                            {{result.AreaName}} - <span class="h2 mb-3" innerHtml="{{result.Name | highlight : query}}"></span> - {{'FIELD_ORDER' | translate}} {{result.Ordinal}}
                        </div>
                    </a>
                </div>
                <div class="col-2 col-md-1 mt-2" ipsPermission="main.customdata">
                    <a uiSref="main.customdata.edit" [uiParams]="{id:result.Id}" class="no-underline">
                        <div class="pt-3 pr-3 d-flex justify-content-end">
                            <span class="fa fa-pencil"></span>
                        </div>
                    </a>
                </div>
            </div>
        </ng-template>
    </ips-list-search>
</div>
