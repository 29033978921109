<div class="pb-0 pt-3 px-3">
    <div class="form-row flex-wrap">
        <!--Image must always be first-->
        <div *ngIf="FullImage" class="col-12 col-md-6 col-lg-3 mb-3">
            <ips-image-view [imgThumbUrl]="ThumbImage" [imgFullUrl]="FullImage"></ips-image-view>
        </div>
        <div class="col-12 col-md">
            <div class="form-row">
                <div *ngFor="let card of DisplayData" class="col-12 mb-3 d-flex align-content-stretch" [ngClass]="[FullImage ? 'col-lg-4' : 'col-lg-3']">
                    <div class="card ips-search-card w-100">
                        <a *ngIf="card.Type !== 'Kit'" uiSref="{{card.Type === 'Location' ? EditOrViewPermission : getCardSref(card.Type)}}" [uiParams]="card.Type != 'LocationGroup' ? {id:card.Id} : {locationId:LocationId, locationGroupId:card.Id}" class="h-100 no-underline">
                            <div class="card-body">
                                <span class="view-info-label mr-1 d-inline-block pb-1">{{card.Key | translate}}</span>
                                <div>
                                    <ng-container *ngIf="isArray(card.Value)">
                                        <ng-container *ngFor="let val of card.Value"><span innerHtml="{{val | highlight : card.CanHighlight ? SearchText : ''}}"></span><br /></ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!isArray(card.Value)">
                                        <span *ngIf="!card.isPendingAssignment; else pending_locGroup" [ngClass]="{'text-nowrap': card.isDate}" innerHtml="{{card.Value | highlight : card.CanHighlight ? SearchText : ''}}"></span>
                                        <ng-template #pending_locGroup><span class="location-group-pending-assignment-label" translate>PENDING_ASSIGNMENT</span></ng-template>
                                    </ng-container>
                                </div>
                            </div>
                        </a>
                        <div *ngIf="card.Type === 'Kit'" class="card-body">
                            <span class="view-info-label">{{card.Key | translate}}</span><br />
                            <span [ngClass]="{'text-nowrap': card.isDate}" innerHtml="{{card.Value | highlight : card.CanHighlight ? SearchText : ''}}"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="Notes" class="form-row">
        <div class="col-12 mb-3 d-flex align-content-stretch">
            <div class="card ips-search-card w-100">
                <a [uiSref]="EditOrViewPermission" [uiParams]="{id:Notes.Id}" class="h-100 no-underline">
                    <div class="card-body">
                        <span class="view-info-label">{{Notes.Key | translate}}</span>
                        <span>{{Notes.Value}}</span>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

