import { Component, OnInit, OnDestroy, HostListener, ViewEncapsulation, ViewChild, TemplateRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StateService, StateParams } from "@uirouter/core";

import { String as IpsString } from "typescript-string-operations";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

import { ListSearchHelper, SearchInfo, SearchResponse, QueryParamItem, ActiveProfileService, HelperFunctionsService, MediaReferenceModel } from "imagine-ui-ng-core";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { IpsModalService, IpsModalInstance } from "imagine-ui-ng-modal";
import { AuthService } from "imagine-ui-ng-security";

import { CampaignService, CampaignData } from "../../../imagine-ui-ng-campaign/index";
import { SpaceService, SpaceModel } from "../../../imagine-ui-ng-store-profile/index";

import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { ItemService, LocationService, LocationModel, LocationModelWithLabel, FixtureItemDataModel } from "../../index";

import { ShopSettingsService } from "../../service/shop-settings.service";
import { ShopSettingModel } from "../../model/ShopSettingModel";
import { ItemRelatedDataModel } from "../../model/ItemRelatedDataModel";
import { BrandBulletinService } from "../../../imagine-ui-ng-store-profile/service/brand-bulletin.service";
import { BrandBulletinModel } from "../../../imagine-ui-ng-store-profile/model/BrandBulletinModel";

interface ScrollInfo {
    search: string;
    locationId: number;
    positionY: number;
    chunkIndex: number;
}

type ProductSearchType = "Location" | "Fixture";

@Component({
    selector: "app-product-search",
    templateUrl: "./product-search.component.html",
    styleUrls: ["./product-search.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ProductSearchComponent implements OnInit, OnDestroy {
    @ViewChild("categoryTemplate") categoryTemplate: TemplateRef<any>;

    public itemHelper: ListSearchHelper;
    public spaceHelper: ListSearchHelper;
    public campaignHelper: ListSearchHelper;

    public busy: boolean;
    public promise: Promise<any>;

    public showOrderReason = true;
    public orderReasons: string[] = [];

    public showCategoryFilter = false;
    public categoriesAvailable = false;
    public spacesAvailable = false;
    public campaignsAvailable = false;
    public showAllLocationsFilter = false;
    public hideCampaignFilters = false;

    public selectedLocationId?: number;
    public selectedLocation: LocationModelWithLabel = null;

    public selectedSpaceId = 0;
    public selectedSpaceLabel: string = null;

    public selectedCampaignId = 0;
    public selectedCampaignLabel: string = null;

    public categoryModal: IpsModalInstance;
    public selectedCategory: any = null;
    public availableCategories: any[] = [];

    public allSpaces: any[] = [];
    public allCategories: any[] = [];
    public allCampaigns: any[] = [];

    public preFilteredSpaces: any[] = [];
    public preFilteredCategories: any[] = [];
    public preFilteredCampaigns: any[] = [];

    public enableSpace = true;
    public enableCampaign = true;
    public enableProductsByFixture = true;

    private searchType: ProductSearchType = "Location";
    private partialFixtureItemRecord: FixtureItemDataModel = null;

    private itemRelatedFilterData: ItemRelatedDataModel = null;

    public screenLoaded = false;
    public searchFilterDataLoaded = false;
    public locationsAvailable = false;

    public searchText = "";

    private scrollY: number;
    private searchScrollKey: string;

    private locale = "en-US";

    private pluralMapping: { [k: string]: string } = {};

    private querySubject: Subject<string> = new Subject<string>();

    public brandBulletin: BrandBulletinModel;

    constructor(
        private locationService: LocationService,
        private itemService: ItemService,
        listSearchHelper: ListSearchHelper,
        private activeProfileService: ActiveProfileService,
        private ipsModal: IpsModalService,
        private stateService: StateService,
        private helperService: HelperFunctionsService,
        private translateService: TranslateService,
        private campaignService: CampaignService,
        private spaceService: SpaceService,
        private authService: AuthService,
        private shopSettingsService: ShopSettingsService,
        private brandBulletinService: BrandBulletinService
    ) {
        this.itemHelper = listSearchHelper.getListSearchHelper(itemService);
        this.spaceHelper = listSearchHelper.getListSearchHelper(spaceService);
        this.campaignHelper = listSearchHelper.getListSearchHelper(campaignService);
        this.itemHelper.beforeResultsAdded = (helper, items) => this.mergeFixtureItems(helper, items);

        this.querySubject.pipe(
            debounceTime(350),
            distinctUntilChanged())
            .subscribe((model: string) => {
                this.getListData(model);
            });
    }

    ngOnInit() {
        this.locale = this.activeProfileService.profile.Locale || navigator.language;

        this.searchScrollKey = "products_search_scroll_" + this.activeProfileService.businessIdentity;
        let scrollInfo: ScrollInfo = JSON.parse(localStorage.getItem(this.searchScrollKey));

        if (scrollInfo) {
            this.searchText = scrollInfo.search;
            this.selectedLocationId = scrollInfo.locationId;
            localStorage.removeItem(this.searchScrollKey);
        }

        this.processQueryParams(this.stateService.params);

        const promises: Promise<any>[] = [];

        const filterPromises: Promise<any>[] = [];

        promises.push(this.shopSettingsService.getOrderReasons().then((response: string[]) => {
            this.orderReasons = response.sort((a, b) => a > b ? 1 : -1);
        }));

        promises.push(this.getSelectedLocation(this.selectedLocationId).then(() => {
            if (this.locationsAvailable) {

                this.getListData(this.searchText, false, true, scrollInfo);
            }
        }));

        promises.push(this.translateService.get(["PRODUCT", "PRODUCTS"]).toPromise().then((response) => {
            this.pluralMapping["=1"] = response["PRODUCT"];
            this.pluralMapping["other"] = response["PRODUCTS"];
        }));

        promises.push(this.shopSettingsService.getSettingByName("ShowOrderReason").then((response: ShopSettingModel) => {
            if (response) {
                this.showOrderReason = (response.Value === "item");
            }
        }));

        promises.push(this.shopSettingsService.getCategoryFilterSetting().then((response: boolean) => {
            if (response) {
                this.showCategoryFilter = response;
            }
        }));

        filterPromises.push(this.itemService.getAllCategories({ searchText: "", chunkIndex: 0, businessIdentity: this.activeProfileService.businessIdentity }, "Item/GetAllCategories").then((result) => {
            if (result) {
                this.allCategories = result.ResultList;
            }
        }).catch((err: any) => {
            this.categoriesAvailable = false;
        }));

        filterPromises.push(this.spaceHelper.searchHelper({ searchText: "" }).then((result) => {
            this.allSpaces = this.spaceHelper.resultList;
        }));

        //filterPromises.push(this.campaignHelper.searchHelper(<SearchInfo>{
        //    searchText: "", additionalQueryParams: [
        //        { param: "ShowActiveOnly", paramValue: "false" },
        //        { param: "ShowExportedOnly", paramValue: "false" },
        //        { param: "ShowCurrentFutureOnly", paramValue: "false" },
        //        { param: "RecordCount", paramValue: "20000" }
        //    ]
        //}, undefined, "Campaign").then((result) => {
        //    this.allCampaigns = this.campaignHelper.resultList;
        //}));


        filterPromises.push(this.campaignService.getList<any[]>().then((response) => {
                this.allCampaigns = response;
        }));


        //Only allow All Locations for Admin users
        if (this.isInRole(["EcommSiteAdmin", "ShoppingAdmin"])) {
            promises.push(this.shopSettingsService.getAllLocationsSetting().then((response: boolean) => {
                if (response) {
                    this.showAllLocationsFilter = response;
                }
            }));
        } else {
            this.showAllLocationsFilter = false;
        }


        if (this.selectedCampaignId && this.selectedCampaignId !== 0) {
            promises.push(this.getSelectedCampaignName(this.selectedCampaignId));
        }

        if (this.selectedSpaceId && this.selectedSpaceId !== 0) {
            promises.push(this.getSelectedSpaceName(this.selectedSpaceId));
        }


        promises.push(this.shopSettingsService.getHideCampaignSetting().then((response: boolean) => {
            if (response) {
                this.hideCampaignFilters = response;
            }
        }));


         promises.push(this.brandBulletinService.getList().then((response: BrandBulletinModel[]) => {

             if (response && response.length > 0 ) {
                let shopVal = response.find(q => q.Type === "Shop");
                if (shopVal && !this.isNullOrSpaces(shopVal.Value)) {
                    this.brandBulletin = shopVal;
                }

            }

         }));


        Promise.all(promises).then(() => {
            this.screenLoaded = true;
        });

        Promise.all(filterPromises).then(() => {
            this.getItemRelatedFilterData().then(() => {
                this.searchFilterDataLoaded = true;
            });
        });
    }

    private mergeFixtureItems(searchHelper: ListSearchHelper, items: FixtureItemDataModel[]): void {
        if (items && items.length) {
            if (this.searchingByFixture) {
                // if we have a partial record in memory, then
                // patch the items from it into the first FixtureItemDataModel
                // and clear out the partial record
                if (this.partialFixtureItemRecord) {
                    items[0].Items.unshift(...this.partialFixtureItemRecord.Items);
                    this.partialFixtureItemRecord = null;
                }

                // if the last item in the list has a partial item list, temporarily remove it and
                // store it in the partialFixtureItemRecord variable.  we'll patch its items in when
                // the next page is loaded
                const lastItemIsPartial = items[items.length - 1].HasPartialItemList;

                if (lastItemIsPartial) {
                    const removedItems = items.splice(items.length - 1, 1);
                    this.partialFixtureItemRecord = removedItems[0];
                }
            }
        }
    }

    public setSearchType(searchType: ProductSearchType): void {
        const previousSearchType = this.searchType;
        this.searchType = searchType;
        const searchTypeChanged = (previousSearchType !== searchType);
        if (searchTypeChanged) {
            this.partialFixtureItemRecord = null;
            this.setCategoriesAvailable();
            this.getListData(this.searchText, true, true);
        }
    }

    private setCategoriesAvailable(): void {
        this.searchFilterDataLoaded = false;
        if (this.searchType === "Fixture") {
            this.itemService.searchingByFixture = true;
        } else {
            this.itemService.searchingByFixture = false;
        }

        this.itemService.getAllCategories({ searchText: "", chunkIndex: 0, businessIdentity: this.activeProfileService.businessIdentity }, "Item/GetAllCategories").then((result) => {
            if (result) {
                this.allCategories = result.ResultList;
                this.setItemRelatedFilterLists();
                this.searchFilterDataLoaded = true;
            }
        }).catch((err: any) => {
            this.categoriesAvailable = false;
        });
    }

    public getItemRelatedFilterData(): Promise<any> {
        this.searchFilterDataLoaded = false;
        const searchInfo: SearchInfo = {
            searchText: "",
            chunkIndex: 0,
            businessIdentity: this.activeProfileService.businessIdentity
        };

        if (this.allCategories.length === 0) {
            this.setCategoriesAvailable();
        }

        if (this.selectedLocation && this.selectedLocation.Id > 0) {
          searchInfo.additionalQueryParams = [{ param: "LocationId", paramValue: this.selectedLocation.Id.toString() }];
        }

        const promise = new Promise((resolve, reject) => {
            this.itemService.getAllRelatedFilters(searchInfo).then((result: ItemRelatedDataModel) => {
                if (result) {
                    this.itemRelatedFilterData = result;
                    this.setItemRelatedFilterLists();
                    resolve();
                    this.searchFilterDataLoaded = true;

                }
            }).catch((err: any) => {
                //TODO
            });
        });

        return promise;
    }

    private setItemRelatedFilterLists(): void {

        this.preFilteredSpaces = this.allSpaces.filter(item => this.itemRelatedFilterData.RelatedSpaceIds.indexOf(item.Id) >= 0);
        this.preFilteredCategories = this.allCategories.filter(cat => this.itemRelatedFilterData.RelatedCategoryIds.indexOf(cat.ExternalId) >= 0);
        this.preFilteredCampaigns = this.allCampaigns.filter(item => this.itemRelatedFilterData.RelatedCampaignIds.indexOf(item.Id) >= 0);
        this.categoriesAvailable = this.preFilteredCategories.length > 0;
        this.spacesAvailable = this.preFilteredSpaces.length > 0;
        this.campaignsAvailable = this.preFilteredCampaigns.length > 0;
    }

    public get searchingByLocation(): boolean {
        return this.searchType === "Location";
    }

    public get searchingByFixture(): boolean {
        return this.searchType === "Fixture";
    }

    public get matchingRecordCount(): number {
        return this.itemHelper.ResultChunkAttributes.TotalRecords || 0;
    }

    public get showNoResults() {
        return this.selectedLocation &&
            this.itemHelper.chunkIndex > 0 &&
            this.itemHelper.resultList.length === 0;
    }

    private addLabelToLocationModel(model: LocationModel): LocationModelWithLabel {
        const returnObject = <LocationModelWithLabel>model;

        returnObject.Label = IpsString.Format("{0} - {1} - {2}, {3}",
            model.LocationIdentifier,
            model.Name,
            model.Addresses[0].City,
            model.Addresses[0].StateProvince);

        return <LocationModelWithLabel>returnObject;
    }

    public getSelectedLocation(locationId?: number): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            if (locationId && locationId !== -1) {
                this.locationService.get(locationId).then((locationResponse: LocationModel) => {
                    this.selectedLocation = this.addLabelToLocationModel(locationResponse);
                    this.locationsAvailable = true;
                    resolve();
                });

            } else {
                this.locationService.search(
                    <SearchInfo>{ searchText: "", chunkIndex: 0, recordCount: 1, businessIdentity: this.activeProfileService.businessIdentity },
                    "Location/Search"
                ).then((locationSearchResponse: SearchResponse<LocationModel>) => {
                    if (locationSearchResponse.ResultChunkAttributes.TotalRecords > 0) {
                        const firstLocation = locationSearchResponse.ResultList[0];
                        this.selectedLocation = this.addLabelToLocationModel(firstLocation);
                        this.locationsAvailable = true;
                    }

                    resolve();
                });
            }
        });
        return promise;
    }

    public getSelectedCampaignName(campaignId: number): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            this.campaignService.get(campaignId).then((response: CampaignData) => {
                this.selectedCampaignLabel = response.Name;
                resolve();
            });
        });

        return promise;
    }

    public getSelectedSpaceName(spaceId: number): Promise<any> {
        const promise = new Promise((resolve, reject) => {
            this.spaceService.get(spaceId).then((response: SpaceModel) => {
                this.selectedSpaceLabel = response.Name;
                resolve();
            });
        });

        return promise;
    }

    public chooseCategory() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "itemcategory", preFilteredList: this.preFilteredCategories } })
            .then((response: any) => {
                this.selectedCategory = response.item;
                this.partialFixtureItemRecord = null;
                this.getListData(this.searchText, true);
            }, () => { }); // Rejected;
    }

    public chooseLocation() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: this.showAllLocationsFilter, allLabel: "NO_LOCATION_SPECIFIED", search: "location" } })
            .then((response: any) => {
                this.selectedLocation = <LocationModelWithLabel>response.item;
                this.partialFixtureItemRecord = null;


                if (this.selectedLocation.Id === 0) {
                    this.selectedLocation.Id = -1;
                    this.enableSpace = false;
                    this.enableCampaign = false;
                    this.enableProductsByFixture = false;

                    let allLabel = this.translateService.instant("ALL_UPPERCASE");

                    this.selectedSpaceId = 0;
                    this.selectedSpaceLabel = allLabel;

                    this.selectedCampaignId = 0;
                    this.selectedCampaignLabel = allLabel;

                    this.searchType = "Location";


                } else {
                    this.enableSpace = true;
                    this.enableCampaign = true;
                    this.enableProductsByFixture = true;
                }

                if (this.selectedLocation.Id > 0) {
                    this.getItemRelatedFilterData();
                }
                this.getListData(this.searchText, true);

            }, () => { }); // Rejected;
    }

    public chooseCampaign() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, activeOnly: true, search: "campaign", preFilteredList: this.preFilteredCampaigns } })
            .then((response: any) => {
                this.selectedCampaignId = response.item.Id;
                this.selectedCampaignLabel = response.item.Label;
                this.partialFixtureItemRecord = null;
                this.getListData(this.searchText, true);
            }, () => { }); // Rejected;
    }

    public chooseSpace() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "space", preFilteredList: this.preFilteredSpaces } })
            .then((response: any) => {
                this.selectedSpaceId = response.item.Id;
                this.selectedSpaceLabel = response.item.Label;
                this.partialFixtureItemRecord = null;
                this.getListData(this.searchText, true);
            }, () => { }); // Rejected;
    }

    public getListData(search: string, forceReload = false, updateQueryString = true, scrollInfo?: ScrollInfo) {
        const searchInfo: SearchInfo = {
            searchText: search || ""
        };

        if (this.searchingByFixture) {
            this.itemService.searchingByFixture = true;
        } else {
            this.itemService.searchingByFixture = false;
        }

        //If it is the All case then send a -1 to the backend
        searchInfo.additionalQueryParams = [{ param: "LocationId", paramValue: this.selectedLocation.Id.toString() }];

        if (this.selectedSpaceId) {
            searchInfo.additionalQueryParams.push({ param: "SpaceId", paramValue: this.selectedSpaceId.toString() });
        }

        if (this.selectedCampaignId) {
            searchInfo.additionalQueryParams.push({ param: "CampaignId", paramValue: this.selectedCampaignId.toString() });
        }

        if (this.selectedCategory && this.selectedCategory.ExternalId) {
            searchInfo.additionalQueryParams.push({ param: "CategoryId", paramValue: this.selectedCategory.ExternalId.toString() });
        }

        this.promise = this.itemHelper.searchHelper(searchInfo, forceReload, undefined)
            .then((response) => {
                if (scrollInfo) {
                    scrollInfo.chunkIndex--;
                    if (scrollInfo.chunkIndex > 0) {
                        this.getListData(search, false, false, scrollInfo);
                    } else {
                        setTimeout(() => { window.scrollTo(0, scrollInfo.positionY); }, 500);
                    }
                }

                if (updateQueryString) {
                    this.updateQueryString();
                }

                // Return for the promise
                return true;
            });
    }

    public onQueryChange(query: string) {
        this.searchText = query;
        this.querySubject.next(query);
    }

    public processQueryParams(parms: StateParams) {
        if (parms.locationId) {
            this.selectedLocationId = Number(parms.locationId);
        }

        if (parms.campaignId) {
            this.selectedCampaignId = Number(parms.campaignId);
        }

        if (parms.searchType) {
            this.searchType = parms.searchType;
        }

        if (parms.searchText) {
            this.searchText = decodeURIComponent(parms.searchText);
        }

        if (parms.spaceId) {
            this.selectedSpaceId = Number(parms.spaceId);
        }
    }

    public getImageUrl(media: MediaReferenceModel): string {
        let mediaToUse = media;

        if (!media) {
            mediaToUse = this.getDefaultImage();
        } else {
            if (!media.Id || !media.Id.Id || this.isBlankGuid(media.Id.Id)) {
                mediaToUse = this.getDefaultImage();
            }
        }

        return this.buildImageUrl(mediaToUse);
    }

    private isBlankGuid(guid: string): boolean {
        const blankGuid = "00000000-0000-0000-0000-00000000";
        return guid === blankGuid;
    }

    private getDefaultImage(): MediaReferenceModel {
        const baseUrl = this.itemService.urlApiBase;

        const returnModel: MediaReferenceModel = {
            Id: null,
            FileName: "thumbnail.jpg",
            FileExtension: "jpg",
            ResourceUri: `${baseUrl}Media/v1/MasterMedia/PlaceHolder/${this.activeProfileService.businessIdentity}/LocationFixture/Thumbnail/Jpg`,
            MimeType: null,
            Measurement: null,
            Content: null,
            ContentSize: null
        };

        return returnModel;
    }

    private buildImageUrl(media: MediaReferenceModel): string {
        if (media.ResourceUri.indexOf("BusinessIdentity") === -1) {
            media.ResourceUri = IpsString.Format("{0}?BusinessIdentity={1}&idToken={2}", media
                .ResourceUri, this.activeProfileService.businessIdentity, this.authService.getIdToken());
        }

        return media.ResourceUri;
    }

    private updateQueryString() {
        let queryParams: any = {};

        queryParams = {
            searchType: this.searchType,
            searchText: this.searchText,
            locationId: this.selectedLocation.Id
        };

        if (this.selectedCampaignId) {
            queryParams.campaignId = this.selectedCampaignId;
        }

        if (this.selectedSpaceId) {
            queryParams.spaceId = this.selectedSpaceId;
        }

        this.helperService.updateURLParameters(queryParams);
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        } else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    }

    ngOnDestroy() {
        this.saveSearchInfo();
    }

    public saveSearchInfo() {
        if (this.itemHelper) {
            localStorage.setItem(this.searchScrollKey, JSON.stringify({
                search: this.searchText || null,
                locationId: this.selectedLocation.Id,
                positionY: this.scrollY,
                chunkIndex: this.itemHelper.chunkIndex
            }));
        }
    }

    private isInRole(roleNames: string[]): boolean {
        const allThemes = this.activeProfileService.profile.businessIdentityList;

        const allRoles = <string[]>[];

        allThemes.forEach((theme) => {
            allRoles.push(...theme.Role);
        });

        const matchingRoles = allRoles.filter((item) => roleNames.indexOf(item) >= 0);

        return matchingRoles.length > 0;
    }

    private isNullOrSpaces(str: string): boolean {
        return !!str === false || str.match(/^ *$/) !== null;
    }
}
