<div class="col col-md-8" ipsFocus="searchInput">
    <ips-list-search searchLabel="{{'SURVEY' | translate }}"
    searchLabelPlural="{{'SURVEYS' | translate }}"
    labelLoading="{{'ACCESSING_SURVEY_INFO' | translate }}"
    labelLoadingError="{{'ERROR_ACCESSING_SURVEY_INFO' | translate }}"
    [hideList]=true
    [listData]="mySurvey"
    (onListDataChange)="getListData($event.search)"
    [section]="'main.mySurvey'"
    [scroller]=true
    [quickRef]=false
    [itemClickPermission]="'main.mySurvey.view'"
    [promise]="promiseAll"
    [autoFocus]=true
    [query]="query">
        <ng-template #searchHeaderTemplate>
            <div class="row">
                <section class="col-12">
                    <h3 class="title">
                        <span class="header-label-imgn" translate>MY_SURVEYS</span>
                    </h3>
                </section>
                <section class="col-12">
                    <div class="ips-ls-search-module-imgn form-group">
                        <label class="mt-2 mr-2"><b translate>LOCATION</b></label><i class="fa fa-info-circle fa-lg" aria-hidden="true" ngbTooltip="{{'TOOLTIP_SURVEY_LOCATION' | translate }}" placement="bottom"></i>
                        <div class="input-group">
                            <input type="text" class="form-control form-control-lg"
                            #locationField="ngModel" name="location" id="location"
                            [(ngModel)]="location" [ngModelOptions]="{ blur: 200 }"
                            (ngModelChange)="onLocationChange($event)"
                            typeaheadOptionField="Label"
                            typeaheadWaitMs="200"
                            [ngbTypeahead]="getLocation" [resultFormatter]="inputFormatter" [inputFormatter]="inputFormatter"
                            appValidation required>
                            <div class="input-group-append">
                                <button class="btn btn-default btn-lg form-control-lg fileProcess-filter" type="button" (click)="showLocationModal()"><i class="fa fa-search fa-lg"></i></button>
                            </div>
                        </div>
                        <div *ngIf="locationField.errors" class="validation-error-message">
                            <div *ngIf="locationField.errors.required" translate>PLEASE_SELECT_LOCATION</div>
                        </div>
                        <div class="ips-ls-searchBox pt-2">
                            <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                            <input type="text"
                            class="form-control form-control-lg ips-ls-input"
                            [(ngModel)]="query" (ngModelChange)="onQueryChange($event)"
                            id="btn-search"
                            value=""
                            maxlength="20"
                            [ngModelOptions]="{ debounce: 350 }"
                            aria-label="Text input with dropdown button" name="searchInput">
                        </div>
                        <div class="row no-gutters mt-3">
                            <div class="col-12 col-md-auto ml-3">
                                <span class="badge-pill badge-secondary font-weight-normal">{{ displayList.length | number }}</span>
                                <span class="ml-1">{{mySurvey.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping}} {{'FOUND_OF' | translate}}</span>
                            </div>
                            <div class="col-12 col-md-auto ml-3 ml-md-2 mt-1 mt-md-0">
                                <span class="badge-pill badge-secondary font-weight-normal">{{ mySurvey.ResultChunkAttributes.TotalRecords | number }}</span>
                                <span class="ml-1">{{ 'TOTAL' | translate}} {{mySurvey.TotalRecords | i18nPlural: pluralMapping}}</span>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-12 ips-ls-search-module-counts-imgn">
                                <span class="badge-pill badge-secondary ips-ls-search-results-badge">{{displayList.length | number}}</span>
                                <span class="ips-ls-search-total-imgn mr-2">{{mySurvey.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping}} {{'FOUND_OF' | translate}} </span>
                                <div class="avoidwrap w-100 d-sm-inline">
                                    <span class="badge-pill badge-secondary ips-ls-search-total-badge">{{mySurvey.ResultChunkAttributes.TotalRecords | number}}</span>
                                    <span class="ips-ls-search-total-imgn">{{'TOTAL' | translate}} {{mySurvey.TotalRecords | i18nPlural: pluralMapping}}</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </section>
            </div>
        </ng-template>

        <section class="col-12" search-result-List>
            <div class="ips-ls-searchResults">                    
                <div class="list-group" [cgBusy]="promiseAll" cgBusyConfig="{message:{{'LOADING_DATA' | translate}}}">
                    <div infiniteScroll (scrolled)="getMoreListData()" [infiniteScrollDisabled]='busy' [infiniteScrollDistance]='0'>
                        <div *ngIf="loaded">
                            <div *ngFor="let result of displayList | slice:0:numberToDisplay" class="list-group-item">
                                <div class="row">
                                    <div class="col pr-0">
                                        <a uiSref="main.mysurvey.questions" [uiParams]="{id:result.Id, locationId:location.Id}" class="no-underline">
                                            <div>
                                                <div class="ips-search-result-big" innerHTML="{{ result.Name | highlight : search}}"></div>
                                                <div class="pt-2">{{result.QuestionCount}} {{result.QuestionCount | i18nPlural: questionPluralMapping}} </div>
                                                <div class="row progress-bar-vertical pr-3 pt-2" *ngIf="result.EndDate">
                                                    <div class="col ips-w-70">{{result.PercentCompleteUI}}</div>
                                                    <div class="col">
                                                        <div *ngIf="result.PercentComplete === 100">
                                                            <div class="row progress-bar-vertical">
                                                                <div class="col progress ips-progress p-0">
                                                                    <div class="progress-bar bg-secondary" [ngStyle]="{'width' : result.PercentCompleteUI}"></div>
                                                                </div>
                                                                <div class="col-auto pr-0"><i class="form-control-feedback fa fa-lg fa-check ips-font-lg"></i></div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="result.PercentComplete !== 100" class="col p-0">
                                                            <div class="row progress-bar-vertical">
                                                                <div class="col progress ips-progress pl-0">
                                                                    <div class="progress-bar bg-secondary" [ngStyle]="{'width' : result.PercentCompleteUI}"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-auto"><i class="form-control-feedback fa fa-play fa-lg ips-font-lg ips-gray-color"></i></div>
                                                </div>
                                                <div class="survey-date pt-2">{{'SURVEY_CLOSE' | translate}}: {{result.EndDate ? (result.EndDate | date : "mediumDate" : "+0000" ) : ( 'NA' | translate)}} <span *ngIf="result.daysToRespond <= 14 && result.PercentComplete !== 100" class="text-danger">- {{result.respondText}}</span></div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </ips-list-search>
</div>
