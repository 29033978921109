<div class="">
    <form id="ramiInfoInput" class="form-horizontal" name="surveyForm" [formGroup]="surveyForm" novalidate ipsUnsavedWarningForm>
        <div class="col-12">
            <h3 class="ips-gray-color">{{breadCrumbLabel | translate}}</h3>
        </div>

        <!--Information-->
        <div class="card ips-shadow">
            <div class="card-body">
                <div class="row ips-search-result-big">
                    <div class="col ips-gray-color">
                        <div class="d-flex flex-row flex-wrap">
                            <div translate>SURVEY_INFORMATION</div>
                            <div *ngIf="isCollapsedInfo" class="ips-default-font-size ml-md-4 pt-2">
                                <span>{{surveyForm.get('Name').value}}</span>
                            </div>
                            <div *ngIf="isCollapsedInfo" class="ips-default-font-size ml-md-3 pt-2">
                                <span>{{surveyForm.get('StartDate').value ? (surveyForm.get('StartDate').value | date:'mediumDate') + ' - ' : ""}}{{surveyForm.get('EndDate').value ? (surveyForm.get('EndDate').value | date:'mediumDate') : "NA"}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 col-sm-1 d-flex justify-content-end align-items-start pl-0">
                        <button (click)="activeCollapseInfo()" class="btn ips-btn-link-icon" [disabled]="!(this.surveyForm.get('Name').valid && this.surveyForm.get('StartDate').valid)">
                            <span class="ips-gray-color" [ngClass]="['text-right fa fa-chevron-down ips-ls-addIcon', isCollapsedInfo ? '' : 'fa-flip-vertical']"></span>
                        </button>
                    </div>
                </div>

                <div class="row" [ngbCollapse]="isCollapsedInfo">
                    <div class="col">
                        <hr />
                        <div class="form-row">
                            <div class="form-group col-12 col-xl-4">
                                <label class="required" for="Name" translate>NAME</label>
                                <textarea type="text" class="form-control form-control-lg input-full" formControlName="Name" name="nameInput" id="Name" ipsFocus="true" placeholder="{{'ENTER_NAME'| translate}}" rows="1" ipsAutoGrow ></textarea>
                                <ips-show-errors [control]="Name" [errorMessages]="getErrorMessages('Name')"></ips-show-errors>
                            </div>
                            <div class="form-group col-12 col-md-6 col-xl-4">
                                <label class="required" for="StartDate" translate>START_DATE</label>
                                <div class="input-group ips-input-group-icon">
                                    <input type="text" name="StartDate" id="StartDate" class="form-control form-control-lg input-full"
                                           formControlName="StartDate"
                                           placeholder="{{ 'ENTER_START_DATE' | translate }}"
                                           #d="bsDatepicker" bsDatepicker [bsConfig]="startDateOptions" (bsValueChange)="changeStartDate($event)" (blur)="blurStartDate($event)" triggers="" />
                                    <span class="input-group-append">
                                        <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                    </span>
                                </div>
                                <ips-show-errors [control]="StartDate" [errorMessages]="getErrorMessages('StartDate')"></ips-show-errors>
                            </div>
                            <div class="form-group col-12 col-md-6 col-xl-4">
                                <label for="EndDate" translate>END_DATE</label>
                                <div class="input-group ips-input-group-icon">
                                    <input type="text" name="EndDate" id="EndDate" class="form-control form-control-lg input-full"
                                           formControlName="EndDate"
                                           placeholder="{{ 'ENTER_END_DATE' | translate }}"
                                           #d2="bsDatepicker" bsDatepicker [bsConfig]="endDateOptions" (blur)="blurEndDate($event)" triggers="" />
                                    <span class="input-group-append">
                                        <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d2.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                    </span>
                                </div>
                                <ips-show-errors [control]="EndDate" [errorMessages]="getErrorMessages('EndDate')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12">
                                <div class="form-row">
                                    <div class="form-group col-sm-12 mb-2">
                                        <label for="Notes" class="mb-0" translate>NOTES</label>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-sm-12 mb-2">
                                        <textarea class="form-control form-control-lg input-full" name="Notes" id="Notes" cols="30" rows="3" formControlName="Notes" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                                        <ips-show-errors [control]="Notes" [errorMessages]="getErrorMessages('Notes')" cssClass="validation-message-Qty"></ips-show-errors>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Audience-->
        <div class="card ips-shadow mt-4">
            <div class="card-body">
                <div class="row ips-search-result-big">
                    <div class="col ips-gray-color d-flex align-items-center">
                        <span translate>SURVEY_AUDIENCE</span> <span *ngIf="isCollapsedAudience" class="ips-default-font-size ml-4">{{'LOCATION_BALANCE' | translate}}: {{locationBalance | number}}</span>
                    </div>
                    <div class="col-2 col-sm-1 d-flex justify-content-end pl-0">
                        <button (click)="activeCollapseAudience()" class="btn ips-btn-link-icon" [disabled]="!(surveyForm.controls.Markets ? surveyForm.controls.Markets.valid : false)">
                            <span class="ips-gray-color" [ngClass]="['text-right fa fa-chevron-down ips-ls-addIcon', isCollapsedAudience ? '' : 'fa-flip-vertical']"></span>
                        </button>
                    </div>
                </div>
                <div class="row" [ngbCollapse]="isCollapsedAudience">
                    <div class="col">
                        <hr />
                        <app-market-edit *ngIf="markets"
                                         [parent]="surveyForm"
                                         [marketsModel]="markets"
                                         [label]="'AUDIENCE'"
                                         [balanceCalcService]="marketGroupService"
                                         (balanceChanged)="balanceChangedEventHandler($event)"></app-market-edit>
                    </div>
                </div>
            </div>
        </div>

        <!--Questions-->
        <div class="card ips-shadow mt-4">
            <div class="card-body">
                <div class="row ips-search-result-big">
                    <div class="col ips-gray-color" translate>
                        SURVEY_QUESTIONS
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <app-survey-question-edit *ngIf="loaded"
                          [sectionsModel]="sections"
                          [parent]="surveyForm"
                          [openSections]="openSectionOrdinals"
                          (sectionDeleted)="sectionDeleteEventHandler($event)"></app-survey-question-edit>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 col-sm-6 col-lg-3">
                <div class="form-group mt-2">
                    <button id="saveSurvey"
                            class="btn btn-lg btn-block alert-success"
                            (click)="saveSurveyPrompt(false)"
                            [disabled]="surveyForm.invalid || surveyForm.pristine"
                            [ngClass]="{'btn-default': surveyForm.invalid || surveyForm.pristine}" translate>
                        SAVE
                    </button>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3">
                <div class="form-group mt-2">
                    <button id="saveReturnSurvey"
                            class="btn btn-lg btn-block alert-success"
                            (click)="saveSurveyPrompt(true)"
                            [disabled]="surveyForm.invalid || surveyForm.pristine"
                            [ngClass]="{'btn-default': surveyForm.invalid || surveyForm.pristine}" translate>
                        SAVE_AND_RETURN
                    </button>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3">
                <div class="form-group mt-2">
                    <button id="cancelSurvey" class="btn btn-default btn-lg btn-block" uiSref="main.survey.search" type="submit" translate>CANCEL</button>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-lg-3">
                <div class="form-group mt-2">
                    <button id="deleteSurvey"
                            class="btn btn-lg btn-block alert-danger"
                            (click)="deleteSurveyPrompt()"
                            ips-unsaved-warning-clear
                            [disabled]="!surveyForm.value.Id"
                            [ngClass]="{'btn-default': !surveyForm.value.Id}" translate>
                        DELETE_SURVEY
                    </button>
                </div>
            </div>
        </div>

    </form>
</div>
