import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-product-edit",
    templateUrl: "./product-edit.component.html"
})
export class ProductAdminEditComponent implements OnInit {

    constructor(private translateService: TranslateService) {

    }

    ngOnInit() {
    }
}
