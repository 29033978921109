<div>
    <div>
        <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
    </div>
    <div class="ips-modal-header ips-modal-dark-header">
        <h4 class="modal-title m-0" translate>SELECT_DELIVERY_OPTION</h4>
    </div>
    <div class="modal-body">
        <div class="ips-ls-searchResults ips-ls-long-text-wrap list-group">
            <div class="modal-scrollable list-search-infinite-container">
                <div *ngFor="let item of items"
                     class="card ips-shadow mb-2 subgroup-card d-flex flex-row align-items-center">
                    <div class="p-2 pl-3 flex-grow-1 clicker"
                         (click)="itemClicked(item)">
                        {{ item.Value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
