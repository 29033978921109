import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UIRouterModule } from "@uirouter/angular";
import { SortablejsModule } from "angular-sortablejs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CgBusyModule } from "angular-busy2";
import { TranslateModule } from "@ngx-translate/core";
import { ImagineUiNgCoreModule } from "imagine-ui-ng-core";
import { ImagineUiNgSecurityModule } from "imagine-ui-ng-security";
import { ImagineUiNgQuickStartModule } from "imagine-ui-ng-quick-start";
import { ImagineUiNgMessagingModule } from "imagine-ui-ng-messaging";
import { ListSearchModule } from "imagine-ui-ng-list-search";
import { IpsModalModule, IpsModalService } from "imagine-ui-ng-modal";
import { SharedModule } from "../shared/shared.module";
import { MarketModule } from "../market/market.module";

import { SurveySearchComponent } from "./survey/survey-search/survey-search.component";
import { SurveyViewComponent } from "./survey/survey-view/survey-view.component";
import { SurveyEditComponent } from "./survey/survey-edit/survey-edit.component";
import { SurveyQuestionEditComponent } from "./survey/survey-question-edit/survey-question-edit.component";

import { SurveyService } from "../imagine-ui-ng-survey";

import { SURVEY_BASE_STATES } from "./imagine-ui-ng-survey.futureStates";
import { SurveySectionViewComponent } from "./survey/survey-section-view/survey-section-view.component";
import { MySurveySearchComponent } from "./survey/my-survey-search/my-survey-search.component";
import { MySurveyTakeQuestionsComponent } from "./my-survey/my-survey-take-questions/my-survey-take-questions.component";
import { MySurveyTakeSectionComponent } from "./my-survey/my-survey-take-section/my-survey-take-section.component";
import { MySurveyTakeQuestionComponent } from "./my-survey/my-survey-take-question/my-survey-take-question.component";
import { SurveyAnswerSearchModalComponent } from "./survey/survey-answer-search-modal/survey-answer-search-modal.component";
import { MySurveyMessageComponent } from "./my-survey/my-survey-message/my-survey-message.component";

@NgModule({
    imports: [
        CommonModule,
        UIRouterModule.forChild({ states: SURVEY_BASE_STATES }),
        SortablejsModule,
        TypeaheadModule.forRoot(),
        CgBusyModule,
        InfiniteScrollModule,
        TranslateModule.forChild(),
        ImagineUiNgQuickStartModule,
        ImagineUiNgSecurityModule,
        ListSearchModule,
        NgbModule,
        BsDatepickerModule,
        IpsModalModule,
        ImagineUiNgMessagingModule,
        ReactiveFormsModule,
        ImagineUiNgCoreModule,
        SharedModule,
        FormsModule,
        MarketModule
    ],
    declarations: [SurveySearchComponent, SurveyViewComponent, SurveyEditComponent, SurveyQuestionEditComponent, SurveySectionViewComponent, MySurveySearchComponent,
        MySurveyTakeQuestionsComponent, MySurveyTakeSectionComponent, MySurveyTakeQuestionComponent, SurveyAnswerSearchModalComponent, MySurveyMessageComponent],
    entryComponents: [SurveyAnswerSearchModalComponent],
    providers: [
        SurveyService
    ]
})
export class ImagineUiNgSurveyModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ImagineUiNgSurveyModule,
            providers: [IpsModalService]
        };
    }
}
