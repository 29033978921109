<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_ACCOUNT_INFO' | translate}}'}">
        <div *ngIf="roleLoaded && accountLoaded">
            <form id="ramiInfoInput" class="form-horizontal" name="userForm" [formGroup]="userForm" novalidate ipsUnsavedWarningForm>
                <div class="form-row">
                    <div class="form-group col-12 col-sm-6 col-md-4">
                        <label class="required" for="UserName" translate>USERNAME</label>
                        <input type="text" name="UserName" id="UserName" formControlName="UserName" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_USERNAME' | translate}}" *ngIf="inCreateMode" />
                        <input type="text" name="UserName" id="UserName" formControlName="UserName" class="form-control form-control-lg input-full" *ngIf="!inCreateMode">
                        <ips-show-errors [control]="UserName" [errorMessages]="getErrorMessages('UserName')" cssClass="validation-message-Qty" showRequiredPlusOthers="true"></ips-show-errors>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-md-4" *ngIf="onAccountMe && !IsNonSso">
                        <label class="required" for="Role" translate>ROLE</label>
                        <input type="text" name="Role" id="Role" formControlName="Role" class="form-control form-control-lg input-full">
                        <ips-show-errors [control]="Role" [errorMessages]="getErrorMessages('Role')" cssClass="validation-message-Qty"></ips-show-errors>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-md-4" *ngIf="userName">
                        <label class="required" for="Status" translate>STATUS</label>
                        <div class="form-control form-control-lg input-full add-holder">
                            <i class="mr-3" name="Status" [ngClass]="getStatusImage()"></i>{{account.Status}}
                        </div>
                    </div>
                </div>
                <hr />
                <div class="form-row">
                    <div class="form-group col-12 col-sm-6 col-md-4">
                        <label class="required" for="FirstName" translate>FIRST_NAME</label>
                        <input type="text" name="FirstName" id="FirstName" formControlName="FirstName" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_FIRST_NAME' | translate }}">
                        <ips-show-errors [control]="FirstName" [errorMessages]="getErrorMessages('FirstName')" cssClass="validation-message-Qty"></ips-show-errors>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-md-4">
                        <label class="required" for="LastName" translate>LAST_NAME</label>
                        <input type="text" name="LastName" id="LastName" formControlName="LastName" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_LAST_NAME' | translate }}">
                        <ips-show-errors [control]="LastName" [errorMessages]="getErrorMessages('LastName')" cssClass="validation-message-Qty"></ips-show-errors>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-md-4">
                        <label class="required" for="Email" translate>EMAIL</label>
                        <input type="text" name="Email" id="Email" formControlName="Email" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_EMAIL' | translate }}">
                        <ips-show-errors [control]="Email" [errorMessages]="getErrorMessages('Email')" cssClass="validation-message-Qty" showRequiredPlusOthers="true"></ips-show-errors>
                    </div>
                    <div class="form-group col-12 col-sm-6 col-md-4">
                        <label for="Phone" translate>PHONE</label>
                        <input type="text" name="Phone" id="Phone" formControlName="Phone" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_PHONE' | translate }}">
                    </div>
                </div>
                <hr />

                <div *ngIf="!onAccountMe">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label class="required" translate>ROLES</label>
                        </div>
                        <div class="col-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input type="radio" class="" name="RoleType" formControlName="RoleType" value="Corporate" (change)="roleTypeChange('Corporate')">
                                    </div>
                                </div>
                                <div type="text" class="form-control form-control-lg font-weight-bold" translate>CORPORATE_USER <small>(<span translate>ACCESS_TO_ALL_LOCATIONS</span>)</small></div>
                            </div>
                            <ul formArrayName="CorporateRoles" class="ml-4 pl-3 ips-input-group-list list-group">
                                <li *ngFor="let role of CorporateRoles.controls; let i=index" class="list-group-item">
                                    <div [formGroupName]="i" class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <input type="checkbox" formControlName="Selected" /></span>
                                        </div>
                                        <div class="form-control form-control-lg input-full" [ngClass]="{'ips-gray-white-bg': activeRoleType!=='Corporate', 'invalid': CorporateRoles.invalid && (CorporateRoles.dirty || CorporateRoles.touched) }">{{role.get('DisplayName').value}}</div>
                                    </div>
                                </li>
                            </ul>
                            <div class="ml-4 pl-3">
                                <ips-show-errors [control]="userForm.get('CorporateRoles')" [errorMessages]="getErrorMessages('CorporateRoles')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3">
                        <div class="col-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input type="radio" class="" name="RoleType" formControlName="RoleType" value="Field" (change)="roleTypeChange('Field')">
                                    </div>
                                </div>
                                <div type="text" class="form-control form-control-lg font-weight-bold" translate>FIELD_USER <small>(<span translate>ACCESS_TO_SPECIFIED_LOCATIONS</span>)</small></div>
                            </div>
                            <ul formArrayName="FieldRoles" class="ml-4 pl-3 ips-input-group-list list-group">
                                <li *ngFor="let role of FieldRoles.controls; let i=index" class="list-group-item">
                                    <div [formGroupName]="i" class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"> <input type="checkbox" formControlName="Selected" /></span>
                                        </div>
                                        <div class="form-control form-control-lg input-full" [ngClass]="{'ips-gray-white-bg': activeRoleType!=='Field', 'invalid': FieldRoles.invalid && (FieldRoles.dirty || FieldRoles.touched) }">{{role.get('DisplayName').value}}</div>
                                    </div>
                                </li>
                            </ul>
                            <div class="ml-4 pl-3">
                                <ips-show-errors [control]="FieldRoles" [errorMessages]="getErrorMessages('FieldRoles')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="onAccountMe">
                    <div class="form-row">
                        <div class="form-group col-12 col-sm-6 col-md-4">
                            <h3 translate>PREFERENCES</h3>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="onAccountMe">
                        <div class="form-group col-12 col-sm-6 col-md-4">
                            <label for="timeZoneSelect" translate>LOCAL_TIMEZONE</label>
                            <ng2-timezone-picker class="form-control dropdownBtn form-control-lg p-0"
                                                 [timezone]="account.Profile.TimeZone"
                                                 (change)="changeTimezone($event)"
                                                 placeholder="Select timezone"
                                                 showOffset="true"
                                                 guess="true"
                                                 name="timeZoneSelect">
                            </ng2-timezone-picker>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-4">
                            <label for="Locale" translate>LOCALE</label>
                            <select name="Locale" formControlName="Locale" class="form-control form-control-lg">
                                <option *ngFor="let item of localeList" [ngValue]="item.Iso3166TwoCharCountryCode">{{item.Name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-4">
                            <label for="Language" translate>LANGUAGE</label>
                            <select name="Language" formControlName="Language" class="form-control form-control-lg">
                                <option *ngFor="let item of languageList" [ngValue]="item.Code">{{item.Language}}</option>
                            </select>

                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveElement"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveAccountPrompt()"
                                    [disabled]="userForm.invalid || userForm.pristine"
                                    [ngClass]="{'btn-default': userForm.invalid || userForm.pristine}" translate>
                                {{saveLabel}}
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3" *ngIf="!onAccountMe">
                        <div class="form-group mt-2">
                            <button id="cancelUserForm" class="btn btn-default btn-lg btn-block" type="submit" uiSref="main.profileUser.search" translate>
                                CANCEL
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3" *ngIf="onAccountMe">
                        <div class="form-group mt-2">
                            <button id="cancelAccountForm" class="btn btn-default btn-lg btn-block" type="submit" (click)="cancel()" translate>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_ACCOUNT_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>


