var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService } from "imagine-ui-ng-core";
var GeneralSettingsService = /** @class */ (function (_super) {
    __extends(GeneralSettingsService, _super);
    function GeneralSettingsService(http, activeProfileService, helperFunctionsService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.helperFunctionsService = helperFunctionsService;
        _this.EnableSignPlanLayout = "EnableSignPlanLayout";
        _this.ExpandSurveyOptions = "ExpandSurveyOptions";
        _this.EnableUpDownReport = "EnableUpDownReport";
        _this.currentSettings = null;
        _this.init("Settings", "System", "v1");
        _this.currentBusinessId = _this.activeProfileService.businessIdentity;
        return _this;
    }
    GeneralSettingsService.prototype.save = function (settings) {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            return _this.put(settings).then(function (response) {
                _this.currentSettings = null;
                _this.getCurrentSettings().then(function () {
                    resolve();
                }).catch(function () {
                    reject();
                });
            }).catch(function () {
                reject();
            });
        });
        return returnPromise;
    };
    GeneralSettingsService.prototype.getAllSettings = function (includeDefaults) {
        var _this = this;
        if (includeDefaults === void 0) { includeDefaults = false; }
        var route = this.urlApiBaseProductPlusVersion + "AllSettings";
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getByRoute(route).then(function (response) {
                if (includeDefaults) {
                    var defaults = _this.getDefaultSettings();
                    var neededDefaults = defaults.filter(function (defaultItem) {
                        return !response.find(function (item) { return item.Name === defaultItem.Name; });
                    });
                    resolve(neededDefaults.concat(response));
                }
                else {
                    resolve(response);
                }
            }).catch(function () {
                resolve(_this.getDefaultSettings());
            });
        });
        return returnPromise;
    };
    GeneralSettingsService.prototype.getCurrentSettings = function () {
        var _this = this;
        if (this.currentBusinessId !== this.activeProfileService.businessIdentity) {
            this.currentSettings = null;
            this.currentBusinessId = this.activeProfileService.businessIdentity;
        }
        if (this.currentSettings) {
            return Promise.resolve(this.currentSettings);
        }
        else {
            var returnPromise = new Promise(function (resolve, reject) {
                _this.getAllSettings(true).then(function (response) {
                    _this.currentSettings = response;
                    resolve(response);
                }).catch(function () {
                    reject();
                });
            });
            return returnPromise;
        }
    };
    GeneralSettingsService.prototype.getDefaultSettings = function () {
        var settings = [
            {
                "Id": 0,
                "Name": "EnableSignPlanLayout",
                "Value": "false",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "CoverBgImageFile",
                "Value": "",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "CoverHeaderLabel",
                "Value": "POP Placement Sheets",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "CoverFooterLabel",
                "Value": "",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "PageSize",
                "Value": "A3",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "PageOrientation",
                "Value": "2",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "SpaceOrder", "Value": "[]",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "PSICustomerNumber",
                "Value": "",
                "Type": "PSI",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "MapMaterialNumberToCustomerItemCode",
                "Value": "false",
                "Type": "PSI",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "ExpandSurveyOptions",
                "Value": "true",
                "Type": "Survey",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "EnableUpDownReport",
                "Value": "false",
                "Type": "Report",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            }
        ];
        return settings;
    };
    GeneralSettingsService.prototype.getSettingByName = function (name) {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            if (!name) {
                resolve(null);
            }
            else {
                _this.getCurrentSettings().then(function () {
                    var targetSetting = _this.currentSettings.find(function (setting) { return setting.Name === name; });
                    resolve(targetSetting);
                });
            }
        });
        return returnPromise;
    };
    GeneralSettingsService.prototype.getSignPlanLayoutSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.EnableSignPlanLayout).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(false);
                }
            });
        });
        return returnPromise;
    };
    GeneralSettingsService.prototype.canEditSignPlanLayout = function () {
        var _this = this;
        var hasEditPermission = !!this.activeProfileService.permissions.find(function (item) { return item.Name === "EditSignPlan"; });
        var returnPromise = new Promise(function (resolve, reject) {
            if (hasEditPermission) {
                _this.getSignPlanLayoutSetting().then(function (response) {
                    resolve(response);
                }).catch(function () {
                    resolve(false);
                });
            }
            else {
                resolve(false);
            }
        });
        return returnPromise;
    };
    GeneralSettingsService.prototype.canViewSignPlan = function () {
        var _this = this;
        var hasViewPermission = !!this.activeProfileService.permissions.find(function (item) { return item.Name === "ViewSignPlan"; });
        var returnPromise = new Promise(function (resolve, reject) {
            if (hasViewPermission) {
                _this.getSignPlanLayoutSetting().then(function (response) {
                    resolve(response);
                }).catch(function () {
                    resolve(false);
                });
            }
            else {
                resolve(false);
            }
        });
        return returnPromise;
    };
    GeneralSettingsService.prototype.getUpDownReportSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.EnableUpDownReport).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(false);
                }
            });
        });
        return returnPromise;
    };
    GeneralSettingsService.prototype.canViewUpDownReport = function () {
        var _this = this;
        var hasPermission = !!this.activeProfileService.permissions.find(function (item) { return item.Name === "SearchCampaignContentReport"; });
        var returnPromise = new Promise(function (resolve, reject) {
            if (hasPermission) {
                _this.getUpDownReportSetting().then(function (response) {
                    resolve(response);
                }).catch(function () {
                    resolve(false);
                });
            }
            else {
                resolve(false);
            }
        });
        return returnPromise;
    };
    GeneralSettingsService.prototype.canExpandSurveyOptions = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getSettingByName(_this.ExpandSurveyOptions).then(function (response) {
                if (response) {
                    resolve(response.Value === "true");
                }
                else {
                    resolve(false);
                }
            });
        });
        return returnPromise;
    };
    return GeneralSettingsService;
}(IpsBaseWebService));
export { GeneralSettingsService };
