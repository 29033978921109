var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { HolderVersionService } from "../../service/holder-version.service";
import { String as IpsString } from "typescript-string-operations";
import { NgForm } from "@angular/forms";
import { TemplateFormBase } from "../../../shared/templateFormBase";
var HolderVersionEditComponent = /** @class */ (function (_super) {
    __extends(HolderVersionEditComponent, _super);
    function HolderVersionEditComponent(holderVersionService, ipsMessage, $state, trans, translateService, pageTitleService) {
        var _this = _super.call(this) || this;
        _this.holderVersionService = holderVersionService;
        _this.ipsMessage = ipsMessage;
        _this.$state = $state;
        _this.trans = trans;
        _this.translateService = translateService;
        _this.pageTitleService = pageTitleService;
        return _this;
    }
    HolderVersionEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var holderversionId = Number(this.trans.params().id);
        var pageTitle = holderversionId > 0 ? "EDIT_HOLDER_VERSION_INFO" : "CREATE_HOLDER_VERSION_INFO";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);
        // Clear out current location, fill in defaults
        this.holderversion = { Id: holderversionId };
        this.getVersionList();
        // If we got an ID to load, load it.
        // If paramId is not a number it will evaluate to false
        if (holderversionId > 0) {
            //Initial call to populate screen on load
            this.getVersionInfo(holderversionId);
        }
        else {
            this.loaded = true;
        }
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        _super.prototype.setFormPristine.call(this, this.holderversionForm);
    };
    HolderVersionEditComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(["ACCESSING_HOLDER_VERSION_INFO", "PROMPT_DELETE_BODY", "DUPLICATE_VERSION"]).subscribe(function (res) {
            _this.accessHolderVersionInfo = res["ACCESSING_HOLDER_VERSION_INFO"];
            _this.deleteBodyPrompt = res["PROMPT_DELETE_BODY"];
            _this.saveError = res["DUPLICATE_VERSION"];
        });
    };
    //Private Functions
    HolderVersionEditComponent.prototype.deleteVersionInfo = function () {
        var id = this.holderversion.Id;
        return this.holderVersionService.delete(id);
    };
    HolderVersionEditComponent.prototype.getVersionInfo = function (id) {
        var _this = this;
        this.loaded = false;
        this.promise = this.holderVersionService.get(id).then(function (response) {
            Object.assign(_this.holderversion, response);
            _this.originalName = _this.holderversion.Name;
            _this.loaded = true;
        });
    };
    HolderVersionEditComponent.prototype.saveVersionInfo = function () {
        var _this = this;
        var id = this.holderversion.Id;
        if (id > 0) {
            return this.holderVersionService.put(this.holderversion).then(function (response) {
                _this.holderversionForm.form.markAsPristine();
            });
        }
        else if (this.holderversion.Id === 0) {
            return this.holderVersionService.post(this.holderversion).then(function (response) {
                _this.holderversionForm.form.markAsPristine();
            });
        }
    };
    HolderVersionEditComponent.prototype.checkUnique = function (version) {
        var item = this.versionList.filter(function (x) { return x.Name && x.Name.toLocaleLowerCase().trim() === version.toLocaleLowerCase().trim(); });
        if (item.length === 0) {
            return true;
        }
        else {
            return false;
        }
    };
    HolderVersionEditComponent.prototype.getVersionList = function () {
        var _this = this;
        return this.holderVersionService.getList()
            .then(function (response) {
            _this.versionList = response;
        });
    };
    // Public Functions used by view
    HolderVersionEditComponent.prototype.deleteVersionInfoPrompt = function () {
        var _this = this;
        var translated = this.deleteBodyPrompt;
        translated = IpsString.Format(translated, this.holderversion.Name);
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: function () { return _this.deleteVersionInfo(); }, progressMessage: "DELETING" })
            .then(function (result) {
            if (result) {
                _this.$state.go("main.storeProfileHolderVersion.search");
            }
        })
            .catch(function () {
            // rejection
        });
    };
    HolderVersionEditComponent.prototype.saveVersionInfoPrompt = function () {
        var _this = this;
        var unique = this.checkUnique(this.holderversion.Name);
        if ((unique && this.holderversion.Id === 0) || (this.holderversion.Id > 0 && (!unique && this.originalName === this.holderversion.Name)) || (this.holderversion.Id > 0 && unique)) {
            return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: function () { return _this.saveVersionInfo(); }, progressMessage: "SAVING" }).then(function (result) {
                if (result) {
                    _this.$state.go("main.storeProfileHolderVersion.search");
                }
            });
        }
        else {
            this.ipsMessage.error(this.saveError);
        }
    };
    return HolderVersionEditComponent;
}(TemplateFormBase));
export { HolderVersionEditComponent };
