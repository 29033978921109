var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, AfterViewInit } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { ActiveProfileService, AddressModel, CountryModel, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { PageTitleService, CountryService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { LocationService } from "../../index";
import { Transition, StateService } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString } from "typescript-string-operations";
import { TemplateFormBase } from "../../../shared/templateFormBase";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";
import { IpsImageEditComponent } from "imagine-ui-ng-quick-start";
import { AuthService } from "imagine-ui-ng-security";
import { AddressService } from "../../../shared/service/address.service";
import { SuggestedAddressModalComponent } from "../../../shared/suggested-addresses-modal/suggested-addresses-modal.component";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { AlternateAddressService } from "../../../imagine-ui-ng-store-profile";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
var LocationEditComponent = /** @class */ (function (_super) {
    __extends(LocationEditComponent, _super);
    function LocationEditComponent(transition, locationService, addressService, ipsModal, ipsMessage, countryService, $state, translateService, pageTitleService, activeProfileService, authService, alternateAddressService) {
        var _this = _super.call(this) || this;
        _this.addressService = addressService;
        _this.ipsModal = ipsModal;
        _this.ipsMessage = ipsMessage;
        _this.countryService = countryService;
        _this.$state = $state;
        _this.translateService = translateService;
        _this.pageTitleService = pageTitleService;
        _this.activeProfileService = activeProfileService;
        _this.authService = authService;
        _this.alternateAddressService = alternateAddressService;
        _this.loaded = false;
        _this.openLabel = "OPEN_WITH_LABEL";
        _this.initPromotionsLabel = "FIRST_RECEIPT_WITH_LABEL";
        _this.suspendPromotionsLabel = "SUSPEND_DATE_WITH_LABEL";
        _this.openDateEmptyWarning = "OPEN_DATE_EMPTY_WARNING_MESSAGE";
        _this.openDateAfterSuspendDateWarning = "OPEN_DATE_AFTER_SUSPEND_DATE_WARNING_MESSAGE";
        _this.suspendDateBeforeOpenDateWarning = "SUSPEND_DATE_BEFORE_OPEN_DATE_WARNING_MESSAGE";
        _this.information = "INFORMATION_UPPERCASE";
        _this.failedAddressValidation = "FAILED_ADDRESS_VALIDATION";
        _this.closeLocation = "CLOSE_LOCATION";
        _this.locationClosed = "LOCATION_CLOSED";
        _this.showOpenDateEmptyWarning = false;
        _this.showOpenDateAfterSuspendDateWarning = false;
        _this.showSuspendDateBeforeOpenDateWarning = false;
        _this.calendarTheme = "theme-default";
        _this.ErrorStrings = {
            "CALENDAR_DATE_FORMAT": ""
        };
        _this.closeLocationText = "";
        _this.countryList = [];
        _this.showLocationStatus = false;
        _this.alternateAddress = { Id: 0, Label: " " };
        _this.canDeleteErrorList = [];
        _this.deleteLocation = function () {
            return _this.dataService.delete(_this.mylocation.Id);
        };
        _this.ValidateInitDate = function () {
            return _this.validateDateFormat(_this.mylocation.InitiatePromotionsDate);
        };
        _this.ValidateOpenDate = function () {
            return _this.validateDateFormat(_this.mylocation.OpenDate);
        };
        _this.ValidateSuspendDate = function () {
            return _this.validateDateFormat(_this.mylocation.SuspendPromotionsDate);
        };
        _this.getAlternateAddress = function (text$) {
            return text$.pipe(debounceTime(350), distinctUntilChanged(), switchMap(function (val) {
                //Make sure there are at-least two characters before searching
                if (val.length < 2) {
                    return [];
                }
                var searchParams = { searchText: val.trim(), businessIdentity: _this.activeProfileService.businessIdentity, chunkIndex: 0, recordCount: 20, filterOption: "1" };
                return _this.alternateAddressService.search(searchParams, "AlternateAddress/Address/Search")
                    .then(function (result) {
                    result.ResultList.forEach(function (item) {
                        item.Id = item.Id;
                        item.Label = item.AddressIdentifier + " - " + item.Name;
                    });
                    result.ResultList.unshift({
                        Id: 0,
                        Label: _this.translateService.instant("LOCATION_ADDRESS"),
                        AddressType: "None"
                    });
                    return result.ResultList;
                });
            }));
        };
        _this.inputFormatter = function (result) {
            if (result && result.Label) {
                return result.Label;
            }
            return result;
        };
        _this.dataService = locationService;
        var minDate = new Date(Date.UTC(1970, 1, 1, 0, 0, 0, 0));
        var localeName = _this.activeProfileService.profile.Locale || navigator.language;
        _this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();
        _this.openDate = {
            opened: false
        };
        _this.openDateOptions = {
            minDate: minDate,
            containerClass: _this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: _this.dateFormat
        };
        _this.initDate = {
            opened: false
        };
        _this.initDateOptions = {
            minDate: minDate,
            containerClass: _this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: _this.dateFormat
        };
        _this.suspendDate = {
            opened: false
        };
        _this.suspendDateOptions = {
            minDate: minDate,
            containerClass: _this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: _this.dateFormat
        };
        _this.id = Number(transition.params().id);
        if (_this.id > 0) {
            _this.showLocationStatus = true;
        }
        // Clear out current location, fill in defaults
        _this.mylocation = {
            Addresses: [{ Attention: "LOCATION MANAGER", Country: "USA", Order: 1 }],
            AlternateAddresses: [{ Id: 0 }],
            LocationStatusInfo: { Status: "UNKNOWN", IconClass: "unknown", Tooltip: "" }
        };
        return _this;
    }
    LocationEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        var pageTitle = this.id > 0 ? "EDIT_LOCATION" : "CREATE_LOCATION";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);
        // If we got an ID to load, load it.
        if (this.id > 0) {
            //Initial call to populate screen on load
            this.getLocation(this.id);
        }
        else {
            this.showOpenDateEmptyWarning = true;
            this.loaded = true;
        }
        this.countryPromise = this.countryService.Get();
        this.countryPromise.then(function (response) {
            _this.countryList = response;
        });
    };
    LocationEditComponent.prototype.ngAfterViewInit = function () {
        _super.prototype.setFormPristine.call(this, this.locationForm, 700);
    };
    LocationEditComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(["OPEN_WITH_LABEL", "FIRST_RECEIPT_DATE_WITH_LABEL", "SUSPEND_DATE_WITH_LABEL", "OPEN_DATE_EMPTY_WARNING_MESSAGE",
            "OPEN_DATE_AFTER_SUSPEND_DATE_WARNING_MESSAGE", "SUSPEND_DATE_BEFORE_OPEN_DATE_WARNING_MESSAGE", "CALENDAR_DATE_FORMAT", "INFORMATION_UPPERCASE",
            "FAILED_ADDRESS_VALIDATION", "CLOSE_LOCATION", "LOCATION_CLOSED", "LOCATION_ADDRESS"]).subscribe(function (res) {
            _this.openLabel = IpsString.Format(res["OPEN_WITH_LABEL"], _this.dateFormat);
            _this.initPromotionsLabel = IpsString.Format(res["FIRST_RECEIPT_DATE_WITH_LABEL"], _this.dateFormat);
            _this.suspendPromotionsLabel = IpsString.Format(res["SUSPEND_DATE_WITH_LABEL"], _this.dateFormat);
            _this.openDateEmptyWarning = res["OPEN_DATE_EMPTY_WARNING_MESSAGE"];
            _this.openDateAfterSuspendDateWarning = res["OPEN_DATE_AFTER_SUSPEND_DATE_WARNING_MESSAGE"];
            _this.suspendDateBeforeOpenDateWarning = res["SUSPEND_DATE_BEFORE_OPEN_DATE_WARNING_MESSAGE"];
            _this.ErrorStrings["CALENDAR_DATE_FORMAT"] = res["CALENDAR_DATE_FORMAT"];
            _this.information = res["INFORMATION_UPPERCASE"];
            _this.failedAddressValidation = res["FAILED_ADDRESS_VALIDATION"];
            _this.closeLocation = res["CLOSE_LOCATION"];
            _this.locationClosed = res["LOCATION_CLOSED"];
            _this.alternateAddress = { Id: 0, Label: res["LOCATION_ADDRESS"] };
            //Default to close location
            _this.closeLocationText = _this.closeLocation;
            _this.invalidDateErrorMessage = IpsString.Format(_this.ErrorStrings["CALENDAR_DATE_FORMAT"], _this.dateFormat);
        });
    };
    LocationEditComponent.prototype.getLocation = function (id) {
        var _this = this;
        this.loaded = false;
        var canDelete = this.dataService.deleteCheck(id.toString())
            .then(function (response) {
            _this.canDeleteErrorList = response;
        })
            .catch(function (response) {
            _this.canDeleteErrorList = ["-ERROR-"];
        });
        this.promise = this.dataService.get(id).then(function (response) {
            Object.assign(_this.mylocation, response);
            _this.originalAddress = response.Addresses.length ? response.Addresses[0] : null;
            _this.populateThumbnail(_this.mylocation);
            if (response.OpenDate) {
                _this.mylocation.OpenDate = new Date(response.OpenDate);
            }
            if (response.InitiatePromotionsDate) {
                _this.mylocation.InitiatePromotionsDate = new Date(response.InitiatePromotionsDate);
            }
            if (response.SuspendPromotionsDate) {
                _this.mylocation.SuspendPromotionsDate = new Date(response.SuspendPromotionsDate);
            }
            if (response.ClosedDate) {
                _this.originalIsClosed = true;
                _this.mylocation.IsClosed = true;
                _this.mylocation.ClosedDate = response.ClosedDate;
                _this.closeLocationText = _this.locationClosed;
            }
            else {
                _this.closeLocationText = _this.closeLocation;
            }
            //Populate the alternate address
            if (response.AlternateAddresses && response.AlternateAddresses.length > 0) {
                _this.alternateAddress = {
                    Id: response.AlternateAddresses[0].Id,
                    Label: response.AlternateAddresses[0].Id > 0
                        ? response.AlternateAddresses[0].Name
                        : _this.translateService.instant("LOCATION_ADDRESS")
                };
            }
            else {
                //Set default Alternate Address
                _this.mylocation.AlternateAddresses = [{ Id: 0 }];
            }
            _this.showWarnings();
            _this.getLocationStatus();
        });
        Promise.all([canDelete, this.promise]).then(function () {
            _this.loaded = true;
        });
    };
    LocationEditComponent.prototype.showWarnings = function () {
        if (this.mylocation.OpenDate === null || this.mylocation.OpenDate === undefined) {
            this.showOpenDateEmptyWarning = true;
            this.showSuspendDateBeforeOpenDateWarning = false;
            this.showOpenDateAfterSuspendDateWarning = false;
        }
        else {
            this.showOpenDateEmptyWarning = false;
            if (this.mylocation.SuspendPromotionsDate &&
                this.mylocation.OpenDate > this.mylocation.SuspendPromotionsDate) {
                this.showSuspendDateBeforeOpenDateWarning = true;
                this.showOpenDateAfterSuspendDateWarning = true;
            }
            else {
                this.showSuspendDateBeforeOpenDateWarning = false;
                this.showOpenDateAfterSuspendDateWarning = false;
            }
        }
    };
    LocationEditComponent.prototype.saveLocation = function () {
        var _this = this;
        var id = this.mylocation.Id;
        var saveModel = {};
        Object.assign(saveModel, this.mylocation);
        //Don't send 'Location Address' for 'Campaign Shipping Address' field
        if (saveModel.AlternateAddresses &&
            saveModel.AlternateAddresses.length > 0 &&
            saveModel.AlternateAddresses[0].Id === 0) {
            saveModel.AlternateAddresses = [];
        }
        if (id) {
            var promises = [];
            if (saveModel.IsClosed) {
                promises.push(this.dataService.closeLocation(id));
            }
            else if (this.originalIsClosed) {
                promises.push(this.dataService.reopenLocation(id));
            }
            promises.push(this.dataService.put(saveModel, this.files));
            return Promise.all(promises).then(function () {
                _this.locationForm.form.markAsPristine();
            });
        }
        else {
            return this.dataService.post(saveModel, this.files, undefined).then(function (response) {
                _this.locationForm.form.markAsPristine();
                _this.mylocation = response;
            });
        }
    };
    LocationEditComponent.prototype.deleteLocationPrompt = function () {
        var _this = this;
        if (this.loaded && this.canDeleteErrorList.length > 0) {
            this.ipsMessage.error("Unable to delete! Record is begin used in the following Market Groups:\r\n" + this.canDeleteErrorList.join("\r\n"));
            return null;
        }
        var translated = this.translateService.instant("PROMPT_DELETE_BODY");
        translated = IpsString.Format(translated, this.mylocation.Name);
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: this.deleteLocation, progressMessage: "DELETING" }).then(function (result) {
            if (result) {
                _this.$state.go("main.storeProfileLocation.search");
            }
        })
            .catch(function () {
            // rejection
        });
    };
    // Validates the entered address for the location.
    LocationEditComponent.prototype.validateAddressForLocationSave = function (redirect) {
        var _this = this;
        this.addressService.validateAddress(this.addressService.addressModeltoShippingAddress(this.mylocation.Addresses[0])).then(function (validation) {
            if (!validation.Valid) {
                _this.ipsModal.displayTemplateScrollable(SuggestedAddressModalComponent, {
                    suggestedAddresses: validation.Addresses ? validation.Addresses : [],
                    invalidAddress: _this.addressService.addressModeltoShippingAddress(_this.mylocation.Addresses[0])
                }).then(function (response) {
                    var newAddress = _this.addressService.updateAddressModelFromShippingAddress(_this.mylocation.Addresses[0], response, _this.countryList);
                    Object.assign(_this.mylocation.Addresses[0], newAddress);
                    _this.saveLocationPromptInternal(redirect);
                }).catch(function (canceled) { });
            }
            else {
                _this.mylocation.Addresses[0].ValidationStatus = "Validated";
                _this.saveLocationPrompt(redirect);
            }
        }, function (reason) {
            return _this.ipsMessage.confirm({
                body: "FAILED_ADDRESS_VALIDATION_PROMPT",
                buttons: "OK"
            }).then(function (result) {
                // nothing to do, user has been warned and closed the dialog
            }).catch(function (canceled) {
                // nothing to do, user has been warned and closed the dialog
            });
        });
    };
    LocationEditComponent.prototype.saveLocationPrompt = function (redirect) {
        var _this = this;
        if (this.mylocation.IsClosed && !this.originalIsClosed) {
            return this.ipsMessage.confirm({
                body: "PROMPT_CLOSE_LOCATION",
                title: this.closeLocation,
                ok: "YES_CLOSE_LOC",
                cancel: "NO_KEEP_ACTIVE",
                darkHeader: true
            }).then(function (result) {
                if (result) {
                    _this.saveLocationPromptInternal(redirect);
                }
            })
                .catch(function (canceled) {
                _this.mylocation.IsClosed = false;
                _this.changeCloseLocation();
            });
        }
        else {
            return this.saveLocationPromptInternal(redirect);
        }
    };
    LocationEditComponent.prototype.saveLocationPromptInternal = function (redirect) {
        var _this = this;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: function () { return _this.saveLocation(); },
            progressMessage: "SAVING" }).then(function (result) {
            if (result) {
                if (redirect) {
                    _this.$state.go("main.storeProfileLocation.search");
                }
                else {
                    _this.$state.go("main.storeProfileLocation.edit", { id: _this.mylocation.Id });
                }
            }
        });
    };
    LocationEditComponent.prototype.changeOpenDate = function () {
        this.showWarnings();
        this.getLocationStatus();
    };
    LocationEditComponent.prototype.changeSuspendDate = function () {
        this.showWarnings();
        this.getLocationStatus();
    };
    /**
    * Property used for end-store date validation. It must be:
    * Parsable (handled automatically)
    * On or after start date
    */
    LocationEditComponent.prototype.validateDateFormat = function (dateField) {
        var isValid = true;
        var message = "";
        if (dateField === undefined) {
            isValid = false;
            message = IpsString.Format(this.ErrorStrings["CALENDAR_DATE_FORMAT"], this.dateFormat);
        }
        return { isValid: isValid, message: message };
    };
    LocationEditComponent.prototype.populateThumbnail = function (locationModel) {
        if (locationModel.Media) {
            this.fullSizeImage =
                locationModel.Media.ResourceUri + "?BusinessIdentity=" + this.activeProfileService.businessIdentity + "&idToken=" + this.authService.getIdToken();
            this.imageEdit.thumbnail = this.fullSizeImage; //this.fullSizeImage.replace("Original", "Thumbnail");
        }
        else {
            this.fullSizeImage = undefined;
            this.imageEdit.thumbnail = undefined;
        }
        //Set text of image button
        this.imageEdit.selectedImage = this.imageEdit.thumbnail !== undefined;
    };
    LocationEditComponent.prototype.onSelectedImageChanged = function (files) {
        this.files = files;
        if (!this.files) {
            // clear the thumbnail
            this.imageEdit.thumbnail = undefined;
            this.imageEdit.selectedImage = false;
            this.mylocation.MasterMediaId = "00000000-0000-0000-0000-000000000000";
            this.files = undefined;
        }
        this.locationForm.form.markAsDirty();
    };
    LocationEditComponent.prototype.getLocationStatus = function () {
        this.mylocation.LocationStatusInfo = this.dataService.getStatusInfo(this.mylocation);
        this.showLocationStatus = true;
    };
    LocationEditComponent.prototype.changeCloseLocation = function () {
        if (this.mylocation.IsClosed) {
            this.ipsMessage.messageBox({
                title: this.closeLocation,
                body: "CLOSING_LOCATION_INFO",
                darkHeader: true
            }, { size: "sm" });
            this.mylocation.ClosedDate = new Date();
            this.closeLocationText = this.locationClosed;
        }
        else {
            this.mylocation.ClosedDate = null;
            this.closeLocationText = this.closeLocation;
        }
        this.getLocationStatus();
    };
    LocationEditComponent.prototype.campaignShippingAddressChange = function () {
        var _this = this;
        return this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
            resolve: { search: "AlternateAddress" }
        }, { backdrop: "static", centered: true })
            .then(function (response) {
            _this.alternateAddress = response.item;
            _this.alternateAddress.Label = response.item.Id > 0 ? response.item.Name : _this.translateService.instant("LOCATION_ADDRESS");
            _this.mylocation.AlternateAddresses = [];
            _this.mylocation.AlternateAddresses.push(response.item);
            _this.alternateAddressField.control.markAsDirty();
        }, function () { });
    };
    LocationEditComponent.prototype.onAlternateAddressChange = function (alternateAddress) {
        if (typeof alternateAddress !== "string") {
            this.alternateAddress.Label = alternateAddress.AddressIdentifier + " - " + alternateAddress.Name;
            this.mylocation.AlternateAddresses = [];
            this.mylocation.AlternateAddresses.push(alternateAddress);
            this.alternateAddressField.control.markAsDirty();
        }
        else {
            this.mylocation.AlternateAddresses = [];
            this.alternateAddressField.control.markAsPristine();
        }
    };
    return LocationEditComponent;
}(TemplateFormBase));
export { LocationEditComponent };
