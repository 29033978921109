import { Component, OnInit } from "@angular/core";
import { ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { HolderVersionService } from "../../service/holder-version.service";
import { LocationModel } from "../../model/LocationModel";
import { ListSearchComponent, ResultDetailComponent, TranslatedTexts } from "imagine-ui-ng-list-search";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString, StringBuilder } from "typescript-string-operations";


@Component({
  selector: "app-holder-version-search",
  templateUrl: "./holder-version-search.component.html",
  styleUrls: ["./holder-version-search.component.scss"]
})
export class HolderVersionSearchComponent implements OnInit {
    public holderversion: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<{}>;
    public createNewTooltip: string;
    public textMaxLength = "MAX_LENGTH_ERROR";
    public searchLabel: string;
    public searchLabelPlural: string;
    public translated = false;

    constructor(private holderversionService: HolderVersionService, private listSearchHelper: ListSearchHelper, private translateService: TranslateService) {
        this.holderversion = this.listSearchHelper.getListSearchHelper(holderversionService);
        this.busy = this.holderversion.busy;

        holderversionService.head().then((data) => {
            this.holderversion.TotalRecords = data;
        });
    }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
        this.getListData("");
    }
    private translateText() {
        this.translateService.get(["VERSION_INFO", "CREATE_HOLDER_VERSION_INFO", "MAX_LENGTH_ERROR"]).subscribe((res: [string]) => {
            this.createNewTooltip = res["CREATE_HOLDER_VERSION_INFO"];
            this.textMaxLength = res["MAX_LENGTH_ERROR"];
            this.searchLabel = res["VERSION_INFO"];
            this.searchLabelPlural = res["VERSION_INFO"];
            this.translated = true;
        });
    }
    public getListData(search: string) {
        if (this.busy) {
            return;
        }

        let searchInfo: SearchInfo = {
            searchText: search === undefined ? "" : search
        };
        this.promise = this.holderversion.searchHelper(searchInfo);
    }
    public validationMaxLength(value: number) {
        return IpsString.Format(this.textMaxLength, value);
    }
}
