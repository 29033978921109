import { OnInit, TemplateRef } from "@angular/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { ListSearchHelper, SearchInfo, FileDownloadService } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { TranslateService } from "@ngx-translate/core";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { CampaignService, CampaignUpDownAnalyticsService } from "../../index";
import { CampaignUpdownAnalyticsModalComponent } from "../campaign-updown-analytics-modal/campaign-updown-analytics-modal.component";
import { SortHelperService } from "../../../shared/service/sort-helper.service";
var AnalyticsComponent = /** @class */ (function () {
    function AnalyticsComponent(campaignService, campaignUpDownAnalyticService, ipsModal, translateService, ipsMessage, listSearchHelper, downloadService) {
        this.campaignService = campaignService;
        this.campaignUpDownAnalyticService = campaignUpDownAnalyticService;
        this.ipsModal = ipsModal;
        this.translateService = translateService;
        this.ipsMessage = ipsMessage;
        this.listSearchHelper = listSearchHelper;
        this.downloadService = downloadService;
        this.screenReady = false;
        this.loading = true;
        this.welcomeMessage = "CAMPAIGN_ANALYTICS_WELCOME_MESSAGE";
        this.withDateRangeMessage = "CAMPAIGN_ANALYTICS_DATERANGE_MESSAGE";
        this.withDateRangeTwentyMessage = "CAMPAIGN_ANALYTICS_DATERANGEBYTWENTY_MESSAGE";
        this.withLocationAndDateMessage = "CAMPAIGN_ANALYTICS_LOCATION_DATE_MESSAGE";
        this.information = "INFORMATION_UPPERCASE";
        this.printedItemList = [];
        this.pluralMapping = {};
        this.TranslateStrings = {
            "ALL_UPPERCASE": "",
            "INFORMATION_UPPERCASE": "",
            "CAMPAIGN_ANALYTICS_WELCOME_MESSAGE": "",
            "CAMPAIGN_ANALYTICS_DATERANGE_MESSAGE": "",
            "CAMPAIGN_ANALYTICS_DATERANGEBYTWENTY_MESSAGE": "",
            "CAMPAIGN_ANALYTICS_LOCATION_DATE_MESSAGE": "",
            "LOCATION": "",
            "LOCATIONS": ""
        };
        this.dateRangeModel = [];
        this.DateRangeTooBig = false;
        this.processing = false;
        this.setInitialValues();
        this.printSortHelper = new SortHelperService();
    }
    AnalyticsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.TranslateText();
        this.translateService.get("ALL").subscribe(function () { return _this.TranslateText(); });
        this.campaignUpDownAnalytics = this.listSearchHelper.getListSearchHelper(this.campaignUpDownAnalyticService);
        this.campaignUpDownAnalyticService.headByAnalyticsCount().then(function (total) {
            _this.campaignUpDownAnalytics.TotalRecords = total;
        });
        this.getCampaignUpDownStats({ search: "" });
    };
    Object.defineProperty(AnalyticsComponent.prototype, "sortedPrintRecords", {
        get: function () {
            var returnValue = this.printSortHelper.applySort(this.printedItemList);
            return returnValue;
        },
        enumerable: true,
        configurable: true
    });
    AnalyticsComponent.prototype.TranslateText = function () {
        for (var _i = 0, _a = Object.keys(this.TranslateStrings); _i < _a.length; _i++) {
            var key = _a[_i];
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
        this.selectedLocation = { Id: 0, LocationIdentifier: "", Name: "", Label: this.TranslateStrings["ALL_UPPERCASE"] };
        this.information = this.TranslateStrings["INFORMATION_UPPERCASE"];
        this.welcomeMessage = this.TranslateStrings["CAMPAIGN_ANALYTICS_WELCOME_MESSAGE"];
        this.withDateRangeMessage = this.TranslateStrings["CAMPAIGN_ANALYTICS_DATERANGE_MESSAGE"];
        this.withDateRangeTwentyMessage = this.TranslateStrings["CAMPAIGN_ANALYTICS_DATERANGEBYTWENTY_MESSAGE"];
        this.withLocationAndDateMessage = this.TranslateStrings["CAMPAIGN_ANALYTICS_LOCATION_DATE_MESSAGE"];
        this.pluralMapping["=1"] = this.translateService.instant("LOCATION");
        this.pluralMapping["other"] = this.translateService.instant("LOCATIONS");
    };
    AnalyticsComponent.prototype.setInitialValues = function () {
        var month = new Date().getMonth();
        var year = new Date().getFullYear();
        var firstDay = new Date(year, month, 1);
        firstDay.setHours(0, 0, 0, 0); // set to midnight
        var lastDay = new Date(year, month + 1, 0);
        lastDay.setHours(0, 0, 0, 0); //set to midnight
        this.fromDateModel = firstDay;
        this.toDateModel = lastDay;
        this.dateRangeModel.push(this.fromDateModel);
        this.dateRangeModel.push(this.toDateModel);
        this.DateRangeTooBig = false;
    };
    AnalyticsComponent.prototype.getCampaignUpDownStats = function (search) {
        var _this = this;
        var route = "Campaign/GetUpDownReportAnalytics";
        if (this.DateRangeTooBig) {
            this.loading = false;
            return;
        }
        var searchTerm = "";
        if (search !== undefined && search.search !== undefined) {
            searchTerm = search.search;
        }
        this.query = searchTerm;
        this.searchText = search.search;
        var searchInfo = {
            searchText: searchTerm,
            additionalQueryParams: []
        };
        if (this.selectedLocation && this.selectedLocation.Id > 0) {
            searchInfo.additionalQueryParams.push({ param: "LocationId", paramValue: this.selectedLocation.Id.toString() });
        }
        if (this.fromDateModel && this.toDateModel) {
            searchInfo.additionalQueryParams.push({ param: "StartDate", paramValue: encodeURIComponent(this.fromDateModel.toUTCString()) }, { param: "EndDate", paramValue: this.toDateModel.toUTCString() });
        }
        this.promise = this.campaignUpDownAnalytics.searchHelper(searchInfo, undefined, route).then(function (res) {
            _this.printedItemList = _this.campaignUpDownAnalytics.resultList;
            _this.screenReady = true;
            _this.loading = false;
        }).catch(function (err) {
            _this.ipsMessage.error(_this.translateService.instant("UNABLE_CAMPAIGN_UPDOWN_ANALYTICS_DATA"));
        });
    };
    AnalyticsComponent.prototype.changeLocation = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "location" } })
            .then(function (response) {
            _this.selectedLocation = response.item;
            _this.loading = true;
            _this.getCampaignUpDownStats({ search: _this.searchText });
        }, function () { }); // Rejected
    };
    AnalyticsComponent.prototype.viewUserModal = function (item) {
        return this.ipsModal.displayTemplateScrollable(CampaignUpdownAnalyticsModalComponent, { resolve: { campaignUpDownData: item, startDate: this.fromDateModel, endDate: this.toDateModel } }, { backdrop: "static", centered: true });
    };
    AnalyticsComponent.prototype.changeDateRange = function (event) {
        if (event && event.startDate && event.endDate) {
            this.fromDateModel = event.startDate;
            this.toDateModel = event.endDate;
            this.DateRangeTooBig = event.rangeTooBig;
            this.loading = true;
            this.getCampaignUpDownStats({ search: this.searchText });
        }
        else {
            this.fromDateModel = null;
            this.toDateModel = null;
            this.DateRangeTooBig = false;
        }
    };
    AnalyticsComponent.prototype.download = function () {
        this.reportDownloadClickHandler();
    };
    AnalyticsComponent.prototype.reportDownloadClickHandler = function () {
        if (this.DateRangeTooBig) {
            this.ipsMessage.error(this.translateService.instant("UNABLE_CAMPAIGN_UPDOWN_DOWNLOAD"));
            return;
        }
        var url = "";
        if (this.fromDateModel && this.toDateModel) {
            url = this.campaignService.urlApiBaseProductPlusVersion + "Campaign/CampaignUpDownAnalytics/Download?startDate=" + encodeURIComponent(this.fromDateModel.toUTCString()) + "&endDate=" + encodeURIComponent(this.toDateModel.toUTCString());
        }
        this.downloadService.PostDownload(url);
    };
    return AnalyticsComponent;
}());
export { AnalyticsComponent };
