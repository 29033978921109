<div class="d-flex justify-content-between align-items-center px-3">
    <h2 class="title" translate>CHECKOUT</h2>
    <a uiSref="main.myproducts" class="text-underline text-hover-primary" translate>CONTINUE_SHOPPING</a>
</div>
<div *ngIf="!screenLoaded" class="row">
    <div class="ips-loading-box">
        <div class="ips-loading-box-text">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw ips-loading-box-icon"></i>
            <br />
            <span translate>LOADING</span>
        </div>
    </div>
</div>
<form *ngIf="screenLoaded" id="ramiInfoInput" #checkoutForm="ngForm" class="form-horizontal"
      name="checkoutForm" novalidate ipsUnsavedWarningForm role="form">
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card ips-shadow mb-3">
                <div class="card-header d-flex">
                    <span translate>ORDERED_BY</span>
                </div>
                <div class="card-body">
                    <p>
                        <span class="view-info-label" translate>NAME</span>
                        <span>{{CurrentCart.OwnerDetails.FirstName}} {{CurrentCart.OwnerDetails.LastName}}</span>
                    </p>
                    <p>
                        <span class="view-info-label" translate>EMAIL</span>
                        <span>{{CurrentCart.OwnerDetails.Email}}</span>
                    </p>
                    <p>
                        <span class="view-info-label" translate>PHONE</span>
                        <span>{{CurrentCart.OwnerDetails.Phone}}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card ips-shadow mb-3">
                <div class="card-header d-flex">
                    <span translate>SUMMARY</span>
                </div>
                <div class="card-body">
                    <p>
                        <span class="view-info-label" translate>ORDER_QTY</span>
                        <span>{{orderQuantity}}</span>
                    </p>
                    <p *ngIf="costEstimated">
                        <span class="view-info-label" translate>ESTIMATED_SUBTOTAL</span>
                        <span>{{orderSubTotal | currency}}</span>
                    </p>
                    <p *ngIf="!costEstimated">
                        <span class="view-info-label" translate>ESTIMATED_SUBTOTAL</span>
                        <span>Calculating...</span>
                    </p>
                    <p>
                        <span class="view-info-label" translate>PAYMENT_METHOD</span>
                        <span>{{(this.paymentMethod.toLowerCase() === "credit" ? "CREDIT_CARD" : "INVOICE") | translate}}</span>
                    </p>
                    <p>
                        <span class="view-info-label" translate>LOCATIONS</span>
                        <span>{{numberOfLocations}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- new version -->
    <div class="card ips-shadow mb-3 pl-3 pr-3">
        <h3 class="ips-gray-color mb-0" translate>SHIPMENT_ADDRESS</h3>
        <hr />

        <div class="form-group">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <label class=" ml-3 view-info-label required" translate>DELIVER_PRODUCTS_TO</label>
                    <div>
                        <select class="form-control form-control-lg" [(ngModel)]="addressOption" (change)="addressOptionChanged()" name="addressOption">
                            <option *ngFor="let option of addressOptions" [ngValue]="option.value">{{ option.label }}</option>
                        </select>
                        <div class="mt-2 pl-3" *ngIf="addressOption !== 'custom'">
                            <a class="text-info text-underline-hover clicker" (click)="enterNewAddress()" translate><span class="fa fa-plus"></span> ADD_ADDRESS</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <label class="ml-2 view-info-label" translate>SHIPPING_ADDRESS</label>

                    <!-- Displaying address from address book -->
                    <div class="border rounded p-4" *ngIf="isAddressBookEntry(addressOption)">
                        <div class="pull-right">
                            <a class="text-info text-underline-hover clicker" (click)="editAddressBookEntry(addressOption)" translate><span class="fa fa-pencil"></span> EDIT</a>
                        </div>
                        <p>
                            <span *ngIf="addressOption.Address.Attention">
                                {{ 'ATTENTION_TO' | translate}}: {{ addressOption.Address.Attention }}<br />
                            </span>
                            {{ addressOption.Recipient }}<br />
                            {{ addressOption.Address.Line1 }}<br />
                            <span *ngIf="addressOption.Address.Line2">
                                {{ addressOption.Address.Line2 }}<br />
                            </span>
                            {{ addressOption.Address.City }}, {{ addressOption.Address.StateProvince }} {{ addressOption.Address.PostalCode }}
                        </p>
                        <p class="alert alert-info mb-0" translate><span class="fa fa-info-circle text-info"></span> SINGLE_SHIPPING_ADDRESS_MESSAGE</p>
                    </div>

                    <!-- Displaying custom address -->
                    <div class="border rounded p-4" *ngIf="addressOption === 'custom'">
                        <div class="pull-right">
                            <a class="text-info text-underline-hover clicker" (click)="enterNewAddress()" translate><span class="fa fa-pencil"></span> EDIT</a>
                        </div>
                        <p>
                            <span *ngIf="CustomAddress.Attention">
                                {{ 'ATTENTION_TO' | translate}}: {{ CustomAddress.Attention }}<br />
                            </span>
                            {{ CustomAddress.Label }}<br />
                            {{ CustomAddress.Line1 }}<br />
                            <span *ngIf="CustomAddress.Line2">
                                {{ CustomAddress.Line2 }}<br />
                            </span>
                            {{ CustomAddress.City }}, {{ CustomAddress.StateProvince }} {{ CustomAddress.PostalCode }}
                        </p>
                        <p class="alert alert-info mb-0" translate>
                            <span class="fa fa-info-circle text-info"></span> SINGLE_SHIPPING_ADDRESS_MESSAGE
                        </p>
                    </div>

                    <!-- Using location address(es) -->
                    <div class="border rounded p-4" *ngIf="addressOption === 'location'">
                        <p class="alert alert-info">
                            <span class="text-info fa fa-info-circle"></span> {{ 'MULTIPLE_SHIPPING_ADDRESS_MESSAGE' | translate }} <a class="text-info text-underline-hover clicker" (click)="chooseLocation(true)">{{ 'VIEW_LOCATION_ADDRESSES_WITH_COUNT' | translate:{ count: cartLocations.length } }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end new version -->

    <div class="card ips-shadow mb-3 pl-3 pr-3" *ngIf="showDeliverySelection">
        <h3 class="ips-gray-color ml-3 mb-0" translate>DELIVERY</h3>
        <hr />
        <div class="row">
            <div class="form-group col-12 col-lg-6">
                <label class="view-info-label required" for="StartDate" translate>DELIVERY_SERVICE</label>
                <button *ngIf="!selectedShipMethod" class="btn btn-lg btn-default btn-block mb-3"
                        (click)="selectShippingMethod()" [disabled]="estimating || noValidShipMethods">
                    <span translate>SELECT_DELIVERY_OPTION</span>
                    <span *ngIf="estimating" class="fa fa-spinner fa-spin"></span>
                </button>
                <button *ngIf="selectedShipMethod" class="btn btn-lg btn-default btn-block mb-3"
                        (click)="selectShippingMethod()">
                    <span class="selected-ship-method">{{ selectedShipMethod.Value }}</span>
                    <span *ngIf="!shippingEstimated" class="fa fa-spinner fa-spin"></span>
                    <span *ngIf="shippingEstimated">{{ estimatedShipping | currency }} {{ 'ESTIMATED' | translate }}</span>
                </button>
                <div *ngIf="!estimating && noValidShipMethods">
                    <p class="alert alert-danger" translate>
                        <span class="text-error fa fa-exclamation-triangle"></span> NO_ELIGIBLE_SHIP_METHODS
                    </p>
                </div>
            </div>
            <div class="form-group col-12 col-lg-6" *ngIf="showNeedByDate">
                <label class="view-info-label" for="StartDate" translate>Need By Date</label>
                <div class="input-group ips-input-group-icon">
                    <input type="text" class="form-control form-control-lg input-full"
                           placeholder="{{ 'NA' | translate }}" #d="bsDatepicker" bsDatepicker
                           [bsConfig]="needByDateOptions" (bsValueChange)="changeNeedByDate($event)"
                           name="ShipByDate" triggers="" />
                    <!-- <input type="text" name="InStoreDate" id="InStoreDate"
            class="form-control form-control-lg input-full"
            formControlName="NeedByDate"
            formControlName="InStoreDate"
            placeholder="{{ 'ENTER_INSTORE_DATE' | translate }}"
            #d="bsDatepicker" bsDatepicker [bsConfig]="inStoreDateOptions"
            (bsValueChange)="changeInStoreDate($event)" triggers="" /> -->
                    <!-- NeedByDate -->
                    <span class="input-group-append">
                        <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="card ips-shadow mb-3 pl-3 pr-3">
        <h3 class="ips-gray-color ml-3 mb-0" translate>BILLING</h3>
        <hr />
        <div class="form-group" *ngIf="!poNumberSettings.EnableList">
            <label class="view-info-label" for="Notes" translate>PO_NUMBER</label>
            <input type="text" class="form-control form-control-lg input-full"
                   [(ngModel)]="poNumber" placeholder="{{ 'ENTER_PO_NUMBER' | translate }}"
                   name="PONumber" />
        </div>
        <div class="form-group" *ngIf="poNumberSettings.EnableList">
            <label class="view-info-label required" for="Notes" translate>PO_NUMBER</label>
            <select class="form-control form-control-lg" name="PONumber" [(ngModel)]="poNumber">
                <option *ngIf="!poNumber" value="" selected> -- Choose One --</option>
                <option *ngFor="let poNumber of poNumbers" [value]="poNumber">
                    {{poNumber}}
                </option>
            </select>
        </div>
        <div class="form-group" *ngIf="showReferenceNumber">
            <label class="view-info-label" for="ReferenceNumber" translate>REFERENCE_NUMBER</label>
            <input type="text" class="form-control form-control-lg input-full"
                   [(ngModel)]="referenceNumber" placeholder="{{ 'ENTER_REFERENCE_NUMBER' | translate }}"
                   name="ReferenceNumber" />
        </div>
        <div class="form-group" *ngIf="showOrderReason">
            <label class="required" translate>ORDER_REASON</label>
            <select class="form-control form-control-lg"
                    [(ngModel)]="selectedOrderReason" name="SelectedOrderReason">
                <option *ngIf="!selectedOrderReason" value="">
                    {{ 'SELECT_ORDER_REASON' | translate }}
                </option>
                <option *ngFor="let reason of orderReasons" [value]="reason">
                    {{reason}}
                </option>
            </select>
        </div>
        <div class="form-group" *ngIf="showComments">
            <label class="view-info-label" for="Comments">Comments</label>
            <textarea class="form-control form-control-lg" name="Comments" id="Comments" rows="3" [(ngModel)]="comments"></textarea>
        </div>
    </div>
    <div class="card ips-shadow mb-3">
        <div class="card-header d-flex">
            <span translate>ESTIMATED_COST</span>
        </div>
        <div class="card-body">
            <div *ngIf="!costEstimated" class="row">
                <div class="ips-loading-box">
                    <div class="ips-loading-box-text">
                        <i class="fa fa-spinner fa-pulse fa-3x fa-fw ips-loading-box-icon"></i>
                        <br />
                        <span translate>LOADING</span>
                    </div>
                </div>
            </div>
            <div *ngIf="costEstimated" class="row">
                <div class="col-12 col-md-6">
                    <p>
                        <span class="view-info-label" translate>ESTIMATED_SUBTOTAL</span>
                        <span class="slightly-larger">{{orderSubTotal | currency}}</span>
                    </p>
                    <p>
                        <span class="view-info-label" translate>ESTIMATED_SHIPPING</span>
                        <span class="slightly-larger">{{estimatedShipping | currency}}</span>
                    </p>
                    <p>
                        <span class="view-info-label" translate>ESTIMATED_TAX</span>
                        <span class="slightly-larger">{{estimatedTax | currency}}</span>
                    </p>
                    <p>
                        <span class="view-info-label" translate>ESTIMATED_HANDLING</span>
                        <span class="slightly-larger">{{estimatedHandling | currency}}</span>
                    </p>
                    <p>
                        <span class="view-info-label" translate>ESTIMATED_TOTAL</span>
                        <span class="slightly-larger">{{orderTotal | currency}}</span>
                    </p>
                </div>
                <div class="col-12 col-md-6 d-flex align-items-end">
                    <!-- Paying by invoice -->
                    <button *ngIf="!showPayByCreditCard"
                            class="btn btn-lg btn-default btn-block mb-3"
                            [disabled]="!formValid"
                            (click)="placeOrderPrompt()">
                        <span translate>PLACE_ORDER</span>
                    </button>
                    <!-- Paying by credit card-->
                    <button *ngIf="showPayByCreditCard"
                            class="btn btn-lg btn-default btn-block mb-3"
                            (click)="submit()" [disabled]="!formValid"
                            translate>
                        PAY_BY_CREDIT_CARD
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
<form method="post" #paypalForm name="paypalForm" ngNoForm action="{{payflowUrl}}" target="_self">
    <input type="hidden" name="SECURETOKEN" value="{{token}}">
    <input type="hidden" name="SECURETOKENID" value="{{tokenId}}">
</form>

