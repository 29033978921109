<div [formGroup]="market">
    <div class="input-group ips-textarea">
        <ng-template #customItemTemplate let-model="item" let-index="index">
            <h5>{{model.DisplayNameWithPrefix}}</h5>
        </ng-template>
        <textarea formControlName="MarketName"
                  [typeahead]="marketSource"
                  (typeaheadOnSelect)="typeaheadOnSelect($event, market)"
                  [typeaheadOptionsLimit]="20"
                  typeaheadOptionField="name"
                  [typeaheadItemTemplate]="customItemTemplate"
                  [typeaheadScrollable]="true"
                  name="{{market.get('InputName').value}}"
                  class="form-control form-control-lg input-2-icons campaign-market-cancel-warning"
                  (change) ="changeMarketPrefix()"
                  [ngClass]="{'pl-2': !market.get('MarketName').invalid}" rows="1" ipsAutoGrow [appInputPrefix]="marketPrefix"></textarea>
        <div class="input-group-append" ngbDropdown placement="bottom-right">
            <span class="input-group-text" *ngIf="hasPendingLocations(market)">
                <button ngbTooltip="{{getPendingLocationWarningString(market.value)}}" placement="bottom"><i class="fa fa-exclamation-triangle text-warning"></i></button>
            </span>
            <span class="input-group-text" [ngClass]="{'invalid': market.get('MarketName').invalid && (market.get('MarketName').dirty || market.get('MarketName').touched) }">
                <button type="button" class="btn btn-default ips-btn-remove" (click)="removeMarket(marketIndex, market)"><i class="fa fa-remove fa-lg"></i></button>
            </span>
            <ng-container *ngIf="LinkedProfileMode">
                <button type="button" class="btn btn-default last" ngbDropdownToggle><i class="fa fa-search fa-lg pl-1 pr-1"></i></button>
                <div ngbDropdownMenu>
                    <button class="dropdown-item clicker px-3" ngbDropdownItem translate (click)="searchMarket(market, 'LocationGroup')">
                        <span class="ips-small-icon locationGroupsImg mr-3"></span>LOCATION_GROUP
                    </button>
                    <button class="dropdown-item clicker px-3" ngbDropdownItem translate (click)="searchMarket(market, 'FixtureGroup')">
                        <span class="ips-small-icon fixtureGroupImg mr-3"></span>FIXTURE_GROUP
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="!LinkedProfileMode">
                <button type="button" class="btn btn-default last" ngbDropdownToggle><i class="fa fa-search fa-lg pl-1 pr-1"></i></button>
                <div ngbDropdownMenu>
                    <button class="dropdown-item clicker px-3 text-underline-hover font-weight-bold" ngbDropdownItem translate (click)="searchMarket(market, 'AllExceptClosed')">
                        ALL_EXCEPT_CLOSED
                    </button>
                    <button class="dropdown-item clicker px-3 text-underline-hover" ngbDropdownItem translate (click)="searchMarket(market, 'Location')">
                        <span class="ips-small-icon locationsImg mr-3"></span>LOCATION
                    </button>
                    <button class="dropdown-item clicker px-3 text-underline-hover" ngbDropdownItem translate (click)="searchMarket(market, 'LocationSubGroup')">
                        <span class="ips-small-icon locationGroupsImg mr-3"></span>LOCATION_GROUP
                    </button>
                    <button class="dropdown-item clicker px-3 text-underline-hover" ngbDropdownItem translate (click)="searchMarket(market, 'Fixture')">
                        <span class="ips-small-icon fixturesImg mr-3"></span>FIXTURE
                    </button>
                    <button class="dropdown-item clicker px-3 text-underline-hover" ngbDropdownItem translate (click)="searchMarket(market, 'FixtureGroup')">
                        <span class="ips-small-icon fixtureGroupImg mr-3"></span>FIXTURE_GROUP
                    </button>
                    <button class="dropdown-item clicker px-3 text-underline-hover" ngbDropdownItem translate (click)="searchMarket(market, 'MarketGroup')">
                        <span class="ips-small-icon locationSearchImg mr-3"></span>LOCATION_SEARCH
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
