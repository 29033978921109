<div class="col-md-12 col-lg-8">
    <div class="row">
        <section class="col-12" *ngIf="loaded">
            <h3 class="title">
                <span class="header-label-imgn" translate>VIEW_LOCATION_GROUP</span>
                <a class="ml-2" uiSref="main.storeProfileLocationGroup.edit" [uiParams]="{id: myLocationGroup.Id}" ngbTooltip="{{'EDIT' | translate}} {{myLocationGroup.Name}}" placement="bottom" ipsPermission="main.storeProfileLocationGroup.edit"><span class="fa fa fa-pencil editIcon"></span></a>
            </h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:'{{'ACCESSING_LOCATION_GROUP_INFO' | translate}}'}">
        <div class="row" *ngIf="loaded">
            <div class="viewProfileInfo col-12">
                <div class="form-group">
                    <div class="card">
                        <div class="card-header view-info-header">
                            <h5 class="location-group-title">{{myLocationGroup.Name}}</h5>
                        </div>
                        <div class="card-body">
                            <div class="d-flex justify-content-center mb-3" *ngIf="myLocationGroup.FullImage">
                                <ips-image-view class="ips-list-image-center ips-list-image" [imgThumbUrl]="myLocationGroup.FullImage" [imgFullUrl]="myLocationGroup.FullImage" hideLabel="true" noImageText="NO_IMAGE"></ips-image-view>
                            </div>

                            <ul class="list-group long-text-wrap">
                                <li class="list-group-item">
                                    <div class="view-info-label" translate>OBSOLETE</div>
                                    <div class="row view-info-format-imgn ml-0">
                                        <div class="location-group-rule-icon mr-3"></div>
                                        <div>{{myLocationGroup.IsObsolete ? "Yes" :  "No"}}</div>
                                    </div>
                                </li>
                                <li class="list-group-item" ipsPermission="main.storeProfileLocationGroup.view.campaign">
                                    <div class="view-info-label" translate>CAMPAIGN_LOCATION_FEATURE</div>
                                    <div class="row view-info-format-imgn ml-0">
                                        <div class="location-group-rule-icon mr-3"></div>
                                        <div>{{myLocationGroup.IsCampaignLocationFeature ? "Yes" :  "No"}}</div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="view-info-label" translate>OPTION_TYPE</div>
                                    <div class="row  view-info-format-imgn ml-0">
                                        <div class="location-group-rule-icon mr-3" [ngClass]="myLocationGroup.optionTypeClass"></div>
                                        <div>{{myLocationGroup.optionTypeText}}</div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="view-info-label" translate>ASSIGNMENT_RULE</div>
                                    <div class="row view-info-format-imgn ml-0">
                                        <div class="location-group-rule-icon mr-3" [ngClass]="myLocationGroup.ruleClass"></div>
                                        <div>{{myLocationGroup.ruleText}}</div>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="view-info-label" translate>NOTES</div>
                                    <div class="view-info-format-imgn">{{myLocationGroup.Notes}}</div>
                                </li>
                            </ul>
                            <div class="row mt-3">
                                <div class="col ml-3"><label class="view-info-label" translate>OPTIONS</label></div>
                            </div>
                            <ul class="list-group long-text-wrap">
                                <li class="list-group-item pt-3">
                                    <!--PendingAssignment count-->
                                    <div class="row mb-3" *ngIf="myLocationGroup.PendingAssignmentCount > 0">
                                        <div class="ips-w-100 mr-3 pl-2"><button class="btn badge-pill ips-pill ips-pill-pending" (click)="showLocations(myLocationGroup.Id, 0)">{{myLocationGroup.PendingAssignmentCount | number }}</button></div>
                                        <div class="col pt-1 pl-0 location-group-pending-assignment-label" translate>PENDING_ASSIGNMENT</div>
                                    </div>
                                    <div class="mb-3" *ngFor="let subGroup of myLocationGroup.SubGroups; let i = index;">
                                        <hr *ngIf="i > 0 || myLocationGroup.PendingAssignmentCount > 0" />
                                        <div class="row">
                                            <div class="ips-w-100 mr-3 pl-2"><button class="btn badge-pill ips-pill" (click)="showLocations(myLocationGroup.Id, subGroup.Id)">{{subGroup.LocationCount | number}}</button></div>
                                            <div class="col pt-1 pl-0">{{subGroup.Name}}</div>
                                        </div>
                                        <div class="row" *ngIf="subGroup.FullImage">
                                            <div class="ips-offset-100 mt-2  mr-3"></div>
                                            <div class="col mt-2 px-0 ips-list-image">
                                                <ips-image-view [imgThumbUrl]="subGroup.FullImage" [imgFullUrl]="subGroup.FullImage" hideLabel="true" noImageText="NO_IMAGE"></ips-image-view>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_LOCATION_GROUP_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>

