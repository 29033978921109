import { Component, OnInit } from "@angular/core";
import { StateService } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { ProfileService, IpsAnalyticsService } from "imagine-ui-ng-quick-start";
import { IpsLanguageService } from "imagine-ui-ng-translation";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { VersionCheckService } from "./version-check/version-check.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: []
})
export class AppComponent implements OnInit {
    title = "app";

    constructor(
        private $state: StateService,
        private translate: TranslateService,
        private IpsLanguage: IpsLanguageService,
        private profileService: ProfileService,
        private ipsAnalytics: IpsAnalyticsService,
        angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics, // Importing here is necessary to start tracking page views.
        private versionCheckService: VersionCheckService
    ) {
        translate.setDefaultLang("en");

        IpsLanguage.SetToBrowser();

        let username = localStorage.getItem("username");
        if (username) {
            //user identified, persist user here.  Even if captured during login, capturing here will ensure assignment to session upon screen refresh where re-login is not required.
            this.ipsAnalytics.setUserName(username);
        }
    }

    ngOnInit() {
        // Start the version check, which will look for newer code on the server compared to what we are using.
        this.versionCheckService.initVersionCheck("/assets/version.json", 10 * 60 * 1000);
    }
}
