import { Component, OnInit, Input, OnChanges, ViewChild, SimpleChanges } from "@angular/core";
import { AddressModel, ActiveProfileService, CountryModel, RegionModel } from "imagine-ui-ng-core";
import { CountryService, RegionService } from "imagine-ui-ng-quick-start";
import { AsYouType, CountryCode, formatNumber, parseNumber, ParsedNumber } from "libphonenumber-js";
import { String as IpsString } from "typescript-string-operations";
import { TranslateService } from "@ngx-translate/core";

interface RegionDescriptors {
    Name: string;
    Children: RegionModel[];
}

interface AddressUiModel extends AddressModel {
    DisplayPhone: string;
    DisplayFax: string;
}

@Component({
    selector: "app-address-edit",
    templateUrl: "./address-edit.component.html",
    styleUrls: ["./address-edit.component.scss"]
})
export class AddressEditComponent implements OnInit, OnChanges {
    public countryPromise: Promise<CountryModel[]>;
    public regionsPromise: Promise<RegionModel[]>;
    public regionDescriptors: Map<string, RegionModel[]>;
    public regionDescriptorKeys: Promise<string[]>;
    // Used in html template
    public twoCharcountryCode: CountryCode;
    private countryList: CountryModel[] = [];
    private maxLengthMessage: string;
    private invalidPhoneMessage: string;
    public addressInvalidWarning: "ADDRESS_INVALID";
    public information = "INFORMATION_UPPERCASE";
    public addressNotValidated: "ADDRESS_NOT_VALIDATED";

    @Input() address: AddressUiModel;
    @Input() showFax: boolean;
    @Input() showEmail: boolean;

    public errorMessages = {
        "maxlength": (params) => IpsString.Format(this.maxLengthMessage, params.requiredLength),
        "phoneNumber": (params) => this.invalidPhoneMessage
    };

    constructor(private countryService: CountryService,
        private regionService: RegionService,
        private activeProfileService: ActiveProfileService,
        private translateService: TranslateService
    ) {
        this.twoCharcountryCode = "US"; // Default value
    }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.countryPromise = this.countryService.Get();
        this.countryPromise.then(response => {
                this.countryList = response;

                if (this.address) {
                    this.setTwoCharCountryCode(this.address);
                    this.address.BusinessIdentity = this.activeProfileService.businessIdentity;
                }
            }
        );

        //Populate BusID when it doesn't exist
        this.address.BusinessIdentity = this.address.BusinessIdentity || this.activeProfileService.businessIdentity;
    }

    ngOnChanges(changes: SimpleChanges) {
        // Use Display properties as input to the phone control because we will
        // use the actual Phone and Fax properties to store the E.164 formatted
        // phone text
        if (this.address) {
            if (this.address.Phone) {
                this.address.DisplayPhone = this.address.Phone;
            }
            if (this.address.Fax) {
                this.address.DisplayFax = this.address.Fax;
            }
        }

        if (changes.address) {
            const addr = changes.address.currentValue as AddressModel;
            this.loadRegions(addr.Country);
        }
    }

    private translateText() {
        this.translateService.get(["MAX_LENGTH_ERROR", "INVAID_PHONE", "ADDRESS_INVALID", "ADDRESS_NOT_VALIDATED", "INFORMATION_UPPERCASE"]).subscribe((res: [string]) => {
            this.maxLengthMessage = res["MAX_LENGTH_ERROR"];
            this.invalidPhoneMessage = res["INVAID_PHONE"];
            this.addressInvalidWarning = res["ADDRESS_INVALID"];
            this.addressNotValidated = res["ADDRESS_NOT_VALIDATED"];
            this.information = res["INFORMATION_UPPERCASE"];
        });
    }

    private setTwoCharCountryCode(address: AddressModel): void {
        if (address) {
            const val = this.countryList.find(c => c.Iso3166ThreeCharCountryCode === address.Country);
            if (val) {
                this.twoCharcountryCode = val.Iso3166TwoCharCountryCode as CountryCode;
            }
        }
    }

    public loadRegions(countryId: string) {
        if (countryId) {
            this.regionDescriptors = new Map<string, RegionModel[]>();
            this.regionDescriptorKeys = this.regionService.Get(countryId).then((response) => {
                response.forEach((current) => {
                    let value = this.regionDescriptors.get(current.RegionDescriptor);
                    if (!value) {
                        this.regionDescriptors.set(current.RegionDescriptor, [current]);
                    } else {
                        value.push(current);
                    }
                });
                return Array.from(this.regionDescriptors.keys());
            });
        }
    }

    // Called when the country is changed, need to load new regions.
    public countryChanged() {
        if (this.address) {
            this.address.StateProvince = undefined;
            this.setTwoCharCountryCode(this.address);
            this.loadRegions(this.address.Country);
        }
    }

    /**
     * Handler for the save formatted phone number
     * @param phoneNumber - Formatted phone number in the E.164 format
     */
    public phoneChangedEventHandler(phoneNumber: string) {
        this.address.Phone = phoneNumber;
    }

    /**
     * Handler for the save formatted fax number
     * @param phoneNumber - Formatted phone number in the E.164 format
     */
    public faxChangedEventHandler(phoneNumber: string) {
        this.address.Fax = phoneNumber;
    }
}
