<div class="col-md-12 col-lg-8">
    <div class="row">
        <section class="col-12">
            <h3 class="title">
                <span class="header-label-imgn" translate>VIEW_SURVEY</span>
                <a class="ml-2" uiSref="main.survey.edit" [uiParams]="{id: survey.Id}" ngbTooltip="{{'EDIT' | translate}} {{survey.Name}}" placement="bottom" ipsPermission="main.survey.edit"><span class="fa fa fa-pencil editIcon"></span></a>
            </h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_SURVEY_INFO' | translate}}}">
        <div class="row" *ngIf="loaded">
            <div class="col">
                <!--Main Survey data-->
                <div class="form-group">

                    <div class="card">
                        <div class="card-header view-info-header d-flex flex-row">
                            <div class="pr-3">{{survey.Name}}</div><div class="{{surveyStatusInfo.IconClass}}" ngbTooltip="{{surveyStatusInfo.Tooltip | translate}}" placement="bottom"></div>
                        </div>
                        <div class="card-body">
                            <ul class="list-group long-text-wrap">
                                <li class="list-group-item">
                                    <div class="view-info-label" translate>Start/End Date</div>
                                    <div class="view-info-add-margin">{{survey.StartDate | date : "mediumDate" : "+0000"}} - {{survey.EndDate ? (survey.EndDate | date:'mediumDate' : "+0000") : ( 'NA' | translate)}}</div>
                                </li>
                                <li class="list-group-item">
                                    <div class="view-info-label" translate>NOTES</div>
                                    <div class="view-info-format-imgn">{{survey.Notes}}</div>
                                </li>
                                <li class="list-group-item">
                                    <div class="view-info-label" translate>AUDIENCE</div>
                                    <div class="pl-3">
                                        <app-market-view [markets]="survey.Markets" [showLocationModal]="true" (showLocationBalances)="showLocations($event)"></app-market-view>
                                    </div>
                                </li>
                            </ul>

                            <!--Sections-->
                            <div [cgBusy]="promiseSections" cgBusyConfig="{message:{{'ACCESSING_SURVEY_INFO' | translate}}}">
                                <div *ngFor="let section of surveySections">
                                    <app-survey-section-view [section]="section" [locationBalance]="locationBalance"></app-survey-section-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_SURVEY_INFO' | translate }}" [promise]="promise"></ips-error-handler>

</div>
