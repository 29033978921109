<div [formGroup]="itemGroup">
    <div class="input-group ips-textarea">
        <ng-template #customItemTemplate let-model="item" let-index="index">
            <h5>{{model.Name}}</h5>
        </ng-template>
        <textarea formControlName="Name"
                  [typeahead]="typeaheadValues()"
                  (typeaheadOnSelect)="typeaheadOnSelect($event)"
                  [typeaheadOptionsLimit]="20"
                  typeaheadOptionField="Name"
                  [typeaheadItemTemplate]="customItemTemplate"
                  [typeaheadScrollable]="true"
                  class="form-control form-control-lg input-2-icons campaign-market-cancel-warning" rows="1" ipsAutoGrow
                  name="{{itemGroup.get('ControlName').value}}"></textarea>
        <div class="input-group-append">
            <span class="input-group-text" [ngClass]="{'invalid': itemGroup.get('Name').invalid && (itemGroup.get('Name').dirty || itemGroup.get('Name').touched) }">
                <button type="button" class="btn btn-default ips-btn-remove" (click)="removeItem(itemGroupIndex)"><i class="fa fa-remove fa-lg"></i></button>
            </span>
            <button type="button" class="btn btn-default last" (click)="searchItem()"><i class="fa fa-search fa-lg pl-1 pr-1"></i></button>
        </div>
    </div>
</div>
