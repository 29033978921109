var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService } from "imagine-ui-ng-core";
import { Subject } from "rxjs";
var CartService = /** @class */ (function (_super) {
    __extends(CartService, _super);
    function CartService(http, activeProfileService, helperFunctionsService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.helperFunctionsService = helperFunctionsService;
        _this.invalidCartItems = [];
        _this.cartUpdatedSource = new Subject();
        // Observable string streams
        _this.onCartUpdated = _this.cartUpdatedSource.asObservable();
        _this.init("Cart", "Shop", "v1");
        _this.fakeData = {
            Id: 0,
            Owner: "",
            OwnerDetails: null,
            Items: [],
            Locations: [],
            TotalQuantity: 0,
            TotalCost: 0,
            InvalidCartItems: [],
            HasInvalidItems: false
        };
        _this.myCurrentCart = _this.helperFunctionsService.copyObject(_this.fakeData);
        return _this;
    }
    //
    CartService.prototype.head = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.getMyCurrentCart(true).then(function (cartData) {
                resolve(_this.calculateCartQuantityAndCost(cartData).TotalQuantity);
            });
        });
        return promise;
    };
    //
    CartService.prototype.search = function (searchInfo, route) {
        var locationIdParam = searchInfo.additionalQueryParams.find(function (param, index) { return param.param === "LocationId"; });
        var locationIdValue = Number(locationIdParam.paramValue);
        return this.getItemsByLocation(locationIdValue, searchInfo.searchText);
    };
    //
    CartService.prototype.searchByRelation = function (searchInfo, route) {
        return this.search(searchInfo, route);
    };
    //
    CartService.prototype.getFilteredCarts = function (filters) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            // return this.getByRoute(`${this.urlApiBaseProductPlusVersion}Business/${businessId}/InUse`);
            _this.getByRoute(_this.urlApiBasePlusController + "/List")
                .then(function (cartData) {
                for (var i = 0; i < cartData.length; i++) {
                    cartData[i] = _this.calculateCartQuantityAndCost(cartData[i]);
                }
                resolve(cartData);
            }, function (reason) {
                var message = (reason.error ? reason.error.Message : reason.message);
                reject(message);
            });
        });
        return promise;
    };
    //
    CartService.prototype.getMyCart = function () {
        var _this = this;
        this.getMyInvalidCartItems();
        var promise = new Promise(function (resolve, reject) {
            _this.getByRoute("" + _this.urlApiBasePlusController)
                .then(function (cartData) {
                if (cartData.Id) {
                    _this.myCurrentCart = _this.calculateCartQuantityAndCost(cartData);
                }
                else {
                    _this.myCurrentCart = _this.calculateCartQuantityAndCost(_this.helperFunctionsService.copyObject(_this.fakeData));
                }
                _this.broadcastCartUpdate();
                resolve(_this.myCurrentCart);
            }, function (response) {
                _this.myCurrentCart = _this.calculateCartQuantityAndCost(_this.helperFunctionsService.copyObject(_this.fakeData));
                _this.broadcastCartUpdate();
            });
        });
        return promise;
    };
    CartService.prototype.getMyInvalidCartItems = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.getByRoute(_this.urlApiBasePlusController + "/invaliditems")
                .then(function (response) {
                _this.invalidCartItems = response;
                _this.myCurrentCart.InvalidCartItems = _this.invalidCartItems;
                resolve(_this.myCurrentCart.InvalidCartItems);
            }, function (response) {
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    };
    //
    CartService.prototype.getMyCurrentCart = function (forceRefresh) {
        var _this = this;
        forceRefresh = forceRefresh || false;
        if ((this.myCurrentCart.Id === 0) || (forceRefresh === true)) {
            return this.getMyCart();
        }
        else {
            var promise = new Promise(function (resolve, reject) {
                resolve(_this.myCurrentCart);
            });
            return promise;
        }
    };
    //
    CartService.prototype.getItemsByLocation = function (locationId, searchText) {
        var _this = this;
        searchText = searchText || "";
        searchText = searchText.toUpperCase();
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getMyCurrentCart().then(function (cartData) {
                var items = cartData.Items;
                if (searchText) {
                    items = items.filter(function (x) {
                        if (((x.Item.Name) && (x.Item.Name.toUpperCase().indexOf(searchText) !== -1)) ||
                            ((x.Item.Description) && (x.Item.Description.toUpperCase().indexOf(searchText) !== -1)) ||
                            ((x.Item.CustomerItemCode) && (x.Item.CustomerItemCode.toUpperCase().indexOf(searchText) !== -1)) ||
                            ((x.Item.EndDate) && (_this.helperFunctionsService.formattedAsReadableDate(x.Item.EndDate.toString()).indexOf(searchText) !== -1)) ||
                            ((x.Item.UnitPrice) && (x.Item.UnitPrice.toString().toUpperCase().indexOf(searchText) !== -1))) {
                            return true;
                        }
                        return false;
                    });
                }
                items = items.filter(function (x) {
                    if (x.LocationId === locationId) {
                        return true;
                    }
                    return false;
                });
                var returnVal = {
                    ResultList: items,
                    ResultChunkAttributes: {
                        "Index": 0,
                        "TotalRecords": items.length,
                        "RecordCount": items.length
                    }
                };
                resolve(returnVal);
            });
        });
        return returnPromise;
    };
    // Returns the number of unique items (ignoring quantity) in the cart for a given location.
    CartService.prototype.itemsInLocationCart = function (locationId) {
        var items = this.myCurrentCart.Items;
        items = items.filter(function (x) {
            if (x.LocationId === locationId) {
                return true;
            }
            return false;
        });
        return items.length || 0;
    };
    // Returns the the first duplicate (same item id, location id, and order reason) if one
    // exists.  Otherwise return null
    CartService.prototype.findDuplicateCartItem = function (cartItem) {
        var items = this.myCurrentCart.Items.filter(function (x) { return (x.ItemId === cartItem.ItemId) && (x.LocationId === cartItem.LocationId) && (x.OrderReason === cartItem.OrderReason); });
        if (items.length > 0) {
            return items[0];
        }
        return null;
    };
    // Returns the quantity of an item in the cart for a given location.
    CartService.prototype.numberOfItemInLocationCart = function (locationId, itemId) {
        var items = this.myCurrentCart.Items;
        var tempQuantity = 0;
        items = items.filter(function (x) {
            if ((x.LocationId === locationId) && (x.Item) && (x.Item.Id === itemId)) {
                return true;
            }
            return false;
        });
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            tempQuantity = +tempQuantity + +item.Qty;
        }
        return tempQuantity || 0;
    };
    // Determines if an item exists in the current user's cart for a given location.
    CartService.prototype.inCart = function (locationId, itemId) {
        var items = this.myCurrentCart.Items;
        items = items.filter(function (x) {
            if ((x.LocationId === locationId) && (x.Item) && (x.Item.Id === itemId)) {
                return true;
            }
            return false;
        });
        if (items.length > 0) {
            return true;
        }
        return false;
    };
    // Return a copy of the current cart data.
    CartService.prototype.myCurrentCartData = function (locationIds) {
        var cartClone = this.helperFunctionsService.copyObject(this.myCurrentCart);
        if (locationIds) {
            cartClone.Items = cartClone.Items.filter(function (item) { return locationIds.indexOf(item.LocationId) > -1; });
            cartClone = this.calculateCartQuantityAndCost(cartClone);
        }
        return cartClone;
    };
    // This should happen as part of any login, logout, or client switching process to
    // ensure that stale data isn't used.
    CartService.prototype.clearMyCart = function () {
        var _this = this;
        var cartId = this.myCurrentCart.Id || 0;
        var promise = new Promise(function (resolve, reject) {
            _this.deleteByRoute("" + _this.urlApiBasePlusController + cartId)
                .then(function (response) {
                if (response) {
                    _this.myCurrentCart = _this.calculateCartQuantityAndCost({
                        Id: _this.myCurrentCart.Id,
                        Owner: _this.myCurrentCart.Owner,
                        OwnerDetails: null,
                        Items: [],
                        Locations: [],
                        TotalQuantity: 0,
                        TotalCost: 0
                    });
                    _this.broadcastCartUpdate();
                    resolve(response);
                }
                reject("");
            }, function (response) {
                _this.myCurrentCart = null;
                _this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    };
    // STUBBED TODO
    CartService.prototype.addItemToMyCart = function (item) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            var itemId = 0;
            if ((item.Item) && (item.Item.Id)) {
                itemId = item.Item.Id;
            }
            var postData = {
                Id: item.Id || 0,
                LocationId: item.LocationId || 0,
                CartId: _this.myCurrentCart.Id || 0,
                ItemId: itemId,
                DigitalDownload: item.Item.DigitalDownload,
                Qty: item.Qty || 0,
                OrderReason: item.OrderReason || "",
                BusinessIdentity: _this.activeProfileService.businessIdentity || ""
            };
            _this.post(postData, null, _this.urlApiBasePlusController + "Location/Item")
                .then(function (response) {
                response.Item = response.Item || item.Item || null;
                _this.myCurrentCart.Items.push(response);
                _this.myCurrentCart = _this.calculateCartQuantityAndCost(_this.myCurrentCart);
                _this.broadcastCartUpdate();
                resolve(_this.myCurrentCart);
            }, function (response) {
                _this.myCurrentCart = null;
                _this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    };
    CartService.prototype.addToCart = function (item) {
        var _this = this;
        return this.post(item, null, this.urlApiBasePlusController + "Location/Item").then(function (response) {
            _this.getMyCart();
            _this.broadcastCartUpdate();
            return response.Id;
        });
    };
    // STUBBED TODO
    CartService.prototype.updateItemInMyCart = function (item) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            var itemId = 0;
            if ((item.Item) && (item.Item.Id)) {
                itemId = item.Item.Id;
            }
            var postData = {
                Id: item.Id || 0,
                LocationId: item.LocationId || 0,
                CartId: _this.myCurrentCart.Id || 0,
                ItemId: itemId,
                DigitalDownload: item.DigitalDownload,
                Qty: item.Qty || 0,
                OrderReason: item.OrderReason || "",
                BusinessIdentity: _this.activeProfileService.businessIdentity || ""
            };
            _this.put(postData, null, _this.urlApiBasePlusController + "Location/Item")
                .then(function (response) {
                var tempIndex = _this.myCurrentCart.Items.findIndex(function (x) {
                    if ((x.LocationId === item.LocationId) && (x.Id === item.Id)) {
                        return true;
                    }
                    return false;
                });
                if (tempIndex !== -1) {
                    response.Item = response.Item || item.Item || null;
                    _this.myCurrentCart.Items[tempIndex] = response;
                    _this.myCurrentCart = _this.calculateCartQuantityAndCost(_this.myCurrentCart);
                    _this.broadcastCartUpdate();
                    resolve(_this.myCurrentCart);
                }
                reject("Item removed from cart mid-process.");
            }, function (response) {
                _this.myCurrentCart = null;
                _this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    };
    //
    CartService.prototype.estimateCartCost = function (postData) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.post(postData, null, _this.urlApiBasePlusController + "CostEstimate")
                .then(function (response) {
                resolve(response);
            }, function (response) {
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    };
    //
    CartService.prototype.placeCartOrder = function (postData) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.post(postData, null, _this.urlApiBasePlusController + "Order")
                .then(function (response) {
                //clear cart
                _this.myCurrentCart = _this.calculateCartQuantityAndCost({
                    Id: _this.myCurrentCart.Id,
                    Owner: _this.myCurrentCart.Owner,
                    OwnerDetails: null,
                    Items: [],
                    Locations: [],
                    TotalQuantity: 0,
                    TotalCost: 0
                });
                _this.broadcastCartUpdate();
                resolve(response);
            }, function (reason) {
                reject(reason);
            });
        });
        return promise;
    };
    //
    CartService.prototype.getLocationDisplay = function (locationId) {
        var tempIndex = this.myCurrentCart.Locations.findIndex(function (x) {
            if (x.Key === locationId) {
                return true;
            }
            return false;
        });
        if (tempIndex !== -1) {
            return this.myCurrentCart.Locations[tempIndex].Value;
        }
        return "NOT FOUND";
    };
    //
    CartService.prototype.removeLocation = function (locationId) {
        var tempIndex = this.myCurrentCart.Locations.findIndex(function (x) {
            if (x.Key === locationId) {
                return true;
            }
            return false;
        });
        if (tempIndex !== -1) {
            this.myCurrentCart.Locations.splice(tempIndex, 1);
        }
    };
    // STUBBED TODO
    CartService.prototype.removeItemFromMyCart = function (locationId, cartItemId) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.deleteByRoute(_this.urlApiBasePlusController + "CartItem/" + cartItemId)
                .then(function (response) {
                if (response) {
                    var tempIndex = _this.myCurrentCart.Items.findIndex(function (x) {
                        if ((x.LocationId === locationId) && (x.Id === cartItemId)) {
                            return true;
                        }
                        return false;
                    });
                    if (tempIndex !== -1) {
                        _this.myCurrentCart.Items.splice(tempIndex, 1);
                        if (_this.itemsInLocationCart(locationId) === 0) {
                            _this.removeLocation(locationId);
                        }
                        _this.myCurrentCart = _this.calculateCartQuantityAndCost(_this.myCurrentCart);
                        _this.broadcastCartUpdate();
                        resolve(_this.myCurrentCart);
                    }
                    reject("Item removed from cart mid-process.");
                }
                reject("");
            }, function (response) {
                _this.myCurrentCart = null;
                _this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    };
    // STUBBED TODO
    CartService.prototype.clearLocationFromMyCart = function (locationId) {
        var _this = this;
        var cartId = this.myCurrentCart.Id || 0;
        var promise = new Promise(function (resolve, reject) {
            _this.deleteByRoute("" + _this.urlApiBasePlusController + cartId + "/Location/" + locationId)
                .then(function (response) {
                if (response) {
                    var items_2 = _this.myCurrentCart.Items;
                    items_2 = items_2.filter(function (x) {
                        if (x.LocationId === locationId) {
                            return true;
                        }
                        return false;
                    });
                    var _loop_1 = function (i) {
                        var tempIndex = _this.myCurrentCart.Items.findIndex(function (x) {
                            if (x.Id === items_2[i].Id) {
                                return true;
                            }
                            return false;
                        });
                        if (tempIndex !== -1) {
                            _this.myCurrentCart.Items.splice(tempIndex, 1);
                        }
                    };
                    for (var i = 0; i < items_2.length; i++) {
                        _loop_1(i);
                    }
                    _this.removeLocation(locationId);
                    _this.myCurrentCart = _this.calculateCartQuantityAndCost(_this.myCurrentCart);
                    _this.broadcastCartUpdate();
                    resolve(_this.myCurrentCart);
                }
                reject("");
            }, function (response) {
                _this.myCurrentCart = null;
                _this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    };
    // Determines the quantity of a cart for a subset of locations.
    CartService.prototype.quantityOfLimitedCart = function (cart, locations) {
        var tempQuantity = 0;
        var items = cart.Items.filter(function (x) { return locations.indexOf(x.LocationId) !== -1; });
        for (var _i = 0, items_3 = items; _i < items_3.length; _i++) {
            var item = items_3[_i];
            tempQuantity = +tempQuantity + +item.Qty;
        }
        return tempQuantity;
    };
    // Updates the calculated cart data based on the current location and item data.
    CartService.prototype.calculateCartQuantityAndCost = function (cart) {
        var tempQuantity = 0;
        var tempCost = 0;
        if (cart) {
            for (var _i = 0, _a = cart.Items; _i < _a.length; _i++) {
                var item = _a[_i];
                var price = 0;
                if ((item.Item) && (item.Item.UnitPrice)) {
                    price = +item.Item.UnitPrice;
                }
                tempQuantity = +tempQuantity + +item.Qty;
                tempCost += (item.Qty * (price));
            }
            cart.TotalQuantity = tempQuantity;
            cart.TotalCost = tempCost;
        }
        return cart;
    };
    // Service message commands
    CartService.prototype.broadcastCartUpdate = function () {
        this.cartUpdatedSource.next(this.myCurrentCart);
    };
    // update variable pod after the punchout
    CartService.prototype.updateVariablePOD = function (model) {
        var route = this.urlApiBaseProductPlusVersion + "/Cart/UpdateVariablePOD";
        return this.put(model, null, route);
    };
    CartService.prototype.GetSecureToken = function (request) {
        var route = this.urlApiBasePlusController + "GetSecureToken?Amount=" + request.Amount + "&BusinessIdentity=" + this.activeProfileService.profile.businessIdentity + "\n                        &ReturnUrl=" + request.ReturnUrl;
        if (request.CancelUrl !== undefined) {
            route = route + "&CancelUrl=" + request.CancelUrl;
        }
        if (request.ErrorUrl !== undefined) {
            route = route + "&ErrorUrl=" + request.ErrorUrl;
        }
        if (request.UrlMethod !== undefined) {
            route = route + "&UrlMethod=" + request.UrlMethod;
        }
        if (request.CSCRequired !== undefined) {
            route = route + "&CSCRequired=" + request.CSCRequired;
        }
        return this.getByRoute(route);
    };
    // update variable pod after the punchout
    CartService.prototype.updateCartOptions = function (options) {
        var route = this.urlApiBaseProductPlusVersion + "/Cart/Options";
        return this.post(options, null, route);
    };
    //place order with payment information
    CartService.prototype.placeCartOrderWithPayment = function (postData) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.post(postData, null, _this.urlApiBasePlusController + "OrderWithPayment")
                .then(function (response) {
                //clear cart
                _this.myCurrentCart = _this.calculateCartQuantityAndCost({
                    Id: _this.myCurrentCart.Id,
                    Owner: _this.myCurrentCart.Owner,
                    OwnerDetails: null,
                    Items: [],
                    Locations: [],
                    TotalQuantity: 0,
                    TotalCost: 0
                });
                _this.broadcastCartUpdate();
                resolve(response);
            }, function (response) {
                reject(response.Message);
            });
        });
        return promise;
    };
    CartService.prototype.VoidAuthorization = function (transactionId) {
        var route = "" + this.urlApiBasePlusController + transactionId + "/VoidTransaction";
        return this.postByRoute(route, null);
    };
    return CartService;
}(IpsBaseWebService));
export { CartService };
