import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { String as IpsString } from "typescript-string-operations";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormControl, FormArray, Validators, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { SelectionRuleType } from "../../type/SelectionRuleType";
import { CustomDataOptionModel } from "../../model/CustomDataOptionModel";
import { CustomDataFieldService } from "../../service/custom-data-field.service";

@Component({
    selector: "app-ips-list",
    templateUrl: "./ips-list.component.html",
    styleUrls: ["./ips-list.component.scss"]
})
export class IpsListComponent implements OnInit {
    @Input() name: string;
    @Input() isFieldRequired: boolean;
    @Input() values: any[];
    @Input() fieldValues: FormArray;
    @Input() lookupValues: any[];
    @Input() selectionRule: SelectionRuleType;
    @Input() customDataId: number;
    @Output() listChanged = new EventEmitter<CustomDataOptionModel[]>();

    public placeholder = "";
    public listAddLabel = "";
    public listGroup: FormGroup;

    private busy = true;
    private cdfService: CustomDataFieldService;

    private TranslateStrings: { [key: string]: string } = {
        "PLEASE_ENTER_DYNAMIC_NAME": "",
        "ENTER_DYNAMIC_NAME": "",
        "ADD_LABEL": "",
        "DYNAMIC_NAME_REQUIRED": "",
        "PLEASE_ENTER_VALID_DYNAMIC_NAME": ""
    };

    public errorMessages = {
        "required": () => IpsString.Format(this.TranslateStrings["PLEASE_ENTER_DYNAMIC_NAME"], this.name),
        "listRequiredInvalid": () => IpsString.Format(this.TranslateStrings["DYNAMIC_NAME_REQUIRED"], this.name),
        "optionNameInvalid": () => IpsString.Format(this.TranslateStrings["PLEASE_ENTER_VALID_DYNAMIC_NAME"], this.name)
    };

    private itemIndexer = 0;
    public focusableItemInput: string;

    constructor(private translateService: TranslateService, private customDataFieldService: CustomDataFieldService) {
        this.cdfService = customDataFieldService;
    }

    ngOnInit() {
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(() => this.TranslateText());

        let formArray = new FormArray([]);

        this.values.forEach(item => {
            formArray.push(new FormGroup({
                Id: new FormControl(item.Id),
                ValidName: new FormControl(item.Text || item.Name),
                Name: this.cdfService.createNameControl(item.Text || item.Name, this.isFieldRequired),
                CustomDataId: new FormControl(this.customDataId),
                ValidationId: new FormControl(item.ValidationId),
                ControlName: new FormControl(this.name + ++this.itemIndexer),
                CustomDataOptionId: new FormControl(item.CustomDataOptionId)
        }));
        });

        if (this.values.length === 0) {
            formArray.push(this.createNewFormGroup());
        }

        this.listGroup = new FormGroup({
            Items: formArray
        });

        this.fieldValues.push(this.listGroup);

        this.busy = false;
    }

    private createNewFormGroup(): FormGroup {
        return new FormGroup({
            Id: new FormControl(0),
            ValidName: new FormControl(""),
            Name: this.cdfService.createNameControl("", this.isFieldRequired),
            CustomDataId: new FormControl(this.customDataId),
            ValidationId: new FormControl(0),
            CustomDataOptionId: new FormControl(0),
            ControlName: new FormControl(this.name + ++this.itemIndexer)
        });
    }

    private TranslateText() {
        this.translateService.get("ALL").subscribe(() => {
            for (let key of Object.keys(this.TranslateStrings)) {
                this.TranslateStrings[key] = this.translateService.instant(key);
            }
        });

        this.placeholder = IpsString.Format(this.TranslateStrings["ENTER_DYNAMIC_NAME"], this.name);
        this.listAddLabel = this.TranslateStrings["ADD_LABEL"];
    }

    public getErrorMessages() {
        let msgs = Object.assign({}, this.errorMessages);
        return msgs;
    }

    get itemArray(): FormArray {
        return this.listGroup.get("Items") as FormArray;
    }

    public addLink() {
        let control = this.createNewFormGroup();
        this.itemArray.controls.push(control);
        this.updateList(this.itemArray);
        this.itemArray.updateValueAndValidity();
        this.focusableItemInput = control.get("ControlName").value;
    }

    public itemChanged(e: any): void {

        if (e.index !== null) {
            //Single field
            this.itemArray.controls[e.index].get("ValidName").setValue(e.item.Name); //Always set ValidName before Name for validation
            this.itemArray.controls[e.index].get("Name").setValue(e.item.Name); //Needed for single selection modal
            this.itemArray.controls[e.index].get("ValidationId").setValue(e.item.Id); //Needed for single selection modal
            this.itemArray.controls[e.index].get("CustomDataOptionId").setValue(e.item.Id); //Needed for single selection modal

        } else {
            //Multiple results
            const itemFGs = e.item.map(item => (new FormGroup({
                Id: new FormControl(0),
                ValidName: new FormControl(item.Name),
                Name: this.cdfService.createNameControl(item.Name, this.isFieldRequired),
                CustomDataId: new FormControl(this.customDataId),
                ValidationId: new FormControl(item.Id),
                ControlName: new FormControl(this.name + ++this.itemIndexer),
                CustomDataOptionId: new FormControl(item.Id),
            })));
            const items = new FormArray(itemFGs);
            //clear itemArray
            this.itemArray.controls.length = 0;
            items.controls.forEach(item => {
                this.itemArray.controls.push(item);
            });
        }
        this.updateList(this.itemArray);
    }

    public itemDeleted(index: number): void {
        this.itemArray.removeAt(index);
        this.selectedValues.splice(index, 1);

        if (this.itemArray.controls.length === 0) {
            let control = this.createNewFormGroup();
            this.itemArray.controls.push(control);
            control.get("Name").markAsTouched();
        }
        this.updateList(this.itemArray);
    }

    private updateList(control: AbstractControl) {
        control.markAsDirty();
        this.itemArray.updateValueAndValidity();
        const list = this.GetCustomDataValue(control);
        this.listChanged.emit(list);
    }

    private GetCustomDataValue(control: AbstractControl): CustomDataOptionModel[] {
        let values = [];
        control.value.forEach((item: any) => {
            let value = {
                CustomDataId: item.CustomDataId,
                Id: item.Id,
                Name: item.Name,
                CustomDataOptionId: item.ValidationId,
                ControlName: item.ControlName,
                ValidName: item.ValidName,
                ValidationId: item.ValidationId
            };
            values.push(value);
        });
        return values;
    }

    get selectedValues() {
        let ret = [];

        // For CDF list items there are two different locations that the list name value is stored into: Text and Name.
        // Make sure that Text is always used before Name so if there are any changes to the CDF field, they are NOT reflected to the save promo data.
        // - [LocProf].[CustomData] is the actual created CDF field; this is what gives us the Name field.
        // - [LocProf].[CustomData*] table are the saved CDF values of the promo; this is what gives use the Text field.
        // Remember that in the [LocProf].[CustomData*] we do store the name into the text field and also the id of [LocProf].[CustomData], so we can always tie back to it.
        this.itemArray.value.forEach(item => {
            ret.push({ Id: item.CustomDataOptionId, Name: item.Text || item.Name, Label: item.Label || item.Text || item.Name });
        });
        return ret;
    }
}
