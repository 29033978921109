<div>
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close(null)" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0" translate>SELECT_LOCATION</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive tab-search-modal">
            <ips-list-search
            headerLabel=""
            searchLabel="{{'LOCATION' | translate }}"
            searchLabelPlural="{{'LOCATIONS' | translate }}"
            labelLoading="{{'ACCESSING_LOCATION_INFO' | translate }}"
            labelLoadingError="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}"
            [listData]="locations"
            [busy]="busy"
            (onListDataChange)="getLocationData($event.search)"
            [section]=""
            [scroller]="true"
            [quickRef]="true"
            [hideHeader]="true"
            itemClickPermission="PUBLIC"
            [promise]="locationPromise"
            [autoFocus]="focusLocation"
            [isModal]="true"
            [query]="locationQuery"
            [hideList]="true">
                <ng-template #searchHeaderTemplate>
                    <div class="row">
                        <section class="col-12">
                            <div class="ips-ls-search-module-imgn" [ngClass]="{ 'pr-0': infoTooltipText}">
                                <div class="ips-ls-searchBox">
                                    <span class="pl-4 pr-3"><b translate>LOCATION_INFORMATION</b></span>
                                </div>
                                <div class="ips-ls-searchBox input-group">
                                    <select name="locationAttributes" id="locationAttributes" [(ngModel)]="selectedLocationAttribute"
                                    class="form-control dropdownBtn form-control-lg ellipsis"
                                    (ngModelChange)="changeLocationAttribute()">
                                        <option *ngFor="let item of locationAttributes" [ngValue]="item.value">{{item.text}}</option>
                                    </select>
                                </div>
                                <div class="ips-ls-searchBox pt-2">
                                    <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                                    <input type="text"
                                    class="form-control form-control-lg ips-ls-input"
                                    [(ngModel)]="locationQuery" (ngModelChange)="onQueryChange($event)"
                                    id="btn-search"
                                    value=""
                                    maxlength="20"
                                    [ngModelOptions]="{ debounce: 350 }"
                                    aria-label="Text input with dropdown button" name="searchInput">
                                </div>
                                <div class="row no-gutters mt-1">
                                    <div class="col-12 col-md-auto ml-3">
                                        <span class="badge-pill badge-secondary font-weight-normal">{{ locations.ResultChunkAttributes.TotalRecords | number }}</span>
                                        <span class="ml-1">{{locations.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping}} {{'FOUND_OF' | translate}}</span>
                                    </div>
                                    <div class="col-12 col-md-auto ml-3 ml-md-2 mt-1 mt-md-0">
                                        <span class="badge-pill badge-secondary font-weight-normal">{{ locations.TotalRecords | number }}</span>
                                        <span class="ml-1">{{ 'TOTAL' | translate }} {{locations.TotalRecords | i18nPlural: pluralMapping}}</span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </ng-template>
                <section class="col-12" search-result-List>
                    <div class="row">
                        <div class="col-12">
                            <div class="ips-ls-searchResults ips-ls-long-text-wrap list-group col-10">
                                <div class="list-group-item no-border">
                                    <div class="marketed-locations-label">{{listHeader}}</div>
                                    <div class="marketed-locations-count"  *ngIf="selectedLocationAttribute !== 'locationid'" translate>LOCATIONS</div>
                                </div>
                            </div>
                        </div>

                        
                        <div class="col-12">
                            <div class="ips-ls-searchResults ips-ls-long-text-wrap list-group">
                                <div infiniteScroll (scrolled)="getMoreListData()" [infiniteScrollDisabled]="busy" [infiniteScrollDistance]="0" [scrollWindow]="!isModal" [ngClass]="{'list-search-infinite-container': isModal}">
                                    <div list-search-item class="ips-ls-search-item border-0"
                                                          *ngFor="let result of locations.resultList">
                                        
                                            <div class="list-group-item input-group no-round change-hover-color p-0">
                                                <div class="row">
                                                    <div class="col"><div class="py-2 px-3">{{result.Label}}</div></div>
                                                    <div class="col-2 py-2 text-right" *ngIf="selectedLocationAttribute !== 'locationid'">
                                                        {{result.LocationCount}}
                                                    </div>
                                                    <div class="input-group-append col-auto">
                                                        <div class="input-group-text border-0">
                                                            <input class="m-2" type="checkbox" [checked]="result.checked" id="{{result.Label}}" (change)="locationselected(result,$event.target.checked)">
                                                        </div>
                                                    </div>

                                                </div>
                                                
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ips-list-search>
        </div>
        
        <div class="row align-items-center">
            <div class="col-12 col-md-6 ml-5 pr-0">
                <span class="badge-pill badge-success">{{selectedCount}}</span>
                <span class="ml-1">{{selectedCount | i18nPlural: selectedLocationsPluralMapping}}</span>
            </div>
            <div class="col-12 col-md-5 pl-0 pr-4">
                <button type="submit" class="btn btn-default btn-lg btn-block search-modal-done-button mb-2"
                        id="doneButton"
                        (click)="locationsSelected()" translate>
                    DONE
                </button>
            </div>
        </div>

    </form>
</div>
