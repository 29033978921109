import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Transition, StateService } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";

import { ListSearchHelper, SearchInfo, QueryParamItem, ActiveProfileService } from "imagine-ui-ng-core";
import { IpsModalService } from "imagine-ui-ng-modal";

import { LocationService, SimpleSearchLocationModel, LocationModel } from "../../../imagine-ui-ng-store-profile";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { OrderService, OrderStatus, OrderStatusModel, FulfillmentOrderSearchResultModel } from "../../index";
import { StatusModalComponent } from "../status-modal/status-modal.component";
import { SearchCardUIModel } from "../../../shared/index";

interface FulfillmentOrderSearchResultUIModel extends FulfillmentOrderSearchResultModel {
    Cards: SearchCardUIModel[];
}

@Component({
  selector: "app-order-search",
  templateUrl: "./order-search.component.html",
  styleUrls: ["./order-search.component.scss"]
})
export class OrderSearchComponent implements OnInit {
    public myOrder: ListSearchHelper;
    public busy = true;
    public promise: Promise<any>;
    public loaded = false;
    public search = "";
    public searchInfo: SearchInfo;
    public selectedLocation: SimpleSearchLocationModel;
    public selectedStatus: OrderStatusModel;
    private startDate: Date;
    private endDate: Date;
    public placedOrderId: number;
    private today = new Date();

    public chunkSize = 20;
    public numberToDisplay = this.chunkSize;
    public displayList = [];

    private pluralMapping: { [k: string]: string } = {};


    private TranslateStrings: { [key: string]: string } = {
        "ALL_UPPERCASE": "",
        "ORDER": "",
        "ORDERS": "",
        "ADDRESSES": ""
    };

    constructor(
        private orderService: OrderService, listSearchHelper: ListSearchHelper,
        private ipsModal: IpsModalService, private transition: Transition,
        private translateService: TranslateService, private activeProfileService: ActiveProfileService) {
        this.myOrder = listSearchHelper.getListSearchHelper(orderService);
    }

    ngOnInit() {

        this.placedOrderId = Number(this.transition.params().placedOrderId);
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.displayList = [{ Name: "Status", Label: "ORDER_STATUS", CanHighlight: false }, { Name: "OrderedByEmail", Label: "ORDERED_BY", CanHighlight: false },
            { Name: "ShipmentAddress", Label: "SHIPPING_ADDRESS", CanHighlight: false }, { Name: "ShipMethodDisplayName", Label: "DELIVERY_SERVICE", CanHighlight: false }];

        this.getListData();
    }

    private translateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
        this.pluralMapping["=1"] = this.translateService.instant("ORDER");
        this.pluralMapping["other"] = this.translateService.instant("ORDERS");
        this.selectedLocation = { Id: 0, LocationIdentifier: "", Name: "", Label: this.TranslateStrings["ALL_UPPERCASE"] };
        this.selectedStatus = { value: "ALL", text: this.TranslateStrings["ALL_UPPERCASE"] };
    }

    public selectLocation() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "location" } })
            .then((response: any) => {
                this.selectedLocation = <SimpleSearchLocationModel>response.item;
                this.getListData();
            }, () => { }); // Rejected;
    }

    public selectOrderStatus() {
        this.ipsModal.displayTemplateScrollable(StatusModalComponent, { resolve: { type: "order" } })
            .then((response: OrderStatusModel) => {
                this.selectedStatus = response;
                this.getListData();
            }, () => { }); // Rejected;
    }

    public getListData(search?: string) {
        // reset number to display
        this.numberToDisplay = this.chunkSize;
        let additionalQueryParams: QueryParamItem[] = [];
        if (this.selectedLocation && this.selectedLocation.Id > 0) {
            additionalQueryParams.push(<QueryParamItem>{ param: "LocationId", paramValue: this.selectedLocation.Id.toString() });
        }
        if (this.selectedStatus && this.selectedStatus.value !== "ALL") {
            additionalQueryParams.push(<QueryParamItem>{ param: "OrderStatus", paramValue: this.selectedStatus.value });
        }
        if (this.startDate && this.endDate) {
            additionalQueryParams.push(<QueryParamItem>{ param: "StartDate", paramValue: this.startDate.toUTCString() });
            additionalQueryParams.push(<QueryParamItem>{ param: "EndDate", paramValue: this.endDate.toUTCString() });
        }

        let searchInfo = <SearchInfo>{};
        searchInfo.searchText = this.search;
        searchInfo.businessIdentity = this.activeProfileService.businessIdentity,
        searchInfo.additionalQueryParams = additionalQueryParams;

        this.promise = this.myOrder.searchHelper(searchInfo).then((response) => {
            Object.assign(this.myOrder, response);
            this.myOrder.resultList.forEach((order: FulfillmentOrderSearchResultUIModel) => {
                order.Cards = [];
                let keys = Object.keys(order);
                if (this.displayList) {
                    this.displayList.forEach((display) => {
                        let key = keys.find(q => q === display.Name);
                        if (key) {
                            order.Cards.push({
                                Id: order.Id,
                                Key: display.Label,
                                Value: order[key],
                                Type: "Order",
                                CanHighlight: display.CanHighlight
                            });
                            return;
                        } else {
                            let val = "";
                            if (display.Name === "ShipmentAddress") {
                                if (order.AddressCount > 1) {
                                    val = `${order.AddressCount} ${this.translateService.instant("ADDRESSES")}`;
                                } else if (order.Address !== null && order.Address !== undefined) {
                                    val = `${order.Address.Line1} ${order.Address.City}, ${order.Address.StateProvince} ${order.Address.PostalCode}`;
                                }

                            }
                            if (display.Name === "ShipMethodDisplayName") {
                                if (order.Address) {
                                    val = order.Address.ShipMethodDisplayName;
                                }
                            }
                            order.Cards.push({
                                Id: order.Id,
                                Key: display.Label,
                                Value: val,
                                Type: "Order",
                                CanHighlight: display.CanHighlight
                            });

                        }
                    });

                }
            });

        });
    }

    public changeDateRange(event: any) {
        if (event) {
            this.startDate = event.startDate;
            this.endDate = event.endDate;
            this.getListData();
        }
    }

    private onQueryChange(change: string) {
        let originText = this.search;
        if (originText !== change) {
            this.search = change;
            this.getListData();
        }
    }
}
