import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";

@Injectable()
export class StoreFrontCategoryService extends IpsBaseWebService {
  private route: string;

  constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
    super(http, <any>environment, activeProfileService);
    this.init("Location", "Profile", "v1");
    this.route = `${this.urlApiBaseProductPlusVersion}Location/StoreFront/Categories`;
  }

  public headByStoreFrontCategory() {
    return this.headByRoute(this.route);
  }

  // override ipsBaseWebService search method
  search<T>(searchInfo: SearchInfo, route: string): Promise<T> {
    return this.searchByRelation(searchInfo, "Location/StoreFront/Categories/Search");
  }

  get<T>(id: number): Promise<T> {
    return this.getByRoute(`${this.urlApiBaseProductPlusVersion}Location/StoreFront/Category/${id}`);
  }

  post<T>(values: any): Promise<T> {
    return super.post(values, null, `${this.urlApiBaseProductPlusVersion}Location/StoreFront/Category`);
  }

  put<T>(values: any): Promise<T> {
    return super.put(values, null, `${this.urlApiBaseProductPlusVersion}Location/StoreFront/Category`);
  }

  delete(id: number): Promise<any> {
    return super.deleteByRoute(`${this.urlApiBaseProductPlusVersion}Location/StoreFront/Category/${id}`);
  }

  getList<T>(): Promise<any> {
    let route = `${this.urlApiBaseProductPlusVersion}Location/StoreFront/Categories`;
    return this.getByRoute(route);
  }

}
