import { OnInit, EventEmitter } from "@angular/core";
import { String as IpsString } from "typescript-string-operations";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormControl, FormArray, AbstractControl } from "@angular/forms";
import { CustomDataFieldService } from "../../service/custom-data-field.service";
var IpsListComponent = /** @class */ (function () {
    function IpsListComponent(translateService, customDataFieldService) {
        var _this = this;
        this.translateService = translateService;
        this.customDataFieldService = customDataFieldService;
        this.listChanged = new EventEmitter();
        this.placeholder = "";
        this.listAddLabel = "";
        this.busy = true;
        this.TranslateStrings = {
            "PLEASE_ENTER_DYNAMIC_NAME": "",
            "ENTER_DYNAMIC_NAME": "",
            "ADD_LABEL": "",
            "DYNAMIC_NAME_REQUIRED": "",
            "PLEASE_ENTER_VALID_DYNAMIC_NAME": ""
        };
        this.errorMessages = {
            "required": function () { return IpsString.Format(_this.TranslateStrings["PLEASE_ENTER_DYNAMIC_NAME"], _this.name); },
            "listRequiredInvalid": function () { return IpsString.Format(_this.TranslateStrings["DYNAMIC_NAME_REQUIRED"], _this.name); },
            "optionNameInvalid": function () { return IpsString.Format(_this.TranslateStrings["PLEASE_ENTER_VALID_DYNAMIC_NAME"], _this.name); }
        };
        this.itemIndexer = 0;
        this.cdfService = customDataFieldService;
    }
    IpsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(function () { return _this.TranslateText(); });
        var formArray = new FormArray([]);
        this.values.forEach(function (item) {
            formArray.push(new FormGroup({
                Id: new FormControl(item.Id),
                ValidName: new FormControl(item.Text || item.Name),
                Name: _this.cdfService.createNameControl(item.Text || item.Name, _this.isFieldRequired),
                CustomDataId: new FormControl(_this.customDataId),
                ValidationId: new FormControl(item.ValidationId),
                ControlName: new FormControl(_this.name + ++_this.itemIndexer),
                CustomDataOptionId: new FormControl(item.CustomDataOptionId)
            }));
        });
        if (this.values.length === 0) {
            formArray.push(this.createNewFormGroup());
        }
        this.listGroup = new FormGroup({
            Items: formArray
        });
        this.fieldValues.push(this.listGroup);
        this.busy = false;
    };
    IpsListComponent.prototype.createNewFormGroup = function () {
        return new FormGroup({
            Id: new FormControl(0),
            ValidName: new FormControl(""),
            Name: this.cdfService.createNameControl("", this.isFieldRequired),
            CustomDataId: new FormControl(this.customDataId),
            ValidationId: new FormControl(0),
            CustomDataOptionId: new FormControl(0),
            ControlName: new FormControl(this.name + ++this.itemIndexer)
        });
    };
    IpsListComponent.prototype.TranslateText = function () {
        var _this = this;
        this.translateService.get("ALL").subscribe(function () {
            for (var _i = 0, _a = Object.keys(_this.TranslateStrings); _i < _a.length; _i++) {
                var key = _a[_i];
                _this.TranslateStrings[key] = _this.translateService.instant(key);
            }
        });
        this.placeholder = IpsString.Format(this.TranslateStrings["ENTER_DYNAMIC_NAME"], this.name);
        this.listAddLabel = this.TranslateStrings["ADD_LABEL"];
    };
    IpsListComponent.prototype.getErrorMessages = function () {
        var msgs = Object.assign({}, this.errorMessages);
        return msgs;
    };
    Object.defineProperty(IpsListComponent.prototype, "itemArray", {
        get: function () {
            return this.listGroup.get("Items");
        },
        enumerable: true,
        configurable: true
    });
    IpsListComponent.prototype.addLink = function () {
        var control = this.createNewFormGroup();
        this.itemArray.controls.push(control);
        this.updateList(this.itemArray);
        this.itemArray.updateValueAndValidity();
        this.focusableItemInput = control.get("ControlName").value;
    };
    IpsListComponent.prototype.itemChanged = function (e) {
        var _this = this;
        if (e.index !== null) {
            //Single field
            this.itemArray.controls[e.index].get("ValidName").setValue(e.item.Name); //Always set ValidName before Name for validation
            this.itemArray.controls[e.index].get("Name").setValue(e.item.Name); //Needed for single selection modal
            this.itemArray.controls[e.index].get("ValidationId").setValue(e.item.Id); //Needed for single selection modal
            this.itemArray.controls[e.index].get("CustomDataOptionId").setValue(e.item.Id); //Needed for single selection modal
        }
        else {
            //Multiple results
            var itemFGs = e.item.map(function (item) { return (new FormGroup({
                Id: new FormControl(0),
                ValidName: new FormControl(item.Name),
                Name: _this.cdfService.createNameControl(item.Name, _this.isFieldRequired),
                CustomDataId: new FormControl(_this.customDataId),
                ValidationId: new FormControl(item.Id),
                ControlName: new FormControl(_this.name + ++_this.itemIndexer),
                CustomDataOptionId: new FormControl(item.Id),
            })); });
            var items = new FormArray(itemFGs);
            //clear itemArray
            this.itemArray.controls.length = 0;
            items.controls.forEach(function (item) {
                _this.itemArray.controls.push(item);
            });
        }
        this.updateList(this.itemArray);
    };
    IpsListComponent.prototype.itemDeleted = function (index) {
        this.itemArray.removeAt(index);
        this.selectedValues.splice(index, 1);
        if (this.itemArray.controls.length === 0) {
            var control = this.createNewFormGroup();
            this.itemArray.controls.push(control);
            control.get("Name").markAsTouched();
        }
        this.updateList(this.itemArray);
    };
    IpsListComponent.prototype.updateList = function (control) {
        control.markAsDirty();
        this.itemArray.updateValueAndValidity();
        var list = this.GetCustomDataValue(control);
        this.listChanged.emit(list);
    };
    IpsListComponent.prototype.GetCustomDataValue = function (control) {
        var values = [];
        control.value.forEach(function (item) {
            var value = {
                CustomDataId: item.CustomDataId,
                Id: item.Id,
                Name: item.Name,
                CustomDataOptionId: item.ValidationId,
                ControlName: item.ControlName,
                ValidName: item.ValidName,
                ValidationId: item.ValidationId
            };
            values.push(value);
        });
        return values;
    };
    Object.defineProperty(IpsListComponent.prototype, "selectedValues", {
        get: function () {
            var ret = [];
            // For CDF list items there are two different locations that the list name value is stored into: Text and Name.
            // Make sure that Text is always used before Name so if there are any changes to the CDF field, they are NOT reflected to the save promo data.
            // - [LocProf].[CustomData] is the actual created CDF field; this is what gives us the Name field.
            // - [LocProf].[CustomData*] table are the saved CDF values of the promo; this is what gives use the Text field.
            // Remember that in the [LocProf].[CustomData*] we do store the name into the text field and also the id of [LocProf].[CustomData], so we can always tie back to it.
            this.itemArray.value.forEach(function (item) {
                ret.push({ Id: item.CustomDataOptionId, Name: item.Text || item.Name, Label: item.Label || item.Text || item.Name });
            });
            return ret;
        },
        enumerable: true,
        configurable: true
    });
    return IpsListComponent;
}());
export { IpsListComponent };
