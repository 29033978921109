var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var ProductAdminService = /** @class */ (function (_super) {
    __extends(ProductAdminService, _super);
    function ProductAdminService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.http = http;
        _this.init("ProductAdmin", "Profile", "v1");
        return _this;
    }
    ProductAdminService.prototype.search = function (searchInfo) {
        return this.searchByRelation(searchInfo, "ProductAdmin/Search");
    };
    ProductAdminService.prototype.get = function (id) {
        var searchInfo = { searchText: "", additionalQueryParams: [{ param: "Estimate", paramValue: id.toString() }] };
        searchInfo.chunkIndex = 0;
        searchInfo.recordCount = 10;
        return this.searchByRelation(searchInfo, "Estimate/Search");
    };
    return ProductAdminService;
}(IpsBaseWebService));
export { ProductAdminService };
