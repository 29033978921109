var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var PromotionService = /** @class */ (function (_super) {
    __extends(PromotionService, _super);
    function PromotionService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("Promotion", "Campaign", "v1");
        return _this;
    }
    PromotionService.prototype.headByCampaignId = function (campaignId) {
        var route = "Campaign/" + campaignId + "/Promotion";
        return this.headByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    PromotionService.prototype.getPromotionHeader = function (promotionId) {
        var route = "PromotionHeader/" + promotionId;
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    PromotionService.prototype.getPromotionSummary = function (campaignId) {
        var route = "Campaign/" + campaignId + "/PromotionSummary";
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    PromotionService.prototype.checkPromotionName = function (promo) {
        var route = "Campaign/PromotionName/available";
        return this.postByRoute("" + this.urlApiBaseProductPlusVersion + route, promo);
    };
    PromotionService.prototype.movePromotion = function (promo) {
        var route = this.urlApiBaseProductPlusVersion + "Promotion/Move";
        return this.put(promo, undefined, route);
    };
    return PromotionService;
}(IpsBaseWebService));
export { PromotionService };
