<div [formGroup]="parent">
    <!--Sections-->
    <div formArrayName="SurveySections">
        <div *ngFor="let section of Sections.controls; let i=index;">
            <div class="card ips-shadow mt-4" [formGroupName]="i" id="{{'section_' + section.get('Id').value}}">
                <div class="card-body">
                    <div class="row ips-search-result-big" *ngIf="section.get('IsCollapsed').value">
                        <div class="col d-flex flex-row flex-wrap ips-gray-color" >
                            <div><label class="mb-0">{{section.get('Name').value}}</label></div>
                            <div><span class="ips-default-font-size ml-4">{{Questions(section).controls.length || section.get('QuestionCount').value | number}} {{Questions(section).controls.length || section.get('QuestionCount').value | number | i18nPlural: pluralMapping}}</span></div>
                        </div>

                        <div class="col-2 d-flex justify-content-end align-items-start">
                            <button (click)="activeCollapse(section)" class="btn ips-btn-link-icon" [disabled]="!(section ? section.valid : false)">
                                <span class="ips-gray-color" [ngClass]="['text-right fa fa-chevron-down ips-ls-addIcon', section.get('IsCollapsed').value ? '' : 'fa-flip-vertical']"></span>
                            </button>
                        </div>

                    </div>

                    <div class="row mt-2" [ngbCollapse]="section.get('IsCollapsed').value">
                        <div class="form-group col-12 col-xl-8 mb-3 mb-xl-0">
                            <label class="required" for="SectionName" translate>SECTION_NAME</label>
                            <div class="input-group section-label-with-icon">
                                <div class="input-group-prepend">
                                    <div class="input-group-text"><div class="section-icon"></div></div>
                                </div>
                                <textarea type="text" class="form-control form-control-lg" id="SectionName" formControlName="Name" name="{{section.get('InputName').value}}" placeholder="{{ 'ENTER_SECTION_NAME' | translate}}" rows="1" ipsAutoGrow></textarea>
                            </div>
                            <ips-show-errors [control]="section.get('Name')" [errorMessages]="getErrorMessages('SectionName')"></ips-show-errors>
                        </div>
                        <div class="form-group col-12 col-xl-4">
                            <label>Display Order</label>
                            <div class="input-group mb-3 option-control ips-market-button">
                                <textarea type="text" class="form-control form-control-lg" readonly formControlName="Ordinal" rows="1" ipsAutoGrow></textarea>
                                <div class="input-group-append">
                                    <button class="btn btn-default" type="button" (click)="moveSectionUp(i)" [disabled]="section.get('Ordinal').value === 1"><i class="fa fa-lg ips-gray-color px-1 fa-caret-up"></i></button>
                                    <button class="btn btn-default" type="button" (click)="moveSectionDown(i)" [disabled]="section.get('Ordinal').value === Sections.controls.length"><i class="fa fa-lg ips-gray-color px-1 fa-caret-down"></i></button>
                                </div>
                            </div>
                        </div>

                        <!--Questions-->
                        <div class="col-12">
                            <div formArrayName="SurveyQuestions">
                                <div *ngFor="let question of Questions(section).controls; let q=index;">
                                    <div [formGroupName]="q">
                                        <div class="card ips-shadow ips-gray-light-bg-color mt-4">
                                            <div class="card-body pb-0">
                                                <div class="row mt-2">
                                                    <div class="form-group col-12 mb-3">
                                                        <label class="required" for="QuestionName" translate>QUESTION</label>
                                                        <div class="input-group section-label-with-icon">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text"><div class="question-icon"></div></div>
                                                            </div>
                                                            <textarea type="text" class="form-control form-control-lg" id="QuestionName" formControlName="Name" name="{{question.get('Market.InputName').value}}" placeholder="{{ 'ENTER_QUESTION' | translate}}" rows="1" ipsAutoGrow></textarea>
                                                        </div>
                                                        <ips-show-errors [control]="question.get('Name')" [errorMessages]="getErrorMessages('QuestionName')"></ips-show-errors>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group ips-instruction-group">
                                                            <label translate>QUESTION_INSTRUCTIONS</label>
                                                            <textarea class="form-control form-control-lg input-full"
                                                                      cols="30" rows="3"
                                                                      formControlName="Instruction"
                                                                      [typeahead]="getInstructionSource(section, question)"
                                                                      [typeaheadOptionsLimit]="20"
                                                                      [typeaheadMinLength]="2"
                                                                      [typeaheadScrollable]="true"
                                                                      [typeaheadOptionsInScrollableView]="5"></textarea>
                                                            <ips-show-errors [control]="question.get('Instruction')" [errorMessages]="getErrorMessages()" cssClass="validation-message-Qty"></ips-show-errors>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-row">
                                                            <div class="form-group col-12 mb-3">
                                                                <label class="required" translate>LINKED_PROFILE</label>
                                                                <app-market-typeahead-search [market]="question.get('Market')"
                                                                                             [marketIndex]="q"
                                                                                             pendingLocationWarning="{{'AUDIENCE_PENDING_LOCATIONS_WARNING' | translate}}"
                                                                                             [showInUseFeatures]="true"
                                                                                             [featuresInAudience]="featuresInAudience"
                                                                                             [featuresInQuestion]="featuresInQuestion"
                                                                                             [fixtureGroupsInQuestion]="fixtureGroupsInQuestion"
                                                                                             [fixturesInQuestion]="fixturesInQuestion"
                                                                                             [isSingleSelect]="true"
                                                                                             [marketSource]="getMarketSource(section, question)"
                                                                                             (marketChanged)="marketChangedEventHandler($event, question)"
                                                                                             (marketDeleted)="marketDeletedEventHandler($event.market)"></app-market-typeahead-search>
                                                                <ips-show-errors [control]="question.get('Market').get('MarketName')" [errorMessages]="getErrorMessages('locationgroupname')"></ips-show-errors>
                                                            </div>
                                                            <!--Image for xs, sm, md -->
                                                            <div class="col-12 form-group ips-list-image d-flex d-lg-none" *ngIf="HasImage(question)">
                                                                <ips-image-view class="ips-list-image" [imgThumbUrl]="question.get('FullImage').value" [imgFullUrl]="question.get('FullImage').value" hideLabel="true"></ips-image-view>
                                                            </div>

                                                            <div class="form-group col-12 col-xl-6" [ngClass]="{'col-lg-6': !HasImage(question)}">
                                                                <label class="required" translate>ANSWER_TYPE</label>
                                                                <div class="input-group mb-3 option-control ips-market-button">
                                                                    <textarea type="text" class="form-control form-control-lg" readonly (click)="changeAnswerType(question)" [disabled]="!question.get('EnableAnswerType').value" value="{{(getAnswerTypeText(question)) | translate}}" rows="1" ipsAutoGrow></textarea>
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-default" type="button" (click)="changeAnswerType(question)" [disabled]="!question.get('EnableAnswerType').value"><i class="fa fa-lg ips-gray-color px-1" [ngClass]="getAnswerTypeIconClass(question)" aria-hidden="true"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="!isUsingFixture(question)" class="form-group col-12 col-xl-6" [ngClass]="{'col-lg-6': !HasImage(question)}">
                                                                <label class="required" translate>OPTION_CONTROL</label>
                                                                <div class="input-group mb-3 option-control ips-market-button">
                                                                    <textarea type="text" class="form-control form-control-lg" readonly (click)="changeOptionControl(question)" [disabled]="!question.get('EnableOptionControl').value" value="{{(question.get('OptionControl').value ? 'CAN_ADD_OPTIONS' : 'CANNOT_ADD_OPTIONS') | translate}}" rows="1" ipsAutoGrow></textarea>
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-default" type="button" (click)="changeOptionControl(question)" [disabled]="!question.get('EnableOptionControl').value"><i class="fa fa-lg ips-gray-color px-1" [ngClass]="{'fa-unlock': question.get('OptionControl').value , 'fa-lock': !question.get('OptionControl').value }" aria-hidden="true"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-xl-6" [ngClass]="{'col-lg-6': !HasImage(question)}">
                                                                <label class="required" translate>MESSAGE_CONTROL</label>
                                                                <div class="input-group mb-3 option-control ips-market-button">
                                                                    <textarea type="text" class="form-control form-control-lg" readonly (click)="changeMessageControl(question)" [disabled]="!question.get('EnableMessageControl').value" value="{{getMessageControlText(question) | translate}}" rows="1" ipsAutoGrow></textarea>
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-default" type="button" (click)="changeMessageControl(question)" [disabled]="!question.get('EnableMessageControl').value"><i class="fa fa-lg ips-gray-color px-1" [ngClass]="getMessageControlIconClass(question)" aria-hidden="true"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-12 col-xl-6" [ngClass]="{'col-lg-6': !HasImage(question)}">
                                                                <label>Display Order</label>
                                                                <div class="input-group mb-3 option-control ips-market-button">
                                                                    <textarea type="text" class="form-control form-control-lg" readonly formControlName="Ordinal" rows="1" ipsAutoGrow></textarea>
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-default" type="button" (click)="moveQuestionUp(section, q)" [disabled]="question.get('Ordinal').value === 1"><i class="fa fa-lg ips-gray-color px-1 fa-caret-up"></i></button>
                                                                        <button class="btn btn-default" type="button" (click)="moveQuestionDown(section, q)" [disabled]="question.get('Ordinal').value === Questions(section).controls.length"><i class="fa fa-lg ips-gray-color px-1 fa-caret-down"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!--Image for lg, xl -->
                                                    <div class="ips-list-image d-none d-lg-flex mr-3 mb-3" *ngIf="HasImage(question)">
                                                        <ips-image-view class="ips-list-image" [imgThumbUrl]="question.get('FullImage').value" [imgFullUrl]="question.get('FullImage').value" hideLabel="true"></ips-image-view>
                                                    </div>
                                                </div>
                                                <div class="ips-search-result-big">
                                                    <div class="col-sm-1 pr-0 d-flex justify-content-end ips-top-right-controls">
                                                        <button class="button-no-style" (click)="removeQuestion(section, q)" [disabled]="Questions(section).length === 1"><i class="fa fa-remove"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-12 mt-4 mb-2">
                            <button id="createQuestion"
                                    class="btn btn-lg btn-block text-left ips-add-bar"
                                    (click)="addQuestion(section)"
                                    [ipsFocus]="focusQuestionInput">
                                <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
                                <span class="add-holder-label" translate>CREATE_QUESTION</span>
                            </button>
                        </div>

                        <div class="ips-search-result-big">
                            <div class="col-sm-1 d-flex justify-content-end ips-top-right-controls">
                                <button class="button-no-style mr-2" (click)="removeSection(section, i)" [disabled]="Sections.length === 1"><i class="fa fa-remove"></i></button>
                                <button (click)="activeCollapse(section)" class="btn ips-btn-link-icon" [disabled]="!(section ? section.valid : false)">
                                    <span class="ips-gray-color" [ngClass]="['text-right fa fa-chevron-down ips-ls-addIcon', section.get('IsCollapsed').value ? '' : 'fa-flip-vertical']"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="form-group col-12 mb-2">
            <button id="createSection"
                    class="btn btn-lg btn-block text-left ips-add-bar pr-2"
                    (click)="addSection()"
                    [ipsFocus]="focusSectionInput">
                <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
                <span class="add-holder-label" translate>CREATE_SECTION</span>
                <span class="pull-right color-gray tooltip-container" placement="bottom-right" ngbTooltip="{{'TOOLTIP_CREATE_SECTION' | translate}}">
                    <i class="fa fa-info-circle"></i>
                </span>
            </button>
        </div>
    </div>

</div>
<!--</div>-->
