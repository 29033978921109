<div [formGroup]="parent">
    <div formGroupName="messageForm">
        <div formArrayName="PatternGroups">
            <div *ngFor="let patternGroup of PatternGroups.controls; let j=index; let patternGroupLast = last;">
                <div [formGroupName]="j">
                    <div class="card ips-shadow">
                        <div class="card-body pb-2">
                            <div class="form-row">
                                <div class="form-group col-2 w-col-lg-10 mb-0 text-center"><label class=" ips-label-double m-0" translate>PATTERN_POSITION</label></div>
                                <div class="form-group col-auto mb-0"><label class="required ips-label-double" translate>MESSAGE</label></div>
                            </div>
                            <div formArrayName="MessagePatterns" [sortablejs]="MessagePatterns(patternGroup)" [sortablejsOptions]="setSortableOptions(patternGroup)" stickyHeader="PATTERN_MESSAGES">
                                <div *ngFor="let pattern of MessagePatterns(patternGroup).controls let i=index">
                                    <div class="form-row">
                                        <!--Ordinal column-->
                                        <div class="form-group col-2 w-col-lg-10 text-center">
                                            <div class="form-control input form-control-lg input-full ips-info btn-label ips-market-button" [textContent]="pattern.get('Ordinal').value"></div>
                                        </div>
                                        <div class="form-group col d-flex mb-0">
                                            <div class="flex-grow-1">
                                                <!--Message name Field-->
                                                <div [formGroupName]="i">
                                                    <div *ngIf="!pattern.get('IsEmpty').value" class="input-group ips-textarea">
                                                        <textarea formControlName="MessageName"
                                                                  [typeahead]="messageNames"
                                                                  [typeaheadOptionsLimit]="20"
                                                                  [typeaheadMinLength]="0"
                                                                  container="body" type="text"
                                                                  name="{{pattern.get('InputName').value}}"
                                                                  class="form-control form-control-lg ips-no-resize" (click)="setFocus($event)" rows="1" ipsAutoGrow></textarea>
                                                        <div class="input-group-append">
                                                            <span class="input-group-text" [ngClass]="{'invalid': promotionHelperService.CheckIfInvalid(pattern.get('MessageName')) }">
                                                                <button class="btn btn-default ips-btn-remove"
                                                                        (click)="removePatternPrompt(patternGroup, i, t)"
                                                                        ngbTooltip="{{'TOOLTIP_DELETE_PATTERN_MESSAGE' | translate}}" placement="bottom" #t="ngbTooltip">
                                                                    <i class="fa fa-remove fa-lg"></i>
                                                                </button>
                                                            </span>
                                                            <button class="btn btn-default px-3 last" (click)="cloneMessage(patternGroup, pattern)"><i class="clone-icon" ngbTooltip="{{'TOOLTIP_CLONE_PATTERN_MESSAGE' | translate}}" placement="bottom"></i></button>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="pattern.get('IsEmpty').value" class="input-group ips-textarea">
                                                        <input class="form-control form-control-lg ips-light" type="text" formControlName="MessageName" />
                                                        <div class="input-group-append">
                                                            <button class="btn btn-default ips-btn-remove ips-light" ngbTooltip="{{'TOOLTIP_DELETE_PATTERN_MESSAGE' | translate}}" placement="bottom" (click)="removePatternPrompt(patternGroup, i)"><i class="fa fa-remove fa-lg"></i></button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ips-show-errors [control]="pattern.get('MessageName')" [errorMessages]="getErrorMessages('pattern')"></ips-show-errors>
                                            </div>
                                            <div *ngIf="reorderMessages" class="form-group grab-cursor">
                                                <!--Drag Drop Icon - Float right must appear before main content that fills the gap between left and right-->
                                                <div class="drag-drop-icon"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row mb-4" *ngIf="pattern.get('IsCdfEnabled').value" [hidden]="reorderMessages">
                                        <div class="offset-2 ips-offset-lg-10 col-10 col-lg">
                                            <app-custom-data-field-container area="PromotionMessage" [linkId]="pattern.get('PromotionMessageId').value" [parent]="pattern" minFieldSize="col-12 col-xl-6" (loaded)="cdfLoadedHandler()"></app-custom-data-field-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <button id="createMessage"
                                            class="btn btn-lg btn-block text-left ips-add-bar pr-2"
                                            (click)="addNewPattern(patternGroup)" [ipsFocus]="focusableMessageInput"
                                            [disabled]="disableCreateMessagePatternButton(patternGroup)">
                                        <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
                                        <span class="add-holder-label" translate>CREATE_MESSAGE</span>
                                        <span class="pull-right color-gray tooltip-container" placement="bottom-right" ngbTooltip="{{'TOOLTIP_ADD_MESSAGE_PATTERN' | translate}}">
                                            <i class="fa fa-info-circle"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="form-group col-12">
                                    <button id="createEmptyMessage"
                                            class="btn btn-lg btn-block text-left ips-add-bar pr-2"
                                            (click)="addNewPattern(patternGroup, true)">
                                        <span class="empty-message-icon" aria-hidden="true"></span>
                                        <span class="add-holder-label" translate>CREATE_EMPTY_POSITION</span>
                                        <span class="pull-right color-gray tooltip-container" placement="bottom-right" ngbTooltip="{{'TOOLTIP_ADD_EMPTY_POSITION' | translate}}">
                                            <i class="fa fa-info-circle"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <app-market-edit [parent]="patternGroup"
                                             [marketsModel]="markets"
                                             [label]="'MARKET'"
                                             [balanceCalcService]="promotionMessageService"
                                             [promoStartDate]="parent.value.StartDate"
                                             [promoEndDate]="parent.value.EndDate"
                                             (marketChanged)="marketChangedEventHandler($event)"></app-market-edit>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
