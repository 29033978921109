<div class="col-md-6" *ngIf="translated" ipsFocus="searchInput">
    <ips-list-search headerLabel="{{'RELATED_SPACES' | translate }}"
                     [searchLabel]="searchLabel"
                     [searchLabelPlural]="searchLabelPlural"
                     labelLoading="{{'ACCESSING_SPACE_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_SPACE_INFO' | translate }}"
                     [listData]="space"
                     [busy]="busy"
                     (onListDataChange)="getListData($event.search)"
                     [section]="'main.storeProfileSpace'"
                     [scroller]=true
                     [quickRef]=false
                     [itemClickPermission]="'main.storeProfileSpace.view'"
                     [promise]="promise"
                     [createNewTooltip]="createNewTooltip"
                     [autoFocus]=true></ips-list-search>
</div>
