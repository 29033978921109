var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { OnInit, NgZone, QueryList } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { PatternGroupService, PromotionMessageService } from "../../index";
import { TranslateService } from "@ngx-translate/core";
import { HolderVersionService } from "../../../imagine-ui-ng-store-profile/index";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { PromotionHelperService } from "../../service/promotionHelper.service";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { CustomDataFieldContainerComponent } from "../../../shared/custom-data-field/custom-data-field-container/custom-data-field-container.component";
import { String as IpsString } from "typescript-string-operations";
var PlacementEditComponent = /** @class */ (function () {
    function PlacementEditComponent(translateService, patternGroupService, activeProfileService, ipsModal, promotionMessageService, promotionHelperService, fb, zone, holderVersionService) {
        var _this = this;
        this.translateService = translateService;
        this.patternGroupService = patternGroupService;
        this.activeProfileService = activeProfileService;
        this.ipsModal = ipsModal;
        this.promotionMessageService = promotionMessageService;
        this.promotionHelperService = promotionHelperService;
        this.fb = fb;
        this.zone = zone;
        this.holderVersionService = holderVersionService;
        this.isClone = false;
        this.positionLimitLoaded = false;
        this.loaded = false;
        this.tmpIdIndexer = 1;
        this.positiveNumberPattern = "^\\d+$";
        this.TranslateStrings = {
            "ENTER_POSITIVE_WHOLE_NUMBER": "",
            "MAX_LENGTH_ERROR": ""
        };
        this.errorMessages = {
            "required": function () { return _this.TranslateStrings["ENTER_POSITIVE_WHOLE_NUMBER"]; },
            "pattern": function () { return _this.TranslateStrings["ENTER_POSITIVE_WHOLE_NUMBER"]; },
            "maxlength": function (params) { return IpsString.Format(_this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength); }
        };
    }
    PlacementEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loaded = false;
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(function () { return _this.TranslateText(); });
        this.initializePlacementsForUI();
    };
    PlacementEditComponent.prototype.createFormControls = function (placementGroups, placements) {
        var placementsArray = this.fb.array(this.createPlacementFormArray(placementGroups, placements));
        if (this.placementsFrmArr) {
            this.parent.setControl("placementsFrmArr", placementsArray);
        }
        else {
            this.parent.addControl("placementsFrmArr", placementsArray);
        }
    };
    PlacementEditComponent.prototype.createPlacementFormArray = function (placementGroups, placements) {
        var _this = this;
        var formArray = [];
        placementGroups.forEach(function (placemengGroup) {
            var placementGroupPlacements = placements.filter(function (plc) { return plc.HolderId === placemengGroup.HolderId; });
            var placementFormGroup = _this.createPlacementGroupFormGroup(placemengGroup, placementGroupPlacements);
            formArray.push(placementFormGroup);
        });
        return formArray;
    };
    PlacementEditComponent.prototype.createPlacementGroupFormGroup = function (placementGroup, placements) {
        var _this = this;
        var formGroup = this.fb.group(placementGroup);
        formGroup.addControl("defaultCdfValues", this.fb.control([]));
        if (placementGroup.PositionLocationCountOptions) {
            var locationCountOptionsForms = placementGroup.PositionLocationCountOptions.map(function (option) { return _this.fb.group(option); });
            formGroup.setControl("PositionLocationCountOptions", this.fb.array(locationCountOptionsForms));
        }
        var placementForms = placements.map(function (plc) { return _this.createPlacementFormGroup(plc, formGroup); });
        this.addPlacementGroupListenters(formGroup);
        formGroup.addControl("MessagePlacements", this.fb.array(placementForms));
        return formGroup;
    };
    PlacementEditComponent.prototype.createPlacementFormGroup = function (placement, placementGroup) {
        var _this = this;
        var placementForm = this.fb.group(placement);
        var locationAssignments = [];
        //added listener to printMessage for changes
        var ctrl = placementForm.get("PrintMessage");
        if (ctrl !== null) {
            ctrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
                _this.printMessageChanged(placementForm, value);
            });
        }
        if (placement.MessagePlacement) {
            locationAssignments = Object.assign([], placement.MessagePlacement.LocationAssignments);
            var msgPlacement = this.fb.group(__assign({}, placement.MessagePlacement, { LocationAssignments: this.fb.array(locationAssignments) }));
            if (!placement.MessagePlacement.CustomerItemCode) {
                msgPlacement.addControl("CustomerItemCode", this.fb.control(""));
            }
            if (!placement.MessagePlacement.CategoryCode) {
                msgPlacement.addControl("CategoryCode", this.fb.control(""));
            }
            placementForm.setControl("MessagePlacement", msgPlacement);
        }
        if (placement.MessagePlacementList) {
            var msgPlacementFGs = placement.MessagePlacementList.map(function (item) {
                var grp = _this.fb.group(__assign({}, item, { LocationAssignments: _this.fb.array(locationAssignments) }));
                if (!item.CustomerItemCode) {
                    grp.addControl("CustomerItemCode", _this.fb.control(""));
                }
                if (!item.CategoryCode) {
                    grp.addControl("CategoryCode", _this.fb.control(""));
                }
                return grp;
            });
            var msgPlacements = new FormArray(msgPlacementFGs);
            placementForm.setControl("MessagePlacementSizes", msgPlacements);
            this.getMessagePlacementSizesCtrl(placementForm).controls.forEach(function (item) {
                if (!_this.useMessagePattern) {
                    _this.setCtrlValidationAndSubscription(item, placementGroup);
                }
                else {
                    item.get("CustomerItemCode").setValidators([Validators.maxLength(50)]);
                }
                if (!item.get("Selected").value) {
                    item.get("QuantityIncrease").disable();
                    item.get("FulfillmentOperand").disable();
                    item.patchValue({ FinalQuantity: 0 });
                }
                //added listener to printSize for changes
                _this.addLisentnerToPrintSize(item);
            });
            delete placement.MessagePlacementList;
        }
        else {
            placementForm.setControl("MessagePlacementSizes", new FormArray([]));
        }
        return placementForm;
    };
    PlacementEditComponent.prototype.addPlacementGroupListenters = function (placementGroupForm) {
        var _this = this;
        var ctrl = placementGroupForm.get("SelectedPosition");
        if (ctrl !== null) {
            ctrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
                _this.positionLimitSelected(placementGroupForm);
            });
        }
        var patternTypeCtrl = placementGroupForm.get("SelectedPatternType");
        if (patternTypeCtrl !== null) {
            patternTypeCtrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
                _this.patternTypeSelected(placementGroupForm);
            });
        }
        placementGroupForm.get("QuantityIncrease").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        placementGroupForm.get("QuantityIncrease").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
            _this.patternQtyIncreaseChange(placementGroupForm, value);
        });
        placementGroupForm.get("FulfillmentOperand").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        placementGroupForm.get("FulfillmentOperand").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
            _this.patternFulfillmentOperandChanged(placementGroupForm, value);
        });
    };
    Object.defineProperty(PlacementEditComponent.prototype, "placementsFrmArr", {
        get: function () {
            return this.parent.get("placementsFrmArr");
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Get the list of placements that exist for the specified placementGroup control
     * @param placementGroupCtrl - PlacementGroup control that contains the placements.
     */
    PlacementEditComponent.prototype.getMsgPlacementsCtrl = function (placementGroupCtrl) {
        return placementGroupCtrl.get("MessagePlacements");
    };
    /**
     * Returns the inner MessagePlacement formGroup contained in the PlacementEdit formGroup
     * @param placementCtrl
     */
    PlacementEditComponent.prototype.getMessagePlacementCtrl = function (placementCtrl) {
        return placementCtrl.get("MessagePlacement");
    };
    /**
     * Returns the inner MessagePlacementSizes formGroup contained in the PlacementEdit formGroup
     * @param placementCtrl
     */
    PlacementEditComponent.prototype.getMessagePlacementSizesCtrl = function (placementCtrl) {
        return placementCtrl.get("MessagePlacementSizes");
    };
    PlacementEditComponent.prototype.TranslateText = function () {
        var _this = this;
        this.translateService.get("ALL").subscribe(function () {
            _this.withinFixtureTooltip = _this.translateService.instant("TOOLTIP_PATTERN_WITHIN_FIXTURE");
            _this.acrossFixtureTooltip = _this.translateService.instant("TOOLTIP_PATTERN_ACROSS_FIXTURE");
            _this.placementQtyIncreaseTypePositionTooltip = _this.translateService.instant("TOOLTIP_PLACEMENT_POSITION_QTY_INCREASE_TYPE");
            _this.placementQtyIncreaseTypeTooltip = _this.translateService.instant("TOOLTIP_PLACEMENT_QTY_INCREASE_TYPE");
            for (var _i = 0, _a = Object.keys(_this.TranslateStrings); _i < _a.length; _i++) {
                var key = _a[_i];
                _this.TranslateStrings[key] = _this.translateService.instant(key);
            }
        });
    };
    PlacementEditComponent.prototype.getErrorMessages = function () {
        return this.errorMessages;
    };
    /**
     * Call to set various properties needed by UI.
     */
    PlacementEditComponent.prototype.initializePositionLocationCountsOptions = function (placementGroups) {
        var _this = this;
        this.loaded = false;
        this.positionLimitLoaded = false;
        if (placementGroups[0] === undefined || placementGroups[0].PatternTypeEnum === "None") {
            return Promise.resolve();
        }
        return this.loadPositionLocationCountsOptions(placementGroups, this.patternGroups).then(function () {
            _this.loaded = true;
            _this.positionLimitLoaded = true;
        });
    };
    /**
     * Call this method to get the latest position location counts. Will NOT update control values.
     * @param placementGroups - List of placement groups to get counts for.
     * @param patternGroup - Optional. If not set, will get the first pattern group from the controls. Must pass this in on initial load.
     */
    PlacementEditComponent.prototype.loadPositionLocationCountsOptions = function (placementGroups, patternGroups) {
        var _this = this;
        // Making an assumption for now that we only want position location counts when there is one patternGroup only, so get the first patternGroup.
        if (!patternGroups) {
            patternGroups = this.promotionHelperService.getCurrentPatternGroupsFromForm(this.parent);
        }
        var promoMessages;
        if (this.selectedMessagePattern === "priorityFill") {
            var marketModel_1;
            marketModel_1 = [];
            patternGroups[0].MessagePatterns.map(function (mp) {
                mp.Markets.forEach(function (mar) {
                    marketModel_1.push(mar);
                });
            });
            promoMessages = patternGroups.map(function (pg) {
                pg.Markets.forEach(function (m, i) { return m.Ordinal = i + 1; });
                var promoMessage = {
                    Id: 0,
                    Name: "",
                    PromotionId: _this.promoId,
                    StartDate: _this.parent.value.StartDate,
                    EndDate: _this.parent.value.EndDate,
                    Markets: marketModel_1
                };
                return promoMessage;
            });
        }
        else {
            promoMessages = patternGroups.map(function (pg) {
                pg.Markets.forEach(function (m, i) { return m.Ordinal = i + 1; });
                var promoMessage = {
                    Id: 0,
                    Name: "",
                    PromotionId: _this.promoId,
                    StartDate: _this.parent.value.StartDate,
                    EndDate: _this.parent.value.EndDate,
                    Markets: pg.Markets
                };
                return promoMessage;
            });
        }
        return this.patternGroupService.calculatePositionLocationCounts(promoMessages, placementGroups).then(function (response) {
            //update PlacementGroup With PositionLocationCountsOptions
            response.forEach(function (responsePlacementGroup) {
                var placementGroup = placementGroups.find(function (pg) { return pg.HolderId === responsePlacementGroup.HolderId; });
                if (placementGroup) {
                    var position = void 0;
                    var options = responsePlacementGroup.PositionLocationCountOptions;
                    var maxPosition = options[options.length - 1];
                    if (responsePlacementGroup.PositionLimit === 0 || responsePlacementGroup.PositionLimit === undefined ||
                        responsePlacementGroup.PositionLimit > maxPosition.Position ||
                        (responsePlacementGroup.SelectedPosition !== undefined && responsePlacementGroup.SelectedPosition > maxPosition.Position)) {
                        position = maxPosition.Position;
                    }
                    else {
                        var selected = responsePlacementGroup.PositionLocationCountOptions.filter(function (plo) { return plo.Position === responsePlacementGroup.PositionLimit; })[0];
                        position = selected.Position;
                    }
                    placementGroup.SelectedPatternType = placementGroup.PatternTypeEnum;
                    placementGroup.SelectedPosition = position;
                    placementGroup.PositionLimit = position;
                    placementGroup.PositionLocationCountOptions = responsePlacementGroup.PositionLocationCountOptions;
                }
            });
            return placementGroups;
        });
    };
    PlacementEditComponent.prototype.initializePlacementsForUI = function () {
        var _this = this;
        var placements = [];
        var placementGroups = []; // UI placement group for non-pattern. groups by holder. does NOT match DB
        if (!this.parent.get("ClearAllMessages").value) {
            this.messageAndPlacements.forEach(function (calcResultModel) {
                //Get unique holder list
                var uniqueHolderIds = calcResultModel.MessagePlacements.map(function (item) { return item.HolderId; }).filter(function (v, i, a) { return a.indexOf(v) === i; });
                //Merge all MessagePlacement into an array
                uniqueHolderIds.forEach(function (holderId) {
                    var msgPlacements = calcResultModel.MessagePlacements.filter(function (item) { return item.HolderId === holderId; });
                    // Temporary list of placements for a specific holder, could be from multiple DB placementGroups for non-pattern.
                    msgPlacements[0].MessagePlacementList = [];
                    for (var i = 0; i < msgPlacements.length; i++) {
                        var newPlacement = Object.assign({}, msgPlacements[i].MessagePlacement);
                        newPlacement.Selected = msgPlacements[i].Selected;
                        newPlacement.HolderId = msgPlacements[i].HolderId;
                        if (newPlacement.Height && newPlacement.Width) {
                            newPlacement.Show = true;
                        }
                        msgPlacements[0].MessagePlacementList.push(newPlacement);
                    }
                });
                //Remove unneeded msgPlacements
                for (var i = calcResultModel.MessagePlacements.length - 1; i > 0; i--) {
                    if (!calcResultModel.MessagePlacements[i].MessagePlacementList) {
                        calcResultModel.MessagePlacements.splice(i, 1);
                    }
                }
                calcResultModel.MessagePlacements.forEach(function (placement) {
                    var tempIdIndex = _this.tmpIdIndexer++;
                    placement.DisplayName = placement.FixtureTypeName + " - " + placement.HolderName;
                    placement.loaded = true;
                    var msgPlacement = placement.MessagePlacement;
                    msgPlacement.HolderId = placement.HolderId;
                    msgPlacement.IdName = "placement" + tempIdIndex;
                    msgPlacement.TempId = tempIdIndex;
                    // Set message info
                    msgPlacement.TempMessageId = calcResultModel.PromotionMessage.TempId;
                    msgPlacement.PromotionMessageName = calcResultModel.PromotionMessage.Name;
                    placements = placements.concat(placement);
                });
            });
            this.patternGroups.forEach(function (patternGroup) {
                patternGroup.PlacementGroups.forEach(function (pg) {
                    // Set PrintMessage on the UI placement foreach DB placementGroup
                    placements.filter(function (p) { return p.PromotionMessagePlacementGroupId === pg.Id; }).forEach(function (uiP) { return uiP.PrintMessage = pg.PrintMessage; });
                    //These sums are for patterns only!
                    var positionQty = 0;
                    var finalQuantity = 0;
                    _this.messageAndPlacements.forEach(function (msgItem) {
                        msgItem.MessagePlacements.forEach(function (msgPlacementItem) {
                            if (msgPlacementItem.PromotionMessagePlacementGroupId === pg.Id) {
                                for (var i = 0; i < msgPlacementItem.MessagePlacementList.length; i++) {
                                    //Don't include the fake row results
                                    if (msgPlacementItem.MessagePlacementList[i].Show) {
                                        positionQty += msgPlacementItem.MessagePlacementList[i].HolderCount;
                                        finalQuantity += msgPlacementItem.MessagePlacementList[i].FinalQuantity;
                                    }
                                }
                            }
                        });
                    });
                    // If we don't have a GUI placement group yet, make one. This means the first placement group settings win.
                    if (!placementGroups.some(function (pgItem) { return pgItem.HolderId === pg.HolderId; })) {
                        var plc = placements.find(function (item) { return item.HolderId === pg.HolderId; });
                        // set displayName
                        pg.PatternGroupType = patternGroup.PatternGroupType;
                        pg.SelectedPatternType = pg.PatternTypeEnum ? pg.PatternTypeEnum : "none";
                        pg.SelectedPosition = pg.PositionLimit;
                        pg.PositionQty = positionQty;
                        pg.QuantityIncreaseIdName = "placement" + _this.tmpIdIndexer++;
                        pg.FinalQuantity = finalQuantity;
                        if (plc) {
                            pg.DisplayName = plc.DisplayName;
                            pg.QuantityIncrease = plc.MessagePlacement.QuantityIncrease;
                            pg.QuantityIncreaseTarget = plc.MessagePlacement.QuantityIncreaseTarget || "Holder";
                            pg.FulfillmentOperand = plc.MessagePlacement.FulfillmentOperand;
                            pg.FulfillmentOperator = plc.MessagePlacement.FulfillmentOperator || _this.campaignFulfillmentType;
                            pg.HolderId = plc.HolderId;
                        }
                        else {
                            pg.DisplayName = "";
                            pg.QuantityIncrease = 0;
                            pg.QuantityIncreaseTarget = "Holder";
                            pg.FulfillmentOperand = _this.campaignFulfillmentQty;
                            pg.FulfillmentOperator = _this.campaignFulfillmentType;
                        }
                        placementGroups.push(pg);
                    }
                });
            });
        }
        if (this.isClone) {
            this.zeroOutIds(placementGroups, placements);
        }
        // Add array to avoid console error before asyn operation
        this.parent.addControl("placementsFrmArr", this.fb.array([]));
        if (this.useMessagePattern && placementGroups) {
            this.initializePositionLocationCountsOptions(placementGroups).then(function () {
                _this.createFormControls(placementGroups, placements);
                _this.sortPlacementGroups();
                _this.sortPlacements();
                _this.loaded = true;
            });
        }
        else {
            Promise.all(placementGroups.map(function (pg) { return _this.initializePositionLocationCountsOptions([pg]); })).then(function () {
                _this.createFormControls(placementGroups, placements);
                _this.sortPlacementGroups();
                _this.sortPlacements();
                _this.loaded = true;
            });
        }
    };
    /**
     * Zeros out ids for items that would be saved to the db like placement group, message id, promotion id.
     * @param placementGroups
     * @param placements
     */
    PlacementEditComponent.prototype.zeroOutIds = function (placementGroups, placements) {
        placementGroups.forEach(function (pg) {
            pg.Id = undefined;
            pg.PromotionMessagePatternGroupId = 0;
        });
        placements.forEach(function (p) {
            p.PromotionId = 0;
            p.PromotionMessagePlacementGroupId = undefined; // Can't be 0, causes foreign key constraint error.
            p.MessagePlacement.Id = 0;
            if (p.MessagePlacementList) {
                p.MessagePlacementList.forEach(function (pl) {
                    pl.Id = 0;
                    pl.PromotionMessageId = 0;
                    pl.CustomerItemCode = undefined;
                });
            }
            if (p.MessagePlacementSizes) {
                p.MessagePlacementSizes.forEach(function (pl) {
                    pl.Id = 0;
                    pl.PromotionMessageId = 0;
                    pl.CustomerItemCode = undefined;
                });
            }
        });
    };
    PlacementEditComponent.prototype.shouldShowPlacements = function () {
        return this.loaded && this.placementsFrmArr && this.placementsFrmArr.controls.length > 0;
    };
    PlacementEditComponent.prototype.onHolderPlacementsAdded = function (response) {
        var _this = this;
        //remove placements not selected
        var indexesToRemove = [];
        this.placementsFrmArr.controls.forEach(function (placementGroupCtrl, index) {
            var placementGroup = placementGroupCtrl.value;
            var found = response.some(function (item) {
                return item.Holders.some(function (holder) {
                    return holder.Id === placementGroup.HolderId;
                });
            });
            if (!found) {
                indexesToRemove.push(index);
            }
        });
        if (indexesToRemove.length > 0) {
            indexesToRemove = indexesToRemove.sort();
            // Walk backwards removing items from the end of the formArray
            for (var i = indexesToRemove.length - 1; i >= 0; i--) {
                this.placementsFrmArr.removeAt(indexesToRemove[i]);
            }
            this.placementsFrmArr.markAsDirty();
        }
        //placement to add
        var controls = this.placementsFrmArr.controls;
        var currentTempMsgIds = [];
        var msgs = this.promotionHelperService.getCurrentMessagesFromForm(this.parent);
        var patternGroups = this.promotionHelperService.getCurrentPatternGroupsFromForm(this.parent);
        // Remove duplicates
        msgs = msgs.filter(function (msg) {
            if (currentTempMsgIds.indexOf(msg.TempMessageId) === -1) {
                currentTempMsgIds.push(msg.TempMessageId);
                return true;
            }
            return false;
        });
        var holderIdsAdded = [];
        response.forEach(function (fixtureGroup) {
            fixtureGroup.Holders.forEach(function (holder) {
                var hasHolderId = controls.some(function (ctrl) { return ctrl.value.HolderId === holder.Id; });
                if (!hasHolderId) {
                    holderIdsAdded.push(holder.Id);
                    var placements_1 = _this.createPlacements(fixtureGroup, holder, msgs);
                    var placementGroup = {
                        Id: 0,
                        HolderId: holder.Id,
                        PromotionMessagePatternGroupId: _this.useMessagePattern ? patternGroups[0].Id : 0,
                        PatternGroupType: _this.useMessagePattern ? "MultiMessage" : "SingleMessage",
                        PatternTypeEnum: "None",
                        BusinessIdentity: _this.activeProfileService.businessIdentity,
                        DisplayName: placements_1[0].DisplayName,
                        PositionLimit: _this.useMessagePattern ? undefined : 99999,
                        SelectedPosition: _this.useMessagePattern ? undefined : 99999,
                        SelectedPatternType: "None",
                        PositionQty: 0,
                        QuantityIncreaseIdName: "placement" + _this.tmpIdIndexer++,
                        QuantityIncrease: 0,
                        QuantityIncreaseTarget: "Holder",
                        FulfillmentOperand: _this.campaignFulfillmentQty,
                        FulfillmentOperator: _this.campaignFulfillmentType,
                        FinalQuantity: 0,
                        PrintMessage: true
                    };
                    var newPlacementGroupCtrl_1;
                    if (_this.useMessagePattern) {
                        _this.positionLimitLoaded = false;
                        _this.loadPositionLocationCountsOptions([placementGroup]).then(function (placementGroups) {
                            newPlacementGroupCtrl_1 = _this.createPlacementGroupFormGroup(placementGroups[0], placements_1);
                            _this.placementsFrmArr.push(newPlacementGroupCtrl_1);
                            _this.placementsFrmArr.markAsDirty();
                            _this.calculatePlacementGroupQuantities(newPlacementGroupCtrl_1);
                            _this.positionLimitLoaded = true;
                        });
                    }
                    else {
                        newPlacementGroupCtrl_1 = _this.createPlacementGroupFormGroup(placementGroup, placements_1);
                        _this.placementsFrmArr.push(newPlacementGroupCtrl_1);
                        _this.placementsFrmArr.markAsDirty();
                    }
                }
            });
        });
        if (!this.useMessagePattern && this.selectedMessagePattern === "priorityFill" && holderIdsAdded.length > 0) {
            this.calculateAllPriorityFillPlacementQuantities(msgs, holderIdsAdded);
        }
        else if (!this.useMessagePattern && holderIdsAdded.length > 0) {
            for (var i = 0; i < msgs.length; i++) {
                this.calculateAllPlacementQuantities(msgs[i].TempMessageId, holderIdsAdded);
            }
        }
    };
    PlacementEditComponent.prototype.createPlacements = function (fixtureGroup, holder, messages) {
        var _this = this;
        var ret = [];
        messages.forEach(function (msg) {
            var placement = _this.getNewPlacement(msg, fixtureGroup.Name, holder);
            ret.push(placement);
        });
        return ret;
    };
    PlacementEditComponent.prototype.getNewPlacement = function (mainMsg, fixtureGroupName, holder) {
        // Generate a new placement for a given holder and its fixture group (aka fixtureType)
        var messagePlacement = this.createNewPromotionMessagePlacementModel(holder.Id, mainMsg.MessageName, mainMsg.Id, mainMsg.TempMessageId);
        var placement = {
            HolderId: holder.Id,
            HolderName: holder.Name,
            FixtureTypeName: fixtureGroupName,
            PromotionId: this.promoId,
            DisplayName: fixtureGroupName + " - " + holder.Name,
            Selected: true,
            PromotionMessagePlacementGroupId: null,
            MessagePlacement: messagePlacement,
            MessagePlacementList: [messagePlacement],
            PrintMessage: true,
            loaded: true
        };
        return placement;
    };
    PlacementEditComponent.prototype.createNewPromotionMessagePlacementModel = function (holderId, promotionMessageName, promotionMessageId, promotionMessageTempMessageId) {
        var index = this.tmpIdIndexer++;
        return {
            Id: 0,
            IdName: "placement" + index,
            TempId: index,
            HolderId: holderId,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            PromotionMessageName: promotionMessageName,
            FinalQuantity: 0,
            FulfillmentOperand: this.campaignFulfillmentQty,
            FulfillmentOperator: this.campaignFulfillmentType,
            HolderCount: 0,
            LocationCount: 0,
            LocationAssignments: [],
            PromotionMessageId: promotionMessageId,
            QuantityIncrease: 0,
            QuantityIncreaseTarget: "Holder",
            TempMessageId: promotionMessageTempMessageId,
            UseAllSizes: this.useMessagePattern,
            Selected: true,
            CustomerItemCode: "",
            HolderVersionInfoName: undefined,
            CategoryCode: ""
        };
    };
    PlacementEditComponent.prototype.reSortPlacementAndCalculateHolderQuantity = function () {
        if (this.placementsFrmArr.length === 0) {
            return;
        }
        //re-order placements within the groups
        this.sortPlacements();
        this.calculateAllPlacementQuantities(null);
    };
    /**
     * Order placement groups relative to each other by displayname
     */
    PlacementEditComponent.prototype.sortPlacementGroups = function () {
        // Sort placementGroups by DisplayName
        var placementGroups = this.placementsFrmArr;
        placementGroups.controls = placementGroups.controls.sort(function (n1, n2) { return (n1.value.DisplayName.localeCompare(n2.value.DisplayName)); });
    };
    /**
     * Loops through each placement group and orders placements within each group by messageName or ordinal
     */
    PlacementEditComponent.prototype.sortPlacements = function () {
        var _this = this;
        var currentMessages = this.promotionHelperService.getCurrentMessagesFromForm(this.parent);
        this.placementsFrmArr.controls.forEach(function (ctrl) { return _this.sortPlacementsWithinPlacementGroup(ctrl, currentMessages); });
    };
    /**
     * For a given placement group, will order messages by name if nonPattern, or will order messages by ordinal if pattern.
     * @param placementGroupCtrl
     */
    PlacementEditComponent.prototype.sortPlacementsWithinPlacementGroup = function (placementGroupCtrl, currentMessages) {
        var placmentGroup = placementGroupCtrl.value;
        if (placmentGroup.PatternGroupType === "MultiMessage") {
            var messgePlacements = this.getMsgPlacementsCtrl(placementGroupCtrl);
            messgePlacements.controls = messgePlacements.controls.sort(function (n1, n2) {
                // get ordinal from first message found based on tempId, will have the lowest ordinal since they are kept in order.
                var n1Msg = currentMessages.find(function (msg) { return msg.TempMessageId ===
                    n1.value.MessagePlacement.TempMessageId; });
                var n2Msg = currentMessages.find(function (msg) { return msg.TempMessageId ===
                    n2.value.MessagePlacement.TempMessageId; });
                return n1Msg.Ordinal - n2Msg.Ordinal;
            });
        }
    };
    PlacementEditComponent.prototype.getOperatorIconName = function (operator) {
        var result = "NA";
        switch (operator) {
            case "Percent":
                result = "fa-percent";
                break;
            case "Number":
                result = "fa-hashtag";
                break;
        }
        return result;
    };
    PlacementEditComponent.prototype.getIncreaseTargetNameKey = function (increaseTarget) {
        var result = "NA";
        switch (increaseTarget) {
            case "Location":
                result = "LOCATION";
                break;
            case "Holder":
                if (this.useMessagePattern) {
                    result = "POSITION";
                }
                else {
                    result = "HOLDER";
                }
                break;
        }
        return result;
    };
    PlacementEditComponent.prototype.getIncreaseTargetTooltip = function () {
        return this.useMessagePattern ? this.placementQtyIncreaseTypePositionTooltip : this.placementQtyIncreaseTypeTooltip;
    };
    PlacementEditComponent.prototype.patternTypeClick = function (placementGroupCtrl) {
        var placementGroup = placementGroupCtrl.value;
        var newPatternType = placementGroup.PatternTypeEnum === "AcrossFixtures" ? "WithinFixture" : "AcrossFixtures";
        placementGroupCtrl.patchValue({
            PatternTypeEnum: newPatternType
        });
        placementGroupCtrl.markAsDirty();
        // reload positionLocationCountOptions and recalculate placements
        this.loadPositionLocationCountsOptionsAndReCalculatePlacement([placementGroupCtrl]);
    };
    /**
     * Call this when type is changed
     * @param placementCtrl - placement control at size level
     * @param placementGroup - placement group control of placement control
     */
    PlacementEditComponent.prototype.qtyIncreaseTypeClick = function (placementGroup, placementCtrl) {
        placementCtrl.patchValue({
            QuantityIncreaseTarget: placementCtrl.value.QuantityIncreaseTarget === "Holder" ? "Location" : "Holder"
        });
        placementCtrl.markAsDirty();
        this.calculatePlacementQuantities(placementGroup, placementCtrl);
    };
    PlacementEditComponent.prototype.qtyIncreaseChange = function (placementCtrl, placementGroup) {
        this.calculatePlacementQuantities(placementGroup, placementCtrl);
    };
    /**
     * Call when fulfillment type changes
     * @param placementCtrl - - placement control at size level
     * @param placementGroup - placement group control of placement control
     */
    PlacementEditComponent.prototype.fulfillmentOperatorClick = function (placementGroup, placementCtrl) {
        //const msgPlacementCtrl = this.getMessagePlacementCtrl(placementCtrl);
        //let msgPlacement = msgPlacementCtrl.value as PromotionMessagePlacementModel;
        placementCtrl.patchValue({
            FulfillmentOperator: placementCtrl.value.FulfillmentOperator === "Percent" ? "Number" : "Percent"
        });
        placementCtrl.markAsDirty();
        this.calculatePlacementQuantities(placementGroup, placementCtrl);
    };
    PlacementEditComponent.prototype.fulfillmentOperandChanged = function (msgPlacementCtrl, placementGroup) {
        this.calculatePlacementQuantities(placementGroup, msgPlacementCtrl);
    };
    PlacementEditComponent.prototype.positionLimitSelected = function (placementGroupCtrl) {
        placementGroupCtrl.patchValue({
            PositionLimit: Number(placementGroupCtrl.get("SelectedPosition").value)
        });
        placementGroupCtrl.markAsDirty();
        this.calculatePlacementGroupQuantities(placementGroupCtrl);
    };
    PlacementEditComponent.prototype.patternTypeSelected = function (placementGroupCtrl) {
        var val = placementGroupCtrl.get("SelectedPatternType").value;
        placementGroupCtrl.patchValue({
            PatternTypeEnum: val
        });
        placementGroupCtrl.markAsDirty();
        // reload positionLocationCountOptions and recalculate placements
        if (val === "None") {
            placementGroupCtrl.patchValue({
                SelectedPosition: 99999
            });
            this.positionLimitSelected(placementGroupCtrl);
        }
        else {
            this.loadPositionLocationCountsOptionsAndReCalculatePlacement([placementGroupCtrl]);
        }
    };
    PlacementEditComponent.prototype.onMainMessageAdded = function (mainMessage) {
        var _this = this;
        // Only work with non-empty messages.
        if (mainMessage.TempMessageId !== undefined && mainMessage.TempMessageId !== null) {
            var placementGroupCtrls = this.placementsFrmArr.controls;
            placementGroupCtrls.forEach(function (ctrl) {
                var existingPlacements = _this.getMsgPlacementsCtrl(ctrl);
                var existingPlacement = existingPlacements.at(0).value;
                var fixtureGroupName = existingPlacement.FixtureTypeName;
                var holder = {
                    Name: existingPlacement.HolderName,
                    Id: existingPlacement.HolderId
                };
                var newPlacement = _this.getNewPlacement(mainMessage, fixtureGroupName, holder);
                var placementCtrl = _this.createPlacementFormGroup(newPlacement, ctrl);
                existingPlacements.push(placementCtrl);
            });
        }
        this.calculateAllPlacementQuantities(mainMessage.TempMessageId);
    };
    /**
     * We call DB to get new position limits, then update the controls, and optionally call calculate location balance.
     * @param placementGroupCtrl
     * @param doCalculate - defaults to true. Set to false when you want to update the location counts without calculating. Current use
     * case is when markets change, we want one large recalc instead of doing a recalc here.
     */
    PlacementEditComponent.prototype.loadPositionLocationCountsOptionsAndReCalculatePlacement = function (placementGroupCtrls, doCalculate) {
        var _this = this;
        if (doCalculate === void 0) { doCalculate = true; }
        this.positionLimitLoaded = false;
        var placementGroupValues = placementGroupCtrls.map(function (ctrl) { return ctrl.value; });
        return this.loadPositionLocationCountsOptions(placementGroupValues).then(function (placementGroups) {
            placementGroups.forEach(function (placementGroup) {
                var placementGroupCtrl = placementGroupCtrls.find(function (ctrl) { return ctrl.value.HolderId === placementGroup.HolderId; });
                placementGroupCtrl.setControl("PositionLocationCountOptions", _this.fb.array(placementGroup.PositionLocationCountOptions.map(function (pg) { return _this.fb.group(pg); })));
                var positionIndex = placementGroup.PositionLocationCountOptions[placementGroup.PositionLocationCountOptions.length - 1].Position;
                placementGroupCtrl.patchValue({ SelectedPosition: positionIndex, PositionLimit: positionIndex }, { emitEvent: doCalculate });
                placementGroupCtrl.markAsDirty();
            });
            _this.positionLimitLoaded = true;
        });
    };
    PlacementEditComponent.prototype.onPatternMessageOrdinalChanged = function () {
        var _this = this;
        // Use zone.run to make sure the GUI updates.
        this.zone.run(function () {
            _this.reSortPlacementAndCalculateHolderQuantity();
        });
    };
    PlacementEditComponent.prototype.onMainMessageDeleted = function (mainMessage) {
        var _this = this;
        var placementGroupsToRemove = [];
        //For this.placementsFrmArr.controls, each entry is a placement group.
        //For placementsCtrl.controls, each entry is a placement w/ MessagePlacement property.
        this.placementsFrmArr.controls.forEach(function (placementGroupsCtrl, index) {
            var placementsCtrl = _this.getMsgPlacementsCtrl(placementGroupsCtrl);
            placementsCtrl.controls.some(function (placementControl, placeIndex) {
                var placement = placementControl.value;
                if (placement.MessagePlacement.TempMessageId === mainMessage.TempMessageId) {
                    placementsCtrl.removeAt(placeIndex);
                    // Should only be one placement per message, break out.
                    return true;
                }
                // tell the 'some' loop to keep going.
                return false;
            });
            if (placementsCtrl.controls.length === 0) {
                placementGroupsToRemove.push(index);
            }
        });
        for (var i = placementGroupsToRemove.length - 1; i >= 0; i--) {
            this.placementsFrmArr.removeAt(placementGroupsToRemove[i]);
        }
        if (this.useMessagePattern) {
            this.reSortPlacementAndCalculateHolderQuantity();
        }
        if (this.selectedMessagePattern === "priorityFill") {
            var currentPatternGroups = this.promotionHelperService.getCurrentPatternGroupsFromForm(this.parent);
            var patternGroup = currentPatternGroups[0];
            this.calculateAllPriorityFillPlacementQuantities(patternGroup.MessagePatterns);
        }
    };
    PlacementEditComponent.prototype.onMessageNameChanged = function (messageInfo) {
        var _this = this;
        this.placementsFrmArr.controls.forEach(function (placementGroupCtrl) {
            var placements = _this.getMsgPlacementsCtrl(placementGroupCtrl);
            placements.controls.some(function (placementControl, placeIndex) {
                var placement = placementControl.value;
                if (placement.MessagePlacement.TempMessageId === messageInfo.TempMessageId) {
                    var ctrl = _this.getMessagePlacementCtrl(placementControl);
                    ctrl.patchValue({
                        PromotionMessageName: messageInfo.MessageName
                    });
                    var sizeCtrl = _this.getMessagePlacementSizesCtrl(placementControl);
                    if (sizeCtrl && sizeCtrl.controls) {
                        sizeCtrl.controls.forEach(function (ct) {
                            ct.patchValue({
                                PromotionMessageName: messageInfo.MessageName
                            });
                        });
                    }
                    // Should only be one placement per message, break out.
                    return true;
                }
                // tell the 'some' loop to keep going.
                return false;
            });
        });
    };
    PlacementEditComponent.prototype.removePlacement = function (index) {
        this.placementsFrmArr.removeAt(index);
        this.placementsFrmArr.markAsDirty();
    };
    PlacementEditComponent.prototype.showLocationBalanceModal = function (placementCtrl, placementGroupCtrl) {
        var _this = this;
        var msgPlacement = placementCtrl.value;
        if (msgPlacement.LocationAssignments && msgPlacement.LocationAssignments.length) {
            this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
                resolve: { search: "listbylocation", listOfLocationIds: msgPlacement.LocationAssignments }
            }, {
                windowClass: "no-list-group-item-interaction"
            });
        }
        else if (msgPlacement.LocationCount === 0) {
            this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
                resolve: { search: "listbylocation", listOfLocationIds: [] }
            }, {
                windowClass: "no-list-group-item-interaction"
            });
        }
        else {
            var balanceCalcModel = this.deriveMessagePlacementCalculationModel(placementCtrl, true, placementGroupCtrl);
            var locationListRequest = {
                MessagePlacementCalculationDetail: balanceCalcModel,
                TargetMessageTempId: msgPlacement.TempMessageId,
                HolderId: msgPlacement.HolderId,
                Width: msgPlacement.Width,
                Height: msgPlacement.Height,
                HolderversionInfoName: msgPlacement.HolderVersionInfoName
            };
            this.promotionMessageService.calculatePlacementLocationBalanceList(locationListRequest).then(function (locationIdList) {
                _this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
                    resolve: { search: "listbylocation", listOfLocationIds: locationIdList.map(Number) }
                }, {
                    windowClass: "no-list-group-item-interaction"
                });
            });
        }
    };
    /**
     * Call this method to recalculate quantities for all placements, pattern or non-pattern.
     * @param tempMessageId - If null or undefined, must be a MultiMessage pattern. Otherwise patternGroup for specified TempMessageId will be used
     * and only placements for that tempMessageId will be used.
     * @param holderIds - Limit the placements recalculated to this list of holder ids. Used when adding new placements.
     */
    PlacementEditComponent.prototype.calculateAllPlacementQuantities = function (tempMessageId, holderIds) {
        var _this = this;
        var patternGroup;
        if (tempMessageId === undefined || tempMessageId === null) {
            var patternGroups = this.promotionHelperService.getCurrentPatternGroupsFromForm(this.parent);
            patternGroup = patternGroups[0];
            var patternGroupType = patternGroup.PatternGroupType;
            if (patternGroupType === "SingleMessage") {
                throw new Error("tempMessageId must not be undefined when working with nonPatterns");
            }
        }
        else {
            patternGroup = this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, tempMessageId);
        }
        patternGroup.StartDate = this.parent.value.StartDate;
        patternGroup.EndDate = this.parent.value.EndDate;
        // message placements / placement groups
        var balanceCalcModel = this.GetPlacementModelForMarketCalc(patternGroup, holderIds);
        balanceCalcModel.PatternGroupInfo = patternGroup;
        balanceCalcModel.ReturnLocationBalance = false;
        if (this.selectedMessagePattern === "priorityFill") {
            if (balanceCalcModel.PatternGroupInfo) {
                balanceCalcModel.PatternGroupInfo.Markets = balanceCalcModel.PatternGroupInfo.MessagePatterns[0].Markets;
                balanceCalcModel.PatternGroupInfoForPriorityFill = [];
                balanceCalcModel.PatternGroupInfoForPriorityFill.push(balanceCalcModel.PatternGroupInfo);
            }
            this.promotionMessageService.calculatePriorityFillLocationBalance(balanceCalcModel).then(function (response) {
                if (response !== null) {
                    _this.UpdatePlacementsAfterCalculation(response.MessagePlacements, patternGroup);
                }
            });
        }
        else {
            // Call server to get new totals
            this.promotionMessageService.calculateLocationBalance(balanceCalcModel).then(function (response) {
                if (response !== null) {
                    _this.UpdatePlacementsAfterCalculation(response.MessagePlacements, patternGroup);
                }
            });
        }
    };
    PlacementEditComponent.prototype.calculatePlacementQuantitiesByPlacementGroup = function (plcGroup) {
        var _this = this;
        plcGroup.MessagePlacements.forEach(function (x) {
            _this.calculateAllPlacementQuantities(x.MessagePlacement.TempMessageId, [plcGroup.HolderId]);
        });
    };
    PlacementEditComponent.prototype.calculateAllPriorityFillPlacementQuantities = function (messageData, holderIds) {
        var _this = this;
        var patternGroup;
        var patternGroupData;
        for (var i = 0; i < messageData.length; i++) {
            var tempMessageId = messageData[i].TempMessageId;
            if (!patternGroup) {
                patternGroup = this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, tempMessageId);
            }
        }
        var balanceCalcModel = this.GetPlacementModelForMarketCalc(patternGroup, holderIds);
        patternGroup.StartDate = this.parent.value.StartDate;
        patternGroup.EndDate = this.parent.value.EndDate;
        balanceCalcModel.PatternGroupInfo = patternGroup;
        if (balanceCalcModel.PatternGroupInfo) {
            balanceCalcModel.PatternGroupInfo.Markets = balanceCalcModel.PatternGroupInfo.MessagePatterns[0].Markets;
            //Added PR 8873 "Unhide Priority Fill"
            balanceCalcModel.PatternGroupInfoForPriorityFill = [];
            balanceCalcModel.PatternGroupInfoForPriorityFill.push(balanceCalcModel.PatternGroupInfo);
        }
        balanceCalcModel.ReturnLocationBalance = false;
        // Call server to get new totals
        this.promotionMessageService.calculatePriorityFillLocationBalance(balanceCalcModel).then(function (response) {
            if (response !== null) {
                _this.UpdatePlacementsAfterCalculation(response.MessagePlacements, patternGroupData);
            }
        });
    };
    /**
      * Call this to recalculate for all placements in a single placementGroup. This only works when the patternGroup is multiMessage patternGroup and all placements
      * below the placementGroup are using the same markets
      * @param placementGroupCtrl
      */
    PlacementEditComponent.prototype.calculatePlacementGroupQuantities = function (placementGroupCtrl) {
        var plcGroupVal = placementGroupCtrl.value;
        if (plcGroupVal.PatternGroupType === "SingleMessage") {
            this.calculatePlacementQuantitiesByPlacementGroup(plcGroupVal);
        }
        else {
            this.calculatePlacementQuantities(placementGroupCtrl);
        }
    };
    /**
     * Calls the DB to calculate HolderCount, FinalQuantity, and LocationCount
     * @param messagePlacement - only passed in by togglePrintMessage() The Message Placement control has messagePlacementSizes. It's loaded property is used to hide the no holder message
     * @param placementCtrl - Placement control is used only to check if FulfillmentOperand/QuantityIncrease has a value
     * @param placementGroupCtrl - Placement Group control that contains all the placement info.
     */
    PlacementEditComponent.prototype.calculatePlacementQuantities = function (placementGroupCtrl, placementCtrl, messagePlacement) {
        var _this = this;
        // only recalc if we have a valid value
        var msgPlacement;
        if (this.useMessagePattern) {
            //message pattern - only placementGroup is passed in
            msgPlacement = placementGroupCtrl.value;
        }
        else {
            msgPlacement = placementCtrl.value;
        }
        if ((msgPlacement.FulfillmentOperand !== null &&
            msgPlacement.FulfillmentOperand >= 0) &&
            (msgPlacement.QuantityIncrease !== null &&
                msgPlacement.QuantityIncrease >= 0)) {
            var balanceCalcModel = this.deriveMessagePlacementCalculationModel(placementCtrl, false, placementGroupCtrl);
            //set Message Placement loaded to false so that the no holder message won't shown
            if (messagePlacement) {
                messagePlacement.patchValue({ loaded: false });
            }
            if (this.selectedMessagePattern === "priorityFill") {
                this.promotionMessageService.calculatePriorityFillLocationBalance(balanceCalcModel).then(function (response) {
                    if (response !== null) {
                        _this.updatePlacementQuantities(response.MessagePlacements, placementGroupCtrl);
                        //set Message Placement loaded to true after it's loaded
                        if (messagePlacement) {
                            messagePlacement.patchValue({ loaded: true });
                        }
                    }
                });
            }
            else {
                this.promotionMessageService.calculateLocationBalance(balanceCalcModel).then(function (response) {
                    if (response !== null) {
                        _this.updatePlacementQuantities(response.MessagePlacements, placementGroupCtrl);
                        //set Message Placement loaded to true after the it's loaded
                        if (messagePlacement) {
                            messagePlacement.patchValue({ loaded: true });
                        }
                    }
                });
            }
        }
    };
    PlacementEditComponent.prototype.UpdatePostionLocationCountsForMarketCalc = function () {
        if (this.useMessagePattern && this.placementsFrmArr.controls.length > 0) {
            // Reload position location counts
            return this.loadPositionLocationCountsOptionsAndReCalculatePlacement(this.placementsFrmArr.controls, false);
        }
        else {
            return Promise.resolve();
        }
    };
    PlacementEditComponent.prototype.GetPlacementModelForMarketCalc = function (patternGroup, holderIds) {
        var result = {
            MessagePlacements: [],
            PlacementGroups: [],
            ReturnLocationBalance: false,
            PatternGroupInfo: undefined,
            PlacementElements: undefined,
            DefaultFulfillmentOperator: this.campaignFulfillmentType,
            DefaultFulfillmentQuantity: this.campaignFulfillmentQty
        };
        var tempMsgIds = patternGroup.MessagePatterns.map(function (msg) { return msg.TempMessageId; });
        // Foreach placementGroup, find the messages that match the patternGroup messageid
        if (this.placementsFrmArr) {
            this.placementsFrmArr.controls.forEach(function (placementGroupCtrl) {
                var calcPlaceGroup = Object.assign({}, placementGroupCtrl.value);
                var placements = calcPlaceGroup.MessagePlacements.filter(function (plc) { return tempMsgIds.some(function (tmp) { return tmp === plc.MessagePlacement.TempMessageId &&
                    (!holderIds || holderIds.some(function (h) { return h === plc.HolderId; })); }); });
                if (placements && placements.length > 0) {
                    calcPlaceGroup.MessagePlacements = undefined;
                    calcPlaceGroup.PositionLocationCountOptions = undefined;
                    calcPlaceGroup.PositionLimit = calcPlaceGroup.SelectedPosition;
                    calcPlaceGroup.SelectedPosition = undefined;
                    calcPlaceGroup.SelectedPatternType = undefined;
                    result.PlacementGroups.push(calcPlaceGroup);
                    result.MessagePlacements = result.MessagePlacements.concat(placements);
                }
            });
        }
        return result;
    };
    PlacementEditComponent.prototype.UpdatePlacementsAfterCalculation = function (messagePlacements, patternGroup) {
        var _this = this;
        this.placementsFrmArr.controls.forEach(function (placementGroupCtrl) {
            _this.updatePlacementQuantities(messagePlacements, placementGroupCtrl);
        });
    };
    /**
     * Call this function whenever there are calculation results that need to be updated on the screen
     * holder qantity.
     * @param placements - List of placements returned by calculation model
     * @param placementGroupCtrl - placementGroup to update.
     */
    PlacementEditComponent.prototype.updatePlacementQuantities = function (placements, placementGroupCtrl) {
        var _this = this;
        var placementsCtrl = this.getMsgPlacementsCtrl(placementGroupCtrl);
        var placementGroup = placementGroupCtrl.value;
        var finalQty = 0;
        var posQty = 0;
        placements.forEach(function (calculatedPlace) {
            if (calculatedPlace.HolderId === placementGroup.HolderId) {
                // Find the placement in this group
                var placementCtrl = placementsCtrl.controls.find(function (plc) { return plc.value.MessagePlacement.TempMessageId ===
                    calculatedPlace.MessagePlacement.TempMessageId; });
                // find the placement control by size
                var controls = _this.getMessagePlacementSizesCtrl(placementCtrl).controls;
                var foundPlacementCtrl = controls.find(function (plcCtrl) {
                    return plcCtrl.value.TempMessageId === calculatedPlace.MessagePlacement.TempMessageId &&
                        plcCtrl.value.Width === calculatedPlace.MessagePlacement.Width &&
                        plcCtrl.value.Height === calculatedPlace.MessagePlacement.Height &&
                        // NOTE: double equal compare on purpose. when name comes back null from backend, the triple equal wasn't working here, was considered different, and then
                        // we were showing an empty placement with no sizes since it happened to be the null weight record.
                        plcCtrl.value.HolderVersionInfoName == calculatedPlace.MessagePlacement.HolderVersionInfoName;
                } // tslint:disable-line
                );
                if (foundPlacementCtrl) {
                    foundPlacementCtrl.patchValue({
                        HolderCount: calculatedPlace.MessagePlacement.HolderCount,
                        FinalQuantity: calculatedPlace.MessagePlacement.FinalQuantity,
                        LocationCount: calculatedPlace.MessagePlacement.LocationCount,
                    });
                    if (_this.selectedMessagePattern === "priorityFill")
                        foundPlacementCtrl.setControl("LocationAssignments", _this.fb.array(calculatedPlace.MessagePlacement.LocationAssignments));
                }
                else {
                    //Lets add the new size
                    var existingMsgPlacement = placementCtrl.value.MessagePlacementSizes[0];
                    var newMsgPlacement = _this.createNewPromotionMessagePlacementModel(existingMsgPlacement.HolderId, existingMsgPlacement.PromotionMessageName, existingMsgPlacement.PromotionMessageId, existingMsgPlacement.TempMessageId);
                    Object.assign(newMsgPlacement, calculatedPlace.MessagePlacement);
                    newMsgPlacement.Show = true;
                    newMsgPlacement.HolderVersionInfoName = calculatedPlace.MessagePlacement.HolderVersionInfoName;
                    var plcGrpCtrl = _this.fb.group(__assign({}, newMsgPlacement, { LocationAssignments: _this.fb.array(newMsgPlacement.LocationAssignments) }));
                    if (!_this.useMessagePattern) {
                        _this.setCtrlValidationAndSubscription(plcGrpCtrl, placementGroupCtrl);
                    }
                    else {
                        plcGrpCtrl.get("CustomerItemCode").setValidators([Validators.maxLength(50)]);
                    }
                    _this.addLisentnerToPrintSize(plcGrpCtrl);
                    _this.getMessagePlacementSizesCtrl(placementCtrl).push(plcGrpCtrl);
                }
                //Lets clear out the sizes that no longer exist
                var messagePlacementSizes = placementCtrl.get("MessagePlacementSizes");
                if (_this.selectedMessagePattern !== "priorityFill") {
                    for (var i = messagePlacementSizes.controls.length - 1; i >= 0; i--) {
                        //Make sure to leave the null size row
                        if (messagePlacementSizes.controls[i].get("Height") === null && messagePlacementSizes.controls[i].get("Width") === null) {
                            continue;
                        }
                        if (_this.checkIfExists(placements, messagePlacementSizes.controls[i])) {
                            messagePlacementSizes.removeAt(i);
                        }
                    }
                }
                finalQty += calculatedPlace.MessagePlacement.FinalQuantity;
                posQty += calculatedPlace.MessagePlacement.HolderCount;
                //placementCtrl.patchValue({ "HolderVersionInfoName": calculatedPlace.HolderVersionInfoName };
            }
        });
        //update PositionQTY and FinalQTY for pattern
        if (this.useMessagePattern) {
            placementGroupCtrl.patchValue({
                "PositionQty": posQty,
                "FinalQuantity": finalQty
            });
        }
    };
    PlacementEditComponent.prototype.setCtrlValidationAndSubscription = function (placementCtrl, placementGroupCtrl) {
        var _this = this;
        placementCtrl.get("QuantityIncrease").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        placementCtrl.get("FulfillmentOperand").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        placementCtrl.get("CustomerItemCode").setValidators([Validators.maxLength(50)]);
        placementCtrl.get("QuantityIncrease").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
            _this.qtyIncreaseChange(placementCtrl, placementGroupCtrl);
        });
        placementCtrl.get("FulfillmentOperand").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function () {
            _this.fulfillmentOperandChanged(placementCtrl, placementGroupCtrl);
        });
    };
    PlacementEditComponent.prototype.checkIfExists = function (placements, placementSize) {
        return !placements.find(function (item) {
            return item.MessagePlacement.TempMessageId === placementSize.get("TempMessageId").value &&
                item.MessagePlacement.Height === placementSize.get("Height").value &&
                item.MessagePlacement.Width === placementSize.get("Width").value &&
                placementSize.get("HolderVersionInfoName") ? item.MessagePlacement.HolderVersionInfoName === placementSize.get("HolderVersionInfoName").value : true;
        });
    };
    /**
     * Will return a single placementGroup for the given placement. Use when updating just one placement row.
     * @param placementCtrl - control that has placement detail
     * @param forLocationBalance - true to calculatie location balance
     * @param placementGroupCtrl - control that has a list of MessagePlacements
     */
    PlacementEditComponent.prototype.deriveMessagePlacementCalculationModel = function (placementCtrl, forLocationBalance, placementGroupCtrl) {
        if (forLocationBalance === void 0) { forLocationBalance = false; }
        var ret = {};
        var placementsForCalc = [];
        var placementGroupData = [];
        var calcPlacementGroup;
        calcPlacementGroup = Object.assign({}, placementGroupCtrl.value);
        // assmble MessagePlacements for non-pattern message
        // UI stored the placement details in MessagePlacementSizes under the first item in MessagePlacements
        // recreate MessagePlacements by MessagePlacementSizes
        if (calcPlacementGroup.PatternGroupType === "SingleMessage") {
            var msgPlacement = calcPlacementGroup.MessagePlacements.find(function (mp) { return mp.MessagePlacement.TempMessageId ===
                placementCtrl.value.TempMessageId; });
            msgPlacement.MessagePlacementSizes.forEach(function (mp) {
                var plcModel = Object.assign({}, calcPlacementGroup.MessagePlacements[0]);
                plcModel.MessagePlacement = mp;
                placementsForCalc.push(plcModel);
            });
        }
        else if (calcPlacementGroup.PatternGroupType === "MultiMessage") {
            // assmble MessagePlacements for pattern message
            // UI stored the placement details in MessagePlacementSizes under the first item in MessagePlacements
            calcPlacementGroup.MessagePlacements.forEach(function (mp) {
                //the placement detail are the same for all sizes, so only send in the first one for calculation
                //set useAllSizes to true
                var msgPlacement = mp.MessagePlacementSizes.find(function (mps) { return mps.Width === undefined; });
                if (!msgPlacement) {
                    msgPlacement = mp.MessagePlacementSizes[0];
                }
                if (msgPlacement) {
                    mp.MessagePlacement = msgPlacement;
                }
                mp.MessagePlacement.UseAllSizes = true;
            });
            placementsForCalc = calcPlacementGroup.MessagePlacements;
        }
        else if (calcPlacementGroup.PatternGroupType === "PriorityMessage") {
            var targetTempMessageId_1 = placementCtrl.value.TempMessageId;
            var msgPlacement = calcPlacementGroup.MessagePlacements.find(function (mp) { return mp.MessagePlacement.TempMessageId === targetTempMessageId_1; });
            var initialPlacement_1 = calcPlacementGroup.MessagePlacements[0];
            msgPlacement.MessagePlacementSizes.forEach(function (mp) {
                var plcModel = Object.assign({}, initialPlacement_1);
                plcModel.MessagePlacement = mp;
                placementsForCalc.push(plcModel);
            });
            var otherPlacements = calcPlacementGroup.MessagePlacements.filter(function (mp) { return mp.MessagePlacement.TempMessageId !== targetTempMessageId_1; });
            otherPlacements.forEach(function (otherPlacement) {
                otherPlacement.MessagePlacementSizes.forEach(function (mps) {
                    var plcModel = Object.assign({}, otherPlacement);
                    plcModel.MessagePlacement = mps;
                    placementsForCalc.push(plcModel);
                });
            });
        }
        calcPlacementGroup.MessagePlacements = undefined; // Clear these out so we don't send over the wire.
        calcPlacementGroup.PositionLimit = calcPlacementGroup.SelectedPosition; // The gui doesn't set PositionLimit, just selectedPosition limit
        placementGroupData.push(calcPlacementGroup);
        var targetedPatternGroup = this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, placementsForCalc[0].MessagePlacement.TempMessageId);
        if (calcPlacementGroup.PatternGroupType === "PriorityMessage") {
            var targetMessageGroup = targetedPatternGroup.MessagePatterns.find(function (n) { return n.TempMessageId === placementsForCalc[0].MessagePlacement.TempMessageId; });
            if (targetMessageGroup) {
                targetedPatternGroup.Markets = Object.assign([], targetMessageGroup.Markets);
            }
        }
        ret = {
            PromotionMessage: undefined,
            MessagePlacements: placementsForCalc,
            ReturnLocationBalance: forLocationBalance,
            PatternGroupInfo: targetedPatternGroup,
            PatternGroupInfoForPriorityFill: [],
            PlacementGroups: placementGroupData,
            DefaultFulfillmentOperator: this.campaignFulfillmentType,
            DefaultFulfillmentQuantity: this.campaignFulfillmentQty
        };
        ret.PatternGroupInfo.StartDate = this.parent.value.StartDate;
        ret.PatternGroupInfo.EndDate = this.parent.value.EndDate;
        return ret;
    };
    PlacementEditComponent.prototype.getCurrentPlacements = function () {
        var result = [];
        var placementGroups = this.placementsFrmArr.controls.map(function (plc) { return plc.value; });
        placementGroups.forEach(function (placementGroup) {
            result = result.concat(placementGroup.MessagePlacements);
        });
        return result;
    };
    PlacementEditComponent.prototype.prepareSaveModel = function (promoSave) {
        var _this = this;
        if (this.placementsFrmArr === null) {
            return;
        }
        var uiPlacementGroups = this.placementsFrmArr.value;
        promoSave.PatternGroups.forEach(function (patternGroupModel) {
            //PlacementGroups
            patternGroupModel.PlacementGroups = [];
            var businessIdentity = promoSave.BusinessIdentity;
            uiPlacementGroups.forEach(function (uiPlacementGroup) {
                if (uiPlacementGroup.PatternGroupType === "MultiMessage") {
                    var placementGrp = Object.assign({}, uiPlacementGroup);
                    //Remove unneeded data
                    delete placementGrp["DisplayName"];
                    delete placementGrp["MessagePlacements"];
                    delete placementGrp["PositionLocationCountOptions"];
                    patternGroupModel.PlacementGroups.push(placementGrp);
                }
                else {
                    // Can't use the parent placementGroup, must make one for each message that belongs in this patternGroup
                    uiPlacementGroup.MessagePlacements.forEach(function (messagePlacement) {
                        if (patternGroupModel.MessagePatterns.some(function (msgPattern) { return !!messagePlacement.MessagePlacementSizes.find(function (item) { return item.TempMessageId === msgPattern.TempMessageId; }); })) {
                            var plcGrpModel = {
                                Id: messagePlacement.PromotionMessagePlacementGroupId,
                                HolderId: messagePlacement.HolderId,
                                PatternTypeEnum: uiPlacementGroup.PatternTypeEnum,
                                PositionLimit: uiPlacementGroup.PositionLimit,
                                PromotionMessagePatternGroupId: patternGroupModel.Id,
                                BusinessIdentity: promoSave.BusinessIdentity,
                                PrintMessage: messagePlacement.PrintMessage
                            };
                            patternGroupModel.PlacementGroups.push(plcGrpModel);
                        }
                    });
                }
            });
            //MessagePatterns => MessagePlacements
            patternGroupModel.MessagePatterns.forEach(function (mp) {
                var promotionMessagePlacementSaveModel = [];
                var tempId = mp.TempMessageId;
                if (!tempId) {
                    // Skip this empty message
                    return;
                }
                uiPlacementGroups.filter(function (placmentGroup) { return placmentGroup.MessagePlacements.filter(function (placement) { return placement.MessagePlacement.TempMessageId === tempId; }); }).map(function (fixture) {
                    var newFixture = Object.assign({}, fixture);
                    var newPlacement;
                    for (var i = 0; i < newFixture.MessagePlacements.length; i++) {
                        var placement = newFixture.MessagePlacements[i];
                        var sizes = placement.MessagePlacementSizes.filter(function (item) { return item.TempMessageId === tempId; });
                        if (sizes) {
                            if (sizes.find(function (item) { return !_this.useMessagePattern || item.Show; }) || (sizes.length === 1 && sizes[0].UseAllSizes)) {
                                newPlacement = placement;
                                break;
                            }
                        }
                    }
                    if (newPlacement) {
                        //Add in sizes to save model
                        newPlacement.MessagePlacementSizes.forEach(function (item) {
                            var newMsgPlacement = Object.assign({}, item);
                            newMsgPlacement.HolderId = newPlacement.HolderId;
                            if (!newMsgPlacement.BusinessIdentity) {
                                newMsgPlacement.BusinessIdentity = businessIdentity;
                            }
                            newMsgPlacement.PromotionMessagePlacementGroupId = newPlacement.PromotionMessagePlacementGroupId;
                            promotionMessagePlacementSaveModel.push(newMsgPlacement);
                        });
                    }
                    return null;
                });
                mp.MessagePlacements = promotionMessagePlacementSaveModel;
            });
        });
    };
    /**
     * Removes all existing placements
     */
    PlacementEditComponent.prototype.clearPlacements = function () {
        var placementGroups = this.placementsFrmArr;
        if (placementGroups && placementGroups.length > 0) {
            while (placementGroups.length > 0) {
                placementGroups.removeAt(0);
            }
        }
    };
    PlacementEditComponent.prototype.patternQtyIncreaseTypeClick = function (placementGroupCtrl) {
        var _this = this;
        placementGroupCtrl.patchValue({
            QuantityIncreaseTarget: placementGroupCtrl.value.QuantityIncreaseTarget === "Holder" ? "Location" : "Holder"
        });
        placementGroupCtrl.get("MessagePlacements").controls.forEach(function (placementItem) {
            _this.getMessagePlacementSizesCtrl(placementItem).controls.forEach(function (sizeItem) {
                sizeItem.get("QuantityIncreaseTarget").setValue(placementGroupCtrl.get("QuantityIncreaseTarget").value);
            });
        });
        placementGroupCtrl.markAsDirty();
        this.calculatePlacementQuantities(placementGroupCtrl);
    };
    PlacementEditComponent.prototype.patternFulfillmentOperatorClick = function (placementGroupCtrl) {
        var _this = this;
        placementGroupCtrl.patchValue({
            FulfillmentOperator: placementGroupCtrl.value.FulfillmentOperator === "Percent" ? "Number" : "Percent"
        });
        placementGroupCtrl.get("MessagePlacements").controls.forEach(function (placementItem) {
            _this.getMessagePlacementSizesCtrl(placementItem).controls.forEach(function (sizeItem) {
                sizeItem.get("FulfillmentOperator").setValue(placementGroupCtrl.get("FulfillmentOperator").value);
            });
        });
        placementGroupCtrl.markAsDirty();
        this.calculatePlacementQuantities(placementGroupCtrl);
    };
    PlacementEditComponent.prototype.addLisentnerToPrintSize = function (placement) {
        var _this = this;
        var ctrl = placement.get("Selected");
        if (ctrl !== null) {
            ctrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
                _this.printSizeChanged(placement, value);
            });
        }
    };
    PlacementEditComponent.prototype.printMessageChanged = function (placement, printMessage) {
        var placementGroup = placement.parent.parent;
        var messagePlacementSizes = placement.get("MessagePlacementSizes");
        if (printMessage) {
            this.calculatePlacementQuantities(placementGroup, messagePlacementSizes.controls[0], placement);
        }
        else {
            //Clear all sizes and add one with no sizes
            var messagePlacement = this.createNewPromotionMessagePlacementModel(messagePlacementSizes.value[0].HolderId, messagePlacementSizes.value[0].PromotionMessageName, messagePlacementSizes.value[0].PromotionMessageId, messagePlacementSizes.value[0].TempMessageId);
            messagePlacement.UseAllSizes = true; // Set to true because size will be null. balance calc needs it true to get back list of sizes using these settings.
            while (messagePlacementSizes.length > 0) {
                messagePlacementSizes.removeAt(0);
            }
            messagePlacementSizes.push(this.fb.group(messagePlacement));
        }
        placement.patchValue({ PrintMessage: printMessage });
        placement.markAsDirty();
        placementGroup.markAsDirty();
    };
    PlacementEditComponent.prototype.printSizeChanged = function (placementSize, printSize) {
        var placementGroup = placementSize.parent.parent.parent.parent;
        if (!printSize) {
            placementSize.get("QuantityIncrease").disable();
            placementSize.get("FulfillmentOperand").disable();
            placementSize.patchValue({ FinalQuantity: 0 });
        }
        else {
            placementSize.get("QuantityIncrease").enable();
            placementSize.get("FulfillmentOperand").enable();
            placementSize.patchValue({ QuantityIncrease: 0 });
            placementSize.patchValue({
                QuantityIncrease: 0,
                QuantityIncreaseTarget: "Holder",
                FulfillmentOperand: this.campaignFulfillmentQty,
                FulfillmentOperator: this.campaignFulfillmentType
            });
            this.calculatePlacementQuantities(placementGroup, placementSize);
        }
        placementSize.markAsDirty();
    };
    PlacementEditComponent.prototype.patternQtyIncreaseChange = function (placementGroup, value) {
        var _this = this;
        if (value || value === 0) {
            this.getMsgPlacementsCtrl(placementGroup).controls.forEach(function (placementItem) {
                _this.getMessagePlacementSizesCtrl(placementItem).controls.forEach(function (sizeItem) {
                    sizeItem.get("QuantityIncrease").setValue(value);
                });
            });
            this.calculatePlacementQuantities(placementGroup);
        }
    };
    PlacementEditComponent.prototype.patternFulfillmentOperandChanged = function (placementGroup, value) {
        var _this = this;
        if (value || value === 0) {
            this.getMsgPlacementsCtrl(placementGroup).controls.forEach(function (placementItem) {
                _this.getMessagePlacementSizesCtrl(placementItem).controls.forEach(function (sizeItem) {
                    sizeItem.get("FulfillmentOperand").setValue(value);
                });
            });
            this.calculatePlacementQuantities(placementGroup);
        }
    };
    PlacementEditComponent.prototype.saveCustomData = function (saveModel) {
        var promises = [];
        this.customDataFieldContainers.forEach(function (container) {
            if (container.subArea === "PlacementFixture") {
                var placement_1 = container.parent.value;
                for (var i = 0; i < saveModel.PatternGroups.length; i++) {
                    var savedPatternGroup = saveModel.PatternGroups[i];
                    var matchMsgPattern = savedPatternGroup.MessagePatterns.find(function (mp) { return mp.MessageName === placement_1.PromotionMessageName; });
                    if (matchMsgPattern && matchMsgPattern.MessagePlacements) {
                        var matchPlc = matchMsgPattern.MessagePlacements.find(function (plc) { return plc.HolderId === placement_1.HolderId &&
                            plc.Height === placement_1.Height &&
                            plc.Width === placement_1.Width &&
                            // NOTE: double equal compare on purpose. when name comes back null from backend, the triple equal wasn't working here, was considered different, and then
                            // we were saving an empty placement with no holderversion .
                            plc.HolderVersionInfoName == placement_1.HolderVersionInfoName; }); // tslint:disable-line
                        if (matchPlc) {
                            container.linkId = matchPlc.Id;
                            promises.push(container.save(matchPlc.Id));
                            break;
                        }
                    }
                }
            }
            else {
                var placementGroup_1 = container.parent.value;
                for (var i = 0; i < saveModel.PatternGroups.length; i++) {
                    var savedPatternGroup = saveModel.PatternGroups[i];
                    var matchPlcGrp = savedPatternGroup.PlacementGroups.find(function (savedPlcGrp) { return savedPlcGrp.HolderId === placementGroup_1.HolderId; });
                    if (matchPlcGrp) {
                        container.linkId = matchPlcGrp.Id;
                        promises.push(container.save(matchPlcGrp.Id));
                        break;
                    }
                }
            }
        });
        return promises;
    };
    PlacementEditComponent.prototype.showNoFixtureMessage = function (messagePlacementSizes) {
        return messagePlacementSizes.controls.length === 0 ||
            (messagePlacementSizes.controls.length === 1 && !messagePlacementSizes.controls[0].value.Show); //for fake row
    };
    PlacementEditComponent.prototype.defaultCdfValuesChanged = function (cdfValues, placementGroupCtrl) {
        if (cdfValues.initialLoad) {
            placementGroupCtrl.patchValue({ defaultCdfValues: cdfValues }, { emitEvent: true });
        }
        else {
            //fix save of when defaults are changed after intial save.
            cdfValues.values[0].Values.forEach(function (value) {
                value.Id = 0;
            });
            placementGroupCtrl.patchValue({ defaultCdfValues: cdfValues }, { emitEvent: true });
        }
    };
    PlacementEditComponent.prototype.GetPlacementGroupDefaultCdf = function (placementGroupCtrl) {
        return placementGroupCtrl.get("defaultCdfValues").value;
    };
    return PlacementEditComponent;
}());
export { PlacementEditComponent };
