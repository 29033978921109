import { Directive, Attribute } from "@angular/core";
import { NG_VALIDATORS, Validator, ValidatorFn, FormControl } from "@angular/forms";

@Directive({
    selector: "[appPositiveWholeNumberRequiredValidator][ngModel]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: PositiveWholeNumberRequiredValidator,
        multi: true
    }]
})
export class PositiveWholeNumberRequiredValidator implements Validator {
    private validator: ValidatorFn;

    constructor() {
        this.validator = this.positiveWholeNumberRequiredValidator();
    }

    positiveWholeNumberRequiredValidator(): ValidatorFn {
        return (c: FormControl) => {
            let value = Number(c.value);
            if (!Number.isInteger(value) || value <= 0) {
                return { "positiveWholeNumReqired": true };
            }
            return null;
        };
    }

    validate(c: FormControl) {
        return this.validator(c);
    }
}
