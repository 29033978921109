<div class="col ips-shadow card ips-white-bg my-3 pb-2">
    <div class="row mt-3">
        <div class="col d-flex">
            <div class="status-icon question-icon-bgi mr-2 flex-shrink-0"></div>
            <div>
                {{question.Name}}
            </div>
        </div>
    </div>
    <div *ngIf="question.ProfileType ==='FixtureGroup'">
        <div class="card ips-shadow my-3 subgroup-card d-flex flex-row align-items-center" [ngClass]="{'ips-checked-card': question.NoFixture}">
            <div class="d-flex flex-row flex-grow-1">
                <div class="p-2 pl-3 flex-grow-1 clicker" (click)="CheckFixtureClicked(true)" translate>THIS_LOCATION_DOES_NOT_HAVE_THESE_FIXTURES</div>
                <div class="p-2"><i *ngIf="question.NoFixture" class="fa fa-check fa-2x pr-3 survey-check clicker" (click)="CheckFixtureClicked(true)"></i></div>
            </div>
        </div>
        <div class="card ips-shadow my-3 subgroup-card d-flex flex-row align-items-center" [ngClass]="{'ips-checked-card': !question.NoFixture}">
            <div class="d-flex flex-row flex-grow-1">
                <div class="p-2 pl-3 flex-grow-1 clicker" (click)="CheckFixtureClicked(false)" translate>THIS_LOCATION_DOES_HAVE_THESE_FIXTURES</div>
                <div class="p-2"><i *ngIf="!question.NoFixture" class="fa fa-check fa-2x pr-3 survey-check clicker" (click)="CheckFixtureClicked(false)"></i></div>
            </div>
        </div>
    </div>
    <div class="row mt-3" *ngIf="!!question.Instruction">
        <div class="col-12">
            <label><b><span translate>INSTRUCTIONS</span>:</b></label>
            <div>{{question.Instruction}}</div>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="mt-3" *ngIf="locationGroup.FullImage">
            <ips-image-view class="ips-list-image" [imgThumbUrl]="locationGroup.ThumbImage" [imgFullUrl]="locationGroup.FullImage" hideLabel="true"></ips-image-view>
        </div>
    </div>
    <div class="row mb-2 mt-3" *ngIf="!question.NoFixture">
        <div class="col d-flex align-items-center">
            <div class="status-icon answer-icon-bgi mr-2"></div>
            <div *ngIf="ShowAllOptions"><span *ngIf="IsOneToOneRule()" translate>CHOOSE_ONE_ANSWER</span><span *ngIf="!IsOneToOneRule()" translate>CHOOSE_ALL_ANSWERS_THAT_APPLY</span>:</div>
            <div *ngIf="!ShowAllOptions"><span>{{ AnswerLabel | translate }}</span></div>
        </div>
    </div>
    <!--If still loading, Show a spinner-->
    <div *ngIf="Loading" class="d-flex justify-content-center mb-2">
        <i class="fa fa-spinner fa-pulse fa-fw fa-2x" aria-hidden="true"></i>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_INFO' | translate }}" [promise]="LoadingPromise"></ips-error-handler>
    <div *ngIf="!Loading" [formGroup]="AnswerForm">
        <div [cgBusy]="SavePromise" cgBusyConfig="{message:{{'SAVING' | translate}}}">
            <!-- **** Location Group ****-->
            <div *ngIf="locationGroup && locationGroup.SubGroups">
                <!-- **** Existing SubGroups ****-->
                <div *ngIf="!expandSurveyOptions">
                    <button class="btn btn-default btn-lg btn-block" (click)="chooseLocationGroup()">{{ getLocationGroupButtonLabel() }}</button>
                </div>
                <div *ngIf="expandSurveyOptions">
                    <ng-container formArrayName="SubGroups">
                        <div *ngFor="let subGroup of SubGroups.controls; let i = index; trackBy: trackControlById">
                            <div class="card ips-shadow my-2 subgroup-card d-flex flex-row"
                                 [ngClass]="{'ips-checked-card': subGroup.value.Selected}"
                                 *ngIf="ShowAllOptions || subGroup.value.Selected">
                                <div class="d-flex flex-row flex-grow-1 align-items-center" [formGroupName]="i">
                                    <div *ngIf="subGroup.value.HasSubGroupImage" class="survey-question-image">
                                        <ips-image-view *ngIf="subGroup.value.ThumbImage" class="ips-list-image" [imgThumbUrl]="subGroup.value.ThumbImage" [imgFullUrl]="subGroup.value.FullImage" hideLabel="true"></ips-image-view>
                                    </div>
                                    <div class="p-2 pl-3 flex-grow-1 clicker" (click)="BoxClicked(subGroup)">{{subGroup.value.Name}}</div>
                                    <!--NOTE: July 30, 2019 - removed check-boxes and radio boxes to speed up IE. Not really needed anyways since BoxClicked needed all
                        the logic to check/uncheck items anyways-->
                                    <i class="fa fa-check fa-2x pr-3 survey-check clicker" (click)="BoxClicked(subGroup)"></i>
                                </div>
                            </div>
                            <div class="d-flex flex-row">
                                <ips-show-errors [control]="subGroup.get('Selected')" [errorMessages]="getNameErrorMessages('name')"></ips-show-errors>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- **** New SubGroups ****-->
                <ng-container formArrayName="NewSubGroups">
                    <div *ngFor="let subGroup of NewSubGroups.controls; let i = index">
                        <div class="d-flex">
                            <div class="card ips-shadow no-padding my-2 flex-grow-1 mr-1" [formGroupName]="i">
                                <app-multi-type-input class="input-full ips-custom-input"
                                                      [ipsClass]="'no-border subgroup-card'"
                                                      [inputType]="question.LocationGroup.OptionType"
                                                      formControlName="Name"
                                                      [dateOptions]="dateOptions"
                                                      [placeholder]="AnswerLabel"
                                                      focus="{{ShowAllOptions}}"></app-multi-type-input><!--Need to set focus will interpolation, passing ShowAllOptions directly doesn't work-->
                            </div>
                            <button *ngIf="ShowAllOptions" type="button" class="clicker input-x text-center remove-x pr-0" (click)="RemoveNewOption(i)"><i class="fa fa-remove fa-lg"></i></button>
                        </div>
                        <ips-show-errors [control]="subGroup.get('Name')" [errorMessages]="getNameErrorMessages('name')"></ips-show-errors>
                    </div>
                </ng-container>
                <div *ngIf="ShowAllOptions && AllowAnother()" class="card subgroup-card ips-shadow my-2 d-flex flex-row align-items-center clicker" (click)="AddAnotherOption()">
                    <i class="fa fa-plus ips-font-lg addIconLarge add-holder-icon m-3" aria-hidden="true"></i>
                    <span>Add another answer</span>
                </div>

            </div>

            <!-- **** Fixture Group ****-->
            <div *ngIf="question.FixtureGroup && !question.NoFixture">
                <div formArrayName="Fixtures">
                    <div *ngFor="let fixture of Fixtures.controls; let i = index">
                        <div class="card ips-shadow my-3 subgroup-card" [formGroupName]="i">
                            <div class="d-flex flex-row p-2 pl-3">
                                <div>{{fixture.value.Name}}<span *ngIf="fixture.value.Model"> - {{fixture.value.Model}}</span></div>
                            </div>
                            <div class="row p-2 pl-3 justify-content-end">
                                <div *ngIf="fixture.value.HasImage" class="col-12 col-md-3 survey-question-image">
                                    <ips-image-view *ngIf="fixture.value.ThumbImage" class="ips-list-image" [imgThumbUrl]="fixture.value.ThumbImage" [imgFullUrl]="fixture.value.FullImage" hideLabel="true"></ips-image-view>
                                </div>
                                <div class="col-12 col-md-5 pl-sm-0">
                                    <ul class="ips-ul-noBullets pl-sm-0">
                                        <li *ngFor="let holder of fixture.value.Holders" class="searchResults mt-2">{{holder.Quantity | number}} - {{holder.Name}} - {{holder.Width | number: '1.0-7'}} x {{holder.Height | number: '1.0-7' }}</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="row">
                                        <div class="col-1 col-md-2"></div>
                                        <div class="col-11 col-md-10"><label class="font-weight-bold mr-2 required" translate>FIXTURE_QUANTITY</label></div>
                                    </div>
                                    <div class="row" *ngIf="!fixture.value.Confirmed">
                                        <div class="col-2 col-md-2 d-flex justify-content-end pr-0 align-items-start mt-3">
                                            <i class="fa fa-reply fa-2x clicker" *ngIf="fixture.value.Quantity !== fixture.value.OriginalQuantity" aria-hidden="true" (click)="resetQuantity(fixture);" ngbTooltip="{{'UNDO' | translate}}" placement="bottom"></i>
                                        </div>
                                        <div class="col-10 col-md-10">
                                            <input type="number" class="form-control form-control-lg input-full subgroup-card" formControlName="Quantity"
                                                   name="Quantity{{fixture.value.Id}}" required />
                                            <ips-show-errors [control]="fixture.get('Quantity')" [errorMessages]="getNameErrorMessages('quantity')"></ips-show-errors>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!!fixture.value.Confirmed">
                                        <div class="col-1 col-md-2"></div>
                                        <div class="col-11 col-md-10">
                                            <div class="d-flex flex-row flex-grow-1 subgroup-card align-items-center form-control form-control-lg clicker"
                                                 [ngClass]="{'ips-checked-card': fixture.value.Confirmed}"
                                                 (click)="FixtureCountClicked(fixture)">
                                                <div class="flex-grow-1">{{fixture.value.Quantity | number}}</div>
                                                <div class="p-2"><i *ngIf="!question.NoFixture" class="fa fa-check fa-2x survey-check"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-end mt-3 mb-2 align-items-center">
                <div *ngIf="ShowAllOptions" class="col-12 col-md-6 text-right mb-2 mb-md-0">
                    <span class="badge-pill badge-success">{{SelectedCount()}}</span>
                    <span class="ml-1">{{SelectedCount() | i18nPlural: answerPluralMapping}}</span>
                </div>
                <div class="col-12 col-md-6">
                    <button type="button" class="btn btn-default btn-lg btn-block" (click)="SaveAnswer()" [disabled]="!AnswerForm.valid || Saving || ShouldDisableSave()" translate>{{confirmLabel}}</button>
                </div>
            </div>

            <div class="row justify-content-end mt-3 mb-3" *ngIf="question.MySurveyAnswer && !HasChanges()">
                <div class="col-12 col-md-6 d-flex">
                    <i class="fa fa-check mr-1 mt-1"></i>
                    <div translate>CONFIRMED<span>: {{question.MySurveyAnswer.ConfirmedDate | date:'medium'}} - {{question.MySurveyAnswer.UserName}}</span></div>
                </div>
            </div>
            <div *ngIf="question.MessageControl === 'CanSendMessage'">
                <div class="row mt-4 mb-3" *ngIf="!messageOpen">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btn btn-link" (click)="messageAdminClick()" translate>MESSAGE_SURVEY_ADMIN</button>
                    </div>
                </div>

                <div class="row mt-4 mb-3" *ngIf="messageOpen">
                    <div class="col-12">
                        <app-my-survey-message [SurveyId]="surveyId"
                                               [SurveyQuestionId]="question.Id"
                                               [LocationId]="locationId"
                                               [parentForm]="AnswerForm"
                                               (RemoveMessage)="messageRemovedHandler()"
                                               [MySurveyMessage]="question.MySurveyMessage"
                                               (FormDirtyChanged)="checkFormDirty()">
                        </app-my-survey-message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
