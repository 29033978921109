import { Component, OnInit } from "@angular/core";
import { SurveyService } from "../../service/survey.service";
import { Transition, StateService } from "@uirouter/core";
import { LocationService, LocationModel } from "../../../imagine-ui-ng-store-profile";
import { SurveyModel } from "../../model/SurveyModel";
import { SurveySectionTakeUIModel } from "../../model/SurveySectionTakeUIModel";
import { FormGroup, FormArray } from "@angular/forms";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";

@Component({
    selector: "app-my-survey-take-questions",
    templateUrl: "./my-survey-take-questions.component.html",
    styleUrls: ["./my-survey-take-questions.component.scss"]
})
export class MySurveyTakeQuestionsComponent implements OnInit {
    public promiseSectionLoad: Promise<void>;
    public promiseNext: Promise<any>;
    public sections: SurveySectionTakeUIModel[];
    public SurveyLabel: string;
    public LocationLabel: string;
    public QuestionsFormGroup: FormGroup;
    private surveyId: number;
    public locationId: number;

    public nextSurvey = 0;
    public nextLocation = 0;

    public expandSurveyOptions = true;

    constructor(private transition: Transition, private surveyService: SurveyService, private locationService: LocationService, private $state: StateService,
        private settingsService: GeneralSettingsService) { }

    ngOnInit() {
        this.surveyId = this.transition.params().id;
        this.locationId = this.transition.params().locationId;

        // Get list of sections
        this.QuestionsFormGroup = new FormGroup({
            Sections: new FormArray([])
        });

        if (this.surveyId > 0 && this.locationId > 0) {
            this.loadSections();
            this.loadLocation(this.locationId);
            this.loadSurvey(this.surveyId);
            const nextLocPromise = this.surveyService.getNextLocation(this.surveyId, this.locationId).then(response => {
                if (response.toString() !== this.locationId.toString()) {
                    this.nextLocation = response;
                }
            });

            const nextSurveyPromise = this.surveyService.getNextSurvey(this.surveyId, this.locationId).then(response => {
                if (response.toString() !== this.surveyId.toString()) {
                    this.nextSurvey = response;
                }
            });

            this.promiseNext = Promise.all([nextLocPromise, nextSurveyPromise]);
        }
        this.getExpandSurveyOptionsSetting();
    }

    get SectionsArray(): FormArray {
        return this.QuestionsFormGroup.get("Sections") as FormArray;
    }

    private loadSurvey(id: number) {
        this.surveyService.get<SurveyModel>(id).then(response => {
            this.SurveyLabel = response.Name;
        });
    }

    private loadSections(): void {
        this.promiseSectionLoad = this.surveyService.getMySurveySections(this.surveyId, this.locationId).then((response: SurveySectionTakeUIModel[]) => {
            this.sections = response;
            let startingNumber = 1;
            for (let i = 0; i < this.sections.length; i++) {
                this.SectionsArray.controls.push(new FormGroup({}));
                if (i === 0) {
                    this.sections[i].StartingQuestionNumber = startingNumber;
                } else {
                    startingNumber = startingNumber + this.sections[i - 1].QuestionCount;
                    this.sections[i].StartingQuestionNumber = startingNumber;
                }
            }
        });
    }

    private loadLocation(id: number): void {
        this.locationService.get<LocationModel>(id).then(response => {
            this.LocationLabel = `${response.LocationIdentifier} - ${response.Name}`;
        });
    }

    private getExpandSurveyOptionsSetting(): Promise<any> {
        return this.settingsService.canExpandSurveyOptions().then((response: boolean) => {
            this.expandSurveyOptions = response;
        });
    }

    public NextSurvey() {
        this.$state.go("main.mysurvey.questions", { id: this.nextSurvey, locationId: this.locationId });
    }

    public NextLocation() {
        this.$state.go("main.mysurvey.questions", { id: this.surveyId, locationId: this.nextLocation });
    }

    public checkFormDirty() {
        const isDirty = this.SectionsArray.controls.some(c => {

            let questions = ((c as FormGroup).controls.Questions as FormArray);
            if (questions) {
                return questions.controls.some(q => {
                    if (q.dirty === true) {
                        return true;
                    } else {
                        const controls = (q as FormGroup).controls;
                        let isControlDirty = (controls.SubGroups && (controls.SubGroups as FormArray).controls.some(sg => sg.dirty === true)) ||
                            (controls.Fixtures && (controls.Fixtures as FormArray).controls.some(f => f.dirty === true)) ||
                            (controls.Messge && controls.Message.dirty === true);
                        return isControlDirty;
                    }
                });
            } else {
                return false;
            }
        });
        if (isDirty) {
            this.QuestionsFormGroup.markAsDirty();
        } else {
            this.QuestionsFormGroup.markAsPristine();
        }
    }
}
