import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { SpaceService } from "../../service/space.service";
import { SpaceModel } from "../../model/SpaceModel";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { NgForm } from "@angular/forms";
import { TemplateFormBase } from "../../../shared/templateFormBase";

@Component({
  selector: "app-space-edit",
  templateUrl: "./space-edit.component.html",
  styleUrls: ["./space-edit.component.scss"]
})
export class SpaceEditComponent extends TemplateFormBase implements OnInit {
    @ViewChild("spaceForm") public spaceForm: NgForm;

    public loaded: boolean;
    public promise: Promise<any>;

    public deleteBodyPrompt: string;
    public accessSpaceInfo: string;
    public breadCrumbLabel: string;
    public myspace: SpaceModel;

    @Input() stateParams;
    constructor(private spaceService: SpaceService, private ipsMessage: IpsMessageService, private $state: StateService,
                private trans: Transition, private translateService: TranslateService, private pageTitleService: PageTitleService) {
        super();
    }

    ngOnInit() {
        let spaceId = Number(this.trans.params().id);
        let pageTitle = spaceId > 0 ? "EDIT_SPACE" : "CREATE_SPACE" ;

        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        // Clear out current location, fill in defaults
        this.myspace = { Id: spaceId } as SpaceModel;

        // If we got an ID to load, load it.
        // If paramId is not a number it will evaluate to false
        if (spaceId > 0) {
            //Initial call to populate screen on load
            this.getSpace(spaceId);
        } else {
            this.loaded = true;
        }

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
        super.setFormPristine(this.spaceForm);
    }

    private translateText() {
        this.translateService.get(["ACCESSING_SPACE_INFO", "PROMPT_DELETE_BODY"]).subscribe((res: [string]) => {
            this.accessSpaceInfo = res["ACCESSING_SPACE_INFO"];
            this.deleteBodyPrompt = res["PROMPT_DELETE_BODY"];
        });
    }

    //Private Functions
    private deleteSpace (): Promise<void> {
        let id = this.myspace.Id;
        return this.spaceService.delete(id);
    }

    private getSpace (id: number) {
        this.loaded = false;

        this.promise = this.spaceService.get(id).then((response) => {
            Object.assign(this.myspace, response);
            this.loaded = true;
        });
    }

    private saveSpace (): Promise<any> {
        let id = String(this.myspace.Id);
        if (id) {
            return this.spaceService.put<SpaceModel>(this.myspace).then((response: SpaceModel) => {
                this.spaceForm.form.markAsPristine();
            });
        } else {
            return this.spaceService.post<SpaceModel>(this.myspace).then((response: SpaceModel) => {
                this.spaceForm.form.markAsPristine();
            });
        }
    }

    // Public Functions used by view
    public deleteSpacePrompt () {
        let translated: string = this.deleteBodyPrompt;
        translated = IpsString.Format(translated, this.myspace.Name) as string;
        // "DELETE_SPACE_CONFIRMATION"
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deleteSpace(), progressMessage: "DELETING" })
        .then((result) => {
            if (result) {
                this.$state.go("main.storeProfileSpace.search");
            }
        })
        .catch(() => {
            // rejection
        });
    }

    public saveSpacePrompt () {
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveSpace(), progressMessage: "SAVING" }).then( (result) => {
            if (result) {
                this.$state.go("main.storeProfileSpace.search");
            }
        });
    }

}
