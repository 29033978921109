import { Directive, Attribute, Input, OnInit } from "@angular/core";
import { NG_VALIDATORS, Validator, ValidatorFn, FormControl } from "@angular/forms";

@Directive({
    selector: "[appDuplicateValidator][ngModel]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: DuplicateValidator,
        multi: true
    }]
})
export class DuplicateValidator implements Validator, OnInit {
    // tslint:disable-next-line:no-input-rename
    @Input("appDuplicateValidator") namePrefix;

    private validator: ValidatorFn;

    ngOnInit() {
        this.validator = this.duplicateValidator();
    }

    duplicateValidator(): ValidatorFn {
        const htmlNamePrefix = this.namePrefix.toLowerCase();
        return (c: FormControl) => {

            let count = 0;
            let search = (c.value || "").toString().toLowerCase();
            let currentValue = "";

            Object.keys(c.parent.controls).some(function (key, index) {
                //Make sure we only look at fields with name prefix
                if ((key || "").toString().toLowerCase().startsWith(htmlNamePrefix)) {
                    currentValue = (c.parent.controls[key].value || "").toString().toLowerCase();

                    //When we find two then we know we have a duplicate
                    if (currentValue !== "" && currentValue === search && ++count === 2) {
                        return true;
                    }
                }
            });

            if (count === 2) {
                return {
                    appDuplicateValidator: {
                        valid: false
                    }
                };
            }
            return null; //null means validation has passed
        };
    }

    validate(c: FormControl) {
        return this.validator(c);
    }
}
