import { OnInit, EventEmitter } from "@angular/core";
import { SurveyService } from "../../service/survey.service";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { FormGroup } from "@angular/forms";
var MySurveyTakeSectionComponent = /** @class */ (function () {
    function MySurveyTakeSectionComponent(surveyService, ipsMessage) {
        this.surveyService = surveyService;
        this.ipsMessage = ipsMessage;
        this.formDirtyChanged = new EventEmitter();
        this.loaded = false;
    }
    MySurveyTakeSectionComponent.prototype.ngOnInit = function () {
        if (!this.isCollapsed) {
            this.getQuestions();
        }
    };
    MySurveyTakeSectionComponent.prototype.activeCollapse = function () {
        this.isCollapsed = !this.isCollapsed;
        if (!this.isCollapsed && !this.questions) {
            this.getQuestions();
        }
    };
    MySurveyTakeSectionComponent.prototype.getQuestions = function () {
        var _this = this;
        this.loaded = false;
        this.promise = this.surveyService.getSurveyQuestionList(this.section.Id, this.locationId).then(function (questions) {
            _this.questions = questions;
        });
        var answerPromise = this.surveyService.getMySectionQuestionAnswers(this.section.Id, this.locationId).then(function (answers) {
            if (answers) {
                _this.answers = answers;
            }
        }, function (error) {
            var errorMessage = error.Message || error;
            _this.ipsMessage.error("Error loading answers: " + errorMessage);
        });
        Promise.all([this.promise, answerPromise]).then(function () {
            var _loop_1 = function (i) {
                var question = _this.questions[i];
                var answer = _this.answers.MySurveyQuestions.find(function (a) { return a.QuestionId === question.Id; });
                if (answer) {
                    question.MySurveyAnswer = answer;
                }
                var message = _this.answers.MySurveyMessages.find(function (a) { return a.SurveyQuestionId === question.Id; });
                if (message) {
                    question.MySurveyMessage = message;
                }
            };
            for (var i = 0; i < _this.questions.length; i++) {
                _loop_1(i);
            }
            _this.loaded = true;
        }, function (error) {
            //this.ipsMessage.error(error);
        });
    };
    MySurveyTakeSectionComponent.prototype.isStatusComplete = function () {
        return this.section.MySurveyStatus === "Completed";
    };
    MySurveyTakeSectionComponent.prototype.HasPendingChanges = function () {
        return this.parentForm.dirty;
    };
    MySurveyTakeSectionComponent.prototype.updateStatus = function () {
        var completed = !this.questions.some(function (q) { return q.MySurveyAnswer === undefined; });
        this.section.MySurveyStatus = completed ? "Completed" : "AwaitingAssignment";
    };
    MySurveyTakeSectionComponent.prototype.checkFormDirty = function () {
        this.formDirtyChanged.emit();
    };
    return MySurveyTakeSectionComponent;
}());
export { MySurveyTakeSectionComponent };
