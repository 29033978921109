import { OnInit, TemplateRef, EventEmitter } from "@angular/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SignPlanService } from "../service/sign-plan.service";
var SignPlanTransformDetailComponent = /** @class */ (function () {
    function SignPlanTransformDetailComponent(modalService, signPlanService) {
        this.modalService = modalService;
        this.signPlanService = signPlanService;
        this.id = 0;
        this.type = "Holder";
        this.imageUrl = "";
        this.top = 0;
        this.left = 0;
        this.rotation = 0;
        this.changesAccepted = new EventEmitter();
        this.loaded = false;
        this.loadingContent = false;
    }
    Object.defineProperty(SignPlanTransformDetailComponent.prototype, "enableDiagnostics", {
        get: function () {
            return window.enableDiagnostics;
        },
        enumerable: true,
        configurable: true
    });
    SignPlanTransformDetailComponent.prototype.cancel = function () {
        this.close();
    };
    SignPlanTransformDetailComponent.prototype.edit = function () {
        this.loadingContent = true;
        this.modalInstance = this.modalService.displayComponentAndReturnInstance(this.editTemplate, null, { size: "lg", backdrop: "static", container: ".sign-plan-transform-container" });
        this.loadingContent = false;
    };
    //
    SignPlanTransformDetailComponent.prototype.close = function (closeMessage) {
        this.modalInstance.close();
        this.initializeModel();
    };
    SignPlanTransformDetailComponent.prototype.acceptChanges = function () {
        this.modalInstance.close();
        this.changesAccepted.emit(this.model);
    };
    SignPlanTransformDetailComponent.prototype.onDragEnd = function (event) {
        var x = event.position.x;
        var y = event.position.y;
        this.model.left = x;
        this.model.top = y;
    };
    SignPlanTransformDetailComponent.prototype.onResizeEnd = function (event) {
        this.model.height = event.size.height;
        this.model.width = event.size.width;
    };
    SignPlanTransformDetailComponent.prototype.getButtonLabel = function () {
        var cropped = (!!this.top ||
            !!this.left ||
            !!this.width ||
            !!this.height);
        var rotated = Number(this.rotation) !== 0;
        if (cropped && rotated) {
            return "Cropped + Rotated";
        }
        else if (cropped || rotated) {
            if (cropped) {
                return "Cropped";
            }
            else if (rotated) {
                return "Rotated";
            }
        }
        else {
            return "Not Transformed";
        }
    };
    SignPlanTransformDetailComponent.prototype.initializeModel = function () {
        this.model = {
            id: this.id,
            rotation: this.rotation || 0,
            top: this.top,
            left: this.left,
            height: this.height,
            width: this.width
        };
    };
    SignPlanTransformDetailComponent.prototype.getRotateHeight = function (height, width, rotation) {
        if (rotation === 90 || rotation === 270) {
            return width;
        }
        else {
            return height;
        }
    };
    SignPlanTransformDetailComponent.prototype.getPreviewTransform = function (model) {
        console.log("get preview transform: ", model);
        var translateFactor = this.getTranslateFactor(model);
        var xVal = translateFactor;
        var yVal = translateFactor;
        if (model.rotation === 90 || model.rotation === 270) {
            yVal = 0;
            xVal = 0;
        }
        else {
            xVal = 0;
        }
        var returnVal = "rotate(" + model.rotation + "deg) translateX(" + xVal + "px) translateY(" + yVal + "px)";
        console.log("got preview transform: ", returnVal);
        return returnVal;
    };
    SignPlanTransformDetailComponent.prototype.getLargestDimension = function (model) {
        if (model.height > model.width) {
            return model.height;
        }
        else if (model.width > model.height) {
            return model.width;
        }
        else {
            return 0;
        }
    };
    SignPlanTransformDetailComponent.prototype.getTranslateFactor = function (model) {
        if (model.height > model.width) {
            if (model.rotation === 90 || model.rotation === 270) {
                var difference = model.height - model.width;
                var factor = Math.floor(difference / 2) * -1;
                if (model.rotation === 270) {
                    factor = factor * -1;
                }
                return factor;
            }
            else {
                return 0;
            }
        }
        else if (model.width > model.height) {
            if (model.rotation === 90 || model.rotation === 270) {
                var difference = model.width - model.height;
                var factor = Math.floor(difference / 2);
                if (model.rotation === 270) {
                    factor = factor * -1;
                }
                return factor;
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    };
    // ----- ON INIT -----
    //
    SignPlanTransformDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initializeModel();
        if (this.imageUrl) {
            this.loaded = true;
        }
        else {
            if (this.id) {
                if (this.type.toLowerCase() === "holder") {
                    this.signPlanService.getHolderPreviewImage(this.id).then(function (response) {
                        _this.imageUrl = response;
                        _this.loaded = true;
                    });
                }
                else if (this.type.toLowerCase() === "element") {
                    this.signPlanService.getElementPreviewImage(this.id).then(function (response) {
                        _this.imageUrl = response;
                        _this.loaded = true;
                    });
                }
                else {
                    this.loaded = true;
                }
            }
            else {
                this.loaded = true;
            }
        }
    };
    return SignPlanTransformDetailComponent;
}());
export { SignPlanTransformDetailComponent };
