/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ips-image-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./ips-image-view.component";
import * as i5 from "imagine-ui-ng-modal";
var styles_IpsImageViewComponent = [i0.styles];
var RenderType_IpsImageViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IpsImageViewComponent, data: {} });
export { RenderType_IpsImageViewComponent as RenderType_IpsImageViewComponent };
function View_IpsImageViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "ips-btn-image btn-link p-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.imagePopover() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["class", "img-thumbnail border-0 p-0"]], [[8, "src", 4], [8, "alt", 0]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.imgError(_co) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-expand ips-expand-icon"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgThumbUrl; var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 2).transform("THUMBNAIL_ALT")), ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_IpsImageViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "view-info-label"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["IMAGE"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_IpsImageViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "card ips-search-card w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IpsImageViewComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideLabel; _ck(_v, 3, 0, currVal_0); var currVal_1 = ""; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.noImageText; _ck(_v, 6, 0, currVal_2); }); }
export function View_IpsImageViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "ips-image-container mx-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IpsImageViewComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IpsImageViewComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imgThumbUrl; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.imgThumbUrl; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_IpsImageViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ips-image-view", [], null, null, null, View_IpsImageViewComponent_0, RenderType_IpsImageViewComponent)), i1.ɵdid(1, 114688, null, 0, i4.IpsImageViewComponent, [i5.IpsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IpsImageViewComponentNgFactory = i1.ɵccf("ips-image-view", i4.IpsImageViewComponent, View_IpsImageViewComponent_Host_0, { imgThumbUrl: "imgThumbUrl", imgFullUrl: "imgFullUrl", hideLabel: "hideLabel", noImageText: "noImageText" }, {}, []);
export { IpsImageViewComponentNgFactory as IpsImageViewComponentNgFactory };
