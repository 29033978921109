import { Component, OnInit } from "@angular/core";
// import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { ActiveProfileService, CountryModel, RegionModel, HelperFunctionsService } from "imagine-ui-ng-core";
import { CountryService, RegionService } from "imagine-ui-ng-quick-start";
import { AsYouType, CountryCode, formatNumber, parseNumber, ParsedNumber } from "libphonenumber-js";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ShippingAddress, KeyValuePair } from "../../index";
import { AddressService } from "../../../shared/service/address.service";

@Component({
    selector: "app-custom-shipping-location-modal",
    templateUrl: "./custom-shipping-location-modal.component.html",
    styleUrls: ["./custom-shipping-location-modal.component.scss"]
})

export class CustomShippingLocationModalComponent implements OnInit {
    // ----- DATA -----
    // public customAddressForm: FormGroup;
    public currentAddress: ShippingAddress;
    public countryPromise: Promise<CountryModel[]>;
    public regionDescriptors: Map<string, RegionModel[]>;
    public regionDescriptorKeys: Promise<string[]>;
    public twoCharcountryCode: CountryCode;
    private countryList: CountryModel[] = [];

    public deleting = false;
    public deleted = false;

    constructor(
        private translateService: TranslateService,
        private activeProfileService: ActiveProfileService,
        private countryService: CountryService,
        private helperFunctionsService: HelperFunctionsService,
        private regionService: RegionService,
        private modalInstance: NgbActiveModal,
        private addressService: AddressService
    ) {
    }

    // ----- ON INIT -----
    //
    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.currentAddress.Country = this.currentAddress.Country || "US";
        this.currentAddress.StateProvince = this.currentAddress.StateProvince || "";

        setTimeout(() => {
            this.countryPromise = this.countryService.Get();
            this.countryPromise.then((response) => {
                this.countryList = response;
                this.countryChanged(false);
            });

            //Populate BusID when it doesn't exist
            this.currentAddress.BusinessIdentity =
                this.currentAddress.BusinessIdentity || this.activeProfileService.businessIdentity;
        });
    }

    // ----- FUNCTIONS -----
    //
    private translateText() {
        this.translateService.get([
            "FOUND_OF", "TOTAL", "SELECT_LOCATIONS", "CANCEL", "LOCATION", "LOCATIONS",
        ]).subscribe((res: [string]) => {
            // this.shipMethodLabelPlural = res["DELIVERY_OPTIONS"];
        });
    }

    //
    public validate() {
        if ((this.currentAddress.Label === null) || (this.currentAddress.Label === "")) {
            this.currentAddress.Label =
                this.currentAddress.Line1 + "" + this.currentAddress.City;
        }
        return true;
    }

    //
    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    private getThreeCharCountryCode(): string {
        if (this.currentAddress) {
            if (this.currentAddress.Country && this.currentAddress.Country.length === 3) {
                return this.currentAddress.Country;
            } else {
                const val = this.countryList.find(c => c.Iso3166TwoCharCountryCode === this.currentAddress.Country);
                if (val) {
                    return val.Iso3166ThreeCharCountryCode;
                }
            }
        }
    }

    // Called when the country is changed, need to load new regions.
    public countryChanged(clearSelection: boolean) {
        if (this.currentAddress) {
            if (clearSelection) {
                this.currentAddress.StateProvince = "";
            }
            this.loadRegions(this.getThreeCharCountryCode());
        }
    }

    //
    public loadRegions(countryId: string) {
        if (countryId) {
            this.regionDescriptors = new Map<string, RegionModel[]>();
            this.regionDescriptorKeys = this.regionService.Get(countryId).then((response) => {
                response.forEach((current) => {
                    let value = this.regionDescriptors.get(current.RegionDescriptor);
                    if (!value) {
                        this.regionDescriptors.set(current.RegionDescriptor, [current]);
                    } else {
                        value.push(current);
                    }
                });
                return Array.from(this.regionDescriptors.keys());
            });
        }
    }

    //
    public updateAddress() {
        if (this.validate()) {
            let newAddress = this.helperFunctionsService.copyObject(this.currentAddress);
            newAddress.Country = this.addressService.getTwoCharCountryCode(newAddress.Country, this.countryList);
            this.modalInstance.close(newAddress);
        }
    }

    public removeFromAddressBook(address: ShippingAddress) {
        this.deleting = true;

        const addressBookEntry = this.addressService.convertToAddressBookEntry(address);
        this.addressService.deleteFromAddressBook(addressBookEntry).then((response) => {
            // todo: figure this out
            address.RemovedFromAddressBook = true;
            address.AddressBookAlias = "";
            address.SaveToAddressBook = false;

            this.deleted = true;
            this.deleting = false;
        });
    }
}
