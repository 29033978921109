var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var ItemService = /** @class */ (function (_super) {
    __extends(ItemService, _super);
    function ItemService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.init("Item", "Shop", "v1");
        return _this;
    }
    ItemService.prototype.searchByRelation = function (searchInfo, route) {
        if (this.searchingByFixture) {
            var fixtureRoute = "Item/SearchWithFixture";
            return _super.prototype.searchByRelation.call(this, searchInfo, fixtureRoute);
        }
        else {
            return _super.prototype.searchByRelation.call(this, searchInfo, route);
        }
    };
    ItemService.prototype.getCategoryHierarchy = function (level) {
        if (level === void 0) { level = 1; }
        var route = this.urlApiBasePlusController + "GetCategoryHierarchy?level=" + level;
        return this.getByRoute(route);
    };
    ItemService.prototype.getAllCategories = function (searchInfo, route) {
        return this.searchByRelation(searchInfo, route);
    };
    ItemService.prototype.getAllRelatedFilters = function (searchInfo) {
        var relatedFilterRoute = "Item/GetAllRelatedFilterData";
        return this.searchByRelation(searchInfo, relatedFilterRoute);
    };
    ItemService.prototype.itemCountByLocation = function (locationId, byFixture) {
        if (byFixture === void 0) { byFixture = false; }
        var routeSearchPath = byFixture ? "SearchWithFixture" : "Search";
        var route = "" + this.urlApiBasePlusController + routeSearchPath + "?locationId=" + locationId;
        return this.headByRoute(route);
    };
    // Determines if the item exists in the current user's cart for the given location.
    ItemService.prototype.ItemDataToCartLocationItem = function (item, locationId, Quantity, OrderReason) {
        return {
            Id: 0,
            LocationId: locationId || 0,
            Qty: Quantity,
            OrderReason: OrderReason,
            DigitalDownload: item.DigitalDownload,
            Item: item,
            ItemId: item.Id
        };
    };
    return ItemService;
}(IpsBaseWebService));
export { ItemService };
