<h2 class="ml-3" translate>REQUEST_FORM</h2>

<div *ngIf="loading" class="border loading-box-bg">
    <p class="text-center p-5 m-3">
        <span class="fa fa-spin fa-spinner fa-2x d-block mb-3"></span> {{ 'LOADING_DATA' | translate }}...
    </p>
</div>
<div [hidden]="!loaded">
    <div class="form-row"><div class="col"><h3 class="ml-3 my-1">Instructions</h3></div></div>
    <div class="ml-3">{{instruction}}</div>

    <form id="requestForm" class="form-horizontal" name="requestForm" [formGroup]="requestForm" novalidate ipsUnsavedWarningForm>
        <div class="">
            <div *ngFor="let group of customDataGroupList">
                <div class="form-row"><div class="col"><h3 class="ml-3 mb-1">{{group.Name}}</h3></div></div>
                <div class="card">
                    <div class="card-body">
                        <div class="form-row">
                            <div *ngFor="let field of group.Fields" [ngClass]="field.CustomClass">
                                <app-custom-data-field-control [customDataResult]="field" 
                                                               [fieldValues]="fieldValues"></app-custom-data-field-control>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row"><div class="col"><h3 class="ml-3 mb-1" translate>ATTACHMENT</h3></div></div>
        <div class="row" *ngIf="formFields.length > 0">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="fileProcess-cancel-imgn">
                            <a class="input-lg clicker input-x element-x p-0" (click)="removeFile()" ngbTooltip="{{'TOOLTIP_REMOVE_ATTACHMENT' | translate}}" placement="bottom-right"><i class="fa fa-remove fa-lg"></i></a>
                        </div>
                        <div class="">
                            <div class="form-row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <button type="button" class="btn btn-default btn-lg btn-block" (click)="fileOpen()" translate>CHOOSE_UPLOAD_FILE</button>
                                    <!--since file input cannot be customize with text so use a button to select file and hide file input -->
                                    <input type="file" #fileInput name="fileInput" (change)="fileSelected($event)" class="d-none">
                                </div>
                                <div class="col-12 col-md-6 col-lg-7 long-text-wrap">
                                    <div>
                                        <label class="pl-3 mt-2">{{uploadedFileName}}</label>
                                        <div class="validation validation-name text-danger" [hidden]="!hasfileSizeError" translate>MAX_UPLOAD_FILE_SIZE_10M</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
        <div class="row" *ngIf="formFields.length > 0">
            <div class="col-12 col-sm-6 col-lg-3">
                <div class="form-group mt-2">
                    <button id="submitRequest"
                            class="btn btn-lg btn-block alert-success btn-default"
                            (click)="submitRequestPrompt()"
                            [disabled]="requestForm.invalid || requestForm.pristine || hasfileSizeError"
                            [ngClass]="{'btn-default': requestForm.invalid || requestForm.pristine}" translate>
                        SUBMIT_REQUEST
                    </button>
                </div>
            </div>
        </div>
    </form>

</div>
