import { Component, Input, Output, EventEmitter } from "@angular/core";
import { String as IpsString } from "typescript-string-operations";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { CustomDataValueModel } from "../model/CustomDataValueModel";

@Component({template: ""})
export class FieldControlBaseComponent {
    @Input() name: string;
    @Input() isFieldRequired: boolean;
    @Input() value: any;
    @Input() fieldValues: FormArray;
    @Input() customDataId: number;

    @Output() valueChanged = new EventEmitter<CustomDataValueModel>();

    public control: FormControl;
    public group: FormGroup;

    public placeholder = "";

    public TranslateStrings: { [key: string]: string } = {
        "PLEASE_ENTER_DYNAMIC_NAME": "",
        "ENTER_DYNAMIC_NAME": ""
    };

    public errorMessages = {
        "required": () => IpsString.Format(this.TranslateStrings["PLEASE_ENTER_DYNAMIC_NAME"], this.name)
    };

    constructor(public translateService: TranslateService) {

    }

    public TranslateText() {
        this.translateService.get("ALL").subscribe(() => {
            for (let key of Object.keys(this.TranslateStrings)) {
                this.TranslateStrings[key] = this.translateService.instant(key);
            }
        });

        this.placeholder = IpsString.Format(this.TranslateStrings["ENTER_DYNAMIC_NAME"], this.name);
    }


    public getErrorMessages() {
        return this.errorMessages;
    }

    public GetCustomDataValue(): CustomDataValueModel {
        return { CustomDataId: this.customDataId, Id: this.group.get("Id").value, Name: this.name, Text: this.control.value } as CustomDataValueModel;
    }
}
