import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader, LOCALE_ID  } from "@angular/core";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SortablejsModule } from "angular-sortablejs";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { registerLocaleData } from "@angular/common";

// UI Router setup
import { UIRouterModule, UIView } from "@uirouter/angular";
import { APP_STATES } from "./app.states";
import { routerConfigFn } from "./router.config";

import { AppComponent } from "./app.component";
import { MainComponent } from "./main/main.component";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { SsoTokenReceiverComponent } from "./sso-token-receiver/sso-token-receiver.component";
import { ErrorComponent } from "./error/error.component";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout/logout.component";
import { PublicComponent } from "./public/public.component";
import { environment } from "../environments/environment";
import { VersionCheckService } from "./version-check/version-check.service";
import { ContactComponent } from "./contact/contact.component";

// Modules in this app
import { ImagineUiNgStoreProfileModule } from "./imagine-ui-ng-store-profile/imagine-ui-ng-store-profile.module";
import { ImagineUiNgProfileModule } from "./imagine-ui-ng-profile/imagine-ui-ng-profile.module";
import { ImagineUiNgCampaignModule } from "./imagine-ui-ng-campaign/imagine-ui-ng-campaign.module";
import { ImagineUiNgSurveyModule } from "./imagine-ui-ng-survey/imagine-ui-ng-survey.module";
import { ImagineUiNgCustomDataModule } from "./imagine-ui-ng-custom-data/imagine-ui-ng-custom-data.module";
import { ImagineUiNgShopModule } from "./imagine-ui-ng-shop/imagine-ui-ng-shop.module";
import { MarketModule } from "./market/market.module";

// Translate setup
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";

import { FileUploadModule } from "ng2-file-upload";
import { TimezonePickerModule } from "ng2-timezone-selector";

import { ImagineUiNgCoreModule, LogInterceptorService } from "imagine-ui-ng-core";
import { ImagineUiNgSecurityModule, AuthInterceptorService} from "imagine-ui-ng-security";
import { ImagineUiNgQuickStartModule } from "imagine-ui-ng-quick-start";
import { ImagineUiNgMessagingModule } from "imagine-ui-ng-messaging";
import { ImagineUiNgTranslationModule, CustomLoader, CustomLoaderOptions } from "imagine-ui-ng-translation";

// Modal
import { IpsModalModule, IpsModalDefaultSettings, IpsModalService } from "imagine-ui-ng-modal";
import { APP_MODAL_DEFAULTS, APP_CORE_CONFIG } from "./app.config";

//List Search
import { ListSearchModule } from "imagine-ui-ng-list-search";
import { InfiniteScrollModule } from "ngx-infinite-scroll";

// Idle keep alive
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgbModule, NgbDateAdapter, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";

import { ProfileInterceptor } from "./shared/profile-interceptor";
import { SharedModule } from "./shared/shared.module";
import { Angulartics2UirouterModule } from "angulartics2/uiroutermodule";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";

import { NgbDateNativeAdapter } from "./shared/NgbDateNativeAdapter";
import { CgBusyModule } from "angular-busy2";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { BrowserSupportComponent } from "./browser-support/browser-support.component";


//load supported locales
//not all the locale is defined in angular locale data, so use language code to load locale data
//Dynamic import cannot be used when targeting ECMAScript 2015 modules so commented out for now
//if update module to "module": "esnext" in tsconfig.app.json, all locale data is loaded in the bundle

//let lang = navigator.language || navigator["userLanguage"];
//lang = lang.split("-")[0];
//import(`@angular/common/locales/${lang}.js`).then(locale => {
//    registerLocaleData(locale.default);
//});

//import spanish locale for testing
import es from "@angular/common/locales/es";
registerLocaleData(es);



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: any) {
    let baseApiUrl = environment.baseApiUrl;

    let date = (new Date()).getTime();

    return new CustomLoader(http,
        {
            onSiteLangs: ["en", "en-US"],
            onSiteFiles: [
                {
                    fullPath: "/assets/i18n/General.en.json?" + date, //Needed for cache busting
                    prefix: "/assets/i18n/General.",
                    suffix: ".json?" + date
                }
            ],
            otherLangFiles: [
                {
                    prefix: baseApiUrl + "System/v1/Translation/search/general/",
                    suffix: ""
                }
            ]
        } as CustomLoaderOptions);
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HomeComponent,
        SsoTokenReceiverComponent,
        ErrorComponent,
        LoginComponent,
        LogoutComponent,
        PublicComponent,
        AboutComponent,
        UnauthorizedComponent,
        BrowserSupportComponent,
        ContactComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        ImagineUiNgTranslationModule.forRoot({ baseApiUrl: environment.baseApiUrl }),
        UIRouterModule.forRoot({
            states: APP_STATES,
            useHash: false,
            otherwise: { state: "public.login" },
            config: routerConfigFn
        }),
        ImagineUiNgSecurityModule.forRoot({
            baseApiUrl: environment.baseApiUrl,
            ApplicationName: "IMAGINE SYMPHONY",
            oktaClientId: environment.oktaClientId,
            oktaTenantUrl: environment.oktaTenantUrl,
            redirectUrl: environment.redirectUrl + "login/ssoTokenReceiver",
            apiManagementKey: environment.apiManagementKey
        }),
        MarketModule.forRoot(),
        SharedModule.forRoot(),
        IpsModalModule,
        InfiniteScrollModule,
        ListSearchModule,
        NgIdleKeepaliveModule.forRoot(),
        ImagineUiNgQuickStartModule.forRoot({
            baseApiUrl: <any>environment.baseApiUrl,
            chunkSize: <any>environment.chunkSize,
            delayTimer: <any>environment.delayTimer,
            maxIdleCount: <any>environment.maxIdleCount,
            maxSessionInHrs: <any>environment.maxSessionInHrs,
            idleMinsBeforeLogout: <any>environment.idleMinsBeforeLogout,
            idleMinsBeforeWarn: <any>environment.idleMinsBeforeWarn,
            keepAliveIntervalMins: <any>environment.keepAliveIntervalMins,
            pageTitleDefaultAppName: "IMAGINE SYMPHONY", // Only used if a state doesn"t provide a data.pageTitle element
            oktaUserSettingsUrl: <any>environment.oktaUserSettingsUrl,
            analyticsId: <any>environment.analyticsId
        }),
        ImagineUiNgCampaignModule, //.forRoot(),
        ImagineUiNgSurveyModule, //.forRoot(),
        ImagineUiNgCustomDataModule,
        ImagineUiNgShopModule,
        NgbModule.forRoot(),
        NgbModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        Angulartics2UirouterModule.forRoot([Angulartics2GoogleAnalytics]),
        ImagineUiNgCoreModule.forRoot(APP_CORE_CONFIG),
        ImagineUiNgMessagingModule.forRoot(),
        // Internal modules
        ImagineUiNgStoreProfileModule,
        FileUploadModule,
        ImagineUiNgProfileModule,
        TimezonePickerModule,
        SortablejsModule.forRoot({ animation: 200 }),
        LoadingBarHttpClientModule,
        CgBusyModule.forRoot({ delay: 1000, minDuration: 1100, message: "Accessing Information", backdrop: false })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ProfileInterceptor, multi: true },
        { provide: IpsModalDefaultSettings, useValue: APP_MODAL_DEFAULTS },
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptorService, multi: true },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        { provide: IpsModalService, useClass: IpsModalService },
        { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader }, // needed for lazy loading
        {
            provide: LOCALE_ID,
            useFactory: (translate: TranslateService) => {
                return translate.currentLang;
            },
            deps: [TranslateService]
        },
        VersionCheckService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
