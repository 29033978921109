var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SubscriptionManager } from "../subscription-manager/subscription-manager";
/**
 * Use as the superclass for anything managed by angular's dependency injection for care-free use of `subscribeTo()`. It simply calls `unsubscribe()` during `ngOnDestroy()`. If you override `ngOnDestroy()` in your subclass, be sure to invoke the super implementation.
 *
 * ```ts
 * @Injectable()
 * // or @Component()
 * // or @Directive()
 * // or @Pipe()
 * class MyThing extends AutoDestroyable {
 *   constructor(somethingObservable: Observable) {
 *     super();
 *     this.subscribeTo(somethingObservable);
 *   }
 *
 *   ngOnDestroy() {
 *     // if you override ngOnDestroy, be sure to call this too
 *     super.ngOnDestroy();
 *   }
 * }
 * ```
 */
var AutoDestroyable = /** @class */ (function (_super) {
    __extends(AutoDestroyable, _super);
    function AutoDestroyable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AutoDestroyable.prototype.ngOnDestroy = function () {
        this.unsubscribe();
    };
    return AutoDestroyable;
}(SubscriptionManager));
export { AutoDestroyable };
