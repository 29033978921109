var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from "@angular/core";
import { NgForm, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { StateService } from "@uirouter/core";
import { ActiveProfileService, HelperFunctionsService, CountryModel, SearchResponse } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { IpsModalService } from "imagine-ui-ng-modal";
import { environment } from "../../../../environments/environment";
import { SuggestedAddressModalComponent } from "../../../shared/suggested-addresses-modal/suggested-addresses-modal.component";
import { TemplateFormBase } from "../../../shared/templateFormBase";
import { ShipMethodPreferenceService } from "../../service/ship-method-preference.service";
import { ShipMethodListModalComponent } from "../../ship-method/ship-method-list-modal/ship-method-list-modal.component";
import { CartLocationListModalComponent } from "../cart-location-list-modal/cart-location-list-modal.component";
import { CartLocationShippingModalComponent } from "../cart-location-shipping-modal/cart-location-shipping-modal.component";
import { CustomShippingLocationModalComponent } from "../custom-shipping-location-modal/custom-shipping-location-modal.component";
import { PaymentSettingsService, AddressService, CartService } from "../../../imagine-ui-ng-shop";
import { LocationService } from "../../../imagine-ui-ng-store-profile/index";
import { CountryService } from "imagine-ui-ng-quick-start";
import { CartCountryListModalComponent } from "../cart-country-list-modal/cart-country-list-modal.component";
import uniq from "lodash-es/uniq";
import { ShopSettingsService } from "../../service/shop-settings.service";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";
var CartCheckoutComponent = /** @class */ (function (_super) {
    __extends(CartCheckoutComponent, _super);
    function CartCheckoutComponent(addressService, stateService, cartService, activeProfileService, ipsModal, translateService, helperFunctionsService, shipMethodService, ipsMessage, paymentSettingsService, locationService, countryService, shopSettingsService) {
        var _this = _super.call(this) || this;
        _this.addressService = addressService;
        _this.stateService = stateService;
        _this.cartService = cartService;
        _this.activeProfileService = activeProfileService;
        _this.ipsModal = ipsModal;
        _this.translateService = translateService;
        _this.helperFunctionsService = helperFunctionsService;
        _this.shipMethodService = shipMethodService;
        _this.ipsMessage = ipsMessage;
        _this.paymentSettingsService = paymentSettingsService;
        _this.locationService = locationService;
        _this.countryService = countryService;
        _this.shopSettingsService = shopSettingsService;
        _this.screenLoaded = false;
        _this.poNumber = "";
        _this.comments = "";
        _this.locale = "en-US";
        _this.failedAddressValidation = "FAILED_ADDRESS_VALIDATION";
        _this.countryList = [];
        _this.addressOption = "location";
        _this.addressOptions = [];
        _this.addressBookEntries = [];
        _this.savedEstimates = {};
        _this.estimating = false;
        _this.noValidShipMethods = false;
        _this.calendarTheme = "theme-default";
        _this.showOrderReason = false;
        _this.orderReasons = [];
        _this.selectedOrderReason = "";
        _this.showReferenceNumber = true;
        _this.showNeedByDate = false;
        _this.showComments = false;
        _this.poNumbers = [];
        _this.showDeliverySelection = false;
        _this.textToTranslate = [
            "ADDITIONAL_POP",
            "PROFILE_CHANGE",
            "STORE_DISCARD",
            "WEATHER_DAMAGE_BY_SHIPPER",
            "REPLACEMENT_KIT_NOT_DELIVERED",
            "BOARD_TOUR_EXEC_VISIT",
            "REFRESH_REPLACEMENT_INV",
            "REMODEL_STORE",
            "ELEMENT_MISSING",
            "WRONG_SIZE_ELEMENT",
            "MONTHLY_KIT_NOT_RECEIVED",
            "FAULTY_PACKAGING",
            "KIT_NOT_RECEIVED",
            "NEW_STORE_ORDER",
            "GRAND_OPENING",
            "CHANGEOVER_STORE",
            "CHANGES",
            "CHANGE_IN_CONTROL_KIT",
            "ACQUISITION_KIT",
            "NEW_STORE_ACQUISITION",
            "INCORRECT_MESSAGE",
            "CUSTOMER_ITEM_CODE",
            "PRODUCT_DESCRIPTION",
            "PROMPT_CLEAR_ITEM_FROM_CART",
            "SIZE_WIDTH_HEIGHT",
            "SIZE_WIDTH_HEIGHT_DEPTH",
            "AVAILABLE_QUANTITY",
            "LAST_AVAILABLE_DATE",
            "PRICE",
            "PROMOTION",
            "FIXTURE_GROUP_HOLDER",
            "SPACE",
            "PRODUCT_TYPE",
            "AVAILABLE_NOW",
            "PLACEMENT",
            "NOT_AVAILABLE",
            "ITEM_PREVIOUSLY_ORDERED",
            "FAILED_ADDRESS_VALIDATION"
        ];
        _this.payflowUrl = environment.payflowUrl;
        _this.cartId = 0;
        _this.costEstimated = false;
        _this.shippingEstimated = false;
        _this.referenceNumber = null;
        _this.NeedByDate = null;
        _this.CustomAddress = {
            Attention: null,
            Label: null,
            Id: 0,
            OrderId: 0,
            NeedByDate: null,
            ReleaseDate: null,
            ShipMethodId: 0,
            FirstName: null,
            LastName: null,
            Company: null,
            Title: null,
            Line1: null,
            Line2: null,
            Line3: null,
            City: null,
            StateProvince: null,
            Country: null,
            PostalCode: null,
            Phone: null,
            Email: null,
            BusinessIdentity: _this.activeProfileService.businessIdentity,
            ValidationStatus: "Unknown",
            AddressBookAlias: "",
            AddressBookId: 0,
            SaveToAddressBook: false,
            RemovedFromAddressBook: false
        };
        return _this;
    }
    // ----- FUNCTIONS -----
    //
    CartCheckoutComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(this.textToTranslate).subscribe(function (res) {
            _this.translatedTexts = res;
            _this.failedAddressValidation = _this.translatedTexts["FAILED_ADDRESS_VALIDATION"];
        });
    };
    CartCheckoutComponent.prototype.changeNeedByDate = function (value) {
        this.NeedByDate.setValue(value);
    };
    // Sets up the post data for an order based on the current cart and selected locations.
    CartCheckoutComponent.prototype.createCartOrderPostData = function (isCostEstimation) {
        var toReturn = {
            CartId: this.CurrentCart.Id,
            OrderedBy: this.CurrentCart.OwnerDetails,
            LocationIdsToOrder: this.cartLocations,
            ShipMethodId: this.selectedShipMethod ? this.selectedShipMethod.Key : ((this.showDeliverySelection || !isCostEstimation) ? null : -1),
            NeedByDate: this.NeedByDate.value || null,
            PONumber: this.poNumber || null,
            Comments: this.comments || null,
            ReferenceNumber: this.referenceNumber || null,
            OrderReason: this.selectedOrderReason || null,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            EstimatedSubtotal: Number(this.orderSubTotal),
            EstimatedShippingCost: Number(this.estimatedShipping),
            EstimatedTax: Number(this.estimatedTax),
            EstimatedHandling: Number(this.estimatedHandling),
            PaymentType: this.paymentIsCreditCard() ? "Credit" : "Invoice"
        };
        switch (this.addressOption) {
            case "custom":
                toReturn.ShipToAddress = this.CustomAddress;
                toReturn.ShipToAddress.Company = this.CustomAddress.Attention;
                toReturn.ShipToAddress.Country = this.addressService.getTwoCharCountryCode(toReturn.ShipToAddress.Country, this.countryList);
                break;
            case "location":
                // do nothing
                break;
            default:
                var targetAddress = this.addressService.convertToShippingAddress(this.addressOption);
                targetAddress.Country = this.addressService.getTwoCharCountryCode(targetAddress.Country, this.countryList);
                toReturn.ShipToAddress = targetAddress;
                break;
        }
        return toReturn;
    };
    CartCheckoutComponent.prototype.getLocationsByCountry = function (countryCode) {
        var filteredLocationIds = this.fullLocationDetails.filter(function (item) { return item.Addresses[0].Country === countryCode; }).map(function (item) { return item.Id; });
        return filteredLocationIds;
    };
    Object.defineProperty(CartCheckoutComponent.prototype, "formValid", {
        get: function () {
            var poNumberValid = (this.poNumberSettings.EnableList && !!this.poNumber) || !this.poNumberSettings.EnableList;
            if (this.showOrderReason) {
                return !!this.selectedOrderReason && this.shippingEstimated && poNumberValid;
            }
            else {
                return this.shippingEstimated && poNumberValid;
            }
        },
        enumerable: true,
        configurable: true
    });
    CartCheckoutComponent.prototype.createForm = function () {
        this.NeedByDate = new FormControl("", []);
    };
    // Makes a call to estimate the cost of creating an order based on the current cart
    // and selected locations.
    CartCheckoutComponent.prototype.estimateCost = function (addressChanged) {
        var _this = this;
        if (addressChanged === void 0) { addressChanged = true; }
        var returnPromise = new Promise(function (resolve, reject) {
            _this.orderSubTotal =
                _this.helperFunctionsService.formatAsPrice(_this.CurrentCart.TotalCost);
            _this.estimatedShipping = null;
            _this.totalBeforeTax = null;
            _this.estimatedTax = null;
            _this.estimatedHandling = null;
            _this.orderTotal = null;
            _this.costEstimated = true;
            _this.shippingEstimated = false;
            var estPromise;
            if (addressChanged || !_this.cartEstimate) {
                _this.estimating = true;
                estPromise = _this.cartService.estimateCartCost(_this.createCartOrderPostData(true))
                    .then(function (response) {
                    _this.estimating = false;
                    _this.cartEstimate = response;
                    // Set new shipmethods
                    _this.setAvailableShipMethods();
                    // Save off estimate for efficiency. If their are no valid ship methods, clear the saved value instead so we can try again just in case
                    // there is a network glitch causing the problem
                    if (_this.addressOption.AddressId) {
                        _this.savedEstimates[_this.addressOption.AddressId] = _this.noValidShipMethods ? null : _this.cartEstimate;
                    }
                    else {
                        _this.savedEstimates[_this.addressOption] = _this.noValidShipMethods ? null : _this.cartEstimate;
                    }
                    resolve();
                }, function (response) {
                    _this.estimating = false;
                    _this.ipsMessage.error(response);
                    reject(response);
                });
            }
            else {
                estPromise = Promise.resolve();
            }
            if (_this.selectedShipMethod) {
                estPromise.then(function () {
                    if (_this.availableShipMethods.some(function (s) { return s.Key === _this.selectedShipMethod.Key; })) {
                        // Shipmethod is valid, update costs
                        var estimate = _this.cartEstimate.ShipmethodEstimates.find(function (s) { return s.ShipMethodId === _this.selectedShipMethod.Key; });
                        _this.orderSubTotal =
                            _this.helperFunctionsService.formatAsPrice(_this.cartEstimate.OrderSubTotal);
                        _this.estimatedShipping =
                            _this.helperFunctionsService.formatAsPrice(estimate.EstimatedShipping);
                        _this.totalBeforeTax =
                            _this.helperFunctionsService.formatAsPrice(estimate.TotalBeforeTax);
                        _this.estimatedTax =
                            _this.helperFunctionsService.formatAsPrice(estimate.EstimatedTax);
                        _this.estimatedHandling =
                            _this.helperFunctionsService.formatAsPrice(estimate.EstimatedHandling);
                        _this.orderTotal =
                            _this.helperFunctionsService.formatAsPrice(estimate.OrderTotal);
                        _this.costEstimated = true;
                        _this.shippingEstimated = true;
                    }
                    else {
                        // Selected shipmethod no longer valid
                        _this.selectedShipMethod = null;
                    }
                    resolve();
                });
            }
            else if (!_this.showDeliverySelection) {
                estPromise.then(function () {
                    if (_this.availableShipMethods.some(function (s) { return s.Key === -1; })) {
                        // Shipmethod is valid, update costs
                        var estimate = _this.cartEstimate.ShipmethodEstimates.find(function (s) { return s.ShipMethodId === -1; });
                        _this.orderSubTotal =
                            _this.helperFunctionsService.formatAsPrice(_this.cartEstimate.OrderSubTotal);
                        _this.estimatedShipping =
                            _this.helperFunctionsService.formatAsPrice(estimate.EstimatedShipping);
                        _this.totalBeforeTax =
                            _this.helperFunctionsService.formatAsPrice(estimate.TotalBeforeTax);
                        _this.estimatedTax =
                            _this.helperFunctionsService.formatAsPrice(estimate.EstimatedTax);
                        _this.estimatedHandling =
                            _this.helperFunctionsService.formatAsPrice(estimate.EstimatedHandling);
                        _this.orderTotal =
                            _this.helperFunctionsService.formatAsPrice(estimate.OrderTotal);
                        _this.costEstimated = true;
                        _this.shippingEstimated = true;
                    }
                    else {
                        // Selected shipmethod no longer valid
                        _this.selectedShipMethod = null;
                    }
                    resolve();
                });
            }
        });
        return returnPromise;
    };
    CartCheckoutComponent.prototype.setAvailableShipMethods = function () {
        var _this = this;
        if (this.cartEstimate) {
            this.availableShipMethods = this.cartEstimate.ShipmethodEstimates
                .filter(function (item) { return item.OrderFragmentShipEstimateCount === _this.cartEstimate.TotalOrderFragmentCount; })
                .map(function (item) {
                return {
                    Key: item.ShipMethodId,
                    Value: item.ShipMethodDisplayName
                };
            });
            this.noValidShipMethods = this.availableShipMethods.length === 0;
        }
    };
    CartCheckoutComponent.prototype.placeOrderPrompt = function () {
        var _this = this;
        var targetState = "main.myorders.view";
        return this.ipsMessage.waitForWork({
            body: "PLACING_ORDER",
            workFunction: function () { return _this.placeOrder(); },
            progressMessage: "PLACING_ORDER"
        }).then(function (result) {
            if (result) {
                var paramObject = {
                    id: _this.placedOrderId,
                    showBanner: true
                };
                _this.stateService.go(targetState, paramObject);
            }
        });
    };
    // Places the order.
    CartCheckoutComponent.prototype.placeOrder = function () {
        var _this = this;
        return this.cartService.placeCartOrder(this.createCartOrderPostData(false))
            .then(function (orderId) {
            _this.placedOrderId = Number(orderId);
            _this.checkoutForm.form.markAsPristine();
        });
    };
    // Prompts the user to select from a list of suggested addresses.
    CartCheckoutComponent.prototype.selectFromSuggestedAddresses = function (oldAddress, validation, label) {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SuggestedAddressModalComponent, {
            suggestedAddresses: validation.Addresses,
            invalidAddress: oldAddress
        }).then(function (response) {
            if (_this.addressOption === "custom") {
                if (oldAddress.SaveToAddressBook) {
                    var model_1 = _this.addressService.convertToAddressBookEntry(response);
                    model_1.Address.ValidationStatus = "Validated";
                    model_1.Address.Country = _this.addressService.getTwoCharCountryCode(model_1.Address.Country, _this.countryList);
                    _this.addressService.saveToAddressBook(model_1).then(function (entries) {
                        _this.updateAddressBookEntries(entries, model_1.AddressId, model_1.Alias);
                        _this.estimateCost()
                            .then(function () {
                            _this.checkoutForm.form.markAsDirty();
                        });
                    });
                }
                else {
                    _this.CustomAddress = response;
                    _this.CustomAddress.Country = _this.addressService.getTwoCharCountryCode(_this.CustomAddress.Country, _this.countryList);
                    _this.CustomAddress.Label = label;
                    _this.estimateCost()
                        .then(function () {
                        _this.checkoutForm.form.markAsDirty();
                    });
                }
            }
            else {
                var currentOption_1 = _this.addressOption;
                Object.assign(currentOption_1.Address, response);
                currentOption_1.Address.Country = _this.addressService.getTwoCharCountryCode(currentOption_1.Address.Country, _this.countryList);
                currentOption_1.Recipient = label;
                currentOption_1.Alias = oldAddress.AddressBookAlias;
                _this.addressService.saveToAddressBook(currentOption_1).then(function (res) {
                    _this.updateAddressBookEntries(res, currentOption_1.AddressId, currentOption_1.Alias);
                    _this.estimateCost()
                        .then(function () {
                        _this.checkoutForm.form.markAsDirty();
                    });
                });
            }
        }).catch(function (canceled) {
            // If the currentAddress isn't valid, switch back to Location Addresses.
            if (_this.addressOption === "custom") {
                if (_this.CustomAddress.Label === null) {
                    _this.addressOption = "location";
                    _this.estimateCost();
                }
            }
        });
    };
    CartCheckoutComponent.prototype.buildAddressOptionList = function () {
        var _a;
        this.addressOptions = [];
        this.addressOptions.push({ label: "Location Addresses", value: "location" });
        var savedAddresses = this.addressBookEntries.map(function (item) {
            return { label: item.Alias, value: item };
        });
        (_a = this.addressOptions).push.apply(_a, savedAddresses);
        this.addressOptions.push({ label: "Custom Address", value: "custom" });
    };
    CartCheckoutComponent.prototype.addressOptionChanged = function () {
        var loadSavedEstimate = true;
        if (this.addressOption === "custom") {
            if (!this.isCustomAddressPopulated()) {
                loadSavedEstimate = false;
                this.enterNewAddress();
            }
        }
        if (loadSavedEstimate) {
            if (this.addressOption.AddressId) {
                this.cartEstimate = this.savedEstimates[this.addressOption.AddressId];
            }
            else {
                this.cartEstimate = this.savedEstimates[this.addressOption];
            }
            this.setAvailableShipMethods();
            this.estimateCost(false);
        }
    };
    CartCheckoutComponent.prototype.isCustomAddressPopulated = function () {
        return !!this.CustomAddress.Label;
    };
    CartCheckoutComponent.prototype.isAddressBookEntry = function (address) {
        return !!address.Address &&
            !!address.Id &&
            !!address.Alias;
    };
    CartCheckoutComponent.prototype.entryRemovedFromAddressBook = function (address) {
        Object.assign(this.CustomAddress, address);
        var removalIndex = this.addressOptions.findIndex(function (item) { return item.value.Id === address.AddressBookId; });
        this.addressOptions.splice(removalIndex, 1);
        this.CustomAddress.AddressBookAlias = "";
        this.CustomAddress.AddressBookId = 0;
        this.CustomAddress.RemovedFromAddressBook = false;
        this.CustomAddress.SaveToAddressBook = false;
        this.addressOption = "custom";
    };
    CartCheckoutComponent.prototype.editAddressBookEntry = function (entry) {
        var _this = this;
        var editModel = this.addressService.convertToShippingAddress(entry);
        this.ipsModal.displayTemplateScrollable(CustomShippingLocationModalComponent, { currentAddress: editModel }).then(function (response) {
            var originalModel = _this.addressService.convertToShippingAddress(entry);
            var oldAddress = {
                Line1: originalModel.Line1,
                Line2: originalModel.Line2,
                City: originalModel.City,
                StateProvince: originalModel.StateProvince,
                PostalCode: originalModel.PostalCode,
                Country: originalModel.Country
            };
            var newAddress = {
                Line1: response.Line1,
                Line2: response.Line2,
                City: response.City,
                StateProvince: response.StateProvince,
                PostalCode: response.PostalCode,
                Country: response.Country
            };
            var areDifferent = JSON.stringify(oldAddress) !== JSON.stringify(newAddress);
            if (areDifferent) {
                _this.addressService.validateAddress(response)
                    .then(function (validation) {
                    if (validation.Valid) {
                        var model_2 = _this.addressService.convertToAddressBookEntry(response);
                        if (response.RemovedFromAddressBook) {
                            _this.entryRemovedFromAddressBook(response);
                        }
                        else {
                            _this.addressService.saveToAddressBook(model_2).then(function (addressBookResponse) {
                                _this.updateAddressBookEntries(addressBookResponse, model_2.AddressId, model_2.Alias);
                                _this.estimateCost()
                                    .then(function () {
                                    _this.checkoutForm.form.markAsDirty();
                                });
                            });
                        }
                    }
                    else {
                        _this.selectFromSuggestedAddresses(response, validation, response.Label);
                    }
                }, function (reason) {
                    _this.ipsMessage.confirm({
                        body: "FAILED_ADDRESS_VALIDATION_PROMPT",
                        buttons: "OK"
                    }).then(function (result) {
                        if (result) {
                            // TODO:  is this right?
                            Object.assign(entry, response);
                            _this.CustomAddress = response;
                            _this.estimateCost()
                                .then(function () {
                                _this.checkoutForm.form.markAsDirty();
                            });
                        }
                        else {
                            // If the currentAddress isn't valid, switch back to Location Addresses.
                            if (_this.addressOption === "custom") {
                                if (_this.CustomAddress.Label === null) {
                                    _this.addressOption = "location";
                                    _this.estimateCost();
                                }
                            }
                        }
                    }).catch(function (canceled) {
                        // If the currentAddress isn't valid, switch back to Location Addresses.
                        if (_this.addressOption === "custom") {
                            if (_this.CustomAddress.Label === null) {
                                _this.addressOption = "location";
                                _this.estimateCost();
                            }
                        }
                    });
                });
            }
            else {
                var model_3 = _this.addressService.convertToAddressBookEntry(response);
                if (response.RemovedFromAddressBook) {
                    _this.entryRemovedFromAddressBook(response);
                }
                else {
                    _this.addressService.saveToAddressBook(model_3).then(function (addressBookResponse) {
                        _this.updateAddressBookEntries(addressBookResponse, model_3.AddressId, model_3.Alias);
                        _this.estimateCost()
                            .then(function () {
                            _this.checkoutForm.form.markAsDirty();
                        });
                    });
                }
            }
        }).catch(function () {
            // If the currentAddress isn't valid, switch back to Location Addresses.
            if (_this.addressOption === "custom") {
                if (_this.CustomAddress.Label === null) {
                    _this.addressOption = "location";
                    _this.estimateCost();
                }
            }
        });
    };
    CartCheckoutComponent.prototype.updateAddressBookEntries = function (entries, selectedAddressId, selectedAlias) {
        this.addressBookEntries = entries;
        this.buildAddressOptionList();
        if (selectedAddressId) {
            this.addressOption = this.addressOptions.find(function (item) { return item.value.AddressId === selectedAddressId; }).value;
        }
        else if (selectedAlias) {
            this.addressOption = this.addressOptions.find(function (item) { return item.value.Alias === selectedAlias; }).value;
        }
        else {
            this.addressOption = "custom";
        }
    };
    //
    CartCheckoutComponent.prototype.enterNewAddress = function () {
        var _this = this;
        this.addressOption = "custom";
        var modelResult = this.ipsModal.displayTemplateScrollable(CustomShippingLocationModalComponent, { currentAddress: this.helperFunctionsService.copyObject(this.CustomAddress) });
        modelResult.then(function (response) {
            _this.addressService.validateAddress(response)
                .then(function (validation) {
                if (validation.Valid) {
                    if (response.SaveToAddressBook) {
                        var model_4 = _this.addressService.convertToAddressBookEntry(response);
                        model_4.Address.ValidationStatus = "Validated";
                        _this.addressService.saveToAddressBook(model_4).then(function (entries) {
                            _this.updateAddressBookEntries(entries, model_4.AddressId, model_4.Alias);
                        });
                    }
                    else {
                        _this.CustomAddress = response;
                        _this.addressOption = "custom";
                    }
                    _this.estimateCost()
                        .then(function () {
                        _this.checkoutForm.form.markAsDirty();
                    });
                }
                else {
                    _this.selectFromSuggestedAddresses(response, validation, response.Label);
                }
            }, function (reason) {
                _this.ipsMessage.confirm({
                    body: "FAILED_ADDRESS_VALIDATION_PROMPT",
                    buttons: "OK"
                }).then(function (result) {
                    if (result) {
                        _this.CustomAddress = response;
                        _this.estimateCost()
                            .then(function () {
                            _this.checkoutForm.form.markAsDirty();
                        });
                    }
                    else {
                        // If the currentAddress isn't valid, switch back to Location Addresses.
                        if (_this.CustomAddress.Label === null) {
                            _this.addressOption = "location";
                            _this.estimateCost();
                        }
                    }
                }).catch(function (canceled) {
                    // If the currentAddress isn't valid, switch back to Location Addresses.
                    if (_this.CustomAddress.Label === null) {
                        _this.addressOption = "location";
                        _this.estimateCost();
                    }
                });
            });
        }, function () {
            // If the currentAddress isn't valid, switch back to Location Addresses.
            if (_this.CustomAddress.Label === null) {
                _this.addressOption = "location";
                _this.estimateCost();
            }
        });
        return modelResult;
    };
    // Creates a dialog where the user selects which locations to check out.
    CartCheckoutComponent.prototype.chooseLocation = function (displayAddresses) {
        var _this = this;
        displayAddresses = displayAddresses || false;
        if (displayAddresses) {
            this.ipsModal.displayTemplateScrollable(CartLocationShippingModalComponent, {
                cartLocations: this.CurrentCart.Locations || [],
                currentSelections: this.cartLocations || []
            });
        }
        else {
            //Get the list of valid payment methods by location
            this.paymentSettingsService
                .getAvailablePaymentMethods(this.CurrentCart.Locations
                ? this.CurrentCart.Locations.map(function (i) { return i.Key; })
                : [0])
                .then(function (result) {
                //Make sure at-least one of the locations have payment methods defined
                if (!result.find(function (q) { return q.CreditCard || q.Invoice; })) {
                    var response = "PROMPT_NO_PAYMENT_METHODS";
                    if (result.find(function (q) { return q.ContainsOtherLocations; })) {
                        response = "LOCATION_NO_PAYMENT_METHODS";
                    }
                    _this.ipsMessage.error(response).finally(function () {
                        _this.stateService.transitionTo("main.mycart");
                    });
                }
                else {
                    var cartLocations = _this.CurrentCart.Locations
                        ? _this.CurrentCart.Locations.map(function (i) {
                            var paymentMethod = result.find(function (q) { return q.LocationId === i.Key; }) ||
                                { LocationId: i.Key, Invoice: false, CreditCard: false };
                            return {
                                LocationId: i.Key,
                                Label: i.Value,
                                Invoice: paymentMethod.Invoice,
                                CreditCard: paymentMethod.CreditCard,
                                ValidationStatus: i.ValidationStatus
                            };
                        })
                        : [];
                    if (_this.selectedCountryCode) {
                        var targetLocationIds_1 = _this.getLocationsByCountry(_this.selectedCountryCode);
                        cartLocations = cartLocations.filter(function (item) { return targetLocationIds_1.indexOf(item.LocationId) > -1; });
                    }
                    _this.ipsModal.displayTemplateScrollable(CartLocationListModalComponent, {
                        cartLocations: cartLocations.filter(function (loc) {
                            return (_this.paymentMethod !== "Credit" && loc.Invoice) || (_this.paymentMethod !== "Invoice" && loc.CreditCard);
                        }),
                        currentSelections: [],
                        displayAddresses: displayAddresses,
                        allowPayment: _this.paymentMethod
                    }).then(function (res) {
                        _this.cartLocations = res.Locations;
                        _this.paymentMethod = res.PaymentMethod;
                        _this.numberOfLocations = _this.cartLocations.length;
                        _this.CurrentCart = _this.cartService.myCurrentCartData(_this.cartLocations);
                        _this.orderQuantity = _this.CurrentCart.TotalQuantity;
                        if (res.Locations.indexOf(-1) > -1) {
                            var ind = _this.addressOptions.findIndex(function (q) { return q.value === "location"; });
                            if (ind > -1) {
                                _this.addressOptions.splice(ind, 1);
                                _this.addressOption = _this.addressOptions[0];
                                _this.getCustomAddressModel();
                                return;
                            }
                        }
                        else {
                            _this.estimateCost();
                        }
                        _this.screenLoaded = true;
                    }, function () {
                        // If the user cancels on their first prompt, take them back to the cart page.
                        if (_this.screenLoaded === false) {
                            _this.stateService.transitionTo("main.mycart");
                        }
                    });
                }
            });
        }
    };
    CartCheckoutComponent.prototype.getCustomAddressModel = function () {
        var _this = this;
        this.enterNewAddress().then(function (result) {
            _this.screenLoaded = true;
            //this.estimateCost();
        }).catch(function (e) {
            _this.ipsMessage.confirm({
                title: "WARNING",
                body: "YOU_MUST_PROVIDE_CUSTOM_ADDRESS",
                ok: "ADD_CUSTOM_ADDRESS",
                cancel: "RETURN_TO_CART"
            }).then(function () {
                _this.getCustomAddressModel();
            }).catch(function () {
                _this.stateService.transitionTo("main.mycart");
            });
        });
    };
    // Creates a dialog for selecting the country for checkout.
    CartCheckoutComponent.prototype.selectCountry = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(CartCountryListModalComponent, {
            AvailableCountries: this.locationCountries
        }).then(function (response) {
            _this.selectedCountryCode = response;
            _this.chooseLocation();
        }, function () {
            _this.stateService.transitionTo("main.mycart");
        });
    };
    // Creates a dialog for selecting the method for shipping the order.
    CartCheckoutComponent.prototype.selectShippingMethod = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(ShipMethodListModalComponent, {
            items: this.availableShipMethods
        }).then(function (response) {
            _this.selectedShipMethod = response;
            _this.estimateCost(false);
        }, function () {
            // do nothing
        });
    };
    CartCheckoutComponent.prototype.getSecureToken = function () {
        var _this = this;
        if (!this.token || !this.tokenId) {
            var rtnUrl = environment.redirectUrl + "shop/paypalpostback/" + this.CurrentCart.Id;
            var authAmount = parseFloat(this.orderTotal);
            var cancelUrl = environment.redirectUrl + "shop/cart-checkout/" + this.CurrentCart.Id;
            var request = { Amount: authAmount, ReturnUrl: rtnUrl, ErrorUrl: rtnUrl, CancelUrl: cancelUrl };
            return this.cartService.GetSecureToken(request).then(function (response) {
                _this.token = response.SecureToken;
                _this.tokenId = response.SecureTokenId;
            });
        }
    };
    CartCheckoutComponent.prototype.submit = function () {
        var _this = this;
        //save cartOptions for placing order later
        var options = {
            CustomAddress: {
                ShipMethodId: this.selectedShipMethod ? this.selectedShipMethod.Key : null,
                FirstName: this.CurrentCart.OwnerDetails.FirstName,
                LastName: this.CurrentCart.OwnerDetails.LastName,
                Email: this.CurrentCart.OwnerDetails.Email,
                Phone: this.CurrentCart.OwnerDetails.Phone,
                ValidationStatus: "Unknown"
            },
            PONumber: this.poNumber || null,
            ReferenceNumber: this.referenceNumber || null,
            OrderReason: this.selectedOrderReason || null,
            SelectedLocationIds: this.cartLocations,
            ShipToAddress: this.CustomAddress.Line1 === null ? null : this.CustomAddress,
            EstimatedSubtotal: Number(this.orderSubTotal),
            EstimatedShippingCost: Number(this.estimatedShipping),
            EstimatedTax: Number(this.estimatedTax),
            EstimatedHandling: Number(this.estimatedHandling)
        };
        this.cartService.updateCartOptions(options).then(function (response) {
            _this.getSecureToken().then(function () {
                //mark form pristine
                _this.checkoutForm.form.markAsPristine();
                // call setTimeout so that form can be updated before submission
                setTimeout(function () {
                    _this.paypalForm.nativeElement.submit();
                }, 1);
            });
        }).catch(function (errorResponse) {
            _this.ipsMessage.error(errorResponse.message || errorResponse.error.Message);
        });
    };
    Object.defineProperty(CartCheckoutComponent.prototype, "showPayByCreditCard", {
        get: function () {
            return this.paymentIsCreditCard() && Number(this.orderTotal) > 0;
        },
        enumerable: true,
        configurable: true
    });
    CartCheckoutComponent.prototype.disabledFilter = function (type) {
        if (type === "Invoice") {
            return this.paymentMethod !== "Invoice" && this.paymentMethod !== "Both";
        }
        else if (type === "Credit") {
            return this.paymentMethod !== "Credit" && this.paymentMethod !== "Both";
        }
        return false;
    };
    CartCheckoutComponent.prototype.paymentIsCreditCard = function () {
        return this.paymentMethod === "Credit";
    };
    CartCheckoutComponent.prototype.getShopSettings = function () {
        var _this = this;
        return this.shopSettingsService.getSettingByName("ShowOrderReason").then(function (response) {
            if (response) {
                _this.showOrderReason = (response.Value === "order");
            }
        });
    };
    CartCheckoutComponent.prototype.getPONumberSettings = function () {
        var _this = this;
        return this.shopSettingsService.getPONumberSetting().then(function (response) {
            if (response) {
                _this.poNumberSettings = response;
                _this.poNumbers = _this.poNumberSettings.Options.split(/\r\n|\r|\n/g);
            }
        });
    };
    CartCheckoutComponent.prototype.getShowDeliverySelectionSetting = function () {
        var _this = this;
        return this.shopSettingsService.getShowDeliverySelectionSetting().then(function (response) {
            if (response) {
                _this.showDeliverySelection = response;
                _this.estimateCost(false);
            }
        });
    };
    // ----- ON INIT -----
    // Logic to run on start up.
    CartCheckoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        this.tomorrow.setHours(0, 0, 0, 0); // set to midnight
        var localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();
        this.needByDateOptions = {
            minDate: this.tomorrow,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };
        this.createForm();
        this.locale = this.activeProfileService.profile.Locale || navigator.language;
        this.cartId = this.stateService.params.cartId || 0;
        if (this.cartId === 0) {
            this.stateService.transitionTo("main.mycart", {});
        }
        this.paymentMethod = this.stateService.params.paymentMethod || "Both";
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        var countryPromise = this.countryService.Get();
        countryPromise.then(function (response) {
            _this.countryList = response;
        });
        var settingsPromise = this.getShopSettings();
        var poNumberSettingsPromise = this.getPONumberSettings();
        var referenceNumberPromise = this.shopSettingsService.getReferenceNumberSetting().then(function (response) {
            _this.showReferenceNumber = response;
        });
        var commentsPromise = this.shopSettingsService.getCommentsSetting().then(function (response) {
            _this.showComments = response;
        });
        var needByDatePromise = this.shopSettingsService.getNeedByDateSetting().then(function (response) {
            _this.showNeedByDate = response;
        });
        var addressPromise = this.addressService.getAddressBook().then(function (response) {
            _this.addressBookEntries = response;
            _this.buildAddressOptionList();
        });
        var orderReasonsPromise = this.shopSettingsService.getOrderReasons().then(function (response) {
            _this.orderReasons = response.sort(function (a, b) { return a > b ? 1 : -1; });
        });
        var showDeliverySelectionPromise = this.getShowDeliverySelectionSetting();
        Promise.all([settingsPromise, poNumberSettingsPromise, addressPromise, orderReasonsPromise, referenceNumberPromise, needByDatePromise, showDeliverySelectionPromise]).then(function () {
            _this.cartService.getMyCurrentCart().then(function () {
                _this.CurrentCart = _this.cartService.myCurrentCartData();
                var tempLocations = _this.CurrentCart.Items.map(function (x) { return x.LocationId; });
                _this.cartLocations = tempLocations.filter(function (x, pos) {
                    return tempLocations.indexOf(x) === pos; // Ensures only the first instance is added.
                });
                _super.prototype.setFormPristine.call(_this, _this.checkoutForm);
                if (tempLocations.length > 1) {
                    _this.locationService.searchLocationsByIds(tempLocations).then(function (locResponse) {
                        _this.fullLocationDetails = locResponse.ResultList;
                        var countryCodes = uniq(locResponse.ResultList.map(function (item) {
                            return item.Addresses[0].Country;
                        }));
                        if (countryCodes.length > 1) {
                            _this.countryService.Get().then(function (countryResponse) {
                                _this.locationCountries = countryResponse.filter(function (item) { return countryCodes.indexOf(item.Iso3166ThreeCharCountryCode) > -1; });
                                _this.selectCountry();
                            });
                        }
                        else {
                            _this.chooseLocation();
                        }
                    });
                }
                else {
                    _this.chooseLocation();
                }
            });
        });
    };
    return CartCheckoutComponent;
}(TemplateFormBase));
export { CartCheckoutComponent };
