<div class="form-group" [formGroup]="group">
    <label [ngClass]="{'required': isFieldRequired}">{{name}}</label>
    <textarea *ngIf="selectionRule === 'Multiple'"
              formControlName="Text"
              class="form-control form-control-lg" ></textarea>
    <input *ngIf="selectionRule !== 'Multiple'"
           type="text"
           formControlName="Text"
           class="form-control form-control-lg input-full"
           placeholder="{{ placeholder}}">
    <ips-show-errors [control]="group.get('Text')" [errorMessages]="getErrorMessages()"></ips-show-errors>
</div>
