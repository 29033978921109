import { Component, OnInit, ViewChildren, QueryList } from "@angular/core";
import { FormGroup, FormControl, FormArray, Validators, AbstractControl } from "@angular/forms";
import { DatePipe } from "@angular/common"; // TODO: in angular 6 we can just import formatDate function from angular/common
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PageTitleService, IpsImageEditComponent } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { LocationGroupService, LocationGroupModel, LocationSubGroupModel, LocationGroupRule } from "../../../imagine-ui-ng-store-profile";
import { CampaignService } from "../../../imagine-ui-ng-campaign";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";
import { OptionType } from "../../type/OptionType";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "imagine-ui-ng-security";
import { SurveyService } from "../../../imagine-ui-ng-survey";
import { SurveyInUseModel } from "../../../imagine-ui-ng-survey/model/SurveyInUseModel";
import { IpsModalService } from "imagine-ui-ng-modal";
import { LocationGroupDeleteModalComponent } from "../location-group-delete-modal/location-group-delete-modal.component";
import { PaymentSettingsService } from "../../../imagine-ui-ng-shop";

interface LocationSubGroupModelEdit extends LocationSubGroupModel {
    InputName: string;
    RemoveImage: string;
}

interface ImageSave {
    Id: number;
    Type: string;
    Files: any;
}

interface LocationGroupModelEdit extends LocationGroupModel {
    /**
	* Override to get editing tracking
	*/
    SubGroups: LocationSubGroupModelEdit[];
    RemoveImage: string;
}

interface OptionTypeInfo {
    TypeName: OptionType;
    DisplayName: string;
    IconClass: string;
}

interface FeatureImage {
    Name: string;
    Files: FileList;
}

@Component({
    selector: "app-location-group-edit",
    templateUrl: "./location-group-edit.component.html",
    styleUrls: ["../store-profile-location-group/store-profile-location-group.component.scss"]
})

export class LocationGroupEditComponent implements OnInit {
    private indexer = 0;
    public breadCrumbLabel: string;
    public loaded: boolean;
    public myLocationGroup: LocationGroupModelEdit;
    public originalRule: LocationGroupRule;
    public promise: Promise<void>;
    private dupeSubGroupNameMessage: string;
    private enterHeightMessage: string;
    private requiredMessage: string;
    private maxLengthMessage: string;
    private requiredNameMessage: string;
    private promptDeleteBodyMessage: string;
    private reservedNameMessage: string;
    public focusInput: string;
    public focusableInput: string;
    public locationGroupForm: FormGroup;
    public editingExisting = false;
    public disableRuleSelection = false;
    private Id: FormControl;
    private BusinessIdentity: FormControl;
    private Name: FormControl;
    private Notes: FormControl;
    private Rule: FormControl;
    private OptionType: FormControl;
    private MasterMediaId: FormControl;
    private MediaType: FormControl;
    private FullSizeImage: FormControl;
    private SelectedImage: FormControl;
    private IsObsolete: FormControl;
    private IsCampaignLocationFeature: FormControl;

    public dateFormat: string;
    public dateOptions: {};
    private invalidDateErrorMessage: string;
    // Used by GUI for determining which controls to use for Options
    public currentOptionType: OptionType;
    public HasNA = false;

    @ViewChildren(IpsImageEditComponent) public images: QueryList<IpsImageEditComponent>;

    public featureImageFiles: FeatureImage[] = [];
    private emptyGuid = "00000000-0000-0000-0000-000000000000";
    private idNumber = 0;

    public OptionTypes: OptionTypeInfo[] = [
        { TypeName: "Text", DisplayName: "OPTION_TYPE_TEXT", IconClass: "option-type-text-icon" },
        { TypeName: "Bool", DisplayName: "OPTION_TYPE_BOOL", IconClass: "option-type-bool-icon" },
        { TypeName: "Number", DisplayName: "OPTION_TYPE_NUMBER", IconClass: "option-type-number-icon" },
        { TypeName: "Date", DisplayName: "OPTION_TYPE_DATE", IconClass: "option-type-date-icon" }];

    private errorMessages = {
        "required": () => this.requiredMessage,
        "maxlength": (params) => IpsString.Format(this.maxLengthMessage, params.requiredLength),
        "dupeSubGroupNameMessage": () => this.dupeSubGroupNameMessage,
        "bsDate": () => this.invalidDateErrorMessage,
        "reservedName": () => this.reservedNameMessage
    };

    constructor(private locationGroupService: LocationGroupService, private ipsMessage: IpsMessageService, private $state: StateService,
        private transition: Transition, private translateService: TranslateService, private pageTitleService: PageTitleService,
        private activeProfileService: ActiveProfileService, private campaignService: CampaignService, private datePipe: DatePipe,
        private authService: AuthService, private surveyService: SurveyService, private ipsModal: IpsModalService, private paymentSettingsService: PaymentSettingsService) { }

    ngOnInit() {

        let paramId = this.transition.params().id === "0" ? "" : this.transition.params().id;

        this.focusInput = paramId.toString() === "" ? "nameInput" : "addSubGroupInput";
        let pageTitle = paramId.toString() === "" ? "CREATE_LOCATION_GROUP" : "EDIT_LOCATION_GROUP";

        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        this.editingExisting = paramId > 0;
        this.createForm();

        //If we got an ID to load, load it.
        if (paramId > 0) {
            //Initial call to populate screen on load
            this.getLocationGroup(paramId);
        } else if (paramId === "") {
            this.loaded = true;
        }

        let localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();

        this.dateOptions = {
            containerClass: "theme-default",
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        } as BsDatepickerConfig;

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    get SubGroups(): FormArray {
        return this.locationGroupForm.get("SubGroups") as FormArray;
    }

    private translateText() {
        this.translateService.get(["DUPLICATE_SUBGROUP_NAME", "PLEASE_ENTER_HEIGHT", "FIELD_REQUIRED", "MAX_LENGTH_ERROR", "PLEASE_ENTER_OPTION", "PROMPT_DELETE_BODY",
            "CALENDAR_DATE_FORMAT", "RESERVED_SUBGROUP_NAME"]).subscribe((res: [string]) => {
            this.dupeSubGroupNameMessage = res["DUPLICATE_SUBGROUP_NAME"];
            this.enterHeightMessage = res["PLEASE_ENTER_HEIGHT"];
            this.requiredMessage = res["FIELD_REQUIRED"];
            this.maxLengthMessage = res["MAX_LENGTH_ERROR"];
            this.requiredNameMessage = res["PLEASE_ENTER_OPTION"];
            this.promptDeleteBodyMessage = res["PROMPT_DELETE_BODY"];
            this.invalidDateErrorMessage = IpsString.Format(res["CALENDAR_DATE_FORMAT"], this.dateFormat);
            this.reservedNameMessage = res["RESERVED_SUBGROUP_NAME"];
        });
    }

    public getNameErrorMessages() {
        let msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = () => this.requiredNameMessage;
        return msgs;
    }

    public getOptionTypeErrorMessages() {
        let msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = () => this.requiredMessage;
        return msgs;
    }

    private createForm() {
        this.Id = new FormControl("");
        this.BusinessIdentity = new FormControl("");
        this.Name = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.Notes = new FormControl("", Validators.maxLength(500));
        this.Rule = new FormControl("", Validators.required);
        this.OptionType = new FormControl({ value: "", disabled: this.editingExisting }, Validators.required);
        this.OptionType.valueChanges.subscribe(value => {
            this.optionTypeChanged(value);
        });
        this.MasterMediaId = new FormControl(this.emptyGuid);
        this.MediaType = new FormControl("Unknown");
        this.FullSizeImage = new FormControl("");
        this.SelectedImage = new FormControl(false);
        this.IsObsolete = new FormControl(false);
        this.IsCampaignLocationFeature = new FormControl(false);

        this.locationGroupForm = new FormGroup({
            Id: this.Id,
            BusinessIdentity: this.BusinessIdentity,
            Name: this.Name,
            Notes: this.Notes,
            Rule: this.Rule,
            OptionType: this.OptionType,
            SubGroups: new FormArray([this.getNewSubGroupCtrl(this.createEmptySubGroup(), this.currentOptionType)]),
            MasterMediaId: this.MasterMediaId,
            MediaType: this.MediaType,
            FullSizeImage: this.FullSizeImage,
            SelectedImage: this.SelectedImage,
            IsObsolete: this.IsObsolete,
            IsCampaignLocationFeature: this.IsCampaignLocationFeature
        });
    }

    private createEmptySubGroup(isNa: boolean = false): LocationSubGroupModelEdit {
        return {
            Id: 0,
            Name: isNa ? "Not Applicable" : "",
            BusinessIdentity: this.activeProfileService.businessIdentity,
            InputName: "subGroupHtmlName" + this.indexer++,
            LocationCount: 0,
            IsNA: isNa
        } as LocationSubGroupModelEdit;
    }

    private optionTypeChanged(optionTypeValue) {

        if (optionTypeValue === this.currentOptionType) {
            return;
        }
        // Check for changes
        let hasContent = false;
        if (this.currentOptionType !== "Bool") {
            hasContent = this.SubGroups.controls.some((subGroup) => {
                const value = subGroup.get("Name").value;
                if (!!value) {
                    return true;
                }
                return false;
            });
        }
        if (hasContent) {
            // prompt the user if they actually want to make a change
            this.ipsMessage.confirm({
                title: "WARNING",
                body: "OPTION_TYPE_CHANGE_WARNING",
                ok: "YES_RESET_MY_INFO",
                cancel: "NO_KEEP_MY_INFO"
            }).then(() => {
                this.setOptionsForNewOptionType(optionTypeValue);
            })
            .catch(() => {
                // User chose to keep changes and not select a different option type, reset the radio button back to what it was.
                this.OptionType.patchValue(this.currentOptionType);
            });
        } else {
            // There aren't any changes, just make the change
            this.setOptionsForNewOptionType(optionTypeValue);
        }
    }


    public toggleObsolete(locationGroupControl: FormGroup) {

        if ((<any>event.srcElement).tagName === "INPUT") {
            return;
        }

        let isObsoleteControl = locationGroupControl.get("IsObsolete");
        isObsoleteControl.setValue(!isObsoleteControl.value);
        isObsoleteControl.markAsDirty();
    }

    public toggleCampaignLocationFeature(locationGroupControl: FormGroup) {
        if ((<any>event.srcElement).tagName === "INPUT") {
            return;
        }

        let isCampaignLocationFeatureControl = locationGroupControl.get("IsCampaignLocationFeature");
        isCampaignLocationFeatureControl.setValue(!isCampaignLocationFeatureControl.value);
        isCampaignLocationFeatureControl.markAsDirty();
    }

    private setOptionsForNewOptionType(optionTypeValue: OptionType) {
        this.currentOptionType = optionTypeValue;
        this.HasNA = false;
        while (this.SubGroups.length !== 0) {
            this.SubGroups.removeAt(0);
        }
        //User chose to proceed with the change
        if (optionTypeValue === "Bool") {
            let yes = this.createEmptySubGroup();
            yes.Name = "Yes";
            let yesCtrl = this.getNewSubGroupCtrl(yes, this.currentOptionType);
            this.SubGroups.push(yesCtrl);

            let no = this.createEmptySubGroup();
            no.Name = "No";
            let noCtrl = this.getNewSubGroupCtrl(no, this.currentOptionType);
            this.SubGroups.push(noCtrl);

            //set default assignment rule to one to one for optionType = yes/no
            //disable assignment rule selection
            this.Rule.patchValue("Single");
            this.Rule.disable();
            this.disableRuleSelection = true;
        } else {
            this.SubGroups.push(this.getNewSubGroupCtrl(this.createEmptySubGroup(), this.currentOptionType));

            //enable assignement rule selection for none yes/no type
            this.disableRuleSelection = false;
            this.Rule.enable();
        }
    }

    public getLocationGroup(id: string) {
        this.loaded = false;
        this.promise = this.locationGroupService.get<LocationGroupModelEdit>(id).then((response: LocationGroupModelEdit) => {
            //sort subGroups
            this.locationGroupService.sortSubGroup(response.SubGroups, response.OptionType);

            this.currentOptionType = response.OptionType;

            let fullSizeImage = (response.Media && response.MasterMediaId !== this.emptyGuid) ?
                `${response.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}` : "";

            this.locationGroupForm.patchValue({
                Id: response.Id,
                BusinessIdentity: response.BusinessIdentity,
                Name: response.Name,
                Notes: response.Notes,
                Rule: response.Rule,
                OptionType: response.OptionType,
                MasterMediaId: response.MasterMediaId || this.emptyGuid,
                MediaType: response.MediaType || "Unknown",
                RemoveImage: "",
                FullSizeImage: fullSizeImage,
                SelectedImage: fullSizeImage ? fullSizeImage.indexOf("/PlaceHolder/") === -1 : false,
                IsObsolete: response.IsObsolete,
                IsCampaignLocationFeature: response.IsCampaignLocationFeature
            });

            this.HasNA = response.SubGroups.some(s => !!s.IsNA);
            const subGroupFGs = response.SubGroups.map(subGroup => this.getNewSubGroupCtrl(subGroup, subGroup.IsNA ? "Text" : response.OptionType));
            const subGroups = new FormArray(subGroupFGs);
            this.locationGroupForm.setControl("SubGroups", subGroups);

            this.originalRule = response.Rule;
            this.getFocusableInput();

            this.loaded = true;
        });
    }

    private getFocusableInput() {
        this.focusableInput = "subGroupHtmlName" + (this.indexer - 1);
    }

    private getNewSubGroupCtrl(subGroup: LocationSubGroupModelEdit, optionType: OptionType): FormGroup {
        let nameVal: any = subGroup.Name;
        if (!!nameVal) {
            if (optionType === "Date") {
                nameVal = new Date(nameVal);
            } else if (optionType === "Number") {
                nameVal = Number(nameVal);
            }
        }
        let fullSizeImage = (subGroup.Media && subGroup.MasterMediaId !== this.emptyGuid) ?
            `${subGroup.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}` : "";

        let nameControl = new FormControl({ value: nameVal, disabled: subGroup.IsNA }, [Validators.required, this.subGroupValidator, Validators.maxLength(50)]);

        let ctrl = new FormGroup({
            Id: new FormControl(subGroup.Id),
            BusinessIdentity: new FormControl(subGroup.BusinessIdentity),
            Name: nameControl,
            InputName: new FormControl(subGroup.InputName),
            LocationCount: new FormControl(subGroup.LocationCount),
            MasterMediaId: new FormControl(subGroup.MasterMediaId || this.emptyGuid),
            MediaType: new FormControl(subGroup.MediaType || "Unknown"),
            RemoveImage: new FormControl(""),
            FullSizeImage: new FormControl(fullSizeImage),
            IdNumber: new FormControl(this.idNumber++),
            SelectedImage: new FormControl(fullSizeImage ? fullSizeImage.indexOf("/PlaceHolder/") === -1 : false),
            IsNA: new FormControl(subGroup.IsNA)
        });
        if (optionType === "Bool") {
            ctrl.disable();
            ctrl.valueChanges.subscribe((value) => {
                if (ctrl.valid) {
                    (value as Date).setHours(0, 0, 0, 0);
                    ctrl.patchValue(value, { emitEvent: false });
                }
            });

            //disable assignment rule selection
            this.Rule.disable();
            this.disableRuleSelection = true;
        }
        return ctrl;
    }

    public addSubGroup(isNa: boolean = false) {
        let subGroups = this.SubGroups;

        const ctrl = this.getNewSubGroupCtrl(this.createEmptySubGroup(isNa), isNa ? "Text" : this.currentOptionType);
        subGroups.push(ctrl);
        if (isNa) {
            this.HasNA = true;
            this.locationGroupForm.markAsDirty();
            setTimeout(() => { this.SubGroups.controls.forEach(newCtrl => newCtrl.get("Name").updateValueAndValidity()); }, 50);
        }
        this.getFocusableInput();
    }

    removeSubGroup(frmGrpIndex: number, toolTip: NgbTooltip) {
        toolTip.close();

        let subGroups = this.SubGroups;

        if (subGroups.controls.length <= 1) {
            this.ipsMessage.error("AT_LEAST_ONE_OPTION_REQUIRED");
            return;
        }

        let subgroup = subGroups.controls[frmGrpIndex];

        if (subgroup && subgroup.get("Id").value > 0) {
            const subGroupId = <number>subgroup.get("Id").value;
            this.campaignService.getCountByLocationSubGroup(subGroupId).then((count) => {
                let errorMessage = "";
                if (count > 0) {
                    errorMessage = IpsString.Format(this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_CAMPAIGN"), count);
                }
                this.surveyService.getLocationSubGroupSurveyInUse(subGroupId)
                    .then((surveysInUse: SurveyInUseModel[]) => {
                        let usedInAudience = false;
                        let surveyNames: string[] = [];
                        if (surveysInUse.length > 0) {
                            let surveysWithAudience = surveysInUse.filter(s => s.Audiences && s.Audiences.length > 0);
                            usedInAudience = surveysWithAudience.length > 0;
                            surveyNames = surveysWithAudience.map(s => s.Name);
                        }
                        if (usedInAudience) {
                            errorMessage = errorMessage === "" ? errorMessage : errorMessage + "\n\n";
                            errorMessage = errorMessage +
                                IpsString.Format(
                                    this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_SURVEY"),
                                    surveyNames.join(", "));
                        }
                        this.paymentSettingsService.checkLocationSubGroupsAreInUse([subGroupId])
                            .then(result => {
                                if (result) {
                                    errorMessage = errorMessage === "" ? errorMessage : errorMessage + "\n\n";
                                    errorMessage += this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_PAYMENTSETTINGS");
                                }
                                if (errorMessage !== "") {
                                    this.ipsMessage.error(errorMessage);
                                } else {

                                    this.locationGroupService.deleteCheck(subGroupId.toString())
                                        .then((response: any) => {
                                            let canDeleteErrorList = response;

                                            if (canDeleteErrorList.length > 0) {
                                                this.ipsMessage.error("Unable to delete! Record is begin used in the following Market Groups:\r\n" + canDeleteErrorList.join("\r\n"));
                                            } else {
                                                this.ipsMessage.confirm()
                                                    .then((confirm: boolean) => {
                                                        if (confirm) {
                                                            if (this.deleteSubGroupCtrl(frmGrpIndex)) {
                                                                this.locationGroupForm.markAsDirty();
                                                            }
                                                        }
                                                    })
                                                    .catch(() => {
                                                        // rejection
                                                    });
                                            }

                                        })
                                        .catch(() => {
                                            this.ipsMessage.error("Unable to delete! Record is may be used in the following Market Groups:\r\n-ERROR-");
                                        });
                                }
                            });
                    });
            });
        } else {
            if (this.deleteSubGroupCtrl(frmGrpIndex)) {
                this.locationGroupForm.markAsDirty();
            }
        }
    }

    private deleteSubGroupCtrl(index: number) {

        const subGroupsControl = <FormArray>this.locationGroupForm.controls["SubGroups"];
        const count = subGroupsControl.controls.length;
        const control = subGroupsControl.at(index);
        if (!!control.get("IsNA").value) {
            this.HasNA = false;
            setTimeout(() => { this.SubGroups.controls.forEach(newCtrl => newCtrl.get("Name").updateValueAndValidity()); }, 50);
        }
        subGroupsControl.removeAt(index);

        return count !== subGroupsControl.controls.length;
    }

    public saveLocationGroupPrompt(redirect: boolean) {
        let id = this.locationGroupForm.value.Id || 0;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveLocationGroup(), progressMessage: "SAVING" }).then((result: boolean) => {
            if (result && redirect) {
                this.$state.go("main.storeProfileLocationGroup.search");
            } else {
                this.$state.go("main.storeProfileLocationGroup.edit", { id: this.locationGroupForm.value.Id }, { reload: true });
            }
        });
    }

    private saveLocationGroup() {
        // use getRawValue to include disabled controls values.
        let locationGroup = this.locationGroupForm.getRawValue();

        //add business Identity to locationGroup/subgroupd
        for (let i = 0; i < locationGroup.SubGroups.length; i++) {
            let subGroup = locationGroup.SubGroups[i];
            subGroup.BusinessIdentity = this.activeProfileService.businessIdentity;
            if (this.currentOptionType === "Date" && !subGroup.IsNA) {
                // Store in the format we want on the View and List pages. This is also parseable by .Net
                const date = subGroup.Name as Date;
                subGroup.Name = this.datePipe.transform(date, "MMM d, yyyy");
            }
        }
        locationGroup.BusinessIdentity = this.activeProfileService.businessIdentity;

        if (locationGroup.Id) {
            return this.locationGroupService.put<LocationGroupModelEdit>(locationGroup).then((response) => {

                this.populateDataFromResponse(response);

                return this.saveImages().then(r => {
                    this.populateMediaDataFromResponse(r);
                    this.locationGroupForm.markAsPristine();
                });

            });
        } else {
            return this.locationGroupService.post<LocationGroupModelEdit>(locationGroup).then((response) => {

                this.populateDataFromResponse(response);

                return this.saveImages().then(r => {
                    this.populateMediaDataFromResponse(r);
                    this.locationGroupForm.markAsPristine();
                 });
            });
        }
    }

    public saveImages(): Promise<any> {
        let promises = [];
        let imageSaveList = this.pullImageObject();

        for (let i = 0; i < imageSaveList.length; i++) {

            if (imageSaveList[i].Type === "feature") {
                //Feature image
                promises.push(this.locationGroupService.saveFeatureImage(imageSaveList[i].Id, imageSaveList[i].Files));
            } else {
                //Option images
                promises.push(this.locationGroupService.saveOptionImage(imageSaveList[i].Id, imageSaveList[i].Files));
            }
        }

        if (promises.length === 0) {
            promises.push(Promise.resolve());
        }

        return Promise.all(promises);
    }

    public pullImageObject(): ImageSave[] {
        //Pull all the images that need to be saved
        return this.images.filter(item => item.fileInput.nativeElement.files.length > 0).map(m => {

            let id = 0;
            let type = "";
            if (m.name.toLowerCase() === "featureimage") {
                id = this.locationGroupForm.value.Id;
                type = "feature";
            } else {
                id = this.SubGroups.controls.find(ctrl => ctrl.get("IdNumber").value === Number(m.name)).get("Id").value;
                type = "option";
            }

            return <ImageSave>{ Id: id, Type: type, Files: m.fileInput.nativeElement.files[0] };
        });
    }

    public populateDataFromResponse(response: LocationGroupModelEdit) {
        this.locationGroupForm.patchValue({
            Id: response.Id,
        });

        //Do options
        this.SubGroups.controls.forEach(option => {

            let subGroupItem = response.SubGroups.find((item) => String(item.Name).toLowerCase() === String(option.value.Name).toLowerCase());
            if (subGroupItem) {
                option.patchValue({ Id: subGroupItem.Id });
            }
        });
    }

    private populateMediaDataFromResponse(response: LocationSubGroupModel[]) {
        if (response && response[0]) {
            response.forEach(result => {
                let option = this.SubGroups.controls.find((item) => item.value.Id === result.Id);

                if (option) {
                    option.patchValue({ MasterMediaId: result.MasterMediaId, MediaType: result.MediaType });
                }
            });
        }
    }

    public deleteLocationGroupPrompt() {
        let promptMessage = IpsString.Format(this.promptDeleteBodyMessage, this.locationGroupForm.get("Name").value);
        const locationGroupId = this.locationGroupForm.value.Id;
        // TODO: add check for campaigns in use also.
        this.campaignService.getCountByLocationGroup(locationGroupId).then((campaignCount: number) => {
            this.surveyService.getLocationGroupSurveyInUse(locationGroupId).then((surveysInUse: SurveyInUseModel[]) => {
                let deleteNotAllowedMsg = "";
                if (campaignCount > 0) {
                    deleteNotAllowedMsg = IpsString.Format(this.translateService.instant("UNABLE_DELETE_LOCGROUP_USED_BY_CAMPAIGN"), campaignCount);
                }
                const usedInAudience = surveysInUse.length > 0 && surveysInUse.some(s => s.Audiences && s.Audiences.length > 0);
                // If used in an audience, cannot delete.
                if (usedInAudience) {
                    const surveyNames = surveysInUse.filter(s => s.Audiences && s.Audiences.length > 0)
                        .map(s => s.Name);
                    const msg = IpsString.Format(
                        this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_SURVEY"),
                        surveyNames.join(", "));

                    deleteNotAllowedMsg += deleteNotAllowedMsg.length > 0 ? "\n\n" + msg : msg;
                }

                const subGroupIds = this.SubGroups.value.map(sg => sg.Id);
                this.paymentSettingsService.checkLocationSubGroupsAreInUse(subGroupIds)
                    .then(result => {
                        if (result) {
                            deleteNotAllowedMsg = deleteNotAllowedMsg === "" ? deleteNotAllowedMsg : deleteNotAllowedMsg + "\n\n";
                            deleteNotAllowedMsg += this.translateService.instant("UNABLE_DELETE_SUBGROUP_USED_BY_PAYMENTSETTINGS");
                        }

                        if (deleteNotAllowedMsg.length > 0) {
                            return this.ipsMessage.error(deleteNotAllowedMsg);
                        }

                        // If we made it this far, not used in Markets/Audiences. Check for questions in use.
                        if (surveysInUse.length > 0) {
                            this.ipsModal.displayComponent(LocationGroupDeleteModalComponent,
                                { surveysInUse: surveysInUse },
                                { backdrop: "static" }).then((agreed: boolean) => {
                                    if (agreed) {
                                        return this.ipsModal.prompt({
                                            displayMode: "Progress",
                                            workFunction: () => this.deleteLocationGroup(),
                                            progressMessage: "DELETING"
                                        },
                                            {}).then((confirm: boolean) => {
                                                if (confirm) {
                                                    this.$state.go("main.storeProfileLocationGroup.search");
                                                }
                                            });
                                    }
                                },
                                    (canceled) => {
                                        // Do nothing on cancel
                                    });
                            // Nothing in use
                        } else {
                            this.ipsMessage
                                .confirmDelete({
                                    body: promptMessage,
                                    workFunction: () => this.deleteLocationGroup(),
                                    progressMessage: "DELETING"
                                }).then((confirm: boolean) => {
                                    if (confirm) {
                                        this.$state.go("main.storeProfileLocationGroup.search");
                                    }
                                });
                        }
                    }, (error) => {
                        return this.ipsMessage.error(error);
                    });

            }, (error) => {
                return this.ipsMessage.error(error);
            });
        }, (error) => {
            return this.ipsMessage.error(error);
        });

    }

    private deleteLocationGroup() {
        let locationGroup = this.locationGroupForm.value;
        return this.locationGroupService.delete(locationGroup.Id);
    }

    private subGroupValidator(control: AbstractControl) {

        if (!control.parent) {
            return null;
        }
        // Skip type validation for this one
        if (control.parent.value.IsNA) {
            return null;
        }

        const subGroups = control.parent.parent as FormArray;
        let controlValue: any = null;
        if (!!control.value) {
            if (control.value.getMonth) {
                // Use toDateString to ignore the time component of the date.
                controlValue = (control.value as Date).toDateString();
            } else {
                controlValue = control.value.toString().toLowerCase();
            }
        }
        let itemsWithSameName = subGroups.controls.filter(function (sg) {
            let sgValue = sg.get("Name").value;
            if (!!sgValue) {
                if (sgValue.getMonth) {
                    sgValue = sgValue.toDateString();
                } else {
                    sgValue = sgValue.toString().toLowerCase();
                }
            }
            return sgValue === controlValue;
        });

        if (itemsWithSameName.length > 1) {
            return { dupeSubGroupNameMessage: { valid: false } };
        }

        if (controlValue === "not applicable") {
            return { reservedName: { valid: false } };
        }
        return null;
    }

    public ruleChange() {
        if (this.originalRule === "Multiple") {
            //call server to validate if rule can change
            this.locationGroupService.canSwitchRule(this.locationGroupForm.value.Id.toString()).then((result) => {
                if (!result) {
                    this.Rule.patchValue(this.originalRule);
                    this.ipsMessage.error("UNABLE_CHANGE_RULE");
                }
            });
        }
    }

    public onSelectedImageChanged(files: any, name: string) {
        let featureImageFile: FeatureImage;
        let image = this.images.find((item) => item.name === name.toString());

        if (this.featureImageFiles) {
            featureImageFile = this.featureImageFiles.find((item) => item.Name === name.toString());
        }

        if (featureImageFile) {
            featureImageFile.Files = files;
        } else {
            this.featureImageFiles.push({ Name: name, Files: files });
        }

        this.locationGroupForm.markAsDirty();

        if (!files) {
            image.selectedImage = false;

            if (typeof(name) === "string" && name.toLowerCase() === "featureimage") {
                this.locationGroupForm.patchValue({
                    MasterMediaId: "00000000-0000-0000-0000-000000000000"
                });
            } else {
                let subGroup = this.SubGroups.controls.find(ctrl => ctrl.get("IdNumber").value === name);
                subGroup.get("MasterMediaId").setValue("00000000-0000-0000-0000-000000000000");
            }

        }
        this.locationGroupForm.markAsDirty();
    }
}
