<div>
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0" translate>SHOW_LOCATION_INFO</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive">
            <ips-list-search headerLabel=""
                             searchLabel="{{'LOCATION_GROUP' | translate }}"
                             searchLabelPlural="{{'LOCATION_GROUPS' | translate }}"
                             labelLoading="{{'ACCESSING_LOCATION_INFO' | translate }}"
                             labelLoadingError="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}"
                             [listData]="listSearch"
                             (onListDataChange)="getListData($event.search)"
                             [busy]="busy"
                             [scroller]="true"
                             [hideHeader]="true"
                             [hideTotals]="true"
                             [promise]="promise"
                             [autoFocus]="true"
                             [isModal]="true"
                             [showUnCheckAll]="true"
                             (onUnCheckAllClick)="handleUncheckAll()"
                             [showCheckAll]="activeBusinessId != 'Bsns.00000104'"
                             (onCheckAllClick)="handleCheckAll()"
                             [selectedCount]="selectedCount"
                             [query]="query">
                <ng-template #listSearchItemTemplate let-result="result">
                    <div class="input-group no-round">
                        <div class="form-control input-full py-3 border-0 clicker" (click)="optionChecked(result)">
                            {{result.LableName | translate}} : {{result.Label | translate}}
                            <div class="location-group-rule-icon" [ngClass]="result.RuleClass" [ngbTooltip]="result.RuleTooltip" placement="bottom" container="body"></div>
                        </div>
                        <div class="input-group-append">
                            <div class="input-group-text border-top-0 border-bottom-0 border-right-0">
                                <input name="{{'selectedCheck' + result.Label}}" id="selectedCheck" type="checkbox" [(ngModel)]="result.Selected" (ngModelChange)="selectedOptionChanged(result)" />
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ips-list-search>
            <div class="row" *ngIf="!busy">
                <div class="col-12 pl-4 pr-4">
                    <button type="submit" class="btn btn-default btn-lg btn-block search-modal-done-button mt-2"
                            id="doneButton"
                            (click)="returnResults()" translate>
                        DONE
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
