<div class="row">
    <div class="col" *ngIf="brandBulletin">
        <div class="extraSpace visible-xs-block visible-sm-block"></div>
        <div class="extraSpace"></div>
        <div id="noticeContainer" class="notice-container">
            <div class="notice-label-set"><i class="fa fa-bell notice-icon" aria-hidden="true"></i>{{'NOTICE_ALLCAPS' | translate}}:</div>
            <div class="notice-text">{{brandBulletin.Value}}</div>
        </div>
    </div>
    <!-- Force next columns to break to new line -->
    <div class="w-100"></div>
    <div class="col-12 landingNavSpace"></div>
    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.storeProfileLocation.search">
        <a id="locationsLink" class="landingNavLink" uiSref="main.storeProfileLocation.search">
            <div class="locationsImg landingNavIcon"></div>
            <h3 translate>LOCATIONS</h3>
        </a>
    </section>
    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.storeProfileLocationGroup.search">
        <a id="locationGroupsLink" class="landingNavLink" uiSref="main.storeProfileLocationGroup.search">
            <div class="locationGroupsImg landingNavIcon"></div>
            <h3 translate>LOCATION_GROUPS</h3>
        </a>
    </section>
    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.storeProfileFixture.search">
        <a id="fixturesLink" class="landingNavLink" uiSref="main.storeProfileFixture.search">
            <div class="fixturesImg landingNavIcon"></div>
            <h3 translate>FIXTURES</h3>
        </a>
    </section>
    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.storeProfileFixtureGroup.search">
        <a id="fixtureGroupsLink" class="landingNavLink" uiSref="main.storeProfileFixtureGroup.search">
            <div class="fixtureGroupImg landingNavIcon"></div>
            <h3 translate>FIXTURE_GROUPS</h3>
        </a>
    </section>
    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.storeProfileHolderVersion.search">
        <a id="versionLink" class="landingNavLink" uiSref="main.storeProfileHolderVersion.search">
            <div class="holderVersionImg landingNavIcon"></div>
            <h3 translate>VERSION_INFO</h3>
        </a>
    </section>
    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.storeProfileSpace.search">
        <a id="spacesLink" class="landingNavLink" uiSref="main.storeProfileSpace.search">
            <div class="spacesImg landingNavIcon"></div>
            <h3 translate>SPACES</h3>
        </a>
    </section>
    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.storeProfileAlternateAddress.search">
        <a id="alternateAddressLink" class="landingNavLink" uiSref="main.storeProfileAlternateAddress.search">
            <div class="alternateAddressesImg landingNavIcon"></div>
            <h3 translate>ALTERNATE_ADDRESSES</h3>
        </a>
    </section>

    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.storeProfileStoreFront">
        <a id="storeFrontLink" class="landingNavLink" uiSref="main.storeProfileStoreFront">
            <div class="storeFrontSetupImg landingNavIcon"></div>
            <h3 translate>Store Front Setup</h3>
        </a>
    </section>
</div>
