import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-browser-support",
    templateUrl: "./browser-support.component.html",
    styleUrls: ["./browser-support.component.scss"]
})
export class BrowserSupportComponent implements OnInit {

    constructor() {}

    ngOnInit() {
    }

}
