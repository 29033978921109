import { Component, OnInit, ViewChild, HostListener, OnDestroy, AfterViewChecked } from "@angular/core";
import { I18nPluralPipe } from "@angular/common";
import { SearchInfo, QueryParamItem, ActiveProfileService, ListSearchHelper } from "imagine-ui-ng-core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { IpsModalService } from "imagine-ui-ng-modal";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchComponent, ComplexSearchBoxComponent, TranslatedTexts } from "imagine-ui-ng-list-search";
import { StateParams, Transition } from "@uirouter/angular";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";

import { LocationGroupModel, LocationModel, LocationModelWithLabel, LocationGroupAssignmentModel, SimpleSearchLocationGroupModel, LocationGroupService, LocationService } from "../../index";
import { FormControl } from "@angular/forms";

interface LocationGroupWithLabelModel extends LocationGroupModel {
    Label: string;
}

interface ResultModel {
    item: any;
}


interface FilterOptions {
    locationGroup: (locationGroup: LocationGroupWithLabelModel) => boolean;
    assignment: (locationGroup: LocationGroupWithLabelModel) => boolean;
}

interface AssignmentOption {
    Id: number;
    Label: string;
}

interface ScrollInfo {
    search: string;
    positionY: number;
    chunkIndex: number;
    location: any;
    locationFeature: any;
    assignment: any;
    collapseStates: any;
}

interface CollapseState {
    id: number;
    state: boolean;
}

@Component({
    selector: "app-location-group-search",
    templateUrl: "./location-group-search.component.html",
    styleUrls: ["../store-profile-location-group/store-profile-location-group.component.scss"]
})
export class LocationGroupSearchComponent implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChild(ComplexSearchBoxComponent) private searchBox: ComplexSearchBoxComponent;
    @ViewChild(ListSearchComponent) private listSearch: ListSearchComponent;

    public pluralMapping: { [k: string]: string } = {
        "=1": "label",
        "other": "label"
    };
    private dataService: LocationGroupService;
    private unsavedChangesExist = false;
    private location: ListSearchHelper;
    private allLabel = "ALL_UPPERCASE";
    private pendingAssignmentLabel = "PENDING_ASSIGNMENT";
    private locGroupLabel: string;
    private locGroupLabelPlural: string;

    public locationGroups: ListSearchHelper;
    public busy = true;
    public loaded = false;
    public editing = false;
    public includeObsolete = false;
    public showCampaignLocationFeature = false;
    public disableAssignment = false;
    public toolTipCreateNewLocationGroup = "";
    public maxDropDownCount = 2;
    public totalLocationCount = 0;
    public totalLocationGroupCount = 0;

    // Lists for drop downs
    public availableLocations = [{ Id: 0, Label: this.allLabel, Name: this.allLabel } as LocationModelWithLabel];
    public availableLocationGroups: SimpleSearchLocationGroupModel[] = [
        { Id: 0, Label: this.allLabel, Name: this.allLabel } as SimpleSearchLocationGroupModel
    ];
    public availableAssignments: AssignmentOption[] = [
            { Id: 0, Label: this.allLabel },
            { Id: 1, Label: this.pendingAssignmentLabel }
    ];
    public selectedLocationGroup: SimpleSearchLocationGroupModel;
    public selectedAssignment: AssignmentOption;
    private saveData: LocationGroupAssignmentModel[] = [];


    public currentSearchText = "";
    public currentFilterOptions = { locationGroup: null, assignment: null } as FilterOptions;
    public userQuery = "";
    public reloading = false;
    public selectedLocation = { Id: 0 } as LocationModelWithLabel;
    public breadCrumbLabel: string;
    public promise: Promise<void>;
    public savePromptFn: any;
    private searchLabel: string;
    private searchLabelPlural: string;
    private translated = false;


    private scrollY: number;
    private searchInfo: SearchInfo;
    private scrollInfo: ScrollInfo;
    private initailDocumentHeight: number;
    private scrollInfoKey: string;

    constructor(private locationGroupService: LocationGroupService, private locationService: LocationService, listSearchHelper: ListSearchHelper,
        private ipsMessage: IpsMessageService, private translateService: TranslateService, private ipsModal: IpsModalService,
        protected activeProfileService: ActiveProfileService, private transition: Transition
        ) {
        this.dataService = locationGroupService;
        this.locationGroups = listSearchHelper.getListSearchHelper(this.dataService);
        this.location = listSearchHelper.getListSearchHelper(locationService);
        this.savePromptFn = this.savePrompt.bind(this);
    }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.loadScrollInfo();

        this.getLocationList();
        this.getLocationGroupList();

        this.setInitalFilters();

        this.initailDocumentHeight = document.documentElement.scrollHeight;

    }

    private loadScrollInfo() {
        this.scrollInfoKey = "loc_feature_search_scroll_" + this.activeProfileService.businessIdentity;
        this.scrollInfo = JSON.parse(localStorage.getItem(this.scrollInfoKey));

        if (this.scrollInfo) {
            this.selectedLocation = this.scrollInfo.location;
            this.selectedAssignment = this.scrollInfo.assignment;
            this.currentSearchText = this.scrollInfo.search;
            this.setSelectedLocationGroup(this.scrollInfo.locationFeature);
        } else {
            this.scrollInfo = { collapseStates: [] } as ScrollInfo;
            this.selectedLocation = this.availableLocations[0];
            this.selectedAssignment = this.availableAssignments[0];
            this.setSelectedLocationGroup(this.availableLocationGroups[0] as LocationGroupWithLabelModel);
        }
    }

    private translateText() {
        this.translateService.get(["LOCATION_GROUPS", "LOCATION_GROUP", "ALL_UPPERCASE", "PENDING_ASSIGNMENT", "CREATE_NEW_LOCATION_GROUP"]).subscribe((res: [string]) => {
            this.locGroupLabel = res["LOCATION_GROUP"];
            this.locGroupLabelPlural = res["LOCATION_GROUPS"];

            this.allLabel = res["ALL_UPPERCASE"];
            this.pendingAssignmentLabel = res["PENDING_ASSIGNMENT"];
            this.toolTipCreateNewLocationGroup = res["CREATE_NEW_LOCATION_GROUP"];

            this.availableLocations[0].Label = this.allLabel;
            this.availableLocationGroups[0].Label = this.allLabel;
            this.availableAssignments[0].Label = this.allLabel;
            this.availableAssignments[1].Label = this.pendingAssignmentLabel;


            this.pluralMapping = {
                "=1": this.locGroupLabel,
                "other": this.locGroupLabelPlural
            };

            this.translated = true;
        });
    }

    private disableAssignmentSelect() {
        this.disableAssignment = true;
        this.selectedAssignment = { Id: 0, Label: this.allLabel };
    }

    private setSelectedLocationGroup(item: LocationGroupWithLabelModel) {
        this.selectedLocationGroup = item;
        this.currentFilterOptions.locationGroup = item.Id === 0 ? null : this.locationGroupFilter;
        if (item.Id !== 0) {
            this.disableAssignmentSelect();
        } else {
            this.disableAssignment = false;
        }
    }

    private updateSelectedLocationGroup(item: LocationGroupWithLabelModel) {
        this.setSelectedLocationGroup(item);
        this.searchBox.selectedItem2 = item;
        this.reloadData();
    }

    private updateSelectedAssignment(item: AssignmentOption) {
        this.selectedAssignment = item;
        //Remove existing collapsed states so we don't interfere with new searches
        this.scrollInfo.collapseStates = [];

        this.reloadData();
    }

    private updateSelectedLocation(item: LocationModelWithLabel) {
        this.selectedLocation = item;
        this.searchBox.selectedItem1 = item;
        this.reloadData();
    }


    private removeAllItemFromList(list: any[]) {
        if (list.length > 0 && list[0].Id === 0) {
            list.shift();
        }
    }

    private showLocationSelectionModal() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "location" } })
            .then((response: ResultModel) => {
                this.updateSelectedLocation(response.item);
                // remove the first item, which is All
                this.removeAllItemFromList(this.location.resultList);

                //Remove existing collapsed states so we don't interfere with new searches
                this.scrollInfo.collapseStates = [];
            },
            // Rejected
            () => {
                // remove the first item, which is All
                this.removeAllItemFromList(this.location.resultList);
            });
    }

    private showLocationGroupSelectionModal() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "locationgroup" } })
            .then((response: ResultModel) => {
                this.updateSelectedLocationGroup(response.item);
                // remove the first item, which is All
                this.removeAllItemFromList(this.locationGroups.resultList);

                //Remove existing collapsed states so we don't interfere with new searches
                this.scrollInfo.collapseStates = [];
            },
            // Rejected
            () => {
                // remove the first item, which is All
                this.removeAllItemFromList(this.locationGroups.resultList);
            });
    }

    // Set rule class info, and original values.
    private setLocGroupValues() {

        for (let i = 0; i < this.locationGroups.resultList.length; i++) {
            let locationGroup = this.locationGroups.resultList[i];
            if (locationGroup.init) {
                continue;
            }

            this.locationGroupService.setLocFeatureInfo(locationGroup, this.translateService);

            if (locationGroup.Rule === "Single") {
                for (let j = 0; j < locationGroup.SubGroups.length; j++) {
                    if (locationGroup.SubGroups[j].LocationCount === 1) {
                        locationGroup.originalSelectedSubGroup = locationGroup.SubGroups[j].Id;
                        locationGroup.selectedSubGroup = locationGroup.SubGroups[j].Id;
                        break;
                    }
                }
                if (!locationGroup.originalSelectedSubGroup) {
                    // If no subGroup is assigned yet, then we have to set by hand to -1, which isn"t a valid subgroup id
                    locationGroup.originalSelectedSubGroup = -1;
                }
            } else {
                for (let k = 0; k < locationGroup.SubGroups.length; k++) {
                    let subGroup = locationGroup.SubGroups[k];
                    subGroup.originalLocationCount = subGroup.LocationCount;
                }
            }

            //sort locationSubGroup
            this.locationGroupService.sortSubGroup(locationGroup.SubGroups, locationGroup.OptionType);

            // singnal that we did setup on original values and rule info
            locationGroup.init = true;
        }
    }
    private getListDataInternal(search: string, filterOption: FilterOptions, forceReload: boolean) {
        if (this.busy) {
            return;
        }



        if (!search) {
            search = "";
        }
        let additionalQueryParams: QueryParamItem[] = [];
        this.editing = this.selectedLocation && this.selectedLocation.Id > 0;
        if (this.editing) {
            additionalQueryParams.push({ param: "locationId", paramValue: this.selectedLocation.Id.toString() });
        }
        if (this.selectedLocationGroup && this.selectedLocationGroup.Id > 0) {
            additionalQueryParams.push({ param: "locationGroupId", paramValue: this.selectedLocationGroup.Id.toString() });
        }

        if (this.includeObsolete) {
            additionalQueryParams.push({ param: "ShowObsoleteItems", paramValue: this.includeObsolete.toString() });
        }

        if (this.showCampaignLocationFeature) {
            additionalQueryParams.push({ param: "ShowCampaignLocationFeatures", paramValue: this.showCampaignLocationFeature.toString() });
        }

        //Override record count and pull all chucks at once
        let recordCount = 15;
        if (this.scrollInfo && this.scrollInfo.chunkIndex > 0) {
            recordCount = 15 * this.scrollInfo.chunkIndex;
        }

        this.searchInfo = { searchText: search, showUnassignedItems: this.selectedAssignment.Id === 1, additionalQueryParams: additionalQueryParams, recordCount: recordCount } as SearchInfo;
        this.promise = this.locationGroups.searchHelper(this.searchInfo, forceReload).then(() => {

            // Catch condition when single array result is return that is a null object. This state should reset the filters and try again - spoke to Eric about this 9/21/2018
            if (this.locationGroups.resultList.length === 1 && !this.locationGroups.resultList[0] && (this.searchInfo.searchText !== "" || this.searchInfo.additionalQueryParams.length > 0)) {
                this.locationGroups.resultList = [];

                //Reset to default values
                this.selectedLocation = this.availableLocations[0];
                this.selectedAssignment = this.availableAssignments[0];
                this.setSelectedLocationGroup(this.availableLocationGroups[0] as LocationGroupWithLabelModel);

                //Call to get new results
                return this.getListDataInternal("", { locationGroup: null, assignment: null }, false);
            }

            if (filterOption) {
                this.locationGroups.resultList = filterOption.locationGroup ? this.locationGroups.resultList.filter(filterOption.locationGroup) : this.locationGroups.resultList;
            }
            this.setLocGroupValues();
            this.reloading = false;

            //Set existing loc features collapsible state
            let count = this.locationGroups.resultList.length;
            for (let i = 0; i < count; i++) {
                let item = this.locationGroups.resultList[i];
                item.isCollapsed = true;

                //Check for previous saved loc feature collapse state
                let stateResult = this.scrollInfo.collapseStates.find(stateItem => stateItem.id === item.Id );

                if (stateResult) {
                    item.isCollapsed = stateResult.state;
                    continue;
                }

                if ((i === 0 && count === 1)) {
                    item.isCollapsed = false;
                    continue;
                }

                item.SubGroups.forEach(subItem => {
                    if (!!this.currentSearchText && subItem.Name.toLowerCase().indexOf(this.currentSearchText.toLowerCase()) > -1) {
                        item.isCollapsed = false;
                    }
                });
            }
        });
    }

    public ngAfterViewChecked(): void {

        //Used for scrolling back to previous location
        if (this.scrollInfo && this.scrollInfo.chunkIndex > 0 && this.initailDocumentHeight < document.documentElement.scrollHeight && document.documentElement.scrollHeight >= this.scrollInfo.positionY) {
            //Set chunk index to allow back to default recordCount row pulls
            this.locationGroups.chunkIndex = this.scrollInfo.chunkIndex;
            this.scrollInfo.chunkIndex = 0;
            window.scrollTo(0, this.scrollInfo.positionY);
        }

    }


    private getInitLoc(locId: string) {
        if (locId) {
            return this.locationService.get<LocationModelWithLabel>(locId).then((item) => {
                item.Label = `${item.LocationIdentifier} - ${item.Name} - ${item.Addresses[0].City}, ${item.Addresses[0].StateProvince}`;
                this.updateSelectedLocation(item);
            });
        } else {
            return Promise.resolve();
        }
    }
    private getInitLocGrp(locGrpId: string) {
        if (locGrpId) {
            return this.dataService.get<LocationGroupWithLabelModel>(locGrpId).then((initLg) => {
                initLg.Label = initLg.Name;
                this.updateSelectedLocationGroup(initLg);
            });
        } else {
            return Promise.resolve();
        }
    }

    //Checks if either LocationId or LocationGroupId are passed in on the URL.
    //If so, gets and sets their respective object in this controller (see this.selected...).
    private setInitalFilters() {
        let initalLocationGroupId = this.transition.params().locationGroupId;
        let initalLocationId = this.transition.params().locationId;

        Promise.all([this.getInitLoc(initalLocationId), this.getInitLocGrp(initalLocationGroupId)]).then(() => {
            this.busy = false;
            this.reloadData();
        });
    }
    public locationModalButtonVisible() {
        return this.totalLocationCount > this.maxDropDownCount;
    }

    public locationGroupModalButtonVisible() {
        return this.totalLocationGroupCount > this.maxDropDownCount;
    }

    public getListData(search: string, filterOption: FilterOptions, forceReload: boolean, scrolling: boolean) {
        if (!scrolling && this.unsavedChangesExist) {
            this.ipsMessage.confirm({
                body: "UNSAVED_CHANGES_FILTER",
                ok: "PROMPT_DISCARD_CHANGES",
                cancel: "PROMPT_KEEP_CHANGES"
            })
            .then(() => {
                //User chose to filter by search and discard changes
                this.reloadData();
            })
            .catch(() => {
                //User chose to keep changes and cancel filter change
                //vm.userQuery = CurrentSearchText;
            });
        } else { //no unsaved changes exist so just execute getListData
            this.getListDataInternal(search, filterOption, forceReload);
        }
    }

    public getObsoleteData(showObsolete: FormControl) {
        this.includeObsolete = !this.includeObsolete;
        this.reloadData();
    }

    public showCampaignLocationFeatures(campaignLocationFeatures: FormControl) {
        this.showCampaignLocationFeature = !this.showCampaignLocationFeature;
        this.reloadData();
    }

    public savePrompt (data: LocationGroupAssignmentModel[]) {
        this.saveData = data;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.save(), progressMessage: "SAVING" })
            .then(function (result: LocationGroupWithLabelModel[]) {
                return result;
            });
    }

    public reloadData() {
        this.reloading = true;
        this.unsavedChangesExist = false;
        return this.getListDataInternal(this.currentSearchText, this.currentFilterOptions, true);
    }
    public save() {
        // call the api
        return this.dataService.saveList(this.selectedLocation.Id.toString(), this.saveData).then((result) => {
            return this.reloadData();
        });
    }

    public notifyChangesMade(hasUnsavedChanges: boolean) {
        this.unsavedChangesExist = hasUnsavedChanges;
    }

    public locationGroupFilter = (locationGroup: LocationGroupWithLabelModel): boolean => {
        if (this.selectedLocationGroup.Id === 0) {
            return true;
        }

        return this.selectedLocationGroup.Id === locationGroup.Id;
    }

    public assignmentFilter = (locationGroup: LocationGroupWithLabelModel): boolean => {
        if (this.selectedAssignment.Id === 0) {
            return true;
        }
        return locationGroup.PendingAssignmentCount > 0;
    }

    public getLocationList() {
        this.locationService.head()
            .then((response) => {
                this.totalLocationCount = response;
                // If count is less than max allowed, then get the location list
                if (!this.locationModalButtonVisible()) {
                    // Update the service chunkCount so we get back all the locations.
                    this.locationService.chunkCount = this.maxDropDownCount;
                    this.location.searchHelper({ searchText: "" })
                        .then(() => {
                            this.location.resultList.forEach(function (item: LocationModelWithLabel) {
                                item.Label = `${item.LocationIdentifier} - ${item.Name} - ${item.Addresses[0].City}, ${item.Addresses[0].StateProvince}`;
                            });
                            this.availableLocations = this.availableLocations.concat(this.location.resultList);
                            if (this.availableLocations.length === 2 && this.availableLocations[0].Id === 0) {
                                this.removeAllItemFromList(this.availableLocations);
                                this.selectedLocation = this.availableLocations[0];
                            }
                        });
                }
            });

    }

    public getLocationGroupList() {
        this.locationGroupService.head()
            .then((response) => {
                this.totalLocationGroupCount = response;
                // If count is less than max allowed, then get the locationGroup list
                if (!this.locationGroupModalButtonVisible()) {
                    let searchParams = <SearchInfo>{ searchText: "", businessIdentity: this.activeProfileService.businessIdentity, chunkIndex: 0, recordCount: 1000 };
                    this.locationGroupService.simpleSearch(searchParams)
                        .then((lgResponse) => {
                            lgResponse.ResultList.forEach(function (item: SimpleSearchLocationGroupModel) {
                                item.Label = item.Name;
                            });
                            this.availableLocationGroups = this.availableLocationGroups.concat(lgResponse.ResultList);
                            if (this.availableLocationGroups.length === 2 && this.availableLocationGroups[0].Id === 0) {
                                this.removeAllItemFromList(this.availableLocationGroups);
                                this.setSelectedLocationGroup(this.availableLocationGroups[0] as LocationGroupWithLabelModel);
                            }
                        });
                }
            });
    }

    public selectLocation(item: LocationModelWithLabel) {
        if (this.unsavedChangesExist) {
            this.ipsMessage.confirm({
                body: "UNSAVED_CHANGES_NAV",
                ok: "PROMPT_LEAVE_PAGE",
                cancel: "PROMPT_STAY_PAGE"
            })
            .then(() => {
                //User chose to select new location and discard changes
                if (this.locationModalButtonVisible()) {
                    this.showLocationSelectionModal();
                } else {
                    this.updateSelectedLocation(item);
                }
            })
            .catch(() => {
                //User chose to keep changes and cancel filter change.  Do nothing because
                // we haven"t changed this.selectedLocation yet.
            });
        } else { //no unsaved changes exist so just set the selectedLocation
            if (this.locationModalButtonVisible()) {
                this.showLocationSelectionModal();
            } else {
                this.updateSelectedLocation(item);
            }
        }
    }

    public selectLocationGroup(item: LocationGroupWithLabelModel) {
        if (this.unsavedChangesExist) {
            let bodyMessage = "UNSAVED_CHANGES_FILTER";
            let okMessage = "PROMPT_DISCARD_CHANGES";
            let cancelMessage = "PROMPT_KEEP_CHANGES";
            if (this.locationGroupModalButtonVisible()) {
                bodyMessage = "UNSAVED_CHANGES_NAV";
                okMessage = "PROMPT_LEAVE_PAGE";
                cancelMessage = "PROMPT_STAY_PAGE";
            }
            this.ipsMessage.confirm({
                body: bodyMessage,
                ok: okMessage,
                cancel: cancelMessage
            })
            .then(() => {
                //User chose to select new location group and discard changes
                if (this.locationGroupModalButtonVisible()) {
                    this.showLocationGroupSelectionModal();

                } else {
                    this.updateSelectedLocationGroup(item);
                }
            })
            .catch(() => {
                // User chose to keep changes and cancel filter change
                this.selectedLocationGroup = { Id: this.selectedLocationGroup.Id, Label: this.selectedLocationGroup.Label } as LocationGroupWithLabelModel;
            });
        } else { //no unsaved changes exist so just set the selectedLocationGroup
            if (this.locationGroupModalButtonVisible()) {
                this.showLocationGroupSelectionModal();
            } else {
                this.updateSelectedLocationGroup(item);
            }
        }
    }

    public selectAssignment(item: AssignmentOption) {
        if (this.unsavedChangesExist) {
            this.ipsMessage.confirm({
                body: "UNSAVED_CHANGES_FILTER",
                ok: "PROMPT_DISCARD_CHANGES",
                cancel: "PROMPT_KEEP_CHANGES"
            })
            .then(() => {
                //User chose to select different assignment category and discard changes
                this.updateSelectedAssignment(item);
            })
            .catch(() => {
                // User chose to keep changes and cancel filter change
                this.selectedAssignment = { Id: this.selectedAssignment.Id, Label: this.selectedAssignment.Label };
            });
        } else { //no unsaved changes exist so just set the selectedAssignment and reload
            this.updateSelectedAssignment(item);
        }
    }

    public filterSearch(changes) {
        let originText = this.currentSearchText;
        if (changes.list1SelectedItem && this.selectedLocation.Id !== changes.list1SelectedItem.Id) {
            this.selectedLocation = changes.list1SelectedItem;
        }
        if (changes.list2SelectedItem && this.selectedLocationGroup.Id !== changes.list2SelectedItem.Id) {
            this.setSelectedLocationGroup(changes.list2SelectedItem);
        }
        if (changes.list3SelectedItem && this.selectedAssignment.Id !== changes.list3SelectedItem.Id) {
            if (this.unsavedChangesExist) {
                this.ipsMessage.confirm({
                    body: "UNSAVED_CHANGES_FILTER",
                    ok: "PROMPT_DISCARD_CHANGES",
                    cancel: "PROMPT_KEEP_CHANGES"
                })
                .then(() => {
                    //User chose to select different assignment category and discard changes
                    this.updateSelectedAssignment(changes.list3SelectedItem);
                })
                .catch(() => {
                    // User chose to keep changes and cancel filter change
                    this.selectedAssignment = { Id: this.selectedAssignment.Id, Label: this.selectedAssignment.Label };
                });
            } else { //no unsaved changes exist so just set the selectedAssignment and reload
                this.updateSelectedAssignment(changes.list3SelectedItem);
            }
        }

        //Remove existing collapsed states so we don't interfere with new searches
        this.scrollInfo.collapseStates = [];

        if (originText !== changes.query) {
            this.currentSearchText = changes.query;

            if (this.unsavedChangesExist) {
                this.ipsMessage.confirm({
                    body: "UNSAVED_CHANGES_FILTER",
                    ok: "PROMPT_DISCARD_CHANGES",
                    cancel: "PROMPT_KEEP_CHANGES"
                })
                .then(() => {
                    this.reloadData();
                })
                .catch(() => {
                    this.currentSearchText = originText;
                });
            } else { //no unsaved changes exist so just execute getListData
                this.reloadData();
            }
        }
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        } else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    }

    ngOnDestroy() {
        let collapseStates = this.getCollapseStates();

        localStorage.setItem(this.scrollInfoKey, JSON.stringify({
            search: this.searchInfo.searchText, positionY: this.scrollY, chunkIndex: this.locationGroups.chunkIndex, location: this.selectedLocation, locationFeature: this.selectedLocationGroup,
            assignment: this.selectedAssignment, collapseStates: collapseStates
        }));
    }

    public notifyCollapsibleChange(collapseState: any) {
        let index = this.locationGroups.resultList.findIndex(item => {
            return item.Id === collapseState.id;
        });

        this.locationGroups.resultList[index].isCollapsed = collapseState.state;
        this.scrollInfo.collapseStates = this.getCollapseStates();
    }

    private getCollapseStates(): CollapseState[] {
        //Remember collapse state of each location feature
       return this.locationGroups.resultList.map(item => {
            return { id: item.Id, state: item.isCollapsed };
        });
    }

}
