import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { IpsMessageService } from "imagine-ui-ng-messaging";

import { CampaignService } from "../../index";

interface ResultModel {
    item: any;
}

@Component({
  selector: "app-signplan-problems",
  templateUrl: "./signplan-problems.component.html",
  styleUrls: ["./signplan-problems.component.scss"]
})
export class SignplanProblemsComponent implements OnInit {

    public active = 1;
    public data: any = null;
    public campaignName: string = null;
    public campaignId: number = null;

    constructor(
          private campaignService: CampaignService,
          private translateService: TranslateService,
          private ipsMessage: IpsMessageService,
          private ipsModal: IpsModalService) { }

      ngOnInit() {
      }

      public selectCampaign() {

          this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { search: "campaign" } }).then(
              (response: ResultModel) => {

                  this.campaignId = response.item.Id;
                  this.campaignName = response.item.Label;

                  this.campaignService.getSignPlanProblems(this.campaignId).then(result => {
                      this.data = result;
                  }).catch(() => {
                      this.ipsMessage.error(this.translateService.instant("UNABLE_SIGNPLAN_DATA"));
                  });
                }, () => {
                  this.ipsMessage.error(this.translateService.instant("UNABLE_CAMPAIGN_DATA"));
              }); // Rejected;
      }
}
