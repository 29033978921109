<div class="col-md-8" ipsFocus="searchInput">
    <ips-list-search labelLoading="{{'ACCESSING_USER_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_USER_INFO' | translate }}"
                     [query]="query"
                     [listData]="user"
                     [busy]="busy"
                     (onListDataChange)="getListData($event.search, $event.filterOption)"
                     [filterOptions]="optionList"
                     [selectedFilterOption]="selectedOption"
                     [scroller]=true
                     [promise]="promise"
                     [autoFocus]=true>
        <ng-template #searchHeaderTemplate>
            <div search-title>
                <section class="pt-3 pb-2">
                    <h3>
                        <span class="ips-ls-header-label-imgn" translate>USERS</span><a uiSref="main.profileUser.edit" [uiParams]="{id: 0}" ngbTooltip="{{'CREATE_NEW_USER' | translate }}" placement="bottom" ipsPermission="main.profileUser.edit"><span class="fa fa-plus ips-ls-addIcon"></span></a>
                    </h3>
                </section>
            </div>
            <div class="row">
                <section class="col-12">
                    <div class="ips-ls-search-module-imgn pr-1">
                        <div class="ips-ls-searchBox input-group">
                            <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                            <input type="text"
                            class="form-control form-control-lg ips-ls-input"
                            [(ngModel)]="query" (ngModelChange)="onQueryChange(query)"
                            id="btn-search"
                            value=""
                            maxlength="20"
                            name="searchInput">
                            <div class="input-group-btn">
                                <div class="input-group ips-input-group">
                                    <div class="input-group-prepend">
                                        <i class="input-group-text rounded-0" [ngClass]="selectedOption.class"></i>
                                    </div>
                                    <select name="statusItem" [(ngModel)]="selectedOption" (ngModelChange)="getListData(query, selectedOption)" class="form-control form-control-lg">
                                        <option *ngFor="let item of optionList" [ngValue]="item">{{item.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf="infoTooltipText" class="d-flex align-items-center pl-2">
                                <div class="tooltip-container " [ngbTooltip]="infoTooltipText" placement="bottom-right">
                                    <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ng-template>
        <ng-template #listSearchItemTemplate let-result="result">
            <a uiSref="main.profileUser.view" [uiParams]="{id: result.UserName}" class="list-group-item no-border pr-5">
                <div>
                    <span class="long-text-wrap">{{result.FirstName}} {{result.LastName}} - {{getRole(result)}}</span>
                    <div class="status-icon-right" *ngIf="result.Status"  ngbTooltip="{{result.StatusIconTooltip | translate}}" placement="bottom-right">
                        <i class="img-user-status {{result.StatusIconClass}}"></i>
                    </div>
                    <ul class="searchResults">
                        <li class="searchResults"><label class="font-weight-bold" translate>USERNAME</label><span id="userName">: {{result.UserName}}</span></li>
                        <li class="searchResults"><label class="font-weight-bold" translate>EMAIL</label><span id="userEmail">: {{result.Email}}</span></li>
                    </ul>
                </div>
            </a>
        </ng-template>
    </ips-list-search>
</div>
