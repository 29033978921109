<div>
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0">{{title}}</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive">
            <div class="ips-ls-search-module-imgn">
                <div class="ips-ls-searchBox">
                    <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                    <input type="text"
                    class="form-control form-control-lg ips-ls-input"
                    [(ngModel)]="query" (ngModelChange)="onQueryChange($event)"
                    id="btn-search"
                    value=""
                    maxlength="20"
                    [ngModelOptions]="{ debounce: 350 }"
                    aria-label="Text input with dropdown button" name="searchInput">
                </div>
                <div class="row no-gutters mt-2">
                    <div class="col-12 col-md-auto ml-3 ips-ls-search-module-counts-imgn">
                        <span class="badge-pill badge-secondary font-weight-normal">{{ filteredList.length | number }}</span>
                        <span class="ml-1">{{status}} {{'FOUND_OF' | translate}}</span>
                    </div>
                    <div class="col-12 col-md-auto ml-3 ips-ls-search-module-counts-imgn">
                        <span class="badge-pill badge-secondary font-weight-normal">{{ statusList.length | number }}</span>
                        <span class="ml-1">{{ 'TOTAL' | translate }} {{status}}</span>
                    </div>
                </div>
            </div>
            <div class="ips-ls-searchResults ips-ls-long-text-wrap list-group">
                <div class="modal-scrollable list-search-infinite-container">               
                    <div *ngFor="let status of filteredList" class="card ips-shadow my-1 subgroup-card d-flex flex-row align-items-center mt-3">
                        <div class="p-2 pl-3 flex-grow-1 clicker" (click)="statusClicked(status)"> {{status.text}}</div>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>
