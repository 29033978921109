import { Component, OnInit } from "@angular/core";
import { BrandSettingService } from "../../../imagine-ui-ng-store-profile/index";

@Component({
  selector: "app-help-doc",
  templateUrl: "./help-doc.component.html",
  styleUrls: ["./help-doc.component.scss"]
})
export class HelpDocComponent implements OnInit {


    public promise: Promise<any>;
    public attachedDocuments: any[] = [];
    public loaded: boolean;

  constructor(
      private brandSettingService: BrandSettingService) { }

  ngOnInit() {
      this.loaded = false;
      this.promise = this.getAttachedDocuments().then((response) => {
          Object.assign(this.attachedDocuments, response);

          this.loaded = true;
      });
  }

  private getAttachedDocuments(): Promise<any> {
      return this.brandSettingService.getHelpDocuments().then((response) => {
          this.attachedDocuments = response;
      });
  }

  public attachmentUrl(document: any): string {
      return this.brandSettingService.getHelpDocumentMediaUrl(document.MasterMediaId, document.MediaType);
  }

}
