var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var StoreFrontCategoryService = /** @class */ (function (_super) {
    __extends(StoreFrontCategoryService, _super);
    function StoreFrontCategoryService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("Location", "Profile", "v1");
        _this.route = _this.urlApiBaseProductPlusVersion + "Location/StoreFront/Categories";
        return _this;
    }
    StoreFrontCategoryService.prototype.headByStoreFrontCategory = function () {
        return this.headByRoute(this.route);
    };
    // override ipsBaseWebService search method
    StoreFrontCategoryService.prototype.search = function (searchInfo, route) {
        return this.searchByRelation(searchInfo, "Location/StoreFront/Categories/Search");
    };
    StoreFrontCategoryService.prototype.get = function (id) {
        return this.getByRoute(this.urlApiBaseProductPlusVersion + "Location/StoreFront/Category/" + id);
    };
    StoreFrontCategoryService.prototype.post = function (values) {
        return _super.prototype.post.call(this, values, null, this.urlApiBaseProductPlusVersion + "Location/StoreFront/Category");
    };
    StoreFrontCategoryService.prototype.put = function (values) {
        return _super.prototype.put.call(this, values, null, this.urlApiBaseProductPlusVersion + "Location/StoreFront/Category");
    };
    StoreFrontCategoryService.prototype.delete = function (id) {
        return _super.prototype.deleteByRoute.call(this, this.urlApiBaseProductPlusVersion + "Location/StoreFront/Category/" + id);
    };
    StoreFrontCategoryService.prototype.getList = function () {
        var route = this.urlApiBaseProductPlusVersion + "Location/StoreFront/Categories";
        return this.getByRoute(route);
    };
    return StoreFrontCategoryService;
}(IpsBaseWebService));
export { StoreFrontCategoryService };
