import { Component, OnInit } from "@angular/core";
import { ProfileService } from "imagine-ui-ng-quick-start";
import { StateService } from "@uirouter/angular";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"]
})
export class ErrorComponent implements OnInit {

  constructor(private profileService: ProfileService, private stateService: StateService) {
    profileService.clearCache();
  }

  public tryAgain() {
    this.profileService.signOut(true).then(() => {
        this.stateService.go("public.login");
    }).catch(() => {
        this.stateService.go("public.login");
    });
  }

  ngOnInit() {
  }

}
