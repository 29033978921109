import { OnInit, OnDestroy, AfterViewChecked } from "@angular/core";
import { ActiveProfileService, ListSearchHelper } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { IpsModalService } from "imagine-ui-ng-modal";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchComponent, ComplexSearchBoxComponent } from "imagine-ui-ng-list-search";
import { Transition } from "@uirouter/angular";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { LocationGroupModel, LocationModelWithLabel, LocationGroupAssignmentModel, SimpleSearchLocationGroupModel, LocationGroupService, LocationService } from "../../index";
var LocationGroupSearchComponent = /** @class */ (function () {
    function LocationGroupSearchComponent(locationGroupService, locationService, listSearchHelper, ipsMessage, translateService, ipsModal, activeProfileService, transition) {
        var _this = this;
        this.locationGroupService = locationGroupService;
        this.locationService = locationService;
        this.ipsMessage = ipsMessage;
        this.translateService = translateService;
        this.ipsModal = ipsModal;
        this.activeProfileService = activeProfileService;
        this.transition = transition;
        this.pluralMapping = {
            "=1": "label",
            "other": "label"
        };
        this.unsavedChangesExist = false;
        this.allLabel = "ALL_UPPERCASE";
        this.pendingAssignmentLabel = "PENDING_ASSIGNMENT";
        this.busy = true;
        this.loaded = false;
        this.editing = false;
        this.includeObsolete = false;
        this.showCampaignLocationFeature = false;
        this.disableAssignment = false;
        this.toolTipCreateNewLocationGroup = "";
        this.maxDropDownCount = 2;
        this.totalLocationCount = 0;
        this.totalLocationGroupCount = 0;
        // Lists for drop downs
        this.availableLocations = [{ Id: 0, Label: this.allLabel, Name: this.allLabel }];
        this.availableLocationGroups = [
            { Id: 0, Label: this.allLabel, Name: this.allLabel }
        ];
        this.availableAssignments = [
            { Id: 0, Label: this.allLabel },
            { Id: 1, Label: this.pendingAssignmentLabel }
        ];
        this.saveData = [];
        this.currentSearchText = "";
        this.currentFilterOptions = { locationGroup: null, assignment: null };
        this.userQuery = "";
        this.reloading = false;
        this.selectedLocation = { Id: 0 };
        this.translated = false;
        this.locationGroupFilter = function (locationGroup) {
            if (_this.selectedLocationGroup.Id === 0) {
                return true;
            }
            return _this.selectedLocationGroup.Id === locationGroup.Id;
        };
        this.assignmentFilter = function (locationGroup) {
            if (_this.selectedAssignment.Id === 0) {
                return true;
            }
            return locationGroup.PendingAssignmentCount > 0;
        };
        this.dataService = locationGroupService;
        this.locationGroups = listSearchHelper.getListSearchHelper(this.dataService);
        this.location = listSearchHelper.getListSearchHelper(locationService);
        this.savePromptFn = this.savePrompt.bind(this);
    }
    LocationGroupSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        this.loadScrollInfo();
        this.getLocationList();
        this.getLocationGroupList();
        this.setInitalFilters();
        this.initailDocumentHeight = document.documentElement.scrollHeight;
    };
    LocationGroupSearchComponent.prototype.loadScrollInfo = function () {
        this.scrollInfoKey = "loc_feature_search_scroll_" + this.activeProfileService.businessIdentity;
        this.scrollInfo = JSON.parse(localStorage.getItem(this.scrollInfoKey));
        if (this.scrollInfo) {
            this.selectedLocation = this.scrollInfo.location;
            this.selectedAssignment = this.scrollInfo.assignment;
            this.currentSearchText = this.scrollInfo.search;
            this.setSelectedLocationGroup(this.scrollInfo.locationFeature);
        }
        else {
            this.scrollInfo = { collapseStates: [] };
            this.selectedLocation = this.availableLocations[0];
            this.selectedAssignment = this.availableAssignments[0];
            this.setSelectedLocationGroup(this.availableLocationGroups[0]);
        }
    };
    LocationGroupSearchComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(["LOCATION_GROUPS", "LOCATION_GROUP", "ALL_UPPERCASE", "PENDING_ASSIGNMENT", "CREATE_NEW_LOCATION_GROUP"]).subscribe(function (res) {
            _this.locGroupLabel = res["LOCATION_GROUP"];
            _this.locGroupLabelPlural = res["LOCATION_GROUPS"];
            _this.allLabel = res["ALL_UPPERCASE"];
            _this.pendingAssignmentLabel = res["PENDING_ASSIGNMENT"];
            _this.toolTipCreateNewLocationGroup = res["CREATE_NEW_LOCATION_GROUP"];
            _this.availableLocations[0].Label = _this.allLabel;
            _this.availableLocationGroups[0].Label = _this.allLabel;
            _this.availableAssignments[0].Label = _this.allLabel;
            _this.availableAssignments[1].Label = _this.pendingAssignmentLabel;
            _this.pluralMapping = {
                "=1": _this.locGroupLabel,
                "other": _this.locGroupLabelPlural
            };
            _this.translated = true;
        });
    };
    LocationGroupSearchComponent.prototype.disableAssignmentSelect = function () {
        this.disableAssignment = true;
        this.selectedAssignment = { Id: 0, Label: this.allLabel };
    };
    LocationGroupSearchComponent.prototype.setSelectedLocationGroup = function (item) {
        this.selectedLocationGroup = item;
        this.currentFilterOptions.locationGroup = item.Id === 0 ? null : this.locationGroupFilter;
        if (item.Id !== 0) {
            this.disableAssignmentSelect();
        }
        else {
            this.disableAssignment = false;
        }
    };
    LocationGroupSearchComponent.prototype.updateSelectedLocationGroup = function (item) {
        this.setSelectedLocationGroup(item);
        this.searchBox.selectedItem2 = item;
        this.reloadData();
    };
    LocationGroupSearchComponent.prototype.updateSelectedAssignment = function (item) {
        this.selectedAssignment = item;
        //Remove existing collapsed states so we don't interfere with new searches
        this.scrollInfo.collapseStates = [];
        this.reloadData();
    };
    LocationGroupSearchComponent.prototype.updateSelectedLocation = function (item) {
        this.selectedLocation = item;
        this.searchBox.selectedItem1 = item;
        this.reloadData();
    };
    LocationGroupSearchComponent.prototype.removeAllItemFromList = function (list) {
        if (list.length > 0 && list[0].Id === 0) {
            list.shift();
        }
    };
    LocationGroupSearchComponent.prototype.showLocationSelectionModal = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "location" } })
            .then(function (response) {
            _this.updateSelectedLocation(response.item);
            // remove the first item, which is All
            _this.removeAllItemFromList(_this.location.resultList);
            //Remove existing collapsed states so we don't interfere with new searches
            _this.scrollInfo.collapseStates = [];
        }, 
        // Rejected
        function () {
            // remove the first item, which is All
            _this.removeAllItemFromList(_this.location.resultList);
        });
    };
    LocationGroupSearchComponent.prototype.showLocationGroupSelectionModal = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "locationgroup" } })
            .then(function (response) {
            _this.updateSelectedLocationGroup(response.item);
            // remove the first item, which is All
            _this.removeAllItemFromList(_this.locationGroups.resultList);
            //Remove existing collapsed states so we don't interfere with new searches
            _this.scrollInfo.collapseStates = [];
        }, 
        // Rejected
        function () {
            // remove the first item, which is All
            _this.removeAllItemFromList(_this.locationGroups.resultList);
        });
    };
    // Set rule class info, and original values.
    LocationGroupSearchComponent.prototype.setLocGroupValues = function () {
        for (var i = 0; i < this.locationGroups.resultList.length; i++) {
            var locationGroup = this.locationGroups.resultList[i];
            if (locationGroup.init) {
                continue;
            }
            this.locationGroupService.setLocFeatureInfo(locationGroup, this.translateService);
            if (locationGroup.Rule === "Single") {
                for (var j = 0; j < locationGroup.SubGroups.length; j++) {
                    if (locationGroup.SubGroups[j].LocationCount === 1) {
                        locationGroup.originalSelectedSubGroup = locationGroup.SubGroups[j].Id;
                        locationGroup.selectedSubGroup = locationGroup.SubGroups[j].Id;
                        break;
                    }
                }
                if (!locationGroup.originalSelectedSubGroup) {
                    // If no subGroup is assigned yet, then we have to set by hand to -1, which isn"t a valid subgroup id
                    locationGroup.originalSelectedSubGroup = -1;
                }
            }
            else {
                for (var k = 0; k < locationGroup.SubGroups.length; k++) {
                    var subGroup = locationGroup.SubGroups[k];
                    subGroup.originalLocationCount = subGroup.LocationCount;
                }
            }
            //sort locationSubGroup
            this.locationGroupService.sortSubGroup(locationGroup.SubGroups, locationGroup.OptionType);
            // singnal that we did setup on original values and rule info
            locationGroup.init = true;
        }
    };
    LocationGroupSearchComponent.prototype.getListDataInternal = function (search, filterOption, forceReload) {
        var _this = this;
        if (this.busy) {
            return;
        }
        if (!search) {
            search = "";
        }
        var additionalQueryParams = [];
        this.editing = this.selectedLocation && this.selectedLocation.Id > 0;
        if (this.editing) {
            additionalQueryParams.push({ param: "locationId", paramValue: this.selectedLocation.Id.toString() });
        }
        if (this.selectedLocationGroup && this.selectedLocationGroup.Id > 0) {
            additionalQueryParams.push({ param: "locationGroupId", paramValue: this.selectedLocationGroup.Id.toString() });
        }
        if (this.includeObsolete) {
            additionalQueryParams.push({ param: "ShowObsoleteItems", paramValue: this.includeObsolete.toString() });
        }
        if (this.showCampaignLocationFeature) {
            additionalQueryParams.push({ param: "ShowCampaignLocationFeatures", paramValue: this.showCampaignLocationFeature.toString() });
        }
        //Override record count and pull all chucks at once
        var recordCount = 15;
        if (this.scrollInfo && this.scrollInfo.chunkIndex > 0) {
            recordCount = 15 * this.scrollInfo.chunkIndex;
        }
        this.searchInfo = { searchText: search, showUnassignedItems: this.selectedAssignment.Id === 1, additionalQueryParams: additionalQueryParams, recordCount: recordCount };
        this.promise = this.locationGroups.searchHelper(this.searchInfo, forceReload).then(function () {
            // Catch condition when single array result is return that is a null object. This state should reset the filters and try again - spoke to Eric about this 9/21/2018
            if (_this.locationGroups.resultList.length === 1 && !_this.locationGroups.resultList[0] && (_this.searchInfo.searchText !== "" || _this.searchInfo.additionalQueryParams.length > 0)) {
                _this.locationGroups.resultList = [];
                //Reset to default values
                _this.selectedLocation = _this.availableLocations[0];
                _this.selectedAssignment = _this.availableAssignments[0];
                _this.setSelectedLocationGroup(_this.availableLocationGroups[0]);
                //Call to get new results
                return _this.getListDataInternal("", { locationGroup: null, assignment: null }, false);
            }
            if (filterOption) {
                _this.locationGroups.resultList = filterOption.locationGroup ? _this.locationGroups.resultList.filter(filterOption.locationGroup) : _this.locationGroups.resultList;
            }
            _this.setLocGroupValues();
            _this.reloading = false;
            //Set existing loc features collapsible state
            var count = _this.locationGroups.resultList.length;
            var _loop_1 = function (i) {
                var item = _this.locationGroups.resultList[i];
                item.isCollapsed = true;
                //Check for previous saved loc feature collapse state
                var stateResult = _this.scrollInfo.collapseStates.find(function (stateItem) { return stateItem.id === item.Id; });
                if (stateResult) {
                    item.isCollapsed = stateResult.state;
                    return "continue";
                }
                if ((i === 0 && count === 1)) {
                    item.isCollapsed = false;
                    return "continue";
                }
                item.SubGroups.forEach(function (subItem) {
                    if (!!_this.currentSearchText && subItem.Name.toLowerCase().indexOf(_this.currentSearchText.toLowerCase()) > -1) {
                        item.isCollapsed = false;
                    }
                });
            };
            for (var i = 0; i < count; i++) {
                _loop_1(i);
            }
        });
    };
    LocationGroupSearchComponent.prototype.ngAfterViewChecked = function () {
        //Used for scrolling back to previous location
        if (this.scrollInfo && this.scrollInfo.chunkIndex > 0 && this.initailDocumentHeight < document.documentElement.scrollHeight && document.documentElement.scrollHeight >= this.scrollInfo.positionY) {
            //Set chunk index to allow back to default recordCount row pulls
            this.locationGroups.chunkIndex = this.scrollInfo.chunkIndex;
            this.scrollInfo.chunkIndex = 0;
            window.scrollTo(0, this.scrollInfo.positionY);
        }
    };
    LocationGroupSearchComponent.prototype.getInitLoc = function (locId) {
        var _this = this;
        if (locId) {
            return this.locationService.get(locId).then(function (item) {
                item.Label = item.LocationIdentifier + " - " + item.Name + " - " + item.Addresses[0].City + ", " + item.Addresses[0].StateProvince;
                _this.updateSelectedLocation(item);
            });
        }
        else {
            return Promise.resolve();
        }
    };
    LocationGroupSearchComponent.prototype.getInitLocGrp = function (locGrpId) {
        var _this = this;
        if (locGrpId) {
            return this.dataService.get(locGrpId).then(function (initLg) {
                initLg.Label = initLg.Name;
                _this.updateSelectedLocationGroup(initLg);
            });
        }
        else {
            return Promise.resolve();
        }
    };
    //Checks if either LocationId or LocationGroupId are passed in on the URL.
    //If so, gets and sets their respective object in this controller (see this.selected...).
    LocationGroupSearchComponent.prototype.setInitalFilters = function () {
        var _this = this;
        var initalLocationGroupId = this.transition.params().locationGroupId;
        var initalLocationId = this.transition.params().locationId;
        Promise.all([this.getInitLoc(initalLocationId), this.getInitLocGrp(initalLocationGroupId)]).then(function () {
            _this.busy = false;
            _this.reloadData();
        });
    };
    LocationGroupSearchComponent.prototype.locationModalButtonVisible = function () {
        return this.totalLocationCount > this.maxDropDownCount;
    };
    LocationGroupSearchComponent.prototype.locationGroupModalButtonVisible = function () {
        return this.totalLocationGroupCount > this.maxDropDownCount;
    };
    LocationGroupSearchComponent.prototype.getListData = function (search, filterOption, forceReload, scrolling) {
        var _this = this;
        if (!scrolling && this.unsavedChangesExist) {
            this.ipsMessage.confirm({
                body: "UNSAVED_CHANGES_FILTER",
                ok: "PROMPT_DISCARD_CHANGES",
                cancel: "PROMPT_KEEP_CHANGES"
            })
                .then(function () {
                //User chose to filter by search and discard changes
                _this.reloadData();
            })
                .catch(function () {
                //User chose to keep changes and cancel filter change
                //vm.userQuery = CurrentSearchText;
            });
        }
        else { //no unsaved changes exist so just execute getListData
            this.getListDataInternal(search, filterOption, forceReload);
        }
    };
    LocationGroupSearchComponent.prototype.getObsoleteData = function (showObsolete) {
        this.includeObsolete = !this.includeObsolete;
        this.reloadData();
    };
    LocationGroupSearchComponent.prototype.showCampaignLocationFeatures = function (campaignLocationFeatures) {
        this.showCampaignLocationFeature = !this.showCampaignLocationFeature;
        this.reloadData();
    };
    LocationGroupSearchComponent.prototype.savePrompt = function (data) {
        var _this = this;
        this.saveData = data;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: function () { return _this.save(); }, progressMessage: "SAVING" })
            .then(function (result) {
            return result;
        });
    };
    LocationGroupSearchComponent.prototype.reloadData = function () {
        this.reloading = true;
        this.unsavedChangesExist = false;
        return this.getListDataInternal(this.currentSearchText, this.currentFilterOptions, true);
    };
    LocationGroupSearchComponent.prototype.save = function () {
        var _this = this;
        // call the api
        return this.dataService.saveList(this.selectedLocation.Id.toString(), this.saveData).then(function (result) {
            return _this.reloadData();
        });
    };
    LocationGroupSearchComponent.prototype.notifyChangesMade = function (hasUnsavedChanges) {
        this.unsavedChangesExist = hasUnsavedChanges;
    };
    LocationGroupSearchComponent.prototype.getLocationList = function () {
        var _this = this;
        this.locationService.head()
            .then(function (response) {
            _this.totalLocationCount = response;
            // If count is less than max allowed, then get the location list
            if (!_this.locationModalButtonVisible()) {
                // Update the service chunkCount so we get back all the locations.
                _this.locationService.chunkCount = _this.maxDropDownCount;
                _this.location.searchHelper({ searchText: "" })
                    .then(function () {
                    _this.location.resultList.forEach(function (item) {
                        item.Label = item.LocationIdentifier + " - " + item.Name + " - " + item.Addresses[0].City + ", " + item.Addresses[0].StateProvince;
                    });
                    _this.availableLocations = _this.availableLocations.concat(_this.location.resultList);
                    if (_this.availableLocations.length === 2 && _this.availableLocations[0].Id === 0) {
                        _this.removeAllItemFromList(_this.availableLocations);
                        _this.selectedLocation = _this.availableLocations[0];
                    }
                });
            }
        });
    };
    LocationGroupSearchComponent.prototype.getLocationGroupList = function () {
        var _this = this;
        this.locationGroupService.head()
            .then(function (response) {
            _this.totalLocationGroupCount = response;
            // If count is less than max allowed, then get the locationGroup list
            if (!_this.locationGroupModalButtonVisible()) {
                var searchParams = { searchText: "", businessIdentity: _this.activeProfileService.businessIdentity, chunkIndex: 0, recordCount: 1000 };
                _this.locationGroupService.simpleSearch(searchParams)
                    .then(function (lgResponse) {
                    lgResponse.ResultList.forEach(function (item) {
                        item.Label = item.Name;
                    });
                    _this.availableLocationGroups = _this.availableLocationGroups.concat(lgResponse.ResultList);
                    if (_this.availableLocationGroups.length === 2 && _this.availableLocationGroups[0].Id === 0) {
                        _this.removeAllItemFromList(_this.availableLocationGroups);
                        _this.setSelectedLocationGroup(_this.availableLocationGroups[0]);
                    }
                });
            }
        });
    };
    LocationGroupSearchComponent.prototype.selectLocation = function (item) {
        var _this = this;
        if (this.unsavedChangesExist) {
            this.ipsMessage.confirm({
                body: "UNSAVED_CHANGES_NAV",
                ok: "PROMPT_LEAVE_PAGE",
                cancel: "PROMPT_STAY_PAGE"
            })
                .then(function () {
                //User chose to select new location and discard changes
                if (_this.locationModalButtonVisible()) {
                    _this.showLocationSelectionModal();
                }
                else {
                    _this.updateSelectedLocation(item);
                }
            })
                .catch(function () {
                //User chose to keep changes and cancel filter change.  Do nothing because
                // we haven"t changed this.selectedLocation yet.
            });
        }
        else { //no unsaved changes exist so just set the selectedLocation
            if (this.locationModalButtonVisible()) {
                this.showLocationSelectionModal();
            }
            else {
                this.updateSelectedLocation(item);
            }
        }
    };
    LocationGroupSearchComponent.prototype.selectLocationGroup = function (item) {
        var _this = this;
        if (this.unsavedChangesExist) {
            var bodyMessage = "UNSAVED_CHANGES_FILTER";
            var okMessage = "PROMPT_DISCARD_CHANGES";
            var cancelMessage = "PROMPT_KEEP_CHANGES";
            if (this.locationGroupModalButtonVisible()) {
                bodyMessage = "UNSAVED_CHANGES_NAV";
                okMessage = "PROMPT_LEAVE_PAGE";
                cancelMessage = "PROMPT_STAY_PAGE";
            }
            this.ipsMessage.confirm({
                body: bodyMessage,
                ok: okMessage,
                cancel: cancelMessage
            })
                .then(function () {
                //User chose to select new location group and discard changes
                if (_this.locationGroupModalButtonVisible()) {
                    _this.showLocationGroupSelectionModal();
                }
                else {
                    _this.updateSelectedLocationGroup(item);
                }
            })
                .catch(function () {
                // User chose to keep changes and cancel filter change
                _this.selectedLocationGroup = { Id: _this.selectedLocationGroup.Id, Label: _this.selectedLocationGroup.Label };
            });
        }
        else { //no unsaved changes exist so just set the selectedLocationGroup
            if (this.locationGroupModalButtonVisible()) {
                this.showLocationGroupSelectionModal();
            }
            else {
                this.updateSelectedLocationGroup(item);
            }
        }
    };
    LocationGroupSearchComponent.prototype.selectAssignment = function (item) {
        var _this = this;
        if (this.unsavedChangesExist) {
            this.ipsMessage.confirm({
                body: "UNSAVED_CHANGES_FILTER",
                ok: "PROMPT_DISCARD_CHANGES",
                cancel: "PROMPT_KEEP_CHANGES"
            })
                .then(function () {
                //User chose to select different assignment category and discard changes
                _this.updateSelectedAssignment(item);
            })
                .catch(function () {
                // User chose to keep changes and cancel filter change
                _this.selectedAssignment = { Id: _this.selectedAssignment.Id, Label: _this.selectedAssignment.Label };
            });
        }
        else { //no unsaved changes exist so just set the selectedAssignment and reload
            this.updateSelectedAssignment(item);
        }
    };
    LocationGroupSearchComponent.prototype.filterSearch = function (changes) {
        var _this = this;
        var originText = this.currentSearchText;
        if (changes.list1SelectedItem && this.selectedLocation.Id !== changes.list1SelectedItem.Id) {
            this.selectedLocation = changes.list1SelectedItem;
        }
        if (changes.list2SelectedItem && this.selectedLocationGroup.Id !== changes.list2SelectedItem.Id) {
            this.setSelectedLocationGroup(changes.list2SelectedItem);
        }
        if (changes.list3SelectedItem && this.selectedAssignment.Id !== changes.list3SelectedItem.Id) {
            if (this.unsavedChangesExist) {
                this.ipsMessage.confirm({
                    body: "UNSAVED_CHANGES_FILTER",
                    ok: "PROMPT_DISCARD_CHANGES",
                    cancel: "PROMPT_KEEP_CHANGES"
                })
                    .then(function () {
                    //User chose to select different assignment category and discard changes
                    _this.updateSelectedAssignment(changes.list3SelectedItem);
                })
                    .catch(function () {
                    // User chose to keep changes and cancel filter change
                    _this.selectedAssignment = { Id: _this.selectedAssignment.Id, Label: _this.selectedAssignment.Label };
                });
            }
            else { //no unsaved changes exist so just set the selectedAssignment and reload
                this.updateSelectedAssignment(changes.list3SelectedItem);
            }
        }
        //Remove existing collapsed states so we don't interfere with new searches
        this.scrollInfo.collapseStates = [];
        if (originText !== changes.query) {
            this.currentSearchText = changes.query;
            if (this.unsavedChangesExist) {
                this.ipsMessage.confirm({
                    body: "UNSAVED_CHANGES_FILTER",
                    ok: "PROMPT_DISCARD_CHANGES",
                    cancel: "PROMPT_KEEP_CHANGES"
                })
                    .then(function () {
                    _this.reloadData();
                })
                    .catch(function () {
                    _this.currentSearchText = originText;
                });
            }
            else { //no unsaved changes exist so just execute getListData
                this.reloadData();
            }
        }
    };
    LocationGroupSearchComponent.prototype.onWindowScroll = function ($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        }
        else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    };
    LocationGroupSearchComponent.prototype.ngOnDestroy = function () {
        var collapseStates = this.getCollapseStates();
        localStorage.setItem(this.scrollInfoKey, JSON.stringify({
            search: this.searchInfo.searchText, positionY: this.scrollY, chunkIndex: this.locationGroups.chunkIndex, location: this.selectedLocation, locationFeature: this.selectedLocationGroup,
            assignment: this.selectedAssignment, collapseStates: collapseStates
        }));
    };
    LocationGroupSearchComponent.prototype.notifyCollapsibleChange = function (collapseState) {
        var index = this.locationGroups.resultList.findIndex(function (item) {
            return item.Id === collapseState.id;
        });
        this.locationGroups.resultList[index].isCollapsed = collapseState.state;
        this.scrollInfo.collapseStates = this.getCollapseStates();
    };
    LocationGroupSearchComponent.prototype.getCollapseStates = function () {
        //Remember collapse state of each location feature
        return this.locationGroups.resultList.map(function (item) {
            return { id: item.Id, state: item.isCollapsed };
        });
    };
    return LocationGroupSearchComponent;
}());
export { LocationGroupSearchComponent };
