<div class="row" *ngIf="customDataResult.length > 0" [formGroup]="cdfForm">
    <div class="form-group col-12 mb-0">
        <label translate>{{containerTitle}}</label>
        <div class="card">
            <div class="card-body pb-0">
                <div class="form-row">
                    <div *ngFor="let customData of customDataResult" [ngClass]="customData.CustomClass">
                        <app-custom-data-field-control [customDataResult]="customData"
                                                       [fieldValues]="fieldValues"
                                                       (formDirtyChanged)="updateFormDirty()"
                                                       (valueChanged)="valueChangedHandler($event)"
                                                       (loaded)="controlLoadedHandler($event)"></app-custom-data-field-control>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
