import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { ShipmentStatus } from "../type/ShipmentStatus";

@Injectable()
export class ShipmentService extends IpsBaseWebService {

    constructor(http: HttpClient, public activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Shipment", "Shop", "v1");
    }

    public shipmentStatusList(): Promise<ShipmentStatus[]> {
        const route = `${this.urlApiBasePlusController}Status`;
        return this.getByRoute(route);
    }

}
