import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchHelper } from "imagine-ui-ng-core";
import { String as IpsString } from "typescript-string-operations";

import { MarketDisplayInfoLocationSubGroup } from "../model/MarketDisplayInfoLocationSubGroup";

import { LocationGroupService, LocationGroupModel, FixtureGroupService, LocationFixtureService, FixtureByLocationSumService, FixtureGroupWithFixturesModel, FixtureModel, FixtureLocationSumModel, SimpleFixtureGroupModel } from "../../imagine-ui-ng-store-profile/index";
import { MarketType } from "../type/MarketType";
import { MarketSearchModalReturnModel } from "../model/MarketSearchModalReturnModel";
import { MarketModel } from "../model/MarketModel";
import { ProfileQuestionModel } from "../model/ProfileQuestionModel";

interface LocFeatureSearchUIModel extends LocationGroupModel {
    Disabled: boolean;
    Index: number;
    warningMessage: string;
    warningHeader: string;
}

interface FixtureGroupUIModel extends FixtureGroupWithFixturesModel {
    Disabled: boolean;
    Index: number;
    warningMessage: string;
    warningHeader: string;
}

interface FixtureUIModel extends FixtureLocationSumModel {
    Disabled: boolean;
    Index: number;
    warningMessage: string;
    warningHeader: string;
}

@Component({
    selector: "app-linked-feature-search-modal",
    templateUrl: "./linked-feature-search-modal.component.html",
    styleUrls: ["./linked-feature-search-modal.component.scss"]
})
export class LinkedFeatureSearchModalComponent implements OnInit {
    private featureUsedInAudience_key = "OPTION_ALREADY_USED_IN_AUDIENCE";
    private featureUsedInQuestion_key = "ALREADY_USED_IN_QUESTION";
    public requestedMarketType: MarketType; // Used to control which market type is being searched.
    public searchHelper: ListSearchHelper;
    public promise: Promise<any>;
    public query = "";
    public featuresInAudience = [] as MarketModel[];
    public featuresInQuestion = [] as ProfileQuestionModel[];
    public fixtureGroupsInQuestion = [] as ProfileQuestionModel[];
    public fixturesInQuestion = [] as ProfileQuestionModel[];
    public busy = false;
    public title = "SELECT";
    public searchLabel: string;
    public searchLabelPlural: string;
    public labelLoading: string;
    public labelLoadingError: string;
    public selectedMarket = [];
    public selectedCount = 0;
    public isSingleSelect = false;

    pluralMapping: { [k: string]: string } = {
        "=1": "Fixture",
        "other": "Fixtures"
    };

    fixturePluralMapping: { [k: string]: string } = {
        "=0": "Fixture Selected",
        "=1": "Fixture Selected",
        "other": "Fixtures Selected"
    };

    locationGroupPluralMapping: { [k: string]: string } = {
        "=0": "Location Group Selected",
        "=1": "Location Group Selected",
        "other": "Location Groups Selected"
    };

    fixtureGroupPluralMapping: { [k: string]: string } = {
        "=0": "Fixture Group Selected",
        "=1": "Fixture Group Selected",
        "other": "Fixture Groups Selected"
    };

    constructor(private modalInstance: NgbActiveModal, private locationGroupService: LocationGroupService, listSearchHelper: ListSearchHelper,
        private translateService: TranslateService, private fixtureGroupService: FixtureGroupService, private locationFixtureService: LocationFixtureService, private fixtureByLocationSumService: FixtureByLocationSumService) {
        this.searchHelper = listSearchHelper.getListSearchHelper(locationGroupService);
    }

    ngOnInit() {

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
        this.selectedMarket = [];

        switch (this.requestedMarketType) {
            case "LocationGroup":
                this.title = "SELECT_LOCATION_FEATURE";
                this.searchLabel = "LOCATION_GROUP";
                this.searchLabelPlural = "LOCATION_GROUPS";
                this.labelLoading = "ACCESSING_LOCATION_GROUP_INFO";
                this.labelLoadingError = "ERROR_ACCESSING_LOCATION_GROUP_INFO";
                this.locationGroupService.head().then((total) => {
                    this.searchHelper.TotalRecords = total;
                });
                break;
            case "FixtureGroup":
                this.title = "SELECT_FIXTURE_GROUP";
                this.searchLabel = "FIXTURE_GROUP";
                this.searchLabelPlural = "FIXTURE_GROUPS";
                this.labelLoading = "ACCESSING_FIXTURE_GROUP_INFO";
                this.labelLoadingError = "ERROR_ACCESSING_FIXTURE_GROUP_INFO";
                this.fixtureGroupService.head().then((total) => {
                    this.searchHelper.TotalRecords = total;
                });
                break;
            case "Fixture":
                this.title = "SELECT_FIXTURE";
                this.searchLabel = "FIXTURE";
                this.searchLabelPlural = "FIXTURES";
                this.labelLoading = "ACCESSING_FIXTURE_INFO";
                this.labelLoadingError = "ERROR_ACCESSING_FIXTURE_INFO";
                this.fixtureByLocationSumService.head().then((total) => {
                    this.searchHelper.TotalRecords = total;
                });
                break;
        }
        this.getListData("");
    }

    private translateText() {
        this.translateService.get(["OPTION_ALREADY_USED_IN_AUDIENCE", "ALREADY_USED_IN_QUESTION"]).subscribe(
            (res: [string]) => {
                this.featureUsedInAudience_key = res["OPTION_ALREADY_USED_IN_AUDIENCE"];
                this.featureUsedInQuestion_key = res["ALREADY_USED_IN_QUESTION"];
            });

        this.pluralMapping = {
            "=1": this.translateService.instant("FIXTURE"),
            "other": this.translateService.instant("FIXTURES")
        };
    }

    public getListData(search: string) {
        this.query = search;
        let route = "LocationGroup/SimpleSearchWithSub";
        if (this.requestedMarketType === "FixtureGroup") {
            route = "FixtureType/SimpleSearchWithFixtures";
            this.promise = this.searchHelper.searchHelper({ searchText: this.query, recordCount: 20 }, false, route).then(() => {
                    this.searchHelper.resultList.forEach((result: FixtureGroupUIModel) => {
                            this.checkFixtureGroupAvailability(result);
                            this.disableFixtureAvailability(result);
                        });
                    this.setCurrentSelectionCount();
                });
        } else if (this.requestedMarketType === "Fixture") {
            route = "Fixture/SearchLocationFixtureSum";
            this.promise = this.searchHelper.searchHelper({ searchText: this.query, recordCount: 20 }, false, route).then(() => {
                    this.searchHelper.resultList.forEach((result: FixtureUIModel) => {
                            this.checkFixtureAvailability(result);
                            this.disableFixtureAvailability(result);
                        });
                    this.setCurrentSelectionCount();
                });
        } else {
            this.promise = this.searchHelper.searchHelper({ searchText: this.query, recordCount: 20 }, false, route).then(() => {
                    this.searchHelper.resultList.forEach((result: LocFeatureSearchUIModel) => {
                            this.locationGroupService.sortSubGroup(result.SubGroups, result.OptionType);
                            this.disableFixtureAvailability(result);
                            this.checkAvailability(result);
                            this.locationGroupService.setLocFeatureInfo(result, this.translateService);
                        });
                });
        }
    }

    public setCurrentSelectionCount() {
        this.selectedCount = 0;
        for (let i = 0; i < this.featuresInAudience.length; i++) {
            let market = this.featuresInAudience[i];
            if (market.TargetMarketType === this.requestedMarketType) {
                ++this.selectedCount;
            }
        }

        for (let i = 0; i < this.selectedMarket.length; i++) {
            let market = this.selectedMarket[i];
            if (market.Disabled) {
                this.selectedCount--;
            }else {
                this.selectedCount++;
            }
        }
    }

    private checkAvailability(feature: LocFeatureSearchUIModel) {
        for (let i = 0; i < this.featuresInAudience.length; i++) {
            let market = this.featuresInAudience[i];
            if (market.TargetMarketType === "LocationSubGroup" && (<MarketDisplayInfoLocationSubGroup>market.DisplayInfo).LocationGroupId === feature.Id) {
                feature.Disabled = true;
                feature.Index = i;
                feature.warningMessage = IpsString.Format(this.featureUsedInAudience_key, (<MarketDisplayInfoLocationSubGroup>market.DisplayInfo).LocationSubGroupName);
                feature.warningHeader = "FEATURE_UNAVAILABLE";
                break;
            }
        }
        for (let i = 0; i < this.featuresInQuestion.length; i++) {
            let question = this.featuresInQuestion[i];
            if (question.Id === feature.Id) {
                feature.Disabled = true;
                feature.warningMessage = IpsString.Format(this.featureUsedInQuestion_key, question.Name);
                feature.warningHeader = "FEATURE_UNAVAILABLE";
                break;
            }
        }
    }

    private checkFixtureGroupAvailability(fixtureGroup: FixtureGroupUIModel) {
        for (let i = 0; i < this.fixtureGroupsInQuestion.length; i++) {
            let question = this.fixtureGroupsInQuestion[i];
            if (question.Id === fixtureGroup.Id) {
                fixtureGroup.Disabled = true;
                fixtureGroup.warningMessage = IpsString.Format(this.featureUsedInQuestion_key, question.Name);
                fixtureGroup.warningHeader = "FIXTURE_GROUP_UNAVAILABLE";
                break;
            }
        }
    }

    private checkFixtureAvailability(fixture: FixtureUIModel) {
        if (this.fixturesInQuestion.length > 0) {
            for (let i = 0; i < this.fixturesInQuestion.length; i++) {
                let question = this.fixturesInQuestion[i];
                if (question.Id === fixture.Id) {
                    fixture.Disabled = true;
                    fixture.warningMessage = IpsString.Format(this.featureUsedInQuestion_key, question.Name);
                    fixture.warningHeader = "FIXTURE_UNAVAILABLE";
                    break;
                }
            }
        }
    }

    public disableFixtureAvailability(fixture: any) {
        for (let i = 0; i < this.featuresInAudience.length; i++) {
            let question = this.featuresInAudience[i];
            if (question.TargetMarketId === fixture.Id) {
                fixture.Disabled = true;
                fixture.Index = i;
                break;
            }
        }
    }

    public marketSelected(result, isChecked) {
        if (isChecked) {
            ++this.selectedCount;
            this.selectedMarket.push(result);
        }else {
            --this.selectedCount;
            if (result.Disabled) {
                this.selectedMarket.push(result);
            } else {
                this.selectedMarket = this.selectedMarket.filter(x => x.Id !== result.Id);
            }
        }
    }
    public updateSingleMarket(result) {
        this.selectedMarket.push(result);
        this.updateMarket();
    }

    public updateMarket() {
        if (this.selectedMarket.length > 0) {
            let item = this.selectedMarket;
            this.selectedMarket = [];
            return this.modalInstance.close(new MarketSearchModalReturnModel(this.requestedMarketType, item));
        }else {
            this.modalInstance.close();
        }
    }

    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    public disableFeature(locationGroup: LocationGroupModel) {
        let disable = false;
        if (locationGroup.Id === 1) {
            disable = true;
        }

        return disable;
    }

}
