import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { SurveyService } from "../../service/survey.service";
import { SurveySectionTakeUIModel } from "../../model/SurveySectionTakeUIModel";
import { SurveyQuestionModel } from "../../model/SurveyQuestionModel";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { FormGroup } from "@angular/forms";
import { MySurveyQuestionAndMessageModel } from "../../index";

@Component({
    selector: "app-my-survey-take-section",
    templateUrl: "./my-survey-take-section.component.html",
    styleUrls: ["./my-survey-take-section.component.scss"]
})
export class MySurveyTakeSectionComponent implements OnInit {
    @Input() section: SurveySectionTakeUIModel;
    @Input() startingQuestionNumber: number;
    @Input() locationId: number;
    @Input() parentForm: FormGroup;
    @Input() isCollapsed: boolean;
    @Input() expandSurveyOptions: boolean;
    @Output() formDirtyChanged = new EventEmitter<any>();

    public questions: SurveyQuestionModel[];
    public promise: Promise<void>;

    private answers: MySurveyQuestionAndMessageModel;
    public loaded = false;

    constructor(private surveyService: SurveyService,
        private ipsMessage: IpsMessageService) { }

    ngOnInit() {
        if (!this.isCollapsed) {
            this.getQuestions();
        }
    }

    public activeCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
        if (!this.isCollapsed && !this.questions) {
            this.getQuestions();
        }
    }

    private getQuestions(): void {
        this.loaded = false;
        this.promise = this.surveyService.getSurveyQuestionList(this.section.Id, this.locationId).then(questions => {
            this.questions = questions;
        });

        const answerPromise = this.surveyService.getMySectionQuestionAnswers(this.section.Id, this.locationId).then(answers => {
            if (answers) {
                this.answers = answers;
            }
        }, (error) => {
            let errorMessage = error.Message || error;
            this.ipsMessage.error("Error loading answers: " + errorMessage);
        });

        Promise.all([this.promise, answerPromise]).then(() => {

            for (let i = 0; i < this.questions.length; i++) {
                let question = this.questions[i];
                const answer = this.answers.MySurveyQuestions.find(a => a.QuestionId === question.Id);
                if (answer) {
                    question.MySurveyAnswer = answer;
                }

                const message = this.answers.MySurveyMessages.find(a => a.SurveyQuestionId === question.Id);
                if (message) {
                    question.MySurveyMessage = message;
                }
            }
            this.loaded = true;

        }, (error) => {
            //this.ipsMessage.error(error);
        });
    }

    public isStatusComplete(): boolean {
        return this.section.MySurveyStatus === "Completed";
    }

    public HasPendingChanges(): boolean {
        return this.parentForm.dirty;
    }

    public updateStatus() {
        const completed = !this.questions.some(q => q.MySurveyAnswer === undefined);
        this.section.MySurveyStatus = completed ? "Completed" : "AwaitingAssignment";
    }

    public checkFormDirty() {
        this.formDirtyChanged.emit();
    }
}
