<div>
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0" translate>SELECT_QTY_HANDLING</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive">
            <ips-list-search headerLabel=""
                             searchLabel="{{'LOCATION_GROUP' | translate }}"
                             searchLabelPlural="{{'LOCATION_GROUPS' | translate }}"
                             labelLoading="{{'ACCESSING_LOCATION_GROUP_INFO' | translate }}"
                             labelLoadingError="{{'ERROR_ACCESSING_LOCATION_GROUP_INFO' | translate }}"
                             [listData]="locationGroups"
                             [query]="locationGroupQuery"
                             [busy]="busy"
                             [hideHeader]="true"
                             [promise]="locationGroupPromise"
                             [autoFocus]="focusLocationGroup"
                             [isModal]="true"
                             [hideList]="true"
                             [translatedTexts]="translatedTexts"
                             (onListDataChange)="getLocationGroupData($event.search)">
                <section class="col-12" search-result-List>
                    <div class="row">
                        <div class="col-12">
                            <div class="ips-ls-searchResults ips-ls-long-text-wrap list-group clicker">
                                <div class="list-group-item change-hover-color" (click)="marketedLocationsClicked()">
                                    <div class="marketed-locations-label" translate>MARKETED_LOCATIONS</div>
                                    <div class="marketed-locations-count">{{marketedLocations}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="ips-ls-searchResults ips-ls-long-text-wrap list-group">
                                <div infiniteScroll (scrolled)="getMoreListData()" [infiniteScrollDisabled]="busy" [infiniteScrollDistance]="0" [scrollWindow]="!isModal" [ngClass]="{'list-search-infinite-container': isModal}">
                                    <div list-search-item class="ips-ls-search-item clicker" *ngFor="let result of locationGroups.resultList" (click)="itemClicked(result)">
                                        <span class="list-group-item no-border change-hover-color">
                                            <span class="mr-1">{{result.Name}}</span>
                                            <span class="location-group-rule-icon" [ngClass]="result.ruleClass" ngbTooltip="{{result.ruleTooltip}}" placement="bottom"></span>
                                            <ul class="searchResults" *ngFor="let subResult of result.SubGroups">
                                                <li class="searchResults">{{subResult.Name}}</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ips-list-search>
        </div>
    </form>
</div>
