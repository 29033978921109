<div class="col-12">
    <div class="row">
        <section class="col-12 title">
            <h3>
                <span translate>VIEW_FIXTURE_GROUP</span>
                <a class="ml-2" uiSref="main.storeProfileFixtureGroup.edit" [uiParams]="{id: myFixtureGroup.Id}" ipsPermission="main.storeProfileFixtureGroup.edit" ngbTooltip="{{'EDIT' | translate}} {{myFixtureGroup.Name}}" placement="bottom"><span class="fa fa fa-pencil editIcon"></span></a>
            </h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_FIXTURE_GROUP_INFO' | translate}}'}">
        <div class="row" [hidden]="!loaded">
            <section class="col-12 col-md-6">
                <div class="form-group">
                    <div class="card">
                        <div class="card-header view-info-header" translate>
                            {{myFixtureGroup.Name}}
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <div class="view-info-label" translate>{{myFixtureGroup.Holders.length > 1 ? 'CONTAINS_HOLDERS' : 'CONTAINS_HOLDER'}}</div>
                                <div class="view-info-format-imgn" *ngFor="let holder of myFixtureGroup.Holders">{{holder.Name}}</div>
                            </li>
                            <li class="list-group-item">
                                <div class="view-info-label" translate>NOTES_FIXTURE_GROUP</div>
                                <div class="view-info-format-imgn">{{myFixtureGroup.Notes}}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
        <hr>
        <div class="row" [hidden]="!loaded">
            <section class="col-12 col-md-6 pb-0" *ngIf="translated">
                <ips-list-search headerLabel="{{'RELATED_FIXTURES' | translate }}"                                 
                                 [searchLabel]="searchLabel"
                                 [searchLabelPlural]="searchLabelPlural"
                                 labelLoading="{{'ACCESSING_FIXTURE_INFO' | translate }}"
                                 labelLoadingError="{{'ERROR_ACCESSING_FIXTURE_INFO' | translate }}"
                                 [listData]="qrFixture"
                                 [busy]="busy"
                                 (onListDataChange)="getFixtureList($event.search, myFixtureGroup.Id)"
                                 [section]="'main.storeProfileFixture'"
                                 [scroller]="false"
                                 [quickRef]="true"
                                 [itemClickPermission]="'main.storeProfileFixtureGroup.view'"
                                 [promise]="qrFixturePromise"></ips-list-search>
            </section>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_FIXTURE_GROUP_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
