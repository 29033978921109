
<div class="row">
    <div class="col" *ngIf="brandBulletin">
        <div class="extraSpace visible-xs-block visible-sm-block"></div>
        <div class="extraSpace"></div>
        <div id="noticeContainer" class="notice-container">
            <div class="notice-label-set"><i class="fa fa-bell notice-icon" aria-hidden="true"></i>{{'NOTICE_ALLCAPS' | translate}}:</div>
            <div class="notice-text">{{brandBulletin.Value}}</div>
        </div>
    </div>
</div>

<h2 class="title mx-3" translate>MY_PRODUCTS</h2>
<div class="col p-md-0" ipsFocus="searchInput">
    <div class="ips-flex-loading-box mb-4" *ngIf="!screenLoaded">
        <div class="content">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
            <span class="d-block" translate>ACCESSING_MY_PRODUCTS</span>
        </div>
    </div>
    <ips-list-search searchLabel="{{'ITEM' | translate }}"
                     searchLabelPlural="{{'MY_PRODUCTS' | translate }}"
                     labelLoading="{{'ACCESSING_MY_PRODUCTS' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_MY_PRODUCTS_INFO' | translate }}"
                     [listData]="itemHelper"
                     (onListDataChange)="getListData($event.search)"
                     [section]="'main.myproducts'"
                     [scroller]=true
                     [quickRef]=false
                     [itemClickPermission]="'main.myproducts'"
                     [promise]="promise"
                     [autoFocus]=true
                     [query]="searchText"
                     searchItemClass="ips-product-search-result"
                     *ngIf="screenLoaded">

        <ng-template #searchHeaderTemplate>
            <div *ngIf="!locationsAvailable" class="card px-3 ips-shadow">
                <h2 class="feedback" translate>NO_LOCATIONS_AVAILABLE</h2>
            </div>
            <div class="ips-flex-loading-box mb-4" *ngIf="!searchFilterDataLoaded">
                <div class="content">
                    <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
                    <span class="d-block" translate>ACCESSING_MY_PRODUCTS</span>
                </div>
            </div>

            <!-- Category filter enabled -->
            <div class="ips-ls-search-module-imgn" *ngIf="locationsAvailable && showCategoryFilter && searchFilterDataLoaded">
                <div class="form-row">
                    <div class="col-12 col-md-6">
                        <label class="labels" for="businessCustomer" translate>LOCATION</label>
                        <button class="button-no-style ml-1" ngbTooltip="{{'TOOLTIP_LOCATION_MY_PRODUCTS' | translate}}" placement="bottom">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </button>
                        <button id="businessCustomer" (click)="chooseLocation()"
                                class="btn btn-lg btn-block btn-default text-center long-text-wrap">
                            {{ selectedLocation.Label }}
                        </button>
                    </div>
                    <div class="col-12 col-md-6">
                        <div *ngIf="categoriesAvailable">
                            <label class="labels" for="businessCustomer" translate>Category</label>
                            <button class="button-no-style ml-1" ngbTooltip="{{'TOOLTIP_LOCATION_MY_PRODUCTS' | translate}}" placement="bottom">
                                <i class="fa fa-info-circle fa-lg"></i>
                            </button>
                            <button id="btnCategory" (click)="chooseCategory()"
                                    class="btn btn-lg btn-block btn-default text-center long-text-wrap">
                                {{ (selectedCategory && selectedCategory.Name) || 'ALL' }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-row form-group">
                    <div class="col-12 col-md-6">
                        <div *ngIf="spacesAvailable">
                            <label class="labels" for="businessCustomer" translate>SPACE</label>
                            <button class="button-no-style ml-1" ngbTooltip="{{'TOOLTIP_SPACE_MY_PRODUCTS' | translate}}" placement="bottom">
                                <i class="fa fa-info-circle fa-lg"></i>
                            </button>
                            <button id="businessCustomer" (click)="chooseSpace()" [disabled]="!enableSpace"
                                    class="btn btn-lg btn-block btn-default text-center long-text-wrap">
                                {{ selectedSpaceLabel || 'ALL' }}
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div *ngIf="!hideCampaignFilters && campaignsAvailable">
                            <label class="labels" for="businessCustomer" translate>CAMPAIGN</label>
                            <button class="button-no-style ml-1" ngbTooltip="{{'TOOLTIP_CAMPAIGN_MY_PRODUCTS' | translate}}" placement="bottom">
                                <i class="fa fa-info-circle fa-lg"></i>
                            </button>
                            <button id="businessCustomer" (click)="chooseCampaign()" [disabled]="!enableCampaign"
                                    class="btn btn-lg btn-block btn-default text-center long-text-wrap">
                                {{ selectedCampaignLabel || 'ALL' }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-row form-group">
                    <div class="col-12  mb-3 mb-lg-0" [ngClass]="{ 'col-md-8': !hideCampaignFilters }">
                        <div class="input-group">
                            <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                            <input type="text" class="form-control form-control-lg ips-ls-input long-text-wrap"
                                   placeholder="{{ 'MY_PRODUCTS_SEARCH_PLACEHOLDER' | translate}}"
                                   [(ngModel)]="searchText"
                                   (ngModelChange)="onQueryChange($event)" />
                        </div>
                    </div>
                    <div *ngIf="!hideCampaignFilters" class="col-12 col-md-4">
                        <div class="btn-group btn-group-lg w-100">
                            <button class="btn btn-lg btn-default w-50 px-0 long-text-wrap" (click)="setSearchType('Location')" [ngClass]="{ 'active': searchingByLocation }" translate>ALL_ITEMS</button>
                            <button class="btn btn-lg btn-default w-50 px-0 long-text-wrap" (click)="setSearchType('Fixture')" [ngClass]="{ 'active': searchingByFixture }" [disabled]="!enableProductsByFixture" translate>ITEMS_BY_FIXTURE</button>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters mt-1">
                    <div class="col-12 col-md-auto ml-3">
                        <span class="badge-pill badge-secondary font-weight-normal">{{ matchingRecordCount | number }}</span>
                        <span class="ml-1">{{ matchingRecordCount | i18nPlural: pluralMapping }} {{ 'FOUND' | translate }}</span>
                    </div>
                </div>
            </div>

            <!-- Category filter disabled -->
            <div class="ips-ls-search-module-imgn" *ngIf="locationsAvailable && !showCategoryFilter">
                <div class="form-row form-group">
                    <div class="col-12 col-md-4">
                        <label class="labels" for="businessCustomer" translate>LOCATION</label>
                        <button class="button-no-style ml-1" ngbTooltip="{{'TOOLTIP_LOCATION_MY_PRODUCTS' | translate}}" placement="bottom">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </button>
                        <button id="businessCustomer" (click)="chooseLocation()"
                                class="btn btn-lg btn-block btn-default text-center long-text-wrap">
                            {{ selectedLocation.Label }}
                        </button>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="labels" for="businessCustomer" translate>SPACE</label>
                        <button class="button-no-style ml-1" ngbTooltip="{{'TOOLTIP_SPACE_MY_PRODUCTS' | translate}}" placement="bottom">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </button>
                        <button id="businessCustomer" (click)="chooseSpace()" [disabled]="!enableSpace"
                                class="btn btn-lg btn-block btn-default text-center long-text-wrap">
                            {{ selectedSpaceLabel || 'ALL' }}
                        </button>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="labels" for="businessCustomer" translate>CAMPAIGN</label>
                        <button class="button-no-style ml-1" ngbTooltip="{{'TOOLTIP_CAMPAIGN_MY_PRODUCTS' | translate}}" placement="bottom">
                            <i class="fa fa-info-circle fa-lg"></i>
                        </button>
                        <button id="businessCustomer" (click)="chooseCampaign()" [disabled]="!enableCampaign"
                                class="btn btn-lg btn-block btn-default text-center long-text-wrap">
                            {{ selectedCampaignLabel || 'ALL' }}
                        </button>
                    </div>
                </div>
                <div class="form-row form-group">
                    <div class="col-12 col-md-8 mb-3 mb-lg-0">
                        <div class="input-group">
                            <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                            <input type="text" class="form-control form-control-lg ips-ls-input long-text-wrap"
                                   placeholder="{{ 'MY_PRODUCTS_SEARCH_PLACEHOLDER' | translate}}"
                                   [(ngModel)]="searchText"
                                   (ngModelChange)="onQueryChange($event)" />
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="btn-group btn-group-lg w-100">
                            <button class="btn btn-lg btn-default w-50 px-0 long-text-wrap" (click)="setSearchType('Location')" [ngClass]="{ 'active': searchingByLocation }" translate>ALL_ITEMS</button>
                            <button class="btn btn-lg btn-default w-50 px-0 long-text-wrap" (click)="setSearchType('Fixture')" [ngClass]="{ 'active': searchingByFixture }" [disabled]="!enableProductsByFixture" translate>ITEMS_BY_FIXTURE</button>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters mt-1">
                    <div class="col-12 col-md-auto ml-3">
                        <span class="badge-pill badge-secondary font-weight-normal">{{ matchingRecordCount | number }}</span>
                        <span class="ml-1">{{ matchingRecordCount | i18nPlural: pluralMapping }} {{ 'FOUND' | translate }}</span>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #listSearchItemTemplate let-result="result" *ngIf="searchingByLocation">
            <app-item-display-card [ItemData]="result" [LocationId]="selectedLocation.Id" DisplayMode="shop" (punchOut)="saveSearchInfo()"
                                   [Highlight]="searchText" [ShowOrderReasonControl]="showOrderReason" [OrderReasons]="orderReasons"></app-item-display-card>
        </ng-template>

        <ng-template #listSearchItemTemplate let-result="result" *ngIf="searchingByFixture">
            <div class="bg-ips-light p-3 mb-4 ips-shadow fixture-item-group">
                <div class="form-row fixture-header mb-3">
                    <div class="col-auto">
                        <ips-image-view imgThumbUrl="{{ getImageUrl(result.Media) }}" imgFullUrl="{{ getImageUrl(result.Media) }}"></ips-image-view>
                    </div>
                    <div class="col">
                        <h2 class="d-inline text-white mt-0 font-size-heading">
                            {{ 'FIXTURE' | translate }}: {{ result.FixtureName }}
                            <br />
                            {{ 'FIXTURE_QTY' | translate }}: {{ result.FixtureQuantity }} <a class="text-white" ipsPermission="main.storeProfileFixture.search" uiSref="main.storeProfileFixture.search" [uiParams]="{ fixtureId: result.FixtureId, locationId: selectedLocation.Id }"><span class="fa fa-pencil"></span></a>
                        </h2>
                    </div>
                </div>
                <app-item-display-card *ngFor="let item of result.Items" [ItemData]="item" [LocationId]="selectedLocation.Id"
                                       DisplayMode="shop" [ShowOrderReasonControl]="showOrderReason" [OrderReasons]="orderReasons" [Highlight]="searchText"></app-item-display-card>
            </div>
        </ng-template>

    </ips-list-search>
    <div *ngIf="showNoResults" class="card px-3 ips-shadow">
        <h2 translate>NO_MATCHING_PRODUCTS</h2>
    </div>
</div>
