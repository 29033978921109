<div class="form-group" [formGroup]="group">
    <label [ngClass]="{'required': isFieldRequired}">{{name}}</label>
    <div class="input-group ips-input-group-icon">
        <input type="text"
               name="date"
               [formControl]="control"
               class="form-control form-control-lg"
               placeholder="{{placeholder}}"
               bsDatepicker
               [bsConfig]="dateOptions"
               #d="bsDatepicker"
               triggers="">
        <span class="input-group-append">
            <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
        </span>
    </div>
    <ips-show-errors [control]="group.get('Date')" [errorMessages]="getErrorMessages()"></ips-show-errors>
</div>
