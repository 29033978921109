import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
  } from "@angular/common/http";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { Observable, throwError } from "rxjs";
import { catchError} from "rxjs/operators";
import { IpsMessageService } from "imagine-ui-ng-messaging";

@Injectable()
export class ProfileInterceptor implements HttpInterceptor {

  constructor(public activeProfileService: ActiveProfileService, private ipsMessage: IpsMessageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.activeProfileService.businessIdentity) {

      let headers = request.headers.set("BusinessIdentity", this.activeProfileService.businessIdentity);
      if (this.activeProfileService.profile.Locale) {
          headers = headers.set("Accept-Language", this.activeProfileService.profile.Locale);
      }
      request = request.clone({
          headers: headers
        }
      );
    }

    // TODO: see if we want to handle 401 response here. See https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8
      return next.handle(request).pipe(
          catchError((error: any) => {
              if (error instanceof HttpErrorResponse) {
                  if (error.status === 403) {
                     this.ipsMessage.error("You do not have permissions to perform this action.");
                   }
              }
              return throwError(error);
          })
      );
  }
}
