var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { BaseWebServiceWithDefaultImage } from "imagine-ui-ng-quick-start";
import { environment } from "../../../../environments/environment";
var FixtureService = /** @class */ (function (_super) {
    __extends(FixtureService, _super);
    function FixtureService(http, activeProfileService, authService) {
        var _this = _super.call(this, http, environment, activeProfileService, authService) || this;
        _this.init("Fixture", "Profile", "v1");
        _this.businessId = activeProfileService.businessIdentity;
        _this.token = authService.getIdToken();
        return _this;
    }
    FixtureService.prototype.getWithUnassignedHolders = function (id) {
        return this.getByRoute("" + this.urlApiBasePlusController + id + "?includeUnassignedHolders=true");
    };
    FixtureService.prototype.getLayoutDetails = function (fixtureId) {
        return this.getByRoute("" + this.urlApiBasePlusController + fixtureId + "/Layout");
    };
    FixtureService.prototype.putLayoutDetails = function (model, files) {
        var url = this.urlApiBaseProductPlusVersion + "FixtureLayout";
        return this.put(model, files, url);
    };
    FixtureService.prototype.getAttachedDocuments = function (fixtureId) {
        var url = "" + this.urlApiBasePlusController + fixtureId + "/Documents";
        return this.getByRoute(url);
    };
    FixtureService.prototype.getAttachmentMediaUrl = function (mediaId) {
        var url = this.urlApiBase + "Media/v1/MasterMedia/" + this.businessId + "/Document/" + mediaId + "/Original/Jpg?BusinessIdentity=" + this.businessId + "&idToken=" + this.token;
        return url;
    };
    FixtureService.prototype.deleteAttachment = function (id) {
        var url = this.urlApiBasePlusController + "Document/" + id;
        return this.deleteByRoute(url);
    };
    FixtureService.prototype.addAttachment = function (model, file) {
        var url = this.urlApiBasePlusController + "Document";
        return this.post(model, file, url);
    };
    FixtureService.prototype.deleteCheck = function (id) {
        var route = "" + this.urlApiBasePlusController + id + "/DeleteCheck";
        return this.getByRoute(route);
    };
    return FixtureService;
}(BaseWebServiceWithDefaultImage));
export { FixtureService };
