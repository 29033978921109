import { NgForm } from "@angular/forms";
var ɵ0 = function (form) {
    return form;
};
// This directive should be placed in any nested child directives html in order to provide the parent form. This allows the parent
// form to be aware of the child component controls. Taken from https://medium.com/@john_oerter/angular-nested-forms-and-validation-844ea05d4063
var ProvideParentFormDirective = /** @class */ (function () {
    function ProvideParentFormDirective() {
    }
    return ProvideParentFormDirective;
}());
export { ProvideParentFormDirective };
export { ɵ0 };
