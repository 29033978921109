<div class="col-12 col-lg-8">
    <div class="row">
        <section class="col-12 col-sm-12 col-md-12 col-lg-12">
            <h3 class="title">
                <span>{{'VIEW_PROMOTION' | translate}}</span>
                <a uiSref="main.campaign.promotion.edit" [uiParams]="{campaignId:campaignId, id: promotionId}" ipsPermission="main.campaign.edit" ngbTooltip="{{'EDIT' | translate}} {{selectedPromotion.Name}}" placement="bottom"><span class="fa fa-pencil editIcon ml-2"></span></a>
            </h3>
        </section>
    </div>
    <!--Campaign Info-->
    <div class="card" *ngIf="!campaignLoaded && !campaignLoadError">
        <div class="card-body"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> <span translate>LOADING_CAMPAIGN_INFORMATION</span></div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_CAMPAIGN_INFO' | translate }}" [hasError]="campaignLoadError"></ips-error-handler>
    <div class="card ips-card-gray mb-4" *ngIf="campaignLoaded">
        <div class="card-body px-4 pb-1">
            <div class="row">
                <div class="col-12">
                    <label class="font-weight-bold">{{'CAMPAIGN' | translate}}:</label> {{campaign.Name}}
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="font-weight-bold">{{'IN_STORE_DATE' | translate}}:</label> {{campaign.InStoreDate | date:'mediumDate'}}
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="font-weight-bold">{{'START_END_DATE' | translate}}:</label> {{campaign.StartDate | date:'mediumDate'}} - {{campaign.EndDate | date:'mediumDate'}}
                </div>
            </div>
        </div>
    </div>
    <!--Promotion Info-->
    <div class="card" *ngIf="!promotionLoaded && !promotionLoadError">
        <div class="card-body"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i><span translate>LOADING_PROMOTION_INFORMATION</span></div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_PROMOTION_INFO' | translate }}" [hasError]="promotionLoadError"></ips-error-handler>

    <div class="card" *ngIf="promotionLoaded">
        <div class="card-header view-info-header">{{selectedPromotion.Name}}</div>
        <div class="card-body">
            <div class="row row-margin">
                <div class="col-12"><label class="campaign-view-label">{{'START_END_DATE' | translate}}:</label>{{selectedPromotion.StartDate | date : "mediumDate" : "+0000"}} - {{selectedPromotion.EndDate ? (selectedPromotion.EndDate | date : "mediumDate" : "+0000") : ( 'NA' | translate)}}</div>
            </div>
            <div class="row row-margin">
                <div class="col-12">
                    <div class="campaign-view-label">
                        <label>{{'NOTES' | translate}}:</label>
                    </div>
                    <div class="campaign-view-notes">
                        <span>{{selectedPromotion.Notes}}</span>
                    </div>
                </div>
            </div>

            <!--Message Panels>-->
            <div *ngFor="let message of selectedPromotion.PromotionMessages">
                <!--Main Message Panel>-->
                <div class="row">
                    <div class="col-12 message-panels mb-3">
                        <div class="card">
                            <div class="card-header">
                                <a (click)="message.collapse = !message.collapse" class="collapse-link">
                                    <div class="campaign-view-msg-icon"></div>
                                    <div class="campaign-view-msg-name long-text-wrap">{{message.Name}}</div>
                                    <i class="fa fa-chevron-down campaign-view-collapse-icon" [ngClass]="[message.collapse ? '' : 'fa-flip-vertical']"></i>
                                </a>
                            </div>
                            <div *ngIf="!message.collapse">
                                <div class="card-body">
                                    <div class="pattern-position-summary ng-hide" *ngIf="message.isPattern">
                                        <!--Pattern Positions-->
                                        <div class="with-icon">
                                            <span class="icon pattern-icon"></span>
                                            <p><span translate>PATTERN_POSITIONS</span>:</p>
                                            <ul class="nav nav-pills pattern-positions no-interaction">
                                                <li class="nav-item pattern-position mr-2 mb-3" *ngFor="let val of message.totalPositionArray"><a href="#" class="nav-link" [ngClass]="{ active: message.ordinals.indexOf(val) > -1 }">{{val}}</a></li>
                                            </ul>
                                        </div>
                                        <hr class="no-margin-top" />
                                    </div>
                                    <div class="ml-2">
                                        <!--Message markets-->
                                        <app-market-view [markets]="message.Markets"></app-market-view>
                                    </div>
                                </div>
                            </div>
                            <!--Placements-->
                            <ul class="list-group" *ngFor="let placement of message.MessagePlacements">
                                <li class="list-group-item">
                                    <a (click)="toggleMessagePlacementCollapse(placement,true)" class="collapse-link">
                                        <div>
                                            <div class="placement-panel-title-icon"></div>
                                            <h4 class="placement-panel-title-left long-text-wrap">{{placement.DisplayName}}</h4>
                                            <h4 class="placement-panel-title-right">
                                                <span class="campaign-view-placement-qty" ngbTooltip="{{'TOTAL_PIECE_QUANTITY' | translate}}" placement="bottom">{{placement.FinalQuantity | number}}</span>
                                                <span [ngClass]="['text-right fa fa-chevron-down addIcon', placement.collapse ? '' : 'fa-flip-vertical']"></span>
                                            </h4>
                                        </div>
                                    </a>
                                </li>
                                <li class="list-group-item" *ngIf="placement.busy && !placement.collapse">
                                    <div class="placement-detail"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> <span translate> LOADING_FIXTURE_INFORMATION</span></div>
                                </li>
                                <li class="list-group-item placement-detail-list-group-item" *ngIf="!(placement.collapse || placement.busy)">
                                    <!--Pattern Positions detail-->
                                    <div class="placement-pattern-summary ng-hide" *ngIf="message.isPattern">
                                        <div class="detail">
                                            <div class="row header mb-2">
                                                <div class="col-12">
                                                    <span class="ng-hide" *ngIf="placement.PatternTypeEnum === 'AcrossFixtures'" translate>PATTERN_ACROSS_FIXTURES<span class="icon campaign-across-fixtures"></span></span>
                                                    <span class="ng-hide" *ngIf="placement.PatternTypeEnum === 'WithinFixture'" translate>PATTERN_WITHIN_FIXTURES<span class="icon campaign-within-fixtures"></span></span>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-9">
                                                    <span translate>POSITION_THRESHOLD</span><span class="fa fa-info-circle infoIcon" ngbTooltip="{{'TOOLTIP_POSITION_THRESHOLD' | translate}}" placement="bottom"></span>
                                                </div>
                                                <div class="col-3 text-right">
                                                    {{placement.PositionThreshold}}
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-9">
                                                    <span translate>POSITION_LIMIT</span><span class="fa fa-info-circle infoIcon" ngbTooltip="{{'TOOLTIP_POSITION_LIMIT' | translate}}" placement="bottom"></span>
                                                </div>
                                                <div class="col-3 text-right">
                                                    {{placement.PositionLimit}}
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-9">
                                                    <span translate>POSITIONS_AVAILABLE</span><span class="fa fa-info-circle infoIcon" ngbTooltip="{{'TOOLTIP_POSITIONS_AVAILABLE' | translate}}" placement="bottom"></span>
                                                </div>
                                                <div class="col-3 text-right">
                                                    {{placement.PositionsAvailable}}
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="no-margin" />
                                    </div>
                                    <!--Placement Details-->
                                    <div *ngFor="let calculation of placement.QuantitiesByFixtureHolderSize">
                                        <div class="view-detail-format-imgn mt-2">
                                            <div class="row placement-detail">
                                                <div class="col-12" *ngFor="let fixture of calculation.Fixtures; let first = first; let last = last" [ngClass]="{ 'show-link-line': calculation.Fixtures.length > 1 }">
                                                    <div class="row">
                                                        <div class="col-12 campaign-view-placement-fixture-padding " [ngClass]="{ 'holder-last': last, 'holder-first': first, 'holder-middle': !first && !last }"><span class="holder-header">{{fixture.FixtureName}} {{fixture.FixtureModel? " - " + fixture.FixtureModel : ""}} {{fixture.HolderVersionInfoName? " - " + fixture.HolderVersionInfoName : ""}}</span></div>
                                                    </div>

                                                </div>
                                                <div class="col-12">
                                                    <div class="row pb-2">
                                                        <div class="col-1 tiny-width"></div>
                                                        <div class="col-5" translate>
                                                            SIZE
                                                        </div>
                                                        <div class="col-6 campaign-view-detail-value text-right">
                                                            {{calculation.HolderWidth | number: '1.0-7'}} x {{calculation.HolderHeight | number: '1.0-7'}}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="row pb-2">
                                                        <div class="col-1 tiny-width"></div>
                                                        <div class="col-5" translate>
                                                            {{message.isPattern ? 'POSITION_QTY' : 'HOLDER_QTY'}}
                                                        </div>
                                                        <div class="col-6 campaign-view-detail-value text-right">
                                                            {{calculation.HolderCount | number}}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="row pb-2">
                                                        <div class="col-1 tiny-width"></div>
                                                        <div class="col-5" translate>
                                                            QTY_INCREASE
                                                        </div>
                                                        <div class="col-6 campaign-view-detail-value text-right">
                                                            {{calculation.QuantityIncreaseLabel}}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="row pb-2">
                                                        <div class="col-1 tiny-width"></div>
                                                        <div class="col-6" translate>
                                                            FULFILLMENT_QTY
                                                        </div>
                                                        <div class="col-5 campaign-view-detail-value text-right">
                                                            {{calculation.FulfillmentQuantityLabel}}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="row pb-2">
                                                        <div class="col-1 tiny-width"></div>
                                                        <div class="col-5" translate>
                                                            FINAL_QTY
                                                        </div>
                                                        <div class="col-6 campaign-view-detail-value text-right">
                                                            {{calculation.FinalQuantity | number}}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="row pb-2">
                                                        <div class="col-1 tiny-width"></div>
                                                        <div class="col-5" translate>
                                                            LOCATION_BALANCE
                                                        </div>
                                                        <div class="col-6 campaign-view-detail-value text-right">
                                                            {{calculation.LocationCount | number}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="no-fixture placement-detail" *ngIf="placement.NoFixture" [ngClass]="{ pattern: message.isPattern }" translate>PLACEMENT_NO_FIXTURE</div>
                                </li>
                            </ul>
                            <!--Elements-->
                            <ul class="list-group" *ngFor="let placementElement of message.PlacementElements">
                                <li class="list-group-item">
                                    <a (click)="toggleMessagePlacementElementCollapse(placementElement)" class="collapse-link">
                                        <div>
                                            <div class="placement-element-panel-title-icon"></div>
                                            <h4 class="placement-panel-title-left long-text-wrap">{{placementElement.DisplayName}}</h4>
                                            <h4 class="placement-panel-title-right">
                                                <span class="campaign-view-placement-qty" ngbTooltip="{{'TOTAL_PIECE_QUANTITY' | translate}}" placement="bottom">{{placementElement.FinalQuantity | number}}</span>
                                                <span [ngClass]="['text-right fa fa-chevron-down addIcon', placementElement.collapse ? '' : 'fa-flip-vertical']"></span>
                                            </h4>
                                        </div>
                                    </a>
                                </li>
                                <li class="list-group-item" *ngIf="placementElement.busy && !placementElement.collapse">
                                    <div class="placement-detail"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> <span translate> LOADING_ELEMENT_INFORMATION</span></div>
                                </li>
                                <li class="list-group-item placement-detail-list-group-item" *ngIf="!(placementElement.collapse || placementElement.busy)">
                                    <!--Element Information-->
                                    <div class="summary" class="LocationSubGroup" [ngClass]="{'view-detail-format-imgn' : placementElement.QuantityHandling === 'LocationGroup', 'view-info-element-imgn' : placementElement.QuantityHandling === 'MarketedLocations'}">
                                        <div class="row placement-detail">
                                            <div class="market-panel-locGrp-icon" *ngIf="!(placementElement.QuantityHandling === 'MarketedLocations')"></div>
                                            <div class="col-9 col-md-8 element-market-name">
                                                <span class="location-group-label">{{placementElement.LocationGroupLabel}}</span>
                                                <span class="location-group-warning" *ngIf="placementElement.PendingAssignmentCount > 0"><i ngbTooltip="{{placementElement.PendingAssignmentTooltip}}" placement="bottom" class="fa fa-exclamation-triangle"></i></span>
                                            </div>
                                            <div class="col-3 col-md-4 element-view-detail-value text-right">
                                                {{placementElement.LocationCount | number}}
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="placementElement.QuantityHandling === 'MarketedLocations'">
                                            <div class="col-7 element-market-name" translate>
                                                QTY
                                            </div>
                                            <div class="col-5 element-view-detail-value text-right">
                                                {{placementElement.PlacementElementDetails[0].Quantity | number}}
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-8 element-market-name" translate>
                                                QTY_SUBTOTAL
                                            </div>
                                            <div class="col-4 element-view-detail-value text-right">
                                                {{placementElement.QuantityTotal | number}}
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-8 element-market-name">
                                                <span class="" translate>FULFILLMENT_QTY</span>
                                            </div>
                                            <div class="col-4 element-view-detail-value text-right">
                                                {{placementElement.FulfillmentQuantityLabel}}
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-7 element-market-name">
                                                <span class="" translate>FINAL_QTY</span>
                                            </div>
                                            <div class="col-5 element-view-detail-value text-right">
                                                {{placementElement.FinalQuantity | number}}
                                            </div>
                                        </div>
                                    </div>

                                    <!--Location subgroup detail-->
                                    <div class="view-info-element-imgn" *ngIf="placementElement.LocationSubGroup">
                                        <div class="element-view-padding">
                                            <hr class="no-margin" />
                                        </div>
                                        <div class="placement-detail">
                                            <div class="view-detail-format-imgn row mt-3" *ngFor="let detail of placementElement.PlacementElementDetails">

                                                <!--LocationSubGroup name and count-->
                                                <div class="col-9 campaign-view-element-detail-padding">
                                                    <span class="holder-header">{{placementElement.LocationGroupName}} {{detail.LocationSubGroupName? " - " + detail.LocationSubGroupName : ""}}</span>
                                                </div>
                                                <div class="col-3 element-view-detail-value text-right">
                                                    {{detail.LocationCount | number}}
                                                </div>

                                                <!--Element qty and final qty rows-->
                                                <div class="col-12">
                                                    <div class="row mt-1">
                                                        <div class="col-1 tiny-width"></div>
                                                        <div class="col-7">
                                                            <span class="holder-header element-view-detail-qty" translate>QTY</span>
                                                        </div>
                                                        <div class="col-4 element-view-detail-value text-right">
                                                            {{detail.Quantity | number}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row mt-1">
                                                        <div class="col-1 tiny-width"></div>
                                                        <div class="col-7">
                                                            <span class="holder-header element-view-detail-qty" translate>FINAL_QTY</span>
                                                        </div>
                                                        <div class="col-4 element-view-detail-value text-right">
                                                            {{detail.FinalQuantity | number}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
