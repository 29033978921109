/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./product-edit.component";
import * as i2 from "@ngx-translate/core";
var styles_ProductAdminEditComponent = [];
var RenderType_ProductAdminEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductAdminEditComponent, data: {} });
export { RenderType_ProductAdminEditComponent as RenderType_ProductAdminEditComponent };
export function View_ProductAdminEditComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["edit product\n"]))], null, null); }
export function View_ProductAdminEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-product-edit", [], null, null, null, View_ProductAdminEditComponent_0, RenderType_ProductAdminEditComponent)), i0.ɵdid(1, 114688, null, 0, i1.ProductAdminEditComponent, [i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductAdminEditComponentNgFactory = i0.ɵccf("app-product-edit", i1.ProductAdminEditComponent, View_ProductAdminEditComponent_Host_0, {}, {}, []);
export { ProductAdminEditComponentNgFactory as ProductAdminEditComponentNgFactory };
