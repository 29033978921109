import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService, SearchInfo } from "imagine-ui-ng-core";
import { GeneralSettingModel } from "../model/GeneralSettingModel";

@Injectable()
export class GeneralSettingsService extends IpsBaseWebService {

    public EnableSignPlanLayout = "EnableSignPlanLayout";
    public ExpandSurveyOptions = "ExpandSurveyOptions";
    public EnableUpDownReport = "EnableUpDownReport";
    private currentSettings: GeneralSettingModel[] = null;
    private currentBusinessId: string;

    constructor(http: HttpClient,
        public activeProfileService: ActiveProfileService,
        public helperFunctionsService: HelperFunctionsService
    ) {
        super(http, <any>environment, activeProfileService);
        this.init("Settings", "System", "v1");
        this.currentBusinessId = this.activeProfileService.businessIdentity;
    }

    public save(settings: GeneralSettingModel[]): Promise<any> {
        const returnPromise = new Promise<any>((resolve, reject) => {
            return this.put(settings).then((response) => {
                this.currentSettings = null;
                this.getCurrentSettings().then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            }).catch(() => {
                reject();
            });
        });

        return returnPromise;
    }

    private getAllSettings(includeDefaults = false): Promise<GeneralSettingModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}AllSettings`;
        let returnPromise = new Promise<any>((resolve, reject) => {
            this.getByRoute(route).then((response: any) => {
                if (includeDefaults) {
                    const defaults = this.getDefaultSettings();
                    const neededDefaults = defaults.filter((defaultItem) => {
                        return !response.find(item => item.Name === defaultItem.Name);
                    });

                    resolve(neededDefaults.concat(response));
                } else {
                    resolve(response);
                }
            }).catch(() => {
                resolve(this.getDefaultSettings());
            });
        });

        return returnPromise;
    }

    public getCurrentSettings(): Promise<GeneralSettingModel[]> {
        if (this.currentBusinessId !== this.activeProfileService.businessIdentity) {
            this.currentSettings = null;
            this.currentBusinessId = this.activeProfileService.businessIdentity;
        }

        if (this.currentSettings) {
            return Promise.resolve(this.currentSettings);
        } else {
            const returnPromise = new Promise<GeneralSettingModel[]>((resolve, reject) => {
                this.getAllSettings(true).then((response) => {
                    this.currentSettings = response;
                    resolve(response);
                }).catch(() => {
                    reject();
                });
            });

            return returnPromise;
        }
    }

    public getDefaultSettings(): GeneralSettingModel[] {
        let settings = [
            {
                "Id": 0,
                "Name": "EnableSignPlanLayout",
                "Value": "false",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "CoverBgImageFile",
                "Value": "",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "CoverHeaderLabel",
                "Value": "POP Placement Sheets",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "CoverFooterLabel",
                "Value": "",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "PageSize",
                "Value": "A3",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "PageOrientation",
                "Value": "2",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "SpaceOrder", "Value": "[]",
                "Type": "SignPlan",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "PSICustomerNumber",
                "Value": "",
                "Type": "PSI",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "MapMaterialNumberToCustomerItemCode",
                "Value": "false",
                "Type": "PSI",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "ExpandSurveyOptions",
                "Value": "true",
                "Type": "Survey",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            },
            {
                "Id": 0,
                "Name": "EnableUpDownReport",
                "Value": "false",
                "Type": "Report",
                "BusinessIdentity": this.activeProfileService.businessIdentity,
                "Options": null
            }
        ];

        return settings;
    }

    public getSettingByName(name: string): Promise<GeneralSettingModel> {
        const returnPromise = new Promise<GeneralSettingModel>((resolve, reject) => {
            if (!name) {
                resolve(null);
            } else {
                this.getCurrentSettings().then(() => {
                    let targetSetting = this.currentSettings.find(setting => setting.Name === name);
                    resolve(targetSetting);
                });
            }
        });

        return returnPromise;
    }

    private getSignPlanLayoutSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.EnableSignPlanLayout).then((response: GeneralSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(false);
                }
            });
        });

        return returnPromise;
    }

    public canEditSignPlanLayout(): Promise<boolean> {
        const hasEditPermission = !!this.activeProfileService.permissions.find(item => item.Name === "EditSignPlan");
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            if (hasEditPermission) {
                this.getSignPlanLayoutSetting().then((response) => {
                    resolve(response);
                }).catch(() => {
                    resolve(false);
                });
            } else {
                resolve(false);
            }
        });

        return returnPromise;
    }

    public canViewSignPlan(): Promise<boolean> {
        const hasViewPermission = !!this.activeProfileService.permissions.find(item => item.Name === "ViewSignPlan");
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            if (hasViewPermission) {
                this.getSignPlanLayoutSetting().then((response) => {
                    resolve(response);
                }).catch(() => {
                    resolve(false);
                });
            } else {
                resolve(false);
            }
        });

        return returnPromise;
    }


    private getUpDownReportSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.EnableUpDownReport).then((response: GeneralSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(false);
                }
            });
        });

        return returnPromise;
    }

    public canViewUpDownReport(): Promise<boolean> {
        const hasPermission = !!this.activeProfileService.permissions.find(item => item.Name === "SearchCampaignContentReport");
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            if (hasPermission) {
                this.getUpDownReportSetting().then((response) => {
                    resolve(response);
                }).catch(() => {
                    resolve(false);
                });
            } else {
                resolve(false);
            }
        });

        return returnPromise;
    }

    public canExpandSurveyOptions(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.ExpandSurveyOptions).then((response: GeneralSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(false);
                }
            });
        });

        return returnPromise;
    }
}
