<div class="col-md-12 col-lg-8 col-xl-6">
    <div class="row">
        <section class="col-12">
            <h3 class="title">
                <span class="header-label-imgn">{{'VIEW_ALTERNATE_ADDRESS' | translate}}</span>
                <a class="ml-2" uiSref="main.storeProfileAlternateAddress.edit" [uiParams]="{id: alternateAddress.Id}" ngbTooltip="{{'EDIT' | translate}} {{alternateAddress.Name}}" placement="bottom" ipsPermission="main.storeProfileAlternateAddress.edit"><span class="fa fa fa-pencil editIcon"></span></a>
            </h3>
        </section>
    </div>

    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_ALTERNATE_ADDRESS_INFO' | translate}}}">
        <div class="row" *ngIf="loaded">
            <section class="col-sm">
                <div class="form-group">
                    <div class="card">
                        <div class="card-header view-info-header d-flex">
                            <span class="pr-3">{{alternateAddress.AddressIdentifier}} - {{alternateAddress.Name}}</span>
                        </div>
                        <div class="form-row">
                            <div class="col" [ngClass]="{'col-lg-8 order-lg-first order-last pr-lg-0': fullImage}">
                                <div class="card-body">
                                    <ul class="list-group long-text-wrap">
                                        <li class="list-group-item">
                                            <div class="view-info-label" translate>POINT_OF_CONTACT</div>
                                            <div class="view-info-add-margin">{{alternateAddress.PointOfContact}}</div>
                                            <div class="view-info-label" translate>ADDRESS</div>
                                            <div class="view-info-add-margin">
                                                <span class="view-info-label">{{'ATTENTION_TO' | translate}}</span><span class="view-info-format-inline">{{alternateAddress.Attention}}</span><br />
                                                <span>{{alternateAddress.Line1}}</span><br />
                                                <span *ngIf="alternateAddress.Line2"><span>{{alternateAddress.Line2}}</span><br /></span>
                                                <span>{{alternateAddress.City}}, {{alternateAddress.StateProvince}} {{alternateAddress.PostalCode}} {{alternateAddress.Country}}</span>
                                                <br />
                                                <p *ngIf="alternateAddress.ValidationStatus == 'Skipped'" class="text-warning mb-0 mt-2"><b>{{information}}: </b>{{addressInvalidWarning}}</p>
                                                <p *ngIf="alternateAddress.ValidationStatus == 'Unknown'" class="text-warning mb-0 mt-2"><b>{{information}}: </b>{{addressNotValidated}}</p>
                                            </div>
                                            <div class="view-info-label" translate>PHONE</div>
                                            <div class="view-info-add-margin">{{alternateAddress.Phone | tel}}</div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="view-info-label" translate>NOTES_LOCATION</div>
                                            <div class="view-info-format-imgn">{{alternateAddress.Notes}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
       
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
