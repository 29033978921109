<div class="col-12 campaign-view-content">
    <div class="row">
        <section class="col-12 col-sm-12 col-md-12 col-lg-12">
            <h3 class="title">
                <span>{{'VIEW_CAMPAIGN' | translate}}</span>
                <a uiSref="main.campaign.edit" [uiParams]="{id:myCampaign.Id}" ipsPermission="main.campaign.edit" ngbTooltip="{{'EDIT' | translate}} {{myCampaign.Name}}" placement="bottom"><span class="fa fa fa-pencil editIcon ml-2"></span></a>
            </h3>
        </section>
    </div>
    <div class="card" *ngIf="!campaignLoaded && !campaignLoadError">
        <div class="card-body"><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> <span translate>LOADING_CAMPAIGN_INFORMATION</span></div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_CAMPAIGN_INFO' | translate }}" [hasError]="campaignLoadError"></ips-error-handler>
    <div class="row" *ngIf="campaignLoaded">
        <div class="col-12 main-promo-card">
            <div class="card">
                <div class="card-header view-info-header">{{myCampaign.Name}}</div>
                <div class="card-body">
                    <div class="row">
                        <!--Campaign info column-->
                        <div class="col-12 col-xl-6">
                            <ul class="list-group long-text-wrap">
                                <li class="list-group-item">
                                    <div class="row-margin">
                                        <label class="campaign-view-label">{{'OBSOLETE' | translate}}:</label>{{myCampaign.IsObsolete ? "Yes" : "No"}}
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row-margin">
                                        <label class="campaign-view-label">{{'IN_STORE_DATE' | translate}}:</label>{{myCampaign.InStoreDate | date : "mediumDate" : "+0000"}}
                                    </div>
                                    <div>
                                        <label class="campaign-view-label">{{'START_END_DATE' | translate}}:</label>{{myCampaign.StartDate | date : "mediumDate" : "+0000"}} - {{myCampaign.EndDate | date : "mediumDate" : "+0000"}}
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row-margin label-min-height">
                                        <label class="campaign-view-label">{{'ABBREVIATED_NAME' | translate}}:</label>{{myCampaign.AbbreviatedName}}
                                    </div>
                                    <div class="row-margin">
                                        <label class="campaign-view-label">{{'FULFILLMENT_DEFAULT' | translate}}:</label>{{myCampaign.FulfillmentQuantity}}<span *ngIf="myCampaign.FulfillmentOperationType === 'Percent'">%</span>
                                    </div>
                                    <div>
                                        <label class="campaign-view-label">{{'ELEMENT_DEFAULT_QTY' | translate}}:</label>{{myCampaign.ElementQuantity}}
                                    </div>
                                </li>
                                <li *ngIf="myCampaign.HasCampaignAddresses && !myCampaign.UseCampaignAddress" class="list-group-item">
                                    <div class="row-margin label-min-height">
                                        <label class="campaign-view-label">{{'OVERRIDING_CAMPAIGN_SHIPPING_ADDRESS' | translate}}</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="campaign-view-label">
                                                <label>{{'NOTES' | translate}}:</label>
                                            </div>
                                            <div>
                                                <span>{{myCampaign.Notes}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <!--Production and Allocation files column-->
                        <div class="col-12 col-xl-6" *ngIf="myCampaign.ProductionMedia || myCampaign.AllocationMedia">
                            <div class="row mt-3 mt-xl-0">
                                <div class="col-sm-12 col-lg-6 col-xl-12" *ngIf="myCampaign.ProductionMedia">
                                    <div class="card ips-search-card mb-3 mb-lg-0 mb-xl-3">
                                        <div class="card-body d-flex align-items-center">
                                            <div class="campaign-production-file-icon mr-3"></div>
                                            <div>
                                                <div><b translate>PRODUCTION_FILE</b>:</div>
                                                <div><button class="btn btn-link btn-lg p-0 long-text-wrap text-left" (click)="fileDownloadHandler(myCampaign.ProductionMedia.ResourceUri)">{{myCampaign.ProductionMedia.FileName}}</button></div>
                                                <div>{{myCampaign.ProductionSubmittedBy}} - {{myCampaign.ProductionSubmittedDateTime | date : "mediumDate" : "+0000"}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-lg-6 col-xl-12" *ngIf="myCampaign.AllocationMedia">
                                    <div class="card ips-search-card">
                                        <div class="card-body d-flex align-items-center">
                                            <div class="campaign-allocation-file-icon mr-3"></div>
                                            <div>
                                                <div><b translate>ALLOCATION_FILE</b>:</div>
                                                <div><button class="btn btn-link btn-lg p-0 long-text-wrap text-left" (click)="fileDownloadHandler(myCampaign.AllocationMedia.ResourceUri)">{{myCampaign.AllocationMedia.FileName}}</button></div>
                                                <div>{{myCampaign.AllocationSubmittedBy}} - {{myCampaign.AllocationSubmittedDateTime | date : "mediumDate" : "+0000"}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12" ipsFocus="searchInput">
    <section class="ips-ls-search-title">
        <section class="ips-ls-search-title-no-link">
            <h3>
                <span class="ips-ls-header-label-imgn" translate>PROMOTIONS</span>
                <a uiSref="main.campaign.promotion.edit" [uiParams]="{campaignId: campaignId, id: 0}" ngbTooltip="{{'ADD_PROMOTION' | translate}}" placement="bottom" ipsPermission="main.campaign.edit"><span class="fa fa-plus ips-ls-addIcon"></span></a>
                <span class="promotion-copy-img icon ips-icon-18 ml-2 mb-1"  (click)="searchPromotion()"  ngbTooltip="{{'CLONE_A_PROMOTION' | translate}}" placement="bottom"></span>
            </h3>
        </section>
    </section>
    <ips-list-search
        hideHeader="true"
        [searchLabel]="searchLabel"
        [searchLabelPlural]="searchLabelPlural"
        labelLoading="{{'ACCESSING_INFO' | translate }}"
        labelLoadingError="{{'ERROR_ACCESSING_INFO' | translate }}"
        [listData]="promotion"
        [busy]="busy"
        [query]="query"
        (onListDataChange)="getListData($event, $event.forceReload, $event.scrolling)"
        scroller="true"
        quick-ref="false"
        section="main.campaign.promotion"
        [promise]="promise"
        createNewTooltip="{{'CREATE_PROMOTION' | translate }}"
        autoFocus="true">
        <ng-template #searchHeaderTemplate>
            <section class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                <div class="ips-ls-search-module-imgn">
                    <div class="row">
                        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <div>
                                <label  class="ips-ls-labels" for="labelName" translate>STATUS</label><i class="fa fa-info-circle ips-ls-info-icon fa-lg" aria-hidden="true" [ngbTooltip]="labelTooltip" placement="bottom"></i><br />
                                <select name="selectedPromotionStatus" [(ngModel)]="selectedPromotionStatus" (ngModelChange)="filterSearch($event)" class="ng-cloak form-control form-control-lg" [disabled]="disableInput">
                                  <option *ngFor="let item of availablePromotionStatus; trackBy: trackByFn" [ngValue]="item">{{item.Status}}</option>
                                </select>
                              </div>                              
                        </div>        
                    </div>
                    <div class="has-feedback has-feedback-left ips-ls-searchBox">
                        <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                        <input type="text"
                               class="form-control form-control-lg"
                               [(ngModel)]="query" (ngModelChange)="search($event)"
                               id="btn-search"
                               value=""
                               maxlength="100"
                               [ngModelOptions]="{ debounce: 350 }"
                               aria-label="Text input with dropdown button">
                     </div>
                     <div search-counts-badge class="col">
                        <div *ngIf="translated" class="row no-gutters mt-1">
                            <div [ngbCollapse]="collapse" class="col-12 col-sm">
                                <div class="row no-gutters mt-1">
                                    <div class="col-12 col-md-auto">
                                        <span class="badge-pill badge-secondary font-weight-normal">{{ promotion.ResultChunkAttributes.TotalRecords | number }}</span>
                                        <span class="ml-1 mr-2">{{ promotion.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping}} {{'FOUND_OF' | translate}} </span>
                                    </div>
                                    <div class="col-12 col-md-auto">
                                        <span class="badge-pill badge-secondary font-weight-normal">{{ totalPromotionCount | number }}</span>
                                        <span class="ml-1">{{ 'TOTAL' | translate}} {{totalPromotionCount | i18nPlural: pluralMapping}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ng-template>
        <ng-template #listSearchItemTemplate let-result="result">
            <div class="px-4 py-2">
                <div class="row mx-0 ips-search-result-big">
                    <div class="col-12 col-md-10">
                        <a class="mr-auto" uiSref="main.campaign.promotion.view" [uiParams]="{campaignId: campaignId, id:result.Id}" innerHtml="{{result.Name | highlight : query}}"></a>
                        <span [ngClass]="result.Status === 'InProgress' ? 'status in-progress' : 'status completed' " >[{{result.Status}}]</span>
                    </div>
                    <div class="col-12 col-md-2 d-flex align-items-center justify-content-end">
                        <span class="promotion-move-img icon ips-icon-18 mr-3" (click)="searchCampaign(result)" ngbTooltip="{{'MOVE_PROMOTION' | translate}}" placement="bottom"></span>
                        <a uiSref="main.campaign.promotion.edit" [uiParams]="{campaignId: campaignId, id: result.Id}" ngbTooltip="{{'EDIT' | translate}} {{result.Name}}" placement="bottom" ipsPermission="main.campaign.edit"><span class="fa fa fa-pencil editIcon"></span></a>
                    </div>
                </div>
                <div class="form-row row-margin">
                    <div class="col">
                        <div class="card ips-search-card">
                            <div class="card-body">
                                <span><b translate>START_END_DATE</b>:</span>
                                <a uiSref="main.campaign.promotion.edit" [uiParams]="{campaignId: campaignId, id: result.Id}">
                                    {{result.StartDate | date:'mediumDate'}} -
                                    <span class="avoidwrap">{{result.EndDate ? (result.EndDate | date:'mediumDate') : ( 'NA' | translate)}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card ips-search-card">
                            <div class="card-body d-flex align-items-center">
                                <div class="campaign-message-img icon-base-card mr-3"></div>
                                <a uiSref="main.campaign.promotion.edit" [uiParams]="{campaignId: campaignId, id: result.Id, scrollTo: 'message'}">{{result.MessageCount | number}} {{result.MessageCount | i18nPlural: messagePluralMapping | translate}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row row-margin">
                    <div class="col">
                        <div class="card ips-search-card">
                            <div class="card-body d-flex align-items-center">
                                <div class="campaign-placement-img icon-base-card mr-3"></div>
                                <a uiSref="main.campaign.promotion.edit" [uiParams]="{campaignId: campaignId, id: result.Id, scrollTo: 'fixtureHolder'}">{{result.PlacementFixtureHolderCount | number}} {{result.PlacementFixtureHolderCount | i18nPlural: fixtureHolderPluralMapping | translate}}</a>
                            </div>
                        </div>
                    </div> 
                    <div class="col">
                        <div class="card ips-search-card" *ngIf="result.PatternGroupType === 'SingleMessage'">
                            <div class="card-body d-flex align-items-center">
                                <div class="campaign-placement-element-img icon-base-card mr-3"></div>
                                <a uiSref="main.campaign.promotion.edit" [uiParams]="{campaignId: campaignId, id: result.Id, scrollTo: 'element'}">{{result.PlacementElementCount | number}} {{result.PlacementElementCount | i18nPlural: elementPluralMapping | translate}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ips-list-search>
</div>

