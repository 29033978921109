import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { OnInit, QueryList, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PromotionMessagePlacementElementEditModel, PromotionMessageService, PromotionMessageModel, Operator, PromotionMessagePlacementElementDetailModel, PromotionMessagePlacementElementModel, MessagePlacementCalculationResultModel, MessagePlacementCalculationModel, ElementLocationBalanceListRequestModel, FulfillmentOperationType, PromotionMessagePatternModel, MessageNameChangeModel, PromotionSaveModel, PromotionMessagePatternSaveModel, PlacementModalSearchResultModel } from "../../index";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { String as IpsString } from "typescript-string-operations";
import { SpaceService, FixtureGroupService } from "../../../imagine-ui-ng-store-profile/index";
import { ElementQuantityHandlingModalComponent } from "../element-quantity-handling-modal/element-quantity-handling-modal.component";
import { PromotionHelperService } from "../../service/promotionHelper.service";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { CustomDataFieldContainerComponent } from "../../../shared/custom-data-field/custom-data-field-container/custom-data-field-container.component";
import { PlacementSearchModalComponent } from "../placement-search-modal/placement-search-modal.component";
import { SelectListComponent } from "../../../shared/select-list/select-list.component";
import { SignPlanService } from "../../../shared/service/sign-plan.service";
var PlacementElementEditComponent = /** @class */ (function () {
    //Form vars
    function PlacementElementEditComponent(ipsModal, changeDetectorRef, activeProfileService, translateService, spaceService, promotionMessageService, fb, promotionHelperService, fixtureGroupService, signPlanService) {
        var _this = this;
        this.ipsModal = ipsModal;
        this.changeDetectorRef = changeDetectorRef;
        this.activeProfileService = activeProfileService;
        this.translateService = translateService;
        this.spaceService = spaceService;
        this.promotionMessageService = promotionMessageService;
        this.fb = fb;
        this.promotionHelperService = promotionHelperService;
        this.fixtureGroupService = fixtureGroupService;
        this.isClone = false;
        this.enableSignPlanLayout = false;
        this.indexer = 0;
        this.positiveNumberPattern = "^\\d+$";
        this.spaces = [];
        this.signPlanWidthOptions = [];
        this.TranslateStrings = {
            "ENTER_POSITIVE_WHOLE_NUMBER": "",
            "MAX_LENGTH_ERROR": ""
        };
        this.errorMessages = {
            "required": function () { return _this.TranslateStrings["ENTER_POSITIVE_WHOLE_NUMBER"]; },
            "pattern": function () { return _this.TranslateStrings["ENTER_POSITIVE_WHOLE_NUMBER"]; },
            "maxlength": function (params) { return IpsString.Format(_this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength); }
        };
        this.signPlanWidthOptions = signPlanService.SignPlanWidthOptions;
    }
    PlacementElementEditComponent.prototype.ngAfterViewChecked = function () {
        this.changeDetectorRef.detectChanges();
    };
    PlacementElementEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loaded = false;
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(function () { return _this.TranslateText(); });
        this.spaceService.getList().then(function (ret) {
            ret.unshift({ Id: 0, Name: _this.translateService.instant("SELECT_SPACE"), Notes: "", BusinessIdentity: "" });
            _this.spaces = ret;
        });
        this.initializePlacementsForUIAndCreateFormControls();
        this.sortElements();
        this.loaded = true;
        return this.promise;
    };
    PlacementElementEditComponent.prototype.createFormControls = function (placementElements) {
        var placementElementsArr = this.fb.array(this.createElementFormArray(placementElements));
        this.parent.addControl("placementElementsFrmArr", placementElementsArr);
    };
    PlacementElementEditComponent.prototype.sortElements = function () {
        var elements = this.placementElementsFrmArr;
        elements.controls = elements.controls.sort(function (n1, n2) { return (n1.value.DisplayName.localeCompare(n2.value.DisplayName)); });
    };
    PlacementElementEditComponent.prototype.createElementFormArray = function (placementElements) {
        var _this = this;
        var formArray = [];
        var elementNameDict = {};
        placementElements.forEach(function (element) {
            var eleHeaderFormGroup = elementNameDict[element.DisplayName];
            if (!eleHeaderFormGroup) {
                // Create an array we can push to, per message, for this element.
                eleHeaderFormGroup = _this.createElementHeaderFormGroup(element);
                elementNameDict[element.DisplayName] = eleHeaderFormGroup;
                formArray.push(eleHeaderFormGroup);
            }
            // Now add an element formGroup for this element entry
            (_this.getMsgPlacementElementsCtrl(eleHeaderFormGroup)).push(_this.createPlacementElementFormGroup(element));
        });
        return formArray;
    };
    PlacementElementEditComponent.prototype.createElementHeaderFormGroup = function (placement) {
        return this.fb.group({
            DisplayName: this.fb.control(placement.DisplayName),
            ElementDetailId: this.fb.control(placement.ElementDetailId),
            MessageElementPlacements: this.fb.array([])
        });
    };
    PlacementElementEditComponent.prototype.setHolderPosition = function (placement, position) {
        if (position < 1 || position > 17) {
            position = 1;
        }
        placement.get("PlacementLocation").setValue(position);
    };
    PlacementElementEditComponent.prototype.getHolderPosition = function (placement) {
        return placement.get("PlacementLocation").value;
    };
    PlacementElementEditComponent.prototype.createPlacementElementFormGroup = function (placement) {
        var _this = this;
        var elementForm = this.fb.group(placement);
        elementForm.addControl("Qty", this.fb.control(placement.PlacementElementDetails[0].Quantity, [Validators.required, Validators.pattern(this.positiveNumberPattern)]));
        if (placement.LocationCount === undefined) {
            elementForm.addControl("LocationCount", this.fb.control(undefined));
        }
        var spaceId = placement.Spaces !== undefined ? placement.Spaces.length > 0 ? placement.Spaces[0].Id : 0 : 0;
        elementForm.addControl("SpaceId", this.fb.control(spaceId));
        var spaceGroups = placement.Spaces.map(function (space) {
            return _this.fb.group(space);
        });
        elementForm.setControl("Spaces", this.fb.array(spaceGroups));
        elementForm.addControl("HolderId", this.fb.control(placement.HolderId || null));
        elementForm.addControl("HolderName", this.fb.control(placement.HolderName || null));
        elementForm.addControl("FixtureGroupName", this.fb.control(placement.FixtureGroupName || null));
        elementForm.addControl("SignPlanMessage", this.fb.control(placement.SignPlanMessage));
        elementForm.addControl("SignPlanWidth", this.fb.control(placement.SignPlanWidth));
        elementForm.addControl("PlacementLocation", this.fb.control(placement.PlacementLocation || null));
        elementForm.get("FulfillmentOperand").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        // Make element details a nested form array
        elementForm.removeControl("PlacementElementDetails");
        this.createPlacementElementDetailsFormArray(elementForm, placement.PlacementElementDetails);
        elementForm.addControl("TempDisplayName", this.fb.control(placement.QuantityHandling === "MarketedLocations" ? this.marketedLocationsText : placement.LocationGroupName));
        elementForm.get("CustomerItemCode").setValidators([Validators.maxLength(50)]);
        this.addListeners(elementForm);
        return elementForm;
    };
    PlacementElementEditComponent.prototype.createPlacementElementDetailsFormArray = function (elementForm, placementDetails) {
        var _this = this;
        var placementDetailFormGroups = placementDetails.map(function (detail) {
            var group = _this.fb.group(detail);
            group.get("Quantity").setValidators([Validators.required, Validators.pattern(_this.positiveNumberPattern)]);
            return group;
        });
        elementForm.setControl("PlacementElementDetails", this.fb.array(placementDetailFormGroups));
        this.addElementDetailListeners(elementForm);
    };
    PlacementElementEditComponent.prototype.addNewPlacementElementControls = function (placements) {
        var _this = this;
        // Grab the first item so we can make the header entry
        var eleHeaderFormGroup = this.createElementHeaderFormGroup(placements[0]);
        placements.forEach(function (element) {
            var placementElementControl = _this.createPlacementElementFormGroup(element);
            // Now add an element formGroup for this element entry
            (_this.getMsgPlacementElementsCtrl(eleHeaderFormGroup)).push(placementElementControl);
        });
        this.placementElementsFrmArr.push(eleHeaderFormGroup);
        this.placementElementsFrmArr.markAsDirty();
    };
    Object.defineProperty(PlacementElementEditComponent.prototype, "placementElementsFrmArr", {
        get: function () {
            return this.parent.get("placementElementsFrmArr");
        },
        enumerable: true,
        configurable: true
    });
    PlacementElementEditComponent.prototype.addListeners = function (elementForm) {
        var _this = this;
        elementForm.get("Qty").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
            // The form watchs qty on the parent control, but placement calc expects qty on the first detail record, so set it
            _this.getPlacmentElementDetailsCtrl(elementForm).at(0).patchValue({ Quantity: value });
            _this.qtyChange(elementForm);
        });
        elementForm.get("FulfillmentOperand").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function () {
            _this.fulfillmentOperandChanged(elementForm);
        });
    };
    PlacementElementEditComponent.prototype.addElementDetailListeners = function (elementForm) {
        var _this = this;
        var elementDetails = this.getPlacmentElementDetailsCtrl(elementForm);
        elementDetails.controls.forEach(function (detail) {
            detail.get("Quantity").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function () {
                _this.qtyChange(elementForm);
            });
        });
    };
    PlacementElementEditComponent.prototype.GetCurrentPlacementElements = function () {
        var result = [];
        var elementHeaders = this.placementElementsFrmArr.controls.map(function (element) { return element.value; });
        elementHeaders.forEach(function (elementHeader) {
            result = result.concat(elementHeader.MessageElementPlacements);
        });
        return result;
    };
    PlacementElementEditComponent.prototype.TranslateText = function () {
        var _this = this;
        this.translateService.get("ALL").subscribe(function () {
            _this.marketedLocationsText = _this.translateService.instant("MARKETED_LOCATIONS");
            _this.locationGroupLocationsPendingWarning = _this.translateService.instant("MARKET_PENDING_LOCATIONS_WARNING");
            for (var _i = 0, _a = Object.keys(_this.TranslateStrings); _i < _a.length; _i++) {
                var key = _a[_i];
                _this.TranslateStrings[key] = _this.translateService.instant(key);
            }
        });
    };
    PlacementElementEditComponent.prototype.getErrorMessages = function () {
        return this.errorMessages;
    };
    /**
     * Call to set various properties needed by UI.
     */
    PlacementElementEditComponent.prototype.initializePlacementsForUIAndCreateFormControls = function () {
        var _this = this;
        var placementElements = [];
        var promotionMessages = [];
        if (!this.parent.get("ClearAllMessages").value) {
            this.messageAndPlacements.forEach(function (mainMsg) {
                if (_this.isClone) {
                    mainMsg.PromotionMessage.Id = 0;
                }
                promotionMessages.push(mainMsg.PromotionMessage);
                mainMsg.PlacementElements.forEach(function (placement) {
                    if (_this.isClone) {
                        placement.Id = 0;
                        placement.MessageId = 0;
                        placement.CustomerItemCode = undefined;
                    }
                    placement.DisplayName = placement.ElementDetailName + " - " + placement.ElementDetailWidth + " x " + placement.ElementDetailHeight;
                    placement.IdName = "placementElement" + _this.indexer++;
                    placement.TempId = _this.indexer++;
                    placement.CustomerItemCode = placement.CustomerItemCode ? placement.CustomerItemCode : null;
                    placement.ElementDetailCategoryCode = placement.ElementDetailCategoryCode ? placement.ElementDetailCategoryCode : null;
                    // Set message info
                    placement.TempMessageId = mainMsg.PromotionMessage.TempId;
                });
                placementElements = placementElements.concat(mainMsg.PlacementElements);
            });
        }
        this.createFormControls(placementElements);
    };
    PlacementElementEditComponent.prototype.getPendingLocationWarningString = function (pendingAmount) {
        return IpsString.Format(this.locationGroupLocationsPendingWarning, pendingAmount.toString());
    };
    // Get the list of element placements that exist for the specified elementDetail control
    PlacementElementEditComponent.prototype.getMsgPlacementElementsCtrl = function (elementHeader) {
        return elementHeader.get("MessageElementPlacements");
    };
    PlacementElementEditComponent.prototype.getPlacmentElementDetailsCtrl = function (placement) {
        return placement.get("PlacementElementDetails");
    };
    PlacementElementEditComponent.prototype.onMainMessageDeleted = function (mainMessage) {
        var _this = this;
        var elementHeadersToRemove = [];
        this.placementElementsFrmArr.controls.forEach(function (elementHeader, index) {
            var placementElementsCtrl = _this.getMsgPlacementElementsCtrl(elementHeader);
            placementElementsCtrl.controls.some(function (placementControl, placeIndex) {
                var placement = placementControl.value;
                if (placement.TempMessageId === mainMessage.TempMessageId) {
                    placementElementsCtrl.removeAt(placeIndex);
                    // Should only be one placement per message, break out.
                    return true;
                }
                // tell the 'some' loop to keep going.
                return false;
            });
            if (placementElementsCtrl.controls.length === 0) {
                elementHeadersToRemove.push(index);
            }
        });
        for (var i = elementHeadersToRemove.length - 1; i >= 0; i--) {
            this.placementElementsFrmArr.removeAt(elementHeadersToRemove[i]);
        }
        if (elementHeadersToRemove.length > 0) {
            this.placementElementsFrmArr.markAsDirty();
        }
    };
    PlacementElementEditComponent.prototype.onMainMessageAdded = function (mainMessage) {
        var _this = this;
        this.placementElementsFrmArr.controls.forEach(function (elementHeader, index) {
            var existingPlacements = _this.getMsgPlacementElementsCtrl(elementHeader);
            var existingPlacement = existingPlacements.at(0).value;
            // Add a placement for the new message
            var element = { Name: existingPlacement.ElementDetailName, Id: existingPlacement.ElementId };
            var detail = { Id: existingPlacement.ElementDetailId, Width: existingPlacement.ElementDetailWidth, Height: existingPlacement.ElementDetailHeight, CategoryCode: existingPlacement.ElementDetailCategoryCode };
            var placement = _this.getNewPlacement(mainMessage, element, detail);
            existingPlacements.push(_this.createPlacementElementFormGroup(placement));
        });
    };
    // Call this when the user updates the list of selected elements
    PlacementElementEditComponent.prototype.onPlacementElementsAdded = function (response) {
        var _this = this;
        var changedPlacements = false;
        // remove elements not selected
        var indexesToRemove = [];
        this.placementElementsFrmArr.controls.forEach(function (elementHeader, index) {
            var elementHeaderControl = elementHeader.value;
            var found = response.some(function (item) {
                return item.ElementDetails.some(function (detail) {
                    return detail.Id === elementHeaderControl.ElementDetailId;
                });
            });
            if (!found) {
                indexesToRemove.push(index);
            }
        });
        if (indexesToRemove.length > 0) {
            // Walk backwards removing items from the end of the formArray
            for (var i = indexesToRemove.length - 1; i >= 0; i--) {
                this.placementElementsFrmArr.removeAt(indexesToRemove[i]);
            }
            this.placementElementsFrmArr.markAsDirty();
        }
        // Fill in placement rows
        var messages = this.promotionHelperService.getCurrentMessagesFromForm(this.parent);
        var controls = this.placementElementsFrmArr.controls;
        var elementDetailIds = [];
        response.forEach(function (element) {
            element.ElementDetails.forEach(function (detail) {
                // See if we already have this element detailId
                var hasElementDetailId = controls.some(function (elementHeader) { return elementHeader.value.ElementDetailId === detail.Id; });
                if (!hasElementDetailId) {
                    var placements_1 = [];
                    messages.forEach(function (message) {
                        var placement = _this.getNewPlacement(message, element, detail);
                        placements_1.push(placement);
                    });
                    // Create the elementHeader and then all the child controls for each message.
                    _this.addNewPlacementElementControls(placements_1);
                    elementDetailIds.push(detail.Id);
                    changedPlacements = true;
                }
            });
        });
        // Mark as dirty
        if (changedPlacements) {
            this.sortElements();
            for (var j = 0; j < messages.length; j++) {
                this.calculateAllPlacementQuantities(messages[j].TempMessageId, elementDetailIds);
            }
        }
    };
    PlacementElementEditComponent.prototype.getNewPlacement = function (mainMsg, element, elementDetail) {
        var index = this.indexer++;
        // Generate a new placement for a given holder and its fixture group (aka fixtureType)
        var placement = {
            ElementId: element.Id,
            ElementDetailId: elementDetail.Id,
            ElementDetailName: element.Name,
            ElementDetailWidth: elementDetail.Width,
            ElementDetailHeight: elementDetail.Height,
            ElementDetailCategoryCode: elementDetail.CategoryCode,
            PromotionId: this.promoId,
            DisplayName: element.Name + " - " + elementDetail.Width + " x " + elementDetail.Height,
            Id: 0,
            IdName: "placement" + index,
            TempId: index,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            MessageId: mainMsg.Id,
            TempMessageId: mainMsg.TempMessageId,
            MessageName: mainMsg.MessageName,
            QuantityHandling: "MarketedLocations",
            FulfillmentOperand: this.campaignFulfillmentQty,
            FulfillmentOperator: this.campaignFulfillmentType,
            FinalQuantity: 0,
            PlacementElementDetails: [{ Id: 0, FinalQuantity: 0, LocationCount: 0, Quantity: this.campaignElementQty || 0, BusinessIdentity: this.activeProfileService.businessIdentity }],
            PendingAssignmentCount: 0,
            TempDisplayName: this.marketedLocationsText,
            LocationGroupId: "",
            LocationGroupName: "",
            LocationCount: 0,
            Selected: true,
            CustomerItemCode: null,
            SpaceId: 0,
            HolderId: null,
            PlacementLocation: "TopLeft",
            Spaces: [],
            SpaceList: [],
            SignPlanMessage: null,
            SignPlanWidth: null,
            HolderName: null,
            FixtureGroupName: null
        };
        return placement;
    };
    PlacementElementEditComponent.prototype.isLocationGroupQtyHandling = function (placement) {
        return placement !== undefined && placement.QuantityHandling === "LocationGroup";
    };
    PlacementElementEditComponent.prototype.shouldShowPlacements = function () {
        return this.loaded && this.placementElementsFrmArr.controls.length > 0;
    };
    PlacementElementEditComponent.prototype.getOperatorIconName = function (operator) {
        var result = "NA";
        switch (operator) {
            case "Percent":
                result = "fa-percent";
                break;
            case "Number":
                result = "fa-hashtag";
                break;
        }
        return result;
    };
    PlacementElementEditComponent.prototype.showQuantityHandlingModal = function (placementControl, toolTip) {
        var _this = this;
        toolTip.close();
        var placement = placementControl.value;
        return this.ipsModal.displayTemplateScrollable(ElementQuantityHandlingModalComponent, { resolve: { marketedLocations: placement.LocationCount } })
            .then(function (response) {
            if (response === undefined) {
                placementControl.patchValue({
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    QuantityHandling: "MarketedLocations",
                    FinalQuantity: 0,
                    PendingAssignmentCount: 0,
                    TempDisplayName: _this.marketedLocationsText
                });
                var placementDetails = placementControl.get("PlacementElementDetails");
                //Remove all placement element details rows
                while (placementDetails.length > 0) {
                    placementDetails.removeAt(0);
                }
                //Add Marketed Locations
                placementDetails.push(_this.fb.group({
                    Id: _this.fb.control(0),
                    Quantity: _this.fb.control(_this.campaignElementQty || 1),
                    FinalQuantity: _this.fb.control(0),
                    LocationCount: _this.fb.control(0),
                    BusinessIdentity: _this.fb.control(_this.activeProfileService.businessIdentity)
                }));
                placementControl.markAsDirty();
            }
            else {
                if (response.Id !== placement.LocationGroupId) {
                    var subGroupsElementDetails_1 = [];
                    // Load up the subgroups
                    response.SubGroups.forEach(function (item) {
                        var subGroupDetail = {
                            Id: 0,
                            LocationSubGroupId: item.Id,
                            LocationSubGroupName: item.Name,
                            Quantity: _this.campaignElementQty || 1,
                            LocationCount: 0,
                            FinalQuantity: 0 // Calculated, default to 0
                        };
                        subGroupsElementDetails_1.push(subGroupDetail);
                    });
                    //For case when coming from "MarketedLocations" to a location group. add missing controls
                    if (!placementControl.controls.LocationGroupId) {
                        placementControl.addControl("LocationGroupId", _this.fb.control(0));
                    }
                    if (!placementControl.controls.LocationGroupName) {
                        placementControl.addControl("LocationGroupName", _this.fb.control(""));
                    }
                    placementControl.patchValue({
                        LocationGroupId: response.Id,
                        LocationGroupName: response.Name,
                        PendingAssignmentCount: response.PendingAssignmentCount,
                        QuantityHandling: "LocationGroup",
                        FinalQuantity: 0,
                        TempDisplayName: response.Name
                    });
                    placementControl.markAsDirty();
                    var detailsControl = _this.getPlacmentElementDetailsCtrl(placementControl);
                    // remove all the original controls
                    for (var i = detailsControl.length - 1; i >= 0; i--) {
                        detailsControl.removeAt(i);
                    }
                    _this.createPlacementElementDetailsFormArray(placementControl, subGroupsElementDetails_1);
                }
            }
            _this.calculatePlacementElementQuantities(placementControl);
        }, function (rejectMessage) {
            // Do nothing, prevent console error
        });
    };
    PlacementElementEditComponent.prototype.fulfillmentOperandChanged = function (placementElementCtrl) {
        this.calculatePlacementElementQuantities(placementElementCtrl);
    };
    PlacementElementEditComponent.prototype.fulfillmentOperatorClick = function (placementElementCtrl) {
        var placementElement = placementElementCtrl.value;
        placementElementCtrl.patchValue({
            FulfillmentOperator: placementElement.FulfillmentOperator === "Percent" ? "Number" : "Percent"
        });
        placementElementCtrl.markAsDirty();
        this.calculatePlacementElementQuantities(placementElementCtrl);
    };
    PlacementElementEditComponent.prototype.togglePrintMessages = function (placementElementCtrl, print) {
        placementElementCtrl.patchValue({
            Selected: print
        });
        placementElementCtrl.markAsDirty();
    };
    PlacementElementEditComponent.prototype.qtyChange = function (placementElementCtrl) {
        this.calculatePlacementElementQuantities(placementElementCtrl);
    };
    PlacementElementEditComponent.prototype.calculateAllPlacementQuantities = function (tempMessageId, elementDetailIds) {
        var _this = this;
        var patternGroup = this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, tempMessageId);
        patternGroup.StartDate = this.parent.value.StartDate;
        patternGroup.EndDate = this.parent.value.EndDate;
        var placementElements = this.getElementPlacementByMessage(tempMessageId, elementDetailIds);
        //Remove un-needed spaces
        if (placementElements) {
            placementElements = Object.assign([], placementElements);
            placementElements.forEach(function (item) {
                // delete item.Spaces;
            });
        }
        //re-calculate
        var balanceCalcModel = {
            PatternGroupInfo: patternGroup,
            MessagePlacements: undefined,
            PlacementElements: placementElements,
            ReturnLocationBalance: false,
            PlacementGroups: undefined,
            DefaultFulfillmentOperator: this.campaignFulfillmentType,
            DefaultFulfillmentQuantity: this.campaignFulfillmentQty
        };
        // Call server to get new totals
        this.promotionMessageService.calculateLocationBalance(balanceCalcModel).then(function (response) {
            if (response !== null) {
                //update placement elements location count
                _this.UpdateElementAfterCalculation(response.PlacementElements, tempMessageId);
            }
        });
    };
    PlacementElementEditComponent.prototype.calculatePerPatternGroupPlacementElementQuantities = function (elementHeader) {
        var _this = this;
        var placementElements = this.getMsgPlacementElementsCtrl(elementHeader);
        placementElements.controls.forEach(function (placementElementCtrl) {
            _this.calculatePlacementElementQuantities(placementElementCtrl);
        });
    };
    PlacementElementEditComponent.prototype.calculatePlacementElementQuantities = function (placementElementControl) {
        var _this = this;
        var placementElement = placementElementControl.value;
        // only recalc if we have a valid value
        var invalid = this.hasInvalidField(placementElement);
        if (!invalid) {
            var balanceCalcModel = this.deriveMessagePlacementCalculationModel(placementElement);
            //Remove un-needed spaces
            if (balanceCalcModel.PlacementElements) {
                balanceCalcModel.PlacementElements = Object.assign([], balanceCalcModel.PlacementElements);
                balanceCalcModel.PlacementElements.forEach(function (item) {
                    // delete item.Spaces;
                });
            }
            this.promotionMessageService.calculateLocationBalance(balanceCalcModel).then(function (response) {
                if (response !== null) {
                    _this.updateElementQty(response.PlacementElements[0], placementElementControl);
                }
            });
        }
    };
    PlacementElementEditComponent.prototype.updateElementQty = function (placementElement, placementElementControl) {
        placementElementControl.patchValue({
            LocationCount: placementElement.LocationCount,
            FinalQuantity: placementElement.FinalQuantity
        });
        // Update subgroups if we have them
        var formDetails = this.getPlacmentElementDetailsCtrl(placementElementControl);
        formDetails.controls.forEach(function (ctrl, index) {
            var existingDetail = ctrl.value;
            var resultDetail = placementElement.PlacementElementDetails.find(function (item) { return existingDetail.LocationSubGroupId ===
                item.LocationSubGroupId; });
            if (resultDetail) {
                ctrl.patchValue({
                    LocationCount: resultDetail.LocationCount,
                    FinalQuantity: resultDetail.FinalQuantity
                });
            }
        });
    };
    PlacementElementEditComponent.prototype.UpdateElementAfterCalculation = function (placementElements, tempMessageId) {
        var _this = this;
        this.placementElementsFrmArr.controls.forEach(function (elementHeader) {
            var plcElementArray = _this.getMsgPlacementElementsCtrl(elementHeader);
            plcElementArray.controls.forEach(function (elementCtrl) {
                var placementElement = elementCtrl.value;
                if (tempMessageId === placementElement.TempMessageId) {
                    var elementObj = placementElements.find(function (ele) { return ele.ElementDetailId === placementElement.ElementDetailId; });
                    // NOTE: when adding a new element placement, we only calculate for those new elements, so it is possible to find a placement for
                    // the existing tempMessageId, but then not find an elementDetail in the calc result model.
                    if (elementObj) {
                        _this.updateElementQty(elementObj, elementCtrl);
                    }
                }
            });
        });
    };
    /**
     * Returns undefined if no element placements. Otherwise returns a list of element placements for the given tempMessageId
     * @param tempMessageId
     */
    PlacementElementEditComponent.prototype.getElementPlacementByMessage = function (tempMessageId, elementDetailIds) {
        if (this.placementElementsFrmArr && this.placementElementsFrmArr.length > 0) {
            var elementPlacements = this.GetCurrentPlacementElements();
            return elementPlacements.filter(function (placement) { return tempMessageId === placement.TempMessageId &&
                (!elementDetailIds || elementDetailIds.some(function (ed) { return ed === placement.ElementDetailId; })); });
        }
        return undefined;
    };
    /**
     * Send in the placementElement and finds the correct market based on the tempMessageId.
     * @param placementElement
     */
    PlacementElementEditComponent.prototype.deriveMessagePlacementCalculationModel = function (placementElement) {
        var calcModel = {
            MessagePlacements: undefined,
            PlacementElements: [placementElement],
            ReturnLocationBalance: false,
            PatternGroupInfo: this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, placementElement.TempMessageId),
            PlacementGroups: undefined,
            DefaultFulfillmentOperator: this.campaignFulfillmentType,
            DefaultFulfillmentQuantity: this.campaignFulfillmentQty
        };
        calcModel.PatternGroupInfo.StartDate = this.parent.value.StartDate;
        calcModel.PatternGroupInfo.EndDate = this.parent.value.EndDate;
        return calcModel;
    };
    PlacementElementEditComponent.prototype.hasInvalidField = function (placementElement) {
        var invalid = false;
        //main message
        if (placementElement.FulfillmentOperand === null || placementElement.FulfillmentOperand < 0) {
            invalid = true;
        }
        if (!invalid) {
            invalid = placementElement.PlacementElementDetails.some(function (item) {
                return item.Quantity === null || item.Quantity < 0;
            });
        }
        return invalid;
    };
    PlacementElementEditComponent.prototype.hasQtyWarning = function (elementPlcCtrl) {
        var val = elementPlcCtrl.value;
        if (val !== undefined && Number(val) < 1) {
            return true;
        }
        return false;
    };
    PlacementElementEditComponent.prototype.removePlacement = function (index) {
        this.placementElementsFrmArr.removeAt(index);
        this.placementElementsFrmArr.markAsDirty();
    };
    PlacementElementEditComponent.prototype.clearHolderSelection = function (placement) {
        placement.get("FixtureGroupName").setValue("");
        placement.get("HolderName").setValue("");
        placement.get("HolderId").setValue(null);
        this.placementElementsFrmArr.markAsDirty();
    };
    PlacementElementEditComponent.prototype.selectElementHolder = function (placement) {
        var _this = this;
        var param = {
            Placements: [],
            SingleSelect: true
        };
        return this.ipsModal.displayTemplateScrollable(PlacementSearchModalComponent, {
            resolve: param
        }, { backdrop: "static", centered: true })
            .then(function (response) {
            var selectedHolder = response.FixtureGroups[0].Holders[0];
            placement.get("FixtureGroupName").setValue(response.FixtureGroups[0].Name);
            placement.get("HolderName").setValue(selectedHolder.Name);
            placement.get("HolderId").setValue(selectedHolder.Id);
            _this.clearSpaceSelection(placement);
            placement.get("SignPlanMessage").setValue("");
            placement.get("SignPlanWidth").setValue(null);
            _this.placementElementsFrmArr.markAsDirty();
        }, function (rejectReason) {
            // Do nothing, this is here to prevent console error.
        });
    };
    PlacementElementEditComponent.prototype.getSpaceButtonLabel = function (placement) {
        if (placement && placement.value) {
            if (placement.value.Spaces && placement.value.Spaces.length > 1) {
                return placement.value.Spaces.length + " Spaces Selected";
            }
            else {
                if (placement.value.Spaces && placement.value.Spaces.length === 1) {
                    return placement.value.Spaces[0].Name;
                }
                else {
                    return "Choose Space(s)";
                }
            }
        }
        else {
            return "Choose Space(s)";
        }
    };
    PlacementElementEditComponent.prototype.clearSpaceSelection = function (placement) {
        placement.setControl("Spaces", this.fb.array([]));
        placement.get("SpaceId").setValue(null);
        placement.markAsDirty();
    };
    PlacementElementEditComponent.prototype.chooseSpace = function (placement) {
        var _this = this;
        var selectedSpaces = placement.value.Spaces && placement.value.Spaces.map(function (space) { return space.Id; });
        var param = {
            title: "Choose Space(s)",
            items: this.spaces.filter(function (space) { return space.Id > 0; }),
            labelField: "Name",
            multiSelect: true,
            selectedIds: selectedSpaces
        };
        return this.ipsModal.displayTemplateScrollable(SelectListComponent, param, { backdrop: "static", centered: true }).then(function (response) {
            var spaceGroups = response.map(function (space) {
                return _this.fb.group(space);
            });
            placement.setControl("Spaces", _this.fb.array(spaceGroups));
            placement.markAsDirty();
        }, function (rejectReason) {
            // Do nothing, this is here to prevent console error.
        });
    };
    PlacementElementEditComponent.prototype.showLocationBalanceModal = function (toolTip, placementElementCtrl, messageTempId, locationCount, locSubGroupId) {
        var _this = this;
        toolTip.close();
        //Do nothing if locationCount is undefined
        if (locationCount === undefined) {
            return;
        }
        //Modal should be shown even if locationCount = 0
        if (locationCount === 0) {
            this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
                resolve: { search: "listbylocation", listOfLocationIds: [] }
            }, {
                windowClass: "no-list-group-item-interaction"
            });
        }
        else {
            var placementElement = placementElementCtrl.value;
            var balanceCalcModel = this.deriveMessagePlacementCalculationModel(placementElement);
            //Remove un-needed spaces
            if (balanceCalcModel.PlacementElements) {
                balanceCalcModel.PlacementElements = Object.assign([], balanceCalcModel.PlacementElements);
                balanceCalcModel.PlacementElements.forEach(function (item) {
                    // delete item.Spaces;
                });
            }
            var locationListRequest = {
                MessagePlacementCalculationDetail: balanceCalcModel,
                TargetMessageTempId: messageTempId,
                ElementDetailId: placementElement.ElementDetailId,
                LocationSubGroupId: locSubGroupId
            };
            this.promotionMessageService.calculateElementLocationBalanceList(locationListRequest).then(function (locationIdList) {
                _this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
                    resolve: { search: "listbylocation", listOfLocationIds: locationIdList.map(Number) },
                }, {
                    windowClass: "no-list-group-item-interaction"
                });
            });
        }
    };
    PlacementElementEditComponent.prototype.onMessageNameChanged = function (messageInfo) {
        var _this = this;
        this.placementElementsFrmArr.controls.forEach(function (elementHeader) {
            var placements = _this.getMsgPlacementElementsCtrl(elementHeader);
            placements.controls.some(function (placementControl, placeIndex) {
                var placementElement = placementControl.value;
                if (placementElement.TempMessageId === messageInfo.TempMessageId) {
                    placementControl.get("MessageName").patchValue(messageInfo.MessageName);
                    // Should only be one placement per message, break out.
                    return true;
                }
                // tell the 'some' loop to keep going.
                return false;
            });
        });
    };
    PlacementElementEditComponent.prototype.prepareSaveModel = function (promoSave) {
        var elements = this.placementElementsFrmArr.value;
        elements.forEach(function (element) {
            if (element.MessageElementPlacements) {
                element.MessageElementPlacements.forEach(function (messageElementPlacement) {
                    if (messageElementPlacement && messageElementPlacement.Spaces) {
                        messageElementPlacement.SpaceList = messageElementPlacement.Spaces.map(function (space) { return space.Id; });
                    }
                });
            }
        });
        promoSave.PatternGroups.forEach(function (item) {
            var tempId = item.MessagePatterns[0].TempMessageId;
            var elementsByMessage = elements.filter(function (element) { return element.MessageElementPlacements.some(function (placement) { return placement.TempMessageId === tempId; }); }).map(function (element) {
                var newElt = Object.assign({}, element);
                // newElt.MessageElementPlacements.forEach(ep => ep.Spaces = undefined);
                return newElt.MessageElementPlacements.find(function (placement) { return placement.TempMessageId === tempId; });
            });
            item.MessagePatterns[0].PlacementElements = elementsByMessage;
        });
    };
    /**
     * Removes all existing placement elements
     */
    PlacementElementEditComponent.prototype.clearPlacements = function () {
        var elementHeaders = this.placementElementsFrmArr;
        if (elementHeaders && elementHeaders.length > 0) {
            while (elementHeaders.length > 0) {
                elementHeaders.removeAt(0);
            }
        }
    };
    PlacementElementEditComponent.prototype.saveCustomData = function (saveModel) {
        var promises = [];
        this.customDataFieldContainers.forEach(function (container) {
            var plcElement = container.parent.value;
            for (var i = 0; i < saveModel.PatternGroups.length; i++) {
                var patternGroup = saveModel.PatternGroups[i];
                var match = patternGroup.MessagePatterns.find(function (mp) { return mp.MessageName === plcElement.MessageName; });
                if (match) {
                    var matchPlcElement = match.PlacementElements.find(function (pe) { return pe.ElementDetailName === plcElement.ElementDetailName && pe.ElementDetailHeight === plcElement.ElementDetailHeight && pe.ElementDetailWidth === plcElement.ElementDetailWidth; });
                    if (matchPlcElement.Id) {
                        container.linkId = matchPlcElement.Id;
                        promises.push(container.save(matchPlcElement.Id));
                        break;
                    }
                }
            }
        });
        return promises;
    };
    return PlacementElementEditComponent;
}());
export { PlacementElementEditComponent };
