var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../../environments/environment";
var LocationBySpaceService = /** @class */ (function (_super) {
    __extends(LocationBySpaceService, _super);
    function LocationBySpaceService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("Location", "Profile", "v1");
        _this.route = _this.urlApiBaseProductPlusVersion + "Space/{id}/Location";
        return _this;
    }
    LocationBySpaceService.prototype.headBySpace = function (id) {
        return this.headByRoute(this.route.replace("{id}", id));
    };
    // Override ipsBaseWebService.search method
    LocationBySpaceService.prototype.search = function (searchInfo, route) {
        return this.searchByRelation(searchInfo, "Space/" + searchInfo.id + "/Location/Search");
    };
    return LocationBySpaceService;
}(IpsBaseWebService));
export { LocationBySpaceService };
