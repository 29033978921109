import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService} from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";

@Injectable()
export class SpaceService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Space", "Profile", "v1");
    }
}
