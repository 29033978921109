var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var CustomDataService = /** @class */ (function (_super) {
    __extends(CustomDataService, _super);
    function CustomDataService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("CustomData", "Tools", "v1");
        return _this;
    }
    CustomDataService.prototype.getCustomDataByArea = function (areaId, populateOptionList) {
        populateOptionList = populateOptionList ? true : false;
        var route = this.urlApiBaseProductPlusVersion + "Area/" + areaId + "/CustomData?populateOptionList=" + populateOptionList;
        return this.getByRoute(route);
    };
    CustomDataService.prototype.getCustomData = function () {
        var route = this.urlApiBaseProductPlusVersion + "CustomData";
        return this.getByRoute(route);
    };
    CustomDataService.prototype.isFieldNameUsed = function (name) {
        var route = this.urlApiBaseProductPlusVersion + "CustomData/used?name=" + encodeURIComponent(name);
        return this.getByRoute(route);
    };
    return CustomDataService;
}(IpsBaseWebService));
export { CustomDataService };
