import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { PromotionHeaderModel, PromotionSummaryModel, PromotionMoveModel } from "..";

@Injectable()
export class PromotionService extends IpsBaseWebService {

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Promotion", "Campaign", "v1");
    }

    public headByCampaignId(campaignId: string): Promise<number> {
        let route = `Campaign/${campaignId}/Promotion`;
        return this.headByRoute(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public getPromotionHeader(promotionId: string): Promise<PromotionHeaderModel> {
        let route = `PromotionHeader/${promotionId}`;
        return this.getByRoute<PromotionHeaderModel>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public getPromotionSummary(campaignId: string): Promise<PromotionSummaryModel> {
        let route = `Campaign/${campaignId}/PromotionSummary`;
        return this.getByRoute<PromotionSummaryModel>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public checkPromotionName(promo: PromotionHeaderModel): Promise<boolean> {
        let route = `Campaign/PromotionName/available`;
        return this.postByRoute<boolean>(`${this.urlApiBaseProductPlusVersion}${route}`, promo);
    }

    public movePromotion(promo: PromotionMoveModel): Promise<boolean> {
        let route = `${this.urlApiBaseProductPlusVersion}Promotion/Move`;
        return this.put<boolean>(promo, undefined, route);
    }
}
