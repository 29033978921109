import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ElementRef, ViewChild } from "@angular/core";
import { FormGroup, FormControl, FormArray, FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { LoggingService, ActiveProfileService } from "imagine-ui-ng-core";
import { CustomDataService } from "../service/custom-data.service";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { CustomerRequestService } from "../service/customer-request.service";
import { AreaService } from "../service/area.service";
import { AreaType } from "../../shared/type/AreaType";
import { StateService } from "@uirouter/angular";
import { BrandSettingService } from "../../imagine-ui-ng-store-profile";

@Component({
  selector: "app-request-form",
  templateUrl: "./request-form.component.html",
  styleUrls: ["./request-form.component.scss"]
})
export class RequestFormComponent implements OnInit {
    @ViewChild("fileInput") public fileInput: ElementRef;
    public requestForm: FormGroup;
    public fieldValues: FormArray;
    public promise: Promise<void>;
    public formFields = [];
    public customDataGroupList = [];
    uploadedFileName: string;
    file: any; //file input control
    hasfileSizeError = false;
    loading = true;
    loaded = false;
    public instruction;

    constructor(private fb: FormBuilder, private requestFormFieldService: CustomDataService, private translateService: TranslateService, private areaService: AreaService,  private stateService: StateService,
        private logger: LoggingService, private activeProfileService: ActiveProfileService, private ipsMessage: IpsMessageService, private customerReqService: CustomerRequestService, private brandSettingService: BrandSettingService) {

    }

    ngOnInit() {
        this.createForm();
        this.brandSettingService.getSettingByName("requestFormInstruction").then(res => this.instruction = res);
        this.getcustomDataGroupList("RequestForm");
    }

    private getData() {

        this.promise = this.requestFormFieldService.getCustomDataByArea("RequestForm", true).then((response) => {
            Object.assign(this.formFields, response);

            //Figure out field width
            for (let item of this.formFields) {
                item["CustomClass"] = [item.FieldType === "Text" && item.SelectionRule === "Multiple" ? "col-12" : "col-6"];
                item["Values"] = [];
            }

            //add fields to group
            this.customDataGroupList.forEach(group => {
                group.Fields = [...this.formFields.filter(field => field.CustomDataGroupId === group.Id)];
                group.Ordinal = group.Fields[0] ? group.Fields[0].Ordinal : 0;
            });

            //remove field group has no fields
            for (let i = 0; i < this.customDataGroupList.length; i++) {
                if (this.customDataGroupList[i].Fields.length === 0) {
                    this.customDataGroupList.splice(i, 1);
                }

            }

            this.customDataGroupList.sort((a, b) => a.Ordinal > b.Ordinal ? 1 : -1);
            this.loaded = true;
            this.loading = false;

        });
    }

    private getcustomDataGroupList(areaId: AreaType) {
        this.areaService.getCustomDataGroup(areaId).then(result => {
            this.customDataGroupList = result;
            this.getData();
        });
    }

    private createForm() {
        this.fieldValues = new FormArray([]);

        this.requestForm = new FormGroup({
            FieldValues: this.fieldValues
        });
    }

    submitRequest() {
        const fields = this.fieldValues.value;
        let requestData = {};
        fields.forEach(x => {
            if (x.Items) {
                const id = x.Items[0].CustomDataId;
                const fieldName = this.formFields.filter(f => f.Id === id)[0].Name;
                let value = x.Items.map(i => i.Name).join(",");
                value = value === "" ? undefined : value;
                requestData[fieldName] = value;
            } else {
                requestData[x.Name] = x.Text || x.Date || x.Number || x.email;
            }
        });
        const req = { BusinessIdentity: this.activeProfileService.businessIdentity, RequestJson: JSON.stringify(requestData) };
        return this.customerReqService.submitRequest(req, this.file);
    }

    submitRequestPrompt() {
        return this.ipsMessage.waitForWork({ body: "SUBMIT_REQUEST", workFunction: () => this.submitRequest(), progressMessage: "SAVING" }).then((result: boolean) => {
            this.requestForm.markAsPristine();
            this.stateService.go("main.requestform", {}, { reload: true });
        });
    }

    fileOpen() {
        this.fileInput.nativeElement.click();
    }

    fileSelected(event) {
        this.file = event.currentTarget.files[0];
        this.uploadedFileName = this.file.name;
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        if (this.file.size > 10458760) { //10M
            this.hasfileSizeError = true;
        } else {
            this.hasfileSizeError = false;
        }

    }

    removeFile() {
        this.file = null;
        this.uploadedFileName = "";
    }
}
