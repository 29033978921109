var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../../environments/environment";
var FixtureByLocationSumService = /** @class */ (function (_super) {
    __extends(FixtureByLocationSumService, _super);
    function FixtureByLocationSumService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("Fixture", "Profile", "v1");
        return _this;
    }
    // Override the ipsBaseWebService.search method.
    FixtureByLocationSumService.prototype.search = function (searchInfo, route) {
        return this.searchByRelation(searchInfo, "Fixture/SearchLocationFixtureSum");
    };
    return FixtureByLocationSumService;
}(IpsBaseWebService));
export { FixtureByLocationSumService };
