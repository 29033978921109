import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UIRouterModule } from "@uirouter/angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CgBusyModule } from "angular-busy2";
import { TranslateModule } from "@ngx-translate/core";
import { ImagineUiNgCoreModule } from "imagine-ui-ng-core";
import { ImagineUiNgSecurityModule } from "imagine-ui-ng-security";
import { ImagineUiNgQuickStartModule } from "imagine-ui-ng-quick-start";
import { ImagineUiNgMessagingModule } from "imagine-ui-ng-messaging";
import { ListSearchModule } from "imagine-ui-ng-list-search";
import { IpsModalModule } from "imagine-ui-ng-modal";
import { SharedModule } from "../shared/shared.module";

import { CUSTOMDATA_BASE_STATES } from "./imagine-ui-ng-custom-data.futureStates";
import { CustomDataSearchComponent } from "./custom-data/custom-data-search/custom-data-search.component";
import { CustomDataEditComponent } from "./custom-data/custom-data-edit/custom-data-edit.component";
import { CustomDataService, AreaService, FieldNameValidator } from "../imagine-ui-ng-custom-data";
import { RequestFormComponent } from "./request-form/request-form.component";

@NgModule({
    imports: [
        CommonModule,
        UIRouterModule.forChild({ states: CUSTOMDATA_BASE_STATES }),
        TranslateModule.forChild(),
        TypeaheadModule.forRoot(),
        CgBusyModule,
        InfiniteScrollModule,
        TranslateModule.forChild(),
        ImagineUiNgQuickStartModule,
        ImagineUiNgSecurityModule,
        ListSearchModule,
        NgbModule,
        BsDatepickerModule,
        IpsModalModule,
        ImagineUiNgMessagingModule,
        ReactiveFormsModule,
        ImagineUiNgCoreModule,
        SharedModule,
        FormsModule
    ],
    declarations: [CustomDataSearchComponent, CustomDataEditComponent, FieldNameValidator, RequestFormComponent],
    providers: [CustomDataService, AreaService]
})
export class ImagineUiNgCustomDataModule { }
