var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl } from "@angular/forms";
import { FormControlSuperclass } from "./form-control-superclass";
/**
 * Extend this when creating a form control that simply wraps an existing form control, to reduce a lot of boilerplate. **Warning:** You _must_ include a constructor in your subclass.
 *
 * Example when you don't need to modify the wrapped control's value:
 * ```ts
 * @Component({
 *   template: `<input [formControl]="formControl">`,
 *   providers: [provideValueAccessor(StringComponent)],
 * })
 * class StringComponent extends WrappedFormControlSuperclass<string> {
 *   // This looks unnecessary, but is required for Angular to provide `Injector`
 *   constructor(injector: Injector) {
 *     super(injector);
 *   }
 * }
 * ```
 *
 * Example when you need to modify the wrapped control's value:
 * ```ts
 * @Component({
 *   template: `<input type="datetime-local" [formControl]="formControl">`,
 *   providers: [provideValueAccessor(DateComponent)],
 * })
 * class DateComponent extends WrappedFormControlSuperclass<Date, string> {
 *   // This looks unnecessary, but is required for Angular to provide `Injector`
 *   constructor(injector: Injector) {
 *     super(injector);
 *   }
 *
 *   protected innerToOuter(value: string): Date {
 *     return new Date(value + "Z");
 *   }
 *
 *   protected outerToInner(value: Date): string {
 *     if (value === null) {
 *       return ""; // happens during initialization
 *     }
 *     return value.toISOString().substr(0, 16);
 *   }
 * }
 * ```
 */
var WrappedFormControlSuperclass = /** @class */ (function (_super) {
    __extends(WrappedFormControlSuperclass, _super);
    function WrappedFormControlSuperclass(injector) {
        var _this = _super.call(this, injector) || this;
        /** Bind this to your inner form control to make all the magic happen. */
        _this.formControl = new FormControl();
        _this.subscribeTo(_this.formControl.valueChanges, function (value) {
            _this.emitOutgoingValue(_this.innerToOuter(value));
        });
        _this.formControl.markAsTouched = function () {
            _this.onTouched();
        };
        return _this;
    }
    /** Called as angular propagates values changes to this `ControlValueAccessor`. You normally do not need to use it. */
    WrappedFormControlSuperclass.prototype.handleIncomingValue = function (value) {
        this.formControl.setValue(this.outerToInner(value), { emitEvent: false });
    };
    /** Called as angular propagates disabled changes to this `ControlValueAccessor`. You normally do not need to use it. */
    WrappedFormControlSuperclass.prototype.setDisabledState = function (isDisabled) {
        if (isDisabled) {
            this.formControl.disable({ emitEvent: false });
        }
        else {
            this.formControl.enable({ emitEvent: false });
        }
        _super.prototype.setDisabledState.call(this, this.isDisabled);
    };
    /** Override this to modify a value coming from the outside to the format needed within this component. */
    WrappedFormControlSuperclass.prototype.outerToInner = function (value) {
        return value;
    };
    /** Override this to modify a value coming from within this component to the format expected on the outside. */
    WrappedFormControlSuperclass.prototype.innerToOuter = function (value) {
        return value;
    };
    /**
     * Return the inner forms validation results if there are any.
     * @param c - This will be the control the consuming form sees. c.errors will contain errors from any validators
     * the user provided on top of any validators your wrapped controls use. we don't need to return those, only our
     * inner ones.
     */
    WrappedFormControlSuperclass.prototype.validate = function (c) {
        return this.formControl.errors;
    };
    return WrappedFormControlSuperclass;
}(FormControlSuperclass));
export { WrappedFormControlSuperclass };
