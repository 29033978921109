<div class="ips-flex-loading-box mb-4">
    <div class="content">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
        <span class="d-block" translate>xmpie add to cart</span>
    </div>
</div>

<ng-template #errorTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Cuztomization Error</h4>
    </div>
    <div class="modal-body px-4 text-large" *ngIf="cartPODModel && cartPODModel.ItemKey">
        An error has occurred during the customization process.  You can try again now, or return to your product listing.
    </div>
    <div class="modal-body px-4 text-large" *ngIf="!cartPODModel || !cartPODModel.ItemKey">
        An error has occurred during the customization process.  Please try again later.
    </div>
    <div class="modal-footer">
        <button *ngIf="cartPODModel && cartPODModel.ItemKey" type="button" class="btn btn-lg btn-outline-danger" (click)="retry()">
            Retry Now
        </button>
        <button type="button" class="btn btn-lg btn-outline-secondary" (click)="cancel()">
            Try Again Later
        </button>
    </div>
</ng-template>
