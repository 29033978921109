import { Component, OnInit, Input } from "@angular/core";
import { DatePipe } from "@angular/common";
import {SearchCardUIModel } from "../index";

interface LocSearchUiModel extends SearchCardUIModel {
    isDate: boolean;
    isPendingAssignment: boolean;
}

@Component({
    selector: "app-search-cards",
    templateUrl: "./search-cards.component.html",
    styleUrls: ["./search-cards.component.scss"],
    providers: [DatePipe]
})
export class SearchCardsComponent implements OnInit {

    @Input() CardData: SearchCardUIModel[];
    @Input() LocationId: number;
    @Input() EditOrViewPermission: string;
    @Input() FullImage: string;
    @Input() ThumbImage: string;
    @Input() SearchText: string;

    public DisplayData: LocSearchUiModel[];
    public Notes: SearchCardUIModel;

    constructor(private datePipe: DatePipe) { }

    ngOnInit() {

        this.ThumbImage = this.ThumbImage || this.FullImage;

        this.Notes = this.CardData.find((q) => q.Key === "NOTES");

        this.DisplayData = this.CardData.filter((q) => q.Key !== "NOTES").map((data: LocSearchUiModel) => {

            //Need to only match dates that are in the correct input format
            let re = new RegExp("^([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2})$");
            if (re.test(data.Value as string)) {
                //Now check that it is a valid date
                let date = Date.parse(data.Value as string);
                if (!isNaN(date)) {
                    data.Value = this.datePipe.transform(data.Value, "mediumDate");
                    data.isDate = true;
                }
            }

            data.isPendingAssignment = !data.Value && data.Type === "LocationGroup";
            return data;
        });
    }

    public getCardSref(cardtype: string) {
        switch (cardtype) {
            case "Location":
                return "main.storeProfileLocation.edit";
            case "LocationGroup":
                return "main.storeProfileLocationGroup.search";
            case "FixtureType":
                return "main.storeProfileFixtureGroup.view";
            case "Order":
                return "";
            default:
                return "main.storeProfileLocation.view";
        }
    }

    isArray(data) {
        return Array.isArray(data);
    }
}
