<ips-batch-processing #batchProcessingComponent [applicationType]="'Ecomm'" [showDownloads]="true" [showMemo]="true" [showCompare]="true" (fileProcessingPanelToggled)="showFileProcessingPanel($event)" [showInstructions]="true" [instructionsHref]="'/assets/docs/FileProcessor_InstructionManual.pdf'" title="{{ 'FILE_PROCESSING' | translate }}">
    <div download-panel>
        <form name="downloadForm" novalidate #downloadForm="ngForm" role="form">
            <div class="fileProcess-chooseFile-imgn">
                <div class="form-row">
                    <div id="selectFile" class="col-12 col-md-6 col-xl-5 mb-3">
                        <span class="file-processing-box">
                            <select name="fileTypes" id="fileTypes" [(ngModel)]="userSelectedDownloadFileType"
                                    class="form-control dropdownBtn form-control-lg ellipsis"
                                    (ngModelChange)="selectDownloadFileType()"
                                    appValidation required>
                                <option value="" translate>CHOOSE_DOWNLOAD_FILE</option>
                                <ng-template ngFor let-item [ngForOf]="downloadFileCategories">
                                    <optgroup label="{{item.text | translate}}">
                                        <option *ngFor="let option of item.options" [ngValue]="option.value"> {{option.text}} </option>
                                    </optgroup>
                                </ng-template>
                            </select>
                        </span>
                    </div>
                    <div class="col-12 col-md-6 col-xl-5 mb-3" *ngIf="userSelectedDownloadFileType && downloadOptions.length > 1 || userSelectedDownloadFileType === 'LocProdRestrict'">
                        <select name="dOptions" id="dOptions" [(ngModel)]="userSelectedDownloadOption"
                                class="form-control dropdownBtn form-control-lg ellipsis"
                                (ngModelChange)="downloadOptionChange()"
                                appValidation required>
                            <option *ngFor="let item of downloadOptions" [ngValue]="item.value">{{item.text}}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6 col-xl-5 mb-3" *ngIf="showDownloadInput()">
                        <div class="input-group ips-textarea">
                            <input type="text" class="form-control form-control-lg"
                                   #downloadInput="ngModel" name="downloadInput" id="downloadInput"
                                   [(ngModel)]="inputId" [ngModelOptions]="{ blur: 200 }"
                                   [ngbTypeahead]="searchFunctionFactory('downloadInput')" [resultFormatter]="inputFormatter" [inputFormatter]="inputFormatter"
                                   appValidation required> <!--placeholder="{{ placeholderText | translate}}" - removed to fix IE bug -->
                            <div class="input-group-append">
                                <button class="btn btn-default btn-lg form-control-lg fileProcess-filter" type="button" (click)="showSelectionModal('downloadInput')"><i class="fa fa-search fa-lg"></i></button>
                            </div>
                        </div>
                        <ips-show-errors [control]="downloadInput" [errorMessages]="getErrorMessages('downloadInput')"></ips-show-errors>
                    </div>
                    <div class="col-12 col-md-6 col-xl-5 mb-3" *ngIf="showDownloadInput2()">
                        <div class="input-group ips-textarea">
                            <input type="text" class="form-control form-control-lg"
                                   #downloadInput2="ngModel" name="downloadInput2" id="downloadInput2"
                                   [(ngModel)]="inputId2" [ngModelOptions]="{ blur: 200 }"
                                   [ngbTypeahead]="searchFunctionFactory('downloadInput2')" [resultFormatter]="inputFormatter" [inputFormatter]="inputFormatter"
                                   appValidation required>
                            <div class="input-group-append">
                                <button class="btn btn-default btn-lg form-control-lg fileProcess-filter" type="button" (click)="showSelectionModal('downloadInput2')" [disabled]="!inputId"><i class="fa fa-search fa-lg"></i></button>
                            </div>
                        </div>
                        <ips-show-errors [control]="downloadInput2" [errorMessages]="getErrorMessages('downloadInput2')"></ips-show-errors>
                    </div>
                    <!--Download Detail options. Currently assuming we show these OR downloadOptions, but not both.-->
                    <div class="col-12 col-md-6 col-xl-5 mb-3" *ngIf="userSelectedDownloadDetail && downloadDetails.length > 1">
                        <select name="dDetails" id="dDetails" [(ngModel)]="userSelectedDownloadDetail"
                                class="form-control dropdownBtn form-control-lg ellipsis"
                                appValidation required>
                            <option *ngFor="let item of downloadDetails" [ngValue]="item.value">{{item.text}}</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6 col-xl-5 mb-3" *ngIf="showDownloadInput3()">
                        <div class="input-group ips-textarea">
                            <input type="text" class="form-control form-control-lg border-right-0"
                                   #downloadInput3="ngModel" name="downloadInput3" id="downloadInput3"
                                   [(ngModel)]="inputId3" [ngModelOptions]="{ blur: 200 }"
                                   [ngbTypeahead]="searchFunctionFactory('downloadInput3')" [resultFormatter]="inputFormatter" [inputFormatter]="inputFormatter"
                                   appValidation>
                            <div class="input-group-append">
                                <span class="input-group-text" [ngClass]="{'disabled': !inputId}">
                                    <button *ngIf="showInput3Remove()" type="button" class="btn btn-default ips-btn-remove" (click)="removeInput3()" [disabled]="!inputId"><i class="fa fa-remove fa-lg"></i></button>
                                </span>
                                <button class="btn btn-default btn-lg form-control-lg fileProcess-filter" type="button" (click)="showSelectionModal('downloadInput3')" [disabled]="!inputId"><i class="fa fa-search fa-lg"></i></button>
                            </div>
                        </div>
                        <ips-show-errors [control]="downloadInput3" [errorMessages]="getErrorMessages('downloadInput3')"></ips-show-errors>
                    </div>
                    <!--Date Range option-->
                    <div class="col-12 col-md-6 col-xl-5 mb-3" *ngIf="showDateRange">
                        <app-ips-date-range [startDate]="startDate" [endDate]="endDate" [daySpan]="30" [showLabel]="false" (rangeChanged)="changeDateRange($event)"></app-ips-date-range>
                    </div>
                    <div class="col-12 col-md-6 offset-xl-0 col-xl-2 mb-3" [hidden]="!userSelectedDownloadFileType" [ngClass]="{'offset-md-6' : addSubmitOffset()}">
                        <button (click)="downloadSubmitPrompt()" [disabled]="disableDownloadSubmit()" class="btn btn-default btn-lg btn-block ng-scope" translate>SUBMIT</button>

                    </div>
                </div>
            </div>
        </form>
    </div>
</ips-batch-processing>

