var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { ShipMethodModel, ShipMethodPreferenceModel } from "../model/ShipMethodModels";
var ShipMethodPreferenceService = /** @class */ (function (_super) {
    __extends(ShipMethodPreferenceService, _super);
    function ShipMethodPreferenceService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.init("ShipMethodPreference", "Shop", "v1");
        return _this;
    }
    ShipMethodPreferenceService.prototype.searchByRelation = function (searchInfo, route) {
        return this.search(searchInfo, route);
    };
    ShipMethodPreferenceService.prototype.search = function (searchInfo, route) {
        var _this = this;
        var searchText = searchInfo.searchText || "";
        var returnPromise = new Promise(function (resolve, reject) {
            var getRoute = _this.urlApiBaseProductPlusVersion + "ShipMethod";
            _this.getByRoute(getRoute).then(function (data) {
                data = data.filter(function (item) { return !searchText || item.DisplayName.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1; });
                data = data.map(function (item) {
                    var model = new ShipMethodModel();
                    Object.assign(model, item);
                    return model;
                });
                data = data.sort(_this.compareShipMethod);
                var returnVal = {
                    ResultList: data,
                    ResultChunkAttributes: {
                        "Index": 0,
                        "TotalRecords": data.length,
                        "RecordCount": data.length
                    }
                };
                resolve(returnVal);
            });
        });
        return returnPromise;
    };
    ShipMethodPreferenceService.prototype.save = function (preferences) {
        var saveRoute = this.urlApiBaseProductPlusVersion + "ShipMethodPreferences";
        return this.put(preferences, null, saveRoute);
    };
    ShipMethodPreferenceService.prototype.get = function () {
        var _this = this;
        var getRoute = this.urlApiBaseProductPlusVersion + "ShipMethodPreferences";
        var promise = new Promise(function (resolve, reject) {
            _this.getByRoute(getRoute).then(function (data) {
                data = data.map(function (item) {
                    var model = new ShipMethodPreferenceModel(null);
                    Object.assign(model, item);
                    return model;
                });
                data = _this.applyDefaultSort(data);
                resolve(data);
            }, function (response) {
                reject(response.Message);
            });
        });
        return promise;
    };
    ShipMethodPreferenceService.prototype.getShipMethods = function () {
        var getRoute = this.urlApiBaseProductPlusVersion + "ShipMethod";
        return this.getByRoute(getRoute);
    };
    ShipMethodPreferenceService.prototype.compareShipMethod = function (a, b) {
        if (a.DisplayName < b.DisplayName) {
            return -1;
        }
        else if (a.DisplayName > b.DisplayName) {
            return 1;
        }
        else {
            return 0;
        }
    };
    ShipMethodPreferenceService.prototype.applyDefaultSort = function (preferences) {
        var _this = this;
        return preferences.sort(function (a, b) {
            return _this.compareShipMethod(a.ShipMethod, b.ShipMethod);
        });
    };
    return ShipMethodPreferenceService;
}(IpsBaseWebService));
export { ShipMethodPreferenceService };
