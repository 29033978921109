var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from "@angular/core";
import { WrappedFormControlSuperclass } from "../wrapped-form-control-superclass";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
var MultiTypeInputComponent = /** @class */ (function (_super) {
    __extends(MultiTypeInputComponent, _super);
    function MultiTypeInputComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.dateOptions = {};
        return _this;
    }
    MultiTypeInputComponent.prototype.ngOnInit = function () {
        if (this.placeholderPrefix) {
            switch (this.inputType) {
                case "Date":
                    this.placeholder = this.placeholderPrefix + "_DATE";
                    break;
                case "Number":
                    this.placeholder = this.placeholderPrefix + "_NUMBER";
                    break;
                default:
                    this.placeholder = this.placeholderPrefix + "_NAME";
                    break;
            }
        }
    };
    return MultiTypeInputComponent;
}(WrappedFormControlSuperclass));
export { MultiTypeInputComponent };
