import { Component, OnInit } from "@angular/core";
import { LocationService } from "../../service/location/location.service";
import { FixtureByLocationService } from "../../service/fixture-by-location.service";
import { SearchInfo, ListSearchHelper, AddressModel, ActiveProfileService, FileDownloadService } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import {
    LocationModel, LocationFixtureModel, FixtureModel, LocationGroupSubGroupModel, LocationGroupModel, LocationGroupByLocationService, LocationStatusModel,
    AlternateAddressModel
} from "../../index";
import { Transition } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { AuthService } from "imagine-ui-ng-security";
import { CampaignService } from "../../../imagine-ui-ng-campaign";
import { SearchModalComponent } from "../../../shared/index";
import { IpsModalService } from "imagine-ui-ng-modal";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";

interface UILocationFixtureModel extends LocationFixtureModel {
    Label: string;
}

interface UILocationGroupSubGroupModel extends LocationGroupSubGroupModel {
    LocationGroupLabel: string;
    Id: number;
}

@Component({
    selector: "app-location-view",
    templateUrl: "./location-view.component.html",
    styleUrls: ["./location-view.component.scss"]
})
export class LocationViewComponent implements OnInit {

    private dataService: LocationService;
    private fixtureDataService: FixtureByLocationService;
    private fixtureSearchByLocation: ListSearchHelper;
    private locationGroupDataService: LocationGroupByLocationService;
    private locationGroupSearchByLocation: ListSearchHelper;
    private previousId: string;
    private previousFilterOption: string;

    public optionList: HTMLOptionElement[];
    public selectedOption: HTMLOptionElement;
    public location: LocationModel;
    public address: AddressModel;
    public campaignShippingAddress = "LOCATION_ADDRESS";
    public promise: Promise<void>;
    public qrFixturePromise: Promise<{}>;
    public qrLocationGroupPromise: Promise<{}>;
    public loaded: boolean;
    public busy: boolean;
    public qrFixture: ListSearchHelper;
    public qrFixtureSearch: string;
    public qrLocationGroup: ListSearchHelper;
    public qrLocationGroupSearch: string;
    public openDateEmptyWarning = "OPEN_DATE_EMPTY_WARNING_MESSAGE";
    public downloadSignPlanError = "ERROR_DOWNLOADING_SIGN_PLAN";
    public upDownPlanError = "ERROR_DOWNLOADING_UP_DOWN";
    public openDateAfterSuspendDateWarning = "OPEN_DATE_AFTER_SUSPEND_DATE_WARNING_MESSAGE";
    public suspendDateBeforeOpenDateWarning = "SUSPEND_DATE_BEFORE_OPEN_DATE_WARNING_MESSAGE";
    public addressInvalidWarning = "ADDRESS_INVALID";
    public addressNotValidated = "ADDRESS_NOT_VALIDATED";
    public information = "INFORMATION_UPPERCASE";
    public showOpenDateEmptyWarning = false;
    public showOpenDateAfterSuspendDateWarning = false;
    public showSuspendDateBeforeOpenDateWarning = false;
    public translatedTexts: TranslatedTexts;
    public locationStatusInfo: LocationStatusModel;

    private locationId: number;
    public fullImage: string;
    public thumbImage: string;

    public enableSignPlan = false;
    public enableUpDownReport = false;

    constructor(
        private transition: Transition,
        private locationService: LocationService,
        fixtureByLocationService: FixtureByLocationService,
        private ipsMessage: IpsMessageService,
        listSearchHelper: ListSearchHelper,
        locationGroupByLocationService: LocationGroupByLocationService,
        private translateService: TranslateService,
        private activeProfileService: ActiveProfileService,
        private campaignService: CampaignService,
        private ipsModal: IpsModalService,
        private authService: AuthService,
        private downloadService: FileDownloadService,
        private settingsService: GeneralSettingsService) {

        this.dataService = locationService;
        this.fixtureDataService = fixtureByLocationService;
        this.fixtureSearchByLocation = listSearchHelper.getListSearchHelper(this.fixtureDataService);

        this.locationGroupDataService = locationGroupByLocationService;
        this.locationGroupSearchByLocation = listSearchHelper.getListSearchHelper(this.locationGroupDataService);

        this.locationId = Number(this.transition.params().id);
        this.location = { Id: this.locationId } as LocationModel;

        this.busy = false;
        this.qrFixture = this.fixtureSearchByLocation;
        this.qrFixtureSearch = "";
        this.qrLocationGroup = this.locationGroupSearchByLocation;
        this.qrLocationGroupSearch = "";
        this.optionList = [
            <HTMLOptionElement>{ text: "ASSIGNED", value: "false", defaultSelected: true },
            <HTMLOptionElement>{ text: "PENDING_ASSIGNMENT", value: "true", defaultSelected: false }
        ];
        this.selectedOption = { value: "false" } as HTMLOptionElement;
    }

    ngOnInit() {
        this.translatedTexts = { foundOf: "found of", total: "total", loadMoreResults: "load more results" };
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        // If we got an ID to load, load it.
        if (this.locationId > 0) {
            //Initial call to populate screen on load
            Promise.all([
                this.getLocation(Number(this.locationId)),
                this.getSignPlanLayoutSetting()
            ]).then(() => {
                this.loaded = true;
            });
        } else {
            this.getSignPlanLayoutSetting().then(() => {
                this.loaded = true;
            });
        }
    }

    private getSignPlanLayoutSetting(): Promise<any> {
        return this.settingsService.canViewSignPlan().then((response: boolean) => {
            this.enableSignPlan = response;
        });
    }

    private translateText() {
        // tslint:disable-next-line:max-line-length
        this.translateService.get(["FOUND_OF", "TOTAL", "LOAD_MORE_RESULTS", "OPEN_DATE_EMPTY_WARNING_MESSAGE", "OPEN_DATE_AFTER_SUSPEND_DATE_WARNING_MESSAGE", "SUSPEND_DATE_BEFORE_OPEN_DATE_WARNING_MESSAGE", "ADDRESS_INVALID", "ADDRESS_NOT_VALIDATED", "ASSIGNED", "PENDING_ASSIGNMENT", "INFORMATION_UPPERCASE", "ERROR_DOWNLOADING_SIGN_PLAN"]).subscribe((res: [string]) => {
            this.translatedTexts.foundOf = res["FOUND_OF"];
            this.translatedTexts.total = res["TOTAL"];
            this.translatedTexts.loadMoreResults = res["LOAD_MORE_RESULTS"];
            this.openDateEmptyWarning = res["OPEN_DATE_EMPTY_WARNING_MESSAGE"];
            this.openDateAfterSuspendDateWarning = res["OPEN_DATE_AFTER_SUSPEND_DATE_WARNING_MESSAGE"];
            this.addressInvalidWarning = res["ADDRESS_INVALID"];
            this.addressNotValidated = res["ADDRESS_NOT_VALIDATED"];
            this.suspendDateBeforeOpenDateWarning = res["SUSPEND_DATE_BEFORE_OPEN_DATE_WARNING_MESSAGE"];
            this.information = res["INFORMATION_UPPERCASE"];
            this.downloadSignPlanError = res["ERROR_DOWNLOADING_SIGN_PLAN"];
            this.upDownPlanError = res["ERROR_DOWNLOADING_UP_DOWN"];

            this.optionList[0].text = res["ASSIGNED"];
            this.optionList[1].text = res["PENDING_ASSIGNMENT"];
        });
    }
    private getLocation(id: string | number) {
        this.loaded = false;

        // Make sure to return the promise.
        this.promise = this.dataService.get<LocationModel>(id).then((response: any) => {
            Object.assign(this.location, response);
            this.address = this.location.Addresses[0];
            let altAddress = this.location.AlternateAddresses.find((f) => f.AddressType === "Campaign");
            if (altAddress) {
                this.campaignShippingAddress = `${altAddress.AddressIdentifier} - ${altAddress.Name}`;
            }

            //Check dates to add warnings to screen
            if (!!this.location.OpenDate === false) {
                this.showOpenDateEmptyWarning = true;
            } else if (this.location.SuspendPromotionsDate && this.location.OpenDate > this.location.SuspendPromotionsDate) {
                this.showSuspendDateBeforeOpenDateWarning = true;
                this.showOpenDateAfterSuspendDateWarning = true;
            }

            this.locationStatusInfo = this.locationService.getStatusInfo(this.location);

            if (this.location.Media) {
                this.fullImage =
                    `${this.location.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity
                    }&idToken=${this.authService.getIdToken()}`;
                this.thumbImage = this.fullImage.replace("Original", "Thumbnail");
            }
            this.loaded = true;
        });
    }

    public chooseCampaign() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: false, search: "campaign" } })
            .then((response: any) => {
                this.downloadPdf(response);
            }, () => { }); // Rejected;
    }

    public downloadPdfWorker(response: any): Promise<any> {
        const campaignId = response.item.Id;
        return this.campaignService.generateSignPlan(this.locationId, campaignId).then((signPlanResponse) => {

            if (signPlanResponse) {

                // generate URL and file name based on input options and response
                const fileName = `Sign Plan - ${response.item.Name} - Location ${this.location.LocationIdentifier}.pdf`;
                const fileGuid = signPlanResponse.replace(".pdf", "");
                const token = this.authService.getIdToken();
                const businessId = this.activeProfileService.businessIdentity;
                const url = `${this.campaignService.urlApiBase}Media/v1/MasterMedia/${businessId}/SignPlan/${fileGuid}/Original/Pdf?businessIdentity=${businessId}&idToken=${token}`;

                // download the file
                this.downloadService.Download(url, fileName);
            }
            else {
                // TODO: resolve issues with sign plan generation, right now just making sure friendly error is displayed
                this.ipsMessage.error(this.downloadSignPlanError);
            }
        });
    }

    public downloadPdf(response: any) {
        return this.ipsMessage.waitForWork({
            body: "Downloading Sign Plan", workFunction: () => this.downloadPdfWorker(response),
            progressMessage: "Downloading Sign Plan"
        });
    }

    public getFixtureList(search: string, id: string) {

        if (!search) {
            search = "";
        }
        let searchInfo: SearchInfo = { searchText: search, id: Number(id) } as SearchInfo;
        this.qrFixturePromise = this.fixtureSearchByLocation.searchHelper(searchInfo);

        //Add ID & label for search grid
        this.qrFixturePromise.then(() => {
            this.qrFixture.resultList.forEach(function (item: UILocationFixtureModel) {
                item.Id = item.FixtureId;
                item.Label = item.Model ? item.FixtureName + " - " + item.Model : item.FixtureName;
            });
        });
    }

    public getLocationGroupList(search: string, id: string, filterOption: HTMLOptionElement) {

        if (!search) {
            search = "";
        }

        if (this.previousId !== id || this.previousFilterOption !== filterOption.value) {
            this.previousFilterOption = filterOption.value;
            this.previousId = id;
            this.locationGroupDataService.headByLocation(id, filterOption.value).then((data) => {
                this.locationGroupSearchByLocation.TotalRecords = data;
            });
        }

        // Note: using Boolean(filterOption.value) always returns true, cast as <any> instead.
        let searchInfo = { searchText: search, id: Number(id), showUnassignedItems: <any>filterOption.value } as SearchInfo;
        this.qrLocationGroupPromise = this.locationGroupSearchByLocation.searchHelper(searchInfo);

        //Add ID & label for search grid
        this.qrLocationGroupPromise.then(() => {
            this.qrLocationGroup.resultList.forEach(function (item: UILocationGroupSubGroupModel) {
                if (!item.LocationGroupLabel) {
                    item.Id = item.LocationGroupId;
                    item.LocationGroupLabel = item.LocationSubGroupName ? `${item.LocationGroupName} - ` : item.LocationGroupName;
                }
            });
        });
    }

    public relatedLocationGroupCollapse() {
        let id = this.locationId;
        this.getLocationGroupList("", id.toString(), this.selectedOption);
        this.locationGroupDataService.headByLocation(id.toString(), this.selectedOption.value).then((data) => {
            this.locationGroupSearchByLocation.TotalRecords = data;
        });
    }

    public relatedFixtureCollapse() {
        let id = this.locationId;
        this.getFixtureList("", id.toString());
        this.fixtureDataService.headByLocation(id.toString()).then((data) => {
            this.fixtureSearchByLocation.TotalRecords = data;
        });
    }

}
