import { HolderModel } from "./HolderModel";
import { CustomerModel } from "./CustomerModel";
import { OptionType } from "../type/OptionType";
import { FixtureLocationSumModel } from "./FixtureLocationSumModel";

export class FixtureGroupModel extends CustomerModel {
    public Id: number;
    public Name: string;
    public Notes: string;
    public StoreFrontCategoryId: number;
    public HasFixtureReference: boolean;
    public Holders?: HolderModel[];
    public OptionType?: OptionType;
    public FixtureLocationSum?: FixtureLocationSumModel;
}
