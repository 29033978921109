import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-store-profile-fixture-group",
    templateUrl: "./store-profile-fixture-group.component.html",
    styleUrls: ["./store-profile-fixture-group.component.scss"]
})
export class StoreProfileFixtureGroupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
