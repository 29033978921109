import { registerLocaleData } from "@angular/common";
import { environment } from "../environments/environment";
// Translate setup
import { TranslateService } from "@ngx-translate/core";
import { CustomLoader, CustomLoaderOptions } from "imagine-ui-ng-translation";
import { APP_MODAL_DEFAULTS } from "./app.config";
//load supported locales
//not all the locale is defined in angular locale data, so use language code to load locale data
//Dynamic import cannot be used when targeting ECMAScript 2015 modules so commented out for now
//if update module to "module": "esnext" in tsconfig.app.json, all locale data is loaded in the bundle
//let lang = navigator.language || navigator["userLanguage"];
//lang = lang.split("-")[0];
//import(`@angular/common/locales/${lang}.js`).then(locale => {
//    registerLocaleData(locale.default);
//});
//import spanish locale for testing
import es from "@angular/common/locales/es";
registerLocaleData(es);
// AoT requires an exported function for factories
export function HttpLoaderFactory(http) {
    var baseApiUrl = environment.baseApiUrl;
    var date = (new Date()).getTime();
    return new CustomLoader(http, {
        onSiteLangs: ["en", "en-US"],
        onSiteFiles: [
            {
                fullPath: "/assets/i18n/General.en.json?" + date,
                prefix: "/assets/i18n/General.",
                suffix: ".json?" + date
            }
        ],
        otherLangFiles: [
            {
                prefix: baseApiUrl + "System/v1/Translation/search/general/",
                suffix: ""
            }
        ]
    });
}
var ɵ0 = APP_MODAL_DEFAULTS, ɵ1 = function (translate) {
    return translate.currentLang;
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
