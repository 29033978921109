import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-store-front-category",
  templateUrl: "./store-front-category.component.html",
  styleUrls: ["./store-front-category.component.scss"]
})
export class StoreFrontCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
