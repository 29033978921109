<div>
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0">{{ title | translate }}</h4>
        </div>
        
        <div class="modal-body ips-modal-search-body ips-modal-responsive no-border-item">
            <ips-list-search searchLabel="{{searchLabel | translate }}"
                             searchLabelPlural="{{searchLabelPlural | translate }}"
                             labelLoading="{{labelLoading | translate }}"
                             labelLoadingError="{{labelLoadingError | translate }}"
                             hideHeader=true
                             [listData]="searchHelper"
                             [busy]="busy"
                             (onListDataChange)="getListData($event.search)"
                             section=""
                             [scroller]=true
                             quickRef=false
                             [promise]="promise"
                             [autoFocus]="true"
                             [isModal]="true">
                <ng-template #listSearchItemTemplate let-result="result">
                    <ng-container *ngIf="requestedMarketType==='Fixture'">
                        <div class="list-group-item list-group-item-action location-group-header mt-0 py-0 pl-3 border-0">
                            <div class="row border">
                                <div class="col py-1">
                                    <div class="row">
                                        <div class="col-md-6 mt-2 mt-md-0">
                                            <span class="location-group-title font-weight-bold">
                                                {{result.Name}}<span *ngIf="result.Model"> - {{result.Model}}</span>
                                            </span>
                                        </div>
                            
                                        <div class="col-md-5 mt-2 d-flex pr-0 align-items-center">
                                            <div class="font-weight-bold mr-2" translate>FIXTURE_QUANTITY</div><br />
                                            <div class="font-weight-bold">{{result.LocationFixtureSum | number}}</div>
                                        </div>
                                    </div>
                                    <div class="list-group-item mt-3  border-warning rounded" *ngIf="result.warningMessage">
                                        <p><b translate>{{result.warningHeader}}</b></p>
                                        <span>{{result.warningMessage}}</span>
                                    </div>

                                    <div class="row">
                                        <ul class="col-md-10 mt-2 mt-md-0" *ngFor="let subResult of result.Holders">
                                            <li *ngIf="subResult.Quantity > 0">
                                                {{subResult.Quantity | number}} - {{subResult.Name}} - {{subResult.Size.Width | number: '1.0-7'}} x {{subResult.Size.Height | number: '1.0-7' }}
                                                <span *ngIf="subResult.HolderVersionInfoName"> - </span>{{subResult.HolderVersionInfoName}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-auto input-group-text justify-content-center rounded-0 border-top-0 border-bottom-0 border-right-0">
                                    <input class="float-right" [checked]="result.Disabled" type="checkbox" id="{{result.Name}}" (change)="marketSelected(result,$event.target.checked)">
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="requestedMarketType==='LocationGroup'">

                        <label class="border-0 list-group-item-action px-3">
                            <div class="row border">
                                <div class="col py-1" (click)="(isSingleSelect && !result.Disabled) && updateSingleMarket(result)">
                                    <div class="location-group-header">
                                        <span class="location-group-title">{{result.Name}}</span>
                                        <div class="location-group-rule-icon" ngClass="{{result.ruleClass}}" ngbTooltip="{{result.ruleTooltip}}" placement="bottom"></div>
                                    </div>

                                    <div class="list-group-item mt-3  border-warning rounded" *ngIf="result.warningMessage">
                                        <p><b translate>{{result.warningHeader}}</b></p>
                                        <span>{{result.warningMessage}}</span>
                                    </div>

                                    <div class="mt-3 pl-4" *ngFor='let subGroup of result.SubGroups'>
                                        <span>{{subGroup.Name}}</span>
                                    </div>
                                </div>
                                <div *ngIf="!isSingleSelect" class="col-auto input-group-append px-0">
                                    <div class="input-group-text rounded-0 border-0">
                                        <input class="float-right" [checked]="result.Disabled" type="checkbox" id="{{result.Name}}" (change)="marketSelected(result,$event.target.checked)">
                                    </div>
                                </div>
                            </div>
                        </label>
                    </ng-container>
                                       
                    <ng-container *ngIf="requestedMarketType==='FixtureGroup'">
                        <div class="border-0 list-group-item-action px-3">
                            <div class="row border">
                                <div class="col py-1" (click)="(isSingleSelect && !result.Disabled) && updateSingleMarket(result)">
                                    <div class="location-group-title col-12">
                                        <div>
                                            <span>{{result.Name}}</span>
                                            <span class="pl-2">({{result.Fixtures.length}} {{result.Fixtures.length | i18nPlural: pluralMapping}})</span>
                                        </div>
                                    </div>
                                    <div class="list-group-item mt-3  border-warning rounded" *ngIf="result.warningMessage">
                                        <p><b translate>{{result.warningHeader}}</b></p>
                                        <span>{{result.warningMessage}}</span>
                                    </div>

                                    <div class="mt-3 pl-4" *ngFor='let fixture of result.Fixtures'>
                                        <span>{{fixture.Name}}</span><span *ngIf="fixture.Model"> - {{fixture.Model}}</span>
                                    </div>
                                </div>
                                <div class="col-auto input-group-append px-0">
                                    <div *ngIf="!isSingleSelect" class="input-group-text rounded-0 border-0">
                                        <input class="" type="checkbox" [checked]="result.Disabled" (change)="marketSelected(result,$event.target.checked)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </ips-list-search>
        </div>

        
        <div class="row align-items-center" *ngIf="!isSingleSelect">
            <div class="col-12 col-md-6 ml-5 pr-0">
                <span class="badge-pill badge-success">{{selectedCount}}</span>
                <span class="ml-1" *ngIf="requestedMarketType==='Fixture'">{{selectedCount | i18nPlural: fixturePluralMapping}}</span>
                <span class="ml-1" *ngIf="requestedMarketType==='LocationGroup'">{{selectedCount | i18nPlural: locationGroupPluralMapping}}</span>
                <span class="ml-1" *ngIf="requestedMarketType==='FixtureGroup'">{{selectedCount | i18nPlural: fixtureGroupPluralMapping}}</span>
            </div>
            <div class="col-12 col-md-5 pl-0 pr-4">
                <button type="submit" class="btn btn-default btn-lg btn-block search-modal-done-button mb-2"
                        id="doneButton"
                        (click)="updateMarket()" translate>
                    DONE
                </button>
            </div>
        </div>

    </form>
</div>
