import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Transition, StateService } from "@uirouter/core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { BrandBulletinModel } from "../../model/BrandBulletinModel";
import { BrandBulletinService } from "../../service/brand-bulletin.service";
import { TemplateFormBase } from "../../../shared/templateFormBase";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString } from "typescript-string-operations";

interface BulletinFormModel {
    profilePageNotice: string;
    profilePageNoticeNotes: string;
}

@Component({
    selector: "app-controls-edit",
    templateUrl: "./controls-edit.component.html",
    styleUrls: ["./controls-edit.component.scss"]
})
export class ControlsEditComponent implements OnInit {
    @ViewChild("controlsForm") public controlsForm: NgForm;
    public loaded: boolean;
    public promise: Promise<any>;
    private maxLengthMessage: string;

    public brandBulletin: BrandBulletinModel;
    public brandBulletinShop: BrandBulletinModel;
    public brandBulletinUpDown: BrandBulletinModel;

    private errorMessages = {
        "maxlength": (params) => IpsString.Format(this.maxLengthMessage, params.requiredLength)
    };

    constructor(private $state: StateService,
        private ipsMessage: IpsMessageService,
        private brandBulletinService: BrandBulletinService,
        private translateService: TranslateService ) {

        // Clear out current brand bulletin, fill in defaults
        this.brandBulletin = <BrandBulletinModel>{ BusinessIdentity: "", Id: 0, Value: "", Notes: "", Type: "Profile" };
        this.brandBulletinShop = <BrandBulletinModel>{ BusinessIdentity: "", Id: 0, Value: "", Notes: "", Type: "Shop" };
        this.brandBulletinUpDown = <BrandBulletinModel>{ BusinessIdentity: "", Id: 0, Value: "", Notes: "", Type: "UpDown" };
    }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.getBrandBulletin();
    }

    private translateText() {
        this.translateService.get(["MAX_LENGTH_ERROR"]).subscribe((res: [string]) => {
            this.maxLengthMessage = res["MAX_LENGTH_ERROR"];
        });
    }

    private getBrandBulletin(): Promise<void> {
        this.promise = this.brandBulletinService.getList<BrandBulletinModel[]>().then(
            (response: BrandBulletinModel[]) => {
                if (response && response.length > 0) {

                    let bb = response.find(q => q.Type === "Profile");
                    if (bb) {
                        this.brandBulletin = bb;
                    }

                    let bbs = response.find(q => q.Type === "Shop");
                    if (bbs) {
                        this.brandBulletinShop = bbs;
                    }

                    let bbud = response.find(q => q.Type === "UpDown");
                    if (bbud) {
                        this.brandBulletinUpDown = bbud;
                    }
                }
                this.loaded = true;
            });

        return this.promise;
    }

    private saveBrandBulletin(): Promise<any> {
         const promises: Promise<any>[] = [];
        //Profile
        let id = String(this.brandBulletin.Id);
        if (id && id !== "0") {
            promises.push( this.brandBulletinService.put(this.brandBulletin).then((response: BrandBulletinModel) => {
                this.brandBulletin.Id = response.Id;
            }));
        } else {
            promises.push( this.brandBulletinService.post(this.brandBulletin).then((response: BrandBulletinModel) => {
                this.brandBulletin.Id = response.Id;
            }));
        }

        //Shop
        id = String(this.brandBulletinShop.Id);
        if (id && id !== "0") {
            promises.push( this.brandBulletinService.put(this.brandBulletinShop).then((response: BrandBulletinModel) => {
                this.brandBulletinShop.Id = response.Id;
            }));
        } else {
            promises.push( this.brandBulletinService.post(this.brandBulletinShop).then((response: BrandBulletinModel) => {
                this.brandBulletinShop.Id = response.Id;
            }));
        }

        //UpDown Report
        id = String(this.brandBulletinUpDown.Id);
        if (id && id !== "0") {
            promises.push(this.brandBulletinService.put(this.brandBulletinUpDown).then((response: BrandBulletinModel) => {
                this.brandBulletinUpDown.Id = response.Id;
            }));
        } else {
            promises.push(this.brandBulletinService.post(this.brandBulletinUpDown).then((response: BrandBulletinModel) => {
                this.brandBulletinUpDown.Id = response.Id;
            }));
        }

        return Promise.all(promises).then(() => {
            this.controlsForm.form.markAsPristine();
        });
    }

    // Public Functions used by view
    public saveBrandBulletinPrompt(redirect: boolean) {
        return this.ipsMessage
            .waitForWork({ body: "SAVING", workFunction: () => this.saveBrandBulletin(), progressMessage: "SAVING" })
            .then((result: BrandBulletinModel) => {
                if (result && redirect) {
                    this.$state.go("main.toolsControls");
                }
            });
    }
}
