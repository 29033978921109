import { Component, OnInit } from "@angular/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { ProfileService } from "imagine-ui-ng-quick-start";
import { StateService } from "@uirouter/core";

@Component({
    selector: "app-logout",
    templateUrl: "./logout.component.html",
    styleUrls: ["./logout.component.scss"]
})
export class LogoutComponent implements OnInit {

    constructor(private profileService: ProfileService, private $state: StateService) {}

    ngOnInit() {
        this.signout();
    }

    public signout() {
        this.profileService.signOut().then(() => {
                let current = this.$state.current;
                this.$state.go("public.login");
            }
        ).catch(() => {
            console.log("logout failed");
            this.$state.go("public.login");
        });
    }

}
