import { Component, OnInit, Input, ViewChild, NgZone } from "@angular/core";
import { TargetState, StateService } from "@uirouter/core";
import { IpsAnalyticsService } from "imagine-ui-ng-quick-start";
import { VersionCheckService } from "../version-check/version-check.service";

@Component({
    selector: "app-login-page",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
    @Input() returnTo: TargetState;

    constructor(private $state: StateService, private zone: NgZone, private ipsAnalytics: IpsAnalyticsService, private versionCheckService: VersionCheckService) {
    }

  public returnToState(result) {
      this.zone.run(() => {
          if (result.loggedIn) {
              //user identified, mark the users identification in the analytics stream
              this.ipsAnalytics.setUserName(result.user);
              // Return to the original state
              const state = this.returnTo.state();
              const params = this.returnTo.params();
              const options = Object.assign({}, this.returnTo.options(), { reload: true });

              //Force a version check here before navigating to existing routes
              this.versionCheckService.checkForReload("/assets/version.json").then(isReloading => {
                  //Look for false so we know we are not just reloading the page
                  if (!isReloading) {
                      if (state.name === "public.oldLogin" || state.name === "public.ssoTokenReceiver") {
                          this.$state.go("main.home");
                      } else if (state.name !== "public.login") {
                          this.$state.go(state, params, options);
                      }
                  }
              });
          } else {
              let message = result.error.message || result.error;
              console.log("failed to load user data: " + message, result.error);
              this.$state.go("error");
          }
      });
  }
}
