var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var PatternGroupService = /** @class */ (function (_super) {
    __extends(PatternGroupService, _super);
    function PatternGroupService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("PatternGroup", "Campaign", "v1");
        return _this;
    }
    PatternGroupService.prototype.getByPromotionId = function (promoId) {
        var route = "Promotion/" + promoId + "/PatternGroup";
        return this.getByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    PatternGroupService.prototype.calculatePositionLocationCounts = function (promoMsgs, placementGroups) {
        var route = this.urlApiBaseProductPlusVersion + "PatternGroup/GetPositionLocationCounts";
        var requestModel = { PromotionMessages: promoMsgs, PromotionMessagePlacementGroup: placementGroups[0] };
        return this.postByRoute(route, requestModel);
    };
    return PatternGroupService;
}(IpsBaseWebService));
export { PatternGroupService };
