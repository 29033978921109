import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IpsBaseWebService, ActiveProfileService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";

import { SimpleSearchFixtureGroupModel } from "../model/SimpleSearchFixtureGroupModel";

@Injectable()
export class FixtureGroupService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("FixtureType", "Profile", "v1");
    }

    public simpleSearch(searchParams: SearchInfo): Promise<SearchResponse<SimpleSearchFixtureGroupModel>> {
        return this.search<SearchResponse<SimpleSearchFixtureGroupModel>>(searchParams, "FixtureType/SimpleSearch");
    }

    public deleteCheck(id: string) {
        let route = `${this.urlApiBasePlusController}${id}/DeleteCheck`;
        return this.getByRoute(route);
    }
}
