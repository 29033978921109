<div class="card ips-shadow" [formGroup]="messageForm">
    <div class="card-header d-flex align-items-center justify-content-between">
        <span translate>SEND_MESSAGE_SURVEY_ADMIN</span><button class="btn btn-link" (click)="messageRemovePrompt()"><i class="fa fa-lg fa-trash-o" aria-hidden="true"></i></button>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-12 form-group">
                <label translate>MESSAGE</label>
                <textarea class="form-control form-control-lg" name="notes" id="notes" rows="3" formControlName="message"></textarea>
                <ips-show-errors [control]="messageForm.get('message')" [errorMessages]="getErrorMessages('message')" cssClass="validation-message-Qty"></ips-show-errors>
            </div>
            <div class="col-12" [ngClass]="{ 'justify-content-end d-flex': imageSelected }">
                <ips-image-edit name="file" [showDelete]="true" (fileChanged)="onSelectedImageChanged($event)"></ips-image-edit>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 offset-md-6 col-md-6">
                <button type="button" class="btn btn-default btn-lg btn-block" (click)="saveMessagePrompt()" ips-unsaved-warning-clear [disabled]="messageForm.invalid || messageForm.pristine" translate>SAVE</button>
            </div>
        </div>
    </div>
</div>
