/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help-doc.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "angular-busy2";
import * as i5 from "./help-doc.component";
import * as i6 from "../../../imagine-ui-ng-store-profile/service/brand-setting.service";
var styles_HelpDocComponent = [i0.styles];
var RenderType_HelpDocComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpDocComponent, data: {} });
export { RenderType_HelpDocComponent as RenderType_HelpDocComponent };
function View_HelpDocComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "pr-3"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attachmentUrl(_v.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.Label; _ck(_v, 1, 0, currVal_1); }); }
function View_HelpDocComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "pr-3"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.Label2; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.Label; _ck(_v, 1, 0, currVal_1); }); }
function View_HelpDocComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [["class", "list-group-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpDocComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpDocComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.LinkSubType !== 5); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.context.$implicit.LinkSubType === 5); _ck(_v, 5, 0, currVal_1); }, null); }
function View_HelpDocComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" There are no Help Documents added. "]))], null, null); }
function View_HelpDocComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row my-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpDocComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpDocComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attachedDocuments; _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.attachedDocuments || (_co.attachedDocuments.length < 1)); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_HelpDocComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "section", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["HELP_DOCUMENTS"])), (_l()(), i1.ɵeld(6, 16777216, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i4.ɵc, [i1.ViewContainerRef, i4.CgBusyDefaults, i1.Renderer2, i1.ComponentFactoryResolver, i1.ElementRef], { cgBusy: [0, "cgBusy"], cgBusyConfig: [1, "cgBusyConfig"] }, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpDocComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.promise; var currVal_2 = i1.ɵinlineInterpolate(1, "{message:'", i1.ɵunv(_v, 7, 1, i1.ɵnov(_v, 8).transform("ACCESSING_HELP_DOCUMENTS")), "'}"); _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _co.loaded; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_HelpDocComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-help-doc", [], null, null, null, View_HelpDocComponent_0, RenderType_HelpDocComponent)), i1.ɵdid(1, 114688, null, 0, i5.HelpDocComponent, [i6.BrandSettingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpDocComponentNgFactory = i1.ɵccf("app-help-doc", i5.HelpDocComponent, View_HelpDocComponent_Host_0, {}, {}, []);
export { HelpDocComponentNgFactory as HelpDocComponentNgFactory };
