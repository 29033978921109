/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./logout.component";
import * as i4 from "imagine-ui-ng-quick-start";
import * as i5 from "@uirouter/core";
var styles_LogoutComponent = [i0.styles];
var RenderType_LogoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "app-logout p-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "strong", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["THANK_YOU"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["SUCCESSFULLY_LOGGED_OUT"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 4, 0, currVal_0); var currVal_1 = ""; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_LogoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.LogoutComponent, [i4.ProfileService, i5.StateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i1.ɵccf("app-logout", i3.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
