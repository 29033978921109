<div *ngIf="!singleSelect">
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0">{{title}}</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive tab-search-modal">
            <ips-list-search headerLabel=""
                             [searchLabel]="searchLabel"
                             [searchLabelPlural]="searchLabelPlural"
                             labelLoading="{{'ACCESSING_INFO' | translate }}"
                             labelLoadingError="{{'ERROR_ACCESSING_INFO' | translate }}"
                             [listData]="listSearch"
                             (onListDataChange)="getListData($event.search)"
                             [busy]="busy"
                             [scroller]="true"
                             [hideHeader]="true"
                             [promise]="promise"
                             [autoFocus]="true"
                             [isModal]="true"
                             [query]="query">
                <ng-template #listSearchItemTemplate let-result="result">
                    <div class="list-group-item no-border">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">{{result.Name}}</div>
                            </div>
                            <div class="col-12 no-round-grp list-item-container">
                                <ng-container *ngIf="placementType==='fixture'">
                                    <div class="input-group no-round clicker" *ngFor="let holder of result.Holders">
                                        <div class="form-control input-full py-3" (click)="holderChecked(result, holder)">
                                            {{holder.Name}}
                                        </div>
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <input [name]="holder.Id" id="selectedCheck" type="checkbox" [(ngModel)]="holder.IsSelected" (ngModelChange)="selectedHolderChanged(result, holder)" />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="placementType==='element'">
                                    <div class="input-group no-round clicker" *ngFor="let detail of result.ElementDetails">
                                        <div class="form-control input-full py-3" (click)="elementChecked(result, detail)">
                                            {{detail.Width}} x {{detail.Height}}
                                        </div>
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <input [name]="detail.Id" id="elementSelectedCheck" type="checkbox" [(ngModel)]="detail.IsSelected" (ngModelChange)="selectedElementChanged(result, detail)" />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ips-list-search>
            <div class="row align-items-center" *ngIf="!busy">
                <div class="col-12 col-md-6 ml-5 pr-0">
                    <span class="badge-pill badge-success">{{selectedCount}}</span>
                    <span class="ml-1">{{selectedCount | i18nPlural: pluralMapping}}</span>
                </div>
                <div class="col-12 col-md-5 pl-0 pr-4">
                    <button type="submit" class="btn btn-default btn-lg btn-block search-modal-done-button mt-2"
                            id="doneButton"
                            (click)="returnResults()" translate>
                        DONE
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<div *ngIf="singleSelect">
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0">{{title}}</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive tab-search-modal">
            <ips-list-search headerLabel=""
                             [searchLabel]="searchLabel"
                             [searchLabelPlural]="searchLabelPlural"
                             labelLoading="{{'ACCESSING_INFO' | translate }}"
                             labelLoadingError="{{'ERROR_ACCESSING_INFO' | translate }}"
                             [listData]="listSearch"
                             (onListDataChange)="getListData($event.search)"
                             [busy]="busy"
                             [scroller]="true"
                             [hideHeader]="true"
                             [promise]="promise"
                             [autoFocus]="true"
                             [isModal]="true"
                             [query]="query">
                <ng-template #listSearchItemTemplate let-result="result">
                    <div class="list-group-item no-border">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">{{result.Name}}</div>
                            </div>
                            <div class="col-12 no-round-grp list-item-container">
                                <ng-container *ngIf="placementType==='fixture'">
                                    <div *ngFor="let holder of result.Holders">
                                    <div class="form-control input-full py-3" (click)="holderChecked(result, holder, true)">
                                        {{holder.Name}}
                                    </div>
                                        </div>
                                </ng-container>
                                <ng-container *ngIf="placementType==='element'">
                                    <div class="input-group no-round clicker" *ngFor="let detail of result.ElementDetails">
                                        <div class="form-control input-full py-3" (click)="elementChecked(result, detail)">
                                            {{detail.Width}} x {{detail.Height}}
                                        </div>
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <input [name]="detail.Id" id="elementSelectedCheck" type="checkbox" [(ngModel)]="detail.IsSelected" (ngModelChange)="selectedElementChanged(result, detail)" />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ips-list-search>
        </div>
    </form>
</div>
