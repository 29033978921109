<div class="row">
    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.campaign.search">
        <a id="spacesLink" class="landingNavLink" uiSref="main.campaign.search">
            <div class="campaignsImg landingNavIcon"></div>
            <h3 translate>CAMPAIGNS</h3>
        </a>
    </section>
    <section class="col-6 col-lg-2 landingNavDescription" ipsPermission="main.storeProfileElement.search">
        <a id="elementsLink" class="landingNavLink" uiSref="main.storeProfileElement.search">
            <div class="elementImg landingNavIcon"></div>
            <h3 translate>ELEMENTS</h3>
        </a>
    </section>
</div>
