import { Component, OnInit, Input } from "@angular/core";
import { IpsModalService } from "imagine-ui-ng-modal";

@Component({
    // tslint:disable-next-line
    selector: "ips-image-view",
    templateUrl: "./ips-image-view.component.html",
    styleUrls: ["./ips-image-view.component.scss"]
})
export class IpsImageViewComponent implements OnInit {
    @Input() imgThumbUrl: string;
    @Input() imgFullUrl: string;
    @Input() hideLabel: boolean;
    @Input() noImageText: string;

    private imageNotFoundText = "IMAGE_NOT_FOUND";

    constructor(private ipsModal: IpsModalService) {}

    ngOnInit() {
        this.noImageText = this.noImageText || "NO_IMAGE_SELECTED";
    }

    public imagePopover() {
        this.ipsModal.imagePopOver(this.imgFullUrl, "ALT_IMAGE", undefined);
    }

    imgError(image) {
        this.noImageText = this.imageNotFoundText;
        this.imgThumbUrl = undefined;
    }

}
