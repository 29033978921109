import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchResponse } from "imagine-ui-ng-core";
import { LocationModel } from "../../model/LocationModel";
import { LocationSearchInfo } from "../../model/LocationSearchInfo";
import { LocationStatusModel } from "../../model/LocationStatusModel";
import { LocationStatus } from "../../type/LocationStatusType";
import { environment } from "../../../../environments/environment";

@Injectable()
export class LocationService extends IpsBaseWebService {

    public locationAttributes = [{ Label: "POINT_OF_CONTACT", Key: "PointOfContact" },
    { Label: "NOTES", Key: "Notes" }, { Label: "OPEN_DATE", Key: "OpenDate" }, { Label: "FIRST_RECEIPT_DATE", Key: "InitiatePromotionsDate" },
    { Label: "SUSPEND_DATE", Key: "SuspendPromotionsDate" }, { Label: "IMAGE", Key: "Image" }];

    public addressAttributes = [{ Label: "ATTENTION_TO", Key: "Attention" }, { Label: "ADDRESS_LINE_1", Key: "Line1" },
    { Label: "ADDRESS_LINE_2", Key: "Line2" }, { Label: "CITY", Key: "City" }, { Label: "STATE_PROVINCE", Key: "StateProvince" }, { Label: "COUNTRY", Key: "Country" },
    { Label: "POSTAL_CODE", Key: "PostalCode" }, { Label: "PHONE", Key: "Phone" }, { Label: "EMAIL", Key: "Email" }, { Label: "FAX", Key: "Fax" }];

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Location", "Profile", "v1");
    }

    public getLocationList(includeClosed?: boolean) {
        includeClosed = includeClosed || false;
        return this.getByRoute(this.urlApiBaseProductPlusVersion + "Location?" +
            "includeClosed=" + encodeURIComponent(includeClosed.toString())
        );
    }

    public searchLocationsByIds(listOfIds: number[]) {
        let route =
            `${environment.baseApiUrl}Profile/v1/Location/FilteredSearch/LocationId`;
        let requestModel: LocationSearchInfo = {
            searchText: null,
            chunkIndex: 0,
            recordCount: listOfIds.length,
            showUnassignedItems: false,
            LocationIds: listOfIds
        };
        return this.postByRoute<SearchResponse<LocationModel>>(route, requestModel);
    }

    public getByExternalId(id: string) {
        return this.getByRoute(this.urlApiBaseProductPlusVersion + "Location/External/" + id);
    }

    public closeLocation(locationId: number) {
        let route = `${this.urlApiBaseProductPlusVersion}Location/${locationId}/SetToClosed`;
        return this.put({}, null, route);
    }

    public reopenLocation(locationId: number) {
        let route = `${this.urlApiBaseProductPlusVersion}Location/${locationId}/SetToNotClosed`;
        return this.put({}, null, route);
    }

    public getStatus(location: LocationModel): LocationStatus {
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        //Closed
        if (location.ClosedDate) {
            return "CLOSED" as LocationStatus;
        }

        //Suspended
        if (location.SuspendPromotionsDate && new Date(location.SuspendPromotionsDate) <= today) {
            return "SUSPENDED" as LocationStatus;
        }

        //Open
        if (location.OpenDate && new Date(location.OpenDate) <= today) {
            return "OPEN" as LocationStatus;
        }

        return "PLANNING" as LocationStatus;
    }

    public getStatusInfo(location: LocationModel): LocationStatusModel {
        let status = this.getStatus(location);
        let info = { IconClass: "", Tooltip: "", Status: status };
        switch (status) {
            case "OPEN":
                info.IconClass = "img-location-status-open";
                info.Tooltip = "TOOLTIP_LOCATION_STATUS_OPEN";
                break;
            case "PLANNING":
                info.IconClass = "img-location-status-planning";
                info.Tooltip = "TOOLTIP_LOCATION_STATUS_PLANNING";
                break;
            case "CLOSED":
                info.IconClass = "img-location-status-closed";
                info.Tooltip = "TOOLTIP_LOCATION_STATUS_CLOSED";
                break;
            case "SUSPENDED":
                info.IconClass = "img-location-status-suspended";
                info.Tooltip = "TOOLTIP_LOCATION_STATUS_SUSPENDED";
                break;
        }
        return info;
    }

    public headByAttribute(attribute: string): Promise<number> {
        let route = `Location/SearchByAttribute/${attribute}`;
        return this.headByRoute(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public headByOrder(id: string) {
        let route = `${this.urlApiBaseProductPlusVersion}Order/${id}/Location`;
        return this.headByRoute(route);
    }

    public deleteCheck(id: string) {
        let route = `${this.urlApiBaseProductPlusVersion}Location/${id}/DeleteCheck`;
        return this.getByRoute(route);
    }


    //Store-Front APIs =============================================
    public storeFrontSearch(qry: string, searchByRef: boolean) {
        let route = `${this.urlApiBaseProductPlusVersion}Location/StoreFront/Search`;
        return this.postByRoute(route, { Query: qry, SearchByRef: searchByRef });
    }

    public storeFrontGet(id: string) {
        let route = `${this.urlApiBaseProductPlusVersion}Location/StoreFront/${id}`;
        return this.getByRoute(route);
    }

    public storeFrontPost(values: any) {
        let route = `${this.urlApiBaseProductPlusVersion}Location/StoreFront`;
        return this.postByRoute(route, values);
    }

    public storeFrontPut(values: any) {
        let route = `${this.urlApiBaseProductPlusVersion}Location/StoreFront`;
        return this.put(values, null, route);
    }

    public storeFrontDelete(id: string) {
        let route = `${this.urlApiBaseProductPlusVersion}Location/StoreFront/${id}`;
        return this.deleteByRoute(route);
    }

    public storeFrontGetCategories() {
        let route = `${this.urlApiBaseProductPlusVersion}Location/StoreFront/Categories`;
        return this.getByRoute(route);
    }

}
