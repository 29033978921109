<div [ngSwitch]="inputType">
    <input *ngSwitchCase="'Number'"
           type="number"
           [formControl]="formControl"
           class="form-control form-control-lg input-full"
           [ngClass]="ipsClass"
           placeholder="{{ placeholder | translate}}"
           [ipsFocus]="focus"
           name="{{name}}">
    <div *ngSwitchCase="'Date'" class="input-group">
        <input type="text"
               [formControl]="formControl"
               class="form-control form-control-lg input-full"
               [ngClass]="ipsClass"
               placeholder="{{ placeholder | translate}}"
               bsDatepicker
               [bsConfig]="dateOptions"
               #d="bsDatepicker"
               triggers=""
               [ipsFocus]="focus"
               name="{{name}}">
        <span class="input-group-append">
            <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" [ngClass]="ipsClass" (click)="d.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
        </span>
    </div>
    <input *ngSwitchDefault
           type="text"
           [formControl]="formControl"
           class="form-control form-control-lg input-full"
           [ngClass]="ipsClass"
           [ipsFocus]="focus"
           placeholder="{{ placeholder | translate}}"
           name="{{name}}">
</div>
