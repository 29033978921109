import { Directive, ElementRef, Renderer2, AfterViewInit, HostListener } from "@angular/core";
declare let $: any;

/*
    This directive is to detect text overflow in the containing div
    if there is no text overflow hide the ellipsis icon

    Example: <div appTextOverflow>{{text}}</div>
*/

@Directive({
    selector: "[appTextOverflow]"
})
export class TextOverflowDirective implements AfterViewInit {

    private init = false;

    constructor(private element: ElementRef, private renderer: Renderer2) {
    }

    ngAfterViewInit() {
        this.checkWidth();
        this.init = true;
    }

    @HostListener("window:resize") onResize() {
        if (this.init) {
            this.checkWidth();
        }
    }

    private checkWidth() {
        const element = this.element.nativeElement;
        if (element.offsetWidth >= element.scrollWidth) {
            $(element).next("div").addClass("d-none");
        }
    }
}
