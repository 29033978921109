import { Component, OnInit } from "@angular/core";
import { BrandSettingService } from "../imagine-ui-ng-store-profile";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { BrandBulletinService } from "../imagine-ui-ng-store-profile/service/brand-bulletin.service";
import { BrandBulletinModel } from "../imagine-ui-ng-store-profile/model/BrandBulletinModel";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
    message = "";
    public brandBulletin: BrandBulletinModel;

    constructor(private brandSettingService: BrandSettingService,
        private activeProfileService: ActiveProfileService,
        private brandBulletinService: BrandBulletinService) { }

    ngOnInit() {
        this.brandSettingService.getSettingByName("homepagemessage").then(res => this.message = res || this.message);

        this.brandBulletinService.getList().then((response: BrandBulletinModel[]) => {

            if (response && response.length > 0 ) {
                let shopVal = response.find(q => q.Type === "Shop");
                if (shopVal && !this.isNullOrSpaces(shopVal.Value)) {
                    this.brandBulletin = shopVal;
                }

            }
        });
    }

    private isNullOrSpaces(str: string): boolean {
        return !!str === false || str.match(/^ *$/) !== null;
    }

}
