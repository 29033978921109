import { OnInit, OnDestroy, TemplateRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StateService } from "@uirouter/core";
import { String as IpsString } from "typescript-string-operations";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
import { ListSearchHelper, SearchInfo, SearchResponse, ActiveProfileService, HelperFunctionsService, MediaReferenceModel } from "imagine-ui-ng-core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { AuthService } from "imagine-ui-ng-security";
import { CampaignService } from "../../../imagine-ui-ng-campaign/index";
import { SpaceService } from "../../../imagine-ui-ng-store-profile/index";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { ItemService, LocationService } from "../../index";
import { ShopSettingsService } from "../../service/shop-settings.service";
import { BrandBulletinService } from "../../../imagine-ui-ng-store-profile/service/brand-bulletin.service";
var ProductSearchComponent = /** @class */ (function () {
    function ProductSearchComponent(locationService, itemService, listSearchHelper, activeProfileService, ipsModal, stateService, helperService, translateService, campaignService, spaceService, authService, shopSettingsService, brandBulletinService) {
        var _this = this;
        this.locationService = locationService;
        this.itemService = itemService;
        this.activeProfileService = activeProfileService;
        this.ipsModal = ipsModal;
        this.stateService = stateService;
        this.helperService = helperService;
        this.translateService = translateService;
        this.campaignService = campaignService;
        this.spaceService = spaceService;
        this.authService = authService;
        this.shopSettingsService = shopSettingsService;
        this.brandBulletinService = brandBulletinService;
        this.showOrderReason = true;
        this.orderReasons = [];
        this.showCategoryFilter = false;
        this.categoriesAvailable = false;
        this.spacesAvailable = false;
        this.campaignsAvailable = false;
        this.showAllLocationsFilter = false;
        this.hideCampaignFilters = false;
        this.selectedLocation = null;
        this.selectedSpaceId = 0;
        this.selectedSpaceLabel = null;
        this.selectedCampaignId = 0;
        this.selectedCampaignLabel = null;
        this.selectedCategory = null;
        this.availableCategories = [];
        this.allSpaces = [];
        this.allCategories = [];
        this.allCampaigns = [];
        this.preFilteredSpaces = [];
        this.preFilteredCategories = [];
        this.preFilteredCampaigns = [];
        this.enableSpace = true;
        this.enableCampaign = true;
        this.enableProductsByFixture = true;
        this.searchType = "Location";
        this.partialFixtureItemRecord = null;
        this.itemRelatedFilterData = null;
        this.screenLoaded = false;
        this.searchFilterDataLoaded = false;
        this.locationsAvailable = false;
        this.searchText = "";
        this.locale = "en-US";
        this.pluralMapping = {};
        this.querySubject = new Subject();
        this.itemHelper = listSearchHelper.getListSearchHelper(itemService);
        this.spaceHelper = listSearchHelper.getListSearchHelper(spaceService);
        this.campaignHelper = listSearchHelper.getListSearchHelper(campaignService);
        this.itemHelper.beforeResultsAdded = function (helper, items) { return _this.mergeFixtureItems(helper, items); };
        this.querySubject.pipe(debounceTime(350), distinctUntilChanged())
            .subscribe(function (model) {
            _this.getListData(model);
        });
    }
    ProductSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locale = this.activeProfileService.profile.Locale || navigator.language;
        this.searchScrollKey = "products_search_scroll_" + this.activeProfileService.businessIdentity;
        var scrollInfo = JSON.parse(localStorage.getItem(this.searchScrollKey));
        if (scrollInfo) {
            this.searchText = scrollInfo.search;
            this.selectedLocationId = scrollInfo.locationId;
            localStorage.removeItem(this.searchScrollKey);
        }
        this.processQueryParams(this.stateService.params);
        var promises = [];
        var filterPromises = [];
        promises.push(this.shopSettingsService.getOrderReasons().then(function (response) {
            _this.orderReasons = response.sort(function (a, b) { return a > b ? 1 : -1; });
        }));
        promises.push(this.getSelectedLocation(this.selectedLocationId).then(function () {
            if (_this.locationsAvailable) {
                _this.getListData(_this.searchText, false, true, scrollInfo);
            }
        }));
        promises.push(this.translateService.get(["PRODUCT", "PRODUCTS"]).toPromise().then(function (response) {
            _this.pluralMapping["=1"] = response["PRODUCT"];
            _this.pluralMapping["other"] = response["PRODUCTS"];
        }));
        promises.push(this.shopSettingsService.getSettingByName("ShowOrderReason").then(function (response) {
            if (response) {
                _this.showOrderReason = (response.Value === "item");
            }
        }));
        promises.push(this.shopSettingsService.getCategoryFilterSetting().then(function (response) {
            if (response) {
                _this.showCategoryFilter = response;
            }
        }));
        filterPromises.push(this.itemService.getAllCategories({ searchText: "", chunkIndex: 0, businessIdentity: this.activeProfileService.businessIdentity }, "Item/GetAllCategories").then(function (result) {
            if (result) {
                _this.allCategories = result.ResultList;
            }
        }).catch(function (err) {
            _this.categoriesAvailable = false;
        }));
        filterPromises.push(this.spaceHelper.searchHelper({ searchText: "" }).then(function (result) {
            _this.allSpaces = _this.spaceHelper.resultList;
        }));
        //filterPromises.push(this.campaignHelper.searchHelper(<SearchInfo>{
        //    searchText: "", additionalQueryParams: [
        //        { param: "ShowActiveOnly", paramValue: "false" },
        //        { param: "ShowExportedOnly", paramValue: "false" },
        //        { param: "ShowCurrentFutureOnly", paramValue: "false" },
        //        { param: "RecordCount", paramValue: "20000" }
        //    ]
        //}, undefined, "Campaign").then((result) => {
        //    this.allCampaigns = this.campaignHelper.resultList;
        //}));
        filterPromises.push(this.campaignService.getList().then(function (response) {
            _this.allCampaigns = response;
        }));
        //Only allow All Locations for Admin users
        if (this.isInRole(["EcommSiteAdmin", "ShoppingAdmin"])) {
            promises.push(this.shopSettingsService.getAllLocationsSetting().then(function (response) {
                if (response) {
                    _this.showAllLocationsFilter = response;
                }
            }));
        }
        else {
            this.showAllLocationsFilter = false;
        }
        if (this.selectedCampaignId && this.selectedCampaignId !== 0) {
            promises.push(this.getSelectedCampaignName(this.selectedCampaignId));
        }
        if (this.selectedSpaceId && this.selectedSpaceId !== 0) {
            promises.push(this.getSelectedSpaceName(this.selectedSpaceId));
        }
        promises.push(this.shopSettingsService.getHideCampaignSetting().then(function (response) {
            if (response) {
                _this.hideCampaignFilters = response;
            }
        }));
        promises.push(this.brandBulletinService.getList().then(function (response) {
            if (response && response.length > 0) {
                var shopVal = response.find(function (q) { return q.Type === "Shop"; });
                if (shopVal && !_this.isNullOrSpaces(shopVal.Value)) {
                    _this.brandBulletin = shopVal;
                }
            }
        }));
        Promise.all(promises).then(function () {
            _this.screenLoaded = true;
        });
        Promise.all(filterPromises).then(function () {
            _this.getItemRelatedFilterData().then(function () {
                _this.searchFilterDataLoaded = true;
            });
        });
    };
    ProductSearchComponent.prototype.mergeFixtureItems = function (searchHelper, items) {
        var _a;
        if (items && items.length) {
            if (this.searchingByFixture) {
                // if we have a partial record in memory, then
                // patch the items from it into the first FixtureItemDataModel
                // and clear out the partial record
                if (this.partialFixtureItemRecord) {
                    (_a = items[0].Items).unshift.apply(_a, this.partialFixtureItemRecord.Items);
                    this.partialFixtureItemRecord = null;
                }
                // if the last item in the list has a partial item list, temporarily remove it and
                // store it in the partialFixtureItemRecord variable.  we'll patch its items in when
                // the next page is loaded
                var lastItemIsPartial = items[items.length - 1].HasPartialItemList;
                if (lastItemIsPartial) {
                    var removedItems = items.splice(items.length - 1, 1);
                    this.partialFixtureItemRecord = removedItems[0];
                }
            }
        }
    };
    ProductSearchComponent.prototype.setSearchType = function (searchType) {
        var previousSearchType = this.searchType;
        this.searchType = searchType;
        var searchTypeChanged = (previousSearchType !== searchType);
        if (searchTypeChanged) {
            this.partialFixtureItemRecord = null;
            this.setCategoriesAvailable();
            this.getListData(this.searchText, true, true);
        }
    };
    ProductSearchComponent.prototype.setCategoriesAvailable = function () {
        var _this = this;
        this.searchFilterDataLoaded = false;
        if (this.searchType === "Fixture") {
            this.itemService.searchingByFixture = true;
        }
        else {
            this.itemService.searchingByFixture = false;
        }
        this.itemService.getAllCategories({ searchText: "", chunkIndex: 0, businessIdentity: this.activeProfileService.businessIdentity }, "Item/GetAllCategories").then(function (result) {
            if (result) {
                _this.allCategories = result.ResultList;
                _this.setItemRelatedFilterLists();
                _this.searchFilterDataLoaded = true;
            }
        }).catch(function (err) {
            _this.categoriesAvailable = false;
        });
    };
    ProductSearchComponent.prototype.getItemRelatedFilterData = function () {
        var _this = this;
        this.searchFilterDataLoaded = false;
        var searchInfo = {
            searchText: "",
            chunkIndex: 0,
            businessIdentity: this.activeProfileService.businessIdentity
        };
        if (this.allCategories.length === 0) {
            this.setCategoriesAvailable();
        }
        if (this.selectedLocation && this.selectedLocation.Id > 0) {
            searchInfo.additionalQueryParams = [{ param: "LocationId", paramValue: this.selectedLocation.Id.toString() }];
        }
        var promise = new Promise(function (resolve, reject) {
            _this.itemService.getAllRelatedFilters(searchInfo).then(function (result) {
                if (result) {
                    _this.itemRelatedFilterData = result;
                    _this.setItemRelatedFilterLists();
                    resolve();
                    _this.searchFilterDataLoaded = true;
                }
            }).catch(function (err) {
                //TODO
            });
        });
        return promise;
    };
    ProductSearchComponent.prototype.setItemRelatedFilterLists = function () {
        var _this = this;
        this.preFilteredSpaces = this.allSpaces.filter(function (item) { return _this.itemRelatedFilterData.RelatedSpaceIds.indexOf(item.Id) >= 0; });
        this.preFilteredCategories = this.allCategories.filter(function (cat) { return _this.itemRelatedFilterData.RelatedCategoryIds.indexOf(cat.ExternalId) >= 0; });
        this.preFilteredCampaigns = this.allCampaigns.filter(function (item) { return _this.itemRelatedFilterData.RelatedCampaignIds.indexOf(item.Id) >= 0; });
        this.categoriesAvailable = this.preFilteredCategories.length > 0;
        this.spacesAvailable = this.preFilteredSpaces.length > 0;
        this.campaignsAvailable = this.preFilteredCampaigns.length > 0;
    };
    Object.defineProperty(ProductSearchComponent.prototype, "searchingByLocation", {
        get: function () {
            return this.searchType === "Location";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductSearchComponent.prototype, "searchingByFixture", {
        get: function () {
            return this.searchType === "Fixture";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductSearchComponent.prototype, "matchingRecordCount", {
        get: function () {
            return this.itemHelper.ResultChunkAttributes.TotalRecords || 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductSearchComponent.prototype, "showNoResults", {
        get: function () {
            return this.selectedLocation &&
                this.itemHelper.chunkIndex > 0 &&
                this.itemHelper.resultList.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    ProductSearchComponent.prototype.addLabelToLocationModel = function (model) {
        var returnObject = model;
        returnObject.Label = IpsString.Format("{0} - {1} - {2}, {3}", model.LocationIdentifier, model.Name, model.Addresses[0].City, model.Addresses[0].StateProvince);
        return returnObject;
    };
    ProductSearchComponent.prototype.getSelectedLocation = function (locationId) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            if (locationId && locationId !== -1) {
                _this.locationService.get(locationId).then(function (locationResponse) {
                    _this.selectedLocation = _this.addLabelToLocationModel(locationResponse);
                    _this.locationsAvailable = true;
                    resolve();
                });
            }
            else {
                _this.locationService.search({ searchText: "", chunkIndex: 0, recordCount: 1, businessIdentity: _this.activeProfileService.businessIdentity }, "Location/Search").then(function (locationSearchResponse) {
                    if (locationSearchResponse.ResultChunkAttributes.TotalRecords > 0) {
                        var firstLocation = locationSearchResponse.ResultList[0];
                        _this.selectedLocation = _this.addLabelToLocationModel(firstLocation);
                        _this.locationsAvailable = true;
                    }
                    resolve();
                });
            }
        });
        return promise;
    };
    ProductSearchComponent.prototype.getSelectedCampaignName = function (campaignId) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.campaignService.get(campaignId).then(function (response) {
                _this.selectedCampaignLabel = response.Name;
                resolve();
            });
        });
        return promise;
    };
    ProductSearchComponent.prototype.getSelectedSpaceName = function (spaceId) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.spaceService.get(spaceId).then(function (response) {
                _this.selectedSpaceLabel = response.Name;
                resolve();
            });
        });
        return promise;
    };
    ProductSearchComponent.prototype.chooseCategory = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "itemcategory", preFilteredList: this.preFilteredCategories } })
            .then(function (response) {
            _this.selectedCategory = response.item;
            _this.partialFixtureItemRecord = null;
            _this.getListData(_this.searchText, true);
        }, function () { }); // Rejected;
    };
    ProductSearchComponent.prototype.chooseLocation = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: this.showAllLocationsFilter, allLabel: "NO_LOCATION_SPECIFIED", search: "location" } })
            .then(function (response) {
            _this.selectedLocation = response.item;
            _this.partialFixtureItemRecord = null;
            if (_this.selectedLocation.Id === 0) {
                _this.selectedLocation.Id = -1;
                _this.enableSpace = false;
                _this.enableCampaign = false;
                _this.enableProductsByFixture = false;
                var allLabel = _this.translateService.instant("ALL_UPPERCASE");
                _this.selectedSpaceId = 0;
                _this.selectedSpaceLabel = allLabel;
                _this.selectedCampaignId = 0;
                _this.selectedCampaignLabel = allLabel;
                _this.searchType = "Location";
            }
            else {
                _this.enableSpace = true;
                _this.enableCampaign = true;
                _this.enableProductsByFixture = true;
            }
            if (_this.selectedLocation.Id > 0) {
                _this.getItemRelatedFilterData();
            }
            _this.getListData(_this.searchText, true);
        }, function () { }); // Rejected;
    };
    ProductSearchComponent.prototype.chooseCampaign = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, activeOnly: true, search: "campaign", preFilteredList: this.preFilteredCampaigns } })
            .then(function (response) {
            _this.selectedCampaignId = response.item.Id;
            _this.selectedCampaignLabel = response.item.Label;
            _this.partialFixtureItemRecord = null;
            _this.getListData(_this.searchText, true);
        }, function () { }); // Rejected;
    };
    ProductSearchComponent.prototype.chooseSpace = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "space", preFilteredList: this.preFilteredSpaces } })
            .then(function (response) {
            _this.selectedSpaceId = response.item.Id;
            _this.selectedSpaceLabel = response.item.Label;
            _this.partialFixtureItemRecord = null;
            _this.getListData(_this.searchText, true);
        }, function () { }); // Rejected;
    };
    ProductSearchComponent.prototype.getListData = function (search, forceReload, updateQueryString, scrollInfo) {
        var _this = this;
        if (forceReload === void 0) { forceReload = false; }
        if (updateQueryString === void 0) { updateQueryString = true; }
        var searchInfo = {
            searchText: search || ""
        };
        if (this.searchingByFixture) {
            this.itemService.searchingByFixture = true;
        }
        else {
            this.itemService.searchingByFixture = false;
        }
        //If it is the All case then send a -1 to the backend
        searchInfo.additionalQueryParams = [{ param: "LocationId", paramValue: this.selectedLocation.Id.toString() }];
        if (this.selectedSpaceId) {
            searchInfo.additionalQueryParams.push({ param: "SpaceId", paramValue: this.selectedSpaceId.toString() });
        }
        if (this.selectedCampaignId) {
            searchInfo.additionalQueryParams.push({ param: "CampaignId", paramValue: this.selectedCampaignId.toString() });
        }
        if (this.selectedCategory && this.selectedCategory.ExternalId) {
            searchInfo.additionalQueryParams.push({ param: "CategoryId", paramValue: this.selectedCategory.ExternalId.toString() });
        }
        this.promise = this.itemHelper.searchHelper(searchInfo, forceReload, undefined)
            .then(function (response) {
            if (scrollInfo) {
                scrollInfo.chunkIndex--;
                if (scrollInfo.chunkIndex > 0) {
                    _this.getListData(search, false, false, scrollInfo);
                }
                else {
                    setTimeout(function () { window.scrollTo(0, scrollInfo.positionY); }, 500);
                }
            }
            if (updateQueryString) {
                _this.updateQueryString();
            }
            // Return for the promise
            return true;
        });
    };
    ProductSearchComponent.prototype.onQueryChange = function (query) {
        this.searchText = query;
        this.querySubject.next(query);
    };
    ProductSearchComponent.prototype.processQueryParams = function (parms) {
        if (parms.locationId) {
            this.selectedLocationId = Number(parms.locationId);
        }
        if (parms.campaignId) {
            this.selectedCampaignId = Number(parms.campaignId);
        }
        if (parms.searchType) {
            this.searchType = parms.searchType;
        }
        if (parms.searchText) {
            this.searchText = decodeURIComponent(parms.searchText);
        }
        if (parms.spaceId) {
            this.selectedSpaceId = Number(parms.spaceId);
        }
    };
    ProductSearchComponent.prototype.getImageUrl = function (media) {
        var mediaToUse = media;
        if (!media) {
            mediaToUse = this.getDefaultImage();
        }
        else {
            if (!media.Id || !media.Id.Id || this.isBlankGuid(media.Id.Id)) {
                mediaToUse = this.getDefaultImage();
            }
        }
        return this.buildImageUrl(mediaToUse);
    };
    ProductSearchComponent.prototype.isBlankGuid = function (guid) {
        var blankGuid = "00000000-0000-0000-0000-00000000";
        return guid === blankGuid;
    };
    ProductSearchComponent.prototype.getDefaultImage = function () {
        var baseUrl = this.itemService.urlApiBase;
        var returnModel = {
            Id: null,
            FileName: "thumbnail.jpg",
            FileExtension: "jpg",
            ResourceUri: baseUrl + "Media/v1/MasterMedia/PlaceHolder/" + this.activeProfileService.businessIdentity + "/LocationFixture/Thumbnail/Jpg",
            MimeType: null,
            Measurement: null,
            Content: null,
            ContentSize: null
        };
        return returnModel;
    };
    ProductSearchComponent.prototype.buildImageUrl = function (media) {
        if (media.ResourceUri.indexOf("BusinessIdentity") === -1) {
            media.ResourceUri = IpsString.Format("{0}?BusinessIdentity={1}&idToken={2}", media
                .ResourceUri, this.activeProfileService.businessIdentity, this.authService.getIdToken());
        }
        return media.ResourceUri;
    };
    ProductSearchComponent.prototype.updateQueryString = function () {
        var queryParams = {};
        queryParams = {
            searchType: this.searchType,
            searchText: this.searchText,
            locationId: this.selectedLocation.Id
        };
        if (this.selectedCampaignId) {
            queryParams.campaignId = this.selectedCampaignId;
        }
        if (this.selectedSpaceId) {
            queryParams.spaceId = this.selectedSpaceId;
        }
        this.helperService.updateURLParameters(queryParams);
    };
    ProductSearchComponent.prototype.onWindowScroll = function ($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        }
        else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    };
    ProductSearchComponent.prototype.ngOnDestroy = function () {
        this.saveSearchInfo();
    };
    ProductSearchComponent.prototype.saveSearchInfo = function () {
        if (this.itemHelper) {
            localStorage.setItem(this.searchScrollKey, JSON.stringify({
                search: this.searchText || null,
                locationId: this.selectedLocation.Id,
                positionY: this.scrollY,
                chunkIndex: this.itemHelper.chunkIndex
            }));
        }
    };
    ProductSearchComponent.prototype.isInRole = function (roleNames) {
        var allThemes = this.activeProfileService.profile.businessIdentityList;
        var allRoles = [];
        allThemes.forEach(function (theme) {
            allRoles.push.apply(allRoles, theme.Role);
        });
        var matchingRoles = allRoles.filter(function (item) { return roleNames.indexOf(item) >= 0; });
        return matchingRoles.length > 0;
    };
    ProductSearchComponent.prototype.isNullOrSpaces = function (str) {
        return !!str === false || str.match(/^ *$/) !== null;
    };
    return ProductSearchComponent;
}());
export { ProductSearchComponent };
