<div class="col-md-12" [ngClass]="{'col-xl-10': fullImage, 'col-lg-8 col-xl-6': !fullImage}">
    <div class="row">
        <section class="col-12">
            <h3 class="title">
                <span class="header-label-imgn">{{'VIEW_LOCATION' | translate}}</span>
                <a class="ml-2" uiSref="main.storeProfileLocation.edit" [uiParams]="{id: location.Id}" ngbTooltip="{{'EDIT' | translate}} {{location.Name}}" placement="bottom" ipsPermission="main.storeProfileLocation.edit"><span class="fa fa fa-pencil editIcon"></span></a>
                <span *ngIf="enableSignPlan" class="ml-2 fa fa fa-object-group editIcon" ngbTooltip="Download Sign Plan for {{location.Name}}" placement="bottom" (click)="chooseCampaign()"></span>
            </h3>
        </section>
    </div>

    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_LOCATION_INFO' | translate}}}">
        <div class="row" *ngIf="loaded">
            <section class="col-sm">
                <div class="form-group">

                    <div class="card">
                        <div class="card-header view-info-header d-flex">
                            <span class="pr-3">{{location.LocationIdentifier}} - {{location.Name}}</span><div class="{{locationStatusInfo.IconClass}}" ngbTooltip="{{locationStatusInfo.Tooltip | translate}}" placement="bottom"></div>
                        </div>
                        <div class="form-row">
                            <div class="col" [ngClass]="{'col-lg-8 order-lg-first order-last pr-lg-0': fullImage}">
                                <div class="card-body">
                                    <ul class="list-group long-text-wrap">
                                        <li class="list-group-item">
                                            <div class="view-info-label" translate>FIRST_RECEIPT_DATE</div>
                                            <div class="view-info-add-margin">{{location.InitiatePromotionsDate | date : "mediumDate" : "+0000"}}</div>
                                            <div class="view-info-label" translate>OPEN_DATE</div>
                                            <div class="view-info-add-margin">
                                                {{location.OpenDate | date : "mediumDate" : "+0000"}}
                                                <p *ngIf="showOpenDateEmptyWarning" class="text-warning"><b>{{information}}: </b>{{openDateEmptyWarning}}</p>
                                                <p *ngIf="showOpenDateAfterSuspendDateWarning" class="text-warning mb-0 mt-2"><b>{{information}}: </b>{{openDateAfterSuspendDateWarning}}</p>
                                            </div>
                                            <div class="view-info-label" translate>SUSPEND_DATE</div>
                                            <div class="view-info-add-margin">
                                                {{location.SuspendPromotionsDate | date : "mediumDate" : "+0000"}}
                                                <p *ngIf="showOpenDateAfterSuspendDateWarning" class="text-warning mb-0 mt-2"><b>{{information}}: </b>{{suspendDateBeforeOpenDateWarning}}</p>
                                            </div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="view-info-label" translate>POINT_OF_CONTACT</div>
                                            <div class="view-info-add-margin">{{location.PointOfContact}}</div>
                                            <div class="view-info-label" translate>ADDRESS</div>
                                            <div class="view-info-add-margin">
                                                <span class="view-info-label">{{'ATTENTION_TO' | translate}}</span><span class="view-info-format-inline">{{address.Attention}}</span><br />
                                                <span>{{address.Line1}}</span><br />
                                                <span *ngIf="address.Line2"><span>{{address.Line2}}</span><br /></span>
                                                <span>{{address.City}}, {{address.StateProvince}} {{address.PostalCode}} {{address.Country}}</span>
                                                <br />
                                                <p *ngIf="address.ValidationStatus == 'Skipped'" class="text-warning mb-0 mt-2"><b>{{information}}: </b>{{addressInvalidWarning}}</p>
                                                <p *ngIf="address.ValidationStatus == 'Unknown'" class="text-warning mb-0 mt-2"><b>{{information}}: </b>{{addressNotValidated}}</p>
                                            </div>
                                            <div class="view-info-label" translate>CAMPAIGN_ALTERNATE_ADDRESS</div>
                                            <div class="view-info-add-margin">{{campaignShippingAddress | translate}}</div>
                                            <div class="view-info-label" translate>PHONE</div>
                                            <div class="view-info-add-margin">{{address.Phone | tel}}</div>
                                            <div class="view-info-label" translate>FAX</div>
                                            <div class="view-info-add-margin">{{address.Fax | tel}}</div>
                                            <div class="view-info-label" translate>EMAIL</div>
                                            <div class="view-info-add-margin">
                                                <a [href]="'mailto:' + address.Email" target="_blank"><span>{{address.Email}}</span></a>
                                            </div>
                                            <div class="view-info-label" translate>REFERENCE_IDENTIFIER</div>
                                            <div class="view-info-add-margin">{{location.ReferenceIdentifier}}</div>
                                        </li>
                                        <li class="list-group-item">
                                            <div class="view-info-label" translate>NOTES_LOCATION</div>
                                            <div class="view-info-format-imgn">{{location.Notes}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 loc-view-image pl-lg-0" *ngIf="fullImage">
                                <ips-image-view [imgThumbUrl]="fullImage" [imgFullUrl]="fullImage"></ips-image-view>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <hr>
        <div class="row" id="relatedLocationGroups" *ngIf="loaded">
            <section class="col box pb-0" ipsPermission="main.storeProfileLocationGroup.search">
                <ips-list-search headerLabel="{{'RELATED_FEATURE_OPTIONS' | translate }}"
                                 searchLabel="{{'LOCATION_GROUP' | translate }}"
                                 searchLabelPlural="{{'LOCATION_GROUPS' | translate }}"
                                 labelLoading="{{'ACCESSING_LOCATION_GROUP_INFO' | translate }}"
                                 labelLoadingError="{{'ERROR_ACCESSING_LOCATION_GROUP_INFO' | translate }}"
                                 [listData]="qrLocationGroup"
                                 [busy]="busy"
                                 (onListDataChange)="getLocationGroupList($event.search, location.Id, $event.filterOption)"
                                 [scroller]=false
                                 [quickRef]=true
                                 [filterOptions]="optionList"
                                 [selectedFilterOption]="selectedOption"
                                 itemClickPermission="main.storeProfileLocationGroup.search"
                                 [promise]="qrLocationGroupPromise"
                                 [isCollapsible]=true
                                 (onCollapsibleClick)="relatedLocationGroupCollapse()"
                                 [translatedTexts]="translatedTexts">
                    <ng-template #listSearchItemTemplate let-result="result">
                        <app-location-group-related-list srefState="main.storeProfileLocationGroup.search" [locationId]="location.Id" (srefParamsChanged)="relatedLocationGroupSrefParamsChanged($event)" [result]="result"></app-location-group-related-list>
                    </ng-template>
                </ips-list-search>
            </section>
        </div>

        <div class="row" id="relatedFixtures" *ngIf="loaded">
            <section class="col box pb-0">
                <ips-list-search headerLabel="{{'RELATED_FIXTURES' | translate }}"
                                 searchLabel="{{'FIXTURES' | translate }}"
                                 searchLabelPlural="{{'FIXTURES' | translate }}"
                                 labelLoading="{{'ACCESSING_FIXTURE_INFO' | translate }}"
                                 labelLoadingError="{{'ERROR_ACCESSING_FIXTURE_INFO' | translate }}"
                                 [listData]="qrFixture"
                                 [busy]="busy"
                                 (onListDataChange)="getFixtureList($event.search, location.Id)"
                                 [scroller]=false
                                 [quickRef]=true
                                 itemClickPermission="main.storeProfileFixture.search"
                                 [promise]="qrFixturePromise"
                                 [isCollapsible]=true
                                 (onCollapsibleClick)="relatedFixtureCollapse()"
                                 [translatedTexts]="translatedTexts">
                    <ng-template #listSearchItemTemplate let-result="result">
                        <a uiSref="main.storeProfileFixture.search" [uiParams]="{fixtureId: result.Id, locationId: location.Id}" ipsPermission="main.storeProfileFixture.search" ipsPermissionLinkDisable="true" class="list-group-item list-group-item-action no-border">
                            <span>
                                <span>{{result.Label}}</span> <span ngbTooltip="{{'TOOLTIP_LOCATION_FIXTURE_COUNT' | translate}}" placement="bottom">({{result.Count}})</span>
                            </span>
                        </a>
                    </ng-template>
                </ips-list-search>
            </section>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
