import { OnInit, Renderer2, TemplateRef } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { DecimalPipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PageTitleService, IpsImageEditComponent } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { AuthService } from "imagine-ui-ng-security";
import { String as IpsString } from "typescript-string-operations";
import { FixtureService, SpaceService, HolderVersionService, FixtureGroupService, LocationByFixtureService } from "../../index";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SignPlanService } from "../../../shared/service/sign-plan.service";
var FixtureEditComponent = /** @class */ (function () {
    function FixtureEditComponent(transition, $state, translateService, fixtureService, ipsMessage, spaceService, holderVersionService, fixtureGroupService, pageTitleService, locationByFixtureService, numberPipe, activeProfileService, authService, settingsService, formBuilder, renderer, modalService, signPlanService) {
        var _this = this;
        this.transition = transition;
        this.$state = $state;
        this.translateService = translateService;
        this.fixtureService = fixtureService;
        this.ipsMessage = ipsMessage;
        this.spaceService = spaceService;
        this.holderVersionService = holderVersionService;
        this.fixtureGroupService = fixtureGroupService;
        this.pageTitleService = pageTitleService;
        this.locationByFixtureService = locationByFixtureService;
        this.numberPipe = numberPipe;
        this.activeProfileService = activeProfileService;
        this.authService = authService;
        this.settingsService = settingsService;
        this.formBuilder = formBuilder;
        this.renderer = renderer;
        this.modalService = modalService;
        this.signPlanService = signPlanService;
        this.showStats = false;
        this.showVeracoreOfferId = false;
        this.hasShownWarning = false;
        this.enableSignPlanLayout = false;
        this.emptyGuid = "00000000-0000-0000-0000-000000000000";
        this.attachedDocuments = [];
        this.signPlanWidthOptions = [];
        this.editing = false;
        this.deleting = false;
        this.processing = false;
        this.errorMessages = {
            "required": function () { return _this.requiredMessage; },
            "pattern": function () { return _this.numericValueRequiredMessage; },
            "maxlength": function (params) { return IpsString.Format(_this.textMaxLength, params.requiredLength); },
            "numericValueRequiredMessage": function () { return _this.numericValueRequiredMessage; },
            "tooManyDecimalPlacesMessage": function () { return _this.tooManyDecimalPlacesMessage; },
            "decimalNumberOnly": function () { return _this.decimalNumberOnly; }
        };
        this.canDeleteErrorList = [];
        this.placedHolders = [];
        this.signPlanWidthOptions = this.signPlanService.SignPlanWidthOptions;
    }
    FixtureEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authToken = this.authService.getIdToken();
        this.businessId = this.activeProfileService.businessIdentity;
        var fixtureId = Number(this.transition.params().id);
        this.id = fixtureId || 0;
        var pageTitle = fixtureId > 0 ? "EDIT_FIXTURE" : "CREATE_FIXTURE";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);
        this.spaceList = null;
        this.versionList = null;
        this.fixture = null;
        this.fgList = null;
        this.loaded = false;
        this.files = null;
        var promises = [];
        this.createForm();
        this.getSignPlanLayoutSetting().finally(function () {
            //If we got an ID to load, load it.
            if (Number(fixtureId) > 0) {
                //Initial call to populate screen on load
                promises.push(_this.getfixture(fixtureId));
            }
            else {
                _this.hasShownWarning = true;
            }
            //Populate the select inputs
            promises.push(_this.getSpaceList());
            promises.push(_this.getAttachedDocuments());
            promises.push(_this.getFixtureGroupList());
            promises.push(_this.getVersionList());
            var canDelete = _this.fixtureService.deleteCheck(_this.id.toString())
                .then(function (response) {
                _this.canDeleteErrorList = response;
            })
                .catch(function (response) {
                _this.canDeleteErrorList = ["-ERROR-"];
            });
            promises.push(canDelete);
            _this.promise = Promise.all(promises)
                .then(function (data) {
                if (Number(fixtureId) > 0) {
                    _this.populateFormHolders(_this.fixture.Holders);
                }
                _this.loaded = true;
            });
            _this.defaultImage = _this.fixtureService.getDefaultImage("LocationFixture");
            _this.imageEdit.thumbnail = _this.defaultImage;
            _this.translateText();
            _this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        });
        //show veracore offer id only for Southeasten grocers = Bsns.00000144
        this.showVeracoreOfferId = this.activeProfileService.businessIdentity === "Bsns.00000144";
    };
    FixtureEditComponent.prototype.getWidthLabel = function (value) {
        return this.signPlanWidthOptions.find(function (option) { return option.value === value; }).key;
    };
    FixtureEditComponent.prototype.onDragEnd = function (event, holder) {
        holder.Left = event.position.x;
        holder.Top = event.position.y;
        this.fixtureForm.markAsDirty();
    };
    FixtureEditComponent.prototype.onResizeEnd = function (event, holder) {
        holder.Height = event.size.height;
        holder.Width = event.size.width;
        this.fixtureForm.markAsDirty();
    };
    FixtureEditComponent.prototype.onSignPlanTransformation = function (e, holderDetail) {
        var value = {
            cropTop: e.top,
            cropLeft: e.left,
            cropHeight: e.height,
            cropWidth: e.width,
            cropRotation: e.rotation
        };
        holderDetail.patchValue(value);
        holderDetail.markAsDirty();
    };
    FixtureEditComponent.prototype.attachmentUrl = function (document) {
        return this.fixtureService.getAttachmentMediaUrl(document.MasterMediaId);
    };
    FixtureEditComponent.prototype.getAttachedDocuments = function () {
        var _this = this;
        return this.fixtureService.getAttachedDocuments(this.id).then(function (response) {
            _this.attachedDocuments = response;
        });
    };
    FixtureEditComponent.prototype.deleteAttachment = function (document) {
        var _this = this;
        return this.ipsMessage.waitForWork({
            body: "Deleting Attachment",
            workFunction: function () { return _this.deleteAttachmentWorker(document); },
            progressMessage: "Deleting..."
        }, null)
            .then(function (result) {
            if (result) {
                _this.getAttachedDocuments();
            }
        });
    };
    FixtureEditComponent.prototype.deleteAttachmentWorker = function (document) {
        return this.fixtureService.deleteAttachment(document.Id);
    };
    FixtureEditComponent.prototype.promptEdit = function (document) {
        this.editing = false;
        if (document) {
            this.editTarget = document;
            this.editing = true;
        }
        else {
            this.editTarget = {
                Id: 0,
                LinkId: this.id,
                LinkType: 3,
                LinkSubType: 0,
                SubmittedBy: "",
                SubmittedDateTime: new Date(),
                MasterMediaId: this.emptyGuid,
                MediaType: 0,
                Label: "",
                Label2: "",
                Width: 0
            };
        }
        this.modalInstance = this.modalService.displayComponentAndReturnInstance(this.editTemplate, null, { size: "lg", backdrop: "static" });
    };
    FixtureEditComponent.prototype.cancelEdit = function () {
        this.modalInstance.close();
        this.editing = false;
        this.editTarget = null;
    };
    FixtureEditComponent.prototype.onSaveSuccess = function () {
        this.getAttachedDocuments();
        this.processing = false;
        this.modalInstance.close();
        this.processingError = null;
    };
    FixtureEditComponent.prototype.onProcessingError = function (message) {
        this.processingError = message;
        this.processing = false;
    };
    FixtureEditComponent.prototype.performSave = function (model) {
        var _this = this;
        this.fixtureService.addAttachment(model, this.additionalImageFiles).then(function (response) {
            _this.onSaveSuccess();
        }).catch(function (response) {
            _this.onProcessingError(response.Message || response.statusText);
        });
    };
    FixtureEditComponent.prototype.performDelete = function (model) {
        var _this = this;
        this.fixtureService.deleteAttachment(model.Id).then(function (response) {
            _this.onDeleteSuccess();
        }).catch(function (response) {
            _this.onProcessingError(response.Message);
        });
    };
    FixtureEditComponent.prototype.save = function () {
        this.processing = true;
        this.processingError = null;
        if (this.editTarget.Label === null || this.editTarget.Label === "") {
            this.processingError = "Missing Required Label.";
            this.processing = false;
            return;
        }
        if (this.editTarget.Label2 === null || this.editTarget.Label2 === "") {
            this.processingError = "Missing Required Label 2.";
            this.processing = false;
            return;
        }
        if (this.editTarget.Width === 0) {
            this.processingError = "Missing Required Width.";
            this.processing = false;
            return;
        }
        if (this.additionalImageFiles === undefined) {
            this.processingError = "Missing Required Image.";
            this.processing = false;
            return;
        }
        this.performSave(this.editTarget);
    };
    FixtureEditComponent.prototype.promptDelete = function (document) {
        this.deletionTarget = document;
        if (this.deletionTarget) {
            this.modalInstance = this.modalService.displayComponentAndReturnInstance(this.deleteTemplate, null, { backdrop: "static" });
        }
    };
    FixtureEditComponent.prototype.cancelDelete = function () {
        this.modalInstance.close();
    };
    FixtureEditComponent.prototype.onDeleteSuccess = function () {
        this.processing = false;
        this.getAttachedDocuments();
        this.modalInstance.close();
        this.deletionTarget = null;
    };
    FixtureEditComponent.prototype.delete = function () {
        this.processing = true;
        this.processingError = null;
        this.performDelete(this.deletionTarget);
    };
    FixtureEditComponent.prototype.getSignPlanLayoutSetting = function () {
        var _this = this;
        return this.settingsService.canEditSignPlanLayout().then(function (response) {
            _this.enableSignPlanLayout = response;
        });
    };
    FixtureEditComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(["ENTER_DECIMAL_NUMBER", "TOO_MANY_DECIMAL_PLACES", "PLEASE_ENTER_NUMBER", "FIXTURE_DELETE", "FIXTURE_DELETE_MANY", "PROMPT_DELETE_BODY",
            "MAX_LENGTH_ERROR", "PLEASE_ENTER_NAME", "PLEASE_ENTER_WIDTH", "PLEASE_ENTER_HEIGHT", "PLEASE_ENTER_QUANTITY", "FIELD_REQUIRED", "PLEASE_SELECT_SPACE",
            "PLEASE_SELECT_FIXTURE_GROUP"]).subscribe(function (res) {
            _this.numericValueRequiredMessage = res["PLEASE_ENTER_NUMBER"];
            _this.tooManyDecimalPlacesMessage = res["TOO_MANY_DECIMAL_PLACES"];
            _this.decimalNumberOnly = res["ENTER_DECIMAL_NUMBER"];
            _this.fixtureDeleteLabel = res["FIXTURE_DELETE"];
            _this.fixtureDeleteManyLabel = res["FIXTURE_DELETE_MANY"];
            _this.promptDeleteBodyLabel = res["PROMPT_DELETE_BODY"];
            _this.textMaxLength = res["MAX_LENGTH_ERROR"];
            _this.requiredFixtureName = res["PLEASE_ENTER_NAME"];
            _this.requiredWidth = res["PLEASE_ENTER_WIDTH"];
            _this.requiredHeight = res["PLEASE_ENTER_HEIGHT"];
            _this.requiredQuantity = res["PLEASE_ENTER_QUANTITY"];
            _this.requiredSpace = res["PLEASE_SELECT_SPACE"];
            _this.requiredFixtureGroup = res["PLEASE_SELECT_FIXTURE_GROUP"];
            _this.requiredMessage = res["FIELD_REQUIRED"];
        });
    };
    FixtureEditComponent.prototype.getErrorMessage = function (key) {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "name":
                    msgs["required"] = function () { return _this.requiredFixtureName; };
                    break;
                case "width":
                    msgs["required"] = function () { return _this.requiredWidth; };
                    break;
                case "height":
                    msgs["required"] = function () { return _this.requiredHeight; };
                    break;
                case "quantity":
                    msgs["required"] = function () { return _this.numericValueRequiredMessage; };
                    break;
                case "space":
                    msgs["required"] = function () { return _this.requiredSpace; };
                    break;
                case "fixturegroup":
                    msgs["required"] = function () { return _this.requiredFixtureGroup; };
                    break;
                default:
            }
        }
        return msgs;
    };
    FixtureEditComponent.prototype.createForm = function () {
        this.fixtureName = this.formBuilder.control("", [Validators.required, Validators.maxLength(50)]);
        this.fixtureMakeModel = this.formBuilder.control("", Validators.maxLength(345));
        this.notes = this.formBuilder.control("", Validators.maxLength(500));
        this.offerId = this.formBuilder.control("", Validators.maxLength(50));
        // Initialize to null in order to make IE default to empty selection.
        this.spaceSelect = this.formBuilder.control(null, Validators.required);
        this.versionSelect = this.formBuilder.control(null);
        this.fixtureGroupSelect = this.formBuilder.control(null, Validators.required);
        this.isObsolete = this.formBuilder.control(false);
        this.fixtureForm = this.formBuilder.group({
            id: [0],
            businessIdentity: [this.activeProfileService.businessIdentity],
            fixtureName: this.fixtureName,
            fixtureMakeModel: this.fixtureMakeModel,
            offerId: this.offerId,
            notes: this.notes,
            isObsolete: this.isObsolete,
            spaceSelect: this.spaceSelect,
            versionSelect: this.versionSelect,
            fixtureGroupSelect: this.fixtureGroupSelect,
            holders: this.formBuilder.array([]),
            masterMediaId: this.formBuilder.control(this.emptyGuid),
            mediaType: this.formBuilder.control(0),
            layoutImageMasterMediaId: this.formBuilder.control(this.emptyGuid),
            layoutImageMediaType: this.formBuilder.control(0),
            signPlanWidth: this.formBuilder.control(this.signPlanWidthOptions[0].value)
        });
    };
    FixtureEditComponent.prototype.getfixture = function (id) {
        var _this = this;
        return this.fixtureService.getWithUnassignedHolders(id)
            .then(function (fixture) {
            //Store off the original values
            _this.originalFixture = Object.assign({}, fixture);
            _this.populateThumbnail(fixture);
            _this.populateLayoutThumbnail(fixture);
            _this.fixture = fixture;
            _this.fixtureForm.patchValue({
                id: fixture.Id,
                businessIdentity: fixture.BusinessIdentity,
                fixtureGroupId: fixture.FixtureGroupId,
                fixtureName: fixture.Name,
                isObsolete: fixture.IsObsolete,
                offerId: fixture.VeracoreOfferId,
                fixtureMakeModel: fixture.Model,
                notes: fixture.Notes,
                masterMediaId: fixture.MasterMediaId,
                mediaType: fixture.MediaType,
                layoutImageMasterMediaId: fixture.LayoutImageMasterMediaId,
                layoutImageMediaType: fixture.LayoutImageMediaType,
                spaceSelect: { Id: fixture.SpaceId, Name: fixture.SpaceName, Notes: "", BusinessIdentity: fixture.BusinessIdentity },
                fixtureGroupSelect: { Id: fixture.FixtureGroupId, Name: fixture.FixtureGroupName, Notes: "", BusinessIdentity: fixture.BusinessIdentity, HasFixtureReference: true, Holders: [] },
                signPlanWidth: fixture.SignPlanWidth
            });
        }).then(function () {
            if (_this.enableSignPlanLayout) {
                _this.fixtureService.getLayoutDetails(id).then(function (response) {
                    _this.populateSignPlanData(response);
                });
            }
        });
    };
    FixtureEditComponent.prototype.populateFormHolders = function (holderList) {
        var _this = this;
        var holders = holderList.map(function (holder) {
            var fg = _this.formBuilder.group({
                id: [holder.Id],
                fixtureId: [holder.FixtureId],
                businessIdentity: [holder.BusinessIdentity],
                width: [holder.Size.Width, _this.numberRequired],
                height: [holder.Size.Height, _this.numberRequired],
                unit: [holder.Size.Unit],
                quantity: [holder.Quantity, [Validators.required, Validators.pattern("^(0|[1-9][0-9]*)$")]],
                holderName: [holder.Name],
                versionSelect: holder.HolderVersionInfoId ? [_this.getSelectedHolderVersion(holder.HolderVersionInfoId)] : "",
                isObsolete: [holder.IsObsolete],
                cropTop: holder.CropTop,
                cropLeft: holder.CropLeft,
                cropHeight: holder.CropHeight,
                cropWidth: holder.CropWidth,
                cropRotation: holder.CropRotation,
                categoryCode: holder.CategoryCode
            });
            return fg;
        });
        this.fixtureForm.setControl("holders", this.formBuilder.array(holders));
    };
    FixtureEditComponent.prototype.onImageUploaded = function (file) {
        if (file) {
        }
        else {
            this.resetAll();
        }
    };
    FixtureEditComponent.prototype.populateSignPlanData = function (holderLayoutData) {
        if (holderLayoutData && holderLayoutData.length > 0) {
            this.placedHolders = holderLayoutData.map(function (item) {
                item.Placed = true;
                return item;
            });
            this.originalFixtureGroupId = holderLayoutData[0].FixtureGroupId;
            this.mergeSignHolderData(this.fixture.Holders);
        }
        else {
            this.placedHolders = [];
            this.originalFixtureGroupId = this.fixtureForm.value.fixtureGroupId;
            this.mergeSignHolderData(this.fixture.Holders);
        }
    };
    FixtureEditComponent.prototype.allHoldersPlaced = function () {
        return this.placedHolders.find(function (item) { return !item.Placed; }) == null;
    };
    FixtureEditComponent.prototype.resetAll = function () {
        var _this = this;
        this.placedHolders.forEach(function (holder) {
            _this.resetPlacement(holder);
        });
    };
    FixtureEditComponent.prototype.resetPlacement = function (holder) {
        holder.Placed = false;
        holder.Height = 0;
        holder.Width = 0;
        holder.Left = 0;
        holder.Top = 0;
    };
    FixtureEditComponent.prototype.getImgSize = function (imgSrc) {
        var newImg = new Image();
        var promise = new Promise(function (resolve, reject) {
            newImg.onload = function () {
                var height = newImg.height;
                var width = newImg.width;
                resolve({
                    height: height,
                    width: width
                });
            };
            newImg.src = imgSrc; // this must be done AFTER setting onload
        });
        return promise;
    };
    FixtureEditComponent.prototype.populateSignHolders = function () {
        var _this = this;
        var hasFixtureGroupChanged = this.fixtureForm.value.fixtureGroupSelect.Id !== this.originalFixtureGroupId;
        if (hasFixtureGroupChanged) {
            this.placedHolders = [];
            this.fixtureForm.value.holders.forEach(function (item) {
                for (var index = 0; index < item.quantity; index++) {
                    _this.placedHolders.push({
                        Id: 0,
                        HolderName: item.holderName,
                        Index: index,
                        FixtureId: item.fixtureId,
                        HolderId: item.id,
                        Left: 0,
                        Top: 0,
                        Height: 0,
                        Width: 0,
                        Placed: false,
                        Rotation: 0,
                        BackgroundColor: "#790123"
                    });
                }
            });
        }
    };
    // called when initially loading data.  merges placed holder layout data with any unplaced holder layouts (derived from holder data)
    FixtureEditComponent.prototype.mergeSignHolderData = function (holders) {
        var _this = this;
        holders.forEach(function (item) {
            var _loop_1 = function (index) {
                var existingHolder = _this.placedHolders.find(function (holder) { return holder.HolderId === item.Id && holder.Index === index; });
                if (!existingHolder) {
                    _this.placedHolders.push({
                        Id: 0,
                        HolderName: item.Name,
                        Index: index,
                        FixtureId: item.FixtureId,
                        HolderId: item.Id,
                        Left: 0,
                        Top: 0,
                        Height: 0,
                        Width: 0,
                        Placed: false,
                        Rotation: 0,
                        BackgroundColor: "#790123"
                    });
                }
            };
            for (var index = 0; index < item.Quantity; index++) {
                _loop_1(index);
            }
        });
    };
    FixtureEditComponent.prototype.holderQuantityChanged = function (id, item) {
        this.placedHolders = [];
        this.populateSignHolders();
        var heightField = item.get("height");
        var widthField = item.get("width");
        heightField.markAsTouched();
        widthField.markAsTouched();
        heightField.updateValueAndValidity();
        widthField.updateValueAndValidity();
    };
    FixtureEditComponent.prototype.getSelectedHolderVersion = function (id) {
        var v = this.versionList.filter(function (x) { return x.Id === id; });
        return v[0];
    };
    FixtureEditComponent.prototype.populateThumbnail = function (fixture) {
        this.fullSizeImage = fixture.Media.ResourceUri + "?BusinessIdentity=" + this.activeProfileService.businessIdentity + "&idToken=" + this.authService.getIdToken();
        this.imageEdit.thumbnail = this.fullSizeImage.replace("Original", "Thumbnail");
        //Set text of image button
        this.imageEdit.selectedImage = this.imageEdit.thumbnail.indexOf("/PlaceHolder/") === -1;
    };
    FixtureEditComponent.prototype.getImageRatio = function () {
        if (this.enableSignPlanLayout && this.layoutImageEdit) {
            this.originalWidth = this.layoutImageEdit.fileImage.nativeElement.naturalWidth;
            this.originalHeight = this.layoutImageEdit.fileImage.nativeElement.naturalHeight;
            this.renderedWidth = this.layoutImageEdit.fileImage.nativeElement.offsetWidth;
            this.renderedHeight = this.layoutImageEdit.fileImage.nativeElement.offsetHeight;
            return {
                x: this.renderedWidth / this.originalWidth,
                y: this.renderedHeight / this.originalHeight
            };
        }
        else {
            return {
                x: 1,
                y: 1
            };
        }
    };
    FixtureEditComponent.prototype.populateLayoutThumbnail = function (fixture) {
        if (this.enableSignPlanLayout && this.layoutImageEdit) {
            this.fullSizeImage = fixture.LayoutMedia.ResourceUri + "?BusinessIdentity=" + this.activeProfileService.businessIdentity + "&idToken=" + this.authService.getIdToken();
            this.layoutImageEdit.thumbnail = this.fullSizeImage;
            //Set text of image button
            this.layoutImageEdit.selectedImage = this.layoutImageEdit.thumbnail.indexOf("/PlaceHolder/") === -1;
        }
    };
    FixtureEditComponent.prototype.getSpaceList = function () {
        var _this = this;
        return this.spaceService.getList()
            .then(function (response) {
            _this.spaceList = response;
        });
    };
    FixtureEditComponent.prototype.getVersionList = function () {
        var _this = this;
        return this.holderVersionService.getList()
            .then(function (response) {
            _this.versionList = response;
        });
    };
    FixtureEditComponent.prototype.getFixtureGroupList = function () {
        var _this = this;
        return this.fixtureGroupService.getList()
            .then(function (response) {
            _this.fgList = response;
        });
    };
    FixtureEditComponent.prototype.showDeleteMessageBox = function (totalLocations) {
        var _this = this;
        var messageBody = "";
        //Only add when there are locations attached
        if (totalLocations > 0) {
            messageBody += IpsString.Format(Number(totalLocations) === 1 ? this.fixtureDeleteLabel : this.fixtureDeleteManyLabel, this.numberPipe.transform(totalLocations)) + "\n";
        }
        messageBody += IpsString.Format(this.promptDeleteBodyLabel, this.fixtureForm.value.fixtureName + (this.fixtureForm.value.fixtureMakeModel ? " - " + this.fixtureForm.value.fixtureMakeModel : ""));
        return this.ipsMessage.confirmDelete({
            body: messageBody,
            workFunction: function () { return _this.deleteFixture(); },
            progressMessage: "DELETING"
        }, null).then(function (result) {
            if (result) {
                _this.$state.go("main.storeProfileFixture.search");
            }
        })
            .catch(function () {
            // rejection
        });
    };
    FixtureEditComponent.prototype.fixtureGroupChange = function (fixtureGroup) {
        if (fixtureGroup) {
            this.populateFormHolders(fixtureGroup.Holders);
        }
    };
    FixtureEditComponent.prototype.deleteFixturePrompt = function () {
        var _this = this;
        if (this.loaded && this.canDeleteErrorList.length > 0) {
            this.ipsMessage.error("Unable to delete! Record is begin used in the following Market Groups:\r\n" + this.canDeleteErrorList.join("\r\n"));
            return null;
        }
        this.locationByFixtureService.headByFixture(this.fixtureForm.value.id, false)
            .then(function (data) {
            //Show location data
            _this.showDeleteMessageBox(data || 0);
        })
            .catch(function (response) {
            //Show without location data
            _this.showDeleteMessageBox(0);
        });
    };
    FixtureEditComponent.prototype.numberRequired = function (control) {
        //Always check to see that we have a number in the input
        var input = control.value;
        if (control.value === null) {
            return { numericValueRequiredMessage: { valid: false } };
        }
        //Skip validation of number if qty is zero
        if (control.parent && Number(control.parent.controls["quantity"].value) === 0) {
            return null;
        }
        var inputStr = input.toString();
        var decimalPart = inputStr.split(".")[1];
        if (input <= 0) {
            return { numericValueRequiredMessage: { valid: false } };
        }
        else if (decimalPart && decimalPart.length > 6 || (inputStr.indexOf("1e-") === 0 && Number(inputStr.split("1e-")[1]) >= 7)) {
            return { tooManyDecimalPlacesMessage: { valid: false } };
        }
        return null;
    };
    FixtureEditComponent.prototype.saveFixturePrompt = function (redirect) {
        //Check to see if user selected at least one holder
        var _this = this;
        var valid = false;
        var checkHolders = this.fixtureForm.value.holders;
        for (var i = 0; i < checkHolders.length; i++) {
            //Find starting index of name in question
            if (checkHolders[i].quantity > 0) {
                valid = true;
                break;
            }
        }
        if (valid) {
            return this.ipsMessage.waitForWork({
                body: "SAVING",
                workFunction: function () { return _this.saveFixtureData(); },
                progressMessage: "SAVING"
            }, null)
                .then(function (result) {
                if (result) {
                    if (redirect) {
                        _this.$state.go("main.storeProfileFixture.search");
                    }
                    else {
                        // Reload the page after a save, its easier
                        _this.$state.go("main.storeProfileFixture.edit", { id: _this.fixtureForm.value.id });
                    }
                }
            });
        }
        this.ipsMessage.error("QTY_AT_LEAST_ONE_HOLDER_REQUIRED");
        event.preventDefault();
        return false;
    };
    FixtureEditComponent.prototype.onSelectedImageChanged = function (files) {
        this.files = files;
        if (!this.files) {
            // Get the placeholder image and set it
            this.imageEdit.thumbnail = this.defaultImage;
            this.imageEdit.selectedImage = false;
            this.fixtureForm.patchValue({
                masterMediaId: this.emptyGuid,
                mediaType: 0
            });
        }
        this.fixtureForm.markAsDirty();
    };
    FixtureEditComponent.prototype.onAdditionalImageChanged = function (files) {
        this.additionalImageFiles = files;
        this.fixtureForm.markAsDirty();
    };
    FixtureEditComponent.prototype.hasLayoutImage = function () {
        var returnValue = this.layoutFiles ||
            (this.fixtureForm.value &&
                this.fixtureForm.value.layoutImageMasterMediaId &&
                this.fixtureForm.value.layoutImageMasterMediaId !== "" &&
                this.fixtureForm.value.layoutImageMasterMediaId !== this.emptyGuid);
        return returnValue;
    };
    FixtureEditComponent.prototype.onSelectedLayoutImageChanged = function (files) {
        this.layoutFiles = files;
        if (!this.layoutFiles) {
            // Get the placeholder image and set it
            this.layoutImageEdit.thumbnail = this.defaultImage;
            this.layoutImageEdit.selectedImage = false;
            this.fixtureForm.patchValue({
                layoutImageMasterMediaId: this.emptyGuid,
                layoutMediaType: 0
            });
            this.resetAll();
        }
        this.fixtureForm.markAsDirty();
    };
    FixtureEditComponent.prototype.createRange = function (total) {
        return new Array(total);
    };
    FixtureEditComponent.prototype.getPlacedHolders = function () {
        return this.placedHolders.filter(function (holder) { return holder.Placed; });
    };
    FixtureEditComponent.prototype.saveFixtureData = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.saveFixture().then(function (response) {
                if (_this.enableSignPlanLayout) {
                    _this.saveFixtureLayout().then(function (layoutResponse) {
                        resolve();
                    });
                }
                else {
                    resolve();
                }
            }).catch(function (response) {
                reject(response);
            });
        });
        return returnPromise;
    };
    FixtureEditComponent.prototype.placeHolder = function (holder) {
        holder.Placed = true;
        holder.Height = 50;
        holder.Width = 100;
        holder.Left = 0;
        holder.Top = 0;
    };
    FixtureEditComponent.prototype.saveFixtureLayout = function () {
        var _this = this;
        var fixture = this.fixtureForm.value;
        var ratio = { x: 1, y: 1 }; // this.getImageRatio();
        var layoutDetails = this.placedHolders.filter(function (holder) { return holder.Placed; })
            .map(function (holder) {
            holder.Left = Math.floor(holder.Left / ratio.x);
            holder.Top = Math.floor(holder.Top / ratio.y);
            holder.Height = Math.floor(holder.Height / ratio.y);
            holder.Width = Math.floor(holder.Width / ratio.x);
            return holder;
        });
        var fixtureLayoutModel = {
            FixtureId: fixture.id,
            MasterMediaId: fixture.layoutImageMasterMediaId,
            MediaType: fixture.layoutImageMediaType,
            LayoutDetails: layoutDetails
        };
        return this.fixtureService.putLayoutDetails(fixtureLayoutModel, this.layoutFiles)
            .then(function (response) {
            _this.fixtureForm.patchValue({
                layoutImageMediaType: response.MediaType,
                layoutImageMasterMediaId: response.MasterMediaId
            });
            _this.fixtureForm.markAsPristine();
        });
    };
    FixtureEditComponent.prototype.saveFixture = function () {
        var _this = this;
        var fixture = this.fixtureForm.value;
        var fixtureModel = {
            Id: fixture.id,
            SpaceId: fixture.spaceSelect.Id,
            SpaceName: fixture.spaceSelect.Name,
            BusinessIdentity: fixture.businessIdentity,
            FixtureGroupId: fixture.fixtureGroupSelect.Id,
            FixtureGroupName: fixture.fixtureGroupSelect.Name,
            Name: fixture.fixtureName,
            Model: fixture.fixtureMakeModel,
            VeracoreOfferId: fixture.offerId,
            Notes: fixture.notes,
            IsObsolete: fixture.isObsolete,
            Holders: fixture.holders.map(function (holder) {
                var h = {
                    id: holder.id,
                    name: holder.holderName,
                    fixtureId: fixture.id,
                    businessIdentity: holder.businessIdentity,
                    size: {
                        width: holder.width,
                        height: holder.height,
                        unit: holder.unit
                    },
                    quantity: holder.quantity,
                    isObsolete: holder.isObsolete,
                    holderVersionInfoId: holder.versionSelect != null ? holder.versionSelect.Id : undefined,
                    cropTop: holder.cropTop,
                    cropLeft: holder.cropLeft,
                    cropWidth: holder.cropWidth,
                    cropHeight: holder.cropHeight,
                    cropRotation: holder.cropRotation,
                    categoryCode: holder.categoryCode
                };
                return h;
            }),
            MasterMediaId: fixture.masterMediaId,
            MediaType: fixture.mediaType,
            LayoutImageMasterMediaId: fixture.layoutImageMasterMediaId,
            LayoutImageMediaType: fixture.layoutImageMediaType,
            SignPlanWidth: fixture.signPlanWidth || 0
        };
        if (fixture.id) {
            return this.fixtureService.put(fixtureModel, this.files)
                .then(function (response) {
                _this.fixtureForm.markAsPristine();
            });
        }
        else {
            return this.fixtureService.post(fixtureModel, this.files)
                .then(function (response) {
                _this.fixtureForm.markAsPristine();
                // Just set the Id so we can re-load the state
                _this.fixtureForm.patchValue({
                    id: response.Id
                });
            });
        }
    };
    FixtureEditComponent.prototype.deleteFixture = function () {
        var id = this.fixtureForm.value.id;
        //TODO: when campaigns exist
        //        check to see if this fixture is tied to a campaign and if so then prevent delete
        return this.fixtureService.delete(id);
    };
    FixtureEditComponent.prototype.compareFn = function (item1, item2) {
        return item1 && item2 ? item1.Id === item2.Id : item1 === item2;
    };
    FixtureEditComponent.prototype.getInputNameLabel = function (control, item) {
        return control.get("InputName").value + "_" + item;
    };
    FixtureEditComponent.prototype.msgPrompt = function (fg) {
        if (!this.hasShownWarning) {
            var h_1 = this.originalFixture.Holders.find(function (f) { return f.Id === fg.get("id").value; });
            if (h_1) {
                if (fg.get("width").value !== h_1.Size.Width || fg.get("height").value !== h_1.Size.Height) {
                    this.ipsMessage.confirm({
                        title: "WARNING",
                        body: "FIXTURE_SIZE_CHANGE_WARNING",
                        ok: "YES_CHANGE_IT",
                        cancel: "NO_KEEP_IT"
                    }).then(function () {
                        //Value change is fine
                    })
                        .catch(function () {
                        //Reset value
                        fg.get("width").setValue(h_1.Size.Width);
                        fg.get("height").setValue(h_1.Size.Height);
                    });
                }
                this.hasShownWarning = true;
            }
        }
    };
    FixtureEditComponent.prototype.toggleObsolete = function (locationGroupControl) {
        var isObsoleteControl = locationGroupControl.get("isObsolete");
        isObsoleteControl.setValue(!isObsoleteControl.value);
        isObsoleteControl.markAsDirty();
    };
    return FixtureEditComponent;
}());
export { FixtureEditComponent };
