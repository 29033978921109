import { Component, OnInit } from "@angular/core";
import { StoreFrontCategoryService } from "../../../service/store-front-category.service";
import { ListSearchHelper, SearchInfo, SearchResultModel } from "imagine-ui-ng-core";
import { StoreFrontCategory } from "../../../index";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString, StringBuilder } from "typescript-string-operations";

interface StoreFrontCategorySearchResult extends StoreFrontCategory, SearchResultModel {
}

@Component({
  selector: "app-store-front-category-search",
  templateUrl: "./store-front-category-search.component.html",
  styleUrls: ["./store-front-category-search.component.scss"]
})
export class StoreFrontCategorySearchComponent implements OnInit {
  public storeFrontCategory: ListSearchHelper;
  public busy: boolean;
  public promise: Promise<any>;
  public createNewTooltip: string;
  public textMaxLength = "MAX_LENGTH_ERROR";
  public searchLabel: string;
  public searchLabelPlural: string;
  public translated = false;

  constructor(private storeFrontCategoryService: StoreFrontCategoryService, private listSearchHelper: ListSearchHelper, private translateService: TranslateService) {
    this.storeFrontCategory = this.listSearchHelper.getListSearchHelper(this.storeFrontCategoryService);
    this.busy = this.storeFrontCategory.busy;

    storeFrontCategoryService.headByStoreFrontCategory().then((data) => {
      this.storeFrontCategory.TotalRecords = data;
    });

  }

  ngOnInit() {
    this.translateText();
    this.translateService.onLangChange.subscribe(() => this.translateText());
    this.getListData("");
  }

  private translateText() {
    this.translateService.get(["STORE_FRONT_CATEGORY", "STORE_FRONT_CATEGORIES", "CREATE_NEW_STORE_FRONT_CATEGORY", "MAX_LENGTH_ERROR"]).subscribe((res: [string]) => {
      this.createNewTooltip = res["CREATE_NEW_STORE_FRONT_CATEGORY"];
      this.textMaxLength = res["MAX_LENGTH_ERROR"];
      this.searchLabel = res["STORE_FRONT_CATEGORY"];
      this.searchLabelPlural = res["STORE_FRONT_CATEGORIES"];
      this.translated = true;
    });
  }

  public getListData(search: string) {
    if (this.busy) {
      return;
    }

    let searchInfo: SearchInfo = {
      searchText: search === undefined ? "" : search
    };

    this.promise = this.storeFrontCategory.searchHelper(searchInfo, undefined, undefined).then(() => {
      this.storeFrontCategory.resultList.forEach((result: StoreFrontCategorySearchResult) => {
        if (result.Label !== result.Name) {
          result.Label = result.Name;
        }
      });
    });
  }

  public validationMaxLength(value: number) {
    return IpsString.Format(this.textMaxLength, value);
  }

}
