import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "app-public",
    templateUrl: "./public.component.html",
    styleUrls: ["./public.component.scss",
        "../../../node_modules/@okta/okta-signin-widget/dist/css/okta-theme.css",
        "../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css"
    ],
    encapsulation: ViewEncapsulation.None
})
export class PublicComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
