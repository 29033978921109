/// <reference path="service/custom-data-field.service.ts" />
/// <reference path="service/custom-data-field.service.ts" />
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { NgbDateNativeAdapter } from "./NgbDateNativeAdapter";
import { NgbDateAdapter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ValidationDirective } from "./validation/validation.directive";
import { ListSearchModule } from "imagine-ui-ng-list-search";
import { ProvideParentFormDirective } from "./provide-parent-form/provide-parent-form.directive";
import { SearchModalComponent } from "./search-modal/search-modal.component";
import { TimeZoneCorrectedDatePipe } from "./time-zone-corrected-date/time-zone-corrected-date.pipe";
import { IpsRequiredValidator } from "./validation/ips-required.validator";
import { AbstractComponent } from "./abstract/abstract.component";
import { UIRouterModule } from "@uirouter/angular";
import { ImagineUiNgCoreModule } from "imagine-ui-ng-core";
import { IpsModalModule, IpsModalService } from "imagine-ui-ng-modal";
import { IpsImageViewComponent } from "./ips-image-view/ips-image-view.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CallbackPipe } from "./pipe/callback.pipe";
import { HighlightFilterPipe } from "./pipe/highlight-filter.pipe";
import { SearchCardsComponent } from "./search-cards/search-cards.component";
import { LocationSubGroupEditComponent } from "./store-profile/location-group/location-sub-group-edit/location-sub-group-edit.component";
import { MultiTypeInputComponent } from "./CustomControls/multi-type-input/multi-type-input.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TabsModule } from "ngx-bootstrap/tabs";
import { CustomDataFieldContainerComponent } from "./custom-data-field/custom-data-field-container/custom-data-field-container.component";
import { CustomDataFieldControlComponent } from "./custom-data-field/custom-data-field-control/custom-data-field-control.component";
import { CustomDataFieldService } from "./service/custom-data-field.service";
import { IpsTextComponent } from "./custom-data-field/ips-text/ips-text.component";
import { IpsDateComponent } from "./custom-data-field/ips-date/ips-date.component";
import { IpsListComponent } from "./custom-data-field/ips-list/ips-list.component";
import { IpsTypeaheadComponent } from "./custom-data-field/ips-typeahead/ips-typeahead.component";
import { ImagineUiNgQuickStartModule } from "imagine-ui-ng-quick-start";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { PositiveWholeNumberRequiredValidator } from "./validation/positive-whole-number-required.validator";
import { IpsDateRangeComponent } from "./ips-date-range/ips-date-range.component";
import { InputPrefixDirective } from "./directives/input-prefix.directive";
import { SelectOnClickDirective } from "./directives/select-on-click.directive";
import { StickyHeaderComponent } from "./sticky-header/sticky-header.component";
import { TextOverflowDirective } from "./directives/text-overflow.directive";
import { SortHelperService } from "./service/sort-helper.service";
import { removeSummaryDuplicates } from "@angular/compiler";
import { DuplicateValidator } from "./validation/duplicate.validator";
import { IpsNumberComponent } from "./custom-data-field/ips-number/ips-number.component";
import { IpsEmailComponent } from "./custom-data-field/ips-email/ips-email.component";
import { FieldControlBaseComponent } from "./custom-data-field/field-control-base";
import { AngularDraggableDirective } from "./directives/angular-draggable.directive";
import { AngularResizableDirective } from "./directives/angular-resizable.directive";
import { GeneralSettingsService } from "./service/general-settings.service";
import { SignPlanService } from "./service/sign-plan.service";
import { SelectListComponent } from "./select-list/select-list.component";
import { SignPlanTransformDetailComponent } from "./sign-plan-transform-detail/sign-plan-transform-detail.component";
import { HelpDocComponent } from "./help-doc/help-doc/help-doc.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CgBusyModule } from "angular-busy2";

@NgModule({
  imports: [
      CgBusyModule,
      CommonModule,
      TranslateModule,
      ListSearchModule,
      UIRouterModule,
      ImagineUiNgCoreModule,
      IpsModalModule,
      FormsModule,
      ReactiveFormsModule,
      BsDatepickerModule,
      BsDropdownModule,
      TabsModule,
      ImagineUiNgQuickStartModule,
      TypeaheadModule.forRoot(),
      NgbModule
  ],
  declarations: [
      ValidationDirective,
      ProvideParentFormDirective,
      SearchModalComponent,
      TimeZoneCorrectedDatePipe,
      IpsRequiredValidator,
      AbstractComponent,
      IpsImageViewComponent,
      CallbackPipe,
      HighlightFilterPipe,
      SearchCardsComponent,
      LocationSubGroupEditComponent,
      MultiTypeInputComponent,
      CustomDataFieldContainerComponent,
      CustomDataFieldControlComponent,
      IpsTextComponent,
      IpsDateComponent,
      IpsListComponent,
      IpsTypeaheadComponent,
      PositiveWholeNumberRequiredValidator,
      IpsDateRangeComponent,
      InputPrefixDirective,
      SelectOnClickDirective,
      StickyHeaderComponent,
      TextOverflowDirective,
      DuplicateValidator,
      IpsNumberComponent,
      IpsEmailComponent,
      FieldControlBaseComponent,
      AngularDraggableDirective,
      AngularResizableDirective,
      SelectListComponent,
      SignPlanTransformDetailComponent,
      HelpDocComponent
  ],
  providers: [
      { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
      CustomDataFieldService,
      SortHelperService,
      GeneralSettingsService,
      SignPlanService
    ],
    entryComponents: [
        SelectListComponent,
        SignPlanTransformDetailComponent
    ],
  exports: [
      ValidationDirective,
      ProvideParentFormDirective,
      TimeZoneCorrectedDatePipe,
      IpsRequiredValidator,
      IpsImageViewComponent,
      CallbackPipe,
      HighlightFilterPipe,
      SearchCardsComponent,
      MultiTypeInputComponent,
      CustomDataFieldContainerComponent,
      CustomDataFieldControlComponent,
      PositiveWholeNumberRequiredValidator,
      IpsDateRangeComponent,
      InputPrefixDirective,
      SelectOnClickDirective,
      StickyHeaderComponent,
      TextOverflowDirective,
      DuplicateValidator,
      AngularDraggableDirective,
      AngularResizableDirective,
      SelectListComponent,
      SignPlanTransformDetailComponent
  ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [IpsModalService]
        };
    }
}
