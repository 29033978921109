import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SearchResponse } from "imagine-ui-ng-core";
import { LocationService, LocationModel, LocationSearchInfo, ValidationStatus } from "../../../imagine-ui-ng-store-profile";
import { KeyValuePair, LocationWithPaymentModel, AllowPayment } from "../../index";

interface DialogListItemModel {
    Id: number;
    Label: string;
    IsSelected: boolean;
    AddressData?: object;
    Invoice: boolean;
    CreditCard: boolean;
    ValidationStatus: ValidationStatus;
}

@Component({
    selector: "app-cart-location-list-modal",
    templateUrl: "./cart-location-list-modal.component.html",
    styleUrls: ["./cart-location-list-modal.component.scss"]
})

export class CartLocationListModalComponent implements OnInit {
    // ----- DATA -----
    public cartLocations: LocationWithPaymentModel[];
    public currentSelections: number[];
    public displayAddresses: boolean;
    public availableLocations: DialogListItemModel[];
    public allLocations: DialogListItemModel[];
    public total: number;
    public totalSelected: number;
    public filter: string;
    public pluralMapping: { [k: string]: string } = {};

    public allowPayment: AllowPayment;
    public showCreditButton: boolean;
    public showInvoiceButton: boolean;

    constructor(
        private locationService: LocationService,
        private translateService: TranslateService,
        private modalInstance: NgbActiveModal
    ) {
        this.filter = "";
    }

    // ----- FUNCTIONS -----
    //
    private translateText() {
        this.translateService.get([
            "FOUND_OF", "TOTAL", "SELECT_LOCATIONS", "CANCEL", "LOCATION", "LOCATIONS",
        ]).subscribe((res: [string]) => {
            this.pluralMapping["=1"] = this.translateService.instant("LOCATION");
            this.pluralMapping["other"] = this.translateService.instant("LOCATIONS");
        });
    }

    //
    public locationClicked(location: DialogListItemModel) {

        //Now check to see if selection has a allowable payment method
        switch (this.allowPayment) {
            case "Both":
                if (!location.CreditCard && !location.Invoice) {
                    return;
                }

                if (location.CreditCard && !location.Invoice) {
                    this.allowPayment = "Credit";
                } else if (!location.CreditCard && location.Invoice) {
                    this.allowPayment = "Invoice";
                }
                break;
            case "Credit":
                if (!location.CreditCard) {
                    return;
                }
                break;
            case "Invoice":
                if (!location.Invoice) {
                    return;
                }
                break;
        }

        location.IsSelected = !location.IsSelected;
        const tempIndex = this.allLocations.findIndex(function(element) {
            return element.Id === location.Id;
        });
        if (tempIndex !== -1) {
            this.allLocations[tempIndex].IsSelected = location.IsSelected;
        }
        this.totalSelected = this.allLocations.filter(x => x.IsSelected).length;
    }

    //
    public selectAll(allowPayment: AllowPayment) {

        this.allowPayment = allowPayment;

        for (let i = 0; i < this.allLocations.length; i++) {
            if (allowPayment === "Credit" && this.allLocations[i].CreditCard) {
                this.allLocations[i].IsSelected = true;
            } else if (allowPayment === "Invoice" && this.allLocations[i].Invoice) {
                this.allLocations[i].IsSelected = true;
            } else if (allowPayment === "Both") {
                this.allLocations[i].IsSelected = true;
            } else {
                this.allLocations[i].IsSelected = false;
            }
        }
        this.totalSelected = this.allLocations.filter(x => x.IsSelected).length;
        this.filterUpdated();
    }

    //
    public deselectAll() {
        for (let i = 0; i < this.allLocations.length; i++) {
            this.allLocations[i].IsSelected = false;
        }
        this.totalSelected = 0;
        this.filterUpdated();
    }

    //
    public getLocationAddresses(): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            if (this.displayAddresses) {
                this.locationService.searchLocationsByIds(this.allLocations.map(x => x.Id))
                .then((locationSearchResponse: SearchResponse<LocationModel>) => {
                    for (let i = 0; i < locationSearchResponse.ResultList.length; i++) {
                        let tempIndex = this.allLocations.findIndex(
                            x => x.Id === locationSearchResponse.ResultList[i].Id
                        );
                        if (tempIndex !== -1) {
                            this.allLocations[tempIndex].AddressData =
                                locationSearchResponse.ResultList[i].Addresses[0];
                            this.allLocations[tempIndex].Label =
                                this.allLocations[tempIndex].AddressData["Line1"] + " " +
                                this.allLocations[tempIndex].AddressData["City"] + ", " +
                                this.allLocations[tempIndex].AddressData["StateProvince"] + " " +
                                this.allLocations[tempIndex].AddressData["Country"] + " " +
                                this.allLocations[tempIndex].AddressData["PostalCode"];
                        }
                    }
                    resolve(true);
                });
            } else {
                resolve(false);
            }
        });
        return promise;
    }

    //
    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    //
    public returnResults() {
        let ret = this.allLocations.filter(x => x.IsSelected).map(x => x.Id);
        this.modalInstance.close({ Locations: ret, PaymentMethod: this.allowPayment });
    }

    //
    public filterUpdated() {
        this.availableLocations = [];
        for (let i = 0; i < this.allLocations.length; i++) {
            if (this.allLocations[i].Label.toUpperCase().indexOf(this.filter.toUpperCase()) >= 0) {
                this.availableLocations.push(this.allLocations[i]);
            }
        }
}

    public disabledFilter(location: DialogListItemModel): boolean {
        return (this.allowPayment !== "Both") && (location.Invoice && this.allowPayment === "Credit" && !location.CreditCard) || (location.CreditCard && this.allowPayment === "Invoice" && !location.Invoice);
    }

    // ----- ON INIT -----
    //
    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.allLocations = [];
        let totalSelected = 0;
        for (let i = 0; i < this.cartLocations.length; i++) {
            const selected = (this.currentSelections.indexOf(this.cartLocations[i].LocationId) !== -1);
            this.allLocations.push({
                Id: this.cartLocations[i].LocationId,
                Label: this.cartLocations[i].Label,
                IsSelected: selected,
                Invoice: this.cartLocations[i].Invoice,
                CreditCard: this.cartLocations[i].CreditCard,
                ValidationStatus: this.cartLocations[i].ValidationStatus
            });
            if (selected) {
                totalSelected++;
            }
        }
        this.allLocations.sort((a, b) => a.Id - b.Id);
        this.getLocationAddresses()
            .then(() => {
                this.filterUpdated();
                this.total = this.allLocations.length;
                this.totalSelected = totalSelected;
            });

        if (!!this.cartLocations.find((cl) => cl.CreditCard)) {
            this.showCreditButton = true;
        }
        if (!!this.cartLocations.find((cl) => cl.Invoice)) {
            this.showInvoiceButton = true;
        }


    }
}
