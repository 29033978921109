<div class="col-md-6" *ngIf="translated" ipsFocus="searchInput">
    <ips-list-search headerLabel="{{'RELATED_FIXTURE_GROUPS' | translate }}"              
                     [searchLabel]="searchLabel"
                     [searchLabelPlural]="searchLabelPlural"
                     labelLoading="{{'ACCESSING_FIXTURE_GROUP_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_FIXTURE_GROUP_INFO' | translate }}"
                     [listData]="fixtureGroup"
                     [busy]="busy"
                     (onListDataChange)="getListData($event.search)"
                     [section]="'main.storeProfileFixtureGroup'"
                     [scroller]="true"
                     [quickRef]="false"
                     [itemClickPermission]="'main.storeProfileFixtureGroup.view'"
                     [promise]="promise"
                     createNewTooltip="{{'CREATE_NEW_FIXTURE_GROUP' | translate }}"
                     [autoFocus]="true"></ips-list-search>
               
</div>
