import { IpsModalDefaultSettings } from "imagine-ui-ng-modal";
import { ImagineUiNgCoreConfig } from "imagine-ui-ng-core";
import { environment } from "../environments/environment";

export const APP_MODAL_DEFAULTS = <IpsModalDefaultSettings>{
    /*
    Default inputs passed to every Component displayed in a modal
    */
    defaultData: {
        body: "Are you sure?",
        ok: "OK",
        cancel: "CANCEL",
        title: ""
    },
    /*
    Default settings are anything you can pass to uibModal open function.
    https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
    */
    defaultSettings: {
        animation: false,
        centered: true,
        size: "md2" as "lg" // do 'as "lg"' to fake out typescript. a class of modal-md2 will be added along with modal-dialog. use this to customize the default md-size
    }
};

export const APP_CORE_CONFIG = <ImagineUiNgCoreConfig>{
    baseApiUrl: environment.baseApiUrl,
    ApplicationName: environment.ApplicationName,
    chunkSize: <any>environment.chunkSize,
    delayTimer: <any>environment.delayTimer,
    loggerApiUrl: environment.loggerApiUrl,
    ApplicationVersion: environment.ApplicationVersion,
    apiManagementKey: environment.apiManagementKey
};
