/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./add-to-cart.component";
import * as i4 from "imagine-ui-ng-core";
import * as i5 from "@uirouter/core";
import * as i6 from "../../service/cart.service";
import * as i7 from "imagine-ui-ng-modal";
import * as i8 from "../../service/punch-out.service";
var styles_XMPieAddToCartComponent = [];
var RenderType_XMPieAddToCartComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_XMPieAddToCartComponent, data: {} });
export { RenderType_XMPieAddToCartComponent as RenderType_XMPieAddToCartComponent };
function View_XMPieAddToCartComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-body px-4 text-large"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" An error has occurred during the customization process. You can try again now, or return to your product listing. "]))], null, null); }
function View_XMPieAddToCartComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-body px-4 text-large"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" An error has occurred during the customization process. Please try again later. "]))], null, null); }
function View_XMPieAddToCartComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-lg btn-outline-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retry() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Retry Now "]))], null, null); }
function View_XMPieAddToCartComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cuztomization Error"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_XMPieAddToCartComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_XMPieAddToCartComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_XMPieAddToCartComponent_4)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-lg btn-outline-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Try Again Later "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.cartPODModel && _co.cartPODModel.ItemKey); _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.cartPODModel || !_co.cartPODModel.ItemKey); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.cartPODModel && _co.cartPODModel.ItemKey); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_XMPieAddToCartComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { errorTemplate: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "ips-flex-loading-box mb-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-pulse fa-3x fa-fw mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [["class", "d-block"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["xmpie add to cart"])), (_l()(), i0.ɵand(0, [[1, 2], ["errorTemplate", 2]], null, 0, null, View_XMPieAddToCartComponent_1))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_XMPieAddToCartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ampie-add-to-cart", [], null, null, null, View_XMPieAddToCartComponent_0, RenderType_XMPieAddToCartComponent)), i0.ɵdid(1, 114688, null, 0, i3.XMPieAddToCartComponent, [i4.ActiveProfileService, i5.Transition, i5.StateService, i6.CartService, i7.IpsModalService, i8.PunchOutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var XMPieAddToCartComponentNgFactory = i0.ɵccf("app-ampie-add-to-cart", i3.XMPieAddToCartComponent, View_XMPieAddToCartComponent_Host_0, {}, {}, []);
export { XMPieAddToCartComponentNgFactory as XMPieAddToCartComponentNgFactory };
