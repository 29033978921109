/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-cards.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ips-image-view/ips-image-view.component.ngfactory";
import * as i3 from "../ips-image-view/ips-image-view.component";
import * as i4 from "imagine-ui-ng-modal";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@uirouter/angular/lib/directives/uiSref";
import * as i8 from "@uirouter/core";
import * as i9 from "../pipe/highlight-filter.pipe";
import * as i10 from "./search-cards.component";
var styles_SearchCardsComponent = [i0.styles];
var RenderType_SearchCardsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchCardsComponent, data: {} });
export { RenderType_SearchCardsComponent as RenderType_SearchCardsComponent };
function View_SearchCardsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12 col-md-6 col-lg-3 mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ips-image-view", [], null, null, null, i2.View_IpsImageViewComponent_0, i2.RenderType_IpsImageViewComponent)), i1.ɵdid(2, 114688, null, 0, i3.IpsImageViewComponent, [i4.IpsModalService], { imgThumbUrl: [0, "imgThumbUrl"], imgFullUrl: [1, "imgFullUrl"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ThumbImage; var currVal_1 = _co.FullImage; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SearchCardsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit, (_v.parent.parent.parent.context.$implicit.CanHighlight ? _co.SearchText : ""))), ""); _ck(_v, 1, 0, currVal_0); }); }
function View_SearchCardsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchCardsComponent_5)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.Value; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SearchCardsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "text-nowrap": 0 }), i1.ɵppd(3, 2)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, _v.parent.parent.parent.context.$implicit.isDate); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.parent.context.$implicit.Value, (_v.parent.parent.parent.context.$implicit.CanHighlight ? _co.SearchText : ""))), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SearchCardsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "location-group-pending-assignment-label"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["PENDING_ASSIGNMENT"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SearchCardsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchCardsComponent_7)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["pending_locGroup", 2]], null, 0, null, View_SearchCardsComponent_8))], function (_ck, _v) { var currVal_0 = !_v.parent.parent.context.$implicit.isPendingAssignment; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SearchCardsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "a", [["class", "h-100 no-underline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i7.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i7.UISref, [i8.UIRouter, [2, i7.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(3, { id: 0 }), i1.ɵpod(4, { locationId: 0, locationGroupId: 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "view-info-label mr-1 d-inline-block pb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchCardsComponent_4)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchCardsComponent_6)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_v.parent.context.$implicit.Type === "Location") ? _co.EditOrViewPermission : _co.getCardSref(_v.parent.context.$implicit.Type)), ""); var currVal_1 = ((_v.parent.context.$implicit.Type != "LocationGroup") ? _ck(_v, 3, 0, _v.parent.context.$implicit.Id) : _ck(_v, 4, 0, _co.LocationId, _v.parent.context.$implicit.Id)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.isArray(_v.parent.context.$implicit.Value); _ck(_v, 11, 0, currVal_3); var currVal_4 = !_co.isArray(_v.parent.context.$implicit.Value); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_v.parent.context.$implicit.Key)); _ck(_v, 7, 0, currVal_2); }); }
function View_SearchCardsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "view-info-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(7, { "text-nowrap": 0 }), i1.ɵppd(8, 2)], function (_ck, _v) { var currVal_2 = _ck(_v, 7, 0, _v.parent.context.$implicit.isDate); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.parent.context.$implicit.Key)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.Value, (_v.parent.context.$implicit.CanHighlight ? _co.SearchText : ""))), ""); _ck(_v, 5, 0, currVal_1); }); }
function View_SearchCardsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "col-12 mb-3 d-flex align-content-stretch"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "card ips-search-card w-100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchCardsComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchCardsComponent_9)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "col-12 mb-3 d-flex align-content-stretch"; var currVal_1 = _ck(_v, 2, 0, (_co.FullImage ? "col-lg-4" : "col-lg-3")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit.Type !== "Kit"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_v.context.$implicit.Type === "Kit"); _ck(_v, 7, 0, currVal_3); }, null); }
function View_SearchCardsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "col-12 mb-3 d-flex align-content-stretch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "card ips-search-card w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "a", [["class", "h-100 no-underline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i7.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(5, 737280, null, 0, i7.UISref, [i8.UIRouter, [2, i7.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(6, { id: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "view-info-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.EditOrViewPermission; var currVal_1 = _ck(_v, 6, 0, _co.Notes.Id); _ck(_v, 5, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.Notes.Key)); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.Notes.Value; _ck(_v, 12, 0, currVal_3); }); }
export function View_SearchCardsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.HighlightFilterPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "pb-0 pt-3 px-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "form-row flex-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchCardsComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-12 col-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchCardsComponent_2)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchCardsComponent_10)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.FullImage; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.DisplayData; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.Notes; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_SearchCardsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-search-cards", [], null, null, null, View_SearchCardsComponent_0, RenderType_SearchCardsComponent)), i1.ɵprd(512, null, i5.DatePipe, i5.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(2, 114688, null, 0, i10.SearchCardsComponent, [i5.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SearchCardsComponentNgFactory = i1.ɵccf("app-search-cards", i10.SearchCardsComponent, View_SearchCardsComponent_Host_0, { CardData: "CardData", LocationId: "LocationId", EditOrViewPermission: "EditOrViewPermission", FullImage: "FullImage", ThumbImage: "ThumbImage", SearchText: "SearchText" }, {}, []);
export { SearchCardsComponentNgFactory as SearchCardsComponentNgFactory };
