import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { SortablejsModule } from "angular-sortablejs";

//import { CAMPAIGN_STATES } from "./imagine-ui-ng-campaign.states";
import { CAMPAIGN_BASE_STATES } from "./imagine-ui-ng-campaign.futureStates";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TabsModule } from "ngx-bootstrap/tabs";
import { CgBusyModule } from "angular-busy2";
import { TranslateModule } from "@ngx-translate/core";
import { ImagineUiNgCoreModule } from "imagine-ui-ng-core";
import { ImagineUiNgSecurityModule } from "imagine-ui-ng-security";
import { ImagineUiNgQuickStartModule } from "imagine-ui-ng-quick-start";
import { ImagineUiNgMessagingModule } from "imagine-ui-ng-messaging";
import { ListSearchModule } from "imagine-ui-ng-list-search";
import { IpsModalModule, IpsModalService } from "imagine-ui-ng-modal";
import { SharedModule } from "../shared/shared.module";
import { MarketModule } from "../market/market.module";
//import { MarketSearchModalComponent } from "../market/market-search-modal/market-search-modal.component";

import { CampaignSearchComponent } from "./campaign/campaign-search/campaign-search.component";
import { CampaignViewComponent } from "./campaign/campaign-view/campaign-view.component";
import { CampaignEditComponent } from "./campaign/campaign-edit/campaign-edit.component";
import { CampaignService } from "./service/campaign.service";
import { PromotionService } from "./service/promotion.service";
import { PromotionEditComponent } from "./campaign/promotion-edit/promotion-edit.component";
import { MessagePatternEditComponent } from "./campaign/message-pattern-edit/message-pattern-edit.component";
import { MessagePriorityFillEditComponent } from "./campaign/message-priority-fill-edit/message-priority-fill-edit.component";
import { MessageEditComponent } from "./campaign/message-edit/message-edit.component";
import { PlacementElementEditComponent } from "./campaign/placement-element-edit/placement-element-edit.component";
import { PlacementEditComponent } from "./campaign/placement-edit/placement-edit.component";
import { PromotionMessageService } from "./service/promotion-message.service";
import { PatternGroupService } from "./service/pattern-group.service";
import { PromotionHelperService } from "./service/promotionHelper.service";
import {  CampaignUpDownAnalyticsService} from "./service/campaign-up-down-analytics.service";
import { PlacementSearchModalComponent } from "./campaign/placement-search-modal/placement-search-modal.component";
import { ElementQuantityHandlingModalComponent } from "./campaign/element-quantity-handling-modal/element-quantity-handling-modal.component";
import { KitContentListComponent } from "./campaign/report/kit-content-list/kit-content-list.component";
import { PromotionViewComponent } from "./campaign/promotion-view/promotion-view.component";
import { CampaignLandingComponent } from "./campaign/campaign-landing/campaign-landing.component";
import { SignplanProblemsComponent } from "./campaign/signplan-problems/signplan-problems.component";
import { AnalyticsComponent } from "./campaign/analytics/analytics.component";
import { CampaignUpdownAnalyticsModalComponent } from "./campaign/campaign-updown-analytics-modal/campaign-updown-analytics-modal.component";

@NgModule({
    imports: [
        SortablejsModule,
        TypeaheadModule.forRoot(),
        CgBusyModule,
        InfiniteScrollModule,
        CommonModule,
        UIRouterModule.forChild({ states: CAMPAIGN_BASE_STATES }),
        TranslateModule.forChild(),
        ImagineUiNgQuickStartModule,
        ImagineUiNgSecurityModule,
        ListSearchModule,
        NgbModule,
        BsDatepickerModule,
        BsDropdownModule,
        IpsModalModule,
        ImagineUiNgMessagingModule,
        FormsModule,
        ReactiveFormsModule,
        ImagineUiNgCoreModule,
        SharedModule,
        MarketModule,
        TabsModule
    ],
    declarations: [CampaignSearchComponent, CampaignViewComponent, CampaignEditComponent, PromotionEditComponent,
        MessagePatternEditComponent, MessageEditComponent, PlacementElementEditComponent, PlacementEditComponent, PlacementSearchModalComponent,
        ElementQuantityHandlingModalComponent,
        KitContentListComponent,
        PromotionViewComponent,
        CampaignLandingComponent,
        SignplanProblemsComponent,
        AnalyticsComponent,
        CampaignUpdownAnalyticsModalComponent,
        MessagePriorityFillEditComponent
    ],
    entryComponents: [PlacementSearchModalComponent, ElementQuantityHandlingModalComponent, CampaignUpdownAnalyticsModalComponent],
    providers: [
        CampaignService,
        PromotionService,
        PromotionMessageService,
        PatternGroupService,
        PromotionHelperService,
        CampaignUpDownAnalyticsService
    ]
})
export class ImagineUiNgCampaignModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ImagineUiNgCampaignModule,
            providers: [IpsModalService]
        };
    }
}
