/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/imagine-ui-ng-quick-start/imagine-ui-ng-quick-start.ngfactory";
import * as i3 from "imagine-ui-ng-security";
import * as i4 from "imagine-ui-ng-quick-start";
import * as i5 from "imagine-ui-ng-core";
import * as i6 from "./login.component";
import * as i7 from "@uirouter/core";
import * as i8 from "../version-check/version-check.service";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ips-login", [["imgAlt", "IMAGINE SYMPHONY"], ["imgSource", "/assets/img/lgo_art_lgn_flex.png"]], null, [[null, "loggedIn"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loggedIn" === en)) {
        var pd_0 = (_co.returnToState($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IpsLoginComponent_0, i2.RenderType_IpsLoginComponent)), i1.ɵprd(512, null, i3.Okta, i3.Okta, [i3.IPS_SECURITY_CONFIG]), i1.ɵdid(2, 114688, null, 0, i4.IpsLoginComponent, [i3.Okta, i3.AuthService, i4.ProfileService, i3.IPS_SECURITY_CONFIG, i5.LoggingService, i4.IpsAnalyticsService], { imgSource: [0, "imgSource"], imgAlt: [1, "imgAlt"] }, { loggedIn: "loggedIn" })], function (_ck, _v) { var currVal_0 = "/assets/img/lgo_art_lgn_flex.png"; var currVal_1 = "IMAGINE SYMPHONY"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-page", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 49152, null, 0, i6.LoginComponent, [i7.StateService, i1.NgZone, i4.IpsAnalyticsService, i8.VersionCheckService], null, null)], null, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login-page", i6.LoginComponent, View_LoginComponent_Host_0, { returnTo: "returnTo" }, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
