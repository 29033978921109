import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CgBusyModule } from "angular-busy2";
import { ListSearchModule } from "imagine-ui-ng-list-search";
import { IpsModalModule, IpsModalService } from "imagine-ui-ng-modal";
import { ImagineUiNgCoreModule } from "imagine-ui-ng-core";
import { ImagineUiNgQuickStartModule } from "imagine-ui-ng-quick-start";

import { SharedModule } from "../shared/shared.module";
import { MarketViewComponent } from "./market-view/market-view.component";
import { MarketSearchModalComponent } from "./market-search-modal/market-search-modal.component";
import { MarketService, DataService, MarketGroupService } from "../market";
import { MarketTypeaheadSearchComponent } from "./market-typeahead-search/market-typeahead-search.component";
import { LinkedFeatureSearchModalComponent } from "./linked-feature-search-modal/linked-feature-search-modal.component";
import { MarketEditComponent } from "./market-edit/market-edit.component";
import { LocationAttributeSearchModalComponent } from "./location-attribute-search-modal/location-attribute-search-modal.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TypeaheadModule.forRoot(),
        CgBusyModule,
        ImagineUiNgCoreModule,
        InfiniteScrollModule,
        TranslateModule,
        IpsModalModule,
        ListSearchModule,
        NgbModule,
        ImagineUiNgQuickStartModule,
        SharedModule
    ],
    declarations: [MarketViewComponent, MarketSearchModalComponent, MarketTypeaheadSearchComponent, LinkedFeatureSearchModalComponent, MarketEditComponent, LocationAttributeSearchModalComponent],
    entryComponents: [MarketSearchModalComponent, LinkedFeatureSearchModalComponent, LocationAttributeSearchModalComponent],
    exports: [MarketViewComponent, MarketSearchModalComponent, MarketTypeaheadSearchComponent, MarketEditComponent],
    providers: [MarketService, DataService, MarketGroupService]
})
export class MarketModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: MarketModule,
            providers: [IpsModalService]
        };
    }
}
