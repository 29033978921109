<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3 translate>EDIT_THEME</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:'{{'ACCESSING_BRAND_INFO' | translate}}'}">
        <form id="ramiInfoInput" class="form-horizontal" name="businessForm" [formGroup]="brandForm" novalidate ipsUnsavedWarningForm role="form">
            <div *ngIf="loaded">
                <div class="updateSection">

                    <div class="row mb-3">
                        <div class="col-6">
                            <div class="btn-group" role="group">
                                <button type="button" class="btn btn-secondary" [ngClass]="{'active': showUrl}" (click)="clickCustomUrl()">Custom URL</button>
                                <button type="button" class="btn btn-secondary" [ngClass]="{'active': !showUrl}" (click)="clickMultipleDocs()">Multiple Documents</button>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="showUrl" class="row">
                        <div class="col-12">
                            <div class="form-group mb-0">
                                <label for="supportUrl" translate>SUPPORT_URL</label>
                                <input type="text" name="supportUrl" id="supportUrl" formControlName="SupportUrl"
                                       class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_URL' | translate}}">
                            </div>
                        </div>
                    </div>


                    <div *ngIf="!showUrl" class="row mb-3">
                        <div class="col-12">
                            <div class="form-group mb-0">
                                <label translate>Multiple Documents</label>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="list-group" *ngFor="let document of attachedDocuments">
                                <li class="list-group-item"><a *ngIf="document.LinkSubType !== 5" [href]="attachmentUrl(document)" class="pr-3">{{ document.Label }}</a><a *ngIf="document.LinkSubType === 5" [href]="document.Label2" target="_blank" class="pr-3">{{ document.Label }}</a> <button type="button" class="close" (click)="removeHelpDocument(document)"><span aria-hidden="true">&times;</span></button></li>
                            </div>
                            <div *ngIf="!attachedDocuments || attachedDocuments.length < 1">
                                <span class="fa fa-info-circle"></span> There are no Help Documents added.
                            </div>
                        </div>

                        <div class="col-12">
                            <button class="btn btn-default" (click)="addHelpDocument()">Add Help Document</button>
                            <input type="file" #fileInput name="fileInput" (change)="fileSelected($event)" class="d-none">
                            <div class="validation validation-name text-danger" [hidden]="!hasfileSizeError" translate>MAX_UPLOAD_FILE_SIZE_10M</div>
                        </div>
                    </div>

                    <app-setup-theme [parentForm]="brandForm" [parentComponent]="this" [theme]="this.business"></app-setup-theme>

                    <hr />

                    <div class="row">
                        <section class="col-12 col-md-6 col-xl-3 pb-4 pb-xl-0">
                            <div class="formSubmit">
                                <button class="btn btn-lg btn-block alert-success"
                                        (click)="saveBusinessPrompt(false)"
                                        [disabled]="brandForm.invalid || brandForm.pristine"
                                        [ngClass]="{'btn-default': brandForm.invalid || brandForm.pristine}" translate>
                                    SAVE
                                </button>
                            </div>
                        </section>
                        <section class="col-12 col-md-6 col-xl-3 pb-4 pb-xl-0">
                            <div class="formSubmit">
                                <button class="btn btn-lg btn-block alert-success"
                                        (click)="saveBusinessPrompt(true)"
                                        [disabled]="brandForm.invalid || brandForm.pristine"
                                        [ngClass]="{'btn-default': brandForm.invalid || brandForm.pristine}" translate>
                                    SAVE_AND_RETURN
                                </button>
                            </div>
                        </section>
                        <section class="col-12 col-md-6 col-xl-3 pb-4 pb-xl-0">
                            <div class="formSubmit">
                                <button class="btn btn-default btn-lg btn-block" uiSref="main.tools" translate>CANCEL</button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_BRAND_INFO' | translate }}" [promise]="promise"></ips-error-handler>









    <ng-template #editTemplate>
        <div class="modal-header bg-light">
            <h4 class="modal-title pull-left">Add Help Document</h4>
            <button type="button" class="close pull-right" aria-label="Close" [disabled]="processing" (click)="cancelEdit()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body px-4 text-large pb-0" *ngIf="processingError">
            <p class="alert alert-danger mb-0">
                <span class="fa fa-exclamation-triangle"></span> {{ processingError }}
            </p>
        </div>
        <form novalidate ipsUnsavedWarningForm #addForm>
            <div class="modal-body px-4">
                <div class="form-row form-group">
                    <div class="col-12 col-sm-6">
                        <label class="font-weight-bold ml-2 required">Help Document Description</label>
                        <input type="text" [(ngModel)]="editTarget.Label" [ngModelOptions]="{ standalone: true }" class="form-control form-control-lg" />
                    </div>
                </div>
                
                <div class="form-row form-group">
                    <div class="col-6">
                        <label class="font-weight-bold ml-2 required">Help Media Type</label>
                        <div class="btn-group" role="group">
                            <button type="button" class="btn btn-secondary" [ngClass]="{'active': helpTypeIsDoc}" (click)="clickHelpDocument()">Document</button>
                            <button type="button" class="btn btn-secondary" [ngClass]="{'active': !helpTypeIsDoc}" (click)="clickHelpURL()">URL</button>
                        </div>
                    </div>
                </div>
                
                <div *ngIf="helpTypeIsDoc" class="row">
                    <div class="col-12 col-sm-6 col-lg-4">
                        <label class="font-weight-bold ml-2 required">Help Document File</label>
                        <button type="button" class="btn btn-default btn-lg btn-block" (click)="fileOpen()" translate>CHOOSE_UPLOAD_FILE</button>
                        <input type="file" #fileInput name="fileInput" (change)="fileSelected($event)" class="d-none">
                        <label class="pl-3 mt-2">{{uploadedFileName}}</label>
                        <div class="validation validation-name text-danger" [hidden]="!hasfileSizeError" translate>MAX_UPLOAD_FILE_SIZE_10M</div>
                    </div>
                </div>

                <div *ngIf="!helpTypeIsDoc" class="form-row form-group">
                    <div class="col-12 col-sm-6">
                        <label class="font-weight-bold ml-2 required">Help URL</label>
                        <input type="text" [(ngModel)]="editTarget.Label2" [ngModelOptions]="{ standalone: true }" class="form-control form-control-lg" />
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" [disabled]="processing" class="btn btn-lg btn-link px-5" (click)="cancelEdit()">
                    Cancel
                </button>
                <button type="submit" [disabled]="processing || addForm.invalid || addForm.pristine" class="btn btn-lg btn-primary px-5" (click)="save()">
                    <span [ngClass]="{ 'fa-spin fa-spinner': processing, 'fa-save': !processing }" class="fa fa-fw"></span> Save
                </button>
            </div>
        </form>
    </ng-template>

    <ng-template #deleteTemplate>
        <div class="modal-header bg-light">
            <h4 class="modal-title pull-left">Delete {{ deletionTarget.Label }} {{ deletionTarget.Label2 }}</h4>
        </div>
        <div class="modal-body px-4 text-large pb-0" *ngIf="processingError">
            <p class="alert alert-danger mb-0">
                <span class="fa fa-exclamation-triangle"></span> {{ processingError }}
            </p>
        </div>
        <div class="modal-body px-4 text-large">
            <h4>Are you sure you want to delete {{ deletionTarget.Label }} {{ deletionTarget.Label2 }}?</h4>
        </div>
        <div class="modal-footer">
            <button type="button" [disabled]="processing" class="btn btn-lg btn-link px-5" data-dismiss="modal" (click)="cancelDelete()">
                No, Go Back
            </button>
            <button type="button" [disabled]="processing" class="btn btn-lg btn-danger px-5" (click)="delete()">
                <span class="fa fa-fw" [ngClass]="{ 'fa-spinner fa-spin': processing, 'fa-trash': !processing }"></span> Yes, Delete it
            </button>
        </div>
    </ng-template>




</div>
