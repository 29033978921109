import { OnInit, EventEmitter, OnChanges, OnDestroy, SimpleChanges, } from "@angular/core";
import { FormBuilder, FormGroup, } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString } from "typescript-string-operations";
import { MarketService, DataService, } from "../index";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SearchModalComponent } from "../../shared/search-modal/search-modal.component";
var MarketEditComponent = /** @class */ (function () {
    function MarketEditComponent(translateService, ipsModal, fb, marketService, dataService) {
        var _this = this;
        this.translateService = translateService;
        this.ipsModal = ipsModal;
        this.fb = fb;
        this.marketService = marketService;
        this.dataService = dataService;
        this.marketsToExclude = []; // Set values here that you want disabled in modals and typeahead
        this.balanceChanged = new EventEmitter(true);
        // Fired when the market changes. Will include the parent FormGroup passed in.
        this.marketChanged = new EventEmitter(true);
        this.marketFinalCountChanged = new EventEmitter(true);
        this.pleaseEnterValid_key = "";
        this.pleaseEnter_key = "";
        this.refineAudience_key = "";
        this.refineAudienceInfo_key = "";
        this.featuresInAudience = [];
        this.featuresInQuestion = [];
        this.fixtureGroupsInQuestion = [];
        this.fixturesInQuestion = [];
        this.initialized = false;
        this.TranslateStrings = {
            MARKET_PENDING_LOCATIONS_WARNING: "",
            MAX_LENGTH_ERROR: "",
        };
        this.errorMessages = {
            required: function () { return _this.TranslateStrings[_this.pleaseEnter_key]; },
            maxlength: function (params) {
                return IpsString.Format(_this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength);
            },
            invalidMarketName: function () {
                return _this.TranslateStrings[_this.pleaseEnterValid_key];
            },
        };
        this.marketSources = [];
        this.isMsgMarket = false;
    }
    MarketEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMsgMarket = !!this.balanceCalcService.calculateMarketLocationBalanceList;
        this.pleaseEnterValid_key = "PLEASE_ENTER_VALID_" + this.label;
        this.pleaseEnter_key = "PLEASE_ENTER_" + this.label;
        this.refineAudience_key = "REFINE_" + this.label;
        this.refineAudienceInfo_key = "REFINE_" + this.label + "_INFO";
        if (!this.marketsToExclude) {
            this.marketsToExclude = [];
        }
        else {
            this.featuresInAudience.concat(this.marketsToExclude);
        }
        // track featuresInAudience internally
        this.featuresInQuestion = this.dataService.featuresInQuestion;
        this.fixtureGroupsInQuestion = this.dataService.fixtureGroupsInQuestion;
        this.fixturesInQuestion = this.dataService.fixturesInQuestion;
        this.TranslateStrings[this.pleaseEnterValid_key] = "";
        this.TranslateStrings[this.pleaseEnter_key] = "";
        this.translateService.get("ALL").subscribe(function () { return _this.TranslateText(); });
        this.translateService.onLangChange.subscribe(function () {
            return _this.TranslateText();
        });
        this.CreateForm();
        this.InitalizeForm(this.marketsModel);
        this.initialized = true;
    };
    MarketEditComponent.prototype.ngOnDestroy = function () {
        // Clear these out, we done with them and any changes while open
        // don't apply to the next instance of this component.
        this.dataService.featuresInQuestion = [];
        this.dataService.featuresInAudience = [];
        this.dataService.fixtureGroupsInQuestion = [];
        this.dataService.fixturesInQuestion = [];
    };
    MarketEditComponent.prototype.ngOnChanges = function (changes) {
        if (!this.initialized) {
            return;
        }
        var marketsToExcludeChange = changes.marketsToExclude;
        if (marketsToExcludeChange) {
            this.setCurrentAudiences();
        }
    };
    MarketEditComponent.prototype.TranslateText = function () {
        for (var _i = 0, _a = Object.keys(this.TranslateStrings); _i < _a.length; _i++) {
            var key = _a[_i];
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    };
    MarketEditComponent.prototype.CreateForm = function () {
        var marketCtrl = this.marketService.newMarketFormGroup(1);
        //let marketGroup = this.fb.group({
        //    markets: this.fb.array([marketCtrl])
        //});
        var marketArray = this.fb.array([marketCtrl]);
        if (this.parent.get("Markets")) {
            this.parent.removeControl("Markets");
        }
        this.parent.addControl("Markets", marketArray);
    };
    MarketEditComponent.prototype.InitalizeForm = function (marketsModel) {
        //init markets
        while (this.Markets.length > 0) {
            this.Markets.removeAt(0);
        }
        if (this.Markets.length === 0) {
            this.featuresInAudience = [];
            this.dataService.updateStorage(marketsModel, "FeatureInAudience"); //clear data in featureinaudience
        }
        if (marketsModel.length > 0) {
            this.AddWithExistingMarket(marketsModel);
        }
        else {
            this.refineMarket();
        }
    };
    MarketEditComponent.prototype.AddWithExistingMarket = function (marketsModel, toUpdate) {
        var _this = this;
        if (toUpdate === void 0) { toUpdate = false; }
        var prevMarket = this.Markets.value;
        var marketLength = prevMarket.length;
        var marketCtrl;
        if (marketLength !== 0) {
            if (prevMarket[marketLength - 1].TargetMarketId === 0 &&
                toUpdate &&
                !this.Markets.valid) {
                this.Markets.removeAt(marketLength - 1);
            }
        }
        if (marketsModel.length > 0) {
            marketsModel.map(function (market) {
                marketCtrl = _this.marketService.getMarketFormGroup(market);
                _this.addMarketObservable(marketCtrl);
                _this.Markets.push(marketCtrl);
            });
            this.balanceChanged.emit(marketsModel[marketsModel.length - 1].LocationBalance);
        }
        else {
            this.refineMarket();
        }
        if (toUpdate) {
            marketsModel = this.featuresInAudience.concat(marketsModel);
        }
        //Reset prior list of in use markets
        this.featuresInAudience = marketsModel.concat(this.marketsToExclude);
        // Add to data service aulso if anyone is using the data service to track this. Can't use this for promotion edit because features can be used in multiple
        // audiences, one per message.
        this.dataService.updateStorage(marketsModel, "FeatureInAudience");
    };
    MarketEditComponent.prototype.hasFinalCountObservers = function () {
        return (this.marketFinalCountChanged.observers &&
            this.marketFinalCountChanged.observers.length > 0);
    };
    MarketEditComponent.prototype.AddNewMarkets = function (marketsModel) {
        this.marketsModel = marketsModel;
        this.marketSources = [];
        this.CreateForm();
        this.InitalizeForm(this.marketsModel);
    };
    MarketEditComponent.prototype.getMarketSource = function (index) {
        return this.marketSources[index];
    };
    MarketEditComponent.prototype.refineMarket = function () {
        var newMarket = this.marketService.newMarketFormGroup(this.Markets.length + 1);
        this.addMarketObservable(newMarket);
        this.Markets.push(newMarket);
        this.focusableMarketInput = newMarket.value.InputName;
    };
    MarketEditComponent.prototype.removeMarket = function (marketIndex) {
        var addObservable = false;
        if (this.Markets.length === 1) {
            addObservable = true;
        }
        //save market observables
        this.marketService.removeMarket(marketIndex, this.Markets);
        //update market observables after delete market;
        if (addObservable) {
            this.marketSources.splice(0, 1);
            this.addMarketObservable(this.Markets.at(0));
        }
        else {
            for (var i = marketIndex; i < this.Markets.length; i++) {
                this.marketSources[i] = this.marketSources[i + 1];
            }
            this.marketSources.splice(this.Markets.length, 1);
        }
        this.Markets.markAsDirty();
    };
    /*
     *Call this to open a search modal to search locations within a specific Location Balance
     */
    MarketEditComponent.prototype.showMarketLocationBalanceLocations = function (marketOrdinal) {
        var _this = this;
        if (this.isMsgMarket) {
            var marketList = this.Markets.value;
            var message = {
                Id: 0,
                Name: "",
                PromotionId: 0,
                TempId: 0,
                BusinessIdentity: marketList[0].BusinessIdentity,
                StartDate: this.promoStartDate,
                EndDate: this.promoEndDate,
                Markets: marketList,
            };
            var locationListRequest = {
                PromotionMessage: message,
                TargetMessageTempId: message.TempId,
                MarketOrdinal: marketOrdinal,
            };
            var promise = void 0;
            promise = this.balanceCalcService.calculateMarketLocationBalanceList(locationListRequest);
            promise.then(function (locationIdList) {
                _this.ipsModal
                    .displayTemplateScrollable(SearchModalComponent, {
                    resolve: {
                        search: "listbylocation",
                        listOfLocationIds: locationIdList.map(Number),
                    },
                }, {
                    windowClass: "no-list-group-item-interaction",
                })
                    .then(function () { }, function (rejectReason) { });
            });
        }
        else {
            // Below is for Audience scenario
            var markets = this.getMarketSaveModel();
            var locationListRequest = {
                Markets: markets,
                MarketOrdinal: marketOrdinal,
            };
            this.balanceCalcService
                .calculateAudienceLocationBalanceList(locationListRequest)
                .then(function (locationIdList) {
                _this.ipsModal
                    .displayTemplateScrollable(SearchModalComponent, {
                    resolve: {
                        search: "listbylocation",
                        listOfLocationIds: locationIdList.map(Number),
                    },
                }, {
                    windowClass: "no-list-group-item-interaction",
                })
                    .then(function () { }, function (rejectReason) { });
            });
        }
    };
    MarketEditComponent.prototype.getMarketSaveModel = function () {
        var markets = [];
        this.Markets.value.forEach(function (m, index) {
            var market = {
                Id: m.Id,
                TargetMarketId: m.TargetMarketId,
                TargetMarketType: m.TargetMarketType,
                TargetAttributeValue: m.TargetAttributeValue,
                Ordinal: index + 1,
                OperationType: m.OperationType,
                BusinessIdentity: m.BusinessIdentity,
            };
            markets.push(market);
        });
        return markets;
    };
    MarketEditComponent.prototype.getErrorMessages = function (key) {
        var msgs = Object.assign({}, this.errorMessages);
        return msgs;
    };
    MarketEditComponent.prototype.locationHandlerChange = function (market, newOperation) {
        if (newOperation) {
            market.patchValue({
                OperationType: newOperation,
            });
            // For some reason the patchValue call is not enough to clear pristine on parent formgroup.
            market.markAsDirty();
            //recalculate audience location balance
            this.calculateAudienceLocationBalance();
        }
    };
    MarketEditComponent.prototype.calculateAudienceLocationBalance = function () {
        var _this = this;
        if (this.isMsgMarket) {
            this.balanceChanged.emit(0);
        }
        else {
            var markets = {
                Markets: this.getMarketSaveModel(),
                MarketOrdinal: null,
            };
            // Call server to get new totals
            if (this.hasFinalCountObservers()) {
                // Get balance calc and location id list
                this.balanceCalcService
                    .calculateLocationBalanceAndList(markets)
                    .then(function (response) {
                    _this.marketFinalCountChanged.emit(response.LocationIds);
                    var marketDictionary = [];
                    _this.marketService.updateMarkets(_this.Markets, response.Markets, marketDictionary);
                    _this.balanceChanged.emit(response.Markets[response.Markets.length - 1]
                        .LocationBalance);
                });
            }
            else {
                // Just do balance calc, we don't need location id list.
                this.balanceCalcService
                    .calculateAudienceLocationBalance(markets.Markets)
                    .then(function (response) {
                    var marketDictionary = [];
                    _this.marketService.updateMarkets(_this.Markets, response, marketDictionary);
                    _this.balanceChanged.emit(response[response.length - 1].LocationBalance);
                });
            }
        }
        this.marketChanged.emit(this.parent);
    };
    MarketEditComponent.prototype.marketChangedEventHandler = function () {
        this.calculateAudienceLocationBalance();
        this.setCurrentAudiences();
    };
    MarketEditComponent.prototype.moveMarket = function (shift, currentIndex) {
        var markets = this.Markets;
        if (markets.length > 1) {
            var newIndex = currentIndex + shift;
            if (newIndex >= 0 && newIndex < markets.length) {
                markets.at(newIndex).value.Ordinal = currentIndex;
                markets.at(currentIndex).value.Ordinal = newIndex;
                if (newIndex === -1) {
                    newIndex = markets.length - 1;
                }
                else if (newIndex === markets.length) {
                    newIndex = 0;
                }
                var currentGroup = markets.at(currentIndex);
                markets.removeAt(currentIndex);
                markets.insert(newIndex, currentGroup);
                //Reset all id back to zero so save will work
                var ordinal_1 = 1;
                markets.controls.forEach(function (mrkt) {
                    mrkt.controls["Id"].setValue(0);
                    mrkt.controls["Ordinal"].setValue(ordinal_1++);
                });
                this.calculateAudienceLocationBalance();
            }
        }
    };
    MarketEditComponent.prototype.marketDeletedEventHandler = function (marketIndex) {
        this.removeMarket(marketIndex);
        //recalculate audience location balance
        if (this.Markets.length > 0) {
            this.calculateAudienceLocationBalance();
        }
        else {
            this.balanceChanged.emit(0);
        }
        this.setCurrentAudiences();
    };
    MarketEditComponent.prototype.addNewMarkets = function (newMarkets) {
        var toUpdate = true;
        this.AddWithExistingMarket(newMarkets, toUpdate);
        this.setCurrentAudiences();
        this.calculateAudienceLocationBalance();
    };
    MarketEditComponent.prototype.removeMarkets = function (markets) {
        for (var i = 0; i < markets.length; i++) {
            var indexToRemove = markets[i].Index - i;
            this.marketDeletedEventHandler(indexToRemove);
            this.setCurrentAudiences();
        }
    };
    MarketEditComponent.prototype.setCurrentAudiences = function () {
        //notify markets changes
        //MarketModel has DisplayInfo contents LocationGroupId
        //This is needed for linked feature search modal to check feature availability
        var models = this.Markets.value;
        models.forEach(function (model) {
            if (model.TargetMarketType === "LocationSubGroup") {
                model.DisplayInfo = {
                    LocationGroupId: model.LocationGroupId,
                    LocationGroupName: model.LocationGroupName,
                    LocationSubGroupName: model.LocationSubGroupName,
                    LocationSubGroupId: model.LocationSubGroupId,
                    OptionType: model.OptionType,
                };
            }
        });
        this.featuresInAudience = models.concat(this.marketsToExclude);
        this.dataService.updateStorage(models, "FeatureInAudience");
    };
    MarketEditComponent.prototype.addMarketObservable = function (marketCtrl) {
        var marketSource = this.marketService.createFilteredMarketSource(marketCtrl, this.fixtureGroupsInQuestion, this.featuresInQuestion, this.featuresInAudience, this.fixturesInQuestion);
        this.marketSources.push(marketSource);
    };
    Object.defineProperty(MarketEditComponent.prototype, "Markets", {
        get: function () {
            // return this.parent.controls.marketGroup.get("markets") as FormArray;
            return this.parent.get("Markets");
        },
        enumerable: true,
        configurable: true
    });
    return MarketEditComponent;
}());
export { MarketEditComponent };
