import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { LocationModel } from "../../model/LocationModel";
import { environment } from "../../../../environments/environment";


@Injectable()
export class LocationByFixtureService extends IpsBaseWebService {
    public LocationId: string;
    private route: string;

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Location", "Profile", "v1");
        this.route = `${this.urlApiBaseProductPlusVersion}Fixture/{id}/Location`;
    }

    public headByFixture(id: any, option: any) {
        return this.headByRoute(this.route.replace("{id}", id) + "?showUnassignedItems=" + option);
    }

    // override ipsBaseWebService search method
    public search<T>(searchInfo: SearchInfo, route: string): Promise<T>  {
        return this.searchByRelation(searchInfo, "Fixture/" + searchInfo.id + "/Location" + "/Simple/Search");
    }
}
