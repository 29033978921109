import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { ShippingAddress, AddressValidationModel } from "../../imagine-ui-ng-store-profile/model/AddressModel";
import { AddressModel, CountryModel } from "imagine-ui-ng-core";
import { AddressBookEntry } from "../model/AddressBookEntryModel";

@Injectable()
export class AddressService extends IpsBaseWebService {

    constructor(http: HttpClient, public activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Address", "Profile", "v1");
    }

    // GET /Profile/v1/Address
    // List all addresses

    // POST /Profile/v1/Address
    // Create a new address

    // PUT /Profile/v1/Address
    // Save changes to an address

    // DELETE /Profile/v1/Address/{id}
    // Delete an address

    // GET /Profile/v1/Address/{id}
    // Get specific address by ID.

    // GET /Profile/v1/Address/Location/{pLocationId}
    // Get a list of addresses associated with a location

    // GET /Profile/v1/Address/conditions
    // Return all known conditions from this Api endpoint

    // POST /Profile/v1/Address/Validate
    public validateAddress(postData: any): Promise<AddressValidationModel> {
        const promise = new Promise<AddressValidationModel>((resolve, reject) => {
            this.post<any>(postData, null, `${this.urlApiBasePlusController}Validate`)
                .then((response: AddressValidationModel) => {
                    resolve(response);
                },
                    (reason: any) => {
                        reject(reason.Message || reason.error.Message);
                    });
        });
        return promise;
    }

    public getThreeCharCountryCode(twoCharacterCountry: string, countryList: CountryModel[]): string {
        if (twoCharacterCountry === "US") return "USA";
        const val = countryList.find(c => c.Iso3166TwoCharCountryCode === twoCharacterCountry);
        if (val) {
            return val.Iso3166ThreeCharCountryCode;
        } else {
            return twoCharacterCountry;
        }
    }

    public getTwoCharCountryCode(country: string, countryList: CountryModel[]): string {
        if (country === "USA") return "US";
        if (country && country.length > 2) {
            const val = countryList.find(c => c.Iso3166ThreeCharCountryCode === country);
            if (val) {
                return val.Iso3166TwoCharCountryCode;
            } else {
                return country;
            }
        } else {
            return country;
        }
    }

    public updateAddressModelFromShippingAddress(
        address: AddressModel, data: ShippingAddress, countryList: CountryModel[]
    ): AddressModel {
        let toReturn: AddressModel = {
            Id: address.Id,
            LocationId: address.LocationId,
            Attention: data.Attention,
            Line1: data.Line1,
            Line2: data.Line2,
            City: data.City,
            StateProvince: data.StateProvince,
            Country: this.getThreeCharCountryCode(data.Country, countryList),
            // Country: address.Country,
            PostalCode: data.PostalCode,
            Phone: data.Phone,
            Fax: address.Fax,
            Email: data.Email,
            Lattitude: address.Lattitude,
            Longitude: address.Longitude,
            BusinessIdentity: data.BusinessIdentity,
            Order: address.Order,
            ValidationStatus: data.ValidationStatus,
            LastValidatedOn: data.LastValidatedOn
    };
        return toReturn;
    }

    public addressModeltoShippingAddress(address: AddressModel): ShippingAddress {
        let toReturn: ShippingAddress = {
            Label: "",
            Id: address.Id,
            Attention: address.Attention,
            OrderId: null,
            NeedByDate: null,
            ReleaseDate: null,
            ShipMethodId: null,
            FirstName: null,
            LastName: null,
            Company: null,
            Title: null,
            Line1: address.Line1,
            Line2: address.Line2,
            Line3: null,
            City: address.City,
            StateProvince: address.StateProvince,
            Country: address.Country,
            PostalCode: address.PostalCode,
            Phone: address.Phone,
            Email: address.Email,
            BusinessIdentity: address.BusinessIdentity,
            ValidationStatus: address.ValidationStatus,
            LastValidatedOn: address.LastValidatedOn,
            AddressBookAlias: "",
            AddressBookId: 0,
            RemovedFromAddressBook: false,
            SaveToAddressBook: false
        };
        return toReturn;
    }

    public getAddressBook(): Promise<AddressBookEntry[]> {
        const route = `${this.urlApiBase}Shop/v1/AddressBook`;
        return this.getByRoute(route);
    }

    public saveToAddressBook(entry: AddressBookEntry): Promise<AddressBookEntry[]> {
        const route = `${this.urlApiBase}Shop/v1/AddressBookEntry`;
        return this.put(entry, null, route);
    }

    public deleteFromAddressBook(entry: AddressBookEntry): Promise<AddressBookEntry[]> {
        const route = `${this.urlApiBase}Shop/v1/AddressBookEntry/${entry.Id}`;
        const returnPromise = new Promise<AddressBookEntry[]>((resolve, reject) => {
            this.deleteByRoute(route).then((deleteResponse: any) => {
                if (deleteResponse.Successful === true && deleteResponse.Resource === true) {
                    this.getAddressBook().then((addressBookResponse) => {
                        resolve(addressBookResponse);
                    });
                } else {
                    reject("Unable to remove entry from address book");
                }
            });
        });

        return returnPromise;
    }

    public convertToShippingAddress(entry: AddressBookEntry): ShippingAddress {
        const returnModel = (<ShippingAddress>{});

        Object.assign(returnModel, entry.Address);
        returnModel.AddressBookAlias = entry.Alias;
        returnModel.Label = entry.Recipient;
        returnModel.AddressBookId = entry.Id;
        returnModel.SaveToAddressBook = true;
        returnModel.Company = entry.Address.Attention;

        return returnModel;
    }

    public convertToAddressBookEntry(address: ShippingAddress): AddressBookEntry {
        const returnModel = (<AddressBookEntry>{});
        returnModel.Id = address.AddressBookId;
        returnModel.Alias = address.AddressBookAlias;
        returnModel.Recipient = address.Label;
        returnModel.AddressId = address.Id;
        returnModel.BusinessIdentity = address.BusinessIdentity;

        returnModel.Address = (<AddressModel>{});
        Object.assign(returnModel.Address, address);

        return returnModel;
    }
}
