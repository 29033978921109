import {first, distinctUntilChanged, debounceTime} from "rxjs/operators";
import { Directive, forwardRef, Input } from "@angular/core";
import { NG_ASYNC_VALIDATORS, AsyncValidator, FormControl } from "@angular/forms";
import { CustomDataService } from "../service/custom-data.service";
import { Observable } from "rxjs";

@Directive({
    selector: "[appFieldNameValidator][ngModel]",
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: forwardRef(() => FieldNameValidator),
        multi: true
    }]
})
export class FieldNameValidator implements AsyncValidator {
    @Input() appFieldNameValidator: string;

    constructor(private customDataService: CustomDataService) {
    }

    fieldNameValidator(name: string): Observable<{ [key: string]: any }> {
        return new Observable(observer => {
            if (!name || name === this.appFieldNameValidator) {
                observer.next(null);
                return;
            }

            this.customDataService.isFieldNameUsed(name).then(result => {
                if (result) {
                    observer.next({ "dupName": true });
                } else {
                    observer.next(null);
                }
            });
        });
    }

    validate(c: FormControl): Observable<{ [key: string]: any }> {
        return this.fieldNameValidator(c.value).pipe(debounceTime(350), distinctUntilChanged(), first());
    }
}
