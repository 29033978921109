import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SearchResponse } from "imagine-ui-ng-core";
import { LocationService, LocationModel, LocationSearchInfo } from "../../../imagine-ui-ng-store-profile";
import { KeyValuePair } from "../../index";

interface DialogListItemModel {
    Id: number;
    Label: string;
    Address: string;
    AddressData?: object;
}

@Component({
    selector: "app-cart-location-shipping-modal",
    templateUrl: "./cart-location-shipping-modal.component.html"
})

export class CartLocationShippingModalComponent implements OnInit {
    // ----- DATA -----
    public cartLocations: KeyValuePair<number, string>[];
    public currentSelections: number[];
    public availableLocations: DialogListItemModel[];
    public allLocations: DialogListItemModel[];
    public total: number;
    public filter: string;
    public pluralMapping: { [k: string]: string } = {};

    constructor(
        private locationService: LocationService,
        private translateService: TranslateService,
        private modalInstance: NgbActiveModal
    ) {
        this.filter = "";
    }

    // ----- FUNCTIONS -----
    //
    private translateText() {
        this.translateService.get([
            "FOUND_OF", "TOTAL", "SELECT_LOCATIONS", "CANCEL", "LOCATION", "LOCATIONS",
        ]).subscribe((res: [string]) => {
            this.pluralMapping["=1"] = this.translateService.instant("LOCATION");
            this.pluralMapping["other"] = this.translateService.instant("LOCATIONS");
        });
    }

    //
    public getLocationAddresses(): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            this.locationService.searchLocationsByIds(this.allLocations.map(x => x.Id))
            .then((locationSearchResponse: SearchResponse<LocationModel>) => {
                for (let i = 0; i < locationSearchResponse.ResultList.length; i++) {
                    let tempIndex = this.allLocations.findIndex(
                        x => x.Id === locationSearchResponse.ResultList[i].Id
                    );
                    if (tempIndex !== -1) {
                        this.allLocations[tempIndex].AddressData =
                            locationSearchResponse.ResultList[i].Addresses[0];
                        this.allLocations[tempIndex].Address =
                            this.allLocations[tempIndex].AddressData["Line1"] + " " +
                            this.allLocations[tempIndex].AddressData["City"] + ", " +
                            this.allLocations[tempIndex].AddressData["StateProvince"] + " " +
                            this.allLocations[tempIndex].AddressData["Country"] + " " +
                            this.allLocations[tempIndex].AddressData["PostalCode"];
                    }
                }
                resolve(true);
            });
        });
        return promise;
    }

    //
    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    //
    public filterUpdated() {
        this.availableLocations = [];
        for (let i = 0; i < this.allLocations.length; i++) {
            if (this.allLocations[i].Label.toUpperCase().indexOf(this.filter.toUpperCase()) >= 0) {
                this.availableLocations.push(this.allLocations[i]);
            }
        }
    }

    // ----- ON INIT -----
    //
    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.allLocations = [];
        for (let i = 0; i < this.cartLocations.length; i++) {
            const selected = (this.currentSelections.indexOf(this.cartLocations[i].Key) !== -1);
            if (selected) {
                this.allLocations.push({
                    Id: this.cartLocations[i].Key,
                    Label: this.cartLocations[i].Value,
                    Address: ""
                });
            }
        }
        this.allLocations.sort((a, b) => a.Id - b.Id);
        this.getLocationAddresses()
        .then(() => {
            this.filterUpdated();
            this.total = this.allLocations.length;
        });
    }
}
