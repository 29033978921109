import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

import {
    PromotionMessagePlacementElementEditModel, PromotionMessageService, PromotionMessageModel, CampaignData, Operator,
    PromotionMessagePlacementElementDetailModel, PromotionMessagePlacementElementModel, MessagePlacementCalculationResultModel,
    MessagePlacementCalculationModel, PromotionModel, ElementLocationBalanceListRequestModel, FulfillmentOperationType, PromotionMessagePatternModel, MessageNameChangeModel,
    PromotionSaveModel, PromotionMessagePatternSaveModel, PlacementModalSearchResultModel
} from "../../index";
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from "@angular/forms";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { ElementModel, ElementDetailModel, SpaceModel, SpaceService, FixtureGroupService } from "../../../imagine-ui-ng-store-profile/index";
import { ElementQuantityHandlingModalComponent } from "../element-quantity-handling-modal/element-quantity-handling-modal.component";
import { PromotionHelperService } from "../../service/promotionHelper.service";
import { AbstractControl } from "@angular/forms/src/model";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { CustomDataFieldContainerComponent } from "../../../shared/custom-data-field/custom-data-field-container/custom-data-field-container.component";
import { PlacementSearchModalComponent } from "../placement-search-modal/placement-search-modal.component";
import { SelectListComponent } from "../../../shared/select-list/select-list.component";
import { SignPlanService } from "../../../shared/service/sign-plan.service";

export interface PlacementElementEditUiModel extends PromotionMessagePlacementElementEditModel {
    IdName: string;
    TempId: number;
    //This is needed for the auto-grow binding to work correctly
    TempDisplayName: string;
    SpaceId: number;
    FixtureGroupName: string;
    HolderName: string;
    ElementId: number;
}

export interface ElementHeaderControl {
    DisplayName: string;
    ElementDetailId: number;
    MessageElementPlacements: FormArray;
}

@Component({
    selector: "app-placement-element-edit",
    templateUrl: "./placement-element-edit.component.html",
    styleUrls: ["./placement-element-edit.component.scss"]
})
export class PlacementElementEditComponent implements OnInit, AfterViewChecked {
    @ViewChildren(CustomDataFieldContainerComponent) customDataFieldContainers: QueryList<CustomDataFieldContainerComponent>;

    @Input() parent: FormGroup;
    @Input() messageAndPlacements: MessagePlacementCalculationResultModel[];
    @Input() campaignFulfillmentQty: number;
    @Input() campaignFulfillmentType: FulfillmentOperationType;
    @Input() campaignElementQty: number;
    @Input() promoId: number;
    @Input() isClone = false;
    @Input() enableSignPlanLayout = false;

    public loaded: boolean;
    public promise: Promise<void>;
    private indexer = 0;
    private marketedLocationsText: string;
    private locationGroupLocationsPendingWarning: string;
    private positiveNumberPattern = "^\\d+$";
    private spaces: SpaceModel[] = [];
    public signPlanWidthOptions: any[] = [];
    //Form vars

    constructor(private ipsModal: IpsModalService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private activeProfileService: ActiveProfileService,
        private translateService: TranslateService,
        private spaceService: SpaceService,
        private promotionMessageService: PromotionMessageService,
        private fb: FormBuilder,
        private promotionHelperService: PromotionHelperService,
        private fixtureGroupService: FixtureGroupService,
        signPlanService: SignPlanService) {
        this.signPlanWidthOptions = signPlanService.SignPlanWidthOptions;
    }

    private TranslateStrings: { [key: string]: string } = {
        "ENTER_POSITIVE_WHOLE_NUMBER": "",
        "MAX_LENGTH_ERROR": ""
    };

    private errorMessages = {
        "required": () => this.TranslateStrings["ENTER_POSITIVE_WHOLE_NUMBER"],
        "pattern": () => this.TranslateStrings["ENTER_POSITIVE_WHOLE_NUMBER"],
        "maxlength": (params) => IpsString.Format(this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength)
    };

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    ngOnInit() {

        this.loaded = false;

        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(() => this.TranslateText());

        this.spaceService.getList<SpaceModel[]>().then(ret => {
            ret.unshift({ Id: 0, Name: this.translateService.instant("SELECT_SPACE"), Notes: "", BusinessIdentity: "" });
            this.spaces = ret;
        });

        this.initializePlacementsForUIAndCreateFormControls();
        this.sortElements();
        this.loaded = true;

        return this.promise;
    }

    createFormControls(placementElements: PromotionMessagePlacementElementEditModel[]): any {
        let placementElementsArr = this.fb.array(this.createElementFormArray(placementElements));

        this.parent.addControl("placementElementsFrmArr", placementElementsArr);
    }

    private sortElements() {
        let elements = this.placementElementsFrmArr;
        elements.controls = elements.controls.sort((n1, n2) => (n1.value.DisplayName.localeCompare(n2.value.DisplayName)));
    }

    private createElementFormArray(placementElements: PromotionMessagePlacementElementEditModel[]): any[] {
        let formArray = [];
        let elementNameDict = {};

        placementElements.forEach((element) => {
            let eleHeaderFormGroup = elementNameDict[element.DisplayName] as FormGroup;

            if (!eleHeaderFormGroup) {
                // Create an array we can push to, per message, for this element.
                eleHeaderFormGroup = this.createElementHeaderFormGroup(element);
                elementNameDict[element.DisplayName] = eleHeaderFormGroup;
                formArray.push(eleHeaderFormGroup);
            }

            // Now add an element formGroup for this element entry
            (this.getMsgPlacementElementsCtrl(eleHeaderFormGroup)).push(this.createPlacementElementFormGroup(element as PlacementElementEditUiModel));
        });

        return formArray;
    }

    private createElementHeaderFormGroup(placement: PromotionMessagePlacementElementEditModel): FormGroup {
        return this.fb.group({
            DisplayName: this.fb.control(placement.DisplayName),
            ElementDetailId: this.fb.control(placement.ElementDetailId),
            MessageElementPlacements: this.fb.array([])
        });
    }

    public setHolderPosition(placement: FormGroup, position: number) {
        if (position < 1 || position > 17) {
            position = 1;
        }

        placement.get("PlacementLocation").setValue(position);
    }

    public getHolderPosition(placement: FormGroup): number {
        return placement.get("PlacementLocation").value;
    }

    private createPlacementElementFormGroup(placement: PlacementElementEditUiModel): FormGroup {
        let elementForm = this.fb.group(placement);
        elementForm.addControl("Qty", this.fb.control(placement.PlacementElementDetails[0].Quantity, [Validators.required, Validators.pattern(this.positiveNumberPattern)]));
        if (placement.LocationCount === undefined) {
            elementForm.addControl("LocationCount", this.fb.control(undefined));
        }
        const spaceId = placement.Spaces !== undefined ? placement.Spaces.length > 0 ? placement.Spaces[0].Id : 0 : 0;
        elementForm.addControl("SpaceId", this.fb.control(spaceId));

        let spaceGroups = placement.Spaces.map((space) => {
            return this.fb.group(space);
        });
        elementForm.setControl("Spaces", this.fb.array(spaceGroups));
        elementForm.addControl("HolderId", this.fb.control(placement.HolderId || null));
        elementForm.addControl("HolderName", this.fb.control(placement.HolderName || null));
        elementForm.addControl("FixtureGroupName", this.fb.control(placement.FixtureGroupName || null));
        elementForm.addControl("SignPlanMessage", this.fb.control(placement.SignPlanMessage));
        elementForm.addControl("SignPlanWidth", this.fb.control(placement.SignPlanWidth));
        elementForm.addControl("PlacementLocation", this.fb.control(placement.PlacementLocation || null));

        elementForm.get("FulfillmentOperand").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
        // Make element details a nested form array
        elementForm.removeControl("PlacementElementDetails");
        this.createPlacementElementDetailsFormArray(elementForm, placement.PlacementElementDetails);

        elementForm.addControl("TempDisplayName", this.fb.control(placement.QuantityHandling === "MarketedLocations" ? this.marketedLocationsText : placement.LocationGroupName));

        elementForm.get("CustomerItemCode").setValidators([Validators.maxLength(50)]);

        this.addListeners(elementForm);

        return elementForm;
    }

    private createPlacementElementDetailsFormArray(elementForm: FormGroup, placementDetails: PromotionMessagePlacementElementDetailModel[]) {
        let placementDetailFormGroups = placementDetails.map((detail) => {
            let group = this.fb.group(detail);
            group.get("Quantity").setValidators([Validators.required, Validators.pattern(this.positiveNumberPattern)]);
            return group;
        });
        elementForm.setControl("PlacementElementDetails", this.fb.array(placementDetailFormGroups));

        this.addElementDetailListeners(elementForm);
    }

    private addNewPlacementElementControls(placements: PlacementElementEditUiModel[]): void {
        // Grab the first item so we can make the header entry
        let eleHeaderFormGroup = this.createElementHeaderFormGroup(placements[0]);
        placements.forEach((element) => {
            let placementElementControl =
                this.createPlacementElementFormGroup(element as PlacementElementEditUiModel);
            // Now add an element formGroup for this element entry
            (this.getMsgPlacementElementsCtrl(eleHeaderFormGroup)).push(placementElementControl);
        });
        this.placementElementsFrmArr.push(eleHeaderFormGroup);
        this.placementElementsFrmArr.markAsDirty();
    }

    get placementElementsFrmArr(): FormArray {
        return this.parent.get("placementElementsFrmArr") as FormArray;
    }

    private addListeners(elementForm: FormGroup) {
        elementForm.get("Qty").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
            // The form watchs qty on the parent control, but placement calc expects qty on the first detail record, so set it
            this.getPlacmentElementDetailsCtrl(elementForm).at(0).patchValue({ Quantity: value });
            this.qtyChange(elementForm);
        });

        elementForm.get("FulfillmentOperand").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(() => {
            this.fulfillmentOperandChanged(elementForm);
        });
    }

    private addElementDetailListeners(elementForm: FormGroup) {
        let elementDetails = this.getPlacmentElementDetailsCtrl(elementForm);
        elementDetails.controls.forEach((detail) => {
            detail.get("Quantity").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(() => {
                this.qtyChange(elementForm);
            });
        });
    }

    public GetCurrentPlacementElements(): PlacementElementEditUiModel[] {
        let result = [] as PlacementElementEditUiModel[];
        let elementHeaders = this.placementElementsFrmArr.controls.map(element => element.value);
        elementHeaders.forEach((elementHeader) => {
            result = result.concat(elementHeader.MessageElementPlacements);
        });
        return result;
    }

    private TranslateText() {
        this.translateService.get("ALL").subscribe(() => {
            this.marketedLocationsText = this.translateService.instant("MARKETED_LOCATIONS");
            this.locationGroupLocationsPendingWarning = this.translateService.instant("MARKET_PENDING_LOCATIONS_WARNING");

            for (let key of Object.keys(this.TranslateStrings)) {
                this.TranslateStrings[key] = this.translateService.instant(key);
            }
        });
    }

    public getErrorMessages() {
        return this.errorMessages;
    }
    /**
     * Call to set various properties needed by UI.
     */
    private initializePlacementsForUIAndCreateFormControls(): void {
        let placementElements = [] as PromotionMessagePlacementElementEditModel[];
        let promotionMessages = [] as PromotionMessageModel[];

        if (!this.parent.get("ClearAllMessages").value) {
            this.messageAndPlacements.forEach((mainMsg) => {
                if (this.isClone) {
                    mainMsg.PromotionMessage.Id = 0;
                }
                promotionMessages.push(mainMsg.PromotionMessage);
                mainMsg.PlacementElements.forEach((placement: PlacementElementEditUiModel) => {
                    if (this.isClone) {
                        placement.Id = 0;
                        placement.MessageId = 0;
                        placement.CustomerItemCode = undefined;
                    }
                    placement.DisplayName = `${placement.ElementDetailName} - ${placement.ElementDetailWidth} x ${placement.ElementDetailHeight}`;
                    placement.IdName = "placementElement" + this.indexer++;
                    placement.TempId = this.indexer++;
                    placement.CustomerItemCode = placement.CustomerItemCode ? placement.CustomerItemCode : null;
                    placement.ElementDetailCategoryCode = placement.ElementDetailCategoryCode ? placement.ElementDetailCategoryCode : null;

                    // Set message info
                    placement.TempMessageId = mainMsg.PromotionMessage.TempId;
                });
                placementElements = placementElements.concat(mainMsg.PlacementElements);
            });
        }

        this.createFormControls(placementElements);
    }

    public getPendingLocationWarningString(pendingAmount: number): string {
        return IpsString.Format(this.locationGroupLocationsPendingWarning, pendingAmount.toString());
    }

    // Get the list of element placements that exist for the specified elementDetail control
    private getMsgPlacementElementsCtrl(elementHeader: FormGroup): FormArray {
        return elementHeader.get("MessageElementPlacements") as FormArray;
    }

    private getPlacmentElementDetailsCtrl(placement: FormGroup): FormArray {
        return placement.get("PlacementElementDetails") as FormArray;
    }

    public onMainMessageDeleted(mainMessage: PromotionMessagePatternModel): void {
        let elementHeadersToRemove = [] as number[];
        this.placementElementsFrmArr.controls.forEach((elementHeader, index) => {
            let placementElementsCtrl = this.getMsgPlacementElementsCtrl(elementHeader as FormGroup);
            placementElementsCtrl.controls.some((placementControl, placeIndex) => {
                let placement = placementControl.value as PromotionMessagePlacementElementEditModel;
                if (placement.TempMessageId === mainMessage.TempMessageId) {
                    placementElementsCtrl.removeAt(placeIndex);
                    // Should only be one placement per message, break out.
                    return true;
                }
                // tell the 'some' loop to keep going.
                return false;
            });

            if (placementElementsCtrl.controls.length === 0) {
                elementHeadersToRemove.push(index);
            }
        });

        for (let i = elementHeadersToRemove.length - 1; i >= 0; i--) {
            this.placementElementsFrmArr.removeAt(elementHeadersToRemove[i]);
        }
        if (elementHeadersToRemove.length > 0) {
            this.placementElementsFrmArr.markAsDirty();
        }
    }

    public onMainMessageAdded(mainMessage: PromotionMessagePatternModel): void {
        this.placementElementsFrmArr.controls.forEach((elementHeader, index) => {
            let existingPlacements = this.getMsgPlacementElementsCtrl(elementHeader as FormGroup);
            const existingPlacement = existingPlacements.at(0).value as PlacementElementEditUiModel;
            // Add a placement for the new message
            let element = { Name: existingPlacement.ElementDetailName, Id: existingPlacement.ElementId } as ElementModel;
            let detail = { Id: existingPlacement.ElementDetailId, Width: existingPlacement.ElementDetailWidth, Height: existingPlacement.ElementDetailHeight, CategoryCode: existingPlacement.ElementDetailCategoryCode } as ElementDetailModel;
            let placement = this.getNewPlacement(mainMessage, element, detail);
            existingPlacements.push(this.createPlacementElementFormGroup(placement));
        });
    }

    // Call this when the user updates the list of selected elements
    public onPlacementElementsAdded(response: ElementModel[]) {
        let changedPlacements = false;

        // remove elements not selected
        let indexesToRemove = [] as number[];
        this.placementElementsFrmArr.controls.forEach((elementHeader, index) => {
            let elementHeaderControl = elementHeader.value as ElementHeaderControl;
            let found = response.some((item) => {
                return item.ElementDetails.some((detail) => {
                    return detail.Id === elementHeaderControl.ElementDetailId;
                });
            });
            if (!found) {
                indexesToRemove.push(index);
            }
        });

        if (indexesToRemove.length > 0) {
            // Walk backwards removing items from the end of the formArray
            for (let i = indexesToRemove.length - 1; i >= 0; i--) {
                this.placementElementsFrmArr.removeAt(indexesToRemove[i]);
            }
            this.placementElementsFrmArr.markAsDirty();
        }

        // Fill in placement rows
        const messages = this.promotionHelperService.getCurrentMessagesFromForm(this.parent);
        let controls = this.placementElementsFrmArr.controls;
        let elementDetailIds = [] as number[];
        response.forEach((element: ElementModel) => {
            element.ElementDetails.forEach((detail) => {
                // See if we already have this element detailId
                let hasElementDetailId = controls.some(elementHeader => (elementHeader.value as ElementHeaderControl).ElementDetailId === detail.Id);
                if (!hasElementDetailId) {
                    let placements = [] as PlacementElementEditUiModel[];
                    messages.forEach((message) => {
                        let placement = this.getNewPlacement(message, element, detail);
                        placements.push(placement);
                    });

                    // Create the elementHeader and then all the child controls for each message.
                    this.addNewPlacementElementControls(placements);
                    elementDetailIds.push(detail.Id);
                    changedPlacements = true;
                }
            });
        });
        // Mark as dirty
        if (changedPlacements) {
            this.sortElements();
            for (let j = 0; j < messages.length; j++) {
                this.calculateAllPlacementQuantities(messages[j].TempMessageId, elementDetailIds);
            }
        }
    }

    private getNewPlacement(mainMsg: PromotionMessagePatternModel, element: ElementModel, elementDetail: ElementDetailModel): PlacementElementEditUiModel {
        let index = this.indexer++;
        // Generate a new placement for a given holder and its fixture group (aka fixtureType)
        let placement = <PlacementElementEditUiModel>{
            ElementId: element.Id,
            ElementDetailId: elementDetail.Id,
            ElementDetailName: element.Name,
            ElementDetailWidth: elementDetail.Width,
            ElementDetailHeight: elementDetail.Height,
            ElementDetailCategoryCode: elementDetail.CategoryCode,
            PromotionId: this.promoId,
            DisplayName: `${element.Name} - ${elementDetail.Width} x ${elementDetail.Height}`,
            Id: 0, // New placement, use id 0
            IdName: "placement" + index,
            TempId: index,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            MessageId: mainMsg.Id,
            TempMessageId: mainMsg.TempMessageId,
            MessageName: mainMsg.MessageName,
            QuantityHandling: "MarketedLocations",
            FulfillmentOperand: this.campaignFulfillmentQty,
            FulfillmentOperator: this.campaignFulfillmentType,
            FinalQuantity: 0, // Calculated, default to 0
            PlacementElementDetails: [{ Id: 0, FinalQuantity: 0, LocationCount: 0, Quantity: this.campaignElementQty || 0, BusinessIdentity: this.activeProfileService.businessIdentity }],
            PendingAssignmentCount: 0,
            TempDisplayName: this.marketedLocationsText,
            LocationGroupId: "",
            LocationGroupName: "",
            LocationCount: 0,
            Selected: true,
            CustomerItemCode: null,
            SpaceId: 0,
            HolderId: null,
            PlacementLocation: "TopLeft",
            Spaces: [],
            SpaceList: [],
            SignPlanMessage: null,
            SignPlanWidth: null,
            HolderName: null,
            FixtureGroupName: null
        };

        return placement;
    }

    public isLocationGroupQtyHandling(placement: PromotionMessagePlacementElementEditModel): boolean {
        return placement !== undefined && placement.QuantityHandling === "LocationGroup";
    }

    public shouldShowPlacements(): boolean {
        return this.loaded && this.placementElementsFrmArr.controls.length > 0;
    }

    public getOperatorIconName(operator: Operator): string {
        let result = "NA";
        switch (operator) {
            case "Percent":
                result = "fa-percent";
                break;
            case "Number":
                result = "fa-hashtag";
                break;
        }
        return result;
    }

    public showQuantityHandlingModal(placementControl: FormGroup, toolTip: NgbTooltip) {
        toolTip.close();
        let placement = placementControl.value as PromotionMessagePlacementElementEditModel;
        return this.ipsModal.displayTemplateScrollable(ElementQuantityHandlingModalComponent,
            { resolve: { marketedLocations: placement.LocationCount } })
            .then((response: any) => {
                if (response === undefined) {
                    placementControl.patchValue({
                        LocationGroupId: undefined,
                        LocationGroupName: undefined,
                        QuantityHandling: "MarketedLocations",
                        FinalQuantity: 0, // Calculated, default to 0
                        PendingAssignmentCount: 0,
                        TempDisplayName: this.marketedLocationsText
                    });

                    let placementDetails = placementControl.get("PlacementElementDetails") as FormArray;

                    //Remove all placement element details rows
                    while (placementDetails.length > 0) {
                        placementDetails.removeAt(0);
                    }

                    //Add Marketed Locations
                    placementDetails.push(this.fb.group({
                        Id: this.fb.control(0),
                        Quantity: this.fb.control(this.campaignElementQty || 1),
                        FinalQuantity: this.fb.control(0),
                        LocationCount: this.fb.control(0),
                        BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity)
                    }));

                    placementControl.markAsDirty();
                } else {
                    if (response.Id !== placement.LocationGroupId) {
                        let subGroupsElementDetails = [] as PromotionMessagePlacementElementDetailModel[];
                        // Load up the subgroups
                        response.SubGroups.forEach(
                            (item) => {
                                let subGroupDetail = <PromotionMessagePlacementElementDetailModel>{
                                    Id: 0,
                                    LocationSubGroupId: item.Id,
                                    LocationSubGroupName: item.Name,
                                    Quantity: this.campaignElementQty || 1,
                                    LocationCount: 0, // Calcualted, default to 0
                                    FinalQuantity: 0 // Calculated, default to 0
                                };
                                subGroupsElementDetails.push(subGroupDetail);
                            });

                        //For case when coming from "MarketedLocations" to a location group. add missing controls
                        if (!placementControl.controls.LocationGroupId) {
                            placementControl.addControl("LocationGroupId", this.fb.control(0));
                        }
                        if (!placementControl.controls.LocationGroupName) {
                            placementControl.addControl("LocationGroupName", this.fb.control(""));
                        }

                        placementControl.patchValue({
                            LocationGroupId: response.Id,
                            LocationGroupName: response.Name,
                            PendingAssignmentCount: response.PendingAssignmentCount,
                            QuantityHandling: "LocationGroup",
                            FinalQuantity: 0, // Calculated, default to 0
                            TempDisplayName: response.Name
                        });
                        placementControl.markAsDirty();

                        let detailsControl = this.getPlacmentElementDetailsCtrl(placementControl);
                        // remove all the original controls
                        for (let i = detailsControl.length - 1; i >= 0; i--) {
                            detailsControl.removeAt(i);
                        }
                        this.createPlacementElementDetailsFormArray(placementControl, subGroupsElementDetails);
                    }
                }

                this.calculatePlacementElementQuantities(placementControl);

            }, (rejectMessage) => {
                // Do nothing, prevent console error
            });
    }

    public fulfillmentOperandChanged(placementElementCtrl: FormGroup): void {
        this.calculatePlacementElementQuantities(placementElementCtrl);
    }

    public fulfillmentOperatorClick(placementElementCtrl: FormGroup): void {
        let placementElement = placementElementCtrl.value as PromotionMessagePlacementElementModel;
        placementElementCtrl.patchValue({
            FulfillmentOperator: placementElement.FulfillmentOperator === "Percent" ? "Number" : "Percent"
        });
        placementElementCtrl.markAsDirty();

        this.calculatePlacementElementQuantities(placementElementCtrl);
    }


    public togglePrintMessages(placementElementCtrl: FormGroup, print: boolean) {
        placementElementCtrl.patchValue({
            Selected: print
        });
        placementElementCtrl.markAsDirty();
    }

    public qtyChange(placementElementCtrl: FormGroup): void {
        this.calculatePlacementElementQuantities(placementElementCtrl);
    }

    private calculateAllPlacementQuantities(tempMessageId: number, elementDetailIds?: number[]) {
        let patternGroup = this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, tempMessageId);
        patternGroup.StartDate = this.parent.value.StartDate;
        patternGroup.EndDate = this.parent.value.EndDate;

        let placementElements = this.getElementPlacementByMessage(tempMessageId, elementDetailIds);

        //Remove un-needed spaces
        if (placementElements) {
            placementElements = Object.assign([], placementElements);
            placementElements.forEach(item => {
                // delete item.Spaces;
            });
        }

        //re-calculate
        let balanceCalcModel = <MessagePlacementCalculationModel>{
            PatternGroupInfo: patternGroup,
            MessagePlacements: undefined,
            PlacementElements: placementElements,
            ReturnLocationBalance: false,
            PlacementGroups: undefined,
            DefaultFulfillmentOperator: this.campaignFulfillmentType,
            DefaultFulfillmentQuantity: this.campaignFulfillmentQty
        };

        // Call server to get new totals
        this.promotionMessageService.calculateLocationBalance(balanceCalcModel).then((response: MessagePlacementCalculationResultModel) => {
            if (response !== null) {
                //update placement elements location count
                this.UpdateElementAfterCalculation(response.PlacementElements, tempMessageId);
            }
        });
    }

    private calculatePerPatternGroupPlacementElementQuantities(elementHeader: FormGroup) {
        let placementElements = this.getMsgPlacementElementsCtrl(elementHeader);
        placementElements.controls.forEach((placementElementCtrl: FormGroup) => {
            this.calculatePlacementElementQuantities(placementElementCtrl);
        });
    }

    private calculatePlacementElementQuantities(placementElementControl: FormGroup) {
        let placementElement: PromotionMessagePlacementElementEditModel = placementElementControl.value;

        // only recalc if we have a valid value
        let invalid = this.hasInvalidField(placementElement);
        if (!invalid) {

            let balanceCalcModel = this.deriveMessagePlacementCalculationModel(placementElement);

            //Remove un-needed spaces
            if (balanceCalcModel.PlacementElements) {
                balanceCalcModel.PlacementElements = Object.assign([], balanceCalcModel.PlacementElements);
                balanceCalcModel.PlacementElements.forEach(item => {
                    // delete item.Spaces;
                });
            }

            this.promotionMessageService.calculateLocationBalance(balanceCalcModel).then((response: MessagePlacementCalculationResultModel) => {
                if (response !== null) {
                    this.updateElementQty(response.PlacementElements[0], placementElementControl);
                }
            });
        }
    }

    private updateElementQty(placementElement: PromotionMessagePlacementElementModel, placementElementControl: FormGroup): void {
        placementElementControl.patchValue({
            LocationCount: placementElement.LocationCount,
            FinalQuantity: placementElement.FinalQuantity
        });

        // Update subgroups if we have them
        let formDetails = this.getPlacmentElementDetailsCtrl(placementElementControl);
        formDetails.controls.forEach((ctrl, index) => {
            let existingDetail = ctrl.value as PromotionMessagePlacementElementDetailModel;
            let resultDetail =
                placementElement.PlacementElementDetails.find((item) => existingDetail.LocationSubGroupId ===
                    item.LocationSubGroupId);
            if (resultDetail) {
                ctrl.patchValue({
                    LocationCount: resultDetail.LocationCount,
                    FinalQuantity: resultDetail.FinalQuantity
                });
            }
        });
    }

    public UpdateElementAfterCalculation(placementElements: PromotionMessagePlacementElementModel[], tempMessageId: number) {
        this.placementElementsFrmArr.controls.forEach(elementHeader => {
            let plcElementArray = this.getMsgPlacementElementsCtrl(elementHeader as FormGroup);
            plcElementArray.controls.forEach(elementCtrl => {
                const placementElement = elementCtrl.value as PlacementElementEditUiModel;
                if (tempMessageId === placementElement.TempMessageId) {
                    let elementObj = placementElements.find(ele => ele.ElementDetailId === placementElement.ElementDetailId);
                    // NOTE: when adding a new element placement, we only calculate for those new elements, so it is possible to find a placement for
                    // the existing tempMessageId, but then not find an elementDetail in the calc result model.
                    if (elementObj) {
                        this.updateElementQty(elementObj, <FormGroup>elementCtrl);
                    }
                }
            });
        });
    }

    /**
     * Returns undefined if no element placements. Otherwise returns a list of element placements for the given tempMessageId
     * @param tempMessageId
     */
    public getElementPlacementByMessage(tempMessageId: number, elementDetailIds?: number[]): PromotionMessagePlacementElementModel[] {
        if (this.placementElementsFrmArr && this.placementElementsFrmArr.length > 0) {
            let elementPlacements = this.GetCurrentPlacementElements();
            return elementPlacements.filter(placement => tempMessageId === placement.TempMessageId &&
                (!elementDetailIds || elementDetailIds.some(ed => ed === placement.ElementDetailId)));
        }
        return undefined;
    }

    /**
     * Send in the placementElement and finds the correct market based on the tempMessageId.
     * @param placementElement
     */
    private deriveMessagePlacementCalculationModel(placementElement: PromotionMessagePlacementElementEditModel): MessagePlacementCalculationModel {
        let calcModel = <MessagePlacementCalculationModel>{
            MessagePlacements: undefined,
            PlacementElements: [placementElement as PromotionMessagePlacementElementModel],
            ReturnLocationBalance: false,
            PatternGroupInfo: this.promotionHelperService.getPatternGroupForMessageFromForm(this.parent, placementElement.TempMessageId),
            PlacementGroups: undefined,  // Not needed for element placements
            DefaultFulfillmentOperator: this.campaignFulfillmentType,
            DefaultFulfillmentQuantity: this.campaignFulfillmentQty
        };

        calcModel.PatternGroupInfo.StartDate = this.parent.value.StartDate;
        calcModel.PatternGroupInfo.EndDate = this.parent.value.EndDate;

        return calcModel;
    }

    private hasInvalidField(placementElement: PromotionMessagePlacementElementEditModel): boolean {
        let invalid = false;
        //main message
        if (placementElement.FulfillmentOperand === null || placementElement.FulfillmentOperand < 0) {
            invalid = true;
        }

        if (!invalid) {
            invalid = placementElement.PlacementElementDetails.some(function (item) {
                return item.Quantity === null || item.Quantity < 0;
            });
        }

        return invalid;
    }

    public hasQtyWarning(elementPlcCtrl: FormControl) {
        let val = elementPlcCtrl.value;
        if (val !== undefined && Number(val) < 1) {
            return true;
        }
        return false;
    }

    public removePlacement(index: number) {
        this.placementElementsFrmArr.removeAt(index);
        this.placementElementsFrmArr.markAsDirty();
    }

    public clearHolderSelection(placement: any) {
        placement.get("FixtureGroupName").setValue("");
        placement.get("HolderName").setValue("");
        placement.get("HolderId").setValue(null);
        this.placementElementsFrmArr.markAsDirty();
    }

    public selectElementHolder(placement: any) {
        let param = {
            Placements: [],
            SingleSelect: true
        };

        return this.ipsModal.displayTemplateScrollable(PlacementSearchModalComponent,
            {
                resolve: param
            }, { backdrop: "static", centered: true })
            .then((response: PlacementModalSearchResultModel) => {
                let selectedHolder = response.FixtureGroups[0].Holders[0];
                placement.get("FixtureGroupName").setValue(response.FixtureGroups[0].Name);
                placement.get("HolderName").setValue(selectedHolder.Name);
                placement.get("HolderId").setValue(selectedHolder.Id);

                this.clearSpaceSelection(placement);
                placement.get("SignPlanMessage").setValue("");
                placement.get("SignPlanWidth").setValue(null);

                this.placementElementsFrmArr.markAsDirty();
            }, (rejectReason) => {
                // Do nothing, this is here to prevent console error.
            });
    }

    public getSpaceButtonLabel(placement: any) {
        if (placement && placement.value) {
            if (placement.value.Spaces && placement.value.Spaces.length > 1) {
                return placement.value.Spaces.length + " Spaces Selected";
            } else {
                if (placement.value.Spaces && placement.value.Spaces.length === 1) {
                    return placement.value.Spaces[0].Name;
                } else {
                    return "Choose Space(s)";
                }
            }
        } else {
            return "Choose Space(s)";
        }
    }

    public clearSpaceSelection(placement: FormGroup) {
        placement.setControl("Spaces", this.fb.array([]));
        placement.get("SpaceId").setValue(null);
        placement.markAsDirty();
    }

    public chooseSpace(placement: FormGroup) {
        let selectedSpaces = placement.value.Spaces && placement.value.Spaces.map(space => space.Id);

        let param = {
            title: "Choose Space(s)",
            items: this.spaces.filter(space => space.Id > 0),
            labelField: "Name",
            multiSelect: true,
            selectedIds: selectedSpaces
        };

        return this.ipsModal.displayTemplateScrollable(SelectListComponent, param, { backdrop: "static", centered: true }).then((response: any) => {
            let spaceGroups = response.map((space) => {
                return this.fb.group(space);
            });
            placement.setControl("Spaces", this.fb.array(spaceGroups));
            placement.markAsDirty();
        }, (rejectReason) => {
            // Do nothing, this is here to prevent console error.
        });
    }

    public showLocationBalanceModal(toolTip: NgbTooltip, placementElementCtrl: FormGroup, messageTempId: number, locationCount: number, locSubGroupId?: number) {
        toolTip.close();
        //Do nothing if locationCount is undefined
        if (locationCount === undefined) {
            return;
        }

        //Modal should be shown even if locationCount = 0
        if (locationCount === 0) {
            this.ipsModal.displayTemplateScrollable(SearchModalComponent,
                {
                    resolve: { search: "listbylocation", listOfLocationIds: <number[]>[] }
                },
                {
                    windowClass: "no-list-group-item-interaction"
                });
        } else {
            let placementElement = placementElementCtrl.value as PromotionMessagePlacementElementEditModel;
            let balanceCalcModel = this.deriveMessagePlacementCalculationModel(placementElement);

            //Remove un-needed spaces
            if (balanceCalcModel.PlacementElements) {
                balanceCalcModel.PlacementElements = Object.assign([], balanceCalcModel.PlacementElements);
                balanceCalcModel.PlacementElements.forEach(item => {
                    // delete item.Spaces;
                });
            }

            let locationListRequest: ElementLocationBalanceListRequestModel = {
                MessagePlacementCalculationDetail: balanceCalcModel,
                TargetMessageTempId: messageTempId,
                ElementDetailId: placementElement.ElementDetailId,
                LocationSubGroupId: locSubGroupId
            };

            this.promotionMessageService.calculateElementLocationBalanceList(locationListRequest).then(
                locationIdList => {
                    this.ipsModal.displayTemplateScrollable(SearchModalComponent,
                        {
                            resolve: { search: "listbylocation", listOfLocationIds: locationIdList.map(Number) },
                        },
                        {
                            windowClass: "no-list-group-item-interaction"
                        });
                });
        }
    }

    public onMessageNameChanged(messageInfo: MessageNameChangeModel) {
        this.placementElementsFrmArr.controls.forEach((elementHeader) => {
            let placements = this.getMsgPlacementElementsCtrl(elementHeader as FormGroup);
            placements.controls.some((placementControl, placeIndex) => {
                let placementElement = placementControl.value as PromotionMessagePlacementElementEditModel;
                if (placementElement.TempMessageId === messageInfo.TempMessageId) {
                    placementControl.get("MessageName").patchValue(messageInfo.MessageName);
                    // Should only be one placement per message, break out.
                    return true;
                }
                // tell the 'some' loop to keep going.
                return false;
            });
        });
    }


    public prepareSaveModel(promoSave: any) {
        let elements = this.placementElementsFrmArr.value;
        elements.forEach((element) => {
            if (element.MessageElementPlacements) {
                element.MessageElementPlacements.forEach(messageElementPlacement => {
                    if (messageElementPlacement && messageElementPlacement.Spaces) {
                        messageElementPlacement.SpaceList = messageElementPlacement.Spaces.map(space => space.Id);
                    }
                });
            }
        });

        promoSave.PatternGroups.forEach((item) => {
            let tempId = item.MessagePatterns[0].TempMessageId;

            let elementsByMessage = elements.filter((element) => element.MessageElementPlacements.some((placement) => placement.TempMessageId === tempId)).map(element => {
                let newElt = Object.assign({}, element);
                // newElt.MessageElementPlacements.forEach(ep => ep.Spaces = undefined);
                return newElt.MessageElementPlacements.find((placement) => placement.TempMessageId === tempId);
            });


            item.MessagePatterns[0].PlacementElements = elementsByMessage;

        });
    }

    /**
     * Removes all existing placement elements
     */
    public clearPlacements() {
        let elementHeaders = this.placementElementsFrmArr;
        if (elementHeaders && elementHeaders.length > 0) {
            while (elementHeaders.length > 0) {
                elementHeaders.removeAt(0);
            }
        }
    }

    public saveCustomData(saveModel: PromotionSaveModel): Promise<any>[] {
        let promises: Promise<any>[] = [];
        this.customDataFieldContainers.forEach(container => {
            const plcElement = container.parent.value as PromotionMessagePlacementElementModel;
            for (let i = 0; i < saveModel.PatternGroups.length; i++) {
                let patternGroup = saveModel.PatternGroups[i];
                const match = patternGroup.MessagePatterns.find(mp => mp.MessageName === plcElement.MessageName) as PromotionMessagePatternSaveModel;
                if (match) {
                    const matchPlcElement = match.PlacementElements.find(pe => pe.ElementDetailName === plcElement.ElementDetailName && pe.ElementDetailHeight === plcElement.ElementDetailHeight && pe.ElementDetailWidth === plcElement.ElementDetailWidth);
                    if (matchPlcElement.Id) {
                        container.linkId = matchPlcElement.Id;
                        promises.push(container.save(matchPlcElement.Id));
                        break;
                    }
                }
            }
        });

        return promises;
    }

}
