import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { NgForm } from "@angular/forms";
import { TemplateFormBase } from "../../../../shared/templateFormBase";
import { StoreFrontCategoryService } from "../../../service/store-front-category.service";
import { StoreFrontCategory } from "../../../index";


@Component({
  selector: "app-store-front-category-edit",
  templateUrl: "./store-front-category-edit.component.html",
  styleUrls: ["./store-front-category-edit.component.scss"]
})
export class StoreFrontCategoryEditComponent extends TemplateFormBase implements OnInit {
  @ViewChild("storeFrontCategoryForm") public storeFrontCategoryForm: NgForm;
  public loaded: boolean;
  public promise: Promise<any>;

  public deleteBodyPrompt: string;
  public accessStoreFrontCategoryInfo: string;
  public breadCrumbLabel: string;
  public storeFrontCategory: StoreFrontCategory;

  @Input() stateParams;

  constructor(private storeFrontCategoryService: StoreFrontCategoryService, private ipsMessage: IpsMessageService, private $state: StateService, private trans: Transition, private translateService: TranslateService, private pageTitleService: PageTitleService) {
    super();
  }

  ngOnInit() {
    let storeFrontCategoryId = Number(this.trans.params().id);
    let pageTitle = storeFrontCategoryId > 0 ? "EDIT_STORE_FRONT_CATEGORY" : "CREATE_STORE_FRONT_CATEGORY";

    this.breadCrumbLabel = pageTitle;
    this.pageTitleService.setTitle([pageTitle]);

    // Clear out current location, fill in defaults
    this.storeFrontCategory = { Id: storeFrontCategoryId } as StoreFrontCategory;

    // If we got an ID to load, load it.
    // If paramId is not a number it will evaluate to false
    if (storeFrontCategoryId > 0) {
      //Initial call to populate screen on load
      this.getStoreFrontCategory(storeFrontCategoryId);
    } else {
      this.loaded = true;
    }

    this.translateText();
    this.translateService.onLangChange.subscribe(() => this.translateText());
    super.setFormPristine(this.storeFrontCategoryForm);
  }

  private translateText() {
    this.translateService.get(["ACCESSING_STORE_FRONT_CATEGORY_INFO", "PROMPT_DELETE_BODY"]).subscribe((res: [string]) => {
      this.accessStoreFrontCategoryInfo = res["ACCESSING_STORE_FRONT_CATEGORY_INFO"];
      this.deleteBodyPrompt = res["PROMPT_DELETE_BODY"];
    });
  }

  //Private Functions
  private deleteStoreFrontCategory(): Promise<void> {
    let id = this.storeFrontCategory.Id;
    return this.storeFrontCategoryService.delete(id);
  }

  private getStoreFrontCategory(id: number) {
    this.loaded = false;

    this.promise = this.storeFrontCategoryService.get(id).then((response) => {
      Object.assign(this.storeFrontCategory, response);
      this.loaded = true;
    });
  }

  private saveStoreFrontCategory(): Promise<any> {
    let id = String(this.storeFrontCategory.Id);
    if (id) {
      return this.storeFrontCategoryService.put<StoreFrontCategory>(this.storeFrontCategory).then((response: StoreFrontCategory) => {
        this.storeFrontCategoryForm.form.markAsPristine();
      });
    } else {
      return this.storeFrontCategoryService.post<StoreFrontCategory>(this.storeFrontCategory).then((response: StoreFrontCategory) => {
        this.storeFrontCategoryForm.form.markAsPristine();
      });
    }
  }

  // Public Functions used by view
  public deleteStoreFrontCategoryPrompt() {
    let translated: string = this.deleteBodyPrompt;
    translated = IpsString.Format(translated, this.storeFrontCategory.Name) as string;
    // "DELETE_STORE_FRONT_CATEGORY_CONFIRMATION"
    return this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deleteStoreFrontCategory(), progressMessage: "DELETING" })
      .then((result) => {
        if (result) {
          this.$state.go("main.storeFrontCategory.search");
        }
      })
      .catch(() => {
        // rejection
      });
  }

  public saveStoreFrontCategoryPrompt() {
    return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveStoreFrontCategory(), progressMessage: "SAVING" }).then((result) => {
      if (result) {
        this.$state.go("main.storeFrontCategory.search");
      }
    });
  }

}
