import { Component, OnInit } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

import { ElementModel, ElementDetailModel, ElementService } from "../../";

import { SearchInfo, SearchResultModel, ListSearchHelper, ActiveProfileService, FileDownloadService, QueryParamItem } from "imagine-ui-ng-core";


interface ElementSearchResult extends ElementModel, SearchResultModel {
}

@Component({
    selector: "app-element-search",
    templateUrl: "./element-search.component.html",
    styleUrls: ["./element-search.component.scss"]
})
export class ElementSearchComponent implements OnInit {
    private element: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<any>;
    public query: string;
    public searchText: string;
    public searchLabel: string;
    public searchLabelPlural: string;
    public translated = false;
    public includeObsolete = false;

    constructor(private elementService: ElementService, private listSearchHelper: ListSearchHelper, private translateService: TranslateService,
        private downloadService: FileDownloadService, private activeProfileService: ActiveProfileService) {
        this.element = listSearchHelper.getListSearchHelper(elementService);
        this.busy = this.element.busy;
    }

    public getListData(search: any) {
        let searchTerm = "";

        let additionalQueryParams: QueryParamItem[] = [];

        if (search !== undefined && search.search !== undefined) {
            searchTerm = search.search;
        }

        this.searchText = search.search;
        if (this.includeObsolete) {
            additionalQueryParams.push({ param: "ShowObsoleteItems", paramValue: this.includeObsolete.toString() });
        }

        let searchInfo = <SearchInfo>{};
        searchInfo.searchText = searchTerm;
        searchInfo.additionalQueryParams = additionalQueryParams;

        this.promise = this.element.searchHelper(searchInfo, undefined, undefined).then(() => {
            this.element.resultList.forEach((result: ElementSearchResult) => {
                if (result.Label !== result.Name) {
                    result.Label = result.Name;
                    result.ChildList = result.ElementDetails.map((detail: ElementDetailModel) => {
                        return `${detail.Width} x ${detail.Height}`;
                    });
                }
            });
        });
    }

    ngOnInit() {
        this.elementService.head().then((data: number) => {
            this.element.TotalRecords = data;
        });

        this.getListData({ search: "" });

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    private translateText() {
        this.translateService.get(["ELEMENT", "ELEMENTS"]).subscribe((res: [string]) => {
            this.searchLabel = res["ELEMENT"];
            this.searchLabelPlural = res["ELEMENTS"];
            this.translated = true;
        });
    }

    public reportDownloadClickHandler() {
        const url = `${this.elementService.urlApiBaseProductPlusVersion}Element/Download?SearchText=&ChunkIndex=0&RecordCount=2147483647&BusinessIdentity=${this.activeProfileService.businessIdentity}`;
        this.downloadService.Download(url);
    }

    public getObsoleteToggleData(isObsolete: boolean) {
        this.includeObsolete = isObsolete;
        this.getListData({search: this.searchText});
    }
}

