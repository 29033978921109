import { Component, OnInit, Input } from "@angular/core";


import cloneDeep from "lodash-es/cloneDeep";
import { StateService, Transition } from "@uirouter/angular";

import { ElementService, ElementModel } from "../../";
import { SearchInfo } from "imagine-ui-ng-core";

@Component({
    selector: "app-element-view",
    templateUrl: "./element-view.component.html",
    styleUrls: ["./element-view.component.scss"]
})
export class ElementViewComponent implements OnInit {
    @Input() element: ElementModel;
    private dataService: ElementService;

    public busy: boolean;
    public myElement: ElementModel;
    public loaded: boolean;
    public promise: Promise<void>;

    constructor(private $transitionService: Transition, elementService: ElementService) {
        this.busy = false;
        this.loaded = false;

        this.dataService = elementService;

        let paramId = this.$transitionService.params().id;
        this.myElement = { Id: Number(paramId) } as ElementModel;
    }

    private getElement(id: string) {
        this.loaded = false;

        this.promise = this.dataService.get<ElementModel>(id).then((response) => {
            this.myElement = cloneDeep(response);
            this.loaded = true;
        });
    }

    public hasElementDetails(): boolean {
        let returnValue = false;

        if (this.myElement !== undefined && this.myElement.ElementDetails !== undefined) {
            returnValue = this.myElement.ElementDetails.length > 0;
        }

        return returnValue;
    }

    ngOnInit() {
        // If we got an ID to load, load it.
        if (this.myElement.Id > 0) {
            //Initial call to populate screen on load
            this.getElement(this.myElement.Id.toString());
        }
    }
}
