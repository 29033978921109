<div class="card ips-shadow mt-4">
    <div class="card-body">
        <div class="ips-search-result-big d-flex justify-content-between">
            <div class="ips-gray-color">
                <label>{{section.Name}}</label>
            </div>
            <div class="d-flex justify-content-end">
                <a uiSref="main.survey.edit" [uiParams]="{id: section.SurveyId, openSections:section.Ordinal}"  ipsPermission="main.survey.edit" class="no-underline">
                    <span class="fa fa-pencil mr-3"></span>
                </a>
                <a id="collapse-button" (click)="activeCollapse()">
                    <span class="ips-gray-color" [ngClass]="['text-right fa fa-chevron-down ips-ls-addIcon', isCollapsed ? '' : 'fa-flip-vertical']"></span>
                </a>
            </div>
        </div>
        <!--Content when NOT collapsed-->
        <div class="row" [ngbCollapse]="isCollapsed">
            <div class="col">
                <div *ngIf="questionsLoading && !isCollapsed" class="mt-2">
                    <div><i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i> <span translate>LOADING</span></div>
                </div>
                <div *ngIf="section.SurveyQuestions">
                    <div *ngFor="let question of section.SurveyQuestions">
                        <hr />
                        <div class="view-info-label" translate>QUESTION</div>
                        <div class="view-info-format-imgn">{{question.Name}}</div>

                        <div class="view-info-label mt-2" translate>QUESTION_INSTRUCTIONS</div>
                        <div class="view-info-format-imgn">{{question.Instruction}}</div>

                        <div class="view-info-label mt-2" translate>LINKED_PROFILE</div>
                        <div class="view-info-format-imgn">{{question.LinkedProfile}}</div>
                        <div class="ips-list-image mb-3" *ngIf="question.FullImage">
                            <ips-image-view [imgThumbUrl]="question.FullImage" [imgFullUrl]="question.FullImage" hideLabel="true" noImageText="NO_IMAGE"></ips-image-view>
                        </div>

                        <div class="view-info-label mt-2" translate>ANSWER_TYPE</div>
                        <div class="view-info-format-imgn" [translate]="question.AnswerTypeText"></div>
                        <div class="view-info-label mt-2" translate>OPTION_CONTROL</div>
                        <div *ngIf="question.OptionControl" class="view-info-format-imgn" translate>CAN_ADD_OPTIONS</div>
                        <div *ngIf="!question.OptionControl" class="view-info-format-imgn" translate>CANNOT_ADD_OPTIONS</div>
                        <div class="view-info-label mt-2" translate>MESSAGE_CONTROL</div>
                        <div class="view-info-format-imgn" translate>{{question.MessageControlText}}</div>

                        <div class="card mt-3 mb-3" *ngIf="question.Message">
                            <div class="card-header view-info-header d-flex flex-row">
                                <div class="pr-3" translate>MESSAGES</div>
                            </div>
                            <div class="card-body">
                                <div class="d-flex flex-row mt-3">
                                    <div class="pr-2 text-center survey-view-locations"><label class="view-info-label" translate>LOCATIONS</label></div>
                                    <div class="px-2 survey-view-percent d-none d-sm-flex"></div>
                                    <div class="px-2"></div>
                                </div>
                                <div class="d-flex flex-row align-items-baseline mb-3">
                                    <div class="d-flex flex-column flex-sm-row align-items-center">
                                        <div class="pr-2 text-center">
                                            <button class="btn badge-pill ips-pill" (click)="showMessageLocations(question)">{{question.Message.LocationCount | number}}</button>
                                        </div>
                                        <div class="px-2 d-flex mt-2 mt-sm-0 justify-content-center survey-view-percent">{{question.Message.Percent}} %</div>
                                    </div>
                                    <div class="px-2 d-flex align-items-center" translate>SURVEY_MESSAGES</div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-3">
                            <div class="card-header view-info-header d-flex flex-row">
                                <div class="pr-3" translate>RESPONSE_METRICS</div>
                            </div>
                            <div class="card-body">
                                <div class="d-flex flex-row mt-3">
                                    <div class="pr-2 text-center survey-view-locations"><label class="view-info-label" translate>LOCATIONS</label></div>
                                    <div class="px-2 survey-view-percent d-none d-sm-flex"></div>
                                    <div class="px-2"></div>
                                </div>
                                <div *ngFor="let answer of question.Answers" class="d-flex flex-row align-items-baseline mb-3">
                                    <div class="d-flex flex-column flex-sm-row align-items-center">
                                        <div class="pr-2 text-center">
                                            <button class="btn badge-pill ips-pill" [ngClass]="{'ips-pill-pending': answer.LocationSubGroupId === 0 || answer.AnswerResultType === 1}" (click)="showLocations(question, answer)">{{answer.LocationCount | number}}</button>
                                        </div>
                                        <div class="px-2 d-flex mt-2 mt-sm-0 justify-content-center survey-view-percent">{{answer.Percent}} %</div>
                                    </div>
                                    <div class="px-2 d-flex align-items-center" [ngClass]="{'location-group-pending-assignment-label': answer.LocationSubGroupId === 0 || answer.AnswerResultType === 1}">{{answer.Name}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

