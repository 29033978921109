import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from "@angular/core";

import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

import {
    PromotionMessagePatternGroupModel, PromotionMessageService, MessageNameChangeModel,
    PromotionSaveModel,
    PromotionMessagePatternGroupSaveModel
} from "../../index";

import { MarketModel } from "../../../market/index";

import { PromotionHelperService } from "../../service/promotionHelper.service";

import { of as observableOf } from "rxjs";
import { takeWhile } from "rxjs/operators";

import { CustomDataFieldContainerComponent } from "../../../shared/custom-data-field/custom-data-field-container/custom-data-field-container.component";
import { PromotionMessagePatternModel } from "../../model/PromotionMessagePatternModel";

@Component({
    selector: "app-message-priority-fill-edit",
    templateUrl: "./message-priority-fill-edit.component.html",
    styleUrls: ["./message-priority-fill-edit.component.scss"]
})
export class MessagePriorityFillEditComponent implements OnInit {
    @ViewChildren(CustomDataFieldContainerComponent) customDataFieldContainers: QueryList<CustomDataFieldContainerComponent>;

    @Input() patternGroupsModel: PromotionMessagePatternGroupModel[];
    @Input() parent: FormGroup;
    @Input() tempMessageId: number;
    @Input() isClone = false;

    @Output() messageDeleted = new EventEmitter<any>();
    @Output() messageAdded = new EventEmitter<any>();
    @Output() messageOrdinalChanged = new EventEmitter<any>();
    @Output() marketChanged = new EventEmitter<any>();
    @Output() messageNameChanged = new EventEmitter<MessageNameChangeModel>();
    @Output() cdfsLoaded = new EventEmitter<void>();

    public promoId: number;
    public messagePatternTemplate: string;
    public sortableOptions;
    public markets: MarketModel[];
    public reorderMessages = false;

    private TranslateStrings: { [key: string]: string } = {
        "PLEASE_ENTER_VALID_MARKET": "",
        "MARKET_PENDING_LOCATIONS_WARNING": "",
        "EMPTY_POSITION": "",
        "MESSAGE_NAME_UNIQUE_TO_CAMPAIGN": "",
        "MESSAGE_PATTERN_NAME_NOT_UNIQUE": "",
        "PLEASE_ENTER_MESSAGE": "",
        "PLEASE_ENTER_MARKET": "",
        "MAX_LENGTH_ERROR": ""
    };

    private errorMessages = {
        "required": () => this.TranslateStrings["PLEASE_ENTER_MESSAGE"],
        "maxlength": (params) => IpsString.Format(this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength),
        "messageNameUniqueToCampaign": () => this.TranslateStrings["MESSAGE_NAME_UNIQUE_TO_CAMPAIGN"],
        "patternNameExists": () => this.TranslateStrings["MESSAGE_PATTERN_NAME_NOT_UNIQUE"],
        "invalidMarketName": () => this.TranslateStrings["PLEASE_ENTER_VALID_MARKET"]
    };

    public messageNames: string[];

    public focusableMessageInput: string;
    public promoStartDate: Date;
    public promoEndDate: Date;
    private loadedMsgCount = 0;
    private cdfLoadedCount = 0;

    constructor(
        private translateService: TranslateService,
        private ipsMessage: IpsMessageService,
        private promotionHelperService: PromotionHelperService,
        public promotionMessageService: PromotionMessageService) { }

    ngOnInit() {
        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.promotionHelperService.tempMessageId = this.tempMessageId;
        this.promotionHelperService.emptyPositionMessageName = this.TranslateStrings["EMPTY_POSITION"];
        this.promotionHelperService.createForm(this.parent, this.messageNameChanged, true, "priority");
        if (!this.parent.get("ClearAllMessages").value) {
            this.loadedMsgCount = this.promotionHelperService.initializeForm(this.patternGroupsModel, this.isClone);
            //this.markets = this.patternGroupsModel && this.patternGroupsModel.length > 0 ? this.patternGroupsModel[0].Markets : [];
        }
        this.messageNames = this.promotionHelperService.getMessageSuggestions();

        this.getFocusableMessageInput();
    }

    public cdfLoadedHandler() {
        this.cdfLoadedCount++;
        if (this.cdfLoadedCount === this.loadedMsgCount) {
            this.cdfsLoaded.emit();
        }
    }

    public setSortableOptions(patternGroup: FormGroup) {
        //drag and drop
        //update event
        return this.sortableOptions = {
            onEnd: (event) => {
                this.updateOrdinal(patternGroup, event);
            },
            handle: ".grab-cursor"
        };
    }

    private TranslateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    }

    public disableCreateMessagePatternButton(patternGroup: FormGroup): boolean {
        return !this.MessagePatterns(patternGroup).valid;
    }

    public cloneMessage(patternGroup: FormGroup, pattern: FormGroup) {
        return;
    }

    public addNewPriority(patternGroup: FormGroup): void {
        let pattern = this.promotionHelperService.newPriorityFillFormGroup(patternGroup);
        this.MessagePatterns(patternGroup).push(pattern);
        this.parent.markAsDirty();
        //raise event to parent
        this.messageAdded.emit(pattern.value);

        this.getFocusableMessageInput();
    }

    public getOriginalMarkets(patternGroupCtrl: FormGroup, patternGroup: FormGroup, index: number): MarketModel[] {
        let foundItem: PromotionMessagePatternModel;
        const msgPattern = patternGroup.get("MessagePatterns") as FormArray;
        let returnValue = null;
        if (patternGroup.value.MessagePatterns.length > index) {
            foundItem = patternGroup.value.MessagePatterns[index];
            if (foundItem) {
                if (!foundItem.Markets) {
                    returnValue = [];
                }
                returnValue = returnValue == null ? foundItem.Markets : returnValue;
            }
        }
        returnValue = returnValue == null ? [] : returnValue;
        return returnValue;
    }

    public placementsExist(): boolean {
        // If there are any placements, there will be one for each message, no need to check for a placement on the specific message.
        return this.promotionHelperService.PlacementsExist(this.parent);
    }


    public removePatternPrompt(patternGroup: FormGroup, index: number, toolTip: NgbTooltip) {
        if (toolTip) {
            toolTip.close();
        }

        let pattern = patternGroup.value.MessagePatterns[index];
        let patternsWithMessageId = this.getPatternsWithTempMessageId(patternGroup, pattern.TempMessageId);
        // Only prompt the user to delete a pattern this is the last pattern referencing a specific message that has placements, otherwise delete it without prompting.
        // Or to be more explicit, Delete the pattern without prompting the user if any of the following is true:
        //// It is an Empty Postion.
        //// Other patterns still reference the same message.
        //// The message to be deleted doesn't have placements assigned yet.
        if (!pattern.IsEmpty && this.placementsExist() && patternsWithMessageId.length === 1) {
            let translated = this.translateService.instant("PROMPT_DELETE_PROMOTION_MESSAGE");
            this.ipsMessage.confirmDelete({ body: translated })
            .then((response: any) => {
                if (response) {
                    this.removePattern(patternGroup, index, patternsWithMessageId);
                }
            })
            .catch(() => {
                // rejection
            });
        } else {
            this.removePattern(patternGroup, index, patternsWithMessageId);
        }
    }

    public removePattern(patternGroup: FormGroup, index: number, duplicateMsgPatterns: FormControl[]) {
        let msgPatterns = patternGroup.get("MessagePatterns") as FormArray;
        let patternToRemove = msgPatterns.at(index).value;
        let msgName = patternToRemove.MessageName;
        msgPatterns.removeAt(index);

        //if message name not exists any more, remove it from suggested message names
        let isEmptyPostion = msgName === this.TranslateStrings["EMPTY_POSITION"];
        let exists = patternGroup.value.MessagePatterns.filter(pattern => pattern.MessageName === msgName ).length > 0;
        if (!isEmptyPostion && !exists) {
            this.messageNames.filter(name => name !== msgName);
        }

        //update ordinal
        msgPatterns.controls.forEach((pattern, i) => {
            pattern.patchValue({
                Ordinal: i + 1,
            });
        });

        //filter returns an empty array if no matches are found, so broadcast Delete message if this was the only pattern referencing the same message
        if (patternToRemove.TempMessageId !== undefined && duplicateMsgPatterns.length <= 1) {
            this.messageDeleted.emit(patternToRemove);
        } else {
            //raise event to re-order placement and re-calculate holder counts
            this.messageOrdinalChanged.emit();
        }

        //delete markets if
        this.parent.markAsDirty();
    }

    private getPatternsWithTempMessageId(patternGroup: FormGroup, tempMessageId: number): FormControl[] {

        let patternsWithSameMessage = [];
        let msgPatterns = patternGroup.get("MessagePatterns") as FormArray;
        msgPatterns.controls.forEach(pattern => {
            if (pattern.value.TempMessageId === tempMessageId) {
                patternsWithSameMessage.push(pattern);
            }
        });
        return patternsWithSameMessage;
    }

    public marketChangedEventHandler(patternGroupCtrl: FormGroup) {
        const tempMessageIdValue = patternGroupCtrl.get("TempMessageId").value;
        this.marketChanged.emit(tempMessageIdValue);
    }

    //jquery.ui.touch.punch prevents the input/textarea to be selected
    //this method set focus on input when it's clicked
    public setFocus(event: KeyboardEvent): void {
        let element = <HTMLElement>event.target;
        element.focus();
    }

    //drag and drop
    private updateOrdinal(patternGroup: FormGroup, event) {
        let oldIndex = event.oldIndex;
        let newIndex = event.newIndex;
        if (oldIndex !== newIndex) {

            //Update position so messages with cdf attached are shown first
            let messageName = this.MessagePatterns(patternGroup).controls[newIndex].get("MessageName").value;

            for (let i = 0; i < this.MessagePatterns(patternGroup).length; i++) {
                let pattern = this.MessagePatterns(patternGroup).controls[i].value;

                if (pattern.MessageName === messageName) {
                    if (!pattern.IsCdfEnabled) {
                        let index = this.MessagePatterns(patternGroup).controls.findIndex(item => item.get("MessageName").value === messageName && item.get("IsCdfEnabled").value);
                        if (index !== -1) {
                            let temp = this.MessagePatterns(patternGroup).controls[i];
                            this.MessagePatterns(patternGroup).controls[i] = this.MessagePatterns(patternGroup).controls[index];
                            this.MessagePatterns(patternGroup).controls[index] = temp;
                        }
                        break;
                    } else {
                        break;
                    }
                }
            }

            for (let i = 0; i < this.MessagePatterns(patternGroup).length; i++) {
                let pattern = this.MessagePatterns(patternGroup).controls[i];
                pattern.patchValue({
                    Ordinal: i + 1
                });
            }

            //raise event to re-order placement and re-calculate holder counts
            this.messageOrdinalChanged.emit();
            this.parent.markAsDirty();
        }

    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "market":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_MARKET"];
                    break;
                default:
            }
        }

        return msgs;
    }


    //auto focus
    private getFocusableMessageInput() {
        this.focusableMessageInput = this.promotionHelperService.getFocusableMessageInput();
    }

    public prepareSaveModel(promotionSaveModel: PromotionSaveModel): any {
        let marketModel: MarketModel[];
        marketModel = [];
        let patternGrp: PromotionMessagePatternGroupSaveModel[];
        patternGrp = [];
        if (this.PatternGroups.value[0]) {
            this.PatternGroups.value[0].MessagePatterns.forEach(mp => {
                let patternData: any = {
                    BusinessIdentity: this.PatternGroups.value[0].BusinessIdentity,
                    Markets: mp.Markets,
                    Id: mp.Id,
                    PromotionId: this.PatternGroups.value[0].PromotionId,
                    PatternGroupType: "PriorityMessage",
                    MessagePatterns: [],
                    MarketGroupId: mp.MarketGroupId,
                    Name: mp.MessageName,
                    PlacementGroups: null
                };
                patternData.MessagePatterns.push(mp);
                patternGrp.push(patternData);
            });
        }
        promotionSaveModel.PatternGroups = patternGrp;
        promotionSaveModel.PatternGroups.forEach(patternGroup => {
            patternGroup.MessagePatterns.forEach((msg) => {
                if (msg.PromotionMessageId === null) {
                    // Clear out the name for empty positions, it must be empty for the backend to work
                    msg.MessageName = "";
                }
            });

            //update ordinal by index
            patternGroup.Markets.forEach((market, index) => market.Ordinal = index + 1);

        });
    }

    get PatternGroups(): FormArray {
        return this.parent.controls.messageForm.get("PatternGroups") as FormArray;
    }

    public MessagePatterns(patternGroup: FormGroup): FormArray {
        return patternGroup.get("MessagePatterns") as FormArray;
    }

    public saveCustomData(saveModel: PromotionSaveModel): Promise<any>[] {
        this.promotionHelperService.patchMessageIdsAfterSave(saveModel);
        let promises: Promise<any>[] = [];
        this.customDataFieldContainers.forEach(container => {
            const msgId = (container.parent.value as PromotionMessagePatternModel).PromotionMessageId;
            promises.push(container.save(msgId));
        });

        return promises;
    }

    public toggleReorderMessages(reorderMessages: boolean) {
        this.reorderMessages = reorderMessages;
    }
}
