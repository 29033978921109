import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchHelper, SearchInfo, SelectOption } from "imagine-ui-ng-core";

import { LocationService } from "../../imagine-ui-ng-store-profile/index";
import { LocationAttributeSearchModel } from "../model/LocationAttributeSearchModel";
import { MarketType } from "../type/MarketType";
import { MarketSearchModalReturnModel } from "../model/MarketSearchModalReturnModel";
import { MarketModel } from "../model/MarketModel";

import { Subject } from "rxjs";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";

@Component({
  selector: "app-location-attribute-search-modal",
  templateUrl: "./location-attribute-search-modal.component.html",
  styleUrls: ["./location-attribute-search-modal.component.scss"]
})
export class LocationAttributeSearchModalComponent implements OnInit {

    public locations: ListSearchHelper;
    public locationPromise: Promise<any>;
    public locationQuery = "";
    public querySubject: Subject<string> = new Subject<string>();
    public isModal = true;
    public listHeader: string;
    public locationAttributes: SelectOption[] = [];
    public selectedLocationAttribute: string;
    public showInUseFeatures = false;
    public featuresInAudience = [] as MarketModel[]; // Actually a list of all Markets, not just features
    public resolve: any; // holds data passed in to modal, specifically showInUseFeatures

    public busy: boolean;
    public focusLocation: boolean;
    public totalCountByAttribute = [];
    public searchHelperByAttribute = [];
    public selectedLocations = [];
    public selectedCount = 0;

    pluralMapping: { [k: string]: string } = {
        "=1": "City",
        "other": "Cities"
    };
    public selectedLocationsPluralMapping: { [k: string]: string } = {
        "=0": "Location Items Selected",
        "=1": "Location Item Selected",
        "other": "Location Items Selected",
    };
    private TranslateStrings: { [key: string]: string } = {
        "ID": "",
        "NAME": "",
        "ITEM": "",
        "ITEMS": "",
        "LOADING": "",
        "CITY": "",
        "CITIES": "",
        "COUNTRY": "",
        "COUNTRIES": "",
        "LOCATION_ID": "",
        "LOCATION": "",
        "LOCATIONS": "",
        "STATE": "",
        "STATES": "",
        "POSTAL_CODE": "",
        "POSTAL_CODES": "",
        "STATE_PROVINCE": ""
    };

    constructor(private modalInstance: NgbActiveModal, private locationService: LocationService,
        private listSearchHelper: ListSearchHelper, private translateService: TranslateService) {
    }

    ngOnInit() {
        if (this.resolve) {
            if (this.resolve.showInUseFeatures) {
                this.showInUseFeatures = this.resolve.showInUseFeatures;
            }
            this.featuresInAudience = this.resolve.featuresInAudience;
        }

        this.querySubject.pipe(
                debounceTime(350),
                distinctUntilChanged())
            .subscribe(model => {
                this.getLocationData(model);
            });

        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.focusLocation = true;
        this.getLocationData("");
    }

    private loadLocationAttributes() {
        this.locationAttributes = [
            { value: "LocationCityState", text: this.TranslateStrings["CITY"] },
            { value: "LocationCountry", text: this.TranslateStrings["COUNTRY"] },
            { value: "LocationId", text: this.TranslateStrings["LOCATION_ID"] },
            { value: "LocationPostalCode", text: this.TranslateStrings["POSTAL_CODE"] },
            { value: "LocationState", text: this.TranslateStrings["STATE"] }
        ];
        this.selectedLocationAttribute = this.locationAttributes[0].value;
    }

    private TranslateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }

        this.pluralMapping = {
            "=1": this.TranslateStrings["CITY"],
            "other": this.TranslateStrings["CITIES"]
        };

        this.listHeader = `${this.TranslateStrings["CITY"]}, ${this.TranslateStrings["STATE_PROVINCE"]}`;
        this.loadLocationAttributes();
    }

    public getLocationData(search: string, loadMore: boolean = false) {
        this.locationQuery = search;
        this.selectedCount = 0;
        let route = `Location/SearchByAttribute/${this.selectedLocationAttribute}`;
        this.locations = this.getSearchHelper(this.selectedLocationAttribute, loadMore);
        this.locationPromise = this.locations.searchHelper({ searchText: search || "" }, undefined, route).then(() => {
            this.locations.resultList.forEach((item: any) => {
                let model = item as LocationAttributeSearchModel;
                switch (this.selectedLocationAttribute) {
                    case "LocationCityState":
                        item.Label = `${model.City}, ${model.StateProvince}`;
                        break;
                    case "LocationCountry":
                        item.Label = `${model.Country} (${model.CountryFullName})`;
                        break;
                    case "LocationId":
                        item.Label = `${model.LocationIdentifier} - ${model.Name} - ${model.City} - ${model.StateProvince}`;
                        break;
                    case "LocationPostalCode":
                        item.Label = model.PostalCode;
                        break;
                    case "LocationState":
                        item.Label = `${model.StateProvince} (${model.StateFullName})`;
                        break;
                }

                if (this.showInUseFeatures) {
                    this.checkLocationAvailability(item);
                }

                if (this.selectedLocations.length > 0) {
                        this.selectedLocations.forEach(location => {
                            if (location.Label === item.Label) {
                                item.checked = true;
                                this.selectedCount++;
                            }
                        });
                }

            });
            this.setSelectedLocationCount();
        });
    }
    public getMoreListData() {
        this.getLocationData(this.locationQuery, true);
    }

    public changeLocationAttribute() {

        switch (this.selectedLocationAttribute) {
            case "LocationCityState":
                this.pluralMapping = {
                    "=1": this.TranslateStrings["CITY"],
                    "other": this.TranslateStrings["CITIES"]
                };
                this.listHeader = `${this.TranslateStrings["CITY"]}, ${this.TranslateStrings["STATE_PROVINCE"]}`;
                break;
            case "LocationCountry":
                this.pluralMapping = {
                    "=1": this.TranslateStrings["COUNTRY"],
                    "other": this.TranslateStrings["COUNTRIES"]
                };
                this.listHeader = this.TranslateStrings["COUNTRY"];
                break;
            case "LocationId":
                this.pluralMapping = {
                    "=1": this.TranslateStrings["LOCATION"],
                    "other": this.TranslateStrings["LOCATIONS"]
                };
                this.listHeader = `${this.TranslateStrings["ID"]} - ${this.TranslateStrings["NAME"]} - ${this.TranslateStrings["CITY"]}, ${this.TranslateStrings["STATE_PROVINCE"]}`;
                break;
            case "LocationPostalCode":
                this.pluralMapping = {
                    "=1": this.TranslateStrings["POSTAL_CODE"],
                    "other": this.TranslateStrings["POSTAL_CODES"]
                };
                this.listHeader = this.TranslateStrings["POSTAL_CODE"];
                break;
            case "LocationState":
                this.pluralMapping = {
                    "=1": this.TranslateStrings["STATE"],
                    "other": this.TranslateStrings["STATES"]
                };
                this.listHeader = this.TranslateStrings["STATE"];
                break;
        default:
            break;
        }
        this.locationQuery = "";
        this.getLocationData(this.locationQuery);
    }

    public close(closeMessage: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    public locationselected(location: any, isChecked) {
        if (isChecked) {
            ++this.selectedCount;
            location.SelectedLocationAttribute = this.selectedLocationAttribute;
            this.selectedLocations.push(location);
        }else {
            --this.selectedCount;
                if (location.Disabled) {
                    this.selectedLocations.push(location);

                } else {
                    this.selectedLocations.splice(location, 1);
                }
            }
        }

    public locationsSelected() {
        if (this.selectedLocations.length > 0) {
            let ret = new MarketSearchModalReturnModel(this.selectedLocationAttribute as MarketType, this.selectedLocations);
            this.selectedLocations = [];
            return this.modalInstance.close(ret);
        }else {
            this.modalInstance.close();
        }
    }

    public onQueryChange(query: string) {
        this.locationQuery = query;
        this.querySubject.next(query);
    }

    public getTotalCount(selectedLocationAttribute: string) {
        let totalCount = this.totalCountByAttribute[selectedLocationAttribute];
        if (totalCount === undefined) {
            return this.locationService.headByAttribute(selectedLocationAttribute).then(total => {
                totalCount = total;
                this.totalCountByAttribute[selectedLocationAttribute] = total;
                return totalCount;
            });
        } else {
            return Promise.resolve(totalCount);
        }
    }

    public getSearchHelper(selectedLocationAttribute: string, loadMore: boolean = false) {
        let searchHelper = this.searchHelperByAttribute[selectedLocationAttribute];
        if (searchHelper === undefined) {
            searchHelper = this.listSearchHelper.getListSearchHelper(this.locationService);
            this.searchHelperByAttribute[selectedLocationAttribute] = searchHelper;

            this.getTotalCount(selectedLocationAttribute).then(result => {
                searchHelper.TotalRecords = result;
            });
        } else if (!loadMore) {
            let newSearchHelper = this.listSearchHelper.getListSearchHelper(this.locationService);
            newSearchHelper.TotalRecords = searchHelper.TotalRecords;
            this.searchHelperByAttribute[selectedLocationAttribute] = newSearchHelper;
            return newSearchHelper;
        }

        return searchHelper;
    }

    public setSelectedLocationCount() {
        if (this.featuresInAudience.length > 0) {
            for (let i = 0; i < this.featuresInAudience.length; i++) {
                let market = this.featuresInAudience[i];
            switch (market.TargetMarketType) {
                    case "Location":
                    case "LocationCityState":
                    case "LocationState":
                    case "LocationId":
                    case "LocationCountry":
                    case "LocationPostalCode":
                            ++this.selectedCount;
                            break;
            }
        }
        }

        if (this.selectedLocations.length > 0) {
            for (let i = 0; i < this.selectedLocations.length; i++) {
                 let market = this.selectedLocations[i];
                 if (market.Disabled) {
                    this.selectedCount--;
                 }else {
                    this.selectedCount++;
                 }
            }
        }
    }

    private checkLocationAvailability(location: any) {
        if (this.featuresInAudience.length > 0) {
            for (let i = 0; i < this.featuresInAudience.length; i++) {
                let market = this.featuresInAudience[i];
            if (this.selectedLocationAttribute === "LocationId" &&
                market.TargetMarketId === location.Id) {
                location.Disabled = true;
                location.checked = true;
            }

            if (this.selectedLocationAttribute === "LocationCityState" &&
                location.Label.replace(" ", "") === market.TargetAttributeValue) {
                    location.Disabled = true;
                    location.checked = true;
            }

            if (this.selectedLocationAttribute === "LocationCountry" &&
                location.Country === market.TargetAttributeValue) {
                    location.Disabled = true;
                    location.checked = true;
            }
            if (this.selectedLocationAttribute === "LocationPostalCode" &&
                location.PostalCode === market.TargetAttributeValue) {
                    location.Disabled = true;
                    location.checked = true;
            }

            if (this.selectedLocationAttribute === "LocationState") {
                let state = location.StateProvince + "," + location.Country;
                if (state === market.TargetAttributeValue) {
                    location.Disabled = true;
                    location.checked = true;
                }
            }

            if (location.Disabled) {
                location.Index = i;
                break;
            }else {
                location.Disabled = false;
            }
            }
        }
    }

}
