import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService} from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { AreaType } from "../../shared";

@Injectable()
export class AreaService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Area", "Tools", "v1");
    }

    public getCustomDataGroup(areaId: AreaType): Promise<any[]> {
        let route = `${this.urlApiBaseProductPlusVersion}${areaId}/CustomDataGroup`;
        return this.getByRoute(route);
    }
}
