import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { LocationFixtureModel } from "../../imagine-ui-ng-store-profile";
import { environment } from "../../../environments/environment";

@Injectable()
export class FixtureByLocationService extends IpsBaseWebService {
    private route: string;
    public LocationId: string;

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Fixture", "Profile", "v1");
        this.route = `${this.urlApiBaseProductPlusVersion}Location/{id}/Fixture`;
    }

    getByLocation(id: string): Promise<LocationFixtureModel[]> {
        this.LocationId = id;
        return this.getByRoute(this.route.replace("{id}", id));
    }
    headByLocation(id: string): Promise<number> {
        return this.headByRoute(this.route.replace("{id}", id));
    }
    // Override the ipsBaseWebService.search method.
    search<T>(searchInfo: SearchInfo, route: string): Promise<T> {
        return this.searchByRelation(searchInfo, "Location/" + searchInfo.id + "/Fixture/Search");
    }

}
