<div class="form-group" >
    <div [ngSwitch]="inputType">
        <div *ngSwitchCase="'Text'" class="input-group">
            <app-ips-text class="w-100" [name]="customDataResult.Name" [isFieldRequired]="customDataResult.IsFieldRequired"
                          [selectionRule]="customDataResult.SelectionRule" [value]="customDataResult.Values[0]"
                          [maxLength]="customDataResult.SelectionRule === 'Multiple' ? 500 : 50"
                          [fieldValues]="fieldValues" [customDataId]="customDataResult.Id"
                          (valueChanged)="valueChangedHandler($event)"></app-ips-text>
        </div>
        <div *ngSwitchCase="'Date'" class="input-group">
            <app-ips-date class="w-100" [name]="customDataResult.Name" [isFieldRequired]="customDataResult.IsFieldRequired"
                          [value]="customDataResult.Values[0]" [fieldValues]="fieldValues"
                          [customDataId]="customDataResult.Id"
                          (valueChanged)="valueChangedHandler($event)"></app-ips-date>
        </div>
        <div *ngSwitchCase="'List'">
            <app-ips-list class="w-100" [name]="customDataResult.Name" [isFieldRequired]="customDataResult.IsFieldRequired"
                          [selectionRule]="customDataResult.SelectionRule" [values]="customDataResult.Values"
                          [fieldValues]="fieldValues" [lookupValues]="customDataResult.CustomDataOptionList"
                          [customDataId]="customDataResult.Id" (listChanged)="updateList($event)"></app-ips-list>
        </div>
        <div *ngSwitchCase="'Number'" class="input-group">
            <app-ips-number class="w-100" [name]="customDataResult.Name" [isFieldRequired]="customDataResult.IsFieldRequired"
                            [value]="customDataResult.Values[0]" [fieldValues]="fieldValues"
                            [customDataId]="customDataResult.Id"
                            (valueChanged)="valueChangedHandler($event)"></app-ips-number>
        </div>
        <div *ngSwitchCase="'Email'" class="input-group">
            <app-ips-email class="w-100" [name]="customDataResult.Name" [isFieldRequired]="customDataResult.IsFieldRequired"
                            [value]="customDataResult.Values[0]" [fieldValues]="fieldValues"
                            [customDataId]="customDataResult.Id"
                            (valueChanged)="valueChangedHandler($event)"></app-ips-email>
        </div>
    </div>
</div>
