import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { NgForm, FormControl, AbstractControl } from "@angular/forms";
import { ListSearchHelper, SelectOption, ActiveProfileService, SearchInfo, SearchResponse, BatchRequestType, FileDownloadService } from "imagine-ui-ng-core";
import { TranslateService } from "@ngx-translate/core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { IpsModalService } from "imagine-ui-ng-modal";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, takeWhile } from "rxjs/operators";

import { AuthService } from "imagine-ui-ng-security";
import { String as IpsString } from "typescript-string-operations";
import { CampaignService, CampaignData, SimpleSearchCampaignModel } from "../../imagine-ui-ng-campaign/index";
import {
    LocationService, FixtureService, FixtureGroupService,
    LocationGroupService, LocationModel, FixtureModel, FixtureGroupModel,
    LocationGroupModel, SimpleSearchLocationModel, SimpleSearchFixtureModel,
    SimpleSearchLocationGroupModel, SimpleSearchFixtureGroupModel
} from "../index";
import { SearchModalComponent } from "../../shared/search-modal/search-modal.component";
import { BatchProcessingComponent, BatchRequestModel, BatchProcessingService } from "imagine-ui-ng-quick-start";
import { SimpleSearchSurveyModel } from "../../imagine-ui-ng-survey/model/SimpleSearchSurveyModel";
import { SurveyService } from "../../imagine-ui-ng-survey/service/survey.service";
import { SurveyModel } from "../../imagine-ui-ng-survey/model/SurveyModel";
import { MarketService } from "../../market";
import { MarketEditUIModel } from "../../market/model/MarketEditUIModel";
import cloneDeep from "lodash-es/cloneDeep";
import assign from "lodash-es/assign";


interface DownloadCodeDictionary { [key: string]: BatchRequestType; } // Use CodeEnum as the key
interface DownloadTypeDictionary { [key: string]: DownloadType; }
interface DownloadType { code: CodeEnum; text: string; }
interface DownloadSelectOption { value: DownloadOption; text: string; }
interface DownloadDetailOption { value: string; text: string; }
interface DownloadFileSelectOption { value: CodeEnum; text: string; }
interface DownloadFileCategories { text: string; options: DownloadFileSelectOption[]; }
type CodeEnum = "LOCFIX" | "LOCS" | "LOCSFIXFEAT" | "FIX" | "LOCFEAT" | "CAMPAIGN" | "PROMOH" | "PRODDTL" | "EXPITEMS" | "SURVEY" | "FEATURE" | "LocProdRestrict" | "ELEMENTS" | "FIXTURES" | "LOCCAMPADD" | "RoleProdRestrict" | "CONTENTLIST" | "USERS" | "PROMOD" | "PROMODATEFIX" | "TAXDATA";
type ExportOptionType = "Location" | "Fixture" | "LocationGroup" | "PendingAssignment" | "Campaign" | "FixtureGroup" | "Survey" | "LocationProductRestriction" | "ContentList" | "AllLocationFixtureFeature" | "Users" | "PromotionDatesExport" | "PromotionEndDateOverrideExport" | "TaxData";
// tslint:disable-next-line:max-line-length
type DownloadOption = "Location" | "Fixture" | "LocationGroup" | "PendingAssignment" | "Campaign" | "FixtureGroup" | "Template" | "AllLocation" | "CampaignDraft" | "CampaignAllocation" | "CampaignProduction" | "Survey" | "Survey.Section" | "Survey.Feature" | "AllLocationFeature" | "AllProducts" | "ContentList" | "CDCStores" | "NoneCDCStores" | "AllLocationFixtureFeature" | "Users" | "PromotionDatesExport" | "PromotionEndDateOverrideExport" | "TaxData";
export interface BatchRequestModelDisplay extends BatchRequestModel { RequestSubmittedBy_Display: string; }
interface FilterInput { Id: string; LocationIdentifier?: string; Label?: string; }
interface ValidationError { isValid: boolean; message: string; }
// tslint:disable-next-line:max-line-length
interface BatchRequestDetail { OptionType?: ExportOptionType; Id?: string; LocationIdentifier?: string; Label?: string; Detail?: string; StartDate?: Date; EndDate?: Date; SurveyId?: string; SectionId?: string; LocationFeature?: string; LocationFeatureName?: string; ShippingGroup?: string; }

@Component({
    selector: "app-file-processing",
    templateUrl: "./file-processing.component.html",
    styleUrls: ["./file-processing.component.scss"]
})
export class FileProcessingComponent implements OnInit, OnDestroy {
    @ViewChild("downloadForm") public fileProcessingForm: NgForm;
    @ViewChild("batchProcessingComponent") public batchProcessingComponent: BatchProcessingComponent;

    public busy: boolean;

    private templateNoData = "TEMPLATE_NO_DATA";
    private byLocation = "BY_LOCATION";
    private byFixture = "BY_FIXTURE";
    private byFixtureGroup = "BY_FIXTURE_GROUP";
    private allLocations = "ALL_LOCATIONS";
    private allPromotions = "ALL_PROMOTIONS";
    private allProducts = "ALL_PRODUCTS";
    private oldCampaignExport = "CHOOSE_OLD_CAMPAIGN_EXPORT";
    private newCampaignExport = "CHOOSE_NEW_CAMPAIGN_EXPORT";
    private textPromptRunCampaignExport = "PROMPT_RUN_CAMPAIGN_EXPORT_BODY";
    private textPromptWarnCampaignExportAlreadyRan = "PROMPT_WARN_CAMPAIGN_EXPORT_ALREADY_RAN_BODY";
    private textPromptRun7ETaxData = "PROMPT_7E_TAX_DATA";

    //file processing
    private downloadFileTypes: string[] = [];
    private accountSupportText = "ACCOUNT_SUPPORT";
    //lookup code by download type
    private downloadTypeDictionary: DownloadTypeDictionary = {};

    //lookup download type by code
    private downloadCodeDictionary: DownloadCodeDictionary = {};
    public placeholderText = null;
    public fileType = null;
    /**
     * Used for saving
     */
    public downloadOption: DownloadOption = null;
    public inputId: string | FilterInput = "";
    public inputId2: string | FilterInput = "";
    public inputId3: string | FilterInput = "";
    private internalId: string | {};
    private locationIdentifier: string;
    public downloadFileOptions: DownloadFileSelectOption[] = [];
    public downloadFileCategories: DownloadFileCategories[] = [];
    public businessIdentity: string;
    public inputRequiredTranslateKey = "";
    public inputRequiredTranslateKey2 = "";
    public inputRequiredTranslateKey3 = "";

    public downloadFileType: CodeEnum;
    public userSelectedDownloadFileType: CodeEnum;
    public userSelectedDownloadOption: DownloadOption;
    public userSelectedDownloadDetail: string;
    public downloadOptions: DownloadSelectOption[] = [];
    public downloadDetails: DownloadDetailOption[] = [];
    private locFixOptions: DownloadSelectOption[] = [];
    private locPrdRestrictionOptions: DownloadSelectOption[] = [];
    private locGrpOptions: DownloadSelectOption[] = [];
    private locGrpOptionsOptions: DownloadSelectOption[] = [];
    private locOptions: DownloadSelectOption[] = [];
    private campaignOptions: DownloadSelectOption[] = [];
    private campaignDownloadDetails: DownloadSelectOption[] = [];
    private contentListDetails: DownloadDetailOption[] = [];
    private prodDetailOptions: DownloadSelectOption[] = [];
    private contentListOptions: DownloadSelectOption[] = [];
    private promoHeaderOptions: DownloadSelectOption[] = [];
    private surveyOptions: DownloadSelectOption[] = [];
    private promoEndDateOverrideOptions: DownloadSelectOption[] = [];
    private taxData: DownloadSelectOption[] = [];
    public promise: Promise<void>;
    public timerAlive: boolean; // used to unsubscribe from the TimerObservable
    // when OnDestroy is called.
    public files: any; //file input control
    public showDateRange = false;
    private startDate: Date;
    private endDate: Date;

    public textMaxLength = "MAX_LENGTH_ERROR";
    public requiredMessage = "PLEASE_ENTER_NAME";

    public exportOptionProfile = ["LocationExport", "LocationGroupExport", "LocationLocationGroupExport", "LocationFixtureFeatureExport", "FixtureExport", "LocationFixtureExport", "LocationCampaignAddressExport"];
    public exportOptionCampaign = ["ContentListExport", "CampaignExport", "ProductionDetailExport", "PromotionHeaderExport", "ElementExport", "PromotionDatesExport", "PromotionEndDateOverrideExport", "TaxDataExport"];
    public exportOptionShop = ["LocationProductRestrictionExport", "RoleProductRestrictionExport"];
    public exportOptionSurvey = ["SurveyExport"];
    public exportOptionReport = ["ExpiredItemsExport", "UsersExport"];

    public errorMessages = {
        "required": () => this.inputRequiredTranslateKey || this.requiredMessage,
        "maxlength": (params) => IpsString.Format(this.textMaxLength, params.requiredLength)
    };

    constructor(
        //protected $document: ng.IDocumentService,
        protected translateService: TranslateService,
        //protected $interval: ng.IIntervalService,
        protected fileProcessingService: BatchProcessingService, protected listSearchHelper: ListSearchHelper,
        protected locationService: LocationService, protected fixtureService: FixtureService, protected fixtureGroupService: FixtureGroupService,
        protected ipsMessage: IpsMessageService,
        //protected imagineConfig: ImagineConfigProvider,
        protected activeProfileService: ActiveProfileService,
        protected locationGroupService: LocationGroupService, protected ipsModal: IpsModalService, protected campaignService: CampaignService,
        protected authService: AuthService, protected surveyService: SurveyService, protected marketService: MarketService,
        protected fileDownload: FileDownloadService) {

        this.businessIdentity = activeProfileService.businessIdentity;
        this.downloadCodeDictionary["LOCFIX"] = "LocationFixtureExport";
        this.downloadCodeDictionary["LOCS"] = "LocationExport";
        this.downloadCodeDictionary["LOCFEAT"] = "LocationLocationGroupExport";
        this.downloadCodeDictionary["LOCSFIXFEAT"] = "LocationFixtureFeatureExport";
        this.downloadCodeDictionary["CAMPAIGN"] = "CampaignExport";
        this.downloadCodeDictionary["PROMOH"] = "PromotionHeaderExport";
        this.downloadCodeDictionary["PRODDTL"] = "ProductionDetailExport";
        this.downloadCodeDictionary["EXPITEMS"] = "ExpiredItemsExport";
        this.downloadCodeDictionary["SURVEY"] = "SurveyExport";
        this.downloadCodeDictionary["FEATURE"] = "LocationGroupExport";
        this.downloadCodeDictionary["LocProdRestrict"] = "LocationProductRestrictionExport";
        this.downloadCodeDictionary["ELEMENTS"] = "ElementExport";
        this.downloadCodeDictionary["FIXTURES"] = "FixtureExport";
        this.downloadCodeDictionary["LOCCAMPADD"] = "LocationCampaignAddressExport";
        this.downloadCodeDictionary["RoleProdRestrict"] = "RoleProductRestrictionExport";
        this.downloadCodeDictionary["CONTENTLIST"] = "ContentListExport";
        this.downloadCodeDictionary["USERS"] = "UsersExport";
        this.downloadCodeDictionary["PROMOD"] = "PromotionDatesExport";
        this.downloadCodeDictionary["PROMODATEFIX"] = "PromotionEndDateOverrideExport";
        this.downloadCodeDictionary["TAXDATA"] = "TaxDataExport";

        let today = new Date();
        today.setHours(0, 0, 0, 0);	// set to midnight
        this.startDate = today;
        this.endDate = today;


        // Set validation debounce to 0 right away so we don"t get a flicker
        //$scope["$validationOptions"] = { debounce: 0 };
        this.timerAlive = true;
    }

    ngOnInit() {
        // Call translate get in order to wait until translations are loaded, then call our translateText method.
        this.translateService.get("ALL").subscribe(() => this.translateText());
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    ngOnDestroy() {
        this.timerAlive = false; // switches TimerObservable off
    }

    private translateText() {
        this.byLocation = this.translateService.instant(this.byLocation);
        this.byFixture = this.translateService.instant(this.byFixture);
        this.byFixtureGroup = this.translateService.instant(this.byFixtureGroup);
        this.templateNoData = this.translateService.instant(this.templateNoData);
        this.allLocations = this.translateService.instant(this.allLocations);
        this.allPromotions = this.translateService.instant(this.allPromotions);
        this.allProducts = this.translateService.instant(this.allProducts);
        this.oldCampaignExport = this.translateService.instant(this.oldCampaignExport);
        this.newCampaignExport = this.translateService.instant(this.newCampaignExport);
        this.textPromptRunCampaignExport = this.translateService.instant(this.textPromptRunCampaignExport);
        this.textPromptWarnCampaignExportAlreadyRan = this.translateService.instant(this.textPromptWarnCampaignExportAlreadyRan);

        this.locFixOptions = [
            { value: "Location", text: this.byLocation },
            { value: "Fixture", text: this.byFixture },
            { value: "FixtureGroup", text: this.byFixtureGroup },
            { value: "Template", text: this.templateNoData }];

        this.locGrpOptions = [
            { value: "PendingAssignment", text: this.translateService.instant("BY_PENDING_ASSIGNMENT") },
            { value: "Location", text: this.byLocation },
            { value: "LocationGroup", text: this.translateService.instant("BY_LOCATION_GROUP") },
            { value: "Template", text: this.templateNoData }];

        this.locGrpOptionsOptions = [
            { value: "AllLocationFeature", text: this.translateService.instant("ALL_LOCATION_FEATURES") },
            { value: "Template", text: this.templateNoData }];

        this.campaignOptions = [{ value: "Campaign", text: "" }];

        this.campaignDownloadDetails = [{ value: "CampaignDraft", text: this.translateService.instant("DRAFT") },
        { value: "CampaignProduction", text: this.translateService.instant("SAVE_AS_PROD_FILE") },
        { value: "CampaignAllocation", text: this.translateService.instant("SAVE_AS_ALLOC_FILE") }];

        this.prodDetailOptions = [{ value: "Campaign", text: "" }];

        this.contentListOptions = [{ value: "ContentList", text: "" }];

        this.promoHeaderOptions = [
            { value: "Campaign", text: this.allPromotions }, // all promotions by campaign
            { value: "Template", text: this.templateNoData }
        ];

        this.locOptions = [
            { value: "AllLocation", text: this.allLocations },
            { value: "Template", text: this.templateNoData }];

        this.locPrdRestrictionOptions = [
            { value: "AllProducts", text: this.allProducts }];

        this.surveyOptions = [{ value: "Survey", text: "" }];

        this.promoEndDateOverrideOptions = [{ value: "Template", text: this.templateNoData }];

        this.taxData = [
            { value: "TaxData", text: "" }
        ];

        this.downloadTypeDictionary["LocationFixtureExport"] = { code: "LOCFIX", text: this.translateService.instant("FIXTURE_QUANTITIES") };
        this.downloadTypeDictionary["LocationExport"] = { code: "LOCS", text: this.translateService.instant("LOCATIONS") };
        this.downloadTypeDictionary["LocationLocationGroupExport"] = { code: "LOCFEAT", text: this.translateService.instant("LOCATION_FEATURE_ASSIGNMENT") };
        this.downloadTypeDictionary["LocationFixtureFeatureExport"] = { code: "LOCSFIXFEAT", text: this.translateService.instant("ALL_LOCATION_FIXTURES_FEATURES") };
        this.downloadTypeDictionary["CampaignExport"] = { code: "CAMPAIGN", text: this.translateService.instant("CAMPAIGN_EXPORT") };
        this.downloadTypeDictionary["PromotionHeaderExport"] = { code: "PROMOH", text: this.translateService.instant("PROMO_HEADERS") };
        this.downloadTypeDictionary["ProductionDetailExport"] = { code: "PRODDTL", text: this.translateService.instant("PRODUCTION_DETAILS") };
        this.downloadTypeDictionary["ContentListExport"] = { code: "CONTENTLIST", text: this.translateService.instant("CONTENT_LIST") };
        this.downloadTypeDictionary["ExpiredItemsExport"] = { code: "EXPITEMS", text: this.translateService.instant("EXPIRED_ITEMS_EXPORT") };
        this.downloadTypeDictionary["SurveyExport"] = { code: "SURVEY", text: this.translateService.instant("SURVEY_EXPORT") };
        this.downloadTypeDictionary["LocationGroupExport"] = { code: "FEATURE", text: this.translateService.instant("LOCATION_FEATURE") };
        this.downloadTypeDictionary["LocationProductRestrictionExport"] = { code: "LocProdRestrict", text: this.translateService.instant("LOCATION_PRODUCT_RESTRICTIONS") };
        this.downloadTypeDictionary["ElementExport"] = { code: "ELEMENTS", text: this.translateService.instant("ELEMENTS") };
        this.downloadTypeDictionary["FixtureExport"] = { code: "FIXTURES", text: this.translateService.instant("FIXTURES") };
        this.downloadTypeDictionary["LocationCampaignAddressExport"] = { code: "LOCCAMPADD", text: this.translateService.instant("ALTERNATE_ADDRESSES") };
        this.downloadTypeDictionary["RoleProductRestrictionExport"] = { code: "RoleProdRestrict", text: this.translateService.instant("ROLE_PRODUCT_RESTRICTIONS") };
        this.downloadTypeDictionary["UsersExport"] = { code: "USERS", text: this.translateService.instant("USERS") };
        this.downloadTypeDictionary["PromotionDatesExport"] = { code: "PROMOD", text: this.translateService.instant("PROMO_DATES") };
        this.downloadTypeDictionary["PromotionEndDateOverrideExport"] = { code: "PROMODATEFIX", text: this.translateService.instant("PROMO_END_DATES_Override") };
        this.downloadTypeDictionary["TaxDataExport"] = { code: "TAXDATA", text: this.translateService.instant("TAX_DATA") };

        this.textMaxLength = this.translateService.instant("MAX_LENGTH_ERROR");
        this.requiredMessage = this.translateService.instant("PLEASE_ENTER_NAME");
    }

    private clearSelection() {
        this.placeholderText = null;
        this.downloadFileType = null;
        this.downloadOption = null;
        this.inputId = null;
        this.inputId2 = null;
        this.inputId3 = null;
        this.internalId = null;
        this.locationIdentifier = null;
        this.userSelectedDownloadOption = null;
        this.userSelectedDownloadDetail = null;
        if (this.downloadFileOptions.length > 0) {
            this.userSelectedDownloadFileType = this.downloadFileOptions[0].value;
        } else {
            this.userSelectedDownloadFileType = "" as CodeEnum;
        }
    }
    private loadOptions(optionList: string[]): DownloadFileSelectOption[] {
        let options: DownloadFileSelectOption[] = [];
        optionList.forEach(o => {
            let type = this.downloadFileTypes.find(ft => ft === o);
            let fileType: DownloadType = this.downloadTypeDictionary[type];
            let option = { value: fileType.code, text: fileType.text };
            options.push(option);
        });
        return options;
    }
    private loadDownloadFileOptions() {
        //PROFILES
        const optionsProfile = this.loadOptions(this.exportOptionProfile);
        this.downloadFileCategories.push({ text: "PROFILES", options: optionsProfile });

        //CAMPAIGNS
        let optionsCampaign = this.loadOptions(this.exportOptionCampaign);
        this.downloadFileCategories.push({ text: "CAMPAIGNS", options: optionsCampaign });

        //SHOP
        let optionsShop = this.loadOptions(this.exportOptionShop);
        this.downloadFileCategories.push({ text: "SHOP", options: optionsShop });

        //SURVEYS
        let optionsSurvey = this.loadOptions(this.exportOptionSurvey);
        this.downloadFileCategories.push({ text: "SURVEYS", options: optionsSurvey });

        //REPORTS
        let optionsReport = this.loadOptions(this.exportOptionReport);
        this.downloadFileCategories.push({ text: "REPORTS", options: optionsReport });

        this.userSelectedDownloadFileType = "" as CodeEnum;
    }

    private getDownloadFileTypes() {
        this.fileProcessingService.getDownloadFileTypes().then((response: string[]) => {
            Object.assign(this.downloadFileTypes, response);

            this.loadDownloadFileOptions();
        });
    }

    /**
    * This gets used as a callback, so it needs to be a property
    */
    private selectDownloadOption() {
        this.downloadOption = this.userSelectedDownloadOption;
        switch (this.downloadOption) {
            case "Location":
                this.placeholderText = "ENTER_LOCATION_ID";
                this.inputRequiredTranslateKey = this.translateService.instant("PLEASE_ENTER_LOCATION_ID");
                break;
            case "Fixture":
                this.placeholderText = "ENTER_FIXTURE_NAME_MODEL";
                this.inputRequiredTranslateKey = this.translateService.instant("PLEASE_ENTER_FIXTURE_NAME_MODEL");
                break;
            case "FixtureGroup":
                this.placeholderText = "ENTER_FIXTURE_GROUP_NAME";
                this.inputRequiredTranslateKey = this.translateService.instant("PLEASE_ENTER_FIXTURE_GROUP_NAME");
                break;
            case "LocationGroup":
                this.placeholderText = "ENTER_LOCATION_GROUP_NAME";
                this.inputRequiredTranslateKey = this.translateService.instant("PLEASE_ENTER_LOCATION_GROUP_NAME");
                break;
            case "Campaign":
                this.placeholderText = "ENTER_CAMPAIGN_NAME";
                this.inputRequiredTranslateKey = this.translateService.instant("PLEASE_ENTER_CAMPAIGN_NAME");
                break;
            case "Survey":
                this.placeholderText = "ENTER_SURVEY_NAME";
                this.inputRequiredTranslateKey = this.translateService.instant("PLEASE_ENTER_SURVEY_NAME");
                this.inputRequiredTranslateKey2 = this.translateService.instant("PLEASE_ENTER_SECTION_NAME");
                this.inputRequiredTranslateKey3 = this.translateService.instant("PLEASE_ENTER_LOCATION_FEATURE_NAME");
                this.inputId2 = { Id: "0", Label: this.translateService.instant("ALL_SECTIONS") };
                break;
        }
    }

    private postRequestExport(): Promise<BatchRequestModel> {
        let requestDetail: BatchRequestDetail;
        switch (this.downloadOption) {
            case "Location":
                requestDetail = { OptionType: this.downloadOption, Id: <string>this.internalId, LocationIdentifier: this.locationIdentifier };
                break;
            case "Fixture":
            case "LocationGroup":
            case "FixtureGroup":
            case "AllLocationFixtureFeature":
            case "Users":
                requestDetail = { OptionType: this.downloadOption, Id: <string>this.internalId, Label: (this.inputId as FilterInput).Label || <string>this.inputId };
                break;
            case "Campaign":
                requestDetail = { OptionType: this.downloadOption, Id: <string>this.internalId, Label: (this.inputId as FilterInput).Label || <string>this.inputId, Detail: this.userSelectedDownloadDetail };
                break;
            case "TaxData":
                requestDetail = { OptionType: this.downloadOption, Id: <string>this.internalId, Label: (this.inputId as FilterInput).Label || <string>this.inputId };
                break;
            case "PendingAssignment":
                requestDetail = { OptionType: this.downloadOption };
                break;
            case "ContentList":
                let label = (this.inputId as FilterInput).Label || <string>this.inputId;
                label = `${label} - ${this.userSelectedDownloadDetail} `;
                requestDetail = {
                    OptionType: this.downloadOption,
                    Id: <string>this.internalId,
                    Label: label,
                    ShippingGroup: this.userSelectedDownloadDetail
                };
                break;
            case "Survey":
                requestDetail = {
                    OptionType: this.downloadOption,
                    SurveyId: (this.inputId as FilterInput).Id, SectionId: (this.inputId2 as FilterInput).Id,
                    Label: `${(this.inputId as FilterInput).Label} - ${(this.inputId2 as FilterInput).Label}`,
                    LocationFeature: this.inputId3 ? (this.inputId3 as FilterInput).Id : "0",
                    LocationFeatureName: this.inputId3 ? (this.inputId3 as FilterInput).Label : ""
                };
                break;
        }
        if (this.downloadFileType === "EXPITEMS") {
            requestDetail = { StartDate: this.startDate, EndDate: this.endDate };
        }
        let requestType = this.downloadCodeDictionary[this.downloadFileType];
        return this.fileProcessingService.postExport(requestType, requestDetail);
    }

    private resetForm(form: NgForm) {
        //reset the download input field, to prevent existing validation error from showing after switching download types
        this.userSelectedDownloadDetail = null;
        this.inputId = null;
        this.inputId2 = null;
        this.inputId3 = null;
        this.resetControl(form.controls["downloadInput"]);
        this.resetControl(form.controls["downloadInput2"]);
        this.resetControl(form.controls["downloadInput3"]);
    }

    private resetControl(control: AbstractControl) {
        if (control) {
            control.markAsUntouched();
            control.markAsPristine();
        }
    }


    //show file download/upload panel
    showFileProcessingPanel(type?: string) {
        this.clearSelection();
        if (type === "download") {
            //only download if downloadFileTypes is empty
            if (this.downloadFileTypes.length < 1) {
                this.getDownloadFileTypes();
            }
        }
    }

    //download file type change
    selectDownloadFileType() {
        this.inputId = null;
        this.resetForm(this.fileProcessingForm);
        this.downloadFileType = this.userSelectedDownloadFileType;
        this.downloadDetails = [];
        this.showDateRange = false;

        switch (this.downloadFileType) {
            case "LOCFIX":
                this.downloadOptions = this.locFixOptions;
                break;
            case "LOCFEAT":
                this.downloadOptions = this.locGrpOptions;
                break;
            case "FEATURE":
                this.downloadOptions = this.locGrpOptionsOptions;
                break;
            case "CAMPAIGN":
                this.downloadOptions = this.campaignOptions;
                this.downloadDetails = this.campaignDownloadDetails;
                this.userSelectedDownloadDetail = this.downloadDetails[0].value;
                break;
            case "LOCS":
                this.downloadOptions = this.locOptions;
                break;
            case "PROMOH":
                this.downloadOptions = this.promoHeaderOptions;
                break;
            case "PRODDTL":
                this.downloadOptions = this.prodDetailOptions;
                break;
            case "CONTENTLIST":
                this.downloadOptions = this.contentListOptions;
                break;
            case "EXPITEMS":
                this.downloadOptions = [];
                this.showDateRange = true;
                break;
            case "SURVEY":
                this.downloadOptions = this.surveyOptions;
                break;
            case "LocProdRestrict":
            case "RoleProdRestrict":
                this.downloadOptions = this.locPrdRestrictionOptions;
                break;
            case "FIXTURES":
            case "ELEMENTS":
            case "LOCCAMPADD":
            case "LOCSFIXFEAT":
            case "USERS":
            case "PROMOD":
                this.downloadOptions = [];
                break;
            case "PROMODATEFIX":
                this.downloadOptions = this.promoEndDateOverrideOptions;
                break;
            case "TAXDATA":
                this.downloadOptions = this.taxData;

        }
        if (this.downloadOptions.length > 0) {
            this.userSelectedDownloadOption = this.downloadOptions[0].value;
        } else {
            this.userSelectedDownloadOption = null;
        }
        this.selectDownloadOption();
    }

    //select download Option
    downloadOptionChange() {
        this.selectDownloadOption();
        this.resetForm(this.fileProcessingForm);
    }

    downloadSubmitPrompt() {
        this.inputIdValiation().then((results: any[]) => {
            let errorMsg = "";

            results.forEach((item) => {
                if (item !== true && !item.isValid) {
                    errorMsg += `${item.message}\n\n`;
                }
            });

            if (errorMsg.length > 0) {
                this.ipsMessage.error(errorMsg);
            } else {
                if (this.batchProcessingComponent.fileProcessingType === "download") {
                    let requestType = this.downloadCodeDictionary[this.downloadFileType];
                    switch (this.downloadOption) {
                        case "Template":
                            //get file path
                            let path = `${this.fileProcessingService.getTemplate(requestType)}`;

                            if (this.userSelectedDownloadFileType === "PROMOH") {
                                path = `${path}/${this.internalId}`;
                            }

                            this.fileDownload.Download(path);
                            break;
                        case "Campaign":
                            //prompt differet warning for first time export and subsequent, except drafts
                            let requestDetail: BatchRequestDetail;
                            let hasCampaignExportRan: boolean;
                            requestDetail = { OptionType: this.downloadOption, Id: <string>this.internalId, Label: (this.inputId as FilterInput).Label || <string>this.inputId, Detail: this.userSelectedDownloadDetail };
                            this.campaignService.get(requestDetail.Id).then((data: CampaignData) => {
                                hasCampaignExportRan = (requestDetail.Detail === "CampaignAllocation" && "AllocationMedia" in data) || (requestDetail.Detail === "CampaignProduction" && "ProductionMedia" in data);
                                if (requestDetail && requestDetail.Detail !== "CampaignDraft") {
                                    this.showConfirmMessageBox(requestDetail.Label, hasCampaignExportRan);
                                }
                                else {
                                    return this.SubmitCampaignExport();
                                }
                            });
                            break;
                        default:
                            return this.ipsMessage.waitForWork({ body: "PROCESSING", successMessage: "EXPORT_REQUEST_SUBMITTED", workFunction: () => this.postRequestExport(), progressMessage: "PROCESSING" }, undefined).then((res: BatchRequestModel) => {
                                if (res) {
                                    this.fileProcessingForm.form.markAsUntouched();
                                    this.batchProcessingComponent.showFileProcessingPanel();
                                    this.batchProcessingComponent.loadData();
                                    this.resetControl(this.fileProcessingForm.controls["fileTypes"]);
                                }
                            });
                    }
                }
            }
            return Promise.resolve();
        }).catch((error) => {
            let errorMsg = "";

            error.forEach((item) => {
                if (!item.isValid) {
                    errorMsg += `${item.message}\n\n`;
                } else if (typeof (item) === "string") {

                }
            });

            if (errorMsg.length > 0) {
                this.ipsMessage.error(errorMsg);
            } else {
                this.ipsMessage.error(this.translateService.instant("VALIDATION_ERROR"));
            }
        });
    }

    //validation location/fixture ID
    inputIdValiation(): Promise<any[]> {
        let promises: Promise<any>[] = [];
        if (this.userSelectedDownloadFileType === "EXPITEMS") {
            return Promise.all([Promise.resolve(true)]);
        }
        if (this.inputId === null) {
            this.inputId = "";
        }

        //Only need to validate when user didn't use the filter or modal
        if (typeof this.inputId === "string") {
            let id = this.inputId.trim();
            switch (this.userSelectedDownloadOption) {
                case "Location":
                    promises.push(this.locationService.getByExternalId(id).then((data: LocationModel) => {
                        //set location Id to internal id
                        this.locationIdentifier = <string>this.inputId;
                        this.internalId = data.Id;
                        return Promise.resolve(true);
                    }).catch((error: any) => {
                        let errorMsg = this.translateService.instant("PLEASE_ENTER_VALID_LOCATION_ID");
                        this.internalId = null;
                        return Promise.resolve({ isValid: false, message: errorMsg });
                    }));
                    break;
                case "Fixture":
                    promises.push(this.fixtureService.get(id).then((data: FixtureModel) => {
                        return Promise.resolve(true);
                    }).catch((error: any) => {
                        let errorMsg = this.translateService.instant("PLEASE_ENTER_VALID_FIXTURE_NAME");
                        this.internalId = null;
                        return Promise.resolve({ isValid: false, message: errorMsg });
                    }));
                    break;
                case "FixtureGroup":
                    promises.push(this.fixtureGroupService.get(id).then((data: FixtureGroupModel) => {
                        return Promise.resolve(true);
                    }).catch((error: any) => {
                        let errorMsg = this.translateService.instant("PLEASE_ENTER_VALID_FIXTURE_GROUP_NAME");
                        this.internalId = null;
                        return Promise.resolve({ isValid: false, message: errorMsg });
                    }));
                    break;
                case "Template":
                case "PendingAssignment":
                case "AllLocation":
                    promises.push(Promise.resolve(true));
                    break;
                case "LocationGroup":
                    promises.push(this.locationGroupService.get(id).then((data: LocationGroupModel) => {
                        return Promise.resolve(true);
                    }).catch((error: any) => {
                        let errorMsg = this.translateService.instant("PLEASE_ENTER_VALID_LOCATIONGROUP_NAME");
                        this.internalId = null;
                        return Promise.resolve({ isValid: false, message: errorMsg });
                    }));
                    break;
                case "Campaign":
                case "ContentList":
                case "TaxData":
                    promises.push(this.campaignService.get(id).then((data: CampaignData) => {
                        return Promise.resolve(true);
                    }).catch((error: any) => {
                        let errorMsg = this.translateService.instant("PLEASE_ENTER_VALID_CAMPAIGN_NAME");
                        this.internalId = null;
                        return Promise.resolve({ isValid: false, message: errorMsg });
                    }));
                    break;
                case "Survey":
                    promises.push(this.surveyService.get(id).then((data: SurveyModel) => {
                        return Promise.resolve(true);
                    }).catch((error: any) => {
                        let errorMsg = this.translateService.instant("PLEASE_ENTER_VALID_SURVEY_NAME");
                        this.internalId = null;
                        return Promise.resolve({ isValid: false, message: errorMsg });
                    }));
                    break;
            }
            this.internalId = this.internalId || this.inputId;
        } else {
            if (this.downloadOption === "Location") {
                //Users use externalId not internalId for location
                this.locationIdentifier = (this.inputId as FilterInput).LocationIdentifier;
            }
            this.internalId = (this.inputId as FilterInput).Id;
            promises.push(Promise.resolve(true));
        }

        this.inputId2 = this.inputId2 || "";
        if (typeof this.inputId2 === "string" || (this.inputId2 as FilterInput).Id === "0") {
            switch (this.userSelectedDownloadOption) {
                case "Survey":
                    //0 is a valid value for surveys
                    if ((this.inputId2 as FilterInput).Id === "0") {
                        promises.push(Promise.resolve(true));
                    } else {
                        promises.push(Promise.resolve({ isValid: false, message: this.translateService.instant("PLEASE_ENTER_VALID_SURVEY_SECTION_NAME") }));
                    }
                    break;
            }
        } else {
            promises.push(Promise.resolve(true));
        }

        this.inputId3 = this.inputId3 || "";
        if (typeof this.inputId3 === "string" || (this.inputId3 as FilterInput).Id === "0") {
            switch (this.userSelectedDownloadOption) {
                case "Survey":
                    //Allow user not to have a location feature
                    if (this.inputId3 === "") {
                        promises.push(Promise.resolve(true));
                    } else {
                        promises.push(Promise.resolve({ isValid: false, message: this.translateService.instant("PLEASE_ENTER_VALID_LOCATION_FEATURE_NAME") }));
                    }

                    break;
            }
        } else {
            promises.push(Promise.resolve(true));
        }

        return Promise.all(promises);
    }

    inputFormatter = (result: any) => {
        if (result && result.Label) {
            return result.Label;
        }
        return result;
    }

    //inputId - filter
    getLocation = (text$: Observable<string>, controlName: string) => {
        return text$.pipe(
            debounceTime(350),
            distinctUntilChanged(),
            switchMap(val => {
                //Make sure there are at least two characters before searching
                if (val.length < 2) {
                    return [];
                }

                let optionName: DownloadOption = cloneDeep(this.downloadOption);

                switch (controlName) {
                    case "downloadInput2":
                        if (this.downloadOption === "Survey") {
                            optionName = "Survey.Section";
                        }
                        break;
                    case "downloadInput3":
                        if (this.downloadOption === "Survey") {
                            optionName = "Survey.Feature";
                        }
                        break;
                }

                let searchParams: SearchInfo = { searchText: val.trim(), businessIdentity: this.activeProfileService.businessIdentity, chunkIndex: 0, recordCount: 20 };
                switch (optionName) {
                    case "Location":
                        return this.locationService.search(searchParams, "Location/SimpleSearch").then((result: SearchResponse<SimpleSearchLocationModel>) => {
                            result.ResultList.forEach(function (item: SimpleSearchLocationModel) {
                                item.Id = item.Id;
                                item.Label = `${item.LocationIdentifier} - ${item.Name}`;
                            });
                            return result.ResultList;
                        });
                    case "Fixture":
                        return this.fixtureService.search(searchParams, "Fixture/SimpleSearch").then((result: SearchResponse<SimpleSearchFixtureModel>) => {
                            result.ResultList.forEach(function (item: SimpleSearchFixtureModel) {
                                item.Label = item.Model ? item.Name + " - " + item.Model : item.Name;
                            });
                            return result.ResultList;
                        });
                    case "FixtureGroup":
                        return this.fixtureGroupService.search(searchParams, "FixtureType/SimpleSearch").then((result: SearchResponse<SimpleSearchFixtureGroupModel>) => {
                            result.ResultList.forEach(function (item: SimpleSearchFixtureGroupModel) {
                                item.Label = item.Name;
                            });
                            return result.ResultList;
                        });
                    case "LocationGroup":
                        return this.locationGroupService.search(searchParams, "LocationGroup/SimpleSearch").then((result: SearchResponse<SimpleSearchLocationGroupModel>) => {
                            result.ResultList.forEach(function (item: SimpleSearchLocationGroupModel) {
                                item.Label = item.Name;
                            });
                            return result.ResultList;
                        });
                    case "Campaign":
                    case "ContentList":
                    case "TaxData":
                    case "Template": // for promo headers, we need to locate a campaign when downloading a template
                        return this.campaignService.search(searchParams, "Campaign/SimpleCampaign/Search").then((result: SearchResponse<SimpleSearchCampaignModel>) => {
                            result.ResultList.forEach(function (item: SimpleSearchCampaignModel) {
                                item.Label = item.Name;
                            });
                            return result.ResultList;
                        });
                    case "Survey":
                        return this.surveyService.search(searchParams, "Survey/Search").then((result: SearchResponse<SimpleSearchSurveyModel>) => {
                            result.ResultList.forEach((item: SimpleSearchSurveyModel) => {
                                item.Label = item.Name;
                            });
                            return result.ResultList;
                        });
                    case "Survey.Section":
                        return this.surveyService.search(searchParams, `Survey/${(this.inputId as FilterInput).Id}/SurveySection/Search`).then((result: SearchResponse<SimpleSearchFixtureGroupModel>) => {

                            result.ResultList = assign([{ Id: 0, Name: this.translateService.instant("ALL_SECTIONS") }], result.ResultList);
                            result.ResultList.forEach((item: SimpleSearchSurveyModel) => {
                                item.Label = item.Name;
                            });
                            return result.ResultList;
                        });
                    case "Survey.Feature":
                        return this.locationGroupService.search(searchParams, "LocationGroup/SimpleSearch").then((result: SearchResponse<SimpleSearchFixtureGroupModel>) => {
                            result.ResultList.forEach((item: SimpleSearchLocationGroupModel) => {
                                item.Label = item.Name;
                            });
                            return result.ResultList;
                        });
                }
            })
        );

    }

    //inputId - search
    showSelectionModal(controlName: string): void {
        let optionName: DownloadOption = cloneDeep(this.downloadOption);
        let resolve = { addAll: false, search: "", surveyId: null, allLabel: null, searchText: "" };

        switch (controlName) {
            case "downloadInput2":
                if (this.downloadOption === "Survey") {
                    optionName = "Survey.Section";
                }
                break;
            case "downloadInput3":
                if (this.downloadOption === "Survey") {
                    optionName = "Survey.Feature";
                }
                break;
        }

        switch (optionName) {
            case "Location":
                resolve.search = "location";
                break;
            case "Fixture":
                resolve.search = "fixture";
                break;
            case "FixtureGroup":
                resolve.search = "fixturegroup";
                break;
            case "LocationGroup":
                resolve.search = "locationgroup";
                break;
            case "Campaign":
            case "TaxData":
            case "ContentList":
            case "Template":
                resolve.search = "campaign";
                break;
            case "Survey":
                resolve.search = "survey";
                break;
            case "Survey.Section":
                resolve.search = "surveysection";
                resolve.addAll = true;
                resolve.allLabel = "ALL_SECTIONS";
                resolve.surveyId = (this.inputId as FilterInput).Id;
                break;
            case "Survey.Feature":

                let market: MarketEditUIModel = <any>{
                    Id: 0,
                    TargetMarketType: "LocationGroup",
                    TargetMarketId: 0
                };

                this.marketService.showMarketSelectionModal(market, [], [], false, false, "LocationGroup", [], [])
                    .then((newMarket) => {
                        this.inputId3 = {
                            Id: (<any>newMarket.DisplayInfo).LocationGroupId,
                            Label: (<any>newMarket.DisplayInfo).LocationGroupName
                        };

                    }).catch(() => {
                        // do nothing on close
                    });
                return;

        }
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: resolve }).then((response) => {
            switch (controlName) {
                case "downloadInput":
                    this.inputId = response.item;

                    if (this.downloadOption === "ContentList") {
                        this.campaignService.getContentListShippingGroup(response.item.Id).then((result) => {
                            let details: DownloadDetailOption[] = [{ value: "Select Shipping Group", text: "Select Shipping Group" }];
                            result.forEach(x => details.push({ value: x, text: x }));
                            this.contentListDetails = details;
                            this.downloadDetails = details;
                            this.userSelectedDownloadDetail = this.downloadDetails[0].value;
                        }).catch(() => {
                            // do nothing on close
                        });
                    }
                    break;
                case "downloadInput2":
                    this.inputId2 = response.item;
                    break;
                case "downloadInput3":
                    this.inputId3 = response.item;
                    break;
            }
        }, (rejectReason) => {
            // do nothing. We have this here to prevent a console log error.
        });
    }

    showDownloadInput(): boolean {
        // user must select a campaign even for a template
        // if the downloadType is PROMOH
        if (this.userSelectedDownloadFileType &&
            this.userSelectedDownloadFileType === "PROMOH") {
            return true;
        } else {
            return this.userSelectedDownloadFileType &&
                this.downloadOption !== "Template" &&
                this.downloadOption !== "PendingAssignment" &&
                this.downloadOption !== "AllLocation" &&
                this.downloadOption !== "AllLocationFeature" &&
                this.userSelectedDownloadFileType !== "LocProdRestrict" &&
                this.userSelectedDownloadFileType !== "RoleProdRestrict" &&
                this.userSelectedDownloadFileType !== "EXPITEMS" &&
                this.userSelectedDownloadFileType !== "ELEMENTS" &&
                this.userSelectedDownloadFileType !== "FIXTURES" &&
                this.userSelectedDownloadFileType !== "LOCCAMPADD" &&
                this.userSelectedDownloadFileType !== "LOCSFIXFEAT" &&
                this.userSelectedDownloadFileType !== "USERS" &&
                this.userSelectedDownloadFileType !== "PROMOD";
        }
    }

    showDownloadInput2(): boolean {
        if (this.userSelectedDownloadFileType === "SURVEY" && this.inputId) {
            return true;
        }
        return false;
    }

    showDownloadInput3(): boolean {
        if (this.userSelectedDownloadFileType === "SURVEY" && this.inputId) {
            return true;
        }
        return false;
    }

    disableDownloadSubmit(): boolean {
        // user must select a campaign when choosing the template option
        // when the file type is PROMOH
        if (this.userSelectedDownloadFileType === "PROMOH" &&
            this.downloadOption === "Template") {
            return this.fileProcessingForm.form.invalid;
        } else if (this.userSelectedDownloadFileType === "CONTENTLIST") {
            if (this.userSelectedDownloadDetail === "Select Shipping Group") {
                return true;
            } else {
                return this.fileProcessingForm.form.invalid;
            }
        } else {
            return this.downloadOption !== "Template" &&
                this.downloadOption !== "PendingAssignment" &&
                this.downloadOption !== "AllLocation" &&
                this.fileProcessingForm.form.invalid;
        }
    }

    public changeDateRange(event: any) {
        if (event && event.startDate && event.endDate) {
            this.startDate = event.startDate;
            this.endDate = event.endDate;
        } else {
            this.startDate = null;
            this.endDate = null;
        }
    }



    public searchFunctionFactory(controlName: string): (text: Observable<string>) => Observable<any[]> {
        let result = (text$: Observable<string>) => {
            return this.getLocation(text$, controlName);
        };
        return result;
    }

    public addSubmitOffset() {
        return this.userSelectedDownloadFileType !== "LOCFIX" &&
            this.userSelectedDownloadFileType !== "PROMOH" &&
            this.userSelectedDownloadFileType !== "CAMPAIGN";

    }

    public showInput3Remove() {
        return this.userSelectedDownloadFileType === "SURVEY";
    }

    public removeInput3() {
        this.inputId3 = "";
        this.fileProcessingForm.controls.downloadInput3.markAsPristine();
    }

    private showConfirmMessageBox(campaignName: string, hasBeenRan: boolean) {
        let messageBody = "";

        if (hasBeenRan) {
            messageBody += IpsString.Format(this.textPromptWarnCampaignExportAlreadyRan, campaignName);
        }
        else {
            messageBody += IpsString.Format(this.textPromptRunCampaignExport, campaignName);
        }

        return this.ipsMessage.confirm({
            body: messageBody,
            workFunction: () => this.SubmitCampaignExport(),
            progressMessage: "SUBMITTING"
        }, null).then((result: boolean) => {
            if (result) {
                //this.$state.go("main.storeProfileFixture.search");
            }
        })
            .catch(() => {
                // rejection
            });
    }

    public getErrorMessages(inputName: string) {
        let msgs = Object.assign({}, this.errorMessages);
        switch (inputName) {
            case "downloadInput":
                msgs["required"] = () => this.inputRequiredTranslateKey;
                break;
            case "downloadInput2":
                msgs["required"] = () => this.inputRequiredTranslateKey2;
                break;
            case "downloadInput3":
                msgs["required"] = () => this.inputRequiredTranslateKey3;
                break;
        }
        return msgs;
    }

    public SubmitCampaignExport() {
        return this.ipsMessage.waitForWork({ body: "PROCESSING", successMessage: "EXPORT_REQUEST_SUBMITTED", workFunction: () => this.postRequestExport(), progressMessage: "PROCESSING" }, undefined).then((res: BatchRequestModel) => {
            if (res) {
                this.fileProcessingForm.form.markAsUntouched();
                this.batchProcessingComponent.showFileProcessingPanel();
                this.batchProcessingComponent.loadData();
                this.resetControl(this.fileProcessingForm.controls["fileTypes"]);
            }
        });

    }

}
