<div class="col-md-12 col-lg-10">
    <div class="row">
        <section class="col-12 title">
            <h3>
                <span translate>VIEW_FIXTURE</span>
                <a class="ml-2" uiSref="main.storeProfileFixture.edit" [uiParams]="{id:myFixture.Id}" ipsPermission="main.storeProfileFixture.edit" ngbTooltip="{{'EDIT' | translate}} {{myFixture.Name}}" placement="bottom"><span class="fa fa fa-pencil editIcon"></span></a>
            </h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:'{{'ACCESSING_FIXTURE_INFO' | translate}}'}">
        <div class="row" [hidden]="!loaded">

            <div *ngIf="enableSignPlanLayout">
                <tabset class="order-tabs">
                    <!-- Main Fixture Details tab-->
                    <tab heading="Fixture Details">
                        <section class="col-sm col-xl-10">
                            <div class="form-group">
                                <div class="card">
                                    <div class="card-header view-info-header">
                                        {{myFixture.Name}}-{{myFixture.Id}}
                                    </div>
                                    <div class="form-row">
                                        <div class="col-lg-8 order-lg-first order-last pr-lg-0">
                                            <div class="card-body">
                                                <ul class="list-group">
                                                    <li class="list-group-item">
                                                        <div class="view-info-label" translate>Obsolete</div>
                                                        <div class="view-info-format-imgn">{{myFixture.IsObsolete ? "Yes" : "No"}}</div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="view-info-label" translate>MAKE_MODEL</div>
                                                        <div class="view-info-format-imgn">{{myFixture.Model}}</div>
                                                    </li>
                                                    <li class="list-group-item" *ngIf="showVeracoreOfferId">
                                                        <div class="view-info-label" translate>VERACORE_OFFER_ID</div>
                                                        <div class="view-info-format-imgn">{{myFixture.VeracoreOfferId}}</div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="view-info-label" translate>LOCATED_IN_SPACE</div>
                                                        <div class="view-info-format-imgn">{{myFixture.SpaceName}}</div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="view-info-label" translate>BELONGS_TO_FIXTURE_GROUP</div>
                                                        <div class="view-info-format-imgn">{{myFixture.FixtureGroupName}}</div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="view-info-label">{{(myFixture.Holders.length == 1 ? 'HOLDER' : 'HOLDERS') | translate}}: {{'SIZE_IN_INCHES' | translate}}</div>
                                                        <div class="view-info-format-imgn">
                                                            <ul class="list-group" >
                                                                <li class="list-group-item" *ngFor="let holder of myFixture.Holders" [ngClass]="{'ips-ls-obsolete-item text-muted' : holder.IsObsolete}">
                                                                    {{holder.Quantity | number}} - {{holder.Name}} - {{holder.Size.Width | number: '1.0-7'}} x {{holder.Size.Height | number: '1.0-7'}}
                                                                    <span *ngIf="holder.HolderVersionInfoName"> - </span>{{holder.HolderVersionInfoName}}
                                                                    <span *ngIf="holder.IsObsolete" class="ml-3 text-danger">Obsolete</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <div class="view-info-label" translate>NOTES_FIXTURE</div>
                                                        <div class="view-info-format-imgn">{{myFixture.Notes}}</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 loc-view-image pl-lg-0">
                                            <ips-image-view [imgThumbUrl]="fullImage" [imgFullUrl]="fullImage"></ips-image-view>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                        <hr>
                        <section class="col-12 col-md-8 pb-0">
                            <ips-list-search headerLabel="{{'RELATED_LOCATIONS' | translate }}"
                                             searchLabel="{{'LOCATION' | translate }}"
                                             searchLabelPlural="{{'LOCATIONS' | translate }}"
                                             labelLoading="{{'ACCESSING_LOCATION_INFO' | translate }}"
                                             labelLoadingError="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}"
                                             [listData]="qrLocation"
                                             [busy]="relatedLocationsBusy"
                                             (onListDataChange)="getLocations($event)"
                                             quickRef=true
                                             [filterOptions]="optionList"
                                             [selectedFilterOption]="selectedOption"
                                             [promise]="qrLocation.promise"
                                             [scroller]=false
                                             [translatedTexts]="translatedTexts">
                                >
                                <ng-template #listSearchItemTemplate let-result="result">
                                    <a uiSref="main.storeProfileLocation.view" [uiParams]="{id:result.Id}" class="list-group-item no-border">
                                        <span>
                                            <span>{{result.LocationLabel}}</span> <span *ngIf="showCountLabel(result.FixtureCountLabel)" ngbTooltip="{{'TOOLTIP_LOCATION_FIXTURE_COUNT' | translate}}" placement="bottom">({{result.FixtureCountLabel}})</span>
                                        </span>
                                    </a>
                                </ng-template>
                            </ips-list-search>
                        </section>
                    </tab>

                    <!-- Sign Plan Layout tab-->
                    <tab heading="Sign Plan Layout">
                        <div class="d-flex align-items-center justify-content-center full-image">
                            <div style="position:relative;">
                                <ips-image-view [imgThumbUrl]="fullLayoutImage" [imgFullUrl]="fullLayoutImage"></ips-image-view>
                                <div *ngFor="let signHolder of placedHolders" class="draggable placed"
                                     [style.top]="signHolder.Top + 'px'"
                                     [style.left]="signHolder.Left + 'px'"
                                     [style.height]="signHolder.Height + 'px'"
                                     [style.width]="signHolder.Width + 'px'"
                                     [style.background]="signHolder.BackgroundColor"
                                     [style.position]="'absolute'" appNgDraggable appNgResizable>
                                    <div class="d-flex align-self-stretch align-content-center justify-content-center h-100 align-items-center">
                                        {{ signHolder.HolderName }} <span>[{{ signHolder.Index }}]</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>

                    <!-- Supporting Documents tab -->
                    <tab heading="Supporting Images">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Label</th>
                                    <th>Width</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let document of attachedDocuments">
                                    <td class="text-center">
                                        <img style="height:auto;max-height:120px;" [style.maxWidth]="(document.width || 300) + 'px'" [src]="attachmentUrl(document)" alt="{{ document.Label }} {{ document.Label2 }}" />
                                    </td>
                                    <td>
                                        {{ document.Label }}<br />
                                        {{ document.Label2 }}
                                    </td>
                                    <td>
                                        {{ getWidthLabel(document.Width) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </tab>
                </tabset>
            </div>

            <div *ngIf="!enableSignPlanLayout">
                <section class="col-sm col-xl-10">
                    <div class="form-group">
                        <div class="card">
                            <div class="card-header view-info-header">
                                {{myFixture.Name}}-{{myFixture.Id}}
                            </div>
                            <div class="form-row">
                                <div class="col-lg-8 order-lg-first order-last pr-lg-0">
                                    <div class="card-body">
                                        <ul class="list-group">
                                            <li class="list-group-item">
                                                <div class="view-info-label" translate>Obsolete</div>
                                                <div class="view-info-format-imgn">{{myFixture.IsObsolete ? "Yes" : "No"}}</div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="view-info-label" translate>MAKE_MODEL</div>
                                                <div class="view-info-format-imgn">{{myFixture.Model}}</div>
                                            </li>
                                            <li class="list-group-item" *ngIf="showVeracoreOfferId">
                                                <div class="view-info-label" translate>VERACORE_OFFER_ID</div>
                                                <div class="view-info-format-imgn">{{myFixture.VeracoreOfferId}}</div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="view-info-label" translate>LOCATED_IN_SPACE</div>
                                                <div class="view-info-format-imgn">{{myFixture.SpaceName}}</div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="view-info-label" translate>BELONGS_TO_FIXTURE_GROUP</div>
                                                <div class="view-info-format-imgn">{{myFixture.FixtureGroupName}}</div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="view-info-label">{{(myFixture.Holders.length == 1 ? 'HOLDER' : 'HOLDERS') | translate}}: {{'SIZE_IN_INCHES' | translate}}</div>
                                                <div class="view-info-format-imgn">
                                                    <ul class="list-group">
                                                        <li class="list-group-item" *ngFor="let holder of myFixture.Holders" [ngClass]="{'ips-ls-obsolete-item text-muted' : holder.IsObsolete}">
                                                            {{holder.Quantity | number}} - {{holder.Name}} - {{holder.Size.Width | number: '1.0-7'}} x {{holder.Size.Height | number: '1.0-7'}}
                                                            <span *ngIf="holder.HolderVersionInfoName"> - </span>{{holder.HolderVersionInfoName}}
                                                            <span *ngIf="holder.IsObsolete" class="ml-3 text-danger">Obsolete</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li class="list-group-item">
                                                <div class="view-info-label" translate>NOTES_FIXTURE</div>
                                                <div class="view-info-format-imgn">{{myFixture.Notes}}</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-lg-4 loc-view-image pl-lg-0">
                                    <ips-image-view [imgThumbUrl]="fullImage" [imgFullUrl]="fullImage"></ips-image-view>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <hr>
                <section class="col-12 col-md-8 pb-0">
                    <ips-list-search headerLabel="{{'RELATED_LOCATIONS' | translate }}"
                                     searchLabel="{{'LOCATION' | translate }}"
                                     searchLabelPlural="{{'LOCATIONS' | translate }}"
                                     labelLoading="{{'ACCESSING_LOCATION_INFO' | translate }}"
                                     labelLoadingError="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}"
                                     [listData]="qrLocation"
                                     [busy]="relatedLocationsBusy"
                                     (onListDataChange)="getLocations($event)"
                                     quickRef=true
                                     [filterOptions]="optionList"
                                     [selectedFilterOption]="selectedOption"
                                     [promise]="qrLocation.promise"
                                     [scroller]=false
                                     [translatedTexts]="translatedTexts">
                        >
                        <ng-template #listSearchItemTemplate let-result="result">
                            <a uiSref="main.storeProfileLocation.view" [uiParams]="{id:result.Id}" class="list-group-item no-border">
                                <span>
                                    <span>{{result.LocationLabel}}</span> <span *ngIf="showCountLabel(result.FixtureCountLabel)" ngbTooltip="{{'TOOLTIP_LOCATION_FIXTURE_COUNT' | translate}}" placement="bottom">({{result.FixtureCountLabel}})</span>
                                </span>
                            </a>
                        </ng-template>
                    </ips-list-search>
                </section>
            </div>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_FIXTURE_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
