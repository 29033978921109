import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { MarketModel, MarketSaveModel, MarketBalanceListRequestModel } from "../../market/index";
import { MarketsAndBalanceListModel } from "../model/MarketsAndBalanceListModel";

@Injectable()
export class MarketGroupService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("MarketGroup", "Campaign", "v1");
    }

    public calculateAudienceLocationBalance(model: MarketSaveModel[]): Promise<MarketModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}MarketGroup/CalculateLocationBalance`;
        return this.postByRoute<MarketModel[]>(route, model);
    }

    public calculateLocationBalanceAndList(requestModel: MarketBalanceListRequestModel): Promise<MarketsAndBalanceListModel> {
        let route = `${this.urlApiBaseProductPlusVersion}MarketGroup/CalculateLocationBalanceAndList`;
        return this.postByRoute<MarketsAndBalanceListModel>(route, requestModel);
    }

    public calculateAudienceLocationBalanceList(requestModel: MarketBalanceListRequestModel): Promise<number[]> {
        let route = `${this.urlApiBaseProductPlusVersion}MarketGroup/MarketLocationBalanceList`;
        return this.postByRoute<number[]>(route, requestModel);
    }

    public checkForCircularMarketGroup(requestModel: MarketBalanceListRequestModel): Promise<boolean> {
        let route = `${this.urlApiBaseProductPlusVersion}MarketGroup/CheckForCircularMarketGroup`;
        return this.postByRoute<boolean>(route, requestModel);
    }

    public deleteCheck(id: string) {
        let route = `${this.urlApiBaseProductPlusVersion}MarketGroup/${id}/DeleteCheck`;
        return this.getByRoute(route);
    }

}
