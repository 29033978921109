/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./paypal-postback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./paypal-postback.component";
import * as i5 from "imagine-ui-ng-core";
import * as i6 from "@uirouter/core";
import * as i7 from "../service/cart.service";
var styles_PaypalPostbackComponent = [i0.styles];
var RenderType_PaypalPostbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaypalPostbackComponent, data: {} });
export { RenderType_PaypalPostbackComponent as RenderType_PaypalPostbackComponent };
function View_PaypalPostbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pb-3"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errorMessage; _ck(_v, 2, 0, currVal_1); }); }
function View_PaypalPostbackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "pb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Result Code:"])), (_l()(), i1.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentInfo.Result; _ck(_v, 3, 0, currVal_0); }); }
function View_PaypalPostbackComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Response Message:"])), (_l()(), i1.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentInfo.Message; _ck(_v, 3, 0, currVal_0); }); }
export function View_PaypalPostbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "ips-flex-loading-box mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "d-block"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["PAYPAL_POSTBACK"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaypalPostbackComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaypalPostbackComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaypalPostbackComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.error; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.error; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_PaypalPostbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-paypal-postback", [], null, null, null, View_PaypalPostbackComponent_0, RenderType_PaypalPostbackComponent)), i1.ɵdid(1, 114688, null, 0, i4.PaypalPostbackComponent, [i5.ActiveProfileService, i6.Transition, i6.StateService, i7.CartService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaypalPostbackComponentNgFactory = i1.ɵccf("app-paypal-postback", i4.PaypalPostbackComponent, View_PaypalPostbackComponent_Host_0, {}, {}, []);
export { PaypalPostbackComponentNgFactory as PaypalPostbackComponentNgFactory };
