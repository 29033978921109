import { Pipe, PipeTransform } from "@angular/core";
import { FixtureModel } from "../model/FixtureModel";

@Pipe({
  name: "spaceFilter",
  pure: false
})
export class SpaceFilterPipe implements PipeTransform {

    transform(allFixtures: FixtureModel[], selectedSpace): any {
        if (!selectedSpace || selectedSpace.Id === 0) {
            return allFixtures;
        }

        return allFixtures.filter(f => f.SpaceId === selectedSpace.Id);

    }

}
