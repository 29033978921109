<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3>{{ breadCrumbLabel | translate }}</h3>
        </section>
    </div>
    <div [cgBusy]="promise" [cgBusyConfig]="{ message: accessStoreFrontCategoryInfo }">
        <div class="row" [hidden]="!loaded">
            <div class="col-12 updateSection">
                <form id="ramiInfoInput" #storeFrontCategoryForm="ngForm" class="form-horizontal" name="storeFrontCategoryForm" novalidate ipsUnsavedWarningForm role="form">
                   <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="required" for="Name" translate>NAME</label>
                            <input type="text" name="name" #storeFrontCategoryName="ngModel" class="form-control form-control-lg input-full" id="name" placeholder="{{ 'ENTER_STORE_FRONT_CATEGORY_NAME' | translate }}"
                                required maxlength="50" [(ngModel)]="storeFrontCategory.Name" appValidation />
                            <div *ngIf="storeFrontCategoryName.errors" class="invalid-feedback">
                                <div *ngIf="storeFrontCategoryName.errors.required">
                                    {{ "ENTER_STORE_FRONT_CATEGORY_NAME" | translate }}
                                </div>
                                <div *ngIf="storeFrontCategoryName.errors.maxlength">
                                    {{ validationMaxLength(50) }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 form-group">
                            <div class="">
                                <label>&nbsp;</label>
                                <div class="input-group">
                                    <div class="form-control form-control-lg input-full" translate>PREFERS_LEFT_SIDE</div>
                                    <div class="input-group-append">
                                        <span class="input-group-text"><input name="preferLeftSide" type="checkbox" #storeFrontCategoryPrefersLeftSide="ngModel" [(ngModel)]="storeFrontCategory.PrefersLeftSide" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 form-group">
                            <label class="required" for="MaxSlots" translate>MAX_SLOTS</label>
                            <input type="text" name="maxSlots" #storeFrontCategoryMaxSlots="ngModel" class="form-control form-control-lg input-full" id="maxSlots" placeholder="{{ 'ENTER_STORE_FRONT_CATEGORY_MAX_SLOTS' | translate }}"
                                required [(ngModel)]="storeFrontCategory.MaxSlots" pattern="^[0-9]+$"  appValidation/>
                            <div *ngIf="storeFrontCategoryMaxSlots.errors" class="invalid-feedback">
                                <div *ngIf="storeFrontCategoryMaxSlots.errors.required">
                                    {{ "ENTER_STORE_FRONT_CATEGORY_MAX_SLOTS" | translate }}
                                </div>
                                <div *ngIf="storeFrontCategoryMaxSlots.errors.pattern">
                                    {{ "MAX_SLOTS_RULE" | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4 form-group">
                            <div class="">
                                <label>&nbsp;</label>
                                <div class="input-group">
                                    <div class="form-control form-control-lg input-full" translate>PREFERS_START</div>
                                    <div class="input-group-append">
                                        <span class="input-group-text"><input name="prefersStart" type="checkbox" #storeFrontCategoryPrefersStart="ngModel" [(ngModel)]="storeFrontCategory.PrefersStart"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <hr/>
                <div class="row">
                    <div class="col-12 col-sm-4 col-md-3 col-lg-3 form-group">
                        <div class="formSubmit">
                            <button id="saveReturnStoreFrontCategory"
                                class="btn btn-lg btn-block alert-success" type="submit"
                                (click)="saveStoreFrontCategoryPrompt()"
                                [disabled]="storeFrontCategoryForm.form.invalid || storeFrontCategoryForm.form.pristine"
                                [ngClass]="{'btn-default': storeFrontCategoryForm.form.invalid || storeFrontCategoryForm.form.pristine}" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3 col-md-3 col-lg-3">
                        <div class="formSubmit">
                            <button id="cancelStoreFrontCategory" class="btn btn-lg btn-block btn-default" type="submit" uiSref="main.storeFrontCategory.search" translate>CANCEL</button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3 col-md-3 offset-sm-2 offset-md-3">
                        <div class="formSubmit">
                            <button id="deleteStoreFrontCategory" class="btn btn-lg btn-block alert-danger" type="submit"
                                    (click)="deleteStoreFrontCategoryPrompt()"
                                    ips-unsaved-warning-clear
                                    [disabled]="true"
                                    [ngClass]="{'btn-default': !storeFrontCategory.Id}" translate>
                                DELETE_STORE_FRONT_CATEGORY
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_STORE_FRONT_CATEGORY_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
