<ul class="market-list">
    <li *ngFor="let market of markets; let first = first" class="message-market mb-3">
        <div class="d-flex justify-content-between align-items-center" [ngClass]="market.TargetMarketType">
            <div class="d-flex justify-content-start flex-grow-1 align-items-center">
                <div class="market-panel-type-icon"></div>
                <div class="message-market-name nowrap pl-3 flex-grow-1" [ngClass]="market.TargetMarketType">
                    <span class="long-text-wrap">{{market.DisplayName}}</span>
                    <span class="location-group-warning" *ngIf="market.PendingAssignmentTooltip"><i ngbTooltip="{{market.PendingAssignmentTooltip}}" placement="bottom" class="fa fa-exclamation-triangle"></i></span>
                </div>
            </div>

            <div class="ips btn-group h-100">
                <button [disabled]="first || market.OperationType !== 'Union'" class="btn btn-lg btn-default disabled" [ngClass]="{ 'active': market.OperationType === 'Union' }" ngbTooltip="{{'AUDIENCE_LOCATIONS_ADD_TIP' | translate}}" placement="bottom">
                    <span class="fa fa-plus"></span>
                </button>
                <button [disabled]="first || market.OperationType !== 'Except'" class="btn btn-lg btn-default disabled" [ngClass]="{ 'active': market.OperationType === 'Except' }" ngbTooltip="{{'AUDIENCE_LOCATIONS_SUBTRACT_TIP' | translate}}" placement="bottom">
                    <span class="fa fa-minus"></span>
                </button>
                <button [disabled]="first || market.OperationType !== 'Intersect'" class="btn btn-lg btn-default disabled" [ngClass]="{ 'active': market.OperationType === 'Intersect' }" ngbTooltip="{{'AUDIENCE_LOCATIONS_INTERSECT_TIP' | translate}}" placement="bottom">
                    <span class="fa custom-ampersand">&amp;</span>
                </button>
            </div>

        </div>
    </li>
    <li class="market-type-bullet-total-locations ml-3">
        <span translate>LOCATION_BALANCE</span>
        <div *ngIf="!showLocationModal" class="pull-right" [ngClass]="{'total-location-one': finalBalance < 10, 'total-location-two': finalBalance >= 10 && finalBalance < 100, 'total-location-three': finalBalance >= 100}">
            {{finalBalance | number}}
        </div>
        <button *ngIf="showLocationModal" class="btn badge-pill ips-pill pull-right text-center" (click)="showLocations()" [ngClass]="{'total-location-one': finalBalance < 10, 'total-location-two': finalBalance >= 10 && finalBalance < 100, 'total-location-three': finalBalance >= 100}">{{finalBalance | number}}</button>
    </li>
</ul>

