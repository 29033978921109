var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var AreaService = /** @class */ (function (_super) {
    __extends(AreaService, _super);
    function AreaService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("Area", "Tools", "v1");
        return _this;
    }
    AreaService.prototype.getCustomDataGroup = function (areaId) {
        var route = "" + this.urlApiBaseProductPlusVersion + areaId + "/CustomDataGroup";
        return this.getByRoute(route);
    };
    return AreaService;
}(IpsBaseWebService));
export { AreaService };
