import { OnInit, EventEmitter, QueryList } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { PromotionMessageService } from "../../index";
import { PromotionHelperService } from "../../service/promotionHelper.service";
import { CustomDataFieldContainerComponent } from "../../../shared/custom-data-field/custom-data-field-container/custom-data-field-container.component";
var MessageEditComponent = /** @class */ (function () {
    function MessageEditComponent(promotionHelperService, ipsMessage, translateService, promotionMessageService) {
        var _this = this;
        this.promotionHelperService = promotionHelperService;
        this.ipsMessage = ipsMessage;
        this.translateService = translateService;
        this.promotionMessageService = promotionMessageService;
        this.isClone = false;
        this.messageDeleted = new EventEmitter();
        this.messageAdded = new EventEmitter();
        this.marketChanged = new EventEmitter();
        this.messageNameChanged = new EventEmitter();
        this.cdfsLoaded = new EventEmitter();
        this.loaded = false;
        this.loadedMsgCount = 0;
        this.cdfLoadedCount = 0;
        this.errorMessages = {
            "required": function () { return _this.TranslateStrings["PLEASE_ENTER_MESSAGE"]; },
            "maxlength": function (params) { return IpsString.Format(_this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength); },
            "messageNameUniqueToCampaign": function () { return _this.TranslateStrings["MESSAGE_NAME_UNIQUE_TO_CAMPAIGN"]; },
            "invalidMarketName": function () { return _this.TranslateStrings["PLEASE_ENTER_VALID_MARKET"]; }
        };
        this.TranslateStrings = {
            "PLEASE_ENTER_VALID_MARKET": "",
            "MARKET_PENDING_LOCATIONS_WARNING": "",
            "MESSAGE_NAME_UNIQUE_TO_CAMPAIGN": "",
            "PLEASE_ENTER_MESSAGE": "",
            "PLEASE_ENTER_MARKET": "",
            "MAX_LENGTH_ERROR": ""
        };
    }
    MessageEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateService.get("ALL").subscribe(function () { return _this.translateText(); });
        this.promotionHelperService.tempMessageId = this.tempMessageId;
        this.promotionHelperService.createForm(this.parent, this.messageNameChanged, false);
        //Initial call to configure data loaded by promotion controller
        if (!this.parent.get("ClearAllMessages").value) {
            this.loadedMsgCount = this.promotionHelperService.initializeForm(this.patternGroupsModel, this.isClone);
        }
        this.messageNames = this.promotionHelperService.getMessageSuggestions();
        this.getFocusableMessageInput();
        //After loading message edit section force it to validate message name
        this.MessagePatterns(0).controls[0].get("MessageName").updateValueAndValidity();
    };
    MessageEditComponent.prototype.cdfLoadedHandler = function () {
        this.cdfLoadedCount++;
        if (this.cdfLoadedCount === this.loadedMsgCount) {
            this.cdfsLoaded.emit();
        }
    };
    MessageEditComponent.prototype.getOriginalMarkets = function (patternGroupCtrl) {
        var name = patternGroupCtrl.get("Name").value;
        var foundItem = this.patternGroupsModel.find(function (p) { return p.Name === name; });
        if (foundItem) {
            return foundItem.Markets;
        }
        return [];
    };
    MessageEditComponent.prototype.translateText = function () {
        for (var _i = 0, _a = Object.keys(this.TranslateStrings); _i < _a.length; _i++) {
            var key = _a[_i];
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    };
    MessageEditComponent.prototype.getErrorMessages = function (key) {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "market":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_ENTER_MARKET"]; };
                    break;
            }
        }
        return msgs;
    };
    MessageEditComponent.prototype.removeMainMessagePrompt = function (messageIndex, message, toolTip) {
        var _this = this;
        toolTip.close();
        if (this.placementsExist(messageIndex)) {
            this.ipsMessage.confirmDelete({ body: "PROMPT_DELETE_PROMOTION_MESSAGE" })
                .then(function (response) {
                if (response) {
                    _this.removeMainMessage(messageIndex, message);
                }
            })
                .catch(function () {
                // rejection
            });
        }
        else {
            this.removeMainMessage(messageIndex, message);
        }
    };
    MessageEditComponent.prototype.removeMainMessage = function (messageIndex, message) {
        var patternGroups = this.PatternGroups;
        var deletedObj = patternGroups.at(messageIndex).value;
        patternGroups.removeAt(messageIndex);
        // raise event to promotion component for placements. Need to return the actual message that was deleted, which is always the first one in our case.
        this.messageDeleted.emit(deletedObj.MessagePatterns[0]);
        this.parent.markAsDirty();
    };
    MessageEditComponent.prototype.placementsExist = function (messageIndex) {
        return this.promotionHelperService.PlacementsExist(this.parent);
    };
    MessageEditComponent.prototype.marketChangedEventHandler = function (patternGroupCtrl) {
        this.marketChanged.emit(patternGroupCtrl.get("TempPatternGroupId").value);
    };
    Object.defineProperty(MessageEditComponent.prototype, "PatternGroups", {
        get: function () {
            return this.parent.controls.messageForm.get("PatternGroups");
        },
        enumerable: true,
        configurable: true
    });
    MessageEditComponent.prototype.MessagePatterns = function (index) {
        return this.parent.controls.messageForm.get(["PatternGroups", index]).get("MessagePatterns");
    };
    //jquery.ui.touch.punch prevents the input/textarea to be selected
    //this method set focus on input when it's clicked
    MessageEditComponent.prototype.setFocus = function (event) {
        var element = event.target;
        element.focus();
    };
    MessageEditComponent.prototype.updateMessageTypeahead = function () {
        this.messageNames = this.promotionHelperService.getMessageSuggestions();
    };
    MessageEditComponent.prototype.createMessage = function () {
        var patternGroups = this.PatternGroups;
        var patternGroup = this.promotionHelperService.newPatternGroup(false);
        patternGroups.push(patternGroup);
        var addedObj = patternGroup.value;
        this.messageAdded.emit(addedObj.MessagePatterns[0]);
        this.getFocusableMessageInput();
    };
    //auto focus
    MessageEditComponent.prototype.getFocusableMessageInput = function () {
        this.focusableMessageInput = this.promotionHelperService.getFocusableMessageInput();
    };
    MessageEditComponent.prototype.prepareSaveModel = function (promotionSaveModel) {
        promotionSaveModel.PatternGroups = this.PatternGroups.value;
        promotionSaveModel.PatternGroups.forEach(function (patternGroup) {
            //update ordinal by index
            patternGroup.Markets.forEach(function (market, index) { return market.Ordinal = index + 1; });
        });
    };
    MessageEditComponent.prototype.saveCustomData = function (saveModel) {
        this.promotionHelperService.patchMessageIdsAfterSave(saveModel);
        var promises = [];
        this.customDataFieldContainers.forEach(function (container) {
            var msgId = container.parent.value.PromotionMessageId;
            promises.push(container.save(msgId));
        });
        return promises;
    };
    return MessageEditComponent;
}());
export { MessageEditComponent };
