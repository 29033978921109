<div *ngIf="LocationItemData" class="item-display-card border pb-0 pt-3 px-3" [ngClass]="{'text-danger border border-danger': isCurrentItemInvalid(LocationItemData.Item.Id)}">
    <h2 class='d-flex mt-0'>
        <span innerHtml="{{LocationItemData.Item.Description | highlight : Highlight}}"></span>
        <!-- <span>{{LocationItemData.Item.Description}}</span> -->
        <i *ngIf='DisplayMode == "cart"' class="fa fa-times" aria-hidden="true"
           (click)="removeFromCart()"></i>
    </h2>
    <div class="form-row">
        <!--Image must always be first-->
        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <div class="mb-3">
                <ips-image-view *ngIf="Image" [imgThumbUrl]="imageUrl"
                                [imgFullUrl]="imageUrl"></ips-image-view>
            </div>
            <!-- Changes to the item-control section should be duplicated below -->
            <div class="item-controls d-none d-md-block mb-3">
                <div class="form-group" *ngIf="showOrderReason">
                    <label class="required" translate>ORDER_REASON</label>
                    <select class="form-control form-control-lg"
                            [(ngModel)]="LocationItemData.OrderReason"
                            (change)="itemDetailsChanged()" [disabled]="orderReasonDisabled">
                        <option *ngIf="LocationItemData.OrderReason == ''" value="">
                            {{ 'SELECT_ORDER_REASON' | translate }}
                        </option>
                        <option *ngFor="let reason of OrderReasons" [value]="reason">
                            {{reason}}
                        </option>
                    </select>
                </div>
                <div class="form-group" *ngIf="showOrderQuantity">
                    <div *ngIf="hasOrderQuantities">
                        <label class="required" translate>ORDER_QUANTITY</label>
                        <select class="form-control form-control-lg"
                                [id]="'item-display-card-2-' + LocationItemData.Id  + '-' + LocationItemData.Item.Id"
                                appSelectOnClick [(ngModel)]="LocationItemData.Qty"
                                [disabled]="LocationItemData.Item.DirectMail"
                                (change)="itemDetailsChanged()">
                            <option *ngFor="let option of getQuantityOptions()" [value]="option.Quantity">{{option.Quantity}}</option>
                        </select>
                    </div>
                    <div *ngIf="!hasOrderQuantities">
                        <label class="required" translate>ORDER_QUANTITY</label>
                        <input type="number" class="form-control form-control-lg" min=1
                               [id]="'item-display-card-2-' + LocationItemData.Id  + '-' + LocationItemData.Item.Id"
                               appSelectOnClick [(ngModel)]="LocationItemData.Qty"
                               [disabled]="LocationItemData.Item.DirectMail"
                               (change)="itemDetailsChanged()">
                    </div>
                </div>
                <div *ngIf="DisplayMode == 'shop'" class="row no-gutters">
                    <div class="col-12" *ngIf="availableForDownload">
                        <button class="btn btn-lg btn-default btn-block px-xl-1" (click)="downloadClicked()" translate>
                            <span class="fa fa-download mr-1"></span>DOWNLOAD
                        </button>
                    </div>
                    <div class="col-12" *ngIf="!availableForDownload && !isRental">
                        <button class="btn btn-lg btn-default btn-block px-xl-1"
                                (click)="addToCart()" *ngIf="!isVariablePOD"
                                [disabled]="!canAddToCart" translate>
                            <span class="fa fa-cart-arrow-down fa-button-icon"></span> ADD_TO_CART
                        </button>
                        <button *ngIf="isVariablePOD && this.NumberInCart === 0"
                                class="btn btn-lg btn-default btn-block px-xl-1"
                                [disabled]="!canAddToCart" (click)="customize()" translate>
                            <span class="fa fa-magic fa-button-icon text-muted"></span> CUSTOMIZE
                        </button>
                        <div *ngIf="NumberInCart > 0" class="col-12 pt-2 text-center">
                            <span class="badge-pill badge-success font-weight-normal">
                                {{NumberInCart}}
                            </span>
                            <span class="ml-1" translate>IN_CART</span>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="!availableForDownload && isRental">
                        <button class="btn btn-lg btn-default btn-block px-xl-1"
                                (click)="addToCart()" [disabled]="!canAddToCart">
                            <span class="fa fa-cart-arrow-down fa-button-icon"></span> Request Follow-Up
                        </button>
                        <div *ngIf="NumberInCart > 0" class="col-12 pt-2 text-center">
                            <span class="badge-pill badge-success font-weight-normal">
                                <span class="fa fa-check text-white"></span>
                            </span>
                            <span class="ml-1">Check out to request</span>
                        </div>
                    </div>
                    <!-- <div class="col-6">
                        <div class="pl-1">
                            <button class="btn btn-lg btn-default btn-block px-xl-1" (click)="toggleFavorite()"
                                    [ngClass]="{'active': IsFavorite }" translate>
                                <span class="fa fa-star-o"></span> FAVORITE
                            </button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-8 col-xl-9">
            <div class="form-row">
                <div *ngFor="let card of Characteristics"
                     class="col-12 col-lg-6 col-xl-4 mb-3 d-flex align-content-stretch">
                    <div class="card ips-search-card w-100">
                        <a class="h-100 no-underline">
                            <div *ngIf="card.Key!=='Category'" class="card-body">
                                <span class="view-info-label d-inline-block pb-0">{{card.Key | translate}}</span>
                                <div class="mt-1">
                                    <span [ngClass]="{'text-nowrap': card.IsDate}"
                                          innerHtml="{{card.Value | highlight : Highlight}}"></span>
                                </div>
                            </div>
                            <div *ngIf="card.Key==='Category'" class="card-body">
                                <span class="view-info-label d-inline-block pb-0">{{card.Key | translate}}</span>
                                <div class="mt-1" *ngFor="let subCategory of card.Value; let index=index">
                                    <span [ngClass]="{'text-nowrap': card.IsDate}"
                                          innerHtml="{{subCategory | highlight : Highlight}}"></span>
                                    <hr *ngIf="card.Value.length !== 1 && card.Value.length !== index+1" />
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Changes to the item-control section should be duplicated above -->
        <div class="item-controls d-md-none mb-3 col-12">
            <div class="form-group" *ngIf="showOrderReason">
                <label class="required" translate>ORDER_REASON</label>
                <select class="form-control form-control-lg"
                        [(ngModel)]="LocationItemData.OrderReason"
                        (change)="itemDetailsChanged()" [disabled]="orderReasonDisabled">
                    <option *ngIf="LocationItemData.OrderReason == ''" value="">
                        {{ 'SELECT_ORDER_REASON' | translate }}
                    </option>
                    <option *ngFor="let reason of OrderReasons" [value]="reason">
                        {{reason}}
                    </option>
                </select>
            </div>
            <div class="form-group" *ngIf="showOrderQuantity">
                <div *ngIf="hasOrderQuantities">
                    <label class="required" translate>ORDER_QUANTITY</label>
                    <select class="form-control form-control-lg"
                            [id]="'item-display-card-2-' + LocationItemData.Id  + '-' + LocationItemData.Item.Id"
                            appSelectOnClick [(ngModel)]="LocationItemData.Qty"
                            [disabled]="LocationItemData.Item.DirectMail"
                            (change)="itemDetailsChanged()">
                        <option *ngFor="let option of getQuantityOptions()" [value]="option.Quantity">{{option.Quantity}}</option>
                    </select>
                </div>
                <div *ngIf="!hasOrderQuantities">
                    <label class="required" translate>ORDER_QUANTITY</label>
                    <input type="number" class="form-control form-control-lg" min=1
                           [id]="'item-display-card-2-' + LocationItemData.Id  + '-' + LocationItemData.Item.Id"
                           appSelectOnClick [(ngModel)]="LocationItemData.Qty"
                           [disabled]="LocationItemData.Item.DirectMail"
                           (change)="itemDetailsChanged()">
                </div>
            </div>
            <div *ngIf="DisplayMode === 'shop'" class="row no-gutters">
                <div class="col-12" *ngIf="availableForDownload">
                    <button class="btn btn-lg btn-default btn-block px-xl-1" (click)="downloadClicked()" translate>
                        <span class="fa fa-download mr-1"></span>DOWNLOAD
                    </button>
                </div>
                <div class="col-12" *ngIf="!availableForDownload">
                    <button class="btn btn-lg btn-default btn-block px-xl-1"
                            (click)="addToCart()" *ngIf="!isVariablePOD"
                            [disabled]="!canAddToCart" translate>
                        <span class="fa fa-cart-arrow-down fa-button-icon"></span> ADD_TO_CART
                    </button>
                    <button *ngIf="isVariablePOD && this.NumberInCart === 0"
                            class="btn btn-lg btn-default btn-block px-xl-1"
                            [disabled]="!canAddToCart" (click)="customize()" translate>
                        <span class="fa fa-magic fa-button-icon text-muted"></span> CUSTOMIZE
                    </button>
                    <div *ngIf="NumberInCart > 0" class="col-12 pt-2 text-center">
                        <span class="badge-pill badge-success font-weight-normal">
                            {{NumberInCart}}
                        </span>
                        <span class="ml-1" translate>IN_CART</span>
                    </div>
                </div>
                <!-- <div class="col-6">
                    <div class="pl-1">
                        <button class="btn btn-lg btn-default btn-block px-xl-1" (click)="toggleFavorite()"
                                [ngClass]="{'active': IsFavorite }" translate>
                            <span class="fa fa-star-o"></span> FAVORITE
                        </button>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
