import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IpsBaseWebService, ActiveProfileService, SearchInfo } from "imagine-ui-ng-core";
import { environment } from "../../../../environments/environment";
import { FixtureModel } from "../../../imagine-ui-ng-store-profile";

@Injectable()
export class FixtureByFixtureGroupService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Fixture", "Profile", "v1");
    }

    headByFixtureGroup(id: string): Promise<number> {
        return this.headByRoute(`${this.urlApiBaseProductPlusVersion}FixtureType/${id}/Fixture`);
    }

    // Override the ipsBaseWebService.search method.
    search<T>(searchInfo: SearchInfo, route: string): Promise<T> {
        return this.searchByRelation(searchInfo, `FixtureType/${searchInfo.id}/Fixture/Search`);
    }
}
