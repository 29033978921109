import { Component, OnInit, ViewChild, Renderer2, TemplateRef } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, AbstractControl } from "@angular/forms";
import { DecimalPipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService, QueryParamItem, ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { PageTitleService, IpsImageEditComponent } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { AuthService } from "imagine-ui-ng-security";

import { String as IpsString, StringBuilder } from "typescript-string-operations";

import { FixtureService, SpaceService, HolderVersionService, FixtureGroupService, LocationByFixtureService, SpaceModel, FixtureModel, HolderVersionInfoModel, FixtureGroupModel, HolderModel } from "../../index";
import { SignPlanHolderModel } from "../../model/SignPlanModel";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";
import { IpsModalInstance, IpsModalService } from "imagine-ui-ng-modal";
import { SignPlanService } from "../../../shared/service/sign-plan.service";

@Component({
    selector: "app-fixture-edit",
    templateUrl: "./fixture-edit.component.html",
    styleUrls: ["./fixture-edit.component.scss"],
    providers: [DecimalPipe]
})
export class FixtureEditComponent implements OnInit {
    @ViewChild("primaryImage") private imageEdit: IpsImageEditComponent;
    @ViewChild("layoutImage") private layoutImageEdit: IpsImageEditComponent;
    @ViewChild("additionalImage") private additionalImageEdit: IpsImageEditComponent;

    @ViewChild("deleteTemplate") deleteTemplate: TemplateRef<any>;
    @ViewChild("editTemplate") editTemplate: TemplateRef<any>;

    private showStats = false;
    public breadCrumbLabel: string;
    public spaceList: SpaceModel[];
    public versionList: HolderVersionInfoModel[];
    public fixture: FixtureModel;
    public fgList: FixtureGroupModel[];
    public loaded: boolean;
    public files: any;
    public layoutFiles: any;
    public additionalImageFiles: any;
    public promise: Promise<any>;
    public fullSizeImage: string;
    public defaultImage: string;

    public numericValueRequiredMessage: string;
    public tooManyDecimalPlacesMessage: string;
    public decimalNumberOnly: string;
    public fixtureDeleteLabel: string;
    public fixtureDeleteManyLabel: string;
    public promptDeleteBodyLabel: string;
    public requiredFixtureName: string;
    public requiredQuantity: string;
    public requiredWidth: string;
    public requiredHeight: string;
    public requiredSpace: string;
    public requiredFixtureGroup: string;
    public requiredMessage: string;
    public textMaxLength: string;

    public fixtureForm: FormGroup;
    public fixtureName: FormControl;
    public fixtureMakeModel: FormControl;
    public offerId: FormControl;
    public notes: FormControl;
    public isObsolete: FormControl;
    public spaceSelect: FormControl;
    public versionSelect: FormControl;
    public fixtureGroupSelect: FormControl;
    public showVeracoreOfferId = false;
    public originalFixture: FixtureModel;
    public hasShownWarning = false;
    private placedHolders: SignPlanHolderModel[];
    private originalFixtureGroupId: number;

    private id: number;
    private scaleFactor: number;

    public enableSignPlanLayout = false;

    private originalWidth: number;
    private originalHeight: number;

    private renderedWidth: number;
    private renderedHeight: number;

    private authToken: string;
    private businessId: string;

    private emptyGuid = "00000000-0000-0000-0000-000000000000";

    private attachedDocuments: any[] = [];
    protected modalInstance: IpsModalInstance;

    private signPlanWidthOptions: any[] = [];

    private editing = false;
    private editTarget: any;
    private deleting = false;
    private deletionTarget: any;

    private processing = false;
    private processingError: string;

    private errorMessages = {
        "required": () => this.requiredMessage,
        "pattern": () => this.numericValueRequiredMessage,
        "maxlength": (params) => IpsString.Format(this.textMaxLength, params.requiredLength),
        "numericValueRequiredMessage": () => this.numericValueRequiredMessage,
        "tooManyDecimalPlacesMessage": () => this.tooManyDecimalPlacesMessage,
        "decimalNumberOnly": () => this.decimalNumberOnly
    };
    public canDeleteErrorList = [];

    constructor(private transition: Transition,
        private $state: StateService,
        private translateService: TranslateService,
        private fixtureService: FixtureService,
        private ipsMessage: IpsMessageService,
        private spaceService: SpaceService,
        private holderVersionService: HolderVersionService,
        private fixtureGroupService: FixtureGroupService,
        private pageTitleService: PageTitleService,
        private locationByFixtureService: LocationByFixtureService,
        private numberPipe: DecimalPipe,
        private activeProfileService: ActiveProfileService,
        private authService: AuthService,
        private settingsService: GeneralSettingsService,
        private formBuilder: FormBuilder,
        private renderer: Renderer2,
        private modalService: IpsModalService,
        private signPlanService: SignPlanService) {
        this.placedHolders = [];
        this.signPlanWidthOptions = this.signPlanService.SignPlanWidthOptions;
    }

    ngOnInit() {
        this.authToken = this.authService.getIdToken();
        this.businessId = this.activeProfileService.businessIdentity;

        let fixtureId = Number(this.transition.params().id);
        this.id = fixtureId || 0;
        let pageTitle = fixtureId > 0 ? "EDIT_FIXTURE" : "CREATE_FIXTURE";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);
        this.spaceList = null;
        this.versionList = null;
        this.fixture = null;
        this.fgList = null;
        this.loaded = false;
        this.files = null;

        let promises: Promise<any>[] = [];

        this.createForm();

        this.getSignPlanLayoutSetting().finally(() => {
            //If we got an ID to load, load it.
            if (Number(fixtureId) > 0) {
                //Initial call to populate screen on load
                promises.push(this.getfixture(fixtureId));
            } else {
                this.hasShownWarning = true;
            }

            //Populate the select inputs
            promises.push(this.getSpaceList());
            promises.push(this.getAttachedDocuments());
            promises.push(this.getFixtureGroupList());
            promises.push(this.getVersionList());


            let canDelete = this.fixtureService.deleteCheck(this.id.toString())
                .then((response: any) => {
                    this.canDeleteErrorList = response;
                })
                .catch((response: any) => {
                    this.canDeleteErrorList = ["-ERROR-"];
                });

            promises.push(canDelete);


            this.promise = Promise.all(promises)
                .then((data: any) => {
                    if (Number(fixtureId) > 0) {
                        this.populateFormHolders(this.fixture.Holders);
                    }
                    this.loaded = true;
                });

            this.defaultImage = this.fixtureService.getDefaultImage("LocationFixture");
            this.imageEdit.thumbnail = this.defaultImage;
            this.translateText();
            this.translateService.onLangChange.subscribe(() => this.translateText());
        });

        //show veracore offer id only for Southeasten grocers = Bsns.00000144
        this.showVeracoreOfferId = this.activeProfileService.businessIdentity === "Bsns.00000144";
    }

    getWidthLabel(value: number): string {
        return this.signPlanWidthOptions.find(option => option.value === value).key;
    }

    onDragEnd(event: any, holder: any) {
        holder.Left = event.position.x;
        holder.Top = event.position.y;
        this.fixtureForm.markAsDirty();
    }

    onResizeEnd(event: any, holder: any) {
        holder.Height = event.size.height;
        holder.Width = event.size.width;
        this.fixtureForm.markAsDirty();
    }

    public onSignPlanTransformation(e: any, holderDetail: FormGroup) {
        const value = {
            cropTop: e.top,
            cropLeft: e.left,
            cropHeight: e.height,
            cropWidth: e.width,
            cropRotation: e.rotation
        };

        holderDetail.patchValue(value);
        holderDetail.markAsDirty();
    }

    attachmentUrl(document: any): string {
        return this.fixtureService.getAttachmentMediaUrl(document.MasterMediaId);
    }

    private getAttachedDocuments(): Promise<any> {
        return this.fixtureService.getAttachedDocuments(this.id).then((response) => {
            this.attachedDocuments = response;
        });
    }

    deleteAttachment(document: any): Promise<any> {
        return this.ipsMessage.waitForWork({
            body: "Deleting Attachment",
            workFunction: () => this.deleteAttachmentWorker(document),
            progressMessage: "Deleting..."
        }, null)
            .then((result: boolean) => {
                if (result) {
                    this.getAttachedDocuments();
                }
            });
    }

    deleteAttachmentWorker(document: any): Promise<any> {
        return this.fixtureService.deleteAttachment(document.Id);
    }

    promptEdit(document: any) {
        this.editing = false;

        if (document) {
            this.editTarget = document;
            this.editing = true;
        } else {
            this.editTarget = {
                Id: 0,
                LinkId: this.id,
                LinkType: 3, // Fixture
                LinkSubType: 0,
                SubmittedBy: "",
                SubmittedDateTime: new Date(),
                MasterMediaId: this.emptyGuid,
                MediaType: 0,
                Label: "",
                Label2: "",
                Width: 0
            };
        }

        this.modalInstance = this.modalService.displayComponentAndReturnInstance(this.editTemplate, null, { size: "lg", backdrop: "static" });
    }

    cancelEdit() {
        this.modalInstance.close();
        this.editing = false;
        this.editTarget = null;
    }

    onSaveSuccess() {
        this.getAttachedDocuments();
        this.processing = false;

        this.modalInstance.close();

        this.processingError = null;
    }

    onProcessingError(message: string) {
        this.processingError = message;
        this.processing = false;
    }

    performSave(model: any) {
        this.fixtureService.addAttachment(model, this.additionalImageFiles).then((response) => {
            this.onSaveSuccess();
        }).catch((response) => {
            this.onProcessingError(response.Message || response.statusText);
        });
    }

    performDelete(model: any) {
        this.fixtureService.deleteAttachment(model.Id).then((response) => {
            this.onDeleteSuccess();
        }).catch((response) => {
            this.onProcessingError(response.Message);
        });
    }

    save() {
        this.processing = true;
        this.processingError = null;

        if (this.editTarget.Label === null || this.editTarget.Label === "") {
            this.processingError = "Missing Required Label.";
            this.processing = false;
            return;
        }

        if (this.editTarget.Label2 === null || this.editTarget.Label2 === "") {
            this.processingError = "Missing Required Label 2.";
            this.processing = false;
            return;
        }

        if (this.editTarget.Width === 0 ) {
            this.processingError = "Missing Required Width.";
            this.processing = false;
            return;
        }

        if (this.additionalImageFiles === undefined) {
            this.processingError = "Missing Required Image.";
            this.processing = false;
            return;
        }
        this.performSave(this.editTarget);
    }

    promptDelete(document: any) {
        this.deletionTarget = document;

        if (this.deletionTarget) {
            this.modalInstance = this.modalService.displayComponentAndReturnInstance(this.deleteTemplate, null, { backdrop: "static" });
        }
    }

    cancelDelete() {
        this.modalInstance.close();
    }

    onDeleteSuccess() {
        this.processing = false;
        this.getAttachedDocuments();

        this.modalInstance.close();
        this.deletionTarget = null;
    }

    delete() {
        this.processing = true;
        this.processingError = null;

        this.performDelete(this.deletionTarget);
    }

    private getSignPlanLayoutSetting(): Promise<any> {
        return this.settingsService.canEditSignPlanLayout().then((response: boolean) => {
            this.enableSignPlanLayout = response;
        });
    }

    private translateText() {
        this.translateService.get(["ENTER_DECIMAL_NUMBER", "TOO_MANY_DECIMAL_PLACES", "PLEASE_ENTER_NUMBER", "FIXTURE_DELETE", "FIXTURE_DELETE_MANY", "PROMPT_DELETE_BODY",
            "MAX_LENGTH_ERROR", "PLEASE_ENTER_NAME", "PLEASE_ENTER_WIDTH", "PLEASE_ENTER_HEIGHT", "PLEASE_ENTER_QUANTITY", "FIELD_REQUIRED", "PLEASE_SELECT_SPACE",
            "PLEASE_SELECT_FIXTURE_GROUP"]).subscribe((res: [string]) => {
                this.numericValueRequiredMessage = res["PLEASE_ENTER_NUMBER"];
                this.tooManyDecimalPlacesMessage = res["TOO_MANY_DECIMAL_PLACES"];
                this.decimalNumberOnly = res["ENTER_DECIMAL_NUMBER"];
                this.fixtureDeleteLabel = res["FIXTURE_DELETE"];
                this.fixtureDeleteManyLabel = res["FIXTURE_DELETE_MANY"];
                this.promptDeleteBodyLabel = res["PROMPT_DELETE_BODY"];
                this.textMaxLength = res["MAX_LENGTH_ERROR"];
                this.requiredFixtureName = res["PLEASE_ENTER_NAME"];
                this.requiredWidth = res["PLEASE_ENTER_WIDTH"];
                this.requiredHeight = res["PLEASE_ENTER_HEIGHT"];
                this.requiredQuantity = res["PLEASE_ENTER_QUANTITY"];
                this.requiredSpace = res["PLEASE_SELECT_SPACE"];
                this.requiredFixtureGroup = res["PLEASE_SELECT_FIXTURE_GROUP"];
                this.requiredMessage = res["FIELD_REQUIRED"];
            });
    }

    public getErrorMessage(key?: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "name":
                    msgs["required"] = () => this.requiredFixtureName;
                    break;
                case "width":
                    msgs["required"] = () => this.requiredWidth;
                    break;
                case "height":
                    msgs["required"] = () => this.requiredHeight;
                    break;
                case "quantity":
                    msgs["required"] = () => this.numericValueRequiredMessage;
                    break;
                case "space":
                    msgs["required"] = () => this.requiredSpace;
                    break;
                case "fixturegroup":
                    msgs["required"] = () => this.requiredFixtureGroup;
                    break;
                default:
            }
        }

        return msgs;
    }

    private createForm() {
        this.fixtureName = this.formBuilder.control("", [Validators.required, Validators.maxLength(50)]);
        this.fixtureMakeModel = this.formBuilder.control("", Validators.maxLength(345));
        this.notes = this.formBuilder.control("", Validators.maxLength(500));
        this.offerId = this.formBuilder.control("", Validators.maxLength(50));
        // Initialize to null in order to make IE default to empty selection.
        this.spaceSelect = this.formBuilder.control(null, Validators.required);
        this.versionSelect = this.formBuilder.control(null);
        this.fixtureGroupSelect = this.formBuilder.control(null, Validators.required);
        this.isObsolete =  this.formBuilder.control(false);

        this.fixtureForm = this.formBuilder.group({
            id: [0],
            businessIdentity: [this.activeProfileService.businessIdentity],
            fixtureName: this.fixtureName,
            fixtureMakeModel: this.fixtureMakeModel,
            offerId: this.offerId,
            notes: this.notes,
            isObsolete: this.isObsolete,
            spaceSelect: this.spaceSelect,
            versionSelect: this.versionSelect,
            fixtureGroupSelect: this.fixtureGroupSelect,
            holders: this.formBuilder.array([]),
            masterMediaId: this.formBuilder.control(this.emptyGuid),
            mediaType: this.formBuilder.control(0),
            layoutImageMasterMediaId: this.formBuilder.control(this.emptyGuid),
            layoutImageMediaType: this.formBuilder.control(0),
            signPlanWidth: this.formBuilder.control(this.signPlanWidthOptions[0].value)
        });
    }

    private getfixture(id: number) {
        return this.fixtureService.getWithUnassignedHolders(id)
            .then((fixture: FixtureModel) => {

                //Store off the original values
                this.originalFixture = Object.assign({}, fixture);

                this.populateThumbnail(fixture);
                this.populateLayoutThumbnail(fixture);

                this.fixture = fixture;
                this.fixtureForm.patchValue({
                    id: fixture.Id,
                    businessIdentity: fixture.BusinessIdentity,
                    fixtureGroupId: fixture.FixtureGroupId,
                    fixtureName: fixture.Name,
                    isObsolete: fixture.IsObsolete,
                    offerId: fixture.VeracoreOfferId,
                    fixtureMakeModel: fixture.Model,
                    notes: fixture.Notes,
                    masterMediaId: fixture.MasterMediaId,
                    mediaType: fixture.MediaType,
                    layoutImageMasterMediaId: fixture.LayoutImageMasterMediaId,
                    layoutImageMediaType: fixture.LayoutImageMediaType,
                    spaceSelect: { Id: fixture.SpaceId, Name: fixture.SpaceName, Notes: "", BusinessIdentity: fixture.BusinessIdentity },
                    fixtureGroupSelect: { Id: fixture.FixtureGroupId, Name: fixture.FixtureGroupName, Notes: "", BusinessIdentity: fixture.BusinessIdentity, HasFixtureReference: true, Holders: [] },
                    signPlanWidth: fixture.SignPlanWidth
                });
            }).then(() => {
                if (this.enableSignPlanLayout) {
                    this.fixtureService.getLayoutDetails(id).then((response) => {
                        this.populateSignPlanData(response);
                    });
                }
            });
    }



    private populateFormHolders(holderList: HolderModel[]) {
        const holders = holderList.map(holder => {
            let fg = this.formBuilder.group({
                id: [holder.Id],
                fixtureId: [holder.FixtureId],
                businessIdentity: [holder.BusinessIdentity],
                width: [holder.Size.Width, this.numberRequired],
                height: [holder.Size.Height, this.numberRequired],
                unit: [holder.Size.Unit],
                quantity: [holder.Quantity, [Validators.required, Validators.pattern("^(0|[1-9][0-9]*)$")]],
                holderName: [holder.Name],
                versionSelect: holder.HolderVersionInfoId ? [this.getSelectedHolderVersion(holder.HolderVersionInfoId)] : "",
                isObsolete: [holder.IsObsolete],
                cropTop: holder.CropTop,
                cropLeft: holder.CropLeft,
                cropHeight: holder.CropHeight,
                cropWidth: holder.CropWidth,
                cropRotation: holder.CropRotation,
                categoryCode: holder.CategoryCode

            });
            return fg;
        });
        this.fixtureForm.setControl("holders", this.formBuilder.array(holders));
    }

    onImageUploaded(file: any) {
        if (file) {

        } else {
            this.resetAll();
        }
    }

    populateSignPlanData(holderLayoutData: any[]) {
        if (holderLayoutData && holderLayoutData.length > 0) {
            this.placedHolders = holderLayoutData.map((item) => {
                item.Placed = true;
                return item;
            });

            this.originalFixtureGroupId = holderLayoutData[0].FixtureGroupId;
            this.mergeSignHolderData(this.fixture.Holders);
        } else {
            this.placedHolders = [];
            this.originalFixtureGroupId = this.fixtureForm.value.fixtureGroupId;
            this.mergeSignHolderData(this.fixture.Holders);
        }
    }

    allHoldersPlaced(): boolean {
        return this.placedHolders.find((item) => !item.Placed) == null;
    }

    resetAll() {
        this.placedHolders.forEach((holder) => {
            this.resetPlacement(holder);
        });
    }

    resetPlacement(holder: any) {
        holder.Placed = false;
        holder.Height = 0;
        holder.Width = 0;
        holder.Left = 0;
        holder.Top = 0;
    }

    private getImgSize(imgSrc): Promise<any> {
        let newImg = new Image();
        let promise = new Promise<any>((resolve, reject) => {
            newImg.onload = function () {
                let height = newImg.height;
                let width = newImg.width;
                resolve({
                    height: height,
                    width: width
                });
            };

            newImg.src = imgSrc; // this must be done AFTER setting onload
        });

        return promise;
    }

    private populateSignHolders() {
        let hasFixtureGroupChanged = this.fixtureForm.value.fixtureGroupSelect.Id !== this.originalFixtureGroupId;

        if (hasFixtureGroupChanged) {
            this.placedHolders = [];

            this.fixtureForm.value.holders.forEach((item) => {
                for (let index = 0; index < item.quantity; index++) {
                    this.placedHolders.push({
                        Id: 0,
                        HolderName: item.holderName,
                        Index: index,
                        FixtureId: item.fixtureId,
                        HolderId: item.id,
                        Left: 0,
                        Top: 0,
                        Height: 0,
                        Width: 0,
                        Placed: false,
                        Rotation: 0,
                        BackgroundColor: "#790123"
                    });
                }
            });
        }
    }

    // called when initially loading data.  merges placed holder layout data with any unplaced holder layouts (derived from holder data)
    private mergeSignHolderData(holders: any[]) {
        holders.forEach((item) => {
            for (let index = 0; index < item.Quantity; index++) {

                let existingHolder = this.placedHolders.find(holder => holder.HolderId === item.Id && holder.Index === index);

                if (!existingHolder) {
                    this.placedHolders.push({
                        Id: 0,
                        HolderName: item.Name,
                        Index: index,
                        FixtureId: item.FixtureId,
                        HolderId: item.Id,
                        Left: 0,
                        Top: 0,
                        Height: 0,
                        Width: 0,
                        Placed: false,
                        Rotation: 0,
                        BackgroundColor: "#790123"
                    });
                }
            }
        });
    }

    holderQuantityChanged(id: any, item: FormGroup) {
        this.placedHolders = [];
        this.populateSignHolders();

        const heightField = item.get("height");
        const widthField = item.get("width");

        heightField.markAsTouched();
        widthField.markAsTouched();

        heightField.updateValueAndValidity();
        widthField.updateValueAndValidity();
    }

    private getSelectedHolderVersion(id: number) {
        let v = this.versionList.filter(x => x.Id === id);
        return v[0];
    }

    private populateThumbnail(fixture: FixtureModel) {
        this.fullSizeImage = `${fixture.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
        this.imageEdit.thumbnail = this.fullSizeImage.replace("Original", "Thumbnail");

        //Set text of image button
        this.imageEdit.selectedImage = this.imageEdit.thumbnail.indexOf("/PlaceHolder/") === -1;
    }

    private getImageRatio(): any {
        if (this.enableSignPlanLayout && this.layoutImageEdit) {
            this.originalWidth = (this.layoutImageEdit.fileImage.nativeElement as HTMLImageElement).naturalWidth;
            this.originalHeight = (this.layoutImageEdit.fileImage.nativeElement as HTMLImageElement).naturalHeight;

            this.renderedWidth = (this.layoutImageEdit.fileImage.nativeElement as HTMLImageElement).offsetWidth;
            this.renderedHeight = (this.layoutImageEdit.fileImage.nativeElement as HTMLImageElement).offsetHeight;

            return {
                x: this.renderedWidth / this.originalWidth,
                y: this.renderedHeight / this.originalHeight
            };
        } else {
            return {
                x: 1,
                y: 1
            };
        }
    }

    private populateLayoutThumbnail(fixture: FixtureModel) {
        if (this.enableSignPlanLayout && this.layoutImageEdit) {
            this.fullSizeImage = `${fixture.LayoutMedia.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
            this.layoutImageEdit.thumbnail = this.fullSizeImage;

            //Set text of image button
            this.layoutImageEdit.selectedImage = this.layoutImageEdit.thumbnail.indexOf("/PlaceHolder/") === -1;
        }
    }

    private getSpaceList() {
        return this.spaceService.getList<SpaceModel[]>()
            .then((response) => {
                this.spaceList = response;
            });
    }

    private getVersionList() {
        return this.holderVersionService.getList<HolderVersionInfoModel[]>()
            .then((response) => {
                this.versionList = response;
            });
    }

    private getFixtureGroupList() {
        return this.fixtureGroupService.getList<FixtureGroupModel[]>()
            .then((response) => {
                this.fgList = response;
            });
    }

    private showDeleteMessageBox(totalLocations: number) {
        let messageBody = "";

        //Only add when there are locations attached
        if (totalLocations > 0) {
            messageBody += IpsString.Format(Number(totalLocations) === 1 ? this.fixtureDeleteLabel : this.fixtureDeleteManyLabel,
                this.numberPipe.transform(totalLocations)) + "\n";
        }

        messageBody += IpsString.Format(this.promptDeleteBodyLabel,
            this.fixtureForm.value.fixtureName + (this.fixtureForm.value.fixtureMakeModel ? " - " + this.fixtureForm.value.fixtureMakeModel : ""));

        return this.ipsMessage.confirmDelete({
            body: messageBody,
            workFunction: () => this.deleteFixture(),
            progressMessage: "DELETING"
        }, null).then((result: boolean) => {
            if (result) {
                this.$state.go("main.storeProfileFixture.search");
            }
        })
            .catch(() => {
                // rejection
            });
    }

    public fixtureGroupChange(fixtureGroup: FixtureGroupModel) {
        if (fixtureGroup) {
            this.populateFormHolders(fixtureGroup.Holders);
        }
    }

    public deleteFixturePrompt() {

        if (this.loaded && this.canDeleteErrorList.length > 0) {
            this.ipsMessage.error("Unable to delete! Record is begin used in the following Market Groups:\r\n" + this.canDeleteErrorList.join("\r\n"));
            return null;
        }

        this.locationByFixtureService.headByFixture(this.fixtureForm.value.id, false)
            .then((data: number) => {
                //Show location data
                this.showDeleteMessageBox(data || 0);

            })
            .catch((response: any) => {
                //Show without location data
                this.showDeleteMessageBox(0);
            });

    }

    public numberRequired(control: AbstractControl) {

        //Always check to see that we have a number in the input
        let input = control.value;
        if (control.value === null) {
            return { numericValueRequiredMessage: { valid: false } };
        }

        //Skip validation of number if qty is zero
        if (control.parent && Number(control.parent.controls["quantity"].value) === 0) {
            return null;
        }

        let inputStr = input.toString();
        let decimalPart = inputStr.split(".")[1];
        if (input <= 0) {
            return { numericValueRequiredMessage: { valid: false } };
        } else if (decimalPart && decimalPart.length > 6 || (inputStr.indexOf("1e-") === 0 && Number(inputStr.split("1e-")[1]) >= 7)) {
            return { tooManyDecimalPlacesMessage: { valid: false } };
        }
        return null;
    }

    public saveFixturePrompt(redirect: boolean) {
        //Check to see if user selected at least one holder

        let valid = false;
        let checkHolders = this.fixtureForm.value.holders;

        for (let i = 0; i < checkHolders.length; i++) {
            //Find starting index of name in question
            if (checkHolders[i].quantity > 0) {
                valid = true;
                break;
            }
        }

        if (valid) {
            return this.ipsMessage.waitForWork({
                body: "SAVING",
                workFunction: () => this.saveFixtureData(),
                progressMessage: "SAVING"
            }, null)
                .then((result: boolean) => {
                    if (result) {
                        if (redirect) {
                            this.$state.go("main.storeProfileFixture.search");
                        } else {
                            // Reload the page after a save, its easier
                            this.$state.go("main.storeProfileFixture.edit", { id: this.fixtureForm.value.id });
                        }
                    }
                });
        }

        this.ipsMessage.error("QTY_AT_LEAST_ONE_HOLDER_REQUIRED");
        event.preventDefault();
        return false;
    }

    public onSelectedImageChanged(files: any) {
        this.files = files;
        if (!this.files) {
            // Get the placeholder image and set it
            this.imageEdit.thumbnail = this.defaultImage;
            this.imageEdit.selectedImage = false;
            this.fixtureForm.patchValue({
                masterMediaId: this.emptyGuid,
                mediaType: 0
            });
        }
        this.fixtureForm.markAsDirty();
    }

    public onAdditionalImageChanged(files: any) {
        this.additionalImageFiles = files;
        this.fixtureForm.markAsDirty();
    }

    hasLayoutImage() {
        let returnValue = this.layoutFiles ||
            (this.fixtureForm.value &&
                this.fixtureForm.value.layoutImageMasterMediaId &&
                this.fixtureForm.value.layoutImageMasterMediaId !== "" &&
                this.fixtureForm.value.layoutImageMasterMediaId !== this.emptyGuid);

        return returnValue;
    }

    public onSelectedLayoutImageChanged(files: any) {
        this.layoutFiles = files;
        if (!this.layoutFiles) {
            // Get the placeholder image and set it
            this.layoutImageEdit.thumbnail = this.defaultImage;
            this.layoutImageEdit.selectedImage = false;

            this.fixtureForm.patchValue({
                layoutImageMasterMediaId: this.emptyGuid,
                layoutMediaType: 0
            });

            this.resetAll();
        }
        this.fixtureForm.markAsDirty();
    }

    createRange(total: number) {
        return new Array(total);
    }

    getPlacedHolders() {
        return this.placedHolders.filter(holder => holder.Placed);
    }

    private saveFixtureData(): Promise<any> {
        let returnPromise = new Promise<any>((resolve, reject) => {
            this.saveFixture().then((response) => {
                if (this.enableSignPlanLayout) {
                    this.saveFixtureLayout().then((layoutResponse) => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            }).catch((response: any) => {
                reject(response);
            });
        });

        return returnPromise;
    }

    private placeHolder(holder: any) {
        holder.Placed = true;
        holder.Height = 50;
        holder.Width = 100;
        holder.Left = 0;
        holder.Top = 0;
    }

    private saveFixtureLayout(): Promise<any> {

        let fixture = this.fixtureForm.value;
        let ratio = { x: 1, y: 1 }; // this.getImageRatio();

        let layoutDetails = this.placedHolders.filter(holder => holder.Placed)
            .map(holder => {
                holder.Left = Math.floor(holder.Left / ratio.x);
                holder.Top = Math.floor(holder.Top / ratio.y);
                holder.Height = Math.floor(holder.Height / ratio.y);
                holder.Width = Math.floor(holder.Width / ratio.x);

                return holder;
            });

        let fixtureLayoutModel = {
            FixtureId: fixture.id,
            MasterMediaId: fixture.layoutImageMasterMediaId,
            MediaType: fixture.layoutImageMediaType,
            LayoutDetails: layoutDetails
        };

        return this.fixtureService.putLayoutDetails(fixtureLayoutModel, this.layoutFiles)
            .then((response) => {
                this.fixtureForm.patchValue({
                    layoutImageMediaType: response.MediaType,
                    layoutImageMasterMediaId: response.MasterMediaId
                });

                this.fixtureForm.markAsPristine();
            });
    }

    private saveFixture(): Promise<any> {
        let fixture = this.fixtureForm.value;
        let fixtureModel = {
            Id: fixture.id,
            SpaceId: fixture.spaceSelect.Id,
            SpaceName: fixture.spaceSelect.Name,
            BusinessIdentity: fixture.businessIdentity,
            FixtureGroupId: fixture.fixtureGroupSelect.Id,
            FixtureGroupName: fixture.fixtureGroupSelect.Name,
            Name: fixture.fixtureName,
            Model: fixture.fixtureMakeModel,
            VeracoreOfferId: fixture.offerId,
            Notes: fixture.notes,
            IsObsolete: fixture.isObsolete,
            Holders: fixture.holders.map((holder) => {
                let h = {
                    id: holder.id,
                    name: holder.holderName,
                    fixtureId: fixture.id,
                    businessIdentity: holder.businessIdentity,
                    size: {
                        width: holder.width,
                        height: holder.height,
                        unit: holder.unit
                    },
                    quantity: holder.quantity,
                    isObsolete: holder.isObsolete,
                    holderVersionInfoId: holder.versionSelect != null ? holder.versionSelect.Id : undefined,
                    cropTop: holder.cropTop,
                    cropLeft: holder.cropLeft,
                    cropWidth: holder.cropWidth,
                    cropHeight: holder.cropHeight,
                    cropRotation: holder.cropRotation,
                    categoryCode: holder.categoryCode
                };
                return h;
            }),
            MasterMediaId: fixture.masterMediaId,
            MediaType: fixture.mediaType,
            LayoutImageMasterMediaId: fixture.layoutImageMasterMediaId,
            LayoutImageMediaType: fixture.layoutImageMediaType,
            SignPlanWidth: fixture.signPlanWidth || 0
        } as FixtureModel;


        if (fixture.id) {
            return this.fixtureService.put<FixtureModel>(fixtureModel, this.files)
                .then((response) => {
                    this.fixtureForm.markAsPristine();
                });

        } else {
            return this.fixtureService.post<FixtureModel>(fixtureModel, this.files)
                .then((response) => {
                    this.fixtureForm.markAsPristine();
                    // Just set the Id so we can re-load the state
                    this.fixtureForm.patchValue({
                        id: response.Id
                    });
                });
        }

    }

    private deleteFixture() {
        let id = this.fixtureForm.value.id;

        //TODO: when campaigns exist
        //        check to see if this fixture is tied to a campaign and if so then prevent delete
        return this.fixtureService.delete(id);
    }

    public compareFn(item1: any, item2: any): boolean {
        return item1 && item2 ? item1.Id === item2.Id : item1 === item2;
    }

    public getInputNameLabel(control: any, item: any) {
        return control.get("InputName").value + "_" + item;
    }

    public msgPrompt(fg: FormGroup) {
        if (!this.hasShownWarning) {

            let h = this.originalFixture.Holders.find((f) => f.Id === fg.get("id").value);

            if (h) {
                if (fg.get("width").value !== h.Size.Width || fg.get("height").value !== h.Size.Height) {

                    this.ipsMessage.confirm({
                        title: "WARNING",
                        body: "FIXTURE_SIZE_CHANGE_WARNING",
                        ok: "YES_CHANGE_IT",
                        cancel: "NO_KEEP_IT"
                    }).then(() => {
                        //Value change is fine
                    })
                        .catch(() => {
                            //Reset value
                            fg.get("width").setValue(h.Size.Width);
                            fg.get("height").setValue(h.Size.Height);
                        });
                }
                this.hasShownWarning = true;
            }
        }
    }

    public toggleObsolete(locationGroupControl: FormGroup) {
        let isObsoleteControl = locationGroupControl.get("isObsolete");
        isObsoleteControl.setValue(!isObsoleteControl.value);
        isObsoleteControl.markAsDirty();
    }



}
