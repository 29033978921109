import { Pipe, PipeTransform } from "@angular/core";
import { FixtureLocationSumModel } from "../model/FixtureLocationSumModel";

@Pipe({
  name: "assignedFilter",
  pure: false
})
export class AssignedFilterPipe implements PipeTransform {

    transform(allFixtures: FixtureLocationSumModel[], selectedFixture): any {
        if (!selectedFixture || selectedFixture.Id === 0) {
            return allFixtures;
        } else if (selectedFixture.Id === 1) {
            return allFixtures.filter(f => f.OriginalSum > 0);
        } else {
            return allFixtures.filter(f => f.OriginalSum === 0);
        }
    }

}
