<app-sticky-header>
    <ng-template #stickyItemTemplate let-stickyHeaderItems="stickyHeaderItems">
            <div *ngFor="let stickyHeader of stickyHeaderItems;" class="d-flex flex-column flex-sm-row">
                <ng-container *ngFor="let item of stickyHeader;">
                    <div *ngIf="item.imageClass" class="d-flex align-items-center">
                        <div class="icon mr-2 ml-2 {{item.imageClass}}"></div>
                        <span>{{item.text}}</span>
                    </div>
                    <ng-container *ngIf="!item.imageClass">
                        <div class="d-flex flex-row clip">
                            <div class="flex-grow-1 clip mx-2" appTextOverflow>{{item.text}}</div>
                            <div class="mr-2"><button class="text-left button-no-style tooltip-container clicker" ngbTooltip="{{item.text}}" placement="bottom-right" #t="ngbTooltip" (click)="t.open()"><i class="fa fa-ellipsis-h fa-2x"></i></button></div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
    </ng-template>
</app-sticky-header>

<div class="col-md-12 col-lg-8"  stickyHeader="">
    <div class="row">
        <section class="col-12">
            <h3 class="title">
                <span class="header-label-imgn" translate>MY_SURVEY_QUESTIONS</span>
            </h3>
        </section>
    </div>
    <!--Location and survey displayed-->
    <div class="row">
        <section class="col-12">
            <div class="ips-ls-search-module-imgn">
                <div class="row pt-2">
                    <div class="col-12 col-md-6 form-group mb-md-0">
                        <label translate>LOCATION</label>
                        <div class="input input-lg form-control">{{LocationLabel}}</div>
                    </div>
                    <div class="col-12 col-md-6 form-group mb-1 mb-md-0">
                        <label translate>MY_SURVEY</label>
                        <div class="input input-lg form-control">{{SurveyLabel}}</div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!--Sections displayed below-->
    <form [formGroup]="QuestionsFormGroup" novalidate ipsUnsavedWarningForm role="form">
        <div [cgBusy]="promiseSectionLoad" cgBusyConfig="{message:{{'ACCESSING_INFO' | translate}}}" formArrayName="Sections" attr.stickyHeader="{{LocationLabel}}" stickyHeaderImage="ips-small-icon locationsImg"  attr.stickyHeader2="{{SurveyLabel}}" stickyHeaderImage2="ips-small-icon mySurveyImg">
            <div *ngFor="let section of sections; let i = index"   attr.stickyHeader="{{section.Name}}">
                <ng-container [formGroupName]="i">
                    <app-my-survey-take-section [section]="section" [startingQuestionNumber]="section.StartingQuestionNumber" [locationId]="locationId" [parentForm]="SectionsArray.at(i)" [isCollapsed]="sections.length > 1" [expandSurveyOptions]="expandSurveyOptions" (formDirtyChanged)="checkFormDirty()"></app-my-survey-take-section>
                </ng-container>
            </div>
            <div>
                <div *ngIf="sections">
                    <div class="row" [cgBusy]="promiseNext" cgBusyConfig="{message:{{'ACCESSING_INFO' | translate}}}">
                        <div *ngIf="nextSurvey" class="col-12 col-md-4 col-lg mt-4"><button type="button" class="btn btn-default btn-lg btn-block" (click)="NextSurvey()" translate>NEXT_SURVEY</button></div>
                        <div *ngIf="nextLocation" class="col-12 col-md-4 col-lg mt-4"><button type="button" class="btn btn-default btn-lg btn-block" (click)="NextLocation()" translate>NEXT_LOCATION</button></div>
                        <div class="col-12 col-md-4 col-lg mt-4"><button id="cancelSurvey" class="btn btn-default btn-lg btn-block" uiSref="main.mysurvey.search" type="submit" translate>RETURN_MY_SURVEYS</button></div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
