var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { IpsBaseWebService, ActiveProfileService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { AuthService } from "imagine-ui-ng-security";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "imagine-ui-ng-security";
import * as i3 from "imagine-ui-ng-core";
var BrandSettingService = /** @class */ (function (_super) {
    __extends(BrandSettingService, _super);
    function BrandSettingService(http, authService, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.authService = authService;
        _this.activeProfileService = activeProfileService;
        _this.init("BrandSetting", "Profile", "v1");
        _this.businessId = activeProfileService.businessIdentity;
        _this.token = authService.getIdToken();
        return _this;
    }
    BrandSettingService.prototype.getSettingByName = function (name) {
        var route = "" + this.urlApiBasePlusController + name;
        return this.getByRoute(route);
    };
    //Help Documents
    BrandSettingService.prototype.getHelpDocuments = function () {
        var url = this.urlApiBasePlusController + "HelpDocument";
        return this.getByRoute(url);
    };
    BrandSettingService.prototype.getHelpDocumentMediaUrl = function (mediaId, mediaType) {
        var typeName = "";
        switch (mediaType) {
            case 1:
                typeName = "Jpg";
                break;
            case 2:
                typeName = "Gif";
                break;
            case 3:
                typeName = "Bmp";
                break;
            case 4:
                typeName = "Tif";
                break;
            case 5:
                typeName = "Pdf";
                break;
            case 6:
                typeName = "Csv";
                break;
            case 7:
                typeName = "Png";
                break;
            case 8:
                typeName = "Xlsx";
                break;
            case 9:
                typeName = "Docx";
                break;
            case 10:
                typeName = "Pptx";
                break;
            case 11:
                typeName = "Vsdx";
                break;
            case 12:
                typeName = "Xps";
                break;
            case 13:
                typeName = "Zip";
                break;
            case 14:
                typeName = "Txt";
                break;
            default:
                typeName = "Pdf";
                break;
        }
        var url = this.urlApiBase + "Media/v1/MasterMedia/" + this.businessId + "/HelpDocument/" + mediaId + "/Original/" + typeName + "?BusinessIdentity=" + this.businessId + "&idToken=" + this.token;
        return url;
    };
    BrandSettingService.prototype.deleteHelpDocument = function (id) {
        var url = this.urlApiBasePlusController + "HelpDocument/" + id;
        return this.deleteByRoute(url);
    };
    BrandSettingService.prototype.addHelpDocument = function (model, file) {
        var url = this.urlApiBasePlusController + "HelpDocument";
        return this.post(model, file, url);
    };
    BrandSettingService.ngInjectableDef = i0.defineInjectable({ factory: function BrandSettingService_Factory() { return new BrandSettingService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService), i0.inject(i3.ActiveProfileService)); }, token: BrandSettingService, providedIn: "root" });
    return BrandSettingService;
}(IpsBaseWebService));
export { BrandSettingService };
