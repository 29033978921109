var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";
import { String as IpsString } from "typescript-string-operations";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { FieldControlBaseComponent } from "../field-control-base";
var IpsDateComponent = /** @class */ (function (_super) {
    __extends(IpsDateComponent, _super);
    function IpsDateComponent(translateService, activeProfileService) {
        var _this = _super.call(this, translateService) || this;
        _this.translateService = translateService;
        _this.activeProfileService = activeProfileService;
        _this.calendarTheme = "theme-default";
        _this.TranslateStrings = {
            "PLEASE_ENTER_DYNAMIC_NAME": "",
            "ENTER_DYNAMIC_NAME": "",
            "CALENDAR_DATE_FORMAT": ""
        };
        _this.errorMessages = {
            "required": function () { return IpsString.Format(_this.TranslateStrings["PLEASE_ENTER_DYNAMIC_NAME"], _this.name); },
            "bsDate": function () { return IpsString.Format(_this.TranslateStrings["CALENDAR_DATE_FORMAT"], _this.dateFormat); }
        };
        return _this;
    }
    IpsDateComponent.setControlValue = function (value, fieldGroup) {
        value = IpsDateComponent.convertToDateControlValue(value);
        fieldGroup.patchValue({ Date: value }, { emitEvent: true });
    };
    IpsDateComponent.convertToDateControlValue = function (value) {
        if (value) {
            return value.Date ? new Date(value.Date) : new Date(value);
        }
        return "";
    };
    IpsDateComponent.prototype.ngOnInit = function () {
        var _this = this;
        var localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();
        this.dateOptions = {
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(function () { return _this.TranslateText(); });
        var validators = [];
        if (this.isFieldRequired) {
            validators.push(Validators.required);
        }
        this.control = new FormControl(IpsDateComponent.convertToDateControlValue(this.value), validators);
        this.control.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
            if (_this.group.valid) {
                var val = _this.GetCustomDataValue();
                val.Date = value;
                _this.valueChanged.emit(val);
            }
        });
        this.group = new FormGroup({
            Name: new FormControl(this.name),
            Date: this.control,
            Id: new FormControl(this.value ? this.value.Id || 0 : 0),
            CustomDataId: new FormControl(this.customDataId)
        });
        this.fieldValues.push(this.group);
    };
    return IpsDateComponent;
}(FieldControlBaseComponent));
export { IpsDateComponent };
