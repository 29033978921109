import { Component, OnInit } from "@angular/core";
import { SurveyModel } from "../../model/SurveyModel";
import { Transition, StateService } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { SurveyService, AudienceBalanceListRequestModel } from "../../index";
import { SurveyStatusModel } from "../../model/SurveyStatusModel";
import { SurveySectionModel } from "../../model/SurveySectionModel";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { IpsModalService } from "imagine-ui-ng-modal";


@Component({
    selector: "app-survey-view",
    templateUrl: "./survey-view.component.html",
    styleUrls: ["./survey-view.component.scss"]
})
export class SurveyViewComponent implements OnInit {

    public survey: SurveyModel;
    public surveyStatusInfo: SurveyStatusModel;
    public surveySections: SurveySectionModel[];
    public promise: Promise<void>;
    public promiseSections: Promise<void>;
    public loaded = false;
    private surveyId: number;
    public locationBalance = 0;

    constructor(private translateService: TranslateService,
        private $state: StateService,
        private transition: Transition,
        private surveyService: SurveyService,
        private ipsModal: IpsModalService) { }

    ngOnInit() {
        this.surveyId = Number(this.transition.params().id);
        this.survey = <SurveyModel>{ Id: this.surveyId };
        this.surveySections = [];
        this.promise = this.surveyService.get<SurveyModel>(this.surveyId).then(surveyData => {
            Object.assign(this.survey, surveyData);
            this.surveyStatusInfo = this.surveyService.getStatusInfo(this.survey);
            if (this.survey.Markets) {
                this.locationBalance = this.survey.Markets[this.survey.Markets.length - 1].LocationBalance;
            }
            this.loaded = true;
        });

        this.promiseSections = this.surveyService.getSurveySections(this.surveyId).then(sectionData => {
            Object.assign(this.surveySections, sectionData);
        });

        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() => this.TranslateText());
    }

    private TranslateText() {
        //this.marketedLocations = this.translateService.instant(this.marketedLocations);
        //this.locationGroupLocationsPendingWarning = this.translateService.instant("MARKET_PENDING_LOCATIONS_WARNING");

        //for (let key of Object.keys(this.TranslateStrings)) {
        //    this.TranslateStrings[key] = this.translateService.instant(key);
        //}
    }

    public showLocations(markets): void {

        let locationListRequest: AudienceBalanceListRequestModel = {
            Markets: markets,
            MarketOrdinal: undefined
        };

        this.surveyService.calculateAudienceLocationBalanceList(locationListRequest).then(locationIdList => {
            this.ipsModal.displayTemplateScrollable(SearchModalComponent,
                {
                    resolve: { search: "listbylocation", listOfLocationIds: locationIdList.map(Number) }
                },
                {
                    windowClass: "no-list-group-item-interaction"
                }).then(() => { }, (rejectReason) => { });
        });
    }

}
