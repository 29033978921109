<div>
    <div class="row">
        <div class="col">
            <h1 class="pl-3 mb-0" translate>WELCOME</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h4 class="welcome-banner">{{message}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col" *ngIf="brandBulletin">
            <div class="extraSpace visible-xs-block visible-sm-block"></div>
            <div class="extraSpace"></div>
            <div id="noticeContainer" class="notice-container">
                <div class="notice-label-set"><i class="fa fa-bell notice-icon" aria-hidden="true"></i>{{'NOTICE_ALLCAPS' | translate}}:</div>
                <div class="notice-text">{{brandBulletin.Value}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 landingNavSpace"></div>
        <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.storeProfile">
            <a class="landingNavLink" uiSref="main.storeProfile">
                <div class="profileImg landingNavIcon"></div>
                <h3 translate>PROFILES</h3>
            </a>
        </section>
        <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.campaignLanding">
            <a class="landingNavLink" uiSref="main.campaignLanding">
                <div class="campaignsImg landingNavIcon"></div>
                <h3 translate>CAMPAIGN_BUILDER</h3>
            </a>
        </section>
        <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.myproducts">
            <a class="landingNavLink" uiSref="main.myproducts">
                <div class="myProductsImg landingNavIcon"></div>
                <h3 translate>MY_PRODUCTS</h3>
            </a>
        </section>
        <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.survey.search">
            <a class="landingNavLink" uiSref="main.survey.search">
                <div class="surveyImg landingNavIcon"></div>
                <h3 translate>SURVEYS</h3>
            </a>
        </section>
        <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.mysurvey.search">
            <a class="landingNavLink" uiSref="main.mysurvey.search">
                <div class="mySurveyImg landingNavIcon"></div>
                <h3 translate>MY_SURVEYS</h3>
            </a>
        </section>
        <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.report.search">
            <a class="landingNavLink" uiSref="main.report.search">
                <div class="reportImg landingNavIcon"></div>
                <h3 translate>REPORTS</h3>
            </a>
        </section>
        <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.tools">
            <a class="landingNavLink" uiSref="main.tools">
                <div class="toolsImg landingNavIcon"></div>
                <h3 translate>ADMIN</h3>
            </a>
        </section>
        <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.requestform">
            <a class="landingNavLink" uiSref="main.requestform">
                <div class="reportImg landingNavIcon"></div>
                <h3 translate>REQUEST_FORM</h3>
            </a>
        </section>
    </div>
</div>
