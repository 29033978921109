import { Component, OnInit } from "@angular/core";
import { ActiveProfileService } from "imagine-ui-ng-core";

declare var LiveAgent: any;

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit {

  constructor(
        public activeProfileService: ActiveProfileService) { }

  ngOnInit() {
    let scriptUrl = "https://helpdesk.gfxi.com/scripts/track.js";
    let node = document.createElement("script");
    node.src = scriptUrl;
    node.id = "la_x2s6df8d";
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    node.onload = function(e) {
      LiveAgent.createButton("mskk1t6i", document.getElementById("chatButton"));
    };
    document.getElementsByTagName("head")[0].appendChild(node);

  }


    launchLiveChat() {

      const liveChatWidget: HTMLElement = document.querySelectorAll("[id*='mskk1t6i']")[0] as HTMLElement;
      if (liveChatWidget) {
        liveChatWidget.click();
      }

  }

}
