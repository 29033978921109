import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";

@Injectable()
export class CampaignUpDownAnalyticsService extends IpsBaseWebService {

  constructor(http: HttpClient, public activeProfileService: ActiveProfileService) {
    super(http, <any>environment, activeProfileService);
    this.init("Campaign", "Campaign", "v1");
  }

  public headByUpDownUser(locationId: number, startDate: any, endDate: any) {
    let route;
    if (startDate && endDate) {
      route = `${this.urlApiBaseProductPlusVersion}Campaign/GetUpDownReportUser/${locationId}?startDate=${encodeURIComponent(startDate.toUTCString())}&endDate=${encodeURIComponent(endDate.toUTCString())}`;
    } else {
      route = `${this.urlApiBaseProductPlusVersion}Campaign/GetUpDownReportUser/${locationId}`;
    }
    return this.headByRoute(route);
  }

  public headByAnalyticsCount() {
    let route = `${this.urlApiBaseProductPlusVersion}Campaign/GetUpDownReportAnalytics/Count`;
    return this.headByRoute(route);
  }

}
