<div class="card ips-shadow mt-4">
    <div class="card-body ips-gray-light-bg-color">
        <button class="btn section-header" id="collapse-button" (click)="activeCollapse()" [disabled]="!(parentForm ? parentForm.valid : false)">
            <div class="row ips-search-result-big align-items-center d-flex flex-row no-gutters mb-1">
                <div class="col ips-gray-color long-text-wrap flex-grow-1 text-left">{{section.Name}}</div>
                <div class="ips-gray-color" [ngClass]="['text-right fa fa-chevron-down ips-ls-addIcon', isCollapsed ? '' : 'fa-flip-vertical']"></div>
            </div>
            <div class="row no-gutters">
                <div *ngIf="!isStatusComplete() && !HasPendingChanges()" class="d-flex align-items-center">
                    <div class="d-inline-block pr-2">
                        <div class="img-location-status-planning fa-lg"></div>
                    </div>
                    <div translate>AWAITING_RESPONSE</div>
                </div>
                <div *ngIf="isStatusComplete() && !HasPendingChanges()" class="d-flex align-items-center">
                    <i class="fa fa-check pr-2" aria-hidden="true"></i>
                    <div translate>COMPLETED</div>
                </div>
                <div *ngIf="HasPendingChanges()">
                    <div translate>PENDING</div>
                </div>
            </div>
        </button>
        <!--Content when NOT collapsed-->
        <div [ngbCollapse]="isCollapsed">
            <div *ngIf="loaded">
                <div *ngFor="let question of questions; let i = index"  attr.stickyHeader="{{question.Name}}">
                    <app-my-survey-take-question [question]="question"
                                                 [questionNumber]="startingQuestionNumber + i"
                                                 [locationId]="locationId"
                                                 [surveyId]="section.SurveyId"
                                                 [parentForm]="parentForm"
                                                 [expandSurveyOptions]="expandSurveyOptions"
                                                 (questionConfirmed)="updateStatus()"
                                                 (formDirtyChanged)="checkFormDirty()"></app-my-survey-take-question>
                </div>
            </div>
        </div>
    </div>
</div>
