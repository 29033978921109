import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { ListSearchHelper, SearchInfo, SearchResponse, ActiveProfileService } from "imagine-ui-ng-core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "imagine-ui-ng-security";
import { IpsModalService } from "imagine-ui-ng-modal";

import { SurveyService, SurveyTakeSearchResultModel } from "../../index";
import { LocationService, SimpleSearchLocationModel, LocationModel } from "../../../imagine-ui-ng-store-profile";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";

import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, takeWhile } from "rxjs/operators";

interface MySurveyUIModel extends SurveyTakeSearchResultModel {
    daysToRespond: number;
    PercentCompleteUI: string;
    respondText: string;
}

interface ScrollInfo {
    search: string;
    positionY: number;
    numberToDisplay: number;
    locationId: string;
}



@Component({
  selector: "app-my-survey-search",
  templateUrl: "./my-survey-search.component.html",
  styleUrls: ["./my-survey-search.component.scss"]
})
export class MySurveySearchComponent implements OnInit, OnDestroy {
    public mySurvey: ListSearchHelper;
    public busy = true;
    public promiseLocation: Promise<any>;
    public promiseSurvey: Promise<any>;
    public promiseAll: Promise<any>;
    public loaded = false;
    public query: string;
    public search = "";
    private scrollY: number;
    public searchInfo: SearchInfo;
    public location: SimpleSearchLocationModel;
    private searchScrollKey: string;

    public chunkSize = 20;
    public numberToDisplay = this.chunkSize;
    public displayList: SurveyTakeSearchResultModel[] = [];
    public filteredList: SurveyTakeSearchResultModel[] = [];


    pluralMapping: { [k: string]: string } = {
        "=1": "Survey",
        "other": "Surveys"
    };

    questionPluralMapping: { [k: string]: string } = {
        "=1": "QUESTION",
        "other": "QUESTIONS"
    };

    private TranslateStrings: { [key: string]: string } = {
        "SURVEY": "",
        "SURVEYS": "",
        "QUESTION": "",
        "QUESTIONS": "",
        "DAY": "",
        "DAYS": ""
    };

    constructor(private ipsModal: IpsModalService, private locationService: LocationService, private surveyService: SurveyService, listSearchHelper: ListSearchHelper, private translateService: TranslateService, private activeProfileService: ActiveProfileService, private authService: AuthService) {
        this.mySurvey = listSearchHelper.getListSearchHelper(surveyService);
    }

    ngOnInit() {
        this.location = { Id: 0, LocationIdentifier: "", Name: "", Label: " " }; //Label cannot be blank or it will show [object object] as the text to user
        this.searchScrollKey = "mysurvey_search_scroll_" + this.activeProfileService.businessIdentity;
        let scrollInfo: ScrollInfo = JSON.parse(localStorage.getItem(this.searchScrollKey));

        if (scrollInfo) {
            this.search = scrollInfo.search;
            this.query = scrollInfo.search;

            if (scrollInfo.locationId) {
                this.promiseLocation = this.locationService.getByExternalId(scrollInfo.locationId).then((data: LocationModel) => {
                    this.location = { Id: data.Id, LocationIdentifier: data.LocationIdentifier, Name: data.Name, Label: `${data.LocationIdentifier} - ${data.Name}` };

                    this.busy = this.mySurvey.busy;
                    this.getListData(scrollInfo);


                });
            }
        }

        if (!scrollInfo || !scrollInfo.locationId) {
            this.getDefaultLocation(scrollInfo);
        }

        this.promiseAll = Promise.all([this.promiseLocation, this.promiseSurvey]);

        this.translateService.get("ALL").subscribe(() => this.TranslateText());

    }

    private TranslateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }

        this.pluralMapping = {
            "=1": this.TranslateStrings["SURVEY"],
            "other": this.TranslateStrings["SURVEYS"]
        };

        this.questionPluralMapping = {
            "=1": this.TranslateStrings["QUESTION"],
            "other": this.TranslateStrings["QUESTIONS"]
        };

    }

    private getDefaultLocation(scrollInfo: ScrollInfo) {
        let searchParams: SearchInfo = {
            searchText: "",
            businessIdentity: this.activeProfileService.businessIdentity,
            chunkIndex: 0,
            recordCount: 1
        };
        this.promiseLocation = this.locationService.search(searchParams, "Location/SimpleSearch").then(
            (result: SearchResponse<SimpleSearchLocationModel>) => {
                if (result.ResultList.length > 0) {
                    this.location = result.ResultList[0];
                    this.location.Label = `${this.location.LocationIdentifier} - ${this.location.Name}`;

                    this.busy = this.mySurvey.busy;
                    return this.getListData(scrollInfo);
                }
            });
    }

    public getListData(scrollInfo: ScrollInfo = null) {
        if (this.location && this.location.Id) {
            this.loaded = false;
            this.searchInfo = {
                searchText: "",
                additionalQueryParams: [{
                    param: "LocationId",
                    paramValue: this.location.Id.toString()
                }]
            };
            this.displayList = [];
            this.mySurvey.ResultChunkAttributes.TotalRecords = 0;
            this.promiseSurvey = this.mySurvey.searchHelper(this.searchInfo, false, "Survey/SurveyTakeSearch").then(() => {
                this.busy = false;

                this.mySurvey.resultList.forEach((item: MySurveyUIModel) => {
                    item.daysToRespond = this.calDaysToRespond(item.EndDate);
                    item.PercentCompleteUI = item.PercentComplete + "%";
                    item.respondText = item.daysToRespond === 1 ? this.translateService.instant("LAST_DAY_TO_RESPOND") : `${item.daysToRespond} ${this.translateService.instant("DAYS_TO_RESPOND")}`;
                });

                this.filterList();

                if (scrollInfo) {
                    this.numberToDisplay = scrollInfo.numberToDisplay;
                    setTimeout(() => { window.scrollTo(0, scrollInfo.positionY); }, 100);
                }

                this.loaded = true;
                // Return for the promise
                return true;
            });
            this.promiseAll = this.promiseSurvey;
        }
    }


    private calDaysToRespond(dateToCompare: Date) {
        const oneDay = 1000 * 60 * 60 * 24;
        const today = new Date().setHours(0, 0, 0, 0);
        const endDate = new Date(dateToCompare).setHours(0, 0, 0, 0);
        let daysToRespond = Math.floor((endDate - today) / oneDay) + 1;

        return daysToRespond;

    }

    private inputFormatter = (result: any) => {
        if (result && result.Label) {
            return result.Label;
        }
        return result;
    }

    private getLocation = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(350),
            distinctUntilChanged(),
            switchMap(val => {
                //Make sure there are atleast two characters before searching
                if (val.length < 2) {
                    return [];
                }

                let searchParams: SearchInfo = { searchText: val.trim(), businessIdentity: this.activeProfileService.businessIdentity, chunkIndex: 0, recordCount: 20 };
                return this.locationService.search(searchParams, "Location/SimpleSearch").then((result: SearchResponse<SimpleSearchLocationModel>) => {
                    result.ResultList.forEach(function (item: SimpleSearchLocationModel) {
                        item.Id = item.Id;
                        item.Label = `${item.LocationIdentifier} - ${item.Name}`;
                    });
                    return result.ResultList;
                });

            })
        );

    }

    private showLocationModal(): void {
        let componentName = "location";
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: false, search: componentName } }).then((response) => {
            this.location = response.item;
            this.location.Label = `${this.location.LocationIdentifier} - ${this.location.Name}`;

            this.getListData();
        });
    }

    private onLocationChange(location) {
        if (typeof location !== "string") {
            this.location = location;
            this.getListData();
        }
    }


    private onQueryChange(change: string) {
        let originText = this.search;
        if (originText !== change) {
            this.search = change;
            this.filterList();
        }
    }

    private filterList() {
        this.filteredList = this.mySurvey.resultList.filter(s => s.Name.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
        this.numberToDisplay = this.chunkSize;
        this.displayList = this.filteredList;
    }

    public getMoreListData() {
        if (this.numberToDisplay + this.chunkSize < this.displayList.length) {
            this.numberToDisplay += this.chunkSize;
        } else if (this.displayList.length > 0) {
            // vm.displayList.length == 0 is probably caused by this function being called before the data
            // is loaded which causes a problem because infinite scroll won"t call again until the control size changes.
            // Since having the numberToDisplay greater than zero when vm.displayList.length == 0 isn"t a problem we"ll
            // just assume that"s the case and leave the display number the same
            this.numberToDisplay = this.displayList.length;
        }
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        } else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    }

    ngOnDestroy() {
        if (this.location && this.location.LocationIdentifier) {
            localStorage.setItem(this.searchScrollKey, JSON.stringify({ search: this.search, positionY: this.scrollY, numberToDisplay: this.numberToDisplay, locationId: this.location.LocationIdentifier}));
        }
    }

}

