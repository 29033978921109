/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public.component.scss.ngstyle";
import * as i1 from "../../../node_modules/@okta/okta-signin-widget/dist/css/okta-theme.css.ngstyle";
import * as i2 from "../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css.ngstyle";
import * as i3 from "@angular/core";
import * as i4 from "../../../node_modules/@uirouter/angular/lib/directives/uiView.ngfactory";
import * as i5 from "@uirouter/angular/lib/directives/uiView";
import * as i6 from "@uirouter/core";
import * as i7 from "./public.component";
var styles_PublicComponent = [i0.styles, i1.styles, i2.styles];
var RenderType_PublicComponent = i3.ɵcrt({ encapsulation: 2, styles: styles_PublicComponent, data: {} });
export { RenderType_PublicComponent as RenderType_PublicComponent };
export function View_PublicComponent_0(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 14, "div", [["class", "login-page"]], null, null, null, null, null)), (_l()(), i3.ɵeld(1, 0, null, null, 2, "div", [["id", "layout-masthead"]], null, null, null, null, null)), (_l()(), i3.ɵeld(2, 0, null, null, 1, "header", [["class", "container-fluid clearfix"]], null, null, null, null, null)), (_l()(), i3.ɵeld(3, 0, null, null, 0, "div", [["class", "logo pull-left"]], null, null, null, null, null)), (_l()(), i3.ɵeld(4, 0, null, null, 5, "div", [["id", "layout-content"]], null, null, null, null, null)), (_l()(), i3.ɵeld(5, 0, null, null, 4, "div", [["class", "container"], ["id", "content"]], null, null, null, null, null)), (_l()(), i3.ɵeld(6, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i3.ɵeld(7, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i3.ɵeld(8, 16777216, null, null, 1, "ui-view", [], null, null, null, i4.View_UIView_0, i4.RenderType_UIView)), i3.ɵdid(9, 245760, null, 0, i5.UIView, [i6.UIRouter, "UIView.PARENT_INJECT", i3.ViewContainerRef], null, null), (_l()(), i3.ɵeld(10, 0, null, null, 4, "div", [["id", "layout-footer"]], null, null, null, null, null)), (_l()(), i3.ɵeld(11, 0, null, null, 3, "footer", [["class", "login-footer"]], null, null, null, null, null)), (_l()(), i3.ɵeld(12, 0, null, null, 2, "div", [["class", "container text-center"]], null, null, null, null, null)), (_l()(), i3.ɵeld(13, 0, null, null, 1, "p", [["class", "crInfo mb-0"]], null, null, null, null, null)), (_l()(), i3.ɵeld(14, 0, null, null, 0, "img", [["alt", "IMAGINE"], ["src", "../../assets/img/logo_brand.png"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 9, 0); }, null); }
export function View_PublicComponent_Host_0(_l) { return i3.ɵvid(0, [(_l()(), i3.ɵeld(0, 0, null, null, 1, "app-public", [], null, null, null, View_PublicComponent_0, RenderType_PublicComponent)), i3.ɵdid(1, 114688, null, 0, i7.PublicComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PublicComponentNgFactory = i3.ɵccf("app-public", i7.PublicComponent, View_PublicComponent_Host_0, {}, {}, []);
export { PublicComponentNgFactory as PublicComponentNgFactory };
