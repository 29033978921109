var ShipMethodModel = /** @class */ (function () {
    function ShipMethodModel() {
        this.Selected = false;
    }
    Object.defineProperty(ShipMethodModel.prototype, "Label", {
        get: function () {
            return this.DisplayName;
        },
        enumerable: true,
        configurable: true
    });
    return ShipMethodModel;
}());
export { ShipMethodModel };
var ShipMethodSubGroupRule = /** @class */ (function () {
    function ShipMethodSubGroupRule() {
    }
    return ShipMethodSubGroupRule;
}());
export { ShipMethodSubGroupRule };
var ShipMethodRule = /** @class */ (function () {
    function ShipMethodRule() {
        this.AllLocations = true;
    }
    ShipMethodRule.prototype.setLocationGroupInfo = function (id, name, pendingLocationCount) {
        this.LocationGroupId = id;
        this.LocationGroupName = name;
        this.PendingLocations = pendingLocationCount;
    };
    ShipMethodRule.prototype.setLocationSubGroups = function (groups) {
        this.SubGroupRules = groups.map(function (item) {
            var returnValue = new ShipMethodSubGroupRule();
            returnValue.LocationSubGroupId = item.Id;
            returnValue.LocationSubGroupName = item.Name;
            returnValue.Preferences = [];
            returnValue.ShipMethodIds = [];
            return returnValue;
        });
    };
    return ShipMethodRule;
}());
export { ShipMethodRule };
var ShipMethodPreferenceModel = /** @class */ (function () {
    function ShipMethodPreferenceModel(businessIdentity, shipMethod) {
        if (shipMethod === void 0) { shipMethod = null; }
        this.Id = 0;
        this.AdminOnly = false;
        this.BusinessIdentity = businessIdentity;
        if (shipMethod) {
            this.ShipMethod = shipMethod;
            this.ShipMethodId = shipMethod.Id;
        }
    }
    ShipMethodPreferenceModel.prototype.SetAdminOnly = function (value) {
        this.AdminOnly = value;
    };
    return ShipMethodPreferenceModel;
}());
export { ShipMethodPreferenceModel };
