import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService, SearchInfo } from "imagine-ui-ng-core";
import { ShopSettingModel, PONumberSettingsModel } from "../model/ShopSettingModel";

@Injectable()
export class ShopSettingsService extends IpsBaseWebService {

    public ShowOrderReasonSettingName = "ShowOrderReason";
    public OrderReasonSettingName = "OrderReasons";
    public PONumberSettingName = "PONumberOptions";
    public HandlingFeePerAddressSettingName = "HandlingFeePerAddress";
    public HandlingFeePerLineItemSettingName = "HandlingFeePerLineItem";
    public HandlingFeePerEachSettingName = "HandlingFeePerEach";
    public ReferenceNumberSettingName = "ShowReferenceNumber";
    public CheckoutLabelSettingName = "CheckoutLabel";
    public NeedByDateSettingName = "ShowNeedByDate";
    public CommentsSettingName = "ShowComments";
    public CategoryFilterSettingName = "ShowCategoryFilter";
    public CategoryPermissionSettingName = "CategoryHasPermissions";
    public AllLocationsSettingName = "AllLocations";
    public HideCampaignSettingName = "HideCampaign";
    public DeliverySelectionSettingName = "ShowDeliverySelection";
    public ShipMethodPreferencesSettingName = "ShipMethodPreferences";

    constructor(http: HttpClient,
        public activeProfileService: ActiveProfileService,
        public helperFunctionsService: HelperFunctionsService
    ) {
        super(http, <any>environment, activeProfileService);
        this.init("Settings", "Shop", "v1");
    }

    public getSettingByName(name: string): Promise<ShopSettingModel> {
        const returnPromise = new Promise<ShopSettingModel>((resolve, reject) => {
            if (!name) {
                resolve(null);
            } else {
                this.getList().then((response: ShopSettingModel[]) => {
                    const matches = response.filter((item) => {
                        return item.Name === name;
                    });

                    if (matches.length) {
                        resolve(matches[0]);
                    } else {
                        resolve(null);
                    }
                });
            }
        });

        return returnPromise;
    }

    private hasAdminAccess(): boolean {
        const adminRoleNames = ["ShoppingAdmin", "EcommSiteAdmin"];
        const allThemes = this.activeProfileService.profile.businessIdentityList;

        const allRoles = <string[]>[];

        allThemes.forEach((theme) => {
            allRoles.push(...theme.Role);
        });

        const matchingRoles = allRoles.filter((item) => adminRoleNames.indexOf(item) >= 0);

        return matchingRoles.length > 0;
    }

    public getPONumberSetting(): Promise<PONumberSettingsModel> {
        const returnPromise = new Promise<PONumberSettingsModel>((resolve, reject) => {
            this.getSettingByName(this.PONumberSettingName).then((settingModel: ShopSettingModel) => {
                const returnValue = <PONumberSettingsModel>settingModel;
                const parsedObj = JSON.parse(settingModel.Value);
                returnValue.EnableList = parsedObj.EnableList;
                returnValue.Options = parsedObj.Options;

                const rawOptions = returnValue.Options.split(/\r\n|\r|\n/g);
                let allowedPONumbers = rawOptions.filter((item) => {
                    if (item.indexOf("_") === 0) {
                        return this.hasAdminAccess();
                    } else {
                        return true;
                    }
                });

                allowedPONumbers = allowedPONumbers.map((item) => {
                    if (item.indexOf("_") === 0) {
                        return item.substring(1);
                    } else {
                        return item;
                    }
                });

                returnValue.Options = allowedPONumbers.join("\r\n");

                resolve(returnValue);
            });
        });

        return returnPromise;
    }

    public getCheckoutLabelSetting(): Promise<string> {
        const returnPromise = new Promise<string>((resolve, reject) => {
            this.getSettingByName(this.CheckoutLabelSettingName).then((response: ShopSettingModel) => {
                if (response) {
                    resolve(response.Value);
                } else {
                    resolve("Checkout by Invoice");
                }
            });
        });

        return returnPromise;
    }

    public getReferenceNumberSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.ReferenceNumberSettingName).then((response: ShopSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(true);
                }
            });
        });

        return returnPromise;
    }

    public getNeedByDateSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.NeedByDateSettingName).then((response: ShopSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(false);
                }
            });
        });

        return returnPromise;
    }

    public getCategoryFilterSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.CategoryFilterSettingName).then((response: ShopSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(false);
                }
            });
        });

        return returnPromise;
    }

    public getCategoryPermissionsSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.CategoryPermissionSettingName).then((response: ShopSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(false);
                }
            });
        });

        return returnPromise;
    }

    public getCommentsSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.CommentsSettingName).then((response: ShopSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(false);
                }
            });
        });

        return returnPromise;
    }

    public getOrderReasons(): Promise<string[]> {
        const defaultValues = [
            "Additional POP",
            "Element Missing from Kit",
            "Faulty Packaging",
            "Grand Opening",
            "Kit Not Received",
            "Profile Change",
            "Store Discard",
            "Weather / Damage by Shipper",
            "Wrong Size Element in Kit"
        ];

        const returnPromise = new Promise<string[]>((resolve, reject) => {
            this.getSettingByName(this.OrderReasonSettingName).then((settingModel: ShopSettingModel) => {
                if (settingModel && settingModel.Value) {
                    const returnValue = settingModel.Value.split(/\r\n|\r|\n/g);
                    resolve(returnValue);
                } else {
                    resolve(defaultValues);
                }
            });
        });

        return returnPromise;
    }



    public getAllLocationsSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.AllLocationsSettingName).then((response: ShopSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(false);
                }
            });
        });

        return returnPromise;
    }


    public getHideCampaignSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.HideCampaignSettingName).then((response: ShopSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(false);
                }
            });
        });

        return returnPromise;
    }

    // Work item 20612 [INC0115821]
    // If 'ShowDeliverySelection' setting is not found, we default it to 'true'
    public getShowDeliverySelectionSetting(): Promise<boolean> {
        const returnPromise = new Promise<boolean>((resolve, reject) => {
            this.getSettingByName(this.DeliverySelectionSettingName).then((response: ShopSettingModel) => {
                if (response) {
                    resolve(response.Value === "true");
                } else {
                    resolve(true);
                }
            });
        });

        return returnPromise;
    }

    /**
     * Get settings that aren't returned by standard route.
     * @param routeName - name to use for restricted route. Example: HandlingFees
     */
    public GetRestrictedSettings(routeName: string): Promise<ShopSettingModel[]> {
        return this.getByRoute<ShopSettingModel[]>(`${this.urlApiBaseProductPlusVersion}Settings/${routeName}`);
    }

    public PutRestrictedSettings(data: ShopSettingModel[], routeName: string): Promise<ShopSettingModel[]> {
        return this.put(data, null, `${this.urlApiBaseProductPlusVersion}Settings/${routeName}`);
    }
}
