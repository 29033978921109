/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-data-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@uirouter/angular/lib/directives/uiSref";
import * as i3 from "@uirouter/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "imagine-ui-ng-security";
import * as i6 from "imagine-ui-ng-core";
import * as i7 from "../../../../../node_modules/imagine-ui-ng-list-search/imagine-ui-ng-list-search.ngfactory";
import * as i8 from "imagine-ui-ng-list-search";
import * as i9 from "angular-busy2";
import * as i10 from "../../../shared/pipe/highlight-filter.pipe";
import * as i11 from "@angular/common";
import * as i12 from "./custom-data-search.component";
import * as i13 from "../../service/custom-data.service";
var styles_CustomDataSearchComponent = [i0.styles];
var RenderType_CustomDataSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomDataSearchComponent, data: {} });
export { RenderType_CustomDataSearchComponent as RenderType_CustomDataSearchComponent };
function View_CustomDataSearchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "row ips-search-result-big"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "a", [["class", "no-underline"], ["uiSref", "main.customdata.edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(4, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(5, { id: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "p-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " - "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "h2 mb-3"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(9, 2), (_l()(), i1.ɵted(10, null, [" - ", " ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 7, "div", [["class", "col-2 col-md-1 mt-2"], ["ipsPermission", "main.customdata"]], null, null, null, null, null)), i1.ɵdid(13, 4276224, null, 0, i5.ɵb, [i1.Renderer2, i1.ElementRef, i5.PermissionService], { ipsPermission: [0, "ipsPermission"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 5, "a", [["class", "no-underline"], ["uiSref", "main.customdata.edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(16, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(17, { id: 0 }), (_l()(), i1.ɵeld(18, 0, null, null, 1, "div", [["class", "pt-3 pr-3 d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "span", [["class", "fa fa-pencil"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "main.customdata.edit"; var currVal_1 = _ck(_v, 5, 0, _v.context.result.Id); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_6 = "main.customdata"; _ck(_v, 13, 0, currVal_6); var currVal_7 = "main.customdata.edit"; var currVal_8 = _ck(_v, 17, 0, _v.context.result.Id); _ck(_v, 16, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.result.AreaName; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.result.Name, _co.query)), ""); _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("FIELD_ORDER")); var currVal_5 = _v.context.result.Ordinal; _ck(_v, 10, 0, currVal_4, currVal_5); }); }
function View_CustomDataSearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "col"], ["ipsFocus", "searchInput"]], null, null, null, null, null)), i1.ɵdid(1, 4866048, null, 0, i6.ɵb, [i1.ElementRef, i1.Renderer2], { ipsFocus: [0, "ipsFocus"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "ips-list-search", [], null, [[null, "onListDataChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onListDataChange" === en)) {
        var pd_0 = (_co.getListData($event.search) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_ListSearchComponent_0, i7.RenderType_ListSearchComponent)), i1.ɵdid(3, 638976, null, 2, i8.ListSearchComponent, [i1.ChangeDetectorRef, i9.CgBusyDefaults], { searchLabel: [0, "searchLabel"], searchLabelPlural: [1, "searchLabelPlural"], labelLoading: [2, "labelLoading"], labelLoadingError: [3, "labelLoadingError"], listData: [4, "listData"], busy: [5, "busy"], section: [6, "section"], scroller: [7, "scroller"], quickRef: [8, "quickRef"], itemClickPermission: [9, "itemClickPermission"], promise: [10, "promise"], createNewTooltip: [11, "createNewTooltip"], query: [12, "query"], autoFocus: [13, "autoFocus"] }, { onListDataChange: "onListDataChange" }), i1.ɵqud(335544320, 1, { searchHeaderTemplate: 0 }), i1.ɵqud(335544320, 2, { listSearchItemTemplate: 0 }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [[2, 2], ["listSearchItemTemplate", 2]], null, 0, null, View_CustomDataSearchComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "searchInput"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.searchLabel; var currVal_2 = _co.searchLabelPlural; var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 2, i1.ɵnov(_v, 6).transform("ACCESSING_CUSTOM_DATA_INFO")), ""); var currVal_4 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 3, i1.ɵnov(_v, 7).transform("ERROR_ACCESSING_CUSTOM_DATA_INFO")), ""); var currVal_5 = _co.customData; var currVal_6 = _co.busy; var currVal_7 = "main.customdata"; var currVal_8 = true; var currVal_9 = false; var currVal_10 = "main.customdata.search"; var currVal_11 = _co.promise; var currVal_12 = _co.createNewTooltip; var currVal_13 = _co.query; var currVal_14 = true; _ck(_v, 3, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }, null); }
export function View_CustomDataSearchComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i10.HighlightFilterPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomDataSearchComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translated; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomDataSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-data-search", [], null, null, null, View_CustomDataSearchComponent_0, RenderType_CustomDataSearchComponent)), i1.ɵdid(1, 114688, null, 0, i12.CustomDataSearchComponent, [i13.CustomDataService, i6.ListSearchHelper, i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomDataSearchComponentNgFactory = i1.ɵccf("app-custom-data-search", i12.CustomDataSearchComponent, View_CustomDataSearchComponent_Host_0, {}, {}, []);
export { CustomDataSearchComponentNgFactory as CustomDataSearchComponentNgFactory };
