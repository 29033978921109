<div appProvideParentForm class="form-group">
    <ng-container *ngIf="showLabel"><label class="required">{{labelText | translate}}</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_DATERANGE_INFO' | translate}}" placement="bottom"></i></ng-container>
    <div class="input-group">
        <input type="text" name="dateRangeName" id="dateRangeId" class="form-control form-control-lg input-full"
               [(ngModel)]="dateRangeModel" (ngModelChange)="changeDateRange()" #rangeDate="ngModel"
               placeholder="{{ 'ENTER_DATE_RANGE' | translate }}"
               #d2="bsDaterangepicker" bsDaterangepicker [maxDate]="maxDate" [bsConfig]="dateRangeOptions" triggers=""
               appValidation />
        <span class="input-group-append">
            <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" [ngClass]="" (click)="d2.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
        </span>
    </div>
    <div *ngIf="rangeDate.errors && rangeDate.errors.required" class="invalid-feedback d-flex" translate>PLEASE_ENTER_DATE_RANGE</div>
    <div *ngIf="rangeDate.errors && rangeDate.errors.rangeTooBig" class="invalid-feedback d-flex" [translate]="'DATE_RANGE_TOO_BIG'" [translateParams]="{ days: daySpan }"></div>
</div>
