var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { FieldControlBaseComponent } from "../field-control-base";
var IpsNumberComponent = /** @class */ (function (_super) {
    __extends(IpsNumberComponent, _super);
    function IpsNumberComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IpsNumberComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(function () { return _this.TranslateText(); });
        var validators = [];
        if (this.isFieldRequired) {
            validators.push(Validators.required);
        }
        this.control = new FormControl(typeof (this.value) === "object" ? this.value.Text : this.value, validators);
        this.control.valueChanges.pipe(distinctUntilChanged(), debounceTime(350)).subscribe(function (value) {
            var val = _this.GetCustomDataValue();
            val.Text = value;
            _this.valueChanged.emit(val);
        });
        this.group = new FormGroup({
            Name: new FormControl(this.name),
            Text: this.control,
            Id: new FormControl(this.value ? this.value.Id || 0 : 0),
            CustomDataId: new FormControl(this.customDataId)
        });
        this.fieldValues.push(this.group);
    };
    return IpsNumberComponent;
}(FieldControlBaseComponent));
export { IpsNumberComponent };
