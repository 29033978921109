import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { PromotionMessagePatternGroupModel, PromotionMessageLocationBalanceModel, PromotionMessagePlacementGroupModel, PatternPositionLocationCountRequestModel } from "../index";

@Injectable()
export class PatternGroupService extends IpsBaseWebService {

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("PatternGroup", "Campaign", "v1");
    }

    public getByPromotionId(promoId: number): Promise<PromotionMessagePatternGroupModel[]> {
        let route = `Promotion/${promoId}/PatternGroup`;
        return this.getByRoute<PromotionMessagePatternGroupModel[]>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public calculatePositionLocationCounts(promoMsgs: PromotionMessageLocationBalanceModel[], placementGroups: PromotionMessagePlacementGroupModel[]): Promise<PromotionMessagePlacementGroupModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}PatternGroup/GetPositionLocationCounts`;
        let requestModel: PatternPositionLocationCountRequestModel = { PromotionMessages: promoMsgs, PromotionMessagePlacementGroup: placementGroups[0] };
        return this.postByRoute<PromotionMessagePlacementGroupModel[]>(route, requestModel);
    }

}
