import { Component, OnInit, Input, Output, EventEmitter  } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { String as IpsString } from "typescript-string-operations";
import { Observable } from "rxjs";
import { of as observableOf } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SearchModalComponent } from "../../search-modal/search-modal.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-ips-typeahead",
  templateUrl: "./ips-typeahead.component.html",
  styleUrls: ["./ips-typeahead.component.scss"]
})
export class IpsTypeaheadComponent implements OnInit {
    @Input() name: string;
    @Input() itemGroup: FormGroup;
    @Input() itemGroupIndex: number;
    @Input() itemSource: Observable<any>;
    @Input() itemSourceList: any[];
    @Input() itemSourceListSelected: any[];
    @Input() multiSelection: boolean;
    @Output() itemChanged = new EventEmitter<any>();
    @Output() itemDeleted = new EventEmitter<any>();

    public modalTitle = "";
    public controlName = "";

    private TranslateStrings: { [key: string]: string } = {
        "SELECT_DYNAMIC_NAME": ""
    };

    constructor(private ipsModal: IpsModalService, private translateService: TranslateService) { }

    ngOnInit() {
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(() => this.TranslateText());
    }

    private TranslateText() {
        this.translateService.get("ALL").subscribe(() => {
            for (let key of Object.keys(this.TranslateStrings)) {
                this.TranslateStrings[key] = this.translateService.instant(key);
            }
        });

        this.modalTitle = IpsString.Format(this.TranslateStrings["SELECT_DYNAMIC_NAME"], this.name);
    }

    public typeaheadOnSelect(e: TypeaheadMatch): void {
        this.itemChanged.emit({ index: this.itemGroupIndex, item: e.item });
    }

    public searchItem(): void {
        let data = this.itemSourceList.map(item => ({ Id: item.Id, Name: item.Name, Label: item.Label || item.Name }));

        this.ipsModal.displayTemplateScrollable(SearchModalComponent,
            {
                resolve: {
                    addAll: false,
                    search: "customdataoption",
                    modalTitle: this.modalTitle,
                    listOfData: data,
                    listOfDataSelected: this.itemSourceListSelected,
                    multiSelection: this.multiSelection
                }
            }).then(result => {
                if (this.multiSelection) {
                    this.itemChanged.emit({ index: null, item: result });
                } else {
                    this.itemChanged.emit({ index: this.itemGroupIndex, item: result.item });
                }
            },
            (reason) => {
                // Do nothing, swallow cancel case so we don't get console error
            });
    }

    public removeItem(itemGroupIndex: number): void {
        this.itemDeleted.emit(itemGroupIndex);
    }

    public typeaheadValues() {
        return this.itemSourceList.filter(item => {
            return !this.itemSourceListSelected.find(s => (s.CustomDataOptionId === item.Id));
        });
    }
}
