<div>
    <form #customAddressForm="ngForm" (ngSubmit)="updateAddress()">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close"
                    (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0" translate>ENTER_NEW_SHIPPING_ADDRESS</h4>
        </div>
        <div class="modal-body address-entry">
            <div class="form-group pl-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="addressbook" id="addressbook" [disabled]="currentAddress.Id && !currentAddress.RemovedFromAddressBook" [(ngModel)]="currentAddress.SaveToAddressBook">
                    <label class="form-check-label" for="addressbook" translate>
                        SAVE_TO_ADDRESS_BOOK
                    </label>
                </div>
            </div>
            <div class="form-group" *ngIf="currentAddress.SaveToAddressBook">
                <a *ngIf="currentAddress.AddressBookId && !currentAddress.RemovedFromAddressBook" [ngClass]="{ 'fade-0s': deleted }" class="text-info text-underline-hover clicker d-inline-block pull-right mb-1 mr-3" (click)="removeFromAddressBook(currentAddress)" translate>
                    <span class="fa fa-check" *ngIf="deleted"></span><span class="fa fa-spin fa-spinner" *ngIf="deleting"></span><span *ngIf="!deleted && !deleting" class="fa fa-trash-o"></span> REMOVE_FROM_ADDRESS_BOOK
                </a>
                <label class="view-info-label required" translate>ADDRESS_ALIAS</label>
                <input type="text" class="form-control form-control-lg input-full"
                       name="alias" [(ngModel)]="currentAddress.AddressBookAlias" maxlength="50"
                       placeholder="{{ 'ENTER_ADDRESS_ALIAS' | translate}}" required />
            </div>
            <div class="form-group">
                <label class="view-info-label required" translate>ATTENTION_TO_COMPANY</label>
                <input type="text" class="form-control form-control-lg input-full"
                       name="attention" [(ngModel)]="currentAddress.Attention" maxlength="100"
                       placeholder="{{ 'ENTER_ATTENTION_TO' | translate}}" required />
            </div>
            <div class="form-group">
                <!-- <label class="view-info-label" for="Notes" translate>PO_NUMBER</label>
                <input type="text" class="form-control form-control-lg input-full"
                placeholder="{{ 'ENTER_PO_NUMBER' | translate }}" /> -->
                <label class="view-info-label required" translate>NAME</label>
                <input type="text" class="form-control form-control-lg input-full"
                       name="name" [(ngModel)]="currentAddress.Label" maxlength="100"
                       placeholder="{{ 'ENTER_NAME' | translate}}" required />
            </div>
            <div class="form-group">
                <label class="view-info-label required" translate>ADDRESS_LINE_1</label>
                <input type="text" class="form-control form-control-lg input-full"
                       name="line1" [(ngModel)]="currentAddress.Line1" maxlength="100"
                       placeholder="{{ 'ENTER_ADDRESS_LINE_1' | translate}}" required />
            </div>
            <div class="form-group">
                <label class="view-info-label" translate>ADDRESS_LINE_2</label>
                <input type="text" class="form-control form-control-lg input-full"
                       name="line2" [(ngModel)]="currentAddress.Line2" maxlength="100"
                       placeholder="{{ 'ENTER_ADDRESS_LINE_2' | translate}}" />
            </div>
            <div class="form-group">
                <label class="view-info-label required" translate>CITY</label>
                <input type="text" class="form-control form-control-lg input-full"
                       name="city" [(ngModel)]="currentAddress.City" maxlength="100"
                       placeholder="{{ 'ENTER_CITY' | translate}}" required />
            </div>
            <div class="form-group">
                <label class="view-info-label required" translate>STATE_PROVINCE</label>
                <select class="form-control form-control-lg ips-select"
                        name="state" id="state" [(ngModel)]="currentAddress.StateProvince"
                        [disabled]="!currentAddress.Country" required>
                    <option value="" disabled selected>Select your option</option>
                    <optgroup *ngFor="let regionDesc of regionDescriptorKeys | async"
                              label="{{regionDesc}}">
                        <option *ngFor="let region of regionDescriptors.get(regionDesc)"
                                [ngValue]="region.RegionCode">
                            {{region.RegionName}}
                        </option>
                    </optgroup>
                </select>
            </div>
            <div class="form-group">
                <label class="view-info-label required" translate>POSTAL_CODE</label>
                <input type="text" class="form-control form-control-lg input-full"
                       name="postalcode" [(ngModel)]="currentAddress.PostalCode" maxlength="10"
                       placeholder="{{ 'ENTER_POSTAL_CODE' | translate}}" required />
            </div>
            <div class="form-group">
                <label class="view-info-label required" translate>COUNTRY</label>
                <select class="form-control form-control-lg ips-select"
                        name="country" [(ngModel)]="currentAddress.Country"
                        (ngModelChange)="countryChanged(true)" required>
                    <option value="" disabled selected>Select your option</option>
                    <option *ngFor="let country of countryPromise | async"
                            [ngValue]="country.Iso3166TwoCharCountryCode">
                        {{country.Name}}
                    </option>
                </select>
            </div>
            <!-- <div class="form-group">
                <label class="view-info-label required" translate>PHONE</label>
                <input type="tel" class="form-control form-control-lg input-full"
                name="phone" [(ngModel)]="currentAddress.Phone" required />
            </div>
            <div class="form-group">
                <label class="view-info-label required" translate>EMAIL</label>
                <input type="email" class="form-control form-control-lg input-full"
                name="email" [(ngModel)]="currentAddress.Email" required />
            </div> -->
        </div>
        <div class="row pl-4 pr-4">
            <!-- <div class="col-6">
                <button class="btn btn-default btn-lg btn-block search-modal-done-button mt-2 mb-2"
                (click)="close()" translate>
                    CANCEL
                </button>
            </div> -->
            <div class="col-12">
                <button class="btn btn-default btn-lg btn-block search-modal-done-button mt-2 mb-2"
                        [disabled]="!customAddressForm.valid" translate>
                    DONE
                </button>
            </div>
        </div>
    </form>
</div>
