import { Component, OnInit, ViewChild, OnDestroy, HostListener } from "@angular/core";
import { ListSearchHelper, QueryParamItem, ActiveProfileService, FileDownloadService } from "imagine-ui-ng-core";
import { ListSearchComponent, ResultDetailComponent, TranslatedTexts } from "imagine-ui-ng-list-search";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "imagine-ui-ng-security";

import {
    LocationService, LocationSearchResultModel, LocationStatusModel,
    SimpleSearchLocationGroupUIModel, LocationFixtureService
} from "../../index";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SearchCardUIModel } from "../../../shared/index";

import { LocationListOptionModalComponent } from "../location-list-option-modal/location-list-option-modal.component";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormControl, Validators, FormGroup, FormArray } from "@angular/forms";
import { MarketModel, MarketGroupModel, MarketGroupSaveModel } from "../../../market/";
import { MarketGroupService } from "../../../market/service/market-group.service";
import cloneDeep from "lodash-es/cloneDeep";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { StateService } from "@uirouter/core";
import { String } from "typescript-string-operations";
import { MarketEditComponent } from "../../../market/market-edit/market-edit.component";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { LocationSearchInfo } from "../../model/LocationSearchInfo";

interface UILocationModel extends LocationSearchResultModel {
    LocationStatusInfo: LocationStatusModel;
    Cards: SearchCardUIModel[];
    FullImage: string;
}

interface ScrollInfo {
    search: string;
    positionY: number;
    chunkIndex: number;
}

type AdvanceSearchState = "Full" | "Partial" | "Hide";

@Component({
    selector: "app-location-search",
    templateUrl: "./location-search.component.html",
    styleUrls: ["./location-search.component.scss"]
})
export class LocationSearchComponent implements OnInit, OnDestroy {
    @ViewChild(ResultDetailComponent) private resultDetail: ResultDetailComponent;
    @ViewChild(ListSearchComponent) private listSearch: ListSearchComponent;
    @ViewChild(MarketEditComponent) private marketEditComponent: MarketEditComponent;

    public location: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<{}>;
    public locationSearchPromise: Promise<void>;
    public query: string;
    private selectedOptionList: SimpleSearchLocationGroupUIModel[];
    private scrollY: number;
    public searchInfo: LocationSearchInfo;
    public editOrViewPermission: string;
    public editLocationGroupPermission: boolean;
    public searchFixtureGroupPermission: boolean;
    private noImageSelected: string;
    private searchScrollKey: string;
    public searchLabel: string;
    public searchLabelPlural: string;
    public translated = false;
    public optionList: HTMLOptionElement[];
    public selectedOption: HTMLOptionElement;
    querySubject: Subject<string> = new Subject<string>();
    public advanceSearchState: AdvanceSearchState = "Hide";

    public locationForm: FormGroup;
    public Id: FormControl;
    public Name: FormControl;
    public Notes: FormControl;

    public markets: MarketModel[] = [];
    private locationIds: number[] = [];
    public marketsToExclude: MarketModel[] = []; // Store currently loaded saved search here

    public pluralMapping: { [k: string]: string } = {
        "=1": "LOCATION",
        "other": "LOCATIONS"
    };

    private TranslateStrings: { [key: string]: string } = {
        "PLEASE_ENTER_LOCATION_SEARCH_NAME": "",
        "MAX_LENGTH_ERROR": "",
        "DUPLICATE_LOCATION_SEARCH_NAME": ""
    };

    public errorMessages = {
        "locationSearchRequired": () => this.TranslateStrings["PLEASE_ENTER_LOCATION_SEARCH_NAME"],
        "maxlength": (params) => String.Format(this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength),
    };
    public canDeleteErrorList = [];
    public loaded = false;


    constructor(private locationService: LocationService, listSearchHelper: ListSearchHelper, private translateService: TranslateService, private ipsModal: IpsModalService, private activeProfileService: ActiveProfileService, private authService: AuthService,
        public marketGroupService: MarketGroupService, private ipsMessage: IpsMessageService, private $state: StateService, private downloadService: FileDownloadService, private locationFixtureService: LocationFixtureService) {
        this.location = listSearchHelper.getListSearchHelper(locationService);
        this.busy = this.location.busy;

        locationService.head().then((data) => {
            this.location.TotalRecords = data;
        });

        this.optionList = [
            <HTMLOptionElement>{ text: "ALL_EXCEPT_CLOSED", value: "all", defaultSelected: true },
            <HTMLOptionElement>{ text: "PLANNING", value: "planning", defaultSelected: false },
            <HTMLOptionElement>{ text: "OPEN", value: "open", defaultSelected: false },
            <HTMLOptionElement>{ text: "SUSPEND", value: "suspended", defaultSelected: false },
            <HTMLOptionElement>{ text: "CLOSED", value: "closed", defaultSelected: false }
        ];
        this.selectedOption = { value: "all" } as HTMLOptionElement;


        this.querySubject.pipe(
                debounceTime(350),
                distinctUntilChanged())
            .subscribe((model: string) => {
                this.getListData(model, null, this.selectedOption);
            });
    }

    ngOnInit() {
        let selectedOptions = localStorage.getItem("loc_search_select_options_" + this.activeProfileService.businessIdentity);
        this.searchScrollKey = "loc_search_scroll_" + this.activeProfileService.businessIdentity;
        let scrollInfo: ScrollInfo = JSON.parse(localStorage.getItem(this.searchScrollKey));
        if (selectedOptions && selectedOptions !== "undefined") {
            this.selectedOptionList = JSON.parse(selectedOptions);
        }

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        if (scrollInfo) {
            this.query = scrollInfo.search;
        }

        this.getListData(this.query || "", scrollInfo);

        this.editOrViewPermission = this.activeProfileService.permissions.find(q => q.Name === "EditLocation") ? "main.storeProfileLocation.edit" : "main.storeProfileLocation.view";
        this.editLocationGroupPermission = !!this.activeProfileService.permissions.find(q => q.Name === "SearchLocationGroup");
        this.searchFixtureGroupPermission = !!this.activeProfileService.permissions.find(q => q.Name === "SearchFixtureGroup");

        this.createForm();
        this.translateService.get("ALL").subscribe(() => this.translateText());
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    private createForm() {
        this.Id = new FormControl(0);
        this.Name = new FormControl("", [Validators.required, Validators.maxLength(150)]);
        this.Notes = new FormControl("", [Validators.maxLength(500)]);

        this.locationForm = new FormGroup({
            Id: this.Id,
            Name: this.Name,
            Notes: this.Notes,
            SavedSearch: new FormControl(true),
            BusinessIdentity: new FormControl(this.activeProfileService.businessIdentity)
        });

    }


    private translateText() {
        this.translateService.get(["LOCATION", "LOCATIONS", "NO_IMAGE_SELECTED", "ALL_EXCEPT_CLOSED", "PLANNING", "OPEN", "SUSPEND", "CLOSED"]).subscribe((res: [string]) => {
            this.searchLabel = res["LOCATION"];
            this.searchLabelPlural = res["LOCATIONS"];
            this.noImageSelected = res["NO_IMAGE_SELECTED"];
            this.optionList[0].text = res["ALL_EXCEPT_CLOSED"];
            this.optionList[1].text = res["PLANNING"];
            this.optionList[2].text = res["OPEN"];
            this.optionList[3].text = res["SUSPEND"];
            this.optionList[4].text = res["CLOSED"];

            for (let key of Object.keys(this.TranslateStrings)) {
                this.TranslateStrings[key] = this.translateService.instant(key);
            }

            this.translated = true;
        });
    }

    getListData(search: string, scrollInfo: ScrollInfo, filterOption: HTMLOptionElement = null, forceReload: boolean = false) {

        if (filterOption) {
            this.selectedOption = filterOption;
        }

        if (this.busy) {
            return;
        }

        // NOTE: advanceSeachParameters not used by api, just used to trigger searchParamsChanged
        let advanceSearchParameters: QueryParamItem[] = [];
        let locGroupIdArray: number[] = null;
        let fixtureGroupIdArray: number[] = null;
        let selectArray: string[] = null;

        if (this.selectedOptionList && this.selectedOptionList.length > 0) {
            selectArray = this.selectedOptionList
                .filter((item) => item.Type === "Location" || item.Type === "Address")
                .map((item) => `${item.Type}.${item.Name}`);

            selectArray.push(
                "Location.LocationIdentifier", "Location.Name", "Location.LocationStatusInfo", "Location.Id",
                "Location.OpenDate", "Location.SuspendPromotionsDate", "Location.InitiatePromotionsDate",
                "Address.City", "Address.StateProvince", "Location.ClosedDate", "Location.Addresses"
            );

            //Check if we need to add an image
            if (this.selectedOptionList.find(q => q.Label.toLowerCase() === "image")) {
                selectArray.push("Location.Media");
            }

            advanceSearchParameters.push({
                param: "select",
                paramValue: selectArray.join(",")
            });

            locGroupIdArray = this.selectedOptionList.filter((item) => item.Type === "LocationGroup")
                .map((item) => item.Id);
            if (locGroupIdArray.length > 0) {
                advanceSearchParameters.push({
                    param: "locGroups",
                    paramValue: locGroupIdArray.join(",")
                });
            }

            fixtureGroupIdArray = this.selectedOptionList.filter((item) => item.Type === "FixtureType")
                .map((item) => item.Id);
            if (fixtureGroupIdArray.length > 0) {
                advanceSearchParameters.push({
                    param: "fixGroups",
                    paramValue: fixtureGroupIdArray.join(",")
                });
            }
        }

        //disable list options when location status is closed
        if (this.listSearch) {
            if (this.selectedOption.value === "closed") {
                this.listSearch.disableListOption();
            } else {
                this.listSearch.enableListOption();
            }
        }

        let closed = this.selectedOption.value === "closed";

        this.searchInfo = {
            searchText: search || "",
            additionalQueryParams: advanceSearchParameters, // NOTE: used only to trigger searchParamsChanged in search helper.
            filterOption: [this.selectedOption.value],
            recordCount: 20,
            LocationFilterType: this.locationIds.length > 0 && !closed ? "LocationId" : "None",
            LocationIds: closed ? [] : this.locationIds,
            FixtureGroups: fixtureGroupIdArray,
            LocationGroups: locGroupIdArray,
            Select: selectArray,
            businessIdentity: this.activeProfileService.businessIdentity
        };

        return this.location.searchHelper(this.searchInfo, forceReload, `${this.locationService.urlApiBaseProductPlusVersion}Location/AdvancedSearch`, true).then((result) => {
            this.location.resultList.forEach((item: UILocationModel) => {

                item.Cards = [];

                item.LocationStatusInfo = this.locationService.getStatusInfo(item);

                let keys = Object.keys(item);

                if (this.selectedOptionList) {
                    this.selectedOptionList.forEach((option: SimpleSearchLocationGroupUIModel) => {

                        // The below code is only for Address and Location types. Skip the rest.
                        if (option.Type !== "Location" && option.Type !== "Address") {
                            return;
                        }


                        //Add image if we have column selected
                        if (option.Name === "Image") {
                            if (item.Media) {
                                item.FullImage =
                                    `${item.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService
                                    .businessIdentity
                                    }&idToken=${this.authService.getIdToken()}`;
                                return;
                            } else {
                                item.Cards.push({ Id: item.Id, Key: (this.locationService.locationAttributes.find(q => q.Key === option.Name)).Label, Value: this.noImageSelected, Type: "Location" });
                                return;
                            }
                        }

                        //Location lookup
                        let key = keys.find(q => q === option.Name);
                        if (key) {
                            item.Cards.push({ Id: item.Id, Key: this.locationService.locationAttributes.find(q => q.Key === option.Name).Label, Value: item[key], Type: "Location" });
                            return;
                        }


                        //Address lookup
                        if (item.Addresses) {
                            let addressKeys = Object.keys(item.Addresses[0]);

                            let addresskey = addressKeys.find(q => q === option.Name);
                            if (addresskey) {
                                item.Cards.push({ Id: item.Id, Key: this.locationService.addressAttributes.find(q => q.Key === option.Name).Label, Value: item.Addresses[0][addresskey], Type: "Location" });
                                return;
                            }
                        }

                        //Add a blank card - no data for this location property
                        item.Cards.push({ Id: item.Id, Key: (this.locationService.locationAttributes.find(q => q.Key === option.Name) || this.locationService.addressAttributes.find(q => q.Key === option.Name)).Label, Value: "", Type: "Location" });

                    });

                    //Add location group cards
                    if (item.LocationGroups) {
                        item.LocationGroups.forEach(lg => {
                            if (lg.SubGroups) {
                                lg.SubGroups.forEach(sg => {
                                    item.Cards.push({ Id: lg.Id, Key: lg.Name, Value: sg.Name, Type: "LocationGroup" });
                                });
                            } else {
                                item.Cards.push({ Id: lg.Id, Key: lg.Name, Value: undefined, Type: "LocationGroup" });
                            }
                        });
                    }


                    ////Add fixture group cards
                    if (item.FixtureGroups) {
                        item.FixtureGroups.forEach(fg => {
                            if (fg.Fixtures.length > 0) {
                                fg.Fixtures.forEach(fix => {
                                    let mod = fix.Model !== undefined ? " - " + fix.Model : "";
                                    item.Cards.push({ Id: fg.FixtureGroupId, Key: fg.FixtureGroupName, Value: fix.Count + " - " + fix.FixtureName +  mod, Type: "FixtureType" });
                                });
                            } else {
                                item.Cards.push({ Id: fg.FixtureGroupId, Key: fg.FixtureGroupName, Value: undefined, Type: "FixtureType" });
                            }
                        });
                    }

                }

                    //sort the cards by key then by value
                    item.Cards.sort((a: SearchCardUIModel, b: SearchCardUIModel) => {
                        if (a.Key === "IMAGE") {
                            return -1;
                        }
                        if (b.Key === "IMAGE") {
                            return 1;
                        }

                        let ret = a.Key.toLowerCase().localeCompare(b.Key.toLowerCase());
                        if (ret === 0) {
                            ret = (a.Value as string).toLowerCase().localeCompare(b.Value ? (b.Value as string).toLowerCase() : "");
                        }
                        return ret;
                    });

            });

            if (scrollInfo) {
                scrollInfo.chunkIndex--;
                if (scrollInfo.chunkIndex > 0) {
                    this.getListData(search, scrollInfo);
                } else {
                    setTimeout(() => { window.scrollTo(0, scrollInfo.positionY); }, 500);
                }
            }
            // Return for the promise
            return true;
        });
    }

    public selectListOptions() {
        return this.ipsModal.displayTemplateScrollable(LocationListOptionModalComponent,
            {
                resolve: {
                    selectedPropertyList: this.selectedOptionList ? this.selectedOptionList.map((item) => ({ Name: item.Name, Type: item.Type })) : [],
                    editLocationGroupPermission: this.editLocationGroupPermission,
                    searchFixtureGroupPermission: this.searchFixtureGroupPermission
                }
            }, { backdrop: "static", centered: true })
            .then((response: SimpleSearchLocationGroupUIModel[]) => {
                console.log("Select List options response");
                this.selectedOptionList = response;
                this.getListData(this.searchInfo.searchText, null);

                //Save settings to cache
                localStorage.setItem("loc_search_select_options_" + this.activeProfileService.businessIdentity, JSON.stringify(this.selectedOptionList));

            }, (rejectReason) => {
                // Do nothing, this is here to prevent console error.
            });

    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        } else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    }

    ngOnDestroy() {
        this.ipsModal.closeModalInstances();
        localStorage.setItem(this.searchScrollKey, JSON.stringify({ search: this.searchInfo.searchText || "", positionY: this.scrollY, chunkIndex: this.location.chunkIndex }));
    }

    public compareFn(item1: any, item2: any): boolean {
        return item1 && item2 ? item1.value === item2.value : item1 === item2;
    }

    public onQueryChange(query: string) {
        this.query = query;
        this.querySubject.next(query);
    }

    public onFilterChange(filter: any) {
        this.selectedOption = filter;
        this.getListData(this.query, null, filter);
    }

    public toggleAdvanceSearch() {

        switch (this.advanceSearchState) {
            case "Full":
                this.advanceSearchState = "Partial";

                break;
            case "Partial":
                this.advanceSearchState = "Full";
                break;
            case "Hide":
                this.advanceSearchState = "Full";

                break;
        }
    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
            case "name":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_LOCATION_SEARCH_NAME"];
                break;
            }
        }
        return msgs;
    }

    private saveLocationSearch(): Promise<void> {
        let id = this.locationForm.value.Id;

        //Create save data object
        let data: MarketGroupSaveModel = cloneDeep(this.locationForm.value);

        data.SavedSearch = true;
        //reset the market ordianl for save
        data.Markets.forEach((m, index) => {
            m.Ordinal = index + 1;
        });

        if (id) {
            return this.marketGroupService.put<MarketGroupSaveModel>(data).then(() => {
                this.locationForm.markAsPristine();
            }).catch(err => {
                return Promise.reject(this.translateSaveError(err));
            });
        } else {
            return this.marketGroupService.post<MarketGroupSaveModel>(data, undefined, undefined).then((response) => {
                this.Id.setValue(response.Id);
                this.locationForm.markAsPristine();
            }).catch(err => {
                return Promise.reject(this.translateSaveError(err));
            });
        }
    }

    private translateSaveError(err): any {
        if (err.error && err.error.Code === "S_E_24000") {
            return {
                data: {
                    Successful: false,
                    Message: this.TranslateStrings["DUPLICATE_LOCATION_SEARCH_NAME"]
                }
            };
        } else {
            return err;
        }
    }

    public saveLocationSearchPrompt(redirect: boolean) {
        return this.ipsMessage
            .waitForWork({ body: "SAVING", workFunction: () => this.saveLocationSearch(), progressMessage: "SAVING" })
            .then(
                (result) => {
                    if (result && redirect) {
                        this.$state.go("main.survey.search");
                    }
                },
                (reject) => {
                    // do nothing
                });
    }


    public createLocationSearch() {
        //Clear out all fields
        this.locationForm.patchValue({
            Id: 0,
            Name: "",
            SavedSearch: true,
            Notes: "",
            BusinessIdentity: this.activeProfileService.businessIdentity
        });

        this.markets = [];
        this.marketEditComponent.AddNewMarkets(this.markets);

    }

    public findLocationSearch(isCopy: boolean = false) {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { search: "marketgroup" } })
            .then((response) => {

                this.loaded = false;

                let canDelete = this.marketGroupService.deleteCheck(response.item.Id.toString())
                    .then((res: any) => {
                        this.canDeleteErrorList = res;
                    })
                    .catch(() => {
                        this.canDeleteErrorList = ["-ERROR-"];
                    });

                let locSearch = this.getLocationSearch(response.item.Id).then(() => {
                    if (isCopy) {
                        this.cloneLocationSearch();
                    }


                    Promise.all([canDelete, locSearch]).then(() => {
                    this.loaded = true;
                });
                });
            }, () => { }); // Rejected
    }

    public cloneLocationSearch() {
        this.locationForm.patchValue({
            Id: 0,
            Name: ""
        });

        this.marketsToExclude = [];
        (this.locationForm.get("Markets") as FormArray).controls.forEach((item: FormGroup) => {
            item.get("Id").setValue(0);
        });
    }

    private getLocationSearch(id: string | number) {

        this.locationSearchPromise = this.marketGroupService.get<MarketGroupModel>(id).then((response: MarketGroupModel) => {

            this.markets = response.Markets || [];

            this.locationForm.patchValue({
                Id: response.Id,
                Name: response.Name,
                SavedSearch: response.SavedSearch,
                Notes: response.Notes,
                BusinessIdentity: response.BusinessIdentity
            });

            this.marketEditComponent.AddNewMarkets(this.markets);
            this.marketsToExclude = [<MarketModel>{ TargetMarketType: "MarketGroup", TargetMarketId: Number(id) }];
        });

        return this.locationSearchPromise;
    }

    public deleteLocationSearchPrompt() {

        if (this.loaded && this.canDeleteErrorList.length > 0) {
            this.ipsMessage.error("Unable to delete! Record is begin used in the following Market Groups:\r\n" + this.canDeleteErrorList.join("\r\n"));
            return null;
        }

        let translated = this.translateService.instant("PROMPT_DELETE_BODY");
        translated = String.Format(translated, this.locationForm.value.Name);
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deleteLocationSearch(), progressMessage: "DELETING" })
        .then((result: any) => {
            if (result) {
                this.createLocationSearch();
            }
        })
        .catch(() => {
            // rejection
        });
    }

    private deleteLocationSearch(): Promise<void> {
        return this.marketGroupService.delete(this.locationForm.value.Id);
    }

    public closeLocationSearch() {
        //Check if user needs to save before collapsing section
        if (this.locationForm.dirty) {
            this.ipsMessage.confirm({
                title: "WARNING",
                body: "UNSAVED_CHANGES_ADVANCED_SEARCH",
                ok: "CLOSE",
                cancel: "KEEP_OPEN"
            }).then(() => {
                this.resetAdvanceSearch();
            })
            .catch(() => {
                // rejection
            });
        } else {
            this.resetAdvanceSearch();
        }
    }

    private resetAdvanceSearch() {
        this.advanceSearchState = "Hide";
        this.markets = [];
        this.locationIds = [];
        this.createForm();
        this.getListData(this.query, null, this.selectedOption, true);
    }

    public balanceChangedEventHandler(locationBalance: number) {
    }

    public marketFinalCountChanged(locationIds: number[]) {
        //Special case when there are no locations found return -1 for id to keep location filter active
        if (locationIds.length === 0) {
            locationIds.push(-1);
        }
        this.locationIds = locationIds;
        this.getListData(this.query, null, this.selectedOption, true);
    }

    public locationDownloadClickHandler() {
        console.log("location download handler called");
        const url = `${this.locationService.urlApiBaseProductPlusVersion}Location/Download`;
        let search: MarketGroupSaveModel = cloneDeep(this.locationForm.value);
        let filter;
        if (search.Markets) {
            console.log("search markets");
            let locSearch = [];
            search.Markets.forEach(m => {
                locSearch.push({
                    Name: m.MarketName,
                    OperationType: m.OperationType,
                    Ordinal: m.Ordinal,
                    LocationBalance: m.LocationBalance
                });
            });
            filter = {
                Name: search.Name,
                LocationCriteria: locSearch
            };
        }
        let newSearchInfo = cloneDeep(this.searchInfo);
        newSearchInfo.Select = [];
        if (this.selectedOptionList && this.selectedOptionList.length > 0) {
            newSearchInfo.Select = this.selectedOptionList
                .filter((item) => item.Type === "Location" || item.Type === "Address")
                .map((item) => `${item.Type}.${item.Name}`);
        }
        let data = {
            locationSearch: newSearchInfo,
            filter: filter,
            businessIdentity: this.activeProfileService.businessIdentity
        };

        this.downloadService.PostDownload(url, data);
    }

}
