import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UIRouterModule } from "@uirouter/angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { ProfileComponent } from "./profile/profile.component";
import { STORE_PROFILE_STATES } from "./imagine-ui-ng-store-profile.states";
import { ToolsComponent } from "./tools/tools.component";
import { SortablejsModule } from "angular-sortablejs";

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CgBusyModule } from "angular-busy2";
import { TranslateModule } from "@ngx-translate/core";
import { ImagineUiNgCoreModule } from "imagine-ui-ng-core";
import { ImagineUiNgSecurityModule } from "imagine-ui-ng-security";
import { ImagineUiNgQuickStartModule } from "imagine-ui-ng-quick-start";
import { ImagineUiNgMessagingModule } from "imagine-ui-ng-messaging";

import { ListSearchModule } from "imagine-ui-ng-list-search";
import { IpsModalModule } from "imagine-ui-ng-modal";

import { SharedModule } from "../shared/shared.module";

import { StoreProfileElementComponent } from "./element/store-profile-element/store-profile-element.component";
import { ElementSearchComponent } from "./element/element-search/element-search.component";
import { ElementViewComponent } from "./element/element-view/element-view.component";
import { ElementEditComponent } from "./element/element-edit/element-edit.component";

import { StoreProfileFixtureComponent } from "./fixture/store-profile-fixture/store-profile-fixture.component";
import { FixtureSearchComponent } from "./fixture/fixture-search/fixture-search.component";
import { FixtureViewComponent } from "./fixture/fixture-view/fixture-view.component";
import { FixtureEditComponent } from "./fixture/fixture-edit/fixture-edit.component";

import { StoreProfileFixtureGroupComponent } from "./fixture-group/store-profile-fixture-group.component";
import { FixtureGroupSearchComponent } from "./fixture-group/fixture-group-search/fixture-group-search.component";
import { FixtureGroupViewComponent } from "./fixture-group/fixture-group-view/fixture-group-view.component";
import { FixtureGroupEditComponent } from "./fixture-group/fixture-group-edit/fixture-group-edit.component";

import { StoreProfileLocationComponent } from "./location/store-profile-location/store-profile-location.component";
import { LocationSearchComponent } from "./location/location-search/location-search.component";
import { LocationViewComponent } from "./location/location-view/location-view.component";

import { StoreProfileLocationGroupComponent } from "./location-group/store-profile-location-group/store-profile-location-group.component";
import { LocationGroupSearchComponent } from "./location-group/location-group-search/location-group-search.component";
import { LocationGroupListComponent } from "./location-group/location-group-list/location-group-list.component";
import { LocationGroupRelatedListComponent } from "./location-group/location-group-related-list/location-group-related-list.component";

import { StoreProfileSpaceComponent } from "./space/store-profile-space/store-profile-space.component";
import { SpaceSearchComponent } from "./space/space-search/space-search.component";
import { SpaceViewComponent } from "./space/space-view/space-view.component";
import { SpaceEditComponent } from "./space/space-edit/space-edit.component";

import { StoreProfileHolderVersionComponent } from "./holder-version/store-profile-holder-version/store-profile-holder-version.component";
import { HolderVersionSearchComponent } from "./holder-version/holder-version-search/holder-version-search.component";
import { HolderVersionViewComponent } from "./holder-version/holder-version-view/holder-version-view.component";
import { HolderVersionEditComponent } from "./holder-version/holder-version-edit/holder-version-edit.component";

import {
    BrandBulletinService, SpaceService, HolderVersionService,
    LocationGroupService, LocationGroupByLocationService,
    FixtureService, FixtureGroupService, FixtureByFixtureGroupService, FixtureBySpaceService, FixtureByLocationService,
    LocationService, LocationBySpaceService, LocationByFixtureService, LocationByLocationGroupService,
    LocationFixtureService, FixtureByLocationSumService, StoreFrontCategoryService, ElementService, SpaceFilterPipe, AssignedFilterPipe, AlternateAddressService, StringToSpacesPipe
} from "../imagine-ui-ng-store-profile";
import { LocationEditComponent } from "./location/location-edit/location-edit.component";
import { AddressEditComponent } from "./location/address-edit/address-edit.component";

import { SearchModalComponent } from "../shared/search-modal/search-modal.component";
import { LocationGroupViewComponent } from "./location-group/location-group-view/location-group-view.component";

import { CampaignService, PromotionService } from "../imagine-ui-ng-campaign";
import { LocationGroupEditComponent } from "./location-group/location-group-edit/location-group-edit.component";
import { ControlsEditComponent } from "./controls/controls-edit/controls-edit.component";
import { ControlsSearchComponent } from "./controls/controls-search/controls-search.component";
import { FileProcessingComponent } from "./file-processing/file-processing.component";
import { LocationListOptionModalComponent } from "./location/location-list-option-modal/location-list-option-modal.component";

import { SurveyService } from "../imagine-ui-ng-survey";
import { LocationGroupDeleteModalComponent } from "./location-group/location-group-delete-modal/location-group-delete-modal.component";
import { MarketModule } from "../market/market.module";
import { AlternateAddressComponent } from "./alternate-address/alternate-address.component";
import { AlternateAddressSearchComponent } from "./alternate-address/alternate-address-search/alternate-address-search.component";
import { AlternateAddressViewComponent } from "./alternate-address/alternate-address-view/alternate-address-view.component";
import { AlternateAddressEditComponent } from "./alternate-address/alternate-address-edit/alternate-address-edit.component";

import { GeneralSettingsEditComponent } from "./general-settings/general-settings-edit/general-settings-edit.component";

import { TabsModule } from "ngx-bootstrap/tabs";
import { SortableModule } from "ngx-bootstrap/sortable";
import { BrandSettingsEditComponent } from "./brand-settings/brand-settings-edit/brand-settings-edit.component";
import { StoreFrontComponent } from "./store-front/store-front/store-front.component";
import { StoreFrontCategorySearchComponent } from "./store-front-category/store-front-category-search/store-front-category-search/store-front-category-search.component";
import { StoreFrontCategoryEditComponent } from "./store-front-category/store-front-category-edit/store-front-category-edit/store-front-category-edit.component";
import { StoreFrontCategoryComponent } from "./store-front-category/store-front-category/store-front-category.component";

@NgModule({
    imports: [
        CgBusyModule,
        InfiniteScrollModule,
        CommonModule,
        UIRouterModule.forChild({ states: STORE_PROFILE_STATES }),
        TranslateModule.forChild(),
        ImagineUiNgQuickStartModule,
        ImagineUiNgSecurityModule,
        ListSearchModule,
        NgbModule,
        BsDatepickerModule,
        TypeaheadModule.forRoot(),
        SortableModule.forRoot(),
        TabsModule,
        IpsModalModule,
        ImagineUiNgMessagingModule,
        FormsModule,
        ReactiveFormsModule,
        ImagineUiNgCoreModule,
        SharedModule,
        MarketModule,
        SortablejsModule
    ],
    declarations: [ProfileComponent, ToolsComponent,
        LocationSearchComponent, StoreProfileLocationComponent, LocationViewComponent,
        SpaceSearchComponent, StoreProfileSpaceComponent, SpaceEditComponent, SpaceViewComponent,
        HolderVersionSearchComponent, StoreProfileHolderVersionComponent, HolderVersionEditComponent, HolderVersionViewComponent,
        LocationGroupSearchComponent, StoreProfileLocationGroupComponent, LocationGroupListComponent, LocationGroupEditComponent, SpaceEditComponent,
        FixtureGroupSearchComponent, StoreProfileFixtureGroupComponent, FixtureGroupViewComponent, FixtureGroupEditComponent,
        FixtureSearchComponent, FixtureViewComponent, FixtureEditComponent, StoreProfileFixtureComponent,
        ElementSearchComponent, ElementViewComponent, ElementEditComponent, StoreProfileElementComponent, SpaceFilterPipe, AssignedFilterPipe, StringToSpacesPipe,
        LocationGroupRelatedListComponent, LocationEditComponent,
        AddressEditComponent, LocationGroupViewComponent, ControlsEditComponent, ControlsSearchComponent, FileProcessingComponent, LocationListOptionModalComponent,
        LocationGroupDeleteModalComponent, AlternateAddressComponent, AlternateAddressSearchComponent, AlternateAddressViewComponent, AlternateAddressEditComponent,
        GeneralSettingsEditComponent,
        BrandSettingsEditComponent,
        StoreFrontComponent,
        StoreFrontCategorySearchComponent,
        StoreFrontCategoryEditComponent,
        StoreFrontCategoryComponent
    ],
    entryComponents: [SearchModalComponent, LocationListOptionModalComponent, LocationGroupDeleteModalComponent],
    providers: [
        BrandBulletinService,
        LocationService,
        LocationBySpaceService,
        LocationByFixtureService,
        LocationByLocationGroupService,
        FixtureService,
        FixtureGroupService,
        FixtureByFixtureGroupService,
        FixtureByLocationService,
        FixtureByLocationSumService,
        StoreFrontCategoryService,
        FixtureBySpaceService,
        LocationFixtureService,
        LocationGroupService,
        LocationGroupByLocationService,
        ElementService,
        SpaceService,
        CampaignService,
        PromotionService,
        DatePipe,
        SurveyService,
        HolderVersionService,
        AlternateAddressService
    ]
})
export class ImagineUiNgStoreProfileModule { }
