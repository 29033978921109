import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { BaseWebServiceWithDefaultImage } from "imagine-ui-ng-quick-start";
import { FixtureModel } from "../../model/FixtureModel";
import { environment } from "../../../../environments/environment";

@Injectable()
export class FixtureService extends BaseWebServiceWithDefaultImage {
    private businessId: string;
    private token: string;

    constructor(http: HttpClient, activeProfileService: ActiveProfileService, authService: AuthService) {
        super(http, <any>environment, activeProfileService, authService);
        this.init("Fixture", "Profile", "v1");

        this.businessId = activeProfileService.businessIdentity;
        this.token = authService.getIdToken();
    }

    getWithUnassignedHolders(id: number): Promise<FixtureModel> {
        return this.getByRoute(`${this.urlApiBasePlusController}${id}?includeUnassignedHolders=true`);
    }

    getLayoutDetails(fixtureId: number): Promise<any> {
        return this.getByRoute(`${this.urlApiBasePlusController}${fixtureId}/Layout`);
    }

    putLayoutDetails(model: any, files?: any): Promise<any> {
        let url = `${this.urlApiBaseProductPlusVersion}FixtureLayout`;
        return this.put(model, files, url);
    }

    getAttachedDocuments(fixtureId: number): Promise<any> {
        let url = `${this.urlApiBasePlusController}${fixtureId}/Documents`;
        return this.getByRoute(url);
    }

    getAttachmentMediaUrl(mediaId: string): string {
        let url = `${this.urlApiBase}Media/v1/MasterMedia/${this.businessId}/Document/${mediaId}/Original/Jpg?BusinessIdentity=${this.businessId}&idToken=${this.token}`;
        return url;
    }

    deleteAttachment(id: number): Promise<any> {
        let url = `${this.urlApiBasePlusController}Document/${id}`;
        return this.deleteByRoute(url);
    }

    addAttachment(model: any, file?: any): Promise<any> {
        let url = `${this.urlApiBasePlusController}Document`;
        return this.post(model, file, url);
    }

    deleteCheck(id: string) {
        let route = `${this.urlApiBasePlusController}${id}/DeleteCheck`;
        return this.getByRoute(route);
    }
}

