var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
var SignPlanService = /** @class */ (function (_super) {
    __extends(SignPlanService, _super);
    function SignPlanService(http, activeProfileService, helperFunctionsService, authService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.helperFunctionsService = helperFunctionsService;
        _this.authService = authService;
        _this.token = null;
        _this.SignPlanWidthOptions = [
            {
                key: "Select a Width",
                value: null
            },
            {
                key: "1/6",
                value: 2
            },
            {
                key: "1/4",
                value: 3
            },
            {
                key: "1/3",
                value: 4
            },
            {
                key: "1/2",
                value: 6
            },
            {
                key: "2/3",
                value: 8
            },
            {
                key: "3/4",
                value: 9
            },
            {
                key: "5/6",
                value: 10
            },
            {
                key: "Full",
                value: 12
            }
        ];
        _this.init("", "", "v1");
        _this.currentBusinessId = _this.activeProfileService.businessIdentity;
        _this.token = _this.authService.getIdToken();
        return _this;
    }
    SignPlanService.prototype.getHolderPreviewImage = function (id) {
        return this.getPreviewImage(id, "Holder");
    };
    SignPlanService.prototype.getElementPreviewImage = function (id) {
        return this.getPreviewImage(id, "Element");
    };
    SignPlanService.prototype.getPreviewImage = function (id, type) {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            var route = _this.urlApiBase + "Campaign/v1/Campaign/PreviewSignImage/" + id + "/TypeId/" + type;
            return _this.getByRoute(route).then(function (response) {
                if (response) {
                    var imageUrl = _this.buildSignPlanImageUrl(response);
                    resolve(imageUrl);
                }
                else {
                    resolve(null);
                }
            }).catch(function () {
                resolve(null);
            });
        });
        return returnPromise;
    };
    SignPlanService.prototype.buildSignPlanImageUrl = function (fileName) {
        var url = this.urlApiBase + "Media/v1/MasterMedia/" + this.currentBusinessId + "/CampaignSign/" + fileName + "/Original/Png?BusinessIdentity=" + this.currentBusinessId + "&idToken=" + this.token;
        return url;
    };
    return SignPlanService;
}(IpsBaseWebService));
export { SignPlanService };
