/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ngx-loading-bar/core/ngx-loading-bar-core.ngfactory";
import * as i3 from "@ngx-loading-bar/core";
import * as i4 from "../../node_modules/@uirouter/angular/lib/directives/uiView.ngfactory";
import * as i5 from "@uirouter/angular/lib/directives/uiView";
import * as i6 from "@uirouter/core";
import * as i7 from "./app.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "imagine-ui-ng-translation";
import * as i10 from "imagine-ui-ng-quick-start";
import * as i11 from "angulartics2/ga";
import * as i12 from "./version-check/version-check.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-loading-bar", [], [[2, "loading-bar-fixed", null]], null, null, i2.View_LoadingBarComponent_0, i2.RenderType_LoadingBarComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingBarComponent, [i3.LoadingBarService], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "ui-view", [], null, null, null, i4.View_UIView_0, i4.RenderType_UIView)), i1.ɵdid(3, 245760, null, 0, i5.UIView, [i6.UIRouter, "UIView.PARENT_INJECT", i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppComponent, [i6.StateService, i8.TranslateService, i9.IpsLanguageService, i10.ProfileService, i10.IpsAnalyticsService, i11.Angulartics2GoogleAnalytics, i12.VersionCheckService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
