var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Copied from https://github.com/simontonsoftware/s-ng-utils
// Removed dependency on micro-dash
import { ChangeDetectorRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { AutoDestroyable } from "./auto-destroyable.class";
/**
 * Use in the `providers` of a component that implements `ControlValueAccessor` to reduce some boilerplate.
 *
 * ```ts
 * @Component({ providers: [provideValueAccessor(MyFormControl)] }
 * class MyFormControl extends BaseFormControl {
 *   // ...
 * }
 * ```
 */
export function provideValueAccessor(type) {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: type,
        multi: true,
    };
}
export function noop() { }
// End micro-dash dependencies
/**
 * Extend this when creating a form control to reduce some boilerplate. **Warning:** You _must_ include a constructor in your subclass.
 *
 * This example allows 2-way binding to a number via `[(ngModel)]`, `[formControl]`, or any other technique that leverages the `ControlValueAccessor` interface.
 * ```ts
 * @Component({
 *   template: `
 *     <button (click)="increment()" [disabled]="isDisabled">{{ counter }}</button>
 *   `,
 *   providers: [provideValueAccessor(CounterComponent)],
 * })
 * class CounterComponent extends FormControlSuperclass<number> {
 *   counter = 0;
 *
 *   // This looks unnecessary, but is required for Angular to provide `Injector`
 *   constructor(injector: Injector) {
 *     super(injector);
 *   }
 *
 *   handleIncomingValue(value: number) {
 *     this.counter = value;
 *   }
 *
 *   increment() {
 *     this.emitOutgoingValue(++this.counter);
 *     this.onTouched();
 *   }
 * }
 * ```
 */
var FormControlSuperclass = /** @class */ (function (_super) {
    __extends(FormControlSuperclass, _super);
    function FormControlSuperclass(injector) {
        var _this = _super.call(this) || this;
        /** Call this to emit a new value when it changes. */
        _this.emitOutgoingValue = noop;
        /** Call this to "commit" a change, traditionally done e.g. on blur. */
        _this.onTouched = noop;
        /** You can bind to this in your template as needed. */
        _this.isDisabled = false;
        _this.changeDetectorRef = injector.get(ChangeDetectorRef);
        return _this;
    }
    /** Called as angular propagates values changes to this `ControlValueAccessor`. You normally do not need to use it. */
    FormControlSuperclass.prototype.writeValue = function (value) {
        this.handleIncomingValue(value);
        this.changeDetectorRef.markForCheck();
    };
    /** Called as angular sets up the binding to this `ControlValueAccessor`. You normally do not need to use it. */
    FormControlSuperclass.prototype.registerOnChange = function (fn) {
        this.emitOutgoingValue = fn;
    };
    /** Called as angular sets up the binding to this `ControlValueAccessor`. You normally do not need to use it. */
    FormControlSuperclass.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /** Called as angular propagates disabled changes to this `ControlValueAccessor`. You normally do not need to use it. */
    FormControlSuperclass.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
        this.changeDetectorRef.markForCheck();
    };
    return FormControlSuperclass;
}(AutoDestroyable));
export { FormControlSuperclass };
