import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from "@angular/core";

import { FormGroup, FormControl, FormArray } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

import {
    PromotionMessagePatternGroupModel, PromotionMessageService, MessageNameChangeModel,
    PromotionSaveModel
} from "../../index";

import { MarketModel } from "../../../market/index";

import { PromotionHelperService } from "../../service/promotionHelper.service";

import { of as observableOf } from "rxjs";
import { takeWhile } from "rxjs/operators";

import { CustomDataFieldContainerComponent } from "../../../shared/custom-data-field/custom-data-field-container/custom-data-field-container.component";
import { PromotionMessagePatternModel } from "../../model/PromotionMessagePatternModel";

@Component({
    selector: "app-message-edit",
    templateUrl: "./message-edit.component.html",
    styleUrls: ["./message-edit.component.scss"]
})
export class MessageEditComponent implements OnInit {
    @ViewChildren(CustomDataFieldContainerComponent) customDataFieldContainers: QueryList<CustomDataFieldContainerComponent>;

    @Input() patternGroupsModel: PromotionMessagePatternGroupModel[];
    @Input() parent: FormGroup;
    @Input() tempMessageId: number;
    @Input() isClone = false;

    @Output() messageDeleted = new EventEmitter<any>();
    @Output() messageAdded = new EventEmitter<any>();
    @Output() marketChanged = new EventEmitter<any>();
    @Output() messageNameChanged = new EventEmitter<MessageNameChangeModel>();
    @Output() cdfsLoaded = new EventEmitter<void>();

    public loaded = false;
    public messageForm: FormGroup;
    private loadedMsgCount = 0;
    private cdfLoadedCount = 0;

    public focusableMessageInput: string;
    public messageNames: string[];

    private errorMessages = {
        "required": () => this.TranslateStrings["PLEASE_ENTER_MESSAGE"],
        "maxlength": (params) => IpsString.Format(this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength),
        "messageNameUniqueToCampaign": () => this.TranslateStrings["MESSAGE_NAME_UNIQUE_TO_CAMPAIGN"],
        "invalidMarketName": () => this.TranslateStrings["PLEASE_ENTER_VALID_MARKET"]
    };

    private TranslateStrings: { [key: string]: string } = {
        "PLEASE_ENTER_VALID_MARKET": "",
        "MARKET_PENDING_LOCATIONS_WARNING": "",
        "MESSAGE_NAME_UNIQUE_TO_CAMPAIGN": "",
        "PLEASE_ENTER_MESSAGE": "",
        "PLEASE_ENTER_MARKET": "",
        "MAX_LENGTH_ERROR": ""
    };

    constructor(
        private promotionHelperService: PromotionHelperService, private ipsMessage: IpsMessageService, private translateService: TranslateService,
        public promotionMessageService: PromotionMessageService) {
    }

    ngOnInit() {
        this.translateService.get("ALL").subscribe(() => this.translateText());
        this.promotionHelperService.tempMessageId = this.tempMessageId;
        this.promotionHelperService.createForm(this.parent, this.messageNameChanged, false);
        //Initial call to configure data loaded by promotion controller
        if (!this.parent.get("ClearAllMessages").value) {
            this.loadedMsgCount = this.promotionHelperService.initializeForm(this.patternGroupsModel, this.isClone);
        }

        this.messageNames = this.promotionHelperService.getMessageSuggestions();
        this.getFocusableMessageInput();

        //After loading message edit section force it to validate message name
        this.MessagePatterns(0).controls[0].get("MessageName").updateValueAndValidity();
    }

    public cdfLoadedHandler() {
        this.cdfLoadedCount++;
        if (this.cdfLoadedCount === this.loadedMsgCount) {
            this.cdfsLoaded.emit();
        }
    }

    public getOriginalMarkets(patternGroupCtrl: FormGroup): MarketModel[] {
        const name = patternGroupCtrl.get("Name").value;


        let foundItem = this.patternGroupsModel.find(p => p.Name === name);

        if (foundItem) {
            return foundItem.Markets;
        }
        return [];
    }

    private translateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "market":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_MARKET"];
                    break;
            }
        }
        return msgs;
    }

    public removeMainMessagePrompt(messageIndex: number, message: FormControl, toolTip: NgbTooltip) {
        toolTip.close();

        if (this.placementsExist(messageIndex)) {
            this.ipsMessage.confirmDelete({ body: "PROMPT_DELETE_PROMOTION_MESSAGE" })
            .then((response) => {
                if (response) {
                    this.removeMainMessage(messageIndex, message);
                }
            })
            .catch(() => {
                // rejection
            });
        } else {
            this.removeMainMessage(messageIndex, message);
        }
    }

    private removeMainMessage(messageIndex: number, message: FormControl) {
        let patternGroups = this.PatternGroups;
        let deletedObj = patternGroups.at(messageIndex).value as PromotionMessagePatternGroupModel;
        patternGroups.removeAt(messageIndex);

        // raise event to promotion component for placements. Need to return the actual message that was deleted, which is always the first one in our case.
        this.messageDeleted.emit(deletedObj.MessagePatterns[0]);
        this.parent.markAsDirty();
    }

    public placementsExist(messageIndex: number): boolean {
        return this.promotionHelperService.PlacementsExist(this.parent);
    }

    public marketChangedEventHandler(patternGroupCtrl: FormGroup) {
        this.marketChanged.emit(patternGroupCtrl.get("TempPatternGroupId").value);
    }

    get PatternGroups(): FormArray {
        return this.parent.controls.messageForm.get("PatternGroups") as FormArray;
    }

    public MessagePatterns(index: number): FormArray {
        return this.parent.controls.messageForm.get(["PatternGroups", index]).get("MessagePatterns") as FormArray;
    }

    //jquery.ui.touch.punch prevents the input/textarea to be selected
    //this method set focus on input when it's clicked
    public setFocus(event: KeyboardEvent): void {
        let element = <HTMLElement>event.target;
        element.focus();
    }

    public updateMessageTypeahead() {
        this.messageNames = this.promotionHelperService.getMessageSuggestions();
    }

    public createMessage(): void {
        let patternGroups = this.PatternGroups;

        let patternGroup = this.promotionHelperService.newPatternGroup(false);
        patternGroups.push(patternGroup);

        let addedObj = patternGroup.value as PromotionMessagePatternGroupModel;
        this.messageAdded.emit(addedObj.MessagePatterns[0]);
        this.getFocusableMessageInput();
    }

    //auto focus
    private getFocusableMessageInput() {
        this.focusableMessageInput = this.promotionHelperService.getFocusableMessageInput();
    }

    public prepareSaveModel(promotionSaveModel: PromotionSaveModel): any {

        promotionSaveModel.PatternGroups = this.PatternGroups.value;

        promotionSaveModel.PatternGroups.forEach(patternGroup => {
            //update ordinal by index
            patternGroup.Markets.forEach((market, index) => market.Ordinal = index + 1);

        });

    }


    public saveCustomData(saveModel: PromotionSaveModel): Promise<any>[] {
        this.promotionHelperService.patchMessageIdsAfterSave(saveModel);
        let promises: Promise<any>[] = [];
        this.customDataFieldContainers.forEach(container => {
            const msgId = (container.parent.value as PromotionMessagePatternModel).PromotionMessageId;
            promises.push(container.save(msgId));
        });

        return promises;
    }
}
