<div class="col-12" ipsFocus="searchInput">
    <div search-title>
        <section class="pt-3 pb-2">
            <h3>
                <span class="ips-ls-header-label-imgn ips-gray-color" translate>REPORTS</span><a [href]="" *ngIf="selectedLocation > 0 || selectedReportType==='productneedweight'" (click)="reportDownloadClickHandler($event)"><span class="fa fa-arrow-circle-down ips-gray-color clicker"></span></a><span *ngIf="selectedLocation == 0 && selectedReportType !=='productneedweight'" class="fa fa-arrow-circle-down ips-gray-color"></span>
            </h3>
        </section>
    </div>
    <div [cgBusy]="promiseMain" cgBusyConfig="{message:{{'ACCESSING_REPORT_INFO' | translate}}'}">
        <div *ngIf="loaded">
            <div class="row">
                <section class="col-12 pb-3">
                    <div class="report-header">
                        <div class="row pt-2" *ngIf="selectedReportType==='' || selectedReportType==='productneedweight'">
                            <div class="col-12 col-md-4 form-group mb-md-0">
                                <!--Report Selector-->
                                <label class="required" translate>REPORTS</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_REPORT_INFO' | translate}}" placement="bottom"></i>
                                <select name="reportTypes" id="reportTypes" [(ngModel)]="selectedReportType"
                                        class="form-control dropdownBtn form-control-lg ellipsis"
                                        (ngModelChange)="changeReportType()">
                                    <option *ngFor="let item of reportOptions" [ngValue]="item.value">{{item.text}}</option>
                                </select>
                            </div>
                        </div>
                        <!--Content List Selected-->
                        <div class="row pt-2" *ngIf="selectedReportType==='contentlist'">
                            <div class="col-12 col-md-4 form-group mb-md-0">
                                <!--Report Selector-->
                                <label class="required" translate>REPORTS</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_REPORT_INFO' | translate}}" placement="bottom"></i>
                                <select name="reportTypes" id="reportTypes" [(ngModel)]="selectedReportType"
                                        class="form-control dropdownBtn form-control-lg ellipsis"
                                        (ngModelChange)="changeReportType()"
                                        appValidation required>
                                    <option *ngFor="let item of reportOptions" [ngValue]="item.value">{{item.text}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-4 form-group mb-md-0">
                                <label class="required" translate>LOCATION</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_LOCATION_INFO' | translate}}" placement="bottom"></i>
                                <button type="button" class="btn btn-lg btn-default form-control long-text-wrap" (click)="changeLocation()" [disabled]="disableLocation">{{LocationLabel}}</button>
                            </div>
                            <div class="col-12 col-md-4 form-group mb-1 mb-md-0">
                                <label translate>CAMPAIGN</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_CAMPAIGN_INFO' | translate}}" placement="bottom"></i>
                                <button type="button" class="btn btn-lg btn-default form-control long-text-wrap" (click)="changeCampaign()">{{CampaignLabel}}</button>
                            </div>
                        </div>
                        <!--Expired Items Selected-->
                        <div class="row pt-2" *ngIf="selectedReportType==='expireditem'">
                            <div class="col-12 col-md-6 col-lg-3 form-group mb-lg-0">
                                <!--Report Selector-->
                                <label class="required" translate>REPORTS</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_REPORT_INFO' | translate}}" placement="bottom"></i>
                                <select name="reportTypes" id="reportTypes" [(ngModel)]="selectedReportType"
                                        class="form-control dropdownBtn form-control-lg ellipsis"
                                        (ngModelChange)="changeReportType()"
                                        appValidation required>
                                    <option *ngFor="let item of reportOptions" [ngValue]="item.value">{{item.text}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3 form-group mb-lg-0">
                                <label class="required" translate>LOCATION</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_LOCATION_INFO' | translate}}" placement="bottom"></i>
                                <button type="button" class="btn btn-lg btn-default form-control long-text-wrap" (click)="changeLocation()" [disabled]="disableLocation">{{LocationLabel}}</button>
                            </div>
                            <div class="col-12 col-lg-6 form-group mb-1 mb-md-0">
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-7 col-xl-6 form-group mb-1 mb-md-0">
                                        <form name="dateForm" novalidate #dateForm="ngForm" role="form">
                                            <app-ips-date-range [startDate]="fromDateModel" [endDate]="toDateModel" [daySpan]="30" (rangeChanged)="changeDateRange($event)"></app-ips-date-range>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!--Up Down Report Selected-->
                        <!--<div class="row pt-2" *ngIf="selectedReportType==='updown'">
                            <div class="col-12 col-md-4 form-group mb-md-0">
                                Report Selector
                                <label class="required" translate>REPORTS</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_REPORT_INFO' | translate}}" placement="bottom"></i>
                                <select name="reportTypes" id="reportTypes" [(ngModel)]="selectedReportType"
                                        class="form-control dropdownBtn form-control-lg ellipsis"
                                        (ngModelChange)="changeReportType()"
                                        appValidation required>
                                    <option *ngFor="let item of reportOptions" [ngValue]="item.value">{{item.text}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-4 form-group mb-md-0">
                                <label class="required" translate>LOCATION</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_LOCATION_INFO' | translate}}" placement="bottom"></i>
                                <button type="button" class="btn btn-lg btn-default form-control long-text-wrap" (click)="changeLocation()" [disabled]="disableLocation">{{LocationLabel}}</button>
                            </div>
                            <div class="col-12 col-md-4 form-group mb-1 mb-md-0">
                                <label translate>CAMPAIGN</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_CAMPAIGN_INFO' | translate}}" placement="bottom"></i>
                                <button type="button" class="btn btn-lg btn-default form-control long-text-wrap" (click)="changeCampaign()">{{CampaignLabel}}</button>
                            </div>
                            <div class="col-12 form-group mb-1 mb-md-0 mt-3">
                                <button type="button" class="btn btn-lg btn-default btn-outline-blue form-control long-text-wrap" (click)="downloadUpDown()" [disabled]="selectedLocation === 0 || selectedCampaign === 0 ">
                                    <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                                    <span translate>DOWNLOAD_UP_DOWN_REPORT</span>
                                </button>
                            </div>
                        </div>-->


                        <!--Imagine Demo Report Selected-->
                        <div class="row pt-2" *ngIf="selectedReportType==='imaginedemo'">

                            <div class="col-12 mb-3" *ngIf="brandBulletin">
                                <div class="extraSpace visible-xs-block visible-sm-block"></div>
                                <div class="extraSpace"></div>
                                <div id="noticeContainer" class="notice-container">
                                    <div class="notice-label-set"><i class="fa fa-bell notice-icon" aria-hidden="true"></i>{{'NOTICE_ALLCAPS' | translate}}:</div>
                                    <div class="notice-text">{{brandBulletin.Value}}</div>
                                </div>
                            </div>

                            <div class="col-12 col-md-4 form-group mb-md-0">
                                <!--Report Selector-->
                                <label class="required" translate>REPORTS</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_REPORT_INFO' | translate}}" placement="bottom"></i>
                                <select name="reportTypes" id="reportTypes" [(ngModel)]="selectedReportType"
                                        class="form-control dropdownBtn form-control-lg ellipsis"
                                        (ngModelChange)="changeReportType()"
                                        appValidation required>
                                    <option *ngFor="let item of reportOptions" [ngValue]="item.value">{{item.text}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-4 form-group mb-md-0">
                                <label class="required" translate>LOCATION</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_LOCATION_INFO' | translate}}" placement="bottom"></i>
                                <button type="button" class="btn btn-lg btn-default form-control long-text-wrap" (click)="changeLocation()" [disabled]="disableLocation">{{LocationLabel}}</button>
                            </div>
                            <div class="col-12 col-md-4 form-group mb-1 mb-md-0">
                                <label translate>REPORT_DATE</label>
                                <div class="input-group ips-input-group-icon">
                                    <input type="text" name="selectedStartDate" id="selectedStartDate" class="form-control form-control-lg input-full"
                                           [(ngModel)]="selectedStartDate"
                                           (ngModelChange)="changeDateRangeUpDown()"
                                           placeholder="{{ 'ENTER_REPORT_DATE' | translate }}"
                                           #d="bsDatepicker" bsDatepicker [bsConfig]="startDateOptions" triggers="" />
                                    <span class="input-group-append">
                                        <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 form-group mb-1 mb-md-0 mt-3">
                                <button type="button" class="btn btn-lg btn-default btn-outline-blue form-control long-text-wrap" (click)="downloadImagineDemo()" [disabled]="selectedLocation === 0 || !selectedStartDate">
                                    <i class="fa fa-object-group" aria-hidden="true"></i>
                                    <span translate>GENERATE_IMAGINE_UPDOWN_REPORT</span>
                                </button>
                            </div>

                            <div *ngIf="downloadfileGuid" class="col-12 form-group mb-1 mb-md-0 my-4">
                                <button type="button" class="btn btn-lg alert-success btn-outline-blue form-control long-text-wrap" (click)="downloadImagineDemoLink()" [disabled]="selectedLocation === 0 || !selectedStartDate">
                                    <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                                    <span>{{ 'DOWNLOAD' | translate }}: {{downloadfileName}}</span>
                                </button>
                            </div>
                        </div>


                    </div>
                </section>
            </div>
            <div *ngIf="showResultGrid">
                <ips-list-search labelLoading="{{'ACCESSING_INFO' | translate}}"
                                 searchLabel="{{searchLabel}}"
                                 searchLabelPlural="{{searchLabelPlural}}"
                                 labelLoadingError="{{'ERROR_ACCESSING_INFO' | translate}}"
                                 [hideHeader]="true"
                                 [query]="query"
                                 [listData]="dataHelper"
                                 [busy]="busy"
                                 (onListDataChange)="getListData($event.search, $event.filterOption)"
                                 [scroller]=true
                                 [promise]="promise"
                                 [autoFocus]=true>
                    <ng-template #listSearchItemTemplate let-result="result">
                        <div class="px-3 pt-3" *ngIf="selectedReportType!=='productneedweight'">
                            <p class="h2 mb-1" innerHtml="{{result.MessageName | highlight : query}}"></p>
                            <p class="h2 mb-3" innerHtml="{{result.FixtureElementLabel | highlight : query}}"></p>
                        </div>

                        <div class="px-3 pt-3" *ngIf="selectedReportType==='productneedweight'">
                            <p class="h2 mb-1" innerHtml="{{result.Description | highlight : query}}"></p>
                        </div>
                        <app-search-cards [CardData]="result.Cards" [LocationId]="result.Id" [EditOrViewPermission]="editOrViewPermission" [FullImage]="result.FullImage"
                                          [SearchText]="query"></app-search-cards>
                    </ng-template>
                </ips-list-search>
            </div>
        </div>
    </div>
</div>
