import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIRouterModule } from "@uirouter/angular";
import { UserSearchComponent } from "./user/user-search/user-search.component";
import { UserViewComponent } from "./user/user-view/user-view.component";
import { UserEditComponent } from "./user/user-edit/user-edit.component";
import { UserComponent } from "./user/user/user.component";

import { PROFILE_STATES } from "./imagine-ui-ng-profile.states";


import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimezonePickerModule } from "ng2-timezone-selector";

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CgBusyModule } from "angular-busy2";
import { TranslateModule } from "@ngx-translate/core";
import { ImagineUiNgCoreModule } from "imagine-ui-ng-core";
import { ImagineUiNgSecurityModule } from "imagine-ui-ng-security";
import { ImagineUiNgQuickStartModule } from "imagine-ui-ng-quick-start";
import { ImagineUiNgMessagingModule } from "imagine-ui-ng-messaging";
import { ListSearchModule } from "imagine-ui-ng-list-search";
import { IpsModalModule } from "imagine-ui-ng-modal";
import { ThemeComponent } from "./theme/theme.component";
import { SetupThemeComponent } from "./setup-theme/setup-theme.component";
import { ColorPickerModule } from "ngx-color-picker";

@NgModule({
    imports: [
        CgBusyModule,
        InfiniteScrollModule,
        CommonModule,
        UIRouterModule.forChild({ states: PROFILE_STATES }),
        TranslateModule.forChild(),
        ImagineUiNgQuickStartModule,
        ImagineUiNgSecurityModule,
        ListSearchModule,
        NgbModule,
        BsDatepickerModule,
        IpsModalModule,
        ImagineUiNgMessagingModule,
        FormsModule,
        ReactiveFormsModule,
        ImagineUiNgCoreModule,
        TimezonePickerModule,
        ColorPickerModule
  ],
    declarations: [UserSearchComponent, UserViewComponent, UserEditComponent, UserComponent, ThemeComponent, SetupThemeComponent],
    providers: [
    ]
})
export class ImagineUiNgProfileModule { }
