<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3 translate>HELP_DOCUMENTS</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:'{{'ACCESSING_HELP_DOCUMENTS' | translate}}'}">
        <div *ngIf="loaded">
            <div class="row my-3">
                <div class="col-12">
                    <div class="list-group" *ngFor="let document of attachedDocuments">
                        <li class="list-group-item"><a *ngIf="document.LinkSubType !== 5" [href]="attachmentUrl(document)" class="pr-3">{{ document.Label }}</a><a *ngIf="document.LinkSubType === 5" [href]="document.Label2" target="_blank" class="pr-3">{{ document.Label }}</a></li>
                    </div>
                    <div *ngIf="!attachedDocuments || attachedDocuments.length < 1">
                        <span class="fa fa-info-circle"></span> There are no Help Documents added.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
