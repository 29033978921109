import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from "@angular/core";
import { CustomDataWithValueModel } from "../../model/CustomDataWithValueModel";
import { AreaType } from "../../type/AreaType";
import { CustomDataFieldService } from "../../service/custom-data-field.service";
import { FormGroup, FormControl, FormArray, FormBuilder } from "@angular/forms";
import { CustomDataValueModel } from "../../../shared/model/CustomDataValueModel";
import { CustomDataValueSaveModel } from "../../../shared/model/CustomDataValueSaveModel";
import { TranslateService } from "@ngx-translate/core";
import { LoggingService, ActiveProfileService } from "imagine-ui-ng-core";
import { SubAreaType } from "../../type/SubAreaType";
import { IpsDateComponent } from "../ips-date/ips-date.component";

interface ValueChangeEventData {
    values: CustomDataWithValueModel[];
    initialLoad: boolean;
}

@Component({
    selector: "app-custom-data-field-container",
    templateUrl: "./custom-data-field-container.component.html",
    styleUrls: ["./custom-data-field-container.component.scss"]
})
export class CustomDataFieldContainerComponent implements OnInit, OnChanges {

    @Input() area: AreaType;
    @Input() linkId: number;
    @Input() parent: FormGroup;
    @Input() subArea: SubAreaType = "Unknown";
    @Input() minFieldSize = "";
    @Input() defaultValues: CustomDataWithValueModel[];
    @Input() isDefault = false; // Set to true if this container will be used for setting default values

    @Output() valuesChanged = new EventEmitter<ValueChangeEventData>();
    @Output() loaded = new EventEmitter<void>();

    private cdfService: CustomDataFieldService;
    private currentDefaultValues: CustomDataWithValueModel[] = [];

    public customDataResult: CustomDataWithValueModel[] = [];
    public promise: Promise<void>;
    public cdfForm: FormGroup;
    public fieldValues: FormArray;
    private loadedControlsCount = 0;
    private defaultInitializeRequired = false;
    private containerTitle: string;
    private itemIndexer = 0;

    private initLoadValues: any;

    private TranslateStrings: { [key: string]: string } = {
        "CUSTOM_DATA_FIELDS_SAVE_ERROR": "",
        "CUSTOM_DATA_FIELDS": "",
        "CUSTOM_DATA_FIELD_DEFAULTS": ""
    };

    constructor(private fb: FormBuilder, customDataFieldService: CustomDataFieldService, private translateService: TranslateService, private logger: LoggingService, private activeProfileService: ActiveProfileService) {
        this.cdfService = customDataFieldService;
    }

    ngOnInit() {
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(() => this.TranslateText());

        this.createForm();

        this.getData();
    }

    ngOnChanges(changes: SimpleChanges) {
        let defaultChange = changes["defaultValues"];
        if (typeof defaultChange !== "undefined") {

            if (this.fieldValues) {
                this.updateDefaultValues(defaultChange.currentValue);
            } else {
                this.initLoadValues = defaultChange.currentValue;
            }
        }
    }

    private TranslateText() {
        this.translateService.get("ALL").subscribe(() => {
            for (let key of Object.keys(this.TranslateStrings)) {
                this.TranslateStrings[key] = this.translateService.instant(key);
            }
        });

        if (this.isDefault) {
            this.containerTitle = this.TranslateStrings["CUSTOM_DATA_FIELD_DEFAULTS"];
        } else {
            this.containerTitle = this.TranslateStrings["CUSTOM_DATA_FIELDS"];
        }
    }

    private createForm() {
        this.fieldValues = new FormArray([]);

        this.cdfForm = new FormGroup({
            FieldValues: this.fieldValues
        });
        this.parent.addControl("FieldValues", this.cdfForm);
    }

    private getData() {
        if (!this.linkId) {
            this.linkId = 0;
        }
        this.defaultInitializeRequired = this.linkId === 0;

        this.promise = this.cdfService.getCustomDataWithValue(this.area, this.linkId, this.subArea).then((response) => {
            Object.assign(this.customDataResult, response);

            //Figure out field width
            this.customDataResult.forEach(item => {
                item["CustomClass"] = [item.FieldType === "Text" && item.SelectionRule === "Multiple" ? "col-12" : this.minFieldSize || "col-6 col-xl-4"];

                item.Values.forEach(valueItem => {
                    valueItem["ValidationId"] = valueItem.CustomDataOptionId;
                });

                if (item.FieldType === "List" && item.CustomDataOptionList) {
                    item.CustomDataOptionList.sort((n1, n2) => n1.Name.localeCompare(n2.Name));
                }
                if (this.isDefault) {
                    // Turn off required for each field if this is a default value container, meaning default values are optional always.
                    item.IsFieldRequired = false;
                }
            });

            if (this.isDefault) {
                //initalize defaultValues
                //raised event to custom fields that default values changed, but don't update the controls
                this.valuesChanged.emit({ values: this.customDataResult, initialLoad: true });
            }

            if (this.customDataResult.length === 0) {
                this.loaded.emit();
            }

        });
    }

    public save(parentId: number = null): Promise<CustomDataValueSaveModel[]> {

        let linkId = parentId || this.linkId;
        let valueList: CustomDataValueModel[] = [];
        this.fieldValues.controls.forEach((item: FormGroup) => {
            valueList = valueList.concat(this.getFormGroupValue(item, true));
        });

        let saveData: CustomDataValueSaveModel[] = [
            {
                LinkId: linkId,
                AreaId: this.area,
                BusinessIdentity: this.activeProfileService.businessIdentity,
                CustomDataValueList: valueList,
                SubAreaId: this.subArea
            }];

        return this.cdfService.putCustomData(saveData).catch((err) => {
            this.logger.error("Failed to save Custom Data Fields", undefined);
            const msg = err ? err.error ? err.error.Message : "" : "";
            return Promise.reject({ Message: `${this.TranslateStrings["CUSTOM_DATA_FIELDS_SAVE_ERROR"]} <br /> ${msg}`});
        });
    }

    private getFormGroupValue(item: FormGroup, filterEmptyItem: boolean = false): CustomDataValueModel[] {
        let value = item.value;
        let valueList: CustomDataValueModel[] = [];
        if (value.Items && value.Items.length > 0) {
            //List
            value.Items.forEach(listItem => {
                if (!filterEmptyItem) {
                    valueList.push({ Id: listItem.Id, CustomDataId: listItem.CustomDataId, Text: listItem.Name, CustomDataOptionId: listItem.ValidationId });
                } else if (listItem.Name) {
                    valueList.push({ Id: listItem.Id, CustomDataId: listItem.CustomDataId, Text: listItem.Name, CustomDataOptionId: listItem.ValidationId });
                }
            });

        } else if (value.Date) {
            //Date
            valueList.push({ Id: value.Id, CustomDataId: value.CustomDataId, Date: value.Date });
        } else if (value.Text) {
            //Text
            valueList.push({ Id: value.Id, CustomDataId: value.CustomDataId, Text: value.Text });
        } else if (value.Number) {
            //Number
            valueList.push({ Id: value.Id, CustomDataId: value.CustomDataId, Text: value.Number });
        } else if (value.email) {
            //Email
            valueList.push({ Id: value.Id, CustomDataId: value.CustomDataId, Text: value.email });
        }


        return valueList;
    }

    private setFormGroupValue(newValue: CustomDataWithValueModel, fieldFormGroup: FormGroup) {
        if (newValue.FieldType === "List") {
            // set list value
            if (newValue.Values.length > 0) {
                let itemsArray = fieldFormGroup.get("Items") as FormArray;
                itemsArray.controls.length = 0;
                newValue.Values.forEach(val => {

                    if (!val["Name"]) {
                        val["Name"] = this.cdfService.createNameControl(val.Text || (val as any).Name, newValue.IsFieldRequired);
                    }

                    if (!val["ValidName"]) {
                        val["ValidName"] = val.Text || (val as any).Name;
                    }

                    if (!val["ControlName"]) {
                        val["ControlName"] = "DefaultValue" + ++this.itemIndexer;
                    }

                    let fg = this.fb.group(val);
                    itemsArray.controls.push(fg);
                });

                itemsArray.updateValueAndValidity();
            }
        } else if (newValue.FieldType === "Date") {
            let val;
            if (newValue.Values.length > 0) {
                val = newValue.Values[0].Date;
            }
            IpsDateComponent.setControlValue(val, fieldFormGroup);
        } else {
            let val;
            if (newValue.Values.length > 0) {
                val = newValue.Values[0].Text;
            }
            fieldFormGroup.patchValue({ Text: val }, { emitEvent: true });
        }
    }

    private updateDefaultValues(eventData: ValueChangeEventData): void {
        //check the eventData if the defaultValues changes are triggered from initial load
        //update custom data control only when changes are from UI change
        //skip the update if the changes are triggered by inital load

        const newDefaultValues = (eventData.initialLoad === undefined ? eventData : eventData.values) as CustomDataWithValueModel[];
        const cdfIds = newDefaultValues.map(nd => nd.Id);
        if (eventData.initialLoad) {
            // Remove currents that are also new
            this.currentDefaultValues = this.currentDefaultValues.filter(cd => cdfIds.indexOf(cd.Id) === -1);
            this.currentDefaultValues = this.currentDefaultValues.concat(newDefaultValues);
            return;
        }

        newDefaultValues.forEach(newDefaultCdf => {
            // Find the field that matches the item.
            let fieldFormGroup;
            if (newDefaultCdf.FieldType === "List") {
                let listTypeCtrls = this.fieldValues.controls.filter(f =>
                     f.get("Items") !== null
                );

                for (let i = 0; i < listTypeCtrls.length; i++) {
                    const itemArray = listTypeCtrls[i].get("Items") as FormArray;
                    for (let j = 0; j < itemArray.controls.length; j++) {
                        const item = itemArray.controls[j];
                        if (item.get("CustomDataId").value === newDefaultCdf.Id) {
                            fieldFormGroup = listTypeCtrls[i];
                            break;
                        }
                    }

                }
            } else {
                fieldFormGroup = this.fieldValues.controls.find((f: FormGroup) => {
                    //Check to see if we are dealing with the list
                    if (f.controls && f.get("Items")) {
                        return !!(f.get("Items") as FormArray).controls.find(fa => fa.get("CustomDataId").value === newDefaultCdf.Id);
                    }

                    //Single formGroup
                    return f.get("CustomDataId").value === newDefaultCdf.Id;
                }) as FormGroup;
            }

            let currentDefaultCdf = this.currentDefaultValues
                ? this.currentDefaultValues.find(d => d.Id === newDefaultCdf.Id)
                : undefined;

            if (fieldFormGroup && (currentDefaultCdf || currentDefaultCdf === undefined || this.defaultInitializeRequired)) {
                // Compare the controls current value to current default
                let currentVals = this.getFormGroupValue(fieldFormGroup);
                // If defaultInitializeRequired, just set to current defaults.
                const stillDefault = this.defaultInitializeRequired ? true : this.CdfValuesMatch(currentDefaultCdf, currentVals);
                if (stillDefault) {
                    this.setFormGroupValue(newDefaultCdf, fieldFormGroup);
                }
            }
        });

        // Remove currents that are also new
        this.currentDefaultValues = this.currentDefaultValues.filter(cd => cdfIds.indexOf(cd.Id) === -1);
        this.currentDefaultValues = this.currentDefaultValues.concat(newDefaultValues);
        this.defaultInitializeRequired = false;
    }

    public updateFormDirty() {
        if (this.fieldValues.dirty) {
            this.parent.markAsDirty();
        }
    }

    public valueChangedHandler(customData: CustomDataWithValueModel): void {
        this.valuesChanged.emit({ values: [customData], initialLoad: false });
    }

    public controlLoadedHandler(customData: CustomDataWithValueModel): void {
        this.loadedControlsCount++;
        if (this.loadedControlsCount === this.customDataResult.length) {
            this.loaded.emit();
            if (this.initLoadValues) {
                this.updateDefaultValues(this.initLoadValues);
                this.initLoadValues = null;
            } else {
            this.updateDefaultValues({ values: this.currentDefaultValues, initialLoad: false });

            }
        }
    }

    private CdfValuesMatch(a: CustomDataWithValueModel, b: CustomDataValueModel[]): boolean {
        if (!a) {
            //a and b are match
            //when a = undefined(the default value is loaded form database)
            // b only has one empty option
            if ((b.length === 1 && b[0].CustomDataOptionId === 0) || b.length === 0) {
                return true;
            }
            return false;
        }

        if (a.Values.length !== b.length && b.length > 0) {
            //a and b are match
            //when a = 0 length array (added by ui)
            // b only has one empty option
            if (b.length === 1 && a.Values.length === 0 && b[0].CustomDataOptionId === 0) {
                return true;
            }

            return false;
        }

        //check match for list type
        if (a.FieldType === "List") {
            return b.every(curVal => {
                // See if value matches the original default value
                let cd = a.Values.find(c => curVal.CustomDataId === c.CustomDataId && curVal.CustomDataOptionId === c.CustomDataOptionId);
                if (!cd) {
                    return false;
                }
                return true;
            });
        }

        //check match for Text or Date type
        return b.every(curVal => {
            // See if value matches the original default value
            let cd = a.Values.find(c => {
                if (a.FieldType === "Text" || a.FieldType === "Email" || a.FieldType === "Number") {
                    return c.Text === curVal.Text;
                }
                return this.datesAreEqual(c.Date, curVal.Date);
            });
            if (!cd) {
                return false;
            }
            return true;
        });
    }

    private datesAreEqual(a: any, b: any): boolean {
        if (!a && !b) {
            return true;
        }
        if (!a || !b) {
            // one is empty but not the other
            return false;
        }
        let aDate = new Date(a);
        let bDate = new Date(b);
        return aDate.toDateString() === bDate.toDateString();
    }
}
