<div class="col-12">
    <!--ng-controller="LocationEditCtrl as Location"-->
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:'{{'ACCESSING_LOCATION_INFO' | translate}}'}">
        <form #locationForm="ngForm" id="ramiInfoInput" class="form-horizontal updateSection" name="locationForm" novalidate ipsUnsavedWarningForm role="form">
            <div [hidden]="!loaded">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-4">
                        <div class="form-group">
                            <label for="locationId" class="required" translate>ID</label>
                            <input type="text" name="locationId" id="locationId" [(ngModel)]="mylocation.LocationIdentifier" #locIdentifier="ngModel"
                                   class="form-control form-control-lg" [disabled]="mylocation.Id > 0" placeholder="{{ 'ENTER_LOCATION_ID' | translate}}"
                                   required maxlength="50" appValidation>
                            <div *ngIf="locIdentifier.errors" class="invalid-feedback">
                                <div *ngIf="locIdentifier.errors.required" translate>
                                    PLEASE_ENTER_LOCATION_ID
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                        <div class="form-group">
                            <label class="required" for="name" translate>NAME</label>
                            <input type="text" name="name" id="name" [(ngModel)]="mylocation.Name" class="form-control form-control-lg" placeholder="{{ 'ENTER_LOCATION_NAME' | translate}}"
                                   #locName="ngModel" appValidation required maxlength="50">
                            <div *ngIf="locName.errors" class="invalid-feedback">
                                <div *ngIf="locName.errors.required" translate>
                                    PLEASE_ENTER_LOCATION_NAME
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                        <div class="form-group">
                            <label for="pointOfContact" translate>POINT_OF_CONTACT</label>
                            <input type="text" name="pointOfContact" id="pointOfContact" [(ngModel)]="mylocation.PointOfContact" class="form-control form-control-lg" placeholder="{{ 'ENTER_POINT_OF_CONTACT' | translate}}">
                        </div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col">
                        <div class="card" [ngClass]="{'card-open': mylocation.LocationStatusInfo.Status==='OPEN', 'card-suspended': mylocation.LocationStatusInfo.Status==='SUSPENDED', 'card-closed': mylocation.LocationStatusInfo.Status==='CLOSED' }">
                            <div class="card-body">
                                <div class="row" *ngIf="showLocationStatus">
                                    <div class="col d-flex align-items-center">
                                        <span translate>LOCATION_STATUS</span>: {{mylocation.LocationStatusInfo.Status | translate}} <div class="{{mylocation.LocationStatusInfo.IconClass}} fa-lg ml-2" ngbTooltip="{{mylocation.LocationStatusInfo.Tooltip | translate}}" placement="bottom"></div>
                                    </div>
                                </div>
                                <hr *ngIf="showLocationStatus" />
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label class="add-right-margin"><span class="pr-3">{{initPromotionsLabel}}</span> <span *ngIf="loaded"><i class="fa fa-info-circle fa-lg" aria-hidden="true" ngbTooltip="{{'TOOLTIP_LOCATION_INITDATE'| translate}}" placement="bottom"></i></span></label>

                                            <div class="input-group">
                                                <input type="text" name="initDate" id="initDate" class="form-control form-control-lg input-full"
                                                       [(ngModel)]="mylocation.InitiatePromotionsDate" #stagingDate="ngModel"
                                                       placeholder="{{ 'ENTER_FIRST_RECEIPT_DATE' | translate }}"
                                                       #d="bsDatepicker" bsDatepicker [bsConfig]="initDateOptions" triggers=""
                                                       appValidation />
                                                <!--ng-model-options="{timezone: 'utc'}" datepicker-options="initDateOptions" -->
                                                <span class="input-group-append">
                                                    <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                                </span>
                                            </div>
                                            <!--d-flex class is needed with input groups to get the error to show. ngIf is required in this case also, otherwise it is always displayed.
                                issue - https://github.com/twbs/bootstrap/issues/23454-->
                                            <div *ngIf="stagingDate.errors" class="invalid-feedback d-flex">{{invalidDateErrorMessage}}</div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label class="add-right-margin"><span class="pr-3">{{openLabel}}</span> <span *ngIf="loaded"><i class="fa fa-info-circle fa-lg" aria-hidden="true" ngbTooltip="{{'TOOLTIP_LOCATION_OPENDATE'| translate}}" placement="bottom"></i></span></label>

                                            <div class="input-group">
                                                <input type="text" name="openDate" id="openDate" class="form-control form-control-lg input-full"
                                                       [ngClass]="{'border-warning': showOpenDateAfterSuspendDateWarning || showOpenDateEmptyWarning}"
                                                       [(ngModel)]="mylocation.OpenDate" (ngModelChange)="changeOpenDate()" #openDate="ngModel"
                                                       placeholder="{{ 'ENTER_OPEN_DATE' | translate }}"
                                                       #d2="bsDatepicker" bsDatepicker [bsConfig]="openDateOptions" triggers=""
                                                       appValidation />
                                                <span class="input-group-append">
                                                    <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" [ngClass]="{'border-warning': showOpenDateAfterSuspendDateWarning || showOpenDateEmptyWarning}" (click)="d2.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                                </span>
                                            </div>
                                            <div *ngIf="openDate.errors" class="invalid-feedback d-flex">{{invalidDateErrorMessage}}</div>
                                            <div class="ips-text-warning" *ngIf="showOpenDateEmptyWarning">
                                                <span><b>{{information}}:</b> {{openDateEmptyWarning}}</span>
                                            </div>
                                            <div class="ips-text-warning" *ngIf="showOpenDateAfterSuspendDateWarning">
                                                <span><b>{{information}}:</b> {{openDateAfterSuspendDateWarning}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <label class="add-right-margin"><span class="pr-3">{{suspendPromotionsLabel}}</span> <span *ngIf="loaded"><i class="fa fa-info-circle fa-lg" aria-hidden="true" ngbTooltip="{{'TOOLTIP_LOCATION_SUSPENDDATE'| translate}}" placement="bottom"></i></span></label>

                                            <div class="input-group">
                                                <input type="text" name="suspendDate" id="suspendDate" class="form-control  form-control-lg input-full"
                                                       [(ngModel)]="mylocation.SuspendPromotionsDate" (ngModelChange)="changeSuspendDate()" #suspendDate="ngModel"
                                                       placeholder="{{ 'ENTER_SUSPEND_DATE' | translate }}"
                                                       #d3="bsDatepicker" bsDatepicker [bsConfig]="suspendDateOptions" triggers=""
                                                       [ngClass]="{'border-warning': showSuspendDateBeforeOpenDateWarning}" />
                                                <span class="input-group-append">
                                                    <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" [ngClass]="{'border-warning': showSuspendDateBeforeOpenDateWarning}" (click)="d3.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                                </span>
                                            </div>
                                            <div *ngIf="suspendDate.errors" class="invalid-feedback d-flex">{{invalidDateErrorMessage}}</div>
                                            <div class="ips-text-warning" *ngIf="showSuspendDateBeforeOpenDateWarning">
                                                <span><b>{{information}}:</b> {{suspendDateBeforeOpenDateWarning}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="form-group">
                                            <div class="">
                                                <label>&nbsp;</label>
                                                <div class="input-group">
                                                    <div class="form-control form-control-lg input-full">{{closeLocationText}}</div>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text"> <input name="CloseLocation" type="checkbox" [(ngModel)]="mylocation.IsClosed" (ngModelChange)="changeCloseLocation()" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div class="form-group">
                            <label translate>IMAGE</label>
                            <ips-image-edit name="file" [showDelete]="true" (fileChanged)="onSelectedImageChanged($event)"></ips-image-edit>
                        </div>
                    </div>
                </div>

                <hr />

                <app-address-edit [address]="mylocation.Addresses[0]" [showFax]="true" [showEmail]="true"></app-address-edit>


                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-4">
                        <div class="form-group">
                            <label for="alternateAddress" class="required" translate>CAMPAIGN_ALTERNATE_ADDRESS</label>
                            <div class="input-group">
                                <input type="text" class="form-control form-control-lg"
                                       #alternateAddressField="ngModel" name="alternateAddress" id="alternateAddress"
                                       [(ngModel)]="alternateAddress" [ngModelOptions]="{ blur: 200 }"
                                       (ngModelChange)="onAlternateAddressChange($event)"
                                       typeaheadOptionField="Label"
                                       typeaheadWaitMs="200"
                                       [ngbTypeahead]="getAlternateAddress" [resultFormatter]="inputFormatter" [inputFormatter]="inputFormatter"
                                       appValidation required>
                                <div class="input-group-append">
                                    <button class="btn btn-default btn-lg form-control-lg fileProcess-filter" type="button" (click)="campaignShippingAddressChange()"><i class="fa fa-search fa-lg"></i></button>
                                </div>
                            </div>
                            <div *ngIf="alternateAddressField.errors" class="validation-error-message">
                                <div *ngIf="alternateAddressField.errors.required" translate>PLEASE_SELECT_ALTERNATE_ADDRESS</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-4 offset-lg-4">
                        <div class="form-group">
                            <label for="referenceIdentifier" translate>REFERENCE_IDENTIFIER</label>
                            <input type="text" name="referenceIdentifier" id="referenceIdentifier" [(ngModel)]="mylocation.ReferenceIdentifier" class="form-control form-control-lg" placeholder="{{ 'ENTER_REFERENCE_IDENTIFIER' | translate}}"
                                   #referenceIdentifier="ngModel" appValidation maxlength="50">
                        </div>
                    </div>

                </div>


                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="form-group">
                            <label class="" for="notes" translate>NOTES</label>
                            <textarea [(ngModel)]="mylocation.Notes" class="form-control form-control-lg input-full" name="notes" id="notes" cols="30" rows="3" maxlength="500" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                        </div>
                    </div>
                </div>

            <hr />

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveLocation"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="validateAddressForLocationSave(false)"
                                    [disabled]="locationForm.form.invalid || locationForm.form.pristine"
                                    [ngClass]="{'btn-default': locationForm.form.invalid || locationForm.form.pristine}" translate>
                                SAVE_LOCATION
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveReturnLocation"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="validateAddressForLocationSave(true)"
                                    [disabled]="locationForm.form.invalid || locationForm.form.pristine"
                                    [ngClass]="{'btn-default': locationForm.form.invalid || locationForm.form.pristine}" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="cancelLocation" class="btn btn-default btn-lg btn-block" uiSref="main.storeProfileLocation.search" translate>CANCEL</button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="deleteLocation"
                                    class="btn btn-lg btn-block alert-danger"
                                    (click)="deleteLocationPrompt()"
                                    ips-unsaved-warning-clear
                                    [disabled]="!mylocation.Id"
                                    [ngClass]="{'btn-default': !mylocation.Id}">
                                {{'DELETE_LOCATION' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>        
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
