import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from "@angular/core";
import { NgForm, FormGroup, FormControl, FormArray, Validators, AbstractControl, ReactiveFormsModule } from "@angular/forms";
import { ActiveProfileService, BusinessIdentityModel, MediaReferenceModel, BusinessIdentityThemeModel } from "imagine-ui-ng-core";
import { ProfileService, BusinessService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { AuthService } from "imagine-ui-ng-security";
import { Transition, StateService } from "@uirouter/angular";
import { String, StringBuilder } from "typescript-string-operations";
import { TranslateService } from "@ngx-translate/core";
import { IpsModalService, IpsModalInstance } from "imagine-ui-ng-modal";
import { BrandSettingService } from "../../imagine-ui-ng-store-profile/index";

@Component({
    selector: "app-theme",
    templateUrl: "./theme.component.html",
    styleUrls: ["./theme.component.scss"]
})
export class ThemeComponent implements OnInit {

    @ViewChild("editTemplate") editTemplate: TemplateRef<any>;
    @ViewChild("deleteTemplate") deleteTemplate: TemplateRef<any>;
    @ViewChild("fileInput") public fileInput: ElementRef;



    private business: BusinessIdentityModel;
    public brandForm: FormGroup;
    public smallLogo: string;
    public largeLogo: string;
    public smallLogoSelected: boolean;
    public largeLogoSelected: boolean;
    public smallLogoFiles: FileList;
    private smallLogoData: FileList;
    public largeLogoFiles: FileList;
    private largeLogoData: FileList;
    private smallLogoPlaceholderSet = false;
    private largeLogoPlaceholderSet = false;
    public loaded: boolean;
    public promise: Promise<any>;
    private SupportUrl: FormControl;
    private emptyGuid = "00000000-0000-0000-0000-000000000000";


    public showUrl = true;
    public loadingContent = false;
    public editing = false;
    public editTarget: any;
    private deletionTarget: any;
    private id: number;
    public attachedDocuments: any[] = [];
    public processing = false;
    public processingError: string;
    public uploadedFileName: string;
    public file: any; //file input control
    public hasfileSizeError = false;
    private modalInstance: IpsModalInstance;
    private supportURLPrevious = "";
    public helpTypeIsDoc = false;

    constructor(private businessService: BusinessService,
        private transition: Transition,
        private stateService: StateService,
        private translateService: TranslateService,
        private activeProfileService: ActiveProfileService,
        private authService: AuthService,
        private ipsMessage: IpsMessageService,
        private profileService: ProfileService,
        private modalService: IpsModalService,
        private brandSettingService: BrandSettingService) {
        this.business = { Name: "", CustomerExternalId: "" } as BusinessIdentityModel;
    }

    ngOnInit() {
        this.createForm();

        this.getBusiness(this.activeProfileService.businessIdentity);

        this.smallLogo = this.businessService.getDefaultImageOriginal("ThemeLogo");
        this.largeLogo = this.businessService.getDefaultImage("ThemeBanner");

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    private createForm() {
        this.SupportUrl = new FormControl("");

        this.brandForm = new FormGroup({
            SupportUrl: this.SupportUrl
        });
    }

    public saveBusinessPrompt(redirect: boolean) {
        return this.ipsMessage
            .waitForWork({ body: "SAVING", workFunction: () => this.saveBusiness(), progressMessage: "SAVING" }).then(
                (result: any) => {
                    if (result) {
                        if (redirect) {
                            this.stateService.go("main.tools");
                        }
                    }
                });
    }

    private saveBusiness() {
        let savePromise: Promise<BusinessIdentityThemeModel> = null;

        let saveModel = {} as BusinessIdentityThemeModel;
        this.business.SupportUrl = this.SupportUrl.value;

        // now assign properties from child form
        this.business.PrimaryHexColor = this.brandForm.controls["primaryHexColor"].value;
        this.business.SecondaryHexColor = this.brandForm.controls["secondaryHexColor"].value;
        this.business.FontHexColor = this.brandForm.controls["fontHexColor"].value;

        saveModel.ExternalId = this.business.ExternalId;
        saveModel.SupportUrl = this.business.SupportUrl;
        saveModel.PrimaryHexColor = this.business.PrimaryHexColor;
        saveModel.SecondaryHexColor =  this.business.SecondaryHexColor;
        saveModel.FontHexColor =  this.business.FontHexColor;

        // call a save route just for business theme data.
        savePromise = this.businessService.putTheme(saveModel);

        let putSmallImagePromise = savePromise.then((resource) => {
            return this.saveSmallLogo(this.business.ExternalId);
        });
        // Chain image save calls one after the other, otherwise the second one can overwrite the first.
        let putLargeImagePromise = putSmallImagePromise.then(() => {
            return this.saveLargeLogo(this.business.ExternalId);
        });
        return Promise.all([savePromise, putSmallImagePromise, putLargeImagePromise]).then(() => {
            this.refreshUserProfileData();
        });
    }

    private saveSmallLogo(businessId: string) {
        if (this.smallLogoFiles || this.smallLogoPlaceholderSet) {
            this.smallLogoData = this.smallLogoFiles;
            return this.businessService.putSmallLogo(businessId, this.smallLogoData);
        }
    }

    private saveLargeLogo(businessId: string) {
        if (this.largeLogoFiles || this.largeLogoPlaceholderSet) {
            this.largeLogoData = this.largeLogoFiles;
            return this.businessService.putLargeLogo(businessId, this.largeLogoData);
        }
    }


    private refreshUserProfileData() {
        this.profileService.loadUserData().then(() => {
            this.brandForm.markAsPristine();
        });
    }

    private translateText() {
    }

    public populateThumbnail(forDataLoad = false) {
        if (forDataLoad || this.smallLogoFiles !== null) {
            this.smallLogo =
                `${this.business.LogoMedia.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity
                }&idToken=${this.authService.getIdToken()}`;
            this.smallLogoPlaceholderSet = false;
        } else {
            this.smallLogo = this.businessService.getDefaultImageOriginal("ThemeLogo");
            this.business.LogoMediaId = this.emptyGuid;
            this.smallLogoPlaceholderSet = true;
        }

        if (forDataLoad || this.largeLogoFiles !== null) {
            this.largeLogo =
                `${this.business.BannerMedia.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity
                }&idToken=${this.authService.getIdToken()}`;
            this.largeLogoPlaceholderSet = false;
        } else {
            this.largeLogo = this.businessService.getDefaultImage("ThemeBanner");
            this.business.BannerMediaId = this.emptyGuid;
            this.largeLogoPlaceholderSet = true;
        }

        this.smallLogoSelected = this.smallLogo.indexOf("/PlaceHolder/") === -1;
        this.largeLogoSelected = this.largeLogo.indexOf("/PlaceHolder/") === -1;
    }

    private getBusiness(externalId: string) {
        this.loaded = false;

        let promises: Promise<any>[] = [];

        promises.push(this.businessService.getByExternalId(externalId).then((response) => {
            Object.assign(this.business, response);
            this.brandForm.patchValue({
                SupportUrl: response.SupportUrl
            });
            this.supportURLPrevious = response.SupportUrl;
            this.populateThumbnail(true);
        }));

        promises.push(this.getAttachedDocuments().then((response) => {
            Object.assign(this.attachedDocuments, response);
        }));


        this.promise = Promise.all(promises)
            .then((data: any) => {
                if (this.SupportUrl.value === "/helpdocs") {
                    this.showUrl = false;
                } else {
                    this.showUrl = true;
                }

                this.loaded = true;
            });

    }


    public clickCustomUrl() {
        this.showUrl = true;

        if (this.SupportUrl.value === "/helpdocs") {
            this.SupportUrl.setValue(this.supportURLPrevious === "/helpdocs" ? "" : this.supportURLPrevious);
        }
    }

    public clickMultipleDocs() {
        this.showUrl = false;

        this.SupportUrl.setValue("/helpdocs");
        this.brandForm.markAsDirty();
    }

    //Help Document Modal//
    public addHelpDocument() {
        this.editing = false;

        this.uploadedFileName = null;

        this.editTarget = {
            Id: 0,
            LinkId: this.id,
            LinkType: 4, // Help Document
            LinkSubType: 0,
            SubmittedBy: "",
            SubmittedDateTime: new Date(),
            MasterMediaId: this.emptyGuid,
            MediaType: 0,
            Label: "",
            Label2: ""
        };

        this.modalInstance = this.modalService.displayComponentAndReturnInstance(this.editTemplate, null, { size: "lg", backdrop: "static" });

    }

    public cancelEdit() {
        this.modalInstance.close();
        this.editing = false;
        this.editTarget = null;
    }


    public fileOpen() {
        this.fileInput.nativeElement.click();
    }

    public fileSelected(event) {
        this.file = event.currentTarget.files[0];
        this.uploadedFileName = this.file.name;
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        if (this.file.size > 10458760) { //10M
            this.hasfileSizeError = true;
        } else {
            this.hasfileSizeError = false;
        }

        this.brandForm.markAsDirty();

    }



    //Help Document Save//
    public save() {
        this.processing = true;
        this.processingError = null;

        if (this.editTarget.Label === null || this.editTarget.Label === "") {
            this.processingError = "Missing Required Help Document Description.";
            this.processing = false;
            return;
        }

        if (this.helpTypeIsDoc && (this.file === null || this.file.value === "")) {
            this.processingError = "Missing Required Help Document.";
            this.processing = false;
            return;
        }

        if (!this.helpTypeIsDoc && (this.editTarget.Label2 === null || this.editTarget.Label2 === "")) {
            this.processingError = "Missing Required Help URL.";
            this.processing = false;
            return;
        }

        this.performSave(this.editTarget);
    }

    private performSave(model: any) {
        this.brandSettingService.addHelpDocument(model, this.file).then((response) => {
            this.onSaveSuccess();
        }).catch((response) => {
            this.onProcessingError(response.Message || response.statusText);
        });
    }

    private onSaveSuccess() {
        this.getAttachedDocuments();
        this.processing = false;

        this.modalInstance.close();

        this.processingError = null;
    }

    private onProcessingError(message: string) {
        this.processingError = message;
        this.processing = false;
    }



    //Help Document Delete//
    public removeHelpDocument(document: any) {
        this.deletionTarget = document;

        if (this.deletionTarget) {
            this.modalInstance = this.modalService.displayComponentAndReturnInstance(this.deleteTemplate, null, { backdrop: "static" });
        }
    }

    public delete() {
        this.processing = true;
        this.processingError = null;

        this.performDelete(this.deletionTarget);
    }

    private performDelete(model: any) {
        this.brandSettingService.deleteHelpDocument(model.Id).then((response) => {
            this.onDeleteSuccess();
        }).catch((response) => {
            this.onProcessingError(response.Message);
        });
    }

    public cancelDelete() {
        this.modalInstance.close();
    }

    private onDeleteSuccess() {
        this.processing = false;
        this.getAttachedDocuments();

        this.modalInstance.close();
        this.deletionTarget = null;
    }

    public attachmentUrl(document: any): string {
        return this.brandSettingService.getHelpDocumentMediaUrl(document.MasterMediaId, document.MediaType);
    }

    private getAttachedDocuments(): Promise<any> {
        return this.brandSettingService.getHelpDocuments().then((response) => {
            this.attachedDocuments = response;
        });
    }

    public clickHelpDocument() {
        this.helpTypeIsDoc = true;
        this.editTarget.Label2 = null;
    }

    public clickHelpURL() {
        this.helpTypeIsDoc = false;
        this.file.value = "";
    }

}
