export const environment = {
    production: true,
    envName: "prod",
    defaultSupportEmail: "__DefaultSupportEmail__",
    baseApiUrl: "__ApiUrl__/",
    chunkSize: "20",
    delayTimer: "60000",
    dataRefreshTimer: "20000",
    oktaTenantUrl: "__AuthApiUrl__",
    oktaUserSettingsUrl: "__AuthApiUrl__/enduser/settings",
    oktaClientId: "__AuthClientId__",
    imagineSSOUrl: "__ImagineSSOUrl__",
    redirectUrl: "__RedirectUrl__",
    responseType: "id_token",
    responseMode: "okta_post_message",
    loggerApiUrl: "__LogApiUrl__/System/v1/ClientLogRecord",
    ApplicationName: "__AppName__",
    ApplicationVersion: "__AppVersion__",
    scope: "[ 'openid', 'profile', 'email' ]",
    debug: false,
    analyticsId: "__AnalyticsId__",
    maxIdleCount: "__maxIdleCount__",
    maxSessionInHrs: "__maxSessionInHrs__",
    idleMinsBeforeWarn: "__idleMinsBeforeWarn__",
    idleMinsBeforeLogout: "__idleMinsBeforeLogout__",
    keepAliveIntervalMins: "__keepAliveIntervalMins__",
    apiManagementKey: "__apiManagementKey__",
    payflowUrl: "__payflowUrl__"
};
