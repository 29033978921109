/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./store-profile-fixture-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@uirouter/angular/lib/directives/uiView.ngfactory";
import * as i3 from "@uirouter/angular/lib/directives/uiView";
import * as i4 from "@uirouter/core";
import * as i5 from "./store-profile-fixture-group.component";
var styles_StoreProfileFixtureGroupComponent = [i0.styles];
var RenderType_StoreProfileFixtureGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StoreProfileFixtureGroupComponent, data: {} });
export { RenderType_StoreProfileFixtureGroupComponent as RenderType_StoreProfileFixtureGroupComponent };
export function View_StoreProfileFixtureGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "ui-view", [], null, null, null, i2.View_UIView_0, i2.RenderType_UIView)), i1.ɵdid(1, 245760, null, 0, i3.UIView, [i4.UIRouter, "UIView.PARENT_INJECT", i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_StoreProfileFixtureGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-store-profile-fixture-group", [], null, null, null, View_StoreProfileFixtureGroupComponent_0, RenderType_StoreProfileFixtureGroupComponent)), i1.ɵdid(1, 114688, null, 0, i5.StoreProfileFixtureGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StoreProfileFixtureGroupComponentNgFactory = i1.ɵccf("app-store-profile-fixture-group", i5.StoreProfileFixtureGroupComponent, View_StoreProfileFixtureGroupComponent_Host_0, {}, {}, []);
export { StoreProfileFixtureGroupComponentNgFactory as StoreProfileFixtureGroupComponentNgFactory };
