
import { distinctUntilChanged, debounceTime, mergeMap, distinct } from "rxjs/operators";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SurveySectionModel, SurveyQuestionModel, SurveyService } from "../../index";
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { LocationGroupInfoModel } from "../../../imagine-ui-ng-store-profile";
import { Observable, Observer, from } from "rxjs";

import { MarketModel, MarketService, ProfileQuestionModel, DataService, MarketDisplayInfoLocationSubGroup, DataStorageType, MarketType } from "../../../market/index";
import { MarketChangedEventModel } from "../../../market/model/MarketChangedEventModel";
import { String as IpsString } from "typescript-string-operations";
import { AnswerType } from "../../type/AnswerType";
import { MessageControl } from "../../type/MessageControl";

interface MarketSource {
    key: string;
    source: Observable<any>;
}

interface SurveyQuestionUIModel extends SurveyQuestionModel {
    Market: MarketModel;
}

@Component({
    selector: "app-survey-question-edit",
    templateUrl: "./survey-question-edit.component.html",
    styleUrls: ["./survey-question-edit.component.scss"]
})
export class SurveyQuestionEditComponent implements OnInit {
    @Input() sectionsModel: SurveySectionModel[];
    @Input() parent: FormGroup;
    @Input() openSections: string[]; //list of ordinals of open sections
    @Output() sectionDeleted = new EventEmitter<number>();

    // New question input focus
    private marketHtmlName = "MarketHtmlName";
    private tempSectionId = 0;
    private tempQuestionId = 0;
    public focusQuestionInput: string;

    // New section input focus
    public sectionHtmlName = "SectionHtmlName";
    public focusSectionInput: string;

    public featuresInAudience = [] as MarketModel[];
    public featuresInQuestion = [] as ProfileQuestionModel[];
    public fixtureGroupsInQuestion = [] as ProfileQuestionModel[];
    public fixturesInQuestion = [] as ProfileQuestionModel[];

    private TranslateStrings: { [key: string]: string } = {
        "QUESTION": "",
        "QUESTIONS": "",
        "PLEASE_ENTER_SECTION_NAME": "",
        "PLEASE_ENTER_QUESTION_NAME": "",
        "PLEASE_ENTER_LINKED_FEATURE_NAME": "",
        "MAX_LENGTH_ERROR": "",
        "PLEASE_ENTER_VALID_LINKED_FEATURE": "",
        "DUPLICATE_SECTION_NAME": ""
    };

    private errorMessages = {
        "maxlength": (params) => IpsString.Format(this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength),
        "invalidMarketName": () => this.TranslateStrings["PLEASE_ENTER_VALID_LINKED_FEATURE"],
        "duplicateSectionName": () => this.TranslateStrings["DUPLICATE_SECTION_NAME"]
    };

    private enterSectionName = "";
    pluralMapping: { [k: string]: string } = {
        "=1": "QUESTION",
        "other": "QUESTIONS"
    };

    public SurveySections: FormArray;
    private surveyId: number;
    private marketSources: MarketSource[] = [];
    private instructionSources: string[] = [];
    private dbInstructions: Record<number, string> = {};

    constructor(private formBuilder: FormBuilder, private translateService: TranslateService, private surveyService: SurveyService,
        private activeProfileService: ActiveProfileService, private authService: AuthService, private marketService: MarketService,
        private dataService: DataService) {
    }

    ngOnInit() {
        this.surveyId = this.parent.get("Id").value;
        this.getInstructions(this.surveyId);
        this.CreateForm();

        this.featuresInAudience = this.dataService.featuresInAudience;
        this.featuresInQuestion = this.dataService.featuresInQuestion;
        this.fixtureGroupsInQuestion = this.dataService.fixtureGroupsInQuestion;
        this.fixturesInQuestion = this.dataService.fixturesInQuestion;

        this.InitalizeForm(this.sectionsModel);

        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() => this.TranslateText());
    }

    private TranslateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
        this.pluralMapping = {
            "=1": this.TranslateStrings["QUESTION"],
            "other": this.TranslateStrings["QUESTIONS"]
        };
    }

    private CreateForm() {
        let sectionCtrl = this.createSectionControl();
        let sections = this.formBuilder.array([sectionCtrl]);
        this.parent.addControl("SurveySections", sections);
    }

    private InitalizeForm(sectionsModel: SurveySectionModel[]) {
        //init sections
        if (sectionsModel && sectionsModel.length > 0) {
            while (this.Sections.length > 0) {
                this.Sections.removeAt(0);
            }

            sectionsModel.map((section: SurveySectionModel) => {
                let sectionCtrl = this.getNewSection(section);
                this.Sections.push(sectionCtrl);
            });

            for (let i = 0; i < this.Sections.length; i++) {
                let ordinal = this.Sections.controls[i].get("Ordinal").value;
                let found = false;

                if (this.openSections) {
                    if (Array.isArray(this.openSections)) {
                        found = this.openSections &&
                            this.openSections.some(sec => {
                                return Number(sec) === ordinal;
                            });

                    } else {
                        found = Number(this.openSections) === ordinal;
                    }
                }
                if (found) {
                    this.activeCollapse(this.Sections.controls[i] as FormGroup, true);
                }
            }
        }
    }

    private getInstructions(surveyId: number): void {
        if (surveyId === 0) { return; }

        this.surveyService.getInstructions(surveyId).then((response) => {
            if (response) {
                response.forEach(q => this.dbInstructions[q.QuestionId] = q.Instruction);
            }
        });
    }
    private getNewSection(section: SurveySectionModel): FormGroup {
        return new FormGroup({
            Id: new FormControl(section.Id),
            Name: new FormControl(section.Name, [Validators.required, Validators.maxLength(50), this.ValidateSectionName(section.Ordinal)]),
            Ordinal: new FormControl(section.Ordinal),
            SurveyQuestions: new FormArray([]),
            IsCollapsed: new FormControl(true),
            TempId: new FormControl(this.tempSectionId++),
            QuestionCount: new FormControl(section.QuestionCount),
            InputName: new FormControl(this.sectionHtmlName + section.Id)
        });
    }

    private addMarketObservable(marketCtrl: AbstractControl, questionId: number, sectionId: number) {
        let marketSource = this.marketService.createFilteredMarketSource(marketCtrl, this.dataService.fixtureGroupsInQuestion, this.dataService.featuresInQuestion, this.dataService.featuresInAudience, this.dataService.fixturesInQuestion, "linkedprofile");
        let key = sectionId + "-" + questionId;
        this.marketSources[key] = marketSource;
    }

    private addInstructionObservable(questionCtrl: AbstractControl, questionId: number, sectionId: number) {
        const instSource = this.createInstructionSource(questionCtrl);
        const key = sectionId + "-" + questionId;
        this.instructionSources[key] = instSource;
    }

    private createQuestionControl(question?: SurveyQuestionModel, sectionId = 0, tempSectionId = 0, ordinal = 1): FormGroup {
        let fullImage = "";
        if (!question) {
            question = <SurveyQuestionModel>{
                Id: 0,
                Name: "",
                Ordinal: ordinal,
                OptionControl: false,
                SectionId: sectionId,
                FixtureGroupId: 0,
                FixtureId: 0,
                LocationGroupId: 0,
                LocationGroupName: "",
                FullImage: "",
                AnswerType: "ChooseFromOption",
                MessageControl: "CanNotSendMessage",
                Instruction: ""
            };
        }

        if (question.LocationGroup && question.LocationGroup.Media) {
            fullImage = `${question.LocationGroup.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
        }

        let tempId = this.tempQuestionId++;
        let targetMarketId = 0;
        let marketName = "";
        let marketType = null;

        if (question.LocationGroup) {
            marketType = "LocationGroup";
            targetMarketId = question.LocationGroup.Id;
            marketName = question.LocationGroup.Name;
        } else if (question.FixtureGroup) {
            marketType = "FixtureGroup";
            targetMarketId = question.FixtureGroup.Id;
            marketName = question.FixtureGroup.Name;
        } else if (question.Fixture) {
            marketType = "Fixture";
            targetMarketId = question.Fixture.Id;
            marketName = question.Fixture.Name;
        }


        let marketCtrl = this.formBuilder.group({
            LinkedProfileMode: new FormControl(true),
            TargetMarketType: new FormControl(marketType),
            TargetMarketId: new FormControl(targetMarketId),
            MarketName: new FormControl(marketName, [Validators.required, this.marketService.checkMarketName()]),
            InputName: new FormControl(this.marketHtmlName + tempSectionId + tempId),
            TypeaheadModalSelection: new FormControl(marketName)
        });

        this.addMarketObservable(marketCtrl, tempId, tempSectionId);

        // Add input focus to the new question.
        this.focusQuestionInput = marketCtrl.get("InputName").value;

        let questionCtrl = new FormGroup({
            Id: new FormControl(question.Id),
            Name: new FormControl(question.Name, [Validators.required, Validators.maxLength(500)]),
            Ordinal: new FormControl(question.Ordinal),
            OptionControl: new FormControl(question.OptionControl),
            SectionId: new FormControl(question.SectionId),
            FullImage: new FormControl(fullImage),
            Market: marketCtrl,
            TempId: new FormControl(tempId),
            EnableOptionControl: new FormControl(true),
            AnswerType: new FormControl(question.AnswerType),
            EnableAnswerType: new FormControl(true),
            MessageControl: new FormControl(question.MessageControl === "Unknown" ? "CanNotSendMessage" : question.MessageControl),
            EnableMessageControl: new FormControl(true),
            Instruction: new FormControl(question.Instruction, [Validators.maxLength(500)])
        });

        questionCtrl.get("Name").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
            const market = questionCtrl.get("Market").value;
            let updateModel = { Id: market.TargetMarketId, Name: value };
            let storageType = market.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : market.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : market.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null as DataStorageType;
            this.dataService.refreshInQuestion(updateModel, storageType);
        });

        this.addInstructionObservable(questionCtrl, tempId, tempSectionId);

        this.setControlsEnabled(questionCtrl, question.LocationGroup && question.LocationGroup.OptionType === "Bool");

        return questionCtrl;
    }

    public getMarketSource(section: FormGroup, question: FormGroup) {
        let key = section.get("TempId").value + "-" + question.get("TempId").value;
        return this.marketSources[key];
    }

    public getInstructionSource(section: FormGroup, question: FormGroup) {
        let key = section.get("TempId").value + "-" + question.get("TempId").value;
        return this.instructionSources[key];
    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "sectionname":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_SECTION_NAME"];
                    break;
                case "questionname":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_QUESTION_NAME"];
                    break;
                case "locationgroupname":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_LINKED_FEATURE_NAME"];
                    break;
            }
        }
        return msgs;
    }


    get Sections(): FormArray {
        return this.parent.get("SurveySections") as FormArray;
    }

    public Questions(section: FormGroup): FormArray {
        return section.get("SurveyQuestions") as FormArray;
    }

    public activeCollapse(section: FormGroup, scrollToSection: boolean = false) {
        let isCollapsed: FormControl = section.get("IsCollapsed") as FormControl;
        let flag = (isCollapsed.value as boolean);
        if (flag && section.get("SurveyQuestions").value.length === 0) {
            this.getSurveyData(section, scrollToSection);
        }

        isCollapsed.setValue(!flag);
    }

    public changeOptionControl(questionControl: FormGroup) {
        let optionControl = questionControl.get("OptionControl");
        optionControl.setValue(!optionControl.value);
        optionControl.markAsDirty();
    }

    public getAnswerTypeText(questionControl: FormGroup): string {
        const answerType: AnswerType = questionControl.get("AnswerType").value;
        const marketType = this.getMarketType(questionControl);
        let result = "";
        switch (answerType) {
            case "ChooseFromOption":
                result = marketType === "FixtureGroup" ? "ENTER_NUMBER_FIXTURES" : "CHOOSE_FROM_OPTIONS";
                break;
            case "TypeInOption":
                result = "TYPE_IN_OPTIONS";
                break;
        }
        return result;
    }

    public getAnswerTypeIconClass(questionControl: FormGroup): any {
        const answerType: AnswerType = questionControl.get("AnswerType").value;
        const isUsingFixture = this.isUsingFixture(questionControl);
        let cssClass: any = {
            "fa-hand-pointer-o": !isUsingFixture && answerType === "ChooseFromOption",
            "fa-keyboard-o": isUsingFixture || answerType === "TypeInOption"
        };

        return cssClass;
    }

    public getMarketType(questionControl: FormGroup): MarketType {
        const market = questionControl.get("Market").value as MarketModel;
        return market.TargetMarketType;
    }

    public isUsingFixture(questionControl: FormGroup): boolean {
        return this.getMarketType(questionControl) === "FixtureGroup" || this.getMarketType(questionControl) === "Fixture";
    }

    public changeAnswerType(questionControl: FormGroup) {
        let answerTypeControl = questionControl.get("AnswerType");
        const currentValue: AnswerType = answerTypeControl.value;
        const newValue: AnswerType = currentValue === "ChooseFromOption" ? "TypeInOption" : "ChooseFromOption";
        answerTypeControl.setValue(newValue);

        if (newValue === "TypeInOption") {
            questionControl.patchValue({
                OptionControl: true // User must be allowed to add new answers
            });
        }

        answerTypeControl.markAsDirty();
        this.setControlsEnabled(questionControl);
    }

    private getSurveyData(section: FormGroup, scrollToSection: boolean = false) {

        let sectionId = section.get("Id").value;
        let tempSectionId = section.get("TempId").value;

        this.surveyService.getSurveyQuestionList(sectionId, null).then((response: SurveyQuestionModel[]) => {
            let questionFGs: FormGroup[] = [];
            const sortedResponse = response.sort((a, b) => a.Ordinal - b.Ordinal);

            sortedResponse.forEach(question => {
                delete this.dbInstructions[question.Id];
                questionFGs.push(this.createQuestionControl(question, sectionId, tempSectionId));
            });
            const questions = new FormArray(questionFGs);
            section.setControl("SurveyQuestions", questions);

            if (scrollToSection) {
                let el = document.getElementById("section_" + sectionId);
                el.scrollIntoView();
            }

        });
    }

    public addQuestion(section: FormGroup) {
        const questions = (section.get("SurveyQuestions") as FormArray);
        questions.push(this.createQuestionControl(null, section.get("Id").value, section.get("TempId").value, questions.length + 1));
    }

    public removeQuestion(section: FormGroup, index: number) {
        const question = <SurveyQuestionUIModel>(section.get("SurveyQuestions") as FormArray).at(index).value;
        let storageType = question.Market.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : question.Market.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : question.Market.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null as DataStorageType;
        this.dataService.removeInQuestion([question.Market.TargetMarketId], storageType);

        (section.get("SurveyQuestions") as FormArray).removeAt(index);
        section.markAsDirty();
    }

    private createSectionControl(section?: SurveySectionModel): FormGroup {
        if (!section) {
            //use ordinal to maintain open sections
            //the ordinal may not be consecutive since some section can be deleted
            let ordinal = this.Sections ? this.Sections.controls.length + 1 : 1;
            section = <SurveySectionModel>{
                Id: 0,
                Name: "",
                Ordinal: ordinal,
                SurveyId: this.surveyId,
                SurveyQuestions: []
            };
        }
        let tempId = this.tempSectionId++;

        // Add input focus to the new section.
        this.focusSectionInput = this.sectionHtmlName + tempId;

        return new FormGroup({
            Id: new FormControl(section.Id),
            Name: new FormControl(section.Name, [this.ValidateSectionName(section.Ordinal), Validators.maxLength(50), Validators.required]),
            Ordinal: new FormControl(section.Ordinal),
            SurveyId: new FormControl(section.SurveyId),
            SurveyQuestions: new FormArray([this.createQuestionControl(null, 0, tempId)]),
            IsCollapsed: new FormControl(false),
            TempId: new FormControl(tempId),
            InputName: new FormControl(this.sectionHtmlName + tempId)
        });
    }

    public moveQuestionUp(section: FormGroup, index: number) {
        const questions = (<FormArray>section.controls.SurveyQuestions).controls;
        const element = questions[index];

        questions.splice(index, 1);
        questions.splice(index - 1, 0, element);

        this.recalculateOrdinals(questions);
    }

    public moveQuestionDown(section: FormGroup, index: number) {
        const questions = (<FormArray>section.controls.SurveyQuestions).controls;
        const element = questions[index];

        questions.splice(index, 1);
        questions.splice(index + 1, 0, element);

        this.recalculateOrdinals(questions);
    }

    public moveSectionUp(index: number) {
        const sections = this.Sections.controls;
        const element = sections[index];

        sections.splice(index, 1);
        sections.splice(index - 1, 0, element);

        this.recalculateOrdinals(sections);
    }

    public moveSectionDown(index: number) {
        const sections = this.Sections.controls;
        const element = sections[index];

        sections.splice(index, 1);
        sections.splice(index + 1, 0, element);

        this.recalculateOrdinals(sections);
    }

    private recalculateOrdinals(controls: AbstractControl[]) {
        controls.forEach((control: FormGroup, index: number) => {
            control.controls["Ordinal"].setValue(index + 1);
            control.controls["Ordinal"].markAsDirty();
            control.controls["Ordinal"].markAsTouched();
        });
    }

    public addSection() {
        this.Sections.push(this.createSectionControl());
    }

    public removeSection(section: FormGroup, index: number) {
        const sectionModel = <SurveySectionModel>section.value;
        let featuresToRemove = [];
        let fixtureGroupsToRemove = [];
        let fixturesToRemove = [];
        sectionModel.SurveyQuestions.forEach((question: SurveyQuestionUIModel) => {
            if (question.Market.TargetMarketType === "LocationGroup") {
                featuresToRemove.push(question.Market.TargetMarketId);
            }
            if (question.Market.TargetMarketType === "FixtureGroup") {
                fixtureGroupsToRemove.push(question.Market.TargetMarketId);
            }
            if (question.Market.TargetMarketType === "Fixture") {
                fixturesToRemove.push(question.Market.TargetMarketId);
            }
        });
        this.dataService.removeInQuestion(featuresToRemove, "FeatureInQuestion");
        this.dataService.removeInQuestion(fixtureGroupsToRemove, "FixtureGroupInQuestion");
        this.dataService.removeInQuestion(fixturesToRemove, "FixtureInQuestion");

        //remove it from formArray
        this.Sections.removeAt(index);

        //raise event to parent to store the sectionId to be deleted
        if (sectionModel.Id !== 0) {
            this.sectionDeleted.emit(sectionModel.Id);
        }

        section.markAsDirty();
        this.Sections.markAsDirty();
    }

    public marketChangedEventHandler(event: MarketChangedEventModel, question: FormGroup) {
        if (event.previous) {
            const previous = <MarketModel>event.previous;
            if (previous.TargetMarketId > 0) {
                let storageType = previous.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : previous.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : previous.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null as DataStorageType;
                this.dataService.removeInQuestion([previous.TargetMarketId], storageType);
            }
        }
        if (event.current) {
            const current = <MarketModel>event.current;
            let storageType = current.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : current.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : current.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null as DataStorageType;
            this.dataService.addInQuestion([{ Id: current.TargetMarketId, Name: (<SurveyQuestionModel>question.value).Name }], storageType);
            let optionTypeIsBool = false;
            if (current.TargetMarketType === "LocationGroup") {
                optionTypeIsBool = (event.current.DisplayInfo as MarketDisplayInfoLocationSubGroup).OptionType.toLowerCase() === "bool";

                if (optionTypeIsBool) {
                    question.get("OptionControl").setValue(false);
                    question.get("AnswerType").setValue(<AnswerType>"ChooseFromOption");
                }
            }
            this.setControlsEnabled(question, optionTypeIsBool);
        }
    }

    private setControlsEnabled(questionControl: FormGroup, optionTypeIsBool: boolean = false) {
        let answerTypeControl = questionControl.get("AnswerType");
        const currentValue: AnswerType = answerTypeControl.value;
        const marketType = this.getMarketType(questionControl);
        let answerTypeEnabled = marketType === "FixtureGroup" || marketType === "Fixture" ? false : !optionTypeIsBool;

        questionControl.get("EnableAnswerType").setValue(answerTypeEnabled);

        let optionControlEnabled = !optionTypeIsBool && currentValue !== "TypeInOption";
        questionControl.get("EnableOptionControl").setValue(optionControlEnabled);
    }

    public marketDeletedEventHandler(market: FormControl) {
        market.patchValue({
            MarketName: ""
        });
        const marketModel = <MarketModel>market.value;
        let storageType = marketModel.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : marketModel.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : marketModel.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null as DataStorageType;
        this.dataService.removeInQuestion([marketModel.TargetMarketId], storageType);
    }

    public ValidateSectionName(sectionOrdinal: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control || control.value.length <= 0 || !this.Sections) {
                // Don't bother checking, return all is okay.
                return null;
            }

            let exists = this.Sections.value.some(sec => {
                return sec.Name === control.value && sec.Ordinal !== sectionOrdinal;
            });

            if (exists) {
                return {
                    duplicateSectionName: {
                        valid: false
                    }
                };
            }
            return null;
        };
    }

    public HasImage(questionControl: FormGroup): boolean {
        return !!questionControl.get("FullImage").value;
    }

    public changeMessageControl(questionControl: FormGroup) {
        let messageControl = questionControl.get("MessageControl");
        const currentValue: MessageControl = messageControl.value;
        const newValue: MessageControl = currentValue === "CanSendMessage" ? "CanNotSendMessage" : "CanSendMessage";

        messageControl.setValue(newValue);
        messageControl.markAsDirty();
    }

    public getMessageControlText(questionControl: FormGroup): string {
        const msgControl: MessageControl = questionControl.get("MessageControl").value;
        let result = "";
        switch (msgControl) {
            case "CanSendMessage":
                result = "CAN_SEND_MESSAGE";
                break;
            case "CanNotSendMessage":
                result = "CANNOT_SEND_MESSAGE";
                break;
        }
        return result;
    }

    public getMessageControlIconClass(questionControl: FormGroup): any {
        const msgControl: MessageControl = questionControl.get("MessageControl").value;
        let cssClass: any = {
            "fa-unlock": msgControl === "CanSendMessage",
            "fa-lock": msgControl === "CanNotSendMessage"
        };

        return cssClass;
    }

    public createInstructionSource(ctrl: AbstractControl): Observable<string> {
        let source = Observable.create((observer: Observer<string>) => {
            // Runs on every search
            observer.next((ctrl.value as SurveyQuestionModel).Instruction);
        }).pipe(debounceTime(365)
            , distinctUntilChanged()
            , mergeMap((inputText: string) => {
                return from(this.getTypeAheadInstructions(inputText.toLowerCase()));
            }));

        return source;
    }

    private getTypeAheadInstructions(inputText: string): Promise<string[]> {
        let instructions: string[] = [];
        this.Sections.controls.forEach(sectionCtrl => {
            const questions = this.Questions(sectionCtrl as FormGroup);
            if (questions) {
                questions.controls.forEach(questionCtrl => {
                    const inst = questionCtrl.value.Instruction as string;
                    if (inst && inst !== "" && inst.toLowerCase().startsWith(inputText) && instructions.indexOf(inst) === -1) {
                        instructions.push(inst);
                    }
                });
            }
        });

        for (let key of Object.keys(this.dbInstructions)) {
            const inst = this.dbInstructions[key];
            if (inst && inst !== "" && inst.toLowerCase().startsWith(inputText) && instructions.indexOf(inst) === -1) {
                instructions.push(inst);
            }
        }

        return Promise.resolve(instructions);
    }
}
