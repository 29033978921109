/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unauthorized.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/imagine-ui-ng-quick-start/imagine-ui-ng-quick-start.ngfactory";
import * as i3 from "imagine-ui-ng-quick-start";
import * as i4 from "@uirouter/core";
import * as i5 from "./unauthorized.component";
var styles_UnauthorizedComponent = [i0.styles];
var RenderType_UnauthorizedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthorizedComponent, data: {} });
export { RenderType_UnauthorizedComponent as RenderType_UnauthorizedComponent };
export function View_UnauthorizedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ips-unauthorized", [], null, null, null, i2.View_ɵc_0, i2.RenderType_ɵc)), i1.ɵdid(1, 114688, null, 0, i3.ɵc, [i4.StateService, i3.ProfileService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_UnauthorizedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unauthorized", [], null, null, null, View_UnauthorizedComponent_0, RenderType_UnauthorizedComponent)), i1.ɵdid(1, 114688, null, 0, i5.UnauthorizedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnauthorizedComponentNgFactory = i1.ɵccf("app-unauthorized", i5.UnauthorizedComponent, View_UnauthorizedComponent_Host_0, {}, {}, []);
export { UnauthorizedComponentNgFactory as UnauthorizedComponentNgFactory };
