import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService, SearchInfo } from "imagine-ui-ng-core";
import { CartDataModel, CartItemModel, CartItemPostData, PlaceCartOrderPostData, CartOptions } from "../model/CartDataModel";
import { ItemSimpleDataModel } from "../model/ItemDataModel";
import { CartItemPodModel } from "../model/CartItemPodModel";
import { TokenRequestModel } from "../model/TokenRequestModel";
import { OrderWithPaymentModel } from "../model/PayflowResponseModel";
import { Subject, Observable } from "rxjs";

@Injectable()
export class CartService extends IpsBaseWebService {
    public myCurrentCart: CartDataModel;
    public invalidCartItems: ItemSimpleDataModel[] = [];

    private fakeData: CartDataModel;

    private cartUpdatedSource = new Subject<CartDataModel>();

    // Observable string streams
    public onCartUpdated = this.cartUpdatedSource.asObservable();

    constructor(http: HttpClient,
        public activeProfileService: ActiveProfileService,
        public helperFunctionsService: HelperFunctionsService
    ) {
        super(http, <any>environment, activeProfileService);
        this.init("Cart", "Shop", "v1");
        this.fakeData = {
            Id: 0,
            Owner: "",
            OwnerDetails: null,
            Items: [],
            Locations: [],
            TotalQuantity: 0,
            TotalCost: 0,
            InvalidCartItems: [],
            HasInvalidItems: false
        };
        this.myCurrentCart = this.helperFunctionsService.copyObject(this.fakeData);
    }

    //
    public head(): Promise<any> {
        const promise = new Promise<number>((resolve, reject) => {
            this.getMyCurrentCart(true).then((cartData: CartDataModel) => {
                resolve(this.calculateCartQuantityAndCost(cartData).TotalQuantity);
            });
        });
        return promise;
    }

    //
    public search(searchInfo: SearchInfo, route: string): Promise<any> {
        const locationIdParam = searchInfo.additionalQueryParams.find((param, index) => param.param === "LocationId");
        const locationIdValue = Number(locationIdParam.paramValue);

        return this.getItemsByLocation(locationIdValue, searchInfo.searchText);
    }

    //
    public searchByRelation(searchInfo: SearchInfo, route: string): Promise<any> {
        return this.search(searchInfo, route);
    }

    //
    public getFilteredCarts(filters: object[]): Promise<CartDataModel[]> {
        const promise = new Promise<CartDataModel[]>((resolve, reject) => {
            // return this.getByRoute(`${this.urlApiBaseProductPlusVersion}Business/${businessId}/InUse`);
            this.getByRoute<CartDataModel[]>(`${this.urlApiBasePlusController}/List`)
            .then((cartData: CartDataModel[]) => {
                    for (let i = 0; i < cartData.length; i++) {
                        cartData[i] = this.calculateCartQuantityAndCost(cartData[i]);
                    }
                    resolve(cartData);
                },
                (reason: any) => {
                    const message = (reason.error ? reason.error.Message : reason.message);
                    reject(message);
                }
            );
        });
        return promise;
    }

    //
    public getMyCart(): Promise<CartDataModel> {
        this.getMyInvalidCartItems();
        const promise = new Promise<CartDataModel>((resolve, reject) => {
            this.getByRoute<CartDataModel>(`${this.urlApiBasePlusController}`)
                .then((cartData: CartDataModel) => {
                    if (cartData.Id) {
                        this.myCurrentCart = this.calculateCartQuantityAndCost(cartData);
                    } else {
                        this.myCurrentCart = this.calculateCartQuantityAndCost(
                            this.helperFunctionsService.copyObject(this.fakeData)
                        );
                    }
                    this.broadcastCartUpdate();
                    resolve(this.myCurrentCart);
                },
                    (response: any) => {
                    this.myCurrentCart = this.calculateCartQuantityAndCost(
                        this.helperFunctionsService.copyObject(this.fakeData)
                    );
                    this.broadcastCartUpdate();
                }
            );

        });
        return promise;
    }

    public getMyInvalidCartItems(): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
            this.getByRoute<any>(`${this.urlApiBasePlusController}/invaliditems`)
                .then((response) => {
                    this.invalidCartItems = response;
                    this.myCurrentCart.InvalidCartItems = this.invalidCartItems;
                    resolve(this.myCurrentCart.InvalidCartItems);
                },
                    (response: any) => {
                        reject(response.Message || response.error.message);
                    }
                );

        });
        return promise;
    }

    //
    public getMyCurrentCart(forceRefresh?: boolean): Promise<CartDataModel> {
        forceRefresh = forceRefresh || false;
        if ((this.myCurrentCart.Id === 0) || (forceRefresh === true)) {
            return this.getMyCart();
        } else {
            const promise = new Promise<CartDataModel>((resolve, reject) => {
                resolve(this.myCurrentCart);

            });
            return promise;
        }
    }

    //
    public getItemsByLocation(locationId: number, searchText: string): Promise<any> {
        searchText = searchText || "";
        searchText = searchText.toUpperCase();
        const returnPromise = new Promise<any>((resolve, reject) => {
            this.getMyCurrentCart().then((cartData: CartDataModel) => {
                let items = cartData.Items;
                if (searchText) {
                    items = items.filter((x) => {
                        if (((x.Item.Name) && (x.Item.Name.toUpperCase().indexOf(searchText) !== -1)) ||
                        ((x.Item.Description) && (x.Item.Description.toUpperCase().indexOf(searchText) !== -1)) ||
                        ((x.Item.CustomerItemCode) && (x.Item.CustomerItemCode.toUpperCase().indexOf(searchText) !== -1)) ||
                        ((x.Item.EndDate) && (this.helperFunctionsService.formattedAsReadableDate(x.Item.EndDate.toString()).indexOf(searchText) !== -1)) ||
                        ((x.Item.UnitPrice) && (x.Item.UnitPrice.toString().toUpperCase().indexOf(searchText) !== -1))) {
                            return true;
                        }
                        return false;
                    });
                }
                items = items.filter((x) => {
                    if (x.LocationId === locationId) {
                        return true;
                    }
                    return false;
                });
                const returnVal = {
                    ResultList: items,
                    ResultChunkAttributes: {
                        "Index": 0,
                        "TotalRecords": items.length,
                        "RecordCount": items.length
                    }
                };
                resolve(returnVal);
            });
        });

        return returnPromise;
    }

    // Returns the number of unique items (ignoring quantity) in the cart for a given location.
    public itemsInLocationCart(locationId: number): number {
        let items = this.myCurrentCart.Items;
        items = items.filter((x) => {
            if (x.LocationId === locationId) {
                return true;
            }
            return false;
        });
        return items.length || 0;
    }

    // Returns the the first duplicate (same item id, location id, and order reason) if one
    // exists.  Otherwise return null
    public findDuplicateCartItem(cartItem: CartItemModel): CartItemModel {
        let items = this.myCurrentCart.Items.filter(
            x => (x.ItemId === cartItem.ItemId) && (x.LocationId === cartItem.LocationId) && (x.OrderReason === cartItem.OrderReason)
        );
        if (items.length > 0) {
            return items[0];
        }
        return null;
    }

    // Returns the quantity of an item in the cart for a given location.
    public numberOfItemInLocationCart(locationId: number, itemId: number): number {
        let items = this.myCurrentCart.Items;
        let tempQuantity = 0;
        items = items.filter((x) => {
            if ((x.LocationId === locationId) && (x.Item) && (x.Item.Id === itemId)) {
                return true;
            }
            return false;
        });
        for (const item of items) {
            tempQuantity = +tempQuantity + +item.Qty;
        }
        return tempQuantity || 0;
    }

    // Determines if an item exists in the current user's cart for a given location.
    public inCart(locationId: number, itemId: number): boolean {
        let items = this.myCurrentCart.Items;
        items = items.filter((x) => {
            if ((x.LocationId === locationId) && (x.Item) && (x.Item.Id === itemId)) {
                return true;
            }
            return false;
        });
        if (items.length > 0) {
            return true;
        }
        return false;
    }

    // Return a copy of the current cart data.
    public myCurrentCartData(locationIds?: number[]): CartDataModel {

        let cartClone: CartDataModel = this.helperFunctionsService.copyObject(this.myCurrentCart);

        if (locationIds) {
            cartClone.Items = cartClone.Items.filter((item: CartItemModel) => locationIds.indexOf(item.LocationId) > -1);
            cartClone = this.calculateCartQuantityAndCost(cartClone);
        }

        return cartClone;
    }

    // This should happen as part of any login, logout, or client switching process to
    // ensure that stale data isn't used.
    public clearMyCart(): Promise<boolean> {
        const cartId = this.myCurrentCart.Id || 0;
        const promise = new Promise<boolean>((resolve, reject) => {
            this.deleteByRoute<boolean>(`${this.urlApiBasePlusController}${cartId}`)
            .then((response: boolean) => {
                if (response) {
                    this.myCurrentCart = this.calculateCartQuantityAndCost({
                        Id: this.myCurrentCart.Id,
                        Owner: this.myCurrentCart.Owner,
                        OwnerDetails: null,
                        Items: [],
                        Locations: [],
                        TotalQuantity: 0,
                        TotalCost: 0
                    });
                    this.broadcastCartUpdate();
                    resolve(response);
                }
                reject("");
            },
            (response: any) => {
                this.myCurrentCart = null;
                this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    }

    // STUBBED TODO
    public addItemToMyCart(item: CartItemModel): Promise<CartDataModel> {
        const promise = new Promise<CartDataModel>((resolve, reject) => {
            let itemId = 0;
            if ((item.Item) && (item.Item.Id)) {
                itemId = item.Item.Id;
            }
            const postData: CartItemPostData = {
                Id: item.Id || 0,
                LocationId: item.LocationId || 0,
                CartId: this.myCurrentCart.Id || 0,
                ItemId: itemId,
                DigitalDownload: item.Item.DigitalDownload,
                Qty: item.Qty || 0,
                OrderReason: item.OrderReason || "",
                BusinessIdentity: this.activeProfileService.businessIdentity || ""
            };
            this.post<any>(postData, null, `${this.urlApiBasePlusController}Location/Item`)
            .then((response: CartItemModel) => {
                response.Item = response.Item || item.Item || null;
                this.myCurrentCart.Items.push(response);
                this.myCurrentCart = this.calculateCartQuantityAndCost(this.myCurrentCart);
                this.broadcastCartUpdate();
                resolve(this.myCurrentCart);
            },
            (response: any) => {
                this.myCurrentCart = null;
                this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    }

    public addToCart(item: CartItemPostData): Promise<number> {
        return this.post<any>(item, null, `${this.urlApiBasePlusController}Location/Item`).then(response => {
            this.getMyCart();
            this.broadcastCartUpdate();
            return response.Id;
        });
    }

    // STUBBED TODO
    public updateItemInMyCart(item: CartItemModel): Promise<CartDataModel> {
        const promise = new Promise<CartDataModel>((resolve, reject) => {
            let itemId = 0;
            if ((item.Item) && (item.Item.Id)) {
                itemId = item.Item.Id;
            }
            const postData: CartItemPostData = {
                Id: item.Id || 0,
                LocationId: item.LocationId || 0,
                CartId: this.myCurrentCart.Id || 0,
                ItemId: itemId,
                DigitalDownload: item.DigitalDownload,
                Qty: item.Qty || 0,
                OrderReason: item.OrderReason || "",
                BusinessIdentity: this.activeProfileService.businessIdentity || ""
            };
            this.put<any>(postData, null, `${this.urlApiBasePlusController}Location/Item`)
            .then((response: CartItemModel) => {
                const tempIndex = this.myCurrentCart.Items.findIndex((x) => {
                    if ((x.LocationId === item.LocationId) && (x.Id === item.Id)) {
                        return true;
                    }
                    return false;
                });
                if (tempIndex !== -1) {
                    response.Item = response.Item || item.Item || null;
                    this.myCurrentCart.Items[tempIndex] = response;
                    this.myCurrentCart = this.calculateCartQuantityAndCost(this.myCurrentCart);
                    this.broadcastCartUpdate();
                    resolve(this.myCurrentCart);
                }
                reject("Item removed from cart mid-process.");
            },
            (response: any) => {
                this.myCurrentCart = null;
                this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    }

    //
    public estimateCartCost(postData: PlaceCartOrderPostData): Promise<object> {
        const promise = new Promise<object>((resolve, reject) => {
            this.post<any>(postData, null, `${this.urlApiBasePlusController}CostEstimate`)
            .then((response: object) => {
                resolve(response);
            },
            (response: any) => {
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    }

    //
    public placeCartOrder(postData: PlaceCartOrderPostData): Promise<object> {
        const promise = new Promise<object>((resolve, reject) => {
            this.post<any>(postData, null, `${this.urlApiBasePlusController}Order`)
                .then((response: object) => {
                    //clear cart
                    this.myCurrentCart = this.calculateCartQuantityAndCost({
                        Id: this.myCurrentCart.Id,
                        Owner: this.myCurrentCart.Owner,
                        OwnerDetails: null,
                        Items: [],
                        Locations: [],
                        TotalQuantity: 0,
                        TotalCost: 0
                    });
                    this.broadcastCartUpdate();
                    resolve(response);
            },
            (reason: any) => {
                reject(reason);
            });
        });
        return promise;
    }

    //
    public getLocationDisplay(locationId: number): string {
        const tempIndex = this.myCurrentCart.Locations.findIndex((x) => {
            if (x.Key === locationId) {
                return true;
            }
            return false;
        });
        if (tempIndex !== -1) {
            return this.myCurrentCart.Locations[tempIndex].Value;
        }
        return "NOT FOUND";
    }

    //
    private removeLocation(locationId: number): void {
        const tempIndex = this.myCurrentCart.Locations.findIndex((x) => {
            if (x.Key === locationId) {
                return true;
            }
            return false;
        });
        if (tempIndex !== -1) {
            this.myCurrentCart.Locations.splice(tempIndex, 1);
        }
    }

    // STUBBED TODO
    public removeItemFromMyCart(locationId: number, cartItemId: number): Promise<CartDataModel> {
        const promise = new Promise<CartDataModel>((resolve, reject) => {
            this.deleteByRoute<boolean>(`${this.urlApiBasePlusController}CartItem/${cartItemId}`)
            .then((response: boolean) => {
                if (response) {
                    const tempIndex = this.myCurrentCart.Items.findIndex((x) => {
                        if ((x.LocationId === locationId) && (x.Id === cartItemId)) {
                            return true;
                        }
                        return false;
                    });
                    if (tempIndex !== -1) {
                        this.myCurrentCart.Items.splice(tempIndex, 1);
                        if (this.itemsInLocationCart(locationId) === 0) {
                            this.removeLocation(locationId);
                        }
                        this.myCurrentCart = this.calculateCartQuantityAndCost(this.myCurrentCart);
                        this.broadcastCartUpdate();
                        resolve(this.myCurrentCart);
                    }
                    reject("Item removed from cart mid-process.");
                }
                reject("");
            },
            (response: any) => {
                this.myCurrentCart = null;
                this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    }

    // STUBBED TODO
    public clearLocationFromMyCart(locationId: number): Promise<CartDataModel> {
        const cartId = this.myCurrentCart.Id || 0;
        const promise = new Promise<CartDataModel>((resolve, reject) => {
            this.deleteByRoute<boolean>(`${this.urlApiBasePlusController}${cartId}/Location/${locationId}`)
            .then((response: boolean) => {
                if (response) {
                    let items = this.myCurrentCart.Items;
                    items = items.filter((x) => {
                        if (x.LocationId === locationId) {
                            return true;
                        }
                        return false;
                    });
                    for (let i = 0; i < items.length; i++) {
                        const tempIndex = this.myCurrentCart.Items.findIndex((x) => {
                            if (x.Id === items[i].Id) {
                                return true;
                            }
                            return false;
                        });
                        if (tempIndex !== -1) {
                            this.myCurrentCart.Items.splice(tempIndex, 1);
                        }
                    }
                    this.removeLocation(locationId);
                    this.myCurrentCart = this.calculateCartQuantityAndCost(this.myCurrentCart);
                    this.broadcastCartUpdate();
                    resolve(this.myCurrentCart);
                }
                reject("");
            },
            (response: any) => {
                this.myCurrentCart = null;
                this.broadcastCartUpdate();
                reject(response.Message || response.error.message);
            });
        });
        return promise;
    }

    // Determines the quantity of a cart for a subset of locations.
    public quantityOfLimitedCart(cart: CartDataModel, locations: number[]): number {
        let tempQuantity = 0;
        const items = cart.Items.filter(x => locations.indexOf(x.LocationId) !== -1);
        for (const item of items) {
            tempQuantity = +tempQuantity + +item.Qty;
        }
        return tempQuantity;
    }

    // Updates the calculated cart data based on the current location and item data.
    private calculateCartQuantityAndCost(cart: CartDataModel): CartDataModel {
        let tempQuantity = 0;
        let tempCost = 0;
        if (cart) {
            for (const item of cart.Items) {
                let price = 0;
                if ((item.Item) && (item.Item.UnitPrice)) {
                    price = +item.Item.UnitPrice;
                }
                tempQuantity = +tempQuantity + +item.Qty;
                tempCost += (item.Qty * (price));
            }
            cart.TotalQuantity = tempQuantity;
            cart.TotalCost = tempCost;
        }
        return cart;
    }

    // Service message commands
    private broadcastCartUpdate() {
        this.cartUpdatedSource.next(this.myCurrentCart);
    }

    // update variable pod after the punchout
    public updateVariablePOD(model: CartItemPodModel) {
        const route = `${this.urlApiBaseProductPlusVersion}/Cart/UpdateVariablePOD`;
        return this.put(model, null, route);
    }

    public GetSecureToken(request: TokenRequestModel): Promise<string> {
        let route = `${this.urlApiBasePlusController}GetSecureToken?Amount=${request.Amount}&BusinessIdentity=${this.activeProfileService.profile.businessIdentity}
                        &ReturnUrl=${request.ReturnUrl}`;
        if (request.CancelUrl !== undefined) {
            route = `${route}&CancelUrl=${request.CancelUrl}`;
        }
        if (request.ErrorUrl !== undefined) {
            route = `${route}&ErrorUrl=${request.ErrorUrl}`;
        }
        if (request.UrlMethod !== undefined) {
            route = `${route}&UrlMethod=${request.UrlMethod}`;
        }
        if (request.CSCRequired !== undefined) {
            route = `${route}&CSCRequired=${request.CSCRequired}`;
        }
        return this.getByRoute(route);
    }

    // update variable pod after the punchout
    public updateCartOptions(options: CartOptions) {
        const route = `${this.urlApiBaseProductPlusVersion}/Cart/Options`;
        return this.post(options, null, route);
    }

    //place order with payment information
    public placeCartOrderWithPayment(postData: OrderWithPaymentModel): Promise<object> {
        const promise = new Promise<object>((resolve, reject) => {
            this.post<any>(postData, null, `${this.urlApiBasePlusController}OrderWithPayment`)
                .then((response: object) => {
                    //clear cart
                    this.myCurrentCart = this.calculateCartQuantityAndCost({
                        Id: this.myCurrentCart.Id,
                        Owner: this.myCurrentCart.Owner,
                        OwnerDetails: null,
                        Items: [],
                        Locations: [],
                        TotalQuantity: 0,
                        TotalCost: 0
                    });
                    this.broadcastCartUpdate();
                    resolve(response);
                },
                    (response: any) => {
                        reject(response.Message);
                    });
        });
        return promise;
    }

    public VoidAuthorization(transactionId: string): Promise<string> {
        let route = `${this.urlApiBasePlusController}${transactionId}/VoidTransaction`;
        return this.postByRoute(route, null);
    }
}
