import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { ListSearchComponent, ResultDetailComponent, TranslatedTexts } from "imagine-ui-ng-list-search";

import { SpaceService, FixtureBySpaceService, LocationBySpaceService, LocationModel, SpaceModel } from "../../index";


@Component({
  selector: "app-space-view",
  templateUrl: "./space-view.component.html",
  styleUrls: ["./space-view.component.scss"]
})
export class SpaceViewComponent implements OnInit {

    //Public Properties
    public promise: Promise<any>;
    public loaded: boolean;
    public myspace: SpaceModel;
    public fixtureSearchHelper: ListSearchHelper;
    public locationSearchHelper: ListSearchHelper;
    private locationLabel: string;
    private locationLabelPlural: string;
    private fixtureLabel: string;
    private fixtureLabelPlural: string;
    public translated = false;

    constructor(private trans: Transition, private translateService: TranslateService, private spaceService: SpaceService,
                private fixtureBySpaceService: FixtureBySpaceService, private locationBySpaceService: LocationBySpaceService, private listSearchHelper: ListSearchHelper) {
    }

    ngOnInit() {
        let id = this.trans.params().id;
        this.myspace = { Id: Number(id) } as SpaceModel;

        this.fixtureSearchHelper = this.listSearchHelper.getListSearchHelper(this.fixtureBySpaceService);
        this.locationSearchHelper = this.listSearchHelper.getListSearchHelper(this.locationBySpaceService);

        // If we got an ID to load, load it.
        if (Number(id) > 0) {
            //Initial call to populate screen on load
            this.loaded = false;

            this.promise = this.spaceService.get(id).then((response) => {
                Object.assign(this.myspace, response);

                // Populate quick reference by space
                this.getFixtureList("", id);
                this.fixtureBySpaceService.headBySpace(id).then((data) => {
                    this.fixtureSearchHelper.TotalRecords = data;
                });

                // Populate quick reference by location
                this.getLocationList("", id);
                this.locationBySpaceService.headBySpace(id).then((data) => {
                    this.locationSearchHelper.TotalRecords = data;
                });
                this.loaded = true;
            });
        }

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }


    private translateText() {
        this.translateService.get(["LOCATION", "LOCATIONS", "FIXTURE", "FIXTURES", "CREATE_NEW_SPACE"]).subscribe((res: [string]) => {
            this.locationLabel = res["LOCATION"];
            this.locationLabelPlural = res["LOCATIONS"];
            this.fixtureLabel = res["FIXTURE"];
            this.fixtureLabelPlural = res["FIXTURES"];
            this.translated = true;
        });
    }

    public getFixtureList(search: string, id: string | number) {
        if (!search) {
            search = "";
        }
        let searchInfo = { searchText: search, id: Number(id) } as SearchInfo;
        this.fixtureSearchHelper.promise = this.fixtureSearchHelper.searchHelper(searchInfo);

        //Add label for search grid
        this.fixtureSearchHelper.promise.then(() => {
            this.fixtureSearchHelper.resultList.forEach((item) => {
                item.Label = item.Model ? item.Name + " - " + item.Model : item.Name;
            });
        });
    }

    public getLocationList(search: string, id: string | number) {
        if (!search) {
            search = "";
        }

        let searchInfo = { searchText: search, id: Number(id) } as SearchInfo;
        this.locationSearchHelper.promise = this.locationSearchHelper.searchHelper(searchInfo);

        //Add label for search grid
        this.locationSearchHelper.promise.then(() => {
            this.locationSearchHelper.resultList.forEach((item) => {
                item.Label = `${item.LocationIdentifier} - ${item.Name} - ${item.Addresses[0].City}, ${item.Addresses[0].StateProvince}`;
            });
        });
    }
}
