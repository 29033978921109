<div>
  <form ipsFocus="searchInput">
      <div>
        <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
      </div>
      <div class="ips-modal-header ips-modal-dark-header">
        <h4 class="modal-title m-0" translate>{{locationIdentifier }} - {{locationName}}</h4>
      </div>
      <div class="modal-body ips-modal-search-body ips-modal-responsive">
        <ips-list-search headerLabel=""
          searchLabel="{{'CAMPAIGN_UP_DOWN_USER' | translate }}"
          searchLabelPlural="{{'CAMPAIGN_UP_DOWN_USERS' | translate }}"
          labelLoading="{{'ACCESSING_CAMPAIGN_UP_DOWN_ANALYTICS_INFO' | translate }}"
          labelLoadingError="{{'ERROR_ACCESSING_CAMPAIGN_UP_DOWN_ANALYTICS_INFO' | translate }}"
          [listData]="campaignUpDownAnalytics"
          [query]="campaignUpDownAnalyticsQuery"
          [busy]="busy"
          [quickRef]="true"
          [scroller]=true
          [hideHeader]="true"
          [promise]="campaignUpDownAnalyticsPromise"
          [autoFocus]=" focusCampaignUpDown"
          [isModal]="true"
          [hideList]="true"
          [translatedTexts]="translatedTexts"
          (onListDataChange)="getData($event.search)">
          <ng-template #searchHeaderTemplate>
            <div class="row">
              <section class="col-12">
                <div class="ips-ls-search-module-imgn" [ngClass]="{ 'pr-0': infoTooltipText}">
                    <div class="ips-ls-searchBox pt-2">
                      <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                      <input type="text"
                        class="form-control form-control-lg ips-ls-input"
                        [(ngModel)]="campaignUpDownAnalyticsQuery" (ngModelChange)="onQueryChange($event)"
                        id="btn-search"
                        value=""
                        maxlength="20"
                        [ngModelOptions]="{ debounce: 350 }"
                        aria-label="Text input with dropdown button" name="searchInput">
                    </div>
                    <div class="row no-gutters mt-2">
                      <div class="col-12 col-md-auto ml-3">
                          <span class="badge-pill badge-secondary font-weight-normal">{{ campaignUpDownAnalytics.ResultChunkAttributes.TotalRecords | number }}</span>
                          <span class="ml-1">{{campaignUpDownAnalytics.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping}} {{'FOUND_OF' | translate}}</span>
                      </div>
                      <div class="col-12 col-md-auto ml-3 ml-md-2 mt-1 mt-md-0">
                          <span class="badge-pill badge-secondary font-weight-normal">{{ campaignUpDownAnalytics.TotalRecords | number }}</span>
                          <span class="ml-1">{{ 'TOTAL' | translate }} {{campaignUpDownAnalytics.TotalRecords | i18nPlural: pluralMapping}}</span>
                      </div>
                    </div>
                </div>
              </section>
            </div>
          </ng-template>
          <section class="col-12" search-result-List>
            <div class="row">
              <div class="col-12">
                <div class="ips-ls-searchResults ips-ls-long-text-wrap list-group col-10">
                  <div class="list-group-item no-border">
                    <div class="marketed-locations-label" translate>DOWNLOAD_BY</div>
                    <div class="marketed-locations-count" translate>REPORT_DATE</div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="ips-ls-searchResults ips-ls-long-text-wrap list-group">
                  <div infiniteScroll (scrolled)="getMoreListData()" [infiniteScrollDisabled]="busy" [infiniteScrollDistance]="0" [scrollWindow]="!isModal" [ngClass]="{'list-search-infinite-container': isModal}">
                    <div list-search-item class="ips-ls-search-item border-0" *ngFor="let result of campaignUpDownAnalytics.resultList">
                      <div class="list-group-item input-group no-round change-hover-color p-0">
                        <div class="row">
                            <div class="col">
                              <div class="py-2 px-3">{{result.Label}}</div>
                            </div>
                            <div class="col-4 offset-col-4">
                              <div class="py-2 px-3">{{ result.ReportDate | date:'shortDate' }}</div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ips-list-search>
      </div>
  </form>
</div>
