import { OnInit, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ItemService, PunchOutService, ShopSettingsService } from "../index";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { ActiveProfileService, HelperFunctionsService, FileDownloadService } from "imagine-ui-ng-core";
import { CartItemPostData, ItemDataModel, ItemSimpleDataModel, CartService, CartItemModel, OrderService } from "../index";
import { AuthService } from "imagine-ui-ng-security";
var ItemDisplayCardComponent = /** @class */ (function () {
    // ----- CONSTRUCTOR -----
    function ItemDisplayCardComponent(translateService, activeProfileService, authService, helperFunctionsService, ipsMessage, cartService, itemService, punchOutService, orderService, fileDownloadService, settingsService) {
        this.translateService = translateService;
        this.activeProfileService = activeProfileService;
        this.authService = authService;
        this.helperFunctionsService = helperFunctionsService;
        this.ipsMessage = ipsMessage;
        this.cartService = cartService;
        this.itemService = itemService;
        this.punchOutService = punchOutService;
        this.orderService = orderService;
        this.fileDownloadService = fileDownloadService;
        this.settingsService = settingsService;
        this.punchOut = new EventEmitter();
        this.TextToTranslate = [
            "ADDITIONAL_POP",
            "PROFILE_CHANGE",
            "STORE_DISCARD",
            "WEATHER_DAMAGE_BY_SHIPPER",
            "REPLACEMENT_KIT_NOT_DELIVERED",
            "BOARD_TOUR_EXEC_VISIT",
            "REFRESH_REPLACEMENT_INV",
            "REMODEL_STORE",
            "ELEMENT_MISSING",
            "WRONG_SIZE_ELEMENT",
            "MONTHLY_KIT_NOT_RECEIVED",
            "FAULTY_PACKAGING",
            "KIT_NOT_RECEIVED",
            "NEW_STORE_ORDER",
            "GRAND_OPENING",
            "CHANGEOVER_STORE",
            "CHANGES",
            "CHANGE_IN_CONTROL_KIT",
            "ACQUISITION_KIT",
            "NEW_STORE_ACQUISITION",
            "INCORRECT_MESSAGE",
            "CUSTOMER_ITEM_CODE",
            "PRODUCT_DESCRIPTION",
            "PROMPT_CLEAR_ITEM_FROM_CART",
            "SIZE_WIDTH_HEIGHT",
            "SIZE_WIDTH_HEIGHT_DEPTH",
            "AVAILABLE_QUANTITY",
            "LAST_AVAILABLE_DATE",
            "PRICE",
            "PROMOTION",
            "FIXTURE_GROUP_HOLDER",
            "SPACE",
            "PRODUCT_TYPE",
            "AVAILABLE_NOW",
            "PLACEMENT",
            "NOT_AVAILABLE",
            "ITEM_PREVIOUSLY_ORDERED",
            "CATEGORY",
            "IMAGINE_ITEM_CODE"
        ];
        this.invalidCartItems = [];
        this.Image = "https://d1icd6shlvmxi6.cloudfront.net/gsc/P5ND2U/75/c6/e8/75c6e8ec3f904f23a84f28b1b25f91d8/images/pbi_9860_-_my_products_-_list/u18349.svg?token=2a3af84035cea29bc988bc0522beef84f9a487807152e6566b52a77a1985924d";
        this.IsFavorite = false;
        this.ShowOrderReasonControl = true;
        this.Characteristics = [];
    }
    // ----- FUNCTIONS -----
    // Determines if the item exists in the current user's cart for the given location.
    ItemDisplayCardComponent.prototype.inCart = function () {
        if (this.cartService.inCart(this.LocationItemData.LocationId, this.LocationItemData.Item.Id)) {
            // if (!this.LocationItemData.OrderReason) {
            //     this.synchFromCart();
            // }
            return true;
        }
        return false;
    };
    //
    ItemDisplayCardComponent.prototype.resetLocalSelections = function () {
        this.LocationItemData.OrderReason = "";
        this.setInitialQuantity();
    };
    // Adds an item to a given location in the cart.
    ItemDisplayCardComponent.prototype.addToCart = function (toAdd) {
        var _this = this;
        toAdd = toAdd || this.LocationItemData;
        var duplicate = this.cartService.findDuplicateCartItem(toAdd);
        if (duplicate === null) {
            this.cartService.addItemToMyCart(this.helperFunctionsService.copyObject(toAdd)).then(function () {
                _this.resetLocalSelections();
                _this.synchFromCart();
            });
        }
        else {
            duplicate.Qty = duplicate.Qty + toAdd.Qty;
            this.cartService.updateItemInMyCart(duplicate);
            this.resetLocalSelections();
            this.synchFromCart();
        }
    };
    // Removes an item from a given location in the cart.
    ItemDisplayCardComponent.prototype.removeFromCart = function () {
        var _this = this;
        var locationDisplay = this.cartService.getLocationDisplay(this.LocationItemData.LocationId);
        var translated = this.translateService.instant("PROMPT_CLEAR_ITEM_FROM_CART", { itemName: this.LocationItemData.Item.Description, locationName: locationDisplay });
        this.ipsMessage.confirm({
            title: "WARNING",
            body: translated,
            ok: "YES_REMOVE_PRODUCTS",
            cancel: "NO_KEEP_PRODUCTS"
        }).then(function () {
            _this.ipsMessage.waitForWork({
                body: "PROCESSING",
                workFunction: function () { return _this.cartService.removeItemFromMyCart(_this.LocationItemData.LocationId, _this.LocationItemData.Id); },
                progressMessage: "PROCESSING"
            });
        })
            .catch(function () {
            // rejection
        });
    };
    // Makes a call back to a passed in function to either add or remove the item from
    // the cart (based on whether or not it is already present for the given location).
    ItemDisplayCardComponent.prototype.toggleInCart = function () {
        if (this.inCart()) {
            this.removeFromCart();
        }
        else {
            this.addToCart(this.LocationItemData);
        }
    };
    // Makes a call back to the passed in function that handles toggling the "favorite" setting
    // of the item.
    ItemDisplayCardComponent.prototype.toggleFavorite = function () {
        console.log(this.LocationItemData.LocationId);
    };
    ItemDisplayCardComponent.prototype.setInitialQuantity = function () {
        if (this.hasOrderQuantities) {
            this.LocationItemData.Qty = this.getQuantityOptions()[0].Quantity;
        }
        else {
            this.LocationItemData.Qty = 1;
        }
    };
    ItemDisplayCardComponent.prototype.isCurrentItemInvalid = function (itemId) {
        if (this.DisplayMode === "cart") {
            this.invalidCartItems = this.cartService.invalidCartItems;
            return this.invalidCartItems.some(function (item) { return item.Id === itemId; });
        }
        return false;
    };
    // Makes a call back to the passed in function that handles an items details being changed,
    // but only if the item is already part of the user's cart.
    ItemDisplayCardComponent.prototype.itemDetailsChanged = function () {
        if ((!this.LocationItemData.Qty) || (this.LocationItemData.Qty < 1)) {
            this.setInitialQuantity();
        }
        else {
            this.LocationItemData.Qty = parseInt(Math.floor(this.LocationItemData.Qty).toString(), 10);
            var elmnt = document.getElementById("item-display-card-1-" + this.LocationItemData.Id + "-" + this.LocationItemData.Item.Id);
            if (elmnt) {
                elmnt.value = this.LocationItemData.Qty.toString();
            }
            elmnt = document.getElementById("item-display-card-2-" + this.LocationItemData.Id + "-" + this.LocationItemData.Item.Id);
            if (elmnt) {
                elmnt.value = this.LocationItemData.Qty.toString();
            }
        }
        if (this.DisplayMode === "cart") {
            this.cartService.updateItemInMyCart(this.LocationItemData);
        }
        this.synchFromCart();
    };
    // Adds characteristics to the item for display.
    ItemDisplayCardComponent.prototype.addCharacteristic = function (key, value) {
        var newCharacteristic = {
            Id: this.Characteristics.length,
            Key: key,
            Value: value,
            Type: "Kit",
            CanHighlight: false,
            IsDate: false
        };
        switch (key) {
            case "CustomerItemCode":
                newCharacteristic.Key = this.TranslatedTexts["CUSTOMER_ITEM_CODE"];
                break;
            case "ProductType":
                newCharacteristic.Key = this.TranslatedTexts["PRODUCT_TYPE"];
                break;
            case "Size":
                newCharacteristic.Key = this.TranslatedTexts["SIZE_WIDTH_HEIGHT"];
                break;
            case "SizeWithDepth":
                newCharacteristic.Key = this.TranslatedTexts["SIZE_WIDTH_HEIGHT_DEPTH"];
                break;
            // case "AvailableQty":
            //     newCharacteristic.Key = this.TranslatedTexts["AVAILABLE_QUANTITY"];
            //     break;
            case "EndDate":
                newCharacteristic.Key = this.TranslatedTexts["LAST_AVAILABLE_DATE"];
                if (this.isRental) {
                    newCharacteristic.Value = "N/A";
                }
                else {
                    if (newCharacteristic.Value) {
                        newCharacteristic.Value =
                            this.helperFunctionsService.formattedAsReadableDate(newCharacteristic.Value.toString());
                        newCharacteristic.IsDate = true;
                    }
                    else {
                        newCharacteristic.Value = this.TranslatedTexts["AVAILABLE_NOW"];
                    }
                }
                break;
            case "UnitPrice":
                newCharacteristic.Key = this.TranslatedTexts["PRICE"];
                if (this.isRental) {
                    newCharacteristic.Value = "N/A";
                }
                else if (!this.hasPriceTiers) {
                    newCharacteristic.Value = this.toPriceString(newCharacteristic.Value);
                }
                break;
            case "UnitPriceOrdered":
                newCharacteristic.Key = this.TranslatedTexts["PRICE"];
                break;
            case "AvailableQty":
                newCharacteristic.Key = this.TranslatedTexts["AVAILABLE_QUANTITY"];
                if (this.isVariablePOD || this.isDigitalDownload) {
                    newCharacteristic.Value = "N/A";
                }
                else if (this.LocationItemData.Item.DisplayAvailable === false && newCharacteristic.Value === 0) {
                    newCharacteristic.Value = "N/A";
                }
                else if (newCharacteristic.Value) {
                    newCharacteristic.Value = Number(newCharacteristic.Value).toString();
                }
                break;
            case "PromotionName":
                newCharacteristic.Key = this.TranslatedTexts["PROMOTION"];
                break;
            case "HolderName":
                newCharacteristic.Key = this.TranslatedTexts["PLACEMENT"];
                break;
            case "SpaceName":
                newCharacteristic.Key = this.TranslatedTexts["SPACE"];
                break;
            case "ImagineItemCode":
                newCharacteristic.Key = this.TranslatedTexts["IMAGINE_ITEM_CODE"];
                break;
            case "Category":
                newCharacteristic.Key = this.TranslatedTexts["CATEGORY"];
                break;
            default:
                return; // If not a presently displayed value, should not be added.
        }
        this.Characteristics.push(newCharacteristic);
    };
    // Updates the local LocationItemData based on what's already in the cart if that's
    // applicable.
    ItemDisplayCardComponent.prototype.synchFromCart = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            var totalQuantity = 0;
            if (_this.DisplayMode === "shop") {
                totalQuantity = _this.cartService.numberOfItemInLocationCart(_this.LocationItemData.LocationId, _this.ItemData.Id);
            }
            _this.NumberInCart = totalQuantity;
            resolve();
        });
        return promise;
    };
    // Calls the translation service to process text.
    ItemDisplayCardComponent.prototype.translateText = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.translateService.get(_this.TextToTranslate).toPromise().then(function (response) {
                _this.TranslatedTexts = response;
                resolve();
            });
        });
        return promise;
    };
    Object.defineProperty(ItemDisplayCardComponent.prototype, "isVariablePOD", {
        get: function () {
            if (this.DisplayMode === "shop") {
                return this.ItemData.Variable || false;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "isDigitalDownload", {
        get: function () {
            var returnValue = false;
            if (this.DisplayMode === "shop") {
                returnValue = this.ItemData.DigitalDownload;
            }
            else if (this.DisplayMode === "cart") {
                returnValue = this.LocationItemData.DigitalDownload;
            }
            return returnValue;
        },
        enumerable: true,
        configurable: true
    });
    ItemDisplayCardComponent.prototype.getQuantityOptions = function () {
        var item = this.DisplayMode === "shop" ? this.ItemData : this.LocationItemData.Item;
        if (item && item.OrderQuantities) {
            var quantityOptions = JSON.parse(item.OrderQuantities);
            return quantityOptions;
        }
        else {
            return [];
        }
    };
    ItemDisplayCardComponent.prototype.getPriceTiers = function () {
        var item = this.DisplayMode === "shop" ? this.ItemData : this.LocationItemData.Item;
        if (item && item.PriceQuantities) {
            var priceTiers = JSON.parse(item.PriceQuantities);
            return priceTiers;
        }
        else {
            return [];
        }
    };
    Object.defineProperty(ItemDisplayCardComponent.prototype, "hasPriceTiers", {
        get: function () {
            return this.getPriceTiers().length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "hasOrderQuantities", {
        get: function () {
            return this.getQuantityOptions().length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "showOrderQuantity", {
        get: function () {
            if (this.DisplayMode === "shop") {
                if (this.ItemData.ProductType === "DropShipment") {
                    return false;
                }
                else {
                    return !this.ItemData.DigitalDownload;
                }
            }
            else if (this.DisplayMode === "cart") {
                if (this.LocationItemData.Item.ProductType === "DropShipment") {
                    return false;
                }
                else {
                    return !this.LocationItemData.DigitalDownload;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "showOrderReason", {
        get: function () {
            if (this.DisplayMode === "shop") {
                if (this.ItemData.ProductType === "DropShipment") {
                    return false;
                }
                else {
                    return this.ShowOrderReasonControl;
                }
            }
            else if (this.DisplayMode === "cart") {
                if (this.LocationItemData.Item.ProductType === "DropShipment") {
                    return false;
                }
                else {
                    return this.ShowOrderReasonControl;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "orderReasonDisabled", {
        get: function () {
            if (this.DisplayMode === "shop") {
                if (this.ItemData.DigitalDownload && this.NumberInCart > 0) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else if (this.DisplayMode === "cart") {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "imageUrl", {
        get: function () {
            if (this.DisplayMode === "shop") {
                return this.Image;
            }
            else if (this.DisplayMode === "cart") {
                var mediaId = this.LocationItemData.Item.MasterMediaId;
                var baseUrl = this.cartService.urlApiBase;
                var businessId = this.activeProfileService.businessIdentity;
                var token = this.authService.getIdToken();
                var url = baseUrl + "Media/v1/MasterMedia/" + businessId + "/Item/" + mediaId + "/Original/Jpg?BusinessIdentity=" + businessId + "&idToken=" + token;
                return url;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "availableForDownload", {
        get: function () {
            var isVariable = this.ItemData.Variable;
            var isDigitalDownload = this.ItemData.DigitalDownload;
            var isDropShipment = (this.ItemData && this.ItemData.ProductType === "DropShipment");
            var hasCost = this.ItemData.UnitPrice > 0;
            var hasBeenOrdered = this.ItemData.OrderIds && !!this.ItemData.OrderIds.length;
            if (isDropShipment) {
                return false;
            }
            if (isVariable) {
                return false;
            }
            else {
                return isDigitalDownload && (!hasCost || hasBeenOrdered);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "downloadButtonDisabled", {
        get: function () {
            return this.LocationItemData.OrderReason === "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "canAddToCart", {
        get: function () {
            var returnValue = false;
            if (this.isDigitalDownload) {
                if (this.NumberInCart > 0) {
                    returnValue = false;
                }
                else {
                    if (this.showOrderReason) {
                        returnValue = this.LocationItemData.OrderReason !== "";
                    }
                    else {
                        return true;
                    }
                }
            }
            else {
                if (this.showOrderReason) {
                    returnValue = this.LocationItemData.OrderReason !== "";
                }
                else {
                    return true;
                }
            }
            return returnValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ItemDisplayCardComponent.prototype, "isRental", {
        get: function () {
            if (this.DisplayMode === "shop") {
                return this.ItemData.ProductType === "DropShipment";
            }
            else if (this.DisplayMode === "cart") {
                return this.LocationItemData.Item.ProductType === "DropShipment";
            }
        },
        enumerable: true,
        configurable: true
    });
    // ----- START UP -----
    ItemDisplayCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateText().then(function () {
            if (!_this.LocationItemData) {
                _this.LocationItemData = _this.itemService.ItemDataToCartLocationItem(_this.ItemData, _this.LocationId, 1, "");
            }
            _this.synchFromCart()
                .then(function () {
                // Add the characteristics for display.
                if ((_this.LocationItemData.Item.MasterMediaId) &&
                    (_this.LocationItemData.Item.Media)) {
                    _this.Image =
                        _this.LocationItemData.Item.Media.ResourceUri + "?BusinessIdentity=" + _this.activeProfileService.businessIdentity + "&idToken=" + _this.authService.getIdToken();
                }
                _this.addCharacteristic("CustomerItemCode", _this.LocationItemData.Item.CustomerItemCode);
                if (_this.LocationItemData.Item["Depth"]) {
                    _this.addCharacteristic("SizeWithDepth", _this.LocationItemData.Item["Width"] + " x " +
                        _this.LocationItemData.Item["Height"] + " x " +
                        _this.LocationItemData.Item["Depth"]);
                }
                else if (_this.LocationItemData.Item["Width"]) {
                    _this.addCharacteristic("Size", _this.LocationItemData.Item["Width"] + " x " +
                        _this.LocationItemData.Item["Height"]);
                }
                else {
                    _this.addCharacteristic("Size", _this.TranslatedTexts["NOT_AVAILABLE"]);
                }
                _this.addCharacteristic("ProductType", _this.LocationItemData.Item.ProductType === "DropShipment" ? "Rental" : _this.LocationItemData.Item.ProductType);
                var hasAvailableQuantity = !!_this.LocationItemData.Item.AvailableQty;
                var availableQuantity = _this.LocationItemData.Item.AvailableQty;
                if (hasAvailableQuantity && availableQuantity < 0) {
                    availableQuantity = 0;
                }
                _this.addCharacteristic("AvailableQty", availableQuantity);
                _this.addCharacteristic("EndDate", _this.LocationItemData.Item.EndDate);
                _this.addPriceCharacteristic();
                if (_this.LocationItemData.Item.SpaceName) {
                    _this.addCharacteristic("SpaceName", _this.LocationItemData.Item.SpaceName);
                }
                if (_this.LocationItemData.Item.FixtureGroupName && _this.LocationItemData.Item.HolderName) {
                    _this.addCharacteristic("HolderName", _this.LocationItemData.Item.FixtureGroupName + " - " + _this.LocationItemData.Item.HolderName);
                }
                else if (_this.LocationItemData.Item.ElementName) {
                    _this.addCharacteristic("HolderName", _this.LocationItemData.Item.ElementName);
                }
                if (_this.LocationItemData.Item.PromotionName) {
                    _this.addCharacteristic("PromotionName", _this.LocationItemData.Item.PromotionName);
                }
                if (_this.LocationItemData.Item.ImagineItemCode) {
                    _this.addCharacteristic("ImagineItemCode", _this.LocationItemData.Item.ImagineItemCode);
                }
                if (_this.LocationItemData.Item.CategoryList) {
                    _this.addCharacteristic("Category", _this.LocationItemData.Item.CategoryList);
                }
            });
        });
    };
    ItemDisplayCardComponent.prototype.toPriceString = function (val) {
        return "$" + Number(val).toFixed(2);
    };
    ItemDisplayCardComponent.prototype.addPriceCharacteristic = function () {
        var _this = this;
        if (this.LocationItemData.DigitalDownload && this.LocationItemData.Item.OrderIds && this.LocationItemData.Item.OrderIds.length > 0) {
            var formattedPrice = this.helperFunctionsService.formatAsPrice(this.LocationItemData.Item.UnitPrice);
            var originalPriceTag = "<span class=\"previously-ordered\">$" + formattedPrice + "</span>";
            var newPriceTag = "<span class=\"ml-2\">$0.00</span>";
            var pricingNoteTag = "<span class=\"previously-ordered-note d-block\">" + this.TranslatedTexts["ITEM_PREVIOUSLY_ORDERED"] + "</span>";
            var finalString = "" + originalPriceTag + newPriceTag + pricingNoteTag;
            this.addCharacteristic("UnitPriceOrdered", finalString);
        }
        else {
            if (this.hasPriceTiers) {
                var tableOpenTag = "<table class=\"table table-sm table-striped\"><tbody>";
                var tableEndTag = "</tbody></table>";
                var tableBody_1 = "";
                this.getPriceTiers().forEach(function (tier) {
                    if (tier.QuantityTo) {
                        var rowString = "<tr><td>" + tier.QuantityFrom + " - " + tier.QuantityTo + "</td><td class=\"text-right\">" + _this.toPriceString(tier.Price) + "</td></tr>";
                        tableBody_1 = tableBody_1 + rowString;
                    }
                    else {
                        var rowString = "<tr><td>" + tier.QuantityFrom + "+</td><td class=\"text-right\">" + _this.toPriceString(tier.Price) + "</td></tr>";
                        tableBody_1 = tableBody_1 + rowString;
                    }
                });
                this.addCharacteristic("UnitPrice", "" + tableOpenTag + tableBody_1 + tableEndTag);
            }
            else {
                this.addCharacteristic("UnitPrice", this.LocationItemData.Item.UnitPrice);
            }
        }
    };
    ItemDisplayCardComponent.prototype.customize = function () {
        var _this = this;
        //save data to local storage for creating a cart item after punchout
        var cartItem = {
            Id: 0,
            LocationId: this.LocationItemData.LocationId,
            CartId: this.cartService.myCurrentCart.Id,
            ItemId: this.LocationItemData.Item.Id,
            DigitalDownload: this.LocationItemData.Item.DigitalDownload,
            Qty: this.LocationItemData.Qty,
            OrderReason: this.LocationItemData.OrderReason,
            Variable: true,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            XMPieProductId: this.ItemData.XMPieProductId,
            XMPieStoreId: this.ItemData.XMPieStoreId,
            DirectMail: this.ItemData.DirectMail
        };
        this.punchOutService.getCustomizeUrl(cartItem).then(function (url) {
            _this.punchOut.emit();
            window.location.href = url;
        });
    };
    ItemDisplayCardComponent.prototype.downloadClicked = function () {
        var _this = this;
        var itemId = this.ItemData.Id;
        var productCode = this.ItemData.CustomerItemCode;
        var locationId = this.LocationId;
        this.orderService.getStaticItemDownloadUrl(productCode, itemId, locationId, null).then(function (response) {
            _this.LocationItemData.OrderReason = "";
            _this.fileDownloadService.Download(response.Url, response.FileName);
        });
    };
    return ItemDisplayCardComponent;
}());
export { ItemDisplayCardComponent };
