import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { CartItemModel  } from "../model/CartDataModel";
import { environment } from "../../../environments/environment";
import { ItemDataModel } from "../model/ItemDataModel";

@Injectable()
export class ItemService extends IpsBaseWebService {

    public searchingByFixture: boolean;

    constructor(http: HttpClient, public activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Item", "Shop", "v1");
    }

    public searchByRelation(searchInfo: SearchInfo, route: string): Promise<any> {
        if (this.searchingByFixture) {
            const fixtureRoute = "Item/SearchWithFixture";
            return super.searchByRelation(searchInfo, fixtureRoute);
        } else {
            return super.searchByRelation(searchInfo, route);
        }
    }

    public getCategoryHierarchy(level = 1): Promise<any> {
        const route = `${this.urlApiBasePlusController}GetCategoryHierarchy?level=${level}`;
        return this.getByRoute(route);
    }

    public getAllCategories(searchInfo: SearchInfo, route: string): Promise<any> {
        return this.searchByRelation(searchInfo, route);
    }

    public getAllRelatedFilters(searchInfo: SearchInfo): Promise<any> {
        const relatedFilterRoute = "Item/GetAllRelatedFilterData";
        return this.searchByRelation(searchInfo, relatedFilterRoute);
    }


    public itemCountByLocation(locationId: number, byFixture = false): Promise<number> {
        const routeSearchPath = byFixture ? "SearchWithFixture" : "Search";
        const route = `${this.urlApiBasePlusController}${routeSearchPath}?locationId=${locationId}`;
        return this.headByRoute(route);
    }

    // Determines if the item exists in the current user's cart for the given location.
    public ItemDataToCartLocationItem(
        item: ItemDataModel, locationId: number, Quantity: number, OrderReason: string
    ): CartItemModel {
        return {
            Id: 0,
            LocationId: locationId || 0,
            Qty: Quantity,
            OrderReason: OrderReason,
            DigitalDownload: item.DigitalDownload,
            Item: item,
            ItemId: item.Id
        };
    }
}
