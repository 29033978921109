import { Ng2StateDeclaration, loadNgModule } from "@uirouter/angular";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { Transition } from "@uirouter/core";
import { LogoutComponent } from "./logout/logout.component";
import { SsoTokenReceiverComponent } from "./sso-token-receiver/sso-token-receiver.component";
import { MainComponent } from "./main/main.component";
import { PublicComponent } from "./public/public.component";
import { ErrorComponent } from "./error/error.component";
import { AboutComponent } from "./about/about.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { BrowserSupportComponent } from "./browser-support/browser-support.component";
import { HelpDocComponent } from "./shared/help-doc/help-doc/help-doc.component";

//import { CAMPAIGN_FUTURE_STATES } from "./imagine-ui-ng-campaign/imagine-ui-ng-campaign.futureStates";
//import { SURVEY_FUTURE_STATES } from "./imagine-ui-ng-survey/imagine-ui-ng-survey.futureStates";
//import { CUSTOMDATA_FUTURE_STATES } from "./imagine-ui-ng-custom-data/imagine-ui-ng-custom-data.futureStates";

/**
 * This is the parent state for the entire application.
 *
 * This state"s primary purposes are:
 * 1) Shows the outermost chrome (including the navigation and logout for authenticated users)
 * 2) Provide a viewport (ui-view) for a substate to plug into
 */

export let mainState: Ng2StateDeclaration = {
    name: "main", redirectTo: "main.home",
    component: MainComponent
};

export let publicState: Ng2StateDeclaration = {
    name: "public",
    redirectTo: "public.login",
    component: PublicComponent
};

/**
 * This is a home screen for authenticated users.
 *
 * It shows giant buttons which activate their respective submodules: Messages, Contacts, Preferences
 */
export let mainHomeState: Ng2StateDeclaration = {
    name: "main.home",
    url: "/home",
    component: HomeComponent,
    data: {
        permissions: { only: "PUBLIC" },
        pageTitle: ["HOME"]
    }
};


/**
 * This is the login state.  It is activated when the user navigates to /login, or if a unauthenticated
 * user attempts to access a protected state (or substate) which requires authentication. (see routerhooks/requiresAuth.js)
 *
 * It shows a fake login widget and prompts the user to authenticate.  Once the user authenticates, it then
 * reactivates the state that the user originally came from.
 */
export let publicLoginState: Ng2StateDeclaration = {
    name: "public.login",
    url: "/login",
    component: LoginComponent,
    resolve: [{
        token: "returnTo",
        deps: [Transition], resolveFn: returnTo
    }],
    data: { pageTitle: ["LOGIN"] }
};

export let publicOldLoginState: Ng2StateDeclaration = {
    name: "public.oldLogin",
    url: "/login/login.html",
    redirectTo: "public.login",
    data: {
        permissions: { only: "PUBLIC" },
        pageTitle: ["LOGIN"]
    }
};


/**
 * A resolve function for "login" state which figures out what state to return to, after a successful login.
 *
 * If the user was initially redirected to login state (due to the requiresAuth redirect), then return the toState/params
 * they were redirected from.  Otherwise, if they transitioned directly, return the fromState/params.  Otherwise
 * return the main "home" state.
 */
export function returnTo($transition$: Transition): any {
    if ($transition$.redirectedFrom() != null) {
        // The user was redirected to the login state (e.g., via the requiresAuth hook when trying to activate contacts)
        // Return to the original attempted target state (e.g., contacts)
        return $transition$.redirectedFrom().targetState();
    }

    const $state = $transition$.router.stateService;

    // The user was not redirected to the login state; they directly activated the login state somehow.
    // Return them to the state they came from.
    let fromStateName = $transition$.from().name;
    if (fromStateName !== "" && fromStateName !== "public.logout" && fromStateName !== "error") {
        return $state.target($transition$.from(), $transition$.params("from"));
    }

    // If the fromState"s name is empty, then this was the initial transition. Just return them to the home state
    return $state.target("main.home");
}

export let publicLogoutState: Ng2StateDeclaration = {
    name: "public.logout",
    url: "/logout",
    component: LogoutComponent,
    data: {
        pageTitle: ["LOGOUT"]
    }
};

export let publicSsoTokenReceiverState: Ng2StateDeclaration = {
    name: "public.ssoTokenReceiver",
    url: "/login/ssoTokenReceiver",
    component: SsoTokenReceiverComponent,
    data: {
        pageTitle: ["SSOTOKENRECEIVER"]
    }
};

export let errorState: Ng2StateDeclaration = {
    name: "error",
    url: "/error",
    component: ErrorComponent,
    data: {
        permissions: { only: "PUBLIC" },
        pageTitle: ["ERROR"]
    }
};

export let mainAboutState: Ng2StateDeclaration = {
    name: "main.about",
    url: "/about",
    component: AboutComponent,
    data: {
        permissions: { only: "PUBLIC" },
        pageTitle: ["ABOUT"]
    }
};

export let mainBrowserSupport: Ng2StateDeclaration = {
    name: "main.browser_support",
    url: "/browsersupport",
    component: BrowserSupportComponent,
    data: {
        permissions: { only: "PUBLIC" },
        pageTitle: ["BROWSER_SUPPORT"]
    }
};

export let mainUnauthorizedState: Ng2StateDeclaration = {
    name: "main.unauthorized",
    url: "/unauthorized",
    component: UnauthorizedComponent,
    data: {
        permissions: { only: "PUBLIC" },
        pageTitle: ["UNAUTHORIZED_TITLE"]
    }
};

export let mainHelpDoc: Ng2StateDeclaration = {
    name: "main.helpdoc",
    url: "/helpdocs",
    component: HelpDocComponent,
    data: {
        permissions: { only: "PUBLIC" },
        pageTitle: ["HELP_DOCUMENTS"]
    }
};

//states = states.concat(CAMPAIGN_FUTURE_STATES).concat(SURVEY_FUTURE_STATES).concat(CUSTOMDATA_FUTURE_STATES);

export const APP_STATES = [mainState, publicState, mainHomeState, publicLoginState, publicOldLoginState, publicLogoutState,
    publicSsoTokenReceiverState, errorState, mainAboutState, mainBrowserSupport, mainUnauthorizedState, mainHelpDoc];
