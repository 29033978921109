import { Directive, ElementRef, Renderer2, OnInit, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
    selector: "[appInputPrefix]"
})
export class InputPrefixDirective implements OnInit, OnChanges {

    @Input("appInputPrefix") prefix: string;
    private focused = false; // Set to true when in focus

    constructor(private element: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.prefix) {
            this.prefix = changes.prefix.currentValue;
            this.prependText(changes.prefix.previousValue);
        }
    }

    @HostListener("input", ["$event"]) onInput(event) {
        console.log("input event");
    }

    @HostListener("change") onValueChange() {
        this.prependText();
    }

    @HostListener("focus") onFocus() {
        this.focused = true;
        let val = this.element.nativeElement.value as string;
        if (val && val.startsWith(this.prefix)) {
            this.element.nativeElement.value = val.slice(this.prefix.length);
        }
    }

    @HostListener("blur") onBlur() {
        this.focused = false;
        this.prependText();
    }

    private prependText(oldValue?: string) {
        let val = this.element.nativeElement.value as string;
        if (oldValue && val.startsWith(oldValue)) {
            val = val.slice(oldValue.length);
        }
        if (!val.startsWith(this.prefix)) {
            this.element.nativeElement.value = this.prefix + val;
        }
    }
}
