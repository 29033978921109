import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchHelper } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";

import { ElementDetailModel, FixtureTypeSearchResultModel, ElementModel, FixtureGroupService, ElementService } from "../../../imagine-ui-ng-store-profile/index";
import { PlacementEditModel, PromotionMessagePlacementElementEditModel } from "../../index";

interface TrackingHolderModel {
    Id: number;
    Name: string;
    IsSelected: boolean;
}

interface TrackingElementDetailModel extends ElementDetailModel {
    IsSelected: boolean;
}

interface TrackingFixtureTypeSearchResultModel extends FixtureTypeSearchResultModel {
    Holders: TrackingHolderModel[];
}

interface TrackingElementSearchResultModel extends ElementModel {
    ElementDetails: TrackingElementDetailModel[];
}

interface TrackingPlacementModalSearchResultModel {
    FixtureGroups: TrackingFixtureTypeSearchResultModel[];
    Elements: TrackingElementSearchResultModel[];
}

interface PlacementSearchModalResolve {
    Placements: PlacementEditModel[];
    PlacementElements: PromotionMessagePlacementElementEditModel[];
    SingleSelect: boolean;
}

@Component({
    selector: "app-placement-search-modal",
    templateUrl: "./placement-search-modal.component.html",
    styleUrls: ["./placement-search-modal.component.scss"]
})
export class PlacementSearchModalComponent implements OnInit {
    public searchLabel: string;
    public searchLabelPlural: string;
    public listSearch: ListSearchHelper;
    public promise: Promise<any>;
    public query = "";
    public placementType = "";

    public elements: ListSearchHelper;
    public elementPromise: Promise<any>;
    public elementQuery = "";
    public focusElement: boolean;

    public resolve: PlacementSearchModalResolve;
    public previousSelectedFixtureGroups: TrackingFixtureTypeSearchResultModel[] = [];
    public previousSelectedElements: TrackingElementSearchResultModel[] = [];
    public selectedCount = 0;
    public title: string;

    public busy = true;
    // List of items selected by the user, used to save state between searches
    private currentSelectedItems: TrackingPlacementModalSearchResultModel = { FixtureGroups: [], Elements: [] };
    public singleSelect = false;

    private TranslateStrings: { [key: string]: string } = {
        "FIXTURE_GROUP": "",
        "FIXTURE_GROUPS": "",
        "ELEMENT": "",
        "ELEMENTS": "",
        "FIXTURE_GROUP_SELECTED": "",
        "FIXTURE_GROUPS_SELECTED": "",
        "ELEMENT_SELECTED": "",
        "ELEMENTS_SELECTED": "",
        "SELECT_ELEMENT": "",
        "SELECT_FIXTURE_GROUP_HOLDER": ""
    };

    public pluralMapping: { [k: string]: string } = {
        "=1": "FIXTURE_GROUP_SELECTED",
        "other": "FIXTURE_GROUPS_SELECTED"
    };


    constructor(private modalInstance: NgbActiveModal, private fixtureGroupService: FixtureGroupService,
        private listSearchHelper: ListSearchHelper, private ipsMessage: IpsMessageService, private elementService: ElementService,
        private translateService: TranslateService) { }

    ngOnInit() {
        this.singleSelect = this.resolve.SingleSelect;

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        //load currentSelectedItems from previous selected
        if (this.resolve.PlacementElements) {
            this.populateCurrentSelectedElements(this.resolve.PlacementElements);
            this.selectedCount = this.getSelectedElementDetailCount();
            this.searchLabel = this.TranslateStrings["ELEMENT"];
            this.searchLabel = this.TranslateStrings["ELEMENTS"];
            this.placementType = "element";
            this.title = this.TranslateStrings["SELECT_ELEMENT"];

            this.pluralMapping = {
                "=1": this.translateService.instant("ELEMENT_SELECTED"),
                "other": this.translateService.instant("ELEMENTS_SELECTED")
            };

            this.listSearch = this.listSearchHelper.getListSearchHelper(this.elementService);
            this.elementService.head().then((total: number) => {
                this.listSearch.TotalRecords = total;
            });
        } else if (this.resolve.Placements) {
            this.populateCurrentSelectedHolders(this.resolve.Placements);
            this.selectedCount = this.getSelectedHolderCount();
            this.searchLabel = this.TranslateStrings["FIXTURE_GROUP"];
            this.searchLabel = this.TranslateStrings["FIXTURE_GROUPS"];
            this.placementType = "fixture";
            this.title = this.TranslateStrings["SELECT_FIXTURE_GROUP_HOLDER"];

            this.pluralMapping = {
                "=1": this.translateService.instant("FIXTURE_GROUP_SELECTED"),
                "other": this.translateService.instant("FIXTURE_GROUPS_SELECTED")
            };

            this.listSearch = this.listSearchHelper.getListSearchHelper(this.fixtureGroupService);

            this.fixtureGroupService.head().then((total: number) => {
                this.listSearch.TotalRecords = total;
            });

        }
        this.busy = true;
        this.getListData("").then(() => {
            this.busy = false;
        });

    }

    private translateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    }

    private populateCurrentSelectedElements(previousSelected: PromotionMessagePlacementElementEditModel[]) {
        previousSelected.forEach((item) => {
            // With multiple main messages, we will get many element placements for the same detail id. only need
            // it once.
            let hasDetail = this.currentSelectedItems.Elements.some(curItem => curItem.ElementDetails[0].Id === item.ElementDetailId);

            if (!hasDetail) {
                let elementDetail = <TrackingElementDetailModel>{
                    Id: item.ElementDetailId,
                    Width: item.ElementDetailWidth,
                    Height: item.ElementDetailHeight,
                    IsSelected: true
                };
                let element = <TrackingElementSearchResultModel>{
                    Name: item.ElementDetailName,
                    ElementDetails: [elementDetail]
                };
                this.currentSelectedItems.Elements.push(element);
            }
        });

        Object.assign(this.previousSelectedElements, this.currentSelectedItems.Elements);
    }

    private populateCurrentSelectedHolders(previousSelected: PlacementEditModel[]) {
        previousSelected.forEach((placement) => {
            let found = this.currentSelectedItems.FixtureGroups.some((item) => {
                return item.Holders.some((itemHodler) => {
                    return itemHodler.Id === placement.HolderId;
                });
            });
            if (!found) {
                let fixtureGroup = <TrackingFixtureTypeSearchResultModel>{
                    Name: placement.FixtureTypeName,
                    Holders: [<TrackingHolderModel>{
                        Id: placement.HolderId,
                        IsSelected: true,
                        Name: placement.HolderName
                    }]
                };
                this.currentSelectedItems.FixtureGroups.push(fixtureGroup);
                Object.assign(this.previousSelectedFixtureGroups, this.currentSelectedItems.FixtureGroups);
            }
        });
    }

    public returnResults() {
        this.modalInstance.close(this.currentSelectedItems);
    }

    public getListData(search: string) {
        this.query = search;

        return this.promise = this.listSearch.searchHelper({ searchText: this.query }).then(() => {
            if (this.placementType === "fixture") {
                this.setDisplayForCurrentlySelectedHolders();
            } else if (this.placementType === "element") {
                this.setDisplayForCurrentlySelectedElements();
            }
            return Promise.resolve();
        });
    }

    public getSelectedHolderCount(): number {
        if (this.currentSelectedItems.FixtureGroups.length === 0) {
            return 0;
        }

        let total = 0;
        this.currentSelectedItems.FixtureGroups.forEach((item) => {
            total += item.Holders.length;
        });

        return total;
    }

    public getSelectedElementDetailCount(): number {
        if (this.currentSelectedItems.Elements.length === 0) {
            return 0;
        }

        let total = 0;
        this.currentSelectedItems.Elements.forEach((item) => {
            total += item.ElementDetails.length;
        });

        return total;
    }

    /**
     * After a search, existing selections in the GUI get wiped out.
     * This method will set isSelected on the fixtureGroups.resultList
     * based on settings prior to the new search.
     */
    private setDisplayForCurrentlySelectedHolders() {
        if (this.currentSelectedItems.FixtureGroups.length > 0) {
            // foreach item returned by the search, look in current selected list to find fixtureType
            this.listSearch.resultList.forEach((fixtureTypeItem: TrackingFixtureTypeSearchResultModel) => {
                fixtureTypeItem.Holders.forEach((holder: TrackingHolderModel) => {
                    let found = this.currentSelectedItems.FixtureGroups.some((item) => {
                        return item.Holders.some((itemHodler) => {
                            return itemHodler.Id === holder.Id;
                        });
                    });
                    if (found) {
                        holder.IsSelected = true;
                    }
                });
            });
        }
    }

    /**
 * After a search, existing selections in the GUI get wiped out.
 * This method will set isSelected on the fixtureGroups.resultList
 * based on settings prior to the new search.
 */
    private setDisplayForCurrentlySelectedElements() {
        if (this.currentSelectedItems.Elements.length > 0) {
            // foreach item returned by the search, look in current selected list to find fixtureType
            this.listSearch.resultList.forEach((elementItem: TrackingElementSearchResultModel) => {

                elementItem.ElementDetails.forEach((detail: TrackingElementDetailModel) => {
                    let found = this.currentSelectedItems.Elements.some((item) => {
                        return item.ElementDetails.some((itemDetail) => {
                            return itemDetail.Id === detail.Id;
                        });

                    });
                    if (found) {
                        detail.IsSelected = true;
                    }
                });
            });
        }
    }

    private selectedItemChanged(): boolean {
        let changed = false;
        this.currentSelectedItems.FixtureGroups.forEach(fg => {
            for (let i = 0; i < fg.Holders.length; i++) {
                let holder = fg.Holders[i];
                let found = this.previousSelectedFixtureGroups.some((item) => {
                    return item.Holders.some((itemHodler) => {
                        return itemHodler.Id === holder.Id;
                    });
                });
                if (!found) {
                    changed = true;
                    break;
                }

            }
        });

        if (!changed) {
            this.previousSelectedFixtureGroups.forEach(fg => {
                for (let i = 0; i < fg.Holders.length; i++) {
                    let holder = fg.Holders[i];
                    let found = this.currentSelectedItems.FixtureGroups.some((item) => {
                        return item.Holders.some((itemHodler) => {
                            return itemHodler.Id === holder.Id;
                        });
                    });
                    if (!found) {
                        changed = true;
                        break;
                    }

                }
            });
        }


        if (!changed) {
            this.currentSelectedItems.Elements.forEach(element => {
                for (let i = 0; i < element.ElementDetails.length; i++) {
                    let detail = element.ElementDetails[i];
                    let found = this.previousSelectedElements.some(item => {
                        return item.ElementDetails.some(itemDetail => {
                            return itemDetail.Id === detail.Id;
                        });
                    });
                    if (!found) {
                        changed = true;
                        break;
                    }
                }
            });
        }



        if (!changed) {
            this.previousSelectedElements.forEach(element => {
                for (let i = 0; i < element.ElementDetails.length; i++) {
                    let detail = element.ElementDetails[i];
                    let found = this.currentSelectedItems.Elements.some(item => {
                        return item.ElementDetails.some(itemDetail => {
                            return itemDetail.Id === detail.Id;
                        });
                    });
                    if (!found) {
                        changed = true;
                        break;
                    }
                }
            });
        }
        return changed;
    }

    public close(closeMessage?: any) {
        if (this.selectedItemChanged()) {
            this.ipsMessage.confirm({ body: "PLACEMENT_SEARCH_ACTIVE_SELECTIONS", ok: "YES", cancel: "NO" }, { windowClass: "message-window-z-index", backdropClass: "backdrop-z-index"})
            .then(() => {
                // Go ahead and close the window
                this.modalInstance.dismiss(closeMessage);
            })
            .catch(() => {
                // rejection
            });
        } else {
            this.modalInstance.dismiss(closeMessage);
        }
    }

    public holderChecked(fixtureGroup: TrackingFixtureTypeSearchResultModel, holder: TrackingHolderModel, returnOnSelect = false): void {
        holder.IsSelected = !holder.IsSelected;
        this.selectedHolderChanged(fixtureGroup, holder, returnOnSelect);
    }

    public selectedHolderChanged(fixtureGroup: TrackingFixtureTypeSearchResultModel, holder: TrackingHolderModel, returnOnSelect = false): void {
        if (holder.IsSelected) {
            // Find the holder
            let found = this.currentSelectedItems.FixtureGroups.some((item) => {
                return item.Holders.some((itemHodler) => {
                    return itemHodler.Id === holder.Id;
                });
            });
            if (!found) {
                this.currentSelectedItems.FixtureGroups.push(<TrackingFixtureTypeSearchResultModel>{
                    Id: fixtureGroup.Id,
                    Name: fixtureGroup.Name,
                    Holders: [holder]
                });
            }
        } else { // remove the item
            this.currentSelectedItems.FixtureGroups =
                this.currentSelectedItems.FixtureGroups.filter((fg) => {
                    let index = -1;
                    let found = fg.Holders.some((item, i) => {
                        if (item.Id === holder.Id) {
                            index = i;
                            return true;
                        }
                    });
                    if (found) {
                        fg.Holders.splice(index, 1);
                    }
                    return !found;
                });
        }
        this.selectedCount = this.getSelectedHolderCount();

        if (returnOnSelect) {
            this.returnResults();
        }
    }

    public elementChecked(element: TrackingElementSearchResultModel, elementDetail: TrackingElementDetailModel): void {
        elementDetail.IsSelected = !elementDetail.IsSelected;
        this.selectedElementChanged(element, elementDetail);
    }

    public selectedElementChanged(element: TrackingElementSearchResultModel, elementDetail: TrackingElementDetailModel): void {
        if (elementDetail.IsSelected) {
            // Find the element detail

            let found = this.currentSelectedItems.Elements.some((item) => {
                return item.ElementDetails.some((itemDetail) => {
                    return itemDetail.Id === elementDetail.Id;
                });

            });
            if (!found) {
                this.currentSelectedItems.Elements.push(<TrackingElementSearchResultModel>{
                    Id: element.Id,
                    Name: element.Name,
                    ElementDetails: [elementDetail]
                });
            }
        } else { // remove the item
            this.currentSelectedItems.Elements =
                this.currentSelectedItems.Elements.filter((ele) => {
                    let index = -1;
                    let found = ele.ElementDetails.some((item, i) => {
                        if (item.Id === elementDetail.Id) {
                            index = i;
                            return true;
                        }
                    });
                    if (found) {
                        ele.ElementDetails.splice(index, 1);
                    }
                    return !found;
                });
        }
        this.selectedCount = this.getSelectedElementDetailCount();
    }
}
