<div class="form-group" [formGroup]="listGroup">
    <label [ngClass]="{'required': isFieldRequired}">{{name}}</label>
    <div formArrayName="Items">
        <div *ngFor="let item of itemArray.controls; let itemIndex=index" [formGroupName]="itemIndex" class="mb-3">
            <app-ips-typeahead [name]="name"
                               [itemGroup]="item"
                               [itemGroupIndex]="itemIndex"
                               [itemSourceList]="lookupValues"
                               [itemSourceListSelected]="selectedValues"
                               [multiSelection]="selectionRule === 'Multiple'"
                               (itemChanged)="itemChanged($event)"
                               (itemDeleted)="itemDeleted($event)"></app-ips-typeahead>
            <ips-show-errors [control]="item.get('Name')" [errorMessages]="getErrorMessages()"></ips-show-errors>
        </div>
    </div>
</div>

<div class="form-row" *ngIf="selectionRule === 'Multiple'">
    <div class="form-group col-12 mb-0">
        <button class="btn btn-lg btn-block text-left ips-add-bar" (click)="addLink()" [disabled]="lookupValues.length === itemArray.length" [ipsFocus]="focusableItemInput" >
            <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
            <span class="add-holder-label">{{listAddLabel}}</span>
        </button>
    </div>
</div>
