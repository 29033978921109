import { Component, OnInit, Input } from "@angular/core";
import { NgForm, FormGroup, FormControl, FormArray, Validators, AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString, StringBuilder } from "typescript-string-operations";

import { ElementService, ElementModel, ElementDetailModel } from "../../index";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";


interface ElementDetail extends ElementDetailModel {
    InputName: string;
    BusinessIdentity: string;
}

interface Element extends ElementModel {
    ElementDetails: ElementDetail[];
    BusinessIdentity: string;
}

@Component({
    selector: "app-element-edit",
    templateUrl: "./element-edit.component.html",
    styleUrls: ["./element-edit.component.scss"]
})
export class ElementEditComponent implements OnInit {

    public loaded: boolean;
    public promise: Promise<any>;
    private indexer = 0;
    private numericValueRequiredMessage: string;
    private tooManyDecimalPlacesMessage: string;
    private decimalNumberOnly: string;
    private duplicatedSize: string;
    private promptDeleteBody: string;
    public breadCrumbLabel: string;
    public focusableWidthInput: string;
    public focusInput: string;
    public requiredElementName: string;
    public requiredWidth: string;
    public requiredHeight: string;
    public requiredMessage: string;
    public textMaxLength = "MAX_LENGTH_ERROR";

    public elementForm: FormGroup;
    private Id: FormControl;
    private BusinessIdentity: FormControl;
    private Name: FormControl;
    private Notes: FormControl;
    private isObsolete: FormControl;
    public originalElement: Element;
    public hasShownWarning = false;
    public enableSignPlan = false;

    private errorMessages = {
        "required": () => this.requiredMessage,
        "maxlength": (params) => IpsString.Format(this.textMaxLength, params.requiredLength),
        "numericValueRequiredMessage": () => this.numericValueRequiredMessage,
        "tooManyDecimalPlacesMessage": () => this.tooManyDecimalPlacesMessage,
        "decimalNumberOnly": () => this.decimalNumberOnly,
        "duplicatedSize": () => this.duplicatedSize
    };

    constructor(private elementService: ElementService, private ipsMessage: IpsMessageService, private $state: StateService,
        private transition: Transition, private translateService: TranslateService, private pageTitleService: PageTitleService,
        private activeProfileService: ActiveProfileService, private settingsService: GeneralSettingsService) { }

    ngOnInit() {

        let paramId = this.transition.params().id === "0" ? "" : this.transition.params().id;

        this.focusInput = paramId.toString() === "" ? "nameInput" : "addSizeInput";
        let pageTitle = paramId.toString() === "" ? "CREATE_ELEMENT" : "EDIT_ELEMENT";

        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        this.createForm();
        this.loaded = false;

        //If we got an ID to load, load it.
        if (paramId > 0) {
            //Initial call to populate screen on load
            const promises: Promise<any>[] = [];
            promises.push(this.getSignPlanSetting());
            promises.push(this.getElement(paramId));

            Promise.all(promises).then(() => {
                this.loaded = true;
            });
        } else if (paramId === "") {
            this.initElementDetailsList();
            this.getFocusableInput();
            this.hasShownWarning = true;
            this.getSignPlanSetting().then(() => {
                this.loaded = true;
            });
        }

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    private getSignPlanSetting(): Promise<any> {
        const returnPromise = new Promise<any>((resolve, reject) => {
            this.settingsService.canEditSignPlanLayout().then((response: boolean) => {
                this.enableSignPlan = response;
                resolve();
            }).catch(() => {
                this.enableSignPlan = false;
                resolve();
            });
        });

        return returnPromise;
    }

    get Details(): FormArray {
        return this.elementForm.get("Details") as FormArray;
    }

    private translateText() {
        this.translateService.get(["PLEASE_ENTER_NUMBER", "TOO_MANY_DECIMAL_PLACES", "ENTER_DECIMAL_NUMBER", "DUPLICATED_SIZE", "PROMPT_DELETE_BODY", "MAX_LENGTH_ERROR",
            "PLEASE_ENTER_AN_ELEMENT_NAME", "PLEASE_ENTER_WIDTH", "PLEASE_ENTER_HEIGHT", "FIELD_REQUIRED"]).subscribe((res: [string]) => {
            this.numericValueRequiredMessage = res["PLEASE_ENTER_NUMBER"];
            this.tooManyDecimalPlacesMessage = res["TOO_MANY_DECIMAL_PLACES"];
            this.decimalNumberOnly = res["ENTER_DECIMAL_NUMBER"];
            this.duplicatedSize = res["DUPLICATED_SIZE"];
            this.promptDeleteBody = res["PROMPT_DELETE_BODY"];
            this.textMaxLength = res["MAX_LENGTH_ERROR"];
            this.requiredElementName = res["PLEASE_ENTER_AN_ELEMENT_NAME"];
            this.requiredWidth = res["PLEASE_ENTER_WIDTH"];
            this.requiredHeight = res["PLEASE_ENTER_HEIGHT"];
            this.requiredMessage = res["FIELD_REQUIRED"];
        });
    }

    public onSignPlanTransformation(e: any, elementDetail: FormGroup) {
        const elementDetailValue = elementDetail.value;
        elementDetailValue.CropTop = e.top;
        elementDetailValue.CropLeft = e.left;
        elementDetailValue.CropHeight = e.height;
        elementDetailValue.CropWidth = e.width;
        elementDetailValue.CropRotation = e.rotation;

        elementDetail.patchValue(elementDetailValue);
        elementDetail.markAsDirty();
    }

    public getNameErrorMessages() {
        let msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = () => this.requiredElementName;
        return msgs;
    }

    public getWidthErrorMessages() {
        let msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = () => this.requiredWidth;
        return msgs;
    }

    public getHeightErrorMessages() {
        let msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = () => this.requiredHeight;
        return msgs;
    }

    private createForm() {
        this.Id = new FormControl("");
        this.BusinessIdentity = new FormControl("");
        this.Name = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.Notes = new FormControl("", Validators.maxLength(500));
        this.isObsolete = new FormControl(false);

        this.elementForm = new FormGroup({
            Id: this.Id,
            BusinessIdentity: this.BusinessIdentity,
            Name: this.Name,
            Notes: this.Notes,
            IsObsolete: this.isObsolete,
            Details: new FormArray([])
        });
    }

    private getElement(id: number): Promise<any> {
        this.promise = this.elementService.get<Element>(id).then((response: Element) => {

            //Store off the original values
            this.originalElement = Object.assign({}, response);

            this.elementForm.patchValue({
                Id: response.Id,
                BusinessIdentity: response.BusinessIdentity,
                Name: response.Name,
                Notes: response.Notes,
                IsObsolete: response.IsObsolete
            });

            const detailFGs = response.ElementDetails.map(detail => this.getNewDetail(detail));
            const details = new FormArray(detailFGs);
            this.elementForm.setControl("Details", details);

            this.initElementDetailsList();
            this.getFocusableInput();
        });

        return this.promise;
    }

    private getFocusableInput() {
        this.focusableWidthInput = "detailHtmlName" + (this.indexer - 1) + "Width";
    }

    private initElementDetailsList() {

        let details = this.elementForm.get("Details") as FormArray;

        if (details.controls.length === 0) {
            this.addDetail();
        }
        this.sortElements();
    }

    private deleteDetail(inputName: number): boolean {

        let details = this.elementForm.get("Details") as FormArray;
        let count = details.controls.length;
        let newValues = [];

        details.controls.splice(inputName, 1);
        details.controls.forEach(c => {
            newValues.concat(c.value);
        });
        details.patchValue(newValues);


        return count !== details.controls.length;
    }

    private saveElementCallback = (): Promise<void> => {

        let element = this.elementForm.value;
        element.ElementDetails = element.Details;
        //add business identity to element/details
        for (let i = 0; i < element.ElementDetails.length; i++) {
            element.ElementDetails[i].BusinessIdentity = this.activeProfileService.businessIdentity;
        }
        element.BusinessIdentity = this.activeProfileService.businessIdentity;

        if (element.Id) {
            return this.elementService.put<Element>(element).then((response: Element) => {
                element = response;
                this.elementForm.markAsPristine();
            });

        } else {
            return this.elementService.post<Element>(element).then((response: Element) => {
                element.Id = response.Id;
                this.elementForm.markAsPristine();
            });
        }
    }

    private deleteElement(): Promise<void> {
        let element: Element = this.elementForm.value;
        return this.elementService.delete(element.Id).then(function (response: Element) {
        });
    }

    private getNewDetail(detail: ElementDetail): FormGroup {
        return new FormGroup({
            Id: new FormControl(detail.Id),
            ElementId: new FormControl(detail.ElementId),
            BusinessIdentity: new FormControl(detail.BusinessIdentity),
            Width: new FormControl(detail.Width, [Validators.required, this.customValidator]),
            Height: new FormControl(detail.Height, [Validators.required, this.customValidator]),
            InputName: new FormControl("detailHtmlName" + this.indexer++),
            CropTop: new FormControl(detail.CropTop),
            CropLeft: new FormControl(detail.CropLeft),
            CropHeight: new FormControl(detail.CropHeight),
            CropWidth: new FormControl(detail.CropWidth),
            CropRotation: new FormControl(detail.CropRotation),
            CategoryCode: new FormControl(detail.CategoryCode)
        });
    }

    public addDetail(): void {

        let details = this.elementForm.get("Details") as FormArray;

        let ElementId = this.elementForm.get("Id") ? this.elementForm.get("Id").value : "";

        details.push(this.getNewDetail({
            Id: 0,
            ElementId: ElementId,
            BusinessIdentity: "",
            Width: null,
            Height: null,
            InputName: "detailHtmlName" + this.indexer++,
            CropTop: 0,
            CropLeft: 0,
            CropWidth: null,
            CropHeight: null,
            CropRotation: 0,
            CategoryCode: null
        }));

        this.getFocusableInput();
    }

    public removeDetail(frmGrpIndex: number): void {

        let details = this.elementForm.get("Details") as FormArray;

        if (details.controls.length === 1) {
            let grp = details.get(frmGrpIndex.toString()) as FormGroup;
            grp.get("Width").setValue("");
            grp.get("Height").setValue("");
            grp.get("CropTop").setValue(0);
            grp.get("CropLeft").setValue(0);
            grp.get("CropWidth").setValue(null);
            grp.get("CropHeight").setValue(null);
            grp.get("CropRotation").setValue(0);
            grp.get("CategoryCode").setValue(null);
            this.elementForm.markAsDirty();
            return;
        }

        if (this.deleteDetail(frmGrpIndex)) {
            // Set as dirty since we removed a detail.
            this.elementForm.markAsDirty();
        }
    }

    public deleteElementPrompt(): void {
        let translated = IpsString.Format(this.promptDeleteBody, this.elementForm.get("Name").value);
        this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deleteElement(), progressMessage: "DELETING" }, undefined)
        .then((result: any) => {
            if (result) {
                this.$state.go("main.storeProfileElement.search");
            }
        })
        .catch(() => {
            // rejection
        });
    }

    public saveElementPrompt(redirect: boolean): Promise<any> {
        let id = this.elementForm.value.Id || 0;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: this.saveElementCallback, progressMessage: "SAVING" }, undefined).then((result: any) => {
            if (result) {
                if (redirect) {
                    this.$state.go("main.storeProfileElement.search");
                } else {
                    this.$state.go("main.storeProfileElement.edit", { id: this.elementForm.value.Id }, { reload: true });
                }
            }
        });
    }

    private numberRequired(input: string) {
        let isValid = false;
        let message = "";

        let inputNbr = Number(input);

        if (isNaN(inputNbr)) {
            return { numericValueRequiredMessage: { valid: false } };
        }

        const inputType = typeof (input);
        // On first load these will be number types
        if (inputType === "string") {
            let decimalPart = input.split(".")[1];
            if (inputNbr <= 0) {
                return { decimalNumberOnly: { valid: false } };
            } else if (decimalPart && decimalPart.length > 6 ||
                (input.indexOf("1e-") === 0 && Number(input.split("1e-")[1]) >= 7)) {
                return { tooManyDecimalPlacesMessage: { valid: false } };
            }
        }

        return null;
    }

    private customValidator(control: AbstractControl) {
        let number: number;

        if (typeof (control.value) === "number") {
            number = control.value;
        } else {
            let inputNbr = Number(control.value);
            if (isNaN(inputNbr)) {
                return { numericValueRequiredMessage: { valid: false } };
            }
            number = inputNbr;
        }

        if (number <= 0) {
            return { decimalNumberOnly: { valid: false } };
        }

        let numberStr = number.toString();
        let decimalPart = numberStr.split(".")[1];
        if (decimalPart && decimalPart.length > 6 || (numberStr.indexOf("1e-") === 0 && Number(numberStr.split("1e-")[1]) >= 7)) {
            return { tooManyDecimalPlacesMessage: { valid: false } };
        }

        //Check for duplicates
        //Get FormArray

        if (!control.parent) {
            return null;
        }

        let array = control.parent.parent;
        let count = 0;
        let width = (control.parent.get("Width").value || "").toString();
        let height = (control.parent.get("Height").value || "").toString();
        let currentWidth = "";
        let currentHeight = "";

        //Check if see if we have matching rows with width and height combo
        Object.keys(array.controls).some(function (key, index) {

            currentWidth = (array.controls[key].get("Width").value || "").toString();
            currentHeight = (array.controls[key].get("Height").value || "").toString();

            //When we find two then we know we have a duplicate
            if (currentWidth !== "" && currentWidth === width &&
                currentHeight !== "" && currentHeight === height &&
                ++count === 2) {
                return true;
            }
        });

        if (count === 2) {
            return {
                duplicatedSize: {
                    valid: false
                }
            };
        }

        return null;
    }

    public getInputNameLabel(control: any, item: any) {
        return control.get("InputName").value + "_" + item;
    }

    private sortElements() {
        let elements = this.elementForm.get("Details") as FormArray;
        elements.controls
            .sort((n1, n2) => {
                let itemA1 = n1.value.Width;
                let itemA2 = n2.value.Width;

                let itemB1 = n1.value.Height;
                let itemB2 = n2.value.Height;

                if (itemA1 < itemA2) { return -1; }
                if (itemA1 > itemA2) { return 1; }
                if (itemB1 < itemB2) { return -1; }
                if (itemB1 > itemB2) { return 1; }
                return 0;
            });
    }

    public msgPrompt(fg: FormGroup) {
        if (!this.hasShownWarning) {

            let ed = this.originalElement.ElementDetails.find((f) => f.Id === fg.get("Id").value);

            if (fg.get("Width").value !== ed.Width || fg.get("Height").value !== ed.Height) {

                this.ipsMessage.confirm({
                        title: "WARNING",
                        body: "ELEMENT_SIZE_CHANGE_WARNING",
                        ok: "YES_CHANGE_IT",
                        cancel: "NO_KEEP_IT"
                    }).then(() => {
                        //Value change is fine
                    })
                    .catch(() => {
                        //Reset value
                        fg.get("Width").setValue(ed.Width);
                        fg.get("Height").setValue(ed.Height);
                    });
            }
            this.hasShownWarning = true;
        }
    }

    public toggleObsolete(locationGroupControl: FormGroup) {
        let isObsoleteControl = locationGroupControl.get("IsObsolete");
        isObsoleteControl.setValue(!isObsoleteControl.value);
        isObsoleteControl.markAsDirty();
    }
}
