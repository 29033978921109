import {distinctUntilChanged, debounceTime} from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { UserService, UserListModel } from "imagine-ui-ng-quick-start";
import { Subject } from "rxjs";

import { ListSearchHelper, SearchInfo, SelectOption } from "imagine-ui-ng-core";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { TranslateService } from "@ngx-translate/core";



interface OptionFormat {
    value: string;
    text: string;
}


@Component({
    selector: "app-user-search",
    templateUrl: "./user-search.component.html",
    styleUrls: ["./user-search.component.scss"]
})
export class UserSearchComponent implements OnInit {

    private all = "ALL";
    private active = "ACTIVE";
    private deactive = "DEACTIVATED";
    private pending = "PENDING";
    private staged = "STAGED";
    private expiredPassword = "EXPIRED_PASSWORD";
    private locked = "LOCKED";
    private suspended = "SUSPENDED";
    public infoTooltipText = "TOOLTIP_USER_STATUS_INFO";
    public translatedTexts: TranslatedTexts;
    public user: ListSearchHelper;
    public busy: boolean;
    public optionList: any[];
    public selectedOption = {};
    public promise: Promise<any>;
    public query: string;
    public querySubject: Subject<string> = new Subject<string>();


    constructor(private userService: UserService, listSearchHelper: ListSearchHelper, private translateService: TranslateService) {
        this.user = listSearchHelper.getListSearchHelper(userService);
        this.busy = this.user.busy;

        this.translatedTexts = {
            foundOf: "",
            total: "",
            loadMoreResults: ""
        };

        this.querySubject.pipe(
            debounceTime(350),
            distinctUntilChanged())
            .subscribe(model => {
                this.getListData(model, this.selectedOption);
            });
    }

    ngOnInit() {
        this.translatedTexts = { foundOf: "found of", total: "total", loadMoreResults: "load more results" };
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.getListData("", { value: "0", text: ""});
    }

    private translateText() {
        this.translateService.get(["FOUND_OF", "TOTAL", "LOAD_MORE_RESULTS", this.all, this.active, this.deactive, this.pending, this.staged, this.expiredPassword, this.locked, this.suspended, this.infoTooltipText]).subscribe((res: [string]) => {
            this.translatedTexts.foundOf = res["FOUND_OF"];
            this.translatedTexts.total = res["TOTAL"];
            this.translatedTexts.loadMoreResults = res["LOAD_MORE_RESULTS"];
            this.infoTooltipText = res[this.infoTooltipText];

            this.optionList = [
                { value: "0", text: res[this.all], class: "img_multi-select" },
                { value: "1", text: res[this.active], class: "img-user-status-active dropdown-status-item" },
                { value: "2", text: res[this.deactive], class: "img-user-status-deactivated dropdown-status-item" },
                { value: "3", text: res[this.pending], class: "img-user-status-pending dropdown-status-item" },
                { value: "4", text: res[this.staged], class: "img-user-status-staged dropdown-status-item" },
                { value: "5", text: res[this.expiredPassword], class: "img-user-status-expiredpassword dropdown-status-item" },
                { value: "7", text: res[this.locked], class: "img-user-status-locked dropdown-status-item" },
                { value: "8", text: res[this.suspended], class: "img-user-status-suspended dropdown-status-item" }
            ];

            this.selectedOption = this.optionList[0];
        });
    }

    public onQueryChange(query: string) {
        this.query = query;
        this.querySubject.next(query);

    }

    public getListData(search: string, filterOption: any) {
        if (this.busy) {
            return;
        }

        if (!search) {
            search = "";
        }

        let searchInfo: SearchInfo = {
            searchText: search, additionalQueryParams: [
                { param: "filterByStatus", paramValue: filterOption.value },
                { param: "userTypes", paramValue: "ExternalLogin" }
            ]
        };
        this.promise = this.user.searchHelper(searchInfo).then(() => {
            // Generate Label since API does not return that.
            for (let i = 0; i < this.user.resultList.length; i++) {
                let item = this.user.resultList[i];
                item.StatusIconClass = this.userService.getStatusIconClass(item.Status);
                item.StatusIconTooltip = this.userService.getStatusIconTooltip(item.Status);
            }
        });
    }

    public getRole(result: UserListModel) {
        return result.Roles
            .map((role) => {
                let roleText = this.userService.getRoleTranslationKey(role.Role);
                this.translateService.get([roleText]).subscribe((res: [string]) => {
                    roleText = res[roleText];
                });
                return roleText;
            }).join(", ");
    }
}
