import { Injectable } from "@angular/core";

import { MarketModel } from "../model/MarketModel";
import { ProfileQuestionModel } from "../model/ProfileQuestionModel";
import { DataStorageType } from "../type/DataStorageType";

@Injectable()
export class DataService {

    public featuresInAudience: MarketModel[] = [];
    public featuresInQuestion: ProfileQuestionModel[] = [];
    public fixtureGroupsInQuestion: ProfileQuestionModel[] = [];
    public fixturesInQuestion: ProfileQuestionModel[] = [];

    constructor() { }

    /**
     * Will overwrite the entire array with what is passed in
     * @param updates
     */
    public updateStorage(updates: MarketModel[] | ProfileQuestionModel[], storageType: DataStorageType) {
        switch (storageType) {
            case "FeatureInAudience":
                this.featuresInAudience.length = 0;
                (<MarketModel[]>updates).forEach(m => this.featuresInAudience.push(m));
                break;
            case "FeatureInQuestion":
                this.featuresInQuestion.length = 0;
                (<ProfileQuestionModel[]>updates).forEach(f => this.featuresInQuestion.push(f));
                break;
            case "FixtureGroupInQuestion":
                this.fixtureGroupsInQuestion.length = 0;
                (<ProfileQuestionModel[]>updates).forEach(fg => this.fixtureGroupsInQuestion.push(fg));
                break;
            case "FixtureInQuestion":
                this.fixturesInQuestion.length = 0;
                (<ProfileQuestionModel[]>updates).forEach(fi => this.fixturesInQuestion.push(fi));
                break;
        }
    }

    public refreshInQuestion(itemToUpdate: ProfileQuestionModel, storageType: DataStorageType) {
        switch (storageType) {
            case "FeatureInQuestion":
                this.featuresInQuestion.some(x => {
                    if (x.Id === itemToUpdate.Id) {
                        x.Name = itemToUpdate.Name;
                        return true;
                    }
                    // keep going
                    return false;
                });
                break;
            case "FixtureGroupInQuestion":
                this.fixtureGroupsInQuestion.some(x => {
                    if (x.Id === itemToUpdate.Id) {
                        x.Name = itemToUpdate.Name;
                        return true;
                    }
                    // keep going
                    return false;
                });
                break;
            case "FixtureInQuestion":
                this.fixturesInQuestion.some(x => {
                    if (x.Id === itemToUpdate.Id) {
                        x.Name = itemToUpdate.Name;
                        return true;
                    }
                    // keep going
                    return false;
                });
                break;

        }
    }

    public removeInQuestion(itemsToRemove: number[], storageType: DataStorageType) {
        //this.featuresInQuestion = this.featuresInQuestion.filter(currentItem => !itemsToRemove.includes(currentItem.Id));
        // Can't set featuresInQuestion to point at a new reference because anyone directly using dataService.featuresInQuestion will no longer be pointing
        // at the new array if you do featuresInQuesiton.filter()
        switch (storageType) {
            case "FeatureInQuestion":
                itemsToRemove.forEach(item => {
                    const index = this.featuresInQuestion.findIndex(q => q.Id === item);
                    if (index > -1) {
                        this.featuresInQuestion.splice(index, 1);
                    }
                });
                break;
            case "FixtureGroupInQuestion":
                itemsToRemove.forEach(item => {
                    const index = this.fixtureGroupsInQuestion.findIndex(q => q.Id === item);
                    if (index > -1) {
                        this.fixtureGroupsInQuestion.splice(index, 1);
                    }
                });
                break;
            case "FixtureInQuestion":
                itemsToRemove.forEach(item => {
                    const index = this.fixturesInQuestion.findIndex(q => q.Id === item);
                    if (index > -1) {
                        this.fixturesInQuestion.splice(index, 1);
                    }
                });
                break;
        }
    }

    public addInQuestion(itemsToAdd: ProfileQuestionModel[], storageType: DataStorageType) {
        switch (storageType) {
            case "FeatureInQuestion":
                itemsToAdd.forEach(x => this.featuresInQuestion.push(x));
                break;
            case "FixtureGroupInQuestion":
                itemsToAdd.forEach(x => this.fixtureGroupsInQuestion.push(x));
                break;
            case "FixtureInQuestion":
                itemsToAdd.forEach(x => this.fixturesInQuestion.push(x));
                break;
        }
    }
}
