import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchHelper, QueryParamItem } from "imagine-ui-ng-core";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { IpsMessageService } from "imagine-ui-ng-messaging";

import { LocationGroupService, LocationService, LocationGroupModel, LocationGroupSubGroupModel, LocationModel, LocationSubGroupModel } from "../../imagine-ui-ng-store-profile/index";
import { MarketSearchModalReturnModel } from "../model/MarketSearchModalReturnModel";
import { MarketModel } from "../model/MarketModel";
import { MarketSaveModel } from "../model/MarketSaveModel";
import { MarketDisplayInfoLocationSubGroup } from "../model/MarketDisplayInfoLocationSubGroup";
import { String as IpsString } from "typescript-string-operations";
import { ProfileQuestionModel } from "../model/ProfileQuestionModel";
import { MarketGroupService } from "../service/market-group.service";
import { MarketGroupModel } from "../model/MarketGroupModel";
import { MarketType } from "../type/MarketType";

interface LocationSubGroupUIModel extends LocationSubGroupModel {
    Disabled: boolean;
    Index: number;
}

interface LocFeatureSearchUIModel extends LocationGroupModel {
    SubGroups: LocationSubGroupUIModel[];
    Disabled: boolean;
    Index: number;
    warningMessage: string;
}

interface MarketModelUI extends MarketModel {
    LocationSubGroupId: number;
}

@Component({
    selector: "app-market-search-modal",
    templateUrl: "./market-search-modal.component.html",
    styleUrls: ["./market-search-modal.component.scss"]
})
export class MarketSearchModalComponent implements OnInit {

    public locationGroups: ListSearchHelper;
    public locationGroupPromise: Promise<any>;
    public locationGroupQuery = "";

    public locations: ListSearchHelper;
    public locationPromise: Promise<any>;
    public locationQuery = "";

    public savedSearches: ListSearchHelper;

    public busy: boolean;
    public focusLocation: boolean;
    public focusLocationGroup: boolean;

    public translatedTexts: TranslatedTexts;
    private featureUsedInAudience = "OPTION_ALREADY_USED_IN_AUDIENCE";
    private featureUsedInQuestion = "ALREADY_USED_IN_QUESTION";
    public marketGroupCircularRef = "MARKET_GROUP_CIRCULAR_REFERENCE";
    public showInUseFeatures = false;
    public disableByFeature = true; //this relies on showInUseFeatures being true. Disable the whole select list by Feature (locationGroup) instead of by Option (SubGroup)
    public resolve: any; // holds data passed in to modal, specifically showInUseFeatures
    public featuresInAudience = [] as MarketModel[]; // Actually a list of all Markets, not just features
    public featuresInQuestion = [] as ProfileQuestionModel[];
    private requestedMarketType: MarketType; // Used to control which market type is being searched.
    private requestedOrdinal: number;
    public showLocationSearch = false;
    public showLocationGroupSearch = false;
    public showSavedSearch = false;
    public title = "SELECT";

    public selectedSearches = [];
    public selectedCount = 0;

    public LocationFeaturePluralMapping: { [k: string]: string } = {
        "=0": "Location Feature Selected",
        "=1": "Location Feature Selected",
        "other": "Location Features Selected",
    };
    public savedSearchPluralMapping: { [k: string]: string } = {
        "=0": "Search Selected",
        "=1": "Search Selected",
        "other": "Searches Selected",
    };

    constructor(private modalInstance: NgbActiveModal, private locationService: LocationService, private locationGroupService: LocationGroupService,
        listSearchHelper: ListSearchHelper, private translateService: TranslateService, private marketGroupService: MarketGroupService, private ipsMessage: IpsMessageService) {
        this.locations = listSearchHelper.getListSearchHelper(locationService);
        this.locationGroups = listSearchHelper.getListSearchHelper(locationGroupService);
        this.savedSearches = listSearchHelper.getListSearchHelper(marketGroupService);
    }

    ngOnInit() {
        if (this.resolve) {
            if (this.resolve.showInUseFeatures) {
                this.showInUseFeatures = this.resolve.showInUseFeatures;
                this.disableByFeature = this.resolve.disableByFeature;
            }
            this.featuresInAudience = this.resolve.featuresInAudience;
            this.featuresInQuestion = this.resolve.featuresInQuestion;
            this.requestedMarketType = this.resolve.requestedMarketType;
            this.requestedOrdinal = this.resolve.requestedOrdinal;
        }

        switch (this.requestedMarketType) {
            case "LocationSubGroup":
                this.showLocationGroupSearch = true;
                this.title = "SELECT_LOCATION_FEATURE";
                this.locationGroupService.head().then((total) => {
                    this.locationGroups.TotalRecords = total;
                });
                this.getLocationGroupData("");
                break;
            case "MarketGroup":
                this.showSavedSearch = true;
                this.title = "SELECT_LOCATION_SEARCH";
                this.marketGroupService.head().then((total) => {
                    this.savedSearches.TotalRecords = total;
                });
                this.getSavedSearchData("");
                break;
        }

        this.translatedTexts = { foundOf: "found of", total: "total", loadMoreResults: "load more results" };
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.focusLocationGroup = true;
        this.focusLocation = true;
    }

    private translateText() {
        this.translateService.get("ALL").subscribe(() => {
            this.translatedTexts.foundOf = this.translateService.instant("FOUND_OF");
            this.translatedTexts.total = this.translateService.instant("TOTAL");
            this.translatedTexts.loadMoreResults = this.translateService.instant("LOAD_MORE_RESULTS");
            this.featureUsedInAudience = this.translateService.instant("OPTION_ALREADY_USED_IN_AUDIENCE");
            this.featureUsedInQuestion = this.translateService.instant("ALREADY_USED_IN_QUESTION");
            this.marketGroupCircularRef = this.translateService.instant("MARKET_GROUP_CIRCULAR_REFERENCE");
        });
    }

    public getLocationGroupData(search: string) {
        this.locationGroupQuery = search;
        let additionalQueryParams = [] as QueryParamItem[];

        //Following overrides exclusive filtering behavior of 'ShowCampaignLocationFeatures'.
        //TODO - This works but is confusing. functionality around this should be refactored.
        //It is now clear that internal users should always have all Location Features returned.
        //The 'Show Campaign Location Feature' checkbox functionality on Location Feature page (profile/locationGroup/search)
        //probably should be a front-end filter only, and ShowCampaignLocationFeatures filtering and
        //IncludeNonCampaignLocationFeatures check removed from back end service
        additionalQueryParams.push({ param: "IncludeNonCampaignLocationFeatures", paramValue: "true" } as QueryParamItem);


        this.locationGroupPromise = this.locationGroups.searchHelper({ searchText: this.locationGroupQuery, recordCount: 5, additionalQueryParams: additionalQueryParams }).then(() => {
            this.locationGroups.resultList.forEach((result: LocationGroupModel) => {
                this.locationGroupService.setLocFeatureInfo(result, this.translateService);
                this.locationGroupService.sortSubGroup(result.SubGroups, result.OptionType);
                if (this.showInUseFeatures) {
                    this.checkAvailability(<LocFeatureSearchUIModel>result);
                }
            });
            this.setSelectionCount();
        });
    }
    public getSavedSearchData(search: string) {
        this.locationQuery = search;
        this.locationPromise = this.savedSearches.searchHelper({ searchText: search }).then(() => {
            this.savedSearches.resultList.forEach((item: any) => {
                let model = item as MarketGroupModel;
                item.Label = `${model.Name}`;
                if (this.showInUseFeatures) {
                    this.checkSavedSearchAvailability(item);
                }
            });
            this.setSelectionCount();
        });
    }

    public constructSubGroupModel(subGroup: any, locationGroup: LocationGroupModel) {
        let locGroupSubGroup = <LocationGroupSubGroupModel>{
            BusinessIdentity: subGroup.BusinessIdentity,
            LocationCount: subGroup.LocationCount,
            LocationGroupId: locationGroup.Id,
            LocationGroupName: locationGroup.Name,
            LocationSubGroupId: subGroup.Id,
            LocationSubGroupName: subGroup.Name,
            Disabled: subGroup.Disabled,
            Index: subGroup.Index
        };
        return locGroupSubGroup;
    }

    public locationGroupChecked(subGroup: any, locationGroup: any, isChecked: Boolean) {
        let item = this.constructSubGroupModel(subGroup, locationGroup);
        if (isChecked) {
            ++this.selectedCount;
            this.selectedSearches.push(item);
        }else {
            --this.selectedCount;
            if (item.Disabled) {
                this.selectedSearches.push(item);
            } else {
                this.selectedSearches = this.selectedSearches.filter(x => x.LocationSubGroupId !== subGroup.Id);
            }
        }
    }

    public locationFeaturesSelected() {
        if (this.selectedSearches.length > 0) {
            let searches = this.selectedSearches;
            this.selectedSearches = [];
            return this.modalInstance.close(new MarketSearchModalReturnModel("LocationSubGroup", searches));
        }else {
            this.modalInstance.close();
        }
    }

    public savedSearchSelected(item, isChecked) {
        if (isChecked) {
            ++this.selectedCount;
            this.selectedSearches.push(item);
        }else {
            --this.selectedCount;
            if (item.Disabled) {
                this.selectedSearches.push(item);

            } else {
                this.selectedSearches = this.selectedSearches.filter(x => x.Id !== item.Id);
            }
        }
    }

    public saveSearchMultiselect() {
        if (this.selectedSearches.length !== 0) {
            let serchesToValidate = this.selectedSearches.filter(x => x.Disabled === false || x.Disabled === undefined);
            if (serchesToValidate.length > 0) {
                this.validateCircularMarketGroup(serchesToValidate).then(
                    (response: boolean) => {
                        let searches = this.selectedSearches;
                        this.selectedSearches = [];
                        if (response === false) {
                            return this.modalInstance.close(new MarketSearchModalReturnModel("MarketGroup", searches));
                        }
                    }).catch((err: any) => {
                        this.ipsMessage.error(IpsString.Format(this.marketGroupCircularRef, err.Detail ));
                });
            }else {
                let searches = this.selectedSearches;
                this.selectedSearches = [];
                return this.modalInstance.close(new MarketSearchModalReturnModel("MarketGroup", searches));
            }
        }else {
            this.modalInstance.close();
        }
    }

    public tabSelected(isLocationGroup: boolean) {
        if (isLocationGroup) {
            this.focusLocationGroup = true;
            this.focusLocation = false;
        } else {
            this.focusLocationGroup = false;
            this.focusLocation = true;
        }
    }

    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    public setSelectionCount() {
        this.selectedCount = 0;
        for (let i = 0; i < this.featuresInAudience.length; i++) {
            let market = this.featuresInAudience[i];
            if (market.TargetMarketType === this.requestedMarketType) {
                this.selectedCount++;
            }
        }

        for (let i = 0; i < this.selectedSearches.length; i++) {
            let market = this.selectedSearches[i];
            if (market.Disabled) {
                this.selectedCount--;
             }else {
                this.selectedCount++;
             }
        }

    }

    private checkAvailability(feature: LocFeatureSearchUIModel) {
        if (this.disableByFeature) {
            for (let i = 0; i < this.featuresInAudience.length; i++) {
                let market = this.featuresInAudience[i];
                if (market.TargetMarketType === "LocationSubGroup" && (<MarketDisplayInfoLocationSubGroup>market.DisplayInfo).LocationGroupId === feature.Id) {
                    feature.Disabled = true;
                    feature.Index = i;
                    feature.warningMessage = IpsString.Format(this.featureUsedInAudience, (<MarketDisplayInfoLocationSubGroup>market.DisplayInfo).LocationSubGroupName);
                    break;
                } else {
                    feature.Disabled = false;
                }
            }
        } else {
            for (let i = 0; i < this.featuresInAudience.length; i++) {
                let market = this.featuresInAudience[i] as MarketModel;
                if (market.TargetMarketType === "LocationSubGroup") {

                    for (let s = 0; s < feature.SubGroups.length; s++) {
                        if ((<MarketDisplayInfoLocationSubGroup>market.DisplayInfo).LocationSubGroupId === feature.SubGroups[s].Id) {
                            feature.SubGroups[s].Disabled = true;
                            feature.SubGroups[s].Index = i;
                            break;
                        } else {
                            feature.Disabled = false;
                        }
                    }
                }
            }
        }
        for (let i = 0; i < this.featuresInQuestion.length; i++) {
            let question = this.featuresInQuestion[i];
            if (question.Id === feature.Id) {
                feature.Disabled = true;
                feature.warningMessage = IpsString.Format(this.featureUsedInQuestion, question.Name);
                break;
            }
        }
    }

    private getMarketValidationModel(items: MarketGroupModel[]): MarketSaveModel[] {
        let markets = [] as MarketSaveModel[];
        items.forEach(item => {
        if (item) {
            let market: MarketSaveModel = {
                Id: 0, //not used
                TargetMarketId: item.Id,
                TargetMarketType: "MarketGroup",
                TargetAttributeValue: "", //not used
                Ordinal: this.requestedOrdinal,
                OperationType: "Union", //not used
                BusinessIdentity: item.BusinessIdentity
            };
            //add currently seletctd item markets
            markets.push(market);
        }
    });
    markets = markets.concat(this.getFeatureInAudinceMarket());
    return markets;
    }


    private getFeatureInAudinceMarket() {
        let markets = [] as MarketSaveModel[];
        for (let i = 0; i < this.featuresInAudience.length; i++) {
            let market = this.featuresInAudience[i] as MarketSaveModel;
            if (market.TargetMarketType === "MarketGroup" && market.Ordinal !== this.requestedOrdinal && !!market.BusinessIdentity ) {

                let previousMarket: MarketSaveModel = {
                    Id: 0, //not used
                    TargetMarketId: market.TargetMarketId,
                    TargetMarketType: "MarketGroup",
                    TargetAttributeValue: "", //not used
                    Ordinal: market.Ordinal,
                    OperationType: "Union", //not used
                    BusinessIdentity: market.BusinessIdentity
                };
                //add prev seletctd item markets
                markets.push(previousMarket);
            }
        }

        return markets;
    }

    private validateCircularMarketGroup(item: MarketGroupModel[]) {
        let markets = { Markets: this.getMarketValidationModel(item), MarketOrdinal: null };
        return (this.marketGroupService.checkForCircularMarketGroup(markets));
    }

    public checkSavedSearchAvailability(marketGroup: any) {
        for (let i = 0; i < this.featuresInAudience.length; i++) {
            let market = this.featuresInAudience[i];
            if (market.TargetMarketType === "MarketGroup" &&
                    market.TargetMarketId === marketGroup.Id) {
                marketGroup.Disabled = true;
                marketGroup.Index = i;
                break;
            } else {
                marketGroup.Disabled = false;
            }
        }
    }
}

