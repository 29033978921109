import { Component, OnInit, Input } from "@angular/core";
import { SurveySectionModel } from "../../model/SurveySectionModel";
import { SurveyService } from "../../service/survey.service";
import { SurveyQuestionModel } from "../../model/SurveyQuestionModel";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { SurveyQuestionAnswerSummaryModel } from "../../model/SurveyQuestionAnswerSummaryModel";
import { LocationGroupService, OptionType } from "../../../imagine-ui-ng-store-profile";
import { SurveyAnswerSearchModalComponent } from "../survey-answer-search-modal/survey-answer-search-modal.component";
import { TranslateService } from "@ngx-translate/core";

interface SurveyQuestionWithImagesModel extends SurveyQuestionModel {
    FullImage: string;
    AnswerTypeText: string;
    MessageControlText: string;
    LinkedProfile: string;
    ProfileType: string;
}

@Component({
    selector: "app-survey-section-view",
    templateUrl: "./survey-section-view.component.html",
    styleUrls: ["./survey-section-view.component.scss"]
})
export class SurveySectionViewComponent implements OnInit {
    @Input() section: SurveySectionModel;

    /**
     * Total number of locations in the survey audience
     */
    @Input() locationBalance: number;

    public isCollapsed = true;
    public promise: Promise<void>;
    public questionsLoading = false;
    private loadError = false;

    constructor(private surveyService: SurveyService, private activeProfileService: ActiveProfileService, private authService: AuthService,
        private ipsModal: IpsModalService, private locationGroupService: LocationGroupService, private translateService: TranslateService) { }

    ngOnInit() {
    }

    public activeCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
        this.getQuestions();
    }

    private getQuestions(): void {
        if (this.section.SurveyQuestions) {
            return;
        }

        this.questionsLoading = true;

        this.promise = this.surveyService.getSurveyQuestionsWithSummary(this.section.Id).then(questionData => {
            this.section.SurveyQuestions = questionData;
            this.section.SurveyQuestions.forEach((item: SurveyQuestionWithImagesModel) => {
                let optionType: OptionType = "Text";
                if (item.LocationGroup) {
                    optionType = item.LocationGroup.OptionType;
                    item.LinkedProfile = `${this.translateService.instant("LOCATION_FEATURE")}: ${item.LocationGroup.Name}`;
                    item.ProfileType = "LocationGroup";
                    if (item.LocationGroup.Media && item.LocationGroup.Media.ResourceUri) {
                        item.FullImage =
                            `${item.LocationGroup.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity
                            }&idToken=${this.authService.getIdToken()}`;
                    }
                } else if (item.FixtureGroup) {
                    item.LinkedProfile = `${this.translateService.instant("FIXTURE_GROUP")}: ${item.FixtureGroup.Name}`;
                    item.ProfileType = "FixtureGroup";
                    optionType = item.FixtureGroup.OptionType;
                } else if (item.Fixture) {
                    item.LinkedProfile = `${this.translateService.instant("FIXTURE")}: ${item.Fixture.Name}`;
                    item.ProfileType = "Fixture";
                    optionType = item.FixtureGroup.OptionType;
                }
                if (item.Answers) {
                    this.locationGroupService.sortSubGroup(item.Answers, optionType);
                    // Make sure subGroupId 0 is first
                    if (item.LocationGroup) {
                    let index = item.Answers.findIndex(a => a.LocationSubGroupId === 0);
                        if (index !== 0) {
                            let awaitingItem = item.Answers[index];
                            item.Answers.splice(index, 1);
                            item.Answers.unshift(awaitingItem);
                        }
                    }
                }
                switch (item.AnswerType) {
                    case "ChooseFromOption":
                        item.AnswerTypeText = item.ProfileType === "FixtureGroup" ? "ENTER_NUMBER_FIXTURES" : "CHOOSE_FROM_OPTIONS";
                        break;
                    case "TypeInOption":
                        item.AnswerTypeText = "TYPE_IN_OPTIONS";
                        break;
                }
                switch (item.MessageControl) {
                    case "CanSendMessage":
                        item.MessageControlText = "CAN_SEND_MESSAGE";
                        break;
                    case "CanNotSendMessage":
                        item.MessageControlText = "CANNOT_SEND_MESSAGE";
                        break;
                    default:
                        item.MessageControlText = "CANNOT_SEND_MESSAGE";
                        break;
                }
            });
            this.questionsLoading = false;
        }, (err) => {
            this.loadError = true;
            this.questionsLoading = false;
        });
    }

    public showLocations(question: SurveyQuestionWithImagesModel, answer: SurveyQuestionAnswerSummaryModel) {
        if (answer.LocationCount === 0) {
            //Modal should be shown even if locationCount = 0
            this.ipsModal.displayTemplateScrollable(SearchModalComponent,
                {
                    resolve: { search: "listbylocation", listOfLocationIds: <number[]>[] }
                },
                {
                    windowClass: "no-list-group-item-interaction"
                });
        } else {
           switch (question.ProfileType) {
                case "LocationGroup":
                    this.ipsModal.displayTemplateScrollable(SurveyAnswerSearchModalComponent,
                    {
                        resolve: {
                            questionId: question.Id,
                            modalTitle: question.Name,
                            headCount: answer.LocationCount,
                            locationSubGroupId: answer.LocationSubGroupId,
                            profileType: question.ProfileType
                        }
                    });
                    break;
                case "FixtureGroup":
                    this.ipsModal.displayTemplateScrollable(SurveyAnswerSearchModalComponent,
                    {
                        resolve: {
                            questionId: question.Id,
                            modalTitle: question.Name,
                            headCount: answer.LocationCount,
                            answerResultType: answer.AnswerResultType,
                            profileType: question.ProfileType
                        }
                    });
                    break;
            }
        }
    }

    public showMessageLocations(question: SurveyQuestionWithImagesModel) {
        if (question.Message.LocationCount === 0) {
            //Modal should be shown even if locationCount = 0
            this.ipsModal.displayTemplateScrollable(SearchModalComponent,
                {
                    resolve: { search: "listbylocation", listOfLocationIds: <number[]>[] }
                },
                {
                    windowClass: "no-list-group-item-interaction"
                });
        } else {
                this.ipsModal.displayTemplateScrollable(SurveyAnswerSearchModalComponent,
                    {
                        resolve: {
                            questionId: question.Id,
                            modalTitle: question.Name,
                            headCount: question.Message.LocationCount,
                            profileType: question.ProfileType,
                            searchType: "message"
                        }
                    });
            }
    }
}
