import { Component, OnInit } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { Transition } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString } from "typescript-string-operations";

import {
    CampaignData, PromotionModel, PromotionMessagePlacementModel, PromotionMessageLocationBalanceModel, PromotionMessagePlacementGroupModel, PromotionMessagePlacementElementModel,
    PlacementQuantityByFixtureHolderSize, PromotionMessageModel, CampaignService, PromotionService, PromotionMessageService, PatternGroupService,
    PromotionHeaderModel, PlacementFixtureDetails, PromotionMessageWithPlacementsModel, MessagePlacementCalculationResultModel, PromotionMessagePatternGroupModel,
} from "../../index";

export interface PromotionUIModel extends PromotionModel {
    Label?: string;
}

export interface MessagePlacementUIModel extends PromotionMessagePlacementModel {
    collapse: boolean;
    busy: boolean;
    DisplayName: string;
    QuantitiesByFixtureHolderSize: PlacementQuantityByFixtureHolderSizeUIModel[];
    NoFixture: boolean;
    PatternTypeEnum: string;
    PositionLimit: number;
    PositionThreshold: number;
    PositionsAvailable: number;
    HolderId: number;
    PositionInfoLoaded: boolean;
}

interface MessageUIModel extends PromotionMessageModel {
    collapse: boolean;
    ordinals: number[];
    totalPositions: number;
    totalPositionArray: Array<number>;
    isPattern: boolean;
    PlacementGroups: PromotionMessagePlacementGroupModel[];
}

export interface PlacementQuantityByFixtureHolderSizeUIModel extends PlacementQuantityByFixtureHolderSize {
    CalculatedQuantityIncrease: number;
    FulfillmentQuantityLabel: string;
    QuantityIncreaseLabel: string;
}

interface PromotionMessagePlacementElementUIModel extends PromotionMessagePlacementElementModel {
    collapse: boolean;
    busy: boolean;
    DisplayName: string;
    FulfillmentQuantityLabel: string;
    QuantityTotal: number;
    LocationGroupLabel: string;
    PendingAssignmentTooltip?: string;
    LocationSubGroup?: boolean;
}

@Component({
    selector: "app-promotion-view",
    templateUrl: "./promotion-view.component.html",
    styleUrls: ["./promotion-view.component.scss"],
    providers: [DecimalPipe]
})
export class PromotionViewComponent implements OnInit {
    public campaignId: number;
    public promotionId: number;
    public selectedPromotion: PromotionUIModel;
    public campaign: CampaignData;
    public campaignLoaded = false;
    public campaignLoadError = false;
    public promotionLoaded = false;
    public promotionLoadError = false;

    private marketedLocations = "MARKETED_LOCATIONS";
    private locationGroupLocationsPendingWarning = "MARKET_PENDING_LOCATIONS_WARNING";

    private TranslateStrings: { [key: string]: string } = {
        "LOCATION": "",
        "HOLDER": "",
        "POSITION": ""
    };

    constructor(
        private transition: Transition,
        private translateService: TranslateService,
        private decimalPipe: DecimalPipe,
        private campaignService: CampaignService,
        private promotionService: PromotionService,
        private promotionMessageService: PromotionMessageService,
        private patternGroupService: PatternGroupService
        ) { }

    ngOnInit() {
        this.campaignId = Number(this.transition.params().campaignId || 0);
        this.promotionId = Number(this.transition.params().id || 0);

        this.selectedPromotion = { Name: "" } as PromotionUIModel;
        this.campaign = { Id: this.campaignId } as CampaignData;

        this.translateService.get("ALL").subscribe(() => this.TranslateText());

        this.getData();
    }

    private TranslateText() {
        this.marketedLocations = this.translateService.instant("MARKETED_LOCATIONS");
        this.locationGroupLocationsPendingWarning = this.translateService.instant("MARKET_PENDING_LOCATIONS_WARNING");

        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    }

    private getData() {
        this.getCampaignHeader(this.campaignId);
        this.getPromotionHeader(this.promotionId);
    }

    private getCampaignHeader(campaignId: number) {
        this.campaignService.get(campaignId).then((campaignData: CampaignData) => {
                Object.assign(this.campaign, campaignData);
                this.campaignLoaded = true;
            },
            (err) => {
                this.campaignLoadError = true;
            });
    }

    private getPromotionHeader(promotionId: number) {
        return this.promotionService.getPromotionHeader(String(promotionId)).then((promo: PromotionHeaderModel) => {
            return this.getPromotionMessages(promo);
        });
    }

    private getPromotionMessages(promotion: PromotionHeaderModel): Promise<void> {
        this.promotionLoaded = false;
        return this.promotionMessageService.GetByPromotionMessagesWithPlacements(promotion.Id, true).then((messageWithPlacementsModel: PromotionMessageWithPlacementsModel) => {
            let messages: MessageUIModel[] = [];

            messageWithPlacementsModel.PromotionMessageAndPlacements.forEach((messageCalc: MessagePlacementCalculationResultModel) => {
                let message: MessageUIModel = messageCalc.PromotionMessage as MessageUIModel;
                let patternGroup = this.getPatternGroupForMessage(message.Id, messageWithPlacementsModel.PatternGroups);
                let placementGroups = patternGroup.PlacementGroups;

                message.collapse = true;
                message.isPattern = false;
                message.PlacementGroups = placementGroups;

                if (patternGroup != null) {
                    let allOrdinals = patternGroup.MessagePatterns.map((item) => {
                        return item.Ordinal;
                    });

                    let maxOrdinal = allOrdinals.reduce((item1, item2) => {
                        return Math.max(item1, item2);
                    });

                    let matchingMessagePatterns = patternGroup.MessagePatterns.filter((item) => {
                        return item.PromotionMessageId === message.Id;
                    });

                    let ordinals = matchingMessagePatterns.map((item) => {
                        return item.Ordinal;
                    });

                    // message is the model used in the UI:
                    // - set markets on it
                    // - set LocationBalance from last market in the list

                    message.Markets = patternGroup.Markets;
                    message.LocationBalance = (patternGroup.Markets && patternGroup.Markets.length > 0) ?
                        patternGroup.Markets[patternGroup.Markets.length - 1].LocationBalance : 0;

                    let positionArray = new Array<number>();

                    for (let index = 0; index < maxOrdinal; index++) {
                        positionArray.push(index + 1);
                    }

                    message.totalPositions = maxOrdinal;
                    message.ordinals = ordinals;
                    message.isPattern = patternGroup.PatternGroupType === "MultiMessage";
                    message.totalPositionArray = positionArray;
                }

                if (!message.MessagePlacements) {
                    message.MessagePlacements = [];
                }


                //remove messagePlacement without width/height
                messageCalc.MessagePlacements = messageCalc.MessagePlacements.filter(mp => mp.MessagePlacement.Width !== undefined);

                let msgPlacementGrpDictionary = [];
                messageCalc.MessagePlacements.forEach((placement) => {
                    if (placement.Selected) {
                        let msgPlacement = <MessagePlacementUIModel>placement.MessagePlacement;
                        msgPlacement.HolderId = placement.HolderId;
                        msgPlacement.DisplayName = !placement.MessagePlacement.HolderVersionInfoName ? `${placement.FixtureTypeName} - ${placement.HolderName}` : `${placement.FixtureTypeName} - ${placement.HolderName}`;
                        msgPlacement.collapse = true;
                        let plcGroup = placementGroups.find(pg => pg.Id === placement.PromotionMessagePlacementGroupId);
                        if (plcGroup && plcGroup.PrintMessage) {
                            if (msgPlacementGrpDictionary[placement.PromotionMessagePlacementGroupId]) {
                                let msgPlacementGrp = msgPlacementGrpDictionary[placement.PromotionMessagePlacementGroupId] as MessagePlacementUIModel;
                                msgPlacementGrp.FinalQuantity += placement.MessagePlacement.FinalQuantity;

                                //fill QuantitiesByFixtureHolderSize for displaying detail
                                let placementBySize = this.convertToPlacementBySizeModel(placement.Fixtures, placement.MessagePlacement, message.isPattern);
                                msgPlacementGrp.QuantitiesByFixtureHolderSize.push(placementBySize);
                            } else {
                                if (placementGroups != null) {
                                    let matchingPatternGroupPlacements = placementGroups.filter((item) => {
                                        return item.HolderId === placement.HolderId;
                                    });

                                    let matchingPatternGroupPlacement = matchingPatternGroupPlacements.length > 0 ? matchingPatternGroupPlacements[0] : null;

                                    if (matchingPatternGroupPlacement != null) {
                                        msgPlacement.PositionLimit = matchingPatternGroupPlacement.PositionLimit;
                                        msgPlacement.PatternTypeEnum = matchingPatternGroupPlacement.PatternTypeEnum;
                                    }
                                }

                                //fill QuantitiesByFixtureHolderSize for displaying detail
                                let placementBySize = this.convertToPlacementBySizeModel(placement.Fixtures, placement.MessagePlacement, message.isPattern);
                                msgPlacement.QuantitiesByFixtureHolderSize = [placementBySize];
                                msgPlacementGrpDictionary[placement.PromotionMessagePlacementGroupId] = placement.MessagePlacement;
                                message.MessagePlacements.push(msgPlacementGrpDictionary[placement.PromotionMessagePlacementGroupId]);
                            }
                        }
                    }
                });

                // Sort the placements
                message.MessagePlacements.sort((n1, n2) => ((<MessagePlacementUIModel>n1).DisplayName.localeCompare((<MessagePlacementUIModel>n2).DisplayName)));

                // Sort fixture name
                message.MessagePlacements.forEach((mp: MessagePlacementUIModel) => {
                    //sort fixture name link array
                    mp.QuantitiesByFixtureHolderSize.forEach((item) => {
                        if (item.Fixtures) {
                            (item.Fixtures as any).sort(function (a: PlacementFixtureDetails, b: PlacementFixtureDetails) {

                                let x = (a.FixtureName + a.FixtureModel).toLowerCase(), y = (b.FixtureName + b.FixtureModel).toLowerCase();

                                return x < y ? -1 : x > y ? 1 : 0;
                            });
                        }
                    });

                    //sort outer array by fixture
                    mp.QuantitiesByFixtureHolderSize.sort(function (a, b) {
                        if (a.Fixtures && b.Fixtures) {
                            let x = (a.Fixtures[0].FixtureName + a.Fixtures[0].FixtureModel).toLowerCase(), y = (b.Fixtures[0].FixtureName + b.Fixtures[0].FixtureModel).toLowerCase();

                            return x < y ? -1 : x > y ? 1 : 0;
                        }
                    });
                });


                if (!messageCalc.PlacementElements) {
                    messageCalc.PlacementElements = [];
                }
                if (!message.PlacementElements) {
                    message.PlacementElements = [];
                }

                messageCalc.PlacementElements.forEach(element => {
                    if (element.Selected) {
                        let uiElement = element as PromotionMessagePlacementElementUIModel;
                        uiElement.DisplayName = `${element.ElementDetailName} - ${element.ElementDetailWidth} X ${element.ElementDetailHeight}`;
                        uiElement.collapse = true;

                        message.PlacementElements.push(uiElement);
                    }
                });

                // sort the elements
                message.PlacementElements.sort((n1, n2) => ((<PromotionMessagePlacementElementUIModel>n1).DisplayName.localeCompare((<PromotionMessagePlacementElementUIModel>n2).DisplayName)));

                messages.push(message);
            });

            // sort messages by the lowest ordinal associated with each
            messages.sort(function (a, b) {
                let aOrdinals = (<MessageUIModel>a).ordinals;
                let bOrdinals = (<MessageUIModel>b).ordinals;

                let minOrdinalA = Math.min.apply(null, aOrdinals);
                let minOrdinalB = Math.min.apply(null, bOrdinals);

                if (minOrdinalA < minOrdinalB) {
                    return -1;
                } else if (minOrdinalA === minOrdinalB) {
                    return 0;
                } else {
                    return 1;
                }
            });

            let promo = (<PromotionUIModel>promotion);
            promo.PromotionMessages = (messages);
            promo.Label = promo.Name;
            this.selectedPromotion = promo;
        }, (error) => {
            this.promotionLoadError = true;
        }).then(() => {
            this.promotionLoaded = true;
        });
    }

    private getPatternGroupForMessage(messageId: number, patternGroups: PromotionMessagePatternGroupModel[]): PromotionMessagePatternGroupModel {
        let foundPatternGroups = patternGroups.filter((patternGroup) => {
            let patternMsg = patternGroup.MessagePatterns.filter((messagePattern) => {
                return messagePattern.PromotionMessageId === messageId;
            });
            return patternMsg.length > 0;
        });
        if (foundPatternGroups.length > 0) {
            return foundPatternGroups[0];
        }
        return null;
    }

    private convertToPlacementBySizeModel(fixtures: PlacementFixtureDetails[], msgPlacement: PromotionMessagePlacementModel, isPattern: boolean) {
        let placement = {} as PlacementQuantityByFixtureHolderSizeUIModel;
        Object.assign(placement, msgPlacement);
        let fulfillmentQtyString;
        let qtyIncreaseLabel: string;
        if (placement.QuantityIncreaseTarget === "Location") {
            placement.CalculatedQuantityIncrease = placement.LocationCount * placement.QuantityIncrease;
            qtyIncreaseLabel = "LOCATION";
        } else if (placement.QuantityIncreaseTarget === "Holder") {
            placement.CalculatedQuantityIncrease = placement.HolderCount * placement.QuantityIncrease;
            qtyIncreaseLabel = isPattern ? "POSITION" : "HOLDER";
        }

        if (placement.FulfillmentOperator === "Percent") {
            let fulfillmentQuantity = Math.ceil((placement.HolderCount + placement.CalculatedQuantityIncrease) * placement.FulfillmentOperand / 100);
            fulfillmentQtyString = this.decimalPipe.transform(fulfillmentQuantity);
            placement.FulfillmentQuantityLabel = `${placement.FulfillmentOperand}% - ${fulfillmentQtyString}`;
        } else if (placement.FulfillmentOperator === "Number") {
            placement.FulfillmentQuantityLabel = this.decimalPipe.transform(placement.FulfillmentOperand);
        }

        placement.QuantityIncreaseLabel = `${this.decimalPipe.transform(placement.QuantityIncrease)} ${this.TranslateStrings[qtyIncreaseLabel]} - ${this.decimalPipe.transform(placement.CalculatedQuantityIncrease)}`;

        placement.HolderWidth = msgPlacement.Width;
        placement.HolderHeight = msgPlacement.Height;
        placement.Fixtures = fixtures;

        return placement;
    }

    public toggleMessagePlacementCollapse(placement: MessagePlacementUIModel): void {
        placement.collapse = !placement.collapse;

        if (!placement.collapse) {

            let selectedMainMessages = this.selectedPromotion.PromotionMessages.filter((msg) => {
                return msg.Id === placement.PromotionMessageId;
            });

            let selectedMainMessage = selectedMainMessages.length > 0 ? selectedMainMessages[0] : null;
            let isPattern = selectedMainMessage == null ? false : (<MessageUIModel>selectedMainMessage).isPattern;

            if (isPattern) {
                let balanceModel: PromotionMessageLocationBalanceModel = {
                    PromotionId: this.selectedPromotion.Id,
                    StartDate: this.selectedPromotion.StartDate,
                    EndDate: this.selectedPromotion.EndDate,
                    Markets: selectedMainMessage.Markets
                };

                if (!placement.PositionInfoLoaded) {
                    placement.busy = true;
                    this.patternGroupService.calculatePositionLocationCounts([balanceModel], (<MessageUIModel>selectedMainMessage).PlacementGroups).then((result) => {
                        // look at first element since we're just grabbing
                        // detail for one specific placement
                        let holderId = placement.HolderId;
                        let allResults = result.filter((item) => item.HolderId === holderId);
                        let firstResult = allResults.length > 0 ? allResults[0] : null;

                        let positionsAvailable = 0;
                        let positionThreshold = 0;
                        let previousMaxLocations = 0;

                        if (firstResult !== null) {
                            // threshold is highest position that exists at all locations,
                            // so we increment threshold until the location count drops
                            for (let index = 0; index < firstResult.PositionLocationCountOptions.length; index++) {
                                if (firstResult.PositionLocationCountOptions[index].LocationCount >= previousMaxLocations) {
                                    previousMaxLocations = firstResult.PositionLocationCountOptions[index].LocationCount;
                                    positionThreshold++;
                                } else {
                                    break;
                                }
                            }

                            positionsAvailable = firstResult.PositionLocationCountOptions.length;
                        }

                        placement.PositionThreshold = positionThreshold;
                        placement.PositionsAvailable = positionsAvailable;
                        placement.PositionInfoLoaded = true;
                    }).then(() => { placement.busy = false; });
                } else {
                    placement.busy = false;
                }
            }

        }
    }

    public toggleMessagePlacementElementCollapse(placementElement: PromotionMessagePlacementElementUIModel): void {
        placementElement.collapse = !placementElement.collapse;
        if (!placementElement.collapse) {
            placementElement.busy = true;
            this.promotionMessageService.GetPlacementElementDetails(placementElement.Id).then((detail) => {
                placementElement.PlacementElementDetails = detail.PlacementElementDetails;
                placementElement.PendingAssignmentCount = detail.PendingAssignmentCount;
                let total = 0;
                if (placementElement.QuantityHandling === "MarketedLocations") {
                    total = detail.PlacementElementDetails[0].FinalQuantity;
                    placementElement.LocationGroupLabel = this.marketedLocations;
                } else if (placementElement.QuantityHandling === "LocationGroup") {
                    total = detail.PlacementElementDetails.reduce((sum, item) => {
                        return sum + item.FinalQuantity;
                    }, 0);
                    placementElement.LocationGroupLabel = placementElement.LocationGroupName;
                    placementElement.LocationSubGroup = true;
                    if (placementElement.PendingAssignmentCount > 0) {
                        placementElement.PendingAssignmentTooltip = IpsString.Format(this.locationGroupLocationsPendingWarning, placementElement.PendingAssignmentCount.toString());
                    }
                }

                placementElement.QuantityTotal = total;
                if (detail.FulfillmentOperator === "Percent") {
                    let fulfillQuantity = Math.ceil(total * detail.FulfillmentOperand / 100);
                    placementElement.FulfillmentQuantityLabel = `${detail.FulfillmentOperand}% - ${this.decimalPipe.transform(fulfillQuantity)}`;
                } else if (detail.FulfillmentOperator === "Number") {
                    placementElement.FulfillmentQuantityLabel = this.decimalPipe.transform(detail.FulfillmentOperand);
                }
            }).then(() => {
                placementElement.busy = false;
            });
        }
    }

}
