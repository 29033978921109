export class ShipMethodModel {
    public Id: number;
    public DisplayName: string;
    public Carrier: string;
    public ServiceCode: string;
    public VCFreightCode: string;
    public OShipMethod: string;
    public HHGDeliveryService: string;

    public Selected: boolean;

    constructor() {
        this.Selected = false;
    }

    public get Label(): string {
        return this.DisplayName;
    }
}

export class ShipMethodSubGroupRule {
    public LocationSubGroupId?: number;
    public LocationSubGroupName: string;
    public Preferences: ShipMethodPreferenceModel[];
    public ShipMethodIds: number[];
}

export class ShipMethodRule {
    public RoleId?: number;
    public AllLocations = true;
    public LocationGroupId?: number;
    public SubGroupRules: ShipMethodSubGroupRule[];
    public LocationGroupName: string;
    public PendingLocations: number;
    public ShipMethodIds: number[];

    public setLocationGroupInfo(id: number, name: string, pendingLocationCount: number): void {
        this.LocationGroupId = id;
        this.LocationGroupName = name;
        this.PendingLocations = pendingLocationCount;
    }

    public setLocationSubGroups(groups: any[]): void {
        this.SubGroupRules = groups.map((item) => {
            const returnValue = new ShipMethodSubGroupRule();

            returnValue.LocationSubGroupId = item.Id;
            returnValue.LocationSubGroupName = item.Name;
            returnValue.Preferences = [];
            returnValue.ShipMethodIds = [];

            return returnValue;
        });
    }
}



export class ShipMethodPreferenceModel {
    public Id: number;
    public ShipMethodId: number;
    public AdminOnly?: boolean;
    public LocationGroupId?: number;
    public LocationSubGroupId?: number;
    public RoleId?: number;
    public ShipMethod: ShipMethodModel;
    public BusinessIdentity: string;

    constructor(businessIdentity: string, shipMethod: ShipMethodModel = null) {
        this.Id = 0;
        this.AdminOnly = false;
        this.BusinessIdentity = businessIdentity;

        if (shipMethod) {
            this.ShipMethod = shipMethod;
            this.ShipMethodId = shipMethod.Id;
        }
    }

    public SetAdminOnly(value: boolean): void {
        this.AdminOnly = value;
    }
}
