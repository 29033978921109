<h2 class="title mx-3" translate>ORDERS</h2>
<div class="col" ipsFocus="searchInput">
    <ips-list-search searchLabel="{{'ORDER' | translate }}"
    searchLabelPlural="{{'ORDERS' | translate }}"
    labelLoading="{{'ACCESSING_MY_ORDERS_INFO' | translate }}"
    labelLoadingError="{{'ERROR_ACCESSING_MY_ORDERS_INFO' | translate }}"
    [hideList]=false
    [listData]="myOrder"
    (onListDataChange)="getListData()"
    [section]="'main.myOrder'"
    [scroller]=true
    [quickRef]=false
    [itemClickPermission]="'main.myOrder.view'"
    [promise]="promise"
    [autoFocus]=true
    [query]="search">

        <ng-template #searchHeaderTemplate>
            <div class="ips-ls-search-module-imgn">
                <div class="form-row">
                    <div class="col-12 col-md-4">
                        <label class="labels" for="businessCustomer" translate>LOCATION</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_LOCATION_MY_ORDERS' | translate}}" placement="bottom"></i>
                        <button id="businessCustomer" (click)="selectLocation()" class="btn btn-lg btn-block btn-default text-center long-text-wrap">{{ selectedLocation.Label }}</button>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="labels" for="businessCustomer" translate>ORDER_STATUS</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_STATUS_MY_ORDERS' | translate}}" placement="bottom"></i>
                        <button id="businessCustomer" (click)="selectOrderStatus()" class="btn btn-lg btn-block btn-default text-center long-text-wrap">{{ selectedStatus.text }}</button>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="labels" for="businessCustomer" translate>ORDER_DATE_RANGE</label><i class="fa fa-info-circle fa-lg ips-ls-info-icon" aria-hidden="true" ngbTooltip="{{'TOOLTIP_ORDER_DATE_MY_ORDERS' | translate}}" placement="bottom"></i>
                        <form name="dateForm" novalidate #dateForm="ngForm" role="form">
                            <app-ips-date-range [startDate]="startDate" [endDate]="endDate" [daySpan]="30" [maxDate]="today" [showLabel]="false" [required]="false" (rangeChanged)="changeDateRange($event)"></app-ips-date-range>
                        </form>
                    </div>
                </div>
                <div class="mt-1">
                    <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                    <input type="text"
                    class="form-control form-control-lg ips-ls-input"
                    [(ngModel)]="query" (ngModelChange)="onQueryChange($event)"
                    id="btn-search"
                    value=""
                    placeholder="{{ 'SEARCH_BY_ORDER_NUMBER' | translate }}"
                    maxlength="20"
                    [ngModelOptions]="{ debounce: 350 }"
                    aria-label="Text input with dropdown button" name="searchInput">
                </div>
                <div class="row no-gutters mt-3">
                    <div class="col-12 col-md-auto ml-3">
                        <span class="badge-pill badge-secondary font-weight-normal">{{myOrder.ResultChunkAttributes.TotalRecords | number}}</span>
                        <span class="ml-1">{{myOrder.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping}} {{'FOUND' | translate}} </span>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #listSearchItemTemplate let-result="result">
            <div class="row ips-search-result-big">
                <div class="col pr-0">
                    <a uiSref="main.myorders.view" [uiParams]="{id:result.Id}" class="no-underline">
                        <div class="px-3 pt-3 loc-label">
                            <div class="text-info text-underline-hover clicker">
                                <span translate>ORDER_NUMBER</span>: <span class="h4 mb-1" innerHtml="{{result.Id | highlight : search}}"></span>
                            </div>
                            <div class="loc-city-state">
                                <span translate>ORDER_DATE</span>: {{result.CreatedDate | date:'medium'}}
                            </div>
                         </div>
                    </a>
                </div>
            </div>
            <app-search-cards [CardData]="result.Cards" [LocationId]="result.Id"
            [EditOrViewPermission]="editOrViewPermission" [FullImage]="result.FullImage"
            [SearchText]="search"></app-search-cards>
        </ng-template>
    </ips-list-search>
    <div *ngIf="myOrder.resultList.length === 0" class="card px-3 ips-shadow">
        <h2 translate>NO_MATCHING_ORDERS</h2>
    </div>
</div>
