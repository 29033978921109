import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { FormatWidth, getLocaleDateFormat } from "@angular/common";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { FormControl, NgModel, Validators } from "@angular/forms";

@Component({
    selector: "app-ips-date-range",
    templateUrl: "./ips-date-range.component.html",
    styleUrls: ["./ips-date-range.component.scss"]
})
export class IpsDateRangeComponent implements OnInit {
    @ViewChild("rangeDate") rangeControl: NgModel;

    @Input() startDate: Date;
    @Input() endDate: Date;
    @Input() daySpan = 0; // Number days to limit the range to. Set to 0 for no limit
    @Input() showLabel = true;
    @Input() labelText = "EXPIRED_DATE_RANGE";
    @Input() required = true;
    @Input() maxDate: Date;
    @Output() rangeChanged = new EventEmitter<any>();

    private day = 1000 * 60 * 60 * 24;
    private calendarTheme = "theme-default";

    public rangeDate: any;
    public dateRangeOptions: Partial<BsDatepickerConfig> = {};
    public localeName: string;
    public dateFormat: string;
    public dateRangeModel: Date[];
    public DateRangeTooBig = false;

    constructor(activeProfileService: ActiveProfileService) {
        let minDate = new Date(Date.UTC(1970, 1, 1, 0, 0, 0, 0));
        this.localeName = activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(this.localeName, FormatWidth.Short).toUpperCase();

        this.dateRangeOptions = {
            minDate: minDate,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            rangeInputFormat: this.dateFormat
        };
    }

    ngOnInit() {
        this.DateRangeTooBig = false;
        if (this.required) {
            this.dateRangeModel = [];
            let today = new Date();
            today.setHours(0, 0, 0, 0);	// set to midnight
            this.dateRangeModel.push(this.startDate || today);
            this.dateRangeModel.push(this.endDate || today);
            this.rangeControl.control.setValidators(Validators.required);
        }
    }

    public changeDateRange() {
        if (this.dateRangeModel && this.dateRangeModel.length > 1) {
            this.checkDateRange(this.dateRangeModel[0], this.dateRangeModel[1]);
            if (this.DateRangeTooBig) {
                this.rangeControl.control.setErrors({ rangeTooBig: true });
            }
            this.rangeChanged.emit({
                startDate: this.dateRangeModel[0],
                endDate: this.dateRangeModel[1],
                rangeTooBig: this.DateRangeTooBig
            });
        } else {
            this.DateRangeTooBig = false;
            this.rangeChanged.emit({
                startDate: null,
                endDate: null,
                rangeTooBig: this.DateRangeTooBig
            });
        }
    }

    private checkDateRange(startDate: Date, endDate: Date) {
        if (startDate && endDate) {
            if (this.daySpan > 0) {
                const diff = Math.abs(Math.floor(startDate.getTime() - endDate.getTime()));

                const days = Math.floor(diff / this.day);
                this.DateRangeTooBig = days > this.daySpan;
            }
        } else {
            // Set to false since we don't have a range yet
            this.DateRangeTooBig = false;
        }
    }

}
