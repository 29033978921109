import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { StateService, StateParams, Transition } from "@uirouter/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { CartItemPodModel, CartItemPostData, CartService, PunchOutService } from "../../index";
import { IpsModalService, IpsModalInstance } from "imagine-ui-ng-modal";

@Component({
    selector: "app-ampie-add-to-cart",
    templateUrl: "./add-to-cart.component.html"
})
export class XMPieAddToCartComponent implements OnInit {
    @ViewChild("errorTemplate") errorTemplate: TemplateRef<any>;

    private cartPODModel: CartItemPodModel;
    private modalInstance: IpsModalInstance;
    private reorder = false;
    private originalOrderId = 0;

    constructor(
        private activeProfileService: ActiveProfileService, private transition: Transition,
        private stateService: StateService, private cartService: CartService,
        private modalService: IpsModalService, private punchOutService: PunchOutService
    ) { }

    ngOnInit() {
        this.processQueryParams(this.transition.params());
        if (this.cartPODModel) {
            const key = this.cartPODModel.ItemKey;
            const cartItem = JSON.parse(localStorage.getItem(key));

            // we have the cart item from the xmpie item key
            if (cartItem) {
                cartItem.PodOrderId = this.cartPODModel.PodOrderId;
                cartItem.PodOrderProductId = this.cartPODModel.PodOrderProductId;

                if (cartItem.PodOrderId && cartItem.PodOrderProductId) {
                    // if we got valid POD data back, add the item to the cart
                    this.cartService.addToCart(cartItem).then(response => {
                        localStorage.removeItem(key);

                        if (!this.reorder) {
                            this.stateService.go("main.myproducts");
                        } else {
                            // TODO:  if we want to transition back to the order screen, use this.originalOrderId to do so
                            this.stateService.go("main.mycart");
                        }
                    });
                } else {
                    // otherwise, show an error message to the user and tell them to retry
                    this.showPodError();
                }
            } else {
                // the cart item wasn't found,
                // show an error and redirect to product screen
                this.showPodError();
            }
        }
    }

    private showPodError() {
        this.modalInstance = this.modalService.displayComponentAndReturnInstance(this.errorTemplate, null, { size: "lg", backdrop: "static" });
    }

    private closeErrorModal() {
        this.modalService.closeModalInstances();
        this.modalInstance = null;
    }

    public retry() {
        this.closeErrorModal();
        this.punchOutService.getCustomizeUrlByKey(this.cartPODModel.ItemKey).then((url) => {
            if (url) {
                window.location.href = url;
            } else {
                this.showPodError();
            }
        });
    }

    public cancel() {
        this.closeErrorModal();
        localStorage.removeItem(this.cartPODModel.ItemKey);
        this.stateService.go("main.myproducts");
    }

    public processQueryParams(parms) {
        this.reorder = parms.reorder || false;
        this.originalOrderId = parms.originalOrderId || 0;

        if (parms.ItemKey && parms.OrderId && parms.OrderProductId) {
            this.cartPODModel = { Id: 0, ItemKey: parms.ItemKey, PodOrderId: parms.OrderId, PodOrderProductId: parms.OrderProductId };
        }
    }
}
