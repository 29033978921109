import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { FieldControlBaseComponent } from "../field-control-base";

@Component({
  selector: "app-ips-number",
  templateUrl: "./ips-number.component.html",
  styleUrls: ["./ips-number.component.scss"]
})
export class IpsNumberComponent extends FieldControlBaseComponent implements OnInit {

    ngOnInit() {
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(() => this.TranslateText());

        let validators = [];

        if (this.isFieldRequired) {
            validators.push(Validators.required);
        }

        this.control = new FormControl(typeof (this.value) === "object" ? this.value.Text : this.value, validators);
        this.control.valueChanges.pipe(distinctUntilChanged(), debounceTime(350)).subscribe((value) => {
            let val = this.GetCustomDataValue();
            val.Text = value;
            this.valueChanged.emit(val);
        });

        this.group = new FormGroup({
            Name: new FormControl(this.name),
            Text: this.control,
            Id: new FormControl(this.value ? this.value.Id || 0 : 0),
            CustomDataId: new FormControl(this.customDataId)
        });

        this.fieldValues.push(this.group);
    }
}

