var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/angular";
import { NgForm } from "@angular/forms";
import { String as IpsString } from "typescript-string-operations";
import { TemplateFormBase } from "../../../shared/templateFormBase";
import { FixtureGroupService, HolderModel, FixtureGroupModel, StoreFrontCategoryService, StoreFrontCategory } from "../../index";
var HolderInternal = /** @class */ (function (_super) {
    __extends(HolderInternal, _super);
    function HolderInternal(_inputName, _inputPriority) {
        var _this = _super.call(this) || this;
        _this.inputName = _inputName;
        _this.inputPriority = _inputPriority;
        _this.Id = 0;
        _this.Name = "";
        _this.Priority = 0,
            _this.HasFixtureReference = false;
        return _this;
    }
    return HolderInternal;
}(HolderModel));
var FixtureGroupInternal = /** @class */ (function (_super) {
    __extends(FixtureGroupInternal, _super);
    function FixtureGroupInternal(holderInputName, holderInputPriority) {
        var _this = _super.call(this) || this;
        _this.Name = "";
        _this.Notes = "";
        _this.Holders = [new HolderInternal(holderInputName, holderInputPriority)];
        return _this;
    }
    return FixtureGroupInternal;
}(FixtureGroupModel));
var FixtureGroupEditComponent = /** @class */ (function (_super) {
    __extends(FixtureGroupEditComponent, _super);
    function FixtureGroupEditComponent(fixtureGroupService, ipsMessage, translateService, pageTitleService, activeProfileService, transition, stateService, storeFrontCategoryService) {
        var _this = _super.call(this) || this;
        _this.ipsMessage = ipsMessage;
        _this.translateService = translateService;
        _this.pageTitleService = pageTitleService;
        _this.activeProfileService = activeProfileService;
        _this.stateService = stateService;
        _this.storeFrontCategoryService = storeFrontCategoryService;
        _this.indexer = 0;
        _this.indexerTwo = 0;
        _this.textUnableDeleteHolderWithName = "UNABLE_DELETE_HOLDER_WITH_NAME";
        _this.textPromptDeleteBody = "PROMPT_DELETE_BODY";
        _this.textMaxLength = "MAX_LENGTH_ERROR";
        _this.requiredMessage = "PLEASE_ENTER_NAME";
        _this.requiredValue = "PLEASE_ENTER_PRIORITY";
        _this.duplicatedHolderName = "DUPLICATE_HOLDER_NAME";
        _this.duplicatePriorityValue = "DUPLICATE_HOLDER_PRIORITY";
        _this.priorityPatternRule = "FIXTURE_HOLDER_PRIORITY_RULE";
        _this.errorMessages = {
            "required": function () { return _this.requiredMessage; },
            "maxlength": function (params) { return IpsString.Format(_this.textMaxLength, params.requiredLength); },
            "appDuplicateValidator": function () { return _this.duplicatedHolderName; },
            "pattern": function () { return _this.priorityPatternRule; }
        };
        _this.canDeleteErrorList = [];
        _this.dataService = fixtureGroupService;
        var id = transition.params().id === "0" ? "" : transition.params().id;
        var pageTitle = id === "" ? "CREATE_FIXTURE_GROUP" : "EDIT_FIXTURE_GROUP";
        _this.breadCrumbLabel = pageTitle;
        _this.pageTitleService.setTitle([pageTitle]);
        _this.myFixtureGroup = new FixtureGroupInternal("holderHtmlName" + _this.indexer++, "holderHtmlPriority" + _this.indexerTwo++);
        //If we got an ID to load, load it.
        if (id > 0) {
            //Initial call to populate screen on load
            _this.getFixtureGroup(id);
        }
        else if (id === "") {
            _this.loaded = true;
        }
        _this.holderLabel = "HOLDER_NAMES";
        return _this;
    }
    FixtureGroupEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateText();
        this.storeFrontCategoryList = null;
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        _super.prototype.setFormPristine.call(this, this.fgForm, 0);
        this.getStoreFrontCategoryList();
    };
    FixtureGroupEditComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(["UNABLE_DELETE_HOLDER_WITH_NAME", "PROMPT_DELETE_BODY", "MAX_LENGTH_ERROR", "PLEASE_ENTER_NAME", "DUPLICATE_HOLDER_NAME", "PLEASE_ENTER_PRIORITY", "DUPLICATE_HOLDER_PRIORITY", "FIXTURE_HOLDER_PRIORITY_RULE"]).subscribe(function (res) {
            _this.textUnableDeleteHolderWithName = res["UNABLE_DELETE_HOLDER_WITH_NAME"];
            _this.textPromptDeleteBody = res["PROMPT_DELETE_BODY"];
            _this.textMaxLength = res["MAX_LENGTH_ERROR"];
            _this.requiredMessage = res["PLEASE_ENTER_NAME"];
            _this.duplicatedHolderName = res["DUPLICATE_HOLDER_NAME"];
            _this.requiredValue = res["PLEASE_ENTER_PRIORITY"];
            _this.duplicatePriorityValue = res["DUPLICATE_HOLDER_PRIORITY"];
            _this.priorityPatternRule = res["FIXTURE_HOLDER_PRIORITY_RULE"];
        });
    };
    // Private functions
    FixtureGroupEditComponent.prototype.findHolder = function (inputName) {
        for (var i = 0; i < this.myFixtureGroup.Holders.length; i++) {
            var holder = this.myFixtureGroup.Holders[i];
            if (holder.inputName === inputName) {
                return holder;
            }
        }
    };
    FixtureGroupEditComponent.prototype.deleteHolder = function (inputName) {
        var arr = this.myFixtureGroup.Holders;
        var deleted = false;
        for (var i = 0; i < arr.length; i++) {
            var holder = arr[i];
            if (holder.inputName === inputName) {
                arr.splice(i, 1);
                deleted = true;
                break;
            }
        }
        return deleted;
    };
    FixtureGroupEditComponent.prototype.checkHolderErrors = function (holders) {
        var msg = "";
        for (var i = 0; i < holders.length; i++) {
            if (holders[i].Result === 3) {
                msg += " " + holders[i].Name + ",";
            }
        }
        if (msg.length) {
            throw new Error("" + this.textUnableDeleteHolderWithName + msg.slice(0, -1));
        }
    };
    FixtureGroupEditComponent.prototype.initHoldersList = function (holderList) {
        if (holderList) {
            // Loop through each item in the array and set the input name.  We need the inputName
            // in the gui only for validaiton.
            for (var i = 0; i < holderList.length; i++) {
                // Need to use a long inputName for html Id, just a number doesn"t work well for validation.
                holderList[i].inputName = "holderHtmlName" + this.indexer++;
                holderList[i].inputPriority = "holderHtmlPriority" + this.indexerTwo++;
            }
            // Add one empty holder if we don"t have any yet
            if (holderList.length === 0) {
                holderList.push(new HolderInternal("holderHtmlName" + this.indexer++, "holderHtmlPriority" + this.indexerTwo++));
            }
        }
    };
    FixtureGroupEditComponent.prototype.reloadHolderListAfterSave = function (newHolderList) {
        this.myFixtureGroup.Holders = [];
        for (var i = 0; i < newHolderList.length; i++) {
            var item = newHolderList[i];
            item.inputName = "holderHtmlName" + this.indexer++;
            item.inputPriority = "holderHtmlPriority" + this.indexerTwo++;
            this.myFixtureGroup.Holders.push(item);
        }
        this.myFixtureGroup.Holders.sort(function (a, b) { return (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : ((b.Name.toLowerCase() > a.Name.toLowerCase()) ? -1 : 0); });
        this.checkHolderErrors(newHolderList);
    };
    FixtureGroupEditComponent.prototype.saveFixtureGroupCallback = function () {
        var _this = this;
        var id = this.myFixtureGroup.Id;
        //add business identity to fixturegroup/holders
        for (var i = 0; i < this.myFixtureGroup.Holders.length; i++) {
            this.myFixtureGroup.Holders[i].BusinessIdentity = this.activeProfileService.businessIdentity;
        }
        this.myFixtureGroup.BusinessIdentity = this.activeProfileService.businessIdentity;
        if (id) {
            return this.dataService.put(this.myFixtureGroup).then(function (response) {
                _this.reloadHolderListAfterSave(response.Holders);
                _this.fgForm.form.markAsPristine();
            });
        }
        else {
            return this.dataService.post(this.myFixtureGroup).then(function (response) {
                _this.reloadHolderListAfterSave(response.Holders);
                _this.fgForm.form.markAsPristine();
                _this.myFixtureGroup = response;
                _this.breadCrumbLabel = "EDIT_FIXTURE_GROUP";
                _this.pageTitleService.setTitle([_this.breadCrumbLabel]);
            });
        }
    };
    FixtureGroupEditComponent.prototype.deleteFixtureGroup = function () {
        var id = this.myFixtureGroup.Id;
        return this.dataService.delete(id).then(function (response) {
        });
    };
    FixtureGroupEditComponent.prototype.getStoreFrontCategoryList = function () {
        var _this = this;
        return this.storeFrontCategoryService.getList()
            .then(function (response) {
            _this.storeFrontCategoryList = response;
        });
    };
    //
    // Public Functions
    //
    FixtureGroupEditComponent.prototype.getFixtureGroup = function (id) {
        var _this = this;
        this.loaded = false;
        var canDelete = this.dataService.deleteCheck(id.toString())
            .then(function (response) {
            _this.canDeleteErrorList = response;
        })
            .catch(function (response) {
            _this.canDeleteErrorList = ["-ERROR-"];
        });
        this.promise = this.dataService.get(id).then(function (response) {
            Object.assign(_this.myFixtureGroup, response);
            var arr = _this.myFixtureGroup.Holders;
            _this.initHoldersList(arr);
            _this.holderLabel = arr.length > 1 ? "HOLDER_NAMES" : "HOLDER_NAME";
        });
        Promise.all([canDelete, this.promise]).then(function () {
            _this.loaded = true;
        });
    };
    FixtureGroupEditComponent.prototype.addHolderIfNoEmpty = function () {
        var arr = this.myFixtureGroup.Holders;
        var spaceFound = false;
        // Loop through each item in the array, and only add one if all are NOT empty
        for (var i = 0; i < arr.length; i++) {
            if (!arr[i].Name || arr[i].Name === "") {
                spaceFound = true;
                break;
            }
        }
        if (!spaceFound) {
            arr.push(new HolderInternal("holderHtmlName" + this.indexer++, "holderHtmlPriority" + this.indexerTwo++));
        }
    };
    FixtureGroupEditComponent.prototype.addHolder = function () {
        var arr = this.myFixtureGroup.Holders;
        arr.push(new HolderInternal("holderHtmlName" + this.indexer++, "holderHtmlPriority" + this.indexerTwo++));
        this.holderLabel = arr.length > 1 ? "HOLDER_NAMES" : "HOLDER_NAME";
    };
    FixtureGroupEditComponent.prototype.removeHolder = function (inputName) {
        var _this = this;
        if (this.myFixtureGroup.Holders.length === 1) {
            this.ipsMessage.error("AT_LEAST_ONE_HOLDER_REQUIRED");
            return;
        }
        var holder = this.findHolder(inputName);
        if (holder && holder.Id > 0) {
            if (holder.HasFixtureReference) {
                this.ipsMessage.error("UNABLE_DELETE_HOLDER");
            }
            else {
                this.ipsMessage.confirm(undefined, undefined)
                    .then(function (result) {
                    if (result) {
                        //do something with the result
                        console.log("deleting holder");
                        if (_this.deleteHolder(inputName)) {
                            // Set as dirty since we removed a holder.
                            _this.fgForm.form.markAsDirty();
                        }
                    }
                })
                    .catch(function () {
                    // rejection
                });
            }
        }
        else {
            if (this.deleteHolder(inputName)) {
                // Set as dirty since we removed a holder.
                this.fgForm.form.markAsDirty();
            }
        }
        this.holderLabel = this.myFixtureGroup.Holders.length > 1 ? "HOLDER_NAMES" : "HOLDER_NAME";
    };
    FixtureGroupEditComponent.prototype.deleteFixtureGroupPrompt = function () {
        var _this = this;
        if (this.loaded && this.canDeleteErrorList.length > 0) {
            this.ipsMessage.error("Unable to delete! Record is begin used in the following Market Groups:\r\n" + this.canDeleteErrorList.join("\r\n"));
            return null;
        }
        if (this.myFixtureGroup.HasFixtureReference) {
            this.ipsMessage.error("UNABLE_DELETE_FIXTURE_GROUP");
        }
        else {
            var translated = IpsString.Format(this.textPromptDeleteBody, this.myFixtureGroup.Name);
            this.ipsMessage.confirmDelete({ body: translated, workFunction: function () { return _this.deleteFixtureGroup(); }, progressMessage: "DELETING" }, undefined)
                .then(function (result) {
                if (result) {
                    _this.stateService.go("main.storeProfileFixtureGroup.search");
                }
            })
                .catch(function () {
                // rejection
            });
        }
    };
    FixtureGroupEditComponent.prototype.saveFixtureGroupPrompt = function (redirect) {
        var _this = this;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: function () { return _this.saveFixtureGroupCallback(); }, progressMessage: "SAVING" }, undefined).then(function (result) {
            if (result && redirect) {
                _this.stateService.go("main.storeProfileFixtureGroup.search");
            }
        });
    };
    FixtureGroupEditComponent.prototype.hasHolderWithText = function () {
        var hasText = false;
        var arr = this.myFixtureGroup.Holders;
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].Name && arr[i].Name.length > 0) {
                hasText = true;
                break;
            }
        }
        return hasText;
    };
    FixtureGroupEditComponent.prototype.validationMaxLength = function (value) {
        return IpsString.Format(this.textMaxLength, value);
    };
    FixtureGroupEditComponent.prototype.getErrorMessage = function (key) {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "holdername":
                    msgs["required"] = function () { return _this.requiredMessage; };
                    msgs["appDuplicateValidator"] = function () { return _this.duplicatedHolderName; };
                    break;
                case "holderpriority":
                    msgs["required"] = function () { return _this.requiredValue; };
                    msgs["appDuplicateValidator"] = function () { return _this.duplicatePriorityValue; };
                    msgs["pattern"] = function () { return _this.priorityPatternRule; };
                    break;
                default:
            }
        }
        return msgs;
    };
    return FixtureGroupEditComponent;
}(TemplateFormBase));
export { FixtureGroupEditComponent };
