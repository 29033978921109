<div [formGroup]="parent">
    <div formArrayName="placementElementsFrmArr">
        <ng-container *ngIf="shouldShowPlacements()">
            <div *ngFor="let elementGroup of placementElementsFrmArr.controls; let groupIndex=index" [formGroupName]="groupIndex" attr.stickyHeader="{{elementGroup.get('DisplayName').value}}">
                <div class="card ips-shadow mb-4">
                    <div class="card-body ips-green-bg">
                        <div class="form-group col-12 pb-0 d-flex align-items-center px-0 mb-1">
                            <label translate class="flex-grow-1 required">ELEMENT</label>
                            <button type="button" class=" button-no-style" (click)="removePlacement(groupIndex)" ngbTooltip="{{'REMOVE_ELEMENT' | translate}}" placement="bottom-right"><i class="fa fa-remove fa-lg remove-icon"></i></button>
                        </div>
                        <div class=" form-group col-lg-6 pl-0">
                            <textarea formControlName="DisplayName" class="form-control form-control-lg" rows="1" ipsAutoGrow readonly></textarea>
                        </div>
                        <!--</div>-->
                        <div class="form-group mb-0">
                            <label translate>MESSAGES</label>
                            <div formArrayName="MessageElementPlacements">
                                <div *ngFor="let placement of elementGroup.controls.MessageElementPlacements.controls; let i=index" [formGroupName]="i" attr.stickyHeader="{{placement.value.MessageName}}">
                                    <!--Message Placement Element Row-->
                                    <div class="card ips-shadow mb-4">
                                        <div class="card-body">

                                            <!--Message Name-->
                                            <!--Message Name-->
                                            <div class="form-row ng-container">
                                                <div class="campaign-message-icon icon ips-icon-30 mr-2 fixture-message-icon"></div>
                                                <div class="flex-grow-1 mb-1 col-md-10">{{placement.value.MessageName}}</div>
                                            </div>
                                            <hr class="mt-0" />
                                            <div class="form-row mt-2 mb-1">
                                                <div class="col-12 col-lg-6 form-group d-flex align-items-end">
                                                    <div class="input-group pl-0">
                                                        <input type="text" class="form-control form-control-lg no-background" readonly value="{{'PRINT_MESSAGE' | translate}}">
                                                        <div class="input-group-append">
                                                            <button class="btn btn-default" (click)="togglePrintMessages(placement, true)" [ngClass]="{'active': placement.value.Selected }" translate>YES</button>
                                                            <button class="btn btn-default" (click)="togglePrintMessages(placement, false)" [ngClass]="{'active': !placement.value.Selected }" translate>NO</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row mt-2 mb-1">
                                                <div class="col-12 col-lg-6 form-group">
                                                    <label translate>CUSTOMER_ITEM_CODE</label>
                                                    <input type="text"
                                                           class="form-control input form-control-lg px-1 text-center"
                                                           formControlName="CustomerItemCode"
                                                           name="{{'custItemCode' + placement.value.IdName}}">
                                                    <ips-show-errors [control]="placement.get('CustomerItemCode')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                                </div>
                                                <div class="col-12 col-lg-6 form-group">
                                                    <label translate>CATEGORY_CODE</label>
                                                    <input type="text"
                                                           class="form-control input form-control-lg px-1 text-center"
                                                           formControlName="ElementDetailCategoryCode" readonly>
                                                </div>
                                                <div class="col-12 col-lg-6 form-group" *ngIf="!enableSignPlanLayout">
                                                    <div class="form-group mb-0">
                                                        <label translate>SPACE</label>
                                                        <button class="btn btn-default btn-lg btn-block" (click)="chooseSpace(placement)">{{ getSpaceButtonLabel(placement) }}</button>
                                                        <div class="text-right">
                                                            <button class="btn btn-link btn-sm text-info" (click)="clearSpaceSelection(placement)" [ngClass]="{ 'invisible': !(placement.value.Spaces && placement.value.Spaces.length) }">
                                                                <span class="fa fa-remove"></span> Clear Space Selection(s)
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="form-row" *ngIf="enableSignPlanLayout">
                                                <div class="col-12 col-lg-6">
                                                    <div class="form-group mb-0">
                                                        <label translate>SPACE</label>
                                                        <button class="btn btn-default btn-lg btn-block" [disabled]="!!placement.value.HolderId" (click)="chooseSpace(placement)">{{ getSpaceButtonLabel(placement) }}</button>
                                                        <div class="text-right">
                                                            <button class="btn btn-link btn-sm text-info" [disabled]="!!placement.value.HolderId" (click)="clearSpaceSelection(placement)" [ngClass]="{ 'invisible': !(placement.value.Spaces && placement.value.Spaces.length) }">
                                                                <span class="fa fa-remove"></span> Clear Space Selection(s)
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Sign Plan Message</label>
                                                        <input type="text" [readonly]="!!placement.value.HolderId" class="form-control form-control-lg" formControlName="SignPlanMessage" />
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Sign Plan Width</label>
                                                        <select [disabled]="!!(placement.value.HolderId)" class="form-control form-control-lg" formControlName="SignPlanWidth">
                                                            <option *ngFor="let option of signPlanWidthOptions" [ngValue]="option.value">{{ option.key }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6 form-group">
                                                    <div class="form-group mb-0">
                                                        <label>Place On Holder</label>
                                                        <button class="btn btn-block btn-default btn-lg" (click)="selectElementHolder(placement)">
                                                            {{ placement.value.HolderId ? (placement.value.FixtureGroupName + ' - ' + placement.value.HolderName) : 'Choose Holder' }}
                                                        </button>
                                                        <div class="text-right">
                                                            <button class="btn btn-link btn-sm text-info" (click)="clearHolderSelection(placement) " [ngClass]="{ 'invisible': !placement.value.HolderId }">
                                                                <span class="fa fa-remove"></span> Clear Holder Selection
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="form-group" *ngIf="placement.value.HolderId">
                                                        <label>Location</label>
                                                        <select formControlName="PlacementLocation" class="form-control form-control-lg">
                                                            <option value="AboveLeft">Outside - Top Left</option>
                                                            <option value="AboveMiddle">Outside - Top Center</option>
                                                            <option value="AboveRight">Outside - Top Right</option>
                                                            <option value="LeftCenter">Outside - Middle Left</option>
                                                            <option value="RightCenter">Outside - Middle Right</option>
                                                            <option value="BelowLeft">Outside - Bottom Left</option>
                                                            <option value="BelowMiddle">Outside - Bottom Center</option>
                                                            <option value="BelowRight">Outside - Bottom Right</option>
                                                            <option value="TopLeft">Inside - Top Left</option>
                                                            <option value="TopMiddle">Inside - Top Center</option>
                                                            <option value="TopRight">Inside - Top Right</option>
                                                            <option value="MiddleLeft">Inside - Middle Left</option>
                                                            <option value="MiddleMiddle">Inside - Middle Center</option>
                                                            <option value="MiddleRight">Inside - Middle Right</option>
                                                            <option value="BottomLeft">Inside - Bottom Left</option>
                                                            <option value="BottomMiddle">Inside - Bottom Center</option>
                                                            <option value="BottomRight">Inside - Bottom Right</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div [hidden]="!placement.value.Selected">
                                        <!--QTY Handling - Main-->
                                        <div class="form-row d-none d-lg-flex">
                                            <div class="form-group col-lg-4 text-left mb-1"><label class="ips-label-double justify-content-left required ml-4" translate>QUANTITY_HANDLING</label></div>
                                            <div class="form-group col-lg-2 text-center mb-0"><label class="ips-label-double justify-content-center mx-0" translate>LOCATION_BALANCE</label></div>
                                            <div class="form-group col-lg-2 text-center mb-0"><label class="ips-label-double justify-content-center mx-0" translate>QTY</label></div>
                                            <div class="form-group col-lg-2 text-center mb-0"><label class="ips-label-double justify-content-center mx-0" translate>FULFILLMENT_QTY</label></div>
                                            <div class="form-group col-lg-2 text-center mb-0"><label class="ips-label-double justify-content-center mx-0" translate>FINAL_QTY</label></div>
                                        </div>

                                        <div class="form-row">
                                            <div class="form-group col-12 col-sm col-md-4 col-lg-4 text-center">
                                                <label class="d-lg-none ips-label-double justify-content-center required mx-0" for="QuantityHandling" translate>QUANTITY_HANDLING</label>
                                                <div class="input-group ips-textarea">
                                                    <textarea formControlName="TempDisplayName" name="{{placement.value.IdName}}" class="form-control form-control-lg ips-no-resize ips-bg-white" rows="1" ipsAutoGrow readonly></textarea>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text fa fa-exclamation-triangle text-warning" *ngIf="placement.value.PendingAssignmentCount > 0" ngbTooltip="{{getPendingLocationWarningString(placement.value.PendingAssignmentCount)}}" placement="bottom"></span>
                                                        <button class="btn btn-default last" (click)="showQuantityHandlingModal(placement, tSearch)" ngbTooltip="{{'SEARCH_LOCATION_GROUP_BUTTON_INFO' | translate}}" placement="bottom" #tSearch="ngbTooltip"><i class="fa fa-search fa-lg"></i></button>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--Location Balance - Main-->
                                            <div class="form-group w-col-sm-15 col-md-2 col-lg-2 text-center" *ngIf="!isLocationGroupQtyHandling(placement.value)">
                                                <label class="d-lg-none ips-label-double mx-0" translate>LOCATION_BALANCE</label>
                                                <button class="btn btn-default btn-lg btn-block px-0" type="button"
                                                        (click)="showLocationBalanceModal(t, placement, placement.value.TempMessageId, placement.value.LocationCount)">
                                                    <div class="mr-2 ml-2" placement="bottom" ngbTooltip="{{'TOOLTIP_PLACEMENT_LOCATIONS' | translate}}" #t="ngbTooltip">{{placement.value.LocationCount | number}}</div>
                                                </button>
                                            </div>
                                            <!--Qty - Main-->
                                            <div class="form-group col-6 w-col-sm-15 col-md-2 col-lg-2 text-center" [ngClass]="{'d-none d-sm-block': isLocationGroupQtyHandling(placement.value)}">
                                                <label class="d-lg-none ips-label-double justify-content-center mx-0" for="Qty" translate>QTY</label>
                                                <div *ngIf="!isLocationGroupQtyHandling(placement.value)">
                                                    <input type="number" class="form-control form-control-lg text-center"
                                                           min="0"
                                                           name="{{'qty' + placement.value.IdName}}"
                                                           placement="bottom" ngbTooltip="{{'TOOLTIP_ELEMENT_PLACEMENT_QTY' | translate}}"
                                                           formControlName="Qty"
                                                           [ngClass]="{'placement-qty-warning': hasQtyWarning(placement.get('Qty'))}" />
                                                    <ips-show-errors [control]="placement.get('Qty')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                                </div>
                                            </div>

                                            <!--Fulfillment QTY - Main-->
                                            <div class="form-group col-6 w-col-sm-20 col-md-2 col-lg-2 text-center" *ngIf="!isLocationGroupQtyHandling(placement.value)">
                                                <label class="d-lg-none ips-label-double justify-content-center mx-0" for="FulfillmentOperand" translate>FULFILLMENT_QTY</label>
                                                <div class="input-group ips-textarea">
                                                    <input type="number" class="form-control form-control-lg text-center px-1"
                                                           name="{{'fulQty' + placement.value.IdName}}"
                                                           placement="bottom" ngbTooltip="{{'TOOLTIP_PLACEMENT_FULFILL' | translate}}"
                                                           formControlName="FulfillmentOperand" />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-default last" (click)="fulfillmentOperatorClick(placement)"
                                                                placement="bottom" ngbTooltip="{{'TOOLTIP_PLACEMENT_FULFILL_TYPE' | translate}}">
                                                            <i class="fa {{getOperatorIconName(placement.value.FulfillmentOperator)}}" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <ips-show-errors [control]="placement.get('FulfillmentOperand')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                            </div>
                                            <!--Final QTY - Main-->
                                            <div class="form-group col-6 w-col-sm-15 col-md-2 col-lg-2 text-center" *ngIf="!isLocationGroupQtyHandling(placement.value)">
                                                <label class="d-lg-none ips-label-double justify-content-center mx-0" translate>FINAL_QTY</label>
                                                <span class="form-control input form-control-lg placement-label px-1" [ngClass]="{'placement-final-qty-warning': placement.value.FinalQuantity === 0}">{{placement.value.FinalQuantity | number}}</span>
                                            </div>

                                        </div>
                                        <!--Main msg element subGroups-->
                                        <div *ngIf="placement.value.Selected && isLocationGroupQtyHandling(placement.value)" formArrayName="PlacementElementDetails">
                                            <div *ngFor="let elementDetail of placement.controls.PlacementElementDetails.controls; let j=index" [formGroupName]="j">
                                                <div class="form-row">
                                                    <!--LocationSubGroup Name - Main subGroup-->
                                                    <div class="form-group col-12 col-sm col-md-4 col-lg-4">
                                                        <span class="form-control input form-control-lg ips-add-bar border border-white">
                                                            {{elementDetail.value.LocationSubGroupName}}
                                                        </span>
                                                    </div>
                                                    <!--Location Balance - Main subGroup-->
                                                    <div class="form-group w-col-sm-15 col-md-2 col-lg-2">
                                                        <button class="btn btn-default btn-lg btn-block btn-thin-padding mx-0" type="button"
                                                                (click)="showLocationBalanceModal(t, placement, placement.value.TempMessageId, elementDetail.value.LocationCount, elementDetail.value.LocationSubGroupId)">
                                                            <div class="mr-2 ml-2" placement="bottom" ngbTooltip="{{'TOOLTIP_PLACEMENT_LOCATIONS' | translate}}" #t="ngbTooltip">{{elementDetail.value.LocationCount | number}}</div>
                                                        </button>
                                                    </div>
                                                    <!--QTY - Main subGroup-->
                                                    <div class="form-group col-6 w-col-sm-15 col-md-2 col-lg-2">
                                                        <input type="number" class="form-control form-control-lg input-full text-center"
                                                               name="{{'qtyDetail' + placement.value.IdName + elementDetail.value.LocationSubGroupId}}"
                                                               placement="bottom" ngbTooltip="{{'TOOLTIP_ELEMENT_PLACEMENT_QTY' | translate}}"
                                                               formControlName="Quantity"
                                                               [ngClass]="{'placement-qty-warning': hasQtyWarning(elementDetail.get('Quantity'))}" />
                                                        <ips-show-errors [control]="elementDetail.get('Quantity')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                                    </div>
                                                    <!--Final QTY - Main subGroup-->
                                                    <div class="form-group col-6 w-col-sm-15 ips-offset-sm-20 col-md-2 offset-md-2 col-lg-2 offset-lg-2">
                                                        <span class="form-control input form-control-lg placement-label px-1" [ngClass]="{'placement-final-qty-warning': elementDetail.value.FinalQuantity === 0}">{{elementDetail.value.FinalQuantity | number}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row" *ngIf="isLocationGroupQtyHandling(placement.value)">
                                            <div class="col-md-8"></div>
                                            <!--Fulfillment QTY - Main-->
                                            <div class="form-group col-6 w-col-sm-20 col-md-2 col-lg-2 text-center">
                                                <label class="d-lg-none ips-label-double justify-content-center mx-0" for="FulfillmentOperand" translate>FULFILLMENT_QTY</label>
                                                <div class="input-group ips-textarea">
                                                    <input type="number" class="form-control form-control-lg text-center px-1"
                                                           name="{{'fulQty' + placement.value.IdName}}"
                                                           placement="bottom" ngbTooltip="{{'TOOLTIP_PLACEMENT_FULFILL' | translate}}"
                                                           formControlName="FulfillmentOperand" />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-default last" (click)="fulfillmentOperatorClick(placement)"
                                                                placement="bottom" ngbTooltip="{{'TOOLTIP_PLACEMENT_FULFILL_TYPE' | translate}}">
                                                            <i class="fa {{getOperatorIconName(placement.value.FulfillmentOperator)}}" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <ips-show-errors [control]="placement.get('FulfillmentOperand')" [errorMessages]="getErrorMessages()"></ips-show-errors>
                                            </div>
                                            <!--Final QTY - Main-->
                                            <div class="form-group col-6 w-col-sm-15 col-md-2 col-lg-2 text-center">
                                                <label class="d-lg-none ips-label-double justify-content-center mx-0" translate>FINAL_QTY</label>
                                                <span class="form-control input form-control-lg placement-label px-1" [ngClass]="{'placement-final-qty-warning': placement.value.FinalQuantity === 0}">{{placement.value.FinalQuantity | number}}</span>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12">
                                                <app-custom-data-field-container area="PromotionPlacement" subArea="PlacementElement" [linkId]="placement.get('Id').value" [parent]="placement" minFieldSize="col-6"></app-custom-data-field-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

