import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-store-profile-element",
    templateUrl: "./store-profile-element.component.html",
    styleUrls: ["./store-profile-element.component.scss"]
})
export class StoreProfileElementComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
