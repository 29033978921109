<div>
    <button type="button" class="fa fa-remove ips-close" aria-label="Close"
            (click)="close()" data-dismiss="modal"></button>
</div>
<div class="ips-modal-header ips-modal-dark-header">
    <h4 class="modal-title m-0" translate>SELECT_SHIPPING_ADDRESSES</h4>
</div>
<div class="modal-body">
    <div class="ips-ls-long-text-wrap list-group">
        <div class="modal-scrollable list-search-infinite-container">
            <div class="mb-3" translate>SELECT_RECOGNIZED_USPS_ADDRESS</div>
            <div *ngFor="let address of addresses; let i = index"
                 class="card ips-shadow mb-2 subgroup-card d-flex flex-row align-items-center clicker"
                 [ngClass]="{ 'ips-checked-card': i === selected }">
                <div class="p-2 pl-3 flex-grow-1" (click)="selectAddress(i, false);">
                    {{address}}
                </div>
                <div *ngIf="i === selected" class="p-2">
                    <i class="fa fa-check fa-2x pr-3 location-check clicker"></i>
                </div>
            </div>
            <hr class="mt-4" />
            <div class="mb-3 mt-3" translate>SELECT_ENTERED_ADDRESS</div>
            <div class="card ips-shadow mb-2 subgroup-card d-flex flex-row align-items-center clicker border-warning">
                <div class="p-3 flex-grow-1" (click)="selectInvalidAddress()">
                    {{formatAddressString(invalidAddress)}}
                    <small class="d-block alert alert-warning mb-0 px-2 mt-2">
                        <span class="fa fa-fw fa-exclamation-triangle text-warning"></span> {{ 'WARNING_ORIGINAL_ENTERED_ADDRESS' | translate }}
                    </small>
                </div>
            </div>
        </div>
    </div>
</div>
