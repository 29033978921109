import { ElementRef, Renderer2, OnInit, EventEmitter, OnChanges, SimpleChanges, OnDestroy, AfterViewInit } from "@angular/core";
import { fromEvent } from "rxjs";
import { Position } from "../model/position";
import { HelperBlock } from "../widgets/helper-block";
var AngularDraggableDirective = /** @class */ (function () {
    function AngularDraggableDirective(el, renderer) {
        this.el = el;
        this.renderer = renderer;
        this.allowDrag = true;
        this.moving = false;
        this.orignal = null;
        this.oldTrans = new Position(0, 0);
        this.tempTrans = new Position(0, 0);
        this.currTrans = new Position(0, 0);
        this.oldZIndex = "";
        this._zIndex = "";
        this.needTransform = false;
        this.originalOffsetLeft = 0;
        this.originalOffsetTop = 0;
        this.moved = false;
        this.draggingSub = null;
        /**
         * Bugfix: iFrames, and context unrelated elements block all events, and are unusable
         * https://github.com/xieziyu/angular2-draggable/issues/84
         */
        this._helperBlock = null;
        this.started = new EventEmitter();
        this.stopped = new EventEmitter();
        this.edge = new EventEmitter();
        /** List of allowed out of bounds edges **/
        this.outOfBounds = {
            top: false,
            right: false,
            bottom: false,
            left: false
        };
        /** Round the position to nearest grid */
        this.gridSize = 1;
        /** Whether to limit the element stay in the bounds */
        this.inBounds = false;
        /** Whether the element should use it's previous drag position on a new drag event. */
        this.trackPosition = true;
        /** Input css scale transform of element so translations are correct */
        this.scale = 1;
        /** Whether to prevent default event */
        this.preventDefaultEvent = false;
        /** Set initial position by offsets */
        this.position = { x: 0, y: 0 };
        /** Lock axis: 'x' or 'y' */
        this.lockAxis = null;
        /** Emit position offsets when moving */
        this.movingOffset = new EventEmitter();
        /** Emit position offsets when put back */
        this.endOffset = new EventEmitter();
        this._helperBlock = new HelperBlock(el.nativeElement, renderer);
    }
    Object.defineProperty(AngularDraggableDirective.prototype, "zIndex", {
        /** Set z-index when not dragging */
        set: function (setting) {
            this.renderer.setStyle(this.el.nativeElement, "z-index", setting);
            this._zIndex = setting;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AngularDraggableDirective.prototype, "appNgDraggable", {
        set: function (setting) {
            if (setting !== undefined && setting !== null && setting !== "") {
                this.allowDrag = !!setting;
                var element = this.getDragEl();
                if (this.allowDrag) {
                    this.renderer.addClass(element, "ng-draggable");
                }
                else {
                    this.putBack();
                    this.renderer.removeClass(element, "ng-draggable");
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    AngularDraggableDirective.prototype.ngOnInit = function () {
        if (this.allowDrag) {
            var element = this.getDragEl();
            this.renderer.addClass(element, "ng-draggable");
            this.originalOffsetLeft = element.offsetLeft;
            this.originalOffsetTop = element.offsetTop;
        }
        this.resetPosition();
    };
    AngularDraggableDirective.prototype.ngOnDestroy = function () {
        this.bounds = null;
        this.handle = null;
        this.orignal = null;
        this.oldTrans = null;
        this.tempTrans = null;
        this.currTrans = null;
        this._helperBlock.dispose();
        this._helperBlock = null;
        if (this.draggingSub) {
            this.draggingSub.unsubscribe();
        }
    };
    AngularDraggableDirective.prototype.ngOnChanges = function (changes) {
        if (changes["position"] && !changes["position"].isFirstChange()) {
            var p = changes["position"].currentValue;
            if (!this.moving) {
                if (Position.isIPosition(p)) {
                    this.oldTrans.set(p);
                }
                else {
                    this.oldTrans.reset();
                }
                this.transform();
            }
            else {
                this.needTransform = true;
            }
        }
    };
    AngularDraggableDirective.prototype.ngAfterViewInit = function () {
        if (this.inBounds) {
            this.boundsCheck();
            this.oldTrans.add(this.tempTrans);
            this.tempTrans.reset();
        }
    };
    AngularDraggableDirective.prototype.getDragEl = function () {
        return this.handle ? this.handle : this.el.nativeElement;
    };
    AngularDraggableDirective.prototype.resetPosition = function () {
        if (Position.isIPosition(this.position)) {
            this.oldTrans.set(this.position);
        }
        else {
            this.oldTrans.reset();
        }
        this.tempTrans.reset();
        this.transform();
    };
    AngularDraggableDirective.prototype.moveTo = function (p) {
        if (this.orignal) {
            p.subtract(this.orignal);
            this.tempTrans.set(p);
            this.tempTrans.divide(this.scale);
            this.transform();
            if (this.bounds) {
                this.edge.emit(this.boundsCheck());
            }
            this.movingOffset.emit(this.currTrans.value);
        }
    };
    AngularDraggableDirective.prototype.transform = function () {
        var translateX = this.tempTrans.x; // + this.oldTrans.x;
        var translateY = this.tempTrans.y; // + this.oldTrans.y;
        if (this.lockAxis === "x") {
            translateX = this.oldTrans.x;
            this.tempTrans.x = 0;
        }
        else if (this.lockAxis === "y") {
            translateY = this.oldTrans.y;
            this.tempTrans.y = 0;
        }
        // Snap to grid: by grid size
        if (this.gridSize > 1) {
            translateX = Math.round(translateX / this.gridSize) * this.gridSize;
            translateY = Math.round(translateY / this.gridSize) * this.gridSize;
        }
        // console.log("translate: ", { x: translateX, y: translateY });
        var value = "translate(" + Math.round(translateX) + "px, " + Math.round(translateY) + "px)";
        // console.log("temp trans: ", this.tempTrans);
        // console.log("old trans: ", this.oldTrans);
        // use absolute positioning along with top and left properties
        if (this.moved && (this.tempTrans.x || this.tempTrans.y)) {
            this.renderer.setStyle(this.el.nativeElement, "position", "absolute");
            this.renderer.setStyle(this.el.nativeElement, "top", (translateY + this.originalOffsetTop) + "px");
            this.renderer.setStyle(this.el.nativeElement, "left", (translateX + this.originalOffsetLeft) + "px");
        }
        // console.log("native element: ", this.el.nativeElement);
        // console.log("original offset: ", { left: this.originalOffsetLeft, top: this.originalOffsetTop });
        //this.renderer.setStyle(this.el.nativeElement, 'transform', value);
        //this.renderer.setStyle(this.el.nativeElement, '-webkit-transform', value);
        //this.renderer.setStyle(this.el.nativeElement, '-ms-transform', value);
        //this.renderer.setStyle(this.el.nativeElement, '-moz-transform', value);
        //this.renderer.setStyle(this.el.nativeElement, '-o-transform', value);
        // save current position
        //this.currTrans.x = translateX;
        //this.currTrans.y = translateY;
        this.removeRange();
    };
    AngularDraggableDirective.prototype.removeRange = function () {
        var s = window.getSelection();
        for (var i = 0; i < s.rangeCount; i++) {
            s.removeRange(s.getRangeAt(i));
        }
    };
    AngularDraggableDirective.prototype.pickUp = function () {
        // get old z-index:
        this.oldZIndex = this.el.nativeElement.style.zIndex ? this.el.nativeElement.style.zIndex : "";
        if (window) {
            this.oldZIndex = window.getComputedStyle(this.el.nativeElement, null).getPropertyValue("z-index");
        }
        if (this.zIndexMoving) {
            this.renderer.setStyle(this.el.nativeElement, "z-index", this.zIndexMoving);
        }
        if (!this.moving) {
            this.started.emit(this.el.nativeElement);
            this.moving = true;
            var element = this.getDragEl();
            this.renderer.addClass(element, "ng-dragging");
            this.originalOffsetLeft = element.offsetLeft;
            this.originalOffsetTop = element.offsetTop;
            /**
             * Fix performance issue:
             * https://github.com/xieziyu/angular2-draggable/issues/112
             */
            this.subscribeEvents();
        }
    };
    AngularDraggableDirective.prototype.subscribeEvents = function () {
        var _this = this;
        this.draggingSub = fromEvent(document, "mousemove", { passive: false }).subscribe(function (event) { return _this.onMouseMove(event); });
        this.draggingSub.add(fromEvent(document, "touchmove", { passive: false }).subscribe(function (event) { return _this.onMouseMove(event); }));
        this.draggingSub.add(fromEvent(document, "mouseup", { passive: false }).subscribe(function () { return _this.putBack(); }));
        // checking if browser is IE or Edge - https://github.com/xieziyu/angular2-draggable/issues/153
        var isIEOrEdge = /msie\s|trident\//i.test(window.navigator.userAgent);
        if (!isIEOrEdge) {
            this.draggingSub.add(fromEvent(document, "mouseleave", { passive: false }).subscribe(function () { return _this.putBack(); }));
        }
        this.draggingSub.add(fromEvent(document, "touchend", { passive: false }).subscribe(function () { return _this.putBack(); }));
        this.draggingSub.add(fromEvent(document, "touchcancel", { passive: false }).subscribe(function () { return _this.putBack(); }));
    };
    AngularDraggableDirective.prototype.unsubscribeEvents = function () {
        this.draggingSub.unsubscribe();
        this.draggingSub = null;
    };
    AngularDraggableDirective.prototype.boundsCheck = function () {
        if (this.bounds) {
            var boundary = this.bounds.getBoundingClientRect();
            var elem = this.el.nativeElement.getBoundingClientRect();
            var result = {
                "top": this.outOfBounds.top ? true : boundary.top < elem.top,
                "right": this.outOfBounds.right ? true : boundary.right > elem.right,
                "bottom": this.outOfBounds.bottom ? true : boundary.bottom > elem.bottom,
                "left": this.outOfBounds.left ? true : boundary.left < elem.left
            };
            if (this.inBounds) {
                if (!result.top) {
                    this.tempTrans.y -= (elem.top - boundary.top) / this.scale;
                }
                if (!result.bottom) {
                    this.tempTrans.y -= (elem.bottom - boundary.bottom) / this.scale;
                }
                if (!result.right) {
                    this.tempTrans.x -= (elem.right - boundary.right) / this.scale;
                }
                if (!result.left) {
                    this.tempTrans.x -= (elem.left - boundary.left) / this.scale;
                }
                this.transform();
            }
            return result;
        }
    };
    /** Get current offset */
    AngularDraggableDirective.prototype.getCurrentOffset = function () {
        return this.currTrans.value;
    };
    AngularDraggableDirective.prototype.putBack = function () {
        if (this._zIndex) {
            this.renderer.setStyle(this.el.nativeElement, "z-index", this._zIndex);
        }
        else if (this.zIndexMoving) {
            if (this.oldZIndex) {
                this.renderer.setStyle(this.el.nativeElement, "z-index", this.oldZIndex);
            }
            else {
                this.el.nativeElement.style.removeProperty("z-index");
            }
        }
        if (this.moving) {
            var coords = {
                x: this.getDragEl().offsetLeft,
                y: this.getDragEl().offsetTop
            };
            // emit an event so the consumer can track current position
            this.stopped.emit({ el: this.el.nativeElement, position: coords });
            // Remove the helper div:
            this._helperBlock.remove();
            if (this.needTransform) {
                if (Position.isIPosition(this.position)) {
                    this.oldTrans.set(this.position);
                }
                else {
                    this.oldTrans.reset();
                }
                this.transform();
                this.needTransform = false;
            }
            if (this.bounds) {
                this.edge.emit(this.boundsCheck());
            }
            this.moving = false;
            this.endOffset.emit(this.currTrans.value);
            if (this.trackPosition) {
                this.oldTrans.add(this.tempTrans);
            }
            this.tempTrans.reset();
            if (!this.trackPosition) {
                this.transform();
            }
            var element = this.getDragEl();
            this.renderer.removeClass(element, "ng-dragging");
            /**
             * Fix performance issue:
             * https://github.com/xieziyu/angular2-draggable/issues/112
             */
            this.unsubscribeEvents();
        }
    };
    AngularDraggableDirective.prototype.checkHandleTarget = function (target, element) {
        // Checks if the target is the element clicked, then checks each child element of element as well
        // Ignores button clicks
        // Ignore elements of type button
        if (element.tagName === "BUTTON") {
            return false;
        }
        // If the target was found, return true (handle was found)
        if (element === target) {
            return true;
        }
        // Recursively iterate this elements children
        for (var child in element.children) {
            if (element.children.hasOwnProperty(child)) {
                if (this.checkHandleTarget(target, element.children[child])) {
                    return true;
                }
            }
        }
        // Handle was not found in this lineage
        // Note: return false is ignore unless it is the parent element
        return false;
    };
    AngularDraggableDirective.prototype.onMouseDown = function (event) {
        // 1. skip right click;
        if (event instanceof MouseEvent && event.button === 2) {
            return;
        }
        // 2. if handle is set, the element can only be moved by handle
        var target = event.target || event.srcElement;
        if (this.handle !== undefined && !this.checkHandleTarget(target, this.handle)) {
            return;
        }
        // 3. if allow drag is set to false, ignore the mousedown
        if (this.allowDrag === false) {
            return;
        }
        if (this.preventDefaultEvent) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.orignal = Position.fromEvent(event, this.getDragEl());
        // this.originalOffsetLeft = this.getDragEl().offsetLeft;
        // this.originalOffsetTop = this.getDragEl().offsetTop;
        //
        // console.log("original offset: ", { left: this.originalOffsetLeft, top: this.originalOffsetTop });
        // console.log("original position: ", this.orignal);
        this.pickUp();
    };
    AngularDraggableDirective.prototype.onMouseMove = function (event) {
        this.moved = true;
        if (this.moving && this.allowDrag) {
            if (this.preventDefaultEvent) {
                event.stopPropagation();
                event.preventDefault();
            }
            // Add a transparent helper div:
            this._helperBlock.add();
            this.moveTo(Position.fromEvent(event, this.getDragEl()));
        }
    };
    return AngularDraggableDirective;
}());
export { AngularDraggableDirective };
