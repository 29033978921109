import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from "@angular/core";
import { CustomDataWithValueModel } from "../../model/CustomDataWithValueModel";
import { FormArray } from "@angular/forms";
import { CustomDataValueModel } from "../../model/CustomDataValueModel";
import { IpsDateComponent } from "../ips-date/ips-date.component";

@Component({
    selector: "app-custom-data-field-control",
    templateUrl: "./custom-data-field-control.component.html",
    styleUrls: ["./custom-data-field-control.component.scss"]
})
export class CustomDataFieldControlComponent implements OnInit, AfterViewInit {
    @ViewChild(IpsDateComponent) private dateControl: IpsDateComponent;

    @Input() customDataResult: CustomDataWithValueModel;
    @Input() fieldValues: FormArray;
    @Output() formDirtyChanged = new EventEmitter<any>();
    @Output() valueChanged = new EventEmitter<CustomDataWithValueModel>();
    @Output() loaded = new EventEmitter<CustomDataWithValueModel>();

    public inputType: string;

    constructor() { }

    ngOnInit() {

        if (this.customDataResult.FieldType === "Text") {
            this.inputType = "Text";
        } else if (this.customDataResult.FieldType === "Date") {
            this.inputType = "Date";
        } else if (this.customDataResult.FieldType === "List") {
            this.inputType = "List";
        } else if (this.customDataResult.FieldType === "Number") {
            this.inputType = "Number";
        } else if (this.customDataResult.FieldType === "Email") {
            this.inputType = "Email";
        }
    }

    ngAfterViewInit() {
        this.loaded.emit(this.customDataResult);
    }

    public updateList(items) {
        this.formDirtyChanged.emit();
        this.valueChangedHandler(items);
    }

    public valueChangedHandler(customData: CustomDataValueModel | CustomDataValueModel[]): void {
        let result: CustomDataWithValueModel = {} as CustomDataWithValueModel;
        Object.assign(result, this.customDataResult);

        if (this.customDataResult.FieldType === "List") {
            result.Values = customData as CustomDataValueModel[];
        } else if (this.customDataResult.FieldType === "Text") {
            let data = customData as CustomDataValueModel;
            result.Values = data.Text === "" ? [] : [customData as CustomDataValueModel];
        } else {
            result.Values = [customData as CustomDataValueModel];
        }

        this.valueChanged.emit(result);
    }
}
