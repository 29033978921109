import { Component, OnInit, Host, Input, EventEmitter, Output, OnChanges, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { ListSearchComponent } from "imagine-ui-ng-list-search";

@Component({
    selector: "app-location-group-related-list",
    templateUrl: "./location-group-related-list.component.html",
    styleUrls: ["../store-profile-location-group/store-profile-location-group.component.scss"]
})
export class LocationGroupRelatedListComponent implements OnInit, OnChanges {
    @Input() srefState: string;
    @Input() result: any;
    @Input() locationId: number;
    @Output() srefParamsChanged: EventEmitter<any> = new EventEmitter();

    constructor( @Host() public listSearch: ListSearchComponent, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["srefParams"]) {
            this.srefParamsChanged.emit(this.result);
        }
        this.changeDetectorRef.detectChanges();
    }

}
