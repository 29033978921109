import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { ActiveProfileService, AddressModel, ValidationStatus, CountryModel, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { PageTitleService, CountryService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { LocationService, LocationModel, LocationStatusModel, AlternateAddressModel } from "../../index";
import { Transition, StateService } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { TemplateFormBase } from "../../../shared/templateFormBase";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";
import { IpsImageEditComponent } from "imagine-ui-ng-quick-start";
import { AuthService } from "imagine-ui-ng-security";
import { AddressService } from "../../../shared/service/address.service";
import { ShippingAddress, AddressValidationModel } from "../../model/AddressModel";
import { SuggestedAddressModalComponent } from "../../../shared/suggested-addresses-modal/suggested-addresses-modal.component";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";

import { AlternateAddressService, SimpleSearchLocationModel } from "../../../imagine-ui-ng-store-profile";

import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap, takeWhile } from "rxjs/operators";
import { validateAllFormFields } from "../../../shared/validation/utils";
import { AddressUIModel } from "../../model/LocationModel";


interface IDatePicker {
    opened: boolean;
}

interface LocationModelUI extends LocationModel {
    IsClosed: boolean;
    LocationStatusInfo: LocationStatusModel;
}

@Component({
    selector: "app-location-edit",
    templateUrl: "./location-edit.component.html",
    styleUrls: ["./location-edit.component.scss"]
})
export class LocationEditComponent extends TemplateFormBase implements OnInit, AfterViewInit {
    @ViewChild("locationForm") public locationForm: NgForm;
    @ViewChild(IpsImageEditComponent) private imageEdit: IpsImageEditComponent;
    @ViewChild("alternateAddressField") public alternateAddressField: NgModel;

    private dataService: LocationService;
    public breadCrumbLabel: string;
    public mylocation: LocationModelUI;
    public loaded = false;
    public promise: Promise<void>;
    public dateFormat: string;
    public openDate: IDatePicker;
    public initDate: IDatePicker;
    public suspendDate: IDatePicker;
    public openDateOptions: {};
    public initDateOptions: {};
    public suspendDateOptions: {};
    public openLabel = "OPEN_WITH_LABEL";
    public initPromotionsLabel = "FIRST_RECEIPT_WITH_LABEL";
    public suspendPromotionsLabel = "SUSPEND_DATE_WITH_LABEL";
    public openDateEmptyWarning = "OPEN_DATE_EMPTY_WARNING_MESSAGE";
    public openDateAfterSuspendDateWarning = "OPEN_DATE_AFTER_SUSPEND_DATE_WARNING_MESSAGE";
    public suspendDateBeforeOpenDateWarning = "SUSPEND_DATE_BEFORE_OPEN_DATE_WARNING_MESSAGE";
    public information = "INFORMATION_UPPERCASE";
    public failedAddressValidation = "FAILED_ADDRESS_VALIDATION";
    public closeLocation = "CLOSE_LOCATION";
    public locationClosed = "LOCATION_CLOSED";
    public showOpenDateEmptyWarning = false;
    public showOpenDateAfterSuspendDateWarning = false;
    public showSuspendDateBeforeOpenDateWarning = false;
    private id: number;
    private calendarTheme = "theme-default";
    public invalidDateErrorMessage: string;
    private ErrorStrings: { [key: string]: string } = {
        "CALENDAR_DATE_FORMAT": ""
    };
    public files: any;
    public fullSizeImage: string;
    public closeLocationText = "";
    public originalIsClosed: boolean;
    public countryPromise: Promise<CountryModel[]>;
    private countryList: CountryModel[] = [];
    public showLocationStatus = false;
    private originalAddress: AddressModel;
    public alternateAddress = { Id: 0, Label: " " };
    public canDeleteErrorList = [];

    constructor(transition: Transition,
        locationService: LocationService,
        private addressService: AddressService,
        private ipsModal: IpsModalService,
        private ipsMessage: IpsMessageService,
        private countryService: CountryService,
        private $state: StateService,
        private translateService: TranslateService,
        private pageTitleService: PageTitleService,
        private activeProfileService: ActiveProfileService,
        private authService: AuthService,
        private alternateAddressService: AlternateAddressService
    ) {
        super();

        this.dataService = locationService;
        let minDate = new Date(Date.UTC(1970, 1, 1, 0, 0, 0, 0));

        let localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();

        this.openDate = {
            opened: false
        };
        this.openDateOptions = {
            minDate: minDate,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };
        this.initDate = {
            opened: false
        };
        this.initDateOptions = {
            minDate: minDate,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        } as BsDatepickerConfig;

        this.suspendDate = {
            opened: false
        };
        this.suspendDateOptions = {
            minDate: minDate,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.id = Number(transition.params().id);

        if (this.id > 0) {
            this.showLocationStatus = true;
        }

        // Clear out current location, fill in defaults
        this.mylocation = {
            Addresses: [{ Attention: "LOCATION MANAGER", Country: "USA", Order: 1 } as AddressUIModel],
            AlternateAddresses: [ { Id: 0} as AlternateAddressModel],
            LocationStatusInfo: { Status: "UNKNOWN", IconClass: "unknown", Tooltip: "" }
        } as LocationModelUI;
    }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        let pageTitle = this.id > 0 ? "EDIT_LOCATION" : "CREATE_LOCATION";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        // If we got an ID to load, load it.
        if (this.id > 0) {
            //Initial call to populate screen on load
            this.getLocation(this.id);
        } else {
            this.showOpenDateEmptyWarning = true;
            this.loaded = true;
        }



        this.countryPromise = this.countryService.Get();
        this.countryPromise.then((response) => {
            this.countryList = response;
        });
    }

    ngAfterViewInit() {
        super.setFormPristine(this.locationForm, 700);
    }

    private translateText() {
        this.translateService.get(["OPEN_WITH_LABEL", "FIRST_RECEIPT_DATE_WITH_LABEL", "SUSPEND_DATE_WITH_LABEL", "OPEN_DATE_EMPTY_WARNING_MESSAGE",
            "OPEN_DATE_AFTER_SUSPEND_DATE_WARNING_MESSAGE", "SUSPEND_DATE_BEFORE_OPEN_DATE_WARNING_MESSAGE", "CALENDAR_DATE_FORMAT", "INFORMATION_UPPERCASE",
            "FAILED_ADDRESS_VALIDATION", "CLOSE_LOCATION", "LOCATION_CLOSED", "LOCATION_ADDRESS"]
        ).subscribe((res: [string]) => {
            this.openLabel = IpsString.Format(res["OPEN_WITH_LABEL"], this.dateFormat);
            this.initPromotionsLabel = IpsString.Format(res["FIRST_RECEIPT_DATE_WITH_LABEL"], this.dateFormat);
            this.suspendPromotionsLabel = IpsString.Format(res["SUSPEND_DATE_WITH_LABEL"], this.dateFormat);
            this.openDateEmptyWarning = res["OPEN_DATE_EMPTY_WARNING_MESSAGE"];
            this.openDateAfterSuspendDateWarning = res["OPEN_DATE_AFTER_SUSPEND_DATE_WARNING_MESSAGE"];
            this.suspendDateBeforeOpenDateWarning = res["SUSPEND_DATE_BEFORE_OPEN_DATE_WARNING_MESSAGE"];
            this.ErrorStrings["CALENDAR_DATE_FORMAT"] = res["CALENDAR_DATE_FORMAT"];
            this.information = res["INFORMATION_UPPERCASE"];
            this.failedAddressValidation = res["FAILED_ADDRESS_VALIDATION"];
            this.closeLocation = res["CLOSE_LOCATION"];
            this.locationClosed = res["LOCATION_CLOSED"];
            this.alternateAddress = { Id: 0, Label: res["LOCATION_ADDRESS"] };

            //Default to close location
            this.closeLocationText = this.closeLocation;

            this.invalidDateErrorMessage = IpsString.Format(this.ErrorStrings["CALENDAR_DATE_FORMAT"], this.dateFormat);
        });
    }

    private getLocation(id: string | number) {
        this.loaded = false;


        let canDelete = this.dataService.deleteCheck(id.toString())
            .then((response: any) => {
                this.canDeleteErrorList = response;
            })
            .catch((response: any) => {
                this.canDeleteErrorList = ["-ERROR-"];
            });


        this.promise = this.dataService.get<LocationModel>(id).then((response: LocationModel) => {
            Object.assign(this.mylocation, response);

            this.originalAddress = response.Addresses.length ? response.Addresses[0] : null;

            this.populateThumbnail(this.mylocation);
            if (response.OpenDate) { this.mylocation.OpenDate = new Date(response.OpenDate); }
            if (response.InitiatePromotionsDate) { this.mylocation.InitiatePromotionsDate = new Date(response.InitiatePromotionsDate); }
            if (response.SuspendPromotionsDate) { this.mylocation.SuspendPromotionsDate = new Date(response.SuspendPromotionsDate); }

            if (response.ClosedDate) {
                this.originalIsClosed = true;
                this.mylocation.IsClosed = true;
                this.mylocation.ClosedDate = response.ClosedDate;
                this.closeLocationText = this.locationClosed;
            } else {
                this.closeLocationText = this.closeLocation;
            }

            //Populate the alternate address
            if (response.AlternateAddresses && response.AlternateAddresses.length > 0) {
                this.alternateAddress = {
                    Id: response.AlternateAddresses[0].Id,
                    Label: response.AlternateAddresses[0].Id > 0
                        ? response.AlternateAddresses[0].Name
                        : this.translateService.instant("LOCATION_ADDRESS")
                };
            } else {
                //Set default Alternate Address
                this.mylocation.AlternateAddresses = [{ Id: 0 } as AlternateAddressModel];
            }

            this.showWarnings();
            this.getLocationStatus();
        });

        Promise.all([canDelete, this.promise]).then(() => {
            this.loaded = true;
        });
    }

    private showWarnings() {
        if (this.mylocation.OpenDate === null || this.mylocation.OpenDate === undefined) {
            this.showOpenDateEmptyWarning = true;
            this.showSuspendDateBeforeOpenDateWarning = false;
            this.showOpenDateAfterSuspendDateWarning = false;
        } else {
            this.showOpenDateEmptyWarning = false;
            if (this.mylocation.SuspendPromotionsDate &&
                this.mylocation.OpenDate > this.mylocation.SuspendPromotionsDate) {
                this.showSuspendDateBeforeOpenDateWarning = true;
                this.showOpenDateAfterSuspendDateWarning = true;
            } else {
                this.showSuspendDateBeforeOpenDateWarning = false;
                this.showOpenDateAfterSuspendDateWarning = false;
            }
        }
    }

    private saveLocation(): Promise<void> {
        let id = this.mylocation.Id;

        let saveModel = {} as LocationModelUI;
        Object.assign(saveModel, this.mylocation);

        //Don't send 'Location Address' for 'Campaign Shipping Address' field
        if (saveModel.AlternateAddresses &&
            saveModel.AlternateAddresses.length > 0 &&
            saveModel.AlternateAddresses[0].Id === 0) {
            saveModel.AlternateAddresses = [];
        }

        if (id) {
            let promises = [];

            if (saveModel.IsClosed) {
                promises.push(this.dataService.closeLocation(id));
            } else if (this.originalIsClosed) {
                promises.push(this.dataService.reopenLocation(id));
            }

            promises.push(this.dataService.put<LocationModel>(saveModel, this.files));

            return Promise.all(promises).then(() => {
                this.locationForm.form.markAsPristine();
            });

        } else {
            return this.dataService.post<LocationModel>(saveModel, this.files, undefined).then((response) => {
                this.locationForm.form.markAsPristine();
                this.mylocation = response as LocationModelUI;
            });
        }
    }

    private deleteLocation = () => {
        return this.dataService.delete(this.mylocation.Id);
    }

    public deleteLocationPrompt() {

        if (this.loaded && this.canDeleteErrorList.length > 0) {
            this.ipsMessage.error("Unable to delete! Record is begin used in the following Market Groups:\r\n" + this.canDeleteErrorList.join("\r\n"));
            return null;
        }

        let translated = this.translateService.instant("PROMPT_DELETE_BODY");
        translated = IpsString.Format(translated, this.mylocation.Name);
        return this.ipsMessage.confirmDelete(
            { body: translated, workFunction: this.deleteLocation, progressMessage: "DELETING" }
        ).then((result: any) => {
            if (result) {
                this.$state.go("main.storeProfileLocation.search");
            }
        })
        .catch(() => {
            // rejection
        });
    }

    // Validates the entered address for the location.
    public validateAddressForLocationSave(redirect: boolean) {
        this.addressService.validateAddress(
            this.addressService.addressModeltoShippingAddress(this.mylocation.Addresses[0])
        ).then((validation: AddressValidationModel) => {
            if (!validation.Valid) {
                this.ipsModal.displayTemplateScrollable(
                    SuggestedAddressModalComponent,
                    {
                        suggestedAddresses: validation.Addresses ? validation.Addresses : [],
                        invalidAddress: this.addressService.addressModeltoShippingAddress(this.mylocation.Addresses[0])
                    }
                ).then((response: ShippingAddress) => {
                    const newAddress =
                        this.addressService.updateAddressModelFromShippingAddress(
                            this.mylocation.Addresses[0], response, this.countryList
                        );
                    Object.assign(this.mylocation.Addresses[0], newAddress);
                    this.saveLocationPromptInternal(redirect);
                }).catch((canceled) => { });
            } else {
                this.mylocation.Addresses[0].ValidationStatus = "Validated";
                this.saveLocationPrompt(redirect);
            }
        },
        (reason) => { // Rejected
            return this.ipsMessage.confirm({
                body: "FAILED_ADDRESS_VALIDATION_PROMPT",
                buttons: "OK"
            }).then((result: any) => {
                // nothing to do, user has been warned and closed the dialog
            }).catch((canceled) => {
                // nothing to do, user has been warned and closed the dialog
            });
        });
    }

    public saveLocationPrompt(redirect: boolean) {
        if (this.mylocation.IsClosed && !this.originalIsClosed) {
            return this.ipsMessage.confirm({
                body: "PROMPT_CLOSE_LOCATION",
                title: this.closeLocation,
                ok: "YES_CLOSE_LOC",
                cancel: "NO_KEEP_ACTIVE",
                darkHeader: true
            }).then((result: any) => {
                if (result) {
                    this.saveLocationPromptInternal(redirect);
                }
            })
            .catch((canceled) => {
                this.mylocation.IsClosed = false;
                this.changeCloseLocation();
            });
        } else {
            return this.saveLocationPromptInternal(redirect);
        }
    }

    private saveLocationPromptInternal(redirect: boolean) {
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveLocation(),
        progressMessage: "SAVING" }).then((result) => {
            if (result) {
                if (redirect) {
                    this.$state.go("main.storeProfileLocation.search");
                } else {
                    this.$state.go("main.storeProfileLocation.edit", { id: this.mylocation.Id });
                }
            }
        });
    }

    public changeOpenDate() {
        this.showWarnings();
        this.getLocationStatus();
    }

    public changeSuspendDate() {
        this.showWarnings();
        this.getLocationStatus();
    }

    public ValidateInitDate = () => {
        return this.validateDateFormat(this.mylocation.InitiatePromotionsDate);
    }

    public ValidateOpenDate = () => {
        return this.validateDateFormat(this.mylocation.OpenDate);
    }

    public ValidateSuspendDate = () => {
        return this.validateDateFormat(this.mylocation.SuspendPromotionsDate);
    }

    /**
    * Property used for end-store date validation. It must be:
    * Parsable (handled automatically)
    * On or after start date
    */
    public validateDateFormat(dateField: Date) {
        let isValid = true;
        let message = "";
        if (dateField === undefined) {
            isValid = false;
            message = IpsString.Format(this.ErrorStrings["CALENDAR_DATE_FORMAT"], this.dateFormat);
        }
        return { isValid: isValid, message: message };
    }

    private populateThumbnail(locationModel: LocationModel) {
        if (locationModel.Media) {
            this.fullSizeImage =
                `${locationModel.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity
                }&idToken=${this.authService.getIdToken()}`;
            this.imageEdit.thumbnail = this.fullSizeImage; //this.fullSizeImage.replace("Original", "Thumbnail");
        } else {
            this.fullSizeImage = undefined;
            this.imageEdit.thumbnail = undefined;
        }
        //Set text of image button
        this.imageEdit.selectedImage = this.imageEdit.thumbnail !== undefined;
    }

    public onSelectedImageChanged(files: any) {
        this.files = files;
        if (!this.files) {
            // clear the thumbnail
            this.imageEdit.thumbnail = undefined;
            this.imageEdit.selectedImage = false;
            this.mylocation.MasterMediaId = "00000000-0000-0000-0000-000000000000";
            this.files = undefined;
        }
        this.locationForm.form.markAsDirty();
    }

    private getLocationStatus() {
        this.mylocation.LocationStatusInfo = this.dataService.getStatusInfo(this.mylocation);

        this.showLocationStatus = true;
    }

    public changeCloseLocation() {
        if (this.mylocation.IsClosed) {
            this.ipsMessage.messageBox({
                    title: this.closeLocation,
                    body: "CLOSING_LOCATION_INFO",
                    darkHeader: true
                },
                { size: "sm" });
            this.mylocation.ClosedDate = new Date();
            this.closeLocationText = this.locationClosed;
        } else {
            this.mylocation.ClosedDate = null;
            this.closeLocationText = this.closeLocation;
        }

        this.getLocationStatus();
    }
    public campaignShippingAddressChange() {
        return this.ipsModal.displayTemplateScrollable(SearchModalComponent,
        {
            resolve: { search: "AlternateAddress" }
        }, { backdrop: "static", centered: true })
        .then((response) => {
            this.alternateAddress = response.item;
            this.alternateAddress.Label = response.item.Id > 0 ? response.item.Name : this.translateService.instant("LOCATION_ADDRESS");
            this.mylocation.AlternateAddresses = [];
            this.mylocation.AlternateAddresses.push(response.item);
            this.alternateAddressField.control.markAsDirty();
        }, () => {});

    }

    public onAlternateAddressChange(alternateAddress) {
        if (typeof alternateAddress !== "string") {
            this.alternateAddress.Label = `${alternateAddress.AddressIdentifier} - ${alternateAddress.Name}`;
            this.mylocation.AlternateAddresses = [];
            this.mylocation.AlternateAddresses.push(alternateAddress);
            this.alternateAddressField.control.markAsDirty();
        } else {
            this.mylocation.AlternateAddresses = [];
            this.alternateAddressField.control.markAsPristine();
        }

    }

    public getAlternateAddress = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(350),
            distinctUntilChanged(),
            switchMap(val => {
                //Make sure there are at-least two characters before searching
                if (val.length < 2) {
                    return [];
                }

                let searchParams: SearchInfo = { searchText: val.trim(), businessIdentity: this.activeProfileService.businessIdentity, chunkIndex: 0, recordCount: 20, filterOption: "1" };
                return this.alternateAddressService.search(searchParams, "AlternateAddress/Address/Search")
                    .then((result: SearchResponse<AlternateAddressModel>) => {

                        result.ResultList.forEach(function(item: AlternateAddressModel) {
                            item.Id = item.Id;
                            item.Label = `${item.AddressIdentifier} - ${item.Name}`;
                        });

                        result.ResultList.unshift({
                            Id: 0,
                            Label: this.translateService.instant("LOCATION_ADDRESS"),
                            AddressType: "None"
                        });

                        return result.ResultList;
                    });

            })
        );
    }

    public inputFormatter = (result: any) => {
        if (result && result.Label) {
            return result.Label;
        }
        return result;
    }

}
