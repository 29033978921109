import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { CampaignData } from "../../imagine-ui-ng-campaign";

@Injectable()
export class CampaignService extends IpsBaseWebService {

    constructor(http: HttpClient, public activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Campaign", "Campaign", "v1");
    }

    public checkCampaignName(campaign: CampaignData): Promise<boolean> {
        let name = encodeURIComponent(campaign.Name);
        let route = `Campaign/Name/available?Name=${name}&BusinessIdentity=${this.activeProfileService.businessIdentity}`;
        return this.getByRoute<boolean>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public getCountByLocationSubGroup(locationSubGroupId: number): Promise<number> {
        let route = `LocationSubGroup/${locationSubGroupId}/Campaign`;
        return this.headByRoute(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public getCountByLocationGroup(locationGroupId: number): Promise<number> {
        let route = `LocationGroup/${locationGroupId}/Campaign`;
        return this.headByRoute(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public getMostRecentActiveCampaign(): Promise<CampaignData> {
        const route = "Campaign/MostRecentActive";
        return this.getByRoute<CampaignData>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public hasAlternateAddresses(): Promise<boolean> {
        const route = "Campaign/HasAlternateAddresses";
        return this.getByRoute<boolean>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public getContentListShippingGroup(campaignId): Promise<string[]> {
        const route = `Campaign/${campaignId}/ContentListShippingGroup`;
        return this.getByRoute<string[]>(`${this.urlApiBaseProductPlusVersion}${route}`);
    }

    public generateSignPlan(locationId: number, campaignId: number): Promise<string> {
        const route = `${this.urlApiBasePlusController}${campaignId}/GenerateSignPlan/${locationId}`;
        return this.getByRoute<string>(route);
    }

    public getSignPlanProblems(campaignId: number): Promise<string> {
        const route = `${this.urlApiBasePlusController}${campaignId}/SignPlanProblems`;
        return this.getByRoute<string>(route);
    }

    public generateImagineDemo(locationId: number, reportDate: any): Promise<string> {
        const route = `${this.urlApiBasePlusController}GenerateUpDownReport/${locationId}?reportDate=${encodeURIComponent(reportDate.toISOString())}`;
        return this.getByRoute<string>(route);
    }

    public generateUpDown(locationId: number, campaignId: number): Promise<string> {
        const route = `${this.urlApiBasePlusController}${campaignId}/GenerateUpDownReport/${locationId}`;
        return this.getByRoute<string>(route);
    }

    public obsoleteCampaign(locationId: number, isObsolete: boolean): Promise<boolean> {
        const route = `Campaign/Obsolete`;
        let model = {
            "Id": locationId,
            "IsObsolete": isObsolete,
            "BusinessIdentity": this.activeProfileService.businessIdentity
        };
        return this.postByRoute<boolean>(`${this.urlApiBaseProductPlusVersion}${route}`, model);
    }
}
