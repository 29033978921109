import { OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Observable, from } from "rxjs";
import { distinctUntilChanged, debounceTime, mergeMap } from "rxjs/operators";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { StateService } from "@uirouter/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { ShipMethodPreferenceModel, ShipMethodRule } from "../../model/ShipMethodModels";
import { ShipMethodPreferenceService } from "../../service/ship-method-preference.service";
import { ShopSettingsService } from "../../service/shop-settings.service";
import { PaymentSettingsService } from "../../service/payment-settings.service";
import { LocationGroupService } from "../../../imagine-ui-ng-store-profile/index";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { DecimalPipe } from "@angular/common";
import { AlertAssignmentService } from "../../service/alert-assignment.service";
import { TranslateService } from "@ngx-translate/core";
import { RoleService } from "imagine-ui-ng-quick-start";
var ShopSettingsEditModel = /** @class */ (function () {
    function ShopSettingsEditModel(showOrderReasonSetting, shipMethodSetting, shipMethodPreferences, paymentMethodSettings, handlingFeeSettings, alertSettings, poNumberSetting, orderReasonSetting, referenceNumberSetting, needByDateSetting, checkoutLabelSetting, commentsSetting, categoryFilterSetting, categoryPermissionSetting, allLocationsFilterSetting, hideCampaignFilterSetting, deliverySelectionSetting) {
        this.ShowOrderReasonSetting = showOrderReasonSetting;
        this.originalShowOrderReasonJson = JSON.stringify(showOrderReasonSetting);
        this.PONumberSetting = poNumberSetting;
        this.originalPONumberJson = JSON.stringify(poNumberSetting);
        this.OrderReasonSetting = orderReasonSetting;
        this.originalOrderReasonJson = JSON.stringify(orderReasonSetting);
        this.ShipMethodSetting = shipMethodSetting;
        //Copy the data into the object to maintain object integrity allowing for use of its functions
        //this.ShipMethodPreferences = Object.assign([], shipMethodPreferences);
        this.ShipMethodPreferences = shipMethodPreferences.map(function (rule) {
            var shipPref = new ShipMethodRule();
            shipPref.RoleId = rule.RoleId;
            shipPref.AllLocations = rule.AllLocations;
            shipPref.LocationGroupId = rule.LocationGroupId;
            shipPref.LocationGroupName = rule.LocationGroupName;
            shipPref.SubGroupRules = rule.SubGroupRules;
            shipPref.PendingLocations = rule.PendingLocations;
            shipPref.ShipMethodIds = rule.ShipMethodIds ? rule.ShipMethodIds : [];
            return shipPref;
        });
        this.originalPreferencesJson = JSON.stringify(shipMethodPreferences);
        this.PaymentMethodSettings = paymentMethodSettings;
        this.originalPaymentMethodSettingsJson = JSON.stringify(paymentMethodSettings);
        this.HandlingFeeSettings = handlingFeeSettings;
        this.originalHanldingFeesJson = JSON.stringify(handlingFeeSettings);
        this.AlertSettings = alertSettings;
        this.originalAlertSettingsJson = JSON.stringify(alertSettings);
        this.CheckoutLabelSetting = checkoutLabelSetting;
        this.originalCheckoutLabelJson = JSON.stringify(checkoutLabelSetting);
        this.ShowNeedByDateSetting = needByDateSetting;
        this.originalShowNeedByDateJson = JSON.stringify(needByDateSetting);
        this.ShowReferenceNumberSetting = referenceNumberSetting;
        this.originalShowReferenceNumberJson = JSON.stringify(referenceNumberSetting);
        this.CommentsSetting = commentsSetting;
        this.originalCommentsJson = JSON.stringify(commentsSetting);
        this.CategoryFilterSetting = categoryFilterSetting;
        this.originalCategoryFilterJson = JSON.stringify(categoryFilterSetting);
        this.CategoryPermissionSetting = categoryPermissionSetting;
        this.originalCategoryPermissionJson = JSON.stringify(categoryPermissionSetting);
        this.AllLocationsFilterSetting = allLocationsFilterSetting;
        this.originalAllLocationsFilterSettingJson = JSON.stringify(allLocationsFilterSetting);
        this.HideCampaignFilterSetting = hideCampaignFilterSetting;
        this.originalHideCampaignFilterSettingJson = JSON.stringify(hideCampaignFilterSetting);
        this.DeliverySelectionSetting = deliverySelectionSetting;
        this.originalDeliverySelectionSettingJson = JSON.stringify(deliverySelectionSetting);
    }
    Object.defineProperty(ShopSettingsEditModel.prototype, "ShippingPreferencesDirty", {
        get: function () {
            return this.isDirty(this.ShipMethodPreferences, this.originalPreferencesJson);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShopSettingsEditModel.prototype, "ShopSettingsDirty", {
        get: function () {
            return this.isDirty(this.ShowOrderReasonSetting, this.originalShowOrderReasonJson) ||
                this.isDirty(this.PONumberSetting, this.originalPONumberJson) ||
                this.isDirty(this.OrderReasonSetting, this.originalOrderReasonJson) ||
                this.isDirty(this.CheckoutLabelSetting, this.originalCheckoutLabelJson) ||
                this.isDirty(this.ShowReferenceNumberSetting, this.originalShowReferenceNumberJson) ||
                this.isDirty(this.ShowNeedByDateSetting, this.originalShowNeedByDateJson) ||
                this.isDirty(this.CommentsSetting, this.originalCommentsJson) ||
                this.isDirty(this.CategoryFilterSetting, this.originalCategoryFilterJson) ||
                this.isDirty(this.CategoryPermissionSetting, this.originalCategoryPermissionJson) ||
                this.isDirty(this.AllLocationsFilterSetting, this.originalAllLocationsFilterSettingJson) ||
                this.isDirty(this.HideCampaignFilterSetting, this.originalHideCampaignFilterSettingJson) ||
                this.isDirty(this.DeliverySelectionSetting, this.originalDeliverySelectionSettingJson) ||
                this.ShippingPreferencesDirty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShopSettingsEditModel.prototype, "PaymentMethodSettingsDirty", {
        get: function () {
            return this.isDirty(this.PaymentMethodSettings, this.originalPaymentMethodSettingsJson);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShopSettingsEditModel.prototype, "HanldingFeeSettingsDirty", {
        get: function () {
            return this.isDirty(this.HandlingFeeSettings, this.originalHanldingFeesJson);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShopSettingsEditModel.prototype, "AlertSettingsDirty", {
        get: function () {
            return this.isDirty(this.AlertSettings, this.originalAlertSettingsJson);
        },
        enumerable: true,
        configurable: true
    });
    ShopSettingsEditModel.prototype.isDirty = function (object, originalJson) {
        var currentJson = JSON.stringify(object);
        var settingsDirty = originalJson !== currentJson;
        return settingsDirty;
    };
    return ShopSettingsEditModel;
}());
var AlertSettingsUiModel = /** @class */ (function () {
    function AlertSettingsUiModel(alertAssignments, alerts) {
        var _this = this;
        this.UserAlertAssignments = [];
        this.elementIndexer = 1;
        this.UserAlertAssignments = alertAssignments.reduce(function (accumulator, current) {
            var existing = accumulator.find(function (val) { return val.Email === current.Email; });
            //const alert = alerts.find((a) => a.Id === current.AlertId);
            //current.AlertName = alert.DisplayName;
            //current.Selected = true; // The fact that we have this record means its selected. Record will be deleted if its unselected
            if (!existing) {
                existing = _this.generateNewUserAlertSetting(alerts, current.Email);
                accumulator.push(existing);
            }
            // Find the assignment and set selected and id
            var alertAssign = existing.Assignments.find(function (a) { return a.AlertId === current.AlertId; });
            alertAssign.Selected = true;
            alertAssign.Id = current.Id;
            return accumulator;
        }, []);
    }
    AlertSettingsUiModel.prototype.generateNewUserAlertSetting = function (alerts, email) {
        var defaultAssignents = alerts.map(function (a) { return ({ AlertId: a.Id, AlertName: a.DisplayName, Email: email, Id: 0, Selected: false, BusinessIdentity: "" }); });
        return { Email: email, Assignments: defaultAssignents, HtmlElementName: "userAlert" + this.elementIndexer++ };
    };
    return AlertSettingsUiModel;
}());
var ShipMethodEditComponent = /** @class */ (function () {
    function ShipMethodEditComponent(activeProfileService, ipsMessage, ipsModal, shipMethodService, stateService, settingsService, paymentMethodSettingService, locationGroupService, decimalPipe, alertAssignmentService, translateService, roleService) {
        var _this = this;
        this.activeProfileService = activeProfileService;
        this.ipsMessage = ipsMessage;
        this.ipsModal = ipsModal;
        this.shipMethodService = shipMethodService;
        this.stateService = stateService;
        this.settingsService = settingsService;
        this.paymentMethodSettingService = paymentMethodSettingService;
        this.locationGroupService = locationGroupService;
        this.decimalPipe = decimalPipe;
        this.alertAssignmentService = alertAssignmentService;
        this.translateService = translateService;
        this.roleService = roleService;
        this.selectedLocationGroupName = "";
        this.loadingSubGroups = false;
        this.loadingShippingSubGroups = [];
        this.canAccessPaymentSettings = false;
        this.canAccessGeneralSettings = false;
        this.canAccessHandlingSettings = false;
        this.canAccessAlertSettings = false;
        this.screenReady = false;
        this.alerts = [];
        this.allRoles = [];
        this.duplicatedValueErrorMessage = "DUPLICATED_VALUE";
        this.invalidEmailMessage = "INVALID_EMAIL";
        this.requiredMessage = "REQUIRED";
        this.alertErrorMessages = {
            "required": function () { return _this.requiredMessage; },
            "email": function () { return _this.invalidEmailMessage; },
            "appDuplicateValidator": function () { return _this.duplicatedValueErrorMessage; }
        };
        this.currentBusinessId = activeProfileService.businessIdentity;
        this.currentBrandName = activeProfileService.theme.Name;
        this.locationGroupSource = this.getLocationGroupSource();
        this.locationShippingGroupSources = [];
    }
    Object.defineProperty(ShipMethodEditComponent.prototype, "PaymentMethodSettings", {
        get: function () {
            return this.settingsModel.PaymentMethodSettings;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipMethodEditComponent.prototype, "HandlingFeeSettings", {
        get: function () {
            return this.settingsModel.HandlingFeeSettings;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipMethodEditComponent.prototype, "AlertSettings", {
        get: function () {
            return this.settingsModel.AlertSettings;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipMethodEditComponent.prototype, "formDirty", {
        get: function () {
            return this.settingsForm.form.dirty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipMethodEditComponent.prototype, "formPristine", {
        get: function () {
            return this.settingsForm.form.pristine;
        },
        enumerable: true,
        configurable: true
    });
    ShipMethodEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var promises = [];
        var shipMethodPreferences = [];
        var shipMethodSettings = null;
        var showOrderReasonSetting = null;
        var orderReasonSetting = null;
        var poNumberSetting = null;
        var paymentMethodSettings = null;
        var hanldingFeeSettings = null;
        var alertAssignments = null;
        var alertSettings = null;
        var checkoutLabelSetting = null;
        var needByDateSetting = null;
        var referenceNumberSetting = null;
        var commentsSetting = null;
        var categoryFilterSetting = null;
        var categoryPermissionSetting = null;
        var allLocationsFilterSetting = null;
        var hideCampaignFilterSetting = null;
        var deliverySelectionSetting = null;
        var adminRoles = ["EcommSiteAdmin", "EFinAdmin", "CustomDataAdmin"];
        var financeAdminRoles = ["EFinAdmin"];
        this.canAccessPaymentSettings = this.isInRole(adminRoles);
        this.canAccessGeneralSettings = !this.isInRole(financeAdminRoles);
        this.canAccessHandlingSettings = this.hasPermission("EditHandlingFeesSetting");
        this.canAccessAlertSettings = this.hasPermission("EditAlertAssignment");
        if (this.canAccessGeneralSettings) {
            promises.push(this.settingsService.getSettingByName("ShipMethodPreferences").then(function (data) {
                console.log("got preferences: ", data);
                if (data) {
                    shipMethodPreferences = JSON.parse(data.Value);
                    console.log("Ship method preferences", shipMethodPreferences);
                    shipMethodPreferences.forEach(function (p) {
                        //p.AllLocations = !p.LocationGroupId;
                        _this.locationShippingGroupSources.push(_this.getLocationShippingGroupSources(p.LocationGroupName));
                        _this.loadingShippingSubGroups.push(false);
                    });
                    shipMethodSettings = data;
                }
            }));
            promises.push(this.roleService.getByApplication("Ecomm").then(function (response) {
                console.log("got all roles: ", response);
                _this.allRoles = response;
            }));
            promises.push(this.shipMethodService.getShipMethods().then(function (response) {
                console.log("got all ship methods: ", response);
                _this.allShipMethods = response;
            }));
        }
        if (this.canAccessGeneralSettings) {
            promises.push(this.settingsService.getSettingByName(this.settingsService.ShowOrderReasonSettingName).then(function (setting) {
                if (setting) {
                    showOrderReasonSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.CategoryFilterSettingName).then(function (setting) {
                if (setting) {
                    categoryFilterSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.CategoryPermissionSettingName).then(function (setting) {
                if (setting) {
                    categoryPermissionSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.OrderReasonSettingName).then(function (setting) {
                if (setting) {
                    orderReasonSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.CheckoutLabelSettingName).then(function (setting) {
                if (setting) {
                    checkoutLabelSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.ReferenceNumberSettingName).then(function (setting) {
                if (setting) {
                    referenceNumberSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.NeedByDateSettingName).then(function (setting) {
                if (setting) {
                    needByDateSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.CommentsSettingName).then(function (setting) {
                if (setting) {
                    commentsSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.PONumberSettingName).then(function (setting) {
                if (setting) {
                    poNumberSetting = setting;
                    var parsedObj = JSON.parse(setting.Value);
                    poNumberSetting.EnableList = parsedObj.EnableList;
                    poNumberSetting.Options = parsedObj.Options;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.AllLocationsSettingName).then(function (setting) {
                if (setting) {
                    allLocationsFilterSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.HideCampaignSettingName).then(function (setting) {
                if (setting) {
                    hideCampaignFilterSetting = setting;
                }
            }));
            promises.push(this.settingsService.getSettingByName(this.settingsService.DeliverySelectionSettingName).then(function (setting) {
                if (setting) {
                    deliverySelectionSetting = setting;
                }
            }));
        }
        if (this.canAccessPaymentSettings) {
            promises.push(this.paymentMethodSettingService.loadData().then(function (settings) {
                if (settings) {
                    paymentMethodSettings = settings;
                    if (settings.ShopperSettings.LocationGroupName) {
                        _this.selectedLocationGroupName = settings.ShopperSettings.LocationGroupName;
                    }
                }
            }));
        }
        if (this.canAccessHandlingSettings) {
            promises.push(this.settingsService.GetRestrictedSettings("HandlingFees").then(function (settings) {
                if (!settings) {
                    settings = [];
                }
                var perAddress = settings.find(function (s) { return s.Name === _this.settingsService.HandlingFeePerAddressSettingName; }) ||
                    _this.getDefaultHandlingSettingModel(_this.settingsService.HandlingFeePerAddressSettingName);
                var perLine = settings.find(function (s) { return s.Name === _this.settingsService.HandlingFeePerLineItemSettingName; }) ||
                    _this.getDefaultHandlingSettingModel(_this.settingsService.HandlingFeePerLineItemSettingName);
                var perEach = settings.find(function (s) { return s.Name === _this.settingsService.HandlingFeePerEachSettingName; }) ||
                    _this.getDefaultHandlingSettingModel(_this.settingsService.HandlingFeePerEachSettingName);
                hanldingFeeSettings = { PerAddress: perAddress, PerEach: perEach, PerLine: perLine };
            }));
        }
        if (this.canAccessAlertSettings) {
            promises.push(this.alertAssignmentService.getAlerts().then(function (al) {
                _this.alerts = al;
            }));
            promises.push(this.alertAssignmentService.getList().then(function (as) {
                alertAssignments = as;
            }));
        }
        Promise.all(promises).then(function () {
            if (_this.canAccessAlertSettings) {
                alertSettings = new AlertSettingsUiModel(alertAssignments, _this.alerts);
            }
            _this.settingsModel = new ShopSettingsEditModel(showOrderReasonSetting, shipMethodSettings, shipMethodPreferences, paymentMethodSettings, hanldingFeeSettings, alertSettings, poNumberSetting, orderReasonSetting, referenceNumberSetting, needByDateSetting, checkoutLabelSetting, commentsSetting, categoryFilterSetting, categoryPermissionSetting, allLocationsFilterSetting, hideCampaignFilterSetting, deliverySelectionSetting);
            if (_this.canAccessGeneralSettings) {
                if (!_this.settingsModel.ShipMethodSetting) {
                    _this.settingsModel.ShipMethodSetting = _this.getDefaultShopSettingModel(_this.settingsService.ShipMethodPreferencesSettingName, "");
                }
                if (!_this.settingsModel.OrderReasonSetting) {
                    _this.settingsModel.OrderReasonSetting = _this.getDefaultShopSettingModel(_this.settingsService.OrderReasonSettingName, "");
                }
                if (!_this.settingsModel.ShowOrderReasonSetting) {
                    _this.settingsModel.ShowOrderReasonSetting = _this.getDefaultShopSettingModel(_this.settingsService.ShowOrderReasonSettingName, "true");
                }
                if (!_this.settingsModel.CategoryFilterSetting) {
                    _this.settingsModel.CategoryFilterSetting = _this.getDefaultShopSettingModel(_this.settingsService.CategoryFilterSettingName, "false");
                }
                if (!_this.settingsModel.CategoryPermissionSetting) {
                    _this.settingsModel.CategoryPermissionSetting = _this.getDefaultShopSettingModel(_this.settingsService.CategoryPermissionSettingName, "true");
                }
                if (!_this.settingsModel.PONumberSetting) {
                    _this.settingsModel.PONumberSetting = _this.getDefaultShopSettingModel(_this.settingsService.PONumberSettingName, null);
                    _this.settingsModel.PONumberSetting.EnableList = false;
                    _this.settingsModel.PONumberSetting.Options = "";
                }
                if (!_this.settingsModel.CheckoutLabelSetting) {
                    _this.settingsModel.CheckoutLabelSetting = _this.getDefaultShopSettingModel(_this.settingsService.CheckoutLabelSettingName, "Checkout by Invoice");
                }
                if (!_this.settingsModel.ShowReferenceNumberSetting) {
                    _this.settingsModel.ShowReferenceNumberSetting = _this.getDefaultShopSettingModel(_this.settingsService.ReferenceNumberSettingName, "true");
                }
                if (!_this.settingsModel.ShowNeedByDateSetting) {
                    _this.settingsModel.ShowNeedByDateSetting = _this.getDefaultShopSettingModel(_this.settingsService.NeedByDateSettingName, "false");
                }
                if (!_this.settingsModel.CommentsSetting) {
                    _this.settingsModel.CommentsSetting = _this.getDefaultShopSettingModel(_this.settingsService.CommentsSettingName, "false");
                }
                if (!_this.settingsModel.AllLocationsFilterSetting) {
                    _this.settingsModel.AllLocationsFilterSetting = _this.getDefaultShopSettingModel(_this.settingsService.AllLocationsSettingName, "false");
                }
                if (!_this.settingsModel.HideCampaignFilterSetting) {
                    _this.settingsModel.HideCampaignFilterSetting = _this.getDefaultShopSettingModel(_this.settingsService.HideCampaignSettingName, "false");
                }
                if (!_this.settingsModel.DeliverySelectionSetting) {
                    _this.settingsModel.DeliverySelectionSetting = _this.getDefaultShopSettingModel(_this.settingsService.DeliverySelectionSettingName, "true");
                }
            }
            _this.originalModelJson = JSON.stringify(_this.settingsModel);
            _this.screenReady = true;
        });
        this.translateService.get("ALL").subscribe(function () { return _this.translateText(); });
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
    };
    ShipMethodEditComponent.prototype.shipMethodName = function (id) {
        var returnValue = null;
        var matchingMethod = this.allShipMethods.find(function (x) { return x.Id === id; });
        if (matchingMethod) {
            returnValue = matchingMethod.DisplayName;
        }
        return returnValue;
    };
    ShipMethodEditComponent.prototype.translateText = function () {
        this.requiredMessage = this.translateService.instant(this.requiredMessage);
        this.invalidEmailMessage = this.translateService.instant(this.invalidEmailMessage);
        this.duplicatedValueErrorMessage = this.translateService.instant(this.duplicatedValueErrorMessage);
    };
    ShipMethodEditComponent.prototype.getDefaultShopSettingModel = function (name, value) {
        var defaultShopSettingModel = {
            Id: 0,
            Name: name,
            Value: value,
            BusinessIdentity: this.currentBusinessId
        };
        return defaultShopSettingModel;
    };
    ShipMethodEditComponent.prototype.getDefaultHandlingSettingModel = function (name) {
        var defaultShopSettingModel = {
            Id: 0,
            Name: name,
            Value: "0",
            BusinessIdentity: this.currentBusinessId
        };
        return defaultShopSettingModel;
    };
    Object.defineProperty(ShipMethodEditComponent.prototype, "isDirty", {
        get: function () {
            var currentSettingsJson = JSON.stringify(this.settingsModel);
            var settingsDirty = this.originalModelJson !== currentSettingsJson;
            if (!settingsDirty) {
                this.settingsForm.form.markAsPristine();
            }
            return settingsDirty;
        },
        enumerable: true,
        configurable: true
    });
    ShipMethodEditComponent.prototype.setMinDecimals = function ($event) {
        $event.target.value = this.decimalPipe.transform($event.target.value, "1.2-10");
    };
    ShipMethodEditComponent.prototype.setPONumberOptions = function (value) {
        if (this.settingsModel.ShowOrderReasonSetting.Value !== value) {
            this.settingsModel.ShowOrderReasonSetting.Value = value;
        }
        if (this.isDirty) {
            this.settingsForm.form.markAsDirty();
        }
        else {
            this.settingsForm.form.markAsPristine();
        }
    };
    ShipMethodEditComponent.prototype.setShowOrderReason = function (value) {
        if (this.settingsModel.ShowOrderReasonSetting.Value !== value) {
            this.settingsModel.ShowOrderReasonSetting.Value = value;
        }
        if (this.isDirty) {
            this.settingsForm.form.markAsDirty();
        }
        else {
            this.settingsForm.form.markAsPristine();
        }
    };
    ShipMethodEditComponent.prototype.setAdminOnly = function (value, preference) {
        preference.SetAdminOnly(value);
        if (this.isDirty) {
            this.settingsForm.form.markAsDirty();
        }
        else {
            this.settingsForm.form.markAsPristine();
        }
    };
    ShipMethodEditComponent.prototype.saveData = function () {
        var _this = this;
        // Note:  this transaction is NOT atomic and
        // there is currently no mechanism to roll back if one of the calls fails
        var promises = [];
        if (this.settingsModel.ShopSettingsDirty) {
            if (this.settingsModel.ShowOrderReasonSetting || this.settingsModel.ShipMethodPreferences) {
                promises.push(this.saveShopSettings());
            }
            if (this.settingsModel.PONumberSetting) {
                promises.push(this.savePONumberSettings());
            }
        }
        //if (this.settingsModel.ShipMethodPreferences) {
        //    promises.push(this.savePreferences());
        //}
        if (this.settingsModel.PaymentMethodSettings && this.settingsModel.PaymentMethodSettingsDirty) {
            promises.push(this.paymentMethodSettingService.saveData(this.settingsModel.PaymentMethodSettings));
        }
        if (this.settingsModel.HandlingFeeSettings && this.settingsModel.HanldingFeeSettingsDirty) {
            promises.push(this.saveHandlingFees());
        }
        if (this.settingsModel.AlertSettings && this.settingsModel.AlertSettingsDirty) {
            promises.push(this.saveAlertSettings());
        }
        return Promise.all(promises).then(function () {
            _this.settingsForm.form.markAsPristine();
        });
    };
    ShipMethodEditComponent.prototype.saveShopSettings = function () {
        var showOrderReasonSetting = this.settingsModel.ShowOrderReasonSetting;
        var orderReasonSetting = this.settingsModel.OrderReasonSetting;
        var checkoutLabelSetting = this.settingsModel.CheckoutLabelSetting;
        var referenceNumberSetting = this.settingsModel.ShowReferenceNumberSetting;
        var needByDateSetting = this.settingsModel.ShowNeedByDateSetting;
        var commentsSetting = this.settingsModel.CommentsSetting;
        var categoryFilterSetting = this.settingsModel.CategoryFilterSetting;
        var categoryPermissionSetting = this.settingsModel.CategoryPermissionSetting;
        var allLocationsFilterSetting = this.settingsModel.AllLocationsFilterSetting;
        var hideCampaignFilterSetting = this.settingsModel.HideCampaignFilterSetting;
        var deliverySelectionSetting = this.settingsModel.DeliverySelectionSetting;
        var shipMethodPreferencesSetting = this.settingsModel.ShipMethodSetting;
        shipMethodPreferencesSetting.Value = JSON.stringify(this.settingsModel.ShipMethodPreferences);
        //tslint:disable-next-line: max-line-length
        return this.settingsService.put([showOrderReasonSetting, orderReasonSetting, checkoutLabelSetting, referenceNumberSetting, needByDateSetting, commentsSetting, categoryFilterSetting, categoryPermissionSetting, allLocationsFilterSetting, hideCampaignFilterSetting, shipMethodPreferencesSetting, deliverySelectionSetting]);
    };
    ShipMethodEditComponent.prototype.savePONumberSettings = function () {
        var setting = this.settingsModel.PONumberSetting;
        setting.Value = JSON.stringify({
            EnableList: setting.EnableList,
            Options: setting.Options
        });
        return this.settingsService.put([setting]);
    };
    ShipMethodEditComponent.prototype.chooseAllLocationsShippingMethods = function (shipMethodPrefIndex) {
        var _this = this;
        var shipMethodIds = this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].ShipMethodIds ? this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].ShipMethodIds : [];
        var resolveData = {
            addAll: false,
            search: "shipmethod",
            multiSelection: true,
            selectedIds: shipMethodIds
        };
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: resolveData })
            .then(function (response) {
            /*const currentIds = resolveData.selectedIds;
            const itemsToAdd = response.map((item) => {
                const returnModel = new ShipMethodPreferenceModel(this.currentBusinessId, item);
                return returnModel;
            }).filter((item) => {
                return currentIds.indexOf(item.ShipMethodId) < 0;
            });
            const idsToAdd = itemsToAdd.map((item) => {
                return item.ShipMethodId;
            });
            if (idsToAdd.length) {
                this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].ShipMethodIds.splice(0, 0, ...idsToAdd);
                this.settingsForm.form.markAsDirty();
            }*/
            //rather than add in, now just copy in newly selected items to complete any add/remove
            var newSelectedItems = response.map(function (item) {
                var returnModel = new ShipMethodPreferenceModel(_this.currentBusinessId, item);
                return returnModel;
            });
            var newSelectedIds = newSelectedItems.map(function (item) {
                return item.ShipMethodId;
            });
            if (newSelectedIds) {
                _this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].ShipMethodIds = newSelectedIds;
                _this.settingsForm.form.markAsDirty();
            }
        }, 
        // Rejected
        function () {
            console.log("rejected");
        });
    };
    ShipMethodEditComponent.prototype.chooseShippingMethods = function (shipMethodPrefIndex, subGroupIndex) {
        var _this = this;
        var shipMethodIds = this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].ShipMethodIds ? this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].ShipMethodIds : [];
        var resolveData = {
            addAll: false,
            search: "shipmethod",
            multiSelection: true,
            selectedIds: shipMethodIds //this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].Preferences.map((item) => item.ShipMethodId) //[]
        };
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: resolveData })
            .then(function (response) {
            /*const currentIds = resolveData.selectedIds;
            const itemsToAdd = response.map((item) => {
                const returnModel = new ShipMethodPreferenceModel(this.currentBusinessId, item);
                return returnModel;
            }).filter((item) => {
                return currentIds.indexOf(item.ShipMethodId) < 0;
            });
            const idsToAdd = itemsToAdd.map((item) => {
                return item.ShipMethodId;
            });
            if (itemsToAdd.length) {
                this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].Preferences.splice(0, 0, ...itemsToAdd);
                this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].ShipMethodIds.splice(0, 0, ...idsToAdd);
                this.settingsForm.form.markAsDirty();
            }*/
            //rather than add in, now just copy in newly selected items to complete any add/remove
            var newSelectedItems = response.map(function (item) {
                var returnModel = new ShipMethodPreferenceModel(_this.currentBusinessId, item);
                return returnModel;
            });
            var newSelectedIds = newSelectedItems.map(function (item) {
                return item.ShipMethodId;
            });
            if (newSelectedIds) {
                _this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].Preferences = newSelectedItems;
                _this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].ShipMethodIds = newSelectedIds;
                _this.settingsForm.form.markAsDirty();
            }
        }, 
        // Rejected
        function () {
            // remove the first item, which is All
            console.log("rejected");
        });
    };
    ShipMethodEditComponent.prototype.addAlertEmail = function () {
        var newUser = this.AlertSettings.generateNewUserAlertSetting(this.alerts, undefined);
        this.AlertSettings.UserAlertAssignments.push(newUser);
    };
    ShipMethodEditComponent.prototype.deleteAlertEmail = function (user) {
        var userIndex = this.AlertSettings.UserAlertAssignments.indexOf(user);
        if (userIndex > -1) {
            this.AlertSettings.UserAlertAssignments.splice(userIndex, 1);
            this.settingsForm.form.markAsDirty();
        }
    };
    ShipMethodEditComponent.prototype.savePreferences = function () {
        var shipMethodPreferences = this.settingsModel.ShipMethodPreferences;
        return this.settingsService.put([shipMethodPreferences]);
    };
    ShipMethodEditComponent.prototype.saveHandlingFees = function () {
        var settings = [];
        settings.push(this.settingsModel.HandlingFeeSettings.PerAddress);
        settings.push(this.settingsModel.HandlingFeeSettings.PerLine);
        settings.push(this.settingsModel.HandlingFeeSettings.PerEach);
        return this.settingsService.PutRestrictedSettings(settings, "HandlingFees");
    };
    ShipMethodEditComponent.prototype.saveAlertSettings = function () {
        var _this = this;
        var settings = [];
        this.AlertSettings.UserAlertAssignments.forEach(function (user) {
            user.Assignments.forEach(function (assign) {
                if (assign.Selected) {
                    assign.Email = user.Email;
                    assign.BusinessIdentity = _this.currentBusinessId;
                    settings.push(assign);
                }
            });
        });
        return this.alertAssignmentService.saveAlertAssignments(settings);
    };
    ShipMethodEditComponent.prototype.savePreferencesPrompt = function (redirect) {
        var _this = this;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: function () { return _this.saveData(); }, progressMessage: "SAVING" })
            .then(function (result) {
            if (result) {
                if (redirect) {
                    _this.stateService.go("main.toolsControls");
                }
                else {
                    _this.stateService.go("main.shipmethods", {}, { reload: true });
                }
            }
        });
    };
    ShipMethodEditComponent.prototype.deletePreference = function (preference) {
        var preferenceIndex = this.settingsModel.ShipMethodPreferences.indexOf(preference);
        this.settingsModel.ShipMethodPreferences.splice(preferenceIndex, 1);
        this.settingsForm.form.markAsDirty();
    };
    ShipMethodEditComponent.prototype.toggleCreditCard = function (object) {
        if (event.srcElement.tagName === "INPUT") {
            return;
        }
        if (object.CreditCardEnabled !== undefined) {
            object.CreditCardEnabled = !object.CreditCardEnabled;
        }
    };
    ShipMethodEditComponent.prototype.toggleInvoice = function (object) {
        if (event.srcElement.tagName === "INPUT") {
            return;
        }
        if (object.InvoiceEnabled !== undefined) {
            object.InvoiceEnabled = !object.InvoiceEnabled;
        }
    };
    ShipMethodEditComponent.prototype.setAllLocations = function (object, val) {
        object.AllLocations = val;
    };
    Object.defineProperty(ShipMethodEditComponent.prototype, "paymentMethodsValid", {
        get: function () {
            if (this.settingsModel.PaymentMethodSettings) {
                return this.settingsModel.PaymentMethodSettings.IsValid;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    ShipMethodEditComponent.prototype.onLocationGroupSelected = function (selectedItem) {
        var _this = this;
        // store result and get subgroups
        this.selectedLocationGroup = selectedItem.item;
        this.selectedLocationGroupName = this.selectedLocationGroup.Name;
        if (this.selectedLocationGroup.Id !== this.settingsModel.PaymentMethodSettings.ShopperSettings.LocationGroupId) {
            this.settingsForm.form.markAsDirty();
            this.loadingSubGroups = true;
            this.locationGroupService.get(this.selectedLocationGroup.Id).then(function (innerResponse) {
                _this.settingsModel.PaymentMethodSettings.ShopperSettings.setLocationGroupInfo(innerResponse.Id, innerResponse.Name, innerResponse.PendingAssignmentCount);
                _this.settingsModel.PaymentMethodSettings.ShopperSettings.setLocationSubGroups(innerResponse.SubGroups);
                _this.loadingSubGroups = false;
            });
        }
    };
    ShipMethodEditComponent.prototype.onShippingLocationGroupSelected = function (selectedItem, index) {
        var _this = this;
        // store result and get subgroups
        this.settingsModel.ShipMethodPreferences[index].LocationGroupName = selectedItem.item.Name;
        if (selectedItem.item.Id !== this.settingsModel.ShipMethodPreferences[index].LocationGroupId) {
            this.settingsForm.form.markAsDirty();
            this.loadingShippingSubGroups[index] = true;
            this.locationGroupService.get(selectedItem.item.Id).then(function (innerResponse) {
                _this.settingsModel.ShipMethodPreferences[index].setLocationGroupInfo(innerResponse.Id, innerResponse.Name, innerResponse.PendingAssignmentCount);
                _this.settingsModel.ShipMethodPreferences[index].setLocationSubGroups(innerResponse.SubGroups);
                _this.loadingShippingSubGroups[index] = false;
            });
        }
    };
    ShipMethodEditComponent.prototype.selectLocationGroup = function () {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: false, search: "locationgroup", locationGroupRule: 1 }, hideTotals: true })
            .then(function (response) {
            _this.onLocationGroupSelected(response);
        }, 
        // Rejected
        function () {
            // do something
        });
    };
    ShipMethodEditComponent.prototype.selectShippingLocationGroup = function (index) {
        var _this = this;
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: false, search: "locationgroup", locationGroupRule: 1 }, hideTotals: true })
            .then(function (response) {
            _this.onShippingLocationGroupSelected(response, index);
        }, 
        // Rejected
        function () {
            // do something
        });
    };
    ShipMethodEditComponent.prototype.typeaheadShippingOnSelect = function (match, index) {
        this.onShippingLocationGroupSelected(match, index);
    };
    ShipMethodEditComponent.prototype.showLocations = function (locFeatureId, locFeatureOptionId) {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
            resolve: { addAll: false, search: "listbylocgroup", locationGroupId: locFeatureId, locationSubgroupId: locFeatureOptionId }
        }, {
            windowClass: "no-list-group-item-interaction"
        });
    };
    ShipMethodEditComponent.prototype.typeaheadOnSelect = function (match) {
        this.onLocationGroupSelected(match);
    };
    ShipMethodEditComponent.prototype.getLocationGroupPromise = function (searchText) {
        var _this = this;
        var locGroupPromise = new Promise(function (resolve, reject) {
            var searchInfo = {
                searchText: searchText || "",
                additionalQueryParams: [
                    { param: "Rule", paramValue: "1" }
                ],
                businessIdentity: _this.currentBusinessId,
                chunkIndex: 0,
                chunkCount: Number(10).toString()
            };
            _this.locationGroupService.search(searchInfo, "LocationGroup/SimpleSearch").then(function (response) {
                resolve(response.ResultList);
            });
        });
        return locGroupPromise;
    };
    ShipMethodEditComponent.prototype.getLocationGroupSource = function () {
        var _this = this;
        return Observable.create(function (observer) {
            // Runs on every search
            observer.next(_this.selectedLocationGroupName);
        }).pipe(debounceTime(365), distinctUntilChanged(), mergeMap(function (searchText) {
            return from(_this.getLocationGroupPromise(searchText));
        }));
    };
    ShipMethodEditComponent.prototype.getLocationShippingGroupSources = function (locationGroupName) {
        var _this = this;
        return Observable.create(function (observer) {
            // Runs on every search
            observer.next(locationGroupName);
        }).pipe(debounceTime(365), distinctUntilChanged(), mergeMap(function (searchText) {
            return from(_this.getLocationGroupPromise(searchText));
        }));
    };
    ShipMethodEditComponent.prototype.createNewShippingRule = function () {
        this.settingsModel.ShipMethodPreferences.push(new ShipMethodRule());
        this.locationShippingGroupSources.push(this.getLocationShippingGroupSources(""));
        this.loadingShippingSubGroups.push(false);
        this.settingsForm.form.markAsDirty();
    };
    ShipMethodEditComponent.prototype.removeShippingRule = function (ruleIndex) {
        this.settingsModel.ShipMethodPreferences.splice(ruleIndex, 1);
        this.locationShippingGroupSources.splice(ruleIndex, 1);
        this.loadingShippingSubGroups.splice(ruleIndex, 1);
        this.settingsForm.form.markAsDirty();
    };
    Object.defineProperty(ShipMethodEditComponent.prototype, "formTouched", {
        get: function () {
            return this.settingsForm.touched;
        },
        enumerable: true,
        configurable: true
    });
    ShipMethodEditComponent.prototype.fieldTouched = function (name) {
        return this.settingsForm.controls[name]
            && this.settingsForm.controls[name].touched;
    };
    ShipMethodEditComponent.prototype.anyFieldTouched = function (names) {
        for (var fieldIndex = 0; fieldIndex < names.length; fieldIndex++) {
            var currentField = names[fieldIndex];
            if (this.fieldTouched(currentField)) {
                return true;
            }
        }
        return false;
    };
    ShipMethodEditComponent.prototype.paymentMethodTouched = function (baseName) {
        var names = [
            baseName + "cc",
            baseName + "invoice"
        ];
        return this.anyFieldTouched(names);
    };
    Object.defineProperty(ShipMethodEditComponent.prototype, "allLocationsDisabled", {
        get: function () {
            return !this.PaymentMethodSettings.ShopperSettings.AllLocations;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShipMethodEditComponent.prototype, "allLocationsEnabled", {
        get: function () {
            return this.PaymentMethodSettings.ShopperSettings.AllLocations;
        },
        enumerable: true,
        configurable: true
    });
    ShipMethodEditComponent.prototype.hasPermission = function (permissionName) {
        return !!this.activeProfileService.permissions.find(function (q) { return q.Name === permissionName; });
    };
    ShipMethodEditComponent.prototype.isInRole = function (roleNames) {
        var allThemes = this.activeProfileService.profile.businessIdentityList;
        var allRoles = [];
        allThemes.forEach(function (theme) {
            allRoles.push.apply(allRoles, theme.Role);
        });
        var matchingRoles = allRoles.filter(function (item) { return roleNames.indexOf(item) >= 0; });
        return matchingRoles.length > 0;
    };
    return ShipMethodEditComponent;
}());
export { ShipMethodEditComponent };
