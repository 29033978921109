<div class="col-md-6" *ngIf="translated" ipsFocus="searchInput">
    <div class="row">
        <div class="col-12">
            <section>
                <h3 class="ips-gray-color">
                    <span class="mr-2">{{searchLabelPlural}}</span>
                    <a uiSref="main.storeProfileElement.edit" [uiParams]="{id: 0}" ngbTooltip="{{'CREATE_NEW_ELEMENT' | translate }}" placement="bottom" ipsPermission="main.storeProfileElement.edit"><span class="fa fa-plus ips-ls-addIcon"></span></a>
                    <a [href]="" (click)="reportDownloadClickHandler($event)"><span class="fa fa-arrow-circle-down ips-gray-color clicker pl-2"></span></a>
                </h3>
            </section>
        </div>
    </div>
    <ips-list-search headerLabel="{{'RELATED_ELEMENTS' | translate }}"
                     [searchLabel]="searchLabel"
                     [searchLabelPlural]="searchLabelPlural"
                     labelLoading="{{'ACCESSING_ELEMENT_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_ELEMENT_INFO' | translate }}"
                     [hideHeader]="true"
                     [listData]="element"
                     [busy]="busy"
                     [query]="query"
                     (onListDataChange)="getListData($event)"
                     section="main.storeProfileElement"
                     scroller="true"
                     quick-ref="false"
                     obsolete="true"
                     (onToggleChange)="getObsoleteToggleData($event)"
                     itemClick-permission="main.storeProfileElement.view"
                     [promise]="promise"
                     createNewTooltip="{{'CREATE_NEW_ELEMENT' | translate }}"
                     autoFocus="true">
    </ips-list-search>
</div>
