<div class="col-12 col-lg-10" ipsFocus="searchInput">
    <ips-list-search searchLabel="{{'FIXTURE' | translate }}"
                     searchLabelPlural="{{'FIXTURES' | translate }}"
                     [hideList]=true
                     labelLoading="{{'ACCESSING_FIXTURE_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_FIXTURE_INFO' | translate }}"
                     createNewTooltip="{{'CREATE_NEW_FIXTURE' | translate }}"
                     [section]="'main.storeProfileFixture'"
                     [itemClickPermission]="'main.storeProfileFixture.edit'"
                     [query]="query"
                     [listData]="fixture"
                     [promise]="promise"
                     [hideTotals]="false">
        <ng-template #searchHeaderTemplate>
            <ips-complex-search-box label1="{{'LOCATION' | translate}}" label2="{{'SPACE' | translate}}" label3="{{'ASSIGNMENT' | translate}}"
                                    label1Tooltip="{{'TOOLTIP_FIXTURESEARCH_LOCATION' | translate}}" label2Tooltip="{{'TOOLTIP_FIXTURESEARCH_SPACE' | translate}}" label3Tooltip="{{'TOOLTIP_FIXTURESEARCH_FIXTURE' | translate}}"
                                    [listData1]="availableLocations" [listData2]="availableSpaces" [listData3]="availableFixtures"
                                    [list1Total]="totalLocationCount" [list2Total]="totalSpaceCount"
                                    (list1SelectItem)="selectLocation(item)" (list2SelectItem)="selectSpace(item)" (list3SelectItem)="selectFixture(item)"
                                    [list1SelectedItem]="userSelectedLocation" [list2SelectedItem]="userSelectedSpace" [list3SelectedItem]="userSelectedFixture"
                                    (searchFunction)="filterSearch($event)"
                                    [maxDropdownCount]="maxDropDownCount"
                                    [query]="query"
                                    [autoFocus]="true">
                <div search-title>
                    <section class="col-12">
                        <h3>
                            <span class="ips-ls-header-label-imgn">{{'FIXTURES' | translate }}</span>
                            <a uiSref="main.storeProfileFixture.edit" [uiParams]="{id: 0}" ipsPermission="main.storeProfileFixture.edit" ngbTooltip="{{'CREATE_NEW_FIXTURE' | translate }}" placement="bottom"><span class="fa fa-plus ips-ls-addIcon"></span></a>
                            <a [href]="" (click)="reportDownloadClickHandler($event)"><span class="fa fa-arrow-circle-down ips-gray-color clicker pl-2"></span></a>
                        </h3>
                    </section>
                </div>
                <div search-counts-badge class="col">
                    <div *ngIf="translated" class="row no-gutters mt-1">
                        <div [ngbCollapse]="collapse" class="col-12 col-sm">
                            <div class="row no-gutters mt-1">
                                <div class="col-12 col-md-auto ml-3">
                                    <span class="badge-pill badge-secondary font-weight-normal">{{ fixture.ResultChunkAttributes.TotalRecords | number }} </span>
                                    <span class="ml-1">{{fixture.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping}} {{'FOUND_OF' | translate}} </span>
                                </div>
                                <div class="col-12 col-md-auto ml-3">
                                    <span class="badge-pill badge-secondary font-weight-normal">{{ totalFixtureCount | number }}</span>
                                    <span class="ml-1">{{ 'TOTAL' | translate}} {{totalFixtureCount | i18nPlural: pluralMapping}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto d-flex justify-content-end list-options-margin mt-2" (click)="getObsoleteData()">
                            <span> <input name="IsObsolete" class="mr-2" id="IsObsolete" type="checkbox" [(ngModel)]="includeObsolete" />{{'SHOW_OBSOLETE' | translate }}</span>
                        </div>
                    </div>
                </div>
            </ips-complex-search-box>
        </ng-template>
        <section class="col-12" search-result-List>
            <div>
                <form name="fixtureForm" #fixtureForm="ngForm" novalidate ipsUnsavedWarningForm>
                    <div class="ips-ls-searchResults">
                        <div class="list-group" *ngIf="scroller">
                            <div infiniteScroll (scrolled)="getMoreListData()" [infiniteScrollDisabled]='busy' [infiniteScrollDistance]='0'>
                                <div *ngFor="let result of displayList | spaceFilter: selectedSpace | assignedFilter: selectedFixture | slice:0:numberToDisplay; last as isLast" class="list-group-item">


                                    <div class="row">
                                        <div class="col-md-2 text-center pr-sm-0">
                                            <div>
                                                <ips-image-view [imgThumbUrl]="result.Media.Thumbnail" [imgFullUrl]="result.Media.ResourceUri"></ips-image-view>
                                            </div>
                                        </div>
                                        <div id="{{result.Id}}" class="col text-xs-center text-sm-left" permission-link-disable="true" ipsPermission="main.storeProfileFixture.view">
                                            <a uiSref="main.storeProfileFixture.view" [uiParams]="{id:result.Id}"><span class="long-text-wrap font-weight-bold" [ngClass]="{ 'list-main-item-imgn' : result.Holders.length > 0}">{{result.Name}}<span *ngIf="result.Model"> - {{result.Model}}</span></span></a>
                                            <ul class="searchResults" *ngFor="let subResult of result.Holders">
                                                <li *ngIf="subResult.Quantity > 0" [ngClass]="{'ips-ls-obsolete-item text-muted' : subResult.IsObsolete}">
                                                    {{subResult.Quantity | number}} - {{subResult.Name}} - {{subResult.Size.Width | number: '1.0-7'}} x {{subResult.Size.Height | number: '1.0-7' }}
                                                    <span *ngIf="subResult.HolderVersionInfoName"> - </span>{{subResult.HolderVersionInfoName}}
                                                    <span *ngIf="subResult.IsObsolete" class="ml-5 text-danger">Obsolete</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-md-4 mt-2 mt-md-0">
                                            <div class="row row-margin">
                                                <div class="col-12 col-md-9 offset-md-3 text-center">
                                                    <label class="font-weight-bold mr-2" translate>FIXTURE_QUANTITY</label><i class="text-left fa fa-info-circle fa-lg tooltip-container" aria-hidden="true" ngbTooltip="{{(selectedLocation.Id !== 0 ? 'TOOLTIP_FIXTURESEARCH_QUANTITY' : 'TOOLTIP_FIXTURESEARCH_QUANTITY_READ_ONLY') | translate}}" placement="bottom-right"></i><br />
                                                    <label class="font-weight-bold" *ngIf="selectedLocation.Id === 0">{{result.LocationFixtureSum | number}}</label>
                                                    <input type="number" class="form-control form-control-lg input-full text-center" [(ngModel)]="result.LocationFixtureSum"
                                                           name="fixtureSum{{result.Id}}" required *ngIf="selectedLocation.Id !== 0" (blur)="locationFixtureSumChanged(result)" />
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="result.LocationFixtureSum !== result.OriginalSum">
                                                <div class="col-1 col-md-3 d-flex justify-content-end pr-0 align-items-center">
                                                    <i class="fa fa-reply fa-2x" aria-hidden="true" (click)="result.LocationFixtureSum = result.OriginalSum;  locationFixtureSumChanged(result);" ngbTooltip="{{'UNDO' | translate}}" placement="bottom"></i>
                                                </div>
                                                <div class="col-11 col-md-9">
                                                    <button type="button" class="btn btn-default btn-lg btn-block"
                                                            id="fixtureBtn{{result.Id}}" [disabled]="fixtureForm.invalid"
                                                            (click)="savePrompt()" translate>
                                                        SAVE_ALL
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isLast?scrollToParamVal():false;"></div>
                                </div>
                                
                            </div>
                            <div *ngIf='fixture.busy' translate>LOADING_DATA</div>
                        </div>
                    </div>
                </form>
            </div>
            <ips-error-handler label="{{'ERROR_ACCESSING_FIXTURE_INFO' | translate }}" [promise]="promise"></ips-error-handler>
        </section>
    </ips-list-search>
</div>
