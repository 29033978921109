/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../store-profile-location-group/store-profile-location-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@uirouter/angular/lib/directives/uiSref";
import * as i3 from "@uirouter/core";
import * as i4 from "./location-group-related-list.component";
import * as i5 from "imagine-ui-ng-list-search";
var styles_LocationGroupRelatedListComponent = [i0.styles];
var RenderType_LocationGroupRelatedListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationGroupRelatedListComponent, data: {} });
export { RenderType_LocationGroupRelatedListComponent as RenderType_LocationGroupRelatedListComponent };
export function View_LocationGroupRelatedListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "list-group-item list-group-item-action no-border"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(3, { locationId: 0, locationGroupId: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.srefState, ""); var currVal_1 = _ck(_v, 3, 0, _co.locationId, _co.result.Id); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.result.LocationGroupLabel; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.result.LocationSubGroupName; _ck(_v, 7, 0, currVal_3); }); }
export function View_LocationGroupRelatedListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-location-group-related-list", [], null, null, null, View_LocationGroupRelatedListComponent_0, RenderType_LocationGroupRelatedListComponent)), i1.ɵdid(1, 638976, null, 0, i4.LocationGroupRelatedListComponent, [i5.ListSearchComponent, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocationGroupRelatedListComponentNgFactory = i1.ɵccf("app-location-group-related-list", i4.LocationGroupRelatedListComponent, View_LocationGroupRelatedListComponent_Host_0, { srefState: "srefState", result: "result", locationId: "locationId" }, { srefParamsChanged: "srefParamsChanged" }, []);
export { LocationGroupRelatedListComponentNgFactory as LocationGroupRelatedListComponentNgFactory };
