import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IpsBaseWebService, ActiveProfileService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";

@Injectable()
export class BrandBulletinService extends IpsBaseWebService {

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("BrandBulletin", "Profile", "v1");
    }
}
