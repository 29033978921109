import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-shop-landing",
    templateUrl: "./shop-landing.component.html"
})
export class ShopLandingComponent implements OnInit {

    constructor(private translateService: TranslateService) {

    }

    ngOnInit() {
        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() => this.TranslateText());
    }

    private TranslateText() {
        //this.marketedLocations = this.translateService.instant(this.marketedLocations);
        //this.locationGroupLocationsPendingWarning = this.translateService.instant("MARKET_PENDING_LOCATIONS_WARNING");

        //for (let key of Object.keys(this.TranslateStrings)) {
        //    this.TranslateStrings[key] = this.translateService.instant(key);
        //}
    }
}
