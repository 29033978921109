<div>
    <div>
        <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
    </div>
    <div class="ips-modal-header ips-modal-dark-header">
        <h4 class="modal-title m-0" translate>SELECT_CHECKOUT_COUNTRY</h4>
        <small translate><span class="fa fa-fw fa-info-circle text-info"></span> SEPARATE_CHECKOUT_PER_COUNTRY</small>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div *ngFor="let country of AvailableCountries">
                    <button class="btn btn-lg btn-block btn-default mb-3" (click)="returnResult(country.Iso3166ThreeCharCountryCode)">{{ country.Name }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
