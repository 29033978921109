import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { ProfileService } from "imagine-ui-ng-quick-start";

@Injectable()
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = "{{POST_BUILD_ENTERS_HASH_HERE}}";
    private dialogDisplaying = false;

    constructor(private http: HttpClient, private ipsMessage: IpsMessageService, private profileService: ProfileService) {}

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */
    public initVersionCheck(url, frequency = 1000 * 60 * 30) {
        setInterval(() => {
                this.checkVersion(url);
            },
            frequency);
    }

    public checkForReload(url) {
        return this.getVersion(url).toPromise()
            .then((response: any) => {
                const hashChanged = this.hasHashChanged(this.currentHash, response.hash);
                console.log("hash1 value: " + this.currentHash);
                console.log("hash2 value: " + response.hash);

                if (hashChanged) {
                    // Get new permissions
                    return this.profileService.setPermissions().then(() => {
                        //Force the browser to reload and don't show modal - Bug 7778
                        window.location.href = window.location.origin;
                        return hashChanged;
                    });
                }
                return Promise.resolve(hashChanged);
            })
            .catch( () => Promise.resolve(false));
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url) {
        this.getVersion(url)
            .subscribe(
                (response: any) => {
                    if (this.dialogDisplaying) {
                        return;
                    }
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);

                    // If new version, do something
                    if (hashChanged) {
                        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                        // for an example: location.reload();
                        this.profileService.setPermissions().then(() => {
                            this.dialogDisplaying = true;
                            this.ipsMessage.error("NEWER_SITE_DETECTED"
                            ).then(() => {
                                    this.dialogDisplaying = false;
                                },
                                () => {
                                    // Do Nothing
                                    this.dialogDisplaying = false;
                                });
                        });
                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentHash = hash;
                },
                (err) => {
                    console.error(err, "Could not get version.json");
                }
            );
    }

    private getVersion(url) {
        // timestamp these requests to invalidate caches
        return this.http.get(url + "?t=" + new Date().getTime(),
            {
                headers: { ignoreLoadingBar: "" }
            });
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === "{{POST_BUILD_ENTERS_HASH_HERE}}" || currentHash === "IGNORE") {
            return false;
        }

        return currentHash !== newHash;
    }
}
