import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActiveProfileService, AddressModel, ValidationStatus, CountryModel } from "imagine-ui-ng-core";
import { PageTitleService, CountryService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { AlternateAddressService, AlternateAddressModel } from "../../index";
import { Transition, StateService } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { TemplateFormBase } from "../../../shared/templateFormBase";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";
import { IpsImageEditComponent } from "imagine-ui-ng-quick-start";
import { AuthService } from "imagine-ui-ng-security";
import { AddressService } from "../../../shared/service/address.service";
import { ShippingAddress, AddressValidationModel } from "../../model/AddressModel";
import { SuggestedAddressModalComponent } from "../../../shared/suggested-addresses-modal/suggested-addresses-modal.component";
import { IpsModalService } from "imagine-ui-ng-modal";

@Component({
    selector: "app-alternate-address-edit",
    templateUrl: "./alternate-address-edit.component.html",
    styleUrls: ["./alternate-address-edit.component.scss"]
})
export class AlternateAddressEditComponent extends TemplateFormBase implements OnInit, AfterViewInit {

    @ViewChild("alternateAddressForm")
    public alternateAddressForm: NgForm;

    private dataService: AlternateAddressService;
    public breadCrumbLabel: string;
    public alternateAddress: AlternateAddressModel;
    public loaded = false;
    public promise: Promise<void>;

    public information = "INFORMATION_UPPERCASE";
    public failedAddressValidation = "FAILED_ADDRESS_VALIDATION";

    private id: number;


    public countryPromise: Promise<CountryModel[]>;
    private countryList: CountryModel[] = [];
    private originalAddress: AlternateAddressModel;

    constructor(transition: Transition,
        private alternateAddressService: AlternateAddressService,
        private addressService: AddressService,
        private ipsModal: IpsModalService,
        private ipsMessage: IpsMessageService,
        private countryService: CountryService,
        private $state: StateService,
        private translateService: TranslateService,
        private pageTitleService: PageTitleService,
        private activeProfileService: ActiveProfileService,
        private authService: AuthService
    ) {
        super();

        this.dataService = alternateAddressService;

        this.id = Number(transition.params().id);

        // Clear out current location, fill in defaults
        this.alternateAddress = {
            Attention: "LOCATION MANAGER",
            Country: "USA",
            AddressType: "Campaign"
        } as AlternateAddressModel;
    }

    ngOnInit() {
        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() => this.TranslateText());

        let pageTitle = this.id > 0 ? "EDIT_ALTERNATE_ADDRESS" : "CREATE_ALTERNATE_ADDRESS";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        // If we got an ID to load, load it.
        if (this.id > 0) {
            //Initial call to populate screen on load
            this.getLocation(this.id);
        } else {
            this.loaded = true;
        }

        this.countryPromise = this.countryService.Get();
        this.countryPromise.then((response) => {
            this.countryList = response;
        });
    }

    ngAfterViewInit() {
        super.setFormPristine(this.alternateAddressForm, 700);
    }

    private TranslateText() {
        this.information = this.translateService.instant("INFORMATION_UPPERCASE");
        this.failedAddressValidation = this.translateService.instant("FAILED_ADDRESS_VALIDATION");
    }

    private getLocation(id: number) {
        this.loaded = false;

        this.promise = this.dataService.get<AlternateAddressModel>(id).then((response: any) => {
            Object.assign(this.alternateAddress, response);

            this.originalAddress = this.alternateAddress;
            this.loaded = true;
        });
    }

    private saveLocation(): Promise<void> {
        let id = this.alternateAddress.Id;

        if (id) {
            return this.dataService.put<AlternateAddressModel>(this.alternateAddress).then(() => {
                this.alternateAddressForm.form.markAsPristine();
            });

        } else {
            return this.dataService.post<AlternateAddressModel>(this.alternateAddress).then((response) => {
                this.alternateAddressForm.form.markAsPristine();
                this.alternateAddress = response as AlternateAddressModel;
            });
        }
    }

    private deleteLocation = () => {
        return this.dataService.delete(this.alternateAddress.Id);
    }

    public deleteLocationPrompt() {
        let translated: string;

        if (this.alternateAddress.InUse) {
            translated = this.translateService.instant("PROMPT_DELETE_BODY_ALTERNATE_ADDRESS");
        } else {
            translated = this.translateService.instant("PROMPT_DELETE_BODY");
        }

        translated = IpsString.Format(translated, this.alternateAddress.Name);
        return this.ipsMessage.confirmDelete(
                { body: translated, workFunction: this.deleteLocation, progressMessage: "DELETING" }
            ).then((result: any) => {
                if (result) {
                    this.$state.go("main.storeProfileAlternateAddress.search");
                }
            })
            .catch(() => {
                // rejection
            });
    }

    // Validates the entered address for the location.
    public validateAddressForLocationSave(redirect: boolean) {
        this.addressService.validateAddress(
            this.addressService.addressModeltoShippingAddress(this.alternateAddress as any)
        ).then((validation: AddressValidationModel) => {
                if (!validation.Valid) {
                    this.ipsModal.displayTemplateScrollable(
                        SuggestedAddressModalComponent,
                        {
                            suggestedAddresses: validation.Addresses ? validation.Addresses : [],
                            invalidAddress: this.addressService.addressModeltoShippingAddress(this.alternateAddress as any)
                        }
                    ).then((response: ShippingAddress) => {
                        let newAddress =
                            this.addressService.updateAddressModelFromShippingAddress(
                                this.alternateAddress as any,
                                response,
                                this.countryList
                            );

                        Object.assign(this.alternateAddress, newAddress);
                        this.saveAlternateAddressPrompt(redirect);
                    }).catch((canceled) => {});
                } else {
                    this.alternateAddress.ValidationStatus = "Validated";
                    this.saveAlternateAddressPrompt(redirect);
                }
            },
            (reason) => { // Rejected
                return this.ipsMessage.confirm({
                    body: "FAILED_ADDRESS_VALIDATION_PROMPT",
                    buttons: "OK"
                }).then((result: any) => {
                    // nothing to do, user has been warned and closed the dialog
                }).catch((canceled) => {
                    // nothing to do, user has been warned and closed the dialog
                });
            });
    }

    public saveAlternateAddressPrompt(redirect: boolean) {
        return this.ipsMessage.waitForWork({
            body: "SAVING",
            workFunction: () => this.saveLocation(),
            progressMessage: "SAVING"
        }).then((result) => {
            if (result) {
                if (redirect) {
                    this.$state.go("main.storeProfileAlternateAddress.search");
                } else {
                    this.$state.go("main.storeProfileAlternateAddress.edit", { id: this.alternateAddress.Id });
                }
            }
        });
    }
}
