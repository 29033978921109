import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService, SearchInfo } from "imagine-ui-ng-core";
import { LocationPaymentMethodModel } from "../";
import { PaymentMethodSettingsViewModel, AdminPaymentMethodSettingsModel, ShopperSettingsViewModel, SubGroupSettingViewModel, PaymentMethodSettingModel, UserPaymentMethodSettingsModel } from "../model/PaymentMethodSettingModels";
import { LocationGroupService } from "../../imagine-ui-ng-store-profile/index";
import uniq from "lodash-es/uniq";

@Injectable()
export class PaymentSettingsService extends IpsBaseWebService {

    constructor(http: HttpClient,
        public activeProfileService: ActiveProfileService,
        public helperFunctionsService: HelperFunctionsService,
        private locationGroupService: LocationGroupService
    ) {
        super(http, <any>environment, activeProfileService);
        this.init("PaymentMethodSettings", "Shop", "v1");
    }

    public checkLocationSubGroupsAreInUse(locationSubGroupIds: number[]): Promise<boolean> {
        let route = `${this.urlApiBasePlusController}LocationSubGroupsInUse`;
        return this.postByRoute(route, locationSubGroupIds);
    }

    public getAvailablePaymentMethods(locationIds: number[]): Promise<LocationPaymentMethodModel[]> {

        let route = `${this.urlApiBasePlusController}AvailablePaymentMethods?`;
        locationIds.forEach(i => {
            route += `LocationId=${i}&`;
        });
        return this.getByRoute<LocationPaymentMethodModel[]>(route.substr(0, route.length - 1));
    }

    public loadData(): Promise<PaymentMethodSettingsViewModel> {
        const returnPromise = new Promise<PaymentMethodSettingsViewModel>((resolve, reject) => {
            this.getList().then((response: PaymentMethodSettingModel) => {
                const convertedResponse = this.convertToViewModel(response);

                if (convertedResponse.ShopperSettings.LocationGroupId) {
                    this.locationGroupService.get(convertedResponse.ShopperSettings.LocationGroupId).then((locGroupResponse: any) => {
                        convertedResponse.ShopperSettings.PendingLocations = locGroupResponse.PendingAssignmentCount;
                        resolve(convertedResponse);
                    });
                } else {
                    resolve(convertedResponse);
                }
            });
        });

        return returnPromise;
    }

    public saveData(model: PaymentMethodSettingsViewModel): Promise<any> {
        const convertedModel = this.convertToSaveModel(model);
        return this.put(convertedModel);
    }

    public convertToViewModel(model: PaymentMethodSettingModel): PaymentMethodSettingsViewModel {
        const returnModel = new PaymentMethodSettingsViewModel();

        returnModel.AdminSettings = new AdminPaymentMethodSettingsModel();
        returnModel.AdminSettings.CreditCardEnabled = model.AdminSettings.CreditCardEnabled;
        returnModel.AdminSettings.InvoiceEnabled = model.AdminSettings.InvoiceEnabled;

        returnModel.ShopperSettings = new ShopperSettingsViewModel();
        returnModel.ShopperSettings.LocationGroupName = !model.CreditCardSettings.LocationGroupName ? model.InvoiceSettings.LocationGroupName : model.CreditCardSettings.LocationGroupName ;
        returnModel.ShopperSettings.LocationGroupId = !model.CreditCardSettings.LocationGroupId ? model.InvoiceSettings.LocationGroupId : model.CreditCardSettings.LocationGroupId;
        returnModel.ShopperSettings.PendingLocations = !model.CreditCardSettings.PendingLocations ? model.InvoiceSettings.PendingLocations : model.CreditCardSettings.PendingLocations;
        returnModel.ShopperSettings.AllLocations = !!(model.CreditCardSettings.AllLocations || model.InvoiceSettings.AllLocations);
        returnModel.ShopperSettings.CreditCardEnabled = model.CreditCardSettings.AllLocations;
        returnModel.ShopperSettings.InvoiceEnabled = model.InvoiceSettings.AllLocations;
        returnModel.ShopperSettings.LocationGroupSettings = [];

        // get all subgroups
        const allSubgroups = uniq(model.CreditCardSettings.LocationSubGroups.concat(...model.InvoiceSettings.LocationSubGroups));
        const ccSubGroupNames = model.CreditCardSettings.LocationSubGroupNames || [];
        const invoiceSubGroupNames = model.InvoiceSettings.LocationSubGroupNames || [];
        const allSubgroupNames = uniq(ccSubGroupNames.concat(...invoiceSubGroupNames));

        const groupSettings: SubGroupSettingViewModel[] = [];

        allSubgroups.forEach((subgroupId) => {
            const currentSubGroup = allSubgroupNames.find((item) => item.Key === subgroupId);
            const currentSubGroupName = currentSubGroup === undefined ? "" : currentSubGroup.Value;

            const group = new SubGroupSettingViewModel();
            group.SubGroupId = subgroupId;
            group.SubGroupName = currentSubGroupName;
            group.CreditCardEnabled = false;
            group.InvoiceEnabled = false;
            group.CreditCardEnabled = (model.CreditCardSettings.LocationSubGroups.indexOf(group.SubGroupId) >= 0);
            group.InvoiceEnabled = (model.InvoiceSettings.LocationSubGroups.indexOf(group.SubGroupId) >= 0);

            groupSettings.push(group);
        });

        returnModel.ShopperSettings.LocationGroupSettings = groupSettings;

        return returnModel;
    }

    public convertToSaveModel(model: PaymentMethodSettingsViewModel): PaymentMethodSettingModel {
        const saveModel: PaymentMethodSettingModel = {
            AdminSettings: model.AdminSettings,
            CreditCardSettings: null,
            InvoiceSettings: null
        };

        const newCreditSettings = new UserPaymentMethodSettingsModel();
        newCreditSettings.AllLocations = model.ShopperSettings.AllLocations && model.ShopperSettings.CreditCardEnabled;
        newCreditSettings.LocationGroupName = null; // populating this so TS doesn't complain
        newCreditSettings.LocationGroupId = null; // populating this so TS doesn't complain
        newCreditSettings.LocationSubGroupNames = null; // populating this so TS doesn't complain
        newCreditSettings.LocationSubGroups = [];


        const newInvoiceSettings = new UserPaymentMethodSettingsModel();
        newInvoiceSettings.AllLocations = model.ShopperSettings.AllLocations && model.ShopperSettings.InvoiceEnabled;
        newInvoiceSettings.LocationGroupName = null; // populating this so TS doesn't complain
        newInvoiceSettings.LocationGroupId = null; // populating this so TS doesn't complain
        newInvoiceSettings.LocationSubGroupNames = null; // populating this so TS doesn't complain
        newInvoiceSettings.LocationSubGroups = [];

        const ccSubGroups = model.ShopperSettings.LocationGroupSettings.filter((setting) => setting.CreditCardEnabled).map((item) => item.SubGroupId);
        const invoiceSubGroups = model.ShopperSettings.LocationGroupSettings.filter((setting) => setting.InvoiceEnabled).map((item) => item.SubGroupId);

        if (!model.ShopperSettings.AllLocations) {
            newCreditSettings.LocationSubGroups = ccSubGroups;
        } else {
            newCreditSettings.LocationSubGroups = [];
        }

        if (!model.ShopperSettings.AllLocations) {
            newInvoiceSettings.LocationSubGroups = invoiceSubGroups;
        } else {
            newInvoiceSettings.LocationSubGroups = [];
        }

        saveModel.CreditCardSettings = newCreditSettings;
        saveModel.InvoiceSettings = newInvoiceSettings;

        return saveModel;
    }
}
