var HelperBlock = /** @class */ (function () {
    function HelperBlock(parent, renderer) {
        this.parent = parent;
        this.renderer = renderer;
        this._added = false;
        // generate helper div
        var helper = renderer.createElement("div");
        renderer.setStyle(helper, "position", "absolute");
        renderer.setStyle(helper, "width", "100%");
        renderer.setStyle(helper, "height", "100%");
        renderer.setStyle(helper, "background-color", "transparent");
        renderer.setStyle(helper, "top", "0");
        renderer.setStyle(helper, "left", "0");
        // done
        this._helper = helper;
    }
    HelperBlock.prototype.add = function () {
        // append div to parent
        if (this.parent && !this._added) {
            this.parent.appendChild(this._helper);
            this._added = true;
        }
    };
    HelperBlock.prototype.remove = function () {
        if (this.parent && this._added) {
            this.parent.removeChild(this._helper);
            this._added = false;
        }
    };
    HelperBlock.prototype.dispose = function () {
        this._helper = null;
        this._added = false;
    };
    Object.defineProperty(HelperBlock.prototype, "el", {
        get: function () {
            return this._helper;
        },
        enumerable: true,
        configurable: true
    });
    return HelperBlock;
}());
export { HelperBlock };
