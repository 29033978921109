import { from as observableFrom, timer as observableTimer, of as observableOf } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { OnInit } from "@angular/core";
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from "@angular/forms";
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PageTitleService, CountryService, RoleService, ProfileService, UserService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { HttpClient } from "@angular/common/http";
function duplicatePassword(input) {
    if (!input.root["controls"]) {
        return null;
    }
    var exactMatch = input.root["controls"].password.value === input.value;
    return exactMatch ? null : { mismatchedPassword: true };
}
var UserEditComponent = /** @class */ (function () {
    function UserEditComponent(userService, ipsMessage, $state, transition, translateService, pageTitleService, activeProfileService, countryService, roleService, profileService, httpClient, fb) {
        var _this = this;
        this.userService = userService;
        this.ipsMessage = ipsMessage;
        this.$state = $state;
        this.transition = transition;
        this.translateService = translateService;
        this.pageTitleService = pageTitleService;
        this.activeProfileService = activeProfileService;
        this.countryService = countryService;
        this.roleService = roleService;
        this.profileService = profileService;
        this.httpClient = httpClient;
        this.fb = fb;
        this.pageTitle = "";
        this.localTimezonesList = null;
        this.languageList = [];
        this.usernameAndEmailLinked = true;
        this.roleLoaded = false;
        this.accountLoaded = false;
        this.offBrandRoles = []; // List of roles for the user that are NOT for currently selected brand.
        this.errorMessages = {
            "required": function () { return _this.firstNameRequiredMessages; },
            "email": function () { return _this.validEmailMessages; },
            "maxlength": function (params) { return IpsString.Format(_this.textMaxLength, params.requiredLength); },
            "usernameExists": function () { return _this.usernameUnavailable; },
            "missingRole": function () { return _this.missingRole; }
        };
    }
    UserEditComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(["PLEASE_ENTER_FIRST_NAME", "PLEASE_ENTER_LAST_NAME", "PLEASE_ENTER_USERNAME", "PLEASE_ENTER_EMAIL", "PLEASE_SELECT_USER_ROLE", "MAX_LENGTH_ERROR", "INVALID_EMAIL", "USERNAME_UNAVAILABLE", "PLEASE_SELECT_AT_LEAST_ONE_ROLE"]).subscribe(function (res) {
            _this.firstNameRequiredMessages = res["PLEASE_ENTER_FIRST_NAME"];
            _this.lastNameRequiredMessages = res["PLEASE_ENTER_LAST_NAME"];
            _this.userNameRequiredMessages = res["PLEASE_ENTER_USERNAME"];
            _this.emailRequiredMessages = res["PLEASE_ENTER_EMAIL"];
            _this.roleRequiredMessages = res["PLEASE_SELECT_USER_ROLE"];
            _this.validEmailMessages = res["INVALID_EMAIL"];
            _this.textMaxLength = res["MAX_LENGTH_ERROR"];
            _this.usernameUnavailable = res["USERNAME_UNAVAILABLE"];
            _this.missingRole = res["PLEASE_SELECT_AT_LEAST_ONE_ROLE"];
        });
    };
    UserEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var paramId = this.transition.params().id === "0" ? "" : this.transition.params().id;
        this.inCreateMode = paramId === "";
        this.userLang = (navigator.language || navigator.userLanguage).split("-");
        this.onAccountMe = paramId === "me";
        this.userName = this.onAccountMe ? "me" : paramId;
        this.saveLabel = this.userName === "" ? "SAVE_SEND_LINK" : this.onAccountMe ? "SAVE_ACCOUNT" : "SAVE_AND_RETURN";
        this.IsNonSso = this.profileService.isNonSso();
        if (this.onAccountMe) {
            this.pageTitle = "MY_ACCOUNT";
        }
        else {
            this.pageTitle = this.inCreateMode ? "CREATE_USER" : "EDIT_USER";
        }
        this.breadCrumbLabel = this.pageTitle;
        this.pageTitleService.setTitle([this.pageTitle]);
        this.createForm();
        this.getLanguageList();
        this.getLocalList();
        if (!this.onAccountMe) {
            this.promise = this.getRoleList();
        }
        else {
            this.roleLoaded = true;
        }
        if (this.onAccountMe) {
            this.getAccount(this.activeProfileService.profile.UserName + "/me");
        }
        else if (this.userName === "") {
            this.account = {
                RoleId: "",
                Profile: {
                    TimeZone: null,
                    Locale: this.userLang ? this.userLang[1] : null
                },
                Language: this.userLang ? this.userLang[0] : null
            };
            this.accountLoaded = true;
        }
        else {
            this.getAccount(this.userName);
        }
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
    };
    UserEditComponent.prototype.checkUserName = function () {
        var _this = this;
        return function (control) {
            var debounceTime = 500; //milliseconds
            if (_this.transition.params().id !== "0") {
                return observableOf(null);
            }
            else {
                return observableTimer(debounceTime).pipe(switchMap(function () {
                    return observableFrom(_this.userService.checkValidUserName(control.value)).pipe(map(function (result) {
                        switch (result.Status) {
                            case "Available":
                                return null;
                            case "Assigned":
                            case "Unavailable":
                                return { "usernameExists": true };
                        }
                    }));
                }));
            }
        };
    };
    UserEditComponent.prototype.createForm = function () {
        this.UserName = new FormControl({ value: "", disabled: !this.inCreateMode }, [Validators.required, Validators.email, Validators.maxLength(50)], [this.checkUserName()]);
        this.FirstName = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.LastName = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.Email = new FormControl("", [Validators.required, Validators.email, Validators.maxLength(100)]);
        if (this.onAccountMe) {
            this.RoleId = new FormControl("");
            this.RoleType = new FormControl("");
        }
        else {
            this.RoleId = new FormControl("", [Validators.requiredTrue]);
            this.RoleType = new FormControl("", [Validators.required]);
        }
        this.userForm = new FormGroup({
            UserName: this.UserName,
            FirstName: this.FirstName,
            LastName: this.LastName,
            Email: this.Email,
            Phone: new FormControl(""),
            Roles: new FormArray([]),
            TimeZone: new FormControl(""),
            Locale: new FormControl(""),
            Language: new FormControl(""),
            Role: new FormControl({ value: "", disabled: true }),
            RoleId: new FormControl(""),
            RoleType: this.RoleType,
            CorporateRoles: new FormArray([]),
            FieldRoles: new FormArray([])
        }, [this.ValidateRoles()]);
    };
    UserEditComponent.prototype.getErrorMessages = function (key) {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "firstname":
                    msgs["required"] = function () { return _this.firstNameRequiredMessages; };
                    break;
                case "lastname":
                    msgs["required"] = function () { return _this.lastNameRequiredMessages; };
                    break;
                case "username":
                    msgs["required"] = function () { return _this.userNameRequiredMessages; };
                    break;
                case "email":
                    msgs["required"] = function () { return _this.emailRequiredMessages; };
                    break;
                case "role":
                    msgs["required"] = function () { return _this.roleRequiredMessages; };
                    break;
                default:
            }
        }
        return msgs;
    };
    UserEditComponent.prototype.getLanguageList = function () {
        var _this = this;
        this.httpClient.get("./assets/docs/language.json").subscribe(function (data) {
            _this.languageList = data;
        });
    };
    UserEditComponent.prototype.getLocalList = function () {
        var _this = this;
        this.countryService.Get().then(function (data) {
            _this.localeList = data;
        });
    };
    UserEditComponent.prototype.populateRolesList = function (roleId) {
        var _this = this;
        //Pull all the selected roles
        var roles = [];
        var roleType = this.userForm.get("RoleType").value;
        if (roleType) {
            if (roleType === "Corporate") {
                roles = this.CorporateRoles.value.filter(function (role) { return role.Selected; });
            }
            else if (roleType === "Field") {
                roles = this.FieldRoles.value.filter(function (role) { return role.Selected; });
                if (roles) {
                    // set the locationList on the first role. This will ensure the location list is maintained, even if roles were changed.
                    roles.forEach(function (role, index) {
                        if (index === 0) {
                            role.Locations = _this.locationList;
                        }
                        else {
                            role.Locations = undefined;
                        }
                    });
                }
            }
        }
        //Lets add in roles that are not to be shown to user. If they have it let them keep it
        var hiddenRoles = this.account.Profile.Roles ?
            this.account.Profile.Roles.filter(function (role) { return role.Role === "EcommSiteAdmin" ||
                (role.BusinessIdentity === _this.activeProfileService.businessIdentity && (role.Role === "Viewer" || role.Role === "Administrator")); }) :
            [];
        roles = roles.concat(hiddenRoles, this.offBrandRoles);
        this.account.Profile.Roles = roles;
    };
    UserEditComponent.prototype.getRoleList = function () {
        var _this = this;
        this.roleLoaded = false;
        return this.roleService.getByApplication("ecomm")
            .then(function (response) {
            _this.RoleListCorp = response.filter(function (item) { return item.Category === "Corporate"; });
            _this.RoleListField = response.filter(function (item) { return item.Category === "Field"; });
            //Map, filter and sort roles
            var corpRoles = response.filter(function (item) { return item.Category === "Corporate" && item.Name !== "Administrator" && item.Name !== "Viewer"; }).map(function (item) { return _this.createRoleGroup(item); });
            corpRoles.sort(function (a, b) {
                var x = a.value.DisplayName.toLowerCase(), y = b.value.DisplayName.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
            _this.userForm.setControl("CorporateRoles", _this.fb.array(corpRoles));
            var fieldRoles = response.filter(function (item) { return item.Category === "Field"; }).map(function (item) { return _this.createRoleGroup(item); });
            fieldRoles.sort(function (a, b) {
                var x = a.value.DisplayName.toLowerCase(), y = b.value.DisplayName.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
            _this.userForm.setControl("FieldRoles", _this.fb.array(fieldRoles));
            _this.roleLoaded = true;
            _this.DisableCorporateRoles();
            _this.DisableFieldRoles();
        });
    };
    UserEditComponent.prototype.createRoleGroup = function (role) {
        return this.fb.group({
            Id: this.fb.control(role.Id),
            DisplayName: this.fb.control(this.translateService.instant(this.userService.getRoleTranslationKey(role.Name))),
            Selected: this.fb.control(false),
            Role: this.fb.control(role.Name),
            RoleData: this.fb.control(""),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity)
        });
    };
    UserEditComponent.prototype.saveAccount = function () {
        var _this = this;
        var user = this.userForm.value;
        //Set multiple fields into the okta format & add default values
        this.account.Profile.Locale = (user.Language || "en") + "-" + (user.Locale || "US");
        //update this.account with new values
        //if formControl UserName is disabled, its value is not in the user
        this.account.Profile.UserName = user.UserName ? user.UserName : this.userForm.controls["UserName"].value;
        this.account.Profile.FirstName = user.FirstName;
        this.account.Profile.LastName = user.LastName;
        this.account.Profile.Email = user.Email;
        this.account.Profile.PrimaryPhone = user.Phone;
        if (this.onAccountMe) {
            this.account.Language = user.Language;
            //Editing self
            return this.userService.putUser(this.activeProfileService.profile.UserName + "/me", this.account)
                .then(function (result) {
                _this.userForm.markAsPristine();
                //Prompt message to logoff to see changes
                _this.ipsMessage.confirm({
                    body: "PROFILE_RELOAD_REQUIRED",
                    ok: "LOGOUT",
                    cancel: "CANCEL"
                })
                    .then(function (msgResult) {
                    _this.profileService.signOut().then(function () {
                        _this.$state.go("public.login");
                    });
                })
                    .catch(function (msgResult) {
                    _this.$state.go("main.home");
                });
            });
        }
        else if (this.userName.length) {
            //Editing another user
            this.populateRolesList(user.RoleId);
            return this.userService.putUser(this.userName, this.account);
        }
        else {
            //Creating user
            this.populateRolesList(user.RoleId);
            this.account.Profile.CustomerIdentity = [this.activeProfileService.customerId];
            return this.userService.postUser(this.account.Profile.UserName, this.account);
        }
    };
    UserEditComponent.prototype.getAccount = function (id) {
        var _this = this;
        this.accountLoaded = false;
        var promises = [];
        // NOTE: userService get returns roles for ALL brands the user is assigned to.
        promises.push(this.userService.get(id)
            .then(function (response) {
            _this.account = response;
            //Split the okta locale into the multiple form fields
            var localeSplit = _this.account.Profile.Locale ? _this.account.Profile.Locale.split("-") : null;
            _this.account.Language = localeSplit ? localeSplit[0] : null;
            _this.account.Locale = localeSplit ? localeSplit[1] : null;
            //Set default preferences to browser if user hasnt set fields yet
            if (_this.userLang) {
                _this.account.Language = _this.account.Language ? _this.account.Language : _this.userLang[0];
                _this.account.Locale = _this.account.Locale ? _this.account.Locale : _this.userLang[1];
            }
            if (_this.onAccountMe) {
                _this.account.Role = _this.activeProfileService.userTypes.map(function (userType) {
                    return _this.userService.getRoleTranslationKey(userType);
                })
                    .map(function (resourceKey) {
                    _this.translateService.get([resourceKey]).subscribe(function (res) {
                        resourceKey = res[resourceKey];
                    });
                    return resourceKey;
                })
                    .join(", ");
                //RoleId is a required field, but it will not be updated for onAccountMe
                //set it to the RoleName
                _this.account.RoleId = _this.account.Role;
            }
        }));
        this.promise = Promise.all(promises)
            .then(function (response) {
            //Wait for all data to come back before resolving role name to id
            if (!_this.onAccountMe) {
                var roleType_1 = null;
                _this.offBrandRoles = [];
                var brandRoles_1 = [];
                // #20883: 7-Eleven should be able to include 'CorporateShopper' role for both Corporate or Field Users.
                var isSevenElevenUser = null;
                isSevenElevenUser = (_this.activeProfileService.businessIdentity === "Bsns.00000104" || _this.activeProfileService.businessIdentity === "Bsns.00000010");
                //'Bsns.00000010' is added to enable testing on dev;
                if (isSevenElevenUser) {
                    // We are filtering out 'CorporateShopper' as it can be assigned to both Corporate or Field user
                    _this.account.Profile.Roles.filter(function (r) { return r.Role !== "CorporateShopper"; })
                        .forEach(function (item) {
                        // Only check roles for the currently selected businessIdentity
                        if (item.BusinessIdentity === _this.activeProfileService.businessIdentity) {
                            brandRoles_1.push(item);
                            _this.CorporateRoles.controls.forEach(function (control) {
                                if (item.Role === control.get("Role").value) {
                                    control.get("Selected").setValue(true);
                                    control.get("RoleData").setValue(item.RoleData);
                                    _this.userForm.get("RoleType").setValue("Corporate");
                                    roleType_1 = "Corporate";
                                }
                            });
                            _this.FieldRoles.controls.forEach(function (control) {
                                if (item.Role === control.get("Role").value) {
                                    control.get("Selected").setValue(true);
                                    control.get("RoleData").setValue(item.RoleData);
                                    _this.userForm.get("RoleType").setValue("Field");
                                    roleType_1 = "Field";
                                }
                            });
                        }
                        else {
                            // Save off, need to send back when saving the user
                            _this.offBrandRoles.push(item);
                        }
                    });
                    // Process 'CorporateShopper' as a special case
                    var profileRoleCorpShopper = void 0;
                    profileRoleCorpShopper = _this.account.Profile.Roles.find(function (r) { return r.Role === "CorporateShopper"; });
                    if (profileRoleCorpShopper) {
                        if (profileRoleCorpShopper.BusinessIdentity === _this.activeProfileService.businessIdentity) {
                            var ctrlCorporateShopper = void 0;
                            if (roleType_1 === "Corporate") {
                                ctrlCorporateShopper = _this.CorporateRoles.controls.find(function (x) { return x.get("Role").value === "CorporateShopper"; });
                                ctrlCorporateShopper.get("Selected").setValue(true);
                                ctrlCorporateShopper.get("RoleData").setValue(profileRoleCorpShopper.RoleData);
                            }
                            else if (roleType_1 === "Field") {
                                ctrlCorporateShopper = _this.FieldRoles.controls.find(function (x) { return x.get("Role").value === "CorporateShopper"; });
                                ctrlCorporateShopper.get("Selected").setValue(true);
                                ctrlCorporateShopper.get("RoleData").setValue(profileRoleCorpShopper.RoleData);
                            }
                            else {
                                ctrlCorporateShopper = _this.CorporateRoles.controls.find(function (x) { return x.get("Role").value === "CorporateShopper"; });
                                ctrlCorporateShopper.get("Selected").setValue(true);
                                ctrlCorporateShopper.get("RoleData").setValue(profileRoleCorpShopper.RoleData);
                                // If 'CorporateShopper' is the only role, default role type to 'Corporate'
                                _this.userForm.get("RoleType").setValue("Corporate");
                                roleType_1 = "Corporate";
                            }
                        }
                        else {
                            // Save off, need to send back when saving the user
                            _this.offBrandRoles.push(profileRoleCorpShopper);
                        }
                    }
                }
                else {
                    _this.account.Profile.Roles.forEach(function (item) {
                        // Only check roles for the currently selected businessIdentity
                        if (item.BusinessIdentity === _this.activeProfileService.businessIdentity) {
                            brandRoles_1.push(item);
                            _this.CorporateRoles.controls.forEach(function (control) {
                                if (item.Role === control.get("Role").value) {
                                    control.get("Selected").setValue(true);
                                    control.get("RoleData").setValue(item.RoleData);
                                    _this.userForm.get("RoleType").setValue("Corporate");
                                    roleType_1 = "Corporate";
                                }
                            });
                            _this.FieldRoles.controls.forEach(function (control) {
                                if (item.Role === control.get("Role").value) {
                                    control.get("Selected").setValue(true);
                                    control.get("RoleData").setValue(item.RoleData);
                                    _this.userForm.get("RoleType").setValue("Field");
                                    roleType_1 = "Field";
                                }
                            });
                        }
                        else {
                            // Save off, need to send back when saving the user
                            _this.offBrandRoles.push(item);
                        }
                    });
                }
                _this.locationList = _this.profileService.getLocationListFromUser(brandRoles_1);
                if (roleType_1) {
                    _this.roleTypeChange(roleType_1);
                }
            }
            _this.userForm.patchValue({
                UserName: _this.account.Profile.UserName,
                FirstName: _this.account.Profile.FirstName,
                LastName: _this.account.Profile.LastName,
                Email: _this.account.Profile.Email,
                Phone: _this.account.Profile.PrimaryPhone,
                TimeZone: _this.account.Profile.TimeZone,
                Locale: _this.account.Locale,
                Language: _this.account.Language,
                Role: _this.account.Role,
                RoleId: _this.account.RoleId
            });
            _this.accountLoaded = true;
        });
    };
    UserEditComponent.prototype.saveAccountPrompt = function () {
        var _this = this;
        return this.ipsMessage.waitForWork({
            body: "SAVING",
            workFunction: function () { return _this.saveAccount(); },
            progressMessage: "SAVING"
        })
            .then(function (result) {
            if (result && !_this.onAccountMe) {
                _this.userForm.markAsPristine();
                _this.$state.go("main.profileUser.search");
            }
        });
    };
    UserEditComponent.prototype.getStatusImage = function () {
        return this.account && this.account.Status ? this.userService.getStatusIconClass(this.account.Status) : "";
    };
    UserEditComponent.prototype.cancel = function () {
        var fromState = this.transition.from();
        if (fromState && fromState.name !== "") {
            window.history.back();
        }
        else {
            this.$state.go("main.home");
        }
    };
    UserEditComponent.prototype.setEmailIfLinked = function () {
        if (this.usernameAndEmailLinked) {
            this.account.Profile.Email = this.account.Profile.UserName;
        }
    };
    UserEditComponent.prototype.changeTimezone = function (timezone) {
        if (this.account.Profile.TimeZone !== timezone) {
            this.account.Profile.TimeZone = timezone;
            this.userForm.markAsDirty();
        }
    };
    Object.defineProperty(UserEditComponent.prototype, "CorporateRoles", {
        get: function () {
            return this.userForm.get("CorporateRoles");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserEditComponent.prototype, "FieldRoles", {
        get: function () {
            return this.userForm.get("FieldRoles");
        },
        enumerable: true,
        configurable: true
    });
    UserEditComponent.prototype.roleTypeChange = function (roleType) {
        this.activeRoleType = roleType;
        if (roleType === "Corporate") {
            this.DisableFieldRoles();
            this.CorporateRoles.controls.forEach(function (role) { role.enable(); });
        }
        else if (roleType === "Field") {
            this.DisableCorporateRoles();
            this.FieldRoles.controls.forEach(function (role) { role.enable(); });
        }
    };
    UserEditComponent.prototype.DisableCorporateRoles = function () {
        this.CorporateRoles.controls.forEach(function (role) { role.get("Selected").setValue(false); role.disable(); });
    };
    UserEditComponent.prototype.DisableFieldRoles = function () {
        this.FieldRoles.controls.forEach(function (role) { role.get("Selected").setValue(false); role.disable(); });
    };
    UserEditComponent.prototype.ValidateRoles = function () {
        var _this = this;
        return function (control) {
            var roleType = control.get("RoleType").value;
            if (roleType) {
                if (roleType === "Corporate") {
                    //value = this.CorporateRoles.controls.find(role => role.get("Selected").value);
                    if (!_this.CorporateRoles.controls.find(function (role) { return role.get("Selected").value; })) {
                        _this.CorporateRoles.setErrors({ missingRole: { valid: false } });
                    }
                }
                else if (roleType === "Field") {
                    //value = this.FieldRoles.controls.find(role => role.get("Selected").value);
                    if (!_this.FieldRoles.controls.find(function (role) { return role.get("Selected").value; })) {
                        _this.FieldRoles.setErrors({ missingRole: { valid: false } });
                    }
                }
            }
            return null;
        };
    };
    return UserEditComponent;
}());
export { UserEditComponent };
