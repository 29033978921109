import { Component, OnInit } from "@angular/core";
import { ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { TranslateService } from "@ngx-translate/core";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CampaignUpDownAnalyticsService } from "../..";
import { Subject } from "rxjs";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { CampaignUpDownAnalyticsModel } from "../../model/CampaignUpDownAnalyticsModel";

@Component({
  selector: "app-campaign-updown-analytics-modal",
  templateUrl: "./campaign-updown-analytics-modal.component.html",
  styleUrls: ["./campaign-updown-analytics-modal.component.scss"]
})
export class CampaignUpdownAnalyticsModalComponent implements OnInit {
  public searchLabel: string;
  public searchLabelPlural: string;
  public listSearch: ListSearchHelper;
  public campaignUpDownAnalytics: ListSearchHelper;
  public campaignUpDownAnalyticsPromise: Promise<void>;
  public campaignUpDownAnalyticsQuery: string;
  public querySubject: Subject<string> = new Subject<string>();
  public searchInfo: SearchInfo;
  public busy: boolean;
  public focusCampaignUpDown: boolean;
  public resolve: any;
  public locationName: string;
  public locationIdentifier: string;
  public locationId: number;
  public startDate: Date;
  public endDate: Date;
  public translatedTexts: TranslatedTexts;
  public isModal = true;


  pluralMapping: { [k: string]: string } = {
    "=1": "User",
    "other": "Users"
  };

  private TranslateStrings: { [key: string]: string } = {
    "USER": "",
    "USERS": "",
  };

  constructor(private campaignUpDownAnalyticService: CampaignUpDownAnalyticsService, private modalInstance: NgbActiveModal, private listSearchHelper: ListSearchHelper, private translateService: TranslateService) { }

  ngOnInit() {
    this.locationName = this.resolve.campaignUpDownData.LocationName;
    this.locationIdentifier = this.resolve.campaignUpDownData.LocationIdentifier;
    this.locationId = this.resolve.campaignUpDownData.LocationId;
    this.startDate = this.resolve.startDate || null;
    this.endDate = this.resolve.endDate || null;
    this.translateText();
    this.translateService.onLangChange.subscribe(() => this.translateText());
    this.querySubject.pipe(
      debounceTime(350),
      distinctUntilChanged())
      .subscribe(model => {
        this.getData(model);
      });

    this.translateService.onLangChange.subscribe(() => this.translateText());
    this.campaignUpDownAnalytics = this.listSearchHelper.getListSearchHelper(this.campaignUpDownAnalyticService);
    this.campaignUpDownAnalyticService.headByUpDownUser(this.locationId, this.startDate, this.endDate).then((total: number) => {
      this.campaignUpDownAnalytics.TotalRecords = total;
    });
    this.getData("");
  }

  private translateText() {
    this.translateService.get("ALL").subscribe(() => {
      this.searchLabel = this.translateService.instant("CAMPAIGN_UP_DOWN_USER");
      this.searchLabelPlural = this.translateService.instant("CAMPAIGN_UP_DOWN_USERS");
    });

    for (let key of Object.keys(this.TranslateStrings)) {
      this.TranslateStrings[key] = this.translateService.instant(key);
    }

    this.pluralMapping = {
      "=1": this.TranslateStrings["USER"],
      "other": this.TranslateStrings["USERS"]
    };
  }

  public onQueryChange(query: string) {
    this.campaignUpDownAnalyticsQuery = query;
    this.querySubject.next(query);
  }


  public close(closeMessage?: any) {
    this.modalInstance.dismiss(closeMessage);
  }

  public getData(search: string) {
    this.campaignUpDownAnalyticsQuery = search || "";

    const route = `Campaign/GetUpDownReportUser/${this.locationId}/Search`;

    this.searchInfo = {
      searchText: this.campaignUpDownAnalyticsQuery,
      additionalQueryParams: [{ param: "StartDate", paramValue: this.startDate ? encodeURIComponent(this.startDate.toUTCString()) : null }, { param: "EndDate", paramValue: this.endDate ? encodeURIComponent(this.endDate.toUTCString()) : null }]
    };

    return this.campaignUpDownAnalyticsPromise = this.campaignUpDownAnalytics.searchHelper(this.searchInfo, false, route).then((res) => {
      this.campaignUpDownAnalytics.resultList.forEach((result: CampaignUpDownAnalyticsModel) => {
        result.Label = result.UserName;
      });
    });
  }

  public getMoreListData() {
    this.getData(this.campaignUpDownAnalyticsQuery);
  }

}
