import { Directive, ElementRef, HostListener } from "@angular/core";

/*
    This directive is to set an element to auto select on click,
    while not preventing other click events.

    Example: <input appSelectOnClick />
*/

@Directive({
    selector: "[appSelectOnClick]"
})
export class SelectOnClickDirective {
    constructor(private element: ElementRef) {
    }

    @HostListener("click", ["$event"]) onClick($event) {
        this.element.nativeElement.select();
    }
}
