import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-store-profile-fixture",
  templateUrl: "./store-profile-fixture.component.html"
})
export class StoreProfileFixtureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
