<h2 class="title ml-3 d-inline-block" translate>VIEW_ORDER</h2>
<a class="ml-2 border-left pl-2 d-inline-block d-print-none" *ngIf="screenLoaded" href="#" (click)="print()">
    <span class="fa fa-print"></span> Print
</a>

<!-- This stray column is here to compensate for an override of the .container class.  When that is fixed, this can be removed. -->
<div class="col-12 p-lg-0">
    <div class="ips-flex-loading-box mb-4" *ngIf="!screenLoaded">
        <div class="content">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
            <span class="d-block" translate>LOADING_ORDER_DATA</span>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_INFO' | translate }}" [hasError]="orderLoadError"></ips-error-handler>
    <div *ngIf="screenLoaded">
        <div class="alert alert-info d-print-none" *ngIf="showBanner" translate>
            <span class="fa fa-check text-info"></span> ORDER_THANK_YOU
        </div>
        <!--Order Info-->
        <div class="card bg-ips-gray-white mb-4">
            <div class="card-body px-4 pb-1">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div>
                            <label class="view-info-label" translate>ORDER_NUMBER</label> {{myOrder.Id}}
                        </div>
                        <div>
                            <label class="view-info-label" translate>ORDER_STATUS</label> {{myOrder.StatusLabel}}
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div>
                            <label class="view-info-label" translate>ORDER_QTY</label> {{myOrder.OrderQty}}
                        </div>
                        <div>
                            <label class="view-info-label" translate>ORDER_DATE</label> {{myOrder.CreatedDateTime | date:'mediumDate'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-deck">
            <div class="card ips-shadow mb-4">
                <div class="card-header">
                    <span class="pr-3" translate>ORDERED_BY</span>
                </div>
                <div class="card-body">
                    <p><span class="view-info-label" translate>NAME</span><span> {{myOrder.OrderedBy.Address.FirstName}} {{myOrder.OrderedBy.Address.LastName}}</span></p>
                    <p><span class="view-info-label" translate>EMAIL</span><span> {{myOrder.OrderedBy.Address.Email}}</span></p>
                    <p><span class="view-info-label" translate>PHONE</span><span> {{myOrder.OrderedBy.Address.Phone}}</span></p>
                </div>
            </div>
            <div class="card ips-shadow mb-4">
                <div class="card-header">
                    <span class="pr-3" translate>PAYMENT</span>
                </div>
                <div class="card-body">
                    <p><span class="view-info-label" translate>PAY_FOR_PRODUCTS_BY</span><span> {{(myOrder.PaymentType.toLowerCase() === "credit" ? "CREDIT_CARD" : "INVOICE") | translate}}</span></p>
                    <div>
                        <p><span class="view-info-label" translate>ESTIMATED_SUBTOTAL</span><span> {{myOrder.EstimatedSubtotal | currency }}</span></p>
                        <p><span class="view-info-label" translate>ESTIMATED_SHIPPING</span><span> {{myOrder.EstimatedShippingCost | currency }}</span></p>
                        <p><span class="view-info-label" translate>ESTIMATED_HANDLING</span><span> {{myOrder.EstimatedHandling | currency }}</span></p>
                        <p><span class="view-info-label" translate>ESTIMATED_TAX</span><span> {{myOrder.EstimatedTax  | currency }}</span></p>
                        <p><span class="view-info-label" translate>ESTIMATED_TOTAL</span><span> {{myOrder.EstimatedTotal | currency }}</span></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-deck" *ngIf="showDeliverySelection">
            <div class="card ips-shadow">
                <div class="card-header">
                    <span class="pr-3" translate>DELIVERY</span>
                </div>
                <div class="card-body">
                    <p><span class="view-info-label" translate>LOCATIONS</span><span> {{myOrder.LocationIds.length}}</span></p>
                    <p *ngIf="myOrder.ShipTo.length && myOrder.ShipTo[0].ShipMethodDisplayName"><span class="view-info-label" translate>DELIVERY_SERVICE</span><span> {{myOrder.ShipTo[0].ShipMethodDisplayName}}</span></p>
                    <p *ngIf="myOrder.ShipTo.length && myOrder.ShipTo[0].NeedByDate"><span class="view-info-label" translate>Need by Date</span><span> {{myOrder.ShipTo[0].NeedByDate | date}}</span></p>
                </div>
            </div>
            <div class="card ips-shadow">
                <div class="card-header">
                    <span class="pr-3" translate>BILLING</span>
                </div>
                <div class="card-body">
                    <div class="row pb-3" *ngIf="myOrder.PaymentType.toLowerCase() === 'credit'">
                        <div class="col-auto pr-0"><span class="view-info-label" translate>BILLING_ADDRESS</span></div>
                        <div class="col" *ngIf="myOrder.BillTo[0]">
                            <div class="row pl-1"> {{myOrder.BillTo[0].Line1}}</div>
                            <div class="row pl-1"> {{myOrder.BillTo[0].City}}, {{myOrder.BillTo[0].StateProvince}} {{myOrder.BillTo[0].PostalCode}}</div>
                        </div>
                    </div>
                    <p><span class="view-info-label" translate>PO_NUMBER</span><span> {{myOrder.PONumber}}</span></p>
                    <p><span class="view-info-label" translate>REFERENCE_NUMBER</span><span> {{myOrder.ReferenceNumber }}</span></p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="productsLoaded">
        <div class="mb-2 mt-3 mt-lg-4">
            <h3 class="mt-0 mb-0 mb-lg-2 d-inline-block" translate>PRODUCTS_ORDERED</h3>
        </div>

        <tabset class="order-tabs d-print-none">
            <!-- Print Items -->
            <tab heading="{{ 'PRINTED_ITEMS_COUNT' | translate:{ count: sortedPrintRecords.length } }}">
                <!-- Mobile view -->
                <div class="d-block d-lg-none d-print-none">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <span class="d-block font-weight-bold m-0 mb-1" translate>SORT_BY</span>
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !printSortHelper.isUnsorted('Description') }" (click)="printSortHelper.setSort('Description')" translate>NAME <span class="fa" [class]="printSortHelper.getSortClass('Description')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !printSortHelper.isUnsorted('CustomerItemCode') }" (click)="printSortHelper.setSort('CustomerItemCode')" translate>CUSTOMER_ITEM_CODE <span class="fa" [class]="printSortHelper.getSortClass('CustomerItemCode')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !printSortHelper.isUnsorted('Quantity') }" (click)="printSortHelper.setSort('Quantity')" translate>QTY <span class="fa" [class]="printSortHelper.getSortClass('Quantity')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !printSortHelper.isUnsorted('UnitPrice') }" (click)="printSortHelper.setSort('UnitPrice')" translate>PRICE <span class="fa" [class]="printSortHelper.getSortClass('UnitPrice')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !printSortHelper.isUnsorted('TotalCost') }" (click)="printSortHelper.setSort('TotalCost')" translate>TOTAL_CAPITAL <span class="fa" [class]="printSortHelper.getSortClass('TotalCost')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !printSortHelper.isUnsorted('LocationLabel') }" (click)="printSortHelper.setSort('LocationLabel')" translate>LOCATION <span class="fa" [class]="printSortHelper.getSortClass('LocationLabel')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !printSortHelper.isUnsorted('ShipmentAddress') }" (click)="printSortHelper.setSort('ShipmentAddress')" translate>SHIPMENT_ADDRESS <span class="fa" [class]="printSortHelper.getSortClass('ShipmentAddress')"></span></span>
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !printSortHelper.isUnsorted('Status') }" (click)="printSortHelper.setSort('Status')" translate>STATUS <span class="fa" [class]="printSortHelper.getSortClass('Status')"></span></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4" *ngFor="let item of sortedPrintRecords">
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h5 class="m-0">{{ item.Description }}</h5>
                                    <small class="d-block text-black-50">{{ item.CustomerItemCode }}</small>
                                </div>
                                <div class="card-body">
                                    <div class="form-row card-text form-group">
                                        <div class="col-5">
                                            <img style="max-width:100%;height:auto;" class="mb-3" src="{{ item.ThumbImage }}" alt="{{ item.CustomerItemCode }}" />

                                            <span class="d-block font-weight-bold" translate>PRICE</span>
                                            <span>{{ item.TotalCost | currency }}</span>
                                            <small class="d-block text-black-50 mt-1">{{ 'QTY' | translate }}: {{ item.Quantity }}</small>
                                            <small class="d-block text-black-50 mt-1">{{ item.UnitPrice | currency }} {{ 'EACH' | translate }}</small>
                                        </div>
                                        <div class="col-7">
                                            <span class="d-block font-weight-bold" translate>LOCATION</span>
                                            {{ locationLabelSegment(item.LocationLabel, 0) }} - {{ locationLabelSegment(item.LocationLabel, 1) }}<br />
                                            {{ locationLabelSegment(item.LocationLabel, 2) }} - {{ locationLabelSegment(item.LocationLabel, 3) }}

                                            <span class="d-block font-weight-bold mt-3" translate>SHIP_TO</span>
                                            <p class="mb-0" [innerHTML]="formatAddressForDisplay(item.ShipToAddress)"></p>

                                            <span class="d-block font-weight-bold mt-3" translate>STATUS</span>
                                            <small class="">{{ getShipmentStatus(item) }}</small>
                                            <small class="d-block text-info text-hover-info">{{ item.TrackingNumber }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Large screen and print view -->
                <table class="table table-hover border d-lg-table d-none d-print-table table-sm">
                    <thead class="bg-ips-gray-white">
                        <tr>
                            <th class="sorted max-300" (click)="printSortHelper.setSort('Description')" translate>NAME <span class="fa fa-fw d-print-none" [class]="printSortHelper.getSortClass('Description', true)"></span></th>
                            <th class="sorted" (click)="printSortHelper.setSort('CustomerItemCode')" translate>CUSTOMER_ITEM_CODE <span class="fa fa-fw d-print-none" [class]="printSortHelper.getSortClass('CustomerItemCode', true)"></span></th>
                            <th translate>QTY</th>
                            <th translate>PRICE</th>
                            <th translate>TOTAL_CAPITAL</th>
                            <th class="sorted" (click)="printSortHelper.setSort('LocationLabel')" translate>LOCATION <span class="fa fa-fw d-print-none" [class]="printSortHelper.getSortClass('LocationLabel', true)"></span></th>
                            <th class="sorted" (click)="printSortHelper.setSort('ShipmentAddress')" translate>SHIPMENT_ADDRESS <span class="fa fa-fw d-print-none" [class]="printSortHelper.getSortClass('ShipmentAddress', true)"></span></th>
                            <th class="sorted" (click)="printSortHelper.setSort('Status')" translate>STATUS <span class="fa fa-fw d-print-none" [class]="printSortHelper.getSortClass('Status', true)"></span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of sortedPrintRecords">
                            <td>
                                {{ item.Description }}
                                <img class="d-block d-print-none mt-2" style="max-width:80px;height:auto;" src="{{ item.ThumbImage }}" alt="{{ item.CustomerItemCode }}" />
                                <button class="btn btn-lg btn-default btn-block px-xl-1"
                                        (click)="reorder(item)" translate>
                                    <span class="fa fa-magic fa-button-icon text-muted"></span> Re-Order
                                </button>
                                <div *ngIf="item.NumberInCart > 0" class="col-12 pt-2 text-center">
                                    <span class="badge-pill badge-success font-weight-normal">
                                        {{item.NumberInCart}}
                                    </span>
                                    <span class="ml-1" translate>IN_CART</span>
                                </div>
                            </td>
                            <td>
                                {{ item.CustomerItemCode }}
                            </td>
                            <td>
                                {{ item.Quantity }}
                            </td>
                            <td>
                                {{ item.UnitPrice | currency }}
                            </td>
                            <td>
                                {{ item.TotalCost | currency }}
                            </td>
                            <td>
                                {{ locationLabelSegment(item.LocationLabel, 0) }} - {{ locationLabelSegment(item.LocationLabel, 1) }}<br />
                                {{ locationLabelSegment(item.LocationLabel, 2) }} - {{ locationLabelSegment(item.LocationLabel, 3) }}
                            </td>
                            <td [innerHTML]="formatAddressForDisplay(item.ShipToAddress)"></td>
                            <td>
                                <small class="">{{ getShipmentStatus(item) }}</small>
                                <small class="d-block text-info text-hover-info" *ngFor="let link of item.TrackingLinks" [innerHTML]="link"></small>
                            </td>
                        </tr>
                        <tr *ngIf="printedItemList.length === 0">
                            <td colspan="8" translate>NO_PRODUCTS_FOR_ORDER</td>
                        </tr>
                    </tbody>
                </table>
            </tab>
            <!-- Digital Items -->
            <tab heading="{{ 'DIGITAL_ITEMS_COUNT' | translate:{ count: sortedDigitalRecords.length } }}">

                <!-- Mobile view -->
                <div class="d-block d-lg-none d-print-none">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3">
                                <span class="d-block font-weight-bold m-0 mb-1" translate>SORT_BY</span>
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !digitalSortHelper.isUnsorted('Description') }" (click)="digitalSortHelper.setSort('Description')" translate>NAME <span class="fa" [class]="digitalSortHelper.getSortClass('Description')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !digitalSortHelper.isUnsorted('CustomerItemCode') }" (click)="digitalSortHelper.setSort('CustomerItemCode')" translate>CUSTOMER_ITEM_CODE <span class="fa" [class]="digitalSortHelper.getSortClass('CustomerItemCode')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !digitalSortHelper.isUnsorted('UnitPrice') }" (click)="digitalSortHelper.setSort('UnitPrice')" translate>PRICE <span class="fa" [class]="digitalSortHelper.getSortClass('UnitPrice')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !digitalSortHelper.isUnsorted('LocationLabel') }" (click)="digitalSortHelper.setSort('LocationLabel')" translate>LOCATION <span class="fa" [class]="digitalSortHelper.getSortClass('LocationLabel')"></span></span> |
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !digitalSortHelper.isUnsorted('ShipmentAddress') }" (click)="digitalSortHelper.setSort('ShipmentAddress')" translate>SHIPMENT_ADDRESS <span class="fa" [class]="digitalSortHelper.getSortClass('ShipmentAddress')"></span></span>
                                <span class="sorted d-inline-block mr-1" [ngClass]="{ 'active': !digitalSortHelper.isUnsorted('Status') }" (click)="digitalSortHelper.setSort('Status')" translate>STATUS <span class="fa" [class]="digitalSortHelper.getSortClass('Status')"></span></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4" *ngFor="let item of sortedDigitalRecords">
                            <div class="card mb-4">
                                <div class="card-header">
                                    <h5 class="m-0">{{ item.Description }}</h5>
                                    <small class="d-block text-black-50">{{ item.CustomerItemCode }}</small>
                                </div>
                                <div class="card-body">
                                    <div class="form-row card-text form-group">
                                        <div class="col-5">
                                            <img style="max-width:100%;height:auto;" class="mb-3" src="{{ item.ThumbImage }}" alt="{{ item.CustomerItemCode }}" />

                                            <span class="d-block font-weight-bold" translate>PRICE</span>
                                            <span>{{ item.UnitCost | currency }}</span>
                                        </div>
                                        <div class="col-7">
                                            <span class="d-block font-weight-bold" translate>LOCATION</span>
                                            {{ locationLabelSegment(item.LocationLabel, 0) }} - {{ locationLabelSegment(item.LocationLabel, 1) }}<br />
                                            {{ locationLabelSegment(item.LocationLabel, 2) }} - {{ locationLabelSegment(item.LocationLabel, 3) }}

                                            <span class="d-block font-weight-bold mt-3" translate>STATUS</span>
                                            <small class="">{{ getShipmentStatus(item) }}</small>
                                            <small class="d-block text-info text-hover-info" *ngFor="let link of item.TrackingLinks" [innerHTML]="link"></small>
                                        </div>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Large screen and print view -->
                <table class="table table-hover border d-lg-table d-none d-print-table table-sm">
                    <thead class="bg-ips-gray-white">
                        <tr>
                            <th class="sorted" (click)="digitalSortHelper.setSort('Description')" translate>NAME <span class="fa fa-fw d-print-none" [class]="digitalSortHelper.getSortClass('Description', true)"></span></th>
                            <th class="sorted" (click)="digitalSortHelper.setSort('CustomerItemCode')" translate>CUSTOMER_ITEM_CODE <span class="fa fa-fw d-print-none" [class]="digitalSortHelper.getSortClass('CustomerItemCode', true)"></span></th>
                            <th class="sorted" (click)="digitalSortHelper.setSort('TotalCost')" translate>PRICE <span class="fa fa-fw d-print-none" [class]="digitalSortHelper.getSortClass('TotalCost', true)"></span></th>
                            <th class="sorted" (click)="digitalSortHelper.setSort('LocationLabel')" translate>LOCATION <span class="fa fa-fw d-print-none" [class]="digitalSortHelper.getSortClass('LocationLabel', true)"></span></th>
                            <th class="sorted" (click)="digitalSortHelper.setSort('Status')" translate>STATUS <span class="fa fa-fw d-print-none" [class]="digitalSortHelper.getSortClass('Status', true)"></span></th>
                            <th class="d-print-none"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of sortedDigitalRecords">
                            <td>
                                {{ item.Description }}
                                <img class="d-print-none d-block mt-2" style="max-width:80px;height:auto;" src="{{ item.ThumbImage }}" alt="{{ item.CustomerItemCode }}" />
                                <button class="btn btn-lg btn-default btn-block px-xl-1"
                                        (click)="reorder(item)" translate>
                                    <span class="fa fa-magic fa-button-icon text-muted"></span> Re-Order
                                </button>
                                <div *ngIf="item.NumberInCart > 0" class="col-12 pt-2 text-center">
                                    <span class="badge-pill badge-success font-weight-normal">
                                        {{item.NumberInCart}}
                                    </span>
                                    <span class="ml-1" translate>IN_CART</span>
                                </div>
                            </td>
                            <td>
                                {{ item.CustomerItemCode }}
                            </td>
                            <td>
                                {{ item.UnitPrice | currency }}
                            </td>
                            <td>
                                {{ locationLabelSegment(item.LocationLabel, 0) }} - {{ locationLabelSegment(item.LocationLabel, 1) }}<br />
                                {{ locationLabelSegment(item.LocationLabel, 2) }} - {{ locationLabelSegment(item.LocationLabel, 3) }}
                            </td>
                            <td>
                                <small class="font-italic">{{ getPodStatus(item) }}</small>
                            </td>
                            <td class="d-print-none text-center">
                                <!-- Static -->
                                <div *ngIf="!item.Variable">
                                    <button class="btn btn-sm btn-info" (click)="downloadClicked(item, true)" [disabled]="downloadHasExpired(item)"><span class="fa fa-download mr-2"></span>{{ 'DOWNLOAD' | translate }}</button>
                                    <div class="mt-2" *ngIf="item.PodDownloadUrlExpiration">
                                        <small>{{ 'DOWNLOAD_EXPIRES' | translate }}</small>
                                        <span class="d-block">{{ item.PodDownloadUrlExpiration | date }}</span>
                                    </div>
                                </div>

                                <!-- Variable -->
                                <div *ngIf="item.Variable">
                                    <button class="btn btn-sm btn-info" (click)="downloadClicked(item)" [disabled]="downloadHasExpired(item) || hasInProgressStatus(item) || hasFailedStatus(item)"><span class="fa fa-download mr-2"></span>{{ 'DOWNLOAD' | translate }}</button>
                                    <div class="mt-2" *ngIf="item.PodDownloadUrlExpiration">
                                        <small>{{ 'DOWNLOAD_EXPIRES' | translate }}</small>
                                        <span class="d-block">{{ item.PodDownloadUrlExpiration | date }}</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="digitalItemList.length === 0">
                            <td colspan="6" translate>NO_DIGITAL_PRODUCTS_FOR_ORDER</td>
                        </tr>
                    </tbody>
                </table>
            </tab>

        </tabset>

        <!-- Summary Table, displayed for Print -->
        <div class="d-none d-print-block mt-3">
            <table class="table border table-sm">
                <thead class="bg-ips-gray-white">
                    <tr>
                        <th translate>PRODUCT</th>
                        <th translate>QTY</th>
                        <th translate>PRICE</th>
                        <th translate>TOTAL_CAPITAL</th>
                        <th translate>LOCATION</th>
                        <th translate>SHIPMENT_ADDRESS</th>
                        <th translate>STATUS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of orderItemList">
                        <td>
                            {{ item.Description }}
                            <small class="d-block mt-2">{{ item.CustomerItemCode }}</small>
                        </td>
                        <td>
                            {{ item.Quantity }}
                        </td>
                        <td>
                            {{ item.UnitPrice | currency }}
                        </td>
                        <td>
                            {{ item.TotalCost | currency }}
                        </td>
                        <td>
                            {{ locationLabelSegment(item.LocationLabel, 0) }} - {{ locationLabelSegment(item.LocationLabel, 1) }}<br />
                            {{ locationLabelSegment(item.LocationLabel, 2) }} - {{ locationLabelSegment(item.LocationLabel, 3) }}
                        </td>
                        <td *ngIf="item.DigitalDownload"><span class="font-italic">Available via download</span></td>
                        <td *ngIf="!item.DigitalDownload" [innerHTML]="formatAddressForDisplay(item.ShipToAddress)"></td>
                        <td>
                            <small class="">{{ getShipmentStatus(item) }}</small>
                            <small class="d-block text-info text-hover-info" *ngFor="let link of item.TrackingLinks" [innerHTML]="link"></small>
                        </td>
                    </tr>
                    <tr *ngIf="orderItemList.length === 0">
                        <td colspan="7" translate>NO_PRODUCTS_FOR_ORDER</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
