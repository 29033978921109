////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Sticky Headers - How to use:
//  1. Add <app-sticky-header></app-sticky-header> on component html to apply sticky headers on
//  2. Add attribute to root element - stickyHeader=""
//     This will help with node search performance
//  3. For sticky header node stop add the attribute - stickyHeader="PROMOTION_HEADER" stickyHeaderImage="campaign-promo-header-icon"
//     stickyHeader = the text that you want displayed; this will translate if needed
//     stickyHeaderImage = must be blank or a css class that has a background-image set
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { Component, OnInit, HostListener, Input, ViewChild, ContentChild, TemplateRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
declare let $: any;

@Component({
    selector: "app-sticky-header",
    templateUrl: "./sticky-header.component.html",
    styleUrls: ["./sticky-header.component.scss"]
})
export class StickyHeaderComponent implements OnInit {

    @Input() offset = 0;

    public showHeader = false;
    public stickyHeaderItems = [];
    public dynamicHeight = 0;
    @ContentChild("stickyItemTemplate") stickyItemTemplate: TemplateRef<any>;

    constructor(private translateService: TranslateService) { }

    ngOnInit() {
    }

    @HostListener("window:scroll", ["$event"])
    checkScroll(test: any) {

        let node: any = null;
        let nodes = $("[stickyHeader]");
        let scrollY = 0;
        this.stickyHeaderItems = [];

        if (window.scrollY) {
            scrollY = window.scrollY;
        } else if (window.pageYOffset) {
            //Only for IE9+
            scrollY = window.pageYOffset;
        }

        scrollY += Number(this.offset) + this.dynamicHeight;

        for (let i = 0; i < nodes.length; i++) {
            if (!!node === false ||
                (scrollY > $(nodes[i]).offset()["top"] &&
                scrollY - $(nodes[i]).offset()["top"] < scrollY - $(node).offset()["top"] &&
                scrollY - $(nodes[i]).offset()["top"] > 0)) {
                node = <any>nodes[i];
            }
        }

        if (!!node) {
            this.showHeader = false;
            let continueSearch = true;
            let parentNode = $(node).closest("[stickyHeader]");
            while (continueSearch) {
                if (parentNode && parentNode.attr("stickyHeader") !== "") {
                    if (parentNode.attr("stickyHeader2") !== undefined) {
                        this.stickyHeaderItems.unshift([{
                            text: this.translateService.instant(parentNode.attr("stickyHeader")),
                            imageClass: parentNode.attr("stickyHeaderImage") ? parentNode.attr("stickyHeaderImage") : ""
                        }, {
                            text: this.translateService.instant(parentNode.attr("stickyHeader2")),
                            imageClass: parentNode.attr("stickyHeaderImage2") ? parentNode.attr("stickyHeaderImage2") : ""
                        }]);

                    } else {
                        this.stickyHeaderItems.unshift([{
                            text: this.translateService.instant(parentNode.attr("stickyHeader")),
                            imageClass: parentNode.attr("stickyHeaderImage") ? parentNode.attr("stickyHeaderImage") : ""
                        }]);
                    }
                    this.showHeader = true;
                    parentNode = parentNode.parent().closest("[stickyHeader]");
                } else {
                    continueSearch = false;
                    //Store off the size of the height so we can better determine when header should be changed
                    this.dynamicHeight = $("app-sticky-header .card").outerHeight(true);
                }
            }
        }
    }
}
