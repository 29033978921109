var ResizeHandle = /** @class */ (function () {
    function ResizeHandle(parent, renderer, type, css, onMouseDown) {
        var _this = this;
        this.parent = parent;
        this.renderer = renderer;
        this.type = type;
        this.css = css;
        this.onMouseDown = onMouseDown;
        // generate handle div
        var handle = renderer.createElement("div");
        renderer.addClass(handle, "ng-resizable-handle");
        renderer.addClass(handle, css);
        // add default diagonal for se handle
        if (type === "se") {
            renderer.addClass(handle, "ng-resizable-diagonal");
        }
        // append div to parent
        if (this.parent) {
            parent.appendChild(handle);
        }
        // create and register event listener
        this._onResize = function (event) { onMouseDown(event, _this); };
        handle.addEventListener("mousedown", this._onResize, { passive: false });
        handle.addEventListener("touchstart", this._onResize, { passive: false });
        // done
        this._handle = handle;
    }
    ResizeHandle.prototype.dispose = function () {
        this._handle.removeEventListener("mousedown", this._onResize);
        this._handle.removeEventListener("touchstart", this._onResize);
        if (this.parent) {
            this.parent.removeChild(this._handle);
        }
        this._handle = null;
        this._onResize = null;
    };
    Object.defineProperty(ResizeHandle.prototype, "el", {
        get: function () {
            return this._handle;
        },
        enumerable: true,
        configurable: true
    });
    return ResizeHandle;
}());
export { ResizeHandle };
