import { Component, OnInit, } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-ips-select-list",
    templateUrl: "./select-list.component.html",
    styleUrls: ["./select-list.component.scss"]
})

export class SelectListComponent implements OnInit {
    // ----- DATA -----
    title: string;
    items: any[] = [];
    labelField: string;
    multiSelect = false;
    selectedIds: number[] = [];

    public internalItems: any[];

    constructor(
        private modalInstance: NgbActiveModal
    ) { }

    //
    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    public confirm() {
        this.modalInstance.close(this.internalItems.filter(item => item.selected));
    }

    public selectItem(index: number) {
        this.internalItems[index].selected = !this.internalItems[index].selected;
    }

    public selectedItemCount() {
        return this.internalItems.filter(item => item.selected).length;
    }

    public sortItems() {
        this.internalItems = this.internalItems.sort((a, b) => {
            if (a.selected && !b.selected) {
                return -1;
            } else if (a.selected && b.selected || !a.selected && !b.selected) {
                return 0;
            } else {
                return 1;
            }
        });
    }

    public selectInitialItems() {
        if (this.selectedIds && this.selectedIds.length) {
            this.internalItems = this.internalItems.map((item) => {
                if (this.selectedIds.indexOf(item.Id) >= 0) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }

                return item;
            });
        }
    }

    // ----- ON INIT -----
    //
    ngOnInit() {
        console.log("init!");
        this.internalItems = JSON.parse(JSON.stringify(this.items));
        this.selectInitialItems();
        this.sortItems();
    }
}
