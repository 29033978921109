import { Component, OnInit, NgZone, OnDestroy, ViewChild } from "@angular/core";
import { UserTheme, UserProfile, ActiveProfileService } from "imagine-ui-ng-core";
import { ProfileService, IdleKeepAliveService, IpsHeaderComponent } from "imagine-ui-ng-quick-start";
import { AuthService } from "imagine-ui-ng-security";
import { IpsModalService } from "imagine-ui-ng-modal";
import { StateService } from "@uirouter/core";
import { environment } from "../../environments/environment";
import { SubscriptionLike as ISubscription } from "rxjs";
import { CartService, CartDataModel } from "../imagine-ui-ng-shop";
import { GeneralSettingsService } from "../shared/service/general-settings.service";


@Component({
    selector: "app-main",
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.scss"]
})
export class MainComponent implements OnInit, OnDestroy {
    @ViewChild(IpsHeaderComponent) private header: IpsHeaderComponent;
    public IsNonSso: boolean;
    public OktaUserSettingsUrl: string;
    public Theme: UserTheme;
    public Profile: UserProfile;
    public BannerUri: string;
    public DefaultSupportEmail: string;
    private logoutSubscription: ISubscription;
    public BrandClass = "enterprise-branded";
    public enableSignPlan = false;

    constructor(private ipsModal: IpsModalService, private profileService: ProfileService, private zone: NgZone, private $state: StateService,
        authService: AuthService, private activeProfileService: ActiveProfileService, private idleKeepAliveService: IdleKeepAliveService, private cartService: CartService,
        private settingsService: GeneralSettingsService) {
        let settings = environment;

        let idToken = authService.getIdToken();

        this.Profile = activeProfileService.profile;
        this.Theme = activeProfileService.theme;
        this.BrandClass = this.Theme.IsRamiBranded ? this.BrandClass : "theme-branded";
        this.DefaultSupportEmail = "mailto:" + settings.defaultSupportEmail;
        if (this.Theme && this.Theme.BannerMedia) {
            this.BannerUri =
                `${this.Theme.BannerMedia.ResourceUri}?BusinessIdentity=${activeProfileService.businessIdentity
                }&idToken=${idToken}`;
        }
        this.OktaUserSettingsUrl = settings.oktaUserSettingsUrl;
        this.IsNonSso = this.profileService.isNonSso();
    }

    ngOnInit() {
        const viewCartPermission = "ViewCart";

        this.logoutSubscription = this.idleKeepAliveService.onSignOutNeeded.subscribe(() => {
             this.logout();
        });
        this.idleKeepAliveService.Start();

        if (this.activeProfileService.permissions.find((val, index) => val.Name === viewCartPermission)) {
            this.cartService.getMyCurrentCart().then((cartData: CartDataModel) => {
                if (cartData !== null && cartData !== undefined) {
                    this.header.UpdateCartQuantity(cartData.TotalQuantity);
                }
            });
        }

        this.cartService.onCartUpdated.subscribe((data) => {
            if (data !== null && data !== undefined) {
                this.header.UpdateCartQuantity(data.TotalQuantity);
            }
        });

        this.getSignPlanLayoutSetting();
    }

    ngOnDestroy() {
        this.idleKeepAliveService.Stop();
        this.logoutSubscription.unsubscribe();
    }

    private getSignPlanLayoutSetting(): Promise<any> {
        return this.settingsService.canEditSignPlanLayout().then((response: boolean) => {
            this.enableSignPlan = response;
        });
    }

    public handleLogout(response: string) {
        let loginType = response;
        let targetState = "public.login";
        if (loginType !== undefined && loginType !== null) {
            loginType = loginType.toLowerCase();
            let isNonSso = (loginType === "login" || loginType === "login_internal");
            if (!isNonSso) {
                targetState = "public.logout";
            }
        }
        this.zone.run(() => {
            let current = this.$state.current;
            this.$state.go(targetState);

            //close open modal
            this.ipsModal.closeModalInstances();
        });
    }
    public logout() {
        this.profileService.signOut().then((response) => {
            this.handleLogout(response);
        }
        ).catch((response) => {
            console.log("logout failed");
            this.handleLogout(response);
        });
    }
}
