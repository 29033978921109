import { Component, OnInit } from "@angular/core";
import { ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { LocationService, LocationGroupService, SpaceService, FixtureService, FixtureGroupService, LocationByLocationGroupService, LocationSearchInfo, AlternateAddressService } from "../../imagine-ui-ng-store-profile/index";
import { CampaignService, PromotionService } from "../../imagine-ui-ng-campaign/index";
import { LocationModel } from "../../imagine-ui-ng-store-profile/index";
import { TranslateService } from "@ngx-translate/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "../../../environments/environment";
import { DatePipe } from "@angular/common";
import cloneDeep from "lodash-es/cloneDeep";
import { MarketGroupService } from "../../market/service/market-group.service";
import { SurveyService } from "../../imagine-ui-ng-survey/service/survey.service";
import { ShipMethodPreferenceService, ItemService } from "../../imagine-ui-ng-shop/index";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

interface ModelWithLabel {
    Id: number;
    Name: string;
    Label: string;
    LabelRow2: string;
    LabelRow2Bold?: string;
    Selected?: boolean;
}

interface FixtureModelWithLabel extends ModelWithLabel {
    Model: string;
}

interface LocationModelWithLabel extends LocationModel {
    Label: string;
}

interface CampaignModelWithLabel extends ModelWithLabel {
    StartDate: Date;
    EndDate: Date;
}

interface PromotionModelWithLabel extends CampaignModelWithLabel {
    CampaignId: number;
    CampaignName: string;
}

interface SearchConfig {
    addAll: boolean;
    allLabel: string;
    search: string;
    filterId: string;
    listOfLocationIds: number[];
    locationGroupId: string;
    locationSubgroupId: string;
    exportedOnly: boolean;
    activeOnly: boolean;
    currentFutureOnly: boolean;
    listOfData: ModelWithLabel[];
    listOfDataSelected: ModelWithLabel[];
    modalTitle: string;
    multiSelection: boolean;
    idToDisable: number;
    surveyId: string;
    selectedIds: number[];
    preFilteredList: any[];
    orderId: string;
    locationGroupRule: number;
    itemCategoryLevel: number;
    sortBy: string;
    sortDir: string;
}


@Component({
    selector: "app-search-modal",
    templateUrl: "./search-modal.component.html",
    styleUrls: ["./search-modal.component.scss"]
})

export class SearchModalComponent implements OnInit {

    private allLabel = "ALL_UPPERCASE";
    private locationLabel = "LOCATION";
    private spaceLabel: string;
    private locationGroupLabel: string;
    private fixtureLabel: string;
    private fixtureGroupLabel: string;
    private locationLabelPlural = "LOCATIONS";
    private locationGroupLabelPlural: string;
    private spaceLabelPlural: string;
    private fixtureLabelPlural: string;
    private fixtureGroupLabelPlural: string;
    public loadingLabel = "LOADING_DATA";
    private selectLocationLabel = "SELECT_LOCATION";
    private selectLocationGroupLabel = "LOCATION_GROUPS";
    private selectSpaceLabel = "SELECT_SPACE";
    private selectFixtureLabel = "SELECT_FIXTURE";
    private selectFixtureGroupLabel = "SELECT_FIXTURE_GROUP";
    private selectCampaignLabel = "SELECT_CAMPAIGN";
    private selectPromotionLabel = "SELECT_PROMOTION";
    private selectShipMethodLabel = "SELECT_DELIVERY_OPTIONS";
    private cancelLabel = "CANCEL";
    private startEndLabel = "START_END_DATE";
    private selectMarketGroupLabel = "SELECT_LOCATION_SEARCH";
    private marketGroupLabel = "LOCATION_SEARCH";
    private marketGroupLabelPlural = "LOCATION_SEARCHES";
    private selectSurveyLabel = "SELECT_SURVEY";
    private shipMethodLabel = "DELIVERY_OPTION";
    private shipMethodLabelPlural = "DELIVERY_OPTIONS";
    private surveyLabel = "SURVEY";
    private surveyLabelPlural = "SURVEYS";
    private selectSectionLabel = "SELECT_SECTION";
    private sectionLabel = "SECTION";
    private sectionLabelPlural = "SECTIONS";
    private alternateAddressLabel = "ALTERNATE_ADDRESS";
    private alternateAddressLabelPlural = "ALTERNATE_ADDRESSES";
    private selectAlternateAddressLabel = "SELECT_ALTERNATE_ADDRESS";
    private locationAddressLabel = "LOCATION_ADDRESS";
    public sortOptions: any[] = [
        {
            fieldLabel: "Name",
            field: "name",
            direction: "asc",
            directionLabel: "A - Z"
        },
        {
            fieldLabel: "Name",
            field: "name",
            direction: "desc",
            directionLabel: "Z - A"
        },
        {
            fieldLabel: "In-Store Date",
            field: "instoredate",
            direction: "desc",
            directionLabel: "Newest first"
        },
        {
            fieldLabel: "In-Store Date",
            field: "instoredate",
            direction: "asc",
            directionLabel: "Oldest first"
        },
        {
            fieldLabel: "Start Date",
            field: "startdate",
            direction: "desc",
            directionLabel: "Newest first"
        },
        {
            fieldLabel: "Start Date",
            field: "startdate",
            direction: "asc",
            directionLabel: "Oldest first"
        },
        {
            fieldLabel: "End Date",
            field: "ebddate",
            direction: "desc",
            directionLabel: "Newest first"
        },
        {
            fieldLabel: "End Date",
            field: "enddate",
            direction: "asc",
            directionLabel: "Oldest first"
        }
    ];


    private campaignLabel: string;
    private promotionLabel: string;
    private campaignLabelPlural: string;
    private promotionLabelPlural: string;

    public modalTitle: string;
    public searchLabel: string;
    public searchLabelPlural: string;

    public busy: boolean;
    public listSearch: ListSearchHelper;
    public dataService;
    public promise: Promise<any>;
    public resolve: SearchConfig;
    public searchType: string;
    private filterListOfLocationsIds: number[];
    private locGroupId: string;
    private orderId: string;
    private subGroupId: string;
    private exportedOnly = false;
    private currentFutureOnly = false;
    private activeOnly = false;
    public hideTotals = false;
    public multiSelection = false;
    public dataList: ModelWithLabel[];
    private surveyId: string;
    public query: string;
    private useFilteredList = false;
    private querySubject: Subject<string> = new Subject<string>();
    public pluralMapping: { [k: string]: string } = {
        "=1": "Campaign",
        "other": "Campaigns"
    };

    public idToDisable = 0;
    private selectedIds: number[] = [];
    private preFilteredList: any[] = [];
    public selectedSortOption = this.sortOptions[0];

    constructor(private locationService: LocationService, private listSearchHelper: ListSearchHelper, private translateService: TranslateService,
        private locationGroupService: LocationGroupService, private spaceService: SpaceService, private fixtureService: FixtureService,
        private fixtureGroupService: FixtureGroupService, private activeModal: NgbActiveModal, private campaignService: CampaignService,
        private promotionService: PromotionService, private locGroupLocationService: LocationByLocationGroupService, private datePipe: DatePipe,
        private marketGroupService: MarketGroupService, private surveyService: SurveyService, private shipMethodService: ShipMethodPreferenceService,
        private alternateAddressService: AlternateAddressService, private itemService: ItemService) {
        this.querySubject.pipe(
            debounceTime(350),
            distinctUntilChanged())
            .subscribe((search: string) => {
                this.getListData(search);
            });
    }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
        this.useFilteredList = this.resolve.preFilteredList ? this.resolve.preFilteredList.length > 0 : false;


        this.searchType = this.resolve.search.toLowerCase();
        switch (this.searchType) {
            case "shipmethod":
                this.modalTitle = this.selectShipMethodLabel;
                this.searchLabel = this.shipMethodLabel;
                this.searchLabelPlural = this.shipMethodLabelPlural;
                this.dataService = this.shipMethodService;
                this.multiSelection = this.resolve.multiSelection;
                this.selectedIds = this.resolve.selectedIds;
                break;
            case "itemcategory":
                this.modalTitle = "Select a Category";
                this.searchLabel = "Category";
                this.searchLabelPlural = "Categories";
                this.dataService = this.itemService;
                this.preFilteredList = this.resolve.preFilteredList ? this.resolve.preFilteredList : [];
                break;
            case "locationgroup":
                this.modalTitle = this.selectLocationGroupLabel;
                this.searchLabel = this.locationGroupLabel;
                this.searchLabelPlural = this.locationGroupLabelPlural;
                this.dataService = this.locationGroupService;
                break;
            case "locationbyorder":
            case "location":
                this.modalTitle = this.selectLocationLabel;
                this.searchLabel = this.locationLabel;
                this.searchLabelPlural = this.locationLabelPlural;
                this.dataService = this.locationService;
                this.filterListOfLocationsIds = this.searchType === "location" ? this.resolve.listOfLocationIds : [];
                this.allLabel = this.resolve.allLabel ? this.translateService.instant(this.resolve.allLabel) : this.allLabel;
                break;
            case "space":
                this.modalTitle = this.selectSpaceLabel;
                this.searchLabel = this.spaceLabel;
                this.searchLabelPlural = this.spaceLabelPlural;
                this.dataService = this.spaceService;
                this.preFilteredList = this.resolve.preFilteredList ? this.resolve.preFilteredList : [];
                break;
            case "fixture":
                this.modalTitle = this.selectFixtureLabel;
                this.searchLabel = this.fixtureLabel;
                this.searchLabelPlural = this.fixtureLabelPlural;
                this.dataService = this.fixtureService;
                break;
            case "fixturegroup":
                this.modalTitle = this.selectFixtureGroupLabel;
                this.searchLabel = this.fixtureGroupLabel;
                this.searchLabelPlural = this.fixtureGroupLabelPlural;
                this.dataService = this.fixtureGroupService;
                break;
            case "campaign":
                this.modalTitle = this.selectCampaignLabel;
                this.searchLabel = this.campaignLabel;
                this.searchLabelPlural = this.campaignLabelPlural;
                this.dataService = this.campaignService;
                this.exportedOnly = this.resolve.exportedOnly;
                this.currentFutureOnly = this.resolve.currentFutureOnly;
                this.activeOnly = this.resolve.activeOnly;
                this.idToDisable = this.resolve.idToDisable;
                this.preFilteredList = this.resolve.preFilteredList ? this.resolve.preFilteredList : [];
                break;
            case "allpromotion":
            case "promotion":
                this.modalTitle = this.selectPromotionLabel;
                this.searchLabel = this.promotionLabel;
                this.searchLabelPlural = this.promotionLabelPlural;
                this.dataService = this.promotionService;
                break;
            case "listbylocgroup":
                this.modalTitle = this.locationLabel;
                this.searchLabel = this.locationLabel;
                this.searchLabelPlural = this.locationLabelPlural;
                this.dataService = this.locGroupLocationService;
                this.locGroupId = this.resolve.locationGroupId;
                this.subGroupId = this.resolve.locationSubgroupId;
                break;
            case "listbylocation":
                this.modalTitle = this.locationLabel;
                this.searchLabel = this.locationLabel;
                this.searchLabelPlural = this.locationLabelPlural;
                this.dataService = this.locationService;
                this.filterListOfLocationsIds = this.resolve.listOfLocationIds;
                break;
            case "customdataoption":
                this.modalTitle = this.resolve.modalTitle;
                this.hideTotals = true;
                this.multiSelection = this.resolve.multiSelection;
                //initialize selected values
                this.resolve.listOfData.forEach((item: ModelWithLabel) => {
                    if (this.resolve.listOfDataSelected && this.resolve.listOfDataSelected.some(selectedItem => item.Id === selectedItem.Id)) {
                        item.Selected = true;
                    }
                });

                //sort return results for multi selection
                if (this.multiSelection) {
                    this.dataList = cloneDeep(this.resolve.listOfData);
                }
                break;
            case "marketgroup":
                this.modalTitle = this.selectMarketGroupLabel;
                this.searchLabel = this.marketGroupLabel;
                this.searchLabelPlural = this.marketGroupLabelPlural;
                this.dataService = this.marketGroupService;
                break;
            case "survey":
                this.modalTitle = this.selectSurveyLabel;
                this.searchLabel = this.surveyLabel;
                this.searchLabelPlural = this.surveyLabelPlural;
                this.dataService = this.surveyService;
                break;
            case "surveysection":
                this.modalTitle = this.selectSectionLabel;
                this.searchLabel = this.sectionLabel;
                this.searchLabelPlural = this.sectionLabelPlural;
                this.dataService = this.surveyService;
                this.surveyId = this.resolve.surveyId;
                this.allLabel = this.translateService.instant(this.resolve.allLabel);
                break;
            case "alternateaddress":
                this.modalTitle = this.selectAlternateAddressLabel;
                this.searchLabel = this.alternateAddressLabel;
                this.searchLabelPlural = this.alternateAddressLabelPlural;
                this.dataService = this.alternateAddressService;
                break;
        }

         this.listSearch = this.listSearchHelper.getListSearchHelper(this.dataService);
         this.busy = this.listSearch.busy;



        switch (this.searchType) {
            case "allpromotion":
                this.promotionService.head().then((count: number) => {
                    this.listSearch.TotalRecords = count;
                });
                break;
            case "promotion":
                this.promotionService.headByCampaignId(this.resolve.filterId).then((count: number) => {
                    this.listSearch.TotalRecords = count;
                });
                break;
            case "promotion":
                this.promotionService.headByCampaignId(this.resolve.filterId).then((count: number) => {
                    this.listSearch.TotalRecords = count;
                });
                break;
            case "location":
                this.setLocationTotals(this.filterListOfLocationsIds);
                break;
            case "shipmethod":
                //this is all handled in getListData now
                break;
            case "itemcategory":
                if (this.useFilteredList === true) {
                    this.listSearch.TotalRecords = this.preFilteredList.length;
                } else {
                    this.itemService.getAllCategories({ searchText: "", chunkIndex: 0, businessIdentity: this.listSearchHelper.activeProfileService.businessIdentity }, "Item/GetAllCategories").then((result) => {
                        this.listSearch.TotalRecords = result.ResultChunkAttributes.TotalRecords;
                    });
                }
                break;
            case "listbylocation":
                this.listSearch.TotalRecords = this.filterListOfLocationsIds.length;
                break;
            case "listbylocgroup":
                this.locGroupLocationService.headByLocationGroup(this.locGroupId, this.subGroupId).then((count: number) => {
                    this.listSearch.TotalRecords = count;
                });
                break;
            case "campaign":
                if (this.useFilteredList === true) {
                    this.listSearch.TotalRecords = this.preFilteredList.length;
                } else {
                    if (this.exportedOnly || this.activeOnly || this.currentFutureOnly) {
                        // Use a temp listSearch object so we don't mess up the main search variables.
                        let tempListSearch = this.listSearchHelper.getListSearchHelper(this.dataService);
                        tempListSearch
                            .searchHelper(
                                <SearchInfo>{
                                    searchText: "",
                                    recordCount: 1,
                                    additionalQueryParams: [
                                        { param: "ShowExportedOnly", paramValue: this.exportedOnly ? "true" : "false" },
                                        { param: "ShowActiveOnly", paramValue: this.activeOnly ? "true" : "false" },
                                        { param: "ShowCurrentFutureOnly", paramValue: this.currentFutureOnly ? "true" : "false" }
                                    ]
                                },
                                undefined,
                                "Campaign/SimpleCampaign/Search").then(() => {
                                    this.listSearch.TotalRecords = tempListSearch.ResultChunkAttributes.TotalRecords;
                                });
                    } else {
                        this.dataService.head().then((count: number) => {
                            this.listSearch.TotalRecords = count;
                        });
                    }
                }
                break;
            case "customdataoption":
                break;
            case "surveysection":
                this.dataService.getSurveySections(this.surveyId).then((response) => {
                    this.listSearch.TotalRecords = response.length;
                });
                break;
            case "locationbyorder":
                this.locationService.headByOrder(this.resolve.orderId).then((count: number) => {
                    this.listSearch.TotalRecords = count;
                });
                break;
            default:
                if (this.useFilteredList === true) {
                    this.listSearch.TotalRecords = this.preFilteredList.length;
                } else {
                    this.dataService.head().then((count: number) => {
                        this.listSearch.TotalRecords = count;
                    });
                }
                break;
        }

        this.getListData("");
    }

    private translateText() {
        this.translateService.get(["FOUND_OF", "TOTAL", "LOAD_MORE_RESULTS", "CREATE_NEW_SPACE", "ALL_UPPERCASE", "SELECT_LOCATION", "CANCEL", "LOADING_DATA", "LOCATION", "LOCATIONS",
            "SELECT_LOCATION", "LOCATION_GROUPS", "SELECT_LOCATION_FEATURE", "SELECT_SPACE", "SELECT_FIXTURE", "SELECT_FIXTURE_GROUP", "FIXTURES", "FIXTURE", "SPACE", "SPACES", "FIXTURE_GROUPS",
            "SELECT_CAMPAIGN", "CAMPAIGNS", "SELECT_PROMOTION", "PROMOTIONS", "PROMOTION", "CAMPAIGN", "FIXTURE_GROUP", "LOCATION_GROUP", "START_END_DATE", "SELECT_LOCATION_SEARCH",
            "LOCATION_SEARCH", "LOCATION_SEARCHES", "SELECT_SURVEY", "SURVEY", "SURVEYS", "SELECT_SECTION", "SECTION", "SECTIONS", "DELIVERY_OPTION", "DELIVERY_OPTIONS",
            "SELECT_ALTERNATE_ADDRESS", "ALTERNATE_ADDRESS", "ALTERNATE_ADDRESSES"]).subscribe((res: [string]) => {
                this.allLabel = res["ALL_UPPERCASE"];
                this.selectLocationLabel = res["SELECT_LOCATION"];
                this.cancelLabel = res["CANCEL"];
                this.loadingLabel = res["LOADING_DATA"];
                this.locationLabel = res["LOCATION"];
                this.locationLabelPlural = res["LOCATIONS"];
                this.spaceLabel = res["SPACE"];
                this.spaceLabelPlural = res["SPACES"];
                this.fixtureLabel = res["FIXTURE"];
                this.fixtureLabelPlural = res["FIXTURES"];
                this.locationGroupLabel = res["LOCATION_GROUP"];
                this.locationGroupLabelPlural = res["LOCATION_GROUPS"];
                this.fixtureGroupLabel = res["FIXTURE_GROUP"];
                this.fixtureGroupLabelPlural = res["FIXTURE_GROUPS"];
                this.selectLocationGroupLabel = res["SELECT_LOCATION_FEATURE"];
                this.selectSpaceLabel = res["SELECT_SPACE"];
                this.selectFixtureLabel = res["SELECT_FIXTURE"];
                this.selectFixtureGroupLabel = res["SELECT_FIXTURE_GROUP"];
                this.selectCampaignLabel = res["SELECT_CAMPAIGN"];
                this.campaignLabel = res["CAMPAIGN"];
                this.campaignLabelPlural = res["CAMPAIGNS"];
                this.selectPromotionLabel = res["SELECT_PROMOTION"];
                this.promotionLabel = res["PROMOTION"];
                this.promotionLabelPlural = res["PROMOTIONS"];
                this.startEndLabel = res["START_END_DATE"];
                this.selectMarketGroupLabel = res["SELECT_LOCATION_SEARCH"];
                this.marketGroupLabel = res["LOCATION_SEARCH"];
                this.marketGroupLabelPlural = res["LOCATION_SEARCHES"];
                this.selectSurveyLabel = res["SELECT_SURVEY"];
                this.surveyLabel = res["SURVEY"];
                this.surveyLabelPlural = res["SURVEYS"];
                this.selectSectionLabel = res["SELECT_SECTION"];
                this.sectionLabel = res["SECTION"];
                this.sectionLabelPlural = res["SECTIONS"];
                this.shipMethodLabel = res["DELIVERY_OPTION"];
                this.shipMethodLabelPlural = res["DELIVERY_OPTIONS"];
                this.selectAlternateAddressLabel = res["SELECT_ALTERNATE_ADDRESS"];
                this.alternateAddressLabel = res["ALTERNATE_ADDRESS"];
                this.alternateAddressLabelPlural = res["ALTERNATE_ADDRESSES"];


            });
    }

    private addAllOption() {
        if (this.resolve.addAll && this.listSearch.resultList.length > 0 && this.listSearch.resultList[0].Id !== 0) {
            this.listSearch.resultList.unshift({
                Id: 0,
                Name: this.allLabel,
                Label: this.allLabel
            });
        }
    }

    public onQueryChange(query: string) {
        this.query = query;
        this.querySubject.next(query);
    }

    public sortOptionSelected(event: any) {
        this.selectedSortOption = event;
        this.getListData(this.query);
    }

    public getListData(search: string) {
        if (this.busy) {
            return;
        }

        if (!search) {
            search = "";
        }
        let route = "";
        let sortOption = this.selectedSortOption || {
            field: "name",
            direction: "asc"
        };
        switch (this.searchType) {
            case "locationgroup":
                let locGroupSearchInfo: SearchInfo = {
                    searchText: search,
                };

                if (this.resolve.locationGroupRule) {
                    locGroupSearchInfo.additionalQueryParams = [
                        {
                            param: "Rule",
                            paramValue: this.resolve.locationGroupRule.toString()
                        }
                    ];
                }
                this.promise = this.listSearch.searchHelper(locGroupSearchInfo, undefined, "LocationGroup/SimpleSearch");
                break;
            case "campaign":
                if (this.useFilteredList === true) {
                    let returnPromise = new Promise((resolve, reject) => {
                        resolve(this.preFilteredList);
                    });
                    this.promise = returnPromise;
                } else {
                    this.promise = this.listSearch.searchHelper(<SearchInfo>{
                        searchText: search, additionalQueryParams: [
                            { param: "ShowActiveOnly", paramValue: this.activeOnly ? "true" : "false" },
                            { param: "ShowExportedOnly", paramValue: this.exportedOnly ? "true" : "false" },
                            { param: "ShowCurrentFutureOnly", paramValue: this.currentFutureOnly ? "true" : "false" },
                            { param: "SortBy", paramValue: sortOption.field },
                            { param: "SortDirection", paramValue: sortOption.direction }
                        ]
                    }, undefined, "Campaign/SimpleCampaign/Search");
                }
                break;
            case "allpromotion":
                this.promise = this.listSearch.searchHelper(<SearchInfo>{ searchText: search, recordCount: Number(environment.chunkSize) }, undefined, `Promotion/Search`);
                break;
            case "promotion":
                this.promise = this.listSearch.searchHelper(<SearchInfo>{ searchText: search }, undefined, `Campaign/${this.resolve.filterId}/PromotionHeader/Search`);
                break;
            case "listbylocation":
                let searchInfo = <LocationSearchInfo>{ searchText: search, LocationFilterType: "LocationId", LocationIds: this.filterListOfLocationsIds, showUnassignedItems: false, recordCount: Number(environment.chunkSize) };
                route = `${this.locationService.urlApiBaseProductPlusVersion}Location/FilteredSearch/LocationId`;
                this.promise = this.listSearch.searchHelper(searchInfo, undefined, route, true);
                break;
            case "location":
                this.promise = this.getLocationPromise(search, this.filterListOfLocationsIds);
                break;
            case "listbylocgroup":
                this.promise = this.listSearch.searchHelper(<SearchInfo>{ searchText: search, id: Number(this.locGroupId), filterOption: this.subGroupId });
                break;
            case "customdataoption":
                break;
            case "surveysection":
                route = `Survey/${this.surveyId}/SurveySection/Search`;
                this.promise = this.listSearch.searchHelper(<LocationSearchInfo>{ searchText: search }, undefined, route, false);
                break;
            case "shipmethod":
                //always search one time for all (with empty search text) then filter
                this.promise = this.listSearch.searchHelper({ searchText: "" });
                break;
            case "itemcategory":
                if (this.useFilteredList === true) {
                    let returnPromise = new Promise((resolve, reject) => {
                        resolve(this.preFilteredList);
                    });
                    this.promise = returnPromise;
                } else {
                    let level = this.resolve.itemCategoryLevel || 0;
                    route = `Item/GetAllCategories`;
                    let mySearchInfo = <SearchInfo>{
                        searchText: search,
                        additionalQueryParams: [
                            {
                                param: "level",
                                paramValue: level.toString()
                            }
                        ]
                    };
                    this.promise = this.listSearch.searchHelper(mySearchInfo, undefined, route, false);
                }
                break;
            case "locationbyorder":
                let searchByOrder = <LocationSearchInfo>{ searchText: search, recordCount: Number(environment.chunkSize) };
                route = `Order/${this.resolve.orderId}/Location/Search`;
                this.promise = this.listSearch.searchHelper(searchByOrder, undefined, route, false);
                break;
            case "alternateaddress":
                this.promise = this.listSearch.searchHelper({ searchText: search, filterOption: "1" }, undefined, "AlternateAddress/Address/Search", false);
                break;
            case "space":
                if (this.useFilteredList === true) {
                    let returnPromise = new Promise((resolve, reject) => {
                        resolve(this.preFilteredList);
                    });
                    this.promise = returnPromise;
                } else {
                    this.promise = this.listSearch.searchHelper({ searchText: search });
                }
                break;
            default:
                this.promise = this.listSearch.searchHelper({ searchText: search });
                break;
        }

        switch (this.searchType) {
            case "shipmethod":
                //only perform service call first time
                if (this.listSearch.resultList.length <= 0) {
                    this.promise.then(() => {
                        /*const results = this.listSearch.resultList.filter((item, index) => {
                            const foundIndex = this.selectedIds.findIndex((val, i) => {
                                return val === item.Id;
                            });

                            return foundIndex < 0;
                        });*/

                        this.listSearch.resultList.forEach((item: ModelWithLabel) => {
                            const foundIndex = this.selectedIds.findIndex((val, i) => {
                                return val === item.Id;
                            });

                            item.Selected = foundIndex >= 0;
                        });

                        this.dataList = this.listSearch.resultList;
                        //this.listSearch.resultList = results;

                        this.listSearch.ResultChunkAttributes.RecordCount = this.listSearch.resultList.length;
                        this.listSearch.ResultChunkAttributes.TotalRecords = this.listSearch.resultList.length;
                        this.listSearch.TotalRecords =  this.listSearch.ResultChunkAttributes.TotalRecords;
                    });
                }

                if (this.dataList) {
                    this.listSearch.resultList = this.dataList.filter(q => search === "" || q.Label.toLowerCase().indexOf(search.toLowerCase()) > -1);
                }
                this.listSearch.ResultChunkAttributes.TotalRecords = this.listSearch.resultList.length;
                break;
            case "listbylocation":
            case "listbylocgroup":
            case "locationbyorder":
            case "location":
                this.promise.then(() => {
                    this.addAllOption();
                    this.listSearch.resultList.forEach(function (item: LocationModelWithLabel) {
                        if (item.Id !== 0) {
                            item.Label = `${item.LocationIdentifier} - ${item.Name} ${item.ReferenceIdentifier ? "#" + item.ReferenceIdentifier + "#" : "-"} ${item.Addresses[0].City}, ${item.Addresses[0].StateProvince}`;
                        }
                    });
                });
                break;
            case "fixture":
                this.promise.then(() => {
                    this.addAllOption();
                    this.listSearch.resultList.forEach(function (item: FixtureModelWithLabel) {
                        item.Label = item.Model ? item.Name + " - " + item.Model : item.Name;
                    });

                });
                break;
            case "space":
                this.promise.then(() => {
                    if (this.useFilteredList === true) {
                        this.setPreFilteredCounts(search);
                    }
                    this.addAllOption();
                });
                break;
            case "campaign":
                this.promise.then(() => {
                    if (this.useFilteredList === true) {
                        this.setPreFilteredCounts(search);
                    }
                    this.listSearch.resultList.forEach((item: CampaignModelWithLabel) => {
                        item.Label = item.Name;
                        item.LabelRow2 = `${this.datePipe.transform(item.StartDate, "mediumDate")} - ${this.datePipe.transform(item.EndDate, "mediumDate")}`;
                        item.LabelRow2Bold = this.startEndLabel;
                    });
                    this.addAllOption();
                });
                break;
            case "customdataoption":
                this.listSearch.resultList = this.resolve.listOfData.filter(q => search === "" || q.Label.toLowerCase().indexOf(search.toLowerCase()) > -1);
                break;
            case "allpromotion":
                this.promise.then(() => {
                    this.listSearch.resultList.forEach((item: PromotionModelWithLabel) => {
                        item.Label = `${item.Name} (${item.CampaignName})`;
                        item.LabelRow2 = `${this.datePipe.transform(item.StartDate, "mediumDate")} - ${this.datePipe.transform(item.EndDate, "mediumDate")}`;
                        item.LabelRow2Bold = `${this.startEndLabel}`;
                    });
                });
                break;
            case "alternateaddress":
                this.promise.then(() => {
                    if (this.listSearch.resultList.length > 0 && this.listSearch.resultList[0].Id !== 0) {
                        this.listSearch.resultList.unshift({
                            Id: 0,
                            Name: this.locationAddressLabel,
                            Label: this.locationAddressLabel
                        });
                    }
                    this.listSearch.resultList.forEach(function (item: ModelWithLabel) {
                        item.Label = item.Name;
                    });
                });
                break;
            default:
                this.promise.then(() => {
                    //debugger;
                    if (this.useFilteredList === true) {
                        this.setPreFilteredCounts(search);
                    }
                    this.listSearch.resultList.forEach(function (item: ModelWithLabel) {
                        item.Label = item.Name;
                    });

                    this.addAllOption();
                });
                break;
        }

    }

    public itemClicked(item: any) {
        if (this.searchType !== "listbylocation" && this.searchType !== "listbylocgroup" && !this.multiSelection) {
            return this.activeModal.close(item);
        }
    }

    public close(closeMessage?: any) {
        if (closeMessage === undefined) {
            closeMessage = this.cancelLabel;
        }
        this.activeModal.dismiss(closeMessage);
    }

    public returnResults() {
        let ret = this.dataList.filter((item: ModelWithLabel) => item.Selected);
        this.activeModal.close(ret);
    }

    public setPreFilteredCounts(search: string) {
        if (this.searchType === "space") {
            this.listSearch.resultList = this.preFilteredList.filter(item => item.Label.toLowerCase().includes(search.toLowerCase()) === true);
        } else {
            this.listSearch.resultList = this.preFilteredList.filter(item => item.Name.toLowerCase().includes(search.toLowerCase()) === true);
        }
        this.listSearch.ResultChunkAttributes.RecordCount = this.listSearch.resultList.length;
        this.listSearch.ResultChunkAttributes.TotalRecords = this.listSearch.resultList.length;
    }

    public selectedOptionChanged(item: ModelWithLabel) {
        this.dataList.some((data: ModelWithLabel) => {
            if (data.Id === item.Id) {
                data.Selected = item.Selected;
                return true;
            } else {
                // Keep looking
                return false;
            }
        });
    }

    public itemChecked(item: ModelWithLabel) {
        // Toggle selected
        item.Selected = !item.Selected;
        this.selectedOptionChanged(item);
    }

    private getLocationPromise(search: string, locations?: number[]): Promise<any> {
        let returnPromise: Promise<any> = null;

        if (locations) {
            let searchInfo = <LocationSearchInfo>{ searchText: search, LocationFilterType: "LocationId", LocationIds: locations, showUnassignedItems: false, recordCount: Number(environment.chunkSize) };
            let route = `${this.locationService.urlApiBaseProductPlusVersion}Location/FilteredSearch/LocationId`;
            returnPromise = this.listSearch.searchHelper(searchInfo, undefined, route, true);
        } else {
            returnPromise = this.listSearch.searchHelper({ searchText: search });
        }

        return returnPromise;
    }

    private setLocationTotals(locations: number[]) {
        if (locations) {
            this.listSearch.TotalRecords = locations.length;
        } else {
            this.dataService.head().then((count: number) => {
                this.listSearch.TotalRecords = count;
            });
        }
    }
}
