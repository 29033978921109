<div class="fixed-top ips-sticky-header container ips-container" [hidden]="!showHeader">
    <div class="card ips-shadow ips-info-header px-2 mb-4">
        <div class="card-body pt-0 px-2">
            <ips-breadcrumb prefixStateName="main.home"></ips-breadcrumb>
            <div class="mt-2">
                <ng-template #defaultStickyItem>
                    <div *ngFor="let stickyHeader of stickyHeaderItems;">
                        <ng-container *ngFor="let item of stickyHeader;">
                            <ng-container *ngIf="item.imageClass">
                                <div class="icon mr-2 ml-2 {{item.imageClass}}"></div>
                                <span>{{item.text}}</span>
                            </ng-container>
                            <ng-container *ngIf="!item.imageClass">
                                <div class="d-flex flex-row clip">
                                    <div class="flex-grow-1 clip mx-2" appTextOverflow>{{item.text}}</div>
                                    <div class="mr-2"><button class="text-left button-no-style tooltip-container clicker" ngbTooltip="{{item.text}}" placement="bottom-right" #t="ngbTooltip" (click)="t.open()"><i class="fa fa-ellipsis-h fa-2x"></i></button></div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-template>
                <ng-container *ngTemplateOutlet="stickyItemTemplate ? stickyItemTemplate : defaultStickyItem; context: {stickyHeaderItems: stickyHeaderItems}"></ng-container>
            </div>
        </div>
    </div>
</div>
