import { OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { LocationService, LocationModel } from "../../../imagine-ui-ng-store-profile";
var CartLocationShippingModalComponent = /** @class */ (function () {
    function CartLocationShippingModalComponent(locationService, translateService, modalInstance) {
        this.locationService = locationService;
        this.translateService = translateService;
        this.modalInstance = modalInstance;
        this.pluralMapping = {};
        this.filter = "";
    }
    // ----- FUNCTIONS -----
    //
    CartLocationShippingModalComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get([
            "FOUND_OF", "TOTAL", "SELECT_LOCATIONS", "CANCEL", "LOCATION", "LOCATIONS",
        ]).subscribe(function (res) {
            _this.pluralMapping["=1"] = _this.translateService.instant("LOCATION");
            _this.pluralMapping["other"] = _this.translateService.instant("LOCATIONS");
        });
    };
    //
    CartLocationShippingModalComponent.prototype.getLocationAddresses = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.locationService.searchLocationsByIds(_this.allLocations.map(function (x) { return x.Id; }))
                .then(function (locationSearchResponse) {
                var _loop_1 = function (i) {
                    var tempIndex = _this.allLocations.findIndex(function (x) { return x.Id === locationSearchResponse.ResultList[i].Id; });
                    if (tempIndex !== -1) {
                        _this.allLocations[tempIndex].AddressData =
                            locationSearchResponse.ResultList[i].Addresses[0];
                        _this.allLocations[tempIndex].Address =
                            _this.allLocations[tempIndex].AddressData["Line1"] + " " +
                                _this.allLocations[tempIndex].AddressData["City"] + ", " +
                                _this.allLocations[tempIndex].AddressData["StateProvince"] + " " +
                                _this.allLocations[tempIndex].AddressData["Country"] + " " +
                                _this.allLocations[tempIndex].AddressData["PostalCode"];
                    }
                };
                for (var i = 0; i < locationSearchResponse.ResultList.length; i++) {
                    _loop_1(i);
                }
                resolve(true);
            });
        });
        return promise;
    };
    //
    CartLocationShippingModalComponent.prototype.close = function (closeMessage) {
        this.modalInstance.dismiss(closeMessage);
    };
    //
    CartLocationShippingModalComponent.prototype.filterUpdated = function () {
        this.availableLocations = [];
        for (var i = 0; i < this.allLocations.length; i++) {
            if (this.allLocations[i].Label.toUpperCase().indexOf(this.filter.toUpperCase()) >= 0) {
                this.availableLocations.push(this.allLocations[i]);
            }
        }
    };
    // ----- ON INIT -----
    //
    CartLocationShippingModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        this.allLocations = [];
        for (var i = 0; i < this.cartLocations.length; i++) {
            var selected = (this.currentSelections.indexOf(this.cartLocations[i].Key) !== -1);
            if (selected) {
                this.allLocations.push({
                    Id: this.cartLocations[i].Key,
                    Label: this.cartLocations[i].Value,
                    Address: ""
                });
            }
        }
        this.allLocations.sort(function (a, b) { return a.Id - b.Id; });
        this.getLocationAddresses()
            .then(function () {
            _this.filterUpdated();
            _this.total = _this.allLocations.length;
        });
    };
    return CartLocationShippingModalComponent;
}());
export { CartLocationShippingModalComponent };
