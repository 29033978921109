var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { OnInit } from "@angular/core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/angular";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { NgForm } from "@angular/forms";
import { String as IpsString } from "typescript-string-operations";
import { TemplateFormBase } from "../../../shared/templateFormBase";
import { Subject } from "rxjs";
import { AreaService, CustomDataService } from "../../index";
var CustomDataEditComponent = /** @class */ (function (_super) {
    __extends(CustomDataEditComponent, _super);
    function CustomDataEditComponent(dataService, ipsMessage, translateService, pageTitleService, transition, stateService, areaService, activeProfileService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.ipsMessage = ipsMessage;
        _this.translateService = translateService;
        _this.pageTitleService = pageTitleService;
        _this.transition = transition;
        _this.stateService = stateService;
        _this.areaService = areaService;
        _this.activeProfileService = activeProfileService;
        _this.areaList = [];
        _this.myCustomData = {};
        _this.focusableInput = "";
        _this.editingExisting = false;
        _this.indexer = 0;
        _this.fieldGroupIndexer = 0;
        _this.fieldOption = "fieldOption";
        _this.showOrdinalWarning = false;
        _this.customDataDictionary = {};
        _this.ordinalDictionary = {};
        _this.customDataGroupList = [];
        _this.canAddFieldGroup = true;
        _this.fieldTypes = [
            { TypeName: "List", DisplayName: "OPTION_TYPE_LIST", IconClass: "option-type-list-icon" },
            { TypeName: "Text", DisplayName: "OPTION_TYPE_TEXT", IconClass: "option-type-text-icon" },
            { TypeName: "Date", DisplayName: "OPTION_TYPE_DATE", IconClass: "option-type-date-icon" },
            { TypeName: "Number", DisplayName: "OPTION_TYPE_NUMBER", IconClass: "option-type-number-icon" },
            { TypeName: "Email", DisplayName: "OPTION_TYPE_EMAIL", IconClass: "option-type-text-icon" }
        ];
        _this.TranslateStrings = {
            "SINGLE_LINE_ENTRY": "",
            "MULTI_LINE_ENTRY": "",
            "PROMPT_DELETE_BODY": "",
            "MAX_LENGTH_ERROR": "",
            "PLEASE_ENTER_NAME": "",
            "PLEASE_SELECT_RULE": "",
            "PLEASE_ENTER_CUSTOM_DATA_FIELD_NAME": "",
            "PLEASE_ENTER_FIELD_SELECTION": "",
            "SINGLE_SELECTION_ALLOWED": "",
            "MULTIPLE_SELECTIONS_ALLOWED": "",
            "PLEASE_ENTER_FIELD_ORDER": "",
            "TEXT_RULE": "",
            "LIST_RULE": ""
        };
        _this.errorMessages = {
            "required": function () { return _this.translateService.instant("PLEASE_ENTER_NAME"); },
            "maxlength": function (params) { return IpsString.Format(_this.translateService.instant("MAX_LENGTH_ERROR"), params.requiredLength); },
            "dupName": function () { return _this.translateService.instant("DUPLICATE_CUSTOM_FIELD_NAME"); },
            "dupOptionName": function () { return _this.translateService.instant("DUPLICATE_FIELD_SELECTION"); },
            "positiveWholeNumReqired": function () { return _this.translateService.instant("ENTER_POSITIVE_WHOLE_NUMBER"); }
        };
        _this.ordinalSubject = new Subject();
        return _this;
    }
    CustomDataEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateService.get("ALL").subscribe(function () { return _this.translateText(); });
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        var id = this.transition.params().id === "0" ? "" : this.transition.params().id;
        var pageTitle = id === "" ? "CREATE_CUSTOM_DATA_FIELD" : "EDIT_CUSTOM_DATA_FIELD";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);
        this.getAreaList();
        //If we got an ID to load, load it.
        if (id > 0) {
            //Initial call to populate screen on load
            this.editingExisting = true;
            this.getCustomDataField(id);
        }
        else if (id === "") {
            this.myCustomData = {
                Id: 0,
                Name: "",
                Notes: "",
                AreaId: "",
                Ordinal: undefined,
                IsFieldRequired: null,
                FieldType: null,
                SelectionRule: undefined,
                CustomDataOptionList: []
            };
            this.loaded = true;
            this.subscribeOrdinal();
        }
        _super.prototype.setFormPristine.call(this, this.customDataForm, 0);
    };
    CustomDataEditComponent.prototype.getNextAvailableOrdinal = function (areaId) {
        this.myCustomData.Ordinal = this.ordinalDictionary[areaId];
    };
    CustomDataEditComponent.prototype.translateText = function () {
        for (var _i = 0, _a = Object.keys(this.TranslateStrings); _i < _a.length; _i++) {
            var key = _a[_i];
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    };
    CustomDataEditComponent.prototype.subscribeOrdinal = function () {
        var _this = this;
        this.ordinalSubject.pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(function (model) {
            model = _this.myCustomData.Ordinal;
            _this.validateOrdinal(model);
        });
    };
    CustomDataEditComponent.prototype.onFieldOrderChange = function (ordinal) {
        this.ordinalSubject.next(ordinal);
    };
    CustomDataEditComponent.prototype.validateOrdinal = function (ordinal) {
        if (ordinal !== undefined && ordinal !== null) {
            if (this.myCustomData.AreaId) {
                var ordinalList = this.customDataDictionary[this.myCustomData.AreaId].map(function (data) { return data.Ordinal; });
                var index = ordinalList.indexOf(Number(ordinal));
                if (index !== -1) {
                    this.showOrdinalWarning = true;
                }
                else {
                    this.showOrdinalWarning = false;
                }
            }
        }
        else {
            this.showOrdinalWarning = false;
        }
    };
    CustomDataEditComponent.prototype.getErrorMessages = function (key) {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "name":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_ENTER_CUSTOM_DATA_FIELD_NAME"]; };
                    break;
                case "fieldorder":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_ENTER_FIELD_ORDER"]; };
                    break;
                case "fieldselection":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_ENTER_FIELD_SELECTION"]; };
                    break;
                case "rule":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_SELECT_RULE"]; };
                    break;
                case "fieldtype":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_SELECT_FIELD_TYPE"]; };
                    break;
                case "group":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_SELECT_FIELD_GROUP"]; };
                    break;
            }
        }
        return msgs;
    };
    CustomDataEditComponent.prototype.getAreaList = function () {
        var _this = this;
        return this.areaService.getList().then(function (response) {
            Object.assign(_this.areaList, response);
            _this.areaList.forEach(function (item) {
                switch (item.Id) {
                    case "PromotionHeader":
                        item.DisplayName = "PROMOTION_HEADER";
                        break;
                    case "PromotionMessage":
                        item.DisplayName = "PROMOTION_MESSAGE";
                        break;
                    case "PromotionPlacement":
                        item.DisplayName = "PROMOTION_PLACEMENT";
                        break;
                    case "RequestForm":
                        item.DisplayName = "REQUEST_FORM";
                        break;
                }
            });
        });
    };
    CustomDataEditComponent.prototype.selectCustomDataList = function (areaId, setDefault) {
        var _this = this;
        if (setDefault === void 0) { setDefault = true; }
        var selectedCustomDataList = this.customDataDictionary[areaId];
        if (!selectedCustomDataList) {
            this.dataService.getCustomDataByArea(areaId).then(function (result) {
                _this.customDataDictionary[areaId] = result;
                if (setDefault) {
                    var maxOrdinal = result.length === 0 ? 0 : result.map(function (item) { return item.Ordinal; }).reduce(function (item1, item2) {
                        return Math.max(item1, item2);
                    });
                    _this.ordinalDictionary[areaId] = maxOrdinal + 1;
                    _this.myCustomData.Ordinal = maxOrdinal + 1;
                }
            });
        }
    };
    CustomDataEditComponent.prototype.getCustomDataField = function (id) {
        var _this = this;
        this.loaded = false;
        this.dataService.get(id).then(function (response) {
            Object.assign(_this.myCustomData, response);
            _this.originName = _this.myCustomData.Name;
            _this.setRuleText(_this.myCustomData.FieldType);
            _this.subscribeOrdinal();
            _this.loaded = true;
            if (_this.myCustomData.CustomDataOptionList && _this.myCustomData.CustomDataOptionList.length > 0) {
                _this.indexer = _this.myCustomData.CustomDataOptionList.length;
                for (var i = 0; i < _this.myCustomData.CustomDataOptionList.length; i++) {
                    var option = _this.myCustomData.CustomDataOptionList[i];
                    option.ControlName = _this.fieldOption + i;
                }
                _this.myCustomData.CustomDataOptionList.sort(function (n1, n2) { return n1.Name.localeCompare(n2.Name); });
            }
            //load customDataList for selected areaId
            _this.selectCustomDataList(_this.myCustomData.AreaId, false);
            //load customDataGroup
            if (_this.myCustomData.AreaId === "RequestForm" && _this.customDataGroupList.length === 0) {
                _this.getcustomDataGroupList("RequestForm");
            }
        });
    };
    CustomDataEditComponent.prototype.onAreaChange = function (area) {
        var customDataList = this.customDataDictionary[area.Id];
        if (!customDataList) {
            this.selectCustomDataList(area.Id);
        }
        else {
            this.getNextAvailableOrdinal(area.Id);
        }
        if (area.Id === "RequestForm" && this.customDataGroupList.length === 0) {
            this.getcustomDataGroupList(area.Id);
        }
    };
    CustomDataEditComponent.prototype.onCustomDataGroupChange = function (group) {
        this.myCustomData.SelectedCustomDataGroup = group;
    };
    CustomDataEditComponent.prototype.getcustomDataGroupList = function (areaId) {
        var _this = this;
        this.areaService.getCustomDataGroup(areaId).then(function (result) {
            _this.customDataGroupList = result;
            _this.customDataGroupList.forEach(function (x) { return x.inputName = "fieldGroup" + ++_this.fieldGroupIndexer; });
            // Add one empty group if we don"t have any yet
            if (_this.customDataGroupList.length === 0) {
                _this.customDataGroupList.push({ Id: 0, Name: "", AreaId: _this.myCustomData.AreaId, inputName: "fieldGroup" + ++_this.fieldGroupIndexer });
                _this.canAddFieldGroup = false;
            }
            if (_this.editingExisting) {
                _this.myCustomData.SelectedCustomDataGroup = _this.customDataGroupList.find(function (x) { return x.Id === _this.myCustomData.CustomDataGroupId; });
            }
        });
    };
    CustomDataEditComponent.prototype.onFieldTypeChange = function (fieldType) {
        this.myCustomData.FieldType = fieldType.TypeName;
        this.setRuleText(fieldType.TypeName);
        if (this.myCustomData.FieldType === "List") {
            if (!this.myCustomData.CustomDataOptionList || this.myCustomData.CustomDataOptionList.length === 0) {
                this.myCustomData.CustomDataOptionList = [{ Id: 0, Name: "", CustomDataId: this.myCustomData.Id, BusinessIdentity: this.activeProfileService.businessIdentity, ControlName: this.fieldOption + ++this.indexer }];
            }
        }
        else {
            if (this.myCustomData.FieldType === "Date" || this.myCustomData.FieldType === "Number" || this.myCustomData.FieldType === "Email") {
                this.myCustomData.SelectionRule = undefined;
            }
            this.myCustomData.CustomDataOptionList = [];
        }
        this.myCustomData.SelectionRule = undefined;
    };
    CustomDataEditComponent.prototype.onOptionChange = function (option) {
        if (option.Name) {
            for (var i = 0; i < this.myCustomData.CustomDataOptionList.length; i++) {
                var currentOption = this.myCustomData.CustomDataOptionList[i];
                if (option.ControlName !== currentOption.ControlName && option.Name.toLocaleLowerCase() === currentOption.Name.toLocaleLowerCase()) {
                    this.customDataForm.controls[option.ControlName].setErrors({ "dupOptionName": true });
                    break;
                }
            }
        }
    };
    CustomDataEditComponent.prototype.setRuleText = function (fieldType) {
        switch (fieldType) {
            case "Text":
                this.ruleName = this.TranslateStrings["TEXT_RULE"];
                this.singleRule = this.TranslateStrings["SINGLE_LINE_ENTRY"];
                this.multipleRule = this.TranslateStrings["MULTI_LINE_ENTRY"];
                break;
            case "List":
                this.ruleName = this.TranslateStrings["LIST_RULE"];
                this.singleRule = this.TranslateStrings["SINGLE_SELECTION_ALLOWED"];
                this.multipleRule = this.TranslateStrings["MULTIPLE_SELECTIONS_ALLOWED"];
                break;
        }
    };
    CustomDataEditComponent.prototype.addFieldOption = function () {
        var option = { Id: 0, Name: "", CustomDataId: this.myCustomData.Id, BusinessIdentity: this.activeProfileService.businessIdentity, ControlName: this.fieldOption + ++this.indexer };
        if (this.myCustomData.FieldType === "List" &&
            (!this.myCustomData.CustomDataOptionList || this.myCustomData.CustomDataOptionList.length === 0)) {
            this.myCustomData.CustomDataOptionList = [option];
        }
        else {
            this.myCustomData.CustomDataOptionList.push(option);
        }
        var index = this.myCustomData.CustomDataOptionList.length - 1;
        this.focusableInput = this.myCustomData.CustomDataOptionList[index].ControlName;
        this.customDataForm.form.markAsDirty();
    };
    CustomDataEditComponent.prototype.addFieldGroup = function () {
        var group = { Id: 0, Name: "", AreaId: this.myCustomData.AreaId, inputName: "fieldGroup" + ++this.fieldGroupIndexer };
        this.myCustomData.SelectedCustomDataGroup = group;
        this.myCustomData.CustomDataGroupId = group.Id;
        if (!this.customDataGroupList || this.customDataGroupList.length === 0) {
            this.customDataGroupList = [group];
        }
        else {
            this.customDataGroupList.push(group);
        }
        this.canAddFieldGroup = false;
        this.customDataForm.form.markAsDirty();
    };
    CustomDataEditComponent.prototype.removeGroup = function (inputName) {
        if (this.customDataGroupList.length === 1) {
            this.ipsMessage.error("AT_LEAST_ONE_FIELD_GROUP_REQUIRED");
            return;
        }
        var group = this.customDataGroupList.find(function (x) { return x.inputName === inputName; });
        if (group && group.Id > 0) {
            if (group.ReferencedByCustomData) {
                this.ipsMessage.error("UNABLE_DELETE_FIELD_GROUP");
                return;
            }
        }
        //delete group
        this.customDataGroupList = this.customDataGroupList.filter(function (x) { return x.inputName !== inputName; });
        // Set as dirty since we removed a group.
        this.customDataForm.form.markAsDirty();
        this.canAddFieldGroup = this.customDataGroupList.every(function (x) { return x.Id > 0; });
    };
    CustomDataEditComponent.prototype.removeFieldOption = function (index) {
        if (this.myCustomData.CustomDataOptionList.length <= 1) {
            this.ipsMessage.error("AT_LEAST_ONE_FIELD_SELECTION_REQUIRED");
        }
        else {
            this.myCustomData.CustomDataOptionList.splice(index, 1);
            this.customDataForm.form.markAsDirty();
        }
    };
    CustomDataEditComponent.prototype.saveCustomDataPrompt = function (redirect) {
        var _this = this;
        return this.ipsMessage
            .waitForWork({
            body: "SAVING",
            workFunction: function () { return _this.saveCustomData(); },
            progressMessage: "SAVING"
        }, undefined).then(function (result) {
            if (result) {
                if (redirect) {
                    _this.stateService.go("main.customdata.search");
                }
                else {
                    _this.stateService.go("main.customdata.edit", { id: _this.myCustomData.Id }, { reload: _this.editingExisting });
                }
            }
        });
    };
    CustomDataEditComponent.prototype.deleteCustomDataPrompt = function () {
        var _this = this;
        var translated = IpsString.Format(this.TranslateStrings["PROMPT_DELETE_BODY"], this.myCustomData.Name);
        this.ipsMessage.confirmDelete({ body: translated, workFunction: function () { return _this.deleteCustomData(); }, progressMessage: "DELETING" }, undefined)
            .then(function (result) {
            if (result) {
                _this.stateService.go("main.customdata.search");
            }
        })
            .catch(function () {
            // rejection
        });
    };
    CustomDataEditComponent.prototype.saveCustomData = function () {
        var _this = this;
        var id = this.myCustomData.Id;
        //update customDataFieldGroup
        if (this.myCustomData.AreaId === "RequestForm") {
            this.myCustomData.CustomDataGroupList = this.customDataGroupList;
        }
        if (id) {
            return this.dataService.put(this.myCustomData).then(function (response) {
                _this.customDataForm.form.markAsPristine();
            });
        }
        else {
            return this.dataService.post(this.myCustomData).then(function (response) {
                _this.myCustomData.Id = response.Id;
                _this.customDataForm.form.markAsPristine();
            });
        }
    };
    CustomDataEditComponent.prototype.deleteCustomData = function () {
        var id = this.myCustomData.Id;
        return this.dataService.delete(id);
    };
    CustomDataEditComponent.prototype.disableSave = function () {
        return this.customDataForm.invalid || this.customDataForm.pristine || (this.myCustomData.AreaId === "RequestForm" && this.myCustomData.SelectedCustomDataGroup === undefined);
    };
    return CustomDataEditComponent;
}(TemplateFormBase));
export { CustomDataEditComponent };
