import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { StateService } from "@uirouter/core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { BrandSettingService } from "../../../imagine-ui-ng-store-profile";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString } from "typescript-string-operations";
import { BrandSettingsModel } from "../../model/BrandSettingsModel";
import { ActiveProfileService } from "imagine-ui-ng-core";

@Component({
  selector: "app-brand-settings-edit",
  templateUrl: "./brand-settings-edit.component.html",
  styleUrls: ["./brand-settings-edit.component.scss"]
})
export class BrandSettingsEditComponent implements OnInit {
    @ViewChild("settingsForm") settingsForm: NgForm;
    public screenReady = false;
    public promise: Promise<any>;
    private maxLengthMessage: string;

    public requestFormInstruction: BrandSettingsModel;
    public homePageMessage: BrandSettingsModel;

    private errorMessages = {
        "maxlength": (params) => IpsString.Format(this.maxLengthMessage, params.requiredLength)
    };

    constructor(
        private activeProfileService: ActiveProfileService, private brandSettingService: BrandSettingService, private $state: StateService, private ipsMessage: IpsMessageService, private translateService: TranslateService) { }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.getBrandSettings();
    }

    private translateText() {
        this.translateService.get(["MAX_LENGTH_ERROR"]).subscribe((res: [string]) => {
            this.maxLengthMessage = res["MAX_LENGTH_ERROR"];
        });
    }

    private getBrandSettings(): Promise<void> {
        this.promise = this.brandSettingService.getList<BrandSettingsModel[]>().then(
            (response: BrandSettingsModel[]) => {
                const homepage = response.filter(x => x.Name.toLowerCase() === "homepagemessage")[0];
                this.homePageMessage = homepage ? homepage : { Id: 0, Name: "HomePageMessage", Value: "", BusinessIdentity: this.activeProfileService.businessIdentity };
                const requestform = response.filter(x => x.Name.toLowerCase() === "requestforminstruction")[0];
                this.requestFormInstruction = requestform ? requestform : { Id: 0, Name: "RequestFormInstruction", Value: "", BusinessIdentity: this.activeProfileService.businessIdentity };
                this.screenReady = true;
            });

        return this.promise;
    }

    private saveBrandSettings(): Promise<any[]> {
        const promises = [];
        if (this.homePageMessage.Id === 0) {
            promises.push(this.brandSettingService.post(this.homePageMessage));
        } else {
            promises.push(this.brandSettingService.put(this.homePageMessage));
        }
        if (this.requestFormInstruction.Id === 0) {
            promises.push(this.brandSettingService.post(this.requestFormInstruction));
        } else {
            promises.push(this.brandSettingService.put(this.requestFormInstruction));
        }
        return Promise.all(promises);
    }

    // Public Functions used by view
    public saveBrandSettingsPrompt(redirect: boolean) {
        return this.ipsMessage
            .waitForWork({ body: "SAVING", workFunction: () => this.saveBrandSettings(), progressMessage: "SAVING" })
            .then((result: BrandSettingsModel) => {
                this.settingsForm.form.markAsPristine();

                if (redirect) {
                    this.$state.go("main.toolsControls");
                } else {
                    this.$state.go("main.brandSettingsEdit", {}, { reload: true });
                }
            });
    }

}
