import { Component, OnInit, HostListener } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { IpsMessageService } from "imagine-ui-ng-messaging";

import { ListSearchHelper, ActiveProfileService, FileDownloadService, SearchInfo } from "imagine-ui-ng-core";
import {
    CampaignData, CampaignService, PromotionService, PromotionModel
} from "../../index";

import { Transition, StateService } from "@uirouter/core";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";

interface ResultModel {
    item: any;
}

interface ScrollInfo {
    search: string;
    positionY: number;
    chunkIndex: number;
    collapseStates: any;
}

@Component({
    selector: "app-campaign-view",
    templateUrl: "./campaign-view.component.html",
    styleUrls: ["./campaign-view.component.scss"]
})
export class CampaignViewComponent implements OnInit {
    public myCampaign: CampaignData;
    public campaignId: number;
    public campaignLoaded: boolean;
    public campaignLoadError: boolean;
    public promise: Promise<any>;
    public query: string;
    public promotion: ListSearchHelper;
    public searchLabel: string;
    public searchLabelPlural: string;
    private scrollY = 0;
    public busy = false;

    private allLabelStatus = "ALL_UPPERCASE";
    private inProgressStatus = "IN_PROGRESS";
    private completedStatus = "COMPLETED";
    public labelTooltip = "TOOLTIP_PROMOTION_SEARCH";
    public selectedPromotionStatus: any;
    public availablePromotionStatus: any[] = [];
    public translated = false;
    public totalPromotionCount = 0;

    public messagePluralMapping: { [k: string]: string } = {
        "=1": "MESSAGE",
        "other": "MESSAGES"
    };
    public fixtureHolderPluralMapping: { [k: string]: string } = {
        "=1": "FIXTURE_GROUP_HOLDER",
        "other": "FIXTURE_GROUP_HOLDERS"
    };
    public elementPluralMapping: { [k: string]: string } = {
        "=1": "ELEMENT",
        "other": "ELEMENTS"
    };

    public pluralMapping: { [k: string]: string } = {
        "=1": "label",
        "other": "label"
    };

    private marketedLocations = "MARKETED_LOCATIONS";
    private locationGroupLocationsPendingWarning = "MARKET_PENDING_LOCATIONS_WARNING";

    constructor(private translateService: TranslateService, private $state: StateService,
        private transition: Transition, private ipsMessage: IpsMessageService,
        private campaignService: CampaignService,
        private promotionService: PromotionService,
        private fileDownload: FileDownloadService,
        listSearchHelper: ListSearchHelper, private ipsModal: IpsModalService,
        protected activeProfileService: ActiveProfileService) {

        this.promotion = listSearchHelper.getListSearchHelper(this.promotionService);
    }

    ngOnInit() {
        this.campaignId = Number(this.transition.params().id);
        this.campaignLoaded = false;

        this.myCampaign = <CampaignData>{
            Id: Number(this.campaignId)
        };

        this.translateService.get("ALL").subscribe(() => this.TranslateText());

        this.promotionService.headByCampaignId(this.campaignId.toString()).then(promoCount => {
            this.promotion.TotalRecords = promoCount;
            this.totalPromotionCount = promoCount;

            //set the chunk count on the service so we grab existing records on first go
            this.promotionService.chunkCount = promoCount;
            this.getListData({ search: "" }, false, false);
        });

        this.campaignService.get(this.campaignId).then((campaignData: CampaignData) => {
            Object.assign(this.myCampaign, campaignData);
            this.campaignLoaded = true;
        },
            (err) => {
                this.campaignLoadError = true;
            }
        );
    }

    private TranslateText() {
        this.marketedLocations = this.translateService.instant(this.marketedLocations);
        this.locationGroupLocationsPendingWarning = this.translateService.instant("MARKET_PENDING_LOCATIONS_WARNING");
        this.searchLabel = this.translateService.instant("PROMOTION");
        this.searchLabelPlural = this.translateService.instant("PROMOTIONS");
        this.allLabelStatus = this.translateService.instant("ALL_UPPERCASE");
        this.inProgressStatus = this.translateService.instant("IN_PROGRESS");
        this.completedStatus = this.translateService.instant("COMPLETED");
        this.labelTooltip = this.translateService.instant("TOOLTIP_PROMOTION_SEARCH");
        this.availablePromotionStatus = [
            <{ Id: number, Status: string }>{ Id: 0, Status: this.allLabelStatus },
            <{ Id: number, Status: string }>{ Id: 1, Status: this.inProgressStatus },
            <{ Id: number, Status: string }>{ Id: 2, Status: this.completedStatus }];
        this.selectedPromotionStatus = this.availablePromotionStatus[0];
        this.pluralMapping = {
            "=1": this.searchLabel,
            "other": this.searchLabelPlural
        };
        this.translated = true;
    }

    public getListData(search: any, forceReload: boolean = false, scrolling: boolean) {
        let searchTerm = "";

        if (search !== undefined && search.search !== undefined) {
            searchTerm = search.search;
        }
        this.query = searchTerm;
        let sortOption = this.selectedPromotionStatus || {
            Id: 0,
            Status: "All"
        };


        let searchInfo: SearchInfo = {
            searchText: searchTerm,
            chunkCount: this.promotion.TotalRecords.toString(),
            additionalQueryParams: [
                { param: "Status", paramValue: sortOption.Status },
            ]
        };

        return this.promise = this.promotion.searchHelper(searchInfo, false, `Campaign/${this.campaignId}/Promotion/Search`).then(() => {
            if (this.scrollY) {
                setTimeout(() => { window.scrollTo(0, this.scrollY); }, 100);
            }
        });
    }

    public fileDownloadHandler(url: string) {
        this.fileDownload.Download(url);
    }

    public searchCampaign(promotion: PromotionModel) {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent,
            { resolve: { search: "campaign", idToDisable: this.campaignId } })
            .then((response: ResultModel) => {
                const promoMove = {
                    CampaignId: response.item.Id,
                    PromotionId: promotion.Id,
                    BusinessIdentity: promotion.BusinessIdentity
                };
                this.promotionService.movePromotion(promoMove).then(repsonse => {
                    if (repsonse) {
                        //update promotion total count
                        this.promotionService.headByCampaignId(this.campaignId.toString()).then(promoCount => {
                            this.promotion.TotalRecords = promoCount;
                        });

                        //reload the list
                        this.getListData({ search: this.query }, true, false).then(() => {

                            if (this.scrollY) {
                                setTimeout(() => { window.scrollTo(0, this.scrollY); }, 100);
                            }
                        });
                    }
                })
                    .catch(err => {
                        this.ipsMessage.error(err.Message);
                    });
            },
                () => { }); // Rejected;
    }

    public searchPromotion() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { search: "allpromotion" } })
            .then((response: ResultModel) => {
                this.$state.go("main.campaign.promotion.edit", { campaignId: this.campaignId, id: 0, clonePromotionId: response.item.Id }, { reload: true });
            },
                () => { }); // Rejected;
    }

    public filterSearch(changes: string) {
        this.selectedPromotionStatus = changes;
        this.getListData({ search: this.query }, false, false);
    }

    public search(search: string) {
        this.getListData({ search: search }, true, false);
    }


    public trackByFn(index, item) {
        return item.Id;
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        } else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    }
}
