<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_FIXTURE_GROUP_INFO' | translate}}}">
        <div [hidden]="!loaded">
            <form id="ramiInfoInput" #fgForm="ngForm" class="form-horizontal" name="fgForm" novalidate ipsUnsavedWarningForm role="form">
                <div class="form-row">
                    <section class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                        <label class="required" for="fgName" translate>NAME</label>
                        <input type="text" name="fgName" #fgName="ngModel" id="fgName" required maxlength="50" [(ngModel)]="myFixtureGroup.Name"
                               class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_NAME' | translate}}">
                        <ips-show-errors [control]="fgName" [errorMessages]="errorMessages"></ips-show-errors>
                    </section>
                </div>
                <div class="form-row">
                    <section class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
                        <label  for="fgStoreFrontCategory" translate>STORE_FRONT_CATEGORY</label>
                        <select name="fgStoreFrontCategory" #fgStoreFrontCategory="ngModel" id="fgStoreFrontCategory" class="form-control form-control-lg ips-select" [(ngModel)]="myFixtureGroup.StoreFrontCategoryId">
                            <option [ngValue]="undefined" selected>---None---</option>
                            <option *ngFor="let item of storeFrontCategoryList" [ngValue]="item.Id">{{item.Name}}</option>
                        </select>
                    </section>
                </div>
                <div class="form-row">
                    <section class="form-group col-12">
                        <label for="notes" translate>NOTES</label>
                        <textarea [(ngModel)]="myFixtureGroup.Notes" class="form-control form-control-lg input-full" name="notes" #notes="ngModel" id="" cols="30" rows="3" maxlength="500" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                        <ips-show-errors [control]="notes" [errorMessages]="errorMessages"></ips-show-errors>
                    </section>
                </div>
                <div class="form-row">
                    <section class="form-group col-12">
                        <div class="form-row">
                            <div class="form-group col-6 col-sm-4 col-md-4 col-lg-4 mb-0">
                                <label class="required" translate>{{holderLabel}}</label>
                            </div>
                            <div class="form-group col-6 col-sm-2 col-md-2 col-lg-2 mb-0">
                                <label class="required" translate>PRIORITY</label>
                            </div>    
                        </div>
                        
                        <div class="form-row mb-4" *ngFor="let holder of myFixtureGroup.Holders">
                            <div class="form-group col-6 col-sm-4 col-md-4 col-lg-4 mb-0">
                                <div class="input-group">
                                    <input type="text"
                                           [name]="holder.inputName"
                                           required maxlength="50"
                                           [(ngModel)]="holder.Name"
                                           class="form-control form-control-lg input-full"
                                           placeholder="{{ 'ENTER_HOLDER_NAME' | translate}}"
                                           #holderInput="ngModel"
                                           appDuplicateValidator="holderhtmlname">
                                </div>
                                <ips-show-errors [control]="holderInput"  [errorMessages]="getErrorMessage('holdername')"></ips-show-errors>
                            </div>
                            <div class="form-group col-5 col-sm-2 col-md-2 col-lg-2 mb-0">
                                <input type="number" 
                                    [name]="holder.inputPriority"
                                    required 
                                    pattern="^[0-9]+$"
                                    [(ngModel)]="holder.Priority"
                                    placeholder="{{ 'ENTER_HOLDER_PRIORITY' | translate}}"
                                    #holderInputPriority="ngModel"
                                    class="form-control form-control-lg input-full"> 
                            </div>
                            <div class="form-group col-1 col-sm-1 col-md-1 col-lg-1 mb-0 pt-2">
                                <button class="clicker input-x col-1 pl-0" (click)="removeHolder(holder.inputName)" ngbTooltip="{{'DELETE_HOLDER' | translate}}" placement="bottom"><i class="fa fa-remove fa-lg"></i></button>
                            </div>
                        </div>
                        <div class="form-group  col-sm-6 col-md-7 col-lg-7 form-control-lg input-full clicker add-holder" (click)="addHolder()" ngbTooltip="{{'ADD_ANOTHER_HOLDER' | translate}}" placement="bottom"><i class="fa fa-plus addIconLarge " aria-hidden="true"></i> {{'ADD_ANOTHER_HOLDER' | translate}}</div>
                    </section>
                </div>
                <hr>
                <div class="row">
                    <section class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveFixtureGroup"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveFixtureGroupPrompt(false)"
                                    [disabled]="fgForm.invalid || fgForm.pristine"
                                    [ngClass]="{'btn-default': fgForm.form.invalid || fgForm.form.pristine}" translate>
                                SAVE_FIXTURE_GROUP
                            </button>
                        </div>
                    </section>
                    <section class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveReturnFixtureGroup"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveFixtureGroupPrompt(true)"
                                    [disabled]="fgForm.invalid || fgForm.pristine"
                                    [ngClass]="{'btn-default': fgForm.form.invalid || fgForm.form.pristine}" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </section>
                    <section class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="cancelFixtureGroup" class="btn btn-default btn-lg btn-block" uiSref="main.storeProfileFixtureGroup.search" translate>CANCEL</button>
                        </div>
                    </section>
                    <section class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="deleteFixtureGroup"
                                    class="btn btn-lg btn-block alert-danger"
                                    (click)="deleteFixtureGroupPrompt()"
                                    ips-unsaved-warning-clear
                                    [disabled]="!myFixtureGroup.Id"
                                    [ngClass]="{'btn-default': !myFixtureGroup.Id}" translate>
                                DELETE_FIXTURE_GROUP
                            </button>
                        </div>
                    </section>
                </div>
            </form>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_FIXTURE_GROUP_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
