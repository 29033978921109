<div class="col" ipsFocus="searchInput">
    <ips-list-search labelLoading="{{'ACCESSING_LOCATION_GROUP_INFO' | translate }}"
    labelLoadingError="{{'ERROR_ACCESSING_LOCATION_GROUP_INFO' | translate }}"
    [listData]="locationGroups"
    [query]="currentSearchText"
    [busy]="busy"
    (onListDataChange)="getListData($event.search, $event.filterOption, $event.forceReload, $event.scrolling)"
    [section]="'main.storeProfileLocationGroup'"
    [scroller]="true"
    [quickRef]="false"
    [itemClickPermission]="'main.storeProfileLocationGroup.view'"
    [promise]="promise"
    createNewTooltip="{{'CREATE_NEW_LOCATION_GROUP' | translate }}"
    [hideTotals]="false">
        <ng-template #searchHeaderTemplate>
            <ips-complex-search-box label1="{{'LOCATION' | translate}}" label2="{{'LOCATION_GROUP'| translate}}" label3="{{'ASSIGNMENT' | translate}}"
                                    label1Tooltip="{{'TOOLTIP_LOCATION_GROUP_SEARCH_LOCATION' | translate}}" label2Tooltip="{{'TOOLTIP_LOCATION_GROUP_SEARCH_LOCATION_GROUP' | translate}}" label3Tooltip="{{'TOOLTIP_LOCATION_GROUP_SEARCH_ASSIGNMENT' | translate}}"
                                    [listData1]="availableLocations" [listData2]="availableLocationGroups" [listData3]="availableAssignments"
                                    [list1Total]="totalLocationCount" [list2Total]="totalLocationGroupCount"
                                    (list1SelectItem)="selectLocation(item)" (list2SelectItem)="selectLocationGroup(item)" (list3SelectItem)="selectAssignment(item)"
                                    [list1SelectedItem]="selectedLocation" [list2SelectedItem]="selectedLocationGroup" [list3SelectedItem]="selectedAssignment"
                                    [disableInput3]="disableAssignment"
                                    (searchFunction)="filterSearch($event)"
                                    [maxDropdownCount]="maxDropDownCount"
                                    [query]="currentSearchText"
                                    [autoFocus]="true">
                <div search-title>
                    <section class="col">
                        <h3>
                            <span class="ips-ls-header-label-imgn">{{'LOCATION_GROUPS' | translate }}</span>
                            <a uiSref="main.storeProfileLocationGroup.edit" [uiParams]="{id: 0}" ipsPermission="main.storeProfileLocationGroup.edit" ngbTooltip="{{'CREATE_NEW_LOCATION_GROUP' | translate }}" placement="bottom"><span class="fa fa-plus ips-ls-addIcon"></span></a>
                        </h3>
                    </section>
                </div>
                <div search-counts-badge class="col">
                    <div *ngIf="translated" class="row no-gutters mt-1">
                        <div [ngbCollapse]="collapse" class="col-12 col-sm">
                            <div class="row no-gutters mt-1">
                                <div class="col-12 col-md-auto ml-3">
                                    <span class="badge-pill badge-secondary font-weight-normal">{{ locationGroups.ResultChunkAttributes.TotalRecords | number }}</span>
                                    <span class="ml-1">{{locationGroups.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping}} {{'FOUND_OF' | translate}}</span>
                                </div>
                                <div class="col-12 col-md-auto ml-3">
                                    <span class="badge-pill badge-secondary font-weight-normal">{{ totalLocationGroupCount | number }}</span>
                                    <span class="ml-1">{{ 'TOTAL' | translate}} {{totalLocationGroupCount | i18nPlural: pluralMapping}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto d-flex justify-content-end list-options-margin">
                            <div ipsPermission="main.storeProfileLocationGroup.view.campaign">
                                <div class="mt-2 location-group-feature-lateral" (click)="showCampaignLocationFeatures(campaignLocationFeatures)">
                                    <span> <input name="IsCampaignLocationFeature" class="mr-2" id="IsCampaignLocationFeature" type="checkbox" [(ngModel)]="showCampaignLocationFeature" />{{'SHOW_CAMPAIGN_LOCATION_FEATURE' | translate }}</span>
                                </div>
                            </div>
                            <div ipsPermission="main.storeProfileLocationGroup.edit">
                                <div class="mt-2"  (click)="getObsoleteData(isObsolete)">
                                    <span> <input name="IsObsolete" class="mr-2" id="IsObsolete" type="checkbox" [(ngModel)]="includeObsolete" />{{'SHOW_OBSOLETE' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ips-complex-search-box>


        </ng-template>
        <ng-template #listSearchItemTemplate let-result="result">
            <app-location-group-list [editing]="editing"
            [saveChanges]="savePromptFn"
            (notifyChangesMade)="notifyChangesMade($event.hasUnsavedChanges)"
            [reloading]="reloading"
            [result]="result"
            [search]="currentSearchText"
            [isSingleResult]="locationGroups.ResultChunkAttributes.TotalRecords === 1"
            (notifyCollapsibleChange)="notifyCollapsibleChange($event)">
            </app-location-group-list>
        </ng-template>
    </ips-list-search>
</div>
