<div class="ips-image-container mx-auto">
    <button *ngIf="imgThumbUrl" (click)="imagePopover();" class="ips-btn-image btn-link p-0">
        <img class="img-thumbnail border-0 p-0" [src]="imgThumbUrl" alt="{{'THUMBNAIL_ALT' | translate}}" (error)="imgError(this)"/>
        <i class="fa fa-expand ips-expand-icon"></i>
    </button>
    <div *ngIf="!imgThumbUrl" class="card ips-search-card w-100">
        <div class="card-body">
            <span *ngIf="!hideLabel" class="view-info-label" translate>IMAGE</span>
            <span translate>{{noImageText}}</span>
        </div>
    </div>
</div>
