<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3 translate>BROWSER_SUPPORT</h3>
        </section>
    </div>
    <div class="row">
        <div class="col col-md-10 col-lg-8 col-xl-6">
            <div class="card">
                <div class="card-header view-info-header" translate>
                    SUPPORTED_DESKTOP_BROWSERS
                </div>
                <div class="card-body">
                    <div>Internet Explorer 11</div>
                    <div>Microsoft Edge</div>
                    <div>Google Chrome (Latest)</div>
                    <div>Mozilla Firefox (Latest)</div>
                    <div>Safari for Apple Macintosh (Latest)</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col col-md-10 col-lg-8 col-xl-6">
            <div class="card">
                <div class="card-header view-info-header" translate>
                    SUPPORTED_MOBILE_BROWSERS
                </div>
                <div class="card-body">
                    <div>Safari for iOS</div>
                    <div>Android Native Browser</div>
                    <div>Google Chrome for Android OS</div>
                    <div>Internet Explorer</div>
                </div>
            </div>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col col-md-10 col-lg-8 col-xl-6">
            <p translate>BROWSER_SUPPORT_DESCRIPTION</p>
        </div>
    </div>
</div>
