<h3 class="title ml-3">General Settings</h3>

<div class="ips-flex-loading-box mb-4" *ngIf="!screenReady">
    <div class="content">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
        <span class="d-block">Loading General Settings...</span>
    </div>
</div>

<div *ngIf="screenReady">
    <div class="row">
        <div class="col-12 col-lg-8 col-xl-6">
            <form #settingsForm="ngForm" name="settingsForm" novalidate ipsUnsavedWarningForm role="form">
                <tabset class="order-tabs tab-container">
                    <tab *ngFor="let type of settingTypes" heading="{{ type.label }}">
                        <div *ngFor="let setting of getSettingsByType(type.name); let i=index" class="form-group">
                            <div [ngSwitch]="setting.Name">
                                <div *ngSwitchCase="'SpaceOrder'">
                                    <label class="view-info-label ml-3 mb-0">{{ setting.Name | stringToSpaces }}</label>

                                    <ng-template #itemTemplate let-item="item" let-index="index">
                                        <div class="list-group-item py-1">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <span class="fa fa-fw fa-ellipsis-v text-hover-dark text-black-50"></span> {{ item.initData.Name }}
                                                </div>
                                                <div>
                                                    <button class="btn btn-link text-danger" (click)="removeSpace(item.initData)"><span class="fa fa-remove"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>

                                    <div class="card">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <input type="text"
                                                       placeholder="Search for a Space and press Enter to choose"
                                                       [name]="setting.Name"
                                                       class="form-control form-control-lg"
                                                       [(ngModel)]="currentSpace"
                                                       [ngModelOptions]="{ standalone: true }"
                                                       [typeahead]="availableSpaces"
                                                       typeaheadOptionField="Name"
                                                       (typeaheadOnSelect)="selectSpace($event)" />
                                            </div>

                                            <hr />

                                            <div class="list-group">
                                                <bs-sortable [(ngModel)]="selectedSpaces"
                                                             [ngModelOptions]="{ standalone: true }"
                                                             [itemTemplate]="itemTemplate"
                                                             (onChange)="onSortableChange($event)"
                                                             itemClass="sortable-item"
                                                             itemActiveClass="sortable-item-active"
                                                             placeholderItem="Drag here"
                                                             placeholderClass="placeholderStyle"
                                                             wrapperClass="sortable-wrapper"></bs-sortable>
                                            </div>
                                        </div>
                                        <div class="card-footer">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    {{ selectedSpaces.length }} {{ selectedSpaces.count === 1 ? 'Space' : 'Spaces' }} selected
                                                </div>
                                                <div>
                                                    <span class="fa fa-info-circle"></span> Click and drag to reorder
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div *ngSwitchDefault>
                                    <div *ngIf="!setting.Options">
                                        <label class="view-info-label ml-3 mb-0">{{ setting.Name | stringToSpaces  }}</label>
                                        <input type="text" [name]="setting.Name" class="form-control form-control-lg" [(ngModel)]="setting.Value" />
                                    </div>
                                    <div *ngIf="setting.Options">
                                        <label class="view-info-label ml-3 mb-0">{{ setting.Name | stringToSpaces  }}</label>
                                        <select class="form-control form-control-lg" [name]="setting.Name" [(ngModel)]="setting.Value">
                                            <option *ngFor="let option of setting.Options" [value]="option.value">{{ option.key }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </form>
        </div>
    </div>

    <hr class="mb-4" />

    <div class="form-row">
        <div class="col-12 col-sm-6 col-lg-3">
            <div class="form-group">
                <button id="saveCampaign"
                        [disabled]="!settingsForm.dirty"
                        class="btn btn-lg btn-block alert-success"
                        [ngClass]="{ 'btn-default': !settingsForm.dirty }"
                        (click)="savePreferencesPrompt(false)">
                    Save
                </button>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
            <div class="form-group">
                <button id="saveReturnCampaign"
                        [disabled]="!settingsForm.dirty"
                        class="btn btn-lg btn-block alert-success"
                        [ngClass]="{ 'btn-default': !settingsForm.dirty }"
                        (click)="savePreferencesPrompt(true)">
                    Save and Return
                </button>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-3">
            <div class="form-group">
                <button id="cancelCampaign" class="btn btn-default btn-lg btn-block" uiSref="main.toolsControls" type="submit">Cancel</button>
            </div>
        </div>
    </div>
</div>
