<div class="col-md-12">
    <h3 translate>PROFILE_CONTROLS</h3>
    <div [cgBusy]="promise" cgBusyConfig="{message:'{{'ACCESSING_CONTROLS_INFO' | translate}}'}">
        <div *ngIf="loaded">
            <form #controlsForm="ngForm" id="controlsForm" class="form-horizontal updateSection" name="controlsForm" novalidate ipsUnsavedWarningForm role="form">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label for="profilePageNotice" translate>PROFILE_PAGE_NOTICE</label>
                            <textarea class="form-control form-control-lg input-full"
                                      [(ngModel)]="brandBulletin.Value" #profilePageNotice="ngModel"
                                      name="profilePageNotice" id="profilePageNotice"
                                      cols="30" rows="3" value=""
                                      IpsMaxLength="150" appValidation></textarea>
                            <ips-show-errors [control]="profilePageNotice" [errorMessages]="errorMessages"></ips-show-errors>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label for="profilePageNoticeNotes" translate>NOTES</label>
                            <textarea class="form-control form-control-lg input-full"
                                      [(ngModel)]="brandBulletin.Notes" #profilePageNoticeNotes="ngModel"
                                      name="profilePageNoticeNotes" id="profilePageNoticeNotes"
                                      cols="30" rows="3" value=""
                                      IpsMaxLength="500" appValidation></textarea>
                            <ips-show-errors [control]="profilePageNoticeNotes" [errorMessages]="errorMessages"></ips-show-errors>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label for="shopNotice" translate>SHOP_NOTICE</label>
                            <textarea class="form-control form-control-lg input-full"
                                      [(ngModel)]="brandBulletinShop.Value" #shopNotice="ngModel"
                                      name="shopNotice" id="shopNotice"
                                      cols="30" rows="3" value=""
                                      IpsMaxLength="150" appValidation></textarea>
                            <ips-show-errors [control]="shopNotice" [errorMessages]="errorMessages"></ips-show-errors>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label for="shopNotes" translate>NOTES</label>
                            <textarea class="form-control form-control-lg input-full"
                                      [(ngModel)]="brandBulletinShop.Notes" #shopNotes="ngModel"
                                      name="shopNotes" id="shopNotes"
                                      cols="30" rows="3" value=""
                                      IpsMaxLength="500" appValidation></textarea>
                            <ips-show-errors [control]="shopNotes" [errorMessages]="errorMessages"></ips-show-errors>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label for="shopNotice" translate>UP_DOWN_REPORT_NOTICE</label>
                            <textarea class="form-control form-control-lg input-full"
                                      [(ngModel)]="brandBulletinUpDown.Value" upDown="ngModel"
                                      name="upDown" id="upDown"
                                      cols="30" rows="3" value=""
                                      IpsMaxLength="150" appValidation></textarea>
                            <ips-show-errors [control]="upDown" [errorMessages]="errorMessages"></ips-show-errors>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-group">
                            <label for="shopNotes" translate>NOTES</label>
                            <textarea class="form-control form-control-lg input-full"
                                      [(ngModel)]="brandBulletinUpDown.Notes" upDownNotes="ngModel"
                                      name="upDownNotes" id="upDownNotes"
                                      cols="30" rows="3" value=""
                                      IpsMaxLength="500" appValidation></textarea>
                            <ips-show-errors [control]="upDownNotes" [errorMessages]="errorMessages"></ips-show-errors>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="form-group">
                            <button id="saveLocationGroup"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveBrandBulletinPrompt(false)"
                                    [disabled]="controlsForm.invalid || controlsForm.pristine"
                                    [ngClass]="{'btn-default': controlsForm.invalid || controlsForm.pristine}" translate>
                                SAVE
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="form-group">
                            <button id="saveReturnSpace"
                                    class="btn btn-lg btn-block alert-success" type="submit"
                                    (click)="saveBrandBulletinPrompt(true)"
                                    [disabled]="controlsForm.invalid || controlsForm.pristine"
                                    [ngClass]="{'btn-default': controlsForm.invalid || controlsForm.pristine}" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="form-group">
                            <button id="cancel" class="btn btn-lg btn-block" type="submit" uiSref="main.toolsControls" translate>CANCEL</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_CONTROLS_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
