import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { BaseWebServiceWithDefaultImage } from "imagine-ui-ng-quick-start";
import { environment } from "../../../../environments/environment";

@Injectable()
export class FixtureByLocationSumService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Fixture", "Profile", "v1");
    }

    // Override the ipsBaseWebService.search method.
    search<T>(searchInfo: SearchInfo, route: string): Promise<T> {
        return this.searchByRelation(searchInfo, "Fixture/SearchLocationFixtureSum");
    }
}
