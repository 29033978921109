<div class="col-md-12">
    <h3 class="title">
        <span class="header-label-imgn pr-2" translate>VIEW_USER</span>
        <a *ngIf="loaded" id="editUserLink" uiSref="main.profileUser.edit" [uiParams]="{id: myUser.Profile.UserName}" ngbTooltip="{{'EDIT' | translate}} {{myUser.Profile.UserName}}" placement="bottom" ipsPermission="main.profileUser.edit"><i class="fa fa fa-pencil editIcon"></i></a>
    </h3>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_USER_INFO' | translate}}}">
        <div *ngIf="loaded">
            <div class="row">
                <section class="col-12 col-md-6">
                    <div class="form-group">
                        <div class="card">
                            <div class="card-header view-info-header">
                                <span class="pr-3">{{headerLabel}}</span><i class="{{statusIconClass}} statusIcon" ngbTooltip="{{StatusIconTooltip | translate}}" placement="bottom"></i>

                            </div>
                            <div class="card-body">
                                <ul class="list-group long-text-wrap">
                                    <li class="list-group-item">
                                        {{'CONTACT_USER' | translate}}:
                                        <div class="view-info-format-imgn">
                                            <div class="view-info-label" translate>EMAIL</div>
                                            <div class="view-info-add-margin view-info-format-imgn">
                                                <a href="mailto:{{myUser.Profile.Email}}" target="_blank"><span>{{myUser.Profile.Email}}</span></a>
                                            </div>
                                            <div class="view-info-label" translate>PHONE</div>
                                            <div class="view-info-add-margin view-info-format-imgn">
                                                <span> {{myUser.Profile.PrimaryPhone}}</span>
                                            </div>
                                            <div class="view-info-label" translate>ROLE</div>
                                            <div class="view-info-add-margin view-info-format-imgn">
                                                <span>{{userRole}}</span>
                                            </div>
                                        </div>

                                        <hr />

                                        {{'USER_PREFERENCES' | translate}}:
                                        <div class="view-info-format-imgn">
                                            <div class="view-info-label" translate>LOCAL_TIMEZONE</div>
                                            <div class="view-info-add-margin">
                                                <span>{{myUser.Profile.TimeZone}}</span>
                                            </div>
                                            <div class="view-info-label" translate>PREFERRED_LANGUAGE</div>
                                            <div class="view-info-add-margin">
                                                <span>{{Language}}</span>
                                            </div>
                                            <div class="view-info-label" translate>PREFERRED_LOCALE</div><div class="view-info-add-margin">
                                                <span>{{Locale}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <hr>
            <form name="locationForm" #locationForm="ngForm" novalidate ipsUnsavedWarningForm>
                <div class="row" *ngIf="showLocation">
                    <section class="col-12 col-md-8">
                        <ips-list-search headerLabel="{{'RELATED_LOCATIONS' | translate }}"
                                         searchLabel="{{'LOCATION' | translate }}"
                                         searchLabelPlural="{{'LOCATIONS' | translate }}"
                                         labelLoading="{{'ACCESSING_LOCATION_INFO' | translate }}"
                                         labelLoadingError="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}"
                                         [listData]="locationSearch"
                                         [busy]="busy"
                                         (onListDataChange)="getLocationList($event.search, $event.filterOption)"
                                         [quickRef]=true
                                         [filterOptions]="optionList"
                                         [selectedFilterOption]="selectedOption"
                                         [scroller]=false
                                         [promise]="locationSearch.promise"
                                         [query]="query"
                                         [translatedTexts]="translatedTexts">
                            <ng-template #listSearchItemTemplate let-result="result">
                                <div class="row listItemContainer" *ngIf="result">
                                    <div class="col-10 col-md-11 listItem">
                                        <div class="row">
                                            <div class="col-8 col-md-9">
                                                {{result.Label}}
                                            </div>

                                            <div class="col-4 col-md-3 text-right" [hidden]="!result.assignChanged">
                                                <button type="button" class="btn"
                                                        id="locationBtn{{result.id}}" [disabled]="locationForm.invalid"
                                                        (click)="saveLocationPrompt()" translate>
                                                    SAVE_ALL
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 col-md-1 checkContainer listItem">
                                        <input name="assignedCheck{{result.id}}" id="assignedCheck{{result.id}}" type="checkbox" [(ngModel)]="result.isAssigned" (change)="assignChanged(result)" />
                                    </div>
                                </div>
                            </ng-template>
                        </ips-list-search>
                    </section>

                    <ips-error-handler label="{{'ERROR_ACCESSING_LOCATION_INFO' | translate }}" [promise]="locationSearch.promise"></ips-error-handler>
                </div>
            </form>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_USER_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
