<div class="col-12" ipsFocus="searchInput">
    <ips-list-search headerLabel="{{'CAMPAIGNS' | translate }}"
                     searchLabel="{{'CAMPAIGN' | translate }}"
                     searchLabelPlural="{{'CAMPAIGNS' | translate }}"
                     labelLoading="{{'ACCESSING_CAMPAIGN_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_CAMPAIGN_INFO' | translate }}"
                     [listData]="campaign"
                     [busy]="busy"
                     [query]="query"
                     (onListDataChange)="getListData($event)"
                     scroller="true"
                     quick-ref="false"
                     section="main.campaign"
                     [promise]="promise"
                     createNewTooltip="{{'CREATE_CAMPAIGN' | translate }}"
                     autoFocus="true">

        <!-- search form template -->
        <ng-template #searchHeaderTemplate>
            <div search-title>
                <section class="pt-3 pb-2">
                    <h3>
                        <span class="ips-ls-header-label-imgn" translate>CAMPAIGNS</span><a uiSref="main.campaign.edit" [uiParams]="{id: 0}" ngbTooltip="{{'CREATE_NEW_CAMPAIGN' | translate }}" placement="bottom" ipsPermission="main.campaign.edit"><span class="fa fa-plus ips-ls-addIcon"></span></a>
                    </h3>
                </section>
            </div>
            <div class="row">
                <section class="col-12">
                    <div class="ips-ls-search-module-imgn">
                        <div class="my-3">
                            <div class="input-group">
                                <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                                <input type="text" class="form-control form-control-lg ips-ls-input long-text-wrap"
                                       placeholder="Search for a Campaign by Name"
                                       [(ngModel)]="query"
                                       (ngModelChange)="onQueryChange($event)" />
                            </div>
                        </div>

                        <div class="row no-gutters mb-3 mb-md-0">
                            <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                <div class="pl-md-2">
                                    <span class="badge-pill badge-secondary font-weight-normal">{{ matchingRecordCount | number }}</span>
                                    <span class="ml-1">{{ matchingRecordCount | i18nPlural: pluralMapping }} {{ 'FOUND' | translate }}</span> of
                                    <span class="badge-pill badge-secondary font-weight-normal">{{ totalRecords | number }}</span>
                                    <span class="ml-1">{{ 'TOTAL' | translate }} {{ totalRecords | i18nPlural: pluralMapping }}</span>
                                </div>
                            </div>
                            <div class="row col-12 col-xl-6 col-lg-6 col-md-6 justify-content-end">
                                <div dropdown class="col-12 col-md-8">
                                    <button id="button-basic" dropdownToggle type="button" class="btn btn-default dropdown-toggle btn-block dropdown-toggle"
                                            aria-controls="dropdown-basic">
                                        <span class="mr-1">Sort By:</span>
                                        <span class="font-weight-bold">{{ selectedSortOption.fieldLabel }} <span class="font-weight-normal">({{ selectedSortOption.directionLabel }})</span></span>
                                    </button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                                        role="menu" aria-labelledby="button-basic">
                                        <li role="menuitem" *ngFor="let option of sortOptions" (click)="sortOptionSelected(option)">
                                            <div class="dropdown-item clicker">
                                                {{ option.fieldLabel }} <span class="">({{ option.directionLabel }})</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-auto col-md-auto d-flex justify-content-end list-options-margin mt-2" (click)="getObsoleteData()">
                                    <span> <input name="IsObsolete" class="mr-2" id="IsObsolete" type="checkbox" [(ngModel)]="includeObsolete" />{{'SHOW_OBSOLETE' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ng-template>

        <!-- search results template -->
        <ng-template #listSearchItemTemplate let-result="result">
            <div class="px-4 py-2">
                <div class="form-row">
                    <a class="form-control-lg" uiSref="main.campaign.view" [uiParams]="{id:result.Id}" innerHtml="{{result.Name | highlight : query}}"></a>
                </div>
                <div class="form-row">
                    <div class="col-12 col-md-6 mb-2">
                        <div class="card ips-search-card">
                            <div class="card-body"><span><b translate>IN_STORE_DATE</b>:</span> {{result.InStoreDate | date:'mediumDate'}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-2">
                        <div class="card ips-search-card">
                            <div class="card-body"><span><b translate>START_END_DATE</b>:</span> {{result.StartDate | date:'mediumDate'}} - <span class="avoidwrap">{{result.EndDate | date:'mediumDate'}}</span></div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-md-6 mb-2" *ngIf="result.ProductionMedia">
                        <div class="card ips-search-card">
                            <div class="card-body">
                                <div class="form-row align-items-center">
                                    <div class="col-auto">
                                        <div class="campaign-production-file-icon"></div>
                                    </div>
                                    <div class="col">
                                        <div class="form-row reset-line-height">
                                            <div class="col-12"><b translate>PRODUCTION_FILE</b>:<button class="btn btn-link btn-lg p-0 text-left long-text-wrap" (click)="fileDownloadHandler(result.ProductionMedia.ResourceUri)">{{result.ProductionMedia.FileName}}</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-2" *ngIf="result.AllocationMedia">
                        <div class="card ips-search-card">
                            <div class="card-body">
                                <div class="form-row align-items-center">
                                    <div class="col-auto">
                                        <div class="campaign-allocation-file-icon"></div>
                                    </div>
                                    <div class="col">
                                        <div class="form-row reset-line-height">
                                            <div class="col-12"><b translate>ALLOCATION_FILE</b>:<button class="btn btn-link btn-lg p-0 text-left long-text-wrap" (click)="fileDownloadHandler(result.AllocationMedia.ResourceUri)">{{result.AllocationMedia.FileName}}</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ips-list-search>
</div>
