import { Directive, ElementRef, Renderer2, DoCheck, HostBinding } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[appValidation]"
})
export class ValidationDirective implements DoCheck {

    constructor(private renderer: Renderer2, private _element: ElementRef, private control: NgControl) {
    }

    ngDoCheck(): void {
        if (this.control.invalid && (this.control.dirty || this.control.touched)) {
            this.renderer.addClass(this._element.nativeElement, "is-invalid");
        } else {
            this.renderer.removeClass(this._element.nativeElement, "is-invalid");
        }
    }
}
