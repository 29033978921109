export enum UStoreProductionStatus {
    Unknown = -1,
    Waiting = 1,
    InProgress = 2,
    Completed = 3,
    Failed = 4,
    Aborting = 5,
    Aborted = 6,
    OnDemand = 7,
    Suspended = 8
}
