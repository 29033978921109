import { OnInit } from "@angular/core";
import { FileDownloadService, ListSearchHelper } from "imagine-ui-ng-core";
import { CampaignService } from "../../service/campaign.service";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
var CampaignSearchComponent = /** @class */ (function () {
    function CampaignSearchComponent(campaignService, listSearchHelper, fileDownload) {
        var _this = this;
        this.campaignService = campaignService;
        this.fileDownload = fileDownload;
        this.includeObsolete = false;
        this.maxRecordCount = 20; // limit the amount of data that comes back on this screen
        this.totalRecords = 0;
        this.querySubject = new Subject();
        this.pluralMapping = {};
        this.sortOptions = [
            {
                fieldLabel: "Name",
                field: "name",
                direction: "asc",
                directionLabel: "A - Z"
            },
            {
                fieldLabel: "Name",
                field: "name",
                direction: "desc",
                directionLabel: "Z - A"
            },
            {
                fieldLabel: "In-Store Date",
                field: "instoredate",
                direction: "desc",
                directionLabel: "Newest first"
            },
            {
                fieldLabel: "In-Store Date",
                field: "instoredate",
                direction: "asc",
                directionLabel: "Oldest first"
            },
            {
                fieldLabel: "Start Date",
                field: "startdate",
                direction: "desc",
                directionLabel: "Newest first"
            },
            {
                fieldLabel: "Start Date",
                field: "startdate",
                direction: "asc",
                directionLabel: "Oldest first"
            },
            {
                fieldLabel: "End Date",
                field: "ebddate",
                direction: "desc",
                directionLabel: "Newest first"
            },
            {
                fieldLabel: "End Date",
                field: "enddate",
                direction: "asc",
                directionLabel: "Oldest first"
            }
        ];
        this.selectedSortOption = null;
        this.campaign = listSearchHelper.getListSearchHelper(campaignService);
        this.busy = this.campaign.busy;
        this.pluralMapping["=1"] = "Campaign";
        this.pluralMapping["other"] = "Campaigns";
        this.selectedSortOption = this.sortOptions[0];
        this.querySubject.pipe(debounceTime(350), distinctUntilChanged())
            .subscribe(function (model) {
            _this.getListData({ search: model });
        });
    }
    CampaignSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.campaignService.head().then(function (data) {
            _this.totalRecords = data;
        });
        this.getListData({ search: "" });
    };
    CampaignSearchComponent.prototype.onQueryChange = function (query) {
        this.query = query;
        this.querySubject.next(query);
    };
    Object.defineProperty(CampaignSearchComponent.prototype, "matchingRecordCount", {
        get: function () {
            return this.campaign.ResultChunkAttributes.TotalRecords || 0;
        },
        enumerable: true,
        configurable: true
    });
    CampaignSearchComponent.prototype.sortOptionSelected = function (event) {
        console.log("event: ", event);
        this.selectedSortOption = event;
        this.getListData({ search: this.query });
    };
    CampaignSearchComponent.prototype.getListData = function (search) {
        var searchTerm = "";
        var additionalQueryParams = [];
        if (search !== undefined && search.search !== undefined) {
            searchTerm = search.search;
        }
        this.query = searchTerm;
        this.searchText = search.search;
        var sortOption = this.selectedSortOption || {
            field: "name",
            direction: "asc"
        };
        var searchInfo = {
            searchText: searchTerm,
            recordCount: this.maxRecordCount,
            additionalQueryParams: [
                { param: "SortBy", paramValue: sortOption.field },
                { param: "SortDirection", paramValue: sortOption.direction }
            ]
        };
        if (this.includeObsolete) {
            searchInfo.additionalQueryParams.push({ param: "ShowObsoleteItems", paramValue: this.includeObsolete.toString() });
        }
        this.promise = this.campaign.searchHelper(searchInfo, undefined, "Campaign/SimpleCampaign/Search");
    };
    CampaignSearchComponent.prototype.fileDownloadHandler = function (url) {
        this.fileDownload.Download(url);
    };
    CampaignSearchComponent.prototype.getObsoleteData = function () {
        this.includeObsolete = !this.includeObsolete;
        this.getListData({ search: this.searchText });
    };
    return CampaignSearchComponent;
}());
export { CampaignSearchComponent };
