<div appProvideParentForm>
    <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="form-group">
                <label class="" for="attention" translate>ATTENTION_TO</label>
                <input type="text" name="attention" id="attention" [(ngModel)]="address.Attention" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_ATTENTION_TO' | translate}}"
                       maxlength="100">
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="form-group">
                <label class="required" for="addressLine1" translate>ADDRESS_LINE_1</label>
                <input type="text" name="addressLine1" id="addressLine1" [(ngModel)]="address.Line1" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_ADDRESS_LINE_1' | translate}}"
                       #addressLine1="ngModel" appValidation required maxlength="100">
                <div *ngIf="addressLine1.errors" class="invalid-feedback">
                    <div *ngIf="addressLine1.errors.required" translate>PLEASE_ENTER_ADDRESS_LINE_1</div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="form-group">
                <label class="" for="addressLine2" translate>ADDRESS_LINE_2</label>
                <input type="text" name="addressLine2" id="addressLine2" [(ngModel)]="address.Line2" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_ADDRESS_LINE_2' | translate}}"
                       maxlength="100">
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="form-group">
                <label class="required" for="city" translate>CITY</label>
                <input type="text" name="city" id="city" [(ngModel)]="address.City" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_CITY' | translate}}"
                       #addressCity="ngModel" appValidation required maxlength="100">
                <div *ngIf="addressCity.errors" class="invalid-feedback">
                    <div *ngIf="addressCity.errors.required" translate>PLEASE_ENTER_CITY</div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4" [hidden]="!address.Country">
            <div class="form-group">
                <label class="required" for="state" translate>STATE_PROVINCE</label>
                <select name="state" id="state" class="form-control form-control-lg ips-select" [(ngModel)]="address.StateProvince"
                        #addressState="ngModel" appValidation required>
                    <!--Needed for IE to default to unselected-->
                    <option value="undefined" disabled></option>
                    <optgroup *ngFor="let regionDesc of regionDescriptorKeys | async" label="{{regionDesc}}">
                        <option *ngFor="let region of regionDescriptors.get(regionDesc)" [ngValue]="region.RegionCode">{{region.RegionName}}</option>
                    </optgroup>
                </select>
                <div *ngIf="addressState.errors" class="invalid-feedback">
                    <div *ngIf="addressState.errors.required" translate>PLEASE_ENTER_STATE_PROVINCE</div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg">
            <div class="form-group">
                <label class="required" for="postalCode" translate>POSTAL_CODE</label>
                <input type="text" name="postalCode" id="postalCode" [(ngModel)]="address.PostalCode" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_POSTAL_CODE' | translate}}"
                       #addressPostalCode="ngModel" appValidation required maxlength="10" />
                <!--we decided to turn off postal validation in order to support multiple countries-->
                <!--Original pattern used with ghiscoding validation attribute: pattern=^([A-Za-z\d\s-]{0,10})-->
                <div *ngIf="addressPostalCode.errors" class="invalid-feedback">
                    <div *ngIf="addressPostalCode.errors.required" translate>PLEASE_ENTER_POSTAL_CODE</div>
                    <div *ngIf="addressPostalCode.errors.pattern" translate>INVALID_POSTALCODE</div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="form-group">
                <label class="required" for="country" translate>COUNTRY</label>
                <!--Example: ng-options="country.code as country.name for country in countries". country.code - will be used as the [(ngModel)] attribute. country.name - will be used to display the item in select.-->
                <select name="country" id="country" [(ngModel)]="address.Country"
                        class="form-control form-control-lg ips-select"
                        (ngModelChange)="countryChanged()"
                        #addressCountry="ngModel" appValidation required>
                    <option *ngFor="let country of countryPromise | async" [ngValue]="country.Iso3166ThreeCharCountryCode">{{country.Name}}</option>
                </select>
                <div *ngIf="addressCountry.errors" class="invalid-feedback" translate>PLEASE_ENTER_COUNTRY</div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-8 pt-4 mb-3">
            <p *ngIf="address.ValidationStatus == 'Skipped'" class="text-warning mb-0 mt-2"><b>{{information}}: </b>{{addressInvalidWarning}}</p>
            <p *ngIf="address.ValidationStatus == 'Unknown'" class="text-warning mb-0 mt-2"><b>{{information}}: </b>{{addressNotValidated}}</p>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="form-group">
                <label class="" for="phone" translate>PHONE</label>
                <ips-phone-input name="phone" [twoCharCountryCode]="twoCharcountryCode" [phone]="address.DisplayPhone" #addressPhone (saveFormattedNumber)="phoneChangedEventHandler($event)" placeholder="{{ 'ENTER_PHONE' | translate}}"></ips-phone-input>
                <ips-show-errors [control]="addressPhone.inputControl" [errorMessages]="errorMessages"></ips-show-errors>
            </div>
        </div>
        <div *ngIf="showFax" class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="form-group">
                <label class="" for="fax" translate>FAX</label>
                <ips-phone-input name="fax" [twoCharCountryCode]="twoCharcountryCode" [phone]="address.DisplayFax" #addressFax (saveFormattedNumber)="faxChangedEventHandler($event)" placeholder="{{ 'ENTER_FAX' | translate}}"></ips-phone-input>
                <ips-show-errors [control]="addressFax.inputControl" [errorMessages]="errorMessages"></ips-show-errors>
            </div>
        </div>
        <div *ngIf="showEmail" class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="form-group">
                <label class="" for="email" translate>EMAIL</label>
                <input type="email" name="email" id="email" [(ngModel)]="address.Email" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_EMAIL' | translate}}"
                       #addressEmail="ngModel" appValidation maxlength="255" [email]="address.Email !='' && address.Email != null">
                <!--The code above for email is used to allow any empty string or null value. otherwise it was showing as invalid-->
                <div *ngIf="addressEmail.errors" class="invalid-feedback" translate>INVALID_EMAIL</div>
            </div>
        </div>
    </div>
</div>
