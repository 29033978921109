<div class="col" ipsFocus="searchInput">
    <ips-list-search searchLabel="{{'SURVEY' | translate }}"
                     searchLabelPlural="{{'SURVEYS' | translate }}"
                     labelLoading="{{'ACCESSING_SURVEY_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_SURVEY_INFO' | translate }}"
                     [listData]="survey"
                     (onListDataChange)="getListData($event.search)"
                     [section]="'main.survey'"
                     [scroller]=true
                     [quickRef]=false
                     [itemClickPermission]="'main.survey.view'"
                     [promise]="promise"
                     createNewTooltip="{{'CREATE_NEW_SURVEY' | translate }}"
                     [autoFocus]=true
                     [query]="query">
        <ng-template #listSearchItemTemplate let-result="result">
            <div class="row ips-search-result-big">
                <div class="col pr-0">
                    <a uiSref="main.survey.view" [uiParams]="{id:result.Id}" class="no-underline">
                        <div class="p-3">
                            <div class="row d-flex align-items-center">
                                <div class="col-10 col-md-auto"><span class="h2 mb-3" innerHtml="{{result.Name | highlight : query}}"></span></div>
                                <div class="col-2 col-md-auto pt-0 px-2 survey-status">
                                    <div class="{{result.SurveyStatusInfo.IconClass}}" ngbTooltip="{{result.SurveyStatusInfo.Tooltip | translate}}" placement="bottom"></div>
                                </div>
                                <div class="col-12 col-md-auto pt-0-md survey-date">{{result.StartDate | date : "mediumDate" : "+0000" : locale }} - {{result.EndDate ? (result.EndDate | date : "mediumDate" : "+0000" : locale) : ( 'NA' | translate)}}</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-2 col-md-1 mt-2" ipsPermission="main.survey.edit">
                    <a uiSref="main.survey.edit" [uiParams]="{id:result.Id}" class="no-underline">
                        <div class="pt-3 pr-3 d-flex justify-content-end">
                            <span class="fa fa-pencil"></span>
                        </div>
                    </a>
                </div>
            </div>
        </ng-template>
    </ips-list-search>
</div>
