import { Pipe, PipeTransform  } from "@angular/core";


@Pipe({
    name: "stringToSpaces"
})

export class StringToSpacesPipe implements PipeTransform {
    transform(value: string) {
        if ((typeof value) !== "string") {
            return value;
        }
        value = value.replace(/([^A-Z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][^A-Z])/g, "$1 $2");
        value = value[0].toUpperCase() + value.slice(1);
        return value;
    }
}
