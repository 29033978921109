import { Component, OnInit, NgZone } from "@angular/core";
import { StateService } from "@uirouter/core";
import { AuthService, OktaToken } from "imagine-ui-ng-security";
import { ProfileService } from "imagine-ui-ng-quick-start";
import { environment } from "../../environments/environment";
import { LoggingService } from "imagine-ui-ng-core";
import { VersionCheckService } from "../version-check/version-check.service";

@Component({
    selector: "app-sso-token-receiver",
    templateUrl: "./sso-token-receiver.component.html",
    styleUrls: ["./sso-token-receiver.component.scss"]
})
export class SsoTokenReceiverComponent implements OnInit {
    public failedSignIn = false;

    constructor(private $state: StateService, private authService: AuthService, private profileService: ProfileService, private zone: NgZone,
        private $log: LoggingService, private versionCheckService: VersionCheckService) {
    }

    ngOnInit() {

        console.log("ssoTokenReceiver constructor");

        this.authService.parseFromUrl().then(() => {
            console.log("ssoTokenReceive received id token successfully: ");
            this.zone.run(() => {
                this.loadUserAndRedirect();
            });
        }).catch((error) => {
            console.log("failed parseFromUrl");
            this.authService.signOut();
            //since the user is redirected to this page, the user has a session with okta
            //failed because it parses security token from url
            //so if there is a signOnMethod in localstorage redirect properly or just show the failed text
            if (this.profileService.isNonSso()) {
                this.zone.run(() => {
                    this.$state.go("public.login");
                });
            } else {
                // Show the failed text
                this.failedSignIn = true;
            }
        });
    }

    loadUserAndRedirect(): void {
        this.profileService.loadUserData().then(() => {
            const username = localStorage.getItem("username");
            const claims = localStorage.getItem("claims");
            const claimObj = JSON.parse(claims);
            const logItem = {
                user: username,
                application: environment.ApplicationName,
                event: "login",
                sessionId: claimObj.sub,
                locale: claimObj.locale,
                zoneInfo: claimObj.zoneinfo
            };
            this.$log.info(JSON.stringify(logItem), "ssoTokenReceiver.loadUserAndRedirect()");

            //store the time user logged in for checking user max session timeout
            sessionStorage.setItem("loginTime", Date.now().toString());

            //Force a version check here before navigating to existing routes
            this.versionCheckService.checkForReload("/assets/version.json").then(isReloading => {
                //Look for false so we know we are not just reloading the page
                if (!isReloading) {
                    this.$state.go("main.home", {}, {});
                }
            });
        });
    }
}
