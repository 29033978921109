import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { environment } from "../../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { BaseWebServiceWithDefaultImage } from "imagine-ui-ng-quick-start";
import { IHaveName } from "../../model/IHaveName";
import { LocationGroupModel } from "../../model/LocationGroupModel";
import { LocationGroupAssignmentModel } from "../../model/LocationGroupAssignmentModel";
import { SimpleSearchLocationGroupModel } from "../../model/SimpleSearchLocationGroupModel";
import { SimpleSearchLocationGroupWithSubModel } from "../../model/SimpleSearchLocationGroupWithSubModel";
import { OptionType } from "../../type/OptionType";
import { LocationSubGroupModel } from "../../model/LocationSubGroupModel";

@Injectable()
export class LocationGroupService extends BaseWebServiceWithDefaultImage {

    constructor(http: HttpClient, activeProfileService: ActiveProfileService, authService: AuthService) {
        super(http, <any>environment, activeProfileService, authService);
        this.init("LocationGroup", "Profile", "v1");
    }

    public canSwitchRule(locationGroupId: string): Promise<boolean> {
      let route = `${this.urlApiBaseProductPlusVersion}LocationGroup/{0}/canSwitchRule`;
      return this.getByRoute(route.replace("{0}", locationGroupId));
    }

    public setLocFeatureInfo(locationGroup: LocationGroupModel, $translate: TranslateService): void {
        let singleAssignmentTooltip = "TOOLTIP_LOCATION_GROUP_RULE_SINGLE";
        let multipleAssignmentTooltip = "TOOLTIP_LOCATION_GROUP_RULE_MULTIPLE";
        let singleAssignmentClass = "location-group-rule-single";
        let multipleAssignmentClass = "location-group-rule-multiple";
        let textOptionClass = "option-type-text-icon";
        let yesNoOptionClass = "option-type-bool-icon";
        let numberOptionClass = "option-type-number-icon";
        let dateOptionClass = "option-type-date-icon";

        switch (locationGroup.Rule) {
          case "Multiple":
              locationGroup.ruleTooltip = multipleAssignmentTooltip;
              locationGroup.ruleText = $translate.instant("LOCATION_GROUP_RULE_MULTIPLE");
              locationGroup.ruleClass = multipleAssignmentClass;
              break;
          case "Single":
              locationGroup.ruleTooltip = singleAssignmentTooltip;
              locationGroup.ruleText = $translate.instant("LOCATION_GROUP_RULE_SINGLE");
              locationGroup.ruleClass = singleAssignmentClass;
              break;
          default:
              locationGroup.ruleText = "";
              locationGroup.ruleClass = "";
              locationGroup.ruleTooltip = "";
              break;
        }

        switch (locationGroup.OptionType) {
          case "Number":
              locationGroup.optionTypeText = $translate.instant("OPTION_TYPE_NUMBER");
              locationGroup.optionTypeClass = numberOptionClass;
              break;
          case "Bool":
              locationGroup.optionTypeText = $translate.instant("OPTION_TYPE_BOOL");
              locationGroup.optionTypeClass = yesNoOptionClass;
              break;
          case "Date":
              locationGroup.optionTypeText = $translate.instant("OPTION_TYPE_DATE");
              locationGroup.optionTypeClass = dateOptionClass;
              break;
          default:
              locationGroup.optionTypeText = $translate.instant("OPTION_TYPE_TEXT");
              locationGroup.optionTypeClass = textOptionClass;
              break;
        }

        locationGroup.ruleTooltip = $translate.instant(locationGroup.ruleTooltip).replace("{0}", locationGroup.Name);
    }

    public saveList(locationId: string, locationGroupList: LocationGroupAssignmentModel[]): Promise<LocationGroupAssignmentModel[]> {
      let route = `${this.urlApiBaseProductPlusVersion}Location/${locationId}/LocationGroup/saveList`;
      return this.put(locationGroupList, null, route);
    }

    public simpleSearch(searchParams: SearchInfo): Promise<SearchResponse<SimpleSearchLocationGroupModel>> {
      return this.search<SearchResponse<SimpleSearchLocationGroupModel>>(searchParams, "LocationGroup/SimpleSearch");
    }

    public simpleSearchWithSubGroup(searchParams: SearchInfo): Promise<SearchResponse<SimpleSearchLocationGroupWithSubModel>> {
        return this.search<SearchResponse<SimpleSearchLocationGroupWithSubModel>>(searchParams, "LocationGroup/SimpleSearchWithSub");
    }

    public compareNumber() {
        return (a, b) => {
            if (a.IsNA) return 1;
            if (b.IsNA) return -1;
            return a.Name - b.Name;
        };
    }

    public compareString() {
        return (a, b) => {
            if (a.IsNA) return 1;
            if (b.IsNA) return -1;
            return a.Name.localeCompare(b.Name);
        };
    }

    public compareDate() {
        return (a, b) => {
            if (a.IsNA) return 1;
            if (b.IsNA) return -1;
            a = new Date(a.Name);
            b = new Date(b.Name);
            return a > b ? 1 : a < b ? -1 : 0;
        };
    }

    public sortSubGroup(subGroups: IHaveName[], optionType: OptionType) {
        switch (optionType) {
        case "Number":
            subGroups.sort(this.compareNumber());
            break;
        case "Date":
            subGroups.sort(this.compareDate());
            break;
        default:
            subGroups.sort(this.compareString());
            break;
        }
    }

    public saveFeatureImage(locationFeatureId: number, file: any): Promise<LocationGroupModel> {
        let route = `${this.urlApiBaseProductPlusVersion}LocationGroup/${locationFeatureId}/Image`;
        return this.put({ Id: locationFeatureId }, file, route);
    }

    public saveOptionImage(featureOptionId: number, file: any): Promise<LocationSubGroupModel> {
        let route = `${this.urlApiBaseProductPlusVersion}LocationSubGroup/${featureOptionId}/Image`;
        return this.put({ Id: featureOptionId }, file, route);
    }

    public deleteCheck(id: string) {
        let route = `${this.urlApiBaseProductPlusVersion}LocationSubGroup/${id}/DeleteCheck`;
        return this.getByRoute(route);
    }
}
