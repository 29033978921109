import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CountryModel } from "imagine-ui-ng-core";

@Component({
    selector: "app-cart-country-list-modal",
    templateUrl: "./cart-country-list-modal.component.html",
    styleUrls: ["./cart-country-list-modal.component.scss"]
})

export class CartCountryListModalComponent implements OnInit {
    // ----- DATA -----
    @Input() AvailableCountries: CountryModel[];

    constructor(private modalInstance: NgbActiveModal) {

    }

    //
    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    //
    public returnResult(countryCode: string) {
        this.modalInstance.close(countryCode);
    }

    // ----- ON INIT -----
    //
    ngOnInit() {

    }
}
