import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: "root"
})
export class CustomerRequestService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("CustomerRequest", "Profile", "v1");
    }

    public submitRequest(request, file) {
        const route = `${this.urlApiBaseProductPlusVersion}CustomerRequest`;
        return this.post(request, file, route);
    }
}
