/**
 * The code is from https://github.com/angular/angular/issues/14440
 * The base class works for template form
 */

import { NgForm } from "@angular/forms";

export class TemplateFormBase {

    public setFormPristine(ngForm: NgForm, timeoutMs = 0) {
        setTimeout(() => {
            if (ngForm !== undefined) {
                for (const key in ngForm.form.controls) {
                    if (ngForm.form.controls[key]) {
                        ngForm.form.controls[key].markAsPristine();
                    }
                }
                ngForm.form.markAsPristine();
            }
        }, timeoutMs);
    }
}
