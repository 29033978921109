<app-sticky-header></app-sticky-header>
<div class="col-12 d-block d-md-none">
    <div id="noticeContainer" class="message-container mt-3">
        <div class="notice-label-set"><i class="fa fa-bell notice-icon" aria-hidden="true"></i>{{'NOTICE_ALLCAPS' | translate}}:</div>11111
        <div class="notice-text" translate>EDIT_CAMPAIGNS_FOR_MOBILE</div>
    </div>
</div>

<div class="col-12 d-none d-md-block p-0" stickyHeader="">
    <h3>{{breadCrumbLabel | translate}}</h3>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_PROMOTION_INFO' | translate}}'}">
        <div *ngIf="loaded">
            <form id="ramiInfoInput" class="form-horizontal" name="promoForm" [formGroup]="promoForm" novalidate ipsUnsavedWarningForm>

                <!--Campaign Info-->
                <div class="card ips-card-gray mb-4">
                    <div class="card-body px-4 pb-1">
                        <div class="row">
                            <div class="col-12">
                                <label class="font-weight-bold">{{'CAMPAIGN' | translate}}:</label> {{campaignName}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="font-weight-bold">{{'IN_STORE_DATE' | translate}}:</label> {{campaignInStore | date:'mediumDate'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="font-weight-bold">{{'START_END_DATE' | translate}}:</label> {{campaignStart | date:'mediumDate'}} - {{campaignEnd | date:'mediumDate'}}
                            </div>
                        </div>
                    </div>
                </div>

                <!--Promo Info-->
                <div class="card ips-shadow mb-4" stickyHeader="PROMOTION_HEADER" stickyHeaderImage="campaign-promo-header-icon">
                    <div class="card-body">
                        <div class="form-row ips-sticky-top-main bg-white">
                            <div class="form-group col-12 mb-0 pr-0">
                                <div class="ips-section-header pb-0 d-flex align-items-center">
                                    <div class="campaign-promo-header-icon icon"></div>
                                    <span translate class="flex-grow-1">PROMOTION_HEADER</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="form-row">
                            <div class="form-group col-12 col-md-4">
                                <label class="required" for="Name" translate>NAME</label>
                                <input type="text" name="Name" id="Name" formControlName="Name" class="form-control form-control-lg input-full" placeholder="{{ 'ENTER_PROMOTION_NAME' | translate}}" focus="true">
                                <ips-show-errors [control]="Name" [errorMessages]="getErrorMessages('Name')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                            <div class="form-group col-12 col-md-4">
                                <label class="required" for="StartDate" translate>{{StartDateText}}</label>
                                <div class="input-group ips-input-group-icon">
                                    <input type="text" name="StartDate" id="StartDate" class="form-control form-control-lg input-full"
                                           formControlName="StartDate"
                                           placeholder="{{ 'ENTER_START_DATE' | translate }}"
                                           #d="bsDatepicker" bsDatepicker [bsConfig]="startDateOptions" (bsValueChange)="changeStartDate($event)" (blur)="blurStartDate($event)" triggers="" />
                                    <span class="input-group-append">
                                        <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                    </span>
                                </div>
                                <ips-show-errors [control]="StartDate" [errorMessages]="getErrorMessages('StartDate')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                            <div class="form-group col-12 col-md-4">
                                <label for="EndDate" translate>{{EndDateText}}</label>
                                <div class="input-group ips-input-group-icon">
                                    <input type="text" name="EndDate" id="EndDate" class="form-control form-control-lg input-full"
                                           formControlName="EndDate"
                                           placeholder="{{ 'ENTER_END_DATE' | translate }}"
                                           #d2="bsDatepicker" bsDatepicker [bsConfig]="endDateOptions" triggers="" />
                                    <span class="input-group-append">
                                        <button type="button" class="btn btn-secondary form-control-lg imagine-calendar-btn-mod" (click)="d2.toggle()"><i class="fa fa-calendar imagine-calendar-icon-mod"></i></button>
                                    </span>
                                </div>
                                <ips-show-errors [control]="EndDate" [errorMessages]="getErrorMessages('EndDate')" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Notes" translate>NOTES</label>
                            <textarea class="form-control form-control-lg input-full" name="Notes" id="Notes" cols="30" rows="3" formControlName="Notes"></textarea>
                            <ips-show-errors [control]="Notes" [errorMessages]="getErrorMessages('Notes')" cssClass="validation-message-Qty"></ips-show-errors>
                        </div>

                        <app-custom-data-field-container area="PromotionHeader" [linkId]="promotionId" [parent]="promoForm" (loaded)="promoCdfsLoadedHandler()"></app-custom-data-field-container>

                    </div>
                </div>


                <!--Messaging Info-->
                <div id="messageEditCard" class="card ips-shadow mb-4" stickyHeader="MESSAGING" stickyHeaderImage="campaign-message-icon">
                    <div class="card-body">
                        <div class="form-row ips-sticky-top-main bg-white">
                            <div class="form-group col-12 mb-0 pr-0">
                                <div class="ips-section-header pb-0 d-flex align-items-center">
                                    <div class="campaign-message-icon icon"></div>
                                    <span translate class="flex-grow-1">MESSAGING</span>
                                    <button class="text-left button-no-style tooltip-container" ngbTooltip="{{'MESSAGE_HEADER_TIP' | translate}}" placement="bottom-right">
                                        <i class="fa fa-info-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div class="form-group" *ngIf="!showMessageEditComponent">
                            <button id="createMessage"
                                    class="btn btn-lg btn-block add-promotion ips-add-bar"
                                    (click)="createBlankMessage()">
                                <i class="fa fa-plus addIconLarge" aria-hidden="true"></i>
                                <span class="add-holder-label" translate>CREATE_MESSAGE</span>
                                <span class="pull-right color-gray" ngbTooltip="{{'ADD_MESSAGE' | translate}}" placement="bottom">
                                    <i class="fa fa-info-circle"></i>
                                </span>
                            </button>
                        </div>
                        <div class="" *ngIf="showMessageEditComponent">
                            <div class="form-row" *ngIf="createMessaging ">
                                <div class="form-group col-6 mt-2" *ngIf="createMessaging || useMessagePattern">
                                    <div class="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" class="btn btn-default " [ngClass]="{'active': selectedMessageType === 'ordinaryMessage' }" (click)="buttonSelectionChanged('ordinaryMessage')">Messaging</button>
                                        <button type="button" class="btn btn-default" [ngClass]="{'active': selectedMessageType === 'patternMessage' }" (click)="buttonSelectionChanged('patternMessage')"><i class="pattern-icon"></i> Pattern Messaging</button>
                                        <button type="button" class="btn btn-default" [ngClass]="{'active': selectedMessageType === 'priorityFill' }" (click)="buttonSelectionChanged('priorityFill')"><i class="priority-icon"></i> Priority Fill</button>
                                    </div>
                                    <br />
                                </div>
                                <div class="form-group col-6 mt-2"></div>
                                <div class="form-group col-6 mt-2">
                                    <div class="input-group ips-input-group" *ngIf="createMessaging && selectedMessageType === 'patternMessage'">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><div class="pattern-icon"></div></div>
                                        </div>
                                        <input type="text" class="form-control form-control-lg" disabled value="{{'PATTERN' | translate}}">
                                    </div>
                                </div>
                                <div class="form-group col-6 mt-2" *ngIf="promotionId !== 0 && selectedMessageType === 'priorityFill'">
                                </div>
                                <div class="form-group col-6 mt-2">
                                    <div class="input-group ips-input-group" *ngIf="useMessagePattern || selectedMessageType === 'priorityFill'">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text"><div class="drag-drop-icon-sm"></div></div>
                                        </div>
                                        <input type="text" class="form-control form-control-lg" disabled value="{{'REORDER_MESSAGES' | translate}}">
                                        <div class="input-group-append">
                                            <button class="btn btn-default" (click)="toggleReorderMessages(false)" [ngClass]="{'active': !reorderMessages }" translate>OFF</button>
                                            <button class="btn btn-default" (click)="toggleReorderMessages(true)" [ngClass]="{'active': reorderMessages }" translate>ON</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <app-message-edit *ngIf="!useMessagePattern && selectedMessageType === 'ordinaryMessage'"
                                              [parent]="promoForm"
                                              [patternGroupsModel]="patternGroups"
                                              [tempMessageId]="tempMessageId"
                                              (messageDeleted)="messageDeletedEventHandler($event)"
                                              (messageAdded)="messageAddedEventHandler($event)"
                                              (messageNameChanged)="messageNameChangedEventHandler($event)"
                                              (marketChanged)="marketChangedEventHandler($event)"
                                              (cdfsLoaded)="messageCdfsLoadedHandler()"
                                              [isClone]="isCloneMode"></app-message-edit>
                            <app-message-pattern-edit *ngIf="useMessagePattern && selectedMessageType === 'patternMessage'"
                                                      [parent]="promoForm"
                                                      [patternGroupsModel]="patternGroups"
                                                      [tempMessageId]="tempMessageId"
                                                      (messageDeleted)="messageDeletedEventHandler($event)"
                                                      (messageAdded)="messageAddedEventHandler($event)"
                                                      (messageNameChanged)="messageNameChangedEventHandler($event)"
                                                      (marketChanged)="marketChangedEventHandler($event)"
                                                      (messageOrdinalChanged)="messageOrdinalChangedEventHandler($event)"
                                                      (cdfsLoaded)="messageCdfsLoadedHandler()"
                                                      [isClone]="isCloneMode"></app-message-pattern-edit>
                            <app-message-priority-fill-edit *ngIf="selectedMessageType === 'priorityFill'"
                                                            [parent]="promoForm"
                                                            [patternGroupsModel]="patternGroups"
                                                            [tempMessageId]="tempMessageId"
                                                            (messageDeleted)="messageDeletedEventHandler($event)"
                                                            (messageAdded)="messageAddedEventHandler($event)"
                                                            (messageNameChanged)="messageNameChangedEventHandler($event)"
                                                            (marketChanged)="marketChangedEventHandler($event)"
                                                            (messageOrdinalChanged)="messageOrdinalChangedEventHandler($event)"
                                                            (cdfsLoaded)="messageCdfsLoadedHandler()"
                                                            [isClone]="isCloneMode"></app-message-priority-fill-edit>
                        </div>
                    </div>
                </div>


                <!--Fixture Placement-->
                <div id="fixturePlacementCard" class="card ips-shadow mb-4" *ngIf="showMessageEditComponent" stickyHeader="FIXTURE_PLACEMENT" stickyHeaderImage="campaign-placement-icon">
                    <div class="card-body">
                        <div class="form-row ips-sticky-top-main bg-white">
                            <div class="form-group col-12 mb-0 pr-0">
                                <div class="ips-section-header pb-0 d-flex align-items-center">
                                    <div class="campaign-placement-icon icon"></div>
                                    <span translate class="flex-grow-1">FIXTURE_PLACEMENT</span>
                                    <button class="text-left button-no-style tooltip-container" ngbTooltip="{{'MESSAGE_PLACEMENT_TIP' | translate}}" placement="bottom-right">
                                        <i class="fa fa-info-circle pt-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <app-placement-edit [parent]="promoForm" [messageAndPlacements]="promotionMessageAndPlacements"
                                            [campaignFulfillmentQty]="campaignFulfillmentQty" [campaignFulfillmentType]="campaignFulfillmentType"
                                            [promoId]="promotionId" [useMessagePattern]="useMessagePattern" [patternGroups]="patternGroups" [isClone]="isCloneMode" [selectedMessagePattern]="selectedMessageType"></app-placement-edit>
                        <div class="form-row">
                            <div class="form-group col-lg-12 mt-2 mb-2">
                                <button class="btn btn-lg btn-block text-left ips-add-bar pr-2" (click)="placeFixture()">
                                    <i class="fa fa-plus addIconLarge" aria-hidden="true"></i>
                                    <span class="add-holder-label" translate>PLACE_MESSAGE</span>
                                    <span class="pull-right color-gray tooltip-container" ngbTooltip="{{'TOOLTIP_PLACE_MESSAGE' | translate}}" placement="bottom-right">
                                        <i class="fa fa-info-circle"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Element Placement-->
                <div id="elementPlacementCard" class="card ips-shadow mb-4" *ngIf="showMessageEditComponent && !useMessagePattern && selectedMessageType!=='priorityFill'" stickyHeader="ELEMENT_PLACEMENT" stickyHeaderImage="campaign-placement-element-icon">
                    <div class="card-body">
                        <div class="form-row ips-sticky-top-main bg-white">
                            <div class="form-group col-12 mb-0 pr-0">
                                <div class="ips-section-header pb-0 d-flex align-items-center">
                                    <div class="campaign-placement-element-icon icon"></div>
                                    <span translate class="flex-grow-1">ELEMENT_PLACEMENT</span>
                                    <button class="text-left button-no-style tooltip-container" ngbTooltip="{{'ELEMENT_PLACEMENT_TIP' | translate}}" placement="bottom-right">
                                        <i class="fa fa-info-circle pt-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr />

                        <app-placement-element-edit [parent]="promoForm" [messageAndPlacements]="promotionMessageAndPlacements"
                                                    [campaignFulfillmentQty]="campaignFulfillmentQty" [campaignFulfillmentType]="campaignFulfillmentType"
                                                    [campaignElementQty]="campaignElementQty"
                                                    [promoId]="promotionId"
                                                    [enableSignPlanLayout]="enableSignPlanLayout"
                                                    [isClone]="isCloneMode"></app-placement-element-edit>


                        <div class="form-row">
                            <div class="form-group col-lg-12 mt-2 mb-2">
                                <button class="btn btn-lg btn-block text-left ips-add-bar pr-2" (click)="placeElement()">
                                    <i class="fa fa-plus addIconLarge" aria-hidden="true"></i>
                                    <span class="add-holder-label" translate>ADD_ELEMENT</span>
                                    <span class="pull-right color-gray tooltip-container" ngbTooltip="{{'TOOLTIP_PLACE_MESSAGE' | translate}}" placement="bottom-right">
                                        <i class="fa fa-info-circle"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Status Info-->
                <div class="card ips-shadow mb-4" stickyHeader="STATUS" stickyHeaderImage="campaign-status-icon">
                    <div class="card-body">
                        <div class="form-row ips-sticky-top-main bg-white">
                            <div class="form-group col-12 mb-0 pr-0">
                                <div class="ips-section-header pb-0 d-flex align-items-center">
                                    <div class="campaign-status-icon icon"></div>
                                    <span translate class="flex-grow-1">STATUS</span>
                                    <button class="text-left button-no-style tooltip-container" ngbTooltip="{{'STATUS_HEADER_TIP' | translate}}" placement="bottom-right">
                                        <i class="fa fa-info-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="form-row">
                            <div class="form-group col-12 col-md-4">
                                <select class="ng-cloak form-control form-control-lg" [(ngModel)]="selectedStatus" (ngModelChange)="change($event)" [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let x of promotionStatus" [value]="x.Status">{{x.Status}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>



                <hr class="d-none d-lg-block mt-4" />
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="savePromo"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="savePromoPrompt(false)" translate>
                                SAVE
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveReturnPromo"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="savePromoPrompt(true)" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="cancelPromo" class="btn btn-default btn-lg btn-block" uiSref="main.campaign.view" [uiParams]="{id: campaignId}" type="submit" translate>CANCEL</button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="deletePromo"
                                    class="btn btn-lg btn-block alert-danger"
                                    (click)="deletePromoPrompt()"
                                    ips-unsaved-warning-clear
                                    [disabled]="!promoForm.value.Id"
                                    [ngClass]="{'btn-default': !promoForm.value.Id}" translate>
                                DELETE_PROMOTION
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_PROMOTION_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
