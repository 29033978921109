<div>
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0">{{modalTitle | translate}}</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive">
            <ips-list-search [searchLabel]="searchLabel"
                             [searchLabelPlural]="searchLabelPlural"
                             [labelLoading]="loadingLabel"
                             hideHeader=true
                             [listData]="listSearch"
                             [busy]="busy"
                             (onListDataChange)="getListData($event.search)"
                             section=""
                             [scroller]=true
                             quickRef=false
                             (onItemClicked)="itemClicked($event)"
                             [promise]="promise"
                             [autoFocus]="true"
                             [isModal]="true"
                             [hideTotals]="hideTotals">
                <div *ngIf="this.searchType?.toLowerCase() === 'campaign'">
                    <!-- search form template -->
                    <ng-template #searchHeaderTemplate>
                        <div class="row">
                            <section class="col-12">
                                <div class="ips-ls-search-module-imgn">
                                    <div class="my-3">
                                        <div class="input-group">
                                            <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                                            <input type="text" class="form-control form-control-lg ips-ls-input long-text-wrap"
                                                   placeholder=""
                                                   [(ngModel)]="query" [ngModelOptions]="{standalone:true}"
                                                   (ngModelChange)="onQueryChange($event)" />
                                        </div>
                                    </div>

                                    <div class="row no-gutters mb-3 mb-md-0">
                                        <div class="col-12 col-md-auto ml-3">
                                            <span class="badge-pill badge-secondary font-weight-normal">{{ listSearch.ResultChunkAttributes.TotalRecords | number }}</span>
                                            <span class="ml-1">{{listSearch.ResultChunkAttributes.TotalRecords | i18nPlural: pluralMapping}} {{'FOUND' | translate}}</span>
                                        </div>
                                        <div class="col-12 col-md ml-3 mt-1 mt-md-0">
                                            <span class="badge-pill badge-secondary font-weight-normal">{{ listSearch.TotalRecords | number }}</span>
                                            <span class="ml-1">{{ 'TOTAL' | translate}} {{listSearch.TotalRecords | i18nPlural: pluralMapping}}</span>
                                        </div>
                                        <div class="col-12 col-md text-md-right">
                                            <div dropdown>
                                                <button id="button-basic" dropdownToggle type="button" class="btn btn-default dropdown-toggle btn-block dropdown-toggle"
                                                        aria-controls="dropdown-basic">
                                                    <span class="mr-1">Sort By:</span>
                                                    <span class="font-weight-bold">{{ selectedSortOption.fieldLabel }} <span class="font-weight-normal">({{ selectedSortOption.directionLabel }})</span></span>
                                                </button>
                                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                                                    role="menu" aria-labelledby="button-basic">
                                                    <li role="menuitem" *ngFor="let option of sortOptions" (click)="sortOptionSelected(option)">
                                                        <div class="dropdown-item clicker">
                                                            {{ option.fieldLabel }} <span class="">({{ option.directionLabel }})</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </ng-template>
                </div>

                <div *ngIf="multiSelection">
                    <ng-template #listSearchItemTemplate let-result="result">
                        <div class="input-group no-round">
                            <div class="form-control input-full py-3 border-0 clicker" (click)="itemChecked(result)">
                                {{result.Label | translate}}
                            </div>
                            <div class="input-group-append">
                                <div class="input-group-text border-top-0 border-bottom-0 border-right-0">
                                    <input name="{{'selectedCheck' + result.Label}}" id="selectedCheck" type="checkbox" [(ngModel)]="result.Selected" (ngModelChange)="selectedOptionChanged(result)" />
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="this.searchType.toLowerCase() === 'allpromotion' || this.searchType.toLowerCase() === 'campaign'">
                    <ng-template #listSearchItemTemplate let-result="result">
                        <button class="list-group-item no-border clicker list-group-item-action" [disabled]="result.Id === idToDisable">
                            <div>{{result.Label}}</div>
                            <div><b>{{result.LabelRow2Bold}}</b><span *ngIf="result.LabelRow2Bold">: </span>{{result.LabelRow2}}</div>
                        </button>
                    </ng-template>
                </div>
                <div *ngIf="this.searchType.toLowerCase() === 'alternateaddress'">
                    <ng-template #listSearchItemTemplate let-result="result">
                        <div class="row">
                            <div class="col pr-0">
                                <div class="px-3 py-2 loc-label clicker">
                                    <div *ngIf="!result.AddressIdentifier">
                                        <span class="font-weight-bold">{{result.Name | translate}}</span>
                                    </div>
                                    <div *ngIf="result.AddressIdentifier">
                                        <span class="font-weight-bold">{{result.AddressIdentifier + " - " + result.Name}}</span>
                                    </div>
                                    <div *ngIf="result.City" class="loc-city-state">{{result.City}}, {{result.StateProvince}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="this.searchType.toLowerCase() === 'itemcategory'">
                    <ng-template #listSearchItemTemplate let-result="result">
                        <div class="row">
                            <div class="col pr-0">
                                <div class="px-3 py-2 clicker">
                                    <div>{{result.Label}}</div>
                                    <small *ngIf="result.HierarchyPath" class="text-muted">Parent Category: {{result.HierarchyPath}}</small>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ips-list-search>
            <div class="row" *ngIf="multiSelection">
                <div class="col-12 pl-4 pr-4">
                    <button type="submit" class="btn btn-default btn-lg btn-block search-modal-done-button mt-2"
                            id="doneButton"
                            (click)="returnResults()" translate>
                        DONE
                    </button>
                </div>
            </div>

        </div>
    </form>
</div>
