var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var AlternateAddressService = /** @class */ (function (_super) {
    __extends(AlternateAddressService, _super);
    function AlternateAddressService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("AlternateAddress", "Profile", "v1");
        return _this;
    }
    AlternateAddressService.prototype.get = function (id) {
        return this.getByRoute(this.urlApiBaseProductPlusVersion + "AlternateAddress/Address/" + id + "?AddressType=Campaign");
    };
    AlternateAddressService.prototype.head = function () {
        return _super.prototype.headByRoute.call(this, this.urlApiBaseProductPlusVersion + "AlternateAddress/Address?AddressType=Campaign");
    };
    AlternateAddressService.prototype.post = function (inputData) {
        return _super.prototype.post.call(this, inputData, null, this.urlApiBaseProductPlusVersion + "AlternateAddress/Address");
    };
    AlternateAddressService.prototype.put = function (inputData) {
        return _super.prototype.put.call(this, inputData, null, this.urlApiBaseProductPlusVersion + "AlternateAddress/Address");
    };
    AlternateAddressService.prototype.delete = function (id) {
        return _super.prototype.deleteByRoute.call(this, this.urlApiBaseProductPlusVersion + "AlternateAddress/Address/" + id);
    };
    return AlternateAddressService;
}(IpsBaseWebService));
export { AlternateAddressService };
