<div>
    <div>
        <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
    </div>
    <div class="ips-modal-header ips-modal-dark-header">
        <h4 class="modal-title m-0" translate>SELECT_CHECKOUT_LOCATIONS</h4>
    </div>
    <div class="modal-body">
        <div class="ips-ls-search-module-imgn mb-3">
            <div class="input-group my-3">
                <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                <input type="text" [(ngModel)]="filter" (input)="filterUpdated()"
                       class="form-control form-control-lg ips-ls-input long-text-wrap">
            </div>
            <div class="row no-gutters mt-3">
                <div class="col-12 col-md-auto ml-3">
                    <span class="badge-pill badge-secondary font-weight-normal">{{ totalSelected | number }}</span>
                    <span class="ml-1" translate>{{totalSelected | i18nPlural: pluralMapping}} {{'SELECTED_OF' | translate}}</span>
                </div>
                <div class="col-12 col-md-auto ml-3 ml-md-2 mt-1 mt-md-0">
                    <span class="badge-pill badge-secondary font-weight-normal">{{ total | number }}</span>
                    <span class="ml-1">{{'TOTAL' | translate}} {{total | i18nPlural: pluralMapping}}</span>
                </div>
            </div>
        </div>
        <div class="form-row mb-3">
            <div class="col-6">
                <button [ipsFocus]="true" class="btn btn-default btn-lg btn-block search-modal-done-button"
                        (click)="selectAll(allowPayment)" translate>
                    SELECT_ALL
                </button>
            </div>
            <div class="col-6">
                <button class="btn btn-default btn-lg btn-block search-modal-done-button"
                        (click)="deselectAll()" [disabled]="totalSelected == 0" translate>
                    CLEAR_ALL
                </button>
            </div>
        </div>
        <div class="ips-ls-long-text-wrap list-group mb-3">
            <div class="modal-scrollable list-search-infinite-container">
                <div *ngFor="let location of availableLocations"
                     class="card ips-shadow mb-2 subgroup-card"
                     [ngClass]="{'ips-checked-card': location.IsSelected, 'disabled': disabledFilter(location), 'border-warning': (!location.Invoice && !location.CreditCard) }">
                    <div class="d-flex flex-row align-items-center">
                        <div class="p-3 pl-3 flex-grow-1 clicker"
                             (click)="locationClicked(location)" [ngClass]="{'disabled': disabledFilter(location) }">
                            {{location.Label}}
                            <div class="card-body text-warning" *ngIf="!location.Invoice && !location.CreditCard">
                                <p class="card-text" translate>LOCATION_NO_PAYMENT_METHODS</p>
                            </div>
                            <small *ngIf="location.ValidationStatus != 'Validated'"  class="d-block text-black-50 pt-1"><span class="fa fa-fw fa-exclamation-triangle text-warning"></span>{{'WARNING_NON_VALID_ADDRESS' | translate}}</small>
                        </div>
                        <div *ngIf="location.Invoice" class="p-2" (click)="locationClicked(location)">
                            <i class="fa fa-file-text-o fa-2x pr-3 location-check clicker" [ngClass]="{'disabled': disabledFilter(location), 'ips-color-gray-dark': allowPayment === 'Credit' }"></i>
                        </div>
                        <div *ngIf="location.CreditCard" class="p-2" (click)="locationClicked(location)">
                            <i class="fa fa-credit-card fa-2x pr-3 location-check clicker" [ngClass]="{'disabled': disabledFilter(location), 'ips-color-gray-dark': allowPayment === 'Invoice' }"></i>
                        </div>
                        <div *ngIf="location.IsSelected" class="p-2" (click)="locationClicked(location)">
                            <i class="fa fa-check fa-2x pr-3 location-check clicker"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-3">
                <button class="btn btn-default btn-lg btn-block search-modal-done-button"
                        (click)="returnResults()" [disabled]="totalSelected === 0" translate>
                    PROCEED_TO_CHECKOUT
                </button>
            </div>
            <div class="col-12">
                <button class="btn btn-default btn-lg btn-block search-modal-done-button"
                        (click)="close()" translate>
                    DISCARD_AND_RETURN_TO_CART
                </button>
            </div>
        </div>
    </div>
</div>
