import { Component, OnInit } from "@angular/core";
import { ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { CustomDataService } from "../../service/custom-data.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-custom-data-search",
  templateUrl: "./custom-data-search.component.html",
  styleUrls: ["./custom-data-search.component.scss"]
})
export class CustomDataSearchComponent implements OnInit {
    public query: string;
    public customData: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<{}>;
    public createNewTooltip: string;
    public searchLabel: string;
    public searchLabelPlural: string;
    public translated = false;

    constructor(private customDataService: CustomDataService, private listSearchHelper: ListSearchHelper, private translateService: TranslateService) {
        this.customData = this.listSearchHelper.getListSearchHelper(customDataService);
        this.busy = this.customData.busy;

        customDataService.head().then((data) => {
            this.customData.TotalRecords = data;
        });
    }

    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
        this.getListData("");
    }

    private translateText() {
        this.translateService.get(["CUSTOM_DATA_FIELD", "CUSTOM_DATA_FIELDS", "CREATE_NEW_CUSTOM_FIELD"]).subscribe((res: [string]) => {
            this.createNewTooltip = res["CREATE_NEW_CUSTOM_FIELD"];
            this.searchLabel = res["CUSTOM_DATA_FIELD"];
            this.searchLabelPlural = res["CUSTOM_DATA_FIELDS"];
            this.translated = true;
        });
    }

    public getListData(search: string) {
        if (this.busy) {
            return;
        }

        this.query = search;
        let searchInfo: SearchInfo = {
            searchText: search === undefined ? "" : search
        };
        this.promise = this.customData.searchHelper(searchInfo);
    }

}
