<h3 class="title ml-3">Brand Settings</h3>

<div class="ips-flex-loading-box mb-4" *ngIf="!screenReady">
    <div class="content">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
        <span class="d-block">Loading Brand Settings...</span>
    </div>
</div>


<div *ngIf="screenReady">
    <form #settingsForm="ngForm" id="settingsForm" class="form-horizontal updateSection" name="settingsForm" novalidate ipsUnsavedWarningForm role="form">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="profilePageNotice">Home Page Message</label>
                    <textarea class="form-control form-control-lg input-full"
                              [(ngModel)]="homePageMessage.Value" #homePageMsg="ngModel"
                              name="homePageMessage" id="homePageMessage"
                              cols="30" rows="3" value=""
                              IpsMaxLength="1500" appValidation></textarea>
                    <ips-show-errors [control]="homePageMsg" [errorMessages]="errorMessages"></ips-show-errors>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="profilePageNotice">Request Form Instruction</label>
                    <textarea class="form-control form-control-lg input-full"
                              [(ngModel)]="requestFormInstruction.Value" #requestForm="ngModel"
                              name="requestFormInstruction" id="requestFormInstruction"
                              cols="30" rows="3" value=""
                              IpsMaxLength="1500" appValidation></textarea>
                    <ips-show-errors [control]="requestForm" [errorMessages]="errorMessages"></ips-show-errors>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group">
                    <button id="saveLocationGroup"
                            class="btn btn-lg btn-block alert-success"
                            (click)="saveBrandSettingsPrompt(false)"
                            [disabled]="settingsForm.invalid || settingsForm.pristine"
                            [ngClass]="{'btn-default': settingsForm.invalid || settingsForm.pristine}" translate>
                        SAVE
                    </button>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group">
                    <button id="saveReturnSpace"
                            class="btn btn-lg btn-block alert-success" type="submit"
                            (click)="saveBrandSettingsPrompt(true)"
                            [disabled]="settingsForm.invalid || settingsForm.pristine"
                            [ngClass]="{'btn-default': settingsForm.invalid || settingsForm.pristine}" translate>
                        SAVE_AND_RETURN
                    </button>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3">
                <div class="form-group">
                    <button id="cancel" class="btn btn-lg btn-block" type="submit" uiSref="main.toolsControls" translate>CANCEL</button>
                </div>
            </div>
        </div>
    </form>
</div>
