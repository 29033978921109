<div class="col-md-12">
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_LOCATION_GROUP_INFO' | translate}}'}">
        <div *ngIf="loaded">
            <form id="ramiInfoInput" class="form-horizontal" name="locationGroupForm" [formGroup]="locationGroupForm" novalidate ipsUnsavedWarningForm role="form" [ipsFocus]="focusInput">
                <div class="form-row">
                    <div class="form-group col-12 col-md-10 col-lg-8 col-xl-6">
                        <label class="required" for="Name" translate>NAME</label>
                        <input type="text" class="form-control form-control-lg input-full" formControlName="Name" name="nameInput" id="Name" placeholder="{{'ENTER_LOCATION_GROUP_NAME'| translate}}" />
                        <ips-show-errors [control]="Name" [errorMessages]="getNameErrorMessages()"></ips-show-errors>
                    </div>
                    <div class="">
                        <label>&nbsp;</label>
                        <div class="input-group location-group-feature-checkbox" (click)="toggleObsolete(locationGroupForm)">
                            <div class="form-control form-control-lg input-full">{{'OBSOLETE' | translate}}</div>
                            <div class="input-group-append">
                                <span class="input-group-text"> <input formControlName="IsObsolete" name="IsObsolete" id="IsObsolete" type="checkbox" /></span>
                            </div>
                        </div>
                        <div class="input-group location-group-feature-checkbox" (click)="toggleCampaignLocationFeature(locationGroupForm)" ipsPermission="main.storeProfileLocationGroup.edit.campaign">
                            <div class="form-control form-control-lg input-full">{{'CAMPAIGN_LOCATION_FEATURE' | translate}}</div>
                            <div class="input-group-append">
                                <span class="input-group-text"> <input formControlName="IsCampaignLocationFeature" name="IsCampaignLocationFeature" id="IsCampaignLocationFeature" type="checkbox" /></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12 col-md-10 col-lg-8 col-xl-6">
                        <label for="featureImage" translate>FEATURE_IMAGE</label>
                        <ips-image-edit class="ips-edit-image-left" id="featureImage" name="featureImage" #featureImage [showDelete]="true" (fileChanged)="onSelectedImageChanged($event, 'featureImage')" [selectedImage]="SelectedImage.value" [thumbnail]="FullSizeImage.value" [ngClass]="{ 'ips-edit-image': FullSizeImage.value }"></ips-image-edit>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12 col-md-10 col-lg-8 col-xl-6">
                        <label class="required" translate>OPTION_TYPE</label>
                        <div class="no-round-grp">
                            <div class="input-group no-round" *ngFor="let optionType of OptionTypes; let i=index">
                                <div class="form-control form-control-lg input-full no-float" [ngClass]="{'option-type-disabled': editingExisting}">
                                    <div class="d-inline-block col-1 px-0 text-center mr-1"><span class="location-group-rule-icon mr-2" [ngClass]="optionType.IconClass"></span></div>{{optionType.DisplayName | translate}}
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <input name="OptionType" type="radio" value="{{optionType.TypeName}}" formControlName="OptionType" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ips-show-errors [control]="OptionType" [errorMessages]="getOptionTypeErrorMessages()" cssClass="validation-message-Qty"></ips-show-errors>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12 col-md-10 col-lg-8 col-xl-6">
                        <label class="required" translate>ASSIGNMENT_RULE</label>
                        <div class="no-round-grp">
                            <div class="input-group no-round">
                                <div class="form-control form-control-lg input-full no-float" [ngClass]="{'option-type-disabled': disableRuleSelection}">
                                    <div class="d-inline-block col-1 px-0 text-center mr-1"><span class="location-group-rule-icon location-group-rule-single mr-2"></span></div>{{ 'LOCATION_FEATURE_RULE_RADIO_SINGLE' | translate }}
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text"> <input name="Rule" type="radio" value="Single" formControlName="Rule" autocomplete="off" (click)="ruleChange()" /></span>
                                </div>
                            </div>
                            <div class="input-group no-round">
                                <div class="form-control form-control-lg input-full no-float" [ngClass]="{'option-type-disabled': disableRuleSelection}">
                                    <div class="d-inline-block col-1 px-0 text-center mr-1"><span class="location-group-rule-icon location-group-rule-multiple mr-2"></span></div>{{ 'LOCATION_FEATURE_RULE_RADIO_MULTIPLE' | translate }}
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text"> <input name="Rule" type="radio" value="Multiple" formControlName="Rule" autocomplete="off" (click)="ruleChange()" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12 col-md-10 col-lg-8 col-xl-6">
                        <label for="Notes" translate>NOTES</label>
                        <textarea class="form-control form-control-lg input-full" formControlName="Notes" name="Notes" id="Notes" cols="30" rows="3" formControlName="Notes" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                        <ips-show-errors [control]="Notes" [errorMessages]="errorMessages"></ips-show-errors>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12" *ngIf="currentOptionType">
                        <div class="form-row">
                            <div class="form-group col-10">
                                <label class="mb-0" translate>LOCATION_SUBGROUPS</label>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <div class="card border-0 ips-border-md-1">
                                    <div class="card-body p-0 p-md-4 pr-lg-2 pr-xl-0">
                                        <div class="form-row d-none d-md-flex">
                                            <div class="form-group ips-w-320-p mb-0"><label translate>OPTION_IMAGE</label></div>
                                            <div class="form-group col mb-0"><label class="required" translate>OPTION_NAME</label></div>
                                        </div>
                                        <div formArrayName="SubGroups">
                                            <div *ngFor="let item of SubGroups.controls; let i=index">
                                                <div class="form-row" [formGroupName]="i">

                                                    <div class="form-group order-2 order-md-1 ips-w-320-p">
                                                        <ng-container *ngIf="!item.value.IsNA">
                                                            <label class="d-flex d-md-none" translate>OPTION_IMAGE</label>
                                                            <ips-image-edit class="ips-edit-image-left" id="{{item.value.IdNumber}}" name="{{item.value.IdNumber}}" #{{item.value.IdNumber}} [showDelete]="true" (fileChanged)="onSelectedImageChanged($event, item.value.IdNumber)" [selectedImage]="item.value.SelectedImage" [thumbnail]="item.value.FullSizeImage" [ngClass]="{ 'ips-edit-image': item.value.FullSizeImage }"></ips-image-edit>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-12 col-md order-1 order-md-2 pr-4">
                                                        <div class="form-row mb-3">
                                                            <div class="form-group col-12 d-flex d-md-none mb-0">
                                                                <label translate>OPTION_NAME</label>
                                                            </div>
                                                            <div class="pr-0" [ngClass]="{'col-11': currentOptionType !== 'Bool' || item.value.IsNA, 'col-12': currentOptionType === 'Bool' && !item.value.IsNA}" [ngSwitch]="currentOptionType">
                                                                <app-multi-type-input class="ips-custom-input"
                                                                                      [inputType]="item.value.IsNA ? 'Text' : currentOptionType"
                                                                                      formControlName="Name"
                                                                                      [dateOptions]="dateOptions"
                                                                                      [placeholderPrefix]="'ENTER_OPTION'"
                                                                                      name="{{item.value.InputName}}"></app-multi-type-input>
                                                            </div>
                                                            <button type="button"
                                                                    class="clicker input-x col-1 text-center remove-x pr-0"
                                                                    (click)="removeSubGroup(i, tOption)"
                                                                    *ngIf="OptionType.value !== 'Bool' || item.value.IsNA"
                                                                    ngbTooltip="{{'DELETE_SUBGROUP' | translate}}"
                                                                    placement="bottom-right"
                                                                    #tOption="ngbTooltip"
                                                                    [ipsFocus]="focusableInput">
                                                                <i class="fa fa-remove fa-lg"></i>
                                                            </button>
                                                            <div class="col-10">
                                                                <ips-show-errors [control]="item.get('Name')" [errorMessages]="getNameErrorMessages()"></ips-show-errors>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row pr-md-1 pr-lg-2 pr-xl-4">
                                            <div class="col">
                                                <button type="button" class="btn btn-lg btn-block add-holder" (click)="addSubGroup()" name="addSubGroupInput" [ipsFocus]="focusableInput" [disabled]="OptionType.value === 'Bool'">
                                                    <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
                                                    <span class="add-holder-label" translate>ADD_ANOTHER_LOCATION_SUBGROUP</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="form-row pr-md-1 pr-lg-2 pr-xl-4 mt-2">
                                            <div class="col">
                                                <button type="button" class="btn btn-lg btn-block add-holder" (click)="addSubGroup(true)" name="addSubGroupInput" [ipsFocus]="focusableInput" [disabled]="HasNA">
                                                    <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
                                                    <span class="add-holder-label" translate>ADD_NOT_APPLICABLE_OPTION</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <section class="col-12 col-md-6 col-xl-3">
                        <div class="formSubmit">
                            <button id="saveLocationGroup" type="submit"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveLocationGroupPrompt(false)"
                                    [disabled]="locationGroupForm.invalid || locationGroupForm.pristine"
                                    [ngClass]="{'btn-default': locationGroupForm.invalid || locationGroupForm.pristine}" translate>
                                SAVE_LOCATION_GROUP
                            </button>
                        </div>
                    </section>
                    <section class="col-12 col-md-6 col-xl-3">
                        <div class="formSubmit">
                            <button id="saveReturnLocationGroup"
                                    class="btn btn-lg btn-block alert-success" type="button"
                                    (click)="saveLocationGroupPrompt(true)"
                                    [disabled]="locationGroupForm.invalid || locationGroupForm.pristine"
                                    [ngClass]="{'btn-default': locationGroupForm.invalid || locationGroupForm.pristine}" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </section>
                    <section class="col-12 col-md-6 col-xl-3">
                        <div class="formSubmit">
                            <button id="cancelLocationGroup" class="btn btn-lg btn-block btn-default" type="button" uiSref="main.storeProfileLocationGroup.search" translate>CANCEL</button>
                        </div>
                    </section>
                    <section class="col-12 col-md-6 col-xl-3">
                        <div class="formSubmit">
                            <button id="deleteLocationGroup" type="button"
                                    class="btn btn-lg btn-block alert-danger"
                                    (click)="deleteLocationGroupPrompt()"
                                    ips-unsaved-warning-clear
                                    [disabled]="!locationGroupForm.value.Id"
                                    [ngClass]="{'btn-default': !locationGroupForm.value.Id}" translate>
                                DELETE_LOCATION_GROUP
                            </button>
                        </div>
                    </section>
                </div>
            </form>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_LOCATION_GROUP_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
