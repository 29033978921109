import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService} from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { AreaType } from "../../shared/index";

@Injectable()
export class CustomDataService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("CustomData", "Tools", "v1");
    }

    public getCustomDataByArea(areaId: AreaType, populateOptionList?: boolean) {
        populateOptionList = populateOptionList ? true : false;
        let route = `${this.urlApiBaseProductPlusVersion}Area/${areaId}/CustomData?populateOptionList=${populateOptionList}`;
        return this.getByRoute(route);
    }

    public getCustomData() {
        let route = `${this.urlApiBaseProductPlusVersion}CustomData`;
        return this.getByRoute(route);
    }

    public isFieldNameUsed(name: string) {
        let route = `${this.urlApiBaseProductPlusVersion}CustomData/used?name=${encodeURIComponent(name)}`;
        return this.getByRoute(route);
    }
}
