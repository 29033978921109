import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { LocationFixtureModel } from "../model/LocationFixtureModel";
import { environment } from "../../../environments/environment";

@Injectable()
export class LocationFixtureService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("LocationFixture", "Profile", "v1");
    }

    public createLocFixModel(locFixId: number, fixtureId: number, locationId: number, qty: number) {
        return <LocationFixtureModel>{
            Id: locFixId,
            FixtureName: "",
            Model: "",
            FixtureId: fixtureId,
            LocationId: locationId,
            Count: qty,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            LocationIdentifier: ""
        };
    }

    public SaveList(locFixList: LocationFixtureModel[]) {
        return this.postByRoute<LocationFixtureModel[]>(`${this.urlApiBaseProductPlusVersion}LocationFixture/SaveList`, locFixList);
    }
}
