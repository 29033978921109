import { Component, OnInit } from "@angular/core";
import { DatePipe, getLocaleDateFormat, FormatWidth } from "@angular/common";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { AbstractControl, AsyncValidatorFn, ValidationErrors, AsyncValidator, ValidatorFn } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService} from "imagine-ui-ng-core";
import { PageTitleService, CountryService, RoleService, ProfileService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { HttpClient } from "@angular/common/http";
import { CampaignService, CampaignData, PromotionService, PromotionSummaryModel } from "../../index";
import { validateAllFormFieldsWithDialog } from "../../../shared/validation/utils";

interface IDatePicker {
    opened: boolean;
}

@Component({
    selector: "app-campaign-edit",
    templateUrl: "./campaign-edit.component.html",
    styleUrls: ["./campaign-edit.component.scss"],
    providers: [DatePipe]
})
export class CampaignEditComponent implements OnInit {

    public campaignNameUnique = "CAMPAIGN_NAME_UNIQUE";
    public campaignNameValid = "CAMPAIGN_NAME_VALIDATION_ERROR";
    public promptDeleteBody = "PROMPT_DELETE_BODY";
    public campaignDatePast = "CAMPAIGN_DATE_CANNOT_BE_IN_THE_PAST";
    public calendarDateFormat = "CALENDAR_DATE_FORMAT";
    public campaignStartAfterStoreDate = "CAMPAIGN_START_DATE_AFTER_IN_STORE_DATE";
    public campaignEndAterStartDate = "CAMPAIGN_END_DATE_AFTER_START_DATE";
    public campaignSaveError = "CAMPAIGN_SAVE_ERROR";
    public InStoreDateText = "IN_STORE_DATE_WITH_LABEL";
    public StartDateText = "START_DATE_WITH_LABEL";
    public EndDateText = "END_DATE_WITH_LABEL";
    public PromotionsStartAfterInstore = "CAMPAIGN_INSTORE_DATE_AFTER_PROMO_DATE";	// what to show the user
    public PromotionsStartAfterInstoreFormat = "CAMPAIGN_INSTORE_DATE_AFTER_PROMO_DATE"; // translated resource key

    public enterCampaignName = "PLEASE_ENTER_CAMPAIGN_NAME";
    public enterInStoreDate = "PLEASE_ENTER_INSTORE_DATE";
    public enterStartDate = "PLEASE_ENTER_START_DATE";
    public enterEndDate = "PLEASE_ENTER_END_DATE";
    public fulfillmentDefaultRule = "FULFILLMENT_DEFAULT_RULE";
    public enterFulfillmentDefault = "PLEASE_ENTER_FULFILLMENT_DEFAULT";
    public textMaxLength = "MAX_LENGTH_ERROR";
    public pleaseRemoveAllSpaces = "PLEASE_REMOVE_ALL_SPACES";
    public minElementQtyValue = "MIN_VALUE_ERROR";


    public loaded: boolean;
    public promise: Promise<any>;
    public breadCrumbLabel: string;

    public dateFormat: string;
    public inStoreDate: IDatePicker;
    public startDate: IDatePicker;
    public endDate: IDatePicker;
    public inStoreDateOptions: {};
    public startDateOptions: any = {};
    public endDateOptions: any = {};
    public minPromotionDate: Date;

    public disableCreatePromotion = true;
    public showInStoreDateWarning = false;


    public campaignForm: FormGroup;

    private today: Date;
    private orginalCampaignName = "";
    private calendarTheme = "theme-default";
    public hasCampaignAddresses = false;

    private errorMessages = {
        "required": () => "Field is required",
        "maxlength": (params) => IpsString.Format(this.textMaxLength, params.requiredLength),
        "campaignDatePast": () => this.campaignDatePast,
        "calendarDateFormat": () => this.calendarDateFormat,
        "campaignEndAterStartDate": () => this.campaignEndAterStartDate,
        "campaignStartAfterStoreDate": () => this.campaignStartAfterStoreDate,
        "fulfillmentDefaultRule": () => this.fulfillmentDefaultRule,
        "noSpace": () => this.pleaseRemoveAllSpaces,
        "min": (params) => IpsString.Format(this.minElementQtyValue, params.min)
    };

    public Id: FormControl;
    public Name: FormControl;
    public InStoreDate: FormControl;
    public StartDate: FormControl;
    public EndDate: FormControl;
    public FulfillmentQuantity: FormControl;
    public FulfillmentOperationType: FormControl;
    public Notes: FormControl;
    public AbbreviatedName: FormControl;
    public ElementQuantity: FormControl;
    public UseCampaignAddress: FormControl;

    public IsObsolete: boolean;



    constructor(private campaignService: CampaignService, private promotionService: PromotionService, private ipsMessage: IpsMessageService, private $state: StateService,
        private transition: Transition, private translateService: TranslateService, private pageTitleService: PageTitleService,
        private httpClient: HttpClient, private datePipe: DatePipe, private activeProfileService: ActiveProfileService) {
    }

    ngOnInit() {

        let minDate = new Date(Date.UTC(1970, 1, 1, 0, 0, 0, 0));
        this.today = new Date();
        this.today.setHours(0, 0, 0, 0);	// set to midnight

        let localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();

        this.inStoreDate = {
            opened: false
        };
        this.startDate = {
            opened: false
        };
        this.endDate = {
            opened: false
        };
        this.inStoreDateOptions = {
            minDate: this.today,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.startDateOptions = {
            minDate: this.today,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.endDateOptions = {
            minDate: this.today,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.IsObsolete = false;

        let paramId = this.transition.params().id === "0" ? "" : this.transition.params().id;
        let pageTitle = paramId.toString() === "" ? "CREATE_CAMPAIGN" : "EDIT_CAMPAIGN";

        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        this.createForm();


        // If we got an ID to load, load it.
        if (paramId > 0) {
            //Initial call to populate screen on load
            this.getCampaign(paramId);
        } else if (paramId.toString() === "") {
            this.hasAlternateAddresses();
        }
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    private translateText() {
        this.translateService.get([
            "CAMPAIGN_NAME_UNIQUE", "CAMPAIGN_NAME_VALIDATION_ERROR", "PROMPT_DELETE_BODY", "CAMPAIGN_DATE_CANNOT_BE_IN_THE_PAST", "CAMPAIGN_START_DATE_AFTER_IN_STORE_DATE", "CAMPAIGN_END_DATE_AFTER_START_DATE",
            "CAMPAIGN_SAVE_ERROR", "CAMPAIGN_INSTORE_DATE_AFTER_PROMO_DATE", "PLEASE_ENTER_CAMPAIGN_NAME", "PLEASE_ENTER_INSTORE_DATE", "PLEASE_ENTER_START_DATE", "PLEASE_ENTER_END_DATE",
            "FULFILLMENT_DEFAULT_RULE", "PLEASE_ENTER_FULFILLMENT_DEFAULT", "MAX_LENGTH_ERROR", "CALENDAR_DATE_FORMAT", "IN_STORE_DATE_WITH_LABEL", "START_DATE_WITH_LABEL", "END_DATE_WITH_LABEL",
            "PLEASE_REMOVE_ALL_SPACES", "MIN_VALUE_ERROR"
        ]).subscribe((res: [string]) => {

            this.campaignNameUnique = res["CAMPAIGN_NAME_UNIQUE"];
            this.campaignNameValid = res["CAMPAIGN_NAME_VALIDATION_ERROR"];
            this.promptDeleteBody = res["PROMPT_DELETE_BODY"];
            this.campaignDatePast = res["CAMPAIGN_DATE_CANNOT_BE_IN_THE_PAST"];
            this.campaignStartAfterStoreDate = res["CAMPAIGN_START_DATE_AFTER_IN_STORE_DATE"];
            this.campaignEndAterStartDate = res["CAMPAIGN_END_DATE_AFTER_START_DATE"];
            this.campaignSaveError = res["CAMPAIGN_SAVE_ERROR"];
            this.PromotionsStartAfterInstoreFormat = res["CAMPAIGN_INSTORE_DATE_AFTER_PROMO_DATE"];
            this.enterCampaignName = res["PLEASE_ENTER_CAMPAIGN_NAME"];
            this.enterInStoreDate = res["PLEASE_ENTER_INSTORE_DATE"];
            this.enterStartDate = res["PLEASE_ENTER_START_DATE"];
            this.enterEndDate = res["PLEASE_ENTER_END_DATE"];
            this.fulfillmentDefaultRule = res["FULFILLMENT_DEFAULT_RULE"];
            this.enterFulfillmentDefault = res["PLEASE_ENTER_FULFILLMENT_DEFAULT"];
            this.textMaxLength = res["MAX_LENGTH_ERROR"];
            this.pleaseRemoveAllSpaces = res["PLEASE_REMOVE_ALL_SPACES"];
            this.minElementQtyValue = res["MIN_VALUE_ERROR"];

            //Fix for IE bug - always define labels
            this.calendarDateFormat = IpsString.Format(res["CALENDAR_DATE_FORMAT"], this.dateFormat);
            this.InStoreDateText = IpsString.Format(res["IN_STORE_DATE_WITH_LABEL"], this.dateFormat);
            this.StartDateText = IpsString.Format(res["START_DATE_WITH_LABEL"], this.dateFormat);
            this.EndDateText = IpsString.Format(res["END_DATE_WITH_LABEL"], this.dateFormat);
            });
    }

    private createForm() {
        this.Id = new FormControl("");
        this.Name = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.InStoreDate = new FormControl("", [Validators.required, this.ValidateInStoreDate()]);
        this.StartDate = new FormControl("", [this.ValidateStartDate(this.InStoreDate)]);
        this.EndDate = new FormControl("", [this.ValidateEndDate(this.StartDate)]) ;
        this.FulfillmentQuantity = new FormControl("", [Validators.required, this.ValidateFulfillmentQuantity()]);
        this.FulfillmentOperationType = new FormControl("");
        this.Notes = new FormControl("", [Validators.maxLength(500)]);
        this.AbbreviatedName = new FormControl("", [Validators.maxLength(10), this.SpaceValidator]);
        this.ElementQuantity = new FormControl("", [Validators.required, Validators.min(1)]);
        this.UseCampaignAddress = new FormControl(false);

        this.campaignForm = new FormGroup({
            Id: this.Id,
            Name: this.Name,
            InStoreDate: this.InStoreDate,
            StartDate: this.StartDate,
            EndDate: this.EndDate,
            FulfillmentQuantity: this.FulfillmentQuantity,
            FulfillmentOperationType: this.FulfillmentOperationType,
            Notes: this.Notes,
            AbbreviatedName: this.AbbreviatedName,
            ElementQuantity: this.ElementQuantity,
            UseCampaignAddress: this.UseCampaignAddress
        }, [this.ValidateAllFields()]);

        //defaults
        this.FulfillmentOperationType.setValue("Percent");

    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "name":
                    msgs["required"] = () => this.enterCampaignName;
                    break;
                case "instoredate":
                    msgs["required"] = () => this.enterInStoreDate;
                    break;
                case "startdate":
                    msgs["required"] = () => this.enterStartDate;
                    break;
                case "enddate":
                    msgs["required"] = () => this.enterEndDate;
                    break;
                case "fulfillmentquantity":
                    msgs["required"] = () => this.enterFulfillmentDefault;
                    break;
                //default:
            }
        }
        return msgs;
    }


    /**
  * Property used for in-store date validation. It must be:
  * Parsable (handled automatically)
  * Today, or in the future
  */
    ValidateInStoreDate(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control !== undefined) {
                if (control.value < this.today) {
                    return {
                        campaignDatePast: {
                            valid: false
                        }
                    };
                }
            } else {
                return {
                    calendarDateFormat: {
                        valid: false
                    }
                };
            }
            return null;
        };
    }

    /**
    * Property used for start date validation. It must be:
    * Parsable (handled automatically)
    * On or after in-store date
    */
    public ValidateStartDate(InStoreDate: AbstractControl): ValidatorFn {
        return (StartDate: AbstractControl): ValidationErrors | null => {
            if (StartDate !== undefined) {
                if (InStoreDate !== undefined && !!InStoreDate.value && !!StartDate.value) {

                    //Compare dates only - no hours
                    InStoreDate.value.setHours(0, 0, 0, 0);
                    StartDate.value.setHours(0, 0, 0, 0);

                    if (InStoreDate.value > StartDate.value) {
                        return {
                            campaignStartAfterStoreDate: {
                                valid: false
                            }
                        };
                    }
                    if (StartDate.value < this.today) {
                        return {
                            campaignDatePast: {
                                valid: false
                            }
                        };
                    }
                }
            } else {
                return {
                    calendarDateFormat: {
                        valid: false
                    }
                };
            }

            if (!StartDate.value) {
                return {
                    required: {
                        valid: false
                    }
                };
            }

            return null;
        };
    }

    /**
    * Property used for end-store date validation. It must be:
    * Parsable (handled automatically)
    * On or after start date
    */
    public ValidateEndDate(StartDate: AbstractControl): ValidatorFn {
        return (EndDate: AbstractControl): ValidationErrors | null => {
            if (EndDate !== undefined) {
                if (StartDate !== undefined && !!StartDate.value && !!EndDate.value) {

                    //Compare dates only - no hours
                    StartDate.value.setHours(0, 0, 0, 0);
                    EndDate.value.setHours(0, 0, 0, 0);

                    if (StartDate.value > EndDate.value) {
                        return {
                            campaignEndAterStartDate: {
                                valid: false
                            }
                        };
                    }
                    if (EndDate.value < this.today) {
                        return {
                            campaignDatePast: {
                                valid: false
                            }
                        };
                    }
                }
            } else {
                return {
                    calendarDateFormat: {
                        valid: false
                    }
                };
            }

            if (!EndDate.value) {
                return {
                    required: {
                        valid: false
                    }
                };
            }

            return null;
        };
    }

    ValidateFulfillmentQuantity(): any {
        return (FulfillmentQuantity: AbstractControl): ValidationErrors | null => {

            //Check that we have a whole number
            let re = new RegExp("^[0-9]+$");
            if (re.test(FulfillmentQuantity.value)) {
                return null;
            }

            return {
                fulfillmentDefaultRule: {
                    valid: false
                }
            };
        };
    }

    ValidateAllFields(): any {
        return (form: AbstractControl): ValidationErrors | null => {

            //Check that the required date fields have been populated
            if (!form.value.StartDate || !form.value.EndDate) {
                return {
                    required: {
                        valid: false
                    }
                };
            }

            return null;
        };
    }

    private hasAlternateAddresses() {
        this.loaded = false;
        this.promise = this.campaignService.hasAlternateAddresses().then((response) => {
            this.hasCampaignAddresses = response;
            this.loaded = true;
        });
    }

    private getCampaign(id: string | number) {
        this.loaded = false;
        this.promise = this.campaignService.get<CampaignData>(id).then((response) => {

            this.campaignForm.patchValue({
                Id: response.Id,
                Name: response.Name,
                AbbreviatedName: response.AbbreviatedName,
                InStoreDate: new Date(response.InStoreDate),
                StartDate: new Date(response.StartDate),
                EndDate: new Date(response.EndDate),
                FulfillmentQuantity: response.FulfillmentQuantity,
                FulfillmentOperationType: response.FulfillmentOperationType,
                ElementQuantity: response.ElementQuantity,
                //Reverse this so it matches the form field
                UseCampaignAddress: !response.UseCampaignAddress,
                Notes: response.Notes
            });

            this.IsObsolete = response.IsObsolete;

            this.hasCampaignAddresses = response.HasCampaignAddresses;
            this.loaded = true;
            this.orginalCampaignName = response.Name;

            this.startDateOptions.minDate = new Date(response.InStoreDate);
            this.endDateOptions.minDate = new Date(response.StartDate);

            //retrieve minPromotionDate
            this.promotionService.getPromotionSummary(id.toString()).then((promoResponse: PromotionSummaryModel) => {
                // no response indicates no promotions which is a normal scenario
                if (promoResponse && promoResponse.MinStartDate) {
                    this.minPromotionDate = new Date(promoResponse.MinStartDate);
                    if (this.campaignForm.value.InStoreDate > this.minPromotionDate) {
                        this.PromotionsStartAfterInstore = IpsString.Format(this.PromotionsStartAfterInstoreFormat, this.datePipe.transform(this.campaignForm.value.InStoreDate, "mediumDate"));
                        this.showInStoreDateWarning = true;
                    }
                }
            });

            //enable create promotion
            this.disableCreatePromotion = false;

        });
    }

    private saveCampaign(): Promise<void> {
        let id = this.campaignForm.value.Id;

        //Reverse this so it matches the DB field
        this.campaignForm.value.UseCampaignAddress = !this.campaignForm.value.UseCampaignAddress;

        if (id) {
            return this.campaignService.put<CampaignData>(this.campaignForm.value).then(() => {
                this.campaignForm.markAsPristine();
            }).catch((err: Error) => {
                this.ipsMessage.error(this.campaignSaveError);
            });
        } else {
            return this.campaignService.post<CampaignData>(this.campaignForm.value, undefined, undefined).then((response) => {
                this.Id.setValue(response.Id);
                this.campaignForm.markAsPristine();
            }).catch((err: Error) => {
                this.ipsMessage.error(this.campaignSaveError);
            });
        }
    }

    private deleteCampaign() {
        return this.campaignService.delete(this.campaignForm.value.Id);
    }

    private validateCampaignName() {
        if (this.campaignForm.value.Id <= 0 || this.campaignForm.value.Name.toLowerCase() !== this.orginalCampaignName.toLowerCase()) {
            return this.campaignService.checkCampaignName(this.campaignForm.value);
        } else {
            return new Promise((resolve, reject) => { resolve(true);  });
        }
    }

    public addPromotion() {
        this.$state.go("main.campaign.promotion.edit", { campaignId: this.campaignForm.value.Id, id: 0 });
    }

    public saveCampaignPrompt(redirect: boolean) {
        validateAllFormFieldsWithDialog(this.campaignForm, this.ipsMessage, () => { this.saveCampaignPrompt2(redirect); } );
    }

    private saveCampaignPrompt2(redirect: boolean) {
        this.validateCampaignName().then((response: boolean) => {
            if (response === true) {
                return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveCampaign(), progressMessage: "SAVING" }).then((result) => {
                    if (result) {
                        if (redirect) {
                            this.$state.go("main.campaign.search");
                        } else {
                            this.$state.go("main.campaign.edit", { id: this.campaignForm.value.Id }, { reload: true });
                        }
                    }
                });

            } else {
                this.ipsMessage.error(this.campaignNameUnique);
            }
        }).catch((err: Error) => {
            this.ipsMessage.error(this.campaignNameValid);
        });
    }

    public deleteCampaignPrompt() {
        let translated = this.promptDeleteBody;
        translated = IpsString.Format(translated, this.campaignForm.value.Name);
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deleteCampaign(), progressMessage: "DELETING" })
        .then((result: any) => {
            if (result) {
                this.$state.go("main.campaign.search");
            }
        })
        .catch(() => {
            // rejection
        });
    }
    public changeInStoreDate(value: Date) {
        this.startDateOptions.minDate = value < this.today ? this.today : value;
        if (value > this.minPromotionDate) {
            this.PromotionsStartAfterInstore = IpsString.Format(this.PromotionsStartAfterInstoreFormat, this.datePipe.transform(value, "mediumDate"));
            this.showInStoreDateWarning = true;
        } else {
            this.showInStoreDateWarning = false;
        }

        //force validation call
        this.InStoreDate.setValue(value);
        if (!!this.StartDate.value) {
            this.StartDate.updateValueAndValidity();
            if (this.StartDate.errors) {
                this.StartDate.markAsDirty();
            }
        }
    }

    public changeStartDate(value: Date) {
        this.endDateOptions.minDate = value < this.today ? this.today : value;
        //force validation call
        this.StartDate.setValue(value);
        if (!!this.EndDate.value) {
            this.EndDate.updateValueAndValidity();
            if (this.EndDate.errors) {
                this.EndDate.markAsDirty();
            }
        }
    }

    // Used to overcome limitations with required and our custom date validation
    // blurStartDate() && blurEndDate()
    // This will call validate on blur just like require does
    public blurStartDate(test: any) {
        this.StartDate.updateValueAndValidity();
    }
    public blurEndDate(test: any) {
        this.EndDate.updateValueAndValidity();
    }

    public SpaceValidator(control: FormControl) {
        return (control.value || "").indexOf(" ") === -1 ? null : { "noSpace": true };
    }

    public changeUseCampaignAddress() {
        this.campaignForm.get("UseCampaignAddress").setValue(!this.campaignForm.value.UseCampaignAddress);
    }

    public toggleObsoleteCampaign() {
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveObsoleteCampaign(), progressMessage: "SAVING" }).then((result) => {
            if (result) {
                this.$state.go("main.campaign.edit", { id: this.campaignForm.value.Id }, { reload: true });
            }
        });

    }

    public saveObsoleteCampaign() {
        let id = this.campaignForm.value.Id;
        return this.campaignService.obsoleteCampaign(id, !this.IsObsolete)
        .then((res) => {
            // console.log(res)
        }).catch(() => {
            // rejection
        });
    }

}
