import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { ListSearchComponent, ResultDetailComponent, TranslatedTexts } from "imagine-ui-ng-list-search";

import { HolderVersionService, HolderVersionInfoModel } from "../../index";


@Component({
  selector: "app-holder-version-view",
  templateUrl: "./holder-version-view.component.html",
  styleUrls: ["./holder-version-view.component.scss"]
})
export class HolderVersionViewComponent implements OnInit {

    //Public Properties
    public promise: Promise<any>;
    public loaded: boolean;
    public holderversion: HolderVersionInfoModel;
    public fixtureSearchHelper: ListSearchHelper;
    public locationSearchHelper: ListSearchHelper;
    private locationLabel: string;
    private locationLabelPlural: string;
    private fixtureLabel: string;
    private fixtureLabelPlural: string;
    public translated = false;

    constructor(private trans: Transition, private translateService: TranslateService, private holderversionService: HolderVersionService,
                private listSearchHelper: ListSearchHelper) {
    }

    ngOnInit() {
        let id = this.trans.params().id;
        this.holderversion = { Id: Number(id) } as HolderVersionInfoModel;

        // If we got an ID to load, load it.
        if (Number(id) > 0) {
            //Initial call to populate screen on load
            this.loaded = false;

            this.promise = this.holderversionService.get(id).then((response) => {
                Object.assign(this.holderversion, response);
                this.loaded = true;
            });
        }

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }


    private translateText() {
        this.translateService.get(["CREATE_NEW_SPACE"]).subscribe((res: [string]) => {
            this.translated = true;
        });
    }

}
