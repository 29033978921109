import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UIRouterModule } from "@uirouter/angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CgBusyModule } from "angular-busy2";
import { TranslateModule } from "@ngx-translate/core";
import { ImagineUiNgCoreModule } from "imagine-ui-ng-core";
import { ImagineUiNgSecurityModule } from "imagine-ui-ng-security";
import { ImagineUiNgQuickStartModule } from "imagine-ui-ng-quick-start";
import { ImagineUiNgMessagingModule } from "imagine-ui-ng-messaging";
import { ListSearchModule } from "imagine-ui-ng-list-search";
import { IpsModalModule } from "imagine-ui-ng-modal";
import { SharedModule } from "../shared/shared.module";

import { SHOP_BASE_STATES } from "./imagine-ui-ng-shop.futureStates";
import { SelectPaymentComponent } from "./order-payment/select-payment/select-payment.component";
import { CartCheckoutComponent } from "./checkout/cart-checkout/cart-checkout.component";
import { CartSearchComponent } from "./my-cart/cart-search/cart-search.component";
import { ProductSearchComponent } from "./my-products/product-search/product-search.component";
import { ShopLandingComponent } from "./shop-landing/shop-landing.component";
import { ItemDisplayCardComponent } from "./item-display-card/item-display-card.component";
import { ShipMethodEditComponent } from "./ship-method/ship-method-edit/ship-method-edit.component";
import { ShipMethodListModalComponent } from "./ship-method/ship-method-list-modal/ship-method-list-modal.component";
import { CartLocationListModalComponent } from "./checkout/cart-location-list-modal/cart-location-list-modal.component";
import { CartCountryListModalComponent } from "./checkout/cart-country-list-modal/cart-country-list-modal.component";
import { CartLocationShippingModalComponent } from "./checkout/cart-location-shipping-modal/cart-location-shipping-modal.component";
import { CustomShippingLocationModalComponent } from "./checkout/custom-shipping-location-modal/custom-shipping-location-modal.component";
import { SuggestedAddressModalComponent } from "../shared/suggested-addresses-modal/suggested-addresses-modal.component";
import { XMPieAddToCartComponent } from "./xmpie-redirect/add-to-cart/add-to-cart.component";

import { ItemService } from "./service/item.service";
import { ShipMethodPreferenceService } from "./service/ship-method-preference.service";
import { ShopSettingsService } from "./service/shop-settings.service";
import { PaymentSettingsService } from "./service/payment-settings.service";
import { OrderSearchComponent } from "./my-orders/order-search/order-search.component";
import { OrderService } from "./service/order.service";
import { OrderViewComponent } from "./my-orders/order-view/order-view.component";
import { StatusModalComponent } from "./my-orders/status-modal/status-modal.component";
import { ShipmentModalComponent } from "./my-orders/shipment-modal/shipment-modal.component";
import { AddressService } from "../shared/service/address.service";
import { PunchOutService } from "./service/punch-out.service";
import { CartService } from "./service/cart.service";
import { ShipmentService } from "./service/shipment.service";
import { ProductAdminService } from "./service/product-admin.service";

import { PaypalPostbackComponent } from "./paypal-postback/paypal-postback.component";
import { AlertAssignmentService } from "./service/alert-assignment.service";

import { ProductAdminEditComponent } from "./product-admin/product-edit/product-edit.component";
import { ProductAdminSearchComponent } from "./product-admin/product-search/product-search.component";
@NgModule({
    imports: [
        CommonModule,
        UIRouterModule.forChild({ states: SHOP_BASE_STATES }),
        TypeaheadModule.forRoot(),
        CgBusyModule,
        InfiniteScrollModule,
        TranslateModule.forChild(),
        ImagineUiNgQuickStartModule,
        ImagineUiNgSecurityModule,
        ListSearchModule,
        NgbModule,
        BsDatepickerModule,
        TabsModule,
        IpsModalModule,
        ImagineUiNgMessagingModule,
        ReactiveFormsModule,
        ImagineUiNgCoreModule,
        SharedModule,
        FormsModule
    ],
    declarations: [
        SelectPaymentComponent,
        CartCheckoutComponent,
        CartSearchComponent,
        CartLocationListModalComponent,
        CartCountryListModalComponent,
        CartLocationShippingModalComponent,
        CustomShippingLocationModalComponent,
        SuggestedAddressModalComponent,
        ProductSearchComponent,
        ShopLandingComponent,
        ItemDisplayCardComponent,
        ShipMethodEditComponent,
        ShipMethodListModalComponent,
        OrderSearchComponent,
        OrderViewComponent,
        StatusModalComponent,
        XMPieAddToCartComponent,
        ShipmentModalComponent,
        PaypalPostbackComponent,
        ProductAdminEditComponent,
        ProductAdminSearchComponent
    ],
    entryComponents: [
        CartLocationListModalComponent,
        CartCountryListModalComponent,
        CartLocationShippingModalComponent,
        CustomShippingLocationModalComponent,
        SuggestedAddressModalComponent,
        ShipMethodListModalComponent,
        StatusModalComponent,
        ShipmentModalComponent
    ],
    providers: [ItemService, ShipMethodPreferenceService, OrderService, AddressService, PunchOutService, CartService, ShipmentService, ShopSettingsService,
        PaymentSettingsService, DecimalPipe, AlertAssignmentService, ProductAdminService]
})
export class ImagineUiNgShopModule { }
