var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { NgForm } from "@angular/forms";
import { TemplateFormBase } from "../../../../shared/templateFormBase";
import { StoreFrontCategoryService } from "../../../service/store-front-category.service";
var StoreFrontCategoryEditComponent = /** @class */ (function (_super) {
    __extends(StoreFrontCategoryEditComponent, _super);
    function StoreFrontCategoryEditComponent(storeFrontCategoryService, ipsMessage, $state, trans, translateService, pageTitleService) {
        var _this = _super.call(this) || this;
        _this.storeFrontCategoryService = storeFrontCategoryService;
        _this.ipsMessage = ipsMessage;
        _this.$state = $state;
        _this.trans = trans;
        _this.translateService = translateService;
        _this.pageTitleService = pageTitleService;
        return _this;
    }
    StoreFrontCategoryEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var storeFrontCategoryId = Number(this.trans.params().id);
        var pageTitle = storeFrontCategoryId > 0 ? "EDIT_STORE_FRONT_CATEGORY" : "CREATE_STORE_FRONT_CATEGORY";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);
        // Clear out current location, fill in defaults
        this.storeFrontCategory = { Id: storeFrontCategoryId };
        // If we got an ID to load, load it.
        // If paramId is not a number it will evaluate to false
        if (storeFrontCategoryId > 0) {
            //Initial call to populate screen on load
            this.getStoreFrontCategory(storeFrontCategoryId);
        }
        else {
            this.loaded = true;
        }
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        _super.prototype.setFormPristine.call(this, this.storeFrontCategoryForm);
    };
    StoreFrontCategoryEditComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(["ACCESSING_STORE_FRONT_CATEGORY_INFO", "PROMPT_DELETE_BODY"]).subscribe(function (res) {
            _this.accessStoreFrontCategoryInfo = res["ACCESSING_STORE_FRONT_CATEGORY_INFO"];
            _this.deleteBodyPrompt = res["PROMPT_DELETE_BODY"];
        });
    };
    //Private Functions
    StoreFrontCategoryEditComponent.prototype.deleteStoreFrontCategory = function () {
        var id = this.storeFrontCategory.Id;
        return this.storeFrontCategoryService.delete(id);
    };
    StoreFrontCategoryEditComponent.prototype.getStoreFrontCategory = function (id) {
        var _this = this;
        this.loaded = false;
        this.promise = this.storeFrontCategoryService.get(id).then(function (response) {
            Object.assign(_this.storeFrontCategory, response);
            _this.loaded = true;
        });
    };
    StoreFrontCategoryEditComponent.prototype.saveStoreFrontCategory = function () {
        var _this = this;
        var id = String(this.storeFrontCategory.Id);
        if (id) {
            return this.storeFrontCategoryService.put(this.storeFrontCategory).then(function (response) {
                _this.storeFrontCategoryForm.form.markAsPristine();
            });
        }
        else {
            return this.storeFrontCategoryService.post(this.storeFrontCategory).then(function (response) {
                _this.storeFrontCategoryForm.form.markAsPristine();
            });
        }
    };
    // Public Functions used by view
    StoreFrontCategoryEditComponent.prototype.deleteStoreFrontCategoryPrompt = function () {
        var _this = this;
        var translated = this.deleteBodyPrompt;
        translated = IpsString.Format(translated, this.storeFrontCategory.Name);
        // "DELETE_STORE_FRONT_CATEGORY_CONFIRMATION"
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: function () { return _this.deleteStoreFrontCategory(); }, progressMessage: "DELETING" })
            .then(function (result) {
            if (result) {
                _this.$state.go("main.storeFrontCategory.search");
            }
        })
            .catch(function () {
            // rejection
        });
    };
    StoreFrontCategoryEditComponent.prototype.saveStoreFrontCategoryPrompt = function () {
        var _this = this;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: function () { return _this.saveStoreFrontCategory(); }, progressMessage: "SAVING" }).then(function (result) {
            if (result) {
                _this.$state.go("main.storeFrontCategory.search");
            }
        });
    };
    return StoreFrontCategoryEditComponent;
}(TemplateFormBase));
export { StoreFrontCategoryEditComponent };
