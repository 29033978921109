import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-campaign-landing",
  templateUrl: "./campaign-landing.component.html",
  styleUrls: ["./campaign-landing.component.scss"]
})
export class CampaignLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
