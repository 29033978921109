import { Component, OnInit, Host, Input, Output, EventEmitter } from "@angular/core";
import { ListSearchComponent } from "imagine-ui-ng-list-search";

import { LocationGroupModel, LocationSubGroupModel, LocationGroupAssignmentModel } from "../../index";
import { TranslateService } from "@ngx-translate/core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { IpsModalService } from "imagine-ui-ng-modal";

interface NotifyChangesMade {
    hasUnsavedChanges: boolean;
}


export interface LocationSubGroupEditModel extends LocationSubGroupModel {
    originalLocationCount: number;
}

export interface LocationGroupEditModel extends LocationGroupModel {
    selectedSubGroup: number;
    originalSelectedSubGroup: number;
    unSavedChanges: boolean;
    /**
    * Override the base to allow edit tracking
    */
    SubGroups: LocationSubGroupEditModel[];
}

@Component({
    selector: "app-location-group-list",
    templateUrl: "./location-group-list.component.html",
    styleUrls: ["../store-profile-location-group/store-profile-location-group.component.scss"]
})
export class LocationGroupListComponent implements OnInit {

    // saveChanges callback function.
    @Input() public saveChanges: (changes: LocationGroupAssignmentModel[]) => Promise<boolean>;

    @Input() editing: boolean;
    @Input() reloading: boolean;
    @Input() result: any;
    @Input() search: string;
    @Input() isSingleResult: boolean;

    // Set changesMade if you want to be notified whenever a change is made, and if there are unsaved changes. One parameter
    // Will be sent, true for if there are any unsaved changes, otherwise false.
    @Output() notifyChangesMade = new EventEmitter<any>();
    @Output() notifyCollapsibleChange = new EventEmitter<any>();

    public isCollapsed: boolean;
    public optionsText = "";
    public optionsIsDisabled = true;

    constructor( @Host() public listSearch: ListSearchComponent, private translateService: TranslateService, private ipsMessage: IpsMessageService,
                private ipsModal: IpsModalService) { }

    ngOnInit() {
        this.isCollapsed = this.result.isCollapsed;

        this.checkForAssignments();
    }

    private checkForAssignments() {
        this.optionsIsDisabled = true;
        this.result.SubGroups.forEach(item => {
            if (item.LocationCount > 0 || this.result.selectedSubGroup) {
                this.optionsIsDisabled = false;
                return;
            }
        });

        if (this.optionsIsDisabled) {
            this.optionsText = this.translateService.instant("NO_OPTION_SELECTED");
        } else {

            this.optionsText = this.translateService.instant("REMOVE_SELECTED_OPTIONS");
        }

    }

    private unsavedChangesExist() {
        let list = this.listSearch.listData.resultList;
        let hasChanges = false;
        for (let i = 0; i < list.length; i++) {
            let locationGroup = list[i];
            if (locationGroup.unSavedChanges) {
                hasChanges = true;
                break;
            }
        }
        return hasChanges;
    }

    private notifyChangesMadeInternal(hasUnsavedChanges: boolean) {
        // Confirm NA ("Not Applicable") option for multiple option rule
        if (this.result.Rule === "Multiple" && this.result.SubGroups.length > 1) {
            this.confirmNaOption();
        }

        this.notifyChangesMade.emit({ hasUnsavedChanges: hasUnsavedChanges });
        //if (this.notifyChangesMade) {
        //    this.notifyChangesMade({ hasUnsavedChanges: hasUnsavedChanges });
        //}
    }

    private checkForPristineAndNotify() {
        let hasUnsavedChanges = this.unsavedChangesExist();
        if (!hasUnsavedChanges) {
            this.listSearch.listForm.form.markAsPristine();
        }

        this.notifyChangesMadeInternal(hasUnsavedChanges);
    }

    private prepRadioButtonGroupsForSave(locationGroupList: LocationGroupEditModel[]) {
        // Update radio button groups
        for (let i = 0; i < locationGroupList.length; i++) {
            let locationGroup = locationGroupList[i];
            if (locationGroup.Rule === "Single" && locationGroup.selectedSubGroup !== undefined) {
                for (let j = 0; j < locationGroup.SubGroups.length; j++) {
                    let subGroup = locationGroup.SubGroups[j];
                    if (subGroup.Id === locationGroup.selectedSubGroup) {
                        subGroup.LocationCount = 1;
                    } else {
                        subGroup.LocationCount = 0;
                    }
                }
            }
        }
    }

    private getDataToSave(locationGroupList: LocationGroupEditModel[]) {
        let data: LocationGroupEditModel[] = [];
        locationGroupList.forEach(function (locGroup) {
            if (locGroup.unSavedChanges) {
                data.push(locGroup);
            }
        });
        return data;
    }

    // Confirm a user is not combining a standard option with a "Not Applicable" option
    private confirmNaOption() {
        let naOptionSelected = this.result.SubGroups.find(o => o.Name === "Not Applicable" && o.LocationCount >= 1);
        let standardOptionSelected = this.result.SubGroups.find(o => o.Name !== "Not Applicable" && o.LocationCount >= 1);

        if (naOptionSelected !== undefined && standardOptionSelected !== undefined) {
            this.ipsMessage.confirm({
                title: "CONFIRM",
                body: "CONFIRM_NOT_APPLICABLE_SELECTION",
                ok: "CONFIRM",
                cancel: "CANCEL"
            }).then(() => {
                this.selectNaOption();
            }).catch(() => {
                this.unselectNaOption();
            });
        }
    }

    // User confirms NA option, unselect the other options
    private selectNaOption() {
        this.result.SubGroups.forEach(function (group) {
            if (group.Name !== "Not Applicable") {
                group.LocationCount = 0;
            }
        });
    }

    // User confirms not to use NA option, unselect NA option
    private unselectNaOption() {
        this.result.SubGroups.forEach(function (group) {
            if (group.Name === "Not Applicable") {
                group.LocationCount = 0;
            }
        });
    }

    public undoLocationGroupChanges(locationGroup: LocationGroupEditModel) {
        // If its a radio button group, reset the selectedSubGroup to original value
        if (locationGroup.originalSelectedSubGroup !== undefined) {
            locationGroup.selectedSubGroup = locationGroup.originalSelectedSubGroup;
        } else {
            // Check box group needs each locationCount reset.
            locationGroup.SubGroups.forEach(function (subGroup) {
                subGroup.LocationCount = subGroup.originalLocationCount;
            });
        }
        locationGroup.unSavedChanges = false;
        this.checkForPristineAndNotify();

        this.checkForAssignments();
    }

    public subGroupCheckChanged(checked: boolean, subGroup: LocationSubGroupModel, locationGroup: LocationGroupEditModel) {
        subGroup.LocationCount = checked ? 1 : 0;
        let hasChanges = false;
        for (let i = 0; i < locationGroup.SubGroups.length; i++) {
            let subGroupItem = locationGroup.SubGroups[i];
            if (subGroupItem.originalLocationCount !== subGroupItem.LocationCount) {
                hasChanges = true;
                break;
            }
        }
        locationGroup.unSavedChanges = hasChanges;
        this.checkForPristineAndNotify();

        this.checkForAssignments();
    }

    public subGroupRadioChanged(locationGroup: LocationGroupEditModel) {
        locationGroup.unSavedChanges = locationGroup.originalSelectedSubGroup !== locationGroup.selectedSubGroup;
        this.checkForPristineAndNotify();

        this.optionsIsDisabled = false;
        this.optionsText = this.translateService.instant("REMOVE_SELECTED_OPTIONS");
    }

    public undoAllChanges(locationGroupList: LocationGroupEditModel[]) {
        locationGroupList.forEach((locationGroup) => {
            this.undoLocationGroupChanges(locationGroup);
        });
        this.listSearch.listForm.form.markAsPristine();
        // Notify that changes were made, and there are no longer any unsaved changes
        this.notifyChangesMadeInternal(false);
    }

    public saveChangesInternal() {
        this.prepRadioButtonGroupsForSave(this.listSearch.listData.resultList);
        let dataToSave = this.getDataToSave(this.listSearch.listData.resultList);
        this.saveChanges(dataToSave)
            .then((response) => {
                if (response) {
                    this.listSearch.listForm.form.markAsPristine();
                }
            });
    }

    public optionsClear(locationGroup: LocationGroupEditModel) {

        this.result.selectedSubGroup = null;

        //Clear all selected options
        this.result.SubGroups.forEach(item => {
            item.LocationCount = 0;
        });

        this.optionsIsDisabled = true;
        this.optionsText = this.translateService.instant("NO_OPTION_SELECTED");

        locationGroup.unSavedChanges = true;

    }

    public activeCollapse(locationGroup: LocationGroupEditModel) {

        //Check if user needs to save before collapsing section
        if (!this.isCollapsed && locationGroup.unSavedChanges) {
            this.ipsMessage.confirm({
                title: "WARNING",
                body: "UNSAVED_CHANGES_COLLAPSE",
                ok: "SAVE_AND_CLOSE",
                cancel: "DISCARD_AND_CLOSE"
            }).then(() => {
                this.saveChangesInternal();
            }).catch(() => {
                this.undoLocationGroupChanges(locationGroup);
            });
        }
        this.isCollapsed = !this.isCollapsed;

        this.notifyCollapsibleChange.emit({ id: this.result.Id, state: this.isCollapsed });
    }

    public showLocations(locFeatureId: number, locFeatureOptionId: number) {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
            resolve: { addAll: false, search: "listbylocgroup", locationGroupId: locFeatureId, locationSubgroupId: locFeatureOptionId }
            },
            {
                windowClass: "no-list-group-item-interaction"
            });

    }
}
