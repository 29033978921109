import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgForm, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { StateService } from "@uirouter/core";
import { ActiveProfileService, HelperFunctionsService, CountryModel, SearchResponse, AddressModel } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { IpsModalService } from "imagine-ui-ng-modal";
import { environment } from "../../../../environments/environment";
import { SuggestedAddressModalComponent } from "../../../shared/suggested-addresses-modal/suggested-addresses-modal.component";
import { TemplateFormBase } from "../../../shared/templateFormBase";
import { ShipMethodPreferenceModel } from "../../model/ShipMethodModels";
import { ShipMethodPreferenceService } from "../../service/ship-method-preference.service";
import { ShipMethodListModalComponent } from "../../ship-method/ship-method-list-modal/ship-method-list-modal.component";
import { CartLocationListModalComponent } from "../cart-location-list-modal/cart-location-list-modal.component";
import { CartLocationShippingModalComponent } from "../cart-location-shipping-modal/cart-location-shipping-modal.component";
import { CustomShippingLocationModalComponent } from "../custom-shipping-location-modal/custom-shipping-location-modal.component";
import {
    PaymentSettingsService, AddressService, AddressValidationModel, CartDataModel, CartOptions, CartService,
    KeyValuePair, PlaceCartOrderPostData, ShippingAddress, AllowPayment
} from "../../../imagine-ui-ng-shop";
import { LocationService, LocationModel } from "../../../imagine-ui-ng-store-profile/index";
import { CountryService } from "imagine-ui-ng-quick-start";
import { CartCountryListModalComponent } from "../cart-country-list-modal/cart-country-list-modal.component";
import uniq from "lodash-es/uniq";
import { AddressBookEntry } from "../../../shared/model/AddressBookEntryModel";
import { ShopSettingsService } from "../../service/shop-settings.service";
import { ShopSettingModel, PONumberSettingsModel } from "../../model/ShopSettingModel";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";

interface CartEstimate {
    OrderSubTotal: number;
    ShipmethodEstimates: CostEstimatePerShipMethod[];
    TotalOrderFragmentCount: number;
    Errors: string[];
}

interface CostEstimatePerShipMethod {
    ShipMethodId: number;
    ShipMethodDisplayName: string;
    OrderFragmentShipEstimateCount: number; // Number of order fragments that had at least one shipping estimate
    EstimatedShipping: number;
    TotalBeforeTax: number;
    EstimatedTax: number;
    EstimatedHandling: number;
    OrderTotal: number;
}

interface IDatePicker {
    opened: boolean;
}

@Component({
    selector: "app-cart-checkout",
    templateUrl: "./cart-checkout.component.html",
    styleUrls: ["./cart-checkout.component.scss"]
})
export class CartCheckoutComponent extends TemplateFormBase implements OnInit {
    // ----- DATA -----
    @ViewChild("checkoutForm") public checkoutForm: NgForm;
    @ViewChild("paypalForm") public paypalForm: ElementRef;

    // Query parameters
    public cartId: number;

    // Local data
    public payflowUrl: string;
    public screenLoaded = false;
    public CurrentCart: CartDataModel;
    public CustomAddress: ShippingAddress;
    public orderQuantity: number;
    public numberOfLocations: number;
    public orderSubTotal: string;
    public estimatedShipping: string;
    public totalBeforeTax: string;
    public estimatedTax: string;
    public estimatedHandling: string;
    public orderTotal: string;
    public NeedByDate: FormControl;
    public needByDate: IDatePicker;
    public poNumber = "";
    public comments = "";
    public referenceNumber: string;
    public costEstimated: boolean;
    public shippingEstimated: boolean;
    private locale = "en-US";
    private cartLocations: number[];
    private placedOrderId?: number;
    public failedAddressValidation = "FAILED_ADDRESS_VALIDATION";
    public token: string;
    public tokenId: string;
    public paymentMethod: AllowPayment;

    public availableShipMethods: KeyValuePair<number, string>[];
    public selectedShipMethod: KeyValuePair<number, string>;

    public fullLocationDetails: LocationModel[];
    public locationCountries: CountryModel[];
    public selectedCountryCode: string;
    private countryList: CountryModel[] = [];

    public addressOption: AddressBookEntry | string = "location";
    public addressOptions: any[] = [];

    public addressBookEntries: AddressBookEntry[] = [];
    private cartEstimate: CartEstimate;
    private savedEstimates: object = {};
    public estimating = false;
    public noValidShipMethods = false;

    public needByDateOptions: {};
    private tomorrow: Date;
    private calendarTheme = "theme-default";
    public dateFormat: string;

    public showOrderReason = false;
    public orderReasons: string[] = [];
    public selectedOrderReason = "";

    public showReferenceNumber = true;
    public showNeedByDate = false;
    public showComments = false;

    public poNumberSettings: PONumberSettingsModel;
    public poNumbers: string[] = [];

    public showDeliverySelection = false;

    public translatedTexts: any;
    private textToTranslate = [
        "ADDITIONAL_POP",
        "PROFILE_CHANGE",
        "STORE_DISCARD",
        "WEATHER_DAMAGE_BY_SHIPPER",
        "REPLACEMENT_KIT_NOT_DELIVERED",
        "BOARD_TOUR_EXEC_VISIT",
        "REFRESH_REPLACEMENT_INV",
        "REMODEL_STORE",
        "ELEMENT_MISSING",
        "WRONG_SIZE_ELEMENT",
        "MONTHLY_KIT_NOT_RECEIVED",
        "FAULTY_PACKAGING",
        "KIT_NOT_RECEIVED",
        "NEW_STORE_ORDER",
        "GRAND_OPENING",
        "CHANGEOVER_STORE",
        "CHANGES",
        "CHANGE_IN_CONTROL_KIT",
        "ACQUISITION_KIT",
        "NEW_STORE_ACQUISITION",
        "INCORRECT_MESSAGE",
        "CUSTOMER_ITEM_CODE",
        "PRODUCT_DESCRIPTION",
        "PROMPT_CLEAR_ITEM_FROM_CART",
        "SIZE_WIDTH_HEIGHT",
        "SIZE_WIDTH_HEIGHT_DEPTH",
        "AVAILABLE_QUANTITY",
        "LAST_AVAILABLE_DATE",
        "PRICE",
        "PROMOTION",
        "FIXTURE_GROUP_HOLDER",
        "SPACE",
        "PRODUCT_TYPE",
        "AVAILABLE_NOW",
        "PLACEMENT",
        "NOT_AVAILABLE",
        "ITEM_PREVIOUSLY_ORDERED",
        "FAILED_ADDRESS_VALIDATION"
    ];

    constructor(
        private addressService: AddressService,
        private stateService: StateService,
        private cartService: CartService,
        private activeProfileService: ActiveProfileService,
        private ipsModal: IpsModalService,
        private translateService: TranslateService,
        private helperFunctionsService: HelperFunctionsService,
        private shipMethodService: ShipMethodPreferenceService,
        private ipsMessage: IpsMessageService,
        private paymentSettingsService: PaymentSettingsService,
        private locationService: LocationService,
        private countryService: CountryService,
        private shopSettingsService: ShopSettingsService
    ) {
        super();
        this.payflowUrl = environment.payflowUrl;
        this.cartId = 0;
        this.costEstimated = false;
        this.shippingEstimated = false;
        this.referenceNumber = null;
        this.NeedByDate = null;

        this.CustomAddress = {
            Attention: null,
            Label: null,
            Id: 0,
            OrderId: 0,
            NeedByDate: null,
            ReleaseDate: null,
            ShipMethodId: 0,
            FirstName: null,
            LastName: null,
            Company: null,
            Title: null,
            Line1: null,
            Line2: null,
            Line3: null,
            City: null,
            StateProvince: null,
            Country: null,
            PostalCode: null,
            Phone: null,
            Email: null,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            ValidationStatus: "Unknown",
            AddressBookAlias: "",
            AddressBookId: 0,
            SaveToAddressBook: false,
            RemovedFromAddressBook: false
        };
    }

    // ----- FUNCTIONS -----
    //
    private translateText() {
        this.translateService.get(this.textToTranslate).subscribe((res: [string]) => {
            this.translatedTexts = res;
            this.failedAddressValidation = this.translatedTexts["FAILED_ADDRESS_VALIDATION"];
        });
    }

    public changeNeedByDate(value: Date) {
        this.NeedByDate.setValue(value);
    }

    // Sets up the post data for an order based on the current cart and selected locations.
    public createCartOrderPostData(isCostEstimation: boolean): PlaceCartOrderPostData {

        let toReturn: PlaceCartOrderPostData = {
            CartId: this.CurrentCart.Id,
            OrderedBy: this.CurrentCart.OwnerDetails,
            LocationIdsToOrder: this.cartLocations,
            ShipMethodId: this.selectedShipMethod ? this.selectedShipMethod.Key : ((this.showDeliverySelection || !isCostEstimation) ? null : -1),
            NeedByDate: this.NeedByDate.value || null,
            PONumber: this.poNumber || null,
            Comments: this.comments || null,
            ReferenceNumber: this.referenceNumber || null,
            OrderReason: this.selectedOrderReason || null,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            EstimatedSubtotal: Number(this.orderSubTotal),
            EstimatedShippingCost: Number(this.estimatedShipping),
            EstimatedTax: Number(this.estimatedTax),
            EstimatedHandling: Number(this.estimatedHandling),
            PaymentType: this.paymentIsCreditCard() ? "Credit" : "Invoice"
        };

        switch (this.addressOption) {
            case "custom":
                toReturn.ShipToAddress = this.CustomAddress;
                toReturn.ShipToAddress.Company = this.CustomAddress.Attention;
                toReturn.ShipToAddress.Country = this.addressService.getTwoCharCountryCode(toReturn.ShipToAddress.Country, this.countryList);
                break;
            case "location":
                // do nothing
                break;
            default:
                const targetAddress = this.addressService.convertToShippingAddress(<AddressBookEntry>this.addressOption);
                targetAddress.Country = this.addressService.getTwoCharCountryCode(targetAddress.Country, this.countryList);
                toReturn.ShipToAddress = targetAddress;
                break;
        }

        return toReturn;
    }

    public getLocationsByCountry(countryCode: string): number[] {
        const filteredLocationIds = this.fullLocationDetails.filter((item) => item.Addresses[0].Country === countryCode).map((item) => item.Id);
        return filteredLocationIds;
    }

    public get formValid(): boolean {

        let poNumberValid = (this.poNumberSettings.EnableList && !!this.poNumber) || !this.poNumberSettings.EnableList;

        if (this.showOrderReason) {
            return !!this.selectedOrderReason && this.shippingEstimated && poNumberValid;
        } else {
            return this.shippingEstimated && poNumberValid;
        }
    }

    private createForm() {
        this.NeedByDate = new FormControl("", []);
    }

    // Makes a call to estimate the cost of creating an order based on the current cart
    // and selected locations.
    public estimateCost(addressChanged = true): Promise<any> {
        const returnPromise = new Promise<any>((resolve, reject) => {
            this.orderSubTotal =
                this.helperFunctionsService.formatAsPrice(this.CurrentCart.TotalCost);
            this.estimatedShipping = null;
            this.totalBeforeTax = null;
            this.estimatedTax = null;
            this.estimatedHandling = null;
            this.orderTotal = null;
            this.costEstimated = true;
            this.shippingEstimated = false;
            let estPromise: Promise<void>;
            if (addressChanged || !this.cartEstimate) {
                this.estimating = true;
                estPromise = this.cartService.estimateCartCost(this.createCartOrderPostData(true))
                    .then((response: CartEstimate) => {
                        this.estimating = false;
                        this.cartEstimate = response;

                        // Set new shipmethods
                        this.setAvailableShipMethods();

                        // Save off estimate for efficiency. If their are no valid ship methods, clear the saved value instead so we can try again just in case
                        // there is a network glitch causing the problem
                        if ((<AddressBookEntry>this.addressOption).AddressId) {
                            this.savedEstimates[(<AddressBookEntry>this.addressOption).AddressId] = this.noValidShipMethods ? null : this.cartEstimate;
                        } else {
                            this.savedEstimates[<string>this.addressOption] = this.noValidShipMethods ? null : this.cartEstimate;
                        }

                        resolve();
                    },
                        (response) => {
                            this.estimating = false;
                            this.ipsMessage.error(response);
                            reject(response);
                        });
            }
            else {
                estPromise = Promise.resolve();
            }

            if (this.selectedShipMethod) {
                estPromise.then(() => {
                    if (this.availableShipMethods.some(s => s.Key === this.selectedShipMethod.Key)) {
                        // Shipmethod is valid, update costs
                        let estimate = this.cartEstimate.ShipmethodEstimates.find(s => s.ShipMethodId === this.selectedShipMethod.Key);
                        this.orderSubTotal =
                            this.helperFunctionsService.formatAsPrice(this.cartEstimate.OrderSubTotal);
                        this.estimatedShipping =
                            this.helperFunctionsService.formatAsPrice(estimate.EstimatedShipping);
                        this.totalBeforeTax =
                            this.helperFunctionsService.formatAsPrice(estimate.TotalBeforeTax);
                        this.estimatedTax =
                            this.helperFunctionsService.formatAsPrice(estimate.EstimatedTax);
                        this.estimatedHandling =
                            this.helperFunctionsService.formatAsPrice(estimate.EstimatedHandling);
                        this.orderTotal =
                            this.helperFunctionsService.formatAsPrice(estimate.OrderTotal);
                        this.costEstimated = true;
                        this.shippingEstimated = true;
                    } else {
                        // Selected shipmethod no longer valid
                        this.selectedShipMethod = null;
                    }
                    resolve();
                });
            } else if (!this.showDeliverySelection) {
                estPromise.then(() => {
                    if (this.availableShipMethods.some(s => s.Key === -1)) {
                        // Shipmethod is valid, update costs
                        let estimate = this.cartEstimate.ShipmethodEstimates.find(s => s.ShipMethodId === -1);
                        this.orderSubTotal =
                            this.helperFunctionsService.formatAsPrice(this.cartEstimate.OrderSubTotal);
                        this.estimatedShipping =
                            this.helperFunctionsService.formatAsPrice(estimate.EstimatedShipping);
                        this.totalBeforeTax =
                            this.helperFunctionsService.formatAsPrice(estimate.TotalBeforeTax);
                        this.estimatedTax =
                            this.helperFunctionsService.formatAsPrice(estimate.EstimatedTax);
                        this.estimatedHandling =
                            this.helperFunctionsService.formatAsPrice(estimate.EstimatedHandling);
                        this.orderTotal =
                            this.helperFunctionsService.formatAsPrice(estimate.OrderTotal);
                        this.costEstimated = true;
                        this.shippingEstimated = true;
                    } else {
                        // Selected shipmethod no longer valid
                        this.selectedShipMethod = null;
                    }
                    resolve();
                });
            }
        });

        return returnPromise;
    }

    private setAvailableShipMethods() {
        if (this.cartEstimate) {
            this.availableShipMethods = this.cartEstimate.ShipmethodEstimates
                .filter((item) => item.OrderFragmentShipEstimateCount === this.cartEstimate.TotalOrderFragmentCount)
                .map((item) => {
                    return <KeyValuePair<number, string>>{
                        Key: item.ShipMethodId,
                        Value: item.ShipMethodDisplayName
                    };
                });
            this.noValidShipMethods = this.availableShipMethods.length === 0;
        }
    }

    public placeOrderPrompt() {
        const targetState = "main.myorders.view";

        return this.ipsMessage.waitForWork({
            body: "PLACING_ORDER",
            workFunction: () => this.placeOrder(),
            progressMessage: "PLACING_ORDER"
        }).then((result: boolean) => {
            if (result) {
                const paramObject = {
                    id: this.placedOrderId,
                    showBanner: true
                };
                this.stateService.go(targetState, paramObject);
            }
        });
    }

    // Places the order.
    public placeOrder(): Promise<any> {
        return this.cartService.placeCartOrder(this.createCartOrderPostData(false))
            .then((orderId) => {
                this.placedOrderId = Number(orderId);
                this.checkoutForm.form.markAsPristine();
            });
    }

    // Prompts the user to select from a list of suggested addresses.
    public selectFromSuggestedAddresses(
        oldAddress: ShippingAddress,
        validation: AddressValidationModel,
        label: string
    ): void {
        this.ipsModal.displayTemplateScrollable(
            SuggestedAddressModalComponent,
            {
                suggestedAddresses: validation.Addresses,
                invalidAddress: oldAddress
            }
        ).then((response: any) => {
            if (this.addressOption === "custom") {
                if (oldAddress.SaveToAddressBook) {
                    const model = this.addressService.convertToAddressBookEntry(response);
                    model.Address.ValidationStatus = "Validated";
                    model.Address.Country = this.addressService.getTwoCharCountryCode(model.Address.Country, this.countryList);
                    this.addressService.saveToAddressBook(model).then((entries: AddressBookEntry[]) => {
                        this.updateAddressBookEntries(entries, model.AddressId, model.Alias);
                        this.estimateCost()
                            .then(() => {
                                this.checkoutForm.form.markAsDirty();
                            });
                    });
                } else {
                    this.CustomAddress = response;
                    this.CustomAddress.Country = this.addressService.getTwoCharCountryCode(this.CustomAddress.Country, this.countryList);
                    this.CustomAddress.Label = label;
                    this.estimateCost()
                        .then(() => {
                            this.checkoutForm.form.markAsDirty();
                        });
                }
            } else {
                const currentOption = <AddressBookEntry>this.addressOption;
                Object.assign(currentOption.Address, response);
                currentOption.Address.Country = this.addressService.getTwoCharCountryCode(currentOption.Address.Country, this.countryList);
                currentOption.Recipient = label;
                currentOption.Alias = oldAddress.AddressBookAlias;

                this.addressService.saveToAddressBook(currentOption).then((res) => {
                    this.updateAddressBookEntries(res, currentOption.AddressId, currentOption.Alias);
                    this.estimateCost()
                        .then(() => {
                            this.checkoutForm.form.markAsDirty();
                        });
                });
            }
        }).catch((canceled) => {
            // If the currentAddress isn't valid, switch back to Location Addresses.
            if (this.addressOption === "custom") {
                if (this.CustomAddress.Label === null) {
                    this.addressOption = "location";
                    this.estimateCost();
                }
            }
        });
    }

    private buildAddressOptionList(): void {
        this.addressOptions = [];
        this.addressOptions.push({ label: "Location Addresses", value: "location" });

        const savedAddresses = this.addressBookEntries.map((item) => {
            return { label: item.Alias, value: item };
        });

        this.addressOptions.push(...savedAddresses);
        this.addressOptions.push({ label: "Custom Address", value: "custom" });
    }

    public addressOptionChanged() {
        let loadSavedEstimate = true;
        if (this.addressOption === "custom") {
            if (!this.isCustomAddressPopulated()) {
                loadSavedEstimate = false;
                this.enterNewAddress();
            }
        }

        if (loadSavedEstimate) {
            if ((<AddressBookEntry>this.addressOption).AddressId) {
                this.cartEstimate = this.savedEstimates[(<AddressBookEntry>this.addressOption).AddressId];
            }
            else {
                this.cartEstimate = this.savedEstimates[<string>this.addressOption];
            }
            this.setAvailableShipMethods();
            this.estimateCost(false);
        }

    }

    public isCustomAddressPopulated(): boolean {
        return !!this.CustomAddress.Label;
    }

    public isAddressBookEntry(address: any): boolean {
        return !!address.Address &&
            !!address.Id &&
            !!address.Alias;
    }

    private entryRemovedFromAddressBook(address: ShippingAddress) {
        Object.assign(this.CustomAddress, address);

        const removalIndex = this.addressOptions.findIndex((item) => item.value.Id === address.AddressBookId);
        this.addressOptions.splice(removalIndex, 1);

        this.CustomAddress.AddressBookAlias = "";
        this.CustomAddress.AddressBookId = 0;
        this.CustomAddress.RemovedFromAddressBook = false;
        this.CustomAddress.SaveToAddressBook = false;

        this.addressOption = "custom";
    }

    public editAddressBookEntry(entry: AddressBookEntry) {
        const editModel = this.addressService.convertToShippingAddress(entry);

        this.ipsModal.displayTemplateScrollable(
            CustomShippingLocationModalComponent,
            { currentAddress: editModel }
        ).then((response: ShippingAddress) => {
            const originalModel = this.addressService.convertToShippingAddress(entry);
            const oldAddress = {
                Line1: originalModel.Line1,
                Line2: originalModel.Line2,
                City: originalModel.City,
                StateProvince: originalModel.StateProvince,
                PostalCode: originalModel.PostalCode,
                Country: originalModel.Country
            };

            const newAddress = {
                Line1: response.Line1,
                Line2: response.Line2,
                City: response.City,
                StateProvince: response.StateProvince,
                PostalCode: response.PostalCode,
                Country: response.Country
            };

            const areDifferent = JSON.stringify(oldAddress) !== JSON.stringify(newAddress);

            if (areDifferent) {
                this.addressService.validateAddress(response)
                    .then((validation: AddressValidationModel) => {
                        if (validation.Valid) {
                            const model = this.addressService.convertToAddressBookEntry(response);

                            if (response.RemovedFromAddressBook) {
                                this.entryRemovedFromAddressBook(response);
                            } else {
                                this.addressService.saveToAddressBook(model).then((addressBookResponse) => {
                                    this.updateAddressBookEntries(addressBookResponse, model.AddressId, model.Alias);
                                    this.estimateCost()
                                        .then(() => {
                                            this.checkoutForm.form.markAsDirty();
                                        });
                                });
                            }
                        } else {
                            this.selectFromSuggestedAddresses(response, validation, response.Label);
                        }
                    },
                        (reason) => { // Rejected
                            this.ipsMessage.confirm({
                                body: "FAILED_ADDRESS_VALIDATION_PROMPT",
                                buttons: "OK"
                            }).then((result: any) => {
                                if (result) {

                                    // TODO:  is this right?
                                    Object.assign(entry, response);

                                    this.CustomAddress = response;
                                    this.estimateCost()
                                        .then(() => {
                                            this.checkoutForm.form.markAsDirty();
                                        });
                                } else {
                                    // If the currentAddress isn't valid, switch back to Location Addresses.
                                    if (this.addressOption === "custom") {
                                        if (this.CustomAddress.Label === null) {
                                            this.addressOption = "location";
                                            this.estimateCost();
                                        }
                                    }
                                }
                            }).catch((canceled) => {
                                // If the currentAddress isn't valid, switch back to Location Addresses.
                                if (this.addressOption === "custom") {
                                    if (this.CustomAddress.Label === null) {
                                        this.addressOption = "location";
                                        this.estimateCost();
                                    }
                                }
                            });
                        });
            } else {
                const model = this.addressService.convertToAddressBookEntry(response);

                if (response.RemovedFromAddressBook) {
                    this.entryRemovedFromAddressBook(response);
                } else {
                    this.addressService.saveToAddressBook(model).then((addressBookResponse) => {
                        this.updateAddressBookEntries(addressBookResponse, model.AddressId, model.Alias);
                        this.estimateCost()
                            .then(() => {
                                this.checkoutForm.form.markAsDirty();
                            });
                    });
                }
            }
        }).catch(() => { // Rejected
            // If the currentAddress isn't valid, switch back to Location Addresses.
            if (this.addressOption === "custom") {
                if (this.CustomAddress.Label === null) {
                    this.addressOption = "location";
                    this.estimateCost();
                }
            }
        });
    }

    private updateAddressBookEntries(entries: AddressBookEntry[], selectedAddressId?: number, selectedAlias?: string) {
        this.addressBookEntries = entries;
        this.buildAddressOptionList();

        if (selectedAddressId) {
            this.addressOption = this.addressOptions.find((item) => item.value.AddressId === selectedAddressId).value;
        } else if (selectedAlias) {
            this.addressOption = this.addressOptions.find((item) => item.value.Alias === selectedAlias).value;
        } else {
            this.addressOption = "custom";
        }
    }

    //
    public enterNewAddress(): Promise<any> {
        this.addressOption = "custom";
        let modelResult = this.ipsModal.displayTemplateScrollable(
            CustomShippingLocationModalComponent,
            { currentAddress: this.helperFunctionsService.copyObject(this.CustomAddress) }
        );

        modelResult.then((response: ShippingAddress) => {
            this.addressService.validateAddress(response)
                .then((validation: AddressValidationModel) => {
                    if (validation.Valid) {
                        if ((<any>response).SaveToAddressBook) {
                            const model = this.addressService.convertToAddressBookEntry(response);
                            model.Address.ValidationStatus = "Validated";
                            this.addressService.saveToAddressBook(model).then((entries: AddressBookEntry[]) => {
                                this.updateAddressBookEntries(entries, model.AddressId, model.Alias);
                            });
                        } else {
                            this.CustomAddress = response;
                            this.addressOption = "custom";
                        }

                        this.estimateCost()
                            .then(() => {
                                this.checkoutForm.form.markAsDirty();
                            });
                    } else {
                        this.selectFromSuggestedAddresses(response, validation, response.Label);
                    }
                },
                    (reason) => { // Rejected
                        this.ipsMessage.confirm({
                            body: "FAILED_ADDRESS_VALIDATION_PROMPT",
                            buttons: "OK"
                        }).then((result: any) => {
                            if (result) {
                                this.CustomAddress = response;
                                this.estimateCost()
                                    .then(() => {
                                        this.checkoutForm.form.markAsDirty();
                                    });
                            } else {
                                // If the currentAddress isn't valid, switch back to Location Addresses.
                                if (this.CustomAddress.Label === null) {
                                    this.addressOption = "location";
                                    this.estimateCost();
                                }
                            }
                        }).catch((canceled) => {
                            // If the currentAddress isn't valid, switch back to Location Addresses.
                            if (this.CustomAddress.Label === null) {
                                this.addressOption = "location";
                                this.estimateCost();
                            }
                        });
                    });
        },
            () => { // Rejected
                // If the currentAddress isn't valid, switch back to Location Addresses.
                if (this.CustomAddress.Label === null) {
                    this.addressOption = "location";
                    this.estimateCost();
                }
            });

        return modelResult;
    }

    // Creates a dialog where the user selects which locations to check out.
    public chooseLocation(displayAddresses?: boolean) {
        displayAddresses = displayAddresses || false;
        if (displayAddresses) {
            this.ipsModal.displayTemplateScrollable(
                CartLocationShippingModalComponent,
                {
                    cartLocations: this.CurrentCart.Locations || [],
                    currentSelections: this.cartLocations || []
                }
            );
        } else {

            //Get the list of valid payment methods by location
            this.paymentSettingsService
                .getAvailablePaymentMethods(this.CurrentCart.Locations
                    ? this.CurrentCart.Locations.map(i => i.Key)
                    : [0])
                .then(result => {
                    //Make sure at-least one of the locations have payment methods defined
                    if (!result.find(q => q.CreditCard || q.Invoice)) {
                        let response = "PROMPT_NO_PAYMENT_METHODS";
                        if (result.find(q => q.ContainsOtherLocations)) {
                            response = "LOCATION_NO_PAYMENT_METHODS";
                        }

                        this.ipsMessage.error(response).finally(() => {
                            this.stateService.transitionTo("main.mycart");
                        });
                    } else {
                        let cartLocations = this.CurrentCart.Locations
                            ? this.CurrentCart.Locations.map(i => {
                                let paymentMethod = result.find(q => q.LocationId === i.Key) ||
                                    { LocationId: i.Key, Invoice: false, CreditCard: false };
                                return {
                                    LocationId: i.Key,
                                    Label: i.Value,
                                    Invoice: paymentMethod.Invoice,
                                    CreditCard: paymentMethod.CreditCard,
                                    ValidationStatus: i.ValidationStatus
                                };
                            })
                            : [];

                        if (this.selectedCountryCode) {
                            let targetLocationIds = this.getLocationsByCountry(this.selectedCountryCode);
                            cartLocations = cartLocations.filter((item) => targetLocationIds.indexOf(item.LocationId) > -1);
                        }

                        this.ipsModal.displayTemplateScrollable(
                            CartLocationListModalComponent,
                            {
                                cartLocations: cartLocations.filter(loc =>
                                    (this.paymentMethod !== "Credit" && loc.Invoice) || (this.paymentMethod !== "Invoice" && loc.CreditCard)),
                                currentSelections: [],
                                displayAddresses: displayAddresses,
                                allowPayment: this.paymentMethod

                            }
                        ).then((res: any) => {
                            this.cartLocations = res.Locations;


                            this.paymentMethod = res.PaymentMethod;

                            this.numberOfLocations = this.cartLocations.length;

                            this.CurrentCart = this.cartService.myCurrentCartData(this.cartLocations);
                            this.orderQuantity = this.CurrentCart.TotalQuantity;

                            if (res.Locations.indexOf(-1) > -1) {
                                let ind = this.addressOptions.findIndex((q) => q.value === "location");
                                if (ind > -1) {

                                    this.addressOptions.splice(ind, 1);
                                    this.addressOption = this.addressOptions[0];

                                    this.getCustomAddressModel();

                                    return;
                                }
                            }
                            else {
                                this.estimateCost();
                            }

                            this.screenLoaded = true;

                        },
                            () => { // Rejected
                                // If the user cancels on their first prompt, take them back to the cart page.
                                if (this.screenLoaded === false) {
                                    this.stateService.transitionTo("main.mycart");
                                }
                            });
                    }
                });
        }
    }

    private getCustomAddressModel() {
        this.enterNewAddress().then((result) => {
            this.screenLoaded = true;
            //this.estimateCost();
        }).catch((e) => {
            this.ipsMessage.confirm({
                title: "WARNING",
                body: "YOU_MUST_PROVIDE_CUSTOM_ADDRESS",
                ok: "ADD_CUSTOM_ADDRESS",
                cancel: "RETURN_TO_CART"
            }).then(() => {
                this.getCustomAddressModel();
            }).catch(() => {
                this.stateService.transitionTo("main.mycart");
            });


        });
    }

    // Creates a dialog for selecting the country for checkout.
    public selectCountry(): void {
        this.ipsModal.displayTemplateScrollable(
            CartCountryListModalComponent,
            {
                AvailableCountries: this.locationCountries
            }
        ).then((response: string) => {
            this.selectedCountryCode = response;
            this.chooseLocation();
        },
            () => { // Rejected
                this.stateService.transitionTo("main.mycart");
            });
    }

    // Creates a dialog for selecting the method for shipping the order.
    public selectShippingMethod(): void {
        this.ipsModal.displayTemplateScrollable(
            ShipMethodListModalComponent,
            {
                items: this.availableShipMethods
            }
        ).then((response: KeyValuePair<number, string>) => {
            this.selectedShipMethod = response;
            this.estimateCost(false);
        },
            () => { // Rejected
                // do nothing
            });
    }

    public getSecureToken(): Promise<any> {
        if (!this.token || !this.tokenId) {
            const rtnUrl = `${environment.redirectUrl}shop/paypalpostback/${this.CurrentCart.Id}`;
            const authAmount = parseFloat(this.orderTotal);
            const cancelUrl = `${environment.redirectUrl}shop/cart-checkout/${this.CurrentCart.Id}`;

            const request = { Amount: authAmount, ReturnUrl: rtnUrl, ErrorUrl: rtnUrl, CancelUrl: cancelUrl };
            return this.cartService.GetSecureToken(request).then((response: any) => {
                this.token = response.SecureToken;
                this.tokenId = response.SecureTokenId;
            });
        }
    }

    public submit() {
        //save cartOptions for placing order later
        const options: CartOptions = {
            CustomAddress: {
                ShipMethodId: this.selectedShipMethod ? this.selectedShipMethod.Key : null,
                FirstName: this.CurrentCart.OwnerDetails.FirstName,
                LastName: this.CurrentCart.OwnerDetails.LastName,
                Email: this.CurrentCart.OwnerDetails.Email,
                Phone: this.CurrentCart.OwnerDetails.Phone,
                ValidationStatus: "Unknown"
            },
            PONumber: this.poNumber || null,
            ReferenceNumber: this.referenceNumber || null,
            OrderReason: this.selectedOrderReason || null,
            SelectedLocationIds: this.cartLocations,
            ShipToAddress: this.CustomAddress.Line1 === null ? null : this.CustomAddress,
            EstimatedSubtotal: Number(this.orderSubTotal),
            EstimatedShippingCost: Number(this.estimatedShipping),
            EstimatedTax: Number(this.estimatedTax),
            EstimatedHandling: Number(this.estimatedHandling)
        };
        this.cartService.updateCartOptions(options).then((response) => {
            this.getSecureToken().then(() => {
                //mark form pristine
                this.checkoutForm.form.markAsPristine();

                // call setTimeout so that form can be updated before submission
                setTimeout(() => {
                    this.paypalForm.nativeElement.submit();
                }, 1);
            });
        }).catch((errorResponse) => {
            this.ipsMessage.error(errorResponse.message || errorResponse.error.Message);
        });
    }

    get showPayByCreditCard(): boolean {
        return this.paymentIsCreditCard() && Number(this.orderTotal) > 0;
    }

    public disabledFilter(type: string): boolean {
        if (type === "Invoice") {
            return this.paymentMethod !== "Invoice" && this.paymentMethod !== "Both";
        } else if (type === "Credit") {
            return this.paymentMethod !== "Credit" && this.paymentMethod !== "Both";
        }
        return false;
    }

    private paymentIsCreditCard(): boolean {
        return this.paymentMethod === "Credit";
    }

    private getShopSettings(): Promise<any> {
        return this.shopSettingsService.getSettingByName("ShowOrderReason").then((response: ShopSettingModel) => {
            if (response) {
                this.showOrderReason = (response.Value === "order");
            }
        });
    }

    private getPONumberSettings(): Promise<any> {
        return this.shopSettingsService.getPONumberSetting().then((response: PONumberSettingsModel) => {
            if (response) {
                this.poNumberSettings = response;
                this.poNumbers = this.poNumberSettings.Options.split(/\r\n|\r|\n/g);
            }
        });
    }

    private getShowDeliverySelectionSetting(): Promise<any> {
        return this.shopSettingsService.getShowDeliverySelectionSetting().then((response: boolean) => {
            if (response) {
                this.showDeliverySelection = response;
                this.estimateCost(false);
            }
        });
    }

    // ----- ON INIT -----
    // Logic to run on start up.
    ngOnInit() {
        this.tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        this.tomorrow.setHours(0, 0, 0, 0); // set to midnight
        let localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();
        this.needByDateOptions = {
            minDate: this.tomorrow,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };
        this.createForm();

        this.locale = this.activeProfileService.profile.Locale || navigator.language;
        this.cartId = this.stateService.params.cartId || 0;
        if (this.cartId === 0) {
            this.stateService.transitionTo("main.mycart", {});
        }

        this.paymentMethod = this.stateService.params.paymentMethod || "Both";

        this.translateService.onLangChange.subscribe(() => this.translateText());

        let countryPromise = this.countryService.Get();
        countryPromise.then((response) => {
            this.countryList = response;
        });

        const settingsPromise = this.getShopSettings();
        const poNumberSettingsPromise = this.getPONumberSettings();

        const referenceNumberPromise = this.shopSettingsService.getReferenceNumberSetting().then((response) => {
            this.showReferenceNumber = response;
        });

        const commentsPromise = this.shopSettingsService.getCommentsSetting().then((response) => {
            this.showComments = response;
        });

        const needByDatePromise = this.shopSettingsService.getNeedByDateSetting().then((response) => {
            this.showNeedByDate = response;
        });

        const addressPromise = this.addressService.getAddressBook().then((response: AddressBookEntry[]) => {
            this.addressBookEntries = response;
            this.buildAddressOptionList();
        });

        const orderReasonsPromise = this.shopSettingsService.getOrderReasons().then((response: string[]) => {
            this.orderReasons = response.sort((a, b) => a > b ? 1 : -1);
        });

        const showDeliverySelectionPromise = this.getShowDeliverySelectionSetting();

        Promise.all([settingsPromise, poNumberSettingsPromise, addressPromise, orderReasonsPromise, referenceNumberPromise, needByDatePromise, showDeliverySelectionPromise]).then(() => {
            this.cartService.getMyCurrentCart().then(() => {
                this.CurrentCart = this.cartService.myCurrentCartData();
                const tempLocations = this.CurrentCart.Items.map(x => x.LocationId);
                this.cartLocations = tempLocations.filter(function (x, pos) {
                    return tempLocations.indexOf(x) === pos; // Ensures only the first instance is added.
                });
                super.setFormPristine(this.checkoutForm);

                if (tempLocations.length > 1) {
                    this.locationService.searchLocationsByIds(tempLocations).then((locResponse: SearchResponse<LocationModel>) => {
                        this.fullLocationDetails = locResponse.ResultList;

                        const countryCodes = uniq(locResponse.ResultList.map((item) => {
                            return item.Addresses[0].Country;
                        }));

                        if (countryCodes.length > 1) {
                            this.countryService.Get().then((countryResponse) => {
                                this.locationCountries = countryResponse.filter((item) => countryCodes.indexOf(item.Iso3166ThreeCharCountryCode) > -1);
                                this.selectCountry();
                            });
                        } else {
                            this.chooseLocation();
                        }
                    });
                } else {
                    this.chooseLocation();
                }
            });
        });
    }
}
