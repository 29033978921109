import {distinctUntilChanged, debounceTime} from "rxjs/operators";
import { DatePipe, FormatWidth, getLocaleDateFormat } from "@angular/common";
import { Subject } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActiveProfileService, ListSearchHelper, SearchInfo, SelectOption, FileDownloadService } from "imagine-ui-ng-core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { ProfileService } from "imagine-ui-ng-quick-start";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { LocationModel, LocationService, BrandBulletinService, BrandBulletinModel } from "../../../../imagine-ui-ng-store-profile";
import { SearchCardUIModel } from "../../../../shared/index";
import { SearchModalComponent } from "../../../../shared/search-modal/search-modal.component";
import { AllocationDataSearchResultModel, CampaignService } from "../../../index";
import { CampaignData } from "../../../model/CampaignData";
import { environment } from "../../../../../environments/environment";
import { AuthService } from "imagine-ui-ng-security";
import { ItemService, ItemModel } from "../../../../imagine-ui-ng-shop";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { GeneralSettingsService } from "../../../../shared/service/general-settings.service";

interface IDatePicker {
    opened: boolean;
}

interface ResultModel {
    item: any;
}

interface AllocationDataSearchResultUIModel extends AllocationDataSearchResultModel {
    FixtureElementLabel: string;
    Cards: SearchCardUIModel[];
    Expires: string;
    FullImage: string;
}

interface ItemUIModel extends ItemModel {
    Cards: SearchCardUIModel[];
    FullImage: string;
}

interface DisplayModel {
    Name: string;
    Label: string;
    CanHighlight: boolean;
}

@Component({
    selector: "app-kit-content-list",
    templateUrl: "./kit-content-list.component.html",
    styleUrls: ["./kit-content-list.component.scss"]
})
export class KitContentListComponent implements OnInit {
    public searchLabel: string;
    public searchLabelPlural: string;
    public dataHelper: ListSearchHelper;
    public kitContentList: ListSearchHelper;
    public itemHelper: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<any>;
    public promiseMain: Promise<any>;
    public loaded = false;
    public query: string;
    public searchInfo: SearchInfo;
    public querySubject: Subject<string> = new Subject<string>();
    private initialLocationLabel = "CHOOSE_LOCATION";
    public LocationLabel = this.initialLocationLabel;
    private loadingLabel = "LOADING";
    public CampaignLabel = "";
    public selectedLocation = 0;
    public selectedLocationIdentifier = "";
    public selectedCampaign = 0;
    public selectedReportType: string;
    public ExportLink = "";
    private noActiveCampaignsLabel = "NO_ACTIVE_CAMPAIGNS";
    public DateRangeTooBig = false;
    public reportOptions: SelectOption[] = [];
    private day = 1000 * 60 * 60 * 24;

    private displayList: DisplayModel[];
    private disableLocation = false;

    public showResultGrid = true;

    private TranslateStrings: { [key: string]: string } = {
        "CHOOSE_LOCATION": "",
        "ITEM": "",
        "ITEMS": "",
        "LOADING": "",
        "NO_ACTIVE_CAMPAIGNS": "",
        "SELECT_REPORT": "",
        "CAMPAIGN_CONTENT_LIST": "",
        "EXPIRED_ITEM": "",
        "PRODUCTS_NEED_WEIGHT": "",
        "UP_DOWN_REPORT": "",
        "IMAGINE_DEMO_REPORT": "",
        "ERROR_DOWNLOADING_UP_DOWN": ""
    };

    public rangeDate: any;
    public dateRangeOptions: Partial<BsDatepickerConfig> = {};
    private calendarTheme = "theme-default";
    public localeName: string;
    public dateFormat: string;
    public fromDateModel: Date;
    public toDateModel: Date;
    public dateRangeModel: Date[] = [];

    private today: Date;
    public startDate: IDatePicker;
    public startDateOptions: {};
    public selectedStartDate: Date;
    public downloadfileGuid: string;
    public downloadfileName: string;
    public brandBulletin: BrandBulletinModel;

    constructor(private campaignService: CampaignService, listSearchHelper: ListSearchHelper, private ipsModal: IpsModalService, private translateService: TranslateService,
        private activeProfileService: ActiveProfileService, private downloadService: FileDownloadService, private locationService: LocationService,
        private profileService: ProfileService, private authService: AuthService, private datePipe: DatePipe, private itemService: ItemService,
        private ipsMessage: IpsMessageService, private settingsService: GeneralSettingsService,
        private brandBulletinService: BrandBulletinService    ) {
        this.kitContentList = listSearchHelper.getListSearchHelper(campaignService);
        this.itemHelper = listSearchHelper.getListSearchHelper(itemService);

        let minDate = new Date(Date.UTC(1970, 1, 1, 0, 0, 0, 0));
        this.localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(this.localeName, FormatWidth.Short).toUpperCase();

        this.dateRangeOptions = {
            minDate: minDate,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.setInitialValues();
    }

    ngOnInit() {

        this.today = new Date();
        this.today.setHours(0, 0, 0, 0);	// set to midnight

        let localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();

        this.startDate = {
            opened: false
        };
        this.startDateOptions = {
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.promiseMain = this.getUpDownReportSetting();

        this.querySubject.pipe(
            debounceTime(350),
            distinctUntilChanged())
            .subscribe(model => {
                this.getListData(model);
            });

        this.translateService.get("ALL").subscribe(() => this.TranslateText());

        this.brandBulletinService.getList().then((response: BrandBulletinModel[]) => {

            if (response && response.length > 0) {
                let profileVal = response.find(q => q.Type === "UpDown");
                if (profileVal && !this.isNullOrSpaces(profileVal.Value)) {
                    this.brandBulletin = profileVal;
                }

            }
        });
    }

    private TranslateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }

        this.initialLocationLabel = this.TranslateStrings[this.initialLocationLabel];
        this.LocationLabel = this.initialLocationLabel;
        if (this.CampaignLabel === "") {
            this.CampaignLabel = this.TranslateStrings[this.loadingLabel];
        }
        this.searchLabel = this.TranslateStrings["ITEM"];
        this.searchLabelPlural = this.TranslateStrings["ITEMS"];
        this.noActiveCampaignsLabel = this.TranslateStrings["NO_ACTIVE_CAMPAIGNS"];
        this.loadReportOptions();

    }


    /** Set all the data for each report to inital values */
    private setInitialValues() {

        // Common Settings
        this.selectedLocation = 0;
        this.selectedLocationIdentifier = "";
        this.LocationLabel = this.initialLocationLabel;

        // Campaign Content Report
        this.selectedCampaign = 0;
        this.CampaignLabel = "";

        // Expired Items Report
        this.rangeDate = {
            opened: false
        };

        let today = new Date();
        today.setHours(0, 0, 0, 0);	// set to midnight
        this.fromDateModel = today;
        this.toDateModel = today;
        this.dateRangeModel.push(this.fromDateModel);
        this.dateRangeModel.push(this.toDateModel);
        this.DateRangeTooBig = false;
    }

    private loadReportOptions() {
        this.reportOptions = [
            { value: "", text: this.TranslateStrings["SELECT_REPORT"] },
            { value: "contentlist", text: this.TranslateStrings["CAMPAIGN_CONTENT_LIST"]},
            { value: "expireditem", text: this.TranslateStrings["EXPIRED_ITEM"] },
            { value: "productneedweight", text: this.TranslateStrings["PRODUCTS_NEED_WEIGHT"] }

        ];
        this.selectedReportType = this.reportOptions[0].value;
    }



    private getUpDownReportSetting(): Promise<any> {
        return this.settingsService.canViewUpDownReport().then((response: boolean) => {
            if (response) {
                this.reportOptions.push({ value: "imaginedemo", text: this.TranslateStrings["UP_DOWN_REPORT"] });
            }
        }).finally(() => {
            this.loaded = true;
        });
    }

    public getCurrentCampaign() {
        if (this.CampaignLabel === "") {
            this.CampaignLabel = this.TranslateStrings[this.loadingLabel];
        }
        this.campaignService.getMostRecentActiveCampaign().then((campaign: CampaignData) => {
            if (campaign) {
                this.selectedCampaign = campaign.Id;
                this.CampaignLabel = campaign.Name;
                this.setLocation();
            } else {
                this.CampaignLabel = this.noActiveCampaignsLabel;
            }
        });
    }

    public getListData(search: string, forceReload: boolean = false) {

        if (this.selectedReportType === "" || this.DateRangeTooBig) {
            return;
        }

        this.query = search || "";

        if (this.selectedReportType === "contentlist") {
            if (this.selectedLocation === 0 || this.selectedCampaign === 0) {
                return;
            }
            this.searchInfo = {
                searchText: this.query,
                additionalQueryParams: [{ param: "LocationId", paramValue: this.selectedLocation.toString() }, { param: "CampaignId", paramValue: this.selectedCampaign.toString() }]
            };

            const route = "Campaign/AllocationData/Search";
            this.promise = this.kitContentList.searchHelper(this.searchInfo, forceReload, route);

        } else if (this.selectedReportType === "expireditem") {

            if (this.selectedLocation === 0 || this.fromDateModel === null || this.toDateModel === null) {
                return;
            }
            this.searchInfo = {
                searchText: search || "",
                additionalQueryParams: [{ param: "LocationId", paramValue: this.selectedLocation.toString() },
                { param: "StartDate", paramValue: this.fromDateModel.toUTCString() },
                { param: "EndDate", paramValue: this.toDateModel.toUTCString() }]
            };

            const route = "Campaign/AllocationDataExpired/Search";
            this.promise = this.kitContentList.searchHelper(this.searchInfo, undefined, route);
        } else if (this.selectedReportType === "productneedweight") {
            this.searchInfo = { searchText: this.query };

            const route = "Item/SearchForNeedWeight";
            this.promise = this.itemHelper.searchHelper(this.searchInfo, forceReload, route);
        }
        if (this.selectedReportType === "productneedweight") {
            this.promise.then(result => {
                this.itemHelper.busy = false;
                if (this.searchInfo.searchText === "" && this.itemHelper.ResultChunkAttributes) {
                    this.itemHelper.TotalRecords = this.itemHelper.ResultChunkAttributes.TotalRecords;
                }
                this.itemHelper.resultList.forEach((item: ItemUIModel) => {
                    item.Cards = [];
                    let keys = Object.keys(item);
                    if (this.displayList) {
                        this.displayList.forEach((display: DisplayModel) => {
                            //Add image if we have column selected
                            if (display.Name === "Image") {
                                item.FullImage =
                                    `${environment.baseApiUrl}Media/v1/MasterMedia/${this.activeProfileService
                                        .businessIdentity}/Original/Jpg?BusinessIdentity=${
                                    this.activeProfileService
                                        .businessIdentity
                                    }&idToken=${this.authService.getIdToken()}`;
                                return;
                            }

                            if (display.Name === "StartDate") {
                                item.Cards.push({
                                    Id: 0,
                                    Key: display.Label,
                                    Value: this.datePipe.transform(item["StartDate"], "mediumDate"),
                                    Type: "Kit",
                                    CanHighlight: display.CanHighlight
                                });
                                return;
                            }

                            let key = keys.find(q => q === display.Name);
                            if (key) {
                                item.Cards.push({
                                    Id: 0,
                                    Key: display.Label,
                                    Value: item[key],
                                    Type: "Kit",
                                    CanHighlight: display.CanHighlight
                                });
                                return;
                            } else {
                                item.Cards.push({
                                    Id: 0,
                                    Key: display.Label,
                                    Value: "",
                                    Type: "Kit",
                                    CanHighlight: display.CanHighlight
                                });

                            }
                        });
                    }

                });
                this.dataHelper = this.itemHelper;

            });
        } else {
            this.promise.then(result => {
            this.kitContentList.busy = false;
            if (this.searchInfo.searchText === "" && this.kitContentList.ResultChunkAttributes) {
                this.kitContentList.TotalRecords = this.kitContentList.ResultChunkAttributes.TotalRecords;
            }
            this.kitContentList.resultList.forEach((item: AllocationDataSearchResultUIModel) => {

                let typeLabel = item.FixtureTypeName ? item.FixtureTypeName + " - " + item.HolderName : item.ElementName;
                item.FixtureElementLabel = `${typeLabel} - ${item.Width} X ${item.Height}`;

                let expires = new Date(item.PromotionEndDate);
                expires.setDate(expires.getDate() + 1);
                item.Expires = expires.toLocaleDateString();


                item.Cards = [];
                let keys = Object.keys(item);
                if (this.displayList) {
                    this.displayList.forEach((display: DisplayModel) => {
                        //Add image if we have column selected
                        if (display.Name === "Image") {
                            item.FullImage =
                                `${environment.baseApiUrl}Media/v1/MasterMedia/${this.activeProfileService
                                .businessIdentity}/CampaignSign/${item.ErpXrefId}/Original/Jpg?BusinessIdentity=${
                                this.activeProfileService
                                .businessIdentity
                                }&idToken=${this.authService.getIdToken()}`;
                            return;
                        }

                        if (display.Name === "PromotionStartEndDate") {
                            item.Cards.push({
                                Id: 0,
                                Key: display.Label,
                                Value: `${this.datePipe.transform(item["PromotionStartDate"], "mediumDate")} - ${this.datePipe.transform(item["PromotionEndDate"], "mediumDate")}`,
                                Type: "Kit",
                                CanHighlight: display.CanHighlight
                            });
                            return;
                        }

                        let key = keys.find(q => q === display.Name);
                        if (key) {
                            item.Cards.push({
                                Id: 0,
                                Key: display.Label,
                                Value: item[key],
                                Type: "Kit",
                                CanHighlight: display.CanHighlight
                            });
                            return;
                        } else {
                            item.Cards.push({
                                Id: 0,
                                Key: display.Label,
                                Value: "",
                                Type: "Kit",
                                CanHighlight: display.CanHighlight
                            });

                        }
                    });
                }

            });
                this.dataHelper = this.kitContentList;
        });
        }


    }

    public changeLocation() {

        this.downloadfileGuid = "";

        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { search: "location" } })
            .then((response: ResultModel) => {
                this.selectedLocation = response.item.Id;
                this.LocationLabel = response.item.LocationIdentifier + " - " + response.item.Name;
                this.selectedLocationIdentifier = response.item.LocationIdentifier;
                if (this.selectedReportType !== "updown" && this.selectedReportType !== "imaginedemo") {
                    this.reloadData(this.query);
                }
            },
            () => { }); // Rejected
    }

    public changeDateRangeUpDown() {
        this.downloadfileGuid = "";
    }

    public changeReportType() {
        this.setInitialValues();

        this.downloadfileGuid = "";
        this.showResultGrid = true;

        switch (this.selectedReportType) {
            case "contentlist":

                if (this.activeProfileService.businessIdentity.toLowerCase() === "bsns.00000104") {
                    this.displayList = [
                        { Name: "Image", Label: "Image", CanHighlight: true },
                        { Name: "CustomerItemCode", Label: "CUSTOMER_ITEM_CODE", CanHighlight: true },
                        { Name: "SignTotal", Label: "QTY", CanHighlight: false },
                        { Name: "PromotionStartEndDate", Label: "START_AND_END_DATE", CanHighlight: false },
                        { Name: "AbbreviatedName", Label: "ABBREVIATED_NAME", CanHighlight: false },
                        { Name: "UniqueId", Label: "SYSTEM_ID", CanHighlight: false },
                        { Name: "CollectionName", Label: "COLLECTION_NAME", CanHighlight: false }];
                } else {
                    this.displayList = [
                        { Name: "Image", Label: "Image", CanHighlight: true },
                        { Name: "CustomerItemCode", Label: "CUSTOMER_ITEM_CODE", CanHighlight: true },
                        { Name: "SignTotal", Label: "QTY", CanHighlight: false },
                        { Name: "PromotionStartEndDate", Label: "START_AND_END_DATE", CanHighlight: false }
                    ];
                }


                this.getCurrentCampaign();
                this.dataHelper = this.kitContentList;
                break;
            case "expireditem":
                this.displayList = [{ Name: "Image", Label: "IMAGE", CanHighlight: false }, { Name: "CustomerItemCode", Label: "CUSTOMER_ITEM_CODE", CanHighlight: true },
                    { Name: "Expires", Label: "EXPIRES", CanHighlight: false }, { Name: "SignTotal", Label: "QTY", CanHighlight: false },
                    ];
                this.setLocation();
                this.dataHelper = this.kitContentList;
                break;
            case "productneedweight":
                this.displayList = [{ Name: "Image", Label: "IMAGE", CanHighlight: false }, { Name: "CustomerItemCode", Label: "CUSTOMER_ITEM_CODE", CanHighlight: true },
                { Name: "StartDate", Label: "AVAILABLE_DATE", CanHighlight: false }, { Name: "ProductType", Label: "PRODUCT_TYPE", CanHighlight: false },
                ];
                this.reloadData(this.query);
                this.dataHelper = this.itemHelper;
                break;
            case "updown": {
                this.showResultGrid = false;
                this.getCurrentCampaign();
                break;
            }
            case "imaginedemo": {
                this.showResultGrid = false;
                break;
            }
            default:
                this.displayList = [];
                break;
        }
        // Clear everything out when switching reports
        this.kitContentList.resultList = [];
        this.kitContentList.TotalRecords = 0;
        this.kitContentList.ResultChunkAttributes.TotalRecords = 0;

        this.itemHelper.resultList = [];
        this.itemHelper.TotalRecords = 0;
        this.itemHelper.ResultChunkAttributes.TotalRecords = 0;

        this.query = "";
    }

    private setLocation() {
        let locList = this.profileService.getLocationList();
        if (locList && locList.length === 1) {
            this.locationService.getByExternalId(locList[0]).then((data: LocationModel) => {
                this.selectedLocation = data.Id;
                this.LocationLabel = data.Name;
                this.reloadData(this.query);
                this.disableLocation = true;
            });
        }
    }

    public changeCampaign() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { search: "campaign", exportedOnly: true } })
            .then((response: ResultModel) => {
                    this.selectedCampaign = response.item.Id;
                    this.CampaignLabel = response.item.Name;
                    this.reloadData(this.query);
                },
                () => { }); // Rejected
    }

    //public changeDateRange() {
    //    if (this.dateRangeModel && this.dateRangeModel.length > 1) {
    //        this.fromDateModel = this.dateRangeModel[0];
    //        this.toDateModel = this.dateRangeModel[1];
    //        this.checkDateRange();
    //        this.setMaxDate();
    //        this.reloadData(this.query);
    //    }
    //}
    public changeDateRange(event: any) {
        if (event && event.startDate && event.endDate) {
            this.fromDateModel = event.startDate;
            this.toDateModel = event.endDate;
            //this.checkDateRange();
            //this.setMaxDate();
            this.DateRangeTooBig = event.rangeTooBig;
            this.reloadData(this.query);
        } else {
            this.fromDateModel = null;
            this.toDateModel = null;
            this.DateRangeTooBig = false;
        }
    }

    //public changeFromDate() {
    //    this.checkDateRange();
    //    this.setMaxDate();
    //    if (this.fromDateModel) {
    //        this.reloadData(this.query);
    //    }
    //}

    //public changeToDate(skipReload = false) {
    //    this.checkDateRange();
    //    if (this.toDateModel && !skipReload) {
    //        this.reloadData(this.query);
    //    }
    //}

    //private setMaxDate() {
    //    if (this.fromDateModel) {
    //        this.toDateOptions.maxDate = new Date(this.fromDateModel.getTime() + (this.day * 30));
    //    } else {
    //        this.toDateOptions.maxDate = undefined;
    //    }
    //}
    //private checkDateRange() {
    //    if (this.toDateModel && this.fromDateModel) {
    //        const diff = Math.abs(Math.floor(this.fromDateModel.getTime() - this.toDateModel.getTime()));

    //        const days = Math.floor(diff / this.day);
    //        this.DateRangeTooBig = days > 30;
    //    } else {
    //        // Set to false since we don't have a range yet
    //        this.DateRangeTooBig = false;
    //    }
    //}

    public onQueryChange(query: string) {
        this.query = query;
        this.querySubject.next(query);
    }

    public getExportLink() {
        if (this.selectedReportType === "contentlist") {
            let url = `${this.campaignService.urlApiBaseProductPlusVersion}Campaign/KitContentListReport`;
            this.ExportLink = `${url}?SearchText=&ChunkIndex=0&RecordCount=2147483647&LocationId=${this.selectedLocation}&CampaignId=${this.selectedCampaign}&BusinessIdentity=${this.activeProfileService.businessIdentity}`;
        } else if (this.selectedReportType === "expireditem") {
            let url = `${this.campaignService.urlApiBaseProductPlusVersion}Campaign/ExpiredItemsListReport`;
            this.ExportLink =
                `${url}?SearchText=&ChunkIndex=0&RecordCount=2147483647&LocationId=${this.selectedLocation}&StartDate=${
                this.fromDateModel.toUTCString()}&EndDate=${this.toDateModel.toUTCString()}&BusinessIdentity=${this
                .activeProfileService.businessIdentity}`;
        } else if (this.selectedReportType === "productneedweight") {
            let url = `${this.itemService.urlApiBaseProductPlusVersion}Item/ProductNeedWeightReport`;
            this.ExportLink = `${url}?SearchText=&ChunkIndex=0&RecordCount=2147483647&BusinessIdentity=${this.activeProfileService.businessIdentity}`;
        } else {
            this.ExportLink = "";
        }
    }

    public reloadData(search: string, forceReload: boolean = false) {
        this.getListData(search, forceReload);
        this.getExportLink();
    }

    public reportDownloadClickHandler() {
        const url = this.ExportLink;
        if (url !== "") {
            this.downloadService.Download(url);
        }
    }

    public downloadUpDownWorker(): Promise<any> {
        const locationId = this.selectedLocation;
        const campaignId = this.selectedCampaign;

        return this.campaignService.generateUpDown(locationId, campaignId).then((upDownResponse) => {

            if (upDownResponse) {

                // generate URL and file name based on input options and response
                const fileName = `Up Down - Location ${this.selectedLocationIdentifier}.pdf`;
                const fileGuid = upDownResponse.replace(".pdf", "");
                const token = this.authService.getIdToken();
                const businessId = this.activeProfileService.businessIdentity;
                const url = `${this.campaignService.urlApiBase}Media/v1/MasterMedia/${businessId}/UpDownReport/${fileGuid}/Original/Pdf?businessIdentity=${businessId}&idToken=${token}`;

                // download the file
                this.downloadService.Download(url, fileName);
            }
            else {
                this.ipsMessage.error(this.TranslateStrings["ERROR_DOWNLOADING_UP_DOWN"]);
            }
        });
    }

    public downloadUpDown() {
        return this.ipsMessage.waitForWork({
            body: "Downloading Up/Down Report", workFunction: () => this.downloadUpDownWorker(),
            progressMessage: "Downloading Up/Down Report"
        });
    }

    public downloadImagineDemoWorker(): Promise<any> {
        const locationId = this.selectedLocation;
        const reportDate = this.selectedStartDate;
        this.downloadfileGuid = null;
        this.downloadfileName = null;

        return this.campaignService.generateImagineDemo(locationId, reportDate).then((upDownResponse) => {

            if (upDownResponse) {
                this.downloadfileGuid = upDownResponse.replace(".pdf", "");
                this.downloadfileName =  `Up Down - Location ${this.selectedLocationIdentifier}.pdf`;
            }
            else {
                this.ipsMessage.error(this.TranslateStrings["ERROR_DOWNLOADING_UP_DOWN"]);
            }
        });
    }

    public downloadImagineDemo() {
        return this.ipsMessage.waitForWork({
            body: "Generating Up/Down Report", workFunction: () => this.downloadImagineDemoWorker(),
            progressMessage: "Generating Up/Down Report"
        });
    }

    public downloadImagineDemoLink() {
        // download the file
        // generate URL and file name based on input options and response
        const token = this.authService.getIdToken();
        const businessId = this.activeProfileService.businessIdentity;
        const url = `${this.campaignService.urlApiBase}Media/v1/MasterMedia/${businessId}/UpDownReport/${this.downloadfileGuid}/Original/Pdf?businessIdentity=${businessId}&idToken=${token}`;
        this.downloadService.Download(url, this.downloadfileName);
    }

    private isNullOrSpaces(str: string): boolean {
        return !!str === false || str.match(/^ *$/) !== null;
    }

}
