var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { environment } from "../../../environments/environment";
var OrderService = /** @class */ (function (_super) {
    __extends(OrderService, _super);
    function OrderService(http, activeProfileService, authService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.authService = authService;
        _this.init("FulfillmentOrder", "Shop", "v1");
        return _this;
    }
    OrderService.prototype.orderStatusList = function () {
        var route = this.urlApiBasePlusController + "Status";
        return this.getByRoute(route);
    };
    OrderService.prototype.getItemDownloadUrl = function (itemId, locationId, orderItemId, orderReason) {
        var _this = this;
        var route = this.urlApiBasePlusController + "PodDownloadUrl";
        if (itemId && orderItemId) {
            route = route + "?ItemId=" + itemId + "&LocationId=" + locationId + "&OrderItemId=" + orderItemId;
        }
        else if (itemId) {
            route = route + "?ItemId=" + itemId + "&LocationId=" + locationId + "}";
        }
        else if (orderItemId) {
            route = route + "?OrderItemId=" + orderItemId + "&LocationId=" + locationId;
        }
        if (orderReason) {
            route = route + "&OrderReason=" + orderReason;
        }
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getByRoute(route).then(function (response) {
                resolve(response);
            });
        });
        return returnPromise;
    };
    OrderService.prototype.getStaticItemDownloadUrl = function (productCode, itemId, locationId, orderItemId) {
        var _this = this;
        var route = this.urlApiBasePlusController + "PodDownloadUrl";
        if (itemId && orderItemId) {
            route = route + "?ItemId=" + itemId + "&LocationId=" + locationId + "&OrderItemId=" + orderItemId;
        }
        else if (itemId) {
            route = route + "?ItemId=" + itemId + "&LocationId=" + locationId;
        }
        else if (orderItemId) {
            route = route + "?OrderItemId=" + orderItemId + "&LocationId=" + locationId;
        }
        var returnPromise = new Promise(function (resolve, reject) {
            _this.getByRoute(route).then(function (response) {
                var baseUrl = _this.urlApiBase;
                var businessId = _this.activeProfileService.businessIdentity;
                var token = _this.authService.getIdToken();
                var mediaType = response.MediaType;
                var mediaId = response.MasterMediaId;
                var fileName = productCode + "." + mediaType.toLowerCase();
                var url = baseUrl + "Media/v1/MasterMedia/" + businessId + "/Item/" + mediaId + "/Original/" + mediaType + "?BusinessIdentity=" + businessId + "&idToken=" + token;
                resolve({
                    Url: url,
                    FileName: fileName
                });
            });
        });
        return returnPromise;
    };
    OrderService.prototype.getOrderItemDetails = function (orderId) {
        var route = "" + this.urlApiBasePlusController + orderId + "/OrderItemDetails";
        return this.getByRoute(route);
    };
    OrderService.prototype.getTrackingData = function (item) {
        var packages = [];
        item.Shipments.forEach(function (shipment) {
            packages.unshift.apply(packages, shipment.Packages);
        });
        var packagesWithTrackingData = packages.filter(function (pkg) {
            return pkg.TrackingNumber;
        });
        return packagesWithTrackingData.map(function (pkg) {
            return {
                Key: pkg.TrackingNumber,
                Value: pkg.TrackingURL || null
            };
        });
    };
    return OrderService;
}(IpsBaseWebService));
export { OrderService };
