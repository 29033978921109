import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { OrderService, OrderStatus, OrderStatusModel, ShipmentStatus, ShipmentStatusModel, ShipmentService } from "../../index";

type StatusDictionary = {
    [key in OrderStatus]: OrderStatusModel;
};
@Component({
  selector: "app-status-modal",
  templateUrl: "./status-modal.component.html",
  styleUrls: ["./status-modal.component.scss"]
})
export class StatusModalComponent implements OnInit {
    public title: string;
    public status: string;
    public statusList: OrderStatus[] | ShipmentStatus[] = [];
    public filteredList: OrderStatusModel[] | ShipmentStatusModel[] = [];
    public statusDictionary = {};
    public query: string;
    public type: string;
    public resolve: any;


    constructor(private orderService: OrderService, private shipmentService: ShipmentService, private translateService: TranslateService, private modalInstance: NgbActiveModal) { }

    ngOnInit() {

        this.type = this.resolve.type.toLowerCase();

        // Call translate get in order to wait until translations are loaded, then call our translateText method.
        this.translateService.get("ALL").subscribe(() => this.translateText());
        this.translateService.onLangChange.subscribe(() => this.translateText());


        switch (this.type) {
            case "order":
                this.orderService.orderStatusList().then((response) => {
                    this.statusList = response.map(status => status === "Unknown" ? "ALL" : status);
                    this.statusList.sort();
                    this.filteredList = this.statusList.map(status => this.statusDictionary[status]);
                });
                break;
            case "shipment":
                this.shipmentService.shipmentStatusList().then((response) => {
                    this.statusList = response.map(status => status === "Unknown" ? "ALL" : status).filter(x => x !== "InTransit" && x !== "Delivered" && x !== "DeliveryException");
                    this.statusList.sort();
                    this.filteredList = this.statusList.map(status => this.statusDictionary[status]);
                });
                break;
        }
    }

    translateText() {
        this.status = this.type === "order" ? this.translateService.instant("ORDER_STATUS") : this.translateService.instant("SHIPMENT_STATUS");

        this.statusDictionary["ALL"] = { value: "ALL", text: this.translateService.instant("ALL_UPPERCASE") };
        this.statusDictionary["Unknown"] = { value: "Unknown", text: this.translateService.instant("UNKNOWN") };
        this.statusDictionary["Canceled"] = { value: "Canceled", text: this.translateService.instant("CANCELED") };
        this.statusDictionary["Completed"] = { value: "Completed", text: this.translateService.instant("COMPLETED") };
        this.statusDictionary["Processing"] = { value: "Processing", text: this.translateService.instant("PROCESSING") };
        this.statusDictionary["PartialShip"] = { value: "PartialShip", text: this.translateService.instant("PARTIAL_SHIP") };
        this.statusDictionary["Shipped"] = { value: "Shipped", text: this.translateService.instant("SHIPPED") };
        this.statusDictionary["Delivered"] = { value: "Delivered", text: this.translateService.instant("DELIVERED") };
        this.statusDictionary["Complete"] = { value: "Complete", text: this.translateService.instant("COMPLETE") };
        this.statusDictionary["Backordered"] = { value: "Backordered", text: this.translateService.instant("BACKORDERED") };
        this.statusDictionary["DeliveryException"] = { value: "DeliveryException", text: this.translateService.instant("DELIVERY_EXCEPTION") };
        this.statusDictionary["InTransit"] = { value: "InTransit", text: this.translateService.instant("IN_TRANSIT") };
        this.title = this.type === "order" ? this.title = this.translateService.instant("SELECT_ORDER_STATUS") : this.title = this.translateService.instant("SELECT_SHIPMENT_STATUS");
    }

    onQueryChange(search: string) {
        if (search !== "") {
            let list;
            Object.assign(list, this.statusList);
            this.filteredList = list.map(status => status.indexOf(search) !== -1).map(status => this.statusDictionary[status]);
        }
    }

    statusClicked(status: string) {
        return this.modalInstance.close(status);
    }

    close(closeMessage?: any) {
        this.modalInstance.dismiss();
    }
}
