import { Component, OnInit } from "@angular/core";
import { ListSearchHelper, QueryParamItem } from "imagine-ui-ng-core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { LocationGroupService, LocationGroupModel } from "../../../imagine-ui-ng-store-profile/index";

interface QualityHandlingSearchResult {
    marketedLocations: string;
}

@Component({
    selector: "app-element-quantity-handling-modal",
    templateUrl: "./element-quantity-handling-modal.component.html",
    styleUrls: ["./element-quantity-handling-modal.component.scss"]
})
export class ElementQuantityHandlingModalComponent implements OnInit {
    public locationGroups: ListSearchHelper;
    public locationGroupPromise: Promise<void>;
    public locationGroupQuery: string;
    public busy: boolean;
    public focusLocationGroup: boolean;
    public isModal = true;
    public marketedLocations: string;
    public resolve: QualityHandlingSearchResult;
    public translatedTexts: TranslatedTexts;

    constructor(private modalInstance: NgbActiveModal,
        private translateService: TranslateService,
        private locationGroupService: LocationGroupService,
        private listSearchHelper: ListSearchHelper) {
    }

    ngOnInit() {
        this.translatedTexts = { foundOf: "found of", total: "total", loadMoreResults: "load more results" };
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());

        this.marketedLocations = this.resolve.marketedLocations;
        this.locationGroups = this.listSearchHelper.getListSearchHelper(this.locationGroupService);
        this.locationGroupService.head().then((total: number) => {
            this.locationGroups.TotalRecords = total;
        });

        this.getLocationGroupData("");

        this.focusLocationGroup = true;
    }
    private translateText() {
        this.translateService.get("ALL").subscribe(() => {
            this.translatedTexts.foundOf = this.translateService.instant("FOUND_OF");
            this.translatedTexts.total = this.translateService.instant("TOTAL");
            this.translatedTexts.loadMoreResults = this.translateService.instant("LOAD_MORE_RESULTS");
        });
    }

    public getLocationGroupData(search: string) {
        this.locationGroupQuery = search;
        let additionalQueryParams = [] as QueryParamItem[];

        //Following overrides exclusive filtering behavior of 'ShowCampaignLocationFeatures'.
        //TODO - This works but is confusing. functionality around this should be refactored.
        //It is now clear that internal users should always have all Location Features returned.
        //The 'Show Campaign Location Feature' checkbox functionality on Location Feature page (profile/locationGroup/search)
        //probably should be a front-end filter only, and ShowCampaignLocationFeatures filtering and
        //IncludeNonCampaignLocationFeatures check removed from back end service
        additionalQueryParams.push({ param: "IncludeNonCampaignLocationFeatures", paramValue: "true" } as QueryParamItem);

        this.locationGroupPromise = this.locationGroups.searchHelper({ searchText: this.locationGroupQuery, recordCount: 5, additionalQueryParams: additionalQueryParams }).then(() => {
            this.locationGroups.resultList.forEach((result: LocationGroupModel) => {
                this.locationGroupService.setLocFeatureInfo(result, this.translateService);
            });
        });
    }

    public getMoreListData() {
        this.getLocationGroupData(this.locationGroupQuery);
    }

    public marketedLocationsClicked() {
        return this.modalInstance.close(undefined);
    }

    public itemClicked(item: any) {
        return this.modalInstance.close(item);
    }

    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }


}
