import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MarketModel } from "../Model/MarketModel";
import { MarketService } from "../service/market.service";
import { MarketDisplayInfoLocationSubGroup } from "../model/MarketDisplayInfoLocationSubGroup";
import { MarketDisplayInfoLocation } from "../model/MarketDisplayInfoLocation";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { TranslateService } from "@ngx-translate/core";
import { MarketDisplayInfo } from "../model/MarketDisplayInfo";

interface MarketUIModel extends MarketModel {
    PendingAssignmentTooltip?: string;
}

@Component({
    selector: "app-market-view",
    templateUrl: "./market-view.component.html",
    styleUrls: ["./market-view.component.scss"]
})
export class MarketViewComponent implements OnInit {
    @Input() showLocationModal: boolean;
    @Input() markets: any; // MarketModel[];
    @Output() showLocationBalances = new EventEmitter<string>();

    public finalBalance: number;
    private locationGroupLocationsPendingWarning = "MARKET_PENDING_LOCATIONS_WARNING";

    constructor(private translateService: TranslateService, private marketService: MarketService) {}

    ngOnInit() {
        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() => this.TranslateText());

        this.finalBalance = this.markets[this.markets.length - 1].LocationBalance;
        this.markets.forEach((market: MarketUIModel) => {
            market.DisplayName = this.getMarketDisplayName(market);
            if (market.PendingLocationCount > 0) {
                market.PendingAssignmentTooltip = IpsString.Format(this.locationGroupLocationsPendingWarning, market.PendingLocationCount.toString());
            }
        });
    }


    private TranslateText() {
        this.locationGroupLocationsPendingWarning = this.translateService.instant("MARKET_PENDING_LOCATIONS_WARNING");
    }

    private getMarketDisplayName(market: MarketModel): string {
        let marketPrefix = this.marketService.getMarketPrefix(market.TargetMarketType);
        let result = "";
        switch (market.TargetMarketType) {
            case "LocationSubGroup":
                let marketDisplayInfo = <MarketDisplayInfoLocationSubGroup>market.DisplayInfo;
                result = `${marketPrefix} ${marketDisplayInfo.LocationGroupName} - ${marketDisplayInfo.LocationSubGroupName}`;
                break;
            case "Location":
            case "LocationId":
                let displayInfo = <MarketDisplayInfoLocation>market.DisplayInfo;
                result = `${marketPrefix} ${displayInfo.LocationIdentifier} - ${displayInfo.Name} - ${displayInfo.City}, ${displayInfo.StateProvince}`;
                break;
            case "MarketGroup":
            case "FixtureGroup":
                let mgDisplayInfo = <MarketDisplayInfo>market.DisplayInfo;
                result = `${marketPrefix} ${mgDisplayInfo.Name}`;
                break;
            case "LocationCityState":
                let locCity = market.DisplayInfo as MarketDisplayInfoLocation;
                result = `${marketPrefix} ${locCity.City}, ${locCity.StateProvince}`;
                break;
            case "LocationState":
                let locState = market.DisplayInfo as MarketDisplayInfoLocation;
                result = `${marketPrefix} ${locState.StateProvince}`;
                break;
            case "LocationCountry":
                let locCountry = market.DisplayInfo as MarketDisplayInfoLocation;
                result = `${marketPrefix} ${locCountry.Country}`;
                break;
            case "LocationPostalCode":
                let locPostal = market.DisplayInfo as MarketDisplayInfoLocation;
                result = `${marketPrefix} ${locPostal.PostalCode}`;
                break;
            default:
                let defaultInfo = <MarketDisplayInfoLocation>market.DisplayInfo;
                result = `${defaultInfo.Name}`;
                break;
        }
        return result;
    }

    showLocations() {
        this.showLocationBalances.emit(this.markets);
    }
}
