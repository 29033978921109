import { Component, OnInit, OnDestroy, HostListener, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { SimpleSearchLocationModel } from "../../../imagine-ui-ng-store-profile";
import { SearchModalComponent, SearchCardUIModel } from "../../../shared/index";

import { ListSearchHelper, FileDownloadService, ActiveProfileService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { OrderService, CartService, CartItemPostData, PunchOutService, FulfillmentOrderViewModel, ShipmentModel, OrderItemDetailsModel, OrderAddressModel, ShipmentStatus, ShipmentStatusModel, UStoreProductionStatus, KeyValuePair } from "../../index";
import { StatusModalComponent } from "../status-modal/status-modal.component";
import { FileDownloadModel } from "../../model/FileDownloadModel";
import { SortHelperService } from "../../../shared/service/sort-helper.service";
import { ShopSettingsService } from "../../service/shop-settings.service";

interface OrderItemDetailsUIModel extends OrderItemDetailsModel {
    Cards: SearchCardUIModel[];
    FullImage: string;
    ThumbImage: string;
    TotalCost: number;
    ShipmentAddress: string;
    TrackingLinks: string[];
}

interface ShipmentUIModel extends ShipmentModel {
    Label: string;
}

interface FulfillmentOrderViewUIModel extends FulfillmentOrderViewModel {
    StatusLabel?: string;
    EstimatedTotal?: number;
}

@Component({
    selector: "app-order-view",
    templateUrl: "./order-view.component.html",
    styleUrls: ["./order-view.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class OrderViewComponent implements OnInit, OnDestroy {
    public myOrder: FulfillmentOrderViewUIModel;
    public order: ListSearchHelper;
    public orderId: number;
    public showBanner = false;
    public orderLoaded: boolean;
    public productsLoaded: boolean;
    public orderLoadError: boolean;
    public promise: Promise<any>;
    public query: string;
    private scrollY: number;
    public busy = false;
    public selectedLocation: SimpleSearchLocationModel;
    public selectedShipmentStatus: ShipmentStatusModel;
    public displayList = [];
    public orderItemList: OrderItemDetailsUIModel[] = [];
    public digitalItemList: OrderItemDetailsUIModel[] = [];
    public printedItemList: OrderItemDetailsUIModel[] = [];
    public filteredOrderItemList: OrderItemDetailsModel[] = [];
    public chunkSize = 20;
    public numberToDisplay: number;
    public statusDictionary = {};

    public printSortHelper: SortHelperService<OrderItemDetailsUIModel>;
    public digitalSortHelper: SortHelperService<OrderItemDetailsUIModel>;

    public screenLoaded = false;

    private timer: any;
    private textNA = "NA";

    public pluralMapping: { [k: string]: string } = {
        "=1": "PRODUCT",
        "other": "PRODUCTS"
    };
    private emptyGuid = "00000000-0000-0000-0000-000000000000";

    public showDeliverySelection = false;

    constructor(private translateService: TranslateService, private $state: StateService,
        private transition: Transition, private ipsMessage: IpsMessageService,
        private orderService: OrderService,
        private punchOutService: PunchOutService,
        private activeProfileService: ActiveProfileService,
        private authService: AuthService,
        private cartService: CartService,
        private shopSettingsService: ShopSettingsService,
        private fileDownload: FileDownloadService,
        private ipsModal: IpsModalService) {
        this.printSortHelper = new SortHelperService<OrderItemDetailsUIModel>();
        this.digitalSortHelper = new SortHelperService<OrderItemDetailsUIModel>();
    }

    ngOnInit() {
        this.orderId = Number(this.transition.params().id);
        this.showBanner = Boolean(this.transition.params().showBanner);

        this.myOrder = <FulfillmentOrderViewModel>{
            Id: Number(this.orderId)
        };

        this.translateService.get("ALL").subscribe(() => this.TranslateText());

        //tslint:disable-next-line: max-line-length
        this.displayList = [{ Name: "CustomerItemCode", Label: "CUSTOMER_ITEM_CODE", CanHighlight: true }, { Name: "Quantity", Label: "QTY", CanHighlight: false }, { Name: "LocationLabel", Label: "LOCATION", CanHighlight: false }, { Name: "ShipmentAddress", Label: "SHIPPING_ADDRESS", CanHighlight: false },
        { Name: "ShipmentStatus", Label: "SHIPMENT_STATUS", CanHighlight: false }, { Name: "ShipmentTrackingNumber", Label: "SHIPMENT_TRACKING_NUMBER", CanHighlight: false }];

        const promises = [this.loadOrderSummary(), this.loadOrderItemDetails(), this.getShowDeliverySelectionSetting()];

        Promise.all(promises).then(() => {
            this.screenLoaded = true;
        });
    }

    get sortedPrintRecords() {
        let returnValue = this.printSortHelper.applySort(this.printedItemList);
        return returnValue;
    }

    get sortedDigitalRecords() {
        let returnValue = this.digitalSortHelper.applySort(this.digitalItemList);
        return returnValue;
    }

    private loadOrderSummary(): Promise<any> {
        return this.orderService.get(this.orderId).then(order => {
            Object.assign(this.myOrder, order);
            this.myOrder.StatusLabel = this.statusDictionary[this.myOrder.Status].text;
            this.myOrder.EstimatedHandling = this.myOrder.EstimatedHandling || 0;
            this.myOrder.EstimatedTotal = this.myOrder.EstimatedSubtotal + this.myOrder.EstimatedShippingCost + this.myOrder.EstimatedTax + this.myOrder.EstimatedHandling;
            this.orderLoaded = true;
        }).catch((err) => {
                this.orderLoadError = true;
        });
    }

    private TranslateText() {
        this.pluralMapping["=1"] = this.translateService.instant("PRODUCT");
        this.pluralMapping["other"] = this.translateService.instant("PRODUCTS");
        this.selectedLocation = { Id: 0, LocationIdentifier: "", Name: "", Label: this.translateService.instant("ALL_UPPERCASE") };
        this.selectedShipmentStatus = { value: "ALL", text: this.translateService.instant("ALL_UPPERCASE") };

        this.statusDictionary["ALL"] = { value: "ALL", text: this.translateService.instant("ALL_UPPERCASE") };
        this.statusDictionary["Unknown"] = { value: "Unknown", text: this.translateService.instant("UNKNOWN") };
        this.statusDictionary["Canceled"] = { value: "Canceled", text: this.translateService.instant("CANCELED") };
        this.statusDictionary["Completed"] = { value: "Completed", text: this.translateService.instant("COMPLETED") };
        this.statusDictionary["Processing"] = { value: "Processing", text: this.translateService.instant("PROCESSING") };
        this.statusDictionary["PartialShip"] = { value: "PartialShip", text: this.translateService.instant("PARTIAL_SHIP") };
        this.statusDictionary["Shipped"] = { value: "Shipped", text: this.translateService.instant("SHIPPED") };
        this.statusDictionary["Delivered"] = { value: "Delivered", text: this.translateService.instant("DELIVERED") };
        this.statusDictionary["Complete"] = { value: "Complete", text: this.translateService.instant("COMPLETE") };
        this.statusDictionary["Backordered"] = { value: "Backordered", text: this.translateService.instant("BACKORDERED") };
        this.statusDictionary["DeliveryException"] = { value: "DeliveryException", text: this.translateService.instant("DELIVERY_EXCEPTION") };
        this.statusDictionary["InTransit"] = { value: "InTransit", text: this.translateService.instant("IN_TRANSIT") };
        this.statusDictionary["Printed"] = { value: "Printed", text: this.translateService.instant("PRINTED") };
        this.textNA = this.translateService.instant("NA");
    }

    private loadOrderItemDetails(): Promise<any> {
        return this.promise = this.orderService.getOrderItemDetails(this.orderId).then((response) => {
            Object.assign(this.orderItemList, response);

            this.orderItemList.map((orderItem: OrderItemDetailsUIModel) => {
                orderItem.Cards = [];
                if (this.displayList) {
                    this.displayList.forEach((display) => {
                        let val = this.findValueInOrderItem(display.Name, orderItem);

                        // if Order Reason was populated as part of the order, then show it
                        // otherwise don't show it
                        if (display.Name !== "OrderReason" || val) {
                            orderItem.Cards.push({
                                Id: orderItem.Id,
                                Key: display.Label,
                                Value: val,
                                Type: "Order",
                                CanHighlight: display.CanHighlight
                            });
                        }
                    });

                }
                //Setup image URLS
                orderItem.FullImage = `${orderItem.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
                orderItem.ThumbImage = orderItem.FullImage + "&height=300&width=300";

                // calculate total line item cost
                orderItem.TotalCost = (<any>orderItem).UnitPrice * orderItem.Quantity;

                orderItem.ShipmentAddress = this.formatAddress(orderItem.ShipToAddress);

                if (!orderItem.DigitalDownload) {
                    let trackingLinks = (<string[]>this.findValueInOrderItem("ShipmentTrackingNumber", orderItem));
                    orderItem.TrackingLinks = trackingLinks;
                }

                return orderItem;
            });

            this.printedItemList = this.orderItemList.filter((item) => !item.DigitalDownload || item.ProductType === "DropShipment");
            this.digitalItemList = this.orderItemList.filter((item) => item.DigitalDownload && item.ProductType !== "DropShipment");

            this.initializeVariablePodPolling(response);
            this.productsLoaded = true;
        });
    }

    private getShowDeliverySelectionSetting(): Promise<any> {
        return this.shopSettingsService.getShowDeliverySelectionSetting().then((response: boolean) => {
            if (response) {
                this.showDeliverySelection = response;
            }
        });
    }

    private get incompleteVariablePodItems(): number[] {
        const listToFilter = this.orderItemList || [];

        return listToFilter.filter((item: OrderItemDetailsModel) => {
            return item.Variable && this.hasInProgressStatus(item);
        }).map((item: OrderItemDetailsUIModel) => {
            return item.Id;
        });
    }

    public initializeVariablePodPolling(items: OrderItemDetailsModel[]) {
        const pollingInterval = 20000;

        if (this.incompleteVariablePodItems.length) {
            if (!this.timer) {
                this.timer = setInterval(() => {
                    this.pollVariablePod();
                }, pollingInterval);
            }
        }
    }

    private pollVariablePod() {
        return;

        this.loadOrderItemDetails().then(() => {
            if (this.incompleteVariablePodItems.length === 0) {
                clearInterval(this.timer);
                this.timer = null;
            }
        });
    }

    public hasInProgressStatus(orderItem: OrderItemDetailsModel): boolean {
        const inProgressStatuses = [
            UStoreProductionStatus.Unknown,
            UStoreProductionStatus.Waiting,
            UStoreProductionStatus.InProgress
        ];

        return inProgressStatuses.includes(orderItem.PodVariableStatus);
    }

    public hasFailedStatus(orderItem: OrderItemDetailsModel): boolean {
        const failedStatus = [
            UStoreProductionStatus.Aborted,
            UStoreProductionStatus.Aborting,
            UStoreProductionStatus.Failed,
            UStoreProductionStatus.Suspended
        ];

        return failedStatus.includes(orderItem.PodVariableStatus);
    }

    public getPodStatus(orderItem: OrderItemDetailsModel): string {
        switch (true) {
            case this.hasInProgressStatus(orderItem):
                return "In Progress";
            case this.hasCompletedStatus(orderItem):
                return "Complete";
            case this.hasFailedStatus(orderItem):
                return "Failed";
            case this.downloadHasExpired(orderItem):
                return "Expired";
        }
    }

    public hasCompletedStatus(orderItem: OrderItemDetailsModel): boolean {
        const completedStatus = [
            UStoreProductionStatus.Completed
        ];

        return completedStatus.includes(orderItem.PodVariableStatus);
    }

    private generateTrackingLink(trackingNumber: string, trackingUrl: string): string {
        const link = `<a href="${trackingUrl}" target="_blank" class="text-primary">${trackingNumber}</a>`;
        const span = `<span>${trackingNumber}</span>`;

        if (trackingUrl) {
            return link;
        } else {
            return span;
        }
    }

    public getShipmentStatus(orderItem: OrderItemDetailsUIModel): string {
        let status = orderItem.Shipments.length > 0 ? this.statusDictionary[orderItem.Shipments[0].Status].text : this.statusDictionary["Processing"].text;
        status = status === "Complete" ? "Shipped" : status;
        return status;
    }

    public findValueInOrderItem(key: string, orderItem: OrderItemDetailsUIModel): string | string[] {
        let orderItemKeys = Object.keys(orderItem);
        let found = orderItemKeys.some(q => q === key);
        if (found) {
            return orderItem[key];
        }

        if (orderItem.DigitalDownload) {
            if (key === "ShipmentAddress" || key === "ShipmentTrackingNumber" || key === "ShipmentStatus") {
                return this.textNA;
            }
        }

        if (orderItem.Shipments !== undefined && orderItem.Shipments !== null) {
            if (key === "ShipmentStatus") {
                return orderItem.Shipments.length > 0 ? this.statusDictionary[orderItem.Shipments[0].Status].text : this.statusDictionary["Processing"].text;
            }
            if (key === "ShipmentTrackingNumber") {
                const trackingInfo = this.orderService.getTrackingData(orderItem);
                return trackingInfo.map((trackingItem: KeyValuePair<string, string>) => {
                    return this.generateTrackingLink(trackingItem.Key, trackingItem.Value);
                });
            }
        }
        if (key === "ShipmentAddress" && orderItem.ShipToAddress !== undefined && orderItem.ShipToAddress !== null) {
            return `${orderItem.ShipToAddress.Line1} ${orderItem.ShipToAddress.City}, ${orderItem.ShipToAddress.StateProvince} ${orderItem.ShipToAddress.PostalCode}`;
        }
        return "";

    }

    public selectLocation() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "locationbyorder", orderId: this.orderId } })
            .then((response: any) => {
                this.selectedLocation = <SimpleSearchLocationModel>response.item;
                // this.getListData();
            });
    }

    public selectShipmentStatus() {
        this.ipsModal.displayTemplateScrollable(StatusModalComponent, { resolve: { type: "shipment" } })
            .then((response: ShipmentStatusModel) => {
                this.selectedShipmentStatus = response;
                // this.getListData();
            }, () => {
                // Canceled, do nothing
            }
            );
    }

    public downloadHasExpired(item: OrderItemDetailsModel) {
        const today = new Date();
        const todayAtMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        const expirationDate = new Date(item.PodDownloadUrlExpiration);

        if (expirationDate) {
            return expirationDate < todayAtMidnight;
        } else {
            return false;
        }
    }

    public downloadClicked(item: OrderItemDetailsModel, isStatic = false) {
        if (isStatic) {
            this.orderService.getStaticItemDownloadUrl(item.CustomerItemCode, item.ItemId, item.LocationId, item.Id).then((response: FileDownloadModel) => {
                this.fileDownload.Download(response.Url, response.FileName);
            });
        } else {
            this.orderService.getItemDownloadUrl(item.ItemId, item.LocationId, item.Id, item.OrderReason).then((response: FileDownloadModel) => {
                window.open(response.Url, "_blank");

                // TODO:  uncomment the below, and delete the above line, once the Veracore CORS issues are resolved
                // this.fileDownload.Download(response);
            });
        }
    }

    ngOnDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    public print() {
        event.preventDefault();
        window.print();
    }

    public formatAddressForDisplay(address: OrderAddressModel): string {
        if (address) {
            return `${address.Line1}<br/>${address.City}, ${address.StateProvince} ${address.PostalCode}`;
        } else {
            return null;
        }
    }

    public formatAddress(address: OrderAddressModel): string {
        if (address) {
            return `${address.Line1} ${address.City}, ${address.StateProvince} ${address.PostalCode}`;
        } else {
            return null;
        }
    }

    public locationLabelSegment(locationLabel: string, index: number): string {
        try {
            const token = " - ";
            const segments = locationLabel.split(token);

            return segments[index].replace(token, "");
        } catch {
            return "";
        }
    }

    public get TotalQuantity(): number {
        let totalQuantity = 0;

        this.orderItemList.map((item) => item.Quantity).forEach((item) => {
            totalQuantity += Number(item);
        });

        return totalQuantity;
    }

    reorder(item) {
        //save data to local storage for creating a cart item after punchout
        const cartItem: CartItemPostData = {
            Id: 0,
            LocationId: item.LocationId ? item.LocationId : -1,
            CartId: this.cartService.myCurrentCart.Id,
            ItemId: item.ItemId,
            DigitalDownload: item.DigitalDownload,
            Qty: item.Quantity,
            OrderReason: item.OrderReason,
            Variable: item.Variable,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            OrderProductId: item.PodOrderProductId
        };

        //POD item
        if (item.PodOrderProductId) {
            this.punchOutService.reorder(item.PodOrderProductId, item.PodOrderId).then(response => {
                cartItem.OrderProductId = response.OrderProductId;
                const userName = response.UserName;
                this.punchOutService.getFinalizeUrl(cartItem, this.orderId, userName).then(url => {
                    window.location.href = url;
                });
            });
        } else {  // non-POD

            let duplicate = this.cartService.findDuplicateCartItem(cartItem);
            if (duplicate === null) {
                this.cartService.addToCart(cartItem).then(() => {
                    item.NumberInCart = cartItem.Qty;
                });
            }
            else {
                duplicate.Qty = duplicate.Qty + cartItem.Qty;
                this.cartService.updateItemInMyCart(duplicate);
                const totalQuantity = this.cartService.numberOfItemInLocationCart(cartItem.LocationId, cartItem.ItemId);
                item.NumberInCart = totalQuantity;
            }
        }
    }
}
