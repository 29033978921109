import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { getLocaleDateFormat, FormatWidth, DatePipe } from "@angular/common";
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";
import { SurveyQuestionModel } from "../../model/SurveyQuestionModel";
import { LocationGroupService, LocationGroupModel, LocationSubGroupModel, FixtureByLocationSumService, FixtureLocationSumModel, FixtureAnswerModel } from "../../../imagine-ui-ng-store-profile";
import { ActiveProfileService, ListSearchHelper, SearchInfo, QueryParamItem } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { MySurveyQuestionModel } from "../../model/MySurveyQuestionModel";
import { SurveyService } from "../../service/survey.service";
import { SurveyAnswerSaveModel } from "../../model/SurveyAnswerSaveModel";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString } from "typescript-string-operations";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SelectListComponent } from "../../../shared/select-list/select-list.component";

interface LocationGroupModelWithImages extends LocationGroupModel {
    FullImage: string;
    ThumbImage: string;
    SubGroups: LocationSubGroupUIModel[];
}

interface LocationSubGroupUIModel extends LocationSubGroupModel {
    FullImage: string;
    ThumbImage: string;
    Selected: boolean;
    OriginalSelected: boolean;
}


interface SurveyQuestionUIModel extends SurveyQuestionModel {
    ProfileType: string;
    NoFixture: boolean;
    OriginalNoFixture: boolean;
}

interface FixtureModelWithImages extends FixtureLocationSumModel {
    FullImage: string;
    ThumbImage: string;
    Confirmed: boolean;
}

@Component({
    selector: "app-my-survey-take-question",
    templateUrl: "./my-survey-take-question.component.html",
    styleUrls: ["./my-survey-take-question.component.scss"]
})
export class MySurveyTakeQuestionComponent implements OnInit {
    @Input() question: SurveyQuestionUIModel;
    @Input() questionNumber: number;
    @Input() locationId: number;
    @Input() surveyId: number;
    @Input() parentForm: FormGroup;
    @Input() expandSurveyOptions: boolean;
    @Output() questionConfirmed = new EventEmitter<any>();
    @Output() formDirtyChanged = new EventEmitter<any>();

    public AnswerForm: FormGroup;
    private emptyGuid = "00000000-0000-0000-0000-000000000000";
    public locationGroup = {} as LocationGroupModelWithImages;
    private locationGroupSearch: ListSearchHelper;
    private fixtureSearch: ListSearchHelper;
    private OriginalSelectedSubGroupId: number = null;
    public Saving = false;
    public SavePromise: Promise<any>;
    public dateOptions: BsDatepickerConfig = {} as BsDatepickerConfig;
    private dateFormat: string; // Editing date format
    private dateFormateForDisplay = "MMM d, yyyy"; // View date format
    private dupeSubGroupNameMessage: string;
    private numericValueRequiredMessage: string;
    private maxLengthMessage: string;
    private requiredNameMessage: string;
    private requiredSubGroupMessage: string;
    private invalidDateErrorMessage: string;
    private reservedNameMessage: string;

    public LoadingPromise: Promise<any>;
    public Loading = false;
    public ShowAllOptions = true;
    public AnswerLabel = "ENTER_TEXT";
    public messageOpen = false;
    public LocGroupIsOneToOne = true;
    public confirmLabel = "CONFIRM";

    private errorMessages = {
        "required": () => this.requiredNameMessage,
        "maxlength": (params) => IpsString.Format(this.maxLengthMessage, params.requiredLength),
        "dupeSubGroupNameMessage": () => this.dupeSubGroupNameMessage,
        "numericValueRequiredMessage": () => this.numericValueRequiredMessage,
        "bsDate": () => this.invalidDateErrorMessage,
        "subGroupRequired": () => this.requiredSubGroupMessage,
        "reservedName": () => this.reservedNameMessage
    };

    private translateStrings: { [key: string]: string } = {
        "ANSWERS_SELECTED": "",
        "ANSWER_SELECTED": "",
    };

    public answerPluralMapping: { [k: string]: string } = {
        "=1": "ANSWER_SELECTED",
        "other": "ANSWERS_SELECTED"
    };

    constructor(private locationGroupService: LocationGroupService,
        private activeProfileService: ActiveProfileService,
        private surveyService: SurveyService,
        private authService: AuthService,
        private listSearchHelper: ListSearchHelper,
        private ipsMessage: IpsMessageService,
        private fb: FormBuilder,
        private translateService: TranslateService,
        private datePipe: DatePipe,
        private fixtureByLocationSumService: FixtureByLocationSumService,
        private ipsModal: IpsModalService) {
            this.locationGroupSearch = this.listSearchHelper.getListSearchHelper(locationGroupService);
            this.fixtureSearch = this.listSearchHelper.getListSearchHelper(fixtureByLocationSumService);
    }

    ngOnInit() {
        this.Loading = true;

        this.ShowAllOptions = this.question.AnswerType !== "TypeInOption";
        this.question.ProfileType = this.question.LocationGroup !== undefined ? "LocationGroup" : this.question.FixtureGroup !== undefined ? "FixtureGroup" : undefined;

        if (this.question.ProfileType === "FixtureGroup") {
            const hasAnswers = this.checkHasAnswer();
            this.question.NoFixture = hasAnswers &&
                this.question.MySurveyAnswer.FixtureAnswers.length === 1 &&
                this.question.MySurveyAnswer.FixtureAnswers[0].FixtureId === undefined;
            this.question.OriginalNoFixture = this.question.NoFixture;
            if (!this.question.NoFixture) {
                this.confirmLabel = "CONFIRM_ALL";
            }

        }
        this.createAnswerForm();

        let localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();

        this.dateOptions = {
            containerClass: "theme-default",
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        } as BsDatepickerConfig;

        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() => this.TranslateText());



        try {
            switch (this.question.ProfileType) {
                case "LocationGroup":
                    this.loadLocationGroup();
                    break;
                case "FixtureGroup":
                    this.ShowAllOptions = false;
                    this.AnswerLabel = "ENTER_QUANTITY_FOR_EACH";
                    this.loadFixtureGroup();
                    break;
            }
        } catch (e) {
            this.Loading = false;
        }

        if (this.question.MySurveyMessage && (this.question.MySurveyMessage.Text !== "" || this.question.MySurveyMessage.Media)) {
            this.messageAdminClick();
        }
    }

    private createAnswerForm() {
        switch (this.question.ProfileType) {
            case "LocationGroup":
                let selectedSubGroupIdCtrl = this.fb.control(undefined);
                if (this.IsOneToOneRule()) {
                    selectedSubGroupIdCtrl.setValidators(Validators.required);
                }
                this.AnswerForm = this.fb.group({
                    SubGroups: this.fb.array([]),
                    NewSubGroups: this.fb.array([]),
                    SelectedSubGroupId: selectedSubGroupIdCtrl,
                    IsDirty: this.fb.control(false),
                    HasNASubGroup: this.fb.control(false)
                });
                selectedSubGroupIdCtrl.valueChanges.subscribe(val => {
                    if (val === this.OriginalSelectedSubGroupId) {
                        selectedSubGroupIdCtrl.markAsPristine();
                    }
                    this.formDirtyChanged.emit();
                });
                break;
            case "FixtureGroup":
                this.AnswerForm = this.fb.group({
                    Fixtures: this.fb.array([]),
                    NoFixture: this.fb.control(this.question.NoFixture),
                    IsDirty: this.fb.control(false)
                });
                break;
        }

        if (!this.parentForm.get("Questions")) {
            this.parentForm.addControl("Questions", this.fb.array([this.AnswerForm]));
        } else {
            (this.parentForm.get("Questions") as FormArray).push(this.AnswerForm);
        }
    }

    private TranslateText() {
        this.dupeSubGroupNameMessage = this.translateService.instant("DUPLICATE_SUBGROUP_NAME");
        this.maxLengthMessage = this.translateService.instant("MAX_LENGTH_ERROR");
        this.requiredNameMessage = this.translateService.instant("PLEASE_ENTER_ANSWER");
        this.invalidDateErrorMessage = IpsString.Format(this.translateService.instant("CALENDAR_DATE_FORMAT"), this.dateFormat);
        this.requiredSubGroupMessage = this.translateService.instant("AT_LEAST_ONE_OPTION_REQUIRED");
        this.numericValueRequiredMessage = this.translateService.instant("PLEASE_ENTER_NUMBER");
        this.reservedNameMessage = this.translateService.instant("RESERVED_SUBGROUP_NAME_ANSWER");

        this.answerPluralMapping = {
            "=1": this.translateService.instant("ANSWER_SELECTED"),
            "other": this.translateService.instant("ANSWERS_SELECTED")
        };
    }

    public getNameErrorMessages(key?: string) {
        let msgs = this.errorMessages;
        if (key) {
            switch (key.toLowerCase()) {
            case "name":
                    msgs["required"] = () => this.requiredNameMessage;
                break;
            case "quantity":
                msgs["required"] = () => this.numericValueRequiredMessage;
                break;
            default:
            }
        }

        return msgs;
    }

    get SubGroups(): FormArray {
        return this.AnswerForm.get("SubGroups") as FormArray;
    }

    get Fixtures(): FormArray {
        return this.AnswerForm.get("Fixtures") as FormArray;
    }

    get SelectedSubGroupId(): FormControl {
        return this.AnswerForm.get("SelectedSubGroupId") as FormControl;
    }

    get NewSubGroups(): FormArray {
        return this.AnswerForm.get("NewSubGroups") as FormArray;
    }

    get HasNASubGroupValue(): boolean {
        return this.AnswerForm.get("HasNASubGroup").value;
    }

    private loadLocationGroup(): void {
        Object.assign(this.locationGroup, this.question.LocationGroup);

        this.locationGroupService.sortSubGroup(this.locationGroup.SubGroups, this.locationGroup.OptionType);

        //Setup image URLS
        if (this.locationGroup.Media && this.locationGroup.MasterMediaId !== this.emptyGuid) {
            this.locationGroup.FullImage = `${this.locationGroup.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
            this.locationGroup.ThumbImage = this.locationGroup.FullImage + "&height=300&width=300";
        }
        switch (this.locationGroup.OptionType) {
            case "Number":
                this.AnswerLabel = "ENTER_NUMBER";
                break;
            case "Date":
                this.AnswerLabel = "ENTER_DATE";
                break;
        }

        const hasAnswers = this.checkHasAnswer();

        this.LocGroupIsOneToOne = this.IsOneToOneRule();

        // Initialize each subGroup
        this.locationGroup.SubGroups.forEach(subGroup => {
            subGroup.Selected = false;
            subGroup.OriginalSelected = false;
            if (subGroup.IsNA) {
                this.AnswerForm.patchValue({ HasNASubGroup: true });
            }

            if (subGroup.Media && subGroup.MasterMediaId !== this.emptyGuid) {
                subGroup.FullImage = `${subGroup.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
                subGroup.ThumbImage = subGroup.FullImage + "&height=100&width=150";
            }

            if (hasAnswers) {
                // Set selected on subGroup if answered
                let answered = this.question.MySurveyAnswer.LocationSubGroupId.find(subGroupId => {
                    return subGroupId === subGroup.Id;
                });
                if (answered) {
                    subGroup.Selected = true;
                    subGroup.OriginalSelected = true;
                    if (this.LocGroupIsOneToOne) {
                        this.SelectedSubGroupId.patchValue(subGroup.Id);
                        this.OriginalSelectedSubGroupId = subGroup.Id;
                    }
                }
            } else {
                subGroup.Selected = subGroup.LocationCount > 0;
                subGroup.OriginalSelected = subGroup.LocationCount > 0;
                if (subGroup.Selected) {
                    if (this.LocGroupIsOneToOne) {
                        this.SelectedSubGroupId.patchValue(subGroup.Id);
                        this.OriginalSelectedSubGroupId = subGroup.Id;
                    }
                }
            }

            // Add our form control
            this.SubGroups.push(this.getNewSubGroupCtrl(subGroup));
        });

        if (!this.ShowAllOptions && this.AllowAnother()) {
            this.AddAnotherOption(false);
        }

        // Done loading
        this.Loading = false;
    }

    public getLocationGroupButtonLabel() {
        let _this = this;
        let selectedCount = this.SelectedCount();
        if (selectedCount > 1) {
            return selectedCount + " Features Selected";
        } else if (selectedCount === 1) {
            return _this.SubGroups.controls.filter(subGroup => subGroup.value.Selected === true)[0].value.Name;
        } else {
            return "Choose Feature(s)";
        }
    }

    public chooseLocationGroup() {
        let _this = this;
        let selectedSubGroups = this.SubGroups.controls && this.SubGroups.controls.filter(subGroup => subGroup.value.Selected === true).map(subGroup => subGroup.value.Id);

        let param = {
            title: "Choose Feature(s)",
            items: this.locationGroup.SubGroups.filter(subGroup => subGroup.Id > 0),
            labelField: "Name",
            multiSelect: true,
            selectedIds: selectedSubGroups
        };

        return this.ipsModal.displayTemplateScrollable(SelectListComponent, param, { backdrop: "static", centered: true }).then((response: any) => {
            console.log("Modal response", response);
            //go through all subgroups to see if state changed
            _this.SubGroups.controls.forEach((subGroup: FormGroup) => {
                let responseSubGroupIndex = response.findIndex(responseSubGroup => responseSubGroup.Id === subGroup.value.Id);
                //simulate box click if value changed
                if (!subGroup.value.Selected && responseSubGroupIndex >= 0) {
                    //check was added
                    _this.BoxClicked(subGroup);
                } else if (subGroup.value.Selected && responseSubGroupIndex < 0) {
                    //check was removed
                    _this.BoxClicked(subGroup);
                }
            });
        }, (rejectReason) => {
            // Do nothing, this is here to prevent console error.
        });
    }

    private loadFixtureGroup(): void {
        Object.assign(this.fixtureSearch, { resultList: this.question.FixtureGroup.FixtureLocationSum });
        //this.locationGroupService.sortSubGroup(this.locationGroup.SubGroups, this.locationGroup.OptionType);

        // Initialize each fixture
        this.fixtureSearch.resultList.forEach((fixture: FixtureModelWithImages) => {

            if (fixture.Media && fixture.MasterMediaId !== this.emptyGuid) {
                fixture.FullImage = `${fixture.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
                fixture.ThumbImage = fixture.FullImage + "&height=100&width=150";
            }

            const hasAnswers = this.checkHasAnswer();

                if (hasAnswers) {
                    if (this.question.NoFixture) {
                        //update fixture count to 0 when suvery answer is no fixture
                        fixture.LocationFixtureSum = 0;
                    }
                    fixture.Confirmed = true;
                }

            fixture.OriginalSum = fixture.LocationFixtureSum;

            // Add our form control
            this.Fixtures.push(this.getNewFixtureCtrl(fixture));
        });

        // Done loading
        this.Loading = false;
    }

    private getNewSubGroupCtrl(subGroup: LocationSubGroupUIModel, forNewOption = false): FormGroup {
        let nameControl: FormControl;
        if (forNewOption) {
            nameControl = this.fb.control(subGroup.Name, [Validators.required, Validators.maxLength(50), this.subGroupValidator(this.question.LocationGroup.OptionType === "Date")]);
        } else {
            nameControl = this.fb.control(subGroup.Name);
        }

        let ctrl = this.fb.group({
            Id: subGroup.Id,
            Selected: [subGroup.Selected, this.subGrpRequired(subGroup.Id)],
            FullImage: subGroup.FullImage,
            ThumbImage: subGroup.ThumbImage,
            Name: nameControl,
            OriginalSelected: subGroup.OriginalSelected,
            HasSubGroupImage: subGroup.Media && subGroup.MasterMediaId !== this.emptyGuid,
            IsNA: subGroup.IsNA
        });

        const selectedCtrl = ctrl.get("Selected");
        const origCtrl = ctrl.get("OriginalSelected");
        selectedCtrl.valueChanges.subscribe(val => {
            if (val === origCtrl.value) {
                this.AnswerForm.patchValue({ IsDirty: false });
                selectedCtrl.markAsPristine();
            } else {
                this.AnswerForm.patchValue({ IsDirty: true });
                this.AnswerForm.markAsDirty();
            }
            setTimeout(() => { this.NewSubGroups.controls.forEach(newCtrl => newCtrl.get("Name").updateValueAndValidity()); }, 50);
            this.formDirtyChanged.emit();
        });
        const nameCtrl = ctrl.get("Name");
        nameCtrl.valueChanges.subscribe(val => {
            // NOTE: couldn't get the form to be pristine again when val === null && !this.ShowAllOptions.
            // If the user enters something, then backspaces, it is still dirty.
            this.AnswerForm.patchValue({ IsDirty: true });
            this.AnswerForm.markAsDirty();
            setTimeout(() => { this.NewSubGroups.controls.forEach(newCtrl => newCtrl.get("Name").updateValueAndValidity()); }, 50);
            this.formDirtyChanged.emit();
        });
        return ctrl;
    }

    private getNewFixtureCtrl(fixture: FixtureModelWithImages): FormGroup {
        if (fixture.Media && fixture.MasterMediaId !== this.emptyGuid) {
            fixture.FullImage = `${fixture.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
            fixture.ThumbImage = fixture.FullImage + "&height=100&width=150";
        }
        let holders = this.fb.array([]);
        fixture.Holders.forEach((holder) => {
            if (holder.Quantity > 0) {
                let holderCtrl = this.fb.group({
                    Id: holder.Id,
                    Quantity: [holder.Quantity, [Validators.required, Validators.pattern("^(0|[1-9][0-9]*)$")]],
                    Name: holder.Name,
                    Width: holder.Size.Width,
                    Height: holder.Size.Height
                });
                holders.push(holderCtrl);
            }
        });

        let ctrl = this.fb.group({
            Id: fixture.Id,
            FullImage: fixture.FullImage,
            ThumbImage: fixture.ThumbImage,
            Name: fixture.Name,
            Model: fixture.Model,
            Quantity: fixture.LocationFixtureSum,
            OriginalQuantity: fixture.OriginalSum,
            OriginalConfirmed: fixture.Confirmed,
            Confirmed: fixture.Confirmed,
            HasImage: fixture.Media && fixture.MasterMediaId !== this.emptyGuid,
            Holders: holders
        });
        return ctrl;
    }

    /**
     * Validate the current subGroup against all the other subGroups to check for duplicate names
     * @param subGroups - List of subGroup controls, each must have 'Name' field
     * @param additionalSubGroups - Optional list of additional subGroup controls, each must have 'Name' field
     */
    private subGroupValidator(isDate: boolean): ValidatorFn {
        return (control: AbstractControl) => {
            function getSubGroupCtrlValue(ctrl: AbstractControl): string {
                let result: string = null;
                if (!!ctrl.value) {
                    if (isDate) {
                        if (ctrl.value.getMonth) {
                            // Use toDateString to ignore the time component of the date.
                            result = (ctrl.value as Date).toDateString();
                        } else {
                            result = (new Date(ctrl.value)).toDateString();
                        }
                    } else {
                        result = ctrl.value.toString().toLowerCase();
                    }
                }
                return result;
            }

            let controlValue = getSubGroupCtrlValue(control);
            // July 3, 2019 - Duplicate check removed. Backend will assign to existing subGroup rather than create a new one. Let user type it if
            // they want. UI will show duplicate while on the page but when they re-visit next time, they will see just one instance.
            // July 7, 2019 - Still need duplicate check when existing value is selected. Can't send the same answer twice to the backend. Decided to use
            // validator because otherwise the selected count will be higher than the actual saved answer count.
            if (this.ShowAllOptions) {
                let subGroupNames: string[] = [];
                this.SubGroups.controls.forEach(sg => {
                    // Only care about selected values.
                    if (sg.value.Selected || sg.value.IsNA) {
                        subGroupNames.push(getSubGroupCtrlValue(sg.get("Name")));
                    }
                });

                if (this.NewSubGroups) {
                    this.NewSubGroups.controls.forEach(sg => subGroupNames.push(getSubGroupCtrlValue(sg.get("Name"))));
                }
                let itemsWithSameName = subGroupNames.filter(function (name) {
                    return name === controlValue;
                });

                if (itemsWithSameName.length > 1) {
                    return { dupeSubGroupNameMessage: { valid: false } };
                }
            }

            if (!this.HasNASubGroupValue && controlValue === "not applicable") {
                return { reservedName: { valid: false } };
            }
            return null;
        };
    }

    public IsOneToOneRule(): boolean {
        return this.question.LocationGroup && this.question.LocationGroup.Rule === "Single";
    }

    public AllowAnother(): boolean {
        if (this.ShowAllOptions) {
            return this.question.OptionControl && this.locationGroup.OptionType !== "Bool" &&
                (this.question.LocationGroup.Rule === "Multiple" || this.NewSubGroups.length === 0);
        }
        return this.SelectedCount() === 0;
    }

    private checkNoFixtureAnswer(mySurveyAnswer: MySurveyQuestionModel) {
        return mySurveyAnswer === undefined || mySurveyAnswer.FixtureAnswers === undefined || mySurveyAnswer.FixtureAnswers.length === 0;
    }

    private checkHasAnswer() {
        switch (this.question.ProfileType) {
            case "LocationGroup":
                return this.question.MySurveyAnswer && this.question.MySurveyAnswer.LocationSubGroupId && this.question.MySurveyAnswer.LocationSubGroupId.length > 0;
            case "FixtureGroup":
                return this.question.MySurveyAnswer && this.question.MySurveyAnswer.FixtureAnswers && this.question.MySurveyAnswer.FixtureAnswers.length > 0;
            default:
                return false;
        }
    }

    public SaveAnswer(): void {
        let saveModel: SurveyAnswerSaveModel;
        switch (this.question.ProfileType) {
            case "LocationGroup":
                let selectedList: number[] = [];
                if (this.IsOneToOneRule()) {
                    if (this.SelectedSubGroupId.value) {
                        selectedList.push(this.SelectedSubGroupId.value);
                    }
                } else {
                    selectedList = this.SubGroups.controls.filter(sg => sg.value.Selected).map(sg => sg.value.Id);
                }

                saveModel = {
                    SurveyId: this.surveyId,
                    SurveyQuestionId: this.question.Id,
                    LocationId: this.locationId,
                    OptionType: this.locationGroup.OptionType,
                    NewSubGroupName: undefined,
                    LocationSubGroupId: selectedList
                };

                if (this.NewSubGroups.length > 0) {
                    saveModel.NewSubGroupName = this.NewSubGroups.controls.map(ctrl => {
                        if (this.question.LocationGroup.OptionType === "Date") {
                            // Store in the format we want on the View and List pages. This is also parseable by .Net
                            const date = ctrl.value.Name as Date;
                            return this.datePipe.transform(date, this.dateFormateForDisplay);
                        }
                        return ctrl.value.Name as string;
                    });
                }
                break;
            case "FixtureGroup":
                let fixtureAnswers: FixtureAnswerModel[] = [];
                if (this.question.NoFixture) {
                    fixtureAnswers.push({ FixtureId: undefined, FixtureCount: 0 } as FixtureAnswerModel );
                } else {
                    this.Fixtures.value.forEach((fixture) => {
                        fixtureAnswers.push({ FixtureId: fixture.Id, FixtureCount: fixture.Quantity } as FixtureAnswerModel );
                    });
                }
                saveModel = {
                    SurveyId: this.surveyId,
                    SurveyQuestionId: this.question.Id,
                    LocationId: this.locationId,
                    OptionType: this.question.FixtureGroup.OptionType,
                    FixtureAnswers: fixtureAnswers
                };

                break;
        }

        // Save to the DB.
        this.Saving = true;
        this.SavePromise = this.surveyService.putAnswer(saveModel).then((response) => {
            if (!this.question.MySurveyAnswer) {
                this.question.MySurveyAnswer = {} as MySurveyQuestionModel;
            }
            this.question.MySurveyAnswer.ConfirmedDate = response.ConfirmDate;
            this.question.MySurveyAnswer.UserName = response.Username;

            switch (this.question.ProfileType) {
                case "LocationGroup":
                    if (this.IsOneToOneRule()) {
                        this.OriginalSelectedSubGroupId = this.SelectedSubGroupId.value;
                    }
                    this.SubGroups.controls.forEach(sg => sg.patchValue({ OriginalSelected: sg.value.Selected }));
                    if (this.NewSubGroups.length > 0) {
                        this.NewSubGroups.controls.forEach(sg => {
                            let newSgModel = sg.value as LocationSubGroupUIModel;
                            newSgModel.Selected = true;
                            newSgModel.OriginalSelected = true;
                            if (this.question.LocationGroup.OptionType === "Date") {
                                newSgModel.Name = this.datePipe.transform(newSgModel.Name, this.dateFormateForDisplay);
                            }
                            newSgModel.Id = response.LocationSubGroups.find(nsg => nsg.LocationSubGroupName.toLowerCase() === newSgModel.Name.toString().toLowerCase()).LocationSubGroupId;
                            this.SubGroups.push(this.getNewSubGroupCtrl(newSgModel));
                            if (this.IsOneToOneRule()) {
                                // Set selected for radio button case to the new item just created
                                this.SelectedSubGroupId.patchValue(newSgModel.Id);
                                this.OriginalSelectedSubGroupId = newSgModel.Id;
                            }
                        });

                        // Clear out any of the new entries they had
                        while (this.NewSubGroups.length !== 0) {
                            this.NewSubGroups.removeAt(0);
                        }
                    }
                    break;
                case "FixtureGroup":
                    this.question.OriginalNoFixture = this.question.NoFixture;
                    this.Fixtures.controls.forEach(ctr => {
                        if (this.question.NoFixture) {
                            ctr.patchValue({
                                Quantity: 0,
                                OriginalQuantity: 0
                            });
                        }
                        ctr.get("Confirmed").setValue(true);
                    });
                    this.AnswerForm.markAsPristine();
                    break;
            }

            this.AnswerForm.markAsPristine();
            this.formDirtyChanged.emit();
            this.Saving = false;

            //notify parent to update section status
            this.questionConfirmed.emit();

        }, (error) => {
            this.Saving = false;
            let errorMessage = "";
            // NiceToHave: update ipsMessage.error to take in an exception parameter and do the code below for us.
            if (error) {
                if (error.data && error.data.Message) {
                    errorMessage = error.data.Message;
                } else if (error.error && error.error.Message) {
                    errorMessage = error.error.Message;
                } else if (error.statusText) {
                    errorMessage = error.statusText;
                } else {
                    errorMessage = error.Message;
                }
            }

            this.ipsMessage.error("Error saving answers. " + errorMessage);
        });
    }

    /** Determine if there are noChanges or no previous answers. does NOT check for validity of form,
     * you will have to do that elsewhere.
     */
    public ShouldDisableSave(): boolean {
        // Disable save if there are no changes and I have answered already. Need to leave open if I have never answered
        return !this.HasChanges() && !!this.question.MySurveyAnswer;
    }

    /** Call this when something has changed to determine new hasChanges value. */
    public HasChanges(): boolean {
        let hasChanges = false;
        switch (this.question.ProfileType) {
            case "LocationGroup":
                if (this.IsOneToOneRule()) {
                    hasChanges = this.SelectedSubGroupId.value !== this.OriginalSelectedSubGroupId;
                } else {
                    hasChanges = this.SubGroups.controls.some(sg => sg.value.Selected !== sg.value.OriginalSelected);
                }
                if (!hasChanges) {
                    hasChanges = this.NewSubGroups.length > 0;
                }
                break;
            case "FixtureGroup":
                if (this.question.NoFixture) {
                    hasChanges = this.question.NoFixture !== this.question.OriginalNoFixture;
                } else {
                    hasChanges = this.Fixtures.value.some(answer => answer.Confirmed == null || answer.Confirmed === false);
                }
                break;
        }

        return hasChanges;
    }

    public SelectedCount(): number {
        return this.question.ProfileType === "LocationGroup" ? this.SubGroups.controls.filter(sg => sg.value.Selected === true).length + this.NewSubGroups.length : 0;
    }

    private HasNASelected(): boolean {
        if (this.question.ProfileType !== "LocationGroup") {
            return false;
        }

        return this.SubGroups.controls.some(sg => sg.value.IsNA && sg.value.Selected );
    }

    /**
     * Add a new option for the user add their own answer
     * @param markAsDirty - when true, will mark form as dirty. False should only be used when first loading the form for TypeInAnswer scenario
     */
    public AddAnotherOption(markAsDirty = true) {
        const emptySubGroup = { Id: 0, Selected: true, Name: "", OriginalSelected: true } as LocationSubGroupUIModel;
        this.NewSubGroups.push(this.getNewSubGroupCtrl(emptySubGroup, true));
        if (this.IsOneToOneRule()) {
            this.SubGroups.controls.forEach(sg => {
                sg.value.Selected = false;
            });
            this.SelectedSubGroupId.patchValue(0);
        }

        if (markAsDirty) {
            this.AnswerForm.patchValue({ IsDirty: true });
            this.AnswerForm.markAsDirty();
            this.formDirtyChanged.emit();
        }
    }

    public RemoveNewOption(index: number) {
        this.NewSubGroups.removeAt(index);
        if (!this.HasChanges()) {
            this.AnswerForm.markAsPristine();
        }

        this.formDirtyChanged.emit();
        this.SubGroups.controls[0].get("Selected").updateValueAndValidity();
    }

    private subGrpRequired(subGrpId: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let v = this.AnswerForm.controls;
            if (!control || control.value === true || !this.SubGroups) {
                this.SubGroups.controls.forEach(ctrl => {
                    ctrl.get("Selected").setErrors(null);
                });
                return null;
            }
            const groups = this.SubGroups.value.filter(sg => sg.Id !== subGrpId);
            if (!groups.some(sg => sg.Selected === true) && this.NewSubGroups.controls.length === 0) {
                return {
                    subGroupRequired: {
                        valid: false
                    }
                };
            }
            return null;
        };
    }

    public BoxClicked(subGroupCtrl: FormGroup) {
        let selected = subGroupCtrl.get("Selected");

        const newVal = !selected.value;
        if (this.LocGroupIsOneToOne) {
            this.SelectedSubGroupId.setValue(subGroupCtrl.get("Id").value);
            this.SubGroups.controls.forEach(sg => {
                sg.patchValue({ Selected: false });
            });
            if (this.ShowAllOptions) {
                selected.setValue(true);
            } else {
                selected.setValue(newVal);
            }
        } else {
            const currentIsNA = subGroupCtrl.value.IsNA;
            const hasNASubGroup = this.HasNASubGroupValue;

            // If current item being selected IsNA, and other options are selected, confirm if they want to clear others.
            if (hasNASubGroup && currentIsNA && newVal && this.SelectedCount() > 0) {
                this.ipsMessage.confirm({ body: "CONFIRM_NOT_APPLICABLE_SELECTION", ok: "YES", cancel: "NO" })
                .then((result: any) => {
                    if (result) {
                        // unselect everything else.
                        this.SubGroups.controls.forEach(sg => {
                            sg.patchValue({ Selected: false });
                        });
                        selected.setValue(newVal);
                    }
                })
                .catch((canceled) => {
                    // rejection
                });
            }
            // NA is selected along with a new selection
            else if (hasNASubGroup && !currentIsNA && newVal && this.HasNASelected()) {
                this.ipsMessage.confirm({ body: "CONFIRM_NOT_APPLICABLE_SELECTION", ok: "YES", cancel: "NO" })
                .then((result: any) => {
                })
                .catch((canceled) => {
                    // unselect everything else.
                    this.SubGroups.controls.forEach(sg => {
                        sg.patchValue({ Selected: false });
                    });
                    selected.setValue(newVal);
                });
            }
            else {
                // Toggle check boxes on and off
                selected.setValue(newVal);
            }
        }

        if (!this.ShowAllOptions && this.SelectedCount() === 0) {
            this.AddAnotherOption();
        }
    }

    public FixtureCountClicked(fixtureCtrl: FormGroup) {
        let confirmed = fixtureCtrl.get("Confirmed");
        const newVal = !confirmed.value;
        confirmed.setValue(newVal);
        fixtureCtrl.markAsDirty();
        this.formDirtyChanged.emit();
    }

    public CheckFixtureClicked(noFixture: boolean) {
        if (noFixture) {
            this.confirmLabel = "CONFIRM";
            this.question.NoFixture = true;
        } else {
            this.confirmLabel = "CONFIRM_ALL";
            this.question.NoFixture = false;
        }
        if (this.question.NoFixture !== this.question.OriginalNoFixture) {
            this.AnswerForm.markAsDirty();
            this.formDirtyChanged.emit();
        } else {
            this.AnswerForm.markAsPristine();
            this.formDirtyChanged.emit();
        }
    }

    public resetQuantity(fixtureCtrl: FormGroup) {
        const fixture = fixtureCtrl.value;
        fixtureCtrl.patchValue({
            Quantity: fixture.OriginalQuantity,
            Confirmed: fixture.OriginalConfirmed
        });
        fixtureCtrl.markAsPristine();
        this.formDirtyChanged.emit();
    }

    public messageAdminClick() {
        this.messageOpen = !this.messageOpen;
    }

    public messageRemovedHandler() {
        this.question.MySurveyMessage = null;
        this.messageOpen = false;
    }

    // Used by ForLoop for tracking item. doesn't have to remove element from DOM
    // and re-add if the tracked value doesn't change.
    public trackControlById(index, item) {
        return item.value.Id;
    }
}
