/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sticky-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../directives/text-overflow.directive";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/imagine-ui-ng-quick-start/imagine-ui-ng-quick-start.ngfactory";
import * as i6 from "imagine-ui-ng-quick-start";
import * as i7 from "@uirouter/angular";
import * as i8 from "./sticky-header.component";
import * as i9 from "@ngx-translate/core";
var styles_StickyHeaderComponent = [i0.styles];
var RenderType_StickyHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StickyHeaderComponent, data: {} });
export { RenderType_StickyHeaderComponent as RenderType_StickyHeaderComponent };
function View_StickyHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "icon mr-2 ml-2 ", _v.parent.context.$implicit.imageClass, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.text; _ck(_v, 3, 0, currVal_1); }); }
function View_StickyHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex flex-row clip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["appTextOverflow", ""], ["class", "flex-grow-1 clip mx-2"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 4210688, null, 0, i2.TextOverflowDirective, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 2, "button", [["class", "text-left button-no-style tooltip-container clicker"], ["placement", "bottom-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 212992, [["t", 4]], 0, i3.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i3.NgbTooltipConfig, i1.NgZone, i4.DOCUMENT], { placement: [0, "placement"], ngbTooltip: [1, "ngbTooltip"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-ellipsis-h fa-2x"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = "bottom-right"; var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.text, ""); _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.text; _ck(_v, 4, 0, currVal_0); }); }
function View_StickyHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyHeaderComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyHeaderComponent_5)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.imageClass; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.imageClass; _ck(_v, 4, 0, currVal_1); }, null); }
function View_StickyHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyHeaderComponent_3)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StickyHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StickyHeaderComponent_2)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stickyHeaderItems; _ck(_v, 1, 0, currVal_0); }, null); }
function View_StickyHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_StickyHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "fixed-top ips-sticky-header container ips-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "card ips-shadow ips-info-header px-2 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "card-body pt-0 px-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ips-breadcrumb", [["prefixStateName", "main.home"]], null, null, null, i5.View_IpsBreadcrumbComponent_0, i5.RenderType_IpsBreadcrumbComponent)), i1.ɵdid(4, 114688, null, 0, i6.IpsBreadcrumbComponent, [i7.UIRouter, i7.StateService], { prefixStateName: [0, "prefixStateName"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["defaultStickyItem", 2]], null, 0, null, View_StickyHeaderComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StickyHeaderComponent_6)), i1.ɵdid(8, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(9, { stickyHeaderItems: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "main.home"; _ck(_v, 4, 0, currVal_1); var currVal_2 = _ck(_v, 9, 0, _co.stickyHeaderItems); var currVal_3 = (_co.stickyItemTemplate ? _co.stickyItemTemplate : i1.ɵnov(_v, 6)); _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showHeader; _ck(_v, 0, 0, currVal_0); }); }
export function View_StickyHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-sticky-header", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_StickyHeaderComponent_0, RenderType_StickyHeaderComponent)), i1.ɵdid(1, 114688, null, 1, i8.StickyHeaderComponent, [i9.TranslateService], null, null), i1.ɵqud(335544320, 1, { stickyItemTemplate: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StickyHeaderComponentNgFactory = i1.ɵccf("app-sticky-header", i8.StickyHeaderComponent, View_StickyHeaderComponent_Host_0, { offset: "offset" }, {}, []);
export { StickyHeaderComponentNgFactory as StickyHeaderComponentNgFactory };
