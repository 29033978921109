import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Observable, Observer, from } from "rxjs";
import { distinctUntilChanged, debounceTime, mergeMap, timeout } from "rxjs/operators";

import { ActiveProfileService, RoleModel, SearchInfo, SearchResponse } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { StateService } from "@uirouter/core";
import { IpsModalService } from "imagine-ui-ng-modal";

import { ShipMethodPreferenceModel, ShipMethodRule } from "../../model/ShipMethodModels";
import { ShopSettingModel, PONumberSettingsModel } from "../../model/ShopSettingModel";
import { ShipMethodPreferenceService } from "../../service/ship-method-preference.service";
import { ShopSettingsService } from "../../service/shop-settings.service";
import { PaymentSettingsService } from "../../service/payment-settings.service";
import { LocationGroupService } from "../../../imagine-ui-ng-store-profile/index";

import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { PaymentMethodSettingsViewModel } from "../../model/PaymentMethodSettingModels";
import { DecimalPipe } from "@angular/common";
import { AlertAssignmentModel } from "../../model/AlertAssignmentModel";
import { AlertModel } from "../../model/AlertModel";
import { AlertAssignmentService } from "../../service/alert-assignment.service";
import { TranslateService } from "@ngx-translate/core";
import { RoleService } from "imagine-ui-ng-quick-start";

interface HandlingSettingsUiModel {
    PerAddress: ShopSettingModel;
    PerLine: ShopSettingModel;
    PerEach: ShopSettingModel;
}

class ShopSettingsEditModel {
    public PONumberSetting: PONumberSettingsModel;
    private originalPONumberJson: string;

    public OrderReasonSetting: ShopSettingModel;
    private originalOrderReasonJson: string;

    public CategoryFilterSetting: ShopSettingModel;
    private originalCategoryFilterJson: string;

    public CategoryPermissionSetting: ShopSettingModel;
    private originalCategoryPermissionJson: string;

    public ShowOrderReasonSetting: ShopSettingModel;
    private originalShowOrderReasonJson: string;

    public ShowReferenceNumberSetting: ShopSettingModel;
    private originalShowReferenceNumberJson: string;

    public ShowNeedByDateSetting: ShopSettingModel;
    private originalShowNeedByDateJson: string;

    public CheckoutLabelSetting: ShopSettingModel;
    private originalCheckoutLabelJson: string;

    public CommentsSetting: ShopSettingModel;
    private originalCommentsJson: string;

    public ShipMethodSetting: ShopSettingModel;

    public ShipMethodPreferences: ShipMethodRule[];
    private originalPreferencesJson: string;

    public PaymentMethodSettings: PaymentMethodSettingsViewModel;
    private originalPaymentMethodSettingsJson: string;

    public HandlingFeeSettings: HandlingSettingsUiModel;
    private originalHanldingFeesJson: string;

    public AlertSettings: AlertSettingsUiModel;
    private originalAlertSettingsJson: string;

    public AllLocationsFilterSetting: ShopSettingModel;
    private originalAllLocationsFilterSettingJson: string;


    public HideCampaignFilterSetting: ShopSettingModel;
    private originalHideCampaignFilterSettingJson: string;

    public DeliverySelectionSetting: ShopSettingModel;
    private originalDeliverySelectionSettingJson: string;

    constructor(showOrderReasonSetting: ShopSettingModel,
        shipMethodSetting: ShopSettingModel,
        shipMethodPreferences: ShipMethodRule[],
        paymentMethodSettings: PaymentMethodSettingsViewModel,
        handlingFeeSettings: HandlingSettingsUiModel,
        alertSettings: AlertSettingsUiModel,
        poNumberSetting: PONumberSettingsModel,
        orderReasonSetting: ShopSettingModel,
        referenceNumberSetting: ShopSettingModel,
        needByDateSetting: ShopSettingModel,
        checkoutLabelSetting: ShopSettingModel,
        commentsSetting: ShopSettingModel,
        categoryFilterSetting: ShopSettingModel,
        categoryPermissionSetting: ShopSettingModel,
        allLocationsFilterSetting: ShopSettingModel,
        hideCampaignFilterSetting: ShopSettingModel,
        deliverySelectionSetting: ShopSettingModel
    ) {
        this.ShowOrderReasonSetting = showOrderReasonSetting;
        this.originalShowOrderReasonJson = JSON.stringify(showOrderReasonSetting);

        this.PONumberSetting = poNumberSetting;
        this.originalPONumberJson = JSON.stringify(poNumberSetting);

        this.OrderReasonSetting = orderReasonSetting;
        this.originalOrderReasonJson = JSON.stringify(orderReasonSetting);

        this.ShipMethodSetting = shipMethodSetting;
        //Copy the data into the object to maintain object integrity allowing for use of its functions
        //this.ShipMethodPreferences = Object.assign([], shipMethodPreferences);
        this.ShipMethodPreferences = shipMethodPreferences.map(rule => {
            let shipPref = new ShipMethodRule();
            shipPref.RoleId = rule.RoleId;
            shipPref.AllLocations = rule.AllLocations;
            shipPref.LocationGroupId = rule.LocationGroupId;
            shipPref.LocationGroupName = rule.LocationGroupName;
            shipPref.SubGroupRules = rule.SubGroupRules;
            shipPref.PendingLocations = rule.PendingLocations;
            shipPref.ShipMethodIds = rule.ShipMethodIds ? rule.ShipMethodIds : [];
            return shipPref;
        });
        this.originalPreferencesJson = JSON.stringify(shipMethodPreferences);

        this.PaymentMethodSettings = paymentMethodSettings;
        this.originalPaymentMethodSettingsJson = JSON.stringify(paymentMethodSettings);

        this.HandlingFeeSettings = handlingFeeSettings;
        this.originalHanldingFeesJson = JSON.stringify(handlingFeeSettings);

        this.AlertSettings = alertSettings;
        this.originalAlertSettingsJson = JSON.stringify(alertSettings);

        this.CheckoutLabelSetting = checkoutLabelSetting;
        this.originalCheckoutLabelJson = JSON.stringify(checkoutLabelSetting);

        this.ShowNeedByDateSetting = needByDateSetting;
        this.originalShowNeedByDateJson = JSON.stringify(needByDateSetting);

        this.ShowReferenceNumberSetting = referenceNumberSetting;
        this.originalShowReferenceNumberJson = JSON.stringify(referenceNumberSetting);

        this.CommentsSetting = commentsSetting;
        this.originalCommentsJson = JSON.stringify(commentsSetting);

        this.CategoryFilterSetting = categoryFilterSetting;
        this.originalCategoryFilterJson = JSON.stringify(categoryFilterSetting);

        this.CategoryPermissionSetting = categoryPermissionSetting;
        this.originalCategoryPermissionJson = JSON.stringify(categoryPermissionSetting);

        this.AllLocationsFilterSetting = allLocationsFilterSetting;
        this.originalAllLocationsFilterSettingJson = JSON.stringify(allLocationsFilterSetting);

        this.HideCampaignFilterSetting = hideCampaignFilterSetting;
        this.originalHideCampaignFilterSettingJson = JSON.stringify(hideCampaignFilterSetting);

        this.DeliverySelectionSetting = deliverySelectionSetting;
        this.originalDeliverySelectionSettingJson = JSON.stringify(deliverySelectionSetting);
    }

    public get ShippingPreferencesDirty(): boolean {
        return this.isDirty(this.ShipMethodPreferences, this.originalPreferencesJson);
    }

    public get ShopSettingsDirty(): boolean {
        return this.isDirty(this.ShowOrderReasonSetting, this.originalShowOrderReasonJson) ||
            this.isDirty(this.PONumberSetting, this.originalPONumberJson) ||
            this.isDirty(this.OrderReasonSetting, this.originalOrderReasonJson) ||
            this.isDirty(this.CheckoutLabelSetting, this.originalCheckoutLabelJson) ||
            this.isDirty(this.ShowReferenceNumberSetting, this.originalShowReferenceNumberJson) ||
            this.isDirty(this.ShowNeedByDateSetting, this.originalShowNeedByDateJson) ||
            this.isDirty(this.CommentsSetting, this.originalCommentsJson) ||
            this.isDirty(this.CategoryFilterSetting, this.originalCategoryFilterJson) ||
            this.isDirty(this.CategoryPermissionSetting, this.originalCategoryPermissionJson) ||
            this.isDirty(this.AllLocationsFilterSetting, this.originalAllLocationsFilterSettingJson) ||
            this.isDirty(this.HideCampaignFilterSetting, this.originalHideCampaignFilterSettingJson) ||
            this.isDirty(this.DeliverySelectionSetting, this.originalDeliverySelectionSettingJson) ||
            this.ShippingPreferencesDirty;
    }

    public get PaymentMethodSettingsDirty(): boolean {
        return this.isDirty(this.PaymentMethodSettings, this.originalPaymentMethodSettingsJson);
    }

    public get HanldingFeeSettingsDirty(): boolean {
        return this.isDirty(this.HandlingFeeSettings, this.originalHanldingFeesJson);
    }

    public get AlertSettingsDirty(): boolean {
        return this.isDirty(this.AlertSettings, this.originalAlertSettingsJson);
    }

    private isDirty(object: any, originalJson: string) {
        const currentJson = JSON.stringify(object);
        const settingsDirty = originalJson !== currentJson;

        return settingsDirty;
    }
}

interface AlertAssignmentUiModel extends AlertAssignmentModel {
    AlertName: string;
    Selected: boolean;
}

interface UserAlertAssignmentUiModel {
    Email: string;
    Assignments: AlertAssignmentUiModel[];
    HtmlElementName: string;
}

class AlertSettingsUiModel {
    public UserAlertAssignments: UserAlertAssignmentUiModel[] = [];
    private elementIndexer = 1;

    constructor(alertAssignments: AlertAssignmentModel[], alerts: AlertModel[]) {
        this.UserAlertAssignments = alertAssignments.reduce((accumulator: UserAlertAssignmentUiModel[], current: AlertAssignmentUiModel) => {
            let existing = accumulator.find((val) => val.Email === current.Email);
            //const alert = alerts.find((a) => a.Id === current.AlertId);
            //current.AlertName = alert.DisplayName;
            //current.Selected = true; // The fact that we have this record means its selected. Record will be deleted if its unselected

            if (!existing) {
                existing = this.generateNewUserAlertSetting(alerts, current.Email);
                accumulator.push(existing);
            }
            // Find the assignment and set selected and id
            let alertAssign = existing.Assignments.find((a) => a.AlertId === current.AlertId);
            alertAssign.Selected = true;
            alertAssign.Id = current.Id;

            return accumulator;
        }, []);
    }

    public generateNewUserAlertSetting(alerts: AlertModel[], email: string): UserAlertAssignmentUiModel {
        const defaultAssignents = alerts.map<AlertAssignmentUiModel>((a) => ({ AlertId: a.Id, AlertName: a.DisplayName, Email: email, Id: 0, Selected: false, BusinessIdentity: "" }));
        return <UserAlertAssignmentUiModel>{ Email: email, Assignments: defaultAssignents, HtmlElementName: "userAlert" + this.elementIndexer++ };
    }
}

@Component({
    selector: "app-ship-method-edit",
    templateUrl: "./ship-method-edit.component.html",
    styleUrls: ["./ship-method-edit.component.scss"]
})
export class ShipMethodEditComponent implements OnInit {
    @ViewChild("settingsForm") settingsForm: NgForm;

    public settingsModel: ShopSettingsEditModel;
    private originalModelJson: string;

    public currentBrandName: string;
    private currentBusinessId: string;

    private selectedLocationGroup: any;
    private selectedLocationGroupName = "";
    public locationGroupSource: Observable<any>;
    public locationShippingGroupSources: Observable<any>[];

    public loadingSubGroups = false;
    public loadingShippingSubGroups: boolean[] = [];

    public canAccessPaymentSettings = false;
    public canAccessGeneralSettings = false;
    public canAccessHandlingSettings = false;
    public canAccessAlertSettings = false;

    public screenReady = false;

    private alerts: AlertModel[] = [];

    private allRoles: RoleModel[] = [];
    private allShipMethods: any[];

    public get PaymentMethodSettings(): PaymentMethodSettingsViewModel {
        return this.settingsModel.PaymentMethodSettings;
    }

    public get HandlingFeeSettings(): HandlingSettingsUiModel {
        return this.settingsModel.HandlingFeeSettings;
    }

    public get AlertSettings(): AlertSettingsUiModel {
        return this.settingsModel.AlertSettings;
    }

    public get formDirty(): boolean {
        return this.settingsForm.form.dirty;
    }

    public get formPristine(): boolean {
        return this.settingsForm.form.pristine;
    }

    public duplicatedValueErrorMessage = "DUPLICATED_VALUE";
    public invalidEmailMessage = "INVALID_EMAIL";
    public requiredMessage = "REQUIRED";

    public alertErrorMessages = {
        "required": () => this.requiredMessage,
        "email": () => this.invalidEmailMessage,
        "appDuplicateValidator": () => this.duplicatedValueErrorMessage
    };

    constructor(private activeProfileService: ActiveProfileService,
        private ipsMessage: IpsMessageService,
        private ipsModal: IpsModalService,
        private shipMethodService: ShipMethodPreferenceService,
        private stateService: StateService,
        private settingsService: ShopSettingsService,
        private paymentMethodSettingService: PaymentSettingsService,
        private locationGroupService: LocationGroupService,
        private decimalPipe: DecimalPipe,
        private alertAssignmentService: AlertAssignmentService,
        private translateService: TranslateService,
        private roleService: RoleService) {

        this.currentBusinessId = activeProfileService.businessIdentity;
        this.currentBrandName = activeProfileService.theme.Name;
        this.locationGroupSource = this.getLocationGroupSource();
        this.locationShippingGroupSources = [];
    }

    ngOnInit() {
        const promises: Promise<any>[] = [];
        let shipMethodPreferences: any[] = [];
        let shipMethodSettings: any = null;
        let showOrderReasonSetting: any = null;
        let orderReasonSetting: any = null;
        let poNumberSetting: any = null;
        let paymentMethodSettings: any = null;
        let hanldingFeeSettings: HandlingSettingsUiModel = null;
        let alertAssignments: AlertAssignmentModel[] = null;
        let alertSettings: AlertSettingsUiModel = null;
        let checkoutLabelSetting: ShopSettingModel = null;
        let needByDateSetting: ShopSettingModel = null;
        let referenceNumberSetting: ShopSettingModel = null;
        let commentsSetting: ShopSettingModel = null;
        let categoryFilterSetting: ShopSettingModel = null;
        let categoryPermissionSetting: ShopSettingModel = null;
        let allLocationsFilterSetting: ShopSettingModel = null;
        let hideCampaignFilterSetting: ShopSettingModel = null;
        let deliverySelectionSetting: ShopSettingModel = null;


        const adminRoles = ["EcommSiteAdmin", "EFinAdmin", "CustomDataAdmin"];
        const financeAdminRoles = ["EFinAdmin"];
        this.canAccessPaymentSettings = this.isInRole(adminRoles);
        this.canAccessGeneralSettings = !this.isInRole(financeAdminRoles);
        this.canAccessHandlingSettings = this.hasPermission("EditHandlingFeesSetting");
        this.canAccessAlertSettings = this.hasPermission("EditAlertAssignment");

        if (this.canAccessGeneralSettings) {
            promises.push(this.settingsService.getSettingByName("ShipMethodPreferences").then((data: ShopSettingModel) => {
                console.log("got preferences: ", data);
                if (data) {
                    shipMethodPreferences = JSON.parse(data.Value);
                    console.log("Ship method preferences", shipMethodPreferences);

                    shipMethodPreferences.forEach(p => {
                        //p.AllLocations = !p.LocationGroupId;
                        this.locationShippingGroupSources.push(this.getLocationShippingGroupSources(p.LocationGroupName));
                        this.loadingShippingSubGroups.push(false);
                    });

                    shipMethodSettings = data;
                }
            }));

            promises.push(this.roleService.getByApplication("Ecomm").then((response: any) => {
                console.log("got all roles: ", response);
                this.allRoles = response;
            }));

            promises.push(this.shipMethodService.getShipMethods().then((response: any) => {
                console.log("got all ship methods: ", response);
                this.allShipMethods = response;
            }));
        }

        if (this.canAccessGeneralSettings) {
            promises.push(this.settingsService.getSettingByName(this.settingsService.ShowOrderReasonSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    showOrderReasonSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.CategoryFilterSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    categoryFilterSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.CategoryPermissionSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    categoryPermissionSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.OrderReasonSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    orderReasonSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.CheckoutLabelSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    checkoutLabelSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.ReferenceNumberSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    referenceNumberSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.NeedByDateSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    needByDateSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.CommentsSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    commentsSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.PONumberSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    poNumberSetting = <PONumberSettingsModel>setting;
                    const parsedObj = JSON.parse(setting.Value);

                    poNumberSetting.EnableList = parsedObj.EnableList;
                    poNumberSetting.Options = parsedObj.Options;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.AllLocationsSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    allLocationsFilterSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.HideCampaignSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    hideCampaignFilterSetting = setting;
                }
            }));

            promises.push(this.settingsService.getSettingByName(this.settingsService.DeliverySelectionSettingName).then((setting: ShopSettingModel) => {
                if (setting) {
                    deliverySelectionSetting = setting;
                }
            }));

        }

        if (this.canAccessPaymentSettings) {
            promises.push(this.paymentMethodSettingService.loadData().then((settings: PaymentMethodSettingsViewModel) => {
                if (settings) {
                    paymentMethodSettings = settings;

                    if (settings.ShopperSettings.LocationGroupName) {
                        this.selectedLocationGroupName = settings.ShopperSettings.LocationGroupName;
                    }
                }
            }));
        }

        if (this.canAccessHandlingSettings) {
            promises.push(this.settingsService.GetRestrictedSettings("HandlingFees").then((settings: ShopSettingModel[]) => {
                if (!settings) {
                    settings = [];
                }

                const perAddress = settings.find(s => s.Name === this.settingsService.HandlingFeePerAddressSettingName) ||
                    this.getDefaultHandlingSettingModel(this.settingsService.HandlingFeePerAddressSettingName);

                const perLine = settings.find(s => s.Name === this.settingsService.HandlingFeePerLineItemSettingName) ||
                    this.getDefaultHandlingSettingModel(this.settingsService.HandlingFeePerLineItemSettingName);

                const perEach = settings.find(s => s.Name === this.settingsService.HandlingFeePerEachSettingName) ||
                    this.getDefaultHandlingSettingModel(this.settingsService.HandlingFeePerEachSettingName);

                hanldingFeeSettings = { PerAddress: perAddress, PerEach: perEach, PerLine: perLine };
            }));
        }

        if (this.canAccessAlertSettings) {
            promises.push(this.alertAssignmentService.getAlerts().then((al: AlertModel[]) => {
                this.alerts = al;
            }));
            promises.push(this.alertAssignmentService.getList<AlertAssignmentModel[]>().then((as) => {
                alertAssignments = as;
            }));
        }

        Promise.all(promises).then(() => {
            if (this.canAccessAlertSettings) {
                alertSettings = new AlertSettingsUiModel(alertAssignments, this.alerts);
            }

            this.settingsModel = new ShopSettingsEditModel(showOrderReasonSetting, shipMethodSettings, shipMethodPreferences, paymentMethodSettings,
                hanldingFeeSettings, alertSettings, poNumberSetting, orderReasonSetting,
                referenceNumberSetting, needByDateSetting, checkoutLabelSetting, commentsSetting, categoryFilterSetting, categoryPermissionSetting, allLocationsFilterSetting,
                hideCampaignFilterSetting, deliverySelectionSetting);

            if (this.canAccessGeneralSettings) {
                if (!this.settingsModel.ShipMethodSetting) {
                    this.settingsModel.ShipMethodSetting = this.getDefaultShopSettingModel(this.settingsService.ShipMethodPreferencesSettingName, "");
                }

                if (!this.settingsModel.OrderReasonSetting) {
                    this.settingsModel.OrderReasonSetting = this.getDefaultShopSettingModel(this.settingsService.OrderReasonSettingName, "");
                }

                if (!this.settingsModel.ShowOrderReasonSetting) {
                    this.settingsModel.ShowOrderReasonSetting = this.getDefaultShopSettingModel(this.settingsService.ShowOrderReasonSettingName, "true");
                }

                if (!this.settingsModel.CategoryFilterSetting) {
                    this.settingsModel.CategoryFilterSetting = this.getDefaultShopSettingModel(this.settingsService.CategoryFilterSettingName, "false");
                }

                if (!this.settingsModel.CategoryPermissionSetting) {
                    this.settingsModel.CategoryPermissionSetting = this.getDefaultShopSettingModel(this.settingsService.CategoryPermissionSettingName, "true");
                }

                if (!this.settingsModel.PONumberSetting) {
                    this.settingsModel.PONumberSetting = <PONumberSettingsModel>this.getDefaultShopSettingModel(this.settingsService.PONumberSettingName, null);
                    this.settingsModel.PONumberSetting.EnableList = false;
                    this.settingsModel.PONumberSetting.Options = "";
                }

                if (!this.settingsModel.CheckoutLabelSetting) {
                    this.settingsModel.CheckoutLabelSetting = this.getDefaultShopSettingModel(this.settingsService.CheckoutLabelSettingName, "Checkout by Invoice");
                }

                if (!this.settingsModel.ShowReferenceNumberSetting) {
                    this.settingsModel.ShowReferenceNumberSetting = this.getDefaultShopSettingModel(this.settingsService.ReferenceNumberSettingName, "true");
                }

                if (!this.settingsModel.ShowNeedByDateSetting) {
                    this.settingsModel.ShowNeedByDateSetting = this.getDefaultShopSettingModel(this.settingsService.NeedByDateSettingName, "false");
                }

                if (!this.settingsModel.CommentsSetting) {
                    this.settingsModel.CommentsSetting = this.getDefaultShopSettingModel(this.settingsService.CommentsSettingName, "false");
                }

                if (!this.settingsModel.AllLocationsFilterSetting) {
                    this.settingsModel.AllLocationsFilterSetting = this.getDefaultShopSettingModel(this.settingsService.AllLocationsSettingName, "false");
                }

                if (!this.settingsModel.HideCampaignFilterSetting) {
                    this.settingsModel.HideCampaignFilterSetting = this.getDefaultShopSettingModel(this.settingsService.HideCampaignSettingName, "false");
                }

                if (!this.settingsModel.DeliverySelectionSetting) {
                    this.settingsModel.DeliverySelectionSetting = this.getDefaultShopSettingModel(this.settingsService.DeliverySelectionSettingName, "true");
                }
            }

            this.originalModelJson = JSON.stringify(this.settingsModel);
            this.screenReady = true;
        });

        this.translateService.get("ALL").subscribe(() => this.translateText());
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    public shipMethodName(id: number): string {
        let returnValue = null;
        const matchingMethod = this.allShipMethods.find(x => x.Id === id);

        if (matchingMethod) {
            returnValue = matchingMethod.DisplayName;
        }

        return returnValue;
    }

    private translateText() {
        this.requiredMessage = this.translateService.instant(this.requiredMessage);
        this.invalidEmailMessage = this.translateService.instant(this.invalidEmailMessage);
        this.duplicatedValueErrorMessage = this.translateService.instant(this.duplicatedValueErrorMessage);
    }

    private getDefaultShopSettingModel(name: string, value: string): ShopSettingModel {
        const defaultShopSettingModel: ShopSettingModel = {
            Id: 0,
            Name: name,
            Value: value,
            BusinessIdentity: this.currentBusinessId
        };

        return defaultShopSettingModel;
    }

    private getDefaultHandlingSettingModel(name: string): ShopSettingModel {
        const defaultShopSettingModel: ShopSettingModel = {
            Id: 0,
            Name: name,
            Value: "0",
            BusinessIdentity: this.currentBusinessId
        };

        return defaultShopSettingModel;
    }

    public get isDirty(): boolean {
        const currentSettingsJson = JSON.stringify(this.settingsModel);
        const settingsDirty = this.originalModelJson !== currentSettingsJson;
        if (!settingsDirty) {
            this.settingsForm.form.markAsPristine();
        }
        return settingsDirty;
    }

    public setMinDecimals($event: any) {
        $event.target.value = this.decimalPipe.transform($event.target.value, "1.2-10");
    }

    public setPONumberOptions(value: string) {
        if (this.settingsModel.ShowOrderReasonSetting.Value !== value) {
            this.settingsModel.ShowOrderReasonSetting.Value = value;
        }

        if (this.isDirty) {
            this.settingsForm.form.markAsDirty();
        } else {
            this.settingsForm.form.markAsPristine();
        }
    }

    public setShowOrderReason(value: string) {
        if (this.settingsModel.ShowOrderReasonSetting.Value !== value) {
            this.settingsModel.ShowOrderReasonSetting.Value = value;
        }

        if (this.isDirty) {
            this.settingsForm.form.markAsDirty();
        } else {
            this.settingsForm.form.markAsPristine();
        }
    }

    public setAdminOnly(value: boolean, preference: ShipMethodPreferenceModel) {
        preference.SetAdminOnly(value);

        if (this.isDirty) {
            this.settingsForm.form.markAsDirty();
        } else {
            this.settingsForm.form.markAsPristine();
        }
    }

    private saveData(): Promise<any> {
        // Note:  this transaction is NOT atomic and
        // there is currently no mechanism to roll back if one of the calls fails
        const promises: Promise<any>[] = [];

        if (this.settingsModel.ShopSettingsDirty) {
            if (this.settingsModel.ShowOrderReasonSetting || this.settingsModel.ShipMethodPreferences) {
                promises.push(this.saveShopSettings());
            }

            if (this.settingsModel.PONumberSetting) {
                promises.push(this.savePONumberSettings());
            }
        }

        //if (this.settingsModel.ShipMethodPreferences) {
        //    promises.push(this.savePreferences());
        //}

        if (this.settingsModel.PaymentMethodSettings && this.settingsModel.PaymentMethodSettingsDirty) {
            promises.push(this.paymentMethodSettingService.saveData(this.settingsModel.PaymentMethodSettings));
        }

        if (this.settingsModel.HandlingFeeSettings && this.settingsModel.HanldingFeeSettingsDirty) {
            promises.push(this.saveHandlingFees());
        }

        if (this.settingsModel.AlertSettings && this.settingsModel.AlertSettingsDirty) {
            promises.push(this.saveAlertSettings());
        }

        return Promise.all(promises).then(() => {
            this.settingsForm.form.markAsPristine();
        });
    }

    public saveShopSettings(): Promise<any> {
        const showOrderReasonSetting = this.settingsModel.ShowOrderReasonSetting;
        const orderReasonSetting = this.settingsModel.OrderReasonSetting;
        const checkoutLabelSetting = this.settingsModel.CheckoutLabelSetting;
        const referenceNumberSetting = this.settingsModel.ShowReferenceNumberSetting;
        const needByDateSetting = this.settingsModel.ShowNeedByDateSetting;
        const commentsSetting = this.settingsModel.CommentsSetting;
        const categoryFilterSetting = this.settingsModel.CategoryFilterSetting;
        const categoryPermissionSetting = this.settingsModel.CategoryPermissionSetting;
        const allLocationsFilterSetting = this.settingsModel.AllLocationsFilterSetting;
        const hideCampaignFilterSetting = this.settingsModel.HideCampaignFilterSetting;
        const deliverySelectionSetting = this.settingsModel.DeliverySelectionSetting;
        const shipMethodPreferencesSetting = this.settingsModel.ShipMethodSetting;
        shipMethodPreferencesSetting.Value = JSON.stringify(this.settingsModel.ShipMethodPreferences);
        //tslint:disable-next-line: max-line-length
        return this.settingsService.put([showOrderReasonSetting, orderReasonSetting, checkoutLabelSetting, referenceNumberSetting, needByDateSetting, commentsSetting, categoryFilterSetting, categoryPermissionSetting, allLocationsFilterSetting, hideCampaignFilterSetting, shipMethodPreferencesSetting, deliverySelectionSetting]);
    }

    public savePONumberSettings(): Promise<any> {
        const setting = this.settingsModel.PONumberSetting;

        setting.Value = JSON.stringify({
            EnableList: setting.EnableList,
            Options: setting.Options
        });

        return this.settingsService.put([setting]);
    }

    public chooseAllLocationsShippingMethods(shipMethodPrefIndex) {
        let shipMethodIds = this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].ShipMethodIds ? this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].ShipMethodIds : [];
        const resolveData = {
            addAll: false,
            search: "shipmethod",
            multiSelection: true,
            selectedIds: shipMethodIds
        };

        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: resolveData })
            .then((response: any) => {
                /*const currentIds = resolveData.selectedIds;
                const itemsToAdd = response.map((item) => {
                    const returnModel = new ShipMethodPreferenceModel(this.currentBusinessId, item);
                    return returnModel;
                }).filter((item) => {
                    return currentIds.indexOf(item.ShipMethodId) < 0;
                });
                const idsToAdd = itemsToAdd.map((item) => {
                    return item.ShipMethodId;
                });
                if (idsToAdd.length) {
                    this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].ShipMethodIds.splice(0, 0, ...idsToAdd);
                    this.settingsForm.form.markAsDirty();
                }*/
                //rather than add in, now just copy in newly selected items to complete any add/remove
                const newSelectedItems = response.map((item) => {
                    const returnModel = new ShipMethodPreferenceModel(this.currentBusinessId, item);
                    return returnModel;
                });
                const newSelectedIds = newSelectedItems.map((item) => {
                    return item.ShipMethodId;
                });

                if (newSelectedIds) {
                    this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].ShipMethodIds = newSelectedIds;
                    this.settingsForm.form.markAsDirty();
                }
            },
            // Rejected
            () => {
                console.log("rejected");
            });

    }

    public chooseShippingMethods(shipMethodPrefIndex, subGroupIndex) {
        let shipMethodIds = this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].ShipMethodIds ? this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].ShipMethodIds : [];
        const resolveData = {
            addAll: false,
            search: "shipmethod",
            multiSelection: true,
            selectedIds: shipMethodIds //this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].Preferences.map((item) => item.ShipMethodId) //[]
        };

        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: resolveData })
            .then((response: any) => {
                /*const currentIds = resolveData.selectedIds;
                const itemsToAdd = response.map((item) => {
                    const returnModel = new ShipMethodPreferenceModel(this.currentBusinessId, item);
                    return returnModel;
                }).filter((item) => {
                    return currentIds.indexOf(item.ShipMethodId) < 0;
                });
                const idsToAdd = itemsToAdd.map((item) => {
                    return item.ShipMethodId;
                });
                if (itemsToAdd.length) {
                    this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].Preferences.splice(0, 0, ...itemsToAdd);
                    this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].ShipMethodIds.splice(0, 0, ...idsToAdd);
                    this.settingsForm.form.markAsDirty();
                }*/
                //rather than add in, now just copy in newly selected items to complete any add/remove
                const newSelectedItems = response.map((item) => {
                    const returnModel = new ShipMethodPreferenceModel(this.currentBusinessId, item);
                    return returnModel;
                });
                const newSelectedIds = newSelectedItems.map((item) => {
                    return item.ShipMethodId;
                });

                if (newSelectedIds) {
                    this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].Preferences = newSelectedItems;
                    this.settingsModel.ShipMethodPreferences[shipMethodPrefIndex].SubGroupRules[subGroupIndex].ShipMethodIds = newSelectedIds;
                    this.settingsForm.form.markAsDirty();
                }
            },
                // Rejected
                () => {
                    // remove the first item, which is All
                    console.log("rejected");
                });
    }

    public addAlertEmail() {
        const newUser = this.AlertSettings.generateNewUserAlertSetting(this.alerts, undefined);
        this.AlertSettings.UserAlertAssignments.push(newUser);
    }

    public deleteAlertEmail(user: UserAlertAssignmentUiModel) {
        const userIndex = this.AlertSettings.UserAlertAssignments.indexOf(user);
        if (userIndex > -1) {
            this.AlertSettings.UserAlertAssignments.splice(userIndex, 1);
            this.settingsForm.form.markAsDirty();
        }
    }

    private savePreferences(): Promise<any> {
        const shipMethodPreferences = this.settingsModel.ShipMethodPreferences;
        return this.settingsService.put([shipMethodPreferences]);
    }

    private saveHandlingFees(): Promise<any> {
        let settings = [];
        settings.push(this.settingsModel.HandlingFeeSettings.PerAddress);
        settings.push(this.settingsModel.HandlingFeeSettings.PerLine);
        settings.push(this.settingsModel.HandlingFeeSettings.PerEach);

        return this.settingsService.PutRestrictedSettings(settings, "HandlingFees");
    }

    private saveAlertSettings(): Promise<any> {
        let settings = [];
        this.AlertSettings.UserAlertAssignments.forEach((user) => {
            user.Assignments.forEach((assign) => {
                if (assign.Selected) {
                    assign.Email = user.Email;
                    assign.BusinessIdentity = this.currentBusinessId;
                    settings.push(assign);
                }
            });
        });

        return this.alertAssignmentService.saveAlertAssignments(settings);
    }

    public savePreferencesPrompt(redirect: boolean) {
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveData(), progressMessage: "SAVING" })
            .then((result) => {
                if (result) {
                    if (redirect) {
                        this.stateService.go("main.toolsControls");
                    } else {
                        this.stateService.go("main.shipmethods", {}, { reload: true });
                    }
                }
            });
    }

    public deletePreference(preference: ShipMethodRule) {
        const preferenceIndex = this.settingsModel.ShipMethodPreferences.indexOf(preference);
        this.settingsModel.ShipMethodPreferences.splice(preferenceIndex, 1);
        this.settingsForm.form.markAsDirty();
    }

    public toggleCreditCard(object: any) {
        if ((<any>event.srcElement).tagName === "INPUT") {
            return;
        }

        if (object.CreditCardEnabled !== undefined) {
            object.CreditCardEnabled = !object.CreditCardEnabled;
        }
    }

    public toggleInvoice(object: any) {
        if ((<any>event.srcElement).tagName === "INPUT") {
            return;
        }

        if (object.InvoiceEnabled !== undefined) {
            object.InvoiceEnabled = !object.InvoiceEnabled;
        }
    }

    public setAllLocations(object: any, val: boolean) {
        object.AllLocations = val;
    }

    public get paymentMethodsValid(): boolean {
        if (this.settingsModel.PaymentMethodSettings) {
            return this.settingsModel.PaymentMethodSettings.IsValid;
        } else {
            return true;
        }
    }

    private onLocationGroupSelected(selectedItem: any) {
        // store result and get subgroups
        this.selectedLocationGroup = selectedItem.item;
        this.selectedLocationGroupName = this.selectedLocationGroup.Name;

        if (this.selectedLocationGroup.Id !== this.settingsModel.PaymentMethodSettings.ShopperSettings.LocationGroupId) {
            this.settingsForm.form.markAsDirty();
            this.loadingSubGroups = true;

            this.locationGroupService.get(this.selectedLocationGroup.Id).then((innerResponse: any) => {
                this.settingsModel.PaymentMethodSettings.ShopperSettings.setLocationGroupInfo(innerResponse.Id, innerResponse.Name, innerResponse.PendingAssignmentCount);
                this.settingsModel.PaymentMethodSettings.ShopperSettings.setLocationSubGroups(innerResponse.SubGroups);
                this.loadingSubGroups = false;
            });
        }
    }

    private onShippingLocationGroupSelected(selectedItem: any, index: number) {
        // store result and get subgroups
        this.settingsModel.ShipMethodPreferences[index].LocationGroupName = selectedItem.item.Name;

        if (selectedItem.item.Id !== this.settingsModel.ShipMethodPreferences[index].LocationGroupId) {
            this.settingsForm.form.markAsDirty();
            this.loadingShippingSubGroups[index] = true;

            this.locationGroupService.get(selectedItem.item.Id).then((innerResponse: any) => {
                this.settingsModel.ShipMethodPreferences[index].setLocationGroupInfo(innerResponse.Id, innerResponse.Name, innerResponse.PendingAssignmentCount);
                this.settingsModel.ShipMethodPreferences[index].setLocationSubGroups(innerResponse.SubGroups);

                this.loadingShippingSubGroups[index] = false;
            });
        }
    }

    public selectLocationGroup() {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: false, search: "locationgroup", locationGroupRule: 1 }, hideTotals: true })
            .then((response: any) => {
                this.onLocationGroupSelected(response);
            },
                // Rejected
                () => {
                    // do something
                });
    }

    public selectShippingLocationGroup(index: number) {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: false, search: "locationgroup", locationGroupRule: 1 }, hideTotals: true })
            .then((response: any) => {
                this.onShippingLocationGroupSelected(response, index);
            },
                // Rejected
                () => {
                    // do something
                });
    }

    public typeaheadShippingOnSelect(match: any, index: number) {
        this.onShippingLocationGroupSelected(match, index);
    }

    public showLocations(locFeatureId: number, locFeatureOptionId: number) {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
            resolve: { addAll: false, search: "listbylocgroup", locationGroupId: locFeatureId, locationSubgroupId: locFeatureOptionId }
        },
            {
                windowClass: "no-list-group-item-interaction"
            });

    }

    public typeaheadOnSelect(match: any) {
        this.onLocationGroupSelected(match);
    }

    private getLocationGroupPromise(searchText: string): Promise<any> {
        const locGroupPromise = new Promise<any>((resolve, reject) => {
            const searchInfo: SearchInfo = {
                searchText: searchText || "",
                additionalQueryParams: [
                    { param: "Rule", paramValue: "1" }
                ],
                businessIdentity: this.currentBusinessId,
                chunkIndex: 0,
                chunkCount: Number(10).toString()
            };

            this.locationGroupService.search(searchInfo, "LocationGroup/SimpleSearch").then((response: SearchResponse<any>) => {
                resolve(response.ResultList);
            });
        });

        return locGroupPromise;
    }

    public getLocationGroupSource(): Observable<any> {
        return Observable.create((observer: Observer<string>) => {
            // Runs on every search
            observer.next(this.selectedLocationGroupName);
        }).pipe(debounceTime(365)
            , distinctUntilChanged()
            , mergeMap((searchText: string) => {
                return from(this.getLocationGroupPromise(searchText));
            }));
    }

    public getLocationShippingGroupSources(locationGroupName: string): Observable<any> {

        return Observable.create((observer: Observer<string>) => {
            // Runs on every search
            observer.next(locationGroupName);
        }).pipe(debounceTime(365)
            , distinctUntilChanged()
            , mergeMap((searchText: string) => {
                return from(this.getLocationGroupPromise(searchText));
            }));
    }

    public createNewShippingRule() {
        this.settingsModel.ShipMethodPreferences.push(new ShipMethodRule());
        this.locationShippingGroupSources.push(this.getLocationShippingGroupSources(""));
        this.loadingShippingSubGroups.push(false);
        this.settingsForm.form.markAsDirty();
    }

    public removeShippingRule(ruleIndex: number) {
        this.settingsModel.ShipMethodPreferences.splice(ruleIndex, 1);
        this.locationShippingGroupSources.splice(ruleIndex, 1);
        this.loadingShippingSubGroups.splice(ruleIndex, 1);
        this.settingsForm.form.markAsDirty();
    }

    public get formTouched(): boolean {
        return this.settingsForm.touched;
    }

    public fieldTouched(name: string): boolean {
        return this.settingsForm.controls[name]
            && this.settingsForm.controls[name].touched;
    }

    public anyFieldTouched(names: string[]): boolean {
        for (let fieldIndex = 0; fieldIndex < names.length; fieldIndex++) {
            let currentField = names[fieldIndex];

            if (this.fieldTouched(currentField)) {
                return true;
            }
        }

        return false;
    }

    public paymentMethodTouched(baseName: string) {
        const names = [
            baseName + "cc",
            baseName + "invoice"
        ];

        return this.anyFieldTouched(names);
    }

    public get allLocationsDisabled(): boolean {
        return !this.PaymentMethodSettings.ShopperSettings.AllLocations;
    }

    public get allLocationsEnabled(): boolean {
        return this.PaymentMethodSettings.ShopperSettings.AllLocations;
    }

    private hasPermission(permissionName: string): boolean {
        return !!this.activeProfileService.permissions.find(q => q.Name === permissionName);
    }

    private isInRole(roleNames: string[]): boolean {
        const allThemes = this.activeProfileService.profile.businessIdentityList;

        const allRoles = <string[]>[];

        allThemes.forEach((theme) => {
            allRoles.push(...theme.Role);
        });

        const matchingRoles = allRoles.filter((item) => roleNames.indexOf(item) >= 0);

        return matchingRoles.length > 0;
    }
}
