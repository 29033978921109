import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActiveProfileService, IpsBaseWebService, HelperFunctionsService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";

@Injectable()
export class SignPlanService extends IpsBaseWebService {

    private currentBusinessId: string;
    private token: string = null;

    public SignPlanWidthOptions = [
        {
            key: "Select a Width",
            value: null
        },
        {
            key: "1/6",
            value: 2
        },
        {
            key: "1/4",
            value: 3
        },
        {
            key: "1/3",
            value: 4
        },
        {
            key: "1/2",
            value: 6
        },
        {
            key: "2/3",
            value: 8
        },
        {
            key: "3/4",
            value: 9
        },
        {
            key: "5/6",
            value: 10
        },
        {
            key: "Full",
            value: 12
        }
    ];

    constructor(http: HttpClient,
        public activeProfileService: ActiveProfileService,
        public helperFunctionsService: HelperFunctionsService,
        private authService: AuthService
    ) {
        super(http, <any>environment, activeProfileService);
        this.init("", "", "v1");
        this.currentBusinessId = this.activeProfileService.businessIdentity;
        this.token = this.authService.getIdToken();
    }

    public getHolderPreviewImage(id: number): Promise<any> {
        return this.getPreviewImage(id, "Holder");
    }

    public getElementPreviewImage(id: number): Promise<any> {
        return this.getPreviewImage(id, "Element");
    }

    private getPreviewImage(id: number, type: string): Promise<any> {
        const returnPromise = new Promise<any>((resolve, reject) => {
            let route = `${this.urlApiBase}Campaign/v1/Campaign/PreviewSignImage/${id}/TypeId/${type}`;
            return this.getByRoute(route).then((response: string) => {
                if (response) {
                    const imageUrl = this.buildSignPlanImageUrl(response);
                    resolve(imageUrl);
                } else {
                    resolve(null);
                }
            }).catch(() => {
                resolve(null);
            });
        });

        return returnPromise;
    }

    private buildSignPlanImageUrl(fileName: string): string {
        const url = `${this.urlApiBase}Media/v1/MasterMedia/${this.currentBusinessId}/CampaignSign/${fileName}/Original/Png?BusinessIdentity=${this.currentBusinessId}&idToken=${this.token}`;
        return url;
    }
}
