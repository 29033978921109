<div>
    <div>
        <div id="layout-masthead">
            <header class="container-fluid clearfix">
                <ips-header *ngIf="Theme.IsRamiBranded" [showBrandSelector]="true" [showTermsAndConditions]="false" [myCartState]="'main.mycart'" [showMyCart]="true" [brandClass]="BrandClass" (logOut)="logout()"></ips-header>
                <ips-header *ngIf="!Theme.IsRamiBranded" class="customer-branded"
                            [showBrandSelector]="false"
                            [showTermsAndConditions]="false"
                            [showWaffle]="false"
                            [showMyCart]="true"
                            [myCartState]="'main.mycart'"
                            [showEnterpriseAbout]="false"
                            [brandClass]="BrandClass"
                            [logo]="BannerUri"
                            (logOut)="logout()"></ips-header>
            </header>
        </div>
        <div class="layout-navigation header-retailSpace-branded pt-2 pb-0 pb-md-2 d-print-none" [ngClass]="{'customer-branded': !Theme.IsRamiBranded}" [ngStyle]="!Theme.IsRamiBranded && {'background-color':Theme.PrimaryHexColor, 'color':Theme.SecondaryHexColor}">
            <div class="container">
                <div class="main-nav">
                    <div class="row">
                        <div class="col px-2 pl-sm-0 px-md-0 primary-navigation ips-menu">
                            <ul class="nav d-flex h-100 align-items-center font-weight-bold">
                                <li ngbDropdown class="nav-item dropdown ips-nav-item" #profileDrop="ngbDropdown" ipsPermission="main.storeProfile">
                                    <a class="nav-link clicker px-2 pl-sm-0 pl-md-2" id="navbarDropdown1" role="button" ngbDropdownToggle aria-haspopup="true" aria-expanded="false" translate>PROFILES</a>
                                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown1">
                                        <a ipsPermission="main.storeProfileLocation.search" class="dropdown-item" uiSref="main.storeProfileLocation.search" translate>LOCATIONS</a>
                                        <a ipsPermission="main.storeProfileLocationGroup.search" class="dropdown-item" uiSref="main.storeProfileLocationGroup.search" translate>LOCATION_GROUPS</a>
                                        <a ipsPermission="main.storeProfileFixture.search" class="dropdown-item" uiSref="main.storeProfileFixture.search" translate>FIXTURES</a>
                                        <a ipsPermission="main.storeProfileFixtureGroup.search" class="dropdown-item" uiSref="main.storeProfileFixtureGroup.search" translate>FIXTURE_GROUPS</a>
                                        <a ipsPermission="main.storeProfileHolderVersion.search" class="dropdown-item" uiSref="main.storeProfileHolderVersion.search" translate>VERSION_INFO</a>
                                        <a ipsPermission="main.storeProfileSpace.search" class="dropdown-item" uiSref="main.storeProfileSpace.search" translate>SPACES</a>
                                        <a ipsPermission="main.storeProfileAlternateAddress.search" class="dropdown-item" uiSref="main.storeProfileAlternateAddress.search" translate>ALTERNATE_ADDRESSES</a>
                                        <a ipsPermission="main.storeProfileAlternateAddress.search" class="dropdown-item" uiSref="main.storeProfileStoreFront" translate>Store Front Setup</a>
                                    </div>
                                    <div class="dropdown caret-waffle caret-position"></div>
                                </li>
                                <li ngbDropdown class="nav-item dropdown ips-nav-item" #campaignDrop="ngbDropdown" ipsPermission="main.campaignLanding">
                                    <a class="nav-link clicker px-2 pl-sm-0 pl-md-2" id="navbarDropdown3" role="button" ngbDropdownToggle aria-haspopup="true" aria-expanded="false" translate>CAMPAIGN_BUILDER</a>
                                    <div ngbDropdownMenu class="dropdown-menu line-two" aria-labelledby="navbarDropdown3">
                                        <a class="dropdown-item" uiSref="main.campaign.search" ipsPermission="main.campaign.search" translate>CAMPAIGNS</a>
                                        <a class="dropdown-item" uiSref="main.storeProfileElement.search" ipsPermission="main.storeProfileElement.search" translate>ELEMENTS</a>
                                    </div>
                                    <div class="dropdown caret-waffle caret-position"></div>
                                </li>
                                <li class="nav-item" ipsPermission="main.myproducts">
                                    <a class="nav-link px-2" uiSref="main.myproducts" translate>MY_PRODUCTS</a>
                                </li>
                                <li class="nav-item" ipsPermission="main.myorders.search">
                                    <a class="nav-link px-2" uiSref="main.myorders.search" translate>ORDERS</a>
                                </li>
                                <li class="nav-item" ipsPermission="main.survey.search">
                                    <a class="nav-link px-2" uiSref="main.survey.search" translate>SURVEYS</a>
                                </li>
                                <li class="nav-item" ipsPermission="main.mysurvey.search">
                                    <a class="nav-link px-2" uiSref="main.mysurvey.search" translate>MY_SURVEYS</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link px-2" uiSref="main.report.search" translate ipsPermission="main.report.search">REPORTS</a>
                                </li>
                                <li ngbDropdown placement="bottom-right" class="nav-item dropdown ips-nav-item" #toolsDrop="ngbDropdown" ipsPermission="main.tools">
                                    <a class="nav-link clicker px-2 pr-md-0" id="navbarDropdown2" role="button" ngbDropdownToggle aria-haspopup="true" aria-expanded="false" translate>ADMIN</a>
                                    <div ngbDropdownMenu class="dropdown-menu line-two" aria-labelledby="navbarDropdown2">
                                        <a class="dropdown-item" uiSref="main.fileProcessing" ipsPermission="main.fileProcessing" translate>FILE_PROCESSING</a>
                                        <a class="dropdown-item" uiSref="main.profileUser.search" ipsPermission="main.profileUser.search" translate>USERS</a>
                                        <a class="dropdown-item" uiSref="main.toolsControls" ipsPermission="main.toolsControls" translate>CONTROLS</a>
                                        <a class="dropdown-item" uiSref="main.customdata.search" ipsPermission="main.customdata.search" translate>CUSTOM_DATA_FIELDS</a>
                                        <a class="dropdown-item" uiSref="main.profileTheme" ipsPermission="main.profileTheme" translate>THEME</a>
                                        <a class="dropdown-item" uiSref="main.productadminsearch" ipsPermission="main.productadminsearch" translate>MANAGE_PRODUCTS</a>
                                        <a *ngIf="enableSignPlan" class="dropdown-item" uiSref="main.campaign.signplanproblems" ipsPermission="main.campaign.signplanproblems" translate>SIGN_PLAN_PROBLEMS</a>
                                        <a class="dropdown-item" uiSref="main.storeFrontCategory.search" ipsPermission="main.storeFrontCategory.search" translate>STORE_FRONT_CATEGORIES</a>
                                        <a class="dropdown-item" uiSref="main.analytics" ipsPermission="main.analytics" translate>ANALYTICS</a>
                                    </div>
                                    <div class="dropdown caret-waffle caret-position"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="layout-content">
        <!--class="container ips-container"-->
        <div id="content" class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ips-breadcrumb prefixStateName="main.home" class="d-print-none"></ips-breadcrumb>
                    <ui-view></ui-view>
                </div>
            </div>
        </div>
    </div>
    <!--Only show for enterprise branded customers-->
    <div id="layout-footer" *ngIf="Theme.IsRamiBranded">
        <footer>
            <div class="container text-center">
                <p class="crInfo mb-0">
                    <img src="../../assets/img/logo_brand.png" alt="IMAGINE" />
                </p>
            </div>
        </footer>
    </div>

    <!--Live Agent -->
    <app-contact></app-contact>
</div>
