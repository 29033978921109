/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ips-date-range.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "../provide-parent-form/provide-parent-form.directive";
import * as i6 from "@angular/forms";
import * as i7 from "ngx-bootstrap/datepicker";
import * as i8 from "ngx-bootstrap/component-loader";
import * as i9 from "../validation/validation.directive";
import * as i10 from "./ips-date-range.component";
import * as i11 from "imagine-ui-ng-core";
var styles_IpsDateRangeComponent = [i0.styles];
var RenderType_IpsDateRangeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IpsDateRangeComponent, data: {} });
export { RenderType_IpsDateRangeComponent as RenderType_IpsDateRangeComponent };
function View_IpsDateRangeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["class", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 16777216, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa fa-info-circle fa-lg ips-ls-info-icon"], ["placement", "bottom"]], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i3.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i3.NgbTooltipConfig, i1.NgZone, i4.DOCUMENT], { placement: [0, "placement"], ngbTooltip: [1, "ngbTooltip"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = "bottom"; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 1, i1.ɵnov(_v, 6).transform("TOOLTIP_DATERANGE_INFO")), ""); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.labelText)); _ck(_v, 2, 0, currVal_0); }); }
function View_IpsDateRangeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "invalid-feedback d-flex"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["PLEASE_ENTER_DATE_RANGE"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_IpsDateRangeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "invalid-feedback d-flex"]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(2, { days: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "DATE_RANGE_TOO_BIG"; var currVal_1 = _ck(_v, 2, 0, _co.daySpan); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_IpsDateRangeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { rangeControl: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 24, "div", [["appProvideParentForm", ""], ["class", "form-group"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i5.ProvideParentFormDirective, [], null, null), i1.ɵprd(1024, null, i6.ControlContainer, i5.ɵ0, [i6.NgForm]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IpsDateRangeComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 15, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 10, "input", [["appValidation", ""], ["bsDaterangepicker", ""], ["class", "form-control form-control-lg input-full"], ["id", "dateRangeId"], ["name", "dateRangeName"], ["triggers", ""], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"], [null, "keyup.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).onChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.esc" === en)) {
        var pd_5 = (i1.ɵnov(_v, 10).hide() !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 10).onBlur() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.dateRangeModel = $event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = (_co.changeDateRange() !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i6.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i6.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(9, 737280, [["d2", 4]], 0, i7.BsDaterangepickerDirective, [i7.BsDaterangepickerConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i8.ComponentLoaderFactory], { triggers: [0, "triggers"], bsConfig: [1, "bsConfig"], maxDate: [2, "maxDate"] }, null), i1.ɵdid(10, 16384, null, 0, i7.ɵs, [i7.BsDaterangepickerDirective, i7.BsLocaleService, i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef], null, null), i1.ɵprd(1024, null, i6.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i7.ɵs]), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i6.DefaultValueAccessor, i7.ɵs]), i1.ɵdid(13, 671744, [[1, 4], ["rangeDate", 4]], 0, i6.NgModel, [[2, i6.ControlContainer], [6, i6.NG_VALIDATORS], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(15, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i1.ɵdid(16, 278528, null, 0, i9.ValidationDirective, [i1.Renderer2, i1.ElementRef, i6.NgControl], null, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 3, "span", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "btn btn-secondary form-control-lg imagine-calendar-btn-mod"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "fa fa-calendar imagine-calendar-icon-mod"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IpsDateRangeComponent_2)), i1.ɵdid(23, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IpsDateRangeComponent_3)), i1.ɵdid(25, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLabel; _ck(_v, 5, 0, currVal_0); var currVal_9 = ""; var currVal_10 = _co.dateRangeOptions; var currVal_11 = _co.maxDate; _ck(_v, 9, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = "dateRangeName"; var currVal_13 = _co.dateRangeModel; _ck(_v, 13, 0, currVal_12, currVal_13); _ck(_v, 16, 0); var currVal_14 = "btn btn-secondary form-control-lg imagine-calendar-btn-mod"; _ck(_v, 20, 0, currVal_14); var currVal_15 = (i1.ɵnov(_v, 13).errors && i1.ɵnov(_v, 13).errors.required); _ck(_v, 23, 0, currVal_15); var currVal_16 = (i1.ɵnov(_v, 13).errors && i1.ɵnov(_v, 13).errors.rangeTooBig); _ck(_v, 25, 0, currVal_16); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 17).transform("ENTER_DATE_RANGE")), ""); var currVal_2 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 15).ngClassValid; var currVal_7 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_IpsDateRangeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ips-date-range", [], null, null, null, View_IpsDateRangeComponent_0, RenderType_IpsDateRangeComponent)), i1.ɵdid(1, 114688, null, 0, i10.IpsDateRangeComponent, [i11.ActiveProfileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IpsDateRangeComponentNgFactory = i1.ɵccf("app-ips-date-range", i10.IpsDateRangeComponent, View_IpsDateRangeComponent_Host_0, { startDate: "startDate", endDate: "endDate", daySpan: "daySpan", showLabel: "showLabel", labelText: "labelText", required: "required", maxDate: "maxDate" }, { rangeChanged: "rangeChanged" }, []);
export { IpsDateRangeComponentNgFactory as IpsDateRangeComponentNgFactory };
