import { OnInit } from "@angular/core";
import { SurveyService } from "../../service/survey.service";
import { Transition, StateService } from "@uirouter/core";
import { LocationService } from "../../../imagine-ui-ng-store-profile";
import { FormGroup, FormArray } from "@angular/forms";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";
var MySurveyTakeQuestionsComponent = /** @class */ (function () {
    function MySurveyTakeQuestionsComponent(transition, surveyService, locationService, $state, settingsService) {
        this.transition = transition;
        this.surveyService = surveyService;
        this.locationService = locationService;
        this.$state = $state;
        this.settingsService = settingsService;
        this.nextSurvey = 0;
        this.nextLocation = 0;
        this.expandSurveyOptions = true;
    }
    MySurveyTakeQuestionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.surveyId = this.transition.params().id;
        this.locationId = this.transition.params().locationId;
        // Get list of sections
        this.QuestionsFormGroup = new FormGroup({
            Sections: new FormArray([])
        });
        if (this.surveyId > 0 && this.locationId > 0) {
            this.loadSections();
            this.loadLocation(this.locationId);
            this.loadSurvey(this.surveyId);
            var nextLocPromise = this.surveyService.getNextLocation(this.surveyId, this.locationId).then(function (response) {
                if (response.toString() !== _this.locationId.toString()) {
                    _this.nextLocation = response;
                }
            });
            var nextSurveyPromise = this.surveyService.getNextSurvey(this.surveyId, this.locationId).then(function (response) {
                if (response.toString() !== _this.surveyId.toString()) {
                    _this.nextSurvey = response;
                }
            });
            this.promiseNext = Promise.all([nextLocPromise, nextSurveyPromise]);
        }
        this.getExpandSurveyOptionsSetting();
    };
    Object.defineProperty(MySurveyTakeQuestionsComponent.prototype, "SectionsArray", {
        get: function () {
            return this.QuestionsFormGroup.get("Sections");
        },
        enumerable: true,
        configurable: true
    });
    MySurveyTakeQuestionsComponent.prototype.loadSurvey = function (id) {
        var _this = this;
        this.surveyService.get(id).then(function (response) {
            _this.SurveyLabel = response.Name;
        });
    };
    MySurveyTakeQuestionsComponent.prototype.loadSections = function () {
        var _this = this;
        this.promiseSectionLoad = this.surveyService.getMySurveySections(this.surveyId, this.locationId).then(function (response) {
            _this.sections = response;
            var startingNumber = 1;
            for (var i = 0; i < _this.sections.length; i++) {
                _this.SectionsArray.controls.push(new FormGroup({}));
                if (i === 0) {
                    _this.sections[i].StartingQuestionNumber = startingNumber;
                }
                else {
                    startingNumber = startingNumber + _this.sections[i - 1].QuestionCount;
                    _this.sections[i].StartingQuestionNumber = startingNumber;
                }
            }
        });
    };
    MySurveyTakeQuestionsComponent.prototype.loadLocation = function (id) {
        var _this = this;
        this.locationService.get(id).then(function (response) {
            _this.LocationLabel = response.LocationIdentifier + " - " + response.Name;
        });
    };
    MySurveyTakeQuestionsComponent.prototype.getExpandSurveyOptionsSetting = function () {
        var _this = this;
        return this.settingsService.canExpandSurveyOptions().then(function (response) {
            _this.expandSurveyOptions = response;
        });
    };
    MySurveyTakeQuestionsComponent.prototype.NextSurvey = function () {
        this.$state.go("main.mysurvey.questions", { id: this.nextSurvey, locationId: this.locationId });
    };
    MySurveyTakeQuestionsComponent.prototype.NextLocation = function () {
        this.$state.go("main.mysurvey.questions", { id: this.surveyId, locationId: this.nextLocation });
    };
    MySurveyTakeQuestionsComponent.prototype.checkFormDirty = function () {
        var isDirty = this.SectionsArray.controls.some(function (c) {
            var questions = c.controls.Questions;
            if (questions) {
                return questions.controls.some(function (q) {
                    if (q.dirty === true) {
                        return true;
                    }
                    else {
                        var controls = q.controls;
                        var isControlDirty = (controls.SubGroups && controls.SubGroups.controls.some(function (sg) { return sg.dirty === true; })) ||
                            (controls.Fixtures && controls.Fixtures.controls.some(function (f) { return f.dirty === true; })) ||
                            (controls.Messge && controls.Message.dirty === true);
                        return isControlDirty;
                    }
                });
            }
            else {
                return false;
            }
        });
        if (isDirty) {
            this.QuestionsFormGroup.markAsDirty();
        }
        else {
            this.QuestionsFormGroup.markAsPristine();
        }
    };
    return MySurveyTakeQuestionsComponent;
}());
export { MySurveyTakeQuestionsComponent };
