import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { ListSearchHelper, SearchInfo, QueryParamItem, ActiveProfileService } from "imagine-ui-ng-core";
import { ListSearchComponent, ResultDetailComponent, TranslatedTexts } from "imagine-ui-ng-list-search";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "imagine-ui-ng-security";

import { SurveyService, SurveyModel, SurveyStatusModel, SurveySearchResultModel, SurveyStatus } from "../../index";

interface SurveyUIModel extends SurveyModel {
    SurveyStatusInfo: SurveyStatusModel;
}

interface ScrollInfo {
    search: string;
    positionY: number;
    chunkIndex: number;
}

@Component({
  selector: "app-survey-search",
  templateUrl: "./survey-search.component.html",
  styleUrls: ["./survey-search.component.scss"]
})
export class SurveySearchComponent implements OnInit, OnDestroy {
    public survey: ListSearchHelper;
    public busy: boolean;
    public promise: Promise<any>;
    public query: string;
    private scrollY: number;
    public searchInfo: SearchInfo;
    private searchScrollKey: string;
    private locale = "en-US";

    constructor(private surveyService: SurveyService, listSearchHelper: ListSearchHelper, private translateService: TranslateService, private activeProfileService: ActiveProfileService, private authService: AuthService) {
        this.survey = listSearchHelper.getListSearchHelper(surveyService);
    }

    ngOnInit() {

        this.locale = this.activeProfileService.profile.Locale || navigator.language;

        this.searchScrollKey = "survey_search_scroll_" + this.activeProfileService.businessIdentity;
        let scrollInfo: ScrollInfo = JSON.parse(localStorage.getItem(this.searchScrollKey));

        this.surveyService.head().then((data) => {
            this.survey.TotalRecords = data;
        });

        if (scrollInfo) {
            this.query = scrollInfo.search;
        }

        this.getListData(this.query || "", scrollInfo);
    }

    public getListData(search: string, scrollInfo?: ScrollInfo) {
        if (!search) {
            search = "";
        }

        this.query = search;
        this.searchInfo = {
            searchText: search || ""
        };

        this.promise = this.survey.searchHelper(this.searchInfo, undefined, undefined).then(() => {
            this.survey.resultList.forEach((item: SurveyUIModel) => {
                item.SurveyStatusInfo = this.surveyService.getStatusInfo(item);
            });

            if (scrollInfo) {
                scrollInfo.chunkIndex--;
                if (scrollInfo.chunkIndex > 0) {
                    this.getListData(search, scrollInfo);
                } else {
                    setTimeout(() => { window.scrollTo(0, scrollInfo.positionY); }, 500);
                }
            }

            // Return for the promise
            return true;
        });
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll($event) {
        if (window.scrollY) {
            this.scrollY = window.scrollY;
        } else if (window.pageYOffset) {
            //Only for IE9+
            this.scrollY = window.pageYOffset;
        }
    }

    ngOnDestroy() {
        localStorage.setItem(this.searchScrollKey, JSON.stringify({ search: this.searchInfo.searchText, positionY: this.scrollY, chunkIndex: this.survey.chunkIndex }));
    }

}
