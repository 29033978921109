/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ips-typeahead.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "imagine-ui-ng-core";
import * as i3 from "@angular/forms";
import * as i4 from "ngx-bootstrap/typeahead";
import * as i5 from "ngx-bootstrap/component-loader";
import * as i6 from "@angular/common";
import * as i7 from "./ips-typeahead.component";
import * as i8 from "imagine-ui-ng-modal";
import * as i9 from "@ngx-translate/core";
var styles_IpsTypeaheadComponent = [i0.styles];
var RenderType_IpsTypeaheadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IpsTypeaheadComponent, data: {} });
export { RenderType_IpsTypeaheadComponent as RenderType_IpsTypeaheadComponent };
function View_IpsTypeaheadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.item.Name; _ck(_v, 1, 0, currVal_0); }); }
export function View_IpsTypeaheadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_2 && ad);
    } if (("reset" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 606208, null, 0, i2.ɵc, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵdid(2, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 17, "div", [["class", "input-group ips-textarea"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["customItemTemplate", 2]], null, 0, null, View_IpsTypeaheadComponent_1)), (_l()(), i1.ɵeld(7, 16777216, null, null, 7, "textarea", [["class", "form-control form-control-lg input-2-icons campaign-market-cancel-warning"], ["formControlName", "Name"], ["ipsAutoGrow", ""], ["rows", "1"], ["typeaheadOptionField", "Name"]], [[8, "name", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "typeaheadOnSelect"], [null, "ngModelChange"], [null, "change"], ["window", "resize"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "keyup"], [null, "click"], [null, "focus"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).modelChange() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onChange() !== false);
        ad = (pd_1 && ad);
    } if (("window:resize" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8).onResize($event) !== false);
        ad = (pd_2 && ad);
    } if (("input" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_4 && ad);
    } if (("compositionstart" === en)) {
        var pd_5 = (i1.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_5 && ad);
    } if (("compositionend" === en)) {
        var pd_6 = (i1.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_6 && ad);
    } if (("input" === en)) {
        var pd_7 = (i1.ɵnov(_v, 14).onInput($event) !== false);
        ad = (pd_7 && ad);
    } if (("keyup" === en)) {
        var pd_8 = (i1.ɵnov(_v, 14).onChange($event) !== false);
        ad = (pd_8 && ad);
    } if (("click" === en)) {
        var pd_9 = (i1.ɵnov(_v, 14).onFocus() !== false);
        ad = (pd_9 && ad);
    } if (("focus" === en)) {
        var pd_10 = (i1.ɵnov(_v, 14).onFocus() !== false);
        ad = (pd_10 && ad);
    } if (("blur" === en)) {
        var pd_11 = (i1.ɵnov(_v, 14).onBlur() !== false);
        ad = (pd_11 && ad);
    } if (("keydown" === en)) {
        var pd_12 = (i1.ɵnov(_v, 14).onKeydown($event) !== false);
        ad = (pd_12 && ad);
    } if (("typeaheadOnSelect" === en)) {
        var pd_13 = (_co.typeaheadOnSelect($event) !== false);
        ad = (pd_13 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 4210688, null, 0, i2.ɵa, [i1.ElementRef], null, null), i1.ɵdid(9, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(11, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_j]], { name: [0, "name"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(13, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i1.ɵdid(14, 212992, null, 0, i4.TypeaheadDirective, [i3.NgControl, i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i4.TypeaheadConfig, i5.ComponentLoaderFactory, i1.ChangeDetectorRef], { typeahead: [0, "typeahead"], typeaheadOptionsLimit: [1, "typeaheadOptionsLimit"], typeaheadOptionField: [2, "typeaheadOptionField"], typeaheadItemTemplate: [3, "typeaheadItemTemplate"], typeaheadScrollable: [4, "typeaheadScrollable"] }, { typeaheadOnSelect: "typeaheadOnSelect" }), (_l()(), i1.ɵeld(15, 0, null, null, 7, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "span", [["class", "input-group-text"]], null, null, null, null, null)), i1.ɵdid(17, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(18, { "invalid": 0 }), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-default ips-btn-remove"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeItem(_co.itemGroupIndex) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fa fa-remove fa-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-default last"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "fa fa-search fa-lg pl-1 pr-1"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.itemGroup; _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.itemGroup; _ck(_v, 2, 0, currVal_8); var currVal_17 = "Name"; _ck(_v, 11, 0, currVal_17); var currVal_18 = _co.typeaheadValues(); var currVal_19 = 20; var currVal_20 = "Name"; var currVal_21 = i1.ɵnov(_v, 6); var currVal_22 = true; _ck(_v, 14, 0, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); var currVal_23 = "input-group-text"; var currVal_24 = _ck(_v, 18, 0, (_co.itemGroup.get("Name").invalid && (_co.itemGroup.get("Name").dirty || _co.itemGroup.get("Name").touched))); _ck(_v, 17, 0, currVal_23, currVal_24); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵinlineInterpolate(1, "", _co.itemGroup.get("ControlName").value, ""); var currVal_10 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_11 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_12 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_13 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_14 = i1.ɵnov(_v, 13).ngClassValid; var currVal_15 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_16 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 7, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }); }
export function View_IpsTypeaheadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ips-typeahead", [], null, null, null, View_IpsTypeaheadComponent_0, RenderType_IpsTypeaheadComponent)), i1.ɵdid(1, 114688, null, 0, i7.IpsTypeaheadComponent, [i8.IpsModalService, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IpsTypeaheadComponentNgFactory = i1.ɵccf("app-ips-typeahead", i7.IpsTypeaheadComponent, View_IpsTypeaheadComponent_Host_0, { name: "name", itemGroup: "itemGroup", itemGroupIndex: "itemGroupIndex", itemSource: "itemSource", itemSourceList: "itemSourceList", itemSourceListSelected: "itemSourceListSelected", multiSelection: "multiSelection" }, { itemChanged: "itemChanged", itemDeleted: "itemDeleted" }, []);
export { IpsTypeaheadComponentNgFactory as IpsTypeaheadComponentNgFactory };
