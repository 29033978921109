<h3 class="title ml-3" translate>SHOP_CONTROLS</h3>

<div class="ips-flex-loading-box mb-4" *ngIf="!screenReady">
    <div class="content">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
        <span class="d-block" translate>ACCESSING_SHOP_CONTROLS</span>
    </div>
</div>

<form #settingsForm="ngForm" name="settingsForm" novalidate ipsUnsavedWarningForm role="form">
    <div *ngIf="screenReady" class="mb-4">
        <tabset>
            <tab *ngIf="canAccessGeneralSettings" heading="General" id="orderReason">
                <h6 class="view-info-label ml-3 mb-0">Show Category Filter</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="showCategoryFilter" id="showCategoryFilter" type="radio" [value]="'true'" [(ngModel)]="settingsModel.CategoryFilterSetting.Value">
                                <label class="form-check-label" for="showCategoryFilter">
                                    Show Category filter
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="showCategoryFilter" id="hideCategoryFilter" type="radio" [value]="'false'" [(ngModel)]="settingsModel.CategoryFilterSetting.Value">
                                <label class="form-check-label" for="hideCategoryFilter">
                                    Do not show
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 class="view-info-label ml-3 mb-0">Use Category Permissions</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="showPermissionsFilter" id="showPermissionsFilter" type="radio" [value]="'false'" [(ngModel)]="settingsModel.CategoryPermissionSetting.Value">
                                <label class="form-check-label" for="showPermissionsFilter">
                                    Category Only
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="showPermissionsFilter" id="hidePermissionsFilter" type="radio" [value]="'true'" [(ngModel)]="settingsModel.CategoryPermissionSetting.Value">
                                <label class="form-check-label" for="hidePermissionsFilter">
                                    Category And Category Permissions
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 class="view-info-label ml-3 mb-0" translate>SHOW_ORDER_REASON_AFFIRMATIVE</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="showOrderReason" id="showOrderReasonItem" type="radio" [value]="'item'" [(ngModel)]="settingsModel.ShowOrderReasonSetting.Value">
                                <label class="form-check-label" for="showOrderReasonItem">
                                    Show for each individual item
                                </label>
                            </div>
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="showOrderReason" id="showOrderReasonOrder" type="radio" [value]="'order'" [(ngModel)]="settingsModel.ShowOrderReasonSetting.Value">
                                <label class="form-check-label" for="showOrderReasonOrder">
                                    Show for entire order
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="showOrderReason" id="hideOrderReason" type="radio" [value]="'false'" [(ngModel)]="settingsModel.ShowOrderReasonSetting.Value">
                                <label class="form-check-label" for="hideOrderReason">
                                    Do not show
                                </label>
                            </div>
                            <div class="mt-3" *ngIf="settingsModel.ShowOrderReasonSetting.Value != 'false'">
                                <label class="view-info-label mb-0">Order Reasons</label>
                                <textarea rows="8" class="form-control form-control-lg" [ngModelOptions]="{standalone: true}" [(ngModel)]="settingsModel.OrderReasonSetting.Value"></textarea>
                                <small class="text-black-50">Enter one Order Reason per line</small>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 class="view-info-label ml-3 mb-0" translate>PO Number</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="poNumber" id="poFreeForm" type="radio" [value]="false" [(ngModel)]="settingsModel.PONumberSetting.EnableList">
                                <label class="form-check-label" for="poFreeForm">
                                    Allow free-form text entry
                                </label>
                            </div>
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="poNumber" id="poSpecifiedList" type="radio" [value]="true" [(ngModel)]="settingsModel.PONumberSetting.EnableList">
                                <label class="form-check-label" for="poSpecifiedList">
                                    Provide a pre-defined list of options
                                </label>
                            </div>
                            <div *ngIf="settingsModel.PONumberSetting.EnableList">
                                <textarea rows="8" class="form-control form-control-lg" [ngModelOptions]="{standalone: true}" [(ngModel)]="settingsModel.PONumberSetting.Options"></textarea>
                                <small class="text-black-50">Enter one PO Number per line.  Prefix a value with "_" to restrict it to administrative users only.</small>
                            </div>
                        </div>
                    </div>
                </div>
                <h6 class="view-info-label ml-3 mb-0">Reference Number</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="showReferenceNumber" id="showReferenceNumber" type="radio" [value]="'true'" [(ngModel)]="settingsModel.ShowReferenceNumberSetting.Value" />
                                <label class="form-check-label" for="showReferenceNumber">
                                    Show Reference Number
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="hideReferenceNumber" id="hideReferenceNumber" type="radio" [value]="'false'" [(ngModel)]="settingsModel.ShowReferenceNumberSetting.Value" />
                                <label class="form-check-label" for="hideReferenceNumber">
                                    Do not show
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 class="view-info-label ml-3 mb-0">Comments</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="showComments" id="showComments" type="radio" [value]="'true'" [(ngModel)]="settingsModel.CommentsSetting.Value" />
                                <label class="form-check-label" for="showComments">
                                    Show Comments Field
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="hideComments" id="hideComments" type="radio" [value]="'false'" [(ngModel)]="settingsModel.CommentsSetting.Value" />
                                <label class="form-check-label" for="hideComments">
                                    Do not show
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <h6 class="view-info-label ml-3 mb-0" translate>Checkout Label</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <input type="text" class="form-control form-control-lg" placeholder="Checkout by Invoice" [(ngModel)]="settingsModel.CheckoutLabelSetting.Value" [ngModelOptions]="{standalone: true}" />
                            <small class="text-black-50">Enter the Label to be shown on the Checkout button.  If no value is entered, <b>Checkout by Invoice</b> will be displayed.</small>
                        </div>
                    </div>
                </div>

                <h6 class="view-info-label ml-3 mb-0">Need By Date</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="showNeedByDate" id="showNeedByDate" type="radio" [value]="'true'" [(ngModel)]="settingsModel.ShowNeedByDateSetting.Value" />
                                <label class="form-check-label" for="showNeedByDate">
                                    Show Need By Date
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="hideNeedByDate" id="hideNeedByDate" type="radio" [value]="'false'" [(ngModel)]="settingsModel.ShowNeedByDateSetting.Value" />
                                <label class="form-check-label" for="hideNeedByDate">
                                    Do not show
                                </label>
                            </div>
                        </div>
                    </div>
                </div>


                <h6 class="view-info-label ml-3 mb-0">Show All Locations Filter</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="showAllLocationsFilter" id="showAllLocationsFilter" type="radio" [value]="'true'" [(ngModel)]="settingsModel.AllLocationsFilterSetting.Value">
                                <label class="form-check-label" for="showAllLocationsFilter">
                                    Show All Locations filter
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="showAllLocationsFilter" id="hideAllLocationsFilter" type="radio" [value]="'false'" [(ngModel)]="settingsModel.AllLocationsFilterSetting.Value">
                                <label class="form-check-label" for="hideAllLocationsFilter">
                                    Do not show
                                </label>
                            </div>
                        </div>
                    </div>
                </div>


                <h6 class="view-info-label ml-3 mb-0">Hide Campaign Filters on Product Search</h6>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="pl-3">
                            <div class="form-check mb-1">
                                <input class="form-check-input" name="hideCampaignFilters" id="hideCampaignFilters" type="radio" [value]="'true'" [(ngModel)]="settingsModel.HideCampaignFilterSetting.Value">
                                <label class="form-check-label" for="hideCampaignFilters">
                                    Hide Campaign Filters
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" name="hideCampaignFilters" id="showCampaignFilters" type="radio" [value]="'false'" [(ngModel)]="settingsModel.HideCampaignFilterSetting.Value">
                                <label class="form-check-label" for="showCampaignFilters">
                                    Show Campaign Filters
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </tab>
        <tab *ngIf="canAccessGeneralSettings" heading="{{ 'DELIVERY_OPTIONS' | translate }}" id="shipMethods">

            <h6 class="view-info-label ml-3 mb-0">Show Delivery Selection</h6>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="pl-3">
                        <div class="form-check mb-1">
                            <input class="form-check-input" name="showDeliverySelection" id="showDeliverySelection" type="radio" [value]="'true'" [(ngModel)]="settingsModel.DeliverySelectionSetting.Value"/>
                            <label class="form-check-label" for="showDeliverySelection">
                                Show Delivery Selection
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" name="hideDeliverySelection" id="hideDeliverySelection" type="radio" [value]="'false'" [(ngModel)]="settingsModel.DeliverySelectionSetting.Value"/>
                            <label class="form-check-label" for="hideDeliverySelection">
                                Do not show
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <h4 class="d-inline-block mb-0" translate>AVAILABLE_DELIVERY_OPTIONS</h4><button class="btn btn-sm btn-link text-info px-0 ml-3" (click)="createNewShippingRule()" translate><span class="fa fa-plus"></span> ADD_NEW_RULE</button>
            <hr/>
            <div class="ship-method-rule" *ngFor="let rule of settingsModel.ShipMethodPreferences; let ruleIndex = index">
                <div class="row">
                    <div class="col-3">
                        <label class="font-weight-bold" translate>ROLE</label>
                        <select class="form-control form-control-sm" [(ngModel)]="rule.RoleId" [ngModelOptions]="{ standalone: true }">
                            <option *ngIf="!rule.RoleId" translate>ALL_USERS</option>
                            <option *ngFor="let role of allRoles" value="{{ role.Id }}">{{ role.Name }}</option>
                        </select>
                    </div>
                    <div class="col-9 col-md-9">
                        <div class="ips-input-group-list list-group">
                            <div class="list-group-item mb-3">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <input type="radio" [value]="true" name="{{ruleIndex}}.allLocations" [(ngModel)]="rule.AllLocations"/>
                                        </span>
                                    </div>
                                    <div class="form-control form-control-lg input-full" translate>ALL_LOCATIONS</div>
                                </div>
                            </div>

                            <div class="ml-5" *ngIf="rule.AllLocations">
                                <div class="ips-input-group-list list-group mb-3">
                                    <div class="list-group-item p-3">
                                        <div class="row">
                                            <div class="col-12">
                                                <p class="mb-0" *ngFor="let shipMethodId of rule.ShipMethodIds">{{ shipMethodName(shipMethodId) }}</p>
                                                <button class="btn btn-sm btn-link text-info px-0" (click)="chooseAllLocationsShippingMethods(ruleIndex)" translate>MANAGE_SELECTIONS <span class="fa fa-pencil"></span></button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="list-group-item mb-3">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <input type="radio" [value]="false" name="{{ruleIndex}}.allLocations" [(ngModel)]="rule.AllLocations"/>
                                        </span>
                                    </div>
                                    <div class="form-control form-control-lg input-full" translate>LOCATION_SPECIFIC</div>
                                </div>
                            </div>


                            <div class="ml-5" *ngIf="!rule.AllLocations">
                                <div class="input-group ips-textarea mb-3">
                                    <textarea name="{{ruleIndex}}.locationGroupName" ipsAutoGrow
                                                  disabled="{{ rule.AllLocations }}"
                                                  class="form-control form-control-lg"
                                                  [(ngModel)]="rule.LocationGroupName"
                                                  [ngClass]="{ 'invalid': fieldTouched('locationGroupName') && !rule.LocationGroupName }"
                                                  placeholder="{{ 'SELECT_LOCATION_FEATURE' | translate }}"
                                                  [typeahead]="locationShippingGroupSources[ruleIndex]"
                                                  [typeaheadAsync]="true"
                                                  typeaheadWaitMs="300"
                                                  typeaheadOptionField="Name"
                                                  (typeaheadOnSelect)="typeaheadShippingOnSelect($event, ruleIndex)"></textarea>
                                    <div class="input-group-append">
                                        <span class="input-group-text" *ngIf="rule.PendingLocations" (click)="showLocations(rule.LocationGroupId, 0)">
                                            <i class="fa fa-exclamation-triangle text-warning" ngbTooltip="{{ 'MARKET_PENDING_LOCATIONS_WARNING_TOKENIZED' | translate:{ count: rule.PendingLocations } }}" placement="bottom"></i>
                                        </span>
                                        <button class="btn btn-default last" type="button" (click)="selectShippingLocationGroup(ruleIndex)">
                                            <i class="fa fa-lg fa-search pl-1 pr-1"></i>
                                        </button>
                                    </div>
                                </div>


                                <p class="text-danger font-italic mt-2 mb-0" *ngIf="formTouched && !rule.LocationGroupName" translate>SELECT_A_LOCATION_FEATURE</p>
                                <div class="ml-5">
                                    <div class="ips-flex-loading-box mb-4" *ngIf="loadingShippingSubGroups[ruleIndex]">
                                        <div class="content">
                                            <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
                                            <span class="d-block" translate>ACCESSING_LOCATION_GROUP_INFO</span>
                                        </div>
                                    </div>
                                    <div *ngIf="!loadingShippingSubGroups[ruleIndex]">
                                        <div *ngFor="let subGroup of rule.SubGroupRules; let subGroupIndex = index" class="ips-input-group-list list-group mb-3">
                                            <div class="list-group-item p-3">
                                                <!--<h6 class="mt-0 mb-3 required">{{ subGroup.LocationSubGroupName }}</h6>-->
                                                <div class="row">
                                                    <div class="col-12">
                                                        <span class="font-weight-bold font-italic mb-2">{{ subGroup.LocationSubGroupName }}</span>
                                                        <p class="mb-0" *ngFor="let shipMethodId of subGroup.ShipMethodIds">{{ shipMethodName(shipMethodId) }}</p>
                                                        <br/><button class="btn btn-sm btn-link text-info px-0" (click)="chooseShippingMethods(ruleIndex, subGroupIndex)" translate>MANAGE_SELECTIONS <span class="fa fa-pencil"></span></button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div><!--end if !rule.AllLocations-->



                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-sm btn-link text-info px-0" (click)="removeShippingRule(ruleIndex)" translate>REMOVE_RULE <span class="fa fa-remove"></span></button>
                    </div>
                </div>
                <hr/>
            </div>


        </tab>
            <tab *ngIf="canAccessPaymentSettings" id="paymentSettings">
                <ng-template tabHeading>
                    <span translate>PAYMENT_METHODS</span> <span class="fa fa-fw fa-exclamation-triangle text-danger" *ngIf="!paymentMethodsValid"></span>
                </ng-template>

                <h6 class="view-info-label ml-3" translate>AVAILABLE_PAYMENT_METHODS</h6>
                <div class="row">
                    <div class="col-12 col-md-8">
                        <div class="list-group mb-4">
                            <div class="list-group-item p-3">
                                <h6 class="font-weight-bold mt-0 mb-3 required" translate>SHOP_ADMIN_PAYMENT_METHODS</h6>
                                <div class="row">
                                    <div class="col-12 col-md-9">
                                        <ul class="ips-input-group-list list-group">
                                            <li class="list-group-item" (click)="toggleInvoice(PaymentMethodSettings.AdminSettings)">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <input type="checkbox" name="adminInvoiceEnabled" [(ngModel)]="PaymentMethodSettings.AdminSettings.InvoiceEnabled" />
                                                        </span>
                                                    </div>
                                                    <div class="form-control form-control-lg input-full"
                                                         [ngClass]="{ 'invalid': formTouched && !PaymentMethodSettings.AdminSettings.IsValid }" translate>INVOICE</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item" (click)="toggleCreditCard(PaymentMethodSettings.AdminSettings)">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <input type="checkbox" name="adminCreditCardEnabled" [(ngModel)]="PaymentMethodSettings.AdminSettings.CreditCardEnabled" />
                                                        </span>
                                                    </div>
                                                    <div class="form-control form-control-lg input-full"
                                                         [ngClass]="{ 'invalid': formTouched && !PaymentMethodSettings.AdminSettings.IsValid }" translate>CREDIT_CARD</div>
                                                </div>
                                            </li>
                                        </ul>
                                        <p class="text-danger font-italic mt-2 mb-0" *ngIf="formTouched && !PaymentMethodSettings.AdminSettings.IsValid" translate>SELECT_A_PAYMENT_METHOD</p>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item p-3">
                                <h6 class="font-weight-bold mt-0 mb-3 required" translate>SHOPPER_PAYMENT_METHODS</h6>
                                <div class="row">
                                    <div class="col-12 col-md-9">
                                        <div class="ips-input-group-list list-group">
                                            <div class="list-group-item mb-3" (click)="setAllLocations(PaymentMethodSettings.ShopperSettings, true)">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <input type="radio" [value]="true" name="allLocations" [(ngModel)]="PaymentMethodSettings.ShopperSettings.AllLocations" />
                                                        </span>
                                                    </div>
                                                    <div class="form-control form-control-lg input-full" translate>ALL_LOCATIONS</div>
                                                </div>
                                            </div>
                                            <div class="ips-input-group-list list-group ml-5 mb-3">
                                                <div class="list-group-item" (click)="allLocationsEnabled && toggleInvoice(PaymentMethodSettings.ShopperSettings)">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <input name="allLocationsInvoice" type="checkbox"
                                                                       disabled="{{ allLocationsDisabled }}"
                                                                       [(ngModel)]="PaymentMethodSettings.ShopperSettings.InvoiceEnabled" />
                                                            </span>
                                                        </div>
                                                        <div class="form-control form-control-lg input-full"
                                                             [ngClass]="{ 'ips-gray-white-bg': !allLocationsDisabled, 'invalid': formTouched && allLocationsEnabled && !PaymentMethodSettings.ShopperSettings.IsValid }"
                                                             translate>INVOICE</div>
                                                    </div>
                                                </div>
                                                <div class="list-group-item" (click)="allLocationsEnabled && toggleCreditCard(PaymentMethodSettings.ShopperSettings)">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <input name="allLocationsCC" type="checkbox"
                                                                       disabled="{{ allLocationsDisabled }}"
                                                                       [(ngModel)]="PaymentMethodSettings.ShopperSettings.CreditCardEnabled" />
                                                            </span>
                                                        </div>
                                                        <div class="form-control form-control-lg input-full"
                                                             [ngClass]="{ 'ips-gray-white-bg': !allLocationsDisabled, 'invalid': formTouched && allLocationsEnabled && !PaymentMethodSettings.ShopperSettings.IsValid }"
                                                             translate>CREDIT_CARD</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="text-danger font-italic ml-5" *ngIf="formTouched && allLocationsEnabled && !PaymentMethodSettings.ShopperSettings.IsValid" translate>SELECT_A_PAYMENT_METHOD</p>
                                            <div class="list-group-item mb-3" (click)="setAllLocations(PaymentMethodSettings.ShopperSettings, false)">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">
                                                            <input type="radio" [value]="false" name="specificLocations" [(ngModel)]="PaymentMethodSettings.ShopperSettings.AllLocations" />
                                                        </span>
                                                    </div>
                                                    <div class="form-control form-control-lg input-full" translate>LOCATION_SPECIFIC</div>
                                                </div>
                                            </div>
                                            <div class="ml-5" *ngIf="!PaymentMethodSettings.ShopperSettings.AllLocations">
                                                <div class="input-group ips-textarea mb-3">
                                                    <textarea name="locationGroupName" ipsAutoGrow
                                                              disabled="{{allLocationsEnabled}}"
                                                              class="form-control form-control-lg"
                                                              [(ngModel)]="selectedLocationGroupName"
                                                              [ngClass]="{ 'invalid': fieldTouched('locationGroupName') && !PaymentMethodSettings.ShopperSettings.LocationGroupName }"
                                                              placeholder="{{ 'SELECT_LOCATION_FEATURE' | translate }}"
                                                              [typeahead]="locationGroupSource"
                                                              [typeaheadAsync]="true"
                                                              typeaheadWaitMs="300"
                                                              typeaheadOptionField="Name"
                                                              (typeaheadOnSelect)="typeaheadOnSelect($event)"></textarea>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" *ngIf="PaymentMethodSettings.ShopperSettings.PendingLocations" (click)="showLocations(PaymentMethodSettings.ShopperSettings.LocationGroupId, 0)">
                                                            <i class="fa fa-exclamation-triangle text-warning" ngbTooltip="{{ 'MARKET_PENDING_LOCATIONS_WARNING_TOKENIZED' | translate:{ count: PaymentMethodSettings.ShopperSettings.PendingLocations } }}" placement="bottom"></i>
                                                        </span>
                                                        <button class="btn btn-default last" type="button" (click)="selectLocationGroup()">
                                                            <i class="fa fa-lg fa-search pl-1 pr-1"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <p class="text-danger font-italic mt-2 mb-0" *ngIf="formTouched && !PaymentMethodSettings.ShopperSettings.LocationGroupId" translate>SELECT_A_LOCATION_FEATURE</p>
                                                <div class="ml-5">
                                                    <div class="ips-flex-loading-box mb-4" *ngIf="loadingSubGroups">
                                                        <div class="content">
                                                            <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
                                                            <span class="d-block" translate>ACCESSING_LOCATION_GROUP_INFO</span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!loadingSubGroups">
                                                        <div *ngFor="let subGroup of PaymentMethodSettings.ShopperSettings.LocationGroupSettings" class="ips-input-group-list list-group mb-3">
                                                            <div class="list-group-item p-3">
                                                                <h6 class="mt-0 mb-3 required">{{ subGroup.SubGroupName }}</h6>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <ul class="ips-input-group-list list-group">
                                                                            <li class="list-group-item" (click)="toggleInvoice(subGroup)">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <input disabled="{{ allLocationsEnabled}}" type="checkbox" name="{{subGroup.SubGroupId}}invoice" [(ngModel)]="subGroup.InvoiceEnabled" />
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="form-control form-control-lg input-full"
                                                                                         [ngClass]="{ 'ips-gray-white-bg': !allLocationsEnabled, 'invalid': formTouched && !subGroup.IsValid }" translate>INVOICE</div>
                                                                                </div>
                                                                            </li>
                                                                            <li class="list-group-item" (click)="toggleCreditCard(subGroup)">
                                                                                <div class="input-group">
                                                                                    <div class="input-group-prepend">
                                                                                        <span class="input-group-text">
                                                                                            <input disabled="{{ allLocationsEnabled }}" type="checkbox" name="{{subGroup.SubGroupId}}cc" [(ngModel)]="subGroup.CreditCardEnabled" />
                                                                                        </span>
                                                                                    </div>
                                                                                    <div class="form-control form-control-lg input-full"
                                                                                         [ngClass]="{ 'ips-gray-white-bg': !allLocationsEnabled, 'invalid': formTouched && !subGroup.IsValid }" translate>CREDIT_CARD</div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                        <p class="text-danger font-italic mt-2 mb-0" *ngIf="formTouched && !subGroup.IsValid" translate>SELECT_A_PAYMENT_METHOD</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab *ngIf="canAccessHandlingSettings" id="handlingSettings" ngModelGroup="handlingSettingsGroup" #handlingSettingsGroup="ngModelGroup">
                <ng-template tabHeading>
                    <span translate>HANDLING_CHARGES</span> <span class="fa fa-fw fa-exclamation-triangle text-danger" *ngIf="handlingSettingsGroup.invalid"></span>
                </ng-template>
                <h6 class="view-info-label ml-3" translate>FULLFILMENT_HANDLING_CHARGES</h6>
                <div class="form-row no-gutters">
                    <div class="col-auto form-group">
                        <label translate>ORDER_CHARGE_HANDLING</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">$</div>
                            </div>
                            <input name="perAddress" type="number" class="form-control" (change)="setMinDecimals($event)" [ngModelOptions]="{updateOn: 'blur'}"
                                   (ngModelChange)="HandlingFeeSettings.PerAddress.Value=$event" [ngModel]="HandlingFeeSettings.PerAddress.Value | number: '1.2-10'" required>
                        </div>
                    </div>
                    <div class="col-auto form-group">
                        <label translate>LINE_ITEM_CHARGE_HANDLING</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">$</div>
                            </div>
                            <input name="perLine" type="number" class="form-control" (change)="setMinDecimals($event)" [ngModelOptions]="{updateOn: 'blur'}"
                                   (ngModelChange)="HandlingFeeSettings.PerLine.Value=$event" [ngModel]="HandlingFeeSettings.PerLine.Value | number: '1.2-10'">
                        </div>
                    </div>
                    <div class="col-auto form-group">
                        <label translate>PICK_CHARGE_HANDLING</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">$</div>
                            </div>
                            <input name="perEach" type="number" class="form-control" (change)="setMinDecimals($event)" [ngModelOptions]="{updateOn: 'blur'}"
                                   (ngModelChange)="HandlingFeeSettings.PerEach.Value=$event" [ngModel]="HandlingFeeSettings.PerEach.Value | number: '1.2-10'">
                        </div>
                    </div>
                </div>

            </tab>
            <!--Email Alerts. ngModelGroup used to allow for checking for valid controls just for email alerts-->
            <tab *ngIf="canAccessAlertSettings" id="alertSettings" ngModelGroup="alertSettingsGroup" #alertSettingsGroup="ngModelGroup">
                <ng-template tabHeading>
                    <span translate>EMAIL_ALERTS</span> <span class="fa fa-fw fa-exclamation-triangle text-danger" *ngIf="alertSettingsGroup.invalid"></span>
                </ng-template>
                <div>
                    <div class="row">
                        <h6 class="col-6 view-info-label no-colon" translate>EMAIL</h6>
                        <h6 class="col-6 view-info-label no-colon" translate>ALERT</h6>
                    </div>
                    <div *ngFor="let user of AlertSettings.UserAlertAssignments" class="form-row mb-3">
                        <div class="form-group col-6">
                            <div class="d-flex flex-row">
                                <input type="email"
                                       [(ngModel)]="user.Email"
                                       #emailInput="ngModel"
                                       name="{{user.HtmlElementName}}"
                                       required class="form-control form-control-lg flex-grow-1"
                                       ipsFocus="true"
                                       appDuplicateValidator="userAlert"
                                       email="true" />
                                <button type="button"
                                        class="clicker input-x text-center remove-x pr-0"
                                        (click)="deleteAlertEmail(user)"
                                        ngbTooltip="{{'DELETE' | translate}}"
                                        placement="bottom-right"
                                        #tOption="ngbTooltip">
                                    <i class="fa fa-remove fa-lg"></i>
                                </button>
                            </div>
                            <ips-show-errors [control]="emailInput" [errorMessages]="alertErrorMessages"></ips-show-errors>
                        </div>
                        <div class="col-6">
                            <ul class="ips-input-group-list list-group">
                                <li *ngFor="let assignment of user.Assignments" class="list-group-item">
                                    <!--<p>AlertName: {{assignment.AlertName}} AlertId: {{assignment.AlertId}}</p>-->
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <input type="checkbox" name="option{{user.HtmlElementName}}alId{{assignment.AlertId}}" [(ngModel)]="assignment.Selected" />
                                            </span>
                                        </div>
                                        <div class="form-control form-control-lg input-full">{{assignment.AlertName}}</div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button id="addAlertEmail"
                                    class="btn btn-lg btn-block add-promotion ips-add-bar mb-4"
                                    (click)="addAlertEmail()">
                                <i class="fa fa-plus addIconLarge" aria-hidden="true"></i>
                                <span class="add-holder-label" translate>ADD_EMAIL</span>
                                <span class="pull-right color-gray" ngbTooltip="{{ 'ADD_EMAIL' | translate }}" placement="bottom">
                                    <i class="fa fa-info-circle"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </tab>
        </tabset>
    </div>
</form>

<div class="row" *ngIf="screenReady">
    <div class="col-12 col-sm-6 col-lg-3">
        <div class="form-group">
            <button id="saveCampaign"
                    [disabled]="!isDirty || !paymentMethodsValid || settingsForm.invalid"
                    class="btn btn-lg btn-block alert-success"
                    [ngClass]="{ 'btn-default': !isDirty || !paymentMethodsValid  || settingsForm.invalid}"
                    (click)="savePreferencesPrompt(false)" translate>
                SAVE
            </button>
        </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3">
        <div class="form-group">
            <button id="saveReturnCampaign"
                    [disabled]="!isDirty || !paymentMethodsValid || settingsForm.invalid"
                    class="btn btn-lg btn-block alert-success"
                    [ngClass]="{ 'btn-default': !isDirty || !paymentMethodsValid  || settingsForm.invalid}"
                    (click)="savePreferencesPrompt(true)" translate>
                SAVE_AND_RETURN
            </button>
        </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3">
        <div class="form-group">
            <button id="cancelCampaign" class="btn btn-default btn-lg btn-block" uiSref="main.toolsControls" type="submit" translate>CANCEL</button>
        </div>
    </div>
</div>
