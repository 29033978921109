/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "imagine-ui-ng-security";
import * as i2 from "@uirouter/angular/lib/directives/uiSref";
import * as i3 from "@uirouter/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./shop-landing.component";
var styles_ShopLandingComponent = [];
var RenderType_ShopLandingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShopLandingComponent, data: {} });
export { RenderType_ShopLandingComponent as RenderType_ShopLandingComponent };
export function View_ShopLandingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "section", [["class", "col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription"], ["ipsPermission", "main.myproducts"]], null, null, null, null, null)), i0.ɵdid(1, 4276224, null, 0, i1.ɵb, [i0.Renderer2, i0.ElementRef, i1.PermissionService], { ipsPermission: [0, "ipsPermission"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 6, "a", [["class", "landingNavLink"], ["uiSref", "main.myproducts"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i2.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(4, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "myProductsImg landingNavIcon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "h3", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(7, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["MY_PRODUCTS"]))], function (_ck, _v) { var currVal_0 = "main.myproducts"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "main.myproducts"; _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ShopLandingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-shop-landing", [], null, null, null, View_ShopLandingComponent_0, RenderType_ShopLandingComponent)), i0.ɵdid(1, 114688, null, 0, i5.ShopLandingComponent, [i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShopLandingComponentNgFactory = i0.ɵccf("app-shop-landing", i5.ShopLandingComponent, View_ShopLandingComponent_Host_0, {}, {}, []);
export { ShopLandingComponentNgFactory as ShopLandingComponentNgFactory };
