import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
import { SearchInfo } from "imagine-ui-ng-core";

@Injectable()
export class AlternateAddressService extends IpsBaseWebService {
    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("AlternateAddress", "Profile", "v1");
    }

    get<T>(id: number): Promise<T> {
        return this.getByRoute(`${this.urlApiBaseProductPlusVersion}AlternateAddress/Address/${id}?AddressType=Campaign`);
    }
    head(): Promise<number> {
        return super.headByRoute(`${this.urlApiBaseProductPlusVersion}AlternateAddress/Address?AddressType=Campaign`);
    }
    post<T>(inputData): Promise<T> {
        return super.post(inputData, null, `${this.urlApiBaseProductPlusVersion}AlternateAddress/Address`);
    }

    put<T>(inputData: any): Promise<T> {
        return super.put(inputData, null, `${this.urlApiBaseProductPlusVersion}AlternateAddress/Address`);
    }

    delete(id): Promise<any> {
        return super.deleteByRoute(`${this.urlApiBaseProductPlusVersion}AlternateAddress/Address/${id}`);
    }
}
