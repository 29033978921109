<div class="col-12">
    <div class="row">
        <section class="col-12">
            <h3>{{breadCrumbLabel | translate}}</h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{message:{{'ACCESSING_ELEMENT_INFO' | translate}}'}">
        <div *ngIf="loaded">
            <form id="ramiInfoInput" class="form-horizontal" name="elementForm" [formGroup]="elementForm" novalidate ipsUnsavedWarningForm [ipsFocus]="focusInput">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="form-row">
                            <div class="form-group col-lg-9">
                                <label class="required" for="Name" translate>NAME</label>
                                <input type="text" name="nameInput" class="form-control form-control-lg input-full" id="Name" placeholder="{{ 'ENTER_ELEMENT_NAME' | translate}}" formControlName="Name" />
                                <ips-show-errors [control]="Name" [errorMessages]="getNameErrorMessages()" cssClass="validation-message-Qty"></ips-show-errors>
                            </div>
                            <div class="col-auto mb-2">
                                <label class="d-none d-md-block">&nbsp;</label>
                                <div class="input-group" (click)="toggleObsolete(elementForm)">
                                    <div class="form-control form-control-lg input-full">{{'OBSOLETE' | translate}}</div>
                                    <div class="input-group-append">
                                        <span class="input-group-text"> <input formControlName="IsObsolete" name="IsObsolete" id="IsObsolete" type="checkbox"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-2 mb-0">
                                <label class="required" translate>WIDTH</label>
                            </div>
                            <div class="form-group col-2 mb-0">
                                <label class="required" translate>HEIGHT</label>
                            </div>
                            <div class="form-group col-4 mb-0">
                                <label translate>CATEGORY_CODE</label>
                            </div>
                            <div class="form-group col-3 mb-0" *ngIf="enableSignPlan">
                                <label>Sign Plan Transformations</label>
                            </div>
                        </div>

                        <div formArrayName="Details">
                            <div *ngFor="let item of Details.controls; let i=index">
                                <div class="form-row" [formGroupName]="i">
                                    <div class="form-group col-2 mb-0">
                                        <input type="text"
                                               formControlName="Width"
                                               class="form-control form-control-lg input-full"
                                               placeholder="{{ 'ENTER_WIDTH' | translate}}"
                                               name="{{Details.controls[i].get('InputName').value + 'Width'}}"
                                               (blur)="msgPrompt(Details.controls[i])">
                                    </div>
                                    <div class="form-group col-2 mb-0">
                                        <input type="text"
                                               formControlName="Height"
                                               class="form-control form-control-lg input-full"
                                               placeholder="{{ 'ENTER_HEIGHT' | translate}}"
                                               name="{{Details.controls[i].get('InputName').value + 'Height'}}"
                                               (blur)="msgPrompt(Details.controls[i])">
                                    </div>
                                    <div class="form-group col-4 mb-0">
                                        <input type="text"
                                               formControlName="CategoryCode"
                                               class="form-control form-control-lg input-full"
                                               placeholder="{{ 'ENTER_CATEGORY_CODE' | translate}}"
                                               name="{{Details.controls[i].get('InputName').value + 'CategoryCode'}}"
                                               maxlength="100">
                                    </div>
                                    <div class="form-group col-3" *ngIf="enableSignPlan">
                                        <app-sign-plan-transform-detail (changesAccepted)="onSignPlanTransformation($event, Details.controls[i])"
                                                                        [id]="item.value.Id"
                                                                        type="Element"
                                                                        [top]="item.value.CropTop"
                                                                        [left]="item.value.CropLeft"
                                                                        [width]="item.value.CropWidth"
                                                                        [height]="item.value.CropHeight"
                                                                        [rotation]="item.value.CropRotation">
                                        </app-sign-plan-transform-detail>
                                    </div>
                                    <div class="form-group col-1 mb-0 pt-2">
                                        <button class="clicker input-x col-1 pl-0" (click)="removeDetail(i)" ngbTooltip="{{'DELETE_SIZE' | translate}}" placement="bottom"><i class="fa fa-remove fa-lg"></i></button>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-5">
                                        <ips-show-errors [control]="item.get('Width')" [errorMessages]="getWidthErrorMessages()"></ips-show-errors>
                                    </div>
                                    <div class="form-group col-5">
                                        <ips-show-errors [control]="item.get('Height')" [errorMessages]="getHeightErrorMessages()"></ips-show-errors>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-12">
                                <button type="button" class="btn btn-lg btn-block add-holder" (click)="addDetail()" name="addSizeInput" [ipsFocus]="focusableWidthInput">
                                    <i class="fa fa-plus addIconLarge add-holder-icon" aria-hidden="true"></i>
                                    <span class="add-holder-label" translate>ADD_ANOTHER_SIZE</span>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="notes" translate>NOTES</label>
                            <textarea class="form-control form-control-lg input-full" name="Notes" id="Notes" cols="30" rows="3" formControlName="Notes" placeholder="{{ 'ENTER_NOTES' | translate}}"></textarea>
                            <ips-show-errors [control]="Notes" [errorMessages]="errorMessages"></ips-show-errors>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveElement"
                                    class="btn btn-lg btn-block alert-success"
                                    (click)="saveElementPrompt(false)"
                                    [disabled]="elementForm.invalid || elementForm.pristine"
                                    [ngClass]="{'btn-default': elementForm.invalid || elementForm.pristine}" translate>
                                SAVE_ELEMENT
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="saveReturnElement"
                                    class="btn btn-lg btn-block alert-success" type="submit"
                                    (click)="saveElementPrompt(true)"
                                    [disabled]="elementForm.invalid || elementForm.pristine"
                                    [ngClass]="{'btn-default': elementForm.invalid || elementForm.pristine}" translate>
                                SAVE_AND_RETURN
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="cancelElement" class="btn btn-lg btn-block btn-default" type="submit" uiSref="main.storeProfileElement.search" translate>CANCEL</button>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="form-group mt-2">
                            <button id="deleteElement"
                                    class="btn btn-lg btn-block alert-danger"
                                    (click)="deleteElementPrompt()"
                                    ips-unsaved-warning-clear
                                    [disabled]="!elementForm.value.Id"
                                    [ngClass]="{'btn-default': !elementForm.value.Id}" translate>
                                DELETE_ELEMENT
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_ELEMENT_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
