<div [formGroup]="parent">
    <div formGroupName="messageForm">
        <div formArrayName="PatternGroups">
            <div *ngFor="let patternGroup of PatternGroups.controls; let i=index; let messageLast = last;">
                <div [formGroupName]="i">
                    <div class="form-row">
                        <!-- Message -->
                        <div class="col-12" formArrayName="MessagePatterns">
                            <div>
                                <div *ngFor="let message of MessagePatterns(i).controls; let j=index" class="card ips-shadow mb-4" attr.stickyHeader="{{message.get('MessageName').value}}">
                                    <div class="card-body pb-4" [formGroupName]="j">
                                        <div class="row">
                                            <div class="form-group col-12">
                                                <label class="required" for="MessageName" translate>MESSAGE</label>
                                                <div>
                                                    <textarea formControlName="MessageName"
                                                              [typeahead]="messageNames"
                                                              [typeaheadOptionsLimit]="20"
                                                              [typeaheadMinLength]="0"
                                                              container="body" type="text"
                                                              name="{{message.get('InputName').value}}"
                                                              class="form-control form-control-lg ips-no-resize" (click)="setFocus($event)" (blur)="updateMessageTypeahead($event)" rows="1" ipsAutoGrow></textarea>
                                                </div>
                                                <ips-show-errors [control]="message.get('MessageName')" [errorMessages]="getErrorMessages('message')" cssClass="validation-message-Qty"></ips-show-errors>
                                            </div>
                                            <div class="ips-top-right-controls">
                                                <button class="clicker input-x" (click)="removeMainMessagePrompt(i, message, t)"><i class="fa fa-remove fa-lg tooltip-container" ngbTooltip="{{'TOOLTIP_DELETE_MESSAGE' | translate}}" placement="bottom-right" #t="ngbTooltip"></i></button>
                                            </div>
                                            <div class="col-12">
                                                <app-market-edit [parent]="patternGroup"
                                                                 [marketsModel]="getOriginalMarkets(patternGroup)"
                                                                 [label]="'MARKET'"
                                                                 [balanceCalcService]="promotionMessageService"
                                                                 [promoStartDate]="parent.value.StartDate"
                                                                 [promoEndDate]="parent.value.EndDate"
                                                                 (marketChanged)="marketChangedEventHandler($event)"></app-market-edit>
                                            </div>
                                            <div class="col-12">
                                                <app-custom-data-field-container area="PromotionMessage" [linkId]="message.get('PromotionMessageId').value" [parent]="message" (loaded)="cdfLoadedHandler()"></app-custom-data-field-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-12 mt-2 mb-2">
                <button type="button" class="btn btn-lg btn-block text-left ips-add-bar pr-2" (click)="createMessage()" [ipsFocus]="focusableMessageInput">
                    <i class="fa fa-plus addIconLarge" aria-hidden="true"></i>
                    <span class="add-label" translate>CREATE_MESSAGE</span>
                    <span class="pull-right color-gray">
                        <i class="fa fa-info-circle tooltip-container" ngbTooltip="{{'MESSAGE_HEADER_TIP' | translate}}" placement="bottom-right"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
