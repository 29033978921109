/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./select-list.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_SelectListComponent = [i0.styles];
var RenderType_SelectListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectListComponent, data: {} });
export { RenderType_SelectListComponent as RenderType_SelectListComponent };
function View_SelectListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-check fa-2x pr-3 location-check clicker"]], null, null, null, null, null))], null, null); }
function View_SelectListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "card ips-shadow mb-2 subgroup-card d-flex flex-row align-items-center clicker"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "ips-checked-card": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "p-2 pl-3 flex-grow-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectItem(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectListComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "card ips-shadow mb-2 subgroup-card d-flex flex-row align-items-center clicker"; var currVal_1 = _ck(_v, 2, 0, _v.context.$implicit.selected); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.selected; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _v.context.$implicit[_co.labelField]; _ck(_v, 4, 0, currVal_2); }); }
function View_SelectListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "ips-ls-long-text-wrap list-group mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-scrollable list-search-infinite-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectListComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-default btn-block btn-lg"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["Choose ", " item(s)"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.internalItems; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.selectedItemCount() === 0); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.selectedItemCount(); _ck(_v, 6, 0, currVal_2); }); }
export function View_SelectListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "button", [["aria-label", "Close"], ["class", "fa fa-remove ips-close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ips-modal-header ips-modal-dark-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "modal-title m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectListComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.multiSelect; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); }); }
export function View_SelectListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ips-select-list", [], null, null, null, View_SelectListComponent_0, RenderType_SelectListComponent)), i1.ɵdid(1, 114688, null, 0, i3.SelectListComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectListComponentNgFactory = i1.ɵccf("app-ips-select-list", i3.SelectListComponent, View_SelectListComponent_Host_0, {}, {}, []);
export { SelectListComponentNgFactory as SelectListComponentNgFactory };
