import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { KeyValuePair } from "../model/KeyValuePair";
import { environment } from "../../../environments/environment";
import { OrderStatus } from "../type/OrderStatus";
import { OrderItemDetailsModel } from "../model/OrderItemDetailsModel";
import { ShipmentModel } from "../model/ShipmentModel";
import { PackageModel } from "../model/PackageModel";
import { FileDownloadModel } from "../model/FileDownloadModel";

@Injectable()
export class OrderService extends IpsBaseWebService {

    constructor(http: HttpClient,
        public activeProfileService: ActiveProfileService,
        private authService: AuthService
    ) {
        super(http, <any>environment, activeProfileService);
        this.init("FulfillmentOrder", "Shop", "v1");
    }

    public orderStatusList(): Promise<OrderStatus[]> {
        const route = `${this.urlApiBasePlusController}Status`;
        return this.getByRoute(route);
    }

    public getItemDownloadUrl(itemId: number, locationId: number, orderItemId: number, orderReason: string): Promise<FileDownloadModel> {
        let route = `${this.urlApiBasePlusController}PodDownloadUrl`;

        if (itemId && orderItemId) {
            route = `${route}?ItemId=${itemId}&LocationId=${locationId}&OrderItemId=${orderItemId}`;
        } else if (itemId) {
            route = `${route}?ItemId=${itemId}&LocationId=${locationId}}`;
        } else if (orderItemId) {
            route = `${route}?OrderItemId=${orderItemId}&LocationId=${locationId}`;
        }
        if (orderReason) {
            route = `${route}&OrderReason=${orderReason}`;
        }

        const returnPromise = new Promise<FileDownloadModel>((resolve, reject) => {
            this.getByRoute(route).then((response: FileDownloadModel) => {
                resolve(response);
            });
        });

        return returnPromise;
    }

    public getStaticItemDownloadUrl(productCode: string, itemId: number, locationId: number, orderItemId: number): Promise<FileDownloadModel> {
        let route = `${this.urlApiBasePlusController}PodDownloadUrl`;

        if (itemId && orderItemId) {
            route = `${route}?ItemId=${itemId}&LocationId=${locationId}&OrderItemId=${orderItemId}`;
        } else if (itemId) {
            route = `${route}?ItemId=${itemId}&LocationId=${locationId}`;
        } else if (orderItemId) {
            route = `${route}?OrderItemId=${orderItemId}&LocationId=${locationId}`;
        }

        const returnPromise = new Promise<FileDownloadModel>((resolve, reject) => {
            this.getByRoute(route).then((response: any) => {
                const baseUrl = this.urlApiBase;
                const businessId = this.activeProfileService.businessIdentity;
                const token = this.authService.getIdToken();
                const mediaType = response.MediaType;
                const mediaId = response.MasterMediaId;

                const fileName = `${productCode}.${mediaType.toLowerCase()}`;
                const url = `${baseUrl}Media/v1/MasterMedia/${businessId}/Item/${mediaId}/Original/${mediaType}?BusinessIdentity=${businessId}&idToken=${token}`;

                resolve({
                    Url: url,
                    FileName: fileName
                });
            });
        });

        return returnPromise;
    }

    public getOrderItemDetails(orderId: number): Promise<OrderItemDetailsModel[]> {
        const route = `${this.urlApiBasePlusController}${orderId}/OrderItemDetails`;
        return this.getByRoute(route);
    }

    public getTrackingData(item: OrderItemDetailsModel): KeyValuePair<string, string>[] {
        const packages: PackageModel[] = [];

        item.Shipments.forEach((shipment: ShipmentModel) => {
            packages.unshift(...shipment.Packages);
        });

        const packagesWithTrackingData = packages.filter((pkg: PackageModel) => {
            return pkg.TrackingNumber;
        });

        return packagesWithTrackingData.map((pkg: PackageModel) => {
            return {
                Key: pkg.TrackingNumber,
                Value: pkg.TrackingURL || null
            };
        });
    }
}
