<div class="col-md-6" *ngIf="translated" ipsFocus="searchInput">
    <ips-list-search headerLabel="{{'RELATED_STORE_FRONT_CATEGORIES' | translate }}"
                     [searchLabel]="searchLabel"
                     [searchLabelPlural]="searchLabelPlural"
                     labelLoading="{{'ACCESSING_STORE_FRONT_CATEGORY_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_STORE_FRONT_CATEGORY_INFO' | translate }}"
                     [listData]="storeFrontCategory"
                     [busy]="busy"
                     (onListDataChange)="getListData($event.search)"
                     [section]="'main.storeFrontCategory'"
                     [scroller]=true
                     [quickRef]=false
                     [promise]="promise"
                     [itemClickPermission]="'main.storeFrontCategory.view'"
                     [createNewTooltip]="createNewTooltip"
                     [autoFocus]=true>
                     <ng-template #listSearchItemTemplate let-result="result">
                        <a uiSref="main.storeFrontCategory.view" [uiParams]="{id:result.Id}" class="list-group-item list-group-item-action no-border d-flex justify-content-between align-items-center">
                             {{result.Name}}
                            <span ipsPermission="main.storeFrontCategory.edit">
                                <a uiSref="main.storeFrontCategory.edit" [uiParams]="{ id: result.Id }" class="no-underline">
                                    <div class="d-flex justify-content-end">
                                        <span class="fa fa-pencil"></span>
                                    </div>
                                </a>
                            </span>
                        </a>
                    </ng-template>
    </ips-list-search>
</div>