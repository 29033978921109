import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ShippingAddress } from "../../imagine-ui-ng-shop/index";

@Component({
    selector: "app-suggested-addresses-modal",
    templateUrl: "./suggested-addresses-modal.component.html",
    styleUrls: ["./suggested-addresses-modal.component.scss"]
})

export class SuggestedAddressModalComponent implements OnInit {
    // ----- DATA -----
    public suggestedAddresses: ShippingAddress[];
    public invalidAddress: ShippingAddress;
    public addresses: string[];
    public selected?: number;

    constructor(
        private translateService: TranslateService,
        private modalInstance: NgbActiveModal
    ) {
        this.addresses = [];
        this.selected = null;
    }

    // ----- FUNCTIONS -----
    //
    private translateText() {
        this.translateService.get([
            "FOUND_OF", "TOTAL", "SELECT_LOCATIONS", "CANCEL", "LOCATION", "LOCATIONS",
        ]).subscribe((res: [string]) => {
            // this.shipMethodLabelPlural = res["DELIVERY_OPTIONS"];
        });
    }

    //
    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    //
    public setLocationAddresses(): void {
        this.suggestedAddresses = this.suggestedAddresses || [];

        for (let i = 0; i < this.suggestedAddresses.length; i++) {
            this.addresses.push(this.formatAddressString(this.suggestedAddresses[i]));
        }
    }

    public formatAddressString(address: ShippingAddress): string {
        return address.Line1 + " " +
            (address.Line2 ? address.Line2 : "") + " " +
            address.City + ", " +
            address.StateProvince + " " +
            address.Country + " " +
            address.PostalCode;
    }

    public selectAddress(index: number, skipValidation: boolean) {
        this.selected = index;
        if (this.selected !== null) {
            this.updateAddress(skipValidation);
        }
    }

    public selectInvalidAddress() {
        this.selected = null;
        this.updateAddress(false);
    }

    //
    public updateAddress(skipValidation: boolean) {
        let selectedAddress = this.invalidAddress;
        if (this.selected !== null && this.selected < this.suggestedAddresses.length) {
            selectedAddress.Line1 = this.suggestedAddresses[this.selected].Line1;
            selectedAddress.Line2 = this.suggestedAddresses[this.selected].Line2;
            selectedAddress.City = this.suggestedAddresses[this.selected].City;
            selectedAddress.StateProvince = this.suggestedAddresses[this.selected].StateProvince;
            selectedAddress.Country = this.suggestedAddresses[this.selected].Country;
            selectedAddress.PostalCode = this.suggestedAddresses[this.selected].PostalCode;
            selectedAddress.ValidationStatus = skipValidation ? "Skipped" : "Validated";
        } else {
            selectedAddress.ValidationStatus = "Skipped";
        }
        this.modalInstance.close(selectedAddress);
    }

    // ----- ON INIT -----
    //
    ngOnInit() {
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
        this.setLocationAddresses();
    }
}
