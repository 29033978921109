import { OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchHelper } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { FixtureGroupService, ElementService } from "../../../imagine-ui-ng-store-profile/index";
var PlacementSearchModalComponent = /** @class */ (function () {
    function PlacementSearchModalComponent(modalInstance, fixtureGroupService, listSearchHelper, ipsMessage, elementService, translateService) {
        this.modalInstance = modalInstance;
        this.fixtureGroupService = fixtureGroupService;
        this.listSearchHelper = listSearchHelper;
        this.ipsMessage = ipsMessage;
        this.elementService = elementService;
        this.translateService = translateService;
        this.query = "";
        this.placementType = "";
        this.elementQuery = "";
        this.previousSelectedFixtureGroups = [];
        this.previousSelectedElements = [];
        this.selectedCount = 0;
        this.busy = true;
        // List of items selected by the user, used to save state between searches
        this.currentSelectedItems = { FixtureGroups: [], Elements: [] };
        this.singleSelect = false;
        this.TranslateStrings = {
            "FIXTURE_GROUP": "",
            "FIXTURE_GROUPS": "",
            "ELEMENT": "",
            "ELEMENTS": "",
            "FIXTURE_GROUP_SELECTED": "",
            "FIXTURE_GROUPS_SELECTED": "",
            "ELEMENT_SELECTED": "",
            "ELEMENTS_SELECTED": "",
            "SELECT_ELEMENT": "",
            "SELECT_FIXTURE_GROUP_HOLDER": ""
        };
        this.pluralMapping = {
            "=1": "FIXTURE_GROUP_SELECTED",
            "other": "FIXTURE_GROUPS_SELECTED"
        };
    }
    PlacementSearchModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.singleSelect = this.resolve.SingleSelect;
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        //load currentSelectedItems from previous selected
        if (this.resolve.PlacementElements) {
            this.populateCurrentSelectedElements(this.resolve.PlacementElements);
            this.selectedCount = this.getSelectedElementDetailCount();
            this.searchLabel = this.TranslateStrings["ELEMENT"];
            this.searchLabel = this.TranslateStrings["ELEMENTS"];
            this.placementType = "element";
            this.title = this.TranslateStrings["SELECT_ELEMENT"];
            this.pluralMapping = {
                "=1": this.translateService.instant("ELEMENT_SELECTED"),
                "other": this.translateService.instant("ELEMENTS_SELECTED")
            };
            this.listSearch = this.listSearchHelper.getListSearchHelper(this.elementService);
            this.elementService.head().then(function (total) {
                _this.listSearch.TotalRecords = total;
            });
        }
        else if (this.resolve.Placements) {
            this.populateCurrentSelectedHolders(this.resolve.Placements);
            this.selectedCount = this.getSelectedHolderCount();
            this.searchLabel = this.TranslateStrings["FIXTURE_GROUP"];
            this.searchLabel = this.TranslateStrings["FIXTURE_GROUPS"];
            this.placementType = "fixture";
            this.title = this.TranslateStrings["SELECT_FIXTURE_GROUP_HOLDER"];
            this.pluralMapping = {
                "=1": this.translateService.instant("FIXTURE_GROUP_SELECTED"),
                "other": this.translateService.instant("FIXTURE_GROUPS_SELECTED")
            };
            this.listSearch = this.listSearchHelper.getListSearchHelper(this.fixtureGroupService);
            this.fixtureGroupService.head().then(function (total) {
                _this.listSearch.TotalRecords = total;
            });
        }
        this.busy = true;
        this.getListData("").then(function () {
            _this.busy = false;
        });
    };
    PlacementSearchModalComponent.prototype.translateText = function () {
        for (var _i = 0, _a = Object.keys(this.TranslateStrings); _i < _a.length; _i++) {
            var key = _a[_i];
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    };
    PlacementSearchModalComponent.prototype.populateCurrentSelectedElements = function (previousSelected) {
        var _this = this;
        previousSelected.forEach(function (item) {
            // With multiple main messages, we will get many element placements for the same detail id. only need
            // it once.
            var hasDetail = _this.currentSelectedItems.Elements.some(function (curItem) { return curItem.ElementDetails[0].Id === item.ElementDetailId; });
            if (!hasDetail) {
                var elementDetail = {
                    Id: item.ElementDetailId,
                    Width: item.ElementDetailWidth,
                    Height: item.ElementDetailHeight,
                    IsSelected: true
                };
                var element = {
                    Name: item.ElementDetailName,
                    ElementDetails: [elementDetail]
                };
                _this.currentSelectedItems.Elements.push(element);
            }
        });
        Object.assign(this.previousSelectedElements, this.currentSelectedItems.Elements);
    };
    PlacementSearchModalComponent.prototype.populateCurrentSelectedHolders = function (previousSelected) {
        var _this = this;
        previousSelected.forEach(function (placement) {
            var found = _this.currentSelectedItems.FixtureGroups.some(function (item) {
                return item.Holders.some(function (itemHodler) {
                    return itemHodler.Id === placement.HolderId;
                });
            });
            if (!found) {
                var fixtureGroup = {
                    Name: placement.FixtureTypeName,
                    Holders: [{
                            Id: placement.HolderId,
                            IsSelected: true,
                            Name: placement.HolderName
                        }]
                };
                _this.currentSelectedItems.FixtureGroups.push(fixtureGroup);
                Object.assign(_this.previousSelectedFixtureGroups, _this.currentSelectedItems.FixtureGroups);
            }
        });
    };
    PlacementSearchModalComponent.prototype.returnResults = function () {
        this.modalInstance.close(this.currentSelectedItems);
    };
    PlacementSearchModalComponent.prototype.getListData = function (search) {
        var _this = this;
        this.query = search;
        return this.promise = this.listSearch.searchHelper({ searchText: this.query }).then(function () {
            if (_this.placementType === "fixture") {
                _this.setDisplayForCurrentlySelectedHolders();
            }
            else if (_this.placementType === "element") {
                _this.setDisplayForCurrentlySelectedElements();
            }
            return Promise.resolve();
        });
    };
    PlacementSearchModalComponent.prototype.getSelectedHolderCount = function () {
        if (this.currentSelectedItems.FixtureGroups.length === 0) {
            return 0;
        }
        var total = 0;
        this.currentSelectedItems.FixtureGroups.forEach(function (item) {
            total += item.Holders.length;
        });
        return total;
    };
    PlacementSearchModalComponent.prototype.getSelectedElementDetailCount = function () {
        if (this.currentSelectedItems.Elements.length === 0) {
            return 0;
        }
        var total = 0;
        this.currentSelectedItems.Elements.forEach(function (item) {
            total += item.ElementDetails.length;
        });
        return total;
    };
    /**
     * After a search, existing selections in the GUI get wiped out.
     * This method will set isSelected on the fixtureGroups.resultList
     * based on settings prior to the new search.
     */
    PlacementSearchModalComponent.prototype.setDisplayForCurrentlySelectedHolders = function () {
        var _this = this;
        if (this.currentSelectedItems.FixtureGroups.length > 0) {
            // foreach item returned by the search, look in current selected list to find fixtureType
            this.listSearch.resultList.forEach(function (fixtureTypeItem) {
                fixtureTypeItem.Holders.forEach(function (holder) {
                    var found = _this.currentSelectedItems.FixtureGroups.some(function (item) {
                        return item.Holders.some(function (itemHodler) {
                            return itemHodler.Id === holder.Id;
                        });
                    });
                    if (found) {
                        holder.IsSelected = true;
                    }
                });
            });
        }
    };
    /**
 * After a search, existing selections in the GUI get wiped out.
 * This method will set isSelected on the fixtureGroups.resultList
 * based on settings prior to the new search.
 */
    PlacementSearchModalComponent.prototype.setDisplayForCurrentlySelectedElements = function () {
        var _this = this;
        if (this.currentSelectedItems.Elements.length > 0) {
            // foreach item returned by the search, look in current selected list to find fixtureType
            this.listSearch.resultList.forEach(function (elementItem) {
                elementItem.ElementDetails.forEach(function (detail) {
                    var found = _this.currentSelectedItems.Elements.some(function (item) {
                        return item.ElementDetails.some(function (itemDetail) {
                            return itemDetail.Id === detail.Id;
                        });
                    });
                    if (found) {
                        detail.IsSelected = true;
                    }
                });
            });
        }
    };
    PlacementSearchModalComponent.prototype.selectedItemChanged = function () {
        var _this = this;
        var changed = false;
        this.currentSelectedItems.FixtureGroups.forEach(function (fg) {
            var _loop_1 = function (i) {
                var holder = fg.Holders[i];
                var found = _this.previousSelectedFixtureGroups.some(function (item) {
                    return item.Holders.some(function (itemHodler) {
                        return itemHodler.Id === holder.Id;
                    });
                });
                if (!found) {
                    changed = true;
                    return "break";
                }
            };
            for (var i = 0; i < fg.Holders.length; i++) {
                var state_1 = _loop_1(i);
                if (state_1 === "break")
                    break;
            }
        });
        if (!changed) {
            this.previousSelectedFixtureGroups.forEach(function (fg) {
                var _loop_2 = function (i) {
                    var holder = fg.Holders[i];
                    var found = _this.currentSelectedItems.FixtureGroups.some(function (item) {
                        return item.Holders.some(function (itemHodler) {
                            return itemHodler.Id === holder.Id;
                        });
                    });
                    if (!found) {
                        changed = true;
                        return "break";
                    }
                };
                for (var i = 0; i < fg.Holders.length; i++) {
                    var state_2 = _loop_2(i);
                    if (state_2 === "break")
                        break;
                }
            });
        }
        if (!changed) {
            this.currentSelectedItems.Elements.forEach(function (element) {
                var _loop_3 = function (i) {
                    var detail = element.ElementDetails[i];
                    var found = _this.previousSelectedElements.some(function (item) {
                        return item.ElementDetails.some(function (itemDetail) {
                            return itemDetail.Id === detail.Id;
                        });
                    });
                    if (!found) {
                        changed = true;
                        return "break";
                    }
                };
                for (var i = 0; i < element.ElementDetails.length; i++) {
                    var state_3 = _loop_3(i);
                    if (state_3 === "break")
                        break;
                }
            });
        }
        if (!changed) {
            this.previousSelectedElements.forEach(function (element) {
                var _loop_4 = function (i) {
                    var detail = element.ElementDetails[i];
                    var found = _this.currentSelectedItems.Elements.some(function (item) {
                        return item.ElementDetails.some(function (itemDetail) {
                            return itemDetail.Id === detail.Id;
                        });
                    });
                    if (!found) {
                        changed = true;
                        return "break";
                    }
                };
                for (var i = 0; i < element.ElementDetails.length; i++) {
                    var state_4 = _loop_4(i);
                    if (state_4 === "break")
                        break;
                }
            });
        }
        return changed;
    };
    PlacementSearchModalComponent.prototype.close = function (closeMessage) {
        var _this = this;
        if (this.selectedItemChanged()) {
            this.ipsMessage.confirm({ body: "PLACEMENT_SEARCH_ACTIVE_SELECTIONS", ok: "YES", cancel: "NO" }, { windowClass: "message-window-z-index", backdropClass: "backdrop-z-index" })
                .then(function () {
                // Go ahead and close the window
                _this.modalInstance.dismiss(closeMessage);
            })
                .catch(function () {
                // rejection
            });
        }
        else {
            this.modalInstance.dismiss(closeMessage);
        }
    };
    PlacementSearchModalComponent.prototype.holderChecked = function (fixtureGroup, holder, returnOnSelect) {
        if (returnOnSelect === void 0) { returnOnSelect = false; }
        holder.IsSelected = !holder.IsSelected;
        this.selectedHolderChanged(fixtureGroup, holder, returnOnSelect);
    };
    PlacementSearchModalComponent.prototype.selectedHolderChanged = function (fixtureGroup, holder, returnOnSelect) {
        if (returnOnSelect === void 0) { returnOnSelect = false; }
        if (holder.IsSelected) {
            // Find the holder
            var found = this.currentSelectedItems.FixtureGroups.some(function (item) {
                return item.Holders.some(function (itemHodler) {
                    return itemHodler.Id === holder.Id;
                });
            });
            if (!found) {
                this.currentSelectedItems.FixtureGroups.push({
                    Id: fixtureGroup.Id,
                    Name: fixtureGroup.Name,
                    Holders: [holder]
                });
            }
        }
        else { // remove the item
            this.currentSelectedItems.FixtureGroups =
                this.currentSelectedItems.FixtureGroups.filter(function (fg) {
                    var index = -1;
                    var found = fg.Holders.some(function (item, i) {
                        if (item.Id === holder.Id) {
                            index = i;
                            return true;
                        }
                    });
                    if (found) {
                        fg.Holders.splice(index, 1);
                    }
                    return !found;
                });
        }
        this.selectedCount = this.getSelectedHolderCount();
        if (returnOnSelect) {
            this.returnResults();
        }
    };
    PlacementSearchModalComponent.prototype.elementChecked = function (element, elementDetail) {
        elementDetail.IsSelected = !elementDetail.IsSelected;
        this.selectedElementChanged(element, elementDetail);
    };
    PlacementSearchModalComponent.prototype.selectedElementChanged = function (element, elementDetail) {
        if (elementDetail.IsSelected) {
            // Find the element detail
            var found = this.currentSelectedItems.Elements.some(function (item) {
                return item.ElementDetails.some(function (itemDetail) {
                    return itemDetail.Id === elementDetail.Id;
                });
            });
            if (!found) {
                this.currentSelectedItems.Elements.push({
                    Id: element.Id,
                    Name: element.Name,
                    ElementDetails: [elementDetail]
                });
            }
        }
        else { // remove the item
            this.currentSelectedItems.Elements =
                this.currentSelectedItems.Elements.filter(function (ele) {
                    var index = -1;
                    var found = ele.ElementDetails.some(function (item, i) {
                        if (item.Id === elementDetail.Id) {
                            index = i;
                            return true;
                        }
                    });
                    if (found) {
                        ele.ElementDetails.splice(index, 1);
                    }
                    return !found;
                });
        }
        this.selectedCount = this.getSelectedElementDetailCount();
    };
    return PlacementSearchModalComponent;
}());
export { PlacementSearchModalComponent };
