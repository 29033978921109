import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, ControlContainer, NgForm, NgModel } from "@angular/forms";
import { IpsImageEditComponent } from "imagine-ui-ng-quick-start";

@Component({
    selector: "app-setup-theme",
    templateUrl: "./setup-theme.component.html",
    styleUrls: ["./setup-theme.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class SetupThemeComponent implements OnInit {
    @Input() parentForm: FormGroup;
    @Input() theme: any;
    @Input() parentComponent: any;

    @ViewChild("smallLogo") public smallLogo: IpsImageEditComponent;
    @ViewChild("largeLogo") public largeLogo: IpsImageEditComponent;

    public themeForm: FormGroup;
    public primaryHexColor: FormControl;
    public secondaryHexColor: FormControl;
    public fontHexColor: FormControl;

    public tempPrimaryHexColor: any;
    public tempSecondaryHexColor: any;
    public tempFontHexColor: any;

    public loadingSmallLogo = false;
    public loadingLargeLogo = false;

    smallLogoUploading(state: boolean) {
        this.loadingSmallLogo = state;
    }

    largeLogoUploading(state: boolean) {
        this.loadingLargeLogo = state;
    }

    constructor(private formBuilder: FormBuilder, private container: ControlContainer) { }

    buildForm() {
        this.primaryHexColor = this.formBuilder.control(this.theme.PrimaryHexColor);
        this.secondaryHexColor = this.formBuilder.control(this.theme.SecondaryHexColor);
        this.fontHexColor = this.formBuilder.control(this.theme.FontHexColor);

        this.themeForm = this.formBuilder.group({
            primaryHexColor: this.primaryHexColor,
            secondaryHexColor: this.secondaryHexColor,
            fontHexColor: this.fontHexColor
        });

        this.parentForm.addControl("primaryHexColor", this.primaryHexColor);
        this.parentForm.addControl("secondaryHexColor", this.secondaryHexColor);
        this.parentForm.addControl("fontHexColor", this.fontHexColor);
    }

    private appendHashToColor(ctrl: FormControl): void {
        let color = ctrl.value as string;
        if (color && !color.startsWith("#")) {
            ctrl.setValue("#" + color);
        }
    }

    onPrimaryHexColorChange(color: string) {
        this.primaryHexColor.setValue(color);
        this.primaryHexColor.markAsDirty();
    }

    onPrimaryHexBlur() {
        this.appendHashToColor(this.primaryHexColor);
    }

    onSecondaryHexColorChange(color: any) {
        this.secondaryHexColor.setValue(color);
        this.secondaryHexColor.markAsDirty();
    }

    onSecondaryHexBlur() {
        this.appendHashToColor(this.secondaryHexColor);
    }

    onFontHexColorChange(color: any) {
        this.fontHexColor.setValue(color);
        this.fontHexColor.markAsDirty();
    }

    onFontHexBlur() {
        this.appendHashToColor(this.fontHexColor);
    }

    public onSmallLogoChange(files: File) {
        this.parentComponent.smallLogoFiles = files;
        this.parentForm.markAsDirty();

        if (!this.parentComponent.smallLogoFiles) {
            this.parentComponent.populateThumbnail();
            this.smallLogo.thumbnail = this.parentComponent.smallLogo;
        }
    }

    onLargeLogoChange(files: File) {
        this.parentComponent.largeLogoFiles = files;
        this.parentForm.markAsDirty();

        if (!this.parentComponent.largeLogoFiles) {
            this.parentComponent.populateThumbnail();
            this.largeLogo.thumbnail = this.parentComponent.largeLogo;
        }
    }

    ngOnInit() {
        this.tempPrimaryHexColor = this.theme.PrimaryHexColor;
        this.tempSecondaryHexColor = this.theme.SecondaryHexColor;
        this.tempFontHexColor = this.theme.FontHexColor;

        this.smallLogo.thumbnail = this.parentComponent.smallLogo;
        this.largeLogo.thumbnail = this.parentComponent.largeLogo;
        this.buildForm();
    }
}
