<div class="col-12" *ngIf="translated" ipsFocus="searchInput">
    <ips-list-search headerLabel="{{'RELATED_ALTERNATE_ADDRESSES' | translate }}"
                     [searchLabel]="searchLabel"
                     [searchLabelPlural]="searchLabelPlural"
                     labelLoading="{{'ACCESSING_ALTERNATE_ADDRESS_INFO' | translate }}"
                     labelLoadingError="{{'ERROR_ACCESSING_ALTERNATE_ADDRESS_INFO' | translate }}"
                     [listData]="alternateAddress"
                     [busy]="busy"
                     (onListDataChange)="getListData($event.search)"
                     [section]="'main.storeProfileAlternateAddress'"
                     [scroller]=true
                     [quickRef]=false
                     [promise]="promise"
                     [createNewTooltip]="createNewTooltip"
                     [autoFocus]=true>
        <ng-template #listSearchItemTemplate let-result="result">
            <div class="row ips-search-result-big">
                <div class="col pr-0">
                    <a uiSref="main.storeProfileAlternateAddress.view" [uiParams]="{id:result.Id}" class="no-underline">
                        <div class="px-3 py-2 loc-label">
                            <div>
                                <span>{{result.AddressIdentifier}} - {{result.Name}}</span>
                            </div>
                            <div class="loc-city-state">{{result.City}}, {{result.StateProvince}}</div>
                        </div>
                    </a>
                </div>
                <div class="col-2 col-md-1" ipsPermission="main.storeProfileLocation.edit">
                    <a uiSref="main.storeProfileAlternateAddress.edit" [uiParams]="{id:result.Id}" class="no-underline">
                        <div class="mt-1 pt-3 pr-3 d-flex justify-content-end">
                            <span class="fa fa-pencil"></span>
                        </div>
                    </a>
                </div>
            </div>
        </ng-template>

    </ips-list-search>
</div>
