import {distinctUntilChanged, debounceTime} from "rxjs/operators";
import { Component, OnInit, ViewChild } from "@angular/core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/angular";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { NgForm } from "@angular/forms";
import { String as IpsString } from "typescript-string-operations";
import { TemplateFormBase } from "../../../shared/templateFormBase";

import { Subject } from "rxjs";

import { AreaService, CustomDataService, CustomDataAreaModel} from "../../index";
import { CustomDataModel, CustomDataOptionModel, FieldType, AreaType } from "../../../shared/index";

interface FieldTypeInfo {
    TypeName: FieldType;
    DisplayName: string;
    IconClass: string;
}

interface CustomDataDictionary { [key: string]: CustomDataModel[]; }
interface OrdinalDictionary { [key: string]: number; }

@Component({
    selector: "app-custom-data-edit",
    templateUrl: "./custom-data-edit.component.html",
    styleUrls: ["./custom-data-edit.component.scss"]
})
export class CustomDataEditComponent extends TemplateFormBase implements OnInit {
    @ViewChild("customDataForm")
    public customDataForm: NgForm;

    public breadCrumbLabel: string;
    public loaded: boolean;
    public promise: Promise<void>;

    public areaList: CustomDataAreaModel[] = [];
    public myCustomData: CustomDataModel = {} as CustomDataModel;
    public ruleName: string;
    public singleRule: string;
    public multipleRule: string;
    public focusableInput = "";
    public editingExisting = false;
    private indexer = 0;
    private fieldGroupIndexer = 0;
    private fieldOption = "fieldOption";
    public showOrdinalWarning = false;
    public originName: string;

    private customDataDictionary: CustomDataDictionary = {};
    private ordinalDictionary: OrdinalDictionary = {};
    public customDataGroupList = [];
    public canAddFieldGroup = true;



    public fieldTypes: FieldTypeInfo[] = [
        { TypeName: "List", DisplayName: "OPTION_TYPE_LIST", IconClass: "option-type-list-icon" },
        { TypeName: "Text", DisplayName: "OPTION_TYPE_TEXT", IconClass: "option-type-text-icon" },
        { TypeName: "Date", DisplayName: "OPTION_TYPE_DATE", IconClass: "option-type-date-icon" },
        { TypeName: "Number", DisplayName: "OPTION_TYPE_NUMBER", IconClass: "option-type-number-icon" },
        { TypeName: "Email", DisplayName: "OPTION_TYPE_EMAIL", IconClass: "option-type-text-icon" }
    ];


    private TranslateStrings: { [key: string]: string } = {
        "SINGLE_LINE_ENTRY": "",
        "MULTI_LINE_ENTRY": "",
        "PROMPT_DELETE_BODY": "",
        "MAX_LENGTH_ERROR": "",
        "PLEASE_ENTER_NAME": "",
        "PLEASE_SELECT_RULE": "",
        "PLEASE_ENTER_CUSTOM_DATA_FIELD_NAME": "",
        "PLEASE_ENTER_FIELD_SELECTION": "",
        "SINGLE_SELECTION_ALLOWED": "",
        "MULTIPLE_SELECTIONS_ALLOWED": "",
        "PLEASE_ENTER_FIELD_ORDER": "",
        "TEXT_RULE": "",
        "LIST_RULE": ""
    };

    public errorMessages = {
        "required": () => this.translateService.instant("PLEASE_ENTER_NAME"),
        "maxlength": (params) => IpsString.Format(this.translateService.instant("MAX_LENGTH_ERROR"), params.requiredLength),
        "dupName": () => this.translateService.instant("DUPLICATE_CUSTOM_FIELD_NAME"),
        "dupOptionName": () => this.translateService.instant("DUPLICATE_FIELD_SELECTION"),
        "positiveWholeNumReqired": () => this.translateService.instant("ENTER_POSITIVE_WHOLE_NUMBER")
    };

    public ordinalSubject: Subject<number> = new Subject<number>();

    constructor(private dataService: CustomDataService,
        private ipsMessage: IpsMessageService,
        private translateService: TranslateService,
        private pageTitleService: PageTitleService,
        private transition: Transition,
        private stateService: StateService,
        private areaService: AreaService,
        private activeProfileService: ActiveProfileService) {
        super();
    }

    ngOnInit() {
        this.translateService.get("ALL").subscribe(() => this.translateText());
        this.translateService.onLangChange.subscribe(() => this.translateText());

        let id = this.transition.params().id === "0" ? "" : this.transition.params().id;

        let pageTitle = id === "" ? "CREATE_CUSTOM_DATA_FIELD" : "EDIT_CUSTOM_DATA_FIELD";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        this.getAreaList();


        //If we got an ID to load, load it.
        if (id > 0) {
            //Initial call to populate screen on load
            this.editingExisting = true;
            this.getCustomDataField(id);
        } else if (id === "") {
            this.myCustomData = {
                Id: 0,
                Name: "",
                Notes: "",
                AreaId: "",
                Ordinal: undefined,
                IsFieldRequired: null,
                FieldType: null,
                SelectionRule: undefined,
                CustomDataOptionList: []
            };
            this.loaded = true;
            this.subscribeOrdinal();
        }



        super.setFormPristine(this.customDataForm, 0);
    }

    private getNextAvailableOrdinal(areaId: AreaType) {
        this.myCustomData.Ordinal = this.ordinalDictionary[areaId];
    }

    private translateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    }

    private subscribeOrdinal() {
        this.ordinalSubject.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(model => {
                model = this.myCustomData.Ordinal;
                this.validateOrdinal(model);
            });
    }

    public onFieldOrderChange(ordinal: number) {
        this.ordinalSubject.next(ordinal);
    }

    private validateOrdinal(ordinal) {
        if (ordinal !== undefined && ordinal !== null) {
            if (this.myCustomData.AreaId) {
                let ordinalList = this.customDataDictionary[this.myCustomData.AreaId].map(data => data.Ordinal);
                let index = ordinalList.indexOf(Number(ordinal));
                if (index !== -1) {
                    this.showOrdinalWarning = true;
                } else {
                    this.showOrdinalWarning = false;
                }
            }
        } else {
            this.showOrdinalWarning = false;
        }

    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "name":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_CUSTOM_DATA_FIELD_NAME"];
                    break;
                case "fieldorder":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_FIELD_ORDER"];
                    break;
                case "fieldselection":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_FIELD_SELECTION"];
                    break;
                case "rule":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_SELECT_RULE"];
                    break;
                case "fieldtype":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_SELECT_FIELD_TYPE"];
                    break;
                case "group":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_SELECT_FIELD_GROUP"];
                    break;
            }
        }
        return msgs;
    }

    public getAreaList() {
        return this.areaService.getList().then((response) => {
            Object.assign(this.areaList, response);
                this.areaList.forEach((item: CustomDataAreaModel)  => {
                switch (item.Id) {
                    case "PromotionHeader":
                        item.DisplayName = "PROMOTION_HEADER";
                        break;
                    case "PromotionMessage":
                        item.DisplayName = "PROMOTION_MESSAGE";
                        break;
                    case "PromotionPlacement":
                        item.DisplayName = "PROMOTION_PLACEMENT";
                        break;
                    case "RequestForm":
                        item.DisplayName = "REQUEST_FORM";
                        break;
                }
            });
        });
    }

    public selectCustomDataList(areaId: AreaType, setDefault = true) {
        let selectedCustomDataList = this.customDataDictionary[areaId];
        if (!selectedCustomDataList) {
            this.dataService.getCustomDataByArea(areaId).then((result: CustomDataModel[]) => {
                this.customDataDictionary[areaId] = result;
                if (setDefault) {
                    let maxOrdinal = result.length === 0 ? 0 : result.map(item => item.Ordinal).reduce((item1, item2) => {
                        return Math.max(item1, item2);
                    });
                    this.ordinalDictionary[areaId] = maxOrdinal + 1;
                    this.myCustomData.Ordinal = maxOrdinal + 1;
                }
            });
        }
    }

    public getCustomDataField(id: number): void {
        this.loaded = false;

        this.dataService.get<CustomDataModel>(id).then((response) => {
            Object.assign(this.myCustomData, response);
            this.originName = this.myCustomData.Name;
            this.setRuleText(this.myCustomData.FieldType);
            this.subscribeOrdinal();
            this.loaded = true;

            if (this.myCustomData.CustomDataOptionList && this.myCustomData.CustomDataOptionList.length > 0) {
                this.indexer = this.myCustomData.CustomDataOptionList.length;
                for (let i = 0; i < this.myCustomData.CustomDataOptionList.length; i++) {
                    let option = this.myCustomData.CustomDataOptionList[i];
                    option.ControlName = this.fieldOption + i;
                }

                this.myCustomData.CustomDataOptionList.sort((n1, n2) => n1.Name.localeCompare(n2.Name));
            }

            //load customDataList for selected areaId
            this.selectCustomDataList(this.myCustomData.AreaId as AreaType, false);

            //load customDataGroup
            if (this.myCustomData.AreaId === "RequestForm" && this.customDataGroupList.length === 0) {
                this.getcustomDataGroupList("RequestForm");
            }
        });
    }

    public onAreaChange(area: CustomDataAreaModel) {
        let customDataList = this.customDataDictionary[area.Id];
        if (!customDataList) {
            this.selectCustomDataList(area.Id);
        } else {
            this.getNextAvailableOrdinal(area.Id);
        }
        if (area.Id === "RequestForm" && this.customDataGroupList.length === 0) {
            this.getcustomDataGroupList(area.Id);
        }
    }

    public onCustomDataGroupChange(group) {
        this.myCustomData.SelectedCustomDataGroup = group;
    }

    private getcustomDataGroupList(areaId: AreaType) {
        this.areaService.getCustomDataGroup(areaId).then(result => {
            this.customDataGroupList = result;
            this.customDataGroupList.forEach(x => x.inputName = "fieldGroup" + ++this.fieldGroupIndexer);
            // Add one empty group if we don"t have any yet
            if (this.customDataGroupList.length === 0) {
                this.customDataGroupList.push({ Id: 0, Name: "", AreaId: this.myCustomData.AreaId, inputName: "fieldGroup" + ++this.fieldGroupIndexer });
                this.canAddFieldGroup = false;
            }

            if (this.editingExisting) {
                this.myCustomData.SelectedCustomDataGroup = this.customDataGroupList.find(x => x.Id === this.myCustomData.CustomDataGroupId);
            }
        });
    }

    public onFieldTypeChange(fieldType: FieldTypeInfo) {
        this.myCustomData.FieldType = fieldType.TypeName;
        this.setRuleText(fieldType.TypeName);

        if (this.myCustomData.FieldType === "List") {
            if (!this.myCustomData.CustomDataOptionList || this.myCustomData.CustomDataOptionList.length === 0) {
                this.myCustomData.CustomDataOptionList = [{ Id: 0, Name: "", CustomDataId: this.myCustomData.Id, BusinessIdentity: this.activeProfileService.businessIdentity, ControlName: this.fieldOption + ++this.indexer }];
            }
        } else {
            if (this.myCustomData.FieldType === "Date" || this.myCustomData.FieldType === "Number" || this.myCustomData.FieldType === "Email") {
                this.myCustomData.SelectionRule = undefined;
            }
            this.myCustomData.CustomDataOptionList = [];
        }
        this.myCustomData.SelectionRule = undefined;
    }

    public onOptionChange(option: CustomDataOptionModel) {
        if (option.Name) {
            for (let i = 0; i < this.myCustomData.CustomDataOptionList.length; i++) {
                let currentOption = this.myCustomData.CustomDataOptionList[i];
                if (option.ControlName !== currentOption.ControlName && option.Name.toLocaleLowerCase() === currentOption.Name.toLocaleLowerCase()) {
                    this.customDataForm.controls[option.ControlName].setErrors({ "dupOptionName": true });
                    break;
                }
            }
        }
    }

    private setRuleText(fieldType: string) {
        switch (fieldType) {
            case "Text":
                this.ruleName = this.TranslateStrings["TEXT_RULE"];
                this.singleRule = this.TranslateStrings["SINGLE_LINE_ENTRY"];
                this.multipleRule = this.TranslateStrings["MULTI_LINE_ENTRY"];
                break;
            case "List":
                this.ruleName = this.TranslateStrings["LIST_RULE"];
                this.singleRule = this.TranslateStrings["SINGLE_SELECTION_ALLOWED"];
                this.multipleRule = this.TranslateStrings["MULTIPLE_SELECTIONS_ALLOWED"];
                break;
        }
    }

    public addFieldOption() {
        let option = { Id: 0, Name: "", CustomDataId: this.myCustomData.Id, BusinessIdentity: this.activeProfileService.businessIdentity, ControlName: this.fieldOption + ++this.indexer };
        if (this.myCustomData.FieldType === "List" &&
            (!this.myCustomData.CustomDataOptionList || this.myCustomData.CustomDataOptionList.length === 0)) {
            this.myCustomData.CustomDataOptionList = [option];
        } else {
            this.myCustomData.CustomDataOptionList.push(option);
        }
        let index = this.myCustomData.CustomDataOptionList.length - 1;
        this.focusableInput = this.myCustomData.CustomDataOptionList[index].ControlName;
        this.customDataForm.form.markAsDirty();
    }

    public addFieldGroup() {
        const group = { Id: 0, Name: "", AreaId: this.myCustomData.AreaId, inputName: "fieldGroup" + ++this.fieldGroupIndexer };
        this.myCustomData.SelectedCustomDataGroup = group;
        this.myCustomData.CustomDataGroupId = group.Id;
        if (!this.customDataGroupList || this.customDataGroupList.length === 0) {
            this.customDataGroupList = [group];
        } else {
            this.customDataGroupList.push(group);
        }
        this.canAddFieldGroup = false;
        this.customDataForm.form.markAsDirty();
    }

    public removeGroup(inputName) {
        if (this.customDataGroupList.length === 1) {
            this.ipsMessage.error("AT_LEAST_ONE_FIELD_GROUP_REQUIRED");
            return;
        }

        let group = this.customDataGroupList.find(x => x.inputName === inputName);

        if (group && group.Id > 0) {
            if (group.ReferencedByCustomData) {
                this.ipsMessage.error("UNABLE_DELETE_FIELD_GROUP");
                return;
            }
        }
        //delete group
        this.customDataGroupList = this.customDataGroupList.filter(x => x.inputName !== inputName);
        // Set as dirty since we removed a group.
        this.customDataForm.form.markAsDirty();

        this.canAddFieldGroup = this.customDataGroupList.every(x => x.Id > 0);

    }

    public removeFieldOption(index: number) {
        if (this.myCustomData.CustomDataOptionList.length <= 1) {
            this.ipsMessage.error("AT_LEAST_ONE_FIELD_SELECTION_REQUIRED");
        } else {
            this.myCustomData.CustomDataOptionList.splice(index, 1);
            this.customDataForm.form.markAsDirty();
        }
    }

    public saveCustomDataPrompt(redirect: boolean): Promise<any> {
        return this.ipsMessage
            .waitForWork({
                    body: "SAVING",
                    workFunction: () => this.saveCustomData(),
                    progressMessage: "SAVING"
                },
                undefined).then((result) => {
                if (result) {
                    if (redirect) {
                        this.stateService.go("main.customdata.search");
                    } else {
                        this.stateService.go("main.customdata.edit", { id: this.myCustomData.Id }, {reload: this.editingExisting});
                    }
                }
            });
    }

    public deleteCustomDataPrompt(): void {
        let translated = IpsString.Format(this.TranslateStrings["PROMPT_DELETE_BODY"], this.myCustomData.Name);
        this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deleteCustomData(), progressMessage: "DELETING" }, undefined)
        .then((result) => {
            if (result) {
                this.stateService.go("main.customdata.search");
            }
        })
        .catch(() => {
            // rejection
        });
    }
    private saveCustomData(): Promise<void> {
        let id = this.myCustomData.Id;

        //update customDataFieldGroup
        if (this.myCustomData.AreaId === "RequestForm") {
            this.myCustomData.CustomDataGroupList = this.customDataGroupList;
        }

        if (id) {
            return this.dataService.put<CustomDataModel>(this.myCustomData).then((response) => {
                this.customDataForm.form.markAsPristine();
            });

        } else {
            return this.dataService.post<CustomDataModel>(this.myCustomData).then((response) => {
                this.myCustomData.Id = response.Id;
                this.customDataForm.form.markAsPristine();
            });
        }
    }

    private deleteCustomData(): Promise<void> {
        let id = this.myCustomData.Id;
        return this.dataService.delete(id);
    }

    public disableSave() {
        return this.customDataForm.invalid || this.customDataForm.pristine || (this.myCustomData.AreaId === "RequestForm" && this.myCustomData.SelectedCustomDataGroup === undefined);
    }
}
