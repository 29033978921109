var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var FixtureByLocationService = /** @class */ (function (_super) {
    __extends(FixtureByLocationService, _super);
    function FixtureByLocationService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("Fixture", "Profile", "v1");
        _this.route = _this.urlApiBaseProductPlusVersion + "Location/{id}/Fixture";
        return _this;
    }
    FixtureByLocationService.prototype.getByLocation = function (id) {
        this.LocationId = id;
        return this.getByRoute(this.route.replace("{id}", id));
    };
    FixtureByLocationService.prototype.headByLocation = function (id) {
        return this.headByRoute(this.route.replace("{id}", id));
    };
    // Override the ipsBaseWebService.search method.
    FixtureByLocationService.prototype.search = function (searchInfo, route) {
        return this.searchByRelation(searchInfo, "Location/" + searchInfo.id + "/Fixture/Search");
    };
    return FixtureByLocationService;
}(IpsBaseWebService));
export { FixtureByLocationService };
