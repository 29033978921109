import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ListSearchHelper, SearchInfo, ActiveProfileService, SearchResponse } from "imagine-ui-ng-core";
import { PermissionService } from "imagine-ui-ng-security";
import { SimpleSearchLocationGroupUIModel, LocationGroupService, LocationService, FixtureGroupService, SimpleSearchFixtureGroupModel } from "../../index";
import { PropertyType } from "../../../shared/index";

interface ListOptionResult {
    Name: string;
    Type: PropertyType;
}

@Component({
  selector: "app-location-list-option-modal",
  templateUrl: "./location-list-option-modal.component.html",
  styleUrls: ["./location-list-option-modal.component.scss"]
})


export class LocationListOptionModalComponent implements OnInit {
    public busy: boolean;
    public listSearch: ListSearchHelper;
    public dataList: SimpleSearchLocationGroupUIModel[];
    public fixtureGroups: SimpleSearchLocationGroupUIModel[];
    public promise: Promise<any>;
    public locationGroup = "LocationGroup";
    public location = "Location";
    public address = "Address";
    public selectedPropertyList: ListOptionResult[];
    public resolve: any;
    private editLocationGroupPermission: boolean;
    private searchFixtureGroupPermission: boolean;
    public selectedCount = 0;
    public query: string;
    public activeBusinessId: string;

    constructor(private listSearchHelper: ListSearchHelper, private translateService: TranslateService,
        private locationGroupService: LocationGroupService, private modalInstance: NgbActiveModal, private locationService: LocationService, private permissionService: PermissionService, private fixtureGroupService: FixtureGroupService, private activeProfileService: ActiveProfileService) { }

    ngOnInit() {

        this.activeBusinessId = this.activeProfileService.businessIdentity;

        let promises: Promise<any>[] = [];
        this.listSearch = this.listSearchHelper.getListSearchHelper(this.locationGroupService);
        this.busy = this.listSearch.busy;
        this.selectedPropertyList = this.resolve.selectedPropertyList;
        this.selectedCount = this.selectedPropertyList ? this.selectedPropertyList.length : 0;
        this.editLocationGroupPermission = this.resolve.editLocationGroupPermission;
        this.searchFixtureGroupPermission = this.resolve.searchFixtureGroupPermission;
        this.fixtureGroups = [] as SimpleSearchLocationGroupUIModel[];
        if (this.editLocationGroupPermission) {
            promises.push(this.getListSearch(""));
        }
        if (this.searchFixtureGroupPermission) {
            promises.push(this.getLocationFixtureGroups(""));
        }

        this.promise = Promise.all(promises)
            .then((data: any) => {
              this.dataList = this.addLocationsSortCheck("");
            });
    }

    private getLocationAttributeList(search: string): SimpleSearchLocationGroupUIModel[] {
        let ret = [] as SimpleSearchLocationGroupUIModel[];
        this.locationService.locationAttributes.forEach(loc => {
            let index = loc.Label.toLowerCase().indexOf(search.toLowerCase());
            if (index > -1 || search === "") {
                let searchModel = {
                    Name: loc.Key,
                    Label: loc.Label,
                    Type: this.location,
                    LableName: "LOCATION"
                } as SimpleSearchLocationGroupUIModel;
                ret.push(searchModel);

            }
        });

        this.locationService.addressAttributes.forEach(address => {
            let index = address.Label.toLowerCase().indexOf(search.toLowerCase());
            if (index > -1 || search === "") {
                let searchModel = {
                    Name: address.Key,
                    Label: address.Label,
                    Type: this.address,
                    LableName: "LOCATION"
                } as SimpleSearchLocationGroupUIModel;
                ret.push(searchModel);
            }
        });
        return ret;
    }

    private getLocationFixtureGroups(search: string) {
        this.promise = this.fixtureGroupService.simpleSearch(<SearchInfo>{
            searchText: search,
            businessIdentity: this.activeProfileService.businessIdentity,
            chunkIndex: 0,
            recordCount: 999
        });
        return this.promise.then((result: SearchResponse<SimpleSearchFixtureGroupModel>) => {
                result.ResultList.forEach((item: SimpleSearchLocationGroupUIModel) => {
                    item.Id = item.Id;
                    item.Label = item.Name;
                    item.LableName = "FIXTURE_GROUP";
                    item.Type = "FixtureType";
                    this.fixtureGroups.push(item);
                });

            });
        }

    public close(closeMessage?: any) {
        this.modalInstance.dismiss(closeMessage);
    }

    public getListSearch(search: string) {
        this.promise = this.listSearch.searchHelper(<SearchInfo>{ searchText: search, recordCount: 999 }, undefined, "LocationGroup/SimpleSearch");

        return this.promise.then(() => {
            this.listSearch.resultList.forEach((item: SimpleSearchLocationGroupUIModel) => {
                item.Label = item.Name;
                item.Type = this.locationGroup as PropertyType;
                item.LableName = "LOCATION_GROUP";
                switch (item.Rule) {
                case "Single":
                    item.RuleClass = "location-group-rule-single";
                    item.RuleTooltip = this.translateService.instant("TOOLTIP_LOCATION_GROUP_RULE_SINGLE");
                    break;
                case "Multiple":
                    item.RuleClass = "location-group-rule-multiple";
                    item.RuleTooltip = this.translateService.instant("TOOLTIP_LOCATION_GROUP_RULE_MULTIPLE");
                    break;
                }
                item.RuleTooltip = item.RuleTooltip.replace("{0}", item.Name);
            });

        });
    }

    public getListData(search: string) {
        if (this.busy) {
            return;
        }
        if (!search) {
            search = "";
        }

        if (this.dataList) {
             //search after inital load
            this.listSearch.resultList = this.dataList.filter((item: SimpleSearchLocationGroupUIModel) => {
                return search === "" || item.Label.toLowerCase().indexOf(search.toLowerCase()) > -1;
            });
        }
    }

    private addLocationsSortCheck(search: string) {
        //add static list to the result
        this.listSearch.resultList = this.listSearch.resultList.concat(this.getLocationAttributeList(search));
        this.listSearch.resultList = this.listSearch.resultList.concat(this.fixtureGroups);
            //sort the list
            this.listSearch.resultList.sort((a: SimpleSearchLocationGroupUIModel, b: SimpleSearchLocationGroupUIModel) => {
                let x = a.Label.toLowerCase(), y = b.Label.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;

            });
            //initalize checkbox
            this.listSearch.resultList.forEach((item: SimpleSearchLocationGroupUIModel) => {
                if (this.selectedPropertyList && this.selectedPropertyList.some(selectedItem => item.Name.toLowerCase() === selectedItem.Name.toLowerCase() && item.Type === selectedItem.Type)) {
                    item.Selected = true;
                }
            });
            //save search result to dataList
           return this.listSearch.resultList;
    }

    public handleUncheckAll() {
        this.listSearch.resultList.forEach((item: SimpleSearchLocationGroupUIModel) => {
            item.Selected = false;
        });
        this.dataList.forEach((item: SimpleSearchLocationGroupUIModel) => {
            item.Selected = false;
        });
        this.selectedCount = 0;
    }

    public handleCheckAll() {
        this.listSearch.resultList.forEach((item: SimpleSearchLocationGroupUIModel) => {
            item.Selected = true;
        });
        this.dataList.forEach((item: SimpleSearchLocationGroupUIModel) => {
            item.Selected = true;
        });
        this.selectedCount = this.listSearch.resultList.length;
    }

    public optionChecked(item: SimpleSearchLocationGroupUIModel): void {
        item.Selected = !item.Selected;
        this.selectedOptionChanged(item);
    }

    public selectedOptionChanged(item: SimpleSearchLocationGroupUIModel) {
        this.dataList.some((data: SimpleSearchLocationGroupUIModel) => {
            if (data.Label === item.Label) {
                data.Selected = item.Selected;
                this.selectedCount = item.Selected ? this.selectedCount + 1 : this.selectedCount - 1;
                return true;
            } else {
                // Keep looking
                return false;
            }


        });
    }

    public returnResults() {
        let ret = this.dataList.filter((item: SimpleSearchLocationGroupUIModel) => item.Selected === true);
        this.modalInstance.close(ret);
    }

}
