import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { IpsModalService, IpsModalInstance } from "imagine-ui-ng-modal";
import { ListSearchHelper, ActiveProfileService, QueryParamItem, SearchInfo, FileDownloadService } from "imagine-ui-ng-core";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { TranslateService } from "@ngx-translate/core";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { CampaignService, CampaignUpDownAnalyticsService } from "../../index";
import { CampaignUpdownAnalyticsModalComponent } from "../campaign-updown-analytics-modal/campaign-updown-analytics-modal.component";
import { SimpleSearchLocationModel } from "../../../imagine-ui-ng-store-profile";
import { SortHelperService } from "../../../shared/service/sort-helper.service";

interface ResultModel {
  item: any;
}

interface CampaignUpDownReportStatModel {
  LocationId: string;
  LocationName: string;
  LocationIdentifier: string;
  City: string;
  State: string;
  DownloadCount: number;
}

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.scss"]
})
export class AnalyticsComponent implements OnInit {
  @ViewChild("showDownloadTemplate") downloadTemplate: TemplateRef<any>;
  public listSearch: ListSearchHelper;
  public campaignUpDownAnalytics: ListSearchHelper;
  public screenReady = false;
  public loading = true;
  public query: string;
  public searchText: string;
  public promise: Promise<any>;
  public selectedLocation: SimpleSearchLocationModel;
  public welcomeMessage = "CAMPAIGN_ANALYTICS_WELCOME_MESSAGE";
  public withDateRangeMessage = "CAMPAIGN_ANALYTICS_DATERANGE_MESSAGE";
  public withDateRangeTwentyMessage = "CAMPAIGN_ANALYTICS_DATERANGEBYTWENTY_MESSAGE";
  public withLocationAndDateMessage = "CAMPAIGN_ANALYTICS_LOCATION_DATE_MESSAGE";
  public information = "INFORMATION_UPPERCASE";
  public printSortHelper: SortHelperService<CampaignUpDownReportStatModel>;
  public printedItemList: CampaignUpDownReportStatModel[] = [];

  private pluralMapping: { [k: string]: string } = {};

  private TranslateStrings: { [key: string]: string } = {
    "ALL_UPPERCASE": "",
    "INFORMATION_UPPERCASE": "",
    "CAMPAIGN_ANALYTICS_WELCOME_MESSAGE": "",
    "CAMPAIGN_ANALYTICS_DATERANGE_MESSAGE": "",
    "CAMPAIGN_ANALYTICS_DATERANGEBYTWENTY_MESSAGE": "",
    "CAMPAIGN_ANALYTICS_LOCATION_DATE_MESSAGE": "",
    "LOCATION": "",
    "LOCATIONS": ""
  };

  public fromDateModel: Date;
  public toDateModel: Date;
  public dateRangeModel: Date[] = [];
  public DateRangeTooBig = false;


  public processing = false;
  public processingError: string;

  constructor(
    private campaignService: CampaignService,
    private campaignUpDownAnalyticService: CampaignUpDownAnalyticsService,
    private ipsModal: IpsModalService,
    private translateService: TranslateService,
    private ipsMessage: IpsMessageService,
    private listSearchHelper: ListSearchHelper,
    private downloadService: FileDownloadService) {
    this.setInitialValues();
    this.printSortHelper = new SortHelperService<CampaignUpDownReportStatModel>();
  }


  ngOnInit() {
    this.TranslateText();
    this.translateService.get("ALL").subscribe(() => this.TranslateText());
    this.campaignUpDownAnalytics = this.listSearchHelper.getListSearchHelper(this.campaignUpDownAnalyticService);
    this.campaignUpDownAnalyticService.headByAnalyticsCount().then((total: number) => {
      this.campaignUpDownAnalytics.TotalRecords = total;
    });
    this.getCampaignUpDownStats({ search: "" });
  }

  get sortedPrintRecords() {
    let returnValue = this.printSortHelper.applySort(this.printedItemList);
    return returnValue;
  }

  private TranslateText() {
    for (let key of Object.keys(this.TranslateStrings)) {
      this.TranslateStrings[key] = this.translateService.instant(key);
    }
    this.selectedLocation = { Id: 0, LocationIdentifier: "", Name: "", Label: this.TranslateStrings["ALL_UPPERCASE"] };
    this.information = this.TranslateStrings["INFORMATION_UPPERCASE"];
    this.welcomeMessage = this.TranslateStrings["CAMPAIGN_ANALYTICS_WELCOME_MESSAGE"];
    this.withDateRangeMessage = this.TranslateStrings["CAMPAIGN_ANALYTICS_DATERANGE_MESSAGE"];
    this.withDateRangeTwentyMessage = this.TranslateStrings["CAMPAIGN_ANALYTICS_DATERANGEBYTWENTY_MESSAGE"];
    this.withLocationAndDateMessage = this.TranslateStrings["CAMPAIGN_ANALYTICS_LOCATION_DATE_MESSAGE"];
    this.pluralMapping["=1"] = this.translateService.instant("LOCATION");
    this.pluralMapping["other"] = this.translateService.instant("LOCATIONS");
  }

  private setInitialValues() {
    let month = new Date().getMonth();
    let year = new Date().getFullYear();
    let firstDay = new Date(year, month, 1);
    firstDay.setHours(0, 0, 0, 0);	// set to midnight
    let lastDay = new Date(year, month + 1, 0);
    lastDay.setHours(0, 0, 0, 0); //set to midnight
    this.fromDateModel = firstDay;
    this.toDateModel = lastDay;
    this.dateRangeModel.push(this.fromDateModel);
    this.dateRangeModel.push(this.toDateModel);
    this.DateRangeTooBig = false;
  }

  private getCampaignUpDownStats(search: any) {
    const route = "Campaign/GetUpDownReportAnalytics";

    if (this.DateRangeTooBig) {
      this.loading = false;
      return;
    }

    let searchTerm = "";

    if (search !== undefined && search.search !== undefined) {
      searchTerm = search.search;
    }
    this.query = searchTerm;
    this.searchText = search.search;

    let searchInfo: SearchInfo = {
      searchText: searchTerm,
      additionalQueryParams: []
    };

    if (this.selectedLocation && this.selectedLocation.Id > 0) {
      searchInfo.additionalQueryParams.push({ param: "LocationId", paramValue: this.selectedLocation.Id.toString() });
    }

    if (this.fromDateModel && this.toDateModel) {
      searchInfo.additionalQueryParams.push({ param: "StartDate", paramValue: encodeURIComponent(this.fromDateModel.toUTCString()) }, { param: "EndDate", paramValue: this.toDateModel.toUTCString() });
    }

    this.promise = this.campaignUpDownAnalytics.searchHelper(searchInfo, undefined, route).then((res) => {
      this.printedItemList = this.campaignUpDownAnalytics.resultList;
      this.screenReady = true;
      this.loading = false;
    }).catch((err) => {
      this.ipsMessage.error(this.translateService.instant("UNABLE_CAMPAIGN_UPDOWN_ANALYTICS_DATA"));
    });
  }

  public changeLocation() {
    this.ipsModal.displayTemplateScrollable(SearchModalComponent, { resolve: { addAll: true, search: "location" } })
      .then((response: ResultModel) => {
        this.selectedLocation = <SimpleSearchLocationModel>response.item;
        this.loading = true;
        this.getCampaignUpDownStats({ search: this.searchText });
      },
        () => { }); // Rejected
  }

  public viewUserModal(item: CampaignUpDownReportStatModel) {
    return this.ipsModal.displayTemplateScrollable(CampaignUpdownAnalyticsModalComponent, { resolve: { campaignUpDownData: item, startDate: this.fromDateModel, endDate: this.toDateModel } }, { backdrop: "static", centered: true });
  }

  public changeDateRange(event: any) {
    if (event && event.startDate && event.endDate) {
      this.fromDateModel = event.startDate;
      this.toDateModel = event.endDate;
      this.DateRangeTooBig = event.rangeTooBig;
      this.loading = true;
      this.getCampaignUpDownStats({ search: this.searchText });
    } else {
      this.fromDateModel = null;
      this.toDateModel = null;
      this.DateRangeTooBig = false;
    }
  }

  public download() {
    this.reportDownloadClickHandler();
  }

  public reportDownloadClickHandler() {
    if (this.DateRangeTooBig) {
      this.ipsMessage.error(this.translateService.instant("UNABLE_CAMPAIGN_UPDOWN_DOWNLOAD"));
      return;
    }

    let url = "";
    if (this.fromDateModel && this.toDateModel) {
      url = `${this.campaignService.urlApiBaseProductPlusVersion}Campaign/CampaignUpDownAnalytics/Download?startDate=${encodeURIComponent(this.fromDateModel.toUTCString())}&endDate=${encodeURIComponent(this.toDateModel.toUTCString())}`;
    }

    this.downloadService.PostDownload(url);

  }

}
