import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { LocationModel } from "../../model/LocationModel";
import { environment } from "../../../../environments/environment";

@Injectable()
export class LocationBySpaceService extends IpsBaseWebService {
    public SpaceId: string;
    private route: string;

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Location", "Profile", "v1");
        this.route = `${this.urlApiBaseProductPlusVersion}Space/{id}/Location`;
    }

    public headBySpace(id: string) {
        return this.headByRoute(this.route.replace("{id}", id));
    }
    // Override ipsBaseWebService.search method
    search<T>(searchInfo: SearchInfo, route: string): Promise<T> {
        return this.searchByRelation(searchInfo, "Space/" + searchInfo.id + "/Location/Search");
    }
}
