<h3 class="title ml-3" translate>CONTROLS</h3>

<div class="ips-flex-loading-box mb-4" *ngIf="!screenReady">
    <div class="content">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw mb-3"></i>
        <span class="d-block" translate>ACCESSING_CONTROLS_INFO</span>
    </div>
</div>

<div *ngIf="screenReady">
    <div class="ips-ls-search-module-imgn">
        <div class="form-row form-group">
            <div class="col-12">
                <label>&nbsp;</label>
                <div class="input-group">
                    <i class="form-control-feedback fa fa-search ips-ls-iconSearchInside"></i>
                    <input type="text" class="form-control form-control-lg ips-ls-input long-text-wrap" [(ngModel)]="searchText" [ngModelOptions]="{ standalone: true }" />
                </div>
            </div>
        </div>
        <div class="row no-gutters mt-1">
            <div class="col-12 col-md-auto ml-3">
                <span class="badge-pill badge-secondary font-weight-normal">{{ matchingRecordCount | number }}</span>
                <span class="ml-1">{{ matchingRecordCount | i18nPlural: pluralMapping }} {{ 'FOUND_OF' | translate }}</span>
            </div>
            <div class="col-12 col-md-auto ml-3 ml-md-2 mt-1 mt-md-0">
                <span class="badge-pill badge-secondary font-weight-normal">{{ totalRecordCount | number }}</span>
                <span class="ml-1">{{ 'TOTAL' | translate }} {{ totalRecordCount | i18nPlural: pluralMapping }}</span>
            </div>
        </div>
    </div>

    <div class="list-group mt-3">
        <a class="list-group-item list-group-item-action h4 p-3" uiSref="{{ control.TargetState }}" *ngFor="let control of filteredControls" [hidden]="control.Hidden">
            {{ control.Label | translate }}
        </a>
    </div>
</div>
