<div class="row">
    <div class="col-12 landingNavSpace"></div>
    <section class="col-6 col-sm-3 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.fileProcessing">
        <a class="landingNavLink" uiSref="main.fileProcessing">
            <div class="processingImg landingNavIcon"></div>
            <h3 translate>FILE_PROCESSING</h3>
        </a>
    </section>
    <section class="col-6 col-sm-3 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.profileUser.search">
        <a class="landingNavLink" uiSref="main.profileUser.search">
            <div class="usersImg landingNavIcon"></div>
            <h3 translate>USERS</h3>
        </a>
    </section>
    <section class="col-6 col-sm-3 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.toolsControls">
        <a class="landingNavLink" uiSref="main.toolsControls">
            <div class="controlsImg landingNavIcon"></div>
            <h3 translate>CONTROLS</h3>
        </a>
    </section>
    <section class="col-6 col-sm-3 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.customdata">
        <a class="landingNavLink" uiSref="main.customdata.search">
            <div class="customDataImg landingNavIcon"></div>
            <h3 translate>CUSTOM_DATA_FIELDS</h3>
        </a>
    </section>
    <section class="col-6 col-sm-3 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.profileTheme">
        <a class="landingNavLink" uiSref="main.profileTheme">
            <div class="themeImg landingNavIcon"></div>
            <h3 translate>THEME</h3>
        </a>
    </section>
    <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.productadminsearch">
        <a class="landingNavLink" uiSref="main.productadminsearch">
            <div class="myProductsImg landingNavIcon"></div>
            <h3 translate>MANAGE_PRODUCTS</h3>
        </a>
    </section>
    <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.campaign.signplanproblems">
        <a class="landingNavLink" uiSref="main.campaign.signplanproblems">
            <div class="signplanImg landingNavIcon"></div>
            <h3 translate>SIGN_PLAN_PROBLEMS</h3>
        </a>
    </section>
    <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.storeFrontCategory.search">
        <a class="landingNavLink" uiSref="main.storeFrontCategory.search">
            <div class="storeFrontCategoryImg landingNavIcon"></div>
            <h3 translate>STORE_FRONT_CATEGORIES</h3>
        </a>
    </section>
    <section class="col-6 col-sm-6 col-md-3 col-lg-2 landingNavDescription" ipsPermission="main.analytics">
        <a class="landingNavLink" uiSref="main.analytics">
            <div class="analyticsImg landingNavIcon"></div>
            <h3 translate>ANALYTICS</h3>
        </a>
    </section>
</div>
