import { Component, OnInit } from "@angular/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { SurveyService } from "../../service/survey.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LocationAnswerSeachResultModel } from "../../model/LocationAnswerSearchResultModel";

interface LocationAnswerSeachResultModelWithLabel extends LocationAnswerSeachResultModel {
    Label: string;
}

@Component({
    selector: "app-survey-answer-search-modal",
    templateUrl: "./survey-answer-search-modal.component.html",
    styleUrls: ["./survey-answer-search-modal.component.scss"]
})
export class SurveyAnswerSearchModalComponent implements OnInit {

    public busy: boolean;
    public listSearch: ListSearchHelper;
    public dataService;
    public promise: Promise<any>;
    public resolve: any;
    private questionId: number;
    public searchLabel = "LOCATION";
    public searchLabelPlural = "LOCATIONS";
    private route: string;
    private cancelLabel = "CANCEL";
    public modalTitle: string;

    constructor(private listSearchHelper: ListSearchHelper, private surveyService: SurveyService, private activeModal: NgbActiveModal, private activeProfileService: ActiveProfileService, private authService: AuthService) { }

    ngOnInit() {
        this.listSearch = this.listSearchHelper.getListSearchHelper(this.surveyService);
        this.busy = this.listSearch.busy;

        this.questionId = this.resolve.questionId;
        this.modalTitle = this.resolve.modalTitle;

        this.listSearch.TotalRecords = this.resolve.headCount;
        if (this.resolve.searchType === "message") {
            this.route = `SurveyQuestion/${this.questionId}/Message/Search`;
        } else {
            switch (this.resolve.profileType) {
                case "LocationGroup":
                    this.route = `SurveyQuestion/${this.questionId}/SubGroup/${this.resolve.locationSubGroupId}/Answer/Search`;
                    break;
                case "FixtureGroup":
                    this.route = `SurveyQuestion/${this.questionId}/AnswerResultType/${this.resolve.answerResultType}/Answer/Search`;
                    break;
            }
        }
        this.getListData("");
    }

    public getListData(search: string) {
        if (this.busy) {
            return;
        }

        this.promise = this.listSearch.searchHelper(<SearchInfo>{ searchText: search || "" }, undefined, this.route).then(() => {
            this.listSearch.resultList.forEach((item: LocationAnswerSeachResultModelWithLabel) => {
                if (item.Id !== 0) {
                    item.Label = `${item.LocationIdentifier} - ${item.Name} - ${item.City}, ${item.StateProvince}`;
                }
                if (item.Message && item.Message.Media && item.Message.Media.ResourceUri) {
                    item.Message.FullImage =
                        `${item.Message.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
                }
            });
        });
    }

    public close(closeMessage?: any) {
        if (closeMessage === undefined) {
            closeMessage = this.cancelLabel;
        }
        this.activeModal.dismiss(closeMessage);
    }
}
