var Position = /** @class */ (function () {
    function Position(x, y) {
        this.x = x;
        this.y = y;
    }
    Position.fromEvent = function (e, el) {
        if (el === void 0) { el = null; }
        /**
         * Fix issue: Resize doesn't work on Windows10 IE11 (and on some windows 7 IE11)
         * https://github.com/xieziyu/angular2-draggable/issues/164
         * e instanceof MouseEvent check returns false on IE11
         */
        if (this.isMouseEvent(e)) {
            return new Position(e.clientX, e.clientY);
        }
        else {
            if (el === null || e.changedTouches.length === 1) {
                return new Position(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
            }
            /**
             * Fix issue: Multiple phone draggables at the same time
             * https://github.com/xieziyu/angular2-draggable/issues/128
             */
            for (var i = 0; i < e.changedTouches.length; i++) {
                if (e.changedTouches[i].target === el) {
                    return new Position(e.changedTouches[i].clientX, e.changedTouches[i].clientY);
                }
            }
        }
    };
    Position.isMouseEvent = function (e) {
        return Object.prototype.toString.apply(e).indexOf("MouseEvent") === 8;
    };
    Position.isIPosition = function (obj) {
        return !!obj && ("x" in obj) && ("y" in obj);
    };
    Position.getCurrent = function (el) {
        var pos = new Position(0, 0);
        if (window) {
            var computed = window.getComputedStyle(el);
            if (computed) {
                var x = parseInt(computed.getPropertyValue("left"), 10);
                var y = parseInt(computed.getPropertyValue("top"), 10);
                pos.x = isNaN(x) ? 0 : x;
                pos.y = isNaN(y) ? 0 : y;
            }
            return pos;
        }
        else {
            console.error("Not Supported!");
            return null;
        }
    };
    Position.copy = function (p) {
        return new Position(0, 0).set(p);
    };
    Object.defineProperty(Position.prototype, "value", {
        get: function () {
            return { x: this.x, y: this.y };
        },
        enumerable: true,
        configurable: true
    });
    Position.prototype.add = function (p) {
        this.x += p.x;
        this.y += p.y;
        return this;
    };
    Position.prototype.subtract = function (p) {
        this.x -= p.x;
        this.y -= p.y;
        return this;
    };
    Position.prototype.multiply = function (n) {
        this.x *= n;
        this.y *= n;
    };
    Position.prototype.divide = function (n) {
        this.x /= n;
        this.y /= n;
    };
    Position.prototype.reset = function () {
        this.x = 0;
        this.y = 0;
        return this;
    };
    Position.prototype.set = function (p) {
        this.x = p.x;
        this.y = p.y;
        return this;
    };
    return Position;
}());
export { Position };
