/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./error.component";
import * as i4 from "imagine-ui-ng-quick-start";
import * as i5 from "@uirouter/angular";
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
export function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "text-center add-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "add-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-exclamation-triangle fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "add-padding"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["ERROR_PROFILE_LOAD"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "btn btn-default add-right-margin"], ["translate", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tryAgain() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["TRY_AGAIN"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); var currVal_1 = ""; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 114688, null, 0, i3.ErrorComponent, [i4.ProfileService, i5.StateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorComponentNgFactory = i1.ɵccf("app-error", i3.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
