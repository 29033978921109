import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { String as IpsString } from "typescript-string-operations";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { SelectionRuleType } from "../../type/SelectionRuleType";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { FieldControlBaseComponent } from "../field-control-base";

@Component({
  selector: "app-ips-text",
  templateUrl: "./ips-text.component.html",
  styleUrls: ["./ips-text.component.scss"]
})
export class IpsTextComponent extends FieldControlBaseComponent implements OnInit {
    @Input() selectionRule: SelectionRuleType;
    @Input() maxLength: number;

    public TranslateStrings: { [key: string]: string } = {
        "PLEASE_ENTER_DYNAMIC_NAME": "",
        "ENTER_DYNAMIC_NAME": "",
        "MAX_LENGTH_ERROR": ""
    };

    public errorMessages = {
        "required": () => IpsString.Format(this.TranslateStrings["PLEASE_ENTER_DYNAMIC_NAME"], this.name),
        "maxlength": (params) => IpsString.Format(this.TranslateStrings["MAX_LENGTH_ERROR"], this.maxLength)
    };

    ngOnInit() {
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(() => this.TranslateText());

        let validators = [];

        if (this.isFieldRequired) {
            validators.push(Validators.required);
        }

        if (this.maxLength > 0) {
            validators.push(Validators.maxLength(this.maxLength));
        }

        this.control = new FormControl(typeof (this.value) === "object" ? this.value.Text : this.value, validators);
        this.control.valueChanges.pipe(distinctUntilChanged(), debounceTime(350)).subscribe((value) => {
            let val = this.GetCustomDataValue();
            val.Text = value;
            this.valueChanged.emit(val);
        });

        this.group = new FormGroup({
            Name: new FormControl(this.name),
            Text: this.control,
            Id: new FormControl(this.value ? this.value.Id || 0 : 0),
            CustomDataId: new FormControl(this.customDataId)
        });

        this.fieldValues.push(this.group);
    }
}
