import { Component, OnInit } from "@angular/core";
import { PermissionService } from "imagine-ui-ng-security";
import { StateService } from "@uirouter/angular";
import { TranslateService } from "@ngx-translate/core";

export class ControlModel {
    public Label: string;
    public TargetState: string;
    public Hidden: boolean;
}

@Component({
    selector: "app-controls-search",
    templateUrl: "./controls-search.component.html"
})
export class ControlsSearchComponent implements OnInit {
    public searchText = "";
    public screenReady = false;

    private pluralMapping: { [k: string]: string } = {};

    private controlStates: string[] = [
        "main.toolsControlsProfile",
        "main.shipmethods",
        "main.generalSettingsEdit",
        "main.brandSettingsEdit"
    ];

    public availableControls: ControlModel[] = [];

    constructor(private permissionService: PermissionService,
        private stateService: StateService,
        private translateService: TranslateService) { }

    ngOnInit() {
        const promises: Promise<any>[] = [];

        // run translations
        promises.push(this.translateText());

        // configure available states
        promises.push(this.getAvailableControls().then((response: ControlModel[]) => {
            this.availableControls = response;
        }));

        Promise.all(promises).then(() => {
            this.screenReady = true;
        });
    }

    private translateText(): Promise<any> {
        const promise = this.translateService.get(["CONTROL", "CONTROLS"]).toPromise()
        .then((response) => {
            this.pluralMapping["=1"] = response["CONTROL"];
            this.pluralMapping["other"] = response["CONTROLS"];
        });

        return promise;
    }

    public getAvailableControls(): Promise<ControlModel[]> {
        const returnPromise = new Promise<ControlModel[]>((resolve, reject) => {
            const validStates = this.controlStates.filter((state: string) => {
                return this.permissionService.checkState(state).value;
            });

            const returnStates: ControlModel[] = [];
            if (validStates) {
                for (let index = 0; index < validStates.length; index++) {
                    const state = validStates[index];
                    const targetState = this.stateService.get(state);
                    returnStates.push({
                        Label: targetState.data.pageTitle[0],
                        TargetState: targetState.name,
                        Hidden: false
                    });
                }
            }

            resolve(returnStates);
        });

        return returnPromise;
    }

    public get filteredControls(): ControlModel[] {
        const textToMatch = this.searchText.toLocaleLowerCase() || "";

        this.availableControls.forEach((item) => {
            const hidden = item.Label.toLocaleLowerCase().indexOf(textToMatch) < 0;
            item.Hidden = hidden;
        });

        return this.availableControls;
    }

    public get totalRecordCount(): number {
        return this.availableControls.length;
    }

    public get matchingRecordCount(): number {
        return this.filteredControls.filter((item) => !item.Hidden).length;
    }
}
