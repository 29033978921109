import { OperationResult } from "../type/OperationResult";
import { Dimensions } from "imagine-ui-ng-core";
import { CustomerModel } from "./CustomerModel";
import { HolderVersionInfoModel } from "./HolderVersionInfoModel";

export class HolderModel extends CustomerModel {
    Id: number;
    Name: string;
    FixtureTypeId: number;
    HasFixtureReference: boolean;
    Result: OperationResult;
    Quantity: number;
    Size: Dimensions;
    IsObsolete: boolean;
    FixtureId: number;
    HolderVersionInfoId: number;
    HolderVersionName: string;
    CropTop?: number;
    CropLeft?: number;
    CropWidth?: number;
    CropHeight?: number;
    CropRotation?: number;
    CategoryCode?: string;
    Priority?: number;
}
