/*Sample code taken from https://github.com/ui-router/sample-app-angular/tree/master/src/app*/
import { UIRouter, Category, TransitionService, Transition } from "@uirouter/core";
//import { Visualizer } from "@uirouter/visualizer";
import { ActiveProfileService, UserPermissions } from "imagine-ui-ng-core";
import { AuthService, PermissionService } from "imagine-ui-ng-security";
import { PageTitleService, ProfileService } from "imagine-ui-ng-quick-start";
import { StateObject, Ng2StateDeclaration } from "@uirouter/angular";
import { NgModuleFactoryLoader } from "@angular/core";
import { CartService } from "../app/imagine-ui-ng-shop/index";

//import { googleAnalyticsHook } from "./util/ga";

/**
 * This file contains a Transition Hook which protects a
 * route that requires authentication.
 *
 * This hook redirects to /login when both:
 * - The user is not authenticated
 * - The user is navigating to a state that requires authentication
 */
export function requiresAuthHook(transitionService: TransitionService) {
    // Always require auth if not on the login state or ssoTokenReceiver
    const requiresAuthCriteria = {
        to: (state) => state.name.toLowerCase().indexOf("public") !== 0
    };

    // Function that returns a redirect for the current transition to the login state
    // if the user is not currently authenticated (according to the AuthService)

    const redirectToLogin = (transition: Transition) => {
        const authService: AuthService = transition.injector().get(AuthService);
        const $state = transition.router.stateService;
        if (!authService.isLoggedIn()) {
            authService.getNewIdToken();
            transition.abort();
        }
    };

    // Register the "requires auth" hook with the TransitionsService
    transitionService.onBefore(requiresAuthCriteria, redirectToLogin, { priority: 10 });
}

export function onBeforeCallback (transition: Transition) {
    const $state = transition.router.stateService;
    // NOTE: need to get the profileService just to make sure it is constructed before we access the ActiveProfileService. Only needed
    // when user refreshes or manually changes the URL.
    const profileService: ProfileService = transition.injector().get(ProfileService);
    const activeProfileService: ActiveProfileService = transition.injector().get(ActiveProfileService);
    const permissionService: PermissionService = transition.injector().get(PermissionService);
    const toState = transition.$to();
    const statePermissions = toState.data.permissions;
    let roles = activeProfileService.userTypes;

    let result = { value: true, location: "" };
    if (toState.name.toLowerCase().indexOf("public") !== 0) {
        result = permissionService.check(statePermissions, toState.name);
    }

    if (!result.value) {
        transition.abort();

        //check for redirect
        if (result.location) {

            //disregard if on same page
            if (transition.$from().name === result.location) {
                return;
            }

            $state.go(result.location);

        } else {
            //Unauthorized
            $state.go("main.unauthorized");
        }
    }
}

export function onSuccessCallback(transition: Transition) {
    const pageTitleService: PageTitleService = transition.injector().get(PageTitleService);
    let data = (<any>transition.router.stateService.current).data;
    if (!data) {
        console.error("State is missing 'data' property. Please add data and pageTitle");
    } else {
        pageTitleService.setTitle(data.pageTitle);
    }
}

export function onExitCallback(transition: Transition, state) {
    //when accessing main.campaign.view from breadscrumb the campaignId has to be mapped
    //set campaignId here from previous state
    if (transition.$to().name === "main.campaign.view") {
        let campaignId = transition.params("from").campaignId;
        if (campaignId !== transition.params("to").id) {
            const $state = transition.router.stateService;
            return $state.target(transition.to(),
                {
                    id: campaignId,
                });
        }
    }
}

export function onStartCallback(transition: Transition) {
    const $state = transition.router.stateService;
    const profileService: ProfileService = transition.injector().get(ProfileService);
    const cartService: CartService = transition.injector().get(CartService);

    //Look for brand change in the local storage
    let brandChangeId = localStorage.getItem("brandChangeId");
    if (brandChangeId) {
        localStorage.setItem("brandChangeId", "");
        profileService.changeBrand(brandChangeId).catch(() => {
            $state.go("error");
        }).then(() => {
            // explicitly calling getMyCart which will trigger a broadcast
            // to update the cart header quantity
            cartService.getMyCart();
        });
    }
}

export function setupHooks(transitionService: TransitionService) {

    const successCriteria = {
        to: (state) => true
    };
    transitionService.onBefore(successCriteria, onBeforeCallback);
    transitionService.onStart(successCriteria, onStartCallback);
    transitionService.onSuccess(successCriteria, onSuccessCallback);
    transitionService.onExit({ exiting: "main.campaign.promotion.edit" }, onExitCallback);
    transitionService.onExit({ exiting: "main.campaign.promotion.view" }, onExitCallback);
}

export function routerConfigFn(router: UIRouter) {
    const transitionService = router.transitionService;
    requiresAuthHook(transitionService);
    setupHooks(transitionService);
    //googleAnalyticsHook(transitionService);

    router.urlService.rules.otherwise({ state: "main.home" });

    // Pre-load lazy loaded modules. Used from here: https://github.com/ui-router/angular/issues/252
    const deregisterPreloadLazyLoadedRouteHook = router.transitionService.onSuccess({}, transition => {
        const loader = transition.injector().get(NgModuleFactoryLoader);
        transition.router.stateRegistry.get()
            .filter((state: Ng2StateDeclaration) => !!state.loadChildren)
            .forEach((state: Ng2StateDeclaration) => {
                // JiT
                if (typeof state.loadChildren === "function") {
                    state.loadChildren();
                    // AoT
                } else if (typeof state.loadChildren === "string") {
                    // Ignore the returned promise as we don't care when it loads, as long as the loading process has
                    // been initialised, it will load eventually.
                    loader.load(state.loadChildren);
                }
            });
        deregisterPreloadLazyLoadedRouteHook();
    });
}
