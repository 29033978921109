<div class="col-md-12">
    <div class="row">
        <section class="col-12">
            <h3 class="title">
                <span class="header-label-imgn">{{'VIEW_VERSION_INFO' | translate}}</span>
                <a class="ml-2" uiSref="main.storeProfileHolderVersion.edit" [uiParams] = "{id: holderversion.Id}" ipsPermission="main.storeProfileSpace.edit" ngbTooltip="{{'EDIT' | translate}} {{holderversion.Name}}" placement="bottom-right"><span class="fa fa fa-pencil editIcon"></span></a>
            </h3>
        </section>
    </div>
    <div [cgBusy]="promise" cgBusyConfig="{ message:{{'ACCESSING_VERSION_INFO' | translate}}}">
        <div class="row" [hidden]="!loaded">
            <section class="col-12 col-md-6">
                <div class="form-group">
                    <div class="card">
                        <div class="card-header view-info-header">
                            {{holderversion.Name}}
                        </div>
                        <div class="card-body">
                            <ul class="list-group long-text-wrap">
                                <li class="list-group-item">
                                    <div class="view-info-label" translate>NOTES_VERSION</div>
                                    <div class="view-info-format-imgn"> {{holderversion.Notes}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        
    </div>
    <ips-error-handler label="{{'ERROR_ACCESSING_VERSION_INFO' | translate }}" [promise]="promise"></ips-error-handler>
</div>
