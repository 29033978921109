var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var SurveyService = /** @class */ (function (_super) {
    __extends(SurveyService, _super);
    function SurveyService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("Survey", "Survey", "v1");
        return _this;
    }
    SurveyService.prototype.getStatusInfo = function (survey) {
        var info = { IconClass: "", Tooltip: "" };
        switch (survey.Status) {
            case "Open":
                info.IconClass = "img-survey-status-open";
                info.Tooltip = "TOOLTIP_SURVEY_STATUS_OPEN";
                break;
            case "Prep":
                info.IconClass = "img-survey-status-prep";
                info.Tooltip = "TOOLTIP_SURVEY_STATUS_PREP";
                break;
            case "Closed":
                info.IconClass = "img-survey-status-closed";
                info.Tooltip = "TOOLTIP_SURVEY_STATUS_CLOSED";
                break;
        }
        return info;
    };
    SurveyService.prototype.getSurveySections = function (surveyId) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/" + surveyId + "/SurveySection";
        return this.getByRoute(route);
    };
    SurveyService.prototype.getSurveyQuestionList = function (surveySectionId, locationId) {
        var route = this.urlApiBaseProductPlusVersion + "SurveySection/" + surveySectionId + "/Questions";
        if (locationId) {
            route = route + "?locationId=" + locationId;
        }
        return this.getByRoute(route);
    };
    SurveyService.prototype.getSurveyQuestionsWithSummary = function (surveySectionId) {
        var route = this.urlApiBaseProductPlusVersion + "SurveySection/" + surveySectionId + "/QuestionsWithAnswerSummary";
        return this.getByRoute(route);
    };
    SurveyService.prototype.calculateAudienceLocationBalanceList = function (requestModel) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/AudienceLocationBalanceList";
        return this.postByRoute(route, requestModel);
    };
    SurveyService.prototype.calculateAudienceLocationBalance = function (model) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/CalculateAudienceLocationBalance";
        return this.postByRoute(route, model);
    };
    SurveyService.prototype.postSection = function (model) {
        var route = this.urlApiBaseProductPlusVersion + "SurveySection";
        return this.postByRoute(route, model);
    };
    SurveyService.prototype.putSection = function (model) {
        var route = this.urlApiBaseProductPlusVersion + "SurveySection";
        return this.put(model, undefined, route);
    };
    SurveyService.prototype.deleteSection = function (selectionId) {
        var route = this.urlApiBaseProductPlusVersion + "SurveySection/" + selectionId;
        return this.deleteByRoute(route);
    };
    SurveyService.prototype.getQuestionLocationIds = function (questionId, locationSubGroupId) {
        var route = this.urlApiBaseProductPlusVersion + "SurveyQuestion/" + questionId + "/SubGroup/" + locationSubGroupId + "/LocationIds";
        return this.getByRoute(route);
    };
    SurveyService.prototype.getFeatureList = function (surveyId) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/" + surveyId + "/LocationGroupList";
        return this.getByRoute(route);
    };
    SurveyService.prototype.getFixtureGroupList = function (surveyId) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/" + surveyId + "/FixtureGroupList";
        return this.getByRoute(route);
    };
    SurveyService.prototype.getFixtureList = function (surveyId) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/" + surveyId + "/FixtureList";
        return this.getByRoute(route);
    };
    SurveyService.prototype.getLocationGroupSurveyInUse = function (locationGroupId) {
        var route = this.urlApiBaseProductPlusVersion + "LocationGroup/" + locationGroupId + "/SurveyInUse";
        return this.getByRoute(route);
    };
    SurveyService.prototype.getLocationSubGroupSurveyInUse = function (subGroupId) {
        var route = this.urlApiBaseProductPlusVersion + "LocationSubGroup/" + subGroupId + "/SurveyInUse";
        return this.getByRoute(route);
    };
    SurveyService.prototype.getMySectionQuestionAnswers = function (sectionId, locationId) {
        var route = this.urlApiBaseProductPlusVersion + "SurveySection/" + sectionId + "/Location/" + locationId + "/MyQuestions";
        return this.getByRoute(route);
    };
    SurveyService.prototype.getInstructions = function (surveyId) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/" + surveyId + "/Instruction";
        return this.getByRoute(route);
    };
    /**
     * Save a new answer
     * @param answer
     */
    SurveyService.prototype.postAnswer = function (answer) {
        var route = this.urlApiBaseProductPlusVersion + "/Survey/SaveAnswer";
        return this.postByRoute(route, answer);
    };
    /**
     * Update an existing answer
     * @param answer
     */
    SurveyService.prototype.putAnswer = function (answer) {
        var route = this.urlApiBaseProductPlusVersion + "/Survey/SaveAnswer";
        return this.put(answer, undefined, route);
    };
    SurveyService.prototype.getNextLocation = function (surveyId, locationId) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/" + surveyId + "/GetNextLocation/" + locationId;
        return this.getByRoute(route);
    };
    SurveyService.prototype.getNextSurvey = function (surveyId, locationId) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/" + surveyId + "/Location/" + locationId + "/GetNextSurvey/";
        return this.getByRoute(route);
    };
    SurveyService.prototype.getMySurveySections = function (surveyId, locationId) {
        var route = this.urlApiBaseProductPlusVersion + "Survey/" + surveyId + "/Location/" + locationId + "/MySurveySection";
        return this.getByRoute(route);
    };
    SurveyService.prototype.postMessage = function (surveyMessage, file) {
        var route = this.urlApiBaseProductPlusVersion + "/Survey/Message";
        return this.post(surveyMessage, file, route);
    };
    SurveyService.prototype.putMessage = function (surveyMessage, file) {
        var route = this.urlApiBaseProductPlusVersion + "/Survey/Message";
        return this.put(surveyMessage, file, route);
    };
    SurveyService.prototype.deleteMessage = function (id) {
        var route = this.urlApiBaseProductPlusVersion + "/Survey/Message/" + id;
        return this.deleteByRoute(route);
    };
    return SurveyService;
}(IpsBaseWebService));
export { SurveyService };
