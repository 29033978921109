import { Component, OnInit, ViewChild } from "@angular/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/angular";
import { NgForm } from "@angular/forms";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { TemplateFormBase } from "../../../shared/templateFormBase";

import { FixtureGroupService, CustomerModel, HolderModel, FixtureGroupModel, StoreFrontCategoryService, StoreFrontCategory } from "../../index";

class HolderInternal extends HolderModel {
    public inputName: string;
    public inputPriority: string;
    constructor(_inputName: string, _inputPriority: string) {
        super();
        this.inputName = _inputName;
        this.inputPriority = _inputPriority;
        this.Id = 0;
        this.Name = "";
        this.Priority = 0,
        this.HasFixtureReference = false;
    }
}

class FixtureGroupInternal extends FixtureGroupModel {
    public Holders: HolderInternal[];
    constructor(holderInputName: string, holderInputPriority: string) {
        super();
        this.Name = "";
        this.Notes = "";
        this.Holders = [new HolderInternal(holderInputName, holderInputPriority)];
    }
}

interface IValidationResult {
    isValid: boolean;
    message: string;
}



@Component({
    selector: "app-fixture-group-edit",
    templateUrl: "./fixture-group-edit.component.html",
    styleUrls: ["./fixture-group-edit.component.scss"]
})
export class FixtureGroupEditComponent extends TemplateFormBase implements OnInit {

    @ViewChild("fgForm") public fgForm: NgForm;

    private dataService: FixtureGroupService;
    private indexer = 0;
    private indexerTwo = 0;
    public myFixtureGroup: FixtureGroupInternal;

    public breadCrumbLabel: string;
    public storeFrontCategoryList: StoreFrontCategory[];
    public loaded: boolean;
    public holderLabel: string;
    public promise: Promise<void>;

    public textUnableDeleteHolderWithName = "UNABLE_DELETE_HOLDER_WITH_NAME";
    public textPromptDeleteBody = "PROMPT_DELETE_BODY";

    public textMaxLength = "MAX_LENGTH_ERROR";
    public requiredMessage = "PLEASE_ENTER_NAME";
    public requiredValue = "PLEASE_ENTER_PRIORITY";
    public duplicatedHolderName = "DUPLICATE_HOLDER_NAME";
    public duplicatePriorityValue = "DUPLICATE_HOLDER_PRIORITY";
    public priorityPatternRule = "FIXTURE_HOLDER_PRIORITY_RULE";

    public errorMessages = {
        "required": () => this.requiredMessage,
        "maxlength": (params) => IpsString.Format(this.textMaxLength, params.requiredLength),
        "appDuplicateValidator": () => this.duplicatedHolderName,
        "pattern": () => this.priorityPatternRule
    };
    public canDeleteErrorList = [];

    constructor(fixtureGroupService: FixtureGroupService,
        private ipsMessage: IpsMessageService,
        private translateService: TranslateService,
        private pageTitleService: PageTitleService,
        private activeProfileService: ActiveProfileService,
        transition: Transition,
        private stateService: StateService,
        private storeFrontCategoryService: StoreFrontCategoryService
    ) {
        super();

        this.dataService = fixtureGroupService;

        let id = transition.params().id === "0" ? "" : transition.params().id;

        let pageTitle = id === "" ? "CREATE_FIXTURE_GROUP" : "EDIT_FIXTURE_GROUP";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        this.myFixtureGroup = new FixtureGroupInternal("holderHtmlName" + this.indexer++, "holderHtmlPriority" + this.indexerTwo++);

        //If we got an ID to load, load it.
        if (id > 0) {
            //Initial call to populate screen on load
            this.getFixtureGroup(id);
        } else if (id === "") {
            this.loaded = true;
        }

        this.holderLabel = "HOLDER_NAMES";
    }

    ngOnInit() {
        this.translateText();
        this.storeFrontCategoryList = null;
        this.translateService.onLangChange.subscribe(() => this.translateText());
        super.setFormPristine(this.fgForm, 0);
        this.getStoreFrontCategoryList();
    }

    private translateText() {
        this.translateService.get(["UNABLE_DELETE_HOLDER_WITH_NAME", "PROMPT_DELETE_BODY", "MAX_LENGTH_ERROR", "PLEASE_ENTER_NAME", "DUPLICATE_HOLDER_NAME", "PLEASE_ENTER_PRIORITY", "DUPLICATE_HOLDER_PRIORITY", "FIXTURE_HOLDER_PRIORITY_RULE"]).subscribe((res: [string]) => {

            this.textUnableDeleteHolderWithName = res["UNABLE_DELETE_HOLDER_WITH_NAME"];
            this.textPromptDeleteBody = res["PROMPT_DELETE_BODY"];
            this.textMaxLength = res["MAX_LENGTH_ERROR"];
            this.requiredMessage = res["PLEASE_ENTER_NAME"];
            this.duplicatedHolderName = res["DUPLICATE_HOLDER_NAME"];
            this.requiredValue = res["PLEASE_ENTER_PRIORITY"];
            this.duplicatePriorityValue = res["DUPLICATE_HOLDER_PRIORITY"];
            this.priorityPatternRule = res["FIXTURE_HOLDER_PRIORITY_RULE"];
        });
    }

    // Private functions
    private findHolder(inputName: string): HolderInternal {
        for (let i = 0; i < this.myFixtureGroup.Holders.length; i++) {
            let holder = this.myFixtureGroup.Holders[i];
            if (holder.inputName === inputName) {
                return holder;
            }
        }
    }

    private deleteHolder(inputName: string): boolean {
        let arr = this.myFixtureGroup.Holders;
        let deleted = false;
        for (let i = 0; i < arr.length; i++) {
            let holder = arr[i];
            if (holder.inputName === inputName) {
                arr.splice(i, 1);
                deleted = true;
                break;
            }
        }

        return deleted;
    }

    private checkHolderErrors(holders: HolderModel[]): void {
        let msg = "";
        for (let i = 0; i < holders.length; i++) {
            if (holders[i].Result === 3) {
                msg += " " + holders[i].Name + ",";
            }
        }

        if (msg.length) {
            throw new Error(`${this.textUnableDeleteHolderWithName}${msg.slice(0, -1)}`);
        }
    }

    private initHoldersList(holderList: HolderInternal[]) {
        if (holderList) {
            // Loop through each item in the array and set the input name.  We need the inputName
            // in the gui only for validaiton.
            for (let i = 0; i < holderList.length; i++) {
                // Need to use a long inputName for html Id, just a number doesn"t work well for validation.
                holderList[i].inputName = "holderHtmlName" + this.indexer++;
                holderList[i].inputPriority = "holderHtmlPriority" + this.indexerTwo++;
            }
            // Add one empty holder if we don"t have any yet
            if (holderList.length === 0) {
                holderList.push(new HolderInternal("holderHtmlName" + this.indexer++, "holderHtmlPriority" + this.indexerTwo++));
            }
        }
    }

    private reloadHolderListAfterSave(newHolderList: HolderInternal[]) {
        this.myFixtureGroup.Holders = [];

        for (let i = 0; i < newHolderList.length; i++) {

            let item = newHolderList[i];
            item.inputName = "holderHtmlName" + this.indexer++;
            item.inputPriority = "holderHtmlPriority" + this.indexerTwo++;

            this.myFixtureGroup.Holders.push(item);
        }
        this.myFixtureGroup.Holders.sort(function (a, b) { return (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : ((b.Name.toLowerCase() > a.Name.toLowerCase()) ? -1 : 0); });

        this.checkHolderErrors(newHolderList);
    }

    private saveFixtureGroupCallback(): Promise<void> {
        let id = this.myFixtureGroup.Id;
        //add business identity to fixturegroup/holders
        for (let i = 0; i < this.myFixtureGroup.Holders.length; i++) {
            this.myFixtureGroup.Holders[i].BusinessIdentity = this.activeProfileService.businessIdentity;
        }
        this.myFixtureGroup.BusinessIdentity = this.activeProfileService.businessIdentity;

        if (id) {
            return this.dataService.put<FixtureGroupInternal>(this.myFixtureGroup).then((response) => {
                this.reloadHolderListAfterSave(response.Holders);

                this.fgForm.form.markAsPristine();
            });

        } else {
            return this.dataService.post<FixtureGroupInternal>(this.myFixtureGroup).then((response) => {
                this.reloadHolderListAfterSave(response.Holders);
                this.fgForm.form.markAsPristine();
                this.myFixtureGroup = response;
                this.breadCrumbLabel = "EDIT_FIXTURE_GROUP";
                this.pageTitleService.setTitle([this.breadCrumbLabel]);
            });
        }
    }

    private deleteFixtureGroup(): Promise<void> {
        let id = this.myFixtureGroup.Id;
        return this.dataService.delete(id).then(function (response) {
        });
    }

    private getStoreFrontCategoryList() {
        return this.storeFrontCategoryService.getList()
            .then((response) => {
                this.storeFrontCategoryList = response;
            });
    }

    //
    // Public Functions
    //
    public getFixtureGroup(id: number): void {
        this.loaded = false;

        let canDelete = this.dataService.deleteCheck(id.toString())
            .then((response: any) => {
                this.canDeleteErrorList = response;
            })
            .catch((response: any) => {
                this.canDeleteErrorList = ["-ERROR-"];
            });

        this.promise = this.dataService.get<FixtureGroupInternal>(id).then((response) => {
            Object.assign(this.myFixtureGroup, response);
            let arr = this.myFixtureGroup.Holders;
            this.initHoldersList(arr);

            this.holderLabel = arr.length > 1 ? "HOLDER_NAMES" : "HOLDER_NAME";
        });

        Promise.all([canDelete, this.promise]).then(() => {
            this.loaded = true;
        });
    }

    public addHolderIfNoEmpty(): void {
        let arr = this.myFixtureGroup.Holders;
        let spaceFound = false;

        // Loop through each item in the array, and only add one if all are NOT empty
        for (let i = 0; i < arr.length; i++) {
            if (!arr[i].Name || arr[i].Name === "") {
                spaceFound = true;
                break;
            }
        }
        if (!spaceFound) {
            arr.push(new HolderInternal("holderHtmlName" + this.indexer++, "holderHtmlPriority" + this.indexerTwo++));
        }
    }

    public addHolder(): void {
        let arr = this.myFixtureGroup.Holders;
        arr.push(new HolderInternal("holderHtmlName" + this.indexer++, "holderHtmlPriority" + this.indexerTwo++));


        this.holderLabel = arr.length > 1 ? "HOLDER_NAMES" : "HOLDER_NAME";
    }

    public removeHolder(inputName: string): void {
        if (this.myFixtureGroup.Holders.length === 1) {
            this.ipsMessage.error("AT_LEAST_ONE_HOLDER_REQUIRED");
            return;
        }

        let holder = this.findHolder(inputName);

        if (holder && holder.Id > 0) {
            if (holder.HasFixtureReference) {
                this.ipsMessage.error("UNABLE_DELETE_HOLDER");
            } else {
                this.ipsMessage.confirm(undefined, undefined)
                    .then((result) => {
                        if (result) {
                            //do something with the result
                            console.log("deleting holder");
                            if (this.deleteHolder(inputName)) {
                                // Set as dirty since we removed a holder.
                                this.fgForm.form.markAsDirty();
                            }
                        }
                    })
                    .catch(() => {
                        // rejection
                    });
            }
        } else {
            if (this.deleteHolder(inputName)) {
                // Set as dirty since we removed a holder.
                this.fgForm.form.markAsDirty();
            }
        }

        this.holderLabel = this.myFixtureGroup.Holders.length > 1 ? "HOLDER_NAMES" : "HOLDER_NAME";
    }

    public deleteFixtureGroupPrompt(): void {

        if (this.loaded && this.canDeleteErrorList.length > 0) {
            this.ipsMessage.error("Unable to delete! Record is begin used in the following Market Groups:\r\n" + this.canDeleteErrorList.join("\r\n"));
            return null;
        }

        if (this.myFixtureGroup.HasFixtureReference) {
            this.ipsMessage.error("UNABLE_DELETE_FIXTURE_GROUP");
        } else {
            let translated = IpsString.Format(this.textPromptDeleteBody, this.myFixtureGroup.Name);
            this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deleteFixtureGroup(), progressMessage: "DELETING" }, undefined)
                .then((result) => {
                    if (result) {
                        this.stateService.go("main.storeProfileFixtureGroup.search");
                    }
                })
                .catch(() => {
                    // rejection
                });
        }
    }

    public saveFixtureGroupPrompt(redirect: boolean): Promise<any> {
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: () => this.saveFixtureGroupCallback(), progressMessage: "SAVING" }, undefined).then((result) => {
            if (result && redirect) {
                this.stateService.go("main.storeProfileFixtureGroup.search");
            }
        });
    }

    public hasHolderWithText(): boolean {
        let hasText = false;
        let arr = this.myFixtureGroup.Holders;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].Name && arr[i].Name.length > 0) {
                hasText = true;
                break;
            }
        }
        return hasText;
    }

    public validationMaxLength(value: number) {
        return IpsString.Format(this.textMaxLength, value);
    }

    public getErrorMessage(key?: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "holdername":
                    msgs["required"] = () => this.requiredMessage;
                    msgs["appDuplicateValidator"] = () => this.duplicatedHolderName;
                    break;
                case "holderpriority":
                    msgs["required"] = () => this.requiredValue;
                    msgs["appDuplicateValidator"] = () => this.duplicatePriorityValue;
                    msgs["pattern"] = () => this.priorityPatternRule;
                    break;
                default:
            }
        }

        return msgs;
    }

}
