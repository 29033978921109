import { Component, OnInit } from "@angular/core";
import { StateService, StateParams, Transition } from "@uirouter/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PayflowResponseModel, CartService, OrderWithPaymentModel } from "../index";

@Component({
  selector: "app-paypal-postback",
  templateUrl: "./paypal-postback.component.html",
  styleUrls: ["./paypal-postback.component.scss"]
})
export class PaypalPostbackComponent implements OnInit {
    private paymentInfo: OrderWithPaymentModel;
    private cartId: number;
    public error = false;
    public errorMessage: string;
    constructor(
        private activeProfileService: ActiveProfileService, private transition: Transition,
        private stateService: StateService, private cartService: CartService) {}

    ngOnInit() {
        this.processQueryParams(this.transition.params());
        if (this.paymentInfo.Result === "0") {
            this.cartService.placeCartOrderWithPayment(this.paymentInfo).then((result) => {
                this.stateService.go("main.myorders.view", { id: result, showBanner: true });
            }).catch(() => {
                //void authorization
                this.cartService.VoidAuthorization(this.paymentInfo.TransactionId).then(() => {
                    this.errorMessage = "ORDER_ERROR";
                    this.error = true;
                });
            });
        } else {
            this.errorMessage = "PAYPAL_ERROR";
            this.error = true;
        }
    }
    public processQueryParams(parms) {
        if (parms.cartId) {
            this.cartId = parms.cartId;
            const address = parms.BILLTOSTREET.split("+").join(" ");
            const city = parms.BILLTOCITY.split("+").join(" ");
            this.paymentInfo = {
                Result: parms.RESULT,
                TransactionId: parms.PNREF,
                TransactionType: parms.TRXTYPE,
                Message: parms.RESPMSG,
                AuthCode: parms.AUTHCODE,
                Amount: parms.AMT,
                Email: parms.BILLTOEMAI,
                FirstName: parms.BILLTOFIRSTNAME,
                LastName: parms.BILLTOLASTNAME,
                Line1: address,
                City: city,
                StateProvince: parms.BILLTOSTATE,
                Country: parms.COUNTRY,
                PostalCode: parms.BILLTOZIP,
                Account: parms.ACCT,
                UserName: this.activeProfileService.profile.UserName,
                BusinessIdentity: this.activeProfileService.businessIdentity
            };
        }
    }
}
