import {from as observableFrom, timer as observableTimer, of as observableOf,  Observable } from "rxjs";
import {map, switchMap} from "rxjs/operators";
import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from "@angular/forms";
import { AbstractControl, AsyncValidatorFn, ValidationErrors, AsyncValidator, ValidatorFn } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService, CountryModel, RoleModel } from "imagine-ui-ng-core";
import { PageTitleService, CountryService, RoleService, ProfileService, UserModel, UserService, BusinessIdRoleLocation } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString, StringBuilder } from "typescript-string-operations";
import { HttpClient } from "@angular/common/http";
import union from "lodash-es/union";

interface UIRoleModel extends RoleModel {
    DisplayName?: string;
}

type RoleType = "Corporate" | "Field";

function duplicatePassword(input: FormControl) {

    if (!input.root["controls"]) {
        return null;
    }

    const exactMatch = input.root["controls"].password.value === input.value;
    return exactMatch ? null : { mismatchedPassword: true };
}

@Component({
    selector: "app-user-edit",
    templateUrl: "./user-edit.component.html",
    styleUrls: ["./user-edit.component.scss"]
})
export class UserEditComponent implements OnInit {

    private account: UserModel;
    private pageTitle = "";
    private userLang: string;

    public onAccountMe: boolean;
    public userName: string;
    public inCreateMode: boolean;
    public localTimezonesList: any = null;
    public languageList = [];
    public localeList: CountryModel[];
    public RoleListCorp: UIRoleModel[];
    public RoleListField: UIRoleModel[];
    public saveLabel: string;
    public usernameAndEmailLinked = true;
    public breadCrumbLabel: string;
    public roleLoaded = false;
    public accountLoaded = false;
    public activeRoleType: RoleType;

    public promise: Promise<void>;
    public userForm: FormGroup;
    private UserName: FormControl;
    private FirstName: FormControl;
    private LastName: FormControl;
    private Email: FormControl;
    private RoleId: FormControl;
    private RoleType: FormControl;
    private locationList: any[];  // List of locations assigned to user. need to maintain this list even if roles switched.
    private offBrandRoles: BusinessIdRoleLocation[] = []; // List of roles for the user that are NOT for currently selected brand.
    //validation error message
    private firstNameRequiredMessages: any;
    private lastNameRequiredMessages: any;
    private userNameRequiredMessages: any;
    private roleRequiredMessages: any;
    private emailRequiredMessages: any;
    private validEmailMessages: string;
    private textMaxLength: string;
    private usernameUnavailable: string;
    private missingRole: string;
    public IsNonSso: boolean;

    private errorMessages = {
        "required": () => this.firstNameRequiredMessages,
        "email": () => this.validEmailMessages,
        "maxlength": (params) => IpsString.Format(this.textMaxLength, params.requiredLength),
        "usernameExists": () => this.usernameUnavailable,
        "missingRole": () => this.missingRole
    };

    constructor(private userService: UserService, private ipsMessage: IpsMessageService, private $state: StateService,
        private transition: Transition, private translateService: TranslateService, private pageTitleService: PageTitleService,
        private activeProfileService: ActiveProfileService, private countryService: CountryService, private roleService: RoleService,
        private profileService: ProfileService, private httpClient: HttpClient, private fb: FormBuilder) { }

    private translateText() {
        this.translateService.get(["PLEASE_ENTER_FIRST_NAME", "PLEASE_ENTER_LAST_NAME", "PLEASE_ENTER_USERNAME", "PLEASE_ENTER_EMAIL", "PLEASE_SELECT_USER_ROLE", "MAX_LENGTH_ERROR", "INVALID_EMAIL", "USERNAME_UNAVAILABLE", "PLEASE_SELECT_AT_LEAST_ONE_ROLE"]).subscribe((res: [string]) => {
            this.firstNameRequiredMessages = res["PLEASE_ENTER_FIRST_NAME"];
            this.lastNameRequiredMessages = res["PLEASE_ENTER_LAST_NAME"];
            this.userNameRequiredMessages = res["PLEASE_ENTER_USERNAME"];
            this.emailRequiredMessages = res["PLEASE_ENTER_EMAIL"];
            this.roleRequiredMessages = res["PLEASE_SELECT_USER_ROLE"];
            this.validEmailMessages = res["INVALID_EMAIL"];
            this.textMaxLength = res["MAX_LENGTH_ERROR"];
            this.usernameUnavailable = res["USERNAME_UNAVAILABLE"];
            this.missingRole = res["PLEASE_SELECT_AT_LEAST_ONE_ROLE"];
        });
    }

    ngOnInit() {
        let paramId = this.transition.params().id === "0" ? "" : this.transition.params().id;
        this.inCreateMode = paramId === "";
        this.userLang = (navigator.language || (navigator as any).userLanguage).split("-");
        this.onAccountMe = paramId === "me";
        this.userName = this.onAccountMe ? "me" : paramId;
        this.saveLabel = this.userName === "" ? "SAVE_SEND_LINK" : this.onAccountMe ? "SAVE_ACCOUNT" : "SAVE_AND_RETURN";
        this.IsNonSso = this.profileService.isNonSso();

        if (this.onAccountMe) {
            this.pageTitle = "MY_ACCOUNT";
        } else {
            this.pageTitle = this.inCreateMode ? "CREATE_USER" : "EDIT_USER";
        }
        this.breadCrumbLabel = this.pageTitle;
        this.pageTitleService.setTitle([this.pageTitle]);

        this.createForm();

        this.getLanguageList();
        this.getLocalList();
        if (!this.onAccountMe) {
            this.promise = this.getRoleList();
        } else {
            this.roleLoaded = true;
        }

        if (this.onAccountMe) {
            this.getAccount(this.activeProfileService.profile.UserName + "/me");
        } else if (this.userName === "") {
              this.account = {
                RoleId: "",
                Profile: {
                    TimeZone: null,
                    Locale: this.userLang ? this.userLang[1] : null
                },
                Language: this.userLang ? this.userLang[0] : null
            };
            this.accountLoaded = true;
        } else {
            this.getAccount(this.userName);
        }

        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    private checkUserName(): AsyncValidatorFn {
        return (control: AbstractControl) => {
            let debounceTime = 500; //milliseconds
            if (this.transition.params().id !== "0") {
                return observableOf(null);
            } else {
                return observableTimer(debounceTime).pipe(switchMap(() => {
                    return observableFrom(this.userService.checkValidUserName(control.value)).pipe(map(
                        result => {
                            switch (result.Status) {
                                case "Available":
                                    return null;
                                case "Assigned":
                                case "Unavailable":
                                    return { "usernameExists": true };
                            }
                        }
                    ));
                }));
            }
        };
    }

    private createForm() {
        this.UserName = new FormControl({ value: "", disabled: !this.inCreateMode }, [Validators.required, Validators.email, Validators.maxLength(50)], [this.checkUserName()]);
        this.FirstName = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.LastName = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.Email = new FormControl("", [Validators.required, Validators.email, Validators.maxLength(100)]);

        if (this.onAccountMe) {
            this.RoleId = new FormControl("");
            this.RoleType = new FormControl("");
        } else {
            this.RoleId = new FormControl("", [Validators.requiredTrue]);
            this.RoleType = new FormControl("", [Validators.required]);
        }

        this.userForm = new FormGroup({
            UserName: this.UserName,
            FirstName: this.FirstName,
            LastName: this.LastName,
            Email: this.Email,
            Phone: new FormControl(""),
            Roles: new FormArray([]),
            TimeZone: new FormControl(""),
            Locale: new FormControl(""),
            Language: new FormControl(""),
            Role: new FormControl({ value: "", disabled: true }),
            RoleId: new FormControl(""),
            RoleType: this.RoleType,
            CorporateRoles: new FormArray([]),
            FieldRoles: new FormArray([])
        }, [this.ValidateRoles()]);
    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "firstname":
                    msgs["required"] = () => this.firstNameRequiredMessages;
                    break;
                case "lastname":
                    msgs["required"] = () => this.lastNameRequiredMessages;
                    break;
                case "username":
                    msgs["required"] = () => this.userNameRequiredMessages;
                    break;
                case "email":
                    msgs["required"] = () => this.emailRequiredMessages;
                    break;
                case "role":
                    msgs["required"] = () => this.roleRequiredMessages;
                    break;
                default:
            }
        }

        return msgs;
    }

    private getLanguageList(): void {

        this.httpClient.get("./assets/docs/language.json").subscribe(
            (data: any[]) => {
                this.languageList = data;
            }
        );
    }

    private getLocalList(): void {
        this.countryService.Get().then((data) => {
            this.localeList = data;
        });
    }

    private populateRolesList(roleId: string): void {
        //Pull all the selected roles
        let roles: BusinessIdRoleLocation[] = [];

        let roleType = this.userForm.get("RoleType").value;
        if (roleType) {
            if (roleType === "Corporate") {
                roles = this.CorporateRoles.value.filter(role => role.Selected);
            } else if (roleType === "Field") {
                roles = this.FieldRoles.value.filter(role => role.Selected);
                if (roles) {
                    // set the locationList on the first role. This will ensure the location list is maintained, even if roles were changed.
                    roles.forEach((role, index) => {
                        if (index === 0) {
                            role.Locations = this.locationList;
                        } else {
                            role.Locations = undefined;
                        }
                    });
                }
            }
        }

        //Lets add in roles that are not to be shown to user. If they have it let them keep it
        let hiddenRoles = this.account.Profile.Roles ?
            this.account.Profile.Roles.filter(role => role.Role === "EcommSiteAdmin" ||
                (role.BusinessIdentity === this.activeProfileService.businessIdentity && (role.Role === "Viewer" || role.Role === "Administrator"))) :
            [];

        roles = roles.concat(hiddenRoles, this.offBrandRoles);

        this.account.Profile.Roles = roles;
    }

    private getRoleList(): Promise<void> {
        this.roleLoaded = false;
        return this.roleService.getByApplication("ecomm")
            .then((response: any) => {
                this.RoleListCorp = response.filter(item => item.Category === "Corporate");
                this.RoleListField = response.filter(item => item.Category === "Field");

                //Map, filter and sort roles
                let corpRoles = response.filter(item => item.Category === "Corporate" && item.Name !== "Administrator" && item.Name !== "Viewer").map(item => this.createRoleGroup(item));
                corpRoles.sort((a, b) => {
                    let x = a.value.DisplayName.toLowerCase(), y = b.value.DisplayName.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                });
                this.userForm.setControl("CorporateRoles", this.fb.array(corpRoles));

                let fieldRoles = response.filter(item => item.Category === "Field").map(item => this.createRoleGroup(item));
                fieldRoles.sort((a, b) => {
                    let x = a.value.DisplayName.toLowerCase(), y = b.value.DisplayName.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                });
                this.userForm.setControl("FieldRoles", this.fb.array(fieldRoles));

                this.roleLoaded = true;

                this.DisableCorporateRoles();
                this.DisableFieldRoles();
            });
    }

    private createRoleGroup(role: RoleModel): FormGroup {
        return this.fb.group({
            Id: this.fb.control(role.Id),
            DisplayName: this.fb.control(this.translateService.instant(this.userService.getRoleTranslationKey(role.Name))),
            Selected: this.fb.control(false),
            Role: this.fb.control(role.Name),
            RoleData: this.fb.control(""),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity)
        });
    }

    private saveAccount() {

        let user = this.userForm.value;

        //Set multiple fields into the okta format & add default values
        this.account.Profile.Locale = (user.Language || "en") + "-" + (user.Locale || "US");

        //update this.account with new values
        //if formControl UserName is disabled, its value is not in the user
        this.account.Profile.UserName = user.UserName ? user.UserName : this.userForm.controls["UserName"].value;
        this.account.Profile.FirstName = user.FirstName;
        this.account.Profile.LastName = user.LastName;
        this.account.Profile.Email = user.Email;
        this.account.Profile.PrimaryPhone = user.Phone;


        if (this.onAccountMe) {
            this.account.Language = user.Language;
            //Editing self
            return this.userService.putUser(`${this.activeProfileService.profile.UserName}/me`, this.account)
                .then((result) => {
                    this.userForm.markAsPristine();

                    //Prompt message to logoff to see changes
                    this.ipsMessage.confirm({
                        body: "PROFILE_RELOAD_REQUIRED",
                        ok: "LOGOUT",
                        cancel: "CANCEL"
                    })
                    .then((msgResult: any) => {
                        this.profileService.signOut().then(() => {
                            this.$state.go("public.login");
                        });
                    })
                    .catch((msgResult: any) => {
                        this.$state.go("main.home");
                    });
                });
        } else if (this.userName.length) {
            //Editing another user
            this.populateRolesList(user.RoleId);
            return this.userService.putUser(this.userName, this.account);
        } else {
            //Creating user
            this.populateRolesList(user.RoleId);

            this.account.Profile.CustomerIdentity = [this.activeProfileService.customerId];

            return this.userService.postUser(this.account.Profile.UserName, this.account);
        }
    }

    private getAccount(id: string): void {
        this.accountLoaded = false;
        let promises = [];

        // NOTE: userService get returns roles for ALL brands the user is assigned to.
        promises.push(this.userService.get(id)
            .then((response: any) => {

                this.account = response;

                //Split the okta locale into the multiple form fields
                let localeSplit = this.account.Profile.Locale ? this.account.Profile.Locale.split("-") : null;
                this.account.Language = localeSplit ? localeSplit[0] : null;
                this.account.Locale = localeSplit ? localeSplit[1] : null;

                //Set default preferences to browser if user hasnt set fields yet
                if (this.userLang) {
                    this.account.Language = this.account.Language ? this.account.Language : this.userLang[0];
                    this.account.Locale = this.account.Locale ? this.account.Locale : this.userLang[1];
                }

                if (this.onAccountMe) {
                    this.account.Role = this.activeProfileService.userTypes.map((userType: string) => {
                        return this.userService.getRoleTranslationKey(userType);
                    })
                        .map((resourceKey: string) => {
                            this.translateService.get([resourceKey]).subscribe((res: [string]) => {
                                resourceKey = res[resourceKey];
                            });
                            return resourceKey;
                        })
                        .join(", ");

                    //RoleId is a required field, but it will not be updated for onAccountMe
                    //set it to the RoleName
                    this.account.RoleId = this.account.Role;
                }

            }));

        this.promise = Promise.all(promises)
            .then((response: any) => {

                //Wait for all data to come back before resolving role name to id
                if (!this.onAccountMe) {
                    let roleType: RoleType = null;
                    this.offBrandRoles = [];
                    let brandRoles: BusinessIdRoleLocation[] = [];

                    // #20883: 7-Eleven should be able to include 'CorporateShopper' role for both Corporate or Field Users.
                    let isSevenElevenUser: boolean = null;
                    isSevenElevenUser = (this.activeProfileService.businessIdentity === "Bsns.00000104" || this.activeProfileService.businessIdentity === "Bsns.00000010");
                    //'Bsns.00000010' is added to enable testing on dev;
                    if (isSevenElevenUser) {
                        // We are filtering out 'CorporateShopper' as it can be assigned to both Corporate or Field user
                        this.account.Profile.Roles.filter(r => r.Role !== "CorporateShopper")
                            .forEach((item: BusinessIdRoleLocation) => {
                            // Only check roles for the currently selected businessIdentity
                            if (item.BusinessIdentity === this.activeProfileService.businessIdentity) {
                                brandRoles.push(item);
                                this.CorporateRoles.controls.forEach((control: FormControl) => {
                                    if (item.Role === control.get("Role").value) {
                                        control.get("Selected").setValue(true);
                                        control.get("RoleData").setValue(item.RoleData);
                                        this.userForm.get("RoleType").setValue("Corporate");
                                        roleType = "Corporate";
                                    }
                                });
                                this.FieldRoles.controls.forEach((control: FormControl) => {
                                    if (item.Role === control.get("Role").value) {
                                        control.get("Selected").setValue(true);
                                        control.get("RoleData").setValue(item.RoleData);
                                        this.userForm.get("RoleType").setValue("Field");
                                        roleType = "Field";
                                    }
                                });
                            } else {
                                // Save off, need to send back when saving the user
                                this.offBrandRoles.push(item);
                            }
                        });

                        // Process 'CorporateShopper' as a special case
                        let profileRoleCorpShopper: BusinessIdRoleLocation;
                        profileRoleCorpShopper = this.account.Profile.Roles.find(r => r.Role === "CorporateShopper");

                        if (profileRoleCorpShopper) {
                            if (profileRoleCorpShopper.BusinessIdentity === this.activeProfileService.businessIdentity) {
                                let ctrlCorporateShopper: AbstractControl;

                                if (roleType === "Corporate") {
                                    ctrlCorporateShopper = this.CorporateRoles.controls.find(x => x.get("Role").value === "CorporateShopper");
                                    ctrlCorporateShopper.get("Selected").setValue(true);
                                    ctrlCorporateShopper.get("RoleData").setValue(profileRoleCorpShopper.RoleData);
                                }
                                else if (roleType === "Field") {
                                    ctrlCorporateShopper = this.FieldRoles.controls.find(x => x.get("Role").value === "CorporateShopper");
                                    ctrlCorporateShopper.get("Selected").setValue(true);
                                    ctrlCorporateShopper.get("RoleData").setValue(profileRoleCorpShopper.RoleData);
                                }
                                else {
                                    ctrlCorporateShopper = this.CorporateRoles.controls.find(x => x.get("Role").value === "CorporateShopper");
                                    ctrlCorporateShopper.get("Selected").setValue(true);
                                    ctrlCorporateShopper.get("RoleData").setValue(profileRoleCorpShopper.RoleData);
                                    // If 'CorporateShopper' is the only role, default role type to 'Corporate'
                                    this.userForm.get("RoleType").setValue("Corporate");
                                    roleType = "Corporate";
                                }
                            }
                            else {
                                // Save off, need to send back when saving the user
                                this.offBrandRoles.push(profileRoleCorpShopper);
                            }
                        }
                    } else {
                        this.account.Profile.Roles.forEach((item: BusinessIdRoleLocation) => {
                            // Only check roles for the currently selected businessIdentity
                            if (item.BusinessIdentity === this.activeProfileService.businessIdentity) {

                                brandRoles.push(item);

                                this.CorporateRoles.controls.forEach((control: FormControl) => {
                                    if (item.Role === control.get("Role").value) {
                                        control.get("Selected").setValue(true);
                                        control.get("RoleData").setValue(item.RoleData);
                                        this.userForm.get("RoleType").setValue("Corporate");
                                        roleType = "Corporate";
                                    }
                                });

                                this.FieldRoles.controls.forEach((control: FormControl) => {
                                    if (item.Role === control.get("Role").value) {
                                        control.get("Selected").setValue(true);
                                        control.get("RoleData").setValue(item.RoleData);
                                        this.userForm.get("RoleType").setValue("Field");
                                        roleType = "Field";
                                    }
                                });

                            } else {
                                // Save off, need to send back when saving the user
                                this.offBrandRoles.push(item);
                            }
                        });
                    }


                    this.locationList = this.profileService.getLocationListFromUser(brandRoles);

                    if (roleType) {
                        this.roleTypeChange(roleType);
                    }
                }

                this.userForm.patchValue({
                    UserName: this.account.Profile.UserName,
                    FirstName: this.account.Profile.FirstName,
                    LastName: this.account.Profile.LastName,
                    Email: this.account.Profile.Email,
                    Phone: this.account.Profile.PrimaryPhone,
                    TimeZone: this.account.Profile.TimeZone,
                    Locale: this.account.Locale,
                    Language: this.account.Language,
                    Role: this.account.Role,
                    RoleId: this.account.RoleId
                });

                this.accountLoaded = true;
            });

    }

    public saveAccountPrompt(): Promise<void> {
        return this.ipsMessage.waitForWork({
            body: "SAVING",
            workFunction: () => this.saveAccount(),
            progressMessage: "SAVING"
        })
        .then((result) => {
            if (result && !this.onAccountMe) {
                this.userForm.markAsPristine();
                this.$state.go("main.profileUser.search");
            }
        });
    }

    public getStatusImage(): string {
        return this.account && this.account.Status ? this.userService.getStatusIconClass(this.account.Status) : "";
    }

    public cancel(): void {
        const fromState = this.transition.from();
        if (fromState && fromState.name !== "") {
            window.history.back();
        } else {
            this.$state.go("main.home");
        }
    }

    public setEmailIfLinked(): void {
        if (this.usernameAndEmailLinked) {
            this.account.Profile.Email = this.account.Profile.UserName;
        }
    }

    public changeTimezone(timezone) {
        if (this.account.Profile.TimeZone !== timezone) {
            this.account.Profile.TimeZone = timezone;
            this.userForm.markAsDirty();
        }
    }

    get CorporateRoles(): FormArray {
        return this.userForm.get("CorporateRoles") as FormArray;
    }
    get FieldRoles(): FormArray {
        return this.userForm.get("FieldRoles") as FormArray;
    }

    public roleTypeChange(roleType: RoleType) {
        this.activeRoleType = roleType;
        if (roleType === "Corporate") {
            this.DisableFieldRoles();
            this.CorporateRoles.controls.forEach(role => { role.enable(); });
        } else if (roleType === "Field") {
            this.DisableCorporateRoles();
            this.FieldRoles.controls.forEach(role => { role.enable(); });
        }
    }

    private DisableCorporateRoles() {
        this.CorporateRoles.controls.forEach(role => { role.get("Selected").setValue(false); role.disable(); });
    }

    private DisableFieldRoles() {
        this.FieldRoles.controls.forEach(role => { role.get("Selected").setValue(false); role.disable(); });
    }

    public ValidateRoles(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            let roleType = control.get("RoleType").value;
            if (roleType) {
                if (roleType === "Corporate") {
                    //value = this.CorporateRoles.controls.find(role => role.get("Selected").value);
                    if (!this.CorporateRoles.controls.find(role => role.get("Selected").value)) {
                        this.CorporateRoles.setErrors({ missingRole: { valid: false } });
                    }
                } else if (roleType === "Field") {
                    //value = this.FieldRoles.controls.find(role => role.get("Selected").value);
                    if (!this.FieldRoles.controls.find(role => role.get("Selected").value)) {
                        this.FieldRoles.setErrors({ missingRole: { valid: false } });
                    }
                }
            }
            return null;
        };
    }

}
