import { Component, OnInit } from "@angular/core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { Transition, StateService } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { AbstractControl, FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn } from "@angular/forms";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String } from "typescript-string-operations";
import { SurveySectionModel, SurveyModel, SurveyService, SurveyQuestionModel } from "../../index";
import { MarketModel, DataService, MarketGroupService } from "../../../market/index";
import cloneDeep from "lodash-es/cloneDeep";
import { String as IpsString } from "typescript-string-operations";


interface SaveSurveyQuestionModel extends SurveyQuestionModel {
    Market: MarketModel;
}

interface SaveSurveySectionModel extends SurveySectionModel {
    Questions: SaveSurveyQuestionModel[];
    Remove: boolean;
}

interface UISurveyModel extends SurveyModel {
    Markets: MarketModel[];
}

@Component({
    selector: "app-survey-edit",
    templateUrl: "./survey-edit.component.html",
    styleUrls: ["./survey-edit.component.scss"]
})
export class SurveyEditComponent implements OnInit {

    public breadCrumbLabel = "";
    public isCollapsedInfo = false;
    public isCollapsedAudience = false;
    public loaded = false;
    public promise: Promise<void>;
    public dateFormat: string;
    private today: Date;
    public startDateOptions: any = {};
    public endDateOptions: any = {};
    private calendarTheme = "theme-default";

    public markets: MarketModel[] = null;
    public sectionDeleteList: number[] = [];
    public sections: SurveySectionModel[] = null;
    public openSectionOrdinals: string[];
    public locationBalance: number;

    private TranslateStrings: { [key: string]: string } = {
     "PLEASE_ENTER_SURVEY_NAME": "",
     "PLEASE_ENTER_START_DATE": "",
     "SURVEY_DATE_CANNOT_BE_IN_THE_PAST": "",
     "SURVEY_END_DATE_AFTER_START_DATE": "",
     "CALENDAR_DATE_FORMAT": "",
     "MAX_LENGTH_ERROR": ""
    };

    private errorMessages = {
        "enterSurveyName": () => this.TranslateStrings["PLEASE_ENTER_SURVEY_NAME"],
        "enterStartDate": () => this.TranslateStrings["PLEASE_ENTER_START_DATE"],
        "surveyDatePast": () => this.TranslateStrings["SURVEY_DATE_CANNOT_BE_IN_THE_PAST"],
        "surveyEndAfterStartDate": () => this.TranslateStrings["SURVEY_END_DATE_AFTER_START_DATE"],
        "calendarDateFormat": () => this.TranslateStrings["CALENDAR_DATE_FORMAT"],
        "maxlength": (params) => IpsString.Format(this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength)
    };

    public surveyForm: FormGroup;
    public Id: FormControl;
    public Name: FormControl;
    public StartDate: FormControl;
    public EndDate: FormControl;
    public Notes: FormControl;
    public MarketGroupId: FormControl;

    constructor(private transition: Transition, private pageTitleService: PageTitleService, private translateService: TranslateService, private activeProfileService: ActiveProfileService,
        private ipsMessage: IpsMessageService, private $state: StateService, public surveyService: SurveyService, private dataService: DataService, private marketGroupService: MarketGroupService) { }

    ngOnInit() {

        let paramId = this.transition.params().id === "0" ? "" : this.transition.params().id;
        let pageTitle = paramId.toString() === "" ? "CREATE_SURVEY" : "EDIT_SURVEY";

        this.openSectionOrdinals = this.transition.params().openSections;
        let fromSurveyView = this.transition.from().name === "main.survey.view";

        //collapse info and audience section if it's clicked from section edit in survey view
        if (this.openSectionOrdinals && fromSurveyView) {
            this.isCollapsedInfo = true;
            this.isCollapsedAudience = true;
        }

        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);

        this.today = new Date();
        this.today.setHours(0, 0, 0, 0);	// set to midnight

        let localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();

        this.startDateOptions = {
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.endDateOptions = {
            minDate: this.today,
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.createForm();
        this.translateService.get("ALL").subscribe(() => this.TranslateText());
        this.translateService.onLangChange.subscribe(() => this.TranslateText());


        // If we got an ID to load, load it.
        if (paramId > 0) {
            this.getSurvey(paramId);
            this.loadFeatureList(paramId);
            this.loadFixtureGroupList(paramId);
            this.loadFixtureList(paramId);
        } else if (paramId === "") {
            this.markets = [];
            this.loaded = true;
            //Reset prior data
            this.dataService.updateStorage([], "FeatureInQuestion");
            this.dataService.updateStorage([], "FixtureGroupInQuestion");
            this.dataService.updateStorage([], "FixtureInQuestion");
        }

    }

    private loadFeatureList(surveyId: number) {
        let existingFeatures = [];
        this.surveyService.getFeatureList(surveyId).then(response => {
            response.forEach(item => {
                existingFeatures.push(item);
            });
            this.dataService.updateStorage(existingFeatures, "FeatureInQuestion");
        });
    }

    private loadFixtureGroupList(surveyId: number) {
        let existingFixtureGroups = [];
        this.surveyService.getFixtureGroupList(surveyId).then(response => {
            response.forEach(item => {
                existingFixtureGroups.push(item);
            });
            this.dataService.updateStorage(existingFixtureGroups, "FixtureGroupInQuestion");
        });
    }

    private loadFixtureList(surveyId: number) {
        let existingFixtures = [];
        this.surveyService.getFixtureList(surveyId).then(response => {
            response.forEach(item => {
                existingFixtures.push(item);
            });
            this.dataService.updateStorage(existingFixtures, "FixtureInQuestion");
        });
    }


    private getSurvey(id: string | number) {
        this.loaded = false;

        this.promise = this.surveyService.get<SurveyModel>(id).then((response: UISurveyModel) => {

            this.markets = response.Markets || [];

            const endDate = response.EndDate ? new Date(response.EndDate) : "";
            const startDate = new Date(response.StartDate);

            //end date should be after start date or today
            this.endDateOptions.minDate = startDate < this.today ? this.today : startDate;

            this.surveyForm.patchValue({
                Id: response.Id,
                BusinessIdentity: response.BusinessIdentity,
                Name: response.Name,
                StartDate: startDate,
                EndDate: endDate,
                Notes: response.Notes,
                MarketGroupId: response.MarketGroupId
            });

            this.surveyService.getSurveySections(response.Id).then((sectionResponse: SurveySectionModel[]) => {
                this.sections = sectionResponse;
                this.loaded = true;
            });
        });
    }

    private TranslateText() {
        for (let key of Object.keys(this.TranslateStrings)) {
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
    }

    private createForm() {
        this.Id = new FormControl("");
        this.Name = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.StartDate = new FormControl("", [Validators.required]);
        this.EndDate = new FormControl("", [this.ValidateEndDate(this.StartDate)]);
        this.Notes = new FormControl("", [Validators.maxLength(500)]);
        this.MarketGroupId = new FormControl("");

        this.surveyForm = new FormGroup({
            Id: this.Id,
            Name: this.Name,
            StartDate: this.StartDate,
            EndDate: this.EndDate,
            Notes: this.Notes,
            MarketGroupId: this.MarketGroupId
        }, [this.ValidateAllFields()]);
    }

    public activeCollapseInfo() {
        this.isCollapsedInfo = !this.isCollapsedInfo;
    }

    public activeCollapseAudience() {
        this.isCollapsedAudience = !this.isCollapsedAudience;
    }

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "name":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_SURVEY_NAME"];
                    break;
                case "startdate":
                    msgs["required"] = () => this.TranslateStrings["PLEASE_ENTER_START_DATE"];
                    break;
            }
        }
        return msgs;
    }

    public changeStartDate(value: Date) {
        this.endDateOptions.minDate = value < this.today ? this.today : value;

        //force validation call
        this.StartDate.setValue(value);
        this.StartDate.markAsDirty();
        this.EndDate.updateValueAndValidity();
        if (this.EndDate.errors) {
            this.EndDate.markAsDirty();
        }
    }

    public blurStartDate(test: any) {
        this.StartDate.updateValueAndValidity();
    }
    public blurEndDate(test: any) {
        this.EndDate.updateValueAndValidity();
    }

    ValidateAllFields(): any {
        return (form: AbstractControl): ValidationErrors | null => {

            //Check that the required date fields have been populated
            if (!form.value.StartDate) {
                return {
                    required: {
                        valid: false
                    }
                };
            }

            return null;
        };
    }


    /**
    * Property used for end-store date validation. It must be:
    * Parsable (handled automatically)
    * On or after start date
    */
    public ValidateEndDate(StartDate: AbstractControl): ValidatorFn {
        return (EndDate: AbstractControl): ValidationErrors | null => {
            if (EndDate !== undefined) {
                if (StartDate !== undefined && !!StartDate.value && !!EndDate.value) {

                    //Compare dates only - no hours
                    StartDate.value.setHours(0, 0, 0, 0);
                    EndDate.value.setHours(0, 0, 0, 0);

                    if (StartDate.value > EndDate.value) {
                        return {
                            surveyEndAfterStartDate: {
                                valid: false
                            }
                        };
                    }
                    if (EndDate.value < this.today) {
                        return {
                            surveyDatePast: {
                                valid: false
                            }
                        };
                    }
                }
            } else {
                return {
                    calendarDateFormat: {
                        valid: false
                    }
                };
            }

            return null;
        };
    }

    public saveSurveyPrompt(redirect: boolean) {
        return this.ipsMessage
            .waitForWork({ body: "SAVING", workFunction: () => this.saveSurvey(), progressMessage: "SAVING" }).then(
                (result) => {
                    if (result) {
                        if (redirect) {
                            this.$state.go("main.survey.search");
                        } else {
                            let openSections = this.surveyForm.value.SurveySections
                                .filter(sec => sec.IsCollapsed === false).map(sec => sec.Ordinal);
                            this.$state.go("main.survey.edit",
                                { id: this.surveyForm.value.Id, openSections: openSections },
                                { reload: true });
                        }
                    }
                });
    }

    public deleteSurveyPrompt() {
        let translated = this.translateService.instant("PROMPT_DELETE_BODY");
        translated = String.Format(translated, this.surveyForm.value.Name);
        return this.ipsMessage.confirmDelete({ body: translated, workFunction: () => this.deleteSurvey(), progressMessage: "DELETING" })
        .then((result: any) => {
            if (result) {
                this.$state.go("main.survey.search");
            }
        })
        .catch(() => {
            // rejection
        });
    }

    private saveSurvey(): Promise<void> {
        let id = this.surveyForm.value.Id;

        //Create save data object
        let data = cloneDeep(this.surveyForm.value);
        //data.Markets = data.marketGroup.markets;
        //delete data.marketGroup.markets;

        if (id) {
            return this.surveyService.put<SurveyModel>(data).then(() => {
                return this.saveSections(data, id).then(r => {
                    this.surveyForm.markAsPristine();
                });
            });
        } else {
            return this.surveyService.post<SurveyModel>(data, undefined, undefined).then((response) => {
                this.Id.setValue(response.Id);
                return this.saveSections(data, response.Id).then(r => {
                    this.surveyForm.markAsPristine();
                });
            });
        }
    }

    private saveSections(data: SurveyModel, surveyId: number): Promise<any> {
        let promises = [];
        let sectionSaveList = data["SurveySections"];

        //delete sections
        this.sectionDeleteList.forEach(sectionId => {
            promises.push(this.surveyService.deleteSection(sectionId));
        });

        for (let i = 0; i < sectionSaveList.length; i++) {
            let sectionSaveData = sectionSaveList[i] as SaveSurveySectionModel;

            if (sectionSaveData.SurveyQuestions.length === 0) {
                //Don't save if user didn't open the section
                continue;
            }

            sectionSaveData.Questions = <SaveSurveyQuestionModel[]>sectionSaveData["SurveyQuestions"];
            delete sectionSaveData["SurveyQuestions"];
            sectionSaveData.SurveyId = surveyId;

            for (let q = 0; q < sectionSaveData.Questions.length; q++) {
                let question = sectionSaveData.Questions[q];
                question.Ordinal = q + 1;
                if (question.Market.TargetMarketType === "LocationGroup") {
                    question.LocationGroupId = question.Market.TargetMarketId;
                } else if (question.Market.TargetMarketType === "FixtureGroup") {
                    question.FixtureGroupId = question.Market.TargetMarketId;
                } else if (question.Market.TargetMarketType === "Fixture") {
                    question.FixtureId = question.Market.TargetMarketId;
                }
            }

            if (sectionSaveData.Id === 0) {
                //Create
                promises.push(this.surveyService.postSection(sectionSaveData));
            } else {
                //Edit
                promises.push(this.surveyService.putSection(sectionSaveData));
            }
        }

        if (promises.length === 0) {
            promises.push(Promise.resolve());
        }

        return Promise.all(promises);
    }


    private deleteSurvey(): Promise<void> {
        return this.surveyService.delete(this.surveyForm.value.Id);
    }

    public balanceChangedEventHandler(locationBalance: number) {
        this.locationBalance = locationBalance;
    }

    public sectionDeleteEventHandler(sectionId: number) {
        this.sectionDeleteList.push(sectionId);
    }
}
