import { Component, OnInit } from "@angular/core";
import { BrandBulletinService } from "../service/brand-bulletin.service";
import { BrandBulletinModel } from "../model/BrandBulletinModel";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
    public brandBulletin: BrandBulletinModel;

    constructor(private brandBulletinService: BrandBulletinService) {}

    ngOnInit() {
        return this.brandBulletinService.getList().then((response: BrandBulletinModel[]) => {

            if (response && response.length > 0 ) {
                let profileVal = response.find(q => q.Type === "Profile");
                if (profileVal && !this.isNullOrSpaces(profileVal.Value)) {
                    this.brandBulletin = profileVal;
                }

            }
        });
    }

    private isNullOrSpaces(str: string): boolean {
        return !!str === false || str.match(/^ *$/) !== null;
    }
}
