import { OnInit, EventEmitter } from "@angular/core";
import { getLocaleDateFormat, FormatWidth, DatePipe } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LocationGroupService, FixtureByLocationSumService } from "../../../imagine-ui-ng-store-profile";
import { ActiveProfileService, ListSearchHelper } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { SurveyService } from "../../service/survey.service";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { TranslateService } from "@ngx-translate/core";
import { String as IpsString } from "typescript-string-operations";
import { IpsModalService } from "imagine-ui-ng-modal";
import { SelectListComponent } from "../../../shared/select-list/select-list.component";
var MySurveyTakeQuestionComponent = /** @class */ (function () {
    function MySurveyTakeQuestionComponent(locationGroupService, activeProfileService, surveyService, authService, listSearchHelper, ipsMessage, fb, translateService, datePipe, fixtureByLocationSumService, ipsModal) {
        var _this_1 = this;
        this.locationGroupService = locationGroupService;
        this.activeProfileService = activeProfileService;
        this.surveyService = surveyService;
        this.authService = authService;
        this.listSearchHelper = listSearchHelper;
        this.ipsMessage = ipsMessage;
        this.fb = fb;
        this.translateService = translateService;
        this.datePipe = datePipe;
        this.fixtureByLocationSumService = fixtureByLocationSumService;
        this.ipsModal = ipsModal;
        this.questionConfirmed = new EventEmitter();
        this.formDirtyChanged = new EventEmitter();
        this.emptyGuid = "00000000-0000-0000-0000-000000000000";
        this.locationGroup = {};
        this.OriginalSelectedSubGroupId = null;
        this.Saving = false;
        this.dateOptions = {};
        this.dateFormateForDisplay = "MMM d, yyyy"; // View date format
        this.Loading = false;
        this.ShowAllOptions = true;
        this.AnswerLabel = "ENTER_TEXT";
        this.messageOpen = false;
        this.LocGroupIsOneToOne = true;
        this.confirmLabel = "CONFIRM";
        this.errorMessages = {
            "required": function () { return _this_1.requiredNameMessage; },
            "maxlength": function (params) { return IpsString.Format(_this_1.maxLengthMessage, params.requiredLength); },
            "dupeSubGroupNameMessage": function () { return _this_1.dupeSubGroupNameMessage; },
            "numericValueRequiredMessage": function () { return _this_1.numericValueRequiredMessage; },
            "bsDate": function () { return _this_1.invalidDateErrorMessage; },
            "subGroupRequired": function () { return _this_1.requiredSubGroupMessage; },
            "reservedName": function () { return _this_1.reservedNameMessage; }
        };
        this.translateStrings = {
            "ANSWERS_SELECTED": "",
            "ANSWER_SELECTED": "",
        };
        this.answerPluralMapping = {
            "=1": "ANSWER_SELECTED",
            "other": "ANSWERS_SELECTED"
        };
        this.locationGroupSearch = this.listSearchHelper.getListSearchHelper(locationGroupService);
        this.fixtureSearch = this.listSearchHelper.getListSearchHelper(fixtureByLocationSumService);
    }
    MySurveyTakeQuestionComponent.prototype.ngOnInit = function () {
        var _this_1 = this;
        this.Loading = true;
        this.ShowAllOptions = this.question.AnswerType !== "TypeInOption";
        this.question.ProfileType = this.question.LocationGroup !== undefined ? "LocationGroup" : this.question.FixtureGroup !== undefined ? "FixtureGroup" : undefined;
        if (this.question.ProfileType === "FixtureGroup") {
            var hasAnswers = this.checkHasAnswer();
            this.question.NoFixture = hasAnswers &&
                this.question.MySurveyAnswer.FixtureAnswers.length === 1 &&
                this.question.MySurveyAnswer.FixtureAnswers[0].FixtureId === undefined;
            this.question.OriginalNoFixture = this.question.NoFixture;
            if (!this.question.NoFixture) {
                this.confirmLabel = "CONFIRM_ALL";
            }
        }
        this.createAnswerForm();
        var localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();
        this.dateOptions = {
            containerClass: "theme-default",
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };
        this.translateService.get("ALL").subscribe(function () { return _this_1.TranslateText(); });
        this.translateService.onLangChange.subscribe(function () { return _this_1.TranslateText(); });
        try {
            switch (this.question.ProfileType) {
                case "LocationGroup":
                    this.loadLocationGroup();
                    break;
                case "FixtureGroup":
                    this.ShowAllOptions = false;
                    this.AnswerLabel = "ENTER_QUANTITY_FOR_EACH";
                    this.loadFixtureGroup();
                    break;
            }
        }
        catch (e) {
            this.Loading = false;
        }
        if (this.question.MySurveyMessage && (this.question.MySurveyMessage.Text !== "" || this.question.MySurveyMessage.Media)) {
            this.messageAdminClick();
        }
    };
    MySurveyTakeQuestionComponent.prototype.createAnswerForm = function () {
        var _this_1 = this;
        switch (this.question.ProfileType) {
            case "LocationGroup":
                var selectedSubGroupIdCtrl_1 = this.fb.control(undefined);
                if (this.IsOneToOneRule()) {
                    selectedSubGroupIdCtrl_1.setValidators(Validators.required);
                }
                this.AnswerForm = this.fb.group({
                    SubGroups: this.fb.array([]),
                    NewSubGroups: this.fb.array([]),
                    SelectedSubGroupId: selectedSubGroupIdCtrl_1,
                    IsDirty: this.fb.control(false),
                    HasNASubGroup: this.fb.control(false)
                });
                selectedSubGroupIdCtrl_1.valueChanges.subscribe(function (val) {
                    if (val === _this_1.OriginalSelectedSubGroupId) {
                        selectedSubGroupIdCtrl_1.markAsPristine();
                    }
                    _this_1.formDirtyChanged.emit();
                });
                break;
            case "FixtureGroup":
                this.AnswerForm = this.fb.group({
                    Fixtures: this.fb.array([]),
                    NoFixture: this.fb.control(this.question.NoFixture),
                    IsDirty: this.fb.control(false)
                });
                break;
        }
        if (!this.parentForm.get("Questions")) {
            this.parentForm.addControl("Questions", this.fb.array([this.AnswerForm]));
        }
        else {
            this.parentForm.get("Questions").push(this.AnswerForm);
        }
    };
    MySurveyTakeQuestionComponent.prototype.TranslateText = function () {
        this.dupeSubGroupNameMessage = this.translateService.instant("DUPLICATE_SUBGROUP_NAME");
        this.maxLengthMessage = this.translateService.instant("MAX_LENGTH_ERROR");
        this.requiredNameMessage = this.translateService.instant("PLEASE_ENTER_ANSWER");
        this.invalidDateErrorMessage = IpsString.Format(this.translateService.instant("CALENDAR_DATE_FORMAT"), this.dateFormat);
        this.requiredSubGroupMessage = this.translateService.instant("AT_LEAST_ONE_OPTION_REQUIRED");
        this.numericValueRequiredMessage = this.translateService.instant("PLEASE_ENTER_NUMBER");
        this.reservedNameMessage = this.translateService.instant("RESERVED_SUBGROUP_NAME_ANSWER");
        this.answerPluralMapping = {
            "=1": this.translateService.instant("ANSWER_SELECTED"),
            "other": this.translateService.instant("ANSWERS_SELECTED")
        };
    };
    MySurveyTakeQuestionComponent.prototype.getNameErrorMessages = function (key) {
        var _this_1 = this;
        var msgs = this.errorMessages;
        if (key) {
            switch (key.toLowerCase()) {
                case "name":
                    msgs["required"] = function () { return _this_1.requiredNameMessage; };
                    break;
                case "quantity":
                    msgs["required"] = function () { return _this_1.numericValueRequiredMessage; };
                    break;
                default:
            }
        }
        return msgs;
    };
    Object.defineProperty(MySurveyTakeQuestionComponent.prototype, "SubGroups", {
        get: function () {
            return this.AnswerForm.get("SubGroups");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MySurveyTakeQuestionComponent.prototype, "Fixtures", {
        get: function () {
            return this.AnswerForm.get("Fixtures");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MySurveyTakeQuestionComponent.prototype, "SelectedSubGroupId", {
        get: function () {
            return this.AnswerForm.get("SelectedSubGroupId");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MySurveyTakeQuestionComponent.prototype, "NewSubGroups", {
        get: function () {
            return this.AnswerForm.get("NewSubGroups");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MySurveyTakeQuestionComponent.prototype, "HasNASubGroupValue", {
        get: function () {
            return this.AnswerForm.get("HasNASubGroup").value;
        },
        enumerable: true,
        configurable: true
    });
    MySurveyTakeQuestionComponent.prototype.loadLocationGroup = function () {
        var _this_1 = this;
        Object.assign(this.locationGroup, this.question.LocationGroup);
        this.locationGroupService.sortSubGroup(this.locationGroup.SubGroups, this.locationGroup.OptionType);
        //Setup image URLS
        if (this.locationGroup.Media && this.locationGroup.MasterMediaId !== this.emptyGuid) {
            this.locationGroup.FullImage = this.locationGroup.Media.ResourceUri + "?BusinessIdentity=" + this.activeProfileService.businessIdentity + "&idToken=" + this.authService.getIdToken();
            this.locationGroup.ThumbImage = this.locationGroup.FullImage + "&height=300&width=300";
        }
        switch (this.locationGroup.OptionType) {
            case "Number":
                this.AnswerLabel = "ENTER_NUMBER";
                break;
            case "Date":
                this.AnswerLabel = "ENTER_DATE";
                break;
        }
        var hasAnswers = this.checkHasAnswer();
        this.LocGroupIsOneToOne = this.IsOneToOneRule();
        // Initialize each subGroup
        this.locationGroup.SubGroups.forEach(function (subGroup) {
            subGroup.Selected = false;
            subGroup.OriginalSelected = false;
            if (subGroup.IsNA) {
                _this_1.AnswerForm.patchValue({ HasNASubGroup: true });
            }
            if (subGroup.Media && subGroup.MasterMediaId !== _this_1.emptyGuid) {
                subGroup.FullImage = subGroup.Media.ResourceUri + "?BusinessIdentity=" + _this_1.activeProfileService.businessIdentity + "&idToken=" + _this_1.authService.getIdToken();
                subGroup.ThumbImage = subGroup.FullImage + "&height=100&width=150";
            }
            if (hasAnswers) {
                // Set selected on subGroup if answered
                var answered = _this_1.question.MySurveyAnswer.LocationSubGroupId.find(function (subGroupId) {
                    return subGroupId === subGroup.Id;
                });
                if (answered) {
                    subGroup.Selected = true;
                    subGroup.OriginalSelected = true;
                    if (_this_1.LocGroupIsOneToOne) {
                        _this_1.SelectedSubGroupId.patchValue(subGroup.Id);
                        _this_1.OriginalSelectedSubGroupId = subGroup.Id;
                    }
                }
            }
            else {
                subGroup.Selected = subGroup.LocationCount > 0;
                subGroup.OriginalSelected = subGroup.LocationCount > 0;
                if (subGroup.Selected) {
                    if (_this_1.LocGroupIsOneToOne) {
                        _this_1.SelectedSubGroupId.patchValue(subGroup.Id);
                        _this_1.OriginalSelectedSubGroupId = subGroup.Id;
                    }
                }
            }
            // Add our form control
            _this_1.SubGroups.push(_this_1.getNewSubGroupCtrl(subGroup));
        });
        if (!this.ShowAllOptions && this.AllowAnother()) {
            this.AddAnotherOption(false);
        }
        // Done loading
        this.Loading = false;
    };
    MySurveyTakeQuestionComponent.prototype.getLocationGroupButtonLabel = function () {
        var _this = this;
        var selectedCount = this.SelectedCount();
        if (selectedCount > 1) {
            return selectedCount + " Features Selected";
        }
        else if (selectedCount === 1) {
            return _this.SubGroups.controls.filter(function (subGroup) { return subGroup.value.Selected === true; })[0].value.Name;
        }
        else {
            return "Choose Feature(s)";
        }
    };
    MySurveyTakeQuestionComponent.prototype.chooseLocationGroup = function () {
        var _this = this;
        var selectedSubGroups = this.SubGroups.controls && this.SubGroups.controls.filter(function (subGroup) { return subGroup.value.Selected === true; }).map(function (subGroup) { return subGroup.value.Id; });
        var param = {
            title: "Choose Feature(s)",
            items: this.locationGroup.SubGroups.filter(function (subGroup) { return subGroup.Id > 0; }),
            labelField: "Name",
            multiSelect: true,
            selectedIds: selectedSubGroups
        };
        return this.ipsModal.displayTemplateScrollable(SelectListComponent, param, { backdrop: "static", centered: true }).then(function (response) {
            console.log("Modal response", response);
            //go through all subgroups to see if state changed
            _this.SubGroups.controls.forEach(function (subGroup) {
                var responseSubGroupIndex = response.findIndex(function (responseSubGroup) { return responseSubGroup.Id === subGroup.value.Id; });
                //simulate box click if value changed
                if (!subGroup.value.Selected && responseSubGroupIndex >= 0) {
                    //check was added
                    _this.BoxClicked(subGroup);
                }
                else if (subGroup.value.Selected && responseSubGroupIndex < 0) {
                    //check was removed
                    _this.BoxClicked(subGroup);
                }
            });
        }, function (rejectReason) {
            // Do nothing, this is here to prevent console error.
        });
    };
    MySurveyTakeQuestionComponent.prototype.loadFixtureGroup = function () {
        var _this_1 = this;
        Object.assign(this.fixtureSearch, { resultList: this.question.FixtureGroup.FixtureLocationSum });
        //this.locationGroupService.sortSubGroup(this.locationGroup.SubGroups, this.locationGroup.OptionType);
        // Initialize each fixture
        this.fixtureSearch.resultList.forEach(function (fixture) {
            if (fixture.Media && fixture.MasterMediaId !== _this_1.emptyGuid) {
                fixture.FullImage = fixture.Media.ResourceUri + "?BusinessIdentity=" + _this_1.activeProfileService.businessIdentity + "&idToken=" + _this_1.authService.getIdToken();
                fixture.ThumbImage = fixture.FullImage + "&height=100&width=150";
            }
            var hasAnswers = _this_1.checkHasAnswer();
            if (hasAnswers) {
                if (_this_1.question.NoFixture) {
                    //update fixture count to 0 when suvery answer is no fixture
                    fixture.LocationFixtureSum = 0;
                }
                fixture.Confirmed = true;
            }
            fixture.OriginalSum = fixture.LocationFixtureSum;
            // Add our form control
            _this_1.Fixtures.push(_this_1.getNewFixtureCtrl(fixture));
        });
        // Done loading
        this.Loading = false;
    };
    MySurveyTakeQuestionComponent.prototype.getNewSubGroupCtrl = function (subGroup, forNewOption) {
        var _this_1 = this;
        if (forNewOption === void 0) { forNewOption = false; }
        var nameControl;
        if (forNewOption) {
            nameControl = this.fb.control(subGroup.Name, [Validators.required, Validators.maxLength(50), this.subGroupValidator(this.question.LocationGroup.OptionType === "Date")]);
        }
        else {
            nameControl = this.fb.control(subGroup.Name);
        }
        var ctrl = this.fb.group({
            Id: subGroup.Id,
            Selected: [subGroup.Selected, this.subGrpRequired(subGroup.Id)],
            FullImage: subGroup.FullImage,
            ThumbImage: subGroup.ThumbImage,
            Name: nameControl,
            OriginalSelected: subGroup.OriginalSelected,
            HasSubGroupImage: subGroup.Media && subGroup.MasterMediaId !== this.emptyGuid,
            IsNA: subGroup.IsNA
        });
        var selectedCtrl = ctrl.get("Selected");
        var origCtrl = ctrl.get("OriginalSelected");
        selectedCtrl.valueChanges.subscribe(function (val) {
            if (val === origCtrl.value) {
                _this_1.AnswerForm.patchValue({ IsDirty: false });
                selectedCtrl.markAsPristine();
            }
            else {
                _this_1.AnswerForm.patchValue({ IsDirty: true });
                _this_1.AnswerForm.markAsDirty();
            }
            setTimeout(function () { _this_1.NewSubGroups.controls.forEach(function (newCtrl) { return newCtrl.get("Name").updateValueAndValidity(); }); }, 50);
            _this_1.formDirtyChanged.emit();
        });
        var nameCtrl = ctrl.get("Name");
        nameCtrl.valueChanges.subscribe(function (val) {
            // NOTE: couldn't get the form to be pristine again when val === null && !this.ShowAllOptions.
            // If the user enters something, then backspaces, it is still dirty.
            _this_1.AnswerForm.patchValue({ IsDirty: true });
            _this_1.AnswerForm.markAsDirty();
            setTimeout(function () { _this_1.NewSubGroups.controls.forEach(function (newCtrl) { return newCtrl.get("Name").updateValueAndValidity(); }); }, 50);
            _this_1.formDirtyChanged.emit();
        });
        return ctrl;
    };
    MySurveyTakeQuestionComponent.prototype.getNewFixtureCtrl = function (fixture) {
        var _this_1 = this;
        if (fixture.Media && fixture.MasterMediaId !== this.emptyGuid) {
            fixture.FullImage = fixture.Media.ResourceUri + "?BusinessIdentity=" + this.activeProfileService.businessIdentity + "&idToken=" + this.authService.getIdToken();
            fixture.ThumbImage = fixture.FullImage + "&height=100&width=150";
        }
        var holders = this.fb.array([]);
        fixture.Holders.forEach(function (holder) {
            if (holder.Quantity > 0) {
                var holderCtrl = _this_1.fb.group({
                    Id: holder.Id,
                    Quantity: [holder.Quantity, [Validators.required, Validators.pattern("^(0|[1-9][0-9]*)$")]],
                    Name: holder.Name,
                    Width: holder.Size.Width,
                    Height: holder.Size.Height
                });
                holders.push(holderCtrl);
            }
        });
        var ctrl = this.fb.group({
            Id: fixture.Id,
            FullImage: fixture.FullImage,
            ThumbImage: fixture.ThumbImage,
            Name: fixture.Name,
            Model: fixture.Model,
            Quantity: fixture.LocationFixtureSum,
            OriginalQuantity: fixture.OriginalSum,
            OriginalConfirmed: fixture.Confirmed,
            Confirmed: fixture.Confirmed,
            HasImage: fixture.Media && fixture.MasterMediaId !== this.emptyGuid,
            Holders: holders
        });
        return ctrl;
    };
    /**
     * Validate the current subGroup against all the other subGroups to check for duplicate names
     * @param subGroups - List of subGroup controls, each must have 'Name' field
     * @param additionalSubGroups - Optional list of additional subGroup controls, each must have 'Name' field
     */
    MySurveyTakeQuestionComponent.prototype.subGroupValidator = function (isDate) {
        var _this_1 = this;
        return function (control) {
            function getSubGroupCtrlValue(ctrl) {
                var result = null;
                if (!!ctrl.value) {
                    if (isDate) {
                        if (ctrl.value.getMonth) {
                            // Use toDateString to ignore the time component of the date.
                            result = ctrl.value.toDateString();
                        }
                        else {
                            result = (new Date(ctrl.value)).toDateString();
                        }
                    }
                    else {
                        result = ctrl.value.toString().toLowerCase();
                    }
                }
                return result;
            }
            var controlValue = getSubGroupCtrlValue(control);
            // July 3, 2019 - Duplicate check removed. Backend will assign to existing subGroup rather than create a new one. Let user type it if
            // they want. UI will show duplicate while on the page but when they re-visit next time, they will see just one instance.
            // July 7, 2019 - Still need duplicate check when existing value is selected. Can't send the same answer twice to the backend. Decided to use
            // validator because otherwise the selected count will be higher than the actual saved answer count.
            if (_this_1.ShowAllOptions) {
                var subGroupNames_1 = [];
                _this_1.SubGroups.controls.forEach(function (sg) {
                    // Only care about selected values.
                    if (sg.value.Selected || sg.value.IsNA) {
                        subGroupNames_1.push(getSubGroupCtrlValue(sg.get("Name")));
                    }
                });
                if (_this_1.NewSubGroups) {
                    _this_1.NewSubGroups.controls.forEach(function (sg) { return subGroupNames_1.push(getSubGroupCtrlValue(sg.get("Name"))); });
                }
                var itemsWithSameName = subGroupNames_1.filter(function (name) {
                    return name === controlValue;
                });
                if (itemsWithSameName.length > 1) {
                    return { dupeSubGroupNameMessage: { valid: false } };
                }
            }
            if (!_this_1.HasNASubGroupValue && controlValue === "not applicable") {
                return { reservedName: { valid: false } };
            }
            return null;
        };
    };
    MySurveyTakeQuestionComponent.prototype.IsOneToOneRule = function () {
        return this.question.LocationGroup && this.question.LocationGroup.Rule === "Single";
    };
    MySurveyTakeQuestionComponent.prototype.AllowAnother = function () {
        if (this.ShowAllOptions) {
            return this.question.OptionControl && this.locationGroup.OptionType !== "Bool" &&
                (this.question.LocationGroup.Rule === "Multiple" || this.NewSubGroups.length === 0);
        }
        return this.SelectedCount() === 0;
    };
    MySurveyTakeQuestionComponent.prototype.checkNoFixtureAnswer = function (mySurveyAnswer) {
        return mySurveyAnswer === undefined || mySurveyAnswer.FixtureAnswers === undefined || mySurveyAnswer.FixtureAnswers.length === 0;
    };
    MySurveyTakeQuestionComponent.prototype.checkHasAnswer = function () {
        switch (this.question.ProfileType) {
            case "LocationGroup":
                return this.question.MySurveyAnswer && this.question.MySurveyAnswer.LocationSubGroupId && this.question.MySurveyAnswer.LocationSubGroupId.length > 0;
            case "FixtureGroup":
                return this.question.MySurveyAnswer && this.question.MySurveyAnswer.FixtureAnswers && this.question.MySurveyAnswer.FixtureAnswers.length > 0;
            default:
                return false;
        }
    };
    MySurveyTakeQuestionComponent.prototype.SaveAnswer = function () {
        var _this_1 = this;
        var saveModel;
        switch (this.question.ProfileType) {
            case "LocationGroup":
                var selectedList = [];
                if (this.IsOneToOneRule()) {
                    if (this.SelectedSubGroupId.value) {
                        selectedList.push(this.SelectedSubGroupId.value);
                    }
                }
                else {
                    selectedList = this.SubGroups.controls.filter(function (sg) { return sg.value.Selected; }).map(function (sg) { return sg.value.Id; });
                }
                saveModel = {
                    SurveyId: this.surveyId,
                    SurveyQuestionId: this.question.Id,
                    LocationId: this.locationId,
                    OptionType: this.locationGroup.OptionType,
                    NewSubGroupName: undefined,
                    LocationSubGroupId: selectedList
                };
                if (this.NewSubGroups.length > 0) {
                    saveModel.NewSubGroupName = this.NewSubGroups.controls.map(function (ctrl) {
                        if (_this_1.question.LocationGroup.OptionType === "Date") {
                            // Store in the format we want on the View and List pages. This is also parseable by .Net
                            var date = ctrl.value.Name;
                            return _this_1.datePipe.transform(date, _this_1.dateFormateForDisplay);
                        }
                        return ctrl.value.Name;
                    });
                }
                break;
            case "FixtureGroup":
                var fixtureAnswers_1 = [];
                if (this.question.NoFixture) {
                    fixtureAnswers_1.push({ FixtureId: undefined, FixtureCount: 0 });
                }
                else {
                    this.Fixtures.value.forEach(function (fixture) {
                        fixtureAnswers_1.push({ FixtureId: fixture.Id, FixtureCount: fixture.Quantity });
                    });
                }
                saveModel = {
                    SurveyId: this.surveyId,
                    SurveyQuestionId: this.question.Id,
                    LocationId: this.locationId,
                    OptionType: this.question.FixtureGroup.OptionType,
                    FixtureAnswers: fixtureAnswers_1
                };
                break;
        }
        // Save to the DB.
        this.Saving = true;
        this.SavePromise = this.surveyService.putAnswer(saveModel).then(function (response) {
            if (!_this_1.question.MySurveyAnswer) {
                _this_1.question.MySurveyAnswer = {};
            }
            _this_1.question.MySurveyAnswer.ConfirmedDate = response.ConfirmDate;
            _this_1.question.MySurveyAnswer.UserName = response.Username;
            switch (_this_1.question.ProfileType) {
                case "LocationGroup":
                    if (_this_1.IsOneToOneRule()) {
                        _this_1.OriginalSelectedSubGroupId = _this_1.SelectedSubGroupId.value;
                    }
                    _this_1.SubGroups.controls.forEach(function (sg) { return sg.patchValue({ OriginalSelected: sg.value.Selected }); });
                    if (_this_1.NewSubGroups.length > 0) {
                        _this_1.NewSubGroups.controls.forEach(function (sg) {
                            var newSgModel = sg.value;
                            newSgModel.Selected = true;
                            newSgModel.OriginalSelected = true;
                            if (_this_1.question.LocationGroup.OptionType === "Date") {
                                newSgModel.Name = _this_1.datePipe.transform(newSgModel.Name, _this_1.dateFormateForDisplay);
                            }
                            newSgModel.Id = response.LocationSubGroups.find(function (nsg) { return nsg.LocationSubGroupName.toLowerCase() === newSgModel.Name.toString().toLowerCase(); }).LocationSubGroupId;
                            _this_1.SubGroups.push(_this_1.getNewSubGroupCtrl(newSgModel));
                            if (_this_1.IsOneToOneRule()) {
                                // Set selected for radio button case to the new item just created
                                _this_1.SelectedSubGroupId.patchValue(newSgModel.Id);
                                _this_1.OriginalSelectedSubGroupId = newSgModel.Id;
                            }
                        });
                        // Clear out any of the new entries they had
                        while (_this_1.NewSubGroups.length !== 0) {
                            _this_1.NewSubGroups.removeAt(0);
                        }
                    }
                    break;
                case "FixtureGroup":
                    _this_1.question.OriginalNoFixture = _this_1.question.NoFixture;
                    _this_1.Fixtures.controls.forEach(function (ctr) {
                        if (_this_1.question.NoFixture) {
                            ctr.patchValue({
                                Quantity: 0,
                                OriginalQuantity: 0
                            });
                        }
                        ctr.get("Confirmed").setValue(true);
                    });
                    _this_1.AnswerForm.markAsPristine();
                    break;
            }
            _this_1.AnswerForm.markAsPristine();
            _this_1.formDirtyChanged.emit();
            _this_1.Saving = false;
            //notify parent to update section status
            _this_1.questionConfirmed.emit();
        }, function (error) {
            _this_1.Saving = false;
            var errorMessage = "";
            // NiceToHave: update ipsMessage.error to take in an exception parameter and do the code below for us.
            if (error) {
                if (error.data && error.data.Message) {
                    errorMessage = error.data.Message;
                }
                else if (error.error && error.error.Message) {
                    errorMessage = error.error.Message;
                }
                else if (error.statusText) {
                    errorMessage = error.statusText;
                }
                else {
                    errorMessage = error.Message;
                }
            }
            _this_1.ipsMessage.error("Error saving answers. " + errorMessage);
        });
    };
    /** Determine if there are noChanges or no previous answers. does NOT check for validity of form,
     * you will have to do that elsewhere.
     */
    MySurveyTakeQuestionComponent.prototype.ShouldDisableSave = function () {
        // Disable save if there are no changes and I have answered already. Need to leave open if I have never answered
        return !this.HasChanges() && !!this.question.MySurveyAnswer;
    };
    /** Call this when something has changed to determine new hasChanges value. */
    MySurveyTakeQuestionComponent.prototype.HasChanges = function () {
        var hasChanges = false;
        switch (this.question.ProfileType) {
            case "LocationGroup":
                if (this.IsOneToOneRule()) {
                    hasChanges = this.SelectedSubGroupId.value !== this.OriginalSelectedSubGroupId;
                }
                else {
                    hasChanges = this.SubGroups.controls.some(function (sg) { return sg.value.Selected !== sg.value.OriginalSelected; });
                }
                if (!hasChanges) {
                    hasChanges = this.NewSubGroups.length > 0;
                }
                break;
            case "FixtureGroup":
                if (this.question.NoFixture) {
                    hasChanges = this.question.NoFixture !== this.question.OriginalNoFixture;
                }
                else {
                    hasChanges = this.Fixtures.value.some(function (answer) { return answer.Confirmed == null || answer.Confirmed === false; });
                }
                break;
        }
        return hasChanges;
    };
    MySurveyTakeQuestionComponent.prototype.SelectedCount = function () {
        return this.question.ProfileType === "LocationGroup" ? this.SubGroups.controls.filter(function (sg) { return sg.value.Selected === true; }).length + this.NewSubGroups.length : 0;
    };
    MySurveyTakeQuestionComponent.prototype.HasNASelected = function () {
        if (this.question.ProfileType !== "LocationGroup") {
            return false;
        }
        return this.SubGroups.controls.some(function (sg) { return sg.value.IsNA && sg.value.Selected; });
    };
    /**
     * Add a new option for the user add their own answer
     * @param markAsDirty - when true, will mark form as dirty. False should only be used when first loading the form for TypeInAnswer scenario
     */
    MySurveyTakeQuestionComponent.prototype.AddAnotherOption = function (markAsDirty) {
        if (markAsDirty === void 0) { markAsDirty = true; }
        var emptySubGroup = { Id: 0, Selected: true, Name: "", OriginalSelected: true };
        this.NewSubGroups.push(this.getNewSubGroupCtrl(emptySubGroup, true));
        if (this.IsOneToOneRule()) {
            this.SubGroups.controls.forEach(function (sg) {
                sg.value.Selected = false;
            });
            this.SelectedSubGroupId.patchValue(0);
        }
        if (markAsDirty) {
            this.AnswerForm.patchValue({ IsDirty: true });
            this.AnswerForm.markAsDirty();
            this.formDirtyChanged.emit();
        }
    };
    MySurveyTakeQuestionComponent.prototype.RemoveNewOption = function (index) {
        this.NewSubGroups.removeAt(index);
        if (!this.HasChanges()) {
            this.AnswerForm.markAsPristine();
        }
        this.formDirtyChanged.emit();
        this.SubGroups.controls[0].get("Selected").updateValueAndValidity();
    };
    MySurveyTakeQuestionComponent.prototype.subGrpRequired = function (subGrpId) {
        var _this_1 = this;
        return function (control) {
            var v = _this_1.AnswerForm.controls;
            if (!control || control.value === true || !_this_1.SubGroups) {
                _this_1.SubGroups.controls.forEach(function (ctrl) {
                    ctrl.get("Selected").setErrors(null);
                });
                return null;
            }
            var groups = _this_1.SubGroups.value.filter(function (sg) { return sg.Id !== subGrpId; });
            if (!groups.some(function (sg) { return sg.Selected === true; }) && _this_1.NewSubGroups.controls.length === 0) {
                return {
                    subGroupRequired: {
                        valid: false
                    }
                };
            }
            return null;
        };
    };
    MySurveyTakeQuestionComponent.prototype.BoxClicked = function (subGroupCtrl) {
        var _this_1 = this;
        var selected = subGroupCtrl.get("Selected");
        var newVal = !selected.value;
        if (this.LocGroupIsOneToOne) {
            this.SelectedSubGroupId.setValue(subGroupCtrl.get("Id").value);
            this.SubGroups.controls.forEach(function (sg) {
                sg.patchValue({ Selected: false });
            });
            if (this.ShowAllOptions) {
                selected.setValue(true);
            }
            else {
                selected.setValue(newVal);
            }
        }
        else {
            var currentIsNA = subGroupCtrl.value.IsNA;
            var hasNASubGroup = this.HasNASubGroupValue;
            // If current item being selected IsNA, and other options are selected, confirm if they want to clear others.
            if (hasNASubGroup && currentIsNA && newVal && this.SelectedCount() > 0) {
                this.ipsMessage.confirm({ body: "CONFIRM_NOT_APPLICABLE_SELECTION", ok: "YES", cancel: "NO" })
                    .then(function (result) {
                    if (result) {
                        // unselect everything else.
                        _this_1.SubGroups.controls.forEach(function (sg) {
                            sg.patchValue({ Selected: false });
                        });
                        selected.setValue(newVal);
                    }
                })
                    .catch(function (canceled) {
                    // rejection
                });
            }
            // NA is selected along with a new selection
            else if (hasNASubGroup && !currentIsNA && newVal && this.HasNASelected()) {
                this.ipsMessage.confirm({ body: "CONFIRM_NOT_APPLICABLE_SELECTION", ok: "YES", cancel: "NO" })
                    .then(function (result) {
                })
                    .catch(function (canceled) {
                    // unselect everything else.
                    _this_1.SubGroups.controls.forEach(function (sg) {
                        sg.patchValue({ Selected: false });
                    });
                    selected.setValue(newVal);
                });
            }
            else {
                // Toggle check boxes on and off
                selected.setValue(newVal);
            }
        }
        if (!this.ShowAllOptions && this.SelectedCount() === 0) {
            this.AddAnotherOption();
        }
    };
    MySurveyTakeQuestionComponent.prototype.FixtureCountClicked = function (fixtureCtrl) {
        var confirmed = fixtureCtrl.get("Confirmed");
        var newVal = !confirmed.value;
        confirmed.setValue(newVal);
        fixtureCtrl.markAsDirty();
        this.formDirtyChanged.emit();
    };
    MySurveyTakeQuestionComponent.prototype.CheckFixtureClicked = function (noFixture) {
        if (noFixture) {
            this.confirmLabel = "CONFIRM";
            this.question.NoFixture = true;
        }
        else {
            this.confirmLabel = "CONFIRM_ALL";
            this.question.NoFixture = false;
        }
        if (this.question.NoFixture !== this.question.OriginalNoFixture) {
            this.AnswerForm.markAsDirty();
            this.formDirtyChanged.emit();
        }
        else {
            this.AnswerForm.markAsPristine();
            this.formDirtyChanged.emit();
        }
    };
    MySurveyTakeQuestionComponent.prototype.resetQuantity = function (fixtureCtrl) {
        var fixture = fixtureCtrl.value;
        fixtureCtrl.patchValue({
            Quantity: fixture.OriginalQuantity,
            Confirmed: fixture.OriginalConfirmed
        });
        fixtureCtrl.markAsPristine();
        this.formDirtyChanged.emit();
    };
    MySurveyTakeQuestionComponent.prototype.messageAdminClick = function () {
        this.messageOpen = !this.messageOpen;
    };
    MySurveyTakeQuestionComponent.prototype.messageRemovedHandler = function () {
        this.question.MySurveyMessage = null;
        this.messageOpen = false;
    };
    // Used by ForLoop for tracking item. doesn't have to remove element from DOM
    // and re-add if the tracked value doesn't change.
    MySurveyTakeQuestionComponent.prototype.trackControlById = function (index, item) {
        return item.value.Id;
    };
    return MySurveyTakeQuestionComponent;
}());
export { MySurveyTakeQuestionComponent };
