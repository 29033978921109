import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NgForm } from "@angular/forms";
import { map } from "rxjs/operators";
import { UserService, UserModel, BusinessIdRoleLocation, ProfileService } from "imagine-ui-ng-quick-start";
import { LocationService, LocationModel, LocationSearchInfo } from "../../../imagine-ui-ng-store-profile";
import cloneDeep from "lodash-es/cloneDeep";

import { ListSearchHelper, SearchInfo, SelectOption, ActiveProfileService } from "imagine-ui-ng-core";
import { CountryService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";
import { Transition } from "@uirouter/core";
import { String as IpsString } from "typescript-string-operations";

interface IUiLocationAssignmentModel extends LocationModel {
    Label: string;
    assignChanged: boolean;
    isAssigned: boolean;
    originalIsAssigned: boolean;
    savedIsAssigned: boolean;
}

interface LanguageModel {
    Code: string;
    Language: string;
}


@Component({
    selector: "app-user-view",
    templateUrl: "./user-view.component.html",
    styleUrls: ["./user-view.component.scss"]
})
export class UserViewComponent implements OnInit {
    @ViewChild("locationForm") public locationForm: NgForm;

    private userLocationArray: string[];
    private previousSearch: string;
    private totalLocationRecords: number;

    public myUser: UserModel;
    public locationSearch: ListSearchHelper;
    public breadCrumbLabel: string;
    public busy: boolean;
    public optionList: SelectOption[];
    public selectedOption: SelectOption;
    private userOriginalLocations: string[];
    public userRole: string;
    public Language: string;
    public Locale: string;
    public statusIconClass: string;
    public StatusIconTooltip: string;
    public loaded = false;
    public promise: Promise<any>;
    public showLocation: boolean;
    public headerLabel: string;
    public query: string;
    public previousFilterValue: string;
    public translatedTexts: TranslatedTexts;
    public headerText: string;

    constructor(private transition: Transition, private translateService: TranslateService, private http: HttpClient,
        private userService: UserService,
        private locationService: LocationService,
        listSearchHelper: ListSearchHelper,
        private activeProfileService: ActiveProfileService,
        private ipsMessage: IpsMessageService,
        private countryService: CountryService,
        private profileService: ProfileService) {

        this.locationSearch = listSearchHelper.getListSearchHelper(locationService);
    }

    ngOnInit() {
        this.myUser = {} as UserModel;
        this.busy = this.locationSearch.busy;
        this.optionList = [
            { text: "ALL", value: "all" },
            { text: "ASSIGNED", value: "assigned" },
            { text: "UNASSIGNED", value: "unassigned" }
        ];
        //Can only set the value. If you set it using optionList[1], or include the text property,
        //you will run into scoping issues due to search-box component and how it updates the text and value properties.
        this.selectedOption = { value: "assigned" } as SelectOption;
        this.previousFilterValue = this.selectedOption.value;

        // If we got an username to load, load it.
        let userName = decodeURIComponent(this.transition.params().id);
        if (userName !== "") {
            //Initial call to populate screen on load
            this.getUser(userName);
        }


        this.translatedTexts = { foundOf: "found of", total: "total", loadMoreResults: "load more results" };
        this.translateText();
        this.translateService.onLangChange.subscribe(() => this.translateText());
    }

    private translateText() {
        this.translateService.get(["ALL", "ASSIGNED", "UNASSIGNED", "USER_VIEW_HEADER", "FOUND_OF", "TOTAL", "LOAD_MORE_RESULTS"]).subscribe((res: [string]) => {
            this.translatedTexts.foundOf = res["FOUND_OF"];
            this.translatedTexts.total = res["TOTAL"];
            this.translatedTexts.loadMoreResults = res["LOAD_MORE_RESULTS"];
            this.optionList[0].text = res["ALL"];
            this.optionList[1].text = res["ASSIGNED"];
            this.optionList[2].text = res["UNASSIGNED"];
            this.headerText = res["USER_VIEW_HEADER"];
        });
    }

    //get user role and locations based on the active business identity
    private filterRoleLocationsByBrand(roles: BusinessIdRoleLocation[]) {
        let foundList: BusinessIdRoleLocation[] = [];
        roles.forEach((item) => {
            if (item.BusinessIdentity === this.activeProfileService.businessIdentity) {
                foundList.push(item);
            }
        });
        return foundList;
    }

    //set the user's role and locations
    private setUserRoleLocations(roles: BusinessIdRoleLocation[]) {
        let roleList: string[] = [];
        this.userOriginalLocations = this.profileService.getLocationListFromUser(roles);
        this.showLocation = this.userOriginalLocations !== undefined;
        if (this.showLocation) {
            this.userOriginalLocations.forEach((loc) => {
                loc = loc.trim();
            });
            this.userLocationArray = cloneDeep(this.userOriginalLocations);
        } else {
            this.userLocationArray = [];
        }

        roles.forEach((role) => {
            //if (role.Locations) {
            //    role.Locations.forEach((location: string) => {
            //        location = location.trim();
            //        if (userLocations.indexOf(location) === -1) {
            //            userLocations.push(location);
            //        }
            //    });
            //    this.userLocations = userLocations;
            //    this.userLocationArray = this.userLocationArray.concat(role.Locations);
            //}

            //this.internalUserRoles.push(role.Role);
            this.translateService.get(this.userService.getRoleTranslationKey(role.Role)).subscribe((res: string) => {
                roleList.push(res);
            });
        });

        this.userRole = roleList.join(", ");
    }


    private setLocaleLanguage(user: UserModel) {
        let localeSplit = user.Profile.Locale ? user.Profile.Locale.split("-") : null;
        let code = localeSplit ? localeSplit[0] : null;
        this.http.get("/assets/docs/language.json").toPromise()
            .then((response: LanguageModel[]) => {
                for (let i = 0; i < response.length; i++) {
                    if (response[i].Code === code) {
                        this.Language = response[i].Language;
                        break;
                    }
                }
            });

        if (localeSplit) {
            this.countryService.GetCountryByCode(localeSplit[1]).then((data) => {
                this.Locale = data.ResultList[0].Name;
            });
        }
    }


    //retrieve use by username
    private getUser(userName: string) {
        this.promise = this.userService.get(userName).then((response: UserModel) => {
            Object.assign(this.myUser, response);

            //status icon
            this.statusIconClass = this.userService.getStatusIconClass(this.myUser.Status);
            this.StatusIconTooltip = this.userService.getStatusIconTooltip(this.myUser.Status);

            //locale,language
            this.setLocaleLanguage(this.myUser);

            //user role and location
            let roleLocationList = this.filterRoleLocationsByBrand(this.myUser.Profile.Roles);
            if (roleLocationList !== null) {
                this.setUserRoleLocations(roleLocationList);
            }

            this.loaded = true;
            this.headerLabel = IpsString.Format(this.headerText, this.myUser.Profile.FirstName, this.myUser.Profile.LastName, this.myUser.Profile.UserName);

            if (this.showLocation) {
                //get the location total
                this.locationService.head().then((data) => {
                    this.totalLocationRecords = data;
                    this.locationSearch.TotalRecords = data;
                });

                this.getLocationList(this.query, this.selectedOption.value, true);
            }
        });
    }

    private setAssignmentStatus(filterOptionValue: string) {
        let list = this.locationSearch.resultList;
        list.forEach((item) => {
            let id = item.Label.split("-")[0].trim();
            item.id = id;
            if (this.userOriginalLocations.indexOf(id) !== -1) {
                item.isAssigned = true;
            } else {
                item.isAssigned = false;
            }
            item.originalIsAssigned = item.isAssigned;

            //if there is a saved status use it
            if (item.savedIsAssigned !== undefined) {
                item.isAssigned = item.savedIsAssigned;
            }
        });
        switch (filterOptionValue) {
            case "all":
                this.locationSearch.TotalRecords = this.totalLocationRecords;
                break;
            case "assigned":
                this.locationSearch.TotalRecords = this.userOriginalLocations.length;
                break;
            case "unassigned":
                this.locationSearch.TotalRecords = this.totalLocationRecords - this.userOriginalLocations.length;
                break;
        }

    }

    private assignLocations() {
        let list = this.locationSearch.resultList;

        list.forEach((item) => {
            if (item.isAssigned && !item.originalIsAssigned) {
                this.userLocationArray.push(item.id);
            }
            if (!item.isAssigned && item.originalIsAssigned) {
                let index = this.userLocationArray.indexOf(item.id);
                if (index > -1) {
                    this.userLocationArray.splice(index, 1);
                }
            }
        });

        let roles = this.myUser.Profile.Roles;
        let locationsSet = false;
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].BusinessIdentity === this.activeProfileService.businessIdentity && this.profileService.roleRequiresLocations(roles[i].Role)) {
                if (locationsSet) {
                    roles[i].Locations = [];
                } else {
                    roles[i].Locations = this.userLocationArray;
                    locationsSet = true;
                }
            }
        }

        return this.userService.putUser(this.myUser.Profile.UserName, this.myUser).then((result) => {
            this.locationForm.form.markAsPristine();
        });
    }

    public getLocationList(search: string, filterOption: string | SelectOption, reload: boolean) {
        if (this.busy) {
            return;
        }
        if (!search) {
            search = "";
        }
        //the select box returns an IOption result
        let filterOptionValue: string = filterOption.hasOwnProperty("value") ? (<SelectOption>filterOption).value : (<string>filterOption);

        let route: string;
        let searchInfo: LocationSearchInfo = { searchText: search, recordCount: Number(environment.chunkSize) };
        let forceReload = false;

        if (filterOptionValue === "assigned") {
            searchInfo = { searchText: search, recordCount: Number(environment.chunkSize), LocationIdentifiers: this.userLocationArray, showUnassignedItems: false };
            route = `${environment.baseApiUrl}Profile/v1/Location/FilteredSearch/LocationIdentifier`;
        }
        if (filterOptionValue === "unassigned") {
            searchInfo = { searchText: search, recordCount: Number(environment.chunkSize), LocationIdentifiers: this.userLocationArray, showUnassignedItems: true };
            route = `${environment.baseApiUrl}Profile/v1/Location/FilteredSearch/LocationIdentifier`;
        }
        if (filterOptionValue === "all") {
            searchInfo = { searchText: search, recordCount: Number(environment.chunkSize), filterOption: "", showUnassignedItems: true };
            route = `${environment.baseApiUrl}Profile/v1/Location/FilteredSearch/LocationIdentifier`;
        }
        if (this.previousFilterValue !== filterOptionValue || this.previousSearch !== search) {
            forceReload = true;
        }
        forceReload = reload || forceReload;
        this.locationSearch.promise = this.locationSearch.searchHelper(searchInfo, forceReload, route, true).then((data) => {
            this.locationSearch.resultList.forEach((item: IUiLocationAssignmentModel) => {
                item.Label = `${item.LocationIdentifier} - ${item.Name} - ${item.Addresses[0].City}, ${item.Addresses[0].StateProvince}`;
            });

            this.setAssignmentStatus(filterOptionValue);
            this.previousFilterValue = filterOptionValue;
            this.previousSearch = search;

        });


    }

    public assignChanged(result: IUiLocationAssignmentModel) {
        result.assignChanged = result.isAssigned !== result.originalIsAssigned;

        //save the assignment status for load more
        result.savedIsAssigned = result.isAssigned;

        //if no change set form Pristine
        if (!result.assignChanged) {
            this.locationForm.form.markAsPristine();
        }
    }

    public saveLocationPrompt() {
        let promptData: Object = { body: "ASSIGNED_LOCATIONS", workFunction: () => this.assignLocations(), progressMessage: "ASSIGNED_LOCATIONS" };
        return this.ipsMessage.waitForWork(promptData).then((result) => {
            if (result) {
                //reload the page
                this.selectedOption = { value: this.previousFilterValue, text: "" };
                this.getUser(this.myUser.Profile.UserName);
            }
        });
    }

}
