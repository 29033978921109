import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService, SearchInfo } from "imagine-ui-ng-core";
import { environment } from "../../../../environments/environment";
import { LocationGroupSubGroupModel } from "../../model/LocationGroupSubGroupModel";

@Injectable()
export class LocationGroupByLocationService extends IpsBaseWebService {

    private route: string;
    public LocationId: string;

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("LocationGroup", "Profile", "v1");
        // Have to initialize after super() is called.
        this.route = `${this.urlApiBaseProductPlusVersion}Location/{id}/LocationGroup`;
    }

    public getByLocation(id: string): Promise<LocationGroupSubGroupModel[]> {
        this.LocationId = id;
        return this.getByRoute(this.route.replace("{id}", id));
    }

    public headByLocation(id: string, option: string): Promise<number> {
        return this.headByRoute(this.route.replace("{id}", id) + "?showUnassignedItems=" + option);
    }

    // Override the ipsBaseWebService.search method.
    public search<T>(searchInfo: SearchInfo): Promise <T> {
        return this.searchByRelation(searchInfo, "Location/" + searchInfo.id + "/LocationGroupSubGroup/Search");
    }

}
