import { Component, OnInit } from "@angular/core";
import { LocationModel, LocationGroupModel, LocationSubGroupModel, LocationByLocationGroupService, LocationGroupService, OptionType } from "../../index";
import { SearchInfo, ListSearchHelper, ActiveProfileService } from "imagine-ui-ng-core";
import { Transition } from "@uirouter/core";
import { TranslateService } from "@ngx-translate/core";
import { TranslatedTexts } from "imagine-ui-ng-list-search";
import { SearchModalComponent } from "../../../shared/search-modal/search-modal.component";
import { IpsModalService } from "imagine-ui-ng-modal";
import { AuthService } from "imagine-ui-ng-security";

interface LocationModelWithLabel extends LocationModel {
    Label: string;
}


interface LocationGroupModelWithImages extends LocationGroupModel {
    FullImage: string;
    SubGroups: LocationSubGroupModelWithImages[];
}

interface LocationSubGroupModelWithImages extends LocationSubGroupModel {
    FullImage: string;
}


@Component({
    selector: "app-location-group-view",
    templateUrl: "./location-group-view.component.html",
    styleUrls: ["../store-profile-location-group/store-profile-location-group.component.scss"]
})
export class LocationGroupViewComponent implements OnInit {
    private dataService: LocationGroupService;
    private queryParamSubGroup: string;

    public busy: boolean;
    public myLocationGroup: LocationGroupModelWithImages;
    public loaded: boolean;
    public promise: Promise<void>;
    private locationGroupId: number;
    private emptyGuid = "00000000-0000-0000-0000-000000000000";

    constructor(private transition: Transition,
        private translateService: TranslateService,
        private locationGroupService: LocationGroupService,
        listSearchHelper: ListSearchHelper,
        private ipsModal: IpsModalService,
        private activeProfileService: ActiveProfileService,
        private authService: AuthService
    ) {
        this.locationGroupId = Number(this.transition.params().id);
        this.myLocationGroup = <LocationGroupModelWithImages>{ Id: this.locationGroupId };
        this.dataService = locationGroupService;
        this.queryParamSubGroup = this.transition.params().subgroup;

        this.busy = false;
        this.loaded = false;
    }

    ngOnInit() {
        // If we got an ID to load, load it.
        if (this.locationGroupId > 0) {
            //Initial call to populate screen on load
            this.getLocationGroup(this.locationGroupId.toString());
        }
    }


    private getLocationGroup(id: string) {
        this.loaded = false;

        this.promise = this.dataService.get<LocationGroupModel>(id)
            .then((response: LocationGroupModel) => {
                this.locationGroupService.sortSubGroup(response.SubGroups, response.OptionType);
                Object.assign(this.myLocationGroup, response);
                this.locationGroupService.setLocFeatureInfo(this.myLocationGroup, this.translateService);

                //Setup image URLS
                if (this.myLocationGroup.Media && this.myLocationGroup.MasterMediaId !== this.emptyGuid) {
                    this.myLocationGroup.FullImage = `${this.myLocationGroup.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
                }

                this.myLocationGroup.SubGroups.forEach(item => {
                    if (item.Media && item.MasterMediaId !== this.emptyGuid) {
                        item.FullImage = `${item.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity}&idToken=${this.authService.getIdToken()}`;
                    }
                });

                this.loaded = true;
            });
    }


    public showLocations(locFeatureId: number, locFeatureOptionId: number) {
        this.ipsModal.displayTemplateScrollable(SearchModalComponent, {
                resolve: { addAll: false, search: "listbylocgroup", locationGroupId: locFeatureId, locationSubgroupId: locFeatureOptionId }
            },
            {
                windowClass: "no-list-group-item-interaction"
            });

    }

}
