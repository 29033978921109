import { OnInit } from "@angular/core";
import { FormGroup, FormControl, FormArray, Validators, AbstractControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { PageTitleService } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { String as IpsString } from "typescript-string-operations";
import { ElementService } from "../../index";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";
var ElementEditComponent = /** @class */ (function () {
    function ElementEditComponent(elementService, ipsMessage, $state, transition, translateService, pageTitleService, activeProfileService, settingsService) {
        var _this = this;
        this.elementService = elementService;
        this.ipsMessage = ipsMessage;
        this.$state = $state;
        this.transition = transition;
        this.translateService = translateService;
        this.pageTitleService = pageTitleService;
        this.activeProfileService = activeProfileService;
        this.settingsService = settingsService;
        this.indexer = 0;
        this.textMaxLength = "MAX_LENGTH_ERROR";
        this.hasShownWarning = false;
        this.enableSignPlan = false;
        this.errorMessages = {
            "required": function () { return _this.requiredMessage; },
            "maxlength": function (params) { return IpsString.Format(_this.textMaxLength, params.requiredLength); },
            "numericValueRequiredMessage": function () { return _this.numericValueRequiredMessage; },
            "tooManyDecimalPlacesMessage": function () { return _this.tooManyDecimalPlacesMessage; },
            "decimalNumberOnly": function () { return _this.decimalNumberOnly; },
            "duplicatedSize": function () { return _this.duplicatedSize; }
        };
        this.saveElementCallback = function () {
            var element = _this.elementForm.value;
            element.ElementDetails = element.Details;
            //add business identity to element/details
            for (var i = 0; i < element.ElementDetails.length; i++) {
                element.ElementDetails[i].BusinessIdentity = _this.activeProfileService.businessIdentity;
            }
            element.BusinessIdentity = _this.activeProfileService.businessIdentity;
            if (element.Id) {
                return _this.elementService.put(element).then(function (response) {
                    element = response;
                    _this.elementForm.markAsPristine();
                });
            }
            else {
                return _this.elementService.post(element).then(function (response) {
                    element.Id = response.Id;
                    _this.elementForm.markAsPristine();
                });
            }
        };
    }
    ElementEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var paramId = this.transition.params().id === "0" ? "" : this.transition.params().id;
        this.focusInput = paramId.toString() === "" ? "nameInput" : "addSizeInput";
        var pageTitle = paramId.toString() === "" ? "CREATE_ELEMENT" : "EDIT_ELEMENT";
        this.breadCrumbLabel = pageTitle;
        this.pageTitleService.setTitle([pageTitle]);
        this.createForm();
        this.loaded = false;
        //If we got an ID to load, load it.
        if (paramId > 0) {
            //Initial call to populate screen on load
            var promises = [];
            promises.push(this.getSignPlanSetting());
            promises.push(this.getElement(paramId));
            Promise.all(promises).then(function () {
                _this.loaded = true;
            });
        }
        else if (paramId === "") {
            this.initElementDetailsList();
            this.getFocusableInput();
            this.hasShownWarning = true;
            this.getSignPlanSetting().then(function () {
                _this.loaded = true;
            });
        }
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
    };
    ElementEditComponent.prototype.getSignPlanSetting = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.settingsService.canEditSignPlanLayout().then(function (response) {
                _this.enableSignPlan = response;
                resolve();
            }).catch(function () {
                _this.enableSignPlan = false;
                resolve();
            });
        });
        return returnPromise;
    };
    Object.defineProperty(ElementEditComponent.prototype, "Details", {
        get: function () {
            return this.elementForm.get("Details");
        },
        enumerable: true,
        configurable: true
    });
    ElementEditComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get(["PLEASE_ENTER_NUMBER", "TOO_MANY_DECIMAL_PLACES", "ENTER_DECIMAL_NUMBER", "DUPLICATED_SIZE", "PROMPT_DELETE_BODY", "MAX_LENGTH_ERROR",
            "PLEASE_ENTER_AN_ELEMENT_NAME", "PLEASE_ENTER_WIDTH", "PLEASE_ENTER_HEIGHT", "FIELD_REQUIRED"]).subscribe(function (res) {
            _this.numericValueRequiredMessage = res["PLEASE_ENTER_NUMBER"];
            _this.tooManyDecimalPlacesMessage = res["TOO_MANY_DECIMAL_PLACES"];
            _this.decimalNumberOnly = res["ENTER_DECIMAL_NUMBER"];
            _this.duplicatedSize = res["DUPLICATED_SIZE"];
            _this.promptDeleteBody = res["PROMPT_DELETE_BODY"];
            _this.textMaxLength = res["MAX_LENGTH_ERROR"];
            _this.requiredElementName = res["PLEASE_ENTER_AN_ELEMENT_NAME"];
            _this.requiredWidth = res["PLEASE_ENTER_WIDTH"];
            _this.requiredHeight = res["PLEASE_ENTER_HEIGHT"];
            _this.requiredMessage = res["FIELD_REQUIRED"];
        });
    };
    ElementEditComponent.prototype.onSignPlanTransformation = function (e, elementDetail) {
        var elementDetailValue = elementDetail.value;
        elementDetailValue.CropTop = e.top;
        elementDetailValue.CropLeft = e.left;
        elementDetailValue.CropHeight = e.height;
        elementDetailValue.CropWidth = e.width;
        elementDetailValue.CropRotation = e.rotation;
        elementDetail.patchValue(elementDetailValue);
        elementDetail.markAsDirty();
    };
    ElementEditComponent.prototype.getNameErrorMessages = function () {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = function () { return _this.requiredElementName; };
        return msgs;
    };
    ElementEditComponent.prototype.getWidthErrorMessages = function () {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = function () { return _this.requiredWidth; };
        return msgs;
    };
    ElementEditComponent.prototype.getHeightErrorMessages = function () {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        msgs["required"] = function () { return _this.requiredHeight; };
        return msgs;
    };
    ElementEditComponent.prototype.createForm = function () {
        this.Id = new FormControl("");
        this.BusinessIdentity = new FormControl("");
        this.Name = new FormControl("", [Validators.required, Validators.maxLength(50)]);
        this.Notes = new FormControl("", Validators.maxLength(500));
        this.isObsolete = new FormControl(false);
        this.elementForm = new FormGroup({
            Id: this.Id,
            BusinessIdentity: this.BusinessIdentity,
            Name: this.Name,
            Notes: this.Notes,
            IsObsolete: this.isObsolete,
            Details: new FormArray([])
        });
    };
    ElementEditComponent.prototype.getElement = function (id) {
        var _this = this;
        this.promise = this.elementService.get(id).then(function (response) {
            //Store off the original values
            _this.originalElement = Object.assign({}, response);
            _this.elementForm.patchValue({
                Id: response.Id,
                BusinessIdentity: response.BusinessIdentity,
                Name: response.Name,
                Notes: response.Notes,
                IsObsolete: response.IsObsolete
            });
            var detailFGs = response.ElementDetails.map(function (detail) { return _this.getNewDetail(detail); });
            var details = new FormArray(detailFGs);
            _this.elementForm.setControl("Details", details);
            _this.initElementDetailsList();
            _this.getFocusableInput();
        });
        return this.promise;
    };
    ElementEditComponent.prototype.getFocusableInput = function () {
        this.focusableWidthInput = "detailHtmlName" + (this.indexer - 1) + "Width";
    };
    ElementEditComponent.prototype.initElementDetailsList = function () {
        var details = this.elementForm.get("Details");
        if (details.controls.length === 0) {
            this.addDetail();
        }
        this.sortElements();
    };
    ElementEditComponent.prototype.deleteDetail = function (inputName) {
        var details = this.elementForm.get("Details");
        var count = details.controls.length;
        var newValues = [];
        details.controls.splice(inputName, 1);
        details.controls.forEach(function (c) {
            newValues.concat(c.value);
        });
        details.patchValue(newValues);
        return count !== details.controls.length;
    };
    ElementEditComponent.prototype.deleteElement = function () {
        var element = this.elementForm.value;
        return this.elementService.delete(element.Id).then(function (response) {
        });
    };
    ElementEditComponent.prototype.getNewDetail = function (detail) {
        return new FormGroup({
            Id: new FormControl(detail.Id),
            ElementId: new FormControl(detail.ElementId),
            BusinessIdentity: new FormControl(detail.BusinessIdentity),
            Width: new FormControl(detail.Width, [Validators.required, this.customValidator]),
            Height: new FormControl(detail.Height, [Validators.required, this.customValidator]),
            InputName: new FormControl("detailHtmlName" + this.indexer++),
            CropTop: new FormControl(detail.CropTop),
            CropLeft: new FormControl(detail.CropLeft),
            CropHeight: new FormControl(detail.CropHeight),
            CropWidth: new FormControl(detail.CropWidth),
            CropRotation: new FormControl(detail.CropRotation),
            CategoryCode: new FormControl(detail.CategoryCode)
        });
    };
    ElementEditComponent.prototype.addDetail = function () {
        var details = this.elementForm.get("Details");
        var ElementId = this.elementForm.get("Id") ? this.elementForm.get("Id").value : "";
        details.push(this.getNewDetail({
            Id: 0,
            ElementId: ElementId,
            BusinessIdentity: "",
            Width: null,
            Height: null,
            InputName: "detailHtmlName" + this.indexer++,
            CropTop: 0,
            CropLeft: 0,
            CropWidth: null,
            CropHeight: null,
            CropRotation: 0,
            CategoryCode: null
        }));
        this.getFocusableInput();
    };
    ElementEditComponent.prototype.removeDetail = function (frmGrpIndex) {
        var details = this.elementForm.get("Details");
        if (details.controls.length === 1) {
            var grp = details.get(frmGrpIndex.toString());
            grp.get("Width").setValue("");
            grp.get("Height").setValue("");
            grp.get("CropTop").setValue(0);
            grp.get("CropLeft").setValue(0);
            grp.get("CropWidth").setValue(null);
            grp.get("CropHeight").setValue(null);
            grp.get("CropRotation").setValue(0);
            grp.get("CategoryCode").setValue(null);
            this.elementForm.markAsDirty();
            return;
        }
        if (this.deleteDetail(frmGrpIndex)) {
            // Set as dirty since we removed a detail.
            this.elementForm.markAsDirty();
        }
    };
    ElementEditComponent.prototype.deleteElementPrompt = function () {
        var _this = this;
        var translated = IpsString.Format(this.promptDeleteBody, this.elementForm.get("Name").value);
        this.ipsMessage.confirmDelete({ body: translated, workFunction: function () { return _this.deleteElement(); }, progressMessage: "DELETING" }, undefined)
            .then(function (result) {
            if (result) {
                _this.$state.go("main.storeProfileElement.search");
            }
        })
            .catch(function () {
            // rejection
        });
    };
    ElementEditComponent.prototype.saveElementPrompt = function (redirect) {
        var _this = this;
        var id = this.elementForm.value.Id || 0;
        return this.ipsMessage.waitForWork({ body: "SAVING", workFunction: this.saveElementCallback, progressMessage: "SAVING" }, undefined).then(function (result) {
            if (result) {
                if (redirect) {
                    _this.$state.go("main.storeProfileElement.search");
                }
                else {
                    _this.$state.go("main.storeProfileElement.edit", { id: _this.elementForm.value.Id }, { reload: true });
                }
            }
        });
    };
    ElementEditComponent.prototype.numberRequired = function (input) {
        var isValid = false;
        var message = "";
        var inputNbr = Number(input);
        if (isNaN(inputNbr)) {
            return { numericValueRequiredMessage: { valid: false } };
        }
        var inputType = typeof (input);
        // On first load these will be number types
        if (inputType === "string") {
            var decimalPart = input.split(".")[1];
            if (inputNbr <= 0) {
                return { decimalNumberOnly: { valid: false } };
            }
            else if (decimalPart && decimalPart.length > 6 ||
                (input.indexOf("1e-") === 0 && Number(input.split("1e-")[1]) >= 7)) {
                return { tooManyDecimalPlacesMessage: { valid: false } };
            }
        }
        return null;
    };
    ElementEditComponent.prototype.customValidator = function (control) {
        var number;
        if (typeof (control.value) === "number") {
            number = control.value;
        }
        else {
            var inputNbr = Number(control.value);
            if (isNaN(inputNbr)) {
                return { numericValueRequiredMessage: { valid: false } };
            }
            number = inputNbr;
        }
        if (number <= 0) {
            return { decimalNumberOnly: { valid: false } };
        }
        var numberStr = number.toString();
        var decimalPart = numberStr.split(".")[1];
        if (decimalPart && decimalPart.length > 6 || (numberStr.indexOf("1e-") === 0 && Number(numberStr.split("1e-")[1]) >= 7)) {
            return { tooManyDecimalPlacesMessage: { valid: false } };
        }
        //Check for duplicates
        //Get FormArray
        if (!control.parent) {
            return null;
        }
        var array = control.parent.parent;
        var count = 0;
        var width = (control.parent.get("Width").value || "").toString();
        var height = (control.parent.get("Height").value || "").toString();
        var currentWidth = "";
        var currentHeight = "";
        //Check if see if we have matching rows with width and height combo
        Object.keys(array.controls).some(function (key, index) {
            currentWidth = (array.controls[key].get("Width").value || "").toString();
            currentHeight = (array.controls[key].get("Height").value || "").toString();
            //When we find two then we know we have a duplicate
            if (currentWidth !== "" && currentWidth === width &&
                currentHeight !== "" && currentHeight === height &&
                ++count === 2) {
                return true;
            }
        });
        if (count === 2) {
            return {
                duplicatedSize: {
                    valid: false
                }
            };
        }
        return null;
    };
    ElementEditComponent.prototype.getInputNameLabel = function (control, item) {
        return control.get("InputName").value + "_" + item;
    };
    ElementEditComponent.prototype.sortElements = function () {
        var elements = this.elementForm.get("Details");
        elements.controls
            .sort(function (n1, n2) {
            var itemA1 = n1.value.Width;
            var itemA2 = n2.value.Width;
            var itemB1 = n1.value.Height;
            var itemB2 = n2.value.Height;
            if (itemA1 < itemA2) {
                return -1;
            }
            if (itemA1 > itemA2) {
                return 1;
            }
            if (itemB1 < itemB2) {
                return -1;
            }
            if (itemB1 > itemB2) {
                return 1;
            }
            return 0;
        });
    };
    ElementEditComponent.prototype.msgPrompt = function (fg) {
        if (!this.hasShownWarning) {
            var ed_1 = this.originalElement.ElementDetails.find(function (f) { return f.Id === fg.get("Id").value; });
            if (fg.get("Width").value !== ed_1.Width || fg.get("Height").value !== ed_1.Height) {
                this.ipsMessage.confirm({
                    title: "WARNING",
                    body: "ELEMENT_SIZE_CHANGE_WARNING",
                    ok: "YES_CHANGE_IT",
                    cancel: "NO_KEEP_IT"
                }).then(function () {
                    //Value change is fine
                })
                    .catch(function () {
                    //Reset value
                    fg.get("Width").setValue(ed_1.Width);
                    fg.get("Height").setValue(ed_1.Height);
                });
            }
            this.hasShownWarning = true;
        }
    };
    ElementEditComponent.prototype.toggleObsolete = function (locationGroupControl) {
        var isObsoleteControl = locationGroupControl.get("IsObsolete");
        isObsoleteControl.setValue(!isObsoleteControl.value);
        isObsoleteControl.markAsDirty();
    };
    return ElementEditComponent;
}());
export { ElementEditComponent };
