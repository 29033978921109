<div>
    <form ipsFocus="searchInput">
        <div>
            <button type="button" class="fa fa-remove ips-close" aria-label="Close" (click)="close()" data-dismiss="modal"></button>
        </div>
        <div class="ips-modal-header ips-modal-dark-header">
            <h4 class="modal-title m-0">{{modalTitle | translate}}</h4>
        </div>
        <div class="modal-body ips-modal-search-body ips-modal-responsive">
            <ips-list-search searchLabel="{{searchLabel | translate }}"
                             searchLabelPlural="{{ searchLabelPlural | translate }}"
                             hideHeader=true
                             [listData]="listSearch"
                             [busy]="busy"
                             (onListDataChange)="getListData($event.search)"
                             section=""
                             [scroller]=true
                             quickRef=false
                             [promise]="promise"
                             [autoFocus]="true"
                             [isModal]="true"
                             [hideTotals]="false">
                <ng-template #listSearchItemTemplate let-result="result">
                    <div class="list-group-item border-0">
                        <b>{{result.Label | translate}}</b><br />
                        <b><span translate>RESPONSE</span>:</b>
                        <div *ngFor="let answer of result.Answers">
                            <span class="pl-3">{{answer.Name}}</span>
                        </div>
                        <div *ngIf="result.Message">
                            <div>
                                <strong><span translate>SURVEY_MESSAGE</span>:</strong>
                            </div>
                            <div>{{result.Message.Text}}</div>
                            <div class="ips-list-image mb-3" *ngIf="result.Message.FullImage">
                                <ips-image-view [imgThumbUrl]="result.Message.FullImage" [imgFullUrl]="result.Message.FullImage" hideLabel="true" noImageText="NO_IMAGE"></ips-image-view>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ips-list-search>
        </div>
    </form>
</div>
