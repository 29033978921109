import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-abstract",
    templateUrl: "./abstract.component.html",
})
export class AbstractComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
