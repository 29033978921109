var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var AddressService = /** @class */ (function (_super) {
    __extends(AddressService, _super);
    function AddressService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.activeProfileService = activeProfileService;
        _this.init("Address", "Profile", "v1");
        return _this;
    }
    // GET /Profile/v1/Address
    // List all addresses
    // POST /Profile/v1/Address
    // Create a new address
    // PUT /Profile/v1/Address
    // Save changes to an address
    // DELETE /Profile/v1/Address/{id}
    // Delete an address
    // GET /Profile/v1/Address/{id}
    // Get specific address by ID.
    // GET /Profile/v1/Address/Location/{pLocationId}
    // Get a list of addresses associated with a location
    // GET /Profile/v1/Address/conditions
    // Return all known conditions from this Api endpoint
    // POST /Profile/v1/Address/Validate
    AddressService.prototype.validateAddress = function (postData) {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            _this.post(postData, null, _this.urlApiBasePlusController + "Validate")
                .then(function (response) {
                resolve(response);
            }, function (reason) {
                reject(reason.Message || reason.error.Message);
            });
        });
        return promise;
    };
    AddressService.prototype.getThreeCharCountryCode = function (twoCharacterCountry, countryList) {
        if (twoCharacterCountry === "US")
            return "USA";
        var val = countryList.find(function (c) { return c.Iso3166TwoCharCountryCode === twoCharacterCountry; });
        if (val) {
            return val.Iso3166ThreeCharCountryCode;
        }
        else {
            return twoCharacterCountry;
        }
    };
    AddressService.prototype.getTwoCharCountryCode = function (country, countryList) {
        if (country === "USA")
            return "US";
        if (country && country.length > 2) {
            var val = countryList.find(function (c) { return c.Iso3166ThreeCharCountryCode === country; });
            if (val) {
                return val.Iso3166TwoCharCountryCode;
            }
            else {
                return country;
            }
        }
        else {
            return country;
        }
    };
    AddressService.prototype.updateAddressModelFromShippingAddress = function (address, data, countryList) {
        var toReturn = {
            Id: address.Id,
            LocationId: address.LocationId,
            Attention: data.Attention,
            Line1: data.Line1,
            Line2: data.Line2,
            City: data.City,
            StateProvince: data.StateProvince,
            Country: this.getThreeCharCountryCode(data.Country, countryList),
            // Country: address.Country,
            PostalCode: data.PostalCode,
            Phone: data.Phone,
            Fax: address.Fax,
            Email: data.Email,
            Lattitude: address.Lattitude,
            Longitude: address.Longitude,
            BusinessIdentity: data.BusinessIdentity,
            Order: address.Order,
            ValidationStatus: data.ValidationStatus,
            LastValidatedOn: data.LastValidatedOn
        };
        return toReturn;
    };
    AddressService.prototype.addressModeltoShippingAddress = function (address) {
        var toReturn = {
            Label: "",
            Id: address.Id,
            Attention: address.Attention,
            OrderId: null,
            NeedByDate: null,
            ReleaseDate: null,
            ShipMethodId: null,
            FirstName: null,
            LastName: null,
            Company: null,
            Title: null,
            Line1: address.Line1,
            Line2: address.Line2,
            Line3: null,
            City: address.City,
            StateProvince: address.StateProvince,
            Country: address.Country,
            PostalCode: address.PostalCode,
            Phone: address.Phone,
            Email: address.Email,
            BusinessIdentity: address.BusinessIdentity,
            ValidationStatus: address.ValidationStatus,
            LastValidatedOn: address.LastValidatedOn,
            AddressBookAlias: "",
            AddressBookId: 0,
            RemovedFromAddressBook: false,
            SaveToAddressBook: false
        };
        return toReturn;
    };
    AddressService.prototype.getAddressBook = function () {
        var route = this.urlApiBase + "Shop/v1/AddressBook";
        return this.getByRoute(route);
    };
    AddressService.prototype.saveToAddressBook = function (entry) {
        var route = this.urlApiBase + "Shop/v1/AddressBookEntry";
        return this.put(entry, null, route);
    };
    AddressService.prototype.deleteFromAddressBook = function (entry) {
        var _this = this;
        var route = this.urlApiBase + "Shop/v1/AddressBookEntry/" + entry.Id;
        var returnPromise = new Promise(function (resolve, reject) {
            _this.deleteByRoute(route).then(function (deleteResponse) {
                if (deleteResponse.Successful === true && deleteResponse.Resource === true) {
                    _this.getAddressBook().then(function (addressBookResponse) {
                        resolve(addressBookResponse);
                    });
                }
                else {
                    reject("Unable to remove entry from address book");
                }
            });
        });
        return returnPromise;
    };
    AddressService.prototype.convertToShippingAddress = function (entry) {
        var returnModel = {};
        Object.assign(returnModel, entry.Address);
        returnModel.AddressBookAlias = entry.Alias;
        returnModel.Label = entry.Recipient;
        returnModel.AddressBookId = entry.Id;
        returnModel.SaveToAddressBook = true;
        returnModel.Company = entry.Address.Attention;
        return returnModel;
    };
    AddressService.prototype.convertToAddressBookEntry = function (address) {
        var returnModel = {};
        returnModel.Id = address.AddressBookId;
        returnModel.Alias = address.AddressBookAlias;
        returnModel.Recipient = address.Label;
        returnModel.AddressId = address.Id;
        returnModel.BusinessIdentity = address.BusinessIdentity;
        returnModel.Address = {};
        Object.assign(returnModel.Address, address);
        return returnModel;
    };
    return AddressService;
}(IpsBaseWebService));
export { AddressService };
