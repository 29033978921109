import { distinctUntilChanged, debounceTime, mergeMap } from "rxjs/operators";
import { Validators, FormBuilder } from "@angular/forms";
import { Observable, from } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { IpsModalService } from "imagine-ui-ng-modal";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { FixtureGroupService, LocationService, LocationGroupService } from "../../imagine-ui-ng-store-profile";
import { MarketSearchModalComponent } from "../market-search-modal/market-search-modal.component";
import { LinkedFeatureSearchModalComponent } from "../linked-feature-search-modal/linked-feature-search-modal.component";
import { LocationAttributeSearchModalComponent } from "../location-attribute-search-modal/location-attribute-search-modal.component";
import { MarketGroupService } from "./market-group.service";
var MarketService = /** @class */ (function () {
    function MarketService(translateService, activeProfileService, ipsModal, locationService, locationGroupService, fb, marketGroupService, fixtureGroupService) {
        this.translateService = translateService;
        this.activeProfileService = activeProfileService;
        this.ipsModal = ipsModal;
        this.locationService = locationService;
        this.locationGroupService = locationGroupService;
        this.fb = fb;
        this.marketGroupService = marketGroupService;
        this.fixtureGroupService = fixtureGroupService;
        this.marketHtmlName = "MrktHtml";
        this.marketIndexer = 0;
        this.markets = [];
        this.featureInAudience = [];
    }
    MarketService.prototype.newMarketFormGroup = function (ordinal) {
        if (ordinal === void 0) { ordinal = 1; }
        var inputName = this.marketHtmlName + this.marketIndexer++;
        var marketCtrl = this.fb.group({
            Id: this.fb.control(0),
            MarketName: this.fb.control("", [Validators.required, this.checkMarketName()]),
            BusinessIdentity: this.fb.control(this.activeProfileService.businessIdentity),
            LocationCount: this.fb.control(0),
            LocationBalance: this.fb.control(0),
            OperationType: this.fb.control("Union"),
            TargetMarketType: this.fb.control(null),
            TargetMarketId: this.fb.control(0),
            Ordinal: this.fb.control(ordinal),
            PendingLocationCount: this.fb.control(0),
            InputName: this.fb.control(inputName),
            TypeaheadModalSelection: this.fb.control(null),
            TargetAttributeValue: this.fb.control(""),
            LocationGroupId: this.fb.control(0),
            LocationGroupName: this.fb.control(""),
            LocationSubGroupId: this.fb.control(0),
            LocationSubGroupName: this.fb.control(""),
        });
        this.addMarketListeners(marketCtrl);
        return marketCtrl;
    };
    MarketService.prototype.getMarketFormGroup = function (market) {
        var inputName = this.marketHtmlName + this.marketIndexer++;
        var marketGrp = this.fb.group({
            Id: this.fb.control(market.Id),
            BusinessIdentity: this.fb.control(market.BusinessIdentity),
            LocationCount: this.fb.control(market.LocationCount),
            LocationBalance: this.fb.control(market.LocationBalance),
            OperationType: this.fb.control(market.OperationType),
            TargetMarketType: this.fb.control(market.TargetMarketType),
            TargetMarketId: this.fb.control(market.TargetMarketId),
            Ordinal: this.fb.control(market.Ordinal),
            PendingLocationCount: this.fb.control(market.PendingLocationCount),
            TargetAttributeValue: this.fb.control(market.TargetAttributeValue),
            InputName: this.fb.control(inputName),
            LocationGroupId: this.fb.control(0),
            LocationGroupName: this.fb.control(""),
            LocationSubGroupId: this.fb.control(0),
            LocationSubGroupName: this.fb.control(""),
        });
        //displayInfo
        var displayName;
        switch (market.TargetMarketType) {
            case "Location":
            case "LocationId":
                var locInfo = market.DisplayInfo;
                displayName = locInfo.LocationIdentifier + " - " + locInfo.Name + " - " + locInfo.City + ", " + locInfo.StateProvince;
                break;
            case "LocationCityState":
                var locCity = market.DisplayInfo;
                displayName = locCity.City + ", " + locCity.StateProvince;
                break;
            case "LocationState":
                var locState = market.DisplayInfo;
                displayName = locState.StateProvince + " (" + locState.StateFullName + ")";
                break;
            case "LocationCountry":
                var locCountry = market.DisplayInfo;
                displayName = locCountry.Country + " (" + locCountry.CountryFullName + ")";
                break;
            case "LocationPostalCode":
                var locPostal = market.DisplayInfo;
                displayName = locPostal.PostalCode;
                break;
            case "LocationSubGroup":
                var subGroupInfo = market.DisplayInfo;
                displayName = subGroupInfo.LocationGroupName + " - " + subGroupInfo.LocationSubGroupName;
                marketGrp.patchValue({
                    LocationGroupId: subGroupInfo.LocationGroupId,
                    LocationGroupName: subGroupInfo.LocationGroupName,
                    LocationSubGroupId: subGroupInfo.LocationSubGroupId,
                    LocationSubGroupName: subGroupInfo.LocationSubGroupName,
                });
                break;
            case "MarketGroup":
                var marketGroupInfo = market.DisplayInfo;
                displayName = marketGroupInfo.Name;
                break;
            case "FixtureGroup":
                var fgInfo = market.DisplayInfo;
                displayName = fgInfo.Name;
                break;
            case "Fixture":
                var fInfo = market.DisplayInfo;
                displayName = fInfo.Name;
                break;
            case "AllExceptClosed":
                displayName = this.translateService.instant("ALL_EXCEPT_CLOSED");
                break;
        }
        marketGrp.addControl("TypeaheadModalSelection", this.fb.control(displayName));
        marketGrp.addControl("MarketName", this.fb.control(displayName, [Validators.required, this.checkMarketName()]));
        this.addMarketListeners(marketGrp);
        return marketGrp;
    };
    MarketService.prototype.addMarketListeners = function (marketCtrl) {
        var _this = this;
        var marketNameCtrl = marketCtrl.get("MarketName");
        marketNameCtrl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
            if (!marketNameCtrl.valid) {
                marketCtrl.patchValue({ LocationCount: 0, LocationBalance: 0, TargetMarketId: 0, TargetMarketType: "" });
            }
        });
        //Add instant value change check for removing returns
        marketNameCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe(function (value) {
            _this.DisableReturn(marketNameCtrl, value);
        });
    };
    MarketService.prototype.DisableReturn = function (existingValue, newValue) {
        //Look for the new line and just set value back to its original value
        if (newValue && newValue.indexOf("\n") > -1) {
            existingValue.setValue(newValue.replace("\n", ""));
        }
    };
    //Provides list of markets based on search term. These markets will be appended to an empty object as the ValidationInput property when typeahead-on-select is called from the template
    MarketService.prototype.getTypeAheadMarkets = function (val, searchType) {
        var _this = this;
        if (searchType === void 0) { searchType = null; }
        var searchParams = { searchText: val, businessIdentity: this.activeProfileService.businessIdentity, chunkIndex: 0, recordCount: 20 };
        var markets = [];
        this.markets = [];
        if (searchType === "linkedprofile") {
            return this.locationGroupService.simpleSearch(searchParams).then(function (result) {
                var market = {};
                _this.updateMarketFromLocationGroupModel(result.ResultList, market);
                markets = markets.concat(_this.markets);
                if (markets.length < 20) {
                    searchParams.recordCount = 20 - markets.length;
                    return _this.fixtureGroupService.simpleSearch(searchParams).then(function (fixtureGroup) {
                        fixtureGroup.ResultList.forEach(function (item) {
                            var marketUI = {};
                            _this.updateMarketFromModel(item, marketUI, "FixtureGroup");
                            markets.push(marketUI);
                        });
                        return markets;
                    });
                }
                else {
                    return markets;
                }
            });
        }
        else {
            if (val.length > 1) {
                var searches = ["LocationPostalCode", "LocationId", "LocationCityState", "LocationSubGroup", "LocationCountry", "LocationState"];
                return this.performSearch(searchParams, markets, searches);
            }
            else {
                return Promise.resolve(markets);
            }
        }
    };
    MarketService.prototype.performSearch = function (searchParams, markets, searches) {
        var _this = this;
        if (searches.length === 0 || markets.length >= 20) {
            return Promise.resolve(markets);
        }
        this.markets = [];
        var search = searches.pop();
        searchParams.recordCount = 20 - markets.length;
        if (search === "LocationSubGroup") {
            return this.locationGroupService.search(searchParams, "LocationGroup/Flattened/Search")
                .then(function (feature) {
                var market = {};
                _this.updateMarketFromLocationGroupSubGroupModel(feature.ResultList, market);
                markets = markets.concat(_this.markets);
                return _this.performSearch(searchParams, markets, searches);
            });
        }
        else {
            searchParams.showUnassignedItems = true;
            return this.locationService.search(searchParams, "Location/AdvanceSimpleSearch")
                .then(function (feature) {
                feature.ResultList.forEach(function (item) {
                    var market = {};
                    _this.updateMarketFromTypeAHeadPull(item, market);
                    markets.push(market);
                });
                return Promise.resolve(markets);
            });
        }
    };
    MarketService.prototype.createMarketSource = function (marketCtrl) {
        var _this = this;
        var marketSource = Observable.create(function (observer) {
            // Runs on every search
            observer.next(marketCtrl.value.MarketName);
        }).pipe(debounceTime(365), distinctUntilChanged(), mergeMap(function (marketName) {
            return from(_this.getTypeAheadMarkets(marketName));
        }));
        return marketSource;
    };
    MarketService.prototype.createFilteredMarketSource = function (marketCtrl, fixtureGroupsInQuestion, featuresInQuestion, featuresInAudience, fixturesInQuestion, searchType) {
        var _this = this;
        if (searchType === void 0) { searchType = null; }
        var marketSource = Observable.create(function (observer) {
            // Runs on every search
            observer.next(marketCtrl.value.MarketName);
        }).pipe(debounceTime(365), distinctUntilChanged(), mergeMap(function (marketName) {
            return from(_this.getTypeAheadMarkets(marketName, searchType).then(function (markets) {
                // filter out in use markets.
                var filtered = markets.filter(function (loadedMarket) {
                    // If we are in an Audience/Market field:
                    //  - Don't show any LocationGroup or Subgroup used by a question (uses LocationGroup only)
                    //  - If another audience entry uses a LocationGroup, but a different subGroupId, allow it.
                    // If we are in a question:
                    //  - Don't allow a locationGroup used by another question or Audience/Market entry to be used
                    var loadedLocationGroupId = loadedMarket.TargetMarketType === "LocationGroup" ? loadedMarket.DisplayInfo.LocationGroupId : 0;
                    var loadedSubGroupId = loadedMarket.TargetMarketType === "LocationSubGroup" ? loadedMarket.TargetMarketId : 0;
                    var loadedFixtureGroupId = loadedMarket.TargetMarketType === "FixtureGroup" ? loadedMarket.TargetMarketId : 0;
                    var loadedFixtureId = loadedMarket.TargetMarketType === "Fixture" ? loadedMarket.TargetMarketId : 0;
                    var questionsHaveLocationGroupId = featuresInQuestion.some(function (y) { return y.Id === loadedLocationGroupId; });
                    var questionsHaveFixtureGroupId = fixtureGroupsInQuestion.some(function (f) { return f.Id === loadedFixtureGroupId; });
                    var questionsHaveFixtureId = fixturesInQuestion.some(function (f) { return f.Id === loadedFixtureId; });
                    var audienceHasMarket = featuresInAudience.some(function (x) {
                        // Need this hear to dis-allow a sub-group from showing if already in use in the same audience.
                        return (x.TargetMarketType === "LocationSubGroup" && x.TargetMarketId === loadedSubGroupId) ||
                            // This line is needed for when we are searching in a question, and we want to filter out an audience that is using that feature.
                            (x.TargetMarketType === "LocationSubGroup" && loadedMarket.TargetMarketType === "LocationGroup" && x.DisplayInfo.LocationGroupId === loadedLocationGroupId) ||
                            (x.TargetMarketType === "LocationGroup" && x.TargetMarketId === loadedLocationGroupId) ||
                            (x.TargetMarketType === "LocationId" && x.TargetMarketType === loadedMarket.TargetMarketType && x.TargetMarketId === loadedMarket.TargetMarketId) ||
                            (x.TargetMarketType !== "LocationSubGroup" && x.TargetMarketType !== "LocationGroup" && x.TargetMarketType !== "LocationId" && x.TargetMarketType === loadedMarket.TargetMarketType && x.MarketName === loadedMarket.DisplayName);
                    });
                    return !audienceHasMarket && !questionsHaveLocationGroupId && !questionsHaveFixtureGroupId && !questionsHaveFixtureId;
                });
                return filtered;
            }));
        }));
        return marketSource;
    };
    MarketService.prototype.checkMarketName = function () {
        return function (control) {
            if (control && control.value !== "" && control.parent) {
                //let market = control.parent.value as MarketEditUIModel;
                if (control.value !== control.parent.get("TypeaheadModalSelection").value) { // || !control.parent.get("TypeaheadModalSelection")
                    control.parent.get("TypeaheadModalSelection").setValue(null);
                    return { "invalidMarketName": true };
                }
                return null;
            }
            return null;
        };
    };
    MarketService.prototype.removeMarket = function (marketIndex, markets) {
        markets.removeAt(marketIndex);
        //Make sure there is at least one market input available to the user
        if (markets.length === 0) {
            markets.push(this.newMarketFormGroup(1));
        }
        // Update market ordinals.  Just start at beginning
        markets.controls.forEach(function (market, index) {
            market.patchValue({
                Ordinal: index + 1
            });
        });
        //If we remove first index then make sure the new index 0 has operation type of union
        if (marketIndex === 0) {
            markets.controls[0].get("OperationType").patchValue("Union");
        }
    };
    MarketService.prototype.updateMarketCtrl = function (item, market) {
        market.get("TypeaheadModalSelection").setValue(item.DisplayName);
        switch (item.TargetMarketType) {
            case "Location":
            case "LocationId":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationCityState":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationState":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationCountry":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationPostalCode":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: item.TargetAttributeValue,
                    LocationCount: item.LocationCount,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "LocationSubGroup":
                var subGroupInfo = item.DisplayInfo;
                market.patchValue({
                    MarketName: item.DisplayName,
                    LocationCount: item.LocationCount,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: undefined,
                    LocationGroupId: subGroupInfo ? subGroupInfo.LocationGroupId : undefined,
                    LocationGroupName: subGroupInfo ? subGroupInfo.LocationGroupName : undefined,
                    LocationSubGroupId: subGroupInfo ? subGroupInfo.LocationSubGroupId : undefined,
                    LocationSubGroupName: subGroupInfo ? subGroupInfo.LocationSubGroupName : undefined,
                }, { emitEvent: true });
                break;
            case "LocationGroup":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: undefined,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
            case "FixtureGroup":
            case "Fixture":
            case "MarketGroup":
            case "AllExceptClosed":
                market.patchValue({
                    MarketName: item.DisplayName,
                    TargetMarketId: item.TargetMarketId,
                    TargetMarketType: item.TargetMarketType,
                    TargetAttributeValue: undefined,
                    LocationGroupId: undefined,
                    LocationGroupName: undefined,
                    LocationSubGroupId: undefined,
                    LocationSubGroupName: undefined
                }, { emitEvent: true });
                break;
        }
        //revalidate market name
        market.get("MarketName").updateValueAndValidity();
        market.markAsDirty();
    };
    /**
     * Update the given market formArray with the passed in markets list.
     * @param marketArray - FormArray of market controls to update
     * @param markets - Markets to make the formArray match
     * @param marketDictionary - Dictionary of markets by ordinal. If empty, will be filled in.
     */
    MarketService.prototype.updateMarkets = function (marketArray, markets, marketDictionary) {
        var _loop_1 = function (j) {
            var marketCtrl = marketArray.at(j);
            var marketObj = marketDictionary[marketCtrl.value.Ordinal];
            if (marketDictionary.length === 0) {
                marketObj = markets[j];
            }
            if (!marketObj) {
                marketObj = markets.filter(function (market) { return market.Ordinal === marketCtrl.value.Ordinal; })[0];
                if (marketObj) {
                    marketDictionary[marketCtrl.value.Ordinal] = marketObj;
                }
            }
            if (marketObj) {
                marketCtrl.patchValue({
                    LocationCount: marketObj.LocationCount,
                    LocationBalance: marketObj.LocationBalance,
                    PendingLocationCount: marketObj.PendingLocationCount
                });
            }
        };
        for (var j = 0; j < marketArray.length; j++) {
            _loop_1(j);
        }
    };
    MarketService.prototype.showMarketSelectionModal = function (market, featuresInAudience, featuresInQuestion, showInUseFeatures, disableByFeature, requestedMarketType, fixtureGroupsInQuestion, fixturesInQuestion, isSingleSelect) {
        var _this = this;
        if (showInUseFeatures === void 0) { showInUseFeatures = false; }
        if (disableByFeature === void 0) { disableByFeature = true; }
        if (isSingleSelect === void 0) { isSingleSelect = false; }
        var promise;
        this.featureInAudience = featuresInAudience;
        if (market.TargetMarketType === null || market.TargetMarketType === undefined) {
            market.TargetMarketType = requestedMarketType;
        }
        // Currently LocationGroup/FixtureGroup marketType are only used by survey questions/LinkedProfile.
        // Normal audiences/markets use LocationSubGroup marketType only.
        if (requestedMarketType === "LocationGroup" || requestedMarketType === "FixtureGroup" || requestedMarketType === "Fixture") {
            // Show Feature (aka LocationGroup) / FixtureGroup selection modal.
            //requestedMarketType = "FixtureGroup"
            //tslint:disable-next-line: max-line-length
            promise = this.ipsModal.displayTemplateScrollable(LinkedFeatureSearchModalComponent, { featuresInAudience: featuresInAudience, featuresInQuestion: featuresInQuestion, fixtureGroupsInQuestion: fixtureGroupsInQuestion, fixturesInQuestion: fixturesInQuestion, requestedMarketType: requestedMarketType, isSingleSelect: isSingleSelect });
        }
        else {
            // show in use features in subGroup selection modal
            if (requestedMarketType === "LocationSubGroup" || requestedMarketType === "MarketGroup") {
                var requestedOrdinal = market.Ordinal;
                promise = this.ipsModal.displayTemplateScrollable(MarketSearchModalComponent, {
                    resolve: {
                        showInUseFeatures: showInUseFeatures, featuresInAudience: featuresInAudience, disableByFeature: disableByFeature,
                        featuresInQuestion: featuresInQuestion, requestedMarketType: requestedMarketType, requestedOrdinal: requestedOrdinal
                    }
                });
            }
            else {
                promise = this.ipsModal.displayTemplateScrollable(LocationAttributeSearchModalComponent, {
                    resolve: {
                        showInUseFeatures: showInUseFeatures, featuresInAudience: featuresInAudience
                    }
                });
            }
        }
        return promise
            .then(function (response) {
            _this.markets = [];
            switch (response.ResultType) {
                case "Location":
                case "LocationCityState":
                case "LocationState":
                case "LocationId":
                case "LocationCountry":
                case "LocationPostalCode":
                    var location_1 = response.Result;
                    _this.updateMarketFromLocationsModel(location_1, market, response.ResultType);
                    break;
                case "LocationSubGroup":
                    var locGroupSubGroup = response.Result;
                    _this.updateMarketFromLocationGroupSubGroupModel(locGroupSubGroup, market);
                    break;
                case "LocationGroup":
                    var locGroup = response.Result;
                    _this.updateMarketFromLocationGroupModel(locGroup, market);
                    break;
                case "MarketGroup":
                    var marketGroup = response.Result;
                    _this.updateMarketsFromModel(marketGroup, market, response.ResultType);
                    break;
                case "FixtureGroup":
                    var fixtureGroup = response.Result;
                    _this.updateMarketsFromModel(fixtureGroup, market, response.ResultType);
                    break;
                //TODOL update for fixture
                case "Fixture":
                    var fixture = response.Result;
                    _this.updateMarketsFromModel(fixture, market, response.ResultType);
                    break;
            }
            return _this.markets;
        });
    };
    MarketService.prototype.updateMarketFromModel = function (model, market, marketType) {
        market.TargetMarketId = model.Id;
        market.TargetMarketType = marketType;
        market.DisplayName = model.Name;
        market.DisplayInfo = {
            Id: model.Id,
            Name: model.Name,
            OptionType: marketType === "FixtureGroup" || marketType === "Fixture" ? "Number" : undefined
        };
        var marketPrefix = this.getMarketPrefix(market.TargetMarketType);
        market.DisplayNameWithPrefix = "" + marketPrefix + market.DisplayName;
    };
    MarketService.prototype.updateMarketsFromModel = function (models, prevmarket, marketType) {
        var _this = this;
        var marketLength = this.featureInAudience.length;
        if (marketLength === 1) {
            if (this.featureInAudience[0].TargetMarketType === null || this.featureInAudience[0].TargetMarketType === "") {
                marketLength = marketLength - 1;
            }
        }
        models.forEach(function (model) {
            if (model.Disabled) {
                var market = Object.assign({}, prevmarket);
                market.Disabled = true;
                market.Index = model.Index;
                _this.markets.push(market);
            }
            else {
                var market = _this.newMarketFormGroup(++marketLength).value;
                market.TargetMarketId = model.Id;
                market.TargetMarketType = marketType;
                market.DisplayName = model.Name;
                market.DisplayInfo = {
                    Id: model.Id,
                    Name: model.Name,
                    OptionType: marketType === "FixtureGroup" || marketType === "Fixture" ? "Number" : undefined
                };
                var marketPrefix = _this.getMarketPrefix(market.TargetMarketType);
                market.DisplayNameWithPrefix = "" + marketPrefix + market.DisplayName;
                _this.markets.push(market);
            }
        });
    };
    MarketService.prototype.updateMarketFromLocationsModel = function (locations, prevmarket, searchAttribute) {
        var _this = this;
        var marketLength = this.featureInAudience.length;
        if (marketLength === 1) {
            if (this.featureInAudience[0].TargetMarketType === null || this.featureInAudience[0].TargetMarketType === "") {
                marketLength = marketLength - 1;
            }
        }
        locations.forEach(function (location) {
            if (location.Disabled) {
                var market = Object.assign({}, prevmarket);
                market.Disabled = true;
                market.Index = location.Index;
                _this.markets.push(market);
            }
            else {
                var market = _this.newMarketFormGroup(++marketLength).value;
                searchAttribute = location.SelectedLocationAttribute;
                market.TargetMarketId = location.Id;
                market.TargetMarketType = searchAttribute;
                market.LocationCount = location.LocationCount;
                switch (searchAttribute) {
                    case "LocationCityState":
                        market.DisplayName = location.City + ", " + location.StateProvince;
                        market.TargetAttributeValue = location.City + "," + location.StateProvince;
                        break;
                    case "LocationState":
                        market.DisplayName = location.StateProvince + " (" + location.StateFullName + ")";
                        market.TargetAttributeValue = location.StateProvince + "," + location.Country;
                        break;
                    case "LocationId":
                        market.DisplayName = location.LocationIdentifier + " - " + location.Name + " - " + location.City + ", " + location.StateProvince;
                        market.TargetAttributeValue = "";
                        break;
                    case "LocationCountry":
                        market.DisplayName = location.Country + " (" + location.CountryFullName + ")";
                        market.TargetAttributeValue = location.Country;
                        break;
                    case "LocationPostalCode":
                        market.DisplayName = location.PostalCode;
                        market.TargetAttributeValue = location.PostalCode;
                        break;
                }
                market.DisplayInfo = {
                    Id: location.Id,
                    Name: location.Name,
                    LocationIdentifier: location.LocationIdentifier,
                    City: location.City,
                    StateProvince: location.StateProvince,
                    StateFullName: location.StateFullName,
                    Country: location.Country,
                    CountryFullName: location.CountryFullName,
                    PostalCode: location.PostalCode
                };
                var marketPrefix = _this.getMarketPrefix(market.TargetMarketType);
                market.DisplayNameWithPrefix = "" + marketPrefix + market.DisplayName;
                _this.markets.push(market);
            }
        });
    };
    MarketService.prototype.updateMarketFromLocationGroupSubGroupModel = function (locGroupSubGroups, prevmarket) {
        var _this = this;
        var marketLength = this.featureInAudience.length;
        if (marketLength === 1) {
            if (this.featureInAudience[0].TargetMarketType === null || this.featureInAudience[0].TargetMarketType === "") {
                marketLength = marketLength - 1;
            }
        }
        locGroupSubGroups.forEach(function (locGroupSubGroup) {
            if (locGroupSubGroup.Disabled) {
                var market = Object.assign({}, prevmarket);
                market.Disabled = true;
                market.Index = locGroupSubGroup.Index;
                _this.markets.push(market);
            }
            else {
                var market = _this.newMarketFormGroup(++marketLength).value;
                market.TargetMarketId = locGroupSubGroup.LocationSubGroupId;
                market.DisplayName = locGroupSubGroup.LocationGroupName + " - " + locGroupSubGroup.LocationSubGroupName;
                market.TargetMarketType = "LocationSubGroup";
                market.LocationCount = locGroupSubGroup.LocationCount;
                market.DisplayInfo = {
                    LocationGroupId: locGroupSubGroup.LocationGroupId,
                    LocationGroupName: locGroupSubGroup.LocationGroupName,
                    LocationSubGroupId: locGroupSubGroup.LocationSubGroupId,
                    LocationSubGroupName: locGroupSubGroup.LocationSubGroupName
                };
                var marketPrefix = _this.getMarketPrefix(market.TargetMarketType);
                market.DisplayNameWithPrefix = "" + marketPrefix + market.DisplayName;
                _this.markets.push(market);
            }
        });
    };
    MarketService.prototype.updateMarketFromLocationGroupModel = function (locGroups, prevmarket) {
        var _this = this;
        var marketLength = this.featureInAudience.length;
        if (marketLength === 1) {
            if (this.featureInAudience[0].TargetMarketType === null || this.featureInAudience[0].TargetMarketType === "") {
                marketLength = marketLength - 1;
            }
        }
        locGroups.forEach(function (locGroup) {
            if (locGroup.Disabled) {
                var market = Object.assign({}, prevmarket);
                market.Disabled = true;
                market.Index = locGroup.Index;
                _this.markets.push(market);
            }
            else {
                var market = _this.newMarketFormGroup(++marketLength).value;
                market.TargetMarketId = locGroup.Id;
                market.DisplayName = locGroup.Name;
                market.TargetMarketType = "LocationGroup";
                market.DisplayInfo = {
                    LocationGroupId: locGroup.Id,
                    LocationGroupName: locGroup.Name,
                    OptionType: locGroup.OptionType
                };
                var marketPrefix = _this.getMarketPrefix(market.TargetMarketType);
                market.DisplayNameWithPrefix = "" + marketPrefix + market.DisplayName;
                _this.markets.push(market);
            }
        });
    };
    MarketService.prototype.getMarketPrefix = function (marketType) {
        var result = "";
        switch (marketType) {
            case "LocationCityState":
                result = this.translateService.instant("LOCATION_CITY");
                break;
            case "LocationCountry":
                result = this.translateService.instant("LOCATION_COUNTRY");
                break;
            case "Location":
            case "LocationId":
                result = this.translateService.instant("LOCATION_ID");
                break;
            case "LocationPostalCode":
                result = this.translateService.instant("LOCATION_POSTAL_CODE");
                break;
            case "LocationState":
                result = this.translateService.instant("LOCATION_STATE");
                break;
            case "LocationGroup":
            case "LocationSubGroup":
                result = this.translateService.instant("LOCATION_FEATURE");
                break;
            case "MarketGroup":
                result = this.translateService.instant("LOCATION_SEARCH");
                break;
            case "FixtureGroup":
                result = this.translateService.instant("FIXTURE_GROUP");
                break;
            case "Fixture":
                result = this.translateService.instant("FIXTURE");
                break;
        }
        return result ? "[" + result + "] - " : result;
    };
    MarketService.prototype.updateMarketFromTypeAHeadPull = function (model, market) {
        market.TargetMarketId = model.TargetMarketId;
        market.TargetMarketType = model.MarketType;
        market.TargetAttributeValue = model.TargetAttribute;
        market.LocationCount = model.LocationCount;
        market.DisplayName = model.Label;
        var marketPrefix = this.getMarketPrefix(market.TargetMarketType);
        market.DisplayNameWithPrefix = "" + marketPrefix + market.DisplayName;
    };
    MarketService.prototype.updateMarketFromLocationAll = function (market) {
        market.TargetMarketId = 0;
        market.TargetMarketType = "AllExceptClosed";
        market.LocationCount = 0;
        market.DisplayName = this.translateService.instant("ALL_EXCEPT_CLOSED");
        market.DisplayInfo = {
            Id: 0,
            Name: this.translateService.instant("ALL_EXCEPT_CLOSED")
        };
        var marketPrefix = this.getMarketPrefix(market.TargetMarketType);
        market.DisplayNameWithPrefix = "" + marketPrefix + market.DisplayName;
    };
    return MarketService;
}());
export { MarketService };
