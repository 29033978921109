import { OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { LocationService, LocationModel, ValidationStatus } from "../../../imagine-ui-ng-store-profile";
import { LocationWithPaymentModel, AllowPayment } from "../../index";
var CartLocationListModalComponent = /** @class */ (function () {
    function CartLocationListModalComponent(locationService, translateService, modalInstance) {
        this.locationService = locationService;
        this.translateService = translateService;
        this.modalInstance = modalInstance;
        this.pluralMapping = {};
        this.filter = "";
    }
    // ----- FUNCTIONS -----
    //
    CartLocationListModalComponent.prototype.translateText = function () {
        var _this = this;
        this.translateService.get([
            "FOUND_OF", "TOTAL", "SELECT_LOCATIONS", "CANCEL", "LOCATION", "LOCATIONS",
        ]).subscribe(function (res) {
            _this.pluralMapping["=1"] = _this.translateService.instant("LOCATION");
            _this.pluralMapping["other"] = _this.translateService.instant("LOCATIONS");
        });
    };
    //
    CartLocationListModalComponent.prototype.locationClicked = function (location) {
        //Now check to see if selection has a allowable payment method
        switch (this.allowPayment) {
            case "Both":
                if (!location.CreditCard && !location.Invoice) {
                    return;
                }
                if (location.CreditCard && !location.Invoice) {
                    this.allowPayment = "Credit";
                }
                else if (!location.CreditCard && location.Invoice) {
                    this.allowPayment = "Invoice";
                }
                break;
            case "Credit":
                if (!location.CreditCard) {
                    return;
                }
                break;
            case "Invoice":
                if (!location.Invoice) {
                    return;
                }
                break;
        }
        location.IsSelected = !location.IsSelected;
        var tempIndex = this.allLocations.findIndex(function (element) {
            return element.Id === location.Id;
        });
        if (tempIndex !== -1) {
            this.allLocations[tempIndex].IsSelected = location.IsSelected;
        }
        this.totalSelected = this.allLocations.filter(function (x) { return x.IsSelected; }).length;
    };
    //
    CartLocationListModalComponent.prototype.selectAll = function (allowPayment) {
        this.allowPayment = allowPayment;
        for (var i = 0; i < this.allLocations.length; i++) {
            if (allowPayment === "Credit" && this.allLocations[i].CreditCard) {
                this.allLocations[i].IsSelected = true;
            }
            else if (allowPayment === "Invoice" && this.allLocations[i].Invoice) {
                this.allLocations[i].IsSelected = true;
            }
            else if (allowPayment === "Both") {
                this.allLocations[i].IsSelected = true;
            }
            else {
                this.allLocations[i].IsSelected = false;
            }
        }
        this.totalSelected = this.allLocations.filter(function (x) { return x.IsSelected; }).length;
        this.filterUpdated();
    };
    //
    CartLocationListModalComponent.prototype.deselectAll = function () {
        for (var i = 0; i < this.allLocations.length; i++) {
            this.allLocations[i].IsSelected = false;
        }
        this.totalSelected = 0;
        this.filterUpdated();
    };
    //
    CartLocationListModalComponent.prototype.getLocationAddresses = function () {
        var _this = this;
        var promise = new Promise(function (resolve, reject) {
            if (_this.displayAddresses) {
                _this.locationService.searchLocationsByIds(_this.allLocations.map(function (x) { return x.Id; }))
                    .then(function (locationSearchResponse) {
                    var _loop_1 = function (i) {
                        var tempIndex = _this.allLocations.findIndex(function (x) { return x.Id === locationSearchResponse.ResultList[i].Id; });
                        if (tempIndex !== -1) {
                            _this.allLocations[tempIndex].AddressData =
                                locationSearchResponse.ResultList[i].Addresses[0];
                            _this.allLocations[tempIndex].Label =
                                _this.allLocations[tempIndex].AddressData["Line1"] + " " +
                                    _this.allLocations[tempIndex].AddressData["City"] + ", " +
                                    _this.allLocations[tempIndex].AddressData["StateProvince"] + " " +
                                    _this.allLocations[tempIndex].AddressData["Country"] + " " +
                                    _this.allLocations[tempIndex].AddressData["PostalCode"];
                        }
                    };
                    for (var i = 0; i < locationSearchResponse.ResultList.length; i++) {
                        _loop_1(i);
                    }
                    resolve(true);
                });
            }
            else {
                resolve(false);
            }
        });
        return promise;
    };
    //
    CartLocationListModalComponent.prototype.close = function (closeMessage) {
        this.modalInstance.dismiss(closeMessage);
    };
    //
    CartLocationListModalComponent.prototype.returnResults = function () {
        var ret = this.allLocations.filter(function (x) { return x.IsSelected; }).map(function (x) { return x.Id; });
        this.modalInstance.close({ Locations: ret, PaymentMethod: this.allowPayment });
    };
    //
    CartLocationListModalComponent.prototype.filterUpdated = function () {
        this.availableLocations = [];
        for (var i = 0; i < this.allLocations.length; i++) {
            if (this.allLocations[i].Label.toUpperCase().indexOf(this.filter.toUpperCase()) >= 0) {
                this.availableLocations.push(this.allLocations[i]);
            }
        }
    };
    CartLocationListModalComponent.prototype.disabledFilter = function (location) {
        return (this.allowPayment !== "Both") && (location.Invoice && this.allowPayment === "Credit" && !location.CreditCard) || (location.CreditCard && this.allowPayment === "Invoice" && !location.Invoice);
    };
    // ----- ON INIT -----
    //
    CartLocationListModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translateText();
        this.translateService.onLangChange.subscribe(function () { return _this.translateText(); });
        this.allLocations = [];
        var totalSelected = 0;
        for (var i = 0; i < this.cartLocations.length; i++) {
            var selected = (this.currentSelections.indexOf(this.cartLocations[i].LocationId) !== -1);
            this.allLocations.push({
                Id: this.cartLocations[i].LocationId,
                Label: this.cartLocations[i].Label,
                IsSelected: selected,
                Invoice: this.cartLocations[i].Invoice,
                CreditCard: this.cartLocations[i].CreditCard,
                ValidationStatus: this.cartLocations[i].ValidationStatus
            });
            if (selected) {
                totalSelected++;
            }
        }
        this.allLocations.sort(function (a, b) { return a.Id - b.Id; });
        this.getLocationAddresses()
            .then(function () {
            _this.filterUpdated();
            _this.total = _this.allLocations.length;
            _this.totalSelected = totalSelected;
        });
        if (!!this.cartLocations.find(function (cl) { return cl.CreditCard; })) {
            this.showCreditButton = true;
        }
        if (!!this.cartLocations.find(function (cl) { return cl.Invoice; })) {
            this.showInvoiceButton = true;
        }
    };
    return CartLocationListModalComponent;
}());
export { CartLocationListModalComponent };
