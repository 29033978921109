import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { IpsImageEditComponent } from "imagine-ui-ng-quick-start";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { SurveyService, SurveyMessageWithImageModel } from "../../index";
import { SurveyMessageModel } from "../../model/SurveyMessageModel";
import { ActiveProfileService, MediaType, MediaReferenceModel } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { String } from "typescript-string-operations";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";

@Component({
  selector: "app-my-survey-message",
  templateUrl: "./my-survey-message.component.html",
  styleUrls: ["./my-survey-message.component.scss"]
})
export class MySurveyMessageComponent implements OnInit {
    @ViewChild(IpsImageEditComponent) private imageEdit: IpsImageEditComponent;
    @Input() SurveyId: number;
    @Input() SurveyQuestionId: number;
    @Input() LocationId: number;
    @Input() MySurveyMessage: SurveyMessageWithImageModel;
    @Input() parentForm: FormGroup;

    @Output() RemoveMessage = new EventEmitter<void>();
    @Output() FormDirtyChanged = new EventEmitter<void>();

    public messageForm: FormGroup;
    public files: any;
    public fullSizeImage: string;
    public defaultImage = "";
    public imageSelected = false;
    private emptyGuid = "00000000-0000-0000-0000-000000000000";

    private errorMessages = {
        "maxlength": (params) => String.Format(this.translateService.instant("MAX_LENGTH_ERROR"), params.requiredLength)
    };

    public getErrorMessages(key: string) {
        let msgs = Object.assign({}, this.errorMessages);
        return msgs;
    }

    constructor(private ipsMessage: IpsMessageService, private surveyService: SurveyService,
        private activeProfileService: ActiveProfileService,
        private authService: AuthService, private translateService: TranslateService) { }

    ngOnInit() {

        let id = 0;
        let message = "";
        let masterMediaId: string = this.emptyGuid;
        let mediaType: MediaType = "Unknown";

        if (this.MySurveyMessage) {
            id = this.MySurveyMessage.Id;
            message = this.MySurveyMessage.Text;
            masterMediaId = this.MySurveyMessage.MasterMediaId;
            mediaType = this.MySurveyMessage.MediaType;
            this.populateThumbnail(this.MySurveyMessage.Media);
        }

        this.messageForm = new FormGroup({
            id: new FormControl(id),
            message: new FormControl(message, [Validators.maxLength(500)]),
            masterMediaId: new FormControl(masterMediaId),
            mediaType: new FormControl(mediaType)
        });

        if (!this.parentForm.get("Message")) {
            this.parentForm.addControl("Message", this.messageForm);
        }

        this.onChanges();
    }

    public onSelectedImageChanged(files: any) {
        this.imageSelected = true;
        this.files = files;
        if (!this.files) {
            // Get the placeholder image and set it
            this.imageEdit.thumbnail = this.defaultImage;
            this.imageEdit.selectedImage = false;
            this.messageForm.patchValue({
                masterMediaId: this.emptyGuid,
                mediaType: 0
            });
            this.imageSelected = false;
        }
        this.messageForm.markAsDirty();
        this.FormDirtyChanged.emit();
    }

    public messageRemovePrompt() {
        this.ipsMessage.confirmDelete({
            title: "WARNING",
            body: "PROMPT_DELETE_SURVEY_MESSAGE",
            ok: "DELETE",
            cancel: "KEEP",
            workFunction: () => this.messageRemove(), progressMessage: "DELETING"
        }).then(() => {
            this.RemoveMessage.emit();
            this.markMessageFormPristine();
        })
        .catch(() => {
            // rejection
        });
    }

    private messageRemove() {
        let id = this.messageForm.value.id;
        if (id > 0) {
            return this.surveyService.deleteMessage(id).then(() => {
                this.RemoveMessage.emit();
                this.markMessageFormPristine();

            });
        }
        return Promise.resolve(true);
    }

    public saveMessagePrompt() {
        return this.ipsMessage.waitForWork({
            body: "SAVING",
            workFunction: () => this.saveMessage(),
            progressMessage: "SAVING"
        }, null);
    }

    private saveMessage() {
        let message = this.messageForm.value;

        //delete record from surveyMessage table when message text is empty and no image
        if ((message.message === "" || message.message === undefined) && !this.files) {
            return this.messageRemove();
        } else { //save message
            let surveyMessage: SurveyMessageModel = <SurveyMessageModel>{
                MasterMediaId: message.masterMediaId,
                MediaType: message.mediaType,
                Id: message.id,
                LocationId: this.LocationId,
                SurveyId: this.SurveyId,
                SurveyQuestionId: this.SurveyQuestionId,
                Text: message.message,
                BusinessIdentity: this.activeProfileService.businessIdentity,
                UserName: this.activeProfileService.profile.UserName,
            };

            if (surveyMessage.Id === 0) {
                return this.surveyService.postMessage(surveyMessage, this.files).then((response) => {
                    this.messageForm.patchValue({
                        id: response.Id,
                        masterMediaId: response.MasterMediaId,
                        mediaType: response.MediaType
                    });
                    this.markMessageFormPristine();
                });
            } else {
                return this.surveyService.putMessage(surveyMessage, this.files).then((response) => {
                    this.messageForm.patchValue({
                        masterMediaId: response.MasterMediaId,
                        mediaType: response.MediaType
                    });
                    this.markMessageFormPristine();
                });
            }
        }
    }

    private markMessageFormPristine() {
        const isParentDirty = this.parentForm.get("IsDirty").value;
        this.messageForm.markAsPristine();
        if (isParentDirty) {
            this.parentForm.markAsDirty();
        }
        this.FormDirtyChanged.emit();
    }

    private populateThumbnail(Media: MediaReferenceModel) {
        if (Media) {
            this.fullSizeImage =
                `${Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity
                }&idToken=${this.authService.getIdToken()}`;
            this.imageEdit.thumbnail = this.fullSizeImage;
            this.imageSelected = true;
        } else {
            this.fullSizeImage = undefined;
            this.imageEdit.thumbnail = undefined;
        }
        //Set text of image button
        this.imageEdit.selectedImage = this.imageEdit.thumbnail !== undefined;
    }

    private onChanges(): void {
        this.messageForm.get("message").valueChanges.pipe(
            debounceTime(350),
            distinctUntilChanged()).subscribe(val => {
                this.messageForm.markAsDirty();
            this.FormDirtyChanged.emit();
        });
    }
}
