import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transition, StateService } from "@uirouter/core";
import { ActiveProfileService, QueryParamItem, ListSearchHelper, SearchInfo } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { IpsMessageService } from "imagine-ui-ng-messaging";
import { IpsModalService } from "imagine-ui-ng-modal";
import { ListSearchComponent, ResultDetailComponent, TranslatedTexts } from "imagine-ui-ng-list-search";

import { FixtureService, LocationByFixtureService, FixtureByLocationSumService, LocationModel, SpaceModel, FixtureModel, LocationFixtureModel, LocationByFixtureModel } from "../../index";
import { GeneralSettingsService } from "../../../shared/service/general-settings.service";
import { SignPlanHolderModel } from "../../model/SignPlanModel";

interface ILocationByFixtureUIModel extends LocationByFixtureModel {
    LocationLabel: string;
    FixtureCountLabel: string;
}

@Component({
  selector: "app-fixture-view",
  templateUrl: "./fixture-view.component.html",
  styleUrls: ["./fixture-view.component.scss"]
})
export class FixtureViewComponent implements OnInit {
    public myFixture: FixtureModel;
    private dataService: FixtureService;
    private locationDataService: LocationByFixtureService;
    private location: ListSearchHelper;
    private previousId: number;
    private previousFilterOption: any;

    public fullImage: string;
    public fullLayoutImage: string;
    public thumbnail: string;
    public relatedLocationsBusy: boolean;
    public qrLocation: ListSearchHelper;
    public qrLocationSearch: string;
    public optionList;
    public selectedOption = { value: "false" };
    public loaded: boolean;
    public promise: Promise<any>;
    public query = "";
    public translatedTexts: TranslatedTexts;
    private emptyGuid = "00000000-0000-0000-0000-000000000000";
    public showVeracoreOfferId = false;
    public enableSignPlanLayout = false;
    private placedHolders: SignPlanHolderModel[];
    private attachedDocuments: any[] = [];

    private signPlanWidthOptions = [
        {
            key: "1/6",
            value: 2
        },
        {
            key: "1/4",
            value: 3
        },
        {
            key: "1/3",
            value: 4
        },
        {
            key: "1/2",
            value: 6
        },
        {
            key: "2/3",
            value: 8
        },
        {
            key: "3/4",
            value: 9
        },
        {
            key: "5/6",
            value: 10
        },
        {
            key: "Full",
            value: 12
        }
    ];

    constructor(private transition: Transition, private $state: StateService, private translateService: TranslateService, private ipsModal: IpsModalService, fixtureService: FixtureService, locationByFixtureService: LocationByFixtureService, listSearchHelper: ListSearchHelper
        , private activeProfileService: ActiveProfileService, private authService: AuthService, private settingsService: GeneralSettingsService) {
        this.dataService = fixtureService;
        this.locationDataService = locationByFixtureService;
        this.location = listSearchHelper.getListSearchHelper(locationByFixtureService);
        this.qrLocation = this.location;
        this.placedHolders = [];
    }


    ngOnInit() {
        let fixtureId = this.transition.params().id;
        this.relatedLocationsBusy = this.location.busy;

        this.myFixture = <FixtureModel>{
            Id: fixtureId,
            Media: null,
            Holders: []
        };

        this.getSignPlanLayoutSetting().then(() => {
            // If we got an ID to load, load it.
            if (fixtureId > 0) {
                //Initial call to populate screen on load
                this.getFixture(Number(fixtureId), this.selectedOption).then(() => {
                    let fixturePromises: Promise<any>[] = [];
                    if (this.enableSignPlanLayout) {
                        fixturePromises.push(this.dataService.getLayoutDetails(fixtureId).then((holderLayoutData) => {
                            this.placedHolders = holderLayoutData.map((item) => {
                                item.Placed = true;
                                return item;
                            });
                        }));

                        fixturePromises.push(this.getAttachedDocuments());

                        Promise.all(fixturePromises).then(() => {
                            this.loaded = true;
                        });
                    } else {
                        this.loaded = true;
                    }
                });
            }


            this.translatedTexts = { foundOf: "found of", total: "total", loadMoreResults: "load more results" };
            this.translateText();
            this.translateService.onLangChange.subscribe(() => this.translateText());

            //show veracore offer id only for Southeasten grocers = Bsns.00000144
            this.showVeracoreOfferId = this.activeProfileService.businessIdentity === "Bsns.00000144";
        });
    }

    getWidthLabel(value: number): string {
        return this.signPlanWidthOptions.find(option => option.value === value).key;
    }

    attachmentUrl(document: any): string {
        return this.dataService.getAttachmentMediaUrl(document.MasterMediaId);
    }

    private getAttachedDocuments(): Promise<any> {
        return this.dataService.getAttachedDocuments(this.myFixture.Id).then((response) => {
            this.attachedDocuments = response;
        });
    }

    private getSignPlanLayoutSetting(): Promise<any> {
        return this.settingsService.canEditSignPlanLayout().then((response: boolean) => {
            this.enableSignPlanLayout = response;
        });
    }

    private translateText() {
        this.translateService.get(["FOUND_OF", "TOTAL", "LOAD_MORE_RESULTS", "CREATE_NEW_SPACE", "ASSIGNED_LOCATIONS", "UNASSIGNED_LOCATIONS"]).subscribe((res: [string]) => {
            this.translatedTexts.foundOf = res["FOUND_OF"];
            this.translatedTexts.total = res["TOTAL"];
            this.translatedTexts.loadMoreResults = res["LOAD_MORE_RESULTS"];

            this.optionList = [{ text: res["ASSIGNED_LOCATIONS"], value: "false" }, { text: res["UNASSIGNED_LOCATIONS"], value: "true" }];
        });
    }

    private getFixture(id: number, filterOption: any) {
        this.loaded = false;
        this.promise = this.dataService.get<FixtureModel>(id).then((response) => {
            Object.assign(this.myFixture, response);
            // Populate quick referenc by location
            this.getLocationList("", id, filterOption);
            if (this.myFixture.Media && this.myFixture.MasterMediaId !== this.emptyGuid) {
                this.fullImage =
                    `${this.myFixture.Media.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity
                    }&idToken=${this.authService.getIdToken()}`;
                this.thumbnail = this.fullImage.replace("Original", "Thumbnail");

            }

            if (this.myFixture.LayoutMedia && this.myFixture.LayoutImageMasterMediaId !== this.emptyGuid) {
                this.fullLayoutImage =
                    `${this.myFixture.LayoutMedia.ResourceUri}?BusinessIdentity=${this.activeProfileService.businessIdentity
                    }&idToken=${this.authService.getIdToken()}`;
            }
        });

        return this.promise;
    }

    public getLocations(changes) {
        this.getLocationList(changes.search, this.myFixture.Id, changes.filterOption);
    }

    private getLocationList(search: string, id: number, filterOption: any) {
        if (!search) {
            search = "";
        }

        if (this.previousId !== id || this.previousFilterOption !== filterOption.value) {
            this.previousFilterOption = filterOption.value;
            this.previousId = id;
            this.locationDataService.headByFixture(id, filterOption.value).then((count) => {
                this.location.TotalRecords = count;
            });
        }
        let searchInfo = { searchText: search, id: id, showUnassignedItems: filterOption.value };
        this.qrLocation.promise = this.location.searchHelper(searchInfo);

        //Add label for search grid
        this.qrLocation.promise.then(() => {
            this.qrLocation.resultList.forEach(function(item: ILocationByFixtureUIModel) {
                if (item.FixtureCount > 0) {
                    item.FixtureCountLabel = item.FixtureCount.toString();
                }
                item.LocationLabel = `${item.LocationIdentifier} - ${item.Name} - ${item.Addresses[0].City}, ${item.Addresses[0].StateProvince}`;
            });
        });
    }

    public getMoreListData() {
        this.getLocationList(this.query, this.myFixture.Id, this.selectedOption);
    }

    public showCountLabel(countLabel: string): boolean {
        let count = parseInt(countLabel, 10);
        if (!isNaN(count) && count > 0) {
            return true;
        } else {
            return false;
        }
    }
}
