import { Component, OnInit } from "@angular/core";
import { String as IpsString } from "typescript-string-operations";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { FieldControlBaseComponent } from "../field-control-base";

@Component({
  selector: "app-ips-email",
  templateUrl: "./ips-email.component.html",
  styleUrls: ["./ips-email.component.scss"]
})
export class IpsEmailComponent extends FieldControlBaseComponent implements OnInit {

    public TranslateStrings: { [key: string]: string } = {
        "PLEASE_ENTER_DYNAMIC_NAME": "",
        "ENTER_DYNAMIC_NAME": "",
        "INVALID_EMAIL": ""
    };

    public errorMessages = {
        "required": () => IpsString.Format(this.TranslateStrings["PLEASE_ENTER_DYNAMIC_NAME"], this.name),
        "email": () => this.TranslateStrings["INVALID_EMAIL"]
    };


    ngOnInit() {
        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(() => this.TranslateText());

        let validators = [];

        if (this.isFieldRequired) {
            validators.push(Validators.required);
        }

        validators.push(Validators.email);

        this.control = new FormControl(typeof (this.value) === "object" ? this.value.Text : this.value, validators);
        this.control.valueChanges.pipe(distinctUntilChanged(), debounceTime(350)).subscribe((value) => {
            let val = this.GetCustomDataValue();
            val.Text = value;
            this.valueChanged.emit(val);
        });

        this.group = new FormGroup({
            Name: new FormControl(this.name),
            Text: this.control,
            Id: new FormControl(this.value ? this.value.Id || 0 : 0),
            CustomDataId: new FormControl(this.customDataId)
        });

        this.fieldValues.push(this.group);
    }
}

