import { OnInit } from "@angular/core";
import { PermissionService } from "imagine-ui-ng-security";
import { StateService } from "@uirouter/angular";
import { TranslateService } from "@ngx-translate/core";
var ControlModel = /** @class */ (function () {
    function ControlModel() {
    }
    return ControlModel;
}());
export { ControlModel };
var ControlsSearchComponent = /** @class */ (function () {
    function ControlsSearchComponent(permissionService, stateService, translateService) {
        this.permissionService = permissionService;
        this.stateService = stateService;
        this.translateService = translateService;
        this.searchText = "";
        this.screenReady = false;
        this.pluralMapping = {};
        this.controlStates = [
            "main.toolsControlsProfile",
            "main.shipmethods",
            "main.generalSettingsEdit",
            "main.brandSettingsEdit"
        ];
        this.availableControls = [];
    }
    ControlsSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        var promises = [];
        // run translations
        promises.push(this.translateText());
        // configure available states
        promises.push(this.getAvailableControls().then(function (response) {
            _this.availableControls = response;
        }));
        Promise.all(promises).then(function () {
            _this.screenReady = true;
        });
    };
    ControlsSearchComponent.prototype.translateText = function () {
        var _this = this;
        var promise = this.translateService.get(["CONTROL", "CONTROLS"]).toPromise()
            .then(function (response) {
            _this.pluralMapping["=1"] = response["CONTROL"];
            _this.pluralMapping["other"] = response["CONTROLS"];
        });
        return promise;
    };
    ControlsSearchComponent.prototype.getAvailableControls = function () {
        var _this = this;
        var returnPromise = new Promise(function (resolve, reject) {
            var validStates = _this.controlStates.filter(function (state) {
                return _this.permissionService.checkState(state).value;
            });
            var returnStates = [];
            if (validStates) {
                for (var index = 0; index < validStates.length; index++) {
                    var state = validStates[index];
                    var targetState = _this.stateService.get(state);
                    returnStates.push({
                        Label: targetState.data.pageTitle[0],
                        TargetState: targetState.name,
                        Hidden: false
                    });
                }
            }
            resolve(returnStates);
        });
        return returnPromise;
    };
    Object.defineProperty(ControlsSearchComponent.prototype, "filteredControls", {
        get: function () {
            var textToMatch = this.searchText.toLocaleLowerCase() || "";
            this.availableControls.forEach(function (item) {
                var hidden = item.Label.toLocaleLowerCase().indexOf(textToMatch) < 0;
                item.Hidden = hidden;
            });
            return this.availableControls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlsSearchComponent.prototype, "totalRecordCount", {
        get: function () {
            return this.availableControls.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlsSearchComponent.prototype, "matchingRecordCount", {
        get: function () {
            return this.filteredControls.filter(function (item) { return !item.Hidden; }).length;
        },
        enumerable: true,
        configurable: true
    });
    return ControlsSearchComponent;
}());
export { ControlsSearchComponent };
