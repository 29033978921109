export class PaymentMethodSettingModel {
    AdminSettings: AdminPaymentMethodSettingsModel;
    CreditCardSettings: UserPaymentMethodSettingsModel;
    InvoiceSettings: UserPaymentMethodSettingsModel;
}

export class AdminPaymentMethodSettingsModel {
    CreditCardEnabled: boolean;
    InvoiceEnabled: boolean;

    public get IsValid(): boolean {
        return this.CreditCardEnabled || this.InvoiceEnabled;
    }
}

export class UserPaymentMethodSettingsModel {
    AllLocations: boolean;
    LocationGroupName: string;
    LocationGroupId?: number;
    PendingLocations?: number;
    LocationSubGroups: number[] = [];
    LocationSubGroupNames: object[];
}

export class SubGroupSettingViewModel {
    SubGroupId: number;
    SubGroupName: string;
    CreditCardEnabled: boolean;
    InvoiceEnabled: boolean;

    public get IsValid(): boolean {
        const returnVal = this.CreditCardEnabled || this.InvoiceEnabled;
        return returnVal;
    }
}

export class ShopperSettingsViewModel {
    AllLocations: boolean;
    CreditCardEnabled: boolean;
    InvoiceEnabled: boolean;

    LocationGroupName: string;
    LocationGroupId: number;
    PendingLocations: number;
    LocationGroupSettings: SubGroupSettingViewModel[];

    public get IsValid(): boolean {
        if (this.AllLocations) {
            return this.CreditCardEnabled || this.InvoiceEnabled;
        } else {
            let isValid = this.LocationGroupSettings.length > 0;
            this.LocationGroupSettings.forEach((setting: SubGroupSettingViewModel) => {
                isValid = (isValid && setting.IsValid);
            });

            return isValid;
        }
    }

    public setLocationGroupInfo(id: number, name: string, pendingLocations: number) {
        this.LocationGroupId = id;
        this.LocationGroupName = name;
        this.PendingLocations = pendingLocations;
    }

    public setLocationSubGroups(groups: any[]) {
        this.LocationGroupSettings = groups.map((item) => {
            const returnValue = new SubGroupSettingViewModel();

            returnValue.CreditCardEnabled = false;
            returnValue.InvoiceEnabled = false;
            returnValue.SubGroupId = item.Id;
            returnValue.SubGroupName = item.Name;

            return returnValue;
        });
    }
}

export class PaymentMethodSettingsViewModel {
    AdminSettings: AdminPaymentMethodSettingsModel;
    ShopperSettings: ShopperSettingsViewModel;

    public get IsValid(): boolean {
        return this.ShopperSettings.IsValid && this.AdminSettings.IsValid;
    }
}
