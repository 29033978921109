/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-data-field-control.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ips-text/ips-text.component.ngfactory";
import * as i3 from "../ips-text/ips-text.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../ips-date/ips-date.component.ngfactory";
import * as i6 from "../ips-date/ips-date.component";
import * as i7 from "imagine-ui-ng-core";
import * as i8 from "../ips-list/ips-list.component.ngfactory";
import * as i9 from "../ips-list/ips-list.component";
import * as i10 from "../../service/custom-data-field.service";
import * as i11 from "../ips-number/ips-number.component.ngfactory";
import * as i12 from "../ips-number/ips-number.component";
import * as i13 from "../ips-email/ips-email.component.ngfactory";
import * as i14 from "../ips-email/ips-email.component";
import * as i15 from "@angular/common";
import * as i16 from "./custom-data-field-control.component";
var styles_CustomDataFieldControlComponent = [i0.styles];
var RenderType_CustomDataFieldControlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomDataFieldControlComponent, data: {} });
export { RenderType_CustomDataFieldControlComponent as RenderType_CustomDataFieldControlComponent };
function View_CustomDataFieldControlComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ips-text", [["class", "w-100"]], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.valueChangedHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IpsTextComponent_0, i2.RenderType_IpsTextComponent)), i1.ɵdid(2, 114688, null, 0, i3.IpsTextComponent, [i4.TranslateService], { name: [0, "name"], isFieldRequired: [1, "isFieldRequired"], value: [2, "value"], fieldValues: [3, "fieldValues"], customDataId: [4, "customDataId"], selectionRule: [5, "selectionRule"], maxLength: [6, "maxLength"] }, { valueChanged: "valueChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customDataResult.Name; var currVal_1 = _co.customDataResult.IsFieldRequired; var currVal_2 = _co.customDataResult.Values[0]; var currVal_3 = _co.fieldValues; var currVal_4 = _co.customDataResult.Id; var currVal_5 = _co.customDataResult.SelectionRule; var currVal_6 = ((_co.customDataResult.SelectionRule === "Multiple") ? 500 : 50); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_CustomDataFieldControlComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ips-date", [["class", "w-100"]], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.valueChangedHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IpsDateComponent_0, i5.RenderType_IpsDateComponent)), i1.ɵdid(2, 114688, [[1, 4]], 0, i6.IpsDateComponent, [i4.TranslateService, i7.ActiveProfileService], { name: [0, "name"], isFieldRequired: [1, "isFieldRequired"], value: [2, "value"], fieldValues: [3, "fieldValues"], customDataId: [4, "customDataId"] }, { valueChanged: "valueChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customDataResult.Name; var currVal_1 = _co.customDataResult.IsFieldRequired; var currVal_2 = _co.customDataResult.Values[0]; var currVal_3 = _co.fieldValues; var currVal_4 = _co.customDataResult.Id; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_CustomDataFieldControlComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ips-list", [["class", "w-100"]], null, [[null, "listChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("listChanged" === en)) {
        var pd_0 = (_co.updateList($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_IpsListComponent_0, i8.RenderType_IpsListComponent)), i1.ɵdid(2, 114688, null, 0, i9.IpsListComponent, [i4.TranslateService, i10.CustomDataFieldService], { name: [0, "name"], isFieldRequired: [1, "isFieldRequired"], values: [2, "values"], fieldValues: [3, "fieldValues"], lookupValues: [4, "lookupValues"], selectionRule: [5, "selectionRule"], customDataId: [6, "customDataId"] }, { listChanged: "listChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customDataResult.Name; var currVal_1 = _co.customDataResult.IsFieldRequired; var currVal_2 = _co.customDataResult.Values; var currVal_3 = _co.fieldValues; var currVal_4 = _co.customDataResult.CustomDataOptionList; var currVal_5 = _co.customDataResult.SelectionRule; var currVal_6 = _co.customDataResult.Id; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_CustomDataFieldControlComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ips-number", [["class", "w-100"]], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.valueChangedHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_IpsNumberComponent_0, i11.RenderType_IpsNumberComponent)), i1.ɵdid(2, 114688, null, 0, i12.IpsNumberComponent, [i4.TranslateService], { name: [0, "name"], isFieldRequired: [1, "isFieldRequired"], value: [2, "value"], fieldValues: [3, "fieldValues"], customDataId: [4, "customDataId"] }, { valueChanged: "valueChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customDataResult.Name; var currVal_1 = _co.customDataResult.IsFieldRequired; var currVal_2 = _co.customDataResult.Values[0]; var currVal_3 = _co.fieldValues; var currVal_4 = _co.customDataResult.Id; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_CustomDataFieldControlComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ips-email", [["class", "w-100"]], null, [[null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChanged" === en)) {
        var pd_0 = (_co.valueChangedHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_IpsEmailComponent_0, i13.RenderType_IpsEmailComponent)), i1.ɵdid(2, 114688, null, 0, i14.IpsEmailComponent, [i4.TranslateService], { name: [0, "name"], isFieldRequired: [1, "isFieldRequired"], value: [2, "value"], fieldValues: [3, "fieldValues"], customDataId: [4, "customDataId"] }, { valueChanged: "valueChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customDataResult.Name; var currVal_1 = _co.customDataResult.IsFieldRequired; var currVal_2 = _co.customDataResult.Values[0]; var currVal_3 = _co.fieldValues; var currVal_4 = _co.customDataResult.Id; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_CustomDataFieldControlComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { dateControl: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i15.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomDataFieldControlComponent_1)), i1.ɵdid(5, 278528, null, 0, i15.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomDataFieldControlComponent_2)), i1.ɵdid(7, 278528, null, 0, i15.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomDataFieldControlComponent_3)), i1.ɵdid(9, 278528, null, 0, i15.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomDataFieldControlComponent_4)), i1.ɵdid(11, 278528, null, 0, i15.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomDataFieldControlComponent_5)), i1.ɵdid(13, 278528, null, 0, i15.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i15.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputType; _ck(_v, 3, 0, currVal_0); var currVal_1 = "Text"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "Date"; _ck(_v, 7, 0, currVal_2); var currVal_3 = "List"; _ck(_v, 9, 0, currVal_3); var currVal_4 = "Number"; _ck(_v, 11, 0, currVal_4); var currVal_5 = "Email"; _ck(_v, 13, 0, currVal_5); }, null); }
export function View_CustomDataFieldControlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-data-field-control", [], null, null, null, View_CustomDataFieldControlComponent_0, RenderType_CustomDataFieldControlComponent)), i1.ɵdid(1, 4308992, null, 0, i16.CustomDataFieldControlComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomDataFieldControlComponentNgFactory = i1.ɵccf("app-custom-data-field-control", i16.CustomDataFieldControlComponent, View_CustomDataFieldControlComponent_Host_0, { customDataResult: "customDataResult", fieldValues: "fieldValues" }, { formDirtyChanged: "formDirtyChanged", valueChanged: "valueChanged", loaded: "loaded" }, []);
export { CustomDataFieldControlComponentNgFactory as CustomDataFieldControlComponentNgFactory };
