import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"]
})
export class AboutComponent implements OnInit {
    SiteVersion: string;
    ApiVersion: string;
    errorLabel: string;
    constructor(public $http: HttpClient, public $translate: TranslateService) { }

    ngOnInit() {
        this.SiteVersion = environment.ApplicationVersion;
        this.$translate.get(["UNABLE_TO_GET_API_VERSION"]).subscribe((res: [string]) => {
            this.errorLabel = res["UNABLE_TO_GET_API_VERSION"];
        });
        this.getApiVersion();
    }

    getApiVersion() {
        this.$http.get(`${environment.baseApiUrl}System/v1/Version`).toPromise()
            .then((response: any) => this.ApiVersion = response.Version)
            .catch((response: any) => {
                this.ApiVersion = this.errorLabel;
            });
    }
}
