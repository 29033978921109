<div class="row setup-theme" [formGroup]="themeForm">
    <div class="form-group col-12 col-lg-4">
        <label for="PrimaryHexColor" translate>PRIMARY_COLOR</label>
        <div class="color-container form-control form-control-lg" cpPositionRelativeToArrow="true" cpPosition="bottom" [(colorPicker)]="tempPrimaryHexColor" (colorPickerChange)="onPrimaryHexColorChange($event)">
            <input name="PrimaryHexColor" class="plain" formControlName="primaryHexColor" id="PrimaryHexColor" placeholder="{{ 'ENTER_PRIMARY_COLOR' | translate}}"
                   pattern="#[0-9A-Fa-f]{6}" (blur)="onPrimaryHexBlur()"/>
            <div class="color-preview" [style.background-color]="primaryHexColor.value"></div>
        </div>
    </div>
    <!--<div class="form-group col-12 col-lg-4">
        <label for="SecondaryHexColor" translate>SECONDARY_COLOR</label>
        <div class="color-container form-control form-control-lg" cpPositionRelativeToArrow="true" cpPosition="bottom" [(colorPicker)]="tempSecondaryHexColor" (colorPickerChange)="onSecondaryHexColorChange($event)">
            <input name="SecondaryHexColor" class="plain" id="SecondaryHexColor" formControlName="secondaryHexColor" placeholder="{{ 'ENTER_SECONDARY_COLOR' | translate}}"
                   pattern="#[0-9A-Fa-f]{6}" (blur)="onSecondaryHexBlur()"/>
            <div class="color-preview" [style.background-color]="secondaryHexColor.value"></div>
        </div>
    </div>
    <div class="form-group col-12 col-lg-4">
        <label for="FontHexColor" translate>FONT_COLOR</label>
        <div class="color-container form-control form-control-lg" cpPositionRelativeToArrow="true" cpPosition="bottom" [(colorPicker)]="tempFontHexColor" (colorPickerChange)="onFontHexColorChange($event)">
            <input name="FontHexColor" class="plain" id="FontHexColor" formControlName="fontHexColor" placeholder="{{ 'ENTER_FONT_COLOR' | translate}}"
                   pattern="#[0-9A-Fa-f]{6}" (blur)="onFontHexBlur()"/>
            <div class="color-preview" [style.background-color]="fontHexColor.value"></div>
        </div>
    </div>-->
</div>
<div class="row setup-theme">
    <div class="form-group col-12 col-lg-4 small-logo text-center">
        <div class="d-inline-block w-100 text-center">
            <label class="labels mx-0 mt-0" for="smallLogoFile" translate>SMALL_LOGO</label> <span *ngIf="loadingSmallLogo" class="px-2 fa fa-spin fa-spinner"></span>
        </div>
        <ips-image-edit name="smallLogoFile" #smallLogo id="smallLogoFile" (uploadStarted)="smallLogoUploading(true)" (uploadFinished)="smallLogoUploading(false)"
                        (fileChanged)="onSmallLogoChange($event)" [selectedImage]="parentComponent.smallLogoSelected" [showDelete]="true"></ips-image-edit>
    </div>
    <div class="form-group col-12 col-lg-4 large-logo text-center">
        <div class="d-inline-block w-100 text-center">
            <label class="labels mx-0 mt-0" for="largeLogoFile" translate>LARGE_LOGO</label> <span *ngIf="loadingLargeLogo" class="px-2 fa fa-spin fa-spinner"></span>
        </div>
        <ips-image-edit name="largeLogoFile" #largeLogo id="largeLogoFile" (uploadStarted)="largeLogoUploading(true)" (uploadFinished)="largeLogoUploading(false)"
                        (fileChanged)="onLargeLogoChange($event)" [selectedImage]="parentComponent.largeLogoSelected" [showDelete]="true"></ips-image-edit>
    </div>
</div>
