var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../../environments/environment";
var LocationService = /** @class */ (function (_super) {
    __extends(LocationService, _super);
    function LocationService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.locationAttributes = [{ Label: "POINT_OF_CONTACT", Key: "PointOfContact" },
            { Label: "NOTES", Key: "Notes" }, { Label: "OPEN_DATE", Key: "OpenDate" }, { Label: "FIRST_RECEIPT_DATE", Key: "InitiatePromotionsDate" },
            { Label: "SUSPEND_DATE", Key: "SuspendPromotionsDate" }, { Label: "IMAGE", Key: "Image" }];
        _this.addressAttributes = [{ Label: "ATTENTION_TO", Key: "Attention" }, { Label: "ADDRESS_LINE_1", Key: "Line1" },
            { Label: "ADDRESS_LINE_2", Key: "Line2" }, { Label: "CITY", Key: "City" }, { Label: "STATE_PROVINCE", Key: "StateProvince" }, { Label: "COUNTRY", Key: "Country" },
            { Label: "POSTAL_CODE", Key: "PostalCode" }, { Label: "PHONE", Key: "Phone" }, { Label: "EMAIL", Key: "Email" }, { Label: "FAX", Key: "Fax" }];
        _this.init("Location", "Profile", "v1");
        return _this;
    }
    LocationService.prototype.getLocationList = function (includeClosed) {
        includeClosed = includeClosed || false;
        return this.getByRoute(this.urlApiBaseProductPlusVersion + "Location?" +
            "includeClosed=" + encodeURIComponent(includeClosed.toString()));
    };
    LocationService.prototype.searchLocationsByIds = function (listOfIds) {
        var route = environment.baseApiUrl + "Profile/v1/Location/FilteredSearch/LocationId";
        var requestModel = {
            searchText: null,
            chunkIndex: 0,
            recordCount: listOfIds.length,
            showUnassignedItems: false,
            LocationIds: listOfIds
        };
        return this.postByRoute(route, requestModel);
    };
    LocationService.prototype.getByExternalId = function (id) {
        return this.getByRoute(this.urlApiBaseProductPlusVersion + "Location/External/" + id);
    };
    LocationService.prototype.closeLocation = function (locationId) {
        var route = this.urlApiBaseProductPlusVersion + "Location/" + locationId + "/SetToClosed";
        return this.put({}, null, route);
    };
    LocationService.prototype.reopenLocation = function (locationId) {
        var route = this.urlApiBaseProductPlusVersion + "Location/" + locationId + "/SetToNotClosed";
        return this.put({}, null, route);
    };
    LocationService.prototype.getStatus = function (location) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        //Closed
        if (location.ClosedDate) {
            return "CLOSED";
        }
        //Suspended
        if (location.SuspendPromotionsDate && new Date(location.SuspendPromotionsDate) <= today) {
            return "SUSPENDED";
        }
        //Open
        if (location.OpenDate && new Date(location.OpenDate) <= today) {
            return "OPEN";
        }
        return "PLANNING";
    };
    LocationService.prototype.getStatusInfo = function (location) {
        var status = this.getStatus(location);
        var info = { IconClass: "", Tooltip: "", Status: status };
        switch (status) {
            case "OPEN":
                info.IconClass = "img-location-status-open";
                info.Tooltip = "TOOLTIP_LOCATION_STATUS_OPEN";
                break;
            case "PLANNING":
                info.IconClass = "img-location-status-planning";
                info.Tooltip = "TOOLTIP_LOCATION_STATUS_PLANNING";
                break;
            case "CLOSED":
                info.IconClass = "img-location-status-closed";
                info.Tooltip = "TOOLTIP_LOCATION_STATUS_CLOSED";
                break;
            case "SUSPENDED":
                info.IconClass = "img-location-status-suspended";
                info.Tooltip = "TOOLTIP_LOCATION_STATUS_SUSPENDED";
                break;
        }
        return info;
    };
    LocationService.prototype.headByAttribute = function (attribute) {
        var route = "Location/SearchByAttribute/" + attribute;
        return this.headByRoute("" + this.urlApiBaseProductPlusVersion + route);
    };
    LocationService.prototype.headByOrder = function (id) {
        var route = this.urlApiBaseProductPlusVersion + "Order/" + id + "/Location";
        return this.headByRoute(route);
    };
    LocationService.prototype.deleteCheck = function (id) {
        var route = this.urlApiBaseProductPlusVersion + "Location/" + id + "/DeleteCheck";
        return this.getByRoute(route);
    };
    //Store-Front APIs =============================================
    LocationService.prototype.storeFrontSearch = function (qry, searchByRef) {
        var route = this.urlApiBaseProductPlusVersion + "Location/StoreFront/Search";
        return this.postByRoute(route, { Query: qry, SearchByRef: searchByRef });
    };
    LocationService.prototype.storeFrontGet = function (id) {
        var route = this.urlApiBaseProductPlusVersion + "Location/StoreFront/" + id;
        return this.getByRoute(route);
    };
    LocationService.prototype.storeFrontPost = function (values) {
        var route = this.urlApiBaseProductPlusVersion + "Location/StoreFront";
        return this.postByRoute(route, values);
    };
    LocationService.prototype.storeFrontPut = function (values) {
        var route = this.urlApiBaseProductPlusVersion + "Location/StoreFront";
        return this.put(values, null, route);
    };
    LocationService.prototype.storeFrontDelete = function (id) {
        var route = this.urlApiBaseProductPlusVersion + "Location/StoreFront/" + id;
        return this.deleteByRoute(route);
    };
    LocationService.prototype.storeFrontGetCategories = function () {
        var route = this.urlApiBaseProductPlusVersion + "Location/StoreFront/Categories";
        return this.getByRoute(route);
    };
    return LocationService;
}(IpsBaseWebService));
export { LocationService };
