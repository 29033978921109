import { Component, OnInit } from "@angular/core";
import { getLocaleDateFormat, FormatWidth } from "@angular/common";
import { String as IpsString } from "typescript-string-operations";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { FieldControlBaseComponent } from "../field-control-base";

@Component({
  selector: "app-ips-date",
  templateUrl: "./ips-date.component.html",
  styleUrls: ["./ips-date.component.scss"]
})


export class IpsDateComponent extends FieldControlBaseComponent implements OnInit {
    private calendarTheme = "theme-default";
    public dateFormat: string;
    public dateOptions: {};

    public TranslateStrings: { [key: string]: string } = {
        "PLEASE_ENTER_DYNAMIC_NAME": "",
        "ENTER_DYNAMIC_NAME": "",
        "CALENDAR_DATE_FORMAT": ""
    };

    public errorMessages = {
        "required": () => IpsString.Format(this.TranslateStrings["PLEASE_ENTER_DYNAMIC_NAME"], this.name),
        "bsDate": () => IpsString.Format(this.TranslateStrings["CALENDAR_DATE_FORMAT"], this.dateFormat)
    };

    constructor(public translateService: TranslateService, private activeProfileService: ActiveProfileService) {
        super(translateService);
    }

    public static setControlValue(value: any, fieldGroup: FormGroup) {
        value = IpsDateComponent.convertToDateControlValue(value);
        fieldGroup.patchValue({ Date: value }, {emitEvent: true});
    }

    private static convertToDateControlValue(value: any) {
        if (value) {
            return value.Date ? new Date(value.Date) : new Date(value);
        }
        return "";
    }

    ngOnInit() {

        let localeName = this.activeProfileService.profile.Locale || navigator.language;
        this.dateFormat = getLocaleDateFormat(localeName, FormatWidth.Short).toUpperCase();
        this.dateOptions = {
            containerClass: this.calendarTheme,
            showWeekNumbers: false,
            dateInputFormat: this.dateFormat
        };

        this.TranslateText();
        this.translateService.onDefaultLangChange.subscribe(() => this.TranslateText());

        let validators = [];

        if (this.isFieldRequired) {
            validators.push(Validators.required);
        }

        this.control = new FormControl(IpsDateComponent.convertToDateControlValue(this.value), validators);


        this.control.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe((value) => {
            if (this.group.valid) {
                let val = this.GetCustomDataValue();
                val.Date = value;
                this.valueChanged.emit(val);
            }
        });

        this.group = new FormGroup({
            Name: new FormControl(this.name),
            Date: this.control,
            Id: new FormControl(this.value ? this.value.Id || 0 : 0),
            CustomDataId: new FormControl(this.customDataId)
        });

        this.fieldValues.push(this.group);
    }
}
