import { distinctUntilChanged, debounceTime, mergeMap } from "rxjs/operators";
import { OnInit, EventEmitter } from "@angular/core";
import { SurveyService } from "../../index";
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ActiveProfileService } from "imagine-ui-ng-core";
import { AuthService } from "imagine-ui-ng-security";
import { Observable, from } from "rxjs";
import { MarketService, DataService } from "../../../market/index";
import { String as IpsString } from "typescript-string-operations";
var SurveyQuestionEditComponent = /** @class */ (function () {
    function SurveyQuestionEditComponent(formBuilder, translateService, surveyService, activeProfileService, authService, marketService, dataService) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.translateService = translateService;
        this.surveyService = surveyService;
        this.activeProfileService = activeProfileService;
        this.authService = authService;
        this.marketService = marketService;
        this.dataService = dataService;
        this.sectionDeleted = new EventEmitter();
        // New question input focus
        this.marketHtmlName = "MarketHtmlName";
        this.tempSectionId = 0;
        this.tempQuestionId = 0;
        // New section input focus
        this.sectionHtmlName = "SectionHtmlName";
        this.featuresInAudience = [];
        this.featuresInQuestion = [];
        this.fixtureGroupsInQuestion = [];
        this.fixturesInQuestion = [];
        this.TranslateStrings = {
            "QUESTION": "",
            "QUESTIONS": "",
            "PLEASE_ENTER_SECTION_NAME": "",
            "PLEASE_ENTER_QUESTION_NAME": "",
            "PLEASE_ENTER_LINKED_FEATURE_NAME": "",
            "MAX_LENGTH_ERROR": "",
            "PLEASE_ENTER_VALID_LINKED_FEATURE": "",
            "DUPLICATE_SECTION_NAME": ""
        };
        this.errorMessages = {
            "maxlength": function (params) { return IpsString.Format(_this.TranslateStrings["MAX_LENGTH_ERROR"], params.requiredLength); },
            "invalidMarketName": function () { return _this.TranslateStrings["PLEASE_ENTER_VALID_LINKED_FEATURE"]; },
            "duplicateSectionName": function () { return _this.TranslateStrings["DUPLICATE_SECTION_NAME"]; }
        };
        this.enterSectionName = "";
        this.pluralMapping = {
            "=1": "QUESTION",
            "other": "QUESTIONS"
        };
        this.marketSources = [];
        this.instructionSources = [];
        this.dbInstructions = {};
    }
    SurveyQuestionEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.surveyId = this.parent.get("Id").value;
        this.getInstructions(this.surveyId);
        this.CreateForm();
        this.featuresInAudience = this.dataService.featuresInAudience;
        this.featuresInQuestion = this.dataService.featuresInQuestion;
        this.fixtureGroupsInQuestion = this.dataService.fixtureGroupsInQuestion;
        this.fixturesInQuestion = this.dataService.fixturesInQuestion;
        this.InitalizeForm(this.sectionsModel);
        this.translateService.get("ALL").subscribe(function () { return _this.TranslateText(); });
        this.translateService.onLangChange.subscribe(function () { return _this.TranslateText(); });
    };
    SurveyQuestionEditComponent.prototype.TranslateText = function () {
        for (var _i = 0, _a = Object.keys(this.TranslateStrings); _i < _a.length; _i++) {
            var key = _a[_i];
            this.TranslateStrings[key] = this.translateService.instant(key);
        }
        this.pluralMapping = {
            "=1": this.TranslateStrings["QUESTION"],
            "other": this.TranslateStrings["QUESTIONS"]
        };
    };
    SurveyQuestionEditComponent.prototype.CreateForm = function () {
        var sectionCtrl = this.createSectionControl();
        var sections = this.formBuilder.array([sectionCtrl]);
        this.parent.addControl("SurveySections", sections);
    };
    SurveyQuestionEditComponent.prototype.InitalizeForm = function (sectionsModel) {
        var _this = this;
        //init sections
        if (sectionsModel && sectionsModel.length > 0) {
            while (this.Sections.length > 0) {
                this.Sections.removeAt(0);
            }
            sectionsModel.map(function (section) {
                var sectionCtrl = _this.getNewSection(section);
                _this.Sections.push(sectionCtrl);
            });
            var _loop_1 = function (i) {
                var ordinal = this_1.Sections.controls[i].get("Ordinal").value;
                var found = false;
                if (this_1.openSections) {
                    if (Array.isArray(this_1.openSections)) {
                        found = this_1.openSections &&
                            this_1.openSections.some(function (sec) {
                                return Number(sec) === ordinal;
                            });
                    }
                    else {
                        found = Number(this_1.openSections) === ordinal;
                    }
                }
                if (found) {
                    this_1.activeCollapse(this_1.Sections.controls[i], true);
                }
            };
            var this_1 = this;
            for (var i = 0; i < this.Sections.length; i++) {
                _loop_1(i);
            }
        }
    };
    SurveyQuestionEditComponent.prototype.getInstructions = function (surveyId) {
        var _this = this;
        if (surveyId === 0) {
            return;
        }
        this.surveyService.getInstructions(surveyId).then(function (response) {
            if (response) {
                response.forEach(function (q) { return _this.dbInstructions[q.QuestionId] = q.Instruction; });
            }
        });
    };
    SurveyQuestionEditComponent.prototype.getNewSection = function (section) {
        return new FormGroup({
            Id: new FormControl(section.Id),
            Name: new FormControl(section.Name, [Validators.required, Validators.maxLength(50), this.ValidateSectionName(section.Ordinal)]),
            Ordinal: new FormControl(section.Ordinal),
            SurveyQuestions: new FormArray([]),
            IsCollapsed: new FormControl(true),
            TempId: new FormControl(this.tempSectionId++),
            QuestionCount: new FormControl(section.QuestionCount),
            InputName: new FormControl(this.sectionHtmlName + section.Id)
        });
    };
    SurveyQuestionEditComponent.prototype.addMarketObservable = function (marketCtrl, questionId, sectionId) {
        var marketSource = this.marketService.createFilteredMarketSource(marketCtrl, this.dataService.fixtureGroupsInQuestion, this.dataService.featuresInQuestion, this.dataService.featuresInAudience, this.dataService.fixturesInQuestion, "linkedprofile");
        var key = sectionId + "-" + questionId;
        this.marketSources[key] = marketSource;
    };
    SurveyQuestionEditComponent.prototype.addInstructionObservable = function (questionCtrl, questionId, sectionId) {
        var instSource = this.createInstructionSource(questionCtrl);
        var key = sectionId + "-" + questionId;
        this.instructionSources[key] = instSource;
    };
    SurveyQuestionEditComponent.prototype.createQuestionControl = function (question, sectionId, tempSectionId, ordinal) {
        var _this = this;
        if (sectionId === void 0) { sectionId = 0; }
        if (tempSectionId === void 0) { tempSectionId = 0; }
        if (ordinal === void 0) { ordinal = 1; }
        var fullImage = "";
        if (!question) {
            question = {
                Id: 0,
                Name: "",
                Ordinal: ordinal,
                OptionControl: false,
                SectionId: sectionId,
                FixtureGroupId: 0,
                FixtureId: 0,
                LocationGroupId: 0,
                LocationGroupName: "",
                FullImage: "",
                AnswerType: "ChooseFromOption",
                MessageControl: "CanNotSendMessage",
                Instruction: ""
            };
        }
        if (question.LocationGroup && question.LocationGroup.Media) {
            fullImage = question.LocationGroup.Media.ResourceUri + "?BusinessIdentity=" + this.activeProfileService.businessIdentity + "&idToken=" + this.authService.getIdToken();
        }
        var tempId = this.tempQuestionId++;
        var targetMarketId = 0;
        var marketName = "";
        var marketType = null;
        if (question.LocationGroup) {
            marketType = "LocationGroup";
            targetMarketId = question.LocationGroup.Id;
            marketName = question.LocationGroup.Name;
        }
        else if (question.FixtureGroup) {
            marketType = "FixtureGroup";
            targetMarketId = question.FixtureGroup.Id;
            marketName = question.FixtureGroup.Name;
        }
        else if (question.Fixture) {
            marketType = "Fixture";
            targetMarketId = question.Fixture.Id;
            marketName = question.Fixture.Name;
        }
        var marketCtrl = this.formBuilder.group({
            LinkedProfileMode: new FormControl(true),
            TargetMarketType: new FormControl(marketType),
            TargetMarketId: new FormControl(targetMarketId),
            MarketName: new FormControl(marketName, [Validators.required, this.marketService.checkMarketName()]),
            InputName: new FormControl(this.marketHtmlName + tempSectionId + tempId),
            TypeaheadModalSelection: new FormControl(marketName)
        });
        this.addMarketObservable(marketCtrl, tempId, tempSectionId);
        // Add input focus to the new question.
        this.focusQuestionInput = marketCtrl.get("InputName").value;
        var questionCtrl = new FormGroup({
            Id: new FormControl(question.Id),
            Name: new FormControl(question.Name, [Validators.required, Validators.maxLength(500)]),
            Ordinal: new FormControl(question.Ordinal),
            OptionControl: new FormControl(question.OptionControl),
            SectionId: new FormControl(question.SectionId),
            FullImage: new FormControl(fullImage),
            Market: marketCtrl,
            TempId: new FormControl(tempId),
            EnableOptionControl: new FormControl(true),
            AnswerType: new FormControl(question.AnswerType),
            EnableAnswerType: new FormControl(true),
            MessageControl: new FormControl(question.MessageControl === "Unknown" ? "CanNotSendMessage" : question.MessageControl),
            EnableMessageControl: new FormControl(true),
            Instruction: new FormControl(question.Instruction, [Validators.maxLength(500)])
        });
        questionCtrl.get("Name").valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(function (value) {
            var market = questionCtrl.get("Market").value;
            var updateModel = { Id: market.TargetMarketId, Name: value };
            var storageType = market.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : market.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : market.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null;
            _this.dataService.refreshInQuestion(updateModel, storageType);
        });
        this.addInstructionObservable(questionCtrl, tempId, tempSectionId);
        this.setControlsEnabled(questionCtrl, question.LocationGroup && question.LocationGroup.OptionType === "Bool");
        return questionCtrl;
    };
    SurveyQuestionEditComponent.prototype.getMarketSource = function (section, question) {
        var key = section.get("TempId").value + "-" + question.get("TempId").value;
        return this.marketSources[key];
    };
    SurveyQuestionEditComponent.prototype.getInstructionSource = function (section, question) {
        var key = section.get("TempId").value + "-" + question.get("TempId").value;
        return this.instructionSources[key];
    };
    SurveyQuestionEditComponent.prototype.getErrorMessages = function (key) {
        var _this = this;
        var msgs = Object.assign({}, this.errorMessages);
        if (key) {
            switch (key.toLowerCase()) {
                case "sectionname":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_ENTER_SECTION_NAME"]; };
                    break;
                case "questionname":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_ENTER_QUESTION_NAME"]; };
                    break;
                case "locationgroupname":
                    msgs["required"] = function () { return _this.TranslateStrings["PLEASE_ENTER_LINKED_FEATURE_NAME"]; };
                    break;
            }
        }
        return msgs;
    };
    Object.defineProperty(SurveyQuestionEditComponent.prototype, "Sections", {
        get: function () {
            return this.parent.get("SurveySections");
        },
        enumerable: true,
        configurable: true
    });
    SurveyQuestionEditComponent.prototype.Questions = function (section) {
        return section.get("SurveyQuestions");
    };
    SurveyQuestionEditComponent.prototype.activeCollapse = function (section, scrollToSection) {
        if (scrollToSection === void 0) { scrollToSection = false; }
        var isCollapsed = section.get("IsCollapsed");
        var flag = isCollapsed.value;
        if (flag && section.get("SurveyQuestions").value.length === 0) {
            this.getSurveyData(section, scrollToSection);
        }
        isCollapsed.setValue(!flag);
    };
    SurveyQuestionEditComponent.prototype.changeOptionControl = function (questionControl) {
        var optionControl = questionControl.get("OptionControl");
        optionControl.setValue(!optionControl.value);
        optionControl.markAsDirty();
    };
    SurveyQuestionEditComponent.prototype.getAnswerTypeText = function (questionControl) {
        var answerType = questionControl.get("AnswerType").value;
        var marketType = this.getMarketType(questionControl);
        var result = "";
        switch (answerType) {
            case "ChooseFromOption":
                result = marketType === "FixtureGroup" ? "ENTER_NUMBER_FIXTURES" : "CHOOSE_FROM_OPTIONS";
                break;
            case "TypeInOption":
                result = "TYPE_IN_OPTIONS";
                break;
        }
        return result;
    };
    SurveyQuestionEditComponent.prototype.getAnswerTypeIconClass = function (questionControl) {
        var answerType = questionControl.get("AnswerType").value;
        var isUsingFixture = this.isUsingFixture(questionControl);
        var cssClass = {
            "fa-hand-pointer-o": !isUsingFixture && answerType === "ChooseFromOption",
            "fa-keyboard-o": isUsingFixture || answerType === "TypeInOption"
        };
        return cssClass;
    };
    SurveyQuestionEditComponent.prototype.getMarketType = function (questionControl) {
        var market = questionControl.get("Market").value;
        return market.TargetMarketType;
    };
    SurveyQuestionEditComponent.prototype.isUsingFixture = function (questionControl) {
        return this.getMarketType(questionControl) === "FixtureGroup" || this.getMarketType(questionControl) === "Fixture";
    };
    SurveyQuestionEditComponent.prototype.changeAnswerType = function (questionControl) {
        var answerTypeControl = questionControl.get("AnswerType");
        var currentValue = answerTypeControl.value;
        var newValue = currentValue === "ChooseFromOption" ? "TypeInOption" : "ChooseFromOption";
        answerTypeControl.setValue(newValue);
        if (newValue === "TypeInOption") {
            questionControl.patchValue({
                OptionControl: true // User must be allowed to add new answers
            });
        }
        answerTypeControl.markAsDirty();
        this.setControlsEnabled(questionControl);
    };
    SurveyQuestionEditComponent.prototype.getSurveyData = function (section, scrollToSection) {
        var _this = this;
        if (scrollToSection === void 0) { scrollToSection = false; }
        var sectionId = section.get("Id").value;
        var tempSectionId = section.get("TempId").value;
        this.surveyService.getSurveyQuestionList(sectionId, null).then(function (response) {
            var questionFGs = [];
            var sortedResponse = response.sort(function (a, b) { return a.Ordinal - b.Ordinal; });
            sortedResponse.forEach(function (question) {
                delete _this.dbInstructions[question.Id];
                questionFGs.push(_this.createQuestionControl(question, sectionId, tempSectionId));
            });
            var questions = new FormArray(questionFGs);
            section.setControl("SurveyQuestions", questions);
            if (scrollToSection) {
                var el = document.getElementById("section_" + sectionId);
                el.scrollIntoView();
            }
        });
    };
    SurveyQuestionEditComponent.prototype.addQuestion = function (section) {
        var questions = section.get("SurveyQuestions");
        questions.push(this.createQuestionControl(null, section.get("Id").value, section.get("TempId").value, questions.length + 1));
    };
    SurveyQuestionEditComponent.prototype.removeQuestion = function (section, index) {
        var question = section.get("SurveyQuestions").at(index).value;
        var storageType = question.Market.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : question.Market.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : question.Market.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null;
        this.dataService.removeInQuestion([question.Market.TargetMarketId], storageType);
        section.get("SurveyQuestions").removeAt(index);
        section.markAsDirty();
    };
    SurveyQuestionEditComponent.prototype.createSectionControl = function (section) {
        if (!section) {
            //use ordinal to maintain open sections
            //the ordinal may not be consecutive since some section can be deleted
            var ordinal = this.Sections ? this.Sections.controls.length + 1 : 1;
            section = {
                Id: 0,
                Name: "",
                Ordinal: ordinal,
                SurveyId: this.surveyId,
                SurveyQuestions: []
            };
        }
        var tempId = this.tempSectionId++;
        // Add input focus to the new section.
        this.focusSectionInput = this.sectionHtmlName + tempId;
        return new FormGroup({
            Id: new FormControl(section.Id),
            Name: new FormControl(section.Name, [this.ValidateSectionName(section.Ordinal), Validators.maxLength(50), Validators.required]),
            Ordinal: new FormControl(section.Ordinal),
            SurveyId: new FormControl(section.SurveyId),
            SurveyQuestions: new FormArray([this.createQuestionControl(null, 0, tempId)]),
            IsCollapsed: new FormControl(false),
            TempId: new FormControl(tempId),
            InputName: new FormControl(this.sectionHtmlName + tempId)
        });
    };
    SurveyQuestionEditComponent.prototype.moveQuestionUp = function (section, index) {
        var questions = section.controls.SurveyQuestions.controls;
        var element = questions[index];
        questions.splice(index, 1);
        questions.splice(index - 1, 0, element);
        this.recalculateOrdinals(questions);
    };
    SurveyQuestionEditComponent.prototype.moveQuestionDown = function (section, index) {
        var questions = section.controls.SurveyQuestions.controls;
        var element = questions[index];
        questions.splice(index, 1);
        questions.splice(index + 1, 0, element);
        this.recalculateOrdinals(questions);
    };
    SurveyQuestionEditComponent.prototype.moveSectionUp = function (index) {
        var sections = this.Sections.controls;
        var element = sections[index];
        sections.splice(index, 1);
        sections.splice(index - 1, 0, element);
        this.recalculateOrdinals(sections);
    };
    SurveyQuestionEditComponent.prototype.moveSectionDown = function (index) {
        var sections = this.Sections.controls;
        var element = sections[index];
        sections.splice(index, 1);
        sections.splice(index + 1, 0, element);
        this.recalculateOrdinals(sections);
    };
    SurveyQuestionEditComponent.prototype.recalculateOrdinals = function (controls) {
        controls.forEach(function (control, index) {
            control.controls["Ordinal"].setValue(index + 1);
            control.controls["Ordinal"].markAsDirty();
            control.controls["Ordinal"].markAsTouched();
        });
    };
    SurveyQuestionEditComponent.prototype.addSection = function () {
        this.Sections.push(this.createSectionControl());
    };
    SurveyQuestionEditComponent.prototype.removeSection = function (section, index) {
        var sectionModel = section.value;
        var featuresToRemove = [];
        var fixtureGroupsToRemove = [];
        var fixturesToRemove = [];
        sectionModel.SurveyQuestions.forEach(function (question) {
            if (question.Market.TargetMarketType === "LocationGroup") {
                featuresToRemove.push(question.Market.TargetMarketId);
            }
            if (question.Market.TargetMarketType === "FixtureGroup") {
                fixtureGroupsToRemove.push(question.Market.TargetMarketId);
            }
            if (question.Market.TargetMarketType === "Fixture") {
                fixturesToRemove.push(question.Market.TargetMarketId);
            }
        });
        this.dataService.removeInQuestion(featuresToRemove, "FeatureInQuestion");
        this.dataService.removeInQuestion(fixtureGroupsToRemove, "FixtureGroupInQuestion");
        this.dataService.removeInQuestion(fixturesToRemove, "FixtureInQuestion");
        //remove it from formArray
        this.Sections.removeAt(index);
        //raise event to parent to store the sectionId to be deleted
        if (sectionModel.Id !== 0) {
            this.sectionDeleted.emit(sectionModel.Id);
        }
        section.markAsDirty();
        this.Sections.markAsDirty();
    };
    SurveyQuestionEditComponent.prototype.marketChangedEventHandler = function (event, question) {
        if (event.previous) {
            var previous = event.previous;
            if (previous.TargetMarketId > 0) {
                var storageType = previous.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : previous.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : previous.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null;
                this.dataService.removeInQuestion([previous.TargetMarketId], storageType);
            }
        }
        if (event.current) {
            var current = event.current;
            var storageType = current.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : current.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : current.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null;
            this.dataService.addInQuestion([{ Id: current.TargetMarketId, Name: question.value.Name }], storageType);
            var optionTypeIsBool = false;
            if (current.TargetMarketType === "LocationGroup") {
                optionTypeIsBool = event.current.DisplayInfo.OptionType.toLowerCase() === "bool";
                if (optionTypeIsBool) {
                    question.get("OptionControl").setValue(false);
                    question.get("AnswerType").setValue("ChooseFromOption");
                }
            }
            this.setControlsEnabled(question, optionTypeIsBool);
        }
    };
    SurveyQuestionEditComponent.prototype.setControlsEnabled = function (questionControl, optionTypeIsBool) {
        if (optionTypeIsBool === void 0) { optionTypeIsBool = false; }
        var answerTypeControl = questionControl.get("AnswerType");
        var currentValue = answerTypeControl.value;
        var marketType = this.getMarketType(questionControl);
        var answerTypeEnabled = marketType === "FixtureGroup" || marketType === "Fixture" ? false : !optionTypeIsBool;
        questionControl.get("EnableAnswerType").setValue(answerTypeEnabled);
        var optionControlEnabled = !optionTypeIsBool && currentValue !== "TypeInOption";
        questionControl.get("EnableOptionControl").setValue(optionControlEnabled);
    };
    SurveyQuestionEditComponent.prototype.marketDeletedEventHandler = function (market) {
        market.patchValue({
            MarketName: ""
        });
        var marketModel = market.value;
        var storageType = marketModel.TargetMarketType === "LocationGroup" ? "FeatureInQuestion" : marketModel.TargetMarketType === "FixtureGroup" ? "FixtureGroupInQuestion" : marketModel.TargetMarketType === "Fixture" ? "FixtureInQuestion" : null;
        this.dataService.removeInQuestion([marketModel.TargetMarketId], storageType);
    };
    SurveyQuestionEditComponent.prototype.ValidateSectionName = function (sectionOrdinal) {
        var _this = this;
        return function (control) {
            if (!control || control.value.length <= 0 || !_this.Sections) {
                // Don't bother checking, return all is okay.
                return null;
            }
            var exists = _this.Sections.value.some(function (sec) {
                return sec.Name === control.value && sec.Ordinal !== sectionOrdinal;
            });
            if (exists) {
                return {
                    duplicateSectionName: {
                        valid: false
                    }
                };
            }
            return null;
        };
    };
    SurveyQuestionEditComponent.prototype.HasImage = function (questionControl) {
        return !!questionControl.get("FullImage").value;
    };
    SurveyQuestionEditComponent.prototype.changeMessageControl = function (questionControl) {
        var messageControl = questionControl.get("MessageControl");
        var currentValue = messageControl.value;
        var newValue = currentValue === "CanSendMessage" ? "CanNotSendMessage" : "CanSendMessage";
        messageControl.setValue(newValue);
        messageControl.markAsDirty();
    };
    SurveyQuestionEditComponent.prototype.getMessageControlText = function (questionControl) {
        var msgControl = questionControl.get("MessageControl").value;
        var result = "";
        switch (msgControl) {
            case "CanSendMessage":
                result = "CAN_SEND_MESSAGE";
                break;
            case "CanNotSendMessage":
                result = "CANNOT_SEND_MESSAGE";
                break;
        }
        return result;
    };
    SurveyQuestionEditComponent.prototype.getMessageControlIconClass = function (questionControl) {
        var msgControl = questionControl.get("MessageControl").value;
        var cssClass = {
            "fa-unlock": msgControl === "CanSendMessage",
            "fa-lock": msgControl === "CanNotSendMessage"
        };
        return cssClass;
    };
    SurveyQuestionEditComponent.prototype.createInstructionSource = function (ctrl) {
        var _this = this;
        var source = Observable.create(function (observer) {
            // Runs on every search
            observer.next(ctrl.value.Instruction);
        }).pipe(debounceTime(365), distinctUntilChanged(), mergeMap(function (inputText) {
            return from(_this.getTypeAheadInstructions(inputText.toLowerCase()));
        }));
        return source;
    };
    SurveyQuestionEditComponent.prototype.getTypeAheadInstructions = function (inputText) {
        var _this = this;
        var instructions = [];
        this.Sections.controls.forEach(function (sectionCtrl) {
            var questions = _this.Questions(sectionCtrl);
            if (questions) {
                questions.controls.forEach(function (questionCtrl) {
                    var inst = questionCtrl.value.Instruction;
                    if (inst && inst !== "" && inst.toLowerCase().startsWith(inputText) && instructions.indexOf(inst) === -1) {
                        instructions.push(inst);
                    }
                });
            }
        });
        for (var _i = 0, _a = Object.keys(this.dbInstructions); _i < _a.length; _i++) {
            var key = _a[_i];
            var inst = this.dbInstructions[key];
            if (inst && inst !== "" && inst.toLowerCase().startsWith(inputText) && instructions.indexOf(inst) === -1) {
                instructions.push(inst);
            }
        }
        return Promise.resolve(instructions);
    };
    return SurveyQuestionEditComponent;
}());
export { SurveyQuestionEditComponent };
