import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { SurveyModel } from "../model/SurveyModel";
import { SurveySectionModel } from "../model/SurveySectionModel";
import { SurveyQuestionModel } from "../model/SurveyQuestionModel";
import { SurveyStatusModel } from "../model/SurveyStatusModel";
import { AudienceBalanceListRequestModel } from "../model/AudienceBalanceListRequestModel";
import { MarketModel, MarketSaveModel, ProfileQuestionModel} from "../../market/index";
import { environment } from "../../../environments/environment";
import { SurveyInUseModel } from "../model/SurveyInUseModel";
import { MySurveyQuestionModel } from "../model/MySurveyQuestionModel";
import { SurveyAnswerSaveModel } from "../model/SurveyAnswerSaveModel";
import { SurveyAnswerResponseModel } from "../model/SurveyAnswerResponseModel";
import { SurveyMessageModel, MySurveyQuestionAndMessageModel } from "../index";
import { SurveyMessageWithImageModel } from "../model/SurveyMessageWithImageModel";
import { QuestionInstructionModel } from "../model/QuestionInstructionModel";

@Injectable()
export class SurveyService extends IpsBaseWebService {

    constructor(http: HttpClient, activeProfileService: ActiveProfileService) {
        super(http, <any>environment, activeProfileService);
        this.init("Survey", "Survey", "v1");
    }

    public getStatusInfo(survey: SurveyModel): SurveyStatusModel {
        let info = { IconClass: "", Tooltip: ""};
        switch (survey.Status) {
            case "Open":
                info.IconClass = "img-survey-status-open";
                info.Tooltip = "TOOLTIP_SURVEY_STATUS_OPEN";
                break;
            case "Prep":
                info.IconClass = "img-survey-status-prep";
                info.Tooltip = "TOOLTIP_SURVEY_STATUS_PREP";
                break;
            case "Closed":
                info.IconClass = "img-survey-status-closed";
                info.Tooltip = "TOOLTIP_SURVEY_STATUS_CLOSED";
                break;
        }
        return info;
    }

    public getSurveySections(surveyId: number): Promise<SurveySectionModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}Survey/${surveyId}/SurveySection`;
        return this.getByRoute(route);
    }

    public getSurveyQuestionList(surveySectionId: number, locationId: number): Promise<SurveyQuestionModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}SurveySection/${surveySectionId}/Questions`;

        if (locationId) {
            route = `${route}?locationId=${locationId}`;
        }

        return this.getByRoute(route);
    }

    public getSurveyQuestionsWithSummary(surveySectionId: number): Promise<SurveyQuestionModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}SurveySection/${surveySectionId}/QuestionsWithAnswerSummary`;
        return this.getByRoute(route);
    }

    public calculateAudienceLocationBalanceList(requestModel: AudienceBalanceListRequestModel): Promise<number[]> {
        let route = `${this.urlApiBaseProductPlusVersion}Survey/AudienceLocationBalanceList`;
        return this.postByRoute<number[]>(route, requestModel);
    }

    public calculateAudienceLocationBalance(model: MarketSaveModel[]): Promise<MarketModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}Survey/CalculateAudienceLocationBalance`;
        return this.postByRoute<MarketModel[]>(route, model);
    }

    public postSection(model: SurveySectionModel): Promise<SurveySectionModel> {
        let route = `${this.urlApiBaseProductPlusVersion}SurveySection`;
        return this.postByRoute<SurveySectionModel>(route, model);
    }

    public putSection(model: SurveySectionModel): Promise<SurveySectionModel> {
        let route = `${this.urlApiBaseProductPlusVersion}SurveySection`;
        return this.put<SurveySectionModel>(model, undefined, route);
    }

    public deleteSection(selectionId: number): Promise<boolean> {
        let route = `${this.urlApiBaseProductPlusVersion}SurveySection/${selectionId}`;
        return this.deleteByRoute(route);
    }

    public getQuestionLocationIds(questionId: number, locationSubGroupId: number): Promise<number[]> {
        let route = `${this.urlApiBaseProductPlusVersion}SurveyQuestion/${questionId}/SubGroup/${locationSubGroupId}/LocationIds`;
        return this.getByRoute(route);
    }

    public getFeatureList(surveyId: number): Promise<ProfileQuestionModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}Survey/${surveyId}/LocationGroupList`;
        return this.getByRoute<ProfileQuestionModel[]>(route);
    }

    public getFixtureGroupList(surveyId: number): Promise<ProfileQuestionModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}Survey/${surveyId}/FixtureGroupList`;
        return this.getByRoute<ProfileQuestionModel[]>(route);
    }

    public getFixtureList(surveyId: number): Promise<ProfileQuestionModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}Survey/${surveyId}/FixtureList`;
        return this.getByRoute<ProfileQuestionModel[]>(route);
    }

    public getLocationGroupSurveyInUse(locationGroupId: number): Promise<SurveyInUseModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}LocationGroup/${locationGroupId}/SurveyInUse`;
        return this.getByRoute<SurveyInUseModel[]>(route);
    }

    public getLocationSubGroupSurveyInUse(subGroupId: number): Promise<SurveyInUseModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}LocationSubGroup/${subGroupId}/SurveyInUse`;
        return this.getByRoute<SurveyInUseModel[]>(route);
    }

    public getMySectionQuestionAnswers(sectionId: number, locationId: number): Promise<MySurveyQuestionAndMessageModel> {
        const route = `${this.urlApiBaseProductPlusVersion}SurveySection/${sectionId}/Location/${locationId}/MyQuestions`;
        return this.getByRoute<MySurveyQuestionAndMessageModel>(route);
    }

    public getInstructions(surveyId: number): Promise<QuestionInstructionModel[]> {
        const route = `${this.urlApiBaseProductPlusVersion}Survey/${surveyId}/Instruction`;
        return this.getByRoute<QuestionInstructionModel[]>(route);
    }

    /**
     * Save a new answer
     * @param answer
     */
    public postAnswer(answer: SurveyAnswerSaveModel): Promise<SurveyAnswerResponseModel> {
        const route = `${this.urlApiBaseProductPlusVersion}/Survey/SaveAnswer`;
        return this.postByRoute(route, answer);
    }

    /**
     * Update an existing answer
     * @param answer
     */
    public putAnswer(answer: SurveyAnswerSaveModel): Promise<SurveyAnswerResponseModel> {
        const route = `${this.urlApiBaseProductPlusVersion}/Survey/SaveAnswer`;
        return this.put(answer, undefined, route);
    }

    public getNextLocation(surveyId: number, locationId): Promise<number> {
        let route = `${this.urlApiBaseProductPlusVersion}Survey/${surveyId}/GetNextLocation/${locationId}`;
        return this.getByRoute(route);
    }

    public getNextSurvey(surveyId: number, locationId): Promise<number> {
        let route = `${this.urlApiBaseProductPlusVersion}Survey/${surveyId}/Location/${locationId}/GetNextSurvey/`;
        return this.getByRoute(route);
    }

    public getMySurveySections(surveyId: number, locationId: number): Promise<SurveySectionModel[]> {
        let route = `${this.urlApiBaseProductPlusVersion}Survey/${surveyId}/Location/${locationId}/MySurveySection`;
        return this.getByRoute(route);
    }

    public postMessage(surveyMessage: SurveyMessageModel, file: any): Promise<SurveyMessageWithImageModel> {
        const route = `${this.urlApiBaseProductPlusVersion}/Survey/Message`;
        return this.post(surveyMessage, file, route);
    }

    public putMessage(surveyMessage: SurveyMessageModel, file: any): Promise<SurveyMessageWithImageModel> {
        const route = `${this.urlApiBaseProductPlusVersion}/Survey/Message`;
        return this.put(surveyMessage, file, route);
    }

    public deleteMessage(id: number) {
        const route = `${this.urlApiBaseProductPlusVersion}/Survey/Message/${id}`;
        return this.deleteByRoute(route);
    }
}
