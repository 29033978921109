import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "highlight"
})
export class HighlightFilterPipe implements PipeTransform {
    transform(text: string, search: string, wordBreak: boolean): string {
        if (search && text) {
            // escape any characters that will give regex a problem
            let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
            if (wordBreak) {
                pattern = pattern.split(" ").filter((t) => {
                    return t.length > 0;
                }).join("|");
            }
            const regex = new RegExp(pattern, "gi");
            text = text + "";
            return text.replace(regex, (match) => `<mark class="ips-mark">${match}</mark>`);
        } else {
            return text;
        }
    }
}
