/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sso-token-receiver.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./sso-token-receiver.component";
import * as i5 from "@uirouter/core";
import * as i6 from "imagine-ui-ng-security";
import * as i7 from "imagine-ui-ng-quick-start";
import * as i8 from "imagine-ui-ng-core";
import * as i9 from "../version-check/version-check.service";
var styles_SsoTokenReceiverComponent = [i0.styles];
var RenderType_SsoTokenReceiverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SsoTokenReceiverComponent, data: {} });
export { RenderType_SsoTokenReceiverComponent as RenderType_SsoTokenReceiverComponent };
function View_SsoTokenReceiverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" SIGNING_IN\n"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SsoTokenReceiverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["FAILED_TO_LOG_IN"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SsoTokenReceiverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsoTokenReceiverComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsoTokenReceiverComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.failedSignIn; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.failedSignIn; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SsoTokenReceiverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sso-token-receiver", [], null, null, null, View_SsoTokenReceiverComponent_0, RenderType_SsoTokenReceiverComponent)), i1.ɵdid(1, 114688, null, 0, i4.SsoTokenReceiverComponent, [i5.StateService, i6.AuthService, i7.ProfileService, i1.NgZone, i8.LoggingService, i9.VersionCheckService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SsoTokenReceiverComponentNgFactory = i1.ɵccf("app-sso-token-receiver", i4.SsoTokenReceiverComponent, View_SsoTokenReceiverComponent_Host_0, {}, {}, []);
export { SsoTokenReceiverComponentNgFactory as SsoTokenReceiverComponentNgFactory };
