var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from "@angular/common/http";
import { ActiveProfileService, IpsBaseWebService } from "imagine-ui-ng-core";
import { environment } from "../../../environments/environment";
var LocationFixtureService = /** @class */ (function (_super) {
    __extends(LocationFixtureService, _super);
    function LocationFixtureService(http, activeProfileService) {
        var _this = _super.call(this, http, environment, activeProfileService) || this;
        _this.init("LocationFixture", "Profile", "v1");
        return _this;
    }
    LocationFixtureService.prototype.createLocFixModel = function (locFixId, fixtureId, locationId, qty) {
        return {
            Id: locFixId,
            FixtureName: "",
            Model: "",
            FixtureId: fixtureId,
            LocationId: locationId,
            Count: qty,
            BusinessIdentity: this.activeProfileService.businessIdentity,
            LocationIdentifier: ""
        };
    };
    LocationFixtureService.prototype.SaveList = function (locFixList) {
        return this.postByRoute(this.urlApiBaseProductPlusVersion + "LocationFixture/SaveList", locFixList);
    };
    return LocationFixtureService;
}(IpsBaseWebService));
export { LocationFixtureService };
