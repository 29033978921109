import { IpsBaseWebService, ActiveProfileService } from "imagine-ui-ng-core";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AlertModel } from "../model/AlertModel";
import { AlertAssignmentModel } from "../model/AlertAssignmentModel";

@Injectable()
export class AlertAssignmentService extends IpsBaseWebService {
    constructor(http: HttpClient,
        public activeProfileService: ActiveProfileService
    ) {
        super(http, <any>environment, activeProfileService);
        this.init("AlertAssignment", "Shop", "v1");
    }

    public getAlerts(): Promise<AlertModel[]> {
        return this.getByRoute<AlertModel[]>(`${this.urlApiBaseProductPlusVersion}Alert`);
    }

    public saveAlertAssignments(assignments: AlertAssignmentModel[]): Promise<void> {
        return this.put(assignments, undefined, `${this.urlApiBaseProductPlusVersion}AlertAssignments`);
    }
}
